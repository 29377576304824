export const FINANCING = 'FINANCING'

const Types = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
}

export const NECESSARY_PERMISSIONS = {
  all: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  CREATE: ['CREATE'],
  READ: ['READ'],
  UPDATE: ['UPDATE'],
  DELETE: ['DELETE']
}

// This is the permission groups (the main logic is in here!)
// export const allPermissions: Permissions = { ... }
export const allPermissions = {
  'web.financing.ui.customers': {
    type: ['READ'],
    extraPermissions: [
      {permission: 'service.customer', type: 'READ'},
      {permission: 'web.financing.ui.customers.export', type: 'READ'}
    ]
  },
  'web.financing.ui.customer': {
    type: ['READ', 'CREATE', 'UPDATE'],
    extraPermissions: [
      {permission: 'service.customer', type: ['CREATE', 'UPDATE']},
      {permission: 'service.customer.freeze', type: 'UPDATE'},
      {permission: 'service.customer.manualUnblockDelivery', type: 'UPDATE'},
      {permission: 'service.customer.manualUnblockCheckout', type: 'UPDATE'},
      {permission: 'service.customer.manualUnblockBidding', type: 'UPDATE'},
      {permission: 'service.customer.manualUnblockCredit', type: 'UPDATE'}
    ]
  },
  'web.financing.ui.contract': {
    type: ['READ', 'CREATE', 'UPDATE'],
    extraPermissions: [
      {permission: 'service.contract', type: ['READ', 'CREATE', 'UPDATE']}
    ]
  },
  'web.financing.ui.loans': {
    type: ['READ']
  },
  'web.financing.ui.loan': {
    type: ['READ'],
    extraPermissions: [{permission: 'service.loan', type: 'READ'}]
  },
  'web.financing.ui.ledger': {
    type: ['READ'],
    extraPermissions: [{permission: 'service.ledgerEntry', type: 'READ'}]
  },
  'web.financing.ui.customer.financierSource': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.addDisbursement': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.creditStart': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.repayment': {
    type: ['READ', 'UPDATE']
  },
  'service.eligibilityCheck': {
    type: ['READ']
  },
  'service.loan.rejection': {
    type: ['UPDATE']
  },
  'service.loan.backdatedClosure': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.cancellation': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.requestCancellation': {
    type: ['READ', 'UPDATE']
  },
  'service.loan.minimumInterestPeriodExtension': {
    type: ['READ', 'UPDATE']
  },
  'service.startInterestCalculation': {
    type: ['CREATE']
  },
  'service.loan.exportData': {
    type: ['CREATE']
  },
  'service.approvalRequest.payment': {
    type: ['CREATE', 'UPDATE', 'READ']
  },
  'service.approvalRequest.disbursement': {
    type: ['CREATE', 'UPDATE', 'READ']
  },
  'service.approvalRequest.terms': {
    type: [Types.CREATE, Types.UPDATE, Types.READ]
  },
  permission_manager_override: {
    extraPermissions: [
      {permission: 'service.loan.disbursement.override', type: Types.UPDATE},
      {permission: 'service.loan.creditStart.override', type: Types.UPDATE}
    ]
  },
  'service.blacklistCriteria': {
    extraPermissions: [
      {
        permission: 'service.blacklistCriteria',
        type: NECESSARY_PERMISSIONS.all
      },
      {
        permission: 'service.blacklistCriteria.upsertBlacklistCriteria',
        type: Types.CREATE
      }
    ]
  },
  'service.loan.monthlyPenalInterest': {
    type: [Types.CREATE, Types.READ, Types.DELETE]
  },
  'service.history': {
    type: [Types.READ]
  },
  'service.approvalRequest.activeLoanTerms': {
    type: [Types.READ, Types.CREATE, Types.UPDATE]
  },
  'service.loan.bulkUpload': {
    type: [Types.READ, Types.CREATE]
  },
  'service.approvalRequest.loanPricing': {
    type: [Types.READ, Types.CREATE, Types.UPDATE]
  },
  'service.dealerEnrollShowBanner': {
    type: [Types.READ, Types.CREATE]
  },
  'service.stockaudit': {
    type: [Types.READ, Types.CREATE]
  },
  'service.stockaudit.reports': {
    type: [Types.READ, Types.UPDATE, Types.CREATE]
  },
  'service.finance.vendor.x10': {
    type: [Types.READ, Types.UPDATE]
  },
  'service.finance.vendor.bizloan': {
    type: [Types.READ, Types.UPDATE]
  },
  'service.approvalRequest.additionalLoanFees': {
    type: [Types.READ, Types.CREATE, Types.UPDATE]
  }
}

// NOTE: How this object should be mounted - future typescript implementation
// enum PermissionEnum {
//   CREATE = 'CREATE',
//   READ = 'READ',
//   UPDATE = 'UPDATE',
//   DELETE = 'DELETE'
// }

// type PermissionType = PermissionEnum | PermissionEnum[]

// type Permissions = {
//   [string]: {
//     type?: PermissionType, // NOTE: when a permission doesn't have `type` array, that means that it is only a placeholder group for the `extraPermissions` array
//     extraPermissions: {permission: string, type: PermissionType}[]
//   }
// }
