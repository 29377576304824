import {Grid, TextField} from '@material-ui/core'
import {Schedule} from '@material-ui/icons'
import Select from '../../../components/Select/Select'
import DatePicker from '../../../components/Pickers/DatePicker'
import UserPicker from '../../../components/Pickers/UserPicker'

export const Edit = (props) => {
  const {t, data, options} = props
  const handleTextChange = (name) => (e) => props.update(name)(e.target.value)
  const handleResponsible = (e) =>
    props.update('responsibleName')(e === null ? e : e.label)

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label={t('comment.subject.label')}
          name='CommentSubject'
          id='comment_subject'
          value={data.subject}
          onChange={handleTextChange('subject')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          rows={4}
          label={t('comment.description.label')}
          name='CommentDescription'
          id='comment_description'
          value={data.description}
          onChange={handleTextChange('description')}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid spacing={16} container>
          <Grid item xs={6}>
            <Select
              fullWidth
              label={t('comment.type.label')}
              fieldName='type'
              id='type'
              options={options.commentType}
              selected={props.type}
              onChange={(value) => {
                // Reset subtype on type change otherwise
                // subtype can have incorrect value
                props.update('subtype')('')
                props.update('type')(value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              persistChoice={false}
              fullWidth
              label={t('comment.subtype.label')}
              fieldName='subtype'
              id='subtype'
              options={options.subtypeOptions}
              selected={data.subtype}
              onChange={props.update('subtype')}
            />
          </Grid>
        </Grid>
      </Grid>
      {(data.type === 'task' || props.type === 'task') && (
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              <Select
                fullWidth
                options={options.priority}
                selected={data.priority}
                name='priority'
                label={t('task.priority.label')}
                id='priority'
                onChange={props.update('priority')}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                icon={<Schedule />}
                type='text'
                value={data.dueDate}
                name='dueDate'
                label={t('task.dueDate.label')}
                id='dueDate'
                fieldName='dueDate'
                placeholder={'DD/MM/YYYY'}
                format={'YYYY-MM-DDTHH:mm:ss'}
                onChange={props.update('dueDate')}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                fullWidth
                options={options.statusOptions}
                selected={data.status}
                label={t('global.status.label')}
                name='task_status'
                id='task_status'
                onChange={props.update('status')}
              />
            </Grid>
            <Grid item xs={12}>
              <UserPicker
                filterable
                fullWidth
                id='responsible'
                withTranslate={false}
                label={t('task.responsible.label')}
                userClass='INTERNAL'
                count={props.users.count}
                limit={25}
                placeholder={data.responsibleName}
                options={props.users.options}
                onSelect={handleResponsible}
                selected={data.responsible}
                onLoad={props.setUserOptions}
                onChange={props.update('responsible')}
                debounce
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default Edit
