import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  withStyles
} from '@material-ui/core'
import {ValidatedForm} from '@fcg/formvalidation'

import {
  getAllPermissions,
  getRolePermissions,
  onChange,
  save,
  create,
  resetRolePermissions
} from '../../../store/financingUserGroups/actions'
import {
  getPermissionsConfig,
  getValues
} from '../../../store/financingUserGroups/selectors'
import Edit from './Edit'
import {SlideUp} from '../../../components/Animations/Slides'
import LoadingState from '../../../components/LoadingState'

const styles = (theme) => ({
  actions: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '12px 8px',
    margin: 0,
    borderTop: `1px solid ${theme.palette.grey['300']}`
  }
})

class Editor extends Component {
  state = {
    name: ''
  }

  static getDerivedStateFromProps(props, state) {
    if (state.name === '' && props.name !== '') {
      return {
        name: props.name
      }
    }

    return null
  }

  componentDidMount() {
    this.props.getAllPermissions()

    if (this.props.selectedRoleId) {
      this.props.getRolePermissions(this.props.selectedRoleId)
    }
  }

  handleCancel = (roleId) => {
    let id = this.props.selectedRoleId || roleId

    if (typeof id !== 'string') {
      id = undefined
    }

    this.props.resetRolePermissions()
    this.props.handleClose(id)
  }

  handleSubmit = () => {
    if (this.props.selectedRoleId) {
      this.props.save(this.props.selectedRoleId, this.handleCancel)
    } else {
      this.props.create(this.state.name, this.handleCancel)
    }
  }

  onNameChange = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  render() {
    const {
      t,
      permissionsConfig,
      values,
      handleChange,
      isCreate,
      loadingRolePermissions,
      loadingPermissions
    } = this.props

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={SlideUp}
          maxWidth='md'
          fullWidth
        >
          <ValidatedForm onSubmit={this.handleSubmit} t={t}>
            <DialogContent>
              {loadingRolePermissions || loadingPermissions ? (
                <LoadingState />
              ) : (
                <Edit
                  permissionsConfig={permissionsConfig}
                  t={t}
                  data={values}
                  onChange={handleChange}
                  onNameChange={this.onNameChange}
                  value
                  isCreate={isCreate}
                  name={this.state.name}
                  onClose={this.handleCancel}
                />
              )}
            </DialogContent>

            <DialogActions className={this.props.classes.actions}>
              <Button onClick={this.handleCancel} color='primary'>
                {t('button.cancel')}
              </Button>

              <Button variant='raised' type='submit' color='primary'>
                {isCreate
                  ? t('global.action.create')
                  : t('global.action.update')}
              </Button>
            </DialogActions>
          </ValidatedForm>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const permissionsConfig = getPermissionsConfig(state)
  const values = getValues(state)
  const {
    loadingRolePermissions,
    loadingPermissions,
    groups,
    selected
  } = state.financingUserGroups
  let name = ''

  if (selected !== -1 && groups.length) {
    const selectedGroup = groups.find(({id}) => id === selected)

    if (selectedGroup) {
      name = selectedGroup.name
    }
  }

  return {
    permissionsConfig,
    values,
    loadingRolePermissions,
    loadingPermissions,
    name
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllPermissions: () => dispatch(getAllPermissions()),
  getRolePermissions: (roleId) => dispatch(getRolePermissions(roleId)),
  handleChange: (type, value) => dispatch(onChange(type, value)),
  save: (id, onComplete) => dispatch(save(id, onComplete)),
  create: (name, onComplete) => dispatch(create(name, onComplete)),
  resetRolePermissions: () => dispatch(resetRolePermissions())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(withStyles(styles)(Editor)))
