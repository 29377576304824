import merge from 'lodash/merge'
import {getCountryConfig} from '../../../../config/country'

const checked = getCountryConfig().crmDetails.defaultChecked || []

export const contactSchema = {
  id: '',
  country: '',
  locale: '',
  city: '',
  postcode: '',
  salutation: '',
  firstname: '',
  lastname: '',
  address: '',
  phone: '',
  phone2: '',
  email: '',
  smsMarketing: checked.indexOf('smsMarketing') !== -1 || false,
  emailMarketing: checked.indexOf('emailMarketing') !== -1 || false,
  simplyBookCustomerId: ''
}

const state = {
  fetching: false,
  fetchingLogs: false,
  detail: {...contactSchema},
  lead: {
    count: 0,
    page: 1,
    limit: 25,
    sort: [{field: 'createdAt', order: 'DESC'}],
    data: {}
  },
  comment: {
    count: 0,
    page: 1,
    limit: 25,
    sort: [{field: 'createdAt', order: 'DESC'}],
    data: {}
  },
  task: {
    count: 0,
    page: 1,
    limit: 25,
    sort: [{field: 'dueDate', order: 'ASC'}],
    data: {}
  },
  changes: {
    count: 0,
    page: 1,
    limit: 25,
    sort: [{field: 'updatedAt', order: 'DESC'}],
    data: {}
  }
}

export const getInitialState = (preferences = {}) => {
  const setup = merge(state, preferences)

  return {
    ...setup,
    detail: {
      ...setup.detail
    }
  }
}
