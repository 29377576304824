import * as types from './types'
import {getInitialState, carDataFilesSchema} from './initialState'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'

const reducersFunc = {
  [types.fetchCarDataFilesList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchCarDataFilesListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    carDataFilesList: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchCarDataFilesListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, filters: action.payload}
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: carDataFilesSchema
  })
}
