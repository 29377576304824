import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

import Edit from './Edit'
import {ValidatedForm} from '@fcg/formvalidation'
import * as selectors from '../../store/cardata/selectors'
import {getCarLocations} from '../../store/config/actions'
import {
  updateCarDataField,
  updateCarData,
  resetCarData,
  hideCreateEditDialog,
  createCarData
} from '../../store/cardata/actions'
import {carDataFormConfig} from '../../config/pages/carData/carDataFormConfig'
import formWithCountryOptions from '../../components/formWithCountryOptions'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}
class CarDataEditDialog extends Component {
  componentDidMount() {
    this.props.getLocations()
  }

  handleSubmit = () => {
    const {editState, updateCarData, createCarData, form} = this.props

    editState ? updateCarData(form) : createCarData(form)
  }

  handleInput = (e, name) => {
    this.props.updateCarDataField({
      name,
      value: e.target.value
    })
  }

  handleNumber = (e, name) => {
    this.props.updateCarDataField({
      name,
      value: parseInt(e.target.value)
    })
  }

  handleFloat = (e, name) => {
    this.props.updateCarDataField({
      name,
      value: parseFloat(e.target.value)
    })
  }

  handleCancel = () => {
    this.props.hideCreateEditDialog()
  }

  render() {
    const {t, editState, form, options} = this.props

    const dialogTitle = editState
      ? `${t('global.action.update')} ${t('entity.carData')}`
      : `${t('global.action.create')} ${t('entity.carData')}`

    const buttonText = editState
      ? t('global.action.update')
      : t('global.action.create')

    return (
      <Dialog open={true} maxWidth={false} onClose={this.handleCancel}>
        <ValidatedForm onSubmit={this.handleSubmit} t={t}>
          <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Edit
              {...this.props}
              data={form}
              options={options}
              update={this.update}
              handleTextInput={this.handleInput}
              handleNumberInput={this.handleNumber}
              handleFloatInput={this.handleFloat}
            />
          </DialogContent>
          <DialogActions style={dialogActionsStyles}>
            <Button onClick={this.handleCancel} color='primary'>
              {t('button.cancel')}
            </Button>
            <Button variant='raised' type='submit' color='primary'>
              {buttonText}
            </Button>
          </DialogActions>
        </ValidatedForm>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  form: selectors.getFormData(state),
  editState: selectors.getEditState(state),
  options: {
    locations: state.config.options.locations || []
  }
})

const mapDispatchToProps = (dispatch) => ({
  hideCreateEditDialog: () => dispatch(hideCreateEditDialog()),
  resetCarData: () => dispatch(resetCarData()),
  updateCarDataField: (fieldData) => dispatch(updateCarDataField(fieldData)),
  updateCarData: (carData) => dispatch(updateCarData(carData)),
  createCarData: (carData) => dispatch(createCarData(carData)),
  getLocations: () => dispatch(getCarLocations())
})

const CarDataEditorForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  formWithCountryOptions(
    carDataFormConfig,
    'carDataDetails'
  )(translate()(CarDataEditDialog))
)

export default CarDataEditorForm
