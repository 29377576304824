import i18n from '../i18n-client'

export const formatLocale = (locale) => {
  let output = locale

  if (locale.match('-') !== null) {
    const formattedLocaleParts = locale.split('-')
    output = [
      formattedLocaleParts[0],
      formattedLocaleParts[1].toUpperCase()
    ].join('-')
  }

  return output
}

export const createTranslation = (config) => {
  const country =
    config.country && config.country.language ? config.country : config //TODO: remove this after aligning configs
  const locales = country.language.options.map((option) => option.value)
  const selected = formatLocale(country.language.selected)

  locales.map((locale) => {
    i18n.addResourceBundle(
      formatLocale(locale),
      'admin',
      window.translation!==undefined && window.translation[locale],
      true
    )
  })

  i18n.changeLanguage(selected)

  return i18n
}
