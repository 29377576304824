import {createSelector} from 'reselect'
import groupBy from 'lodash/groupBy'
import {allPermissions} from './permissionsConfig'

const groupSelector = (state) => state.financingUserGroups.groups
const permissionSelector = (state) => state.financingUserGroups.permissions
const rolePermissionSelector = (state) =>
  state.financingUserGroups.rolePermissions

const getUiPermissions = (permissions) => {
  const uiPermissions = permissions.filter(
    (item) =>
      item.entity.includes('web.financing') || item.entity.includes('service')
  )
  const permissionGroups = groupBy(uiPermissions, 'entity')

  return permissionGroups
}

const permissionConfigSelector = createSelector(
  permissionSelector,
  getUiPermissions
)

const rolePermissionConfigSelector = createSelector(
  rolePermissionSelector,
  getUiPermissions
)

export const getPermissionsConfig = createSelector(
  permissionConfigSelector,
  (permissions) => ({permissions, allPermissions})
)

export const getValues = createSelector(
  rolePermissionConfigSelector,
  (permissions) =>
    Object.entries(permissions).reduce(
      (acc, [key, props]) => ({
        ...acc,
        [key]: props.map((prop) => prop.type)
      }),
      {}
    )
)

export const getRoles = createSelector(groupSelector, (groups) =>
  groups.map(({name, id}) => ({
    label: name,
    value: id
  }))
)
