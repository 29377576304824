import {Component} from 'react'
import PropTypes from 'prop-types'
import {cleanInputData} from '../../../../utils/objectOperations'

export const withRange = (WrappedComponent) => {
  return class withRange extends Component {
    state = {
      from: undefined,
      to: undefined
    }

    static propTypes = {
      from: PropTypes.string,
      to: PropTypes.string,
      fieldName: PropTypes.string,
      fromInputProps: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string
      }),
      toInputProps: PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string
      })
    }

    static defaultProps = {
      fromInputProps: {
        label: 'filters.range.from.label',
        id: 'from',
        value: ''
      },
      toInputProps: {
        label: 'filters.range.to.label',
        id: 'to',
        value: ''
      }
    }

    componentDidMount() {
      const {from, to} = this.props.value

      this.setState({from, to})
    }

    handleChange = (name) => (value) => {
      const inputValue =
        typeof value === 'object' && value.target ? value.target.value : value

      this.setState(
        {
          ...this.state,
          [name]:
            this.props.type === 'number'
              ? inputValue !== ''
                ? parseInt(inputValue, 10)
                : undefined
              : inputValue.trim()
        },
        () => {
          this.props.handleChange({
            field: this.props.fieldName,
            value: cleanInputData({...this.state})
          })
        }
      )
    }

    handleKeyUp = (e) => {
      const code = e.keyCode || e.which

      return code === 13 ? this.props.handleSave() : undefined
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleChange={this.handleChange}
          handleKeyUp={this.handleKeyUp}
          from={this.state.from}
          to={this.state.to}
        />
      )
    }
  }
}

export default withRange
