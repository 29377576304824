export type CellVariant = "body " | "footer" | "head"
export enum DownloadType {
    capacity = 'capacity',
    availability = 'availability'
}

export type TableRowCells = {
    uploadTime: String,
    fileName: String,
    uploadedBy: String,
    status: String,
    uploadType?: String,
    createdAt?: String
}

export type StoreObject = {
    storeId: string,
    city: string,
    locationDescription?: string,
    serviceType: string,
    biMapping: string,
    inspectionPoint?: string,
    address1?: string,
    address2?: string,
    zipcode?: string,
    timeZone: string,
    timeZoneOffset: Number,
    lat?: string,
    lng?: string,
    metaInfo?: Object,
    position?: boolean,
    existsOnSimplyBook: boolean,
    region?: string,
    sapPlantId?: string,
    sapStorageLocationId?: string,
    active: boolean
}
