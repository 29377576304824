import * as types from './types'

export const startRangeChange = (calendarId) => (date) => ({
  type: types.startRangeChange,
  payload: {calendarId, startDate: date}
})

export const completeRangeChange = (calendarId) => (date) => ({
  type: types.completeRangeChange,
  payload: {calendarId, endDate: date}
})

export const applyRangePreset = (calendarId) => ({name, from, to}) => ({
  type: types.applyRangePreset,
  payload: {calendarId, name, dates: {from, to}}
})

export const fullRangeChange = (calendarId) => ({from, to}) => ({
  type: types.fullRangeChange,
  payload: {calendarId, startDate: from, endDate: to}
})

export const cancelRangeChange = (calendarId) => () => ({
  type: types.cancelRangeChange,
  payload: {calendarId}
})

export const selectDay = (calendarId) => (day) => ({
  type: types.selectDay,
  payload: {calendarId, day}
})

export const changeVisibleMonths = (calendarId) => (difference) => ({
  type: types.changeVisibleMonths,
  payload: {calendarId, difference}
})

export const reset = (calendarId) => () => ({
  type: types.reset,
  payload: {calendarId}
})

export const actionPackage = {
  startRangeChange,
  completeRangeChange,
  cancelRangeChange,
  fullRangeChange,
  changeVisibleMonths,
  applyRangePreset,
  selectDay,
  reset
}

export const initialize = ({calendarId, isRange, selected}) => ({
  type: types.initialize,
  payload: {calendarId, isRange, selected}
})

export const getActions = (calendarId) => {
  return Object.keys(actionPackage).reduce((acc, action) => {
    acc[action] = actionPackage[action](calendarId)

    return acc
  }, {})
}

export const destroy = (calendarId) => ({
  type: types.destroy,
  payload: {calendarId}
})

export const toggleOpen = (calendarId) => ({
  type: types.toggleOpen,
  payload: {calendarId}
})
