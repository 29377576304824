import styled from 'styled-components'
import {Link} from 'react-router-dom'

const gridSize = 12

export const Title = styled(Link)`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  display: none;

  @media (max-width: 525px) {
    display: block;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 525px) {
    justify-content: space-evenly;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5em;

  @media (max-width: 525px) {
    display: none;
  }
`

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.width ? `${(props.width / gridSize) * 100}%` : '100%'};
  font-size: 14px;
  padding-right: 2em;
`

export const Span = styled.label`
  color: ${(props) => props.color || '#c0c0c0'};
  padding-top: 4px;
`
