import values from 'lodash/values'
import {flattenResponse} from '../utils'

const updateArrayWithItem = (data) => (item) =>
  item.id && item.id === data.id ? data : item
const getChunk = (state, nest) =>
  nest === false ? {...state} : {...state[nest]}

const toggleSort = (nest, single = false) => (state, action) => {
  const chunk = nest === false ? state : state[nest]
  const {sort} = action.payload
  const order = sort.order.value || sort.order
  const currentSortState = [...chunk.sort]
  const currentSortIndex = currentSortState.findIndex(
    (item) => item.field === sort.field
  )

  if (sort.remove === true && currentSortIndex > -1) {
    currentSortState.splice(currentSortIndex, 1)
  } else if (currentSortIndex > -1) {
    sort.order = order === 'DESC' ? 'ASC' : 'DESC'
    currentSortState.splice(currentSortIndex, 1, sort)
  } else {
    if (single === true) {
      currentSortState.splice(0, currentSortState.length)
    }

    currentSortState.push(sort)
  }

  const result =
    nest === false
      ? {...state, sort: [...currentSortState]}
      : {...state, [nest]: {...chunk, sort: [...currentSortState]}}

  return result
}

export const withSort = (types, nest = false) => ({
  [types.setSort]: (state, action) => {
    const chunk = getChunk(state, nest)
    const sort = action.payload.sort

    return nest === false
      ? {...state, sort}
      : {...state, [nest]: {...chunk, sort}}
  },
  [types.toggleSort]: toggleSort(nest)
})

export const withSingleSort = (types, nest = false) => ({
  [types.toggleSort]: toggleSort(nest, true)
})

export const withColumnVisibility = (types, nest = false) => ({
  [types.toggleField]: (state, action) => {
    const chunk = getChunk(state, nest)
    const fields = [...chunk.fields]
    const fieldIndex = fields.findIndex(({key}) => key === action.payload)
    const toggle =
      typeof fields[fieldIndex].show === 'undefined'
        ? false
        : !fields[fieldIndex].show

    fields[fieldIndex].show = toggle

    return nest === false
      ? {...state, fields}
      : {...state, [nest]: {...chunk, fields}}
  }
})

export const withFilters = (types, nest = false) => ({
  [types.updateFilters]: (state, action) => {
    const chunk = getChunk(state, nest)
    const {filters} = action.payload

    return nest === false
      ? {...state, page: 1, filters}
      : {...state, [nest]: {...chunk, page: 1, filters}}
  }
})

export const withDataLoading = (types, nest = false) => ({
  [types.fetching]: (state, action) => {
    const chunk = getChunk(state, nest)
    const fetching =
      typeof action.payload === 'undefined' ? true : action.payload

    return nest === false
      ? {...state, fetching}
      : {...state, [nest]: {...chunk, fetching}}
  },
  [types.dataLoaded]: (state, action) => {
    const chunk = getChunk(state, nest)
    const {data, page, filters, sort, limit, flatten} = action.payload

    const parsedData =
      typeof flatten === 'undefined' || flatten
        ? flattenResponse(data.list, !isNaN(flatten) ? flatten : Infinity)
        : data.list
    const update = {
      data: parsedData,
      count: data.count,
      limit,
      page,
      filters,
      sort,
      fetching: false
    }

    return nest === false
      ? {...state, ...update}
      : {...state, [nest]: {...chunk, ...update}}
  },
  [types.updateOne]: (state, action) => {
    const chunk = getChunk(state, nest)
    const {data, flatten} = action.payload

    const currentData = !nest ? state.data : state[nest].data
    const updatedDataArray = Array.isArray(currentData)
      ? currentData.map(updateArrayWithItem(data))
      : values(currentData).map(updateArrayWithItem(data))
    const parsedData =
      typeof flatten === 'undefined' || flatten
        ? flattenResponse(
            updatedDataArray,
            !isNaN(flatten) ? flatten : Infinity
          )
        : updatedDataArray
    const update = {
      data: parsedData
    }

    return nest === false
      ? {...state, ...update}
      : {...state, [nest]: {...chunk, ...update}}
  }
})

export const generateReducer = (types, nest = false) => ({
  ...withColumnVisibility(types, nest),
  ...withDataLoading(types, nest),
  ...withFilters(types, nest),
  ...withSort(types, nest)
})
