import {FIRST_STEP} from '../../pages/BulkUpload/constants'

const state = {
  isUploading: false,
  uploadError: null,
  selectedBusinessPartner: null,
  list: {
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: []
  },
  activeListType: 'all',
  step: FIRST_STEP,
  dialogVisibility: false,
  dialogNotificationVisibility: false,
  bulkUploadDataList: [],
  upload: {
    loading: false,
    error: null,
    uploadSuccess: [],
    duplicateCar: [],
    uploadFail: []
  },
  form: {
    carType: '',
    purchaseChannel: 'B2B',
    boughtAt: new Date().toISOString(),
    b2bDeal: '',
    purchasedBy: '',
    type: '',
    subtype: 'car',
    contactType: 'seller',
    contactSubType: '',
    bankName: '',
    bankCode: '',
    bankAccountOwner: '',
    bankAccountNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    zip: '',
    city: '',
    taxId: '',
    bankAccountOwnerTaxId: '',
    currentLocation: '',
    purchaseLocation: '',
    company: ''
  }
}

export const getInitialState = () => ({
  options: {},
  ...state
})
