import withRange from './withRange'
import {withStyles} from '@material-ui/core'
import {translate} from 'react-i18next'
import styles from './styles'
import {TextInput} from '@fcg/form-builder'
import {NumberWithConfig} from './NumberFilterWithConfig'

/*
MaterialUI is going to release Slider later, perhaps not even with v1.0.
Refer to https://github.com/mui-org/@material-ui/core/issues/4793 for updates.
*/

/**
 * This function gets the default value of the from and to value types
 * in the filter popup
 * @param {string} type Datatype of the key
 * @returns {number | string}
 */
const getDefaultValue = (type) => (type === 'number' ? 0 : '')

export const FilterRange = withStyles(styles)((props) => {
  const {from, to, fromInputProps, toInputProps, classes, handleChange} = props
  const type = props.type === 'number' ? 'number' : 'text'
  const extractValue = (name) => (value) => handleChange(name)(value)
  const Input = props.currency === true ? NumberWithConfig : TextInput

  return (
    <div className={props.className || 'FilterRange'}>
      <Input
        fullWidth
        type={type}
        {...fromInputProps}
        label={props.t(fromInputProps.label)}
        InputProps={{className: classes.input}}
        value={from || getDefaultValue(type)}
        onChange={extractValue('from')}
      />
      <Input
        fullWidth
        type={type}
        {...toInputProps}
        label={props.t(toInputProps.label)}
        InputProps={{className: classes.input}}
        value={to || getDefaultValue(type)}
        onKeyUp={props.handleKeyUp}
        onChange={extractValue('to')}
      />
    </div>
  )
})

export default withRange(translate()(FilterRange))
