import styled from 'styled-components'
import {translate} from 'react-i18next'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'
import {HeaderTitle} from './HeaderTitle'

const DriverProfileImageWidth = 1 / 3
const DriverPersonalInfowidth = 2 / 3

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const AvatarDriver = styled(Avatar)`
  padding: 50px;

  svg {
    font-size: 50px;
  }
`

const AvatarDriverImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? `${props.width * 100}%` : '100%')};
  font-size: 14px;
  align-items: start;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-left: 5px;
`

const Id = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.33;
  letter-spacing: 2px;
  margin-bottom: 8px;
`

const InfoIcon = (icon) => styled(icon)`
  color: #747474;
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
`

const Span = styled.span`
  color: #7d7d7d;
`

const DriverId = (props) => {
  if (!props.data.id) {
    return null
  }

  const label = props.t('driverList.placeholder.internalId')
  const driverId = props.data.internalId

  return <Id data-test='field-driver-id'>{`${label}: ${driverId}`}</Id>
}

const Status = (props) => {
  if (!props.data.metaInfo.status || !props.userId) {
    return null
  }
  const VerifiedUserIcon = InfoIcon(VerifiedUser)

  return (
    <Content>
      <VerifiedUserIcon />
      <Span data-test='field-driver-status'>
        {props.t(`driver.page.field.status.${props.data.metaInfo.status}`)}
      </Span>
    </Content>
  )
}

const ProfileImage = (props) => {
  const {driverImage} = props

  return driverImage ? (
    <AvatarDriverImage src={driverImage.link} data-test='driver-avatar-image' />
  ) : (
    <AvatarDriver data-test='driver-avatar-no-image'>
      <Person />
    </AvatarDriver>
  )
}

const LeftHeader = (props) => (
  <Wrapper>
    <Content width={DriverProfileImageWidth}>
      <ProfileImage {...props} />
    </Content>
    <Content width={DriverPersonalInfowidth}>
      <DriverId {...props} />
      <HeaderTitle {...props} />
      <Status {...props} />
    </Content>
  </Wrapper>
)

export default translate()(LeftHeader)
