import {Component} from 'react'
import {
  MenuItem,
  TextField,
  Grid,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  withStyles
} from '@material-ui/core'
import {Save, Clear} from '@material-ui/icons'
import omit from 'lodash/omit'

const styles = (theme) => ({
  root: {
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5
  }
})

export class OtherInput extends Component {
  static displayName = 'Select:OtherInput'

  state = {
    value: '',
    show: false
  }

  static defaultProps = {
    type: 'text'
  }

  componentDidCatch(e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  handleKeyUp = (e) => {
    e.preventDefault()
    const keyCode = e.which || e.keyCode

    if (keyCode === 13) {
      return this.save()
    } else if (keyCode === 27) {
      return this.dismiss()
    }
  }

  dismiss = () => this.setState({value: '', show: false})

  showInput = () => this.setState({show: true})

  update = (e) =>
    this.setState({
      value:
        this.props.type === 'number'
          ? parseInt(e.target.value, 10)
          : e.target.value
    })

  save = () => {
    const {value} = this.state

    if (value === '') {
      this.setState({show: false, selected: false})
    }

    this.setState({show: false, value: '', selected: false}, () =>
      this.props.onSave(value)
    )
  }

  render() {
    const {t, classes, multiple} = this.props
    const menuProps = omit(this.props, [
      't',
      'classes',
      'onSave',
      'onRemove',
      'multiple'
    ])
    const {show} = this.state
    const label = t('form.select.other')
    const input = (
      <Grid container>
        <Grid item xs={10}>
          <ListItemText disableTypography>
            <TextField
              value={this.state.value}
              fullWidth
              type={this.props.type}
              onChange={this.update}
              onKeyUp={this.handleKeyUp}
            />
          </ListItemText>
        </Grid>
        <Grid item xs={2}>
          <ListItemSecondaryAction>
            <IconButton onClick={this.save} classes={classes} color='secondary'>
              <Save />
            </IconButton>
            <IconButton onClick={this.dismiss} classes={classes}>
              <Clear />
            </IconButton>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    )

    return (
      <MenuItem value={this.state.value} {...menuProps}>
        {show === true ? (
          input
        ) : (
          <ListItemText
            inset={multiple === true}
            primary={label}
            onClick={this.showInput}
          />
        )}
      </MenuItem>
    )
  }
}

export default withStyles(styles)(OtherInput)
