import gql from '@fcg/lib-gql/gql'
import {createSelector} from 'reselect'
import values from 'lodash/values'
import toArray from 'lodash/toArray'
import {
  getCountryCode,
  isPageHidden,
  selectorPath as configSelectorPath
} from '../config/selectors'
import {compose, values as ramdaValues, find, propEq} from 'ramda'
import {checkUserPermissions} from '../auth/selectors'
import {
  NECCESSARY_PERMISSIONS,
  OWNERSHIP_PERMISSION
} from '../crmUserGroups/permissionsConfig'
import {transformList} from './selectorHelpers'
import {
  TICKET_ENTITY_TYPES,
  TICKET_TYPES_ENUM
} from '../../config/entities/ticket'

import {transformTicketFilters} from '../common/selectors'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 25

const selectorPath = (state) => state.ownershipTransfer
export const listSelectorPath = (state) => selectorPath(state).list

export const getCount = (state) => listSelectorPath(state).count
export const getError = (state) => listSelectorPath(state).error
export const isFetching = (state) => listSelectorPath(state).fetching
export const getUpdateErrors = (state) =>
  listSelectorPath(state).rowUpdateErrors
export const getUpdatingStatus = (state) => listSelectorPath(state).rowsUpdating
export const getFilters = (state) => listSelectorPath(state).filters
export const fieldsPathSelector = (state) =>
  Object.values(selectorPath(state).fields)
export const getSubfields = (state) => selectorPath(state).subfields
export const getSubtableFields = (state) => selectorPath(state).subtableFields
const getCarOwnsershipConfig = createSelector(
  configSelectorPath,
  (config) => config.carOwnership
)
export const filterFields = createSelector(
  fieldsPathSelector,
  (carOwnsershipFields) => {
    if (carOwnsershipFields) {
      return Object.values(carOwnsershipFields)
    }
  }
)
export const getFields = createSelector(
  fieldsPathSelector,
  getCarOwnsershipConfig,
  (carOwnsershipFields, carOwnsershipConfig = []) => {
    if (
      Array.isArray(carOwnsershipConfig.hiddenColumns) &&
      carOwnsershipConfig.hiddenColumns.length
    ) {
      return carOwnsershipFields.reduce((acc, field) => {
        const fieldHidden = carOwnsershipConfig.hiddenColumns.includes(
          field.key
        )

        if (fieldHidden) {
          return acc
        }

        return [...acc, field]
      }, [])
    }

    return carOwnsershipFields
  }
)

export const getSubtableSummaryFields = (state) =>
  values(selectorPath(state).subtableSummaryFields)
export const getLimit = (state) => {
  const {limit} = listSelectorPath(state)

  return limit !== null ? limit : DEFAULT_LIMIT
}
export const getSort = (state) => {
  const sort = listSelectorPath(state).sort

  return sort && Array.isArray(sort) && sort.length
    ? {...sort[0], order: new gql.EnumType(sort[0].order)}
    : null
}
export const getPage = (state) => {
  const {page} = listSelectorPath(state)

  return page !== null ? page : DEFAULT_PAGE
}
export const getLocale = (state) => {
  return state.config.country.selected
}
export const getOptions = (state) => toArray(selectorPath(state).options)
export const getSelectedTicketId = (state) => selectorPath(state).selectedTicket
export const hasUpdated = (state) => selectorPath(state).updateCompleted
export const isTicketUpdating = (state) => selectorPath(state).updateInProgress

export const getCarPaymentReceiptsSelection = (state) =>
  listSelectorPath(state).carPaymentReceiptsSelection

export const getTickets = (state) => {
  const store = selectorPath(state)

  return !store || !store.list || !store.list.data ? [] : store.list.data
}

const addErrorAndLoadingStatus = (updateErrors, updatingStatus) => (
  ticket
) => ({
  ...ticket,
  updateError: updateErrors.filter(({id}) => id === ticket.id),
  isUpdating: updatingStatus.filter((id) => id === ticket.id)
})

export const getListData = createSelector(
  [selectorPath, getUpdateErrors, getUpdatingStatus],
  (store, updateErrors, updatingStatus) => {
    if (!store || !store.list || !store.list.data) {
      return []
    }

    return transformList(store.list.data).map(
      addErrorAndLoadingStatus(updateErrors, updatingStatus)
    )
  }
)

export const getSelectedTicket = createSelector(
  getSelectedTicketId,
  getTickets,
  (ticketId, tickets) =>
    compose(find(propEq('id', ticketId)), ramdaValues)(tickets)
)

export const getRowFetchingStatus = createSelector(listSelectorPath, (list) =>
  list.rowsFetching.map((status) => status.id)
)

export const getRowErrorStatus = createSelector(listSelectorPath, (list) =>
  list.rowsFetchingErrors.map((status) => status)
)

export const getExpandedRows = (state) => listSelectorPath(state).expandedRows
export const isRowExpanded = createSelector(
  getExpandedRows,
  (state, props) => props,
  (rows, rowId) => rows.includes(rowId)
)

export const getTicketById = createSelector(
  [listSelectorPath, (state, props) => props],
  (list, props) => {
    const ticket = values(list.data).find(({id}) => id === props.id)

    return ticket || null
  }
)

export const getTicketEntity = (entityType) =>
  createSelector(getTicketById, (ticket) =>
    ticket && ticket.ticketEntity
      ? ticket.ticketEntity.filter((entity) => entity.entityType === entityType)
      : []
  )
export const getEntityIds = (getEntities) =>
  createSelector(getEntities, (entities) =>
    entities.map(({entityId}) => entityId)
  )
export const getTicketCars = getTicketEntity(TICKET_ENTITY_TYPES.CAR)
export const getTicketDocuments = getTicketEntity(TICKET_ENTITY_TYPES.DOCUMENT)

export const getTicketCarIds = getEntityIds(getTicketCars)
export const getTicketDocumentIds = getEntityIds(getTicketDocuments)

export const rowHasData = createSelector(getTicketDocuments, (docs) =>
  docs ? docs.every((doc) => doc.data) : false
)

const checkPermissionSelector = (permission, type) => (state) =>
  checkUserPermissions(state, {
    entity: permission,
    types: type
  })

export const canAccessPage = checkPermissionSelector(
  OWNERSHIP_PERMISSION,
  NECCESSARY_PERMISSIONS.READ
)

export const hasCreateAccess = checkPermissionSelector(
  OWNERSHIP_PERMISSION,
  NECCESSARY_PERMISSIONS.CREATE
)

export const hasFullAccess = checkPermissionSelector(
  OWNERSHIP_PERMISSION,
  NECCESSARY_PERMISSIONS.all
)

const isOwnershipTransferHidden = isPageHidden('carOwnershipTransfer')

export const isOwnershipListHidden = createSelector(
  [isOwnershipTransferHidden, canAccessPage],
  (isHidden, hasAccess) => isHidden || !hasAccess
)

export const getParsedFilterForQuery = createSelector(
  getFilters,
  transformTicketFilters
)

export const getFetchDataParams = createSelector(
  [(state, props) => props, getCountryCode, getLimit, getFilters, getSort],
  ({payload}, countryCode, defaultLimit, filters, sort) => {
    const limit = payload && payload.limit ? payload.limit : defaultLimit
    const page = payload && payload.page ? payload.page : DEFAULT_PAGE

    return {
      limit,
      page,
      filters,
      sort,
      countryCode: new gql.EnumType(countryCode),
      type: TICKET_TYPES_ENUM.OWNERSHIP
    }
  }
)

const ticketDocuments = (state) => selectorPath(state).documents

export const attachingDocuments = (state) => ticketDocuments(state).isUploading
export const documentsAttached = (state) => ticketDocuments(state).uploaded
export const documentsAttachError = (state) => ticketDocuments(state).error
