import RightPanelDocuments from '../../../../pages/Driver/RightPanelDocuments'

export default {
  stepNum: 4,
  stepTitle: 'driver.documents',
  description: 'driver.panel.description.documents',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: RightPanelDocuments,
            fullWidth: true
          }
        ]
      }
    ]
  }
}
