import {carDataFields} from './carDataFields'
import CarDataList from '../../../pages/CarData/List'
import {carDataFormConfig} from './carDataFormConfig'

export const carDataListConfig = {
  path: 'list',
  label: 'menu.carData.list',
  key: 'carDataList',
  sort: [],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...carDataFields},
  ...carDataFormConfig,
  hiddenFields: [],
  component: CarDataList
}
