import {Component} from 'react'

import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'
import {Grid} from '@material-ui/core'

export class Edit extends Component {
  static displayName = 'Cardata Edit'

  render() {
    const {
      data,
      t,
      handleTextInput,
      handleNumberInput,
      handleFloatInput,
      options
    } = this.props

    if (!data) return null

    return (
      <Grid container>
        <ConfigContext.Consumer>
          {(config) => (
            <FormBuilder
              data={data}
              options={options}
              form={this.props.config.form}
              onChangeHandlers={{
                handleTextInput,
                handleNumberInput,
                handleFloatInput
              }}
              config={config}
              t={t}
            />
          )}
        </ConfigContext.Consumer>
      </Grid>
    )
  }
}
export default Edit
