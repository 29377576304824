const resourceName = 'businessPartners'

export const selectB2BBusinessPartner = `${resourceName}/SELECT_B2B_BUSINESS_PARTNER`
export const fetchBusinessPartnerList = `${resourceName}/FETCH_BUSINESS_PARTNER_LIST`
export const fetchBusinessPartnerListSuccess = `${resourceName}/FETCH_BUSINESS_PARTNER_LIST_SUCCESS`
export const fetchBusinessPartnerListError = `${resourceName}/FETCH_BUSINESS_PARTNER_LIST_ERROR`
export const updateBusinessPartner = `${resourceName}/UPDATE_BUSINESS_PARTNER`

export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
export const toggleField = `${resourceName}/TOGGLE_FIELD`

export const deleteBusinessPartner = `${resourceName}/DELETE_BUSINESS_PARTNER`
export const deleteBusinessPartnerSuccess = `${resourceName}/DELETE_BUSINESS_PARTNER_SUCCESS`
export const deleteBusinessPartnerError = `${resourceName}/DELETE_BUSINESS_PARTNER_ERROR`
