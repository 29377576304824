import TransitJobsList from '../../../pages/TransitJobs/List'
import {transitJobsField, transitJobsCarSubtableFields} from './transitJobField'
import {transitJobFormConfig} from './transitJobFormConfig'

export default {
  exact: true,
  path: 'transit-job',
  label: 'menu.transitJob',
  key: 'transitJobs',
  limit: 25,
  filters: [],
  permissions: {
    entity: 'web.admin.ui.car.transitJobs',
    types: ['READ']
  },
  page: 1,
  fields: Object.values(transitJobsField),
  carSubtableFields: transitJobsCarSubtableFields,
  hiddenFields: [],
  component: TransitJobsList,
  ...transitJobFormConfig
}
