import {Component} from 'react'
import {translate} from 'react-i18next'

import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import QuickFilters from './QuickFilters'
import FilterCreator from './FilterCreator/FilterCreator'
import FilterList from './FilterList'
import noop from 'lodash/noop'
import DeleteDialog from '../../../pages/CRM/common/DeleteDialog'

const styles = (theme) => ({
  bar: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing.unit * 2
  },
  hidden: {
    visibility: 'hidden',
    height: 0
  },
  list: {
    flex: '0 0 60%'
  },
  actions: {
    flex: '0 0 20%',
    alignSelf: 'flex-end'
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  labelInner: {
    marginRight: theme.spacing.unit
  }
})
const PRESET_ACTIVATED = true

export class FilterBar extends Component {
  static defaultProps = {
    onFilterChange: () => {}
  }

  state = {filters: [], activeFilter: null, activePreset: null, deletion: null}

  componentDidMount() {
    if (typeof this.props.filters !== 'undefined') {
      this.setState({filters: this.props.filters})
    }
  }

  activateFilter = (activeFilter, next = noop) => {
    this.setState({activeFilter}, next)
  }

  deactivateFilter = () => {
    this.setState({activeFilter: null})
  }

  activatePreset = (preset) => {
    preset.filters = Array.isArray(preset.filters)
      ? preset.filters.map((filter) => {
          if (filter.type === 'date') {
            const convertedDates = {}
            Object.keys(filter.value).map((key) => {
              if (typeof filter.value[key].format === 'function') {
                convertedDates[key] = filter.value[key].utc().format()
              } else {
                convertedDates[key] = filter.value[key]
              }
            })
            filter.value = convertedDates
          }

          return filter
        })
      : []
    this.setState({activePreset: preset.id, filters: []}, () =>
      this.addFilter(preset.filters, preset.sort, PRESET_ACTIVATED)
    )
  }

  addFilter = (filter, sort = null, presetActivated = false) => {
    const bundle = Array.isArray(filter) ? filter : [filter]

    this.applyFilter(bundle, () => {
      if (typeof sort !== 'undefined' && sort !== null) {
        this.props.onStateChange({filters: this.state.filters, sort})
      } else if (presetActivated && this.props.onPresetSelect) {
        this.props.onPresetSelect({filters: this.state.filters})
      } else {
        this.props.onFilterChange({filters: this.state.filters})
      }
    })
  }

  applyFilter = (bundle, next) => {
    const filters = [...this.state.filters]
    bundle.map((filter) => {
      filter.id =
        typeof filter.id === 'undefined'
          ? `${filter.key}_${filter.value}`
          : filter.id
      const filterIndex = filters.findIndex((item) => item.id === filter.id)

      if (filterIndex === -1) {
        filters.push(filter)
      } else {
        filters.splice(filterIndex, 1, filter)
      }
    })

    this.setState({filters, activeFilter: null}, next)
  }

  removeFilter = (filter) => {
    const filters = [...this.state.filters]
    const filterIndex = filters.findIndex((item) => item.id === filter.id)

    if (filterIndex > -1) {
      filters.splice(filterIndex, 1)
    }

    this.setState({filters, activeFilter: null, activePreset: null}, () =>
      this.props.onFilterChange({filters: this.state.filters})
    )
  }

  openDeleteDialog = (deletion) => {
    this.setState({deletion})
  }

  closeDeleteDialog = () => {
    this.setState({deletion: null})
  }

  render() {
    const {classes, show} = this.props

    return (
      <div className={show ? classes.bar : classes.hidden}>
        <div className={classes.label}>
          <QuickFilters
            shortTitle={this.props.shortTitle}
            presets={this.props.presets}
            saved={this.props.savedStates}
            active={this.state.activePresets}
            activate={this.activatePreset}
            onSavedFilterRemove={this.openDeleteDialog}
          />
        </div>
        <FilterList
          filters={this.state.filters}
          onSelect={this.activateFilter}
          onRemove={this.removeFilter}
        >
          <FilterCreator
            addFilter={this.addFilter}
            editFilter={this.editFilter}
            onSelect={this.activateFilter}
            selected={this.state.filters}
            onClose={this.deactivateFilter}
            options={this.props.fields}
            active={this.state.activeFilter}
          />
          <DeleteDialog
            open={this.state.deletion !== null}
            handleClose={this.closeDeleteDialog}
            label={this.state.deletion && this.state.deletion.name}
            handleSubmit={() => {
              this.props.onSavedFilterRemove(this.state.deletion)
              this.closeDeleteDialog()
            }}
          />
        </FilterList>
      </div>
    )
  }
}

FilterBar.propTypes = {
  filters: PropTypes.array,
  presets: PropTypes.array,
  savedStates: PropTypes.array,
  fields: PropTypes.array,
  classes: PropTypes.object,
  show: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onStateChange: PropTypes.func,
  onSavedFilterRemove: PropTypes.func,
  onPresetSelect: PropTypes.func
}

export default translate()(withStyles(styles)(FilterBar))
