import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const cityhead = merge(
  {
    homepage: '/wms',
    menuItems: {
      hiddenFields: [
        'inspection',
        'crm',
        'dealers',
        'remove',
        'documentConfig',
        'carData',
        'payslips',
        'carOwnershipTransfer',
        'crmusergroups',
        'leadAllocation',
        'transitJobs',
        'locationManager',
        'resourceManager',
        'category',
        'attendanceManager',
        'documentmanagement',
        'ddayBookings',
        'tms'
      ]
    },
    permissions: {},
    fieldFilters: {
      contact: []
    }
  },
  defaultConfig
)
