import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'

const Edit = ({
  data,
  metaInfo,
  updateField,
  updateMetaField,
  form,
  t,
  options,
  ...props
}) => (
  <ConfigContext.Consumer>
    {(config) => (
      <FormBuilder
        t={t}
        users={props.users}
        setDealerOptions={props.setDealerOptions}
        setOnboardingAgentOptions={props.setOnboardingAgentOptions}
        setDealerFarmerOptions={props.setDealerFarmerOptions}
        setDealerHunterOptions={props.setDealerHunterOptions}
        setDealerBidManagerOptions={props.setDealerBidManagerOptions}
        onboardingAgents={props.onboardingAgents}
        dealerFarmers={props.dealerFarmers}
        dealerHunters={props.dealerHunters}
        dealerBidManagers={props.dealerBidManagers}
        data={{
          ...metaInfo,
          ...data,
          depositBalance: metaInfo.depositBalance || 0,
          numberPurchasableCar: metaInfo.numberPurchasableCar || 0,
          metaInfoEmail: metaInfo.email,
          metaInfoInternalId: metaInfo.internalId
        }}
        options={options}
        form={form}
        disabledPrefixFields={props.formConfig.disabledPrefixFields}
        onChangeHandlers={{
          updateField,
          updateMetaField
        }}
        childProps={{
          password: {required: !data.id},
          confirmPassword: {required: !data.id}
        }}
        config={config}
        readOnlyPath={props.readOnlyPath}
      />
    )}
  </ConfigContext.Consumer>
)

export default Edit
