const resourceName = 'crmUserGroups'

const groups = `${resourceName}/GROUPS`
const users = `${resourceName}/USERS`
const permissions = `${resourceName}/PERMISSIONS`
const rolePermissions = `${resourceName}/rolePermissions`

export const groups_fetching = `${groups}/FETCHING`
export const groups_loaded = `${groups}/LOADED`
export const groups_setSelected = `${groups}/SET_SELECTED`
export const groups_resetSelected = `${groups}/RESET_SELECTED`
export const groups_deleting = `${groups}/GROUP_DELETING`
export const groups_delete_success = `${groups}/GROUP_DELETE_SUCCESS`
export const groups_delete_failure = `${groups}/GROUP_DELETE_FAILURE`
export const groups_addNew = `${groups}/ADD_NEW`
export const users_fetching = `${users}/FETCHING`
export const users_loaded = `${users}/LOADED`
export const users_adding = `${users}/USER_ADDING_TO_GROUP`
export const permissions_fetching = `${permissions}/FETCHING`
export const permissions_loaded = `${permissions}/LOADED`
export const rolePermissions_fetching = `${rolePermissions}/FETCHING`
export const rolePermissions_loaded = `${rolePermissions}/LOADED`
export const rolePermissions_reset = `${rolePermissions}/RESET`
