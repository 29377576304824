import {RowOptions} from '@fcg/tars'

const AppointmentCount = (props) => (
  <RowOptions
    classes={props.classes}
    selected={props.selected}
    selectable={props.selectable}
    subTableData={props.subTableData}
    subTable={props.subTableData.length > 0}
    expand={props.shouldExpand}
    subTableDataAmount={props.subTableData.length}
    openSubTable={props.openSubTable}
    onSelect={props.onSelect}
    onOpenSubTable={props.onOpenSubTable}
    rowIndex={props.rowIndex}
    unselectableAligned={props.unselectableAligned}
  />
)

export default AppointmentCount
