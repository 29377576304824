import {
  RegexField,
  ValidatedTextField,
  ValidatedTextFiledWithCounter
} from '../../../components/FormValidation'

// eslint-disable-next-line no-useless-escape
export const regexUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/

export const webhookFormConfig = {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 4,
            children: [
              {
                component: ValidatedTextField,
                fullWidth: true,
                id: 'name',
                label: 'webhook.column.name',
                margin: 'normal',
                required: true,
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 8,
            children: [
              {
                component: RegexField,
                fullWidth: true,
                id: 'url',
                regex: regexUrl,
                required: true,
                label: 'webhook.column.url',
                margin: 'normal',
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 8,
            children: [
              {
                component: ValidatedTextField,
                fullWidth: true,
                id: 'header',
                label: 'webhook.column.header',
                margin: 'normal',
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 4,
            children: [
              {
                component: ValidatedTextFiledWithCounter,
                fullWidth: true,
                id: 'description',
                required: true,
                inputProps: {
                  maxLength: 250
                },
                label: 'webhook.column.description',
                margin: 'normal',
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      }
    ]
  }
}
