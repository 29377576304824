import PropTypes from 'prop-types'

export const Day = (props) => {
  const baseClass = 'calendar__day'
  const {isSelected, isInRange, isOutOfScope, value, handleDayClick} = props
  const selectedClass = isSelected === true ? `${baseClass}--selected` : ''
  const rangeClass = isInRange === true ? `${baseClass}--active` : ''
  const outOfScopeClass = isOutOfScope === true ? `${baseClass}--muted` : ''
  const className = [
    baseClass,
    selectedClass,
    outOfScopeClass,
    rangeClass
  ].join(' ')
  const fullDate = value.format('dddd, Do MMMM YYYY')
  const clickHandler = (e) => {
    e.stopPropagation()
    handleDayClick(value)
  }

  return isInRange || isSelected ? (
    <strong className={className} onClick={clickHandler} title={fullDate}>
      <b>{value.format('D')}</b>
    </strong>
  ) : (
    <span className={className} onClick={clickHandler} title={fullDate}>
      <b>{value.format('D')}</b>
    </span>
  )
}

Day.defaultProps = {
  format: 'DD',
  isSelected: false,
  isOutOfScope: false,
  handleDayClick: () => {}
}

Day.propTypes = {
  value: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isInRange: PropTypes.bool,
  isOutOfScope: PropTypes.bool,
  handleDayClick: PropTypes.func
}

export default Day
