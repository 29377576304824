import * as types from './types'
import {getInitialState} from './initialState'
import {contactSchema} from '../detail/initialState'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'

export default {
  ...tableDecorators.generateReducer(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: contactSchema
  })
}
