import * as types from './types'

export const fetchCarDataFilesList = (payload) => ({
  type: types.fetchCarDataFilesList,
  payload
})

export const fetchCarDataFilesListSuccess = (payload) => ({
  type: types.fetchCarDataFilesListSuccess,
  payload
})

export const fetchCarDataFilesListError = (error) => ({
  type: types.fetchCarDataFilesListError,
  payload: error
})

export const updateCarDataFilesField = (payload) => ({
  type: types.updateCardDataFilesField,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})
