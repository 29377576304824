import * as types from './types'
import * as tableDecorators from '../../utils/tableDecorators'

const reducer = {
  [types.contactDetailLoaded]: (state, action) => {
    return {
      ...state,
      detail: {
        ...state.detail,
        fetching: false,
        ...action.payload
      }
    }
  },
  [types.contactDetailFetching]: (state) => {
    return {
      ...state,
      detail: {
        ...state.detail,
        fetching: true
      }
    }
  },
  [types.contactDataNotFound]: (state, action) => {
    return {
      ...state,
      error: action.payload
    }
  },
  [types.logsLoaded]: (state, action) => {
    return {
      ...state,
      fetchingLogs: false
    }
  },
  [types.logsFetching]: (state) => {
    return {
      ...state,
      fetchingLogs: true
    }
  },
  [types.logsDataNotFound]: (state, action) => {
    return {
      ...state,
      logsError: action.payload
    }
  }
}

export default {
  ...reducer,
  ...tableDecorators.withDataLoading(types.task, 'task'),
  ...tableDecorators.withSingleSort(types.task, 'task'),
  ...tableDecorators.withDataLoading(types.lead, 'lead'),
  ...tableDecorators.withSingleSort(types.lead, 'lead'),
  ...tableDecorators.withDataLoading(types.comment, 'comment'),
  ...tableDecorators.withSingleSort(types.comment, 'comment'),
  ...tableDecorators.withDataLoading(types.changes, 'changes'),
  ...tableDecorators.withSingleSort(types.changes, 'changes')
}
