import keys from 'lodash/keys'

export const fieldEquality = (field) => (config) =>
  config === field || config.field === field
export const getFieldConfig = (config, field) =>
  config.find(fieldEquality(field))
export const containsField = (config, field) =>
  config.some(fieldEquality(field))
export const formIncludesDependencies = (formFields) => (field, dependencies) =>
  dependencies.some(
    (dependency) =>
      !fieldEquality(field)(dependency) && formFields.includes(dependency)
  )
export const getFieldDependencyObject = (formFields) =>
  keys(formFields).reduce((acc, field) => ({...acc, [field]: false}), {})
