import * as fields from './fields'
import gql from '@fcg/lib-gql/gql'
import {
  convertCarLeadInterceptor,
  formatSort,
  buildContactSubquery,
  convertCarLeadCreate,
  formatAppointmentService
} from './helpers'
import {ApiClient} from '@fcg/admin-api-client'
import {
  transformEmptyValues,
  fetchAdditionalData,
  fetchAdditonalDataForCountry,
  formatCountry
} from '../common/helpers'

const client = new ApiClient('contact', [convertCarLeadInterceptor], fields)

const calendarClient = new ApiClient('calendar', [], fields)

// Comments [tasks, regular comments, comment history]

export function getCommentList(params = {}) {
  if (params.commentData && params.commentData.status) {
    const status = [].concat(params.commentData.status)

    params.commentData.status = status.map(
      (status) => new gql.EnumType(status.toUpperCase())
    )
  }

  return client
    .withTransform(formatSort)
    .query('comment', params, 'taskDetailList')
}

export function getComment(params = {}) {
  if (params.status) {
    params.status = params.status.map(
      (status) => new gql.EnumType(status.toUpperCase())
    )
  }

  return client.query('comment', params, 'commentFields')
}

export function createComment(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('bulkCreateComment', params, 'created')
}

export function updateComment(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('updateComment', params, 'commentFields')
}

export function deleteComment(params = {}) {
  return client.query('deleteComment', params, 'deleted')
}

// Changes
export function getChangesList(params = {}) {
  return client
    .withTransform(formatSort)
    .query('history', params, 'changesList')
}

// Contacts
export function getContactList(params = {}, queryStr, flag) {
  let query = 'contactList'
  if (flag === 'contactListPage') {
    query = queryStr
  }
  return client
    .withTransform(formatSort)
    .withIncreasedTimeout()
    .query('contact', params, query)
}

export function getContactDetail({id, subfields, ...params}) {
  const options =
    typeof subfields !== 'undefined'
      ? Object.keys(subfields).reduce((acc, subfield) => {
          return {
            ...acc,
            ...buildContactSubquery({
              contactId: id,
              subfield,
              args: subfields[subfield]
            })
          }
        }, {})
      : {}

  return client.alias({
    contact: {
      endpoint: 'contact',
      query: {...params, limit: 1},
      structure: fields.contactDetail
    },
    ...options
  })
}

export function createContact(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('createContact', params, 'contactFields')
}

export function updateContact(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('updateContact', params, 'contactFields')
}

export function deleteContact(ids = []) {
  return client.query('deleteContact', {ids}, 'deleted')
}

// Walkin endpoint - carLeadCallback, in ContactService
export function createWalkin(params = {}) {
  return client
    .withTransform([transformEmptyValues, formatAppointmentService])
    .query('createCarLeadCallback', params, 'carLeadCallback')
}

// Leads [carLeads, in Contact service terms]
export function getLeadList(params = {}, flag) {
  let query = 'leadList'
  if(flag === 'leadListPage')
  {
    query = 'finalLeadListFields'
  }
  return client
    .withTransform(formatSort)
    .withIncreasedTimeout()
    .query('carLeadCallback', params, query)
}

export function createLead(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('createCarLead', convertCarLeadCreate(params), 'leadFields')
}

export function updateLead(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .query('updateCarLeadCallback', params, 'leadFields')
}

export function deleteLead(ids = []) {
  return client.query('deleteCarLeadCallback', {ids}, 'deleted')
}

export function getLeadOptionList(params = {}) {
  return client.query('carLeadOption', params, 'leadOptions')
}

// Appointments
export function getAppointmentList(params = {}) {
  return client
    .withTransform(formatSort)
    .query('appointment', params, 'appointmentList')
}

export function getCalendarData(params = {}) {
  return calendarClient.query('appointment', params, 'appointmentList')
}

export function createAppointment(params = {}) {
  return client
    .withTransform([transformEmptyValues, formatAppointmentService])
    .query('createAppointment', params, 'appointmentFields')
}

export function updateAppointment(params = {}) {
  return client
    .withTransform([transformEmptyValues])
    .query('updateAppointment', params, 'appointmentFields')
}

export function cancelAppointment(params = {}) {
  return client.query('cancelAppointment', params)
}

export function deleteAppointment(ids = []) {
  return client.query('deleteAppointment', {ids}, 'deleted')
}

// Tier
export const getTiers = (params) => {
  return client
    .withTransform(formatCountry)
    .withAdditionalRequestConfig(fetchAdditionalData)
    .withAdditionalRequestConfig(fetchAdditonalDataForCountry(params.country))
    .query('tier', params, fields.tierList)
}

export const getTierCriteria = (params = {}) =>
  client.query('tierCriteria', params, fields.tierCriteriaList)

export const createTier = (params = {}) =>
  client.mutation('createTier', params, fields.tier)

export const createTierCriteria = (params = {}) =>
  client.mutation('createTierCriteria', params, fields.tierCriteria)

export const updateTier = (params = {}) =>
  client.mutation('updateTier', params, fields.tier)

export const updateTierCriteria = (params = {}) =>
  client.mutation('updateTierCriteria', params, fields.tierCriteria)

export const deleteTier = (params = {}) =>
  client.mutation('deleteTier', params, null)

export const deleteTierCriteria = (params = {}) =>
  client.mutation('deleteTierCriteria', params, null)

export const getParticipant = (params = {}) =>
  client.query('appointment', params, [
    [
      'list',
      [
        'id',
        [
          'participants',
          ['id', 'firstname', 'lastname', 'role', 'email', 'phone']
        ]
      ]
    ]
  ])

export const updateParticipant = (params = {}) =>
  client.query('updateAppointment', params, [
    'id',
    ['participants', ['id', 'firstname', 'lastname', 'role', 'email', 'phone']]
  ])

export const addParticipant = (params = {}) =>
  client.mutation('addParticipant', params, ['id'])

export const removeParticipant = (params = {}) =>
  client.mutation('removeParticipant', params, null)
