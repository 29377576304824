import moment from 'moment'

import Location from '../types/Location'

import GlobalPlaceConfiguration from '../types/GlobalPlaceConfiguration'

export const getCities = (locationList: Location[]) => {
  return locationList
    .filter((location: Location) => location.city)
    .reduce((cities: string[], location: Location) => {
      cities =
        cities.indexOf(location.city) < 0 ? [...cities, location.city] : cities

      return cities
    }, [])
}

export const getRegions = (locationList: Location[]) => {
  return locationList
    .filter((location: Location) => location.region)
    .reduce((regions: string[], location: Location) => {
      regions =
        regions.indexOf(location.region) < 0
          ? [...regions, location.region]
          : regions

      return regions
    }, [])
}

export const returnHolidayDateString = (date1: moment, date2: moment) => {
  const differenceInDays = date2.diff(date1, 'days')

  return differenceInDays > 0
    ? `${moment(date1).format('dddd, MMMM D')} - ${moment(date2).format(
        'dddd, MMMM D'
      )}`
    : `${moment(date1).format('dddd, MMMM D')}`
}

/**
 * converts JsonDate to Date object
 * @param dateStr
 */
export const jsonDateToDate = (dateStr: string) => {
  return moment(dateStr.substr(0, 10))
}

export async function loadScript(url: string, id?: string) {
  await new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script')
    scriptElement.type = 'text/javascript'
    scriptElement.src = url
    scriptElement.async = true
    id && scriptElement.setAttribute('id', id)
    scriptElement.onerror = reject
    scriptElement.onload = resolve
    document.head.appendChild(scriptElement)
  })
}

export const scrollElementToTop = (id: string) => {
  const el = document.getElementById(id)
  if (el) el.scrollTop = 0
}

export const scrollElementIntoView = (id: string) => {
  const el = document.getElementById(id)
  if (el) el.scrollIntoView()
}

export const convertToLowercaseWithInitialCapital = (string: string) => {
  return string.charAt(0) + string.slice(1).toLowerCase()
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const getTitle = (location: Location | GlobalPlaceConfiguration) => {
  if (location.id === 'master') return 'Master template'
  if (location.id === 'newLocation') return 'Add new location'

  return location.location
}
