import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export const sizes = {
  s: '40px',
  xl: '104px',
  lg: '150px',
  auto: 'auto'
}

export const FlexCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 104px;
  max-width: 104px;
  max-height: auto;
  min-height: 48px;
`

export const InlineCell = styled.div`
  display: inline-block;
  min-width: 104px;
  max-width: 104px;
  max-height: auto;
  min-height: 48px;
  cursor: pointer;
`

export const Loader = styled(CircularProgress)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
`

export const colors = {
  white: '#FFFFFF',
  lightGray: '#F2F2F2',
  cellBorder: '#D9D9D9',
  defaultCardColor: '#6082E8',
  borderColor: '#B0B0B0',
  headerBackground: '#F9F9F9',
  headerTextColor: '#3C4043',
  backgroundColorDefault: '#FAFAFA',
  overlayColor: 'rgba(128, 128, 128, 0.3)',
  tooltipBackground: 'rgba(97, 97, 97, 0.8)'
}
