import React, {useState} from 'react'
import EventIcon from '@material-ui/icons/Event'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import SectionWrapper from '../../../components/Sections/SectionWrapper'
import DialogTemplate from '../../../components/Dialog/Dialog'
import HolidayRow from './HolidayRow'
import HolidayPicker from './HolidayPicker/HolidayPicker'
import NoData from '../../../components/NoData/NoData'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import useHolidayActions from './useHolidayActions'
import HolidayObject from '../../../types/HolidayObject'

const sortHolidaysByDate = (a: HolidayObject, b: HolidayObject) =>
  a.from === b.from ? 1 : a.from < b.from ? -1 : 1

const Holidays = ({isMaster}: {isMaster: boolean}) => {
  const classes = useCommonStyles()
  const {holidays, deleteHoliday, addOrUpdateHoliday} = useHolidayActions(
    isMaster
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )
  const [showPicker, setShowPicker] = useState<boolean>(false)

  const [selectedHoliday, setSelectedHoliday] = useState<
    HolidayObject | undefined
  >(undefined)

  const selectRow = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedHoliday(getHoliday(id))
    setShowPicker(true)
  }

  const getHoliday = (id: string): HolidayObject => {
    return holidays.find((holiday: HolidayObject) => holiday.id === id)!
  }

  const showDeleteConfirmationDialog = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedHoliday(getHoliday(id))
    setShowDeleteConfirmation(true)
  }

  const onDelete = () => {
    deleteHoliday(selectedHoliday!.id!, deleteCallback)
  }

  const deleteCallback = () => {
    setShowDeleteConfirmation(false)
    closeHolidayPicker()
  }

  const onAddOrUpdate = (newHoliday: HolidayObject) => {
    addOrUpdateHoliday(newHoliday, closeHolidayPicker)
  }

  const closeHolidayPicker = () => {
    setSelectedHoliday(undefined)
    setShowPicker(false)
  }
  const subtitle = isMaster
    ? 'Define the exceptions and holidays for all locations. Holidays will automatically sync.'
    : 'Add exceptions and holidays to close your location. Enable the exceptions and holidays to open.'

  return (
    <SectionWrapper
      title='Exceptions and holidays 2020'
      subtitle={subtitle}
      fullWidth
    >
      <Grid container>
        {holidays?.length === 0 ? (
          <NoData
            buttonText='ADD HOLIDAY'
            onClick={() => {
              setShowPicker(true)
            }}
          />
        ) : (
          holidays.sort(sortHolidaysByDate).map((holiday) => {
            return (
              <HolidayRow
                isMaster={isMaster}
                key={holiday.id}
                {...holiday}
                onDelete={showDeleteConfirmationDialog}
                onSelect={selectRow}
              />
            )
          })
        )}
      </Grid>
      <Button
        variant='outlined'
        color='primary'
        className={classes.absoluteRightTop}
        startIcon={<EventIcon />}
        onClick={() => {
          setShowPicker(true)
        }}
        data-test='btn-add-holiday'
      >
        ADD HOLIDAY
      </Button>

      {showPicker && (
        <HolidayPicker
          isMaster={isMaster}
          open={showPicker}
          onCancel={closeHolidayPicker}
          selectedHoliday={selectedHoliday}
          onConfirm={onAddOrUpdate}
          onDelete={showDeleteConfirmationDialog}
        />
      )}
      {showDeleteConfirmation && (
        <DialogTemplate
          open={showDeleteConfirmation}
          title={`Are you sure you want to delete ${selectedHoliday!.title}`}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel='YES, DELETE'
          dataTest='delete-holiday-confirm'
          onConfirm={onDelete}
        ></DialogTemplate>
      )}
    </SectionWrapper>
  )
}

export default Holidays
