import XLSX from 'xlsx'

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsBinaryString(file)
  })
}

export const convertFileDataToArray = async (file, columns) => {
  const reader = new FileReader()
  const readAsBinaryString = !!reader.readAsBinaryString
  const binaryStr = await readFileAsync(file)
  const parsedData = XLSX.read(binaryStr, {
    type: readAsBinaryString ? 'binary' : 'array',
    bookVBA: true
  })
  const webSheetName = parsedData.SheetNames[0]
  const webSheet = parsedData.Sheets[webSheetName]
  const data = XLSX.utils.sheet_to_json(webSheet, {header: columns})

  return data
}
