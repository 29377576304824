import {Fragment} from 'react'
import {Popover, withStyles} from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  paper: {
    minWidth: '180px',
    marginTop: '6px'
  }
})

export const FilterDropdown = ({Anchor, ...props}) => {
  const position = props.anchorEl
    ? props.anchorEl.getBoundingClientRect().right > window.innerWidth * 0.75
      ? 'right'
      : 'left'
    : 'left'

  return (
    <Fragment>
      <Anchor />
      <Popover
        open={props.isOpen}
        classes={props.classes}
        onClose={props.close}
        anchorEl={props.anchorEl}
        anchorReference='anchorEl'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position
        }}
      >
        {props.children}
      </Popover>
    </Fragment>
  )
}

export default withStyles(styles)(FilterDropdown)
