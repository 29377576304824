const prefix = 'calendar'

export const initialize = `${prefix}/INITIALIZE`
export const completeRangeChange = `${prefix}/COMPLETE_RANGE_CHANGE`
export const fullRangeChange = `${prefix}/FULL_RANGE_CHANGE`
export const cancelRangeChange = `${prefix}/CANCEL_RANGE_CHANGE`
export const startRangeChange = `${prefix}/START_RANGE_CHANGE`
export const applyRangeChange = `${prefix}/APPLY_RANGE_CHANGE`
export const applyRangePreset = `${prefix}/APPLY_RANGE_PRESET`
export const changeVisibleMonths = `${prefix}/CHANGE_VISIBLE_MONTHS`
export const selectDay = `${prefix}/SELECT_DAY`
export const reset = `${prefix}/RESET`
export const destroy = `${prefix}/DESTROY`
export const toggleOpen = `${prefix}/TOGGLE_OPEN`
