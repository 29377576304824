import styled from 'styled-components'
import {translate} from 'react-i18next'
import {STATUS_TYPE, hasUpdatePermission} from './constants'

const colorBasedOnStatus = {
  pending: 'color: gray; background: #eee;',
  scheduled: 'color: #e67c00; background: #ffeabf;',
  finished: 'color: #74994f; background: #e7ffda;',
  running: 'color: #4a90e2; background: #d5e8ff;',
  aborted: 'color: #c81f34; background: #ffe4e7;'
}

const cursorStyle = (status) => {
  return hasUpdatePermission(
    [
      STATUS_TYPE.PENDING,
      STATUS_TYPE.SCHEDULED,
      STATUS_TYPE.RUNNING,
      STATUS_TYPE.REJECTED
    ],
    status
  )
    ? 'pointer'
    : 'default'
}

const Status = styled.div`
  color: #000;
  background-color: red;
  cursor: ${({status}) => cursorStyle(status)}
  border-radius: 3px;
  width: 80px;
  text-align: center;
  padding: 6px 5px;
  ${({status}) => colorBasedOnStatus[status]};
  ${({height}) => height && `height: ${height}`};
`

const StatusTransitJobs = (props) => {
  const status = props.data || 'pending'

  return (
    <Status
      status={status}
      onClick={() => {
        if (props.onClickHandler) {
          props.onClickHandler()
        }

        return
      }}
      height={props.height}
    >
      {props.t(`transitJobs.field.status.${status}`)}
    </Status>
  )
}

export default translate()(StatusTransitJobs)
