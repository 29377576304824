export function filterTaxInfoCountryOptions(
  name,
  bpType,
  options,
  bpBasedTaxInfoConditions
) {
  const filteredOptions = []

  const bpFormFieldsType =
    bpType === 'individual'
      ? 'individualBpFormFields'
      : 'nonIndividualBpFormFields'

  if (
    options.length > 0 &&
    bpBasedTaxInfoConditions &&
    Object.keys(bpBasedTaxInfoConditions).length > 0
  )
    options?.forEach((option) => {
      bpBasedTaxInfoConditions[bpFormFieldsType][name].forEach((field) => {
        if (option.value === field) {
          filteredOptions.push(field)
        }
      })
    })

  return filteredOptions
}
