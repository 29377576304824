import * as types from './types'
import {getInitialState, driverSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  [types.fetchDriversList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchDriversListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    driverList: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchDriversListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.setDriverImage]: (state, action) => ({
    ...state,
    driverImage: action.payload
  }),
  [types.fetchDriverOptionsSuccess]: (state, action) => ({
    ...state,
    driverOptions: action.payload
  }),
  [types.resetDriver]: (state) => ({
    ...state,
    driver: {
      ...driverSchema
    },
    driverImage: null,
    editState: {error: false, success: false, pending: false},
    visitedPage: []
  }),
  [types.driverSet]: (state, action) => {
    const driver = action.payload.user.list[0]

    return {
      ...state,
      driver: {password: '', confirmPassword: '', ...driver}
    }
  }
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: driverSchema
  })
}
