import Weekday from '../../../types/Weekday'
import {
  weekdayShortString,
  defaultTimeRange,
  DAYS,
  serviceTypes,
  serviceConfigurationTemplate
} from '../../../config/constants'
import OpeningTime from '../../../types/OpeningTime'
import ServiceType from '../../../types/ServiceType'
import OpeningHours from '../../../types/OpeningHours'
import ServiceConfiguration from '../../../types/ServiceConfiguration'
import ServicesConfigurationObject from '../../../types/ServicesConfigurationObject'

export const convertMinToHours = (duration: number) => {
  const totalMinutes = duration
  const minutes = totalMinutes % 60

  return {
    hours: (totalMinutes - minutes) / 60,
    minutes: minutes
  }
}

/**
 * Corresponds to OpeningTime from GraphQL
 */

const createTimeRangeString = (dayTimeRanges: OpeningTime[]) => {
  if (dayTimeRanges[0].closed) return 'Closed'

  return dayTimeRanges.reduce((dayString, range) => {
    dayString =
      dayString +
      `${dayString === '' ? '' : '/'}${range.openingTime.substring(
        0,
        5
      )}-${range.closingTime.substring(0, 5)}`

    return dayString
  }, '')
}

/**
 * Check whether the array contains days without gaps
 * @param array
 */
const isArrayConsecutive = (array: any[]): boolean => {
  let isConsecutive = true
  array.map((el, i) => {
    if (i !== 0 && isConsecutive) {
      if (parseInt(array[i - 1]) + 1 !== parseInt(el)) {
        isConsecutive = false
      }
    }
  })

  return isConsecutive
}

const createDayString = (array: Weekday[]): string => {
  if (array.length > 2 && isArrayConsecutive(array)) {
    return `${weekdayShortString[array[0]]}-${
      weekdayShortString[array[array.length - 1]]
    }`
  } else {
    let str = weekdayShortString[array[0]]
    array.map((day, i) => {
      str =
        i === 0 ? weekdayShortString[day] : `${str}, ${weekdayShortString[day]}`
    })

    return str
  }
}

export const formatServiceOpeningTimesString = (
  openingHours: {[day in Weekday]: OpeningTime[]}
) => {
  // console.log("formatServiceOpeningTimeString input", openingHours)
  const timeRangesObject = Object.keys(openingHours).reduce(
    (dayStrings, day) => {
      const dayString = createTimeRangeString(openingHours[day])
      dayStrings[dayString] = dayStrings[dayString]
        ? [...dayStrings[dayString], Number(day) as Weekday]
        : [Number(day) as Weekday]

      return dayStrings
    },
    // timeRange is something like "10:00-18:00"
    {} as {[timeRange: string]: Weekday[]}
  )
  // console.log("formatServiceOpeningTimeString timeRangesObject", timeRangesObject)

  const openingTimesStrings = Object.keys(timeRangesObject).reduce(
    (timeSlots, slots) => {
      timeSlots = [
        ...timeSlots,
        `${createDayString(timeRangesObject[slots])}: ${slots}`
      ]

      return timeSlots
    },
    [] as string[]
  )
  // console.log("formatServiceOpeningTimeString openingTimeStrings", openingTimesStrings)

  return openingTimesStrings.join(', ')
}

export const addDefaultOpeningTimes = () => {
  const serviceTimeRange = {...defaultTimeRange, bookingType: []}

  return DAYS.reduce((days: OpeningHours, day: Weekday) => {
    days[day] =
      day === 7
        ? [
            {
              ...serviceTimeRange,
              closed: true,
              weekday: day
            } as OpeningTime
          ]
        : [{...serviceTimeRange, weekday: day} as OpeningTime]

    return days
  }, {} as OpeningHours)
}

export const createServiceName = (services: ServiceType[]) => {
  return services.reduce((name, service) => {
    return name === ''
      ? serviceTypes[service]
      : `${name} & ${serviceTypes[service]}`
  }, '')
}

export const createDefaultOpeningTime = (
  day: Weekday,
  service: ServiceType[]
): OpeningTime[] => {
  return [
    {
      ...defaultTimeRange,
      bookingType: service,
      weekday: day,
      closed: true
    }
  ]
}

export const returnServiceConfiguration = (
  globalServicesConfiguration: ServicesConfigurationObject,
  type?: ServiceType[]
): ServiceConfiguration => {
  let configuration = type
    ? globalServicesConfiguration[type.sort().join('_')]
    : serviceConfigurationTemplate
  return configuration || {...serviceConfigurationTemplate, type: type!}
}
