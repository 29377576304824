import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { MainContext } from "../../context/MainContext";
import { Grid, Paper } from "@material-ui/core";
import DatePicker from "../../components/DatePicker";
import DownloadButton from "../../components/Buttons/Download";
import DescriptionIcon from '@material-ui/icons/Description';
import RadioInput from '../Inputs/RadioInput'
import CustomSelect from "../Inputs/Select";
import { getcountryFeatures } from "../../config/featureConfig/utils";
import { getAllstores } from '../../api/stores'
import { StoreObject, DownloadType } from "../../types";

const Download = (props: {}) => {
    const {stores, setStores, countryCode}= useContext(MainContext)
    const [downloadType, setdownloadType] = useState<DownloadType>(DownloadType.capacity)
    const [isLoading, setIsLoading]= useState(true)
    const [selectedStoreIds, setSelectedStoreIds] = useState('')
    const radioInputHandler = (value: DownloadType) => {
        setdownloadType(value)
    }

    const showAvailability=getcountryFeatures(countryCode).availabilityDownload

    useEffect(() => {
        if (stores.data.length <=0 && downloadType === 'availability') {
            getAllstores(countryCode, (response) => {
                let activeStores= response.data.filter((store:StoreObject)=> store.active)
                setStores({ status: response.status, data: activeStores })
                setIsLoading(false)
            })
        }
    }, [countryCode, downloadType])

    const handleOnStoreSelect = (storeObjs: Array<StoreObject>) => {
        const storeIds = storeObjs.map(( item )=> item.storeId )
        setSelectedStoreIds(storeIds.join(','))
    }

    return (
        <Grid container item sm={4} spacing={3} data-testid='download-section'>
            <Paper style={{
                     background: 'white', padding: '29px', borderRadius: '8px', boxShadow: ' 0 2px 4px 0 rgba(0, 0, 0, 0.1)'
            }}>
                <Grid item style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px', marginTop: '70px' }} >
                    <DescriptionIcon style={{ width: '66px', height: '71px' , color: "#616161"}} />
                </Grid>
                <Grid item sm={12} style={{width: "100%", marginBottom: "24px",textAlign: 'center'}}>
                    <span style={{ fontSize: '24px',textAlign: 'center', fontWeight: 600, color: '#616161', marginBottom: '25px', marginTop: '37px' }}>Download File</span>
                </Grid>
                {showAvailability && <Grid item sm={12} style={{ width: "100%", marginTop: "16px" }}>
                    <RadioInput value={downloadType} label='Select download type' options={[{ label: 'Capacity', value: 'capacity' }, { label: 'Availability', value: 'availability' }]} onChange={radioInputHandler} />
                </Grid>}
                {downloadType === 'availability' && <Grid item sm={10}>
                    <CustomSelect title="Select Store" options={stores.data} isLoading={isLoading} onChange={handleOnStoreSelect}/>
                </Grid>}
                <Grid item sm={12} >
                    <DatePicker downloadType={downloadType}/>
                </Grid>
                <Grid item sm={10} >
                    <DownloadButton downloadType={downloadType} selectedStoreIds={selectedStoreIds}/>
                </Grid>
            </Paper>
         </Grid>
    )
}

export default Download
