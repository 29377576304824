const selectorPath = (state) => state.detail

export const getAppointmentsFromLeads = (state, selectedLeadId) => {
  let appointments = []
  const detail = selectorPath(state)

  if (!selectedLeadId && detail?.lead?.data) {
    appointments = Object.values(detail.lead.data).reduce((acc, data) => {
      if (data.appointments) {
        return [...acc, ...data.appointments]
      }

      return acc
    }, [])
  } else {
    const lead = Object.values(detail.lead.data).find(
      (item) => item.id === selectedLeadId
    )

    appointments = lead?.appointments
  }

  return appointments
}
