import * as types from './types'
import {getInitialState, webhookSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  [types.fetchWebhookList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchWebhookListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    webhookList: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchWebhookEventsListSuccess]: (state, action) => ({
    ...state,
    events: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchWebhookListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.openCreateDialog]: (state) => ({
    ...state,
    showCreateDialog: true
  }),
  [types.createWebhookSuccess]: (state) => ({
    ...state,
    isLoading: false,
    isFetchingList: true,
    form: {},
    showCreateDialog: false
  }),
  [types.createWebhookError]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  [types.updateWebhookField]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      [action.payload.name]: action.payload.value
    }
  }),
  [types.hideCreateDialog]: (state) => ({
    ...state,
    edit: false,
    showCreateDialog: false,
    form: {}
  }),
  [types.openNonceDialog]: (state, action) => ({
    ...state,
    webhookNonce: action.createWebhook.nonce,
    showNonceDialog: true
  }),
  [types.hideNonceDialog]: (state) => ({
    ...state,
    showNonceDialog: false,
    webhookNonce: null
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: webhookSchema
  })
}
