const dealerDetails = require('./dealerManagement/ng')
const crmDetails = require('./CRM/ng')
const bulkuploadDetails = require('./bulkUpload/ng')
const carDataDetails = require('./carData/ng')
const locationDetails = require('./locationManagement/ng')
const payslipDetails = require('./payslips/ng')
const carOwnership = require('./carOwnership/ng')
const businessPartnersDetails = require('./businessPartnersDetails/ng')

module.exports = {
  url: 'cars45.com',
  name: 'Nigeria',
  countryCode: 'NG',
  theme: 'nigeria',
  language: {
    selected: 'en-ng',
    options: [{value: 'en-ng', label: 'EN'}]
  },
  locales: ['en-ng'],
  currency: {code: 'NGN', symbol: '₦'},
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [8, 10], code: 234},
  referralCodeType: 'input',
  googleKey:
    '303153516756-r1cdbh0jebquohk2np8ru4uqg4dj2lld.apps.googleusercontent.com',
  carDuplicatedCheckField: 'vin',
  auctionPreviewOffset: 7 * 24 * 60,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      vin: {
        required: true,
        regex: '^.{1,17}$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  createCarViaAppointment: true,
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  crmDetails,
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'category',
    'wms',
    'bulkUploadV2',
    'documentManagement',
    'tms',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
