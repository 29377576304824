import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../config/country'

export const childrenReducer = (field, fields, propName) => {
  if (field && fields && fields.includes(field[propName])) {
    return -1
  }

  if (field.type === 'composite' && field.children && field.children.length) {
    field = {
      ...field,
      children: field.children.reduce((reducedChildren, child) => {
        const c = childrenReducer(child, fields, propName)

        if (c === -1) {
          return reducedChildren
        }

        return [...reducedChildren, c]
      }, [])
    }
  }

  return field
}

export const fieldSelectorCreator = (
  pathSelector,
  countryConfigField,
  countryConfigProp,
  fieldConfigField
) =>
  createSelector(pathSelector, (fields) => {
    const config = getCountryConfig()[countryConfigField]

    if (!config[countryConfigProp] && fields) {
      return fields
    }

    if (fields && fields.length) {
      return fields.map((field) =>
        childrenReducer(field, config[countryConfigProp], fieldConfigField)
      )
    }

    return []
  })
