import {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

// Components
import LegendDialog from './components/Legend/LegendDialog'
import AppointmentFilter from './components/AppointmentFilter'
import AppointmentCalendar from './components/AppointmentCalendar'
// Dialogs
import AppointmentDialog from '../CRM/Appointment/Editor'
import CancelDialog from '../CRM/Appointment/Cancel'
// Context
import {AppointmentContextProvider} from './components/AppointmentContext'
// Redux
import {
  toggleVisibilityCancel,
  toggleVisibilityEdit,
  toggleVisibilityLegend,
  toggleVisibilityLegendIcon,
  selectAppointment,
  onSelectedAppointmentLoading,
  initAppointmentCalendar,
  fetchAppointmentSlots,
  clearRefreshInterval
} from '../../store/calendar/actions'
import {
  getSelectedAppointment,
  isCalendarLoading,
  getAppointmentContactId
} from '../../store/calendar/selectors'
import {getAppointmentStatusesWithMeta} from '../../store/crm/selectors'
// Utilities
import {RenderIf} from '../../components/RenderIf'
import {
  onBeforeReloadListener,
  removeReloadEventHandlers,
  warnBeforeReloadInit
} from './components/utils'
import PermissionWrapper from '../../components/Permissions/PermissionWrapper'
import {BlockNavigation} from '../../components/HOC'
import Walkin from '../CRM/Walkin/Editor'
import {updateWalkinForm, resetWalkinForm} from '../../store/crm/walkin/actions'
import {reactToLocationChange} from '../../store/config/actions'
import {selectedDate} from '../../components/Pickers/Calendar/store/selectors'
import {isOldEntity} from '../CRM/helpers'

const calendarId = 'appointment-calendar'
const CalendarPage = styled.div`
  width: 100%;
  height: 100%;
`

class Calendar extends Component {
  state = {
    showWalkin: false
  }
  constructor(props) {
    super(props)

    this.cellHandlers = {
      onClick: this.props.selectAppointment()
    }

    this.selectedAppointmentHandlers = {
      onSelectedAppointmentLoading
    }
  }

  componentDidMount() {
    this.props.initAppointmentCalendar()
    this.addLeaveEventHandlers()
  }

  componentWillUnmount() {
    this.props.toggleVisibilityLegendIcon()
    removeReloadEventHandlers()
    this.props.clearAutoRefresh()
  }

  addLeaveEventHandlers = () => {
    this.warnBeforeReload = warnBeforeReloadInit(this.props.t)
    onBeforeReloadListener(this.warnBeforeReload)
  }

  switchToCancelAppointment = () => {
    this.props.toggleVisibilityEdit()
    this.props.toggleVisibilityCancel()
  }

  goBack = () => {
    this.props.toggleVisibilityCancel()
    this.props.toggleVisibilityEdit()
  }

  handleShowContact = () => {
    const {origin, pathname} = window.location
    const contactPath = pathname.replace(
      'calendar',
      `contacts/detail/${this.props.selectedAppointmentContactId}`
    )
    const contactDetailPath = `${origin}${contactPath}`

    window.open(contactDetailPath, '_blank')
  }

  openWalkin = async (placeId, slot) => {
    if (isOldEntity({createdAt: new Date()})) {
      const date = this.props.selectedDate.split('T')[0]

      this.setState({showWalkin: true}, async () => {
        await this.props.updateWalkinForm('appointment', {
          name: 'placeId',
          value: placeId
        })
        this.props.reactToLocationChange({placeId}, `${date} ${slot}`)
      })
    }
  }

  closeWalkin = () => {
    this.setState({showWalkin: false}, () => {
      this.props.resetWalkinForm()
    })
  }

  onWalkinSubmit = () => {
    this.closeWalkin()

    this.props.refreshAppointments()
  }

  render() {
    const selectedAppointmentId = this.props.selectedAppointment
      ? this.props.selectedAppointment.id
      : null
    const shouldRenderLegendDialog =
      this.props.appointmentStatuses &&
      this.props.appointmentStatuses.length > 0

    return (
      <PermissionWrapper
        entity='web.admin.ui.crm.appointments2'
        withDescription
      >
        <CalendarPage>
          <AppointmentContextProvider
            cellHandlers={this.cellHandlers}
            appointmentStatuses={this.props.appointmentStatuses}
            selectedAppointment={this.props.selectedAppointment}
            isLoading={this.props.isLoading}
          >
            <AppointmentFilter
              shortTitle
              calendarId={calendarId}
              refreshData={this.props.refreshAppointments}
            />
            <AppointmentCalendar onCellClick={this.openWalkin} />
            <RenderIf if={this.state.showWalkin}>
              <Walkin
                fullScreenDialog={false}
                handleSubmit={this.onWalkinSubmit}
                handleClose={this.closeWalkin}
                ref={(ref) => {
                  this.walkinForm = ref
                }}
                isOpen
              />
            </RenderIf>
            <RenderIf if={this.props.visible.edit}>
              <AppointmentDialog
                creation={false}
                id={selectedAppointmentId}
                isCalendar={true}
                shouldShowContactButton={
                  this.props.selectedAppointmentContactId
                }
                isOpen={this.props.visible.edit}
                handleClose={this.props.selectAppointment()}
                onCancelAppointment={this.switchToCancelAppointment}
                handleSubmit={this.props.selectAppointment()}
                handleShowContact={this.handleShowContact}
              />
            </RenderIf>
            <RenderIf if={this.props.visible.cancel}>
              <CancelDialog
                isCalendar={true}
                isOpen={this.props.visible.cancel}
                id={selectedAppointmentId}
                goBack={this.goBack}
                handleSubmit={this.props.toggleVisibilityCancel}
                handleClose={this.props.toggleVisibilityCancel}
              />
            </RenderIf>
            {/* TODO: Handle these dialogs with Routes just like in lists */}
            <RenderIf if={shouldRenderLegendDialog}>
              <LegendDialog
                config={this.props.appointmentStatuses}
                isOpen={this.props.visible.legend}
                close={this.props.toggleVisibilityLegend}
              />
            </RenderIf>
          </AppointmentContextProvider>
        </CalendarPage>
      </PermissionWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: isCalendarLoading(state),
  visible: state.appointmentCalendar.visible,
  selectedAppointment: getSelectedAppointment(state),
  selectedAppointmentContactId: getAppointmentContactId(state),
  appointmentStatuses: getAppointmentStatusesWithMeta(state),
  selectedDate: selectedDate(calendarId)(state)
})

const mapActionToProps = (dispatch) => ({
  initAppointmentCalendar: () => dispatch(initAppointmentCalendar()),
  toggleVisibilityCancel: () => dispatch(toggleVisibilityCancel()),
  toggleVisibilityEdit: () => dispatch(toggleVisibilityEdit()),
  toggleVisibilityLegend: () => dispatch(toggleVisibilityLegend()),
  toggleVisibilityLegendIcon: () => dispatch(toggleVisibilityLegendIcon()),
  selectAppointment: () => (id) => {
    if (isOldEntity({createdAt: new Date()})) {
      dispatch(toggleVisibilityEdit())
      dispatch(selectAppointment(id))
    }
  },
  refreshAppointments: () => dispatch(fetchAppointmentSlots(true)),
  clearAutoRefresh: () => dispatch(clearRefreshInterval()),
  updateWalkinForm: (entity, input) =>
    dispatch(updateWalkinForm(entity, input)),
  resetWalkinForm: () => dispatch(resetWalkinForm()),
  reactToLocationChange: (place, slot) =>
    dispatch(reactToLocationChange(place, 'appointment', slot))
})

export default BlockNavigation(
  connect(mapStateToProps, mapActionToProps)(translate()(Calendar))
)
