import React from 'react';
import { Loader } from 'motofin-ui';
import '../../Notifications.scss';
import { ErrorLoadingMoreNotifications } from './NotificationsError';

interface ShowMoreNotificationsProps {
    translate: any,
    moreNotificationsLoading: boolean,
    errorLoadingMoreNotifications: boolean,
    handleShowMoreNotificationsClick: () => void,
}

const ShowMoreNotifications = (props: ShowMoreNotificationsProps) => {
    const {
        translate,
        moreNotificationsLoading,
        errorLoadingMoreNotifications,
        handleShowMoreNotificationsClick
    } = props;

    return <div className='showMoreNotifications flexCenter' data-testid="ShowMoreNotificationsWrapper">
        {
            moreNotificationsLoading ?
                <Loader loadingText={translate("tms.loader.text")} loaderHeight="42" loaderWidth="42" className='showMoreNotificationsLoader' />
                :
                <span className="showMoreNotificationsText" onClick={handleShowMoreNotificationsClick} data-testid="ShowMoreNotificationsButton">
                    {translate("ui.notifications.listing.showMoreNotifications")}
                </span>
        }
        {errorLoadingMoreNotifications && <ErrorLoadingMoreNotifications translate={translate} />}
    </div>
}

export default ShowMoreNotifications;