import React, {useState} from 'react'
import cx from 'classnames'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import {Filters} from '../../hooks/useFilters/useFilters'
import Location from '../../types/Location'

type ShapedOption = {value: string; disabled: boolean}

const FilterBar = ({
  filters,
  onFilterChange,
  clearFilters,
  filteredLocationList
}: {
  filters: Filters
  onFilterChange: (field: keyof Filters, value: string[]) => void
  clearFilters: () => void
  filteredLocationList: Location[][]
}) => {
  const classes = useCommonStyles()
  const [shapedOptions, setShapedOptions] = useState<ShapedOption[] | []>([])

  /**
   * Disables options according to previously selected filters
   */
  const shapeOptions = (options: string[], field: keyof Filters) => {
    const sourceIndex =
      filters[field].index > -1
        ? filters[field].index - 1
        : filteredLocationList.length - 1
    const shaped: ShapedOption[] = []
    options.map((option) => {
      const value = field !== 'active' ? option : option === 'active'
      const disabled =
        sourceIndex > 0 &&
        !filteredLocationList[sourceIndex].some((location: Location) => {
          if (field !== 'type') {
            return location[field] === value
          } else {
            return (location.type || []).includes(option)
          }
        })
      shaped.push({
        value: option,
        disabled: disabled
      })
    })

    setShapedOptions(shaped)
  }
  if (!filters) return null

  const filterKeys = Object.keys(filters) as (keyof Filters)[]
  const returnFilterSelects = () => {
    return filterKeys.map((field) => {
      return (
        <Grid key={field} item xs={2} className={classes.paddingRight2}>
          <FormControl className={classes.fullWidth}>
            <InputLabel id={field}>{filters[field].title}</InputLabel>
            <Select
              onOpen={() => {
                shapeOptions(filters[field].options, field)
              }}
              onClose={() => {
                setShapedOptions([])
              }}
              labelId={field}
              fullWidth
              multiple
              value={filters[field]?.selectedFilters || []}
              onChange={(event: React.ChangeEvent<{value: string[]}>) => {
                onFilterChange(field, event.target.value)
              }}
              input={<Input />}
              renderValue={(selected: string[]) => selected.join(', ')}
              disabled={filters[field].options.length === 0}
            >
              {shapedOptions.map((option: ShapedOption) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  <Checkbox
                    color='primary'
                    checked={
                      (filters[field]?.selectedFilters || []).indexOf(
                        option.value
                      ) > -1
                    }
                  />
                  <ListItemText primary={option.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )
    })
  }

  const disableClearButton: boolean = !filterKeys.some(
    (filter) => filters[filter].index > -1
  )

  return (
    <Grid
      container
      className={cx(
        classes.paddingX2,
        classes.fullWidth,
        classes.flexGrow,
        classes.paddingBottom2,
        classes.justifyEnd,
        classes.borderBottom
      )}
    >
      <Grid
        item
        xs={2}
        className={cx(
          classes.paddingRight3,
          classes.selfCenter,
          classes.textRight
        )}
      >
        <Grid item xs={12}>
          <Button
            color='primary'
            endIcon={<CloseIcon />}
            onClick={clearFilters}
            disabled={disableClearButton}
          >
            Clear filters
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color='textSecondary'
            variant='body2'
            className={(classes.letterSpacingNormal, classes.paddingRight2)}
          >
            Showing{' '}
            {filteredLocationList[filteredLocationList.length - 1].length}{' '}
            locations.
          </Typography>
        </Grid>
      </Grid>
      {returnFilterSelects()}
    </Grid>
  )
}

export default FilterBar
