import {Component} from 'react'
import {Grid} from '@material-ui/core'
import AppointmentDetail from './Detail'
import Handlers from '../Handlers'
import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../../components/providers/withConfig'

export class AppointmentEdit extends Component {
  static displayName = 'Appointment Editor'

  static defaultProps = {
    creation: true,
    locationChange: () => {}
  }

  getOptions = () => {
    const {options, data} = this.props
    const appointmentType = options.appointmentType.find(
      ({value}) => value === data.type
    )

    return {
      ...options,
      locationOptions:
        appointmentType && options.locationOptions
          ? options.locationOptions[appointmentType.metaInfo.locationType]
          : options.locationOptions
    }
  }

  render() {
    const {data, creation, loadingSlots, t, originalBookingSource} = this.props
    const handlers = Handlers(this.props)

    if (typeof data === 'undefined' || data === null) return null

    const options = this.getOptions()

    const placeExists =
      options.locationOptions &&
      Array.isArray(options.locationOptions) &&
      options.locationOptions.find(({value}) => value === data.placeId)

    return (
      <Grid container>
        {creation !== true && <AppointmentDetail data={data} />}
        <Grid container spacing={16}>
          <ConfigContext.Consumer>
            {(config) => (
              <FormBuilder
                t={t}
                data={{...data, placeId: placeExists ? data.placeId : null}}
                options={this.getOptions()}
                loadingSlots={loadingSlots}
                form={this.props.config.form}
                setUserOptions={this.props.setUserOptions}
                users={this.props.users}
                onChangeHandlers={{
                  handleChange: handlers.handleChange,
                  handleSelectChange: handlers.handleSelectChange,
                  handleLocationChange: handlers.handleLocationChange,
                  handleAppointmentStatusChange:
                    handlers.handleAppointmentStatusChange,
                  handleTextInput: handlers.handleTextInput,
                  handleAssignedTo: handlers.handleAssignedTo,
                  handleSlotChange: handlers.handleSlotChange,
                  handlePurchaseCoordinatorChange:
                    handlers.handlePurchaseCoordinatorChange,
                  handleAppointmentTypeChange:
                    handlers.handleAppointmentTypeChange
                }}
                isCreation={creation}
                config={{
                  ...config.country,
                  ...this.props.config,
                  disabledBookingSources: config.disabledBookingSources,
                  originalBookingSource
                }}
                disabled={this.props.disabled}
              />
            )}
          </ConfigContext.Consumer>
        </Grid>
      </Grid>
    )
  }
}
export default AppointmentEdit
