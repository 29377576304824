import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {translate} from 'react-i18next'
import styled from 'styled-components'

import {Button} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Cancel from '@material-ui/icons/Cancel'
import Edit from '@material-ui/icons/Edit'

import PageTableBase from '../../components/PageTableBase'
import {ExpandableComponent} from './ExpandableComponent'
import ActionButton from '../../components/layout/ActionButton'
import {PermissionWrapper} from '../../components/Permissions'
import CustomSnackbar from '../../components/Snackbar'
import CancelTransitDialog from './CancelTransit'
import UpdateStatusDialog from './UpdateStatusDialog'

import selectors from '../../store/transitJob/selectors'
import {getLocationOptions} from '../../store/config/actions'
import {fetchDriverOptions} from '../../store/driver/actions'
import {getVisibleActions} from '../CRM/common/helpers'
import {checkUserPermissions} from '../../store/auth/selectors'
import {
  fetchTransitJobList,
  toggleField,
  toggleSort,
  toggleRow,
  updateFilters,
  cancelTransitJob
} from '../../store/transitJob/actions'
import {STATUS_TYPE} from './constants'

const TransitsJobsListPage = styled.div`
  .RichTable__tableWrapper {
    tr {
      box-shadow: none;
    }
  }
`

export class TransitsJobsList extends Component {
  _isMounted = false
  constructor(props) {
    super(props)

    this.state = {
      showCancelTransitDialog: false,
      showUpdateStatusDialog: false,
      transitJobId: ''
    }
  }

  componentDidMount() {
    this._isMounted = true

    if (this._isMounted) {
      this.props.loadOptions()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleSubmit = () => {
    this.routeBack()
    this.props.fetchData()
  }

  routeBack = () => this.props.history.push(this.props.match.path)

  routeEdit = ({id}) => {
    this.props.collapseAllRows()
    this.props.history.push(`${this.props.match.path}/edit/${id}`)
  }

  routeToTransitJobDetail = (e, transitJob) => {
    this.props.history.push(`/transit-detail/${transitJob.id}`)
  }

  checkPermission = (type) =>
    this.props.hasPermissions({
      entity: 'web.admin.ui.car.transitJobs',
      types: [type]
    })

  singleSelectActions = [
    {
      test: () => this.checkPermission('UPDATE'),
      action: {
        event: this.routeEdit,
        icon: <Edit />,
        title: 'global.action.edit',
        hide: (item) =>
          [
            STATUS_TYPE.SCHEDULED,
            STATUS_TYPE.FINISHED,
            STATUS_TYPE.ABORTED,
            STATUS_TYPE.RUNNING
          ].includes(item.status)
      }
    },
    {
      test: () => this.checkPermission('UPDATE'),
      action: {
        event: (item) => {
          this.openStatusChangeDialog(item.id)
        },
        icon: <Edit />,
        title: 'transitJobList.action.updateStatus',
        hide: (item) =>
          [STATUS_TYPE.FINISHED, STATUS_TYPE.ABORTED].includes(item.status)
      }
    },
    {
      test: () => this.checkPermission('READ'),
      action: {
        event: (item) => {
          this.openTransitJobDialog(item.id)
        },
        icon: <Cancel />,
        title: 'global.action.cancel',
        hide: (item) =>
          [STATUS_TYPE.FINISHED, STATUS_TYPE.ABORTED].includes(item.status)
      }
    }
  ]

  openTransitJobDialog = (transitJobId) => {
    this.setState({showCancelTransitDialog: true, transitJobId})
  }

  openStatusChangeDialog = (transitJobId) => {
    this.setState({showUpdateStatusDialog: true, transitJobId})
  }

  closeTransitJobDialog = () => {
    this.setState({showCancelTransitDialog: false, transitJobId: undefined})
  }

  closeStatusChangeDialog = () => {
    this.setState({showUpdateStatusDialog: false, transitJobId: ''})
  }

  cancelTransitJob = (comment) => {
    this.props.cancelTransitJob({
      id: this.state.transitJobId,
      comment
    })
    this.closeTransitJobDialog()
  }

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  render() {
    const {t} = this.props

    const expandableComponentProps = {
      t: this.props.t,
      onRowToggle: this.props.onRowToggle,
      expandedRows: this.props.expandedRows,
      fetchRowData: () => {}
    }

    return (
      <Fragment>
        <TransitsJobsListPage className='page'>
          <PageTableBase
            filterable
            {...this.props}
            multisort={false}
            title='entity.transitsJobs'
            onRowClick={this.routeToTransitJobDetail}
            rowsPerPageOptions={[25, 50, 100]}
            scrollOnOpen
            expandableTable
            singleSelectActions={this.singleSelectTableActions()}
            expandableComponentProps={expandableComponentProps}
            ExpandableComponent={ExpandableComponent}
            expandedRows={this.props.expandedRows}
          />
        </TransitsJobsListPage>
        <PermissionWrapper
          entity='web.admin.ui.car.transitJobs'
          types={['CREATE']}
        >
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              component={Link}
              to={`${this.props.match.path}/create`}
              data-test='transit-create'
            >
              <Add />
            </Button>
          </ActionButton>
        </PermissionWrapper>
        <CancelTransitDialog
          isOpen={this.state.showCancelTransitDialog}
          closeTransitJob={this.closeTransitJobDialog}
          cancelTransitJob={this.cancelTransitJob}
        />
        {this.state.showUpdateStatusDialog ? (
          <UpdateStatusDialog
            isOpen={this.state.showUpdateStatusDialog}
            closeDialog={this.closeStatusChangeDialog}
            t={t}
            transitJobId={this.state.transitJobId}
          />
        ) : null}
        <CustomSnackbar />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {config} = state
  const locationOptions = config.options.locationOptions || {}
  const formOptions = {
    locationOptions: locationOptions['CURRENT'],
    driverOptions: selectors.getDriverOptionsWithType(state)
  }

  return {
    formOptions,
    data: selectors.getTransitListWithPlaces(state),
    fetching: selectors.isFetchingList(state),
    error: selectors.getError(state),
    sort: selectors.getSort(state),
    count: selectors.getCount(state),
    filters: selectors.getFilters(state),
    page: selectors.getPage(state),
    limit: selectors.getLimit(state),
    fields: selectors.getFields(state),
    expandedRows: selectors.getExpandedRows(state),
    hasPermissions: (permissions) => checkUserPermissions(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(fetchTransitJobList(args)),
  cancelTransitJob: (payload) => dispatch(cancelTransitJob(payload)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  collapseAllRows: () => dispatch(toggleRow()),
  onFilterChange: (filter) => dispatch(updateFilters(filter)),
  onRowToggle: (rowData) => dispatch(toggleRow(rowData)),
  loadOptions: () => {
    dispatch(getLocationOptions('CURRENT'))
    dispatch(fetchDriverOptions())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(TransitsJobsList))
