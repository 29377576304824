// we get the config base from static files in src/config
// refer to docs/configuration for the configuration schema.

const TIMEOUT = 60 * 60 * 1000

export const getInitialState = (overrides = {}) => ({
  options: {},
  userInfoTimeout: TIMEOUT,
  ...overrides
})
