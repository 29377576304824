import {Component} from 'react'
import {compose} from 'redux'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import cookies from 'browser-cookies'
import {withAuth as withJumpCloudAuth} from 'react-oidc-context'

import {
  redirectReset,
  callGoogleK2Login,
  authenticateGoogleToken,
  authenticateJumpCloudToken
} from '../../store/auth/actions'
import {
  checkUserPermissions,
  checkUserManagerPermission,
  hasUserPermissions
} from '../../store/auth/selectors'
import {error} from '../../store/global/actions'
import {registerHistory} from '../../utils/history'

import {getHiddenPages} from '../../store/config/selectors'
import {PAYSLIP_PERMISSION} from '../../store/crmUserGroups/permissionsConfig'
import {getGoogleOAuthTokens} from '../../utils/authToken'
import {
  API_STATUS,
  JUMPCLOUD_ID_TOKEN,
  JUMPCLOUD_ACCESS_TOKEN
} from '../../appConstants'

export class Auth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasRedirectedFromPayslips: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.isAuthenticated &&
      nextProps.isAuthenticated !== prevState.hasRedirectedFromPayslips
    ) {
      return {hasRedirectedFromPayslips: false}
    }

    return null
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.isAuthenticated !== nextProps.isAuthenticated ||
      nextProps.redirect !== this.props.redirect ||
      nextProps.hasUserInfo !== this.props.hasUserInfo ||
      nextProps.auth !== this.props.auth ||
      (nextProps.auth &&
        this.props.auth &&
        nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated)
    )
  }

  handleUnauthorizedAccess = () => {
    this.props.error({
      message: 'global.errors.invalidToken',
      action: null
    })

    const loginPath = location.pathname
      ? `/login?returnUrl=${location.pathname}${location.search}`
      : '/login'

    this.props.history.push(loginPath)
  }

  isUnauthorizedAccess = () => {
    return (
      this.props.isAuthenticated === false &&
      this.props.location.pathname.indexOf('login') === -1
    )
  }

  componentDidMount() {
    registerHistory(this.props.history)

    const loginPath = window.location.pathname.includes('k2')
      ? 'k2-login'
      : 'login'

    //If page gets redirected from google
    const {accessToken: googleAccessToken} = getGoogleOAuthTokens()
    // Get the jumpcloud token
    const jumpCloudIdToken = cookies.get(JUMPCLOUD_ID_TOKEN)
    const jumpCloudClientId = cookies.get(window.jumpCloudConfig.clientId)
    const jumpCloudAccessToken = cookies.get(JUMPCLOUD_ACCESS_TOKEN)

    if (googleAccessToken) {
      if (loginPath === 'k2-login') {
        this.props.callGoogleK2Login()
        this.props.history.push('/k2-login/callback')
      } else {
        this.props.authenticateGoogleToken()
      }
    } else if (jumpCloudIdToken) {
      this.props.authenticateJumpCloudToken({
        accessToken: jumpCloudAccessToken,
        idToken: jumpCloudIdToken,
        clientId: jumpCloudClientId
      })
    } else if (
      this.isUnauthorizedAccess() &&
      !this.props.auth?.isAuthenticated
    ) {
      this.handleUnauthorizedAccess()
    }
  }

  getMenuItemsPath = () => {
    const {items, hiddenFields, user} = this.props

    const userHiddenFields =
      typeof user !== 'undefined' && user.menuItems
        ? user.menuItems.hiddenFields || []
        : []

    return items
      .reduce((acc, item) => {
        if (
          hiddenFields.includes(item.key) ||
          userHiddenFields.includes(item.key) ||
          ['inspectionReports'].includes(item.path)
        ) {
          return acc
        }

        if (item.permissions && !this.props.hasPermissions(item.permissions)) {
          return acc
        }

        if (item.subItems) {
          return [
            ...acc,
            ...item.subItems.map((subItem) => `${item.path}/${subItem.path}`)
          ]
        }

        return [...acc, item.path]
      }, [])
      .filter((item) => !item.includes(':'))
  }

  componentDidUpdate(prevProps) {
    let path = this.props.user.homepage

    if (this.props.isAuthenticated && this.props.redirect !== false) {
      this.props.redirectReset()

      if (
        path.includes('/payslips') &&
        !this.props.hasPermissions({
          entity: PAYSLIP_PERMISSION,
          types: ['READ']
        })
      ) {
        const menuItems = this.getMenuItemsPath() || []
        path = menuItems[0] || path

        if (
          path === '/payslips' &&
          !prevProps.location.pathname.includes('login')
        ) {
          path = prevProps.location.pathname
        }

        this.setState({
          hasRedirectedFromPayslips: true
        })
      }

      if (this.props.redirect) {
        path = this.props.redirect
      }

      return this.props.history.push(path)
    }

    // similar code as in componentDidMount
    // when user is already logged in JumpCloud: user will simply redirect from /login to /login/callback
    // so componentDidUpdate will trigger with change in the auth state
    // and proceeds to authenticate the jumpCloud token
    if (
      this.isUnauthorizedAccess() &&
      this.props.auth &&
      this.props.googleLoginProgress === API_STATUS.STALE
    ) {
      if (this.props.auth.isAuthenticated) {
        this.props.authenticateJumpCloudToken()
      } else {
        this.handleUnauthorizedAccess()
      }
    }

    // this check is added because it may happen
    // user fails on /login/callback (i.e jumpCloud authenticated but still not in fcg system)
    // in that case the above if cause will still not work
    if (this.props.redirect && this.props.redirect.startsWith('/login')) {
      this.props.redirectReset()
      this.props.history.push('/login')
    }

    if (
      this.props.hasUserInfo &&
      this.props.history.location.pathname === '/payslips' &&
      !this.state.hasRedirectedFromPayslips &&
      !this.props.hasPermissions({
        entity: PAYSLIP_PERMISSION,
        types: ['READ']
      })
    ) {
      const menuItems = this.getMenuItemsPath() || []
      path = menuItems[0] || path

      this.setState({
        hasRedirectedFromPayslips: true
      })

      return this.props.history.push(path)
    }
  }

  render() {
    if (this.isUnauthorizedAccess() === true) {
      return null
    }

    return this.props.children
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  redirect: state.authReducer.redirect,
  hasPermissions: (permissions) => checkUserPermissions(state, permissions),
  hasUserManagerPermission: checkUserManagerPermission(state),
  items: state.global.menuItems.allFields[0],
  hiddenFields: getHiddenPages(state),
  hasUserInfo: hasUserPermissions(state),
  googleLoginProgress: state.authReducer.googleLoginProgress
})

const mapDispatchToProps = (dispatch) => ({
  redirectReset: () => dispatch(redirectReset()),
  error: (payload) => dispatch(error(payload)),
  callGoogleK2Login: (payload) => dispatch(callGoogleK2Login(payload)),
  authenticateGoogleToken: () => dispatch(authenticateGoogleToken()),
  authenticateJumpCloudToken: (payload) =>
    dispatch(authenticateJumpCloudToken(payload))
})

export default compose(
  withJumpCloudAuth,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Auth)
