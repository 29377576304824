import {
  ValidatedTextField,
  ValidatedSelectField,
  RegexField
} from '../../../../components/FormValidation'
import DealerLocationPicker from '../../../../components/DealerLocationPicker/index'
import {disabledCallback} from './utils'

export default {
  stepNum: 6,
  stepTitle: 'dealer.legalAndAddress',
  description: 'dealer.panel.description.legalAndAddress',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'BooleanRadioInput',
            fullWidth: true,
            id: 'legallyRegistered',
            label: 'dealer.page.field.legallyRegistered',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            type: 'boolean',
            options: [
              {
                value: true,
                label: 'dealer.legallyRegistered.yes'
              },
              {
                value: false,
                label: 'dealer.legallyRegistered.no'
              }
            ],
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'companyName',
            label: 'dealer.page.field.companyName',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalCompanyName',
            label: 'dealer.page.field.legalCompanyName',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            withTranslate: true,
            id: 'companyTypeId',
            label: 'dealer.page.field.companyType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: RegexField,
            fullWidth: true,
            id: 'regon',
            label: 'dealer.page.field.regon',
            margin: 'normal',
            regex: /^[0-9]{9}([0-9]{5})?$/,
            required: true,
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: RegexField,
            fullWidth: true,
            id: 'postCode',
            label: 'dealer.page.field.postCode',
            margin: 'normal',
            regex: /^[0-9]{2}-[0-9]{3}$/,
            required: true,
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            withTranslate: true,
            id: 'businessTypeSelect',
            label: 'dealer.page.field.businessType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'businessType',
            label: 'dealer.page.field.businessType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'registrationType',
            label: 'dealer.page.field.registrationType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'registrationId',
            label: 'dealer.page.field.registrationId',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'companyTradeLicense',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.companyTradeLicense',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'street',
            label: 'dealer.page.field.street',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'zipCode',
            label: 'dealer.page.field.zipCode',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: DealerLocationPicker,
        disabled: disabledCallback
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'district',
            label: 'dealer.page.field.district',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalAdress',
            label: 'dealer.page.field.legalAdress',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            multiline: true,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'companyTaxId',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.companyTaxId',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'DateInput',
            fullWidth: true,
            id: 'taxIdExpiryDate',
            label: 'dealer.page.field.taxIdExpiryDate',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'taxOffice',
            label: 'dealer.page.field.taxOffice',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'dealerTaxOfficeCity',
            label: 'dealer.page.field.dealerTaxOfficeCity',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'dealerTaxOfficeCommune',
            label: 'dealer.page.field.dealerTaxOfficeCommune',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalRepresentativeName',
            label: 'dealer.page.field.legalRepresentativeName',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalRepresentativeLastName',
            label: 'dealer.page.field.legalRepresentativeLastName',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            id: 'legalRepresentativePhone',
            label: 'dealer.page.field.legalRepresentativePhone',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            isNumber: true,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            type: 'email',
            fullWidth: true,
            id: 'legalRepresentativeEmail',
            label: 'dealer.page.field.legalRepresentativeEmail',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalRepresentativeNationality',
            label: 'dealer.page.field.legalRepresentativeNationality',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalRepresentativeProfesion',
            label: 'dealer.page.field.legalRepresentativeProfesion',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'legalRepresentativeId',
            label: 'dealer.page.field.legalRepresentativeId',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'taxId',
            label: 'dealer.page.field.taxId',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'dealersPublicDeedIncorporationDate',
            label: 'dealer.page.field.dealersPublicDeedIncorporationDate',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'dealersPublicDeedNotary',
            label: 'dealer.page.field.dealersPublicDeedNotary',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'dealersPublicDeedNotaryCity',
            label: 'dealer.page.field.dealersPublicDeedNotaryCity',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'companyTransportAuthorityNo',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.companyTransportAuthorityNo',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
