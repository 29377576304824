import {Component} from 'react'
import styled from 'styled-components'
import i18next from 'i18next'
import Button from '@material-ui/core/Button'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {withRouter} from 'react-router'
import {ValidatedStepForm} from '@fcg/formvalidation'
import Close from '@material-ui/icons/Close'
import {loadOptions} from '../../store/crm/actions'
import {
  updateDriver,
  resetDriver,
  updateVisitedPage,
  saveDriver,
  fetchDriverImage
} from '../../store/driver/actions'
import {default as selectors} from '../../store/driver/selectors'
import LoadingState from '../../components/LoadingState'
import Edit from './Edit'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import {cleanUp} from '../../store/documentConfig/actions'

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
`

const FormContainer = styled.div`
  padding: 24px 40px;
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
`

const Title = styled.div`
  font-size: 24px;
  color: #000;
  display: flex;
  justify-content: space-between;
`

const CloseIcon = styled(Close)`
  align-self: center;
  cursor: pointer;
`

const Content = styled.span`
  max-width: 486px;
  margin-top: 8px;
  font-size: 14px;
  white-space: pre-line;
  color: #7e7e7e;
  letter-spacing: 0.25px;
  line-height: 20px;
`

const Footer = styled.div`
  width: 100%;
  height: 80px;
  border: 0;
  bottom: 0;
  border-top: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  flex-direction: row-reverse;
`

const ActionButton = styled(Button)`
  height: 36px;
  padding: 8px;
`

const LoadingMaskWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 10000;

  .centered {
    top: calc(50% - 32px);
    position: absolute;
    left: calc(50% - 32px);
  }
`

class RightPanel extends Component {
  constructor(props) {
    super(props)

    this.link = '/drivers'
    this.state = {
      data: {},
      metaInfo: {},
      driver: {},
      userId: null
    }
  }

  componentDidMount() {
    const {userId} = this.props.match.params

    this.props.cleanupDocumentState()
    this.loadDriverData(this.props)
    this.props.loadOptions()

    if (typeof userId !== 'undefined') {
      this.setState({userId})
      this.props.fetchDriverImage(userId)
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.shouldProceed && nextProps.shouldProceed) {
      const canProceed = await this.canUserProceed()

      this.props.updateProceedStatus(canProceed)
    }

    if (nextProps.driverImage !== this.props.driverImage) {
      this.setState({
        data: {
          ...this.state.data,
          imageUpload: {
            imageUrl: nextProps.driverImage.link
          }
        }
      })
    }
  }

  componentWillUnmount() {
    this.props.resetDriver()
  }

  loadDriverData = (props) => {
    const {metaInfo, driver, ...data} = props.data

    this.setState({data, metaInfo, driver})
  }

  updateField = (e, fieldName) => {
    const value =
      e !== null && typeof e.target !== 'undefined' ? e.target.value : e

    this.setState({
      data: {
        ...this.state.data,
        [fieldName]: fieldName === 'email' ? value.replace(/\s/g, '') : value
      }
    })
  }

  updateProfileImage = (profileImage) => {
    if (profileImage) {
      this.setState({
        data: {
          ...this.state.data,
          imageUpload: {
            ...profileImage,
            imageUrl: [profileImage.file],
            visibility: 'internal',
            fileName: profileImage.name,
            fileType: profileImage.type
          }
        }
      })
    } else {
      this.setState({
        data: omit(this.state.data, 'imageUpload')
      })
    }
  }

  updateDriverDocumentId = (e, fieldName) => {
    const value = e !== null && e.target ? e.target.value : e
    const field = fieldName

    this.setState({
      driver: {...this.state.driver, [field]: value}
    })
  }

  updateDriverInfo = (e, fieldName) => {
    const value = e !== null && e.target ? e.target.checked : e
    const field = fieldName

    this.setState({
      driver: {...this.state.driver, [field]: value}
    })
  }

  updateMetaField = (e, fieldName) => {
    const value = e !== null && e.target ? e.target.value : e
    let field = fieldName

    if (fieldName === 'metaInfoEmail') {
      field = 'email'
    }

    this.setState({
      metaInfo: {...this.state.metaInfo, [field]: value}
    })
  }

  goNext = async () => {
    const value = await this.form.validate()

    if (value && this.props.formLength > this.props.selected) {
      const data = {...this.state.data, metaInfo: this.state.metaInfo}

      this.props.updateDriver(data)
      this.props.updateVisitedPage(this.props.selected)
      this.props.updateSelection(this.props.selected + 1)
    }
  }

  canUserProceed = async () => {
    const value = await this.form.validate()
    const data = {...this.state.data, metaInfo: this.state.metaInfo}

    this.props.updateDriver(data)

    return value
  }

  goPrevious = () => {
    if (this.props.selected > 1) {
      const data = {...this.state.data, metaInfo: this.state.metaInfo}

      this.props.updateDriver(data)
      this.props.updateSelection(this.props.selected - 1)
    }
  }

  save = () => {
    const data = {
      ...this.state.data,
      metaInfo: this.state.metaInfo,
      driver: this.state.driver
    }

    this.props.saveDriver(data)
  }

  handleClose = () => {
    this.props.history.goBack()
  }

  render() {
    const {metaInfo, data, driver} = this.state
    const {formConfig, options, editState} = this.props

    if (
      (this.props.data === null || this.props.data.loaded === false) &&
      this.state.userId
    ) {
      return <LoadingState />
    }

    if (isEmpty(data)) return <LoadingState />

    return (
      <FormWrapper>
        {editState.pending && (
          <LoadingMaskWrapper>
            <LoadingState />
          </LoadingMaskWrapper>
        )}
        <ValidatedStepForm
          t={this.props.t}
          ref={(ref) => (this.form = ref)}
          stepNum={this.props.selected}
          resetValidationOnStepChange
        >
          <FormContainer>
            <Title>
              {this.props.t(formConfig.stepTitle)}
              <CloseIcon onClick={this.handleClose} />
            </Title>
            {i18next.exists(formConfig.description) ? (
              <Content>{this.props.t(formConfig.description)}</Content>
            ) : (
              ''
            )}
            <Edit
              {...this.props}
              t={this.props.t}
              data={data}
              form={formConfig.form}
              metaInfo={metaInfo}
              driver={driver}
              updateField={this.updateField}
              updateDriverDocumentId={this.updateDriverDocumentId}
              updateDriverInfo={this.updateDriverInfo}
              updateMetaField={this.updateMetaField}
              updateProfileImage={this.updateProfileImage}
              options={options}
            />
          </FormContainer>
        </ValidatedStepForm>
        <Footer>
          {this.props.selected < this.props.formLength && (
            <ActionButton
              variant='contained'
              color='primary'
              onClick={this.goNext}
              data-test='button-next'
            >
              {this.props.t('button.next')}
            </ActionButton>
          )}
          {this.props.selected === this.props.formLength && (
            <ActionButton
              variant='contained'
              color='primary'
              onClick={this.save}
              disabled={editState.pending === true}
              data-test='button-save'
            >
              {this.props.t('button.save')}
            </ActionButton>
          )}
          {this.props.selected > 1 && (
            <ActionButton variant='outlined' onClick={this.goPrevious}>
              {this.props.t('button.previous')}
            </ActionButton>
          )}
        </Footer>
      </FormWrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  formConfig: selectors.getDriverFormConfig(state, ownProps.selected),
  data: selectors.getDriverData(state),
  options: selectors.getDriverStatusOptions(state),
  formLength: selectors.getFormLength(state),
  driverImage: selectors.getDriverImage(state),
  editState: selectors.getEditState(state)
})

const mapDispatchToProps = (dispatch) => ({
  saveDriver: (data) => dispatch(saveDriver(data)),
  loadOptions: () => dispatch(loadOptions()),
  resetDriver: () => dispatch(resetDriver()),
  updateDriver: (data) => dispatch(updateDriver(data)),
  fetchDriverImage: (payload) => dispatch(fetchDriverImage(payload)),
  updateVisitedPage: (payload) => dispatch(updateVisitedPage(payload)),
  cleanupDocumentState: () => dispatch(cleanUp())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(withRouter(RightPanel)))
