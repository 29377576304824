import {PureComponent} from 'react'

import styled from 'styled-components'
import {translate} from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from './Card'
import {InlineCell, colors} from './common'
import {
  AppointmentContext,
  AppointmentContextConsumer
} from '../AppointmentContext'
import CardExtend from './ExtendCard'

const {lightGray, white, cellBorder} = colors
const AppointmentCell = styled(InlineCell).attrs(() => ({
  width: 'xl'
}))`
  border: solid 1px ${() => cellBorder};
  border-left: 0;
  border-top: 0;
  background-color: ${({isInOddRow}) => (isInOddRow ? lightGray : white)};
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 4px 3px;
  &:hover {
    -webkit-box-shadow: inset 0px 0px 5px 1px rgba(63, 81, 181, 1);
    -moz-box-shadow: inset 0px 0px 5px 1px rgba(63, 81, 181, 1);
    box-shadow: inset 0px 0px 5px 1px rgba(63, 81, 181, 1);
  }
`
const appointmentCellKey = (rowId, cellIndex) => `cell-${rowId}-${cellIndex}`
const cardKey = (rowId, cardIndex, appointmentId) =>
  `card-${rowId}-${cardIndex}-${appointmentId}`
const extendCellKey = (rowId, cellIndex) => `expand-cell-${rowId}-${cellIndex}`

class TableCellBase extends PureComponent {
  static contextType = AppointmentContext

  constructor(props) {
    super(props)

    this.state = {
      showAllAppointments: false,
      popups: {}
    }
  }

  shouldToggleTooltipFor = (appointment, shown) => () => {
    this.setState({
      popups: {
        [appointment.id]: shown
      }
    })
  }

  shouldShowTooltipFor = (appointment) => {
    return (
      this.state.popups[appointment.id] &&
      Boolean(appointment && appointment.lead)
    )
  }

  onCardExtendClick = () => {
    this.setState((prevState) => ({
      showAllAppointments: !prevState.showAllAppointments
    }))
  }

  onCellClick = () => {
    if (typeof this.props.onCellClick !== 'function') {
      return
    }

    this.props.onCellClick(this.props.cellIndex)
  }

  render() {
    const {cell, rowId, cellIndex} = this.props
    const cards = cell.data.appointments

    return (
      <AppointmentCell
        key={appointmentCellKey(rowId, cellIndex)}
        isInOddRow={this.props.isInOddRow}
        onClick={this.onCellClick}
      >
        {cards.map((appointment, index) => {
          if (
            this.props.selectedAppointment &&
            this.props.selectedAppointment.id &&
            appointment.id === this.props.selectedAppointment.id &&
            this.props.selectedAppointment.isLoading
          ) {
            return <CircularProgress />
          }

          const cardColor = this.props.appointmentStatuses.find(
            (status) => status.value === appointment.status
          )
          const color =
            cardColor && cardColor.metaInfo && cardColor.metaInfo.color
              ? cardColor.metaInfo.color
              : colors.defaultCardColor

          return (
            <Card
              onMouseEnter={this.shouldToggleTooltipFor(appointment, true)}
              onMouseLeave={this.shouldToggleTooltipFor(appointment, false)}
              showTooltip={this.shouldShowTooltipFor(appointment)}
              cardIndex={index}
              key={cardKey(rowId, index, appointment.id)}
              legend={color}
              status={appointment.status}
              cardKey={cardKey(rowId, index, appointment.id)}
              appointment={appointment}
              onCardExtendClick={this.onCardExtendClick}
              showAllAppointments={this.state.showAllAppointments}
              cardsCount={cards.length}
              {...this.props.cellHandlers}
            />
          )
        })}
        {cards.length > 12 ? (
          <CardExtend
            extendKey={extendCellKey(rowId, cellIndex)}
            onClick={this.onCardExtendClick}
            direction={this.state.showAllAppointments ? 'up' : 'down'}
          />
        ) : null}
      </AppointmentCell>
    )
  }
}

export default translate()(AppointmentContextConsumer(TableCellBase))
