export const schedulesFields = {
  name: {
    key: 'name',
    label: 'auctionSchedules.column.name',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  kind: {
    key: 'kind',
    label: 'auctionSchedules.column.type',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  duration: {
    key: 'duration',
    label: 'auctionSchedules.column.duration',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  auctionPreviewOffset: {
    key: 'previewOffset',
    label: 'auctionSchedules.column.auctionPreviewOffset',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  onAuctionEnd: {
    key: 'onAuctionEnd',
    label: 'auctionSchedules.column.onAuctionEnd',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  allowedBidTypes: {
    key: 'allowedBidTypes',
    label: 'auctionSchedules.column.allowedBidTypes',
    type: 'string',
    isFilterable: false,
    isSortable: false
  }
}

export const schedulesSubtableFields = {
  type: {
    key: 'kind',
    label: 'auctionSchedules.column.type',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  duration: {
    key: 'duration',
    label: 'auctionSchedules.column.duration',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  discount: {
    key: 'discount',
    label: 'auctionSchedules.column.discount',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  buyNowDiscount: {
    key: 'buyNowDiscount',
    label: 'auctionSchedules.column.buyNowDiscount',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  bidTypes: {
    key: 'allowedBidTypes',
    label: 'auctionSchedules.column.allowedBidTypes',
    type: 'string',
    isFilterable: false,
    isSortable: false
  }
}
