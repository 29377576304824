import React from 'react'
import Chip from '@material-ui/core/Chip'

type Theme = 'inactive' | 'active' | 'disabled' | 'custom'

const themes: {[theme in Theme]: object} = {
  inactive: {
    backgroundColor: '#efe0e0',
    color: '#d0021b'
  },
  active: {
    backgroundColor: '#f7ffe9',
    color: '#5aa700'
  },
  disabled: {
    backgroundColor: '#f4f4f4',
    color: '#3b424b'
  },
  custom: {
    backgroundColor: '#ffeabf',
    color: '#e67c00'
  }
}

const StatusLabel = ({
  label = 'active',
  theme,
  width
}: {
  label: string
  theme: Theme
  width: string
}) => {
  const style = {
    ...themes[theme],
    borderRadius: '8px',
    width: width || '100px'
  }

  return <Chip style={style} label={label} />
}

export default StatusLabel
