import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {ValidatedForm} from '@fcg/formvalidation'
import Edit from './Edit'
import * as selectors from '../../store/bulkUpload/selectors'
import withConfig from '../../components/providers/withConfig'
import styled from 'styled-components'
import Upload from '../../components/Upload'
import {loadOptions} from '../../store/crm/actions'
import BulkUploadUploadDialog from './BulkUploadDialog'
import {getLocationOptions} from '../../store/config/actions'
import {
  setUploading,
  uploadDocument,
  uploadDataError,
  fetchBulkUploadData,
  toggleBulkUploadDialog,
  saveBulkUploadRequest,
  updateFormData,
  updateStep,
  resetBulkUpload
} from '../../store/bulkUpload/actions'
import {removeAllDataFromResource} from '../../store/resources/actions'
import {BULK_UPLOAD, FIRST_STEP} from './constants'
import CustomSnackbar from '../../components/Snackbar'
import {Delete} from '@material-ui/icons'
import {checkUserPermissions} from '../../store/auth/selectors'
import {showSnackbarNotification} from '../../store/signals/actions'
import * as signalTypes from '../../store/signals/types'

const csvAcceptFile = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
].join(', ')

const Page = styled.section`
  background-color: #eee;
`

const UploadWrapper = styled.div`
  margin: 20px 0px;
  width: 100%;

  h2 {
    margin: 20px 0px;
  }
`

const FileUploaded = styled.div`
  margin: 20px 0px;
  width: 50%;
  background-color: #fff;
  border-width: 1px;
  border-color: #bababa;
  border-style: dashed;
  border-radius: 5px;
  padding: 20px;
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
`

const BulkUploadGrid = styled(Grid)`
  width: 80% !important;
  margin: 20px auto 40px !important;

  hr {
    width: 100%;
    margin: 10px 0px 20px;
  }
`

const PermissionWarning = styled.span`
  font-size: 13px;
  padding-left: 5px;
`

export class Editor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
      users: {options: [], count: 0},
      selectedTab: ''
    }
  }

  componentDidMount() {
    this.props.loadOptions()
  }

  updateField = (e, fieldName) => {
    const value = e && e.target ? e.target.value : e

    this.props.updateFormData({[fieldName]: value})
  }

  readFile = (file) => {
    this.props.fetchBulkuploadData(file)
  }

  openUploadDialog = () => {
    this.props.toggleBulkUploadDialog(true)
  }

  closeUploadDialog = () => {
    this.props.toggleBulkUploadDialog(false)
    this.removeUploadedFile()
  }

  onDrop = (file) => {
    this.props.removeAllDataFromResource(BULK_UPLOAD)
    this.props.resetBulkUpload(false)

    if (file && file.length > 0) {
      this.props.uploadDocument(file)
      this.openUploadDialog()
      this.readFile(file[0])
      this.setState({
        file: file[0]
      })
    } else {
      this.props.showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.document.error',
        open: true
      })
    }
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  removeUploadedFile = () => {
    this.setState({file: undefined})
    this.props.removeAllDataFromResource(BULK_UPLOAD)
    this.props.resetBulkUpload(false)
    this.props.updateStep(FIRST_STEP)
  }

  hasPermissionsToCreateCar = () => {
    const {hasPermissions} = this.props

    return (
      hasPermissions({
        entity: 'service.financialDetails',
        types: ['CREATE']
      }) &&
      hasPermissions({
        entity: 'service.car',
        types: ['CREATE']
      })
    )
  }

  isContinueBtnDisabled = () => {
    const {bulkUploadData} = this.props

    return bulkUploadData.length === 0 || !this.hasPermissionsToCreateCar()
  }

  renderUploader() {
    const {t, isUploading, setUploading} = this.props

    return (
      <UploadWrapper>
        <Typography variant='title'>
          {t('bulkupload.selectfile.upload')}
        </Typography>
        <Divider />
        {!this.state.file ? (
          <Upload
            onDrop={this.onDrop}
            multiple={false}
            accept={csvAcceptFile}
            isUploading={isUploading}
            setUploading={setUploading}
          />
        ) : (
          <FileUploaded>
            {this.state.file.name}
            <Delete onClick={this.removeUploadedFile} />
          </FileUploaded>
        )}
      </UploadWrapper>
    )
  }

  getTypeOptions = (options = []) =>
    options.map((item) => ({
      ...item,
      label: this.props.t(`car.page.carType.${item.value}`)
    }))

  getContactTypeOptions = (options = []) =>
    options.map((item) => ({
      ...item,
      label: this.props.t(`car.page.contactType.${item.value}`)
    }))

  render() {
    const {
      t,
      pageConfig,
      formOptions,
      isUploading,
      hasUploadError,
      isUploadDialogVisible
    } = this.props
    const options = {
      ...formOptions,
      ...{
        carType: this.getTypeOptions(formOptions.carType)
      },
      ...{
        contactType: this.getContactTypeOptions(formOptions.contactType)
      }
    }

    return (
      <Page>
        <ValidatedForm onSubmit={this.openUploadDialog} t={t}>
          <BulkUploadGrid container spacing={16}>
            <Typography variant='title'>{t('bulkupload.create')}</Typography>
            <Divider />
            <Edit
              {...this.props}
              setUserOptions={this.setUserOptions}
              options={options}
              pageConfig={pageConfig.form}
              users={this.state.users}
              updateField={this.updateField}
            />
            <CustomSnackbar />
            {this.renderUploader()}
            <div className='Actions--left'>
              <Button
                variant='raised'
                color='primary'
                type='submit'
                disabled={this.isContinueBtnDisabled()}
              >
                {t('button.continue')}
              </Button>
              {!this.hasPermissionsToCreateCar() && (
                <PermissionWarning>
                  {t('bulkupload.cars.permissionWarning')}
                </PermissionWarning>
              )}
            </div>
          </BulkUploadGrid>
        </ValidatedForm>
        <BulkUploadUploadDialog
          t={t}
          open={isUploadDialogVisible}
          hasUploadError={hasUploadError}
          handleClose={this.closeUploadDialog}
          isUploading={isUploading}
          createCar={this.props.createCar}
        />
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  const {crm, config} = state
  const form = selectors.getFormData(state)
  const locationOptions = config.options.locationOptions || {}
  const formOptions = {
    ...crm.options,
    purchaseLocation: locationOptions['PURCHASE'],
    currentLocation: locationOptions['CURRENT']
  }

  return {
    hasUploadError: selectors.hasUploadError(state),
    bulkUploadData: selectors.getBulkUploadDataList(state),
    isUploadDialogVisible: selectors.isBulkUploadDialogVisible(state),
    isUploading: selectors.isUploading(state),
    form,
    formOptions,
    list: selectors.getBulkUploadDataList(state),
    data: selectors.getFormData(state),
    hasPermissions: (permissions) => checkUserPermissions(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleBulkUploadDialog: (payload) =>
    dispatch(toggleBulkUploadDialog(payload)),
  fetchBulkuploadData: (payload) => dispatch(fetchBulkUploadData(payload)),
  setUploading: (payload) => dispatch(setUploading(payload)),
  uploadDataError: (payload) => dispatch(uploadDataError(payload)),
  uploadDocument: (payload) => dispatch(uploadDocument(payload)),
  updateStep: (payload) => dispatch(updateStep(payload)),
  loadOptions: () => {
    dispatch(getLocationOptions('CURRENT'))
    dispatch(getLocationOptions('PURCHASE'))
    dispatch(loadOptions())
  },
  createCar: () => dispatch(saveBulkUploadRequest()),
  updateFormData: (payload) => dispatch(updateFormData(payload)),
  removeAllDataFromResource: (payload) =>
    dispatch(removeAllDataFromResource(payload)),
  resetBulkUpload: (payload) => dispatch(resetBulkUpload(payload)),
  showSnackbarNotification: (payload) =>
    dispatch(showSnackbarNotification(payload))
})

export default withConfig(
  ['country'],
  connect(mapStateToProps, mapDispatchToProps)(translate()(Editor))
)
