import Payslips from '../../../../pages/Payslips/List'
import {
  payslipFields,
  payslipSubtableFields,
  payslipSubtableSummaryFields
} from './payslipFields'
import {
  createPayslipFields,
  newPayslipSummaryFields
} from '../create/createPayslipFields'
import gql from '@fcg/lib-gql'
import {
  PAYSLIP_PERMISSION,
  NECCESSARY_PERMISSIONS
} from '../../../../store/crmUserGroups/permissionsConfig'

export const sort = [{field: 'createdAt', order: 'DESC'}]
export const filters = []

const ticketStatusValues = [
  'OPEN',
  'IN_PROGRESS',
  'APPROVED',
  'REJECTED',
  'DONE'
]

export const ticketStatuses = ticketStatusValues.reduce(
  (a, t) => ({
    ...a,
    [t]: t
  }),
  {}
)
export const ticketStatusesRequest = ticketStatusValues.reduce(
  (a, t) => ({
    ...a,
    [t]: new gql.EnumType(t)
  }),
  {}
)

export default {
  path: 'payslips',
  key: 'payslips',
  label: 'menu.payslips.sectionHeadline',
  sort: [],
  limit: 25,
  filters,
  page: 1,
  fields: {...payslipFields},
  subtableFields: {...payslipSubtableFields},
  subtableSummaryFields: {...payslipSubtableSummaryFields},
  subfields: [{key: 'subtableData'}],
  component: Payslips,
  newPayslip: Object.values(createPayslipFields),
  newPayslipSummaryFields: Object.values(newPayslipSummaryFields),
  permissions: {
    entity: PAYSLIP_PERMISSION,
    types: NECCESSARY_PERMISSIONS.READ
  }
}
