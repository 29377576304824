import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import FormDividerField from '../../../../components/FormDivider.jsx'

export const sellerDetail = [
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 12,
    children: [
      {
        component: FormDividerField,
        fullWidth: true,
        id: 'sellerDetail',
        label: 'car.page.title.contactSection',
        margin: 'normal'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'contactType',
        label: 'car.contact.page.field.type',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'contactSubType',
        label: 'car.contact.page.field.subtype',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'company',
        label: 'auction.userInfo.companyName',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'firstName',
        label: 'car.contact.page.field.firstName',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'lastName',
        label: 'car.contact.page.field.lastName',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'email',
        label: 'car.contact.page.field.email',
        margin: 'normal',
        type: 'email',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: 'ValidatedPhoneInput',
        fullWidth: true,
        isNumber: true,
        id: 'phone',
        label: 'car.contact.page.field.phone',
        margin: 'normal',
        onChangeHandler: 'updateField',
        validator: {required: true, type: 'phone'}
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'street',
        label: 'car.contact.page.field.street',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'zip',
        label: 'car.contact.page.field.zip',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'city',
        label: 'car.contact.page.field.city',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'taxId',
        label: 'car.contact.page.field.taxId',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  (props) =>
    props.options.bankName && props.options.bankName.length > 0
      ? {
          component: 'Grid',
          item: true,
          xs: 12,
          sm: 4,
          children: [
            {
              component: ValidatedSelectField,
              fullWidth: true,
              id: 'bankName',
              label: 'car.contact.page.field.bankName',
              margin: 'normal',
              onChangeHandler: 'updateField'
            }
          ]
        }
      : {
          component: 'Grid',
          item: true,
          xs: 12,
          sm: 4,
          children: [
            {
              component: ValidatedTextField,
              fullWidth: true,
              id: 'bankName',
              label: 'car.contact.page.field.bankName',
              margin: 'normal',
              onChangeHandler: 'updateField'
            }
          ]
        },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'bankCode',
        label: 'car.contact.page.field.bankCode',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'bankAccountNumber',
        label: 'car.contact.page.field.bankAccountNumber',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'bankAccountOwner',
        label: 'car.contact.page.field.bankAccountOwner',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedTextField,
        fullWidth: true,
        id: 'bankAccountOwnerTaxId',
        label: 'car.contact.page.field.bankAccountOwnerTaxId',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  }
]
