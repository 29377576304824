import styled from 'styled-components'
import {RenderIf} from '../../components/RenderIf'
import Search from '@material-ui/icons/Search'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  margin-bottom: 20px;
  background: #f4f4f4;
`
export const Content = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: space-around;
`
export const NoContent = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
`
export const NoImageText = styled.div`
  margin: 10px 0;
  font-weight: 500;
`
const EmptyTablePlaceholderWrapper = styled.tr`
  width: 100%;
  height: 100px;
  text-align: center;
`
export const EmptyTablePlaceholder = ({
  colspan,
  show,
  t,
  message = 'payslips.dialog.table.placeholder',
  showIcon = true
}) => (
  <RenderIf if={show}>
    <EmptyTablePlaceholderWrapper>
      <td colSpan={colspan}>
        {showIcon ? <Search /> : null}
        <div>{t(message)}</div>
      </td>
    </EmptyTablePlaceholderWrapper>
  </RenderIf>
)
