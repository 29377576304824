import styled from 'styled-components'
import QuestionAnswer from '@material-ui/icons/QuestionAnswer'
import {DocumentSelector} from './DocumentSelector'
import {NoImageText} from '../../commons'

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RenderDocumentSelector = ({
  images,
  selected,
  selectedImage,
  onOpenGallery,
  onSelectImage,
  t,
  showMetaInfo,
  metaInfoInputs
}) => {
  if (images.length) {
    return (
      <DocumentSelector
        selectImage={onSelectImage}
        openGallery={onOpenGallery}
        images={images}
        selected={selected}
        selectedImage={selectedImage}
        showMetaInfo={showMetaInfo}
        metaInfoInputs={metaInfoInputs}
      />
    )
  }

  return (
    <NoImage>
      <QuestionAnswer fontSize='large' color='disabled' />
      <NoImageText>{t('payslips.subtable.noReceipts')}</NoImageText>
    </NoImage>
  )
}
