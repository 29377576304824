import merge from 'lodash/merge'

export const state = {
  dealer: null,
  dealerInternalId: null,
  cars: [],
  documents: [],
  amountChanges: {},
  selectedRows: [],
  filters: [],
  create: {
    created: false,
    isSubmitting: false,
    error: null
  }
}

export const getInitialState = (preferences = {}) => {
  const setup = merge(state, preferences)

  return {
    ...setup
  }
}
