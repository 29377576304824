import * as types from './types'

export default {
  [types.userNotLoggedIn]: (state) => {
    // eslint-disable-next-line no-console
    console.error('auth error: user not logged in')

    return {
      ...state,
      notification: {
        type: 'error',
        message: 'User not logged in.'
      }
    }
  },
  [types.apiError]: (state, action) => {
    const {apiError, errorMessage, showErrorDialog, errorType} = action.payload
    const message =
      typeof errorMessage !== 'undefined'
        ? Array.isArray(errorMessage.errors)
          ? errorMessage.errors
          : [errorMessage]
        : null

    return {
      ...state,
      showErrorDialog: showErrorDialog || false,
      apiError,
      errorMessage: message,
      errorType: errorType || ''
    }
  },
  [types.showErrorDialog]: (state) => {
    return {
      ...state,
      showErrorDialog: true
    }
  },
  [types.success]: (state, action) => {
    return {
      ...state,
      notification: {
        type: 'success',
        ...action.payload
      }
    }
  },
  [types.error]: (state, action) => {
    return {
      ...state,
      notification: {
        type: 'error',
        ...action.payload
      }
    }
  },
  [types.reset]: (state) => {
    return {
      ...state,
      notification: false
    }
  },
  [types.toggleMenu]: (state, action) => {
    return {
      ...state,
      isMenuOpen: action.payload === null ? !state.isMenuOpen : action.payload
    }
  }
}
