import {PureComponent} from 'react'
import withAsyncSource from '../providers/withAsyncSource'
import * as AuthApi from '../../api/auth/requests'
import Select from '../Select/Select'
import {withValidation, validatorFields} from '@fcg/formvalidation'
import errorMessageAdapter from '../FormValidation/errorMessageAdapter'
const loadUsersQuery = []

const UserPicker = withAsyncSource({
  loadData: (query = {}, loadOptions = {}) => {
    // TODO: Move this to Redux with pagination
    loadUsersQuery.push(
      AuthApi.getUserList({...query}).then((response) => {
        const options =
          response.user && response.user.list
            ? response.user.list.map((user) => ({
                label:
                  loadOptions && loadOptions.formatLabel
                    ? loadOptions.formatLabel(user)
                    : `${user.firstname} ${user.lastname}`,
                value: user.id
              }))
            : []

        return {options, count: response.user.count}
      })
    )

    return Promise.all(loadUsersQuery).then((response) => {
      loadUsersQuery.length = 0

      return response[response.length - 1]
    })
  },
  queryBuilder: ({
    userClass = 'INTERNAL',
    // eslint-disable-next-line no-unused-vars
    supervisor = null,
    value = null,
    deletedUser = false,
    ...rest
  }) => ({
    deletedUser,
    userClass,
    ...(value !== null ? {anyName: value} : {}),
    ...rest
  })
})(Select)

class ValidatedUserPickerField extends PureComponent {
  onChange = (selected, label) => {
    const joinLabel = Array.isArray(label) ? label.join(', ') : label

    this.props.onChange({target: {value: selected, label: joinLabel}})
  }

  render() {
    return <UserPicker {...this.props} onChange={this.onChange} />
  }
}

export const ValidatedUserPicker = withValidation(validatorFields.select)(
  errorMessageAdapter(ValidatedUserPickerField)
)

UserPicker.type = 'UserPickerField'
ValidatedUserPicker.type = 'UserPickerField'

export default UserPicker
