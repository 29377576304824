import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import {disabledCallback} from './utils'

export default {
  stepNum: 8,
  stepTitle: 'dealer.carPreferencesAndProfiling',
  description: 'dealer.panel.description.carPreferencesAndProfiling',
  form: {
    children: [
      (props) =>
        props.countryCode && props.countryCode === 'ID'
          ? {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 6,
              children: [
                {
                  component: ValidatedTextField,
                  fullWidth: true,
                  id: 'dealerSize',
                  label: 'dealer.page.field.dealerSize',
                  margin: 'normal',
                  onChangeHandler: 'updateMetaField',
                  disabled: disabledCallback
                }
              ]
            }
          : {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 6,
              children: [
                {
                  component: ValidatedSelectField,
                  fullWidth: true,
                  id: 'dealerSize',
                  label: 'dealer.page.field.dealerSize',
                  margin: 'normal',
                  onChangeHandler: 'updateMetaField',
                  disabled: disabledCallback
                }
              ]
            },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'carPreferences',
            label: 'dealer.page.field.carPreferences',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            multiline: true,
            id: 'dealerLocations',
            label: 'dealer.page.field.dealerLocations',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'businessCategory',
            label: 'dealer.page.field.businessCategory',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'estimatedMargin',
            label: 'dealer.page.field.estimatedMargin',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            fullWidth: true,
            id: 'carPreferencesPrice',
            label: 'dealer.page.field.carPreferencesPrice',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            fullWidth: true,
            isNumber: true,
            id: 'monthlyTurnover',
            label: 'dealer.page.field.monthlyTurnover',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'averageStock',
            label: 'dealer.page.field.averageStock',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'idealStock',
            label: 'dealer.page.field.idealStock',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            id: 'storageTime',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.storageTime',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            id: 'expectedDelivery',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.expectedDelivery',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            id: 'expectedCarCare',
            fullWidth: true,
            multiple: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.expectedCarCare',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            id: 'expectedFinancing',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            label: 'dealer.page.field.expectedFinancing',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'competitors',
            multiline: true,
            label: 'dealer.page.field.competitors',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            isNumber: true,
            fullWidth: true,
            margin: 'normal',
            id: 'employeeCount',
            label: 'dealer.page.field.employeeCount',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            multiple: true,
            id: 'dealerAppsChannels',
            label: 'dealer.page.field.dealerAppsChannels',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
