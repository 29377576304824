import * as types from './types'
import {
  carsSelector,
  selectedRowsSelector,
  createStatusSelector
} from './selectors'
import {
  cleanUp as cleanUpDocuments,
  deleteDocument
} from '../../documentConfig/actions'
import {getPaymentInputValue} from './helpers'
import {documentList} from '../../documentConfig/selectors'

export const setDealer = (id) => ({
  type: types.setDealer,
  payload: {id}
})

export const setCars = (cars) => ({
  type: types.setCars,
  payload: {cars}
})

export const setAmount = (amount, id) => ({
  type: types.setAmount,
  payload: {
    amount,
    id
  }
})

export const updateDueAmount = ({value, row}) => (dispatch, getState) => {
  const changeId = row.financialDetails.id
  const parsedValue = Number(value)
  const purchases = carsSelector(getState())
  const carPurchace = purchases.find(
    ({financialDetails}) => financialDetails.id === changeId
  )
  const amount = getPaymentInputValue(
    parsedValue,
    carPurchace.financialDetails.dueAmount
  )

  dispatch(setAmount(amount, changeId))
}

export const selectAllRows = (ids) => ({
  type: types.selectRow,
  payload: ids
})

export const selectRow = (id) => (dispatch, getState) => {
  let selectedRows = selectedRowsSelector(getState())
  const alreadySelected = selectedRows.find((row) => row === id)

  selectedRows = alreadySelected
    ? selectedRows.filter((row) => row !== alreadySelected)
    : [...selectedRows, id]

  dispatch({
    type: types.selectRow,
    payload: selectedRows
  })
}

export const cleanUpDialog = () => (dispatch, getState) => {
  const documetnList = documentList(getState())
  const documentIds = documetnList.map(({id}) => id)
  const {created} = createStatusSelector(getState())

  if (!created && documetnList && documetnList.length) {
    dispatch(
      deleteDocument({
        documentIds,
        doNotFetch: true
      })
    )
  }

  dispatch(cleanUpDocuments())
  dispatch({type: types.cleanUp})
}

export const createTicketSuccess = () => ({
  type: types.createTicketSuccess
})
export const createTicketError = (error) => ({
  type: types.createTicketError,
  payload: error
})
export const attemptTicketCreation = (payload) => ({
  type: types.attemptTicketCreation,
  payload
})
export const onFilterChange = (filters) => ({
  type: types.onFilterChange,
  payload: filters
})
export const setFilters = (filters) => ({
  type: types.setFilters,
  payload: filters
})
