import {withValidation, validatorFields} from '@fcg/formvalidation'
import {TextField} from '@material-ui/core'
import errorMessageAdapter from './errorMessageAdapter'
import {translate} from 'react-i18next'
import withConfig from '../providers/withConfig'

const phoneValidator = {
  test: () => true,
  validate: (value, props) => {
    if (!value || props.referralCodeType === 'input') {
      return true
    }

    const {code} = props.phone

    const length =
      props.phone.length.min && props.phone.length.max
        ? [props.phone.length.min, props.phone.length.max]
        : props.phone.length

    const regex = new RegExp(`^\\+${code}[0-9]{${length.join(',')}}$`)

    return regex.test(value)
  },
  defaultErrorMessage: (props) => {
    return props.t('global.error.inputType.phoneNumber')
  },
  errorMessageProp: 'phoneError'
}

const referralValidation = {
  test: () => true,
  validate: (value, props) => {
    if (!value) {
      return true
    }

    if (props.referralCodeType === 'input') {
      const regex = /^[0-9a-zA-Z]+$/

      return regex.test(value)
    }

    return true
  },
  defaultErrorMessage: (props) => {
    return props.t('global.error.inputType.referralCode')
  },
  errorMessageProp: 'referralCodeError'
}

const ValidatedTextField = withConfig(
  ['phone', 'referralCodeType'],
  translate()(
    withValidation([
      ...validatorFields.input,
      referralValidation,
      phoneValidator
    ])(errorMessageAdapter(TextField))
  )
)

ValidatedTextField.type = 'ValidatedTextField'

export default ValidatedTextField
