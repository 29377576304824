import {
  withStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@material-ui/core'
import styled from 'styled-components'

const SubPermissionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-right: 24px;
  padding-left: 24px;
`

const SubPermissionLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
`

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      width: '100%'
    },
    mainText: {
      fontSize: theme.typography.body1.fontSize
    },
    descriptionText: {
      fontSize: '12px',
      opacity: 0.6
    },
    checkbox: {
      color: 'rgba(0, 0, 0, 0.5)',
      '&$checked': {
        color: '#000'
      }
    },
    checked: {}
  }
}

const SubPermission = ({classes, permission, type, t, selected, onChange}) => (
  <FormGroup classes={{root: classes.root}}>
    <FormControlLabel
      className={classes.root}
      control={
        <SubPermissionContainer>
          <SubPermissionLabel>
            <Typography className={classes.mainText}>
              {t(`${permission}.${type}.title`)}
            </Typography>
            <Typography className={classes.descriptionText}>
              {t(`${permission}.${type}.description`)}
            </Typography>
          </SubPermissionLabel>

          <Checkbox
            checked={selected}
            onChange={(e) => {
              e.stopPropagation()
              onChange(permission, type)
            }}
            classes={{root: classes.checkbox, checked: classes.checked}}
          />
        </SubPermissionContainer>
      }
    />
  </FormGroup>
)

export default withStyles(styles)(SubPermission)
