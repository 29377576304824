import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  fetchCarDataList,
  deleteCarData,
  toggleSort,
  toggleField,
  updateFilters,
  openCreateDialog,
  openEditDialog
} from '../../store/cardata/actions'
import {DeleteDialog} from '../CRM/common'
import {Button} from '@material-ui/core'
import {Add, Delete, AttachFile} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import * as selectors from '../../store/cardata/selectors'
import CustomSnackbar from '../../components/Snackbar'
import CarDataUploadDialog from './CarDataUploadDialog'
import Editor from './Editor'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

class CarDataList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delete: {...deleteInitialState},
      isUploadDialogVisible: false
    }

    this.openDeleteDialog = this.openDeleteDialog.bind(this)
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this)
  }

  openCreateDialog = (props) => {
    props.openCreateDialog()
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, carDataId, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(carDataId)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  openUploadDialog = () => {
    this.setState({isUploadDialogVisible: true})
  }

  closeUploadDialog = () => {
    this.setState({isUploadDialogVisible: false})
  }

  openEditDialog = (e, cardata) => {
    this.props.openEditDialog(cardata)
  }

  render() {
    return (
      <Fragment>
        {this.props.showCreateEditDialog ? <Editor {...this.props} /> : null}
        <div className='page'>
          <PageTableBase
            {...this.props}
            multisort={false}
            title='entity.carDataConfig'
            onRowClick={this.openEditDialog}
            rowsPerPageOptions={[25, 50, 100]}
            selectable
            filterable
            multiSelectActions={[
              {
                event: (IdsSet) => {
                  this.openDeleteDialog({
                    entity: 'cardata',
                    carDataId: Array.from(IdsSet),
                    action: this.props.deleteCarData
                  })
                },
                icon: <Delete />,
                title: 'global.action.delete'
              }
            ]}
            singleSelectActions={[
              {
                event: (item) => {
                  this.openDeleteDialog({
                    entity: 'carData',
                    carDataId: item.id,
                    action: this.props.deleteCarData
                  })
                },
                icon: <Delete />,
                title: 'global.action.delete'
              }
            ]}
          />
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openUploadDialog}
            >
              <AttachFile />
            </Button>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openCreateDialog.bind({}, this.props)}
            >
              <Add />
            </Button>
          </ActionButton>
          <DeleteDialog {...this.state.delete} />
          <CarDataUploadDialog
            open={this.state.isUploadDialogVisible}
            handleClose={this.closeUploadDialog}
          />
          <CustomSnackbar />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getCarDataList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  showCreateEditDialog: selectors.getCreateDialogState(state)
})

const mapDispatchToProps = (dispatch) => ({
  openCreateDialog: () => dispatch(openCreateDialog()),
  fetchData: (options) => dispatch(fetchCarDataList(options)),
  deleteCarData: (carDataId) => dispatch(deleteCarData(carDataId)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  onFilterChange: ({filters}) => dispatch(updateFilters(filters)),
  openEditDialog: (cardata) => dispatch(openEditDialog(cardata))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CarDataList))
