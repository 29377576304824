import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import {SlideUp} from '../../components/Animations/Slides'
import BulkUploadList from './List'
import ErrorIcon from '@material-ui/icons/Error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import {withStyles} from '@material-ui/core/styles'
import {translate} from 'react-i18next'
import withConfig from '../../components/providers/withConfig'
import * as selectors from '../../store/bulkUpload/selectors'
import {
  updateBulkUploadListVisibilityType,
  resetBulkUpload,
  toggleBulkUploadNotificationDialog,
  updateStep,
  checkDuplication,
  createDuplicateCars
} from '../../store/bulkUpload/actions'
import {
  deleteDataFromCollection,
  removeAllDataFromResource
} from '../../store/resources/actions'
import {Tabs} from '../CRM/common'
import NotificationDialog from './NotificationDialog'
import {
  BULK_UPLOAD,
  NOT_VALID,
  BULK_UPLOAD_ALL,
  VALID,
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP
} from './constants'

const BulkUploadDialogLoadingMask = styled(DialogContent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  div {
    margin: 10px 0px;
  }
`

const LoadingState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Loader = styled(CircularProgress)`
  svg {
    color: #2f897b;
  }
  margin-bottom: 12px;
`

const UploadError = styled.div`
  color: #87292e;

  svg {
    font-size: 40px;
  }
`

const CreatCar = styled(Button)`
  color: #fff !important;
  background-color: #ed6237 !important;
`

const CancelCreatCar = styled(Button)`
  color: #2e8793 !important;
`

const DialogCloseButton = styled(IconButton)`
  right: 10px;
  position: absolute !important;
  top: 10px;
`

const BulkUploadDialogActions = styled(DialogActions)`
  margin: 0px !important;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  padding: 24px;
`

const BulkUploadDialogContent = styled(DialogContent)`
  padding: 0px !important;

  td:first-of-type {
    padding-left: 24px;
  }

  th:first-of-type {
    padding-left: 24px;
  }

  label:not(:first-of-type) {
    margin-left: 20px;
  }
`

const FilterListWrapper = styled.div`
  padding: 0px 24px 0px;
`

const ErrorMessage = styled.div`
  color: #999;
  font-size: 12px;
  flex: auto;
`

const AllCheckbox = withStyles({
  root: {
    color: '#33776b'
  }
})((props) => <Checkbox color='default' {...props} />)

const ErrorCheckbox = withStyles({
  root: {
    color: '#7d3031',
    '&$checked': {
      color: '#33776b'
    }
  }
})((props) => <Checkbox color='default' {...props} />)

const paperProps = {
  style: {
    height: '100%'
  }
}

class BulkUploadUploadDialog extends Component {
  constructor(props) {
    super(props)

    this.selected = new Set()

    this.state = {
      activeSection: NOT_VALID
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.open && this.props.open !== nextProps.open) {
      this.visibilityTypeChanged(BULK_UPLOAD_ALL)
      this.selected.clear()
      this.setState({
        activeSection: NOT_VALID
      })
    }
  }

  renderUploadError = () => {
    const {t} = this.props

    return (
      <Fragment>
        <DialogCloseButton aria-label='close' onClick={this.props.handleClose}>
          <CloseIcon />
        </DialogCloseButton>
        <BulkUploadDialogLoadingMask>
          <UploadError>
            <ErrorIcon />
          </UploadError>
          <UploadError>{t('bulkupload.upload.error')}</UploadError>
          <UploadError>{t('bulkupload.upload.tryagain')}</UploadError>
        </BulkUploadDialogLoadingMask>
      </Fragment>
    )
  }

  renderLoadingMask = () => {
    const {t} = this.props

    return (
      <BulkUploadDialogLoadingMask>
        <Loader />
        <div>{t('bulkupload.upload.document')}</div>
      </BulkUploadDialogLoadingMask>
    )
  }

  sectionConfig = () => [
    {
      label: `
        ${this.props.t('bulkupload.cars.notCreated')}
         (${this.props.notCreatedCount})
      `,
      key: NOT_VALID,
      test: 'tab-not-valid'
    },
    {
      label: `
        ${this.props.t('bulkupload.cars.created')}
         (${this.props.createdCount})
      `,
      key: VALID,
      test: 'tab-valid'
    }
  ]

  updateSelection = (e) => {
    this.setState({
      activeSection: e
    })
    this.visibilityTypeChanged(e)
  }

  visibilityTypeChanged = (visibilityType) => {
    const {updateBulkUploadListVisibilityType} = this.props

    updateBulkUploadListVisibilityType(visibilityType)
  }

  handleCreate = () => {
    const {
      deleteDataFromCollection,
      notValidBulkUploadIds,
      validBulkuploadIds,
      createdCount,
      step
    } = this.props

    if (step === FIRST_STEP) {
      deleteDataFromCollection({
        ids: notValidBulkUploadIds,
        resourceType: BULK_UPLOAD
      })
      this.props.updateStep(SECOND_STEP)
    } else {
      if (createdCount) {
        deleteDataFromCollection({
          ids: validBulkuploadIds,
          resourceType: BULK_UPLOAD
        })
      }
    }

    if (step === FIRST_STEP) {
      this.props.checkDuplication()
    } else {
      this.props.createCar()
    }

    this.closeNotificationDialog()
  }

  openNotificationDialog = () => {
    if (this.props.notValidCarCount > 0) {
      this.props.toggleBulkUploadNotificationDialog(true)
    } else {
      this.handleCreate()
    }
  }

  closeNotificationDialog = () => {
    this.props.toggleBulkUploadNotificationDialog(false)
  }

  onSelectAll = (ids) => {
    if (ids.length > 0) {
      ids.forEach(this.selected.add, this.selected)
    } else {
      this.selected.clear()
    }
  }

  onSelect = ({id}) => {
    const isSelected = this.selected.has(id)

    if (isSelected) {
      this.selected.delete(id)
    } else {
      this.selected.add(id)
    }
  }

  handleSkipDuplication = () => {
    const {createDuplicateCars} = this.props
    createDuplicateCars([])
  }

  createDuplicateCar = () => {
    const {createDuplicateCars} = this.props
    const ids = this.selected.toJSON()
    const duplicateCars = ids.map((id) => ({id}))

    createDuplicateCars(duplicateCars)
  }

  renderDuplicateList = () => {
    const {bulkUploadDataList} = this.props

    return (
      <BulkUploadDialogContent>
        <DialogTitle>
          {this.props.t('bulkupload.duplicate.step.title')}
        </DialogTitle>
        <BulkUploadList
          selectable={true}
          showErrorIcon={false}
          data={bulkUploadDataList}
          onSelectAll={this.onSelectAll}
          onSelect={this.onSelect}
        />
        <BulkUploadDialogActions>
          <ErrorMessage>
            {this.props.t('bulkupload.duplicate.msg')}
          </ErrorMessage>
          <CancelCreatCar onClick={this.handleSkipDuplication}>
            {this.props.t('button.skip')}
          </CancelCreatCar>
          <CreatCar onClick={this.createDuplicateCar}>
            {this.props.t('general.action.createAnywayCars')}
          </CreatCar>
        </BulkUploadDialogActions>
      </BulkUploadDialogContent>
    )
  }

  renderBulkUploadList = () => {
    const {
      dataCount,
      t,
      notValidCarCount,
      activeListType,
      bulkUploadDataList,
      createdCount,
      step,
      notCreatedCount,
      isNotificationDialogVisible
    } = this.props

    return (
      <BulkUploadDialogContent>
        <DialogTitle>
          {step === THIRD_STEP
            ? `
                ${createdCount} ${t('bulkupload.records.created')}
                ${notCreatedCount} ${t('bulkupload.records.errors')}
              `
            : `${dataCount} ${t('bulkupload.records.uploaded')}`}
        </DialogTitle>
        {step === THIRD_STEP ? (
          <Tabs
            sections={this.sectionConfig()}
            handleSelect={this.updateSelection}
            sticky={this.state.hasFullHeader}
            selected={this.state.activeSection}
          />
        ) : (
          <FilterListWrapper>
            <FormLabel>{t('View')}:</FormLabel>
            <FormControlLabel
              control={
                <AllCheckbox
                  data-test='b2b-show-all'
                  checked={activeListType === BULK_UPLOAD_ALL}
                  onChange={() => {
                    this.visibilityTypeChanged(BULK_UPLOAD_ALL)
                  }}
                />
              }
              label='All'
            />
            <FormControlLabel
              control={
                <ErrorCheckbox
                  data-test='b2b-show-errors'
                  checked={activeListType === NOT_VALID}
                  onChange={() => {
                    this.visibilityTypeChanged(NOT_VALID)
                  }}
                />
              }
              label={`Errors (${notValidCarCount})`}
            />
          </FilterListWrapper>
        )}
        <BulkUploadList data={bulkUploadDataList} />
        <BulkUploadDialogActions>
          <ErrorMessage>
            {`
              ${notValidCarCount || notCreatedCount}
                ${t('bulkupload.contains.errors')}.
            `}
            {notCreatedCount
              ? ` ${t('bulkupload.error.resubmit')}`
              : ` ${t('bulkupload.onlyCarsWithoutErrorsWillBeCreated')}`}
          </ErrorMessage>
          <CancelCreatCar
            onClick={this.props.handleClose}
            data-test='b2b-create-car-cancel'
          >
            {t('button.cancel')}
          </CancelCreatCar>
          {((!createdCount && !notCreatedCount) ||
            ((createdCount || notCreatedCount) && notCreatedCount !== 0)) && (
            <CreatCar
              onClick={this.openNotificationDialog}
              data-test='b2b-create-car'
            >
              {notCreatedCount
                ? t('general.action.resubmit')
                : t('general.action.createCars')}
            </CreatCar>
          )}
          <NotificationDialog
            open={isNotificationDialogVisible}
            handleCancel={this.closeNotificationDialog}
            handleSubmit={this.handleCreate}
          />
        </BulkUploadDialogActions>
      </BulkUploadDialogContent>
    )
  }

  renderUploadingErrorOrList = () => {
    if (this.props.isUploading) {
      return this.renderLoadingMask()
    } else if (this.props.hasUploadError) {
      return this.renderUploadError()
    } else {
      if (this.props.step === SECOND_STEP) {
        return this.renderDuplicateList()
      } else {
        return this.renderBulkUploadList()
      }
    }
  }

  renderUploadingState = () => {
    const {
      createdCount,
      validBulkuploadIds = [],
      notValidBulkUploadIds = [],
      isCheckingDuplicateCars
    } = this.props
    const totalCount = validBulkuploadIds.length + notValidBulkUploadIds.length

    return (
      <LoadingState>
        <Loader />
        {isCheckingDuplicateCars ? (
          this.props.t('bulkUpload.checkingDuplicateCars')
        ) : (
          <span>
            {this.props.t('bulkUpload.creating.car.counting', {
              createdCount: Math.min(createdCount + 1, totalCount),
              totalCount
            })}
            <br />
            {this.props.t('bulkUpload.uploadingState')}
          </span>
        )}
      </LoadingState>
    )
  }

  render() {
    return (
      <Dialog
        fullWidth
        disableBackdropClick={false}
        maxWidth={'xl'}
        open={this.props.open}
        PaperProps={paperProps}
        TransitionComponent={SlideUp}
      >
        {' '}
        {this.props.isCheckingDuplicateCars || this.props.isSaving
          ? this.renderUploadingState()
          : this.renderUploadingErrorOrList()}
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  bulkUploadDataList: selectors.getBulkUploadDataListWithValidation(state),
  activeListType: selectors.getActiveListType(state),
  notValidCarCount: selectors.getNotValidBulkUploadDataListCount(state),
  dataCount: selectors.getBulkUploadDataListCount(state),
  duplicateCount: selectors.getDuplicateCount(state),
  createdCount: selectors.getCreatedCount(state),
  notCreatedCount: selectors.getNotCreatedCount(state),
  isSaving: selectors.getSavingState(state),
  isCheckingDuplicateCars: selectors.getIsCheckingDuplicateCars(state),
  notValidBulkUploadIds: selectors.getNotValidBulkUploadDataListIds(state),
  validBulkuploadIds: selectors.getValidBulkUploadDataListIds(state),
  isNotificationDialogVisible: selectors.isBulkUploadNotififcationDialogVisible(
    state
  ),
  step: selectors.getStep(state)
})

const mapDispatchToProps = (dispatch) => ({
  updateBulkUploadListVisibilityType: (payload) =>
    dispatch(updateBulkUploadListVisibilityType(payload)),
  resetBulkUpload: (payload) => dispatch(resetBulkUpload(payload)),
  toggleBulkUploadNotificationDialog: (payload) =>
    dispatch(toggleBulkUploadNotificationDialog(payload)),
  deleteDataFromCollection: (payload) =>
    dispatch(deleteDataFromCollection(payload)),
  removeAllDataFromResource: (payload) =>
    dispatch(removeAllDataFromResource(payload)),
  updateStep: (payload) => dispatch(updateStep(payload)),
  checkDuplication: (payload) => dispatch(checkDuplication(payload)),
  createDuplicateCars: (payload) => dispatch(createDuplicateCars(payload))
})

export default withConfig(
  ['country'],
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate()(BulkUploadUploadDialog))
)
