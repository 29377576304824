import {Component} from 'react'
import {Panel} from '@fcg/tars'
import {connect} from 'react-redux'
import {loadDealer} from '../../store/users/actions'
import * as selectors from '../../store/users/selectors'
import LoadingState from '../../components/LoadingState'

import CustomSnackbar from '../../components/Snackbar'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'

class Editor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEdit: false,
      userId: ''
    }
  }

  componentDidMount() {
    const {userId} = this.props.match.params

    if (userId) {
      this.setState({isEdit: true, userId})
      this.props.loadUser(userId)
    }
  }

  render() {
    if (
      (this.props.data === null || this.props.data.loaded === false) &&
      this.state.userId
    ) {
      return <LoadingState />
    }

    return (
      <section className='DealerDetail page'>
        <Panel
          leftChildren={LeftPanel}
          rightChildren={RightPanel}
          isEdit={this.state.isEdit}
        />
        <CustomSnackbar />
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getDealerData(state)
})

const mapDispatchToProps = (dispatch) => ({
  loadUser: (userId) => dispatch(loadDealer(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Editor)
