exports.getDomain = () => {
  return window.location.hostname.replace('local.', '')
}

exports.getPathName = () => {
  return window.location.pathname
}

/*
 * function parseQueryParams ()
 * Given an array of parameters, return a map of corresponding values from the
 * URL search string.
 * @param fields [String] (optional) defaults to []
 * @return Object
 */

exports.parseQueryParams = (fields = []) => {
  const queryString = window.location.search.substring(1)
  const params = new window.URLSearchParams(queryString)

  if (fields.length === 0) {
    return params
  }

  return fields.reduce((result, field) => {
    result[field] = params.get(field)

    return result
  }, {})
}

exports.getLangParam = () => {
  return this.getPathName().split('/')[1]
}

exports.getPathParam = () => {
  return this.getPathName().split('/')[2]
}
