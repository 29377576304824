import financingUserGroups from '../../../pages/Dealer/financingUserGroups/List'

export default {
  path: 'financing-user-groups',
  label: 'menu.dealers.financingUserGroups',
  key: 'financingUserGroups',
  component: financingUserGroups,
  fields: {
    name: {
      type: 'composite',
      key: 'name',
      layout: 'rows',
      children: [
        {
          type: 'composite',
          separator: ' ',
          key: 'firstLastName',
          children: [
            {
              key: 'firstname',
              label: 'contact.firstname.label',
              separator: ' ',
              type: 'string',
              isSortable: false
            },
            {
              key: 'lastname',
              label: 'contact.lastname.label',
              type: 'string',
              isSortable: false
            }
          ]
        }
      ]
    },
    dueDate: {
      key: 'email',
      label: 'contact.email.label',
      type: 'string',
      isSortable: true
    }
  }
}
