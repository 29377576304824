import Country from '../types/Country'

export type CountryConfig = 'mapApiKey' | 'mapCenter' | 'showAreaManagerField'

export const COUNTRY_CONFIG: {
  [key in CountryConfig]: {
    [key in Country | 'default']?: any
  }
} = {
  mapApiKey: {
    AE: 'AIzaSyBGBsItHYEVoBGP-wVGuvq3yIkkHpORvBE',
    AR: 'AIzaSyBid2C832iQpFrML5PZH68tzDbBFH74jZA',
    CL: 'AIzaSyD9Y2H2JX73erCBbi6amyI3B-bynzPivTM',
    CO: 'AIzaSyAXYfWunMfcJmh1wgbCiLtZjN0ZZai7-RQ',
    EC: 'AIzaSyDEzkScYkR0h_nBMD8k15JrdRGoI-GwLog',
    GL: 'AIzaSyApRRkJV083f0tl8QicEFE5W_SKLsab9lE',
    ID: 'AIzaSyCsmhqZeYrOdq7Aarn9U7O4PSUEI8sJW5k',
    IN: 'AIzaSyBRTok2mA801yy60kNLxOYV3NjrnYvbPnM',
    MX: 'AIzaSyC9m5sqlGiWZ3EPutyCsSlN7ruX7CwVVZg',
    NG: 'AIzaSyDnUcSSY-QEJ9uYKS34CRqNhM66qBwbFts',
    PE: 'AIzaSyDbLRz2NA_VysKb8dBbwiNenPPEXHRKNcc',
    PK: 'AIzaSyAHnjIFidlYYnmkX3iuUYKiE8rRJlrCkDY',
    PL: 'AIzaSyBwALhR_eaey-vmPYKJBPjX469i9OthXcg',
    TR: "AIzaSyAONRn9Dul2M7Luzkau2UemGQaxw2KBeF8"
  },
  mapCenter: {
    default: { lat: -4.943257, lng: 79.41556 },
    AE: { lat: 24.421172, lng: 24.421172 },
    AR: { lat: -35.352467, lng: -65.910244 },
    CL: { lat: -26.741589, lng: -70.318308 },
    CO: { lat: 3.468728, lng: -73.269139 },
    EC: { lat: -0.185306, lng: -78.474152 },
    GL: { lat: 67.085562, lng: -50.737624 },
    ID: { lat: -4.399098, lng: 122.494539 },
    IN: { lat: 22.712212, lng: 78.678123 },
    MX: { lat: 24.058155, lng: -102.860712 },
    NG: { lat: 9.056845, lng: 7.369217 },
    PE: { lat: -12.046098, lng: -77.045738 },
    PK: { lat: 30.125656, lng: 69.165214 },
    PL: { lat: 51.759861, lng: 19.455866 }
  },
  showAreaManagerField: {
    default: false,
    AR: true,
    CL: true,
    CO: true,
    MX: true,
    IN: true
  }
}
