import * as types from './types'
import omit from 'lodash/omit'

const countryOptionIdAndValueMapper = (option) => ({
  label: option.name,
  value: option.id,
  country: option.country
})

export default {
  [types.getConfig]: (state, param) => state[param || ''],
  [types.languageChange]: (state, action) => {
    return {
      ...state,
      country: {
        ...state.country,
        language: {
          ...state.country.language,
          selected: action.payload
        }
      },
      language: {
        ...state.language,
        selected: action.payload
      }
    }
  },
  [types.locationOptions]: (state, action) => {
    const locationByType = state.options.locationOptions || {}
    const locations = action.payload.place.list.map((point) => ({
      label: `${point.location}`,
      value: point.id,
      inspectionPointId: point.inspectionPointId
    }))

    if (action.payload.type) {
      locationByType[action.payload.type] = locations
    }

    return {
      ...state,
      options: {
        ...state.options,
        locationOptions: action.payload.type ? locationByType : locations
      }
    }
  },
  [types.leadSourceOptions]: (state, action) => {
    const {carLeadOption} = action.payload

    return {
      ...state,
      options: {
        ...state.options,
        leadSourceOptions: carLeadOption.leadSource.map((source) => {
          const value = source.replace('step3.leadSource.', '')

          return {
            label: `option.leadSource.${value}`,
            value
          }
        })
      }
    }
  },
  [types.carDataOptions]: (state, action) => {
    
    const item = {...action.payload}
    const [key] = Object.keys(item)
    item[key] = Object.values(item[key]).map((value) => ({
      label: value.name ? value.name : value,
      value: value.name ? value.name : value,
      code : value.code ? value.code : ''
    }))

    return {
      ...state,
      options: {
        ...state.options,
        ...item
      }
    }
  },
  [types.quote]: (state, action) => {
    const quote = {...action.payload}

    return {
      ...state,
      options: {
        ...state.options,
        ...quote
      }
    }
  },
  [types.cityList]: (state, action) => {
    return {
      ...state,
      options: {
        ...state.options,
        city: action.payload
      }
    }
  },
  [types.userConfig]: (state, action) => {
    const userConfig = omit(action.payload, ['homepage'])

    return {
      ...state,
      ...userConfig
    }
  },
  [types.setCountryOptions]: (state, action) => {
    const data = action.payload.data.map(countryOptionIdAndValueMapper)
    const optionsKey = action.payload.optionsKey

    return {
      ...state,
      options: {
        ...state.options,
        [optionsKey]: data
      }
    }
  },
  [types.setPurchaseCoordinatorOptions]: (state, action) => {
    const purchaseCoordinatorOptions = action.payload.map((option) => ({
      label: `${option.firstname} ${option.lastname}`,
      value: {
        id: option.id,
        purchaseCoordinatorPhone: option.metaInfo.phone,
        purchaseCoordinator: `${option.firstname} ${option.lastname}`
      }
    }))

    return {
      ...state,
      options: {
        ...state.options,
        purchaseCoordinatorOptions
      }
    }
  }
}
