import {TextField, InputAdornment} from '@material-ui/core'
import {Search} from '@material-ui/icons'
import SelectAdornment from './SelectAdornment'
import styled from 'styled-components'

const StyledTextInput = styled(TextField)`
  && {
    input {
      cursor: ${({cursor}) => cursor || 'auto'};
    }
  }
`

export const DisplayInput = (props) => {
  const {
    id,
    open,
    onClick,
    inputRef,
    isFetching,
    label,
    selectedLabel,
    disableClear,
    clear,
    color,
    disableUnderline,
    margin,
    disabled,
    error,
    required,
    placeholder,
    withStartAdornment,
    withEndAdornment,
    className,
    disableLabel,
    'data-test': dataTest,
    cursor
  } = props

  const startAdornment = withStartAdornment ? (
    <InputAdornment position='start'>
      <Search />
    </InputAdornment>
  ) : null

  const endAdornment = withEndAdornment ? (
    <SelectAdornment
      isFocused={open}
      isFetching={isFetching}
      clear={clear}
      disableClear={disableClear}
      selectedLabel={selectedLabel}
      disabled={disabled}
    />
  ) : null

  const textFieldLabel = disableLabel ? null : label

  return (
    <StyledTextInput
      fullWidth
      margin={margin}
      label={textFieldLabel}
      className={className}
      disabled={disabled === true || isFetching === true}
      inputRef={inputRef}
      id={id}
      value={isFetching === true ? '' : selectedLabel}
      onClick={onClick}
      error={error}
      placeholder={placeholder}
      InputLabelProps={{
        disableAnimation: true
      }}
      InputProps={{
        disableUnderline,
        style: {color},
        startAdornment,
        endAdornment
      }}
      cursor={cursor}
      required={required}
      data-test={dataTest}
    />
  )
}

DisplayInput.defaultProps = {
  withEndAdornment: true,
  disableLabel: false
}

export default DisplayInput
