import {Component} from 'react'
import styled from 'styled-components'
import {translate} from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'

const Comment = styled(TextField)`
  width: 100%;
`

class CancelTransitDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comment: ''
    }
  }

  onChange = (e) => {
    this.setState({comment: e.target.value})
  }

  resetState = () => {
    this.setState({
      comment: ''
    })
  }

  cancelTransitJob = () => {
    const {cancelTransitJob} = this.props

    cancelTransitJob(this.state.comment)
    this.resetState()
  }

  handleClose = () => {
    this.resetState()
    this.props.closeTransitJob()
  }

  render() {
    const {t, isOpen} = this.props

    return (
      <Dialog open={isOpen} maxWidth='sm' fullWidth>
        <DialogTitle id='form-dialog-title'>
          {t('transitJobsList.status.cancel.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>{t('transitJobsList.status.cancel.dialog.info')}</div>
            <Comment value={this.state.comment} onChange={this.onChange} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>{t('button.cancel')}</Button>
          <Button
            onClick={this.cancelTransitJob}
            variant='raised'
            color='primary'
            data-test='save-current-filter'
          >
            {t('transitJobsList.button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default translate()(CancelTransitDialog)
