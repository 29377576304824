import {Component} from 'react'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import {Content} from '../../commons'
import {SectionTitle} from '../common'
import {RenderDocumentSelector} from './RenderDocumentSelector'
import {DocumentSlideshow} from './DocumentSlideshow'
import MetaInfoInput from './MetaInfoInput'
import {Sidebar} from '@fcg/tars'

const DocumentContent = styled(Content)`
  height: 100%;
`
export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: #d8d8d8;
`

const findSelectedImage = (images, imageIndex) =>
  images.find((image, index) => index === imageIndex)

export class Documents extends Component {
  constructor(props) {
    super(props)

    const selectedImage = this.props.data ? this.props.data[0] : null

    this.state = {
      selected: 0,
      selectedImage,
      modalOpen: false,
      /**
       * Controls visibility of document metaInfo details
       * in @fcg/image-viewer/slideshow
       */
      showSlideshowDetails: true
    }
  }

  openGallery = () => {
    this.setState({
      modalOpen: true
    })
  }

  closeGallery = () => {
    this.setState({
      modalOpen: false
    })
  }

  onSelectImage = (newSelectedIndex) => {
    const selectedImage =
      this.props.data && this.props.data.length
        ? findSelectedImage(this.props.data, newSelectedIndex)
        : null

    this.setState({
      selected: newSelectedIndex,
      selectedImage
    })
  }

  onMetaInfoFieldChange = (field) => (event) => {
    const value = event.target.value

    if (this.state.selectedImage.metaInfo[field] === value) {
      return
    }

    this.props.onMetaInfoChange({
      change: {field, value},
      ticketEntityId: this.state.selectedImage.ticketEntityId,
      ticketId: this.state.selectedImage.ticketId
    })
  }

  onCommentChange = this.onMetaInfoFieldChange('comment')
  onDateChange = this.onMetaInfoFieldChange('date')
  onCategoryChange = this.onMetaInfoFieldChange('category')

  componentDidUpdate(prevProps) {
    const image = findSelectedImage(this.props.data, this.state.selected)
    const prevImage = findSelectedImage(prevProps.data, this.state.selected)

    if (!isEqual(image.metaInfo, prevImage.metaInfo)) {
      this.setState((state) => ({...state, selectedImage: image}))
    }
  }

  toggleSlideshowDetails = () => {
    this.setState({showSlideshowDetails: !this.state.showSlideshowDetails})
  }

  render() {
    if (this.props.data.length && this.state.modalOpen) {
      return (
        <DocumentSlideshow
          selected={this.state.selected}
          selectedImage={this.state.selectedImage}
          images={this.props.data}
          onClose={this.closeGallery}
          onSelect={this.onSelectImage}
          Sidebar={
            <Sidebar isOpen aboveContent={false}>
              <MetaInfoInput
                comment={this.state.selectedImage?.metaInfo?.comment}
                date={this.state.selectedImage?.metaInfo?.date}
                category={this.state.selectedImage?.metaInfo?.category}
                onDocumentDateChange={this.onDateChange}
                onDocumentCommentChange={this.onCommentChange}
                onDocumentCategoryChange={this.onCategoryChange}
              />
            </Sidebar>
          }
        />
      )
    }

    return (
      <DocumentContainer>
        <SectionTitle>
          {this.props.t('payslips.subtable.uploadedReceipts')}
        </SectionTitle>

        <DocumentContent>
          <RenderDocumentSelector
            images={this.props.data}
            isModalOpen={this.state.modalOpen}
            selected={this.state.selected}
            selectedImage={this.state.selectedImage}
            onOpenGallery={this.openGallery}
            onCloseGallery={this.closeGallery}
            onSelectImage={this.onSelectImage}
            t={this.props.t}
            showMetaInfo
            metaInfoInputs={
              <MetaInfoInput
                comment={this.state.selectedImage?.metaInfo?.comment}
                date={this.state.selectedImage?.metaInfo?.date}
                category={this.state.selectedImage?.metaInfo?.category}
                onDocumentDateChange={this.onDateChange}
                onDocumentCommentChange={this.onCommentChange}
                onDocumentCategoryChange={this.onCategoryChange}
              />
            }
          />
        </DocumentContent>
      </DocumentContainer>
    )
  }
}
