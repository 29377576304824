import {Typography} from '@material-ui/core'
import LightbulbOutline from '@material-ui/icons/LightbulbOutline'
import {translate} from 'react-i18next'

const EmptyState = ({t, message}) => (
  <div className='EmptyState centered'>
    <LightbulbOutline />

    <Typography type='subheading'>
      {message || t('global.message.nodata')}
    </Typography>
  </div>
)

export default translate()(EmptyState)
