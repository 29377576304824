import {Contacts, ContactDetail} from '../../../pages/CRM'
import {leadFields, leadSort} from './leads'
import {taskFields, responsible, taskSort} from './tasks'
import {commentFields, commentSort} from './comments'
import {changesFields} from './changes'
import omit from 'lodash/omit'
import * as filters from '../../../pages/CRM/common/Filters'

export default {
  path: 'contacts',
  label: 'menu.crm.contact',
  component: Contacts,
  sort: [{field: 'email', order: 'DESC'}],
  limit: 25,
  filters: [],
  page: 1,
  children: [
    {
      path: 'detail/:id',
      component: ContactDetail,
      label: 'Contact Detail',
      leadTable: {
        fields: omit(leadFields, 'contact'),
        sort: leadSort
      },
      taskTable: {
        fields: {...omit(taskFields, 'contact'), responsible},
        sort: taskSort
      },
      commentTable: {
        fields: commentFields,
        sort: commentSort
      },
      changesTable: {
        fields: changesFields
      }
    }
  ],
  fields: {
    userInfo: {
      type: 'composite',
      label: 'userInfo',
      key: 'userInfo',
      separator: ' ',
      link: {
        base: 'crm/contacts/detail',
        key: 'id'
      },
      children: [
        {
          type: 'string',
          key: 'firstname',
          label: 'contact.firstname.label'
        },
        {
          type: 'string',
          key: 'lastname',
          label: 'contact.lastname.label'
        }
      ]
    },
    email: {
      type: 'string',
      key: 'email',
      label: 'contact.email.label',
      columnName: 'email'
    },
    phone: {
      type: 'composite',
      key: 'phone',
      label: 'phone',
      layout: 'rows',
      separator: '\n',
      children: [
        {
          type: 'string',
          key: 'phone',
          label: 'contact.phone.label',
          filter: filters.PhoneValidation,
          columnName: 'phone'
        },
        {
          type: 'string',
          key: 'phone2',
          label: 'contact.phone2.label',
          columnName: 'phone'
        }
      ]
    },
    address: {
      type: 'string',
      shorten: 25,
      key: 'address',
      label: 'contact.address.label'
    },
    city: {
      type: 'string',
      label: 'contact.address.city',
      key: 'city',
      columnName: 'city',
      filterName: 'city'
    },
    postcode: {
      type: 'string',
      label: 'contact.address.postcode',
      key: 'postcode',
      columnName: 'postcode',
      filterName: 'postcode'
    },
    created: {
      type: 'composite',
      label: 'created',
      key: 'created',
      layout: 'rows',
      children: [
        {
          type: 'date',
          key: 'createdAt',
          label: 'global.createdAt.label',
          time: true,
          isSortable: true
        },
        {
          type: 'email',
          key: 'createdByUserEmail',
          label: 'global.createdBy.label',
          cut: true,
          isSortable: true
        }
      ]
    }
  },
  permissions: {
    entity: 'web.admin.ui.crm.contacts',
    types: ['READ']
  }
}
