import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import Calendar from '../../../../components/Pickers/Calendar'
import ValidatedDatePicker from '../../../../components/FormValidation/ValidatedDatePicker'
import {DatePicker} from '@fcg/form-builder'
import {STATUS_VALUES} from '../../../entities/user'
import {disabledCallback} from './utils'

export default {
  stepNum: 3,
  stepTitle: 'dealer.account',
  description: 'dealer.panel.description.account',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'email',
            label: 'dealer.page.field.login',
            margin: 'normal',
            type: 'text',
            validator: {required: true, type: 'string'},
            onChangeHandler: 'updateField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'password',
            autoComplete: 'new-password',
            label: 'userProfile.field.password',
            margin: 'normal',
            onChangeHandler: 'updateField',
            type: 'password',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'confirmPassword',
            autoComplete: 'new-password',
            label: 'userProfile.field.confirmPassword',
            margin: 'normal',
            onChangeHandler: 'updateField',
            confirmPasswordRef: 'password',
            type: 'password',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            withOther: true,
            filterable: true,
            multiple: false,
            id: 'status',
            label: 'dealer.page.field.status',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            filterable: true,
            multiple: false,
            id: 'onboardingStatus',
            label: 'dealer.page.field.onboardingStatus',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            requiredFn: (props) =>
              props.data.status === STATUS_VALUES.ONBOARDING,
            hiddenFn: (props) => props.data.status !== STATUS_VALUES.ONBOARDING,
            disabled: disabledCallback
          },
          {
            component: ValidatedSelectField,
            fullWidth: true,
            filterable: true,
            id: 'inactiveReason',
            label: 'dealer.page.field.inactiveReason',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            requiredFn: (props) => props.data.status === STATUS_VALUES.INACTIVE,
            hiddenFn: (props) => props.data.status !== STATUS_VALUES.INACTIVE,
            disabled: disabledCallback
          },
          {
            component: ValidatedSelectField,
            fullWidth: true,
            filterable: true,
            id: 'rejectedReason',
            label: 'dealer.page.field.rejectedReason',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            requiredFn: (props) => props.data.status === STATUS_VALUES.REJECTED,
            hiddenFn: (props) => props.data.status !== STATUS_VALUES.REJECTED,
            disabled: disabledCallback
          },
          {
            component: ValidatedSelectField,
            fullWidth: true,
            filterable: true,
            id: 'blockedReason',
            label: 'dealer.page.field.blockedReason',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            requiredFn: (props) => props.data.status === STATUS_VALUES.BLOCKED,
            hiddenFn: (props) => props.data.status !== STATUS_VALUES.BLOCKED,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedDatePicker,
            id: 'onboardingDate',
            label: 'dealer.page.field.onboardingDate',
            margin: 'normal',
            type: 'date',
            fullWidth: true,
            onChangeHandler: 'updateMetaField',
            config: {
              dateFormat: 'YYYY-MM-DD',
              CalendarComponent: Calendar
            },
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: DatePicker,
            id: 'offboardingDate',
            label: 'dealer.page.field.offboardingDate',
            margin: 'normal',
            type: 'date',
            fullWidth: true,
            onChangeHandler: 'updateMetaField',
            config: {
              dateFormat: 'YYYY-MM-DD',
              CalendarComponent: Calendar
            },
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'DateInput',
            id: 'termAccepted',
            disabled: true,
            label: 'dealer.page.field.termAccepted',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            config: {dateFormat: 'YYYY-MM-DD HH:mm'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'maxAmountBids',
            fullWidth: true,
            label: 'dealer.page.field.maxAmountBids',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'systemSetCreditLimit',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            isNumber: true,
            label: 'dealer.page.field.systemSetCreditLimit',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'systemSetDepositAmount',
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            isNumber: true,
            label: 'dealer.page.field.systemSetDepositAmount',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            multiline: true,
            id: 'authorizedPickupPersons',
            label: 'dealer.page.field.authorizedPickupPersons',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
