import styled from 'styled-components'
import {SectionTitle} from '@fcg/form-builder'
import {PureComponent} from 'react'

const Divider = styled(SectionTitle)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding-top: 15px;
  display: block;
  margin-bottom: -20px;
  font-weight: 500;
`

class FormDividerField extends PureComponent {
  render() {
    return <Divider {...this.props} label={this.props.t(this.props.label)} />
  }
}

FormDividerField.type = 'SectionTitle'

export default FormDividerField
