export const INFeatures = {
    availabilityDownload: true
}

export const IDFeatures = {
    availabilityDownload: true
}

export const AEFeatures = {
    availabilityDownload: true
}

export const ARFeatures = {
    availabilityDownload: true
}

export const DEFeatures = {
    availabilityDownload: true
}

export const CLFeatures = {
    availabilityDownload: true
}

export const COFeatures = {
    availabilityDownload: true
}

export const ECFeatures = {
    availabilityDownload: true
}

export const GLFeatures = {
    availabilityDownload: true
}

export const MXFeatures = {
    availabilityDownload: true
}

export const NGFeatures = {
    availabilityDownload: true
}

export const PEFeatures = {
    availabilityDownload: true
}

export const PKFeatures = {
    availabilityDownload: true
}

export const PLFeatures = {
    availabilityDownload: true
}
