import {Component, Fragment} from 'react'
import PanelTableBase from '../../../components/PanelTableBase'
import Panel from '../common/Panel'
import {Delete, Edit, Add} from '@material-ui/icons' 
import {withStyles, Button, Grid} from '@material-ui/core'
import {DeleteDialog} from '../common'
import UserPicker from '../../../components/Pickers/UserPicker'
import LoadingState from '../../../components/LoadingState'
import {translate} from 'react-i18next'
import { Column, Table, AutoSizer } from "react-virtualized";
import EmptyState from '../../../components/EmptyState'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  entity: null
}

class Users extends Component {
  state = {
    delete: {...deleteInitialState},
    users: {options: [], count: 0},
    page: 1,
    all: {},
    filteredUser:[]
  }

  componentDidMount() {
    const {selectedId} = this.props

    this.props.loadUsers(selectedId)
    this.props.setSelectedGroup(selectedId)
  }

  componentWillUnmount() {
    this.props.resetSelectedGroup()
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.users !== nextProps.users){
    this.setState({
        filteredUser: nextProps.users
      })
    }
    if (nextProps.selectedId !== this.props.selectedId) {
      this.props.setSelectedGroup(nextProps.selectedId)
      this.props.loadUsers(nextProps.selectedId)
    }
  }

  setUserOptions = ({options, count, page, all}) => {
    if (page === 1) {
      options = [...options]
    }

    this.setState({users: {options, count}, page, all})
  }

  onUserSelect = (id) => {
    this.props.addUserToRole(this.props.selectedId, id)
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(this.props.selectedId, id)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  searchList = (key) => {
    this.setState({
      filteredUser: this.props.users.filter((user)=> user.firstname.toLowerCase().includes(key.toLowerCase()) || user.lastname.toLowerCase().includes(key.toLowerCase()) || `${user.firstname} ${user.lastname}`.toLowerCase().includes(key.toLowerCase()) || user.email.toLowerCase().includes(key.toLowerCase()))
    })
  }

  

  renderPanelActions = () => {
    const editButtonCta = {
      backgroundColor: '#f1f1f1',
      padding: '10px 24px',
      marginRight: '10px',
      color:'#727373',
    }
    const addNewUserCta = { 
      backgroundColor: '#429387',
      color: 'white',
      padding: '10px 24px',
    }
    return (
    <div style={{display: 'flex', justifyContent: 'right'}}>
      <Button data-testid='editButtonCta'  style={editButtonCta} onClick={() => this.props.onGroupEdit(this.props.selectedId)}>
        {/* <Edit />  */}
        <Edit /> Edit Permissions
      </Button>
      <Button data-testid='addNewUserButtonCta' style={addNewUserCta} onClick={() => this.props.addNewUser(this.props.selectedId)}>
      <Add /> Add New User
      {/* <Edit /> */}
      </Button>
    </div>
  )}

  render() {
    const {
      classes,
      users,
      removeUserFromRole,
      selectedRoleName,
      loading,
      tableConfig,
      t
    } = this.props

    const groupSearchInput = {
      width: '100%',
      padding: '12px',
      border: '1px solid #d5d5d5',
      borderRadius: '4px',
    }

    const totalUserText = {
      marginTop: '6px',
      fontSize: '20px',
      color: 'rgb(63, 81, 181)',
      fontWeight: '500'
    }

    const firstLastNameBox = {
      display: 'inline-block',
      width: '100px',
      // whiteSpace: 'nowrap',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis',
    }
    
    return (
      <Panel
        header={`${selectedRoleName} ${t(
          'crmUserGroups.usersAndPermissions.label'
        )}`}
        actionButton={this.renderPanelActions()}
        pageName='userPermissionPage'
        t={() => {}}
      >
        {loading ? (
          <LoadingState />
        ) : (
          <Fragment>
            {/* <UserPicker
              {...this.state.users}
              // inputPlaceholder={t('crmUserGroups.searchUsers.label')}
              inputPlaceholder={'Search and add new users to the group...'}
              fullWidth
              filterable
              classes={classes}
              disableUnderline
              withStartAdornment
              withEndAdornment={false}
              debounce={true}
              selected={[]}
              onLoad={this.setUserOptions}
              onChange={this.onUserSelect}
              id='userPicker-select'
            /> */}
            <Grid container direction='row' style={{marginTop: '20px', marginBottom: '20px'}}>
                <Grid md={4} sm={4} xs={12} item>
                  <input data-testid='addedUserSearch' placeholder='Search added users...' type='text' style={groupSearchInput} onChange={(event)=> this.searchList(event.target.value)} />
                </Grid>
                <Grid md={8} sm={8} xs={12} item style={{textAlign: 'right'}}>
                  <p data-testid='totalTextWrapper'  style={totalUserText}>Total User : {this.state.filteredUser.length}</p>
                </Grid>
            </Grid>

              <Grid container direction='row' style={{padding: '16px', border: '1px solid #dedede', borderRadius:'4px',
              backgroundColor: '#fdfdff',
              color: '#868686',
              fontWeight: 500}}>
                <Grid md={5} sm={5} xs={5} item>
                  First Name Last Name
                </Grid>
                <Grid md={5} sm={5} xs={5} item >
                  Email
                </Grid>
                <Grid md={2} sm={2} xs={2} item style={{textAlign: 'right', fontWeight: '500'}}>
                  Action
                </Grid>
              </Grid>
              <style>
                  {`.testt:hover{
                    background-color: #efefef;
                    border-radius: '3px';
                  }`}
              </style>
            <div style={{ height: 600}}>
              {this.state.filteredUser.length === 0 
              ? 
              <div style={{display: 'flex', minHeight: '600px'}}><EmptyState /></div> 
              :
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    width={width}
                    height={height}
                    headerHeight={20}
                    rowHeight={50}
                    rowCount={this.state.filteredUser.length}
                    rowGetter={({ index }) => this.state.filteredUser[index]}
                    rowRenderer={({key, index,style, parent}) => {
                      let person = this.state.filteredUser[index]

                      return (
                        <div key={key}  style={style} className='testt'>
                          <Grid  container direction='row' style={{marginTop: '20px', marginBottom: '20px'}}>
                              <Grid md={5} sm={5} xs={5} item style={{paddingLeft: '16px'}}>
                                <span>{person.firstname} {person.lastname}</span>
                              </Grid>
                              <Grid md={5} sm={5} xs={5} item >
                              {person.email}
                              </Grid>
                              <Grid md={2} sm={2} xs={2} item style={{textAlign: 'right', cursor: 'pointer', paddingRight: '20px'}}>
                                <Delete onClick={() => {
                                      this.openDeleteDialog({
                                        entity: 'user',
                                        action: removeUserFromRole,
                                        id: person.id
                                      })
                                  }} />
                              </Grid>
                          </Grid>
                        </div>
                      )
                    }}
                  >
                  </Table>
                )}
              </AutoSizer>
              }
              
            </div>
            {/* <PanelTableBase
              noToolbar
              data={users}
              fields={Object.values(tableConfig)}
              hasFooter={false}
              singleSelectActions={[
                {
                  event: (item) => {
                    this.openDeleteDialog({
                      entity: 'user',
                      action: removeUserFromRole,
                      id: item.id
                    })
                  },
                  icon: <Delete />,
                  title: 'global.action.delete'
                }
              ]}
            /> */}
          </Fragment>
        )}

        <DeleteDialog {...this.state.delete} />
      </Panel>
    )
  }
}

export const GroupUsers = withStyles({
  selectInput: {
    background: 'rgb(244, 244, 244)',
    borderRadius: '4px',
    padding: '8px 16px'
  }
})(translate()(Users))
