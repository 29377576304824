const dealerDetails = require('./dealerManagement/ae')
const crmDetails = require('./CRM/ae')
const bulkuploadDetails = require('./bulkUpload/ae')
const carDataDetails = require('./carData/ae')
const locationDetails = require('./locationManagement/ae')
const payslipDetails = require('./payslips/ae')
const carOwnership = require('./carOwnership/ae')
const businessPartnersDetails = require('./businessPartnersDetails/ae')

module.exports = {
  url: 'wecashanycar.com',
  name: 'UAE',
  countryCode: 'AE',
  theme: 'uae',
  language: {
    selected: 'en-ae',
    options: [{value: 'en-ae', label: 'EN'}]
  },
  locales: ['ar-ae', 'en-ae'],
  currency: {code: 'AED', symbol: 'AED'},
  fallbackLocale: 'en',
  carDuplicatedCheckField: 'vin',
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [8, 9], code: 971},
  referralCodeType: 'input',
  googleKey:
    '543555054723-hj1p9vc3agdrrgmvr94h79j2617is0l0.apps.googleusercontent.com',
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  legacyHost: 'admin1',
  hiddenPages: [
    'carOwnershipTransfer',
    'resourceManager',
    'category',
    'wms',
    'tms',
    'bulkUploadV2',
    'attendanceManager',
    'documentManagement',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: [],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
