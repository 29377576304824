import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import {translate} from 'react-i18next'

export const DeleteDialog = (props) => {
  const {t, heading, entity, content, open} = props
  const confirmationMessage = t('dealerGroups.delete.alert').replace(
    '%groupName%',
    t(`entity.${entity}`)
  )

  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      onBackdropClick={props.handleClose}
      onEscapeKeyDown={props.handleClose}
    >
      <DialogTitle>
        {heading || t('global.action.delete.dialog.title')}
      </DialogTitle>

      <DialogContent>{content || confirmationMessage}</DialogContent>

      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color='primary'
          data-test='button-cancel-delete'
        >
          {t('button.cancel')}
        </Button>
        <Button
          onClick={props.handleSubmit}
          variant='raised'
          color='primary'
          data-test='button-confirm-delete'
        >
          {t('global.action.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default translate()(DeleteDialog)
