import {Component, createRef} from 'react'
import {renderLocationManagerToDom, unmount} from '@fcg/admin-location-manager'

const ID_NAME = 'location-manager'

export class LocationManager extends Component {
  componentDidMount() {
    renderLocationManagerToDom(ID_NAME, this.props.countryCode)
  }

  container = createRef()

  componentWillUnmount() {
    unmount(this.container.current)
  }

  render() {
    return <div id={ID_NAME} style={{width: '100%'}} ref={this.container} />
  }
}
