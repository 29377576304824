import {useState, useEffect, useContext} from 'react'

import {
  getAreasList,
  createOrUpdateArea,
  createOrUpdateLot,
  deleteAreaById,
  deleteLots,
  emptyLot
} from '../../../api/car/requests'

import {MainContext} from '../../../context/MainContext'
import {ValidationContext} from '../../../context/ValidationContext'
import {RESPONSE_MESSAGES} from '../../../hooks/useValidation/validationHelpers'

import AreaObject from '../../../types/AreaObject'
import LotObject from '../../../types/LotObject'

export default function useLotAreasActions() {
  const {
    selectedLocation,
    locationAreas,
    locationLots,
    deleteAreaFromLotAllocation,
    deleteLotFromLotAllocation,
    addOrUpdateAreaInLotAllocation,
    updateLotFromLotAllocation
  } = useContext(MainContext)
  const {setResponseBar} = useContext(ValidationContext)

  const [areas, setAreas] = useState<AreaObject[]>(locationAreas)
  const [lots, setLots] = useState<LotObject[]>(locationLots)

  const getAreas = () => {
    const req = {placeId: selectedLocation!.id!}
    getAreasList(req).then((areas: AreaObject[]) => {
      setAreas(areas)
    })
  }

  useEffect(() => {
    getAreas()
  }, [locationAreas, selectedLocation])

  const addOrUpdateArea = (
    area: any,
    isEditing: boolean,
    lotsForSelectedArea: LotObject[],
    callback: () => void
  ) => {
    const availableLots = area.availableLots
    const prevLotCount = area.prevLotCount

    let deleteLotIdList: string[] = []
    let showError = false

    lotsForSelectedArea.forEach((lot, index) => {
      if (
        lot.id &&
        index >= lotsForSelectedArea.length - (prevLotCount - availableLots)
      ) {
        if (lot.carId) showError = true
        else deleteLotIdList = [...deleteLotIdList, lot.id]
      }
    })

    if (showError) {
      return setResponseBar({
        type: 'error',
        message:
          'Cars must be unassigned from lots before lots can be decreased.'
      })
    }

    area.placeId = selectedLocation!.id!

    delete area.availableLots
    delete area.prevLotCount

    createOrUpdateArea(area).then(
      (newArea: AreaObject) => {
        addOrUpdateAreaInLotAllocation(newArea)

        // Create lots
        area.id = newArea.id
        area.availableLots = availableLots
        addOrUpdateLot(
          {...area, prevLotCount},
          isEditing,
          deleteLotIdList,
          () => {}
        )

        callback()
        setResponseBar({
          type: 'info',
          message: 'Area is updated'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const addOrUpdateLot = (
    area: any,
    isEditing: boolean,
    deleteLotIdList: string[],
    callback: () => void
  ) => {
    const {id, prevLotCount, availableLots} = area

    if (isEditing) {
      if (availableLots > prevLotCount) {
        createOrUpdateLot({
          areaId: id,
          number: availableLots - prevLotCount
        }).then(
          (lot: LotObject) => {
            callback()
            setResponseBar({
              type: 'info',
              message: `${availableLots - prevLotCount} lots created`
            })
          },
          (e: any) => {
            console.log(e)
            setResponseBar({
              type: 'error',
              message: e.toString()
            })
          }
        )
      } else if (availableLots < prevLotCount) {
        deleteLot(deleteLotIdList, () => {})
      }
    } else {
      createOrUpdateLot({areaId: id, number: availableLots}).then(
        (lot: LotObject) => {
          callback()
          setResponseBar({
            type: 'info',
            message: 'Lot is updated'
          })
        },
        (e: any) => {
          console.log(e)
          setResponseBar({
            type: 'error',
            message: e.toString()
          })
        }
      )
    }
  }

  const deleteArea = (id: string, callback: () => void) => {
    deleteAreaById(id).then(
      (isDeleted: boolean) => {
        if (isDeleted) {
          deleteAreaFromLotAllocation(id)
          callback()
          setResponseBar({
            type: 'info',
            message: 'Area is deleted'
          })
        } else {
          setResponseBar({
            type: 'error',
            message: RESPONSE_MESSAGES.error
          })
        }
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const deleteLot = (id: string[], callback: () => void) => {
    deleteLots(id).then(
      (isDeleted: boolean) => {
        if (isDeleted) {
          callback()
          setResponseBar({
            type: 'info',
            message: 'Lots deleted'
          })
        } else {
          setResponseBar({
            type: 'error',
            message: RESPONSE_MESSAGES.error
          })
        }
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const updateLot = (
    id: string,
    carId: string | null,
    callback: () => void
  ) => {
    emptyLot(id, carId).then(
      (lot: LotObject) => {
        if (lot.carId === null) {
          updateLotFromLotAllocation(lot)
          callback()
          setResponseBar({
            type: 'info',
            message: 'Lot is empty'
          })
        } else {
          setResponseBar({
            type: 'error',
            message: RESPONSE_MESSAGES.error
          })
        }
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  return {areas, addOrUpdateArea, deleteArea, deleteLot, updateLot}
}
