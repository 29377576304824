import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../../config/country'

const selectorPath = (state) => state.contact

export const contactFields = (state) => {
  const {list} = selectorPath(state)

  if (list.fields) {
    return Object.values(list.fields)
  }
}

export const childrenReducer = (field, fields, propName) => {
  if (field && fields && fields.includes(field[propName])) {
    return -1
  }

  if (field.type === 'composite' && field.children && field.children.length) {
    field = {
      ...field,
      children: field.children.reduce((reducedChildren, child) => {
        const reduced = childrenReducer(child, fields, propName)

        return reduced === -1 ? reducedChildren : [...reducedChildren, reduced]
      }, [])
    }
  }

  return field
}

export const getContactSelector = (prop, type) => {
  return createSelector(contactFields, (fields) => {
    const {crmDetails: config} = getCountryConfig()
    if (!config[prop] && fields) {
      return fields
    }

    if (fields && fields.length) {
      return fields
        .map((field) => childrenReducer(field, config[prop], type))
        .filter((field) => !!field && field !== -1)
    }

    return []
  })
}

export const getContactFields = getContactSelector(
  'hiddenColumns',
  'columnName'
)
export const getContactFilters = getContactSelector(
  'hiddenFilters',
  'filterName'
)
