import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {withRouter} from 'react-router'
import {
  checkUserManagerPermission,
  checkUserPermissions
} from '../../store/auth/selectors'
import {UserConsumer} from '../providers/withAccessManagement'
import Empty from '../Empty'
import {getHiddenPages, getCountryCode} from './../../store/config/selectors'

const GuardBase = ({
  path,
  children,
  t,
  hasPermissions,
  hasUserManagerPermission,
  history,
  permissions,
  user,
  pageKey,
  parentKey,
  hiddenPages = [],
  countryCode = ''
}) => {
  const routeBack = () => history.goBack()
  const userHiddenFields =
    typeof user !== 'undefined' && user.menuItems
      ? user.menuItems.hiddenFields || []
      : []
  const isParentPageInHiddenFields =
    parentKey && userHiddenFields.includes(parentKey)
  const isPageInHiddenFields = userHiddenFields.includes(pageKey)
  const excludeB2bBulkUploadPageForIN =
    countryCode.toUpperCase() === 'IN' && // only for IN
    (parentKey === 'bulkUpload' || pageKey === 'bulkUpload') && // current page is 'bulkUpload'
    hiddenPages.includes('bulkUpload') // and config.hiddenPage has 'bulkUpload'

  if (
    isParentPageInHiddenFields ||
    isPageInHiddenFields ||
    (permissions &&
      !hasPermissions({
        entity: permissions.entity,
        types: permissions.types || ['READ']
      })) ||
    (path === 'crmusergroups' && !hasUserManagerPermission) ||
    excludeB2bBulkUploadPageForIN
  ) {
    return (
      <Empty
        title={t('guard.page.no.permission')}
        subtitle={t('guard.page.permission.contact.administrator')}
        onClick={routeBack}
        buttonText={t('button.go.back')}
      />
    )
  }

  return children
}

const mapStateToProps = (state) => ({
  hasPermissions: (permissions) => checkUserPermissions(state, permissions),
  hasUserManagerPermission: checkUserManagerPermission(state),
  hiddenPages: getHiddenPages(state),
  countryCode: getCountryCode(state)
})

const Guard = connect(mapStateToProps)(translate()(withRouter(GuardBase)))
export default (props) => UserConsumer(Guard, props)
