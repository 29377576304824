import {values} from 'lodash'

export const NOT_APPLICABLE = 'N/A'

export const DURATION_KIND = {
  MINUTES: {
    value: 'minutes',
    label: 'time.minutes'
  },
  HOURS: {
    value: 'hours',
    label: 'time.hours'
  },
  DAYS: {
    value: 'days',
    label: 'time.days'
  }
}

const autoconfirmEnabled = ['ID', 'AR', 'CL', 'EC', 'PE', 'MX', 'PL']

export const AUCTION_TYPE = {
  LONGTERM: {
    value: 'longterm',
    label: 'auctionSchedules.form.select.longterm'
  },
  YARDSALE: {
    value: 'yardsale',
    label: 'auctionSchedules.form.select.yardsale'
  },
  NEGOTIATION: {
    value: 'negotiation',
    label: 'auctionSchedules.form.select.negotiation'
  },
  MARKETPLACE: {
    value: 'marketplace',
    label: 'auctionSchedules.form.select.marketplace'
  },
  LEAD: {
    value: 'lead',
    label: 'auctionSchedules.form.select.lead'
  },
  B2B: {
    value: 'b2b',
    label: 'auctionSchedules.form.select.b2b'
  },
  PAUSE: {
    value: 'pause',
    label: 'auctionSchedules.form.select.pause'
  },
  BLIND: {
    value: 'blind',
    label: 'auctionSchedules.form.select.blind'
  }
}

// this is correct value
export const BID_TYPE = {
  REGULAR: {
    value: 'REGULAR',
    label: 'auctionSchedules.form.bidType.regular'
  },
  BUY_NOW: {
    value: 'BUY_NOW',
    label: 'auctionSchedules.form.bidType.buyNow'
  },
  RESERVE: {
    value: 'RESERVE',
    label: 'auctionSchedules.form.bidType.reserve'
  },
  AUTO_BID: {
    value: 'AUTO_BID',
    label: 'auctionSchedules.form.bidType.autoBid'
  },
  MAX_AUTO_BID: {
    value: 'MAX_AUTO_BID',
    label: 'auctionSchedules.form.bidType.maxAutoBid'
  },
  PRE_AUTO_BID: {
    value: 'PRE_AUTO_BID',
    label: 'auctionSchedules.form.bidType.preAutoBid'
  },
  OFFER: {
    value: 'OFFER',
    label: 'auctionSchedules.form.bidType.offer'
  },
  REJECT: {
    value: 'REJECT',
    label: 'auctionSchedules.form.bidType.reject'
  },
  BLIND_BID: {
    value: 'BLIND_BID',
    label: 'auctionSchedules.form.select.blindBid'
  }
}

export const ON_AUCTION_END = {
  AUTORESERVE: {
    value: 'AUTORESERVE',
    label: 'auctionSchedules.form.onAuctionEnd.autoreserve'
  },
  AUTOCONFIRM: {
    value: 'AUTOCONFIRM',
    label: 'auctionSchedules.form.onAuctionEnd.autoconfirm'
  },
  NONE: {
    value: 'NONE',
    label: 'form.select.none'
  }
}

const getOnAuctionEndDefaultValue = (country) =>
  autoconfirmEnabled.includes(country)
    ? ON_AUCTION_END.AUTOCONFIRM.value
    : ON_AUCTION_END.AUTORESERVE.value

export const optionStatus = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  HIDDEN: 'hidden'
}

export const formFields = {
  NAME: {
    name: 'NAME',
    key: 'name',
    required: true,
    validate: (value) => value && values !== '',
    errorMessage: 'form.message.validation.required'
  },
  AUCTION_TYPE: {
    name: 'AUCTION_TYPE',
    key: 'auctionType',
    required: true,
    validate: (value) => !!value,
    errorMessage: 'form.message.validation.required'
  },
  DURATION: {
    name: 'DURATION',
    key: 'duration',
    required: true,
    validate: (value) => Number(value) && Number.isInteger(Number(value)),
    errorMessage: 'form.message.validation.required'
  },
  AUCTION_PREVIEW_OFFSET: {
    name: 'AUCTION_PREVIEW_OFFSET',
    key: 'auctionPreviewOffset',
    required: false,
    validate: (value) => Number(value) && Number.isInteger(Number(value))
  },
  DURATION_KIND: {
    name: 'DURATION_KIND',
    key: 'durationKind',
    required: true,
    selected: DURATION_KIND.MINUTES.value,
    validate: (value) => !!value,
    errorMessage: 'form.message.validation.required'
  },
  ON_AUCTION_END_GROUP: {
    name: 'ON_AUCTION_END_GROUP',
    key: 'onAuctionEnd',
    required: true,
    selected: (country) => getOnAuctionEndDefaultValue(country),
    validate: (value) => !!value
  },
  BID_TYPE: {
    name: 'BID_TYPE',
    key: 'bidTypes',
    required: false
  }
}

export const auctionTypeConfig = {
  [AUCTION_TYPE.LONGTERM.value]: {
    availableBidTypes: [
      BID_TYPE.REGULAR.value,
      BID_TYPE.BUY_NOW.value,
      BID_TYPE.AUTO_BID.value,
      BID_TYPE.PRE_AUTO_BID.value
    ],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.ENABLED
    },
    bidTypeStatus: {
      [BID_TYPE.REGULAR.value]: true,
      [BID_TYPE.BUY_NOW.value]: true,
      [BID_TYPE.AUTO_BID.value]: true,
      [BID_TYPE.PRE_AUTO_BID.value]: true
    }
  },
  [AUCTION_TYPE.YARDSALE.value]: {
    availableBidTypes: [
      BID_TYPE.BUY_NOW.value,
      BID_TYPE.RESERVE.value,
      BID_TYPE.OFFER.value
    ],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.ENABLED
    }
  },
  [AUCTION_TYPE.MARKETPLACE.value]: {
    availableBidTypes: NOT_APPLICABLE,
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.HIDDEN,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.HIDDEN
    }
  },
  [AUCTION_TYPE.LEAD.value]: {
    availableBidTypes: [BID_TYPE.RESERVE.value],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.DISABLED
    },
    defaultValues: {
      onAuctionEnd: ON_AUCTION_END.AUTORESERVE.value
    }
  },
  [AUCTION_TYPE.PAUSE.value]: {
    availableBidTypes: NOT_APPLICABLE,
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.HIDDEN,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.HIDDEN
    }
  },
  [AUCTION_TYPE.NEGOTIATION.value]: {
    availableBidTypes: [
      BID_TYPE.BUY_NOW.value,
      BID_TYPE.REJECT.value,
      BID_TYPE.REGULAR.value
    ],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.ENABLED
    }
  },
  [AUCTION_TYPE.B2B.value]: {
    availableBidTypes: [
      BID_TYPE.REGULAR.value,
      BID_TYPE.BUY_NOW.value,
      BID_TYPE.AUTO_BID.value,
      BID_TYPE.PRE_AUTO_BID.value
    ],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.ENABLED
    }
  },
  [AUCTION_TYPE.BLIND.value]: {
    availableBidTypes: [BID_TYPE.BLIND_BID.value],
    onAuctionEnd: {
      [ON_AUCTION_END.AUTORESERVE.value]: optionStatus.ENABLED,
      [ON_AUCTION_END.AUTOCONFIRM.value]: optionStatus.ENABLED
    }
  }
}
