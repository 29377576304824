import {PureComponent} from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {Sidebar} from '@fcg/tars'
import {
  isRowLoading,
  getRowError,
  SubtableContainer,
  SubtableContentLeft,
  SubtableContentRight,
  ButtonWrapper
} from '../../../components/Subtable/styles'
import {DocumentPreview} from '../../../components/DocumentPreview/DocumentPreview'
import FileInfo from '../components/FileInfo'
import {
  SubtableLoading,
  ErrorContent,
  NoDocumentMessage
} from '../../../components/Subtable/components'
import {DocumentList} from './DocumentList'
import {COT_TILE_SELECT_BORDER} from '../../../components/DocumentPreview/utils'
import {
  TICKET_ENTITY_STATUS,
  TICKET_STATUS
} from '../../../config/entities/ticket'
import {
  InProgressBanner,
  ApprovedBanner,
  RejectedBanner
} from '../../../components/DocumentPreview/Banner/Banner'
import {BANNER_POSITIONS} from '../../../components/DocumentPreview/Banner/util'

const DocumentActionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  margin: 20px 0px 20px 0px;
`
const AddDocument = styled.span`
  color: #0065e3;
  cursor: pointer;
`

const customStyles = {
  thumbnailList: {
    selectedTileColor: COT_TILE_SELECT_BORDER
  },
  slideshowStyles: {
    galleryStyles: {
      selectedColor: COT_TILE_SELECT_BORDER
    }
  }
}
const availableAdornements = [
  {
    test: (image) => image.fileStatus === TICKET_ENTITY_STATUS.OPEN,
    adornement: InProgressBanner,
    config: {position: BANNER_POSITIONS.TOP_RIGHT}
  },
  {
    test: (image) => image.fileStatus === TICKET_ENTITY_STATUS.APPROVED,
    adornement: ApprovedBanner,
    config: {position: BANNER_POSITIONS.TOP_RIGHT}
  },
  {
    test: (image) => image.fileStatus === TICKET_ENTITY_STATUS.REJECTED,
    adornement: RejectedBanner,
    config: {position: BANNER_POSITIONS.TOP_RIGHT}
  }
]

const isTicketOpen = ({status}) =>
  ![TICKET_STATUS.APPROVED, TICKET_STATUS.REJECTED].includes(status)

const shouldShowApproveButton = ({documentList, data, canApproveTicket}) =>
  canApproveTicket &&
  documentList.length &&
  data.allDocumentsReviewed &&
  data.allApprovedWithCategories &&
  isTicketOpen(data)

const shouldShowUploadButton = ({data, canAddDocuments}) =>
  canAddDocuments && isTicketOpen(data)

class TicketContentBase extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedImageIndex: 0
    }
  }

  setIndex = (index) => this.setState({selectedImageIndex: index})

  onOpenDocumentDialog = () => this.props.showDocumentUpload(this.props.data)

  render() {
    if (isRowLoading(this.props)) {
      return <SubtableLoading />
    }

    const rowError = getRowError(this.props)

    if (rowError) {
      return <ErrorContent {...this.props} error={rowError} />
    }

    if (
      !this.props.documentList ||
      (this.props.documentList && !this.props.documentList)
    ) {
      return <NoDocumentMessage t={this.props.t} />
    }

    const tableProps = omit(this.props, 'parentProps')

    const selectedImage = this.props.documentList[this.state.selectedImageIndex]

    return (
      <SubtableContainer>
        <SubtableContentLeft>
          <DocumentPreview
            data={this.props.documentList}
            t={this.props.t}
            selectedImageIndex={this.state.selectedImageIndex}
            selectedImage={selectedImage}
            onSelectImage={this.setIndex}
            HeaderChild={FileInfo}
            showSelectedImageInList
            customStyles={customStyles}
            useAdornement
            availableAdornements={availableAdornements}
            Sidebar={
              <Sidebar isOpen aboveContent={false}>
                <div>
                  <label>{this.props.t('documentList.comment')}</label>
                  <p>{selectedImage?.comment}</p>
                </div>
              </Sidebar>
            }
          />
        </SubtableContentLeft>
        <SubtableContentRight justify='flex-start'>
          <DocumentList
            {...tableProps}
            selectedImageIndex={this.state.selectedImageIndex}
            onSelectImage={this.setIndex}
          />

          {shouldShowUploadButton(this.props) ? (
            <DocumentActionRow>
              <AddDocument
                onClick={this.onOpenDocumentDialog}
                data-test='add-new-document-button'
              >
                {this.props.t('carOwnershipTransfer.subtable.addDocument')}
              </AddDocument>
            </DocumentActionRow>
          ) : null}

          {shouldShowApproveButton(this.props) ? (
            <ButtonWrapper justify='flex-end' grow align='flex-end'>
              <Button
                color='primary'
                variant='raised'
                data-test='button-approve-ticket'
                onClick={() => this.props.onDocumentApprove(this.props.data)}
              >
                {this.props.t('carOwnershipTransfer.subtable.approve')}
              </Button>
            </ButtonWrapper>
          ) : null}
        </SubtableContentRight>
      </SubtableContainer>
    )
  }
}

TicketContentBase.propTypes = {
  t: PropTypes.func,
  onDocumentApprove: PropTypes.func
}

TicketContentBase.defaultProps = {
  t: () => {},
  onDocumentApprove: () => {}
}

export const TicketContent = TicketContentBase
