import styled from 'styled-components'
import {EmptyTablePlaceholder} from '../../../components/Subtable/components'
import {SectionTitle} from '../../../components/Subtable/styles'
import PageTableBase from '../../../components/PageTableBase'
import {subtableFields} from '../../../config/pages/carOwnership/listConfig'
import TableRow from '@material-ui/core/TableRow'
import Select from '../../../components/Select/Select'
import TextField from '@material-ui/core/TextField'
import {rowStyleFactory} from '@fcg/tars'
import {TICKET_STATUS} from '../../../config/entities/ticket'

const noop = () => {}

const editableCellOptions = {
  editableCellTypes: {
    select: Select
  },
  defaultEditableField: TextField
}

const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px 12px 12px;
  margin: 0px 0px 10px 0px;
  background-color: white;
  width: 100%;
  height: ${(props) => (props.isEmpty ? 300 : 400)}px;
  position: relative;
`
const grayColor = 'rgba(63, 81, 181, 0.15)'
export const CustomRow = styled(TableRow)`
  && {
    background-color: ${grayColor};

    &:hover {
      background-color: #00ff00;
    }
  }
`
const rowStyleConfig = [
  {
    test: (props) => {
      const selectedImageIndex = props.rowProps.selectedImageIndex

      return selectedImageIndex === props.rowIndex
    },
    rowStyle: CustomRow
  }
]
const customTableRow = rowStyleFactory(rowStyleConfig)
const canEditCell = ({data, allowedToChangeDocumentState}) =>
  allowedToChangeDocumentState &&
  ![TICKET_STATUS.APPROVED, TICKET_STATUS.REJECTED].includes(data.status)
const getEditableCellOptions = (props) => ({
  ...editableCellOptions,
  canEditCell: canEditCell(props),
  selectOptions: (cellProps) =>
    cellProps.field.key === 'category'
      ? props.categoryOptions
      : cellProps.field.options
})

export const DocumentList = (props) => {
  const onCellChange = ({row, value, field, type}) => {
    if (
      field.key === 'status' &&
      typeof props.onDocumentStatusChange === 'function'
    ) {
      if (row.fileStatus === value) {
        return
      }

      return props.onDocumentStatusChange({row, value, field, type})
    }

    if (
      field.key === 'category' &&
      typeof props.onDocumentCategoryChange === 'function'
    ) {
      return props.onDocumentCategoryChange({
        ...row,
        category: value,
        ticketId: props.data.id
      })
    }
  }

  return (
    <DocumentListContainer isEmpty={props.documentList.length === 0}>
      <SectionTitle>
        {props.t('carOwnershipTransfer.subtable.title')}
      </SectionTitle>
      <PageTableBase
        fields={subtableFields}
        rowStyle={customTableRow}
        pageConfig={{}}
        data={props.documentList}
        fetchData={noop}
        multisort={false}
        visibility={false}
        selectable={false}
        withVisibility={false}
        noToolbar
        hasFooter={false}
        tableHeight='sm'
        fixedHeight={props.fixedHeight}
        openLinkInNewTab
        onCellChange={onCellChange}
        selectedImageIndex={props.selectedImageIndex}
        emptyTablePlaceholder={
          <EmptyTablePlaceholder
            t={props.t}
            colspan={subtableFields.length}
            show={props.documentList}
            showIcon={false}
          />
        }
        emptyTableSize='sm'
        editableCellOptions={getEditableCellOptions(props)}
      />
    </DocumentListContainer>
  )
}
