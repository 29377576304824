export const shortenString = (string = '', length = 10) => {
  const _string = string || ''

  return _string.length > length ? `${_string.slice(0, length)}...` : _string
}

// TODO: don't understand it as of now
export const toCamelCase = (string) => {
  /* istanbul ignore next */
  return string.split(' ').map(capitalize)
}

export const capitalize = (word) =>
  (word || '').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())

export const excludeDomain = (email) => {
  const _email = email || ''

  return _email.indexOf('@') > -1 ? _email.split('@')[0] : _email
}

export const wrapInParentheses = (string) => {
  return string !== undefined && string !== null && string !== ''
    ? `(${string})`
    : ''
}
