import CarDataPickerField from '../../../../components/CarDataPicker'
import {getCountryConfig} from '../../../../../config/country'
import {
  RegexField,
  ValidatedSelectField,
  ValidatedTextField,
  ReferralFieldValidated
} from '../../../../components/FormValidation'
import UserPickerField from '../../../../components/Pickers/UserPicker'

export default {
  form: {
    children: [
      {
        component: CarDataPickerField
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'VINnumber',
        children: [
          {
            component: RegexField,
            fullWidth: true,
            regex: /^.{1,17}$/,
            id: 'VINnumber',
            label: 'lead.VINnumber.label',
            margin: 'normal',
            name: 'VINnumber',
            onChangeHandler: 'handleTextInput',
            maxLength: 17,
            type: 'text',
            validator: {type: 'string'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'plateBlock',
        children: [
          {
            component: ValidatedTextField,
            required: getCountryConfig().countryCode === 'TR',
            fullWidth: true,
            id: 'plate',
            label: 'global.plate.label',
            margin: 'normal',
            name: 'plate',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      (props) => {
        if (
          !props.creation &&
          props.crmDetails &&
          props.crmDetails.hideLeadSourceForUpdate
        ) {
          return {}
        }

        return {
          component: 'Grid',
          item: true,
          xs: 12,
          sm: 4,
          id: 'leadSourceBlock',
          children: [
            {
              component: ValidatedSelectField,
              fullWidth: true,
              id: 'leadSource',
              label: 'lead.leadSource.label',
              margin: 'normal',
              name: 'leadSource',
              onChangeHandler: 'handleSelectChange',
              validator: {required: true, type: 'string'}
            }
          ]
        }
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'referralCodeBlock',
        children: [
          {
            component: ReferralFieldValidated,
            fullWidth: true,
            id: 'referralCode',
            label: 'lead.referralCode.label',
            margin: 'normal',
            name: 'referralCode',
            onChangeHandler: 'handleSelectChange',
            placeholder: 'lead.placeholder.referralCode',
            validator: {required: true}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 4,
        sm: 4,
        id: 'priceBlock',
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'price',
            label: 'lead.price.label',
            margin: 'normal',
            name: 'price',
            onChangeHandler: 'handleSelectChange',
            asCurrency: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 4,
        sm: 4,
        id: 'minPriceBlock',
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'minPrice',
            label: 'lead.minprice.label',
            margin: 'normal',
            name: 'minPrice',
            onChangeHandler: 'handleSelectChange',
            asCurrency: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 4,
        sm: 4,
        id: 'maxPriceBlock',
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'maxPrice',
            label: 'lead.maxprice.label',
            margin: 'normal',
            name: 'maxPrice',
            onChangeHandler: 'handleSelectChange',
            asCurrency: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 4,
        sm: 4,
        id: 'selfInspectionPriceBlock',
        children: [
          {
            component: 'NumberInput',
            fullWidth: true,
            id: 'selfInspectionPrice',
            label: 'lead.selfInspectionPrice.label',
            margin: 'normal',
            name: 'selfInspectionPrice',
            onChangeHandler: 'handleSelectChange',
            asCurrency: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'serviceTypeBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'serviceType',
            label: 'lead.serviceType.label',
            margin: 'normal',
            name: 'serviceType',
            onChangeHandler: 'handleSelectChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'statusBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'leadStatus',
            label: 'lead.status.label',
            margin: 'normal',
            name: 'leadStatus',
            options: 'leadStatus',
            required: (props) => props,
            onChangeHandler: 'handleSelectChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'discardedReasonBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'discardedReason',
            label: 'lead.discardedReason.label',
            margin: 'normal',
            name: 'discardedReason',
            onChangeHandler: 'handleSelectChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'externalLeadIdBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'externalLeadId',
            label: 'lead.externalLeadId.label',
            margin: 'normal',
            name: 'externalLeadId',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'responsibleBlock',
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            id: 'responsible',
            label: 'lead.responsible.label',
            margin: 'normal',
            name: 'responsible',
            withTranslate: false,
            userClass: 'INTERNAL',
            onChangeHandler: 'handleSelectChange',
            onLoad: 'setUserOptions',
            onSelect: 'handleResponsible',
            placeholder: (props) => props.data.responsibleName,
            debounce: true,
            limit: 25
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'inspectionTypeBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'inspectionType',
            label: 'lead.inspectionType.label',
            margin: 'normal',
            name: 'inspectionType',
            onChangeHandler: 'handleSelectChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'adId',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'adId',
            label: 'global.adId.label',
            margin: 'normal',
            name: 'adId',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'ownerName',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'ownerName',
            label: 'global.ownerName.label',
            margin: 'normal',
            name: 'ownerName',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'ownerPhone',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'ownerPhone',
            label: 'global.ownerPhone.label',
            margin: 'normal',
            name: 'ownerPhone',
            onChangeHandler: 'handleTextInput'
          }
        ]
      }
    ]
  }
}
