import axios from 'axios'
import {getToken} from '../../utils/authToken'
import {InvalidTokenError} from '../../utils/errors'
import {getCountryConfig} from '../../../../app/config/country'

const countryCode = getCountryConfig().siteCode
const apiDomain = ['test', 'staging', 'production'].includes(environment)
  ? getCountryConfig().apiDomain
  : 'api.olxautos.com'

function isValidToken() {
  const token = getToken()
  if (token === false) {
    throw new InvalidTokenError()
  }

  return token
}

async function makeApiCall(method, baseUrl, responseType, formData) {
  try {
    const token = isValidToken()
    const config = {
      method: method,
      url: baseUrl,
      headers: {
        'content-type': 'application/json',
        'X-PANAMERA-CLIENT-ID': `category-admin-panel-${countryCode}`,
        Authorization: `Bearer ${token}`
      },
      timeout: 300000,
      responseType: responseType,
      data: formData
    }

    const response = await axios(config)

    return response
  } catch (err) {
    if (err.response) {
      const errorData = err.response.data
      if (errorData instanceof Blob) {
        const errText = await errorData.text()

        return JSON.parse(`${errText}`)
      }

      return errorData
    }

    return 'error'
  }
}

export function getCategoryList() {
  const method = 'get'
  const url = `https://${apiDomain}/groot/api/v1/categories/admin-panel/list/cars?sitecode=${countryCode}`
  // const url = `https://groot.internal.eks.stg.ap-southeast-1.horizontals.olx.org/groot/api/v1/categories/admin-panel/list/cars?sitecode=${countryCode}`

  return makeApiCall(method, url)
}

export function downloadCategoryListItem(fileName) {
  const method = 'get'
  //   const url = `https://groot.internal.eks.stg.ap-southeast-1.horizontals.olx.org/groot/api/v1/categories/admin-panel/download/cars?sitecode=${countryCode}&fileName=${fileName}`
  const url = `https://${apiDomain}/groot/api/v1/categories/admin-panel/download/cars?sitecode=${countryCode}&fileName=${fileName}`

  return makeApiCall(method, url, 'blob')
}

export function downloadCategoryFile() {
  const method = 'get'
  //   const url = `https://groot.internal.eks.stg.ap-southeast-1.horizontals.olx.org/groot/api/v1/categories/admin-panel/download/cars?sitecode=${countryCode}`
  // const url = `https://groot.internal.eks.stg.ap-southeast-1.horizontals.olx.org/groot/api/v1/categories/admin-panel/download/cars?sitecode=${countryCode}`
  const url = `https://${apiDomain}/groot/api/v1/categories/admin-panel/download/cars?sitecode=${countryCode}`

  return makeApiCall(method, url, 'blob')
}

export function uploadCategoryFile(file) {
  const method = 'post'
  // const url = `https://groot.internal.eks.stg.ap-southeast-1.horizontals.olx.org/groot/api/v1/categories/admin-panel/upload/cars?sitecode=${countryCode}`
  const url = `/api/groot/upload/v1/categories/admin-panel/upload/cars?sitecode=${countryCode}`
  const formData = new FormData()
  formData.append('file', file)

  return makeApiCall(method, url, null, formData)
}
