import {omit} from 'ramda'
import {TicketContent} from './subtable/TicketContent'

export const ExpandableComponent = ({
  expandableComponentProps,
  rowData,
  rowFetching,
  rowFetchingErrors,
  t,
  ...props
}) => {
  let documentList = []
  let data = null

  if (rowData) {
    documentList = rowData.documentList ? rowData.documentList : []

    data = omit(['documentList'], rowData)
  }

  return (
    <TicketContent
      parentProps={props}
      t={t}
      rowFetching={rowFetching}
      rowFetchingErrors={rowFetchingErrors}
      id={data.id}
      {...expandableComponentProps}
      documentList={documentList}
      data={data}
    />
  )
}
