import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import TextField from '@material-ui/core/TextField'
import {TextfieldWithoutPlaceholder} from '@fcg/tars'
import {formatMetaInfoChange} from '../../utils/dataTransforms'
import StatefulInput from '../../components/StatefulInput/StatefulInput'

export const mapId = ({id}) => id

export const editableCellTypes = {
  string: TextfieldWithoutPlaceholder,
  number: TextfieldWithoutPlaceholder,
  currency: TextfieldWithoutPlaceholder
}
export const editableCellOptions = {
  editableCellTypes,
  defaultEditableField: TextField,
  canEditCell: (props) => !(props.field.key === 'amount' && props.data === 0)
}

export const TableContainer = styled.div`
  margin-top: 24px;
  min-height: 300px;
  position: relative;
`

export const isRowSelectable = (row) => row.amount > 0

export const getNewDocumentState = (props, state) =>
  props.documentIds.reduce((acc, id) => {
    if (state.documentMetaInfo[id]) {
      return acc
    }

    return {
      ...acc,
      documentMetaInfo: {
        ...acc.documentMetaInfo,
        [id]: {comment: '', date: null}
      }
    }
  }, state)

export const getCreateReceiptCustomDocumentColumns = ({
  onCommentBlur,
  onDateBlur
}) => (state) => [
  {
    key: 'metaInfoComment',
    header: 'documentList.comment',
    cell: StatefulInput,
    withWrapper: true,
    wrapperProps: {
      className: 'row__metaInfoComment'
    },
    props: {
      nativeInput: true,
      inputType: 'textarea',
      placeholder: 'payslips.document.placeholder.comment',
      onBlur: onCommentBlur,
      type: 'text'
    },
    defaultData: '',
    getInitialData: (documentId) =>
      formatMetaInfoChange({
        field: 'comment',
        value: state[documentId]?.comment
      })
  },
  {
    key: 'metaInfoDate',
    header: 'documentList.date',
    cell: StatefulInput,
    withWrapper: true,
    wrapperProps: {
      className: 'row__metaInfoDate'
    },
    props: {
      nativeInput: true,
      onBlur: onDateBlur,
      type: 'date'
    },
    defaultData: null,
    getInitialData: (documentId) =>
      formatMetaInfoChange({field: 'date', value: state[documentId]?.date})
  }
]

export const getDocumentsWihtoutDates = (documentMetaInfo) =>
  Object.keys(documentMetaInfo).reduce((acc, documentId) => {
    if (
      isNaN(documentMetaInfo[documentId].date) ||
      documentMetaInfo[documentId].date === null
    ) {
      return {...acc, [documentId]: true}
    }

    return acc
  }, {})

export const getNewStateOnInputBlur = (oldState, documentId, field, value) => {
  let newState = {
    documentMetaInfo: {
      ...oldState.documentMetaInfo,
      [documentId]: {
        ...oldState.documentMetaInfo[documentId],
        [field]: value
      }
    }
  }
  const documentsWithErrors = getDocumentsWihtoutDates(
    newState.documentMetaInfo
  )

  if (!isEqual(oldState.documentsWithErrors, documentsWithErrors)) {
    newState = {
      ...newState,
      documentsWithErrors: {...documentsWithErrors}
    }
  }

  return newState
}

export const removeDeletedDocuments = ({documentIds, documentMetaInfo}) =>
  Object.keys(documentMetaInfo).reduce(
    (acc, id) =>
      documentIds.includes(id) ? acc : {...acc, [id]: documentMetaInfo[id]},
    {}
  )
