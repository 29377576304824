import * as types from './types'
import * as globalActions from '../../global/actions'
import * as ContactApi from '../../../api/contact/requests'
import {formatParams} from '../../utils'
import {getHistory} from '../../../utils/history'
import {getCountryCode} from '../../config/selectors'
import {
  normalizePhoneParams,
  filterPhone,
  getVisibleKeys
} from '../../../pages/CRM/helpers'
import {getCountryConfig} from '../../../../config/country'
import {contactFields, childrenReducer} from './selectors'
export const getContactList = (
  {limit, filters, sort, page} = {},
  flag = 'none'
) => async (dispatch, getState) => {
  dispatch(setFetching())
  const country = getCountryCode(getState())
  const currentState = getState().contact.list
  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page

  const contactData = {...formatParams(filters), country}
  const {params, error} = normalizePhoneParams(
    {limit, contactData, sort, page},
    getState(),
    ['contactData', 'phone']
  )
  const {filters: newFilters, isUpdate} = filterPhone(filters || [], getState())
  if (isUpdate) {
    dispatch({type: types.updateFilters, payload: newFilters})
  }
  if (error) {
    dispatch(
      setContactsLoaded({
        data: {count: 0, list: []},
        limit,
        sort,
        page,
        filters
      })
    ) // to stop loader
    return dispatch(globalActions.error(error))
  }

  let extraFields = ['id', 'country', 'locale']
  if (flag === 'contactListPage') {
    const {crmDetails: config} = getCountryConfig()
    let fields = contactFields(getState())
    if (fields && fields.length) {
      let visibleFields = fields
        .map((field) =>
          childrenReducer(field, config['hiddenColumns'], 'columnName')
        )
        .filter((field) => !!field && field !== -1)
      let fieldsArr = getVisibleKeys(visibleFields)
      extraFields.push(...fieldsArr)
    } else {
      extraFields = []
    }
  }

  let queryStructure = ['count', ['list', [...extraFields]]]

  ContactApi.getContactList(params, queryStructure, flag)
    .then((res) => {
      const {count} = res.contact
      const isOutOfRange = count > 0 && (page - 1) * limit >= count
      if (!isOutOfRange) {
        dispatch(
          setContactsLoaded({
            data: res.contact,
            limit,
            sort,
            page,
            filters
          })
        )
      } else {
        // There is no data at the server for this page.
        // Go back to previous page
        // https://frontiercargroup.atlassian.net/browse/IO-2649
        dispatch(getContactList({page: page - 1}))
      }
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const redirectToContact = (filter = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const country = getCountryCode(state)
  const contactData = {...filter, country}
  const parameters = {limit: 1, contactData}
  let {params, error, filterPhone} = normalizePhoneParams(parameters, state)
  if (error) {
    return dispatch(globalActions.error(error))
  }
  ContactApi.getContactList(params)
    .then((res) => {
      const contact =
        res.contact && res.contact.list && res.contact.list[0]
          ? res.contact.list[0]
          : null
      if (!contact) {
        getHistory().replace(`/crm/contacts/create`, {
          phoneNumber: filterPhone || filter.phone || ''
        })

        return
      }

      const {detail} = state
      const targetId = contact.id

      if (
        !window.location.pathname.includes('crm/contacts/detail/') ||
        !detail ||
        !detail.detail ||
        detail.detail.id !== targetId
      ) {
        getHistory().replace(`/crm/contacts/detail/${targetId}`)
      }
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const setContactsLoaded = (data) => ({
  type: types.dataLoaded,
  payload: data
})

export const createContact = ({data}) => (dispatch, getState) => {
  dispatch(setFormPending())
  const country = getCountryCode(getState())

  return ContactApi.createContact({...data, country})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(resetContactForm())
      dispatch(
        globalActions.success({
          message: 'global.action.create',
          entity: 'contact'
        })
      )
      dispatch(getContactList())
      getHistory().replace(`/crm/contacts/detail/${response.createContact.id}`)

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.create',
          entity: 'contact'
        })
      )
    })
}

export const updateContact = ({id, data}) => (dispatch) => {
  dispatch(setFormPending())

  return ContactApi.updateContact({id, contactData: data})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(
        globalActions.success({
          message: 'global.action.update',
          entity: 'contact'
        })
      )
      dispatch(getContactList())
      dispatch(resetContactForm())

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.update',
          entity: 'contact'
        })
      )
    })
}

export const deleteContact = (ids) => (dispatch) => {
  return ContactApi.deleteContact(ids)
    .then(() => {
      dispatch(
        globalActions.success({
          message: 'global.action.delete',
          entity: 'contact'
        })
      )
      dispatch(getContactList())
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.delete',
          entity: 'contact'
        })
      )
    })
}

export const toggleSort = (sort, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(getContactList({}, flag))
}

export const setSort = (sort, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.setSort,
    payload: sort
  })
  dispatch(getContactList({}, flag))
}

export const toggleField = (label) => ({
  type: types.toggleField,
  payload: label
})

export const updateFilters = (filters, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(getContactList({}, flag))
}

export const setFetching = (fetching = true) => ({
  type: types.fetching,
  payload: fetching
})

export const hydrateContactForm = (params) => (dispatch) => {
  dispatch(setFormPending())

  return ContactApi.getContactList({contactData: {...params}, limit: 1})
    .then((response) => {
      const contact = response.contact.list

      return dispatch({
        type: types.hydrateForm,
        payload: contact[0]
      })
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}

export const updateContactForm = (payload) => ({
  type: types.updateForm,
  payload
})

export const resetContactForm = (payload) => ({
  type: types.resetForm,
  payload
})

export const setFormError = (payload) => ({
  type: types.formError,
  payload
})

export const setFormSuccess = (payload) => ({
  type: types.formSuccess,
  payload
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})
