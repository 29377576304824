import {connect} from 'react-redux'
import filter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import Select from '../../../components/Select/Select'
import {statusTransitJobs} from '../constants'
import selectors from '../../../store/transitJob/selectors'

const FilterBase = (type) => (props) => (
  <Select
    {...props}
    id={props.fieldName}
    label={props.filterLabel || props.label}
    selected={props.value}
    onChange={props.handleChange(type || props.fieldName)}
  />
)

const getPlaces = (state) => {
  const {config} = state
  const locationOptions = config.options.locationOptions || {}

  return {
    options: locationOptions
  }
}

const getPlacesByType = (type) => (state) => {
  const {config} = state
  const locationOptions =
    (config.options.locationOptions &&
      state.config.options.locationOptions[type]) ||
    []

  return {
    options: locationOptions
  }
}

const getDrivers = (state) => ({
  options: selectors.getDriverOptionsWithType(state)
})

const getStatus = () => {
  const status = statusTransitJobs.map((status) => ({
    value: status,
    label: `transitJobs.field.status.${status}`
  }))

  return {
    options: status
  }
}

export const PlacesFilter = connect(getPlaces)(filter(FilterBase()))
export const PlacesFilterCurrent = connect(getPlacesByType('CURRENT'))(
  filter(FilterBase())
)
export const StatusFilter = connect(getStatus)(filter(FilterBase()))
export const DriversFilter = connect(getDrivers)(filter(FilterBase('driverId')))
