const resourceName = `createPayslip`

export const setDealer = `${resourceName}/SET_DEALER`
export const setDealerInternalId = `${resourceName}/SET_DEALER_INTERNAL_ID`
export const setCars = `${resourceName}/SET_CARS`
export const setAmount = `${resourceName}/SET_AMOUNT`
export const selectRow = `${resourceName}/SELECT_ROW`
export const cleanUp = `${resourceName}/CLEAN_UP`
export const createTicket = `${resourceName}/CREATE_TICKET`
export const createTicketSuccess = `${resourceName}/CREATE_TICKET_SUCCESS`
export const createTicketError = `${resourceName}/CREATE_TICKET_ERROR`
export const attemptTicketCreation = `${resourceName}/ATTEMPT_TICKET_CREATION`
export const onFilterChange = `${resourceName}/ON_FILTER_CHANGE`
export const setFilters = `${resourceName}/SET_FILTERS`
