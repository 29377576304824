import React from "react";
import TableBody from '@material-ui/core/TableBody';
import TableRow from './TableRow'
import { TableRowCells } from '../../types'
interface propTypes {
    rows: Array<TableRowCells>
}

const TableBodyWrapped = (props: propTypes): JSX.Element => {
    const { rows = [] } = props;
    return (
        <TableBody>
            {
                Array.isArray(rows) && rows.map((rowData: TableRowCells, index) => {
                    const cells = [rowData['formatedDateTime'], rowData['fileName'], rowData['uploadedBy'], rowData['fileStatus']]
                    return (
                        <TableRow key={index} cells={cells} variant={"body"} />
                    )
                })
            }

        </TableBody>
    )
}

export default TableBodyWrapped
