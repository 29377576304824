import axios from 'axios'

const apiUrl = '/api/login/'

export const oauthLogin = async (config) => {
  try {
    const {googleToken, ...data} = config
    const options = {
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {
        Authorization: `Bearer ${googleToken}`
      }
    }
    const response = await axios(options)

    return response
  } catch (err) {
    return err
  }
}
