import {Component} from 'react'
import moment from 'moment'
import RangePresets from './RangePresets'
import Calendar from './Calendar'
import CalendarInfo from './CalendarInfo'
import CalendarInput from './CalendarInput'
import withConfig from '../../providers/withConfig'
import omit from 'lodash/omit'

export class CalendarViewer extends Component {
  static defaultProps = {
    isRange: false,
    showFooter: true,
    handleRangeChange: () => {},
    submit: () => {},
    update: () => {},
    close: () => {},
    timezone: 'CET'
  }

  componentDidCatch(e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  formatOutput = ({data}) => {
    const {selected} = this.props
    const format =
      typeof this.props.format === 'undefined'
        ? this.props.dateFormat
        : this.props.format

    if (this.props.isRange === true) {
      const output = {...(selected === null ? selected : {}), ...data}

      Object.keys(output).map((key) => {
        if (output[key] !== null && typeof output[key] !== 'undefined') {
          output[key] = moment(output[key]).format(format)
        } else {
          delete output[key]
        }
      })

      return output
    }

    return data.format(format)
  }

  update = (data, next) => {
    const output = this.formatOutput({data})
    this.props.update(output, next)
  }

  submit = () => {
    const data = this.props.selected
    const output = this.formatOutput({data})

    this.props.submit(output)
    this.props.close()
  }

  render() {
    const {showFooter, showInput} = this.props
    const rest = omit(this.props, ['visibleRange', 'showFooter', 'showInput'])
    const baseClass = 'date-picker'
    const singleClass =
      this.props.layout === 'single' ? `${baseClass}--single` : ''
    const className = [baseClass, singleClass].join(' ')

    return (
      <article className={className}>
        <section className='date-picker__body'>
          {showInput && (
            <CalendarInput
              {...rest}
              onUpdate={this.update}
              onComplete={this.submit}
            />
          )}
          <Calendar {...this.props} onUpdate={this.update} />
          {showFooter && <CalendarInfo {...rest} submit={this.submit} />}
        </section>
        {this.props.withPresets && (
          <RangePresets {...rest} onUpdate={this.update} />
        )}
      </article>
    )
  }
}

export default withConfig(['dateFormat'], CalendarViewer)
