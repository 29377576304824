import * as types from './types'

export const fetchBusinessPartnerList = (payload) => ({
  type: types.fetchBusinessPartnerList,
  payload
})

export const fetchBusinessPartnerListSuccess = (payload) => ({
  type: types.fetchBusinessPartnerListSuccess,
  payload
})

export const fetchBusinessPartnerListError = (error) => ({
  type: types.fetchBusinessPartnerListError,
  payload: error
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const deleteBusinessPartner = (payload) => ({
  type: types.deleteBusinessPartner,
  payload
})

export const deleteBusinessPartnerSuccess = () => ({
  type: types.deleteBusinessPartnerSuccess
})

export const deleteBusinessPartnerError = (error) => ({
  type: types.deleteBusinessPartnerError,
  payload: error
})

export function updateBusinessPartner(payload) {
  return {
    type: types.updateBusinessPartner,
    payload
  }
}
