import {PureComponent, Fragment} from 'react'
import FilterSaveDialog from './FilterSaveDialog'
import {Star} from '@material-ui/icons'
import {IconButton} from '@material-ui/core'

class FilterSave extends PureComponent {
  state = {isSaving: false, filterName: ''}

  saveFilters = () => {
    const filter = {
      name: this.state.filterName,
      id: `${this.state.filterName}`,
      filters: this.props.filters,
      sort: this.props.sort
    }

    this.props.save({filter})
    this.close()
  }

  open = () => {
    this.setState({isSaving: true})
  }

  close = () => {
    this.setState({isSaving: false, filterName: ''})
  }

  updateFilterName = (e) => {
    this.setState({filterName: e.target.value})
  }

  render() {
    return (
      <Fragment>
        {this.props.show && (
          <IconButton onClick={this.open} data-test='filter-save'>
            <Star />
          </IconButton>
        )}
        <FilterSaveDialog
          isOpen={this.state.isSaving}
          value={this.state.filterName}
          handleChange={this.updateFilterName}
          close={this.close}
          save={this.saveFilters}
        />
      </Fragment>
    )
  }
}

export default FilterSave
