import {Component} from 'react'
import {
  withStyles,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton
} from '@material-ui/core'
import {ValidatedTextField} from '@fcg/form-builder'
import {Close, ExpandLess, ExpandMore} from '@material-ui/icons'
import styled from 'styled-components'

import VisibilityButton from './Edit/VisibilityButton'
import PermissionDetails from './Edit/PermissionDetails'

const styles = (theme) => {
  // theme.spacing.unit = 8

  return {
    section: {
      width: '100%'
    },
    horizontalLine: {
      height: 1,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      marginTop: theme.spacing.unit * 3 // '24px'
    },
    selectWrapper: {
      padding: `${theme.spacing.unit * 2} ${theme.spacing.unit * 3}` // 16px 24px'
    },
    expandIcon: {
      transform: 'translateY(-50%) rotate(0deg) !important'
    },
    detailsRoot: {
      paddingRight: 0,
      paddingLeft: 0,
      flexDirection: 'column'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing.unit * 3 // '24px'
    },
    title: {
      color: 'rgba(55, 55, 55, 0.8)',
      fontWeight: 'bold',
      letterSpacing: '0px',
      display: 'flex'
    },
    closeButton: {
      display: 'flex',
      width: 'auto',
      height: 'auto'
    },
    extensionPanel: {
      boxShadow: 'none',
      '&:before': {
        bottom: 0,
        top: 'auto'
      },
      '&:last-of-type:before': {
        backgroundColor: 'transparent'
      }
    },
    expandedPanel: {
      backgroundColor: theme.palette.grey['100'],
      margin: 0
    },
    icon: {
      color: '#000'
    }
  }
}

const Heading = styled(Typography)`
  align-self: center;
  font-weight: ${({expanded}) => (expanded ? 'bold' : 500)} !important;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

class Edit extends Component {
  state = {
    expanded: []
  }

  handleExpand = (item) => {
    this.setState((state) => ({
      expanded: (state.expanded || []).includes(item)
        ? state.expanded.filter((f) => f !== item)
        : [...state.expanded, item]
    }))
  }

  handleMainButton = (item, permissionVisible, permissions) => {
    const {props} = this

    // Put all permissions together
    const allPermissions = () => {
      const main = []
      const sub = []

      if (permissions.type) {
        permissions.type.forEach((type) => main.push({[item]: `${type}`}))
      }

      if (permissions.extraPermissions) {
        permissions.extraPermissions.forEach((v) => {
          const setPermission = (p, t) => sub.push({[p]: `${t}`})

          // If type is an Array, push the permissions accordingly!
          if (Array.isArray(v.type)) {
            v.type.forEach((o) => setPermission(v.permission, o))
          } else {
            setPermission(v.permission, v.type)
          }
        })
      }

      return [...main, ...sub]
    }

    // Check if all permissions are selected
    if (
      allPermissions().every(
        (v) =>
          Object.prototype.hasOwnProperty.call(props.data, Object.keys(v)) &&
          props.data[Object.keys(v)].includes(v[Object.keys(v)])
      )
    ) {
      // Loop through all permissions from this group
      allPermissions().forEach((v) => {
        // Check if the type is selected (in case of multiple types per permission)
        if ((props.data[Object.keys(v)] || []).includes(v[Object.keys(v)])) {
          // Loop through all the types
          props.data[Object.keys(v)].forEach((j) => {
            // Only run the function if the type matches
            if (v[Object.keys(v)] === j) {
              props.onChange(Object.keys(v)[0], j)
            }
          })
        }
      })
    } else {
      // No permissions are selected or just some are selected
      const notSelected = allPermissions().filter(
        (v) =>
          !(
            Object.prototype.hasOwnProperty.call(props.data, Object.keys(v)) &&
            props.data[Object.keys(v)].includes(v[Object.keys(v)])
          )
      )

      // Loop through all permissions from this group
      notSelected.forEach((v) => {
        // Select the remaining permissions
        props.onChange(Object.keys(v)[0], v[Object.keys(v)])
      })
    }
  }

  render() {
    return (
      <Grid>
        {this.props.isCreate && (
          <Grid item xs={12}>
            <ValidatedTextField
              required
              fullWidth
              label={this.props.t('crmUserGroups.name.label')}
              name='GroupName'
              id='group_name'
              value={this.props.name}
              onChange={this.props.onNameChange}
            />
          </Grid>
        )}

        <div
          className={this.props.classes.section}
          id='sectionThatImLookingFor'
        >
          <Grid item md={12}>
            <Grid
              item
              container
              direction='row'
              className={this.props.classes.titleContainer}
            >
              <div className={this.props.classes.title}>
                {`${this.props.name} - ${this.props.t(
                  'crmUserGroups.generalPermissions.label'
                )}`}
              </div>
              <IconButton
                className={this.props.classes.closeButton}
                onClick={this.props.onClose}
              >
                <Close />
              </IconButton>
            </Grid>

            {Object.keys(this.props.permissionsConfig.allPermissions).map(
              (item) => {
                const permissionVisible = Boolean(this.props.data[item])

                return (
                  <ExpansionPanel
                    key={`permission_${item}`}
                    expanded={this.state.expanded.includes(item)}
                    onChange={() => {
                      this.handleExpand(item)
                    }}
                    classes={{
                      root: this.props.classes.extensionPanel,
                      expanded: this.props.classes.expandedPanel
                    }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        this.state.expanded.includes(item) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      }
                      classes={{expandIcon: this.props.classes.expandIcon}}
                      IconButtonProps={{
                        className: this.props.classes.icon
                      }}
                    >
                      <Heading expanded={this.state.expanded === item}>
                        {this.props.t(item)}
                      </Heading>

                      <VisibilityButton
                        permission={item}
                        subPermission={
                          this.props.permissionsConfig.allPermissions[item]
                        }
                        selectedPermissions={this.props.data}
                        onChange={() => {
                          this.handleMainButton(
                            item,
                            permissionVisible,
                            this.props.permissionsConfig.allPermissions[item]
                          )
                        }}
                      />
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      classes={{root: this.props.classes.detailsRoot}}
                    >
                      <PermissionDetails
                        t={this.props.t}
                        permission={item}
                        config={this.props.permissionsConfig.allPermissions}
                        data={this.props.data}
                        onChange={this.props.onChange}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              }
            )}
          </Grid>
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles)(Edit)
