import {businessPartnerFields} from './businessPartnerFields'
import BusinessPartnersList from '../../../pages/BusinessPartners/BusinessPartnersList'
import {PERMISSION_ENTITY} from '../../../pages/BusinessPartners/constants/businessPartners'

export const businessPartnerConfig = {
  path: 'businessPartner',
  label: 'menu.b2bBP',
  key: 'businessPartner',
  sort: [],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...businessPartnerFields},
  hiddenFields: [],
  component: BusinessPartnersList,
  permissions: {
    entity: PERMISSION_ENTITY,
    types: ['READ']
  },
  hidden: {}
}
