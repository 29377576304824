import * as fields from './fields'
import {ApiClient, SimpleApiClient} from '@fcg/admin-api-client'
import {formatCountry, formatCountryArray} from './helpers'
import {formatSort} from '../contact/helpers'
import {transformEmptyValues, makeRestApiCall} from '../common/helpers'

const client = new ApiClient('document')
const documentsSimpleClient = new SimpleApiClient('document')
const DOCUMENT_MULTIPART_API = '/restApi/document/upload'

export function fetchPreferenceList(params = {}) {
  return client
    .withTransform(formatCountryArray)
    .query('printPreference', params, fields.pdfPreferenceList)
}

export function loadPdfPreference(params) {
  return client.query('printPreference', params, fields.fetchPreference)
}

export function updatePrintPreference(params = {}) {
  return client.mutation('updatePrintPreference', params, fields.preferenceData)
}

export function deletePrintPreference(params = {}) {
  return client.mutation('deletePrintPreference', params)
}

export function createPrintPreference(params = {}) {
  return client
    .withTransform([transformEmptyValues, formatCountry])
    .mutation('createPrintPreference', params, fields.preferenceData)
}

export function uploadDocument(params = {}) {
  return client.query('uploadDocument', params, fields.documentData, true)
}

export function uploadAsset(params = {}) {
  return client.query('uploadAsset', params, fields.assetData, true)
}

export function deleteDocument(params = {}) {
  return client.mutation('deleteDocument', params)
}

export function updateDocument(params = {}) {
  return client.mutation('updateDocument', params, fields.documentData)
}

export function uploadDocumentMultipart({fileData, ...params}) {
  const formData = new FormData()
  formData.append('file', fileData)
  if (Object.keys(params).length > 0) {
    for (const key in params) {
      formData.append(key, params[key])
    }
  }

  return makeRestApiCall(DOCUMENT_MULTIPART_API, {
    method: 'post',
    formData,
    isMultipart: true,
    hasIncreaseTimeout: true,
    isTokenRequired: true
  })
}

export function documentList(params = {}) {
  return client
    .withTransform(formatSort)
    .query('document', params, fields.documentList)
}

export function downloadDocument(params = {}) {
  return client.query('downloadDocument', params)
}

export function documentDownloadLink(params = {}) {
  return client.query(
    'documentDownloadLink',
    params,
    fields.documentDownloadLink
  )
}

export const fetchDocumentDownloadLinks = async (data) => {
  try {
    return documentsSimpleClient.query('/download-documents', data)
  } catch (error) {
    return null
  }
}
