export default (theme) => ({
  panel: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    '&:first-child': {
      marginTop: 0
    }
  },
  dialog: {
    padding: `0 ${theme.spacing.unit * 6}px`
  },
  dialogHeadline: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing.unit * 2}px`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing.unit
  },
  dialogSubtitle: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing.unit
  },
  field: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  }
})
