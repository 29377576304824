import * as types from './types'
import {getHistory} from '../../utils/history'

export const fetchTransitJobList = (payload) => ({
  type: types.fetchTransitJobList,
  payload
})

export const fetchTransitJobListSuccess = (payload) => ({
  type: types.fetchTransitJobListSuccess,
  payload
})

export const fetchTransitJobListError = (error) => ({
  type: types.fetchTransitJobListError,
  payload: error
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const toggleRow = (payload) => ({
  type: types.toggleRow,
  payload
})

export const updateFormData = (payload) => ({
  type: types.updateFormData,
  payload
})

export const saveTransit = (payload) => ({
  type: types.saveTransit,
  payload
})

export const saveTransitSuccess = (payload) => {
  getHistory().goBack()

  return {
    type: types.saveTransitSuccess,
    payload
  }
}

export const resetTransitForm = (payload) => ({
  type: types.resetForm,
  payload
})

export const cancelTransitJob = (payload) => ({
  type: types.cancelTransitJob,
  payload
})

export const cancelTransitJobSuccess = (payload) => ({
  type: types.cancelTransitJobSuccess,
  payload
})

export const hydrateTransitForm = (payload) => ({
  type: types.hydrateTransitForm,
  payload
})

export const setHydrateFormStatus = (payload) => ({
  type: types.setHydrateFormStatus,
  payload
})

export const setOptionsForCarId = (payload) => ({
  type: types.setOptionsForCarId,
  payload
})

export const fetchDriverImage = (payload) => ({
  type: types.fetchDriverImage,
  payload
})

export const fetchTransitJob = (payload) => ({
  type: types.fetchTransitJob,
  payload
})

export const finishTransitJob = (payload) => ({
  type: types.finishTransitJob,
  payload
})

export const startTransitJob = (payload) => ({
  type: types.startTransitJob,
  payload
})

export const fetchDriverDetails = (payload) => ({
  type: types.fetchDriverDetails,
  payload
})

export const updateTransitJobStatus = (payload) => ({
  type: types.updateTransitJobStatus,
  payload
})
