import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../components/FormValidation'
import {CAR_TYPE} from '../../../pages/CarData/constants'

export const carDataFormConfig = {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'make',
            label: 'carData.column.make',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'model',
            label: 'carData.column.model',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'price',
            label: 'carData.column.price',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'year',
            label: 'carData.column.year',
            margin: 'normal',
            onChangeHandler: 'handleNumberInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'trim',
            label: 'carData.column.trim',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'minprice',
            label: 'carData.column.minprice',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'maxprice',
            label: 'carData.column.maxprice',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'minpriceb',
            label: 'carData.column.minpriceb',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'maxpriceb',
            label: 'carData.column.maxpriceb',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'transmission',
            label: 'carData.column.transmission',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'priceText',
            label: 'carData.column.priceText',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'priceb',
            label: 'carData.column.priceb',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'minmarketprice',
            label: 'carData.column.minmarketprice',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'maxmarketprice',
            label: 'carData.column.maxmarketprice',
            margin: 'normal',
            onChangeHandler: 'handleFloatInput',
            type: 'number'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            options: 'locations',
            id: 'location',
            label: 'carData.column.location',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'bodytype',
            label: 'carData.column.bodytype',
            margin: 'normal',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'sellable',
            label: 'carData.column.isSellable',
            margin: 'normal',
            options: CAR_TYPE,
            onChangeHandler: 'handleTextInput'
          }
        ]
      }
    ]
  }
}
