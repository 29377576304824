import FilterMenuItem from '../FilterMenuItem'
import DropdownContent, {
  DropdownBody,
  DropdownHeading
} from '../../common/Dropdown/Dropdown'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'

const getOptionLabel = (option) =>
  typeof option.filterLabel !== 'undefined' ? option.filterLabel : option.label

// Filter list to be excluded when applied
const customFilterFields = [
  'placeId',
  'inspectionPointName',
  'responsible',
  'responsibleName'
]

const isItemSelected = (selectedFields, option) =>
  selectedFields.findIndex(
    (item) =>
      item.key === option.key ||
      (option.filterName && item.key === option.filterName) ||
      (option &&
        customFilterFields.includes(option.key) &&
        item &&
        customFilterFields.includes(item.key))
  ) > -1

const extractFilter = (filterArr, props) =>
  filterArr
    .filter((option) => option.isFilterable !== false)
    .map((option) => {
      if (isItemSelected(props.selected, option)) {
        return typeof option.children === 'undefined'
          ? null
          : extractFilter(option.children, props)
      }

      return typeof option.children === 'undefined' ? (
        <FilterMenuItem
          key={option.key}
          label={props.t(getOptionLabel(option))}
          onClick={() => props.selectOption(option)}
          data-test={`filter-item-${option.key}`}
        />
      ) : (
        extractFilter(option.children, props)
      )
    })

const Body = (props) => {
  const rest = omit(props, 't')

  return (
    <DropdownBody {...rest}>{extractFilter(props.options, props)}</DropdownBody>
  )
}

const Heading = (props) => (
  <DropdownHeading heading={props.t('filters.creator.heading')} />
)

export const FilterOptions = (props) => {
  return props.show === true ? (
    <DropdownContent
      Heading={<Heading {...props} />}
      Body={<Body {...props} />}
    />
  ) : null
}

export default translate()(FilterOptions)
