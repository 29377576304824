import React from 'react'

import Dialog from '../../../components/Dialog/Dialog'

const ConfirmationDialog = ({
  open,
  service,
  sendResponse = () => {},
  type
}: {
  open: boolean
  service: string
  sendResponse: (response: boolean) => void
  type: 'override' | 'delete'
}) => {
  const confirmLabel = {
    override: 'OVERRIDE EXISTING',
    delete: 'DELETE'
  }
  const cancelLabel = {
    override: 'USE EXISTING',
    delete: 'CANCEL'
  }

  const message = {
    override:
      'You are adding a service that already exists. Creating the chosen service will override the existing one.',
    delete: `You are about to delete ${service} service for this location. Do you want to continue?`
  }

  const title = {
    override: 'Chosen service already exists',
    delete: `Are you sure you want to delete ${service}?`
  }

  return (
    <Dialog
      maxWidth='xs'
      confirmLabel={confirmLabel[type]}
      cancelLabel={cancelLabel[type]}
      title={title[type]}
      open={open}
      onConfirm={() => sendResponse(true)}
      onCancel={() => sendResponse(false)}
      message={message[type]}
    />
  )
}
export default ConfirmationDialog
