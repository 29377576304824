export const changesSort = [{field: 'updatedAt', order: 'DESC'}]
export const changesFields = {
  id: {
    key: 'id',
    label: 'changes.id.label',
    type: 'string'
  },
  type: {
    key: 'type',
    label: 'changes.type.label',
    type: 'string'
  },
  field: {
    key: 'field',
    label: 'changes.field.label',
    type: 'string'
  },
  operation: {
    key: 'operation',
    label: 'changes.operation.label',
    type: 'string'
  },
  value: {
    key: 'value',
    label: 'changes.value.label',
    type: 'string'
  },
  updatedBy: {
    type: 'composite',
    key: 'updated',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        type: 'date',
        time: true,
        key: 'updatedAt',
        label: 'global.updatedAt.label'
      },
      {
        type: 'email',
        key: 'updatedByEmail',
        label: 'global.updatedBy.label',
        cut: true
      }
    ]
  }
}
