import gql from '@fcg/lib-gql/gql'

export const SELLING_PAYMENTS = {
  NONE: 'NONE',
  PARTIAL: 'PARTIAL',
  NOTCONFIRMED: 'NOTCONFIRMED',
  COMPLETE: 'COMPLETE'
}

export const SELLING_PAYMENT_STATUSES = [
  SELLING_PAYMENTS.NONE,
  SELLING_PAYMENTS.PARTIAL,
  SELLING_PAYMENTS.NOTCONFIRMED
]
export const SELLING_PAYMENT_STATUSES_ENUM = SELLING_PAYMENT_STATUSES.map(
  (status) => new gql.EnumType(status)
)

export const SELLING_STATUS = 'SELLING'
export const SELLING_STATUS_ENUM = new gql.EnumType(SELLING_STATUS)

export const SELLING_PAYMENT = 'SELLING_PAYMENT'
export const SELLING_PAYMENT_ENUM = new gql.EnumType(SELLING_PAYMENT)
