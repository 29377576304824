import {useContext, useState, useEffect} from 'react'

import {
  deleteSpecialOpenings,
  createOrUpdateSpecialOpening,
  getSpecialOpenings
} from '../../../api/booking/requests'

import {GlobalContext} from '../../../context/GlobalContext'
import {MainContext} from '../../../context/MainContext'
import {ValidationContext} from '../../../context/ValidationContext'

import {RESPONSE_MESSAGES} from '../../../hooks/useValidation/validationHelpers'
import HolidayObject from '../../../types/HolidayObject'

export default function useHolidayActions(isMaster: boolean) {
  const {
    deleteGlobalHoliday,
    addOrUpdateGlobalHoliday,
    globalHolidays
  } = useContext(GlobalContext)
  const {
    countryCode,
    selectedLocation,
    locationsHolidays,
    setLocationsHolidays,
    deleteFromLocationsHolidays,
    addOrUpdateLocationsHoliday
  } = useContext(MainContext)
  const {setResponseBar} = useContext(ValidationContext)

  const returnHolidays = (locationsHolidays: HolidayObject[] = []) => {
    const globalSpecialOpeningIds: string[] = []
    locationsHolidays.forEach((holiday: any) => {
      if (holiday.globalSpecialOpeningId)
        globalSpecialOpeningIds.push(holiday.globalSpecialOpeningId)
    })
    const applicableGlobalHolidays: any = []
    globalHolidays.forEach((gHoliday: any) => {
      if (
        (gHoliday.applyToAllCities ||
          gHoliday.cities.includes(selectedLocation!.city)) &&
        !globalSpecialOpeningIds.includes(gHoliday.id)
      ) {
        applicableGlobalHolidays.push({
          ...gHoliday,
          global: true
        })
      }
    })

    return applicableGlobalHolidays.concat(locationsHolidays)
  }
  const [holidays, setHolidays] = useState<HolidayObject[] | HolidayObject[]>(
    globalHolidays
  )

  const getLocationsHolidays = () => {
    const req = {country: countryCode, placeId: selectedLocation!.id!}
    getSpecialOpenings(req).then((holidays: HolidayObject[]) => {
      setLocationsHolidays(holidays)
    })
  }

  useEffect(() => {
    if (isMaster) {
      setHolidays(globalHolidays)
    } else {
      getLocationsHolidays()
    }
  }, [selectedLocation])

  useEffect(() => {
    if (isMaster) {
      setHolidays(globalHolidays)
    } else {
      setHolidays(returnHolidays(locationsHolidays))
    }
  }, [locationsHolidays, globalHolidays])

  const deleteHoliday = (id: string, callback: () => void) => {
    deleteSpecialOpenings(isMaster, [id]).then(
      (isDeleted: boolean) => {
        if (isDeleted) {
          isMaster ? deleteGlobalHoliday(id) : deleteFromLocationsHolidays(id)
          callback()
          setResponseBar({
            type: 'info',
            message: 'Holiday is deleted'
          })
        } else {
          setResponseBar({
            type: 'error',
            message: RESPONSE_MESSAGES.error
          })
        }
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const createOrUpdateGlobalHoliday = (
    holiday: HolidayObject,
    callback: () => void
  ) => {
    delete holiday.openingTimes
    createOrUpdateSpecialOpening(isMaster, holiday, countryCode).then(
      (newHoliday: HolidayObject) => {
        addOrUpdateGlobalHoliday(newHoliday)
        callback()
        setResponseBar({
          type: 'info',
          message: 'Master template holidays updated'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const createOrUpdateLocationsHoliday = (
    holiday: any,
    callback: () => void
  ) => {
    delete holiday.applyToAllCities
    delete holiday.cities
    delete holiday.closed
    if (holiday.global) {
      holiday.globalSpecialOpeningId = holiday.id
      delete holiday.id
      delete holiday.global
    }
    holiday.placeId = selectedLocation!.id
    createOrUpdateSpecialOpening(isMaster, holiday, countryCode).then(
      (newHoliday: HolidayObject) => {
        addOrUpdateLocationsHoliday(newHoliday)
        callback()
        setResponseBar({
          type: 'info',
          message: 'Holidays updated'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const addOrUpdateHoliday = (holiday: HolidayObject, callback: () => void) => {
    isMaster
      ? createOrUpdateGlobalHoliday(holiday, callback)
      : createOrUpdateLocationsHoliday(holiday, callback)
  }

  return {holidays, deleteHoliday, addOrUpdateHoliday}
}
