class User {
  constructor() {
    this.config = {}
  }

  setUser = (data) => {
    this.config = {...data}

    return this
  }

  getUser = () => ({...this.config})

  clean = () => {
    this.config = {}

    return this
  }
}

const userResource = new User()

export default userResource
