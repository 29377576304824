const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  orderBy: null,
  count: null,
  list: {
    page: 1,
    limit: 25,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: []
  }
}

export const getInitialState = (config) => {
  return {
    options: {},
    ...state,
    list: {
      ...state.list,
      ...config
    }
  }
}
