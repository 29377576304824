import React from 'react'
import cx from 'classnames'
import Fab from '@material-ui/core/Fab'
import PublicIcon from '@material-ui/icons/Public'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'

import {MainContext} from '../../context/MainContext'
import {history} from '../../../../history'
import {DEFAULT_LOCATIONS} from '../../config/constants'
import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

const ActionButtons = () => {
  const classes = useCommonStyles()
  const {showMaster, setSelectedLocation} = React.useContext(MainContext)

  const handleClick = (page: 'master' | 'newLocation') => {
    setSelectedLocation(DEFAULT_LOCATIONS[page])
    const path = `/location-manager/${page}`
    history.push(path)
  }

  return (
    <div
      className={cx(
        classes.fixed,
        classes.rightBottom,
        classes.flex,
        classes.itemsCenter
      )}
    >
      {showMaster && (
        <Tooltip title='Master Template'>
          <Fab
            onClick={() => handleClick('master')}
            color='primary'
            aria-label='edit'
          >
            <PublicIcon />
          </Fab>
        </Tooltip>
      )}
      <Tooltip title='Add new Location' className={classes.marginX1}>
        <Fab
          color='primary'
          aria-label='add'
          data-test='btn-add-new-location'
          onClick={() => handleClick('newLocation')}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default ActionButtons
