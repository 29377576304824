export default {
  menuItems: {
    hiddenFields: []
  },
  permissions: {
    auction: [],
    sales: []
  },
  fieldFilters: {
    contact: []
  }
}
