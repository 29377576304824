import gql from '@fcg/lib-gql/gql'

export const countryOptions = {
  leadSource: {type: 'leadSource'},
  businessType: {type: 'businessType'},
  serviceType: {type: 'serviceType'},
  leadStatus: {type: 'leadStatus'},
  rejectionReasons: {type: 'rejectionReasons'},
  cancellationReason: {type: 'cancellationReason'},
  appointmentStatus: {type: 'appointmentStatus'},
  bookingSource: {type: 'bookingSource'},
  callStatus: {type: 'callStatus'},
  cancelledInStatus: {type: 'cancelledInStatus'},
  discardedReason: {type: 'discardedReason'},
  CRMCommentSubtype: {type: 'CRMCommentSubtype'},
  CRMTaskType: {type: 'CRMTaskType'},
  appointmentType: {type: 'appointmentTypeCRM'},
  carType: {type: 'carType'},
  BP_Address_State_Type: {type: 'BP_Address_State_Type'},
  purchaseChannel: {type: 'purchaseChannel'},
  B2BDeal: {type: 'B2BDeal'},
  BP_Bank_Account_Type: {type: 'BP_Bank_Account_Type'},
  BP_Tax_Type: {type: 'BP_Tax_Type'},
  BP_Tax_Sub_Type: {type: 'BP_Tax_Sub_Type'},
  BP_Tax_Distribution_Type: {type: 'BP_Tax_Distribution_Type'},
  BP_Status_Type: {type: 'BP_Status_Type'},
  BP_Tag_Type: {type: 'BP_Tag_Type'},
  BP_Contact_Type: {type: 'BP_Contact_Type'},
  BP_Gender_Type: {type: 'BP_Gender_Type'},
  BP_Address_Type: {type: 'BP_Address_Type'},
  BP_Payment_Method: {type: 'BP_Payment_Method'},
  BP_Payment_Terms: {type: 'BP_Payment_Terms'},
  contactType: {type: 'contactType'},
  contactSubType: {type: 'contactSubtype'},
  bankName: {type: 'bankName'},
  payslipRejectionReasons: {type: 'payslipRejectionReasons'},
  inspectionType: {type: 'inspectionTypeCRM'},
  blockedReason: {type: 'blockedReason'},
  onboardingStatus: {type: 'onboardingStatus'},
  inactiveReason: {type: 'inactiveReason'},
  rejectedReason: {type: 'rejectedReason'},
  companyType: {type: 'companyType'},
  businessPartnerType: {type: 'businessPartnerType'},
  leadTransmissionType: {type: 'leadTransmissionType'},
  locations: {type: 'locations'}
}

/**
 *
 * @param {Array<string>} types
 * @returns
 */
export const getCountryOptionsConfig = (types) => {
  let options = {...countryOptions}

  if (types !== null) {
    options = Object.keys(options).reduce((acc, key) => {
      if (types.indexOf(key) > -1) {
        acc[key] = options[key]
      }

      return acc
    }, {})
  }

  return options
}

export function formatPlaceType(request) {
  if (typeof request.type === 'undefined') {
    return request
  }

  request.type = new gql.EnumType(request.type)

  return request
}

export function formatPlaceListTypeResponse(response) {
  return {
    ...response,
    place: {
      ...response.place,
      list: response.place.list.filter((place) => place.type.length > 0)
    }
  }
}

export function formatPlaceResponse(response) {
  return response.place
}

export function formatCreatePlaceResponse(response) {
  return response.createPlace
}
export function formatUpdatePlaceResponse(response) {
  return response.updatePlace
}

export function getSingleResult(response) {
  return Array.isArray(response.list) && response.list.length === 1
    ? response.list[0]
    : response
}

export function encodeMetaInfo(request) {
  return request && request.metaInfo
    ? {...request, metaInfo: JSON.stringify(request.metaInfo)}
    : request
}

export function formatSort(request) {
  if (typeof request.sort === 'undefined') {
    return request
  }

  request.sort = Array.isArray(request.sort)
    ? request.sort.map(convertSort)
    : convertSort(request.sort)

  return request
}

export function convertSort(sort) {
  if (sort.order && typeof sort.order === 'string') {
    sort.order = new gql.EnumType(sort.order)
  } else if (sort.order instanceof gql.EnumType === false) {
    sort.order = new gql.EnumType(sort.order.value)
  }

  return sort
}
