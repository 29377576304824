const listSelectors = {
  getCount: (selectorPath) => (state) => selectorPath(state).count,
  getError: (selectorPath) => (state) => selectorPath(state).error,
  isFetchingList: (selectorPath) => (state) =>
    selectorPath(state).isFetchingList,
  isLoading: (selectorPath) => (state) => selectorPath(state).isLoading,
  getFilters: (selectorPath) => (state) => selectorPath(state).list.filters,
  getFields: (selectorPath) => (state) => selectorPath(state).list.fields,
  getLimit: (selectorPath) => (state) => {
    const {limit} = selectorPath(state).list

    return limit ? limit : 25
  },
  getSort: (selectorPath) => (state) => selectorPath(state).list.sort,
  getPage: (selectorPath) => (state) => {
    const {page} = selectorPath(state).list

    return page ? page : 0
  }
}

export const getListSelectors = (selectorPath) =>
  Object.keys(listSelectors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: listSelectors[key](selectorPath)
    }),
    {}
  )
