import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

export default function SearchField({
  searchValue,
  onChange
}: {
  searchValue: string
  onChange: (value: string) => void
}) {
  const classes = useCommonStyles()

  return (
    <div className={classes.relative}>
      <TextField
        fullWidth
        label='Search'
        // label={t('search.button')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }}
        value={searchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}
