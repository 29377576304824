import React from 'react'
import ServiceOpeningHoursRow from '../../Services/ServicePicker/ServiceOpeningHoursRow'

import FormRow from '../../../../components/Form/FormRow'
import RowDescription from '../../../../components/Form/RowDescription'
import {serviceTypes, serviceKeys} from '../../../../config/constants'
import OpeningTime from '../../../../types/OpeningTime'
import ServiceType from '../../../../types/ServiceType'

const defaultOpeningHoursData = {
  openingTime: '10:00:00',
  closingTime: '18:00:00',
  closed: true,
  slots: 2
}

interface HPServicesProps {
  serviceOpeningTimes: OpeningTime[]
  onChange: (field: string, value: boolean | string | OpeningTime[]) => void
}

const HolidayPickerServices = ({
  serviceOpeningTimes = [],
  onChange
}: HPServicesProps) => {
  const handleDataChange = (
    serviceType: ServiceType,
    newServiceOpeningTimeData: OpeningTime
  ) => {
    const newOpeningTimes = serviceOpeningTimes.filter((openingTime) => {
      if (!openingTime.bookingType?.includes(serviceType)) return openingTime
    })
    onChange('openingTimes', newOpeningTimes.concat(newServiceOpeningTimeData))
  }
  const rowData = serviceKeys.map((type) => {
    const serviceOpeningTimeData = serviceOpeningTimes
      ? serviceOpeningTimes.filter((openingTime) =>
          openingTime.bookingType.includes(type)
        )
      : []
    return {
      rowKey: type,
      rowData:
        serviceOpeningTimeData.length > 0
          ? serviceOpeningTimeData
          : [{...defaultOpeningHoursData, bookingType: [type]}]
    }
  })

  return (
    <FormRow noBorderBottom>
      <RowDescription
        xs={12}
        title='Services'
        description='Which services are available?'
      />
      {rowData.map((service) => {
        return (
          <ServiceOpeningHoursRow
            type='services'
            serviceType={[service.rowKey]}
            key={service.rowKey}
            rowKey={service.rowKey}
            rowData={service.rowData}
            onChange={handleDataChange}
          />
        )
      })}
    </FormRow>
  )
}

export default HolidayPickerServices
