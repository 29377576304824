import Select from '../../../Select/Select'

export default (props) => (
  <Select
    {...props}
    onChange={this.props.handleChange(props.name)}
    selected={props.value}
    id={props.name}
  />
)
