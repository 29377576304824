export const clamp = (value, min, max) => {
  return min < max
    ? value < min
      ? min
      : value > max
      ? max
      : value
    : value < max
    ? max
    : value > min
    ? min
    : value
}

const getLog = (num) => Math.round(Math.log(num) / Math.log(10))

const format = (value, separator = ',') => {
  return value.length === 0
    ? ''
    : value
        .toString()
        .replace(/(\d)((?=(\d{2})+\d{3}$)|(?=\d{3}$))/gi, `$1${separator}`)
}

const getSign = (value) => (value >= 0 ? '' : '-')

const identity = (value) => value

export const formatByUnit = (value, config) => {
  const {units, unitSep} = config
  const sign = getSign(value)
  const powers = units.map(getLog).sort((a, b) => b - a)
  let input = ''

  // check if the value exists and not 0
  if (typeof value !== 'undefined') {
    if (value !== null && value !== 0) {
      input = typeof value === 'string' ? value : String(value).split('')
    } else {
      input = value === null ? '' : Number(value)
    }
  } else {
    return input
  }

  const power =
    powers.find((exp) => input && input.length >= exp) || powers.pop()
  const chunks = []

  if (input === 0) {
    return 0
  }

  while (input && input.length > 0) {
    const chunk =
      power <= input.length ? input.splice(-power) : input.splice(-input.length)

    chunks.unshift(chunk)
  }

  const formatted = chunks
    .filter(identity)
    .map((chunk) => chunk.join(''))
    .map((chunk) => format(chunk, unitSep))
    .join(unitSep)

  return `${sign}${formatted}`
}

export const formatWithDecimals = (value, config) => {
  const {decimalSep} = config

  const num =
    typeof value !== 'number'
      ? typeof value !== 'undefined' && value !== null && isNaN(value) !== true
        ? parseFloat(value)
        : false
      : value

  if (num === false) {
    return ''
  }

  const [string, decimals] = num
    .toFixed(2)
    .replace('.', decimalSep)
    .split(decimalSep)

  return `${formatByUnit(parseInt(string, 10), config)}${decimalSep}${decimals}`
}

// Takes string input of a number
export const parseNumber = (value, config) => {
  const sep = new RegExp(config.unitSep.replace('.', '\\.'), 'g')

  if (typeof value !== 'undefined' && value !== null) {
    const output = value === '' ? value : parseInt(value.replace(sep, ''), 10)

    return output
  }

  return ''
}

export const parseFromDecimals = (value, config) => {
  if (value === '' || typeof value === 'undefined' || value === null) {
    return ''
  }

  const {decimalSep} = config
  const [string, decimals] = value.split(decimalSep)
  const number = `${parseNumber(string, config)}.${decimals}`

  return parseFloat(number)
}
