import {getCountryConfig} from '../../../config/country'
let countryConfig = getCountryConfig()

export const placeFields = [
  'id',
  'country',
  'city',
  'location',
  'type',
  'inspectionPointId',
  'active',
  'region'
]

export const locationFields = [
  ...placeFields,
  'areaManager',
  'areaManagerName',
  'bIMapping',
  'sapPlantId',
  'sapStorageLocationId',
  'address1',
  'address2',
  'zipcode',
  'timezone',
  'timezoneOffset',
  'lat',
  'lng',
  'active',
  'metaInfo',
  'createdAt',
  'updatedAt'
]

const metaInfoField = [
  'metaInfo',
  [
    'color',
    'cancellationReason',
    'rejectionReason',
    'locationType',
    ...(['TR'].includes(countryConfig.countryCode) ? ['dependent'] : '')
  ]
]

export const countryOptionFields = [
  'type',
  'country',
  'name',
  'translationKey',
  metaInfoField
]

const location = ['location', 'locationType', 'placeId', 'country']
const status = ['statusType', 'status']
const financialDetails = [
  'id',
  'dueAmount',
  'processingAmount',
  'buyerDone',
  'processingReference',
  'outstandingAmount'
]
const carFields = [
  'id',
  'internalId',
  'make',
  'model',
  'year',
  'type',
  'licensePlate'
]
const carPaymentDetails = [
  ...carFields,
  ['location', location],
  ['status', status],
  ['financialDetails', financialDetails]
]
export const basicCountryOptionFields = ['id', 'type', 'country', 'name']

export const carDataField = ['id']
export const carDataStringFields = [
  'vin',
  'engineNumber',
  'licensePlate',
  'model'
]
export const locationUpdate = ['id']
export const placeList = ['count', ['list', placeFields]]
export const locationList = ['count', ['list', locationFields]]
export const countryOptionList = [['list', countryOptionFields]]
export const carPaymentDetailsList = [['list', carPaymentDetails]]
export const carCreateField = [
  ...carDataField,
  'vin',
  'licensePlate',
  ...(['TR', 'AR'].includes(countryConfig)
    ? [['financialDetails', ['id']]]
    : '')
]
export const basicCountryOptionsList = [['list', basicCountryOptionFields]]
export const carDetailsList = [['list', carFields]]

export const transitJobFields = [
  'id',
  'status',
  'startPlace',
  'endPlace',
  'startTime',
  'endTime',
  'driver',
  'driverId',
  'internalId',
  'driverId',
  'transitType',
  [
    'transits',
    [
      'id',
      'status',
      'carId',
      'startMileage',
      'endMileage',
      ['car', ['internalId', 'make', 'model', 'year', 'licensePlate']]
    ]
  ]
]
export const transitJobList = ['count', ['list', transitJobFields]]
