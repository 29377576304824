import cookies from 'browser-cookies'

import {
  JUMPCLOUD_USER,
  JUMPCLOUD_ID_TOKEN,
  JUMPCLOUD_ACCESS_TOKEN
} from '../appConstants'

const localStorage = window.localStorage

export const getUser = () => {
  const user = localStorage.getItem('user')

  return user === null ? false : JSON.parse(user)
}

export const checkAuth = () => {
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))
  const jumpCloudToken = cookies.get(JUMPCLOUD_ACCESS_TOKEN)

  const token =
    (oktaTokens && oktaTokens.idToken && oktaTokens.idToken.idToken) ||
    cookies.get('token') ||
    jumpCloudToken

  const userInfo = getUser()
  if (!token || !userInfo) {
    removeAuth()

    return false
  }

  const payload = token.split('.')[1]

  if (typeof payload === 'undefined') {
    removeAuth()

    return false
  }
  const decodedToken = JSON.parse(atob(payload))
  const tokenExp = decodedToken.exp * 1000

  // Jira reference: https://naspersclassifieds.atlassian.net/browse/ROAD-1045
  // If okta token has old issuer then we need to logout that user so that new okta token get generated
  if (
    tokenExp < Date.now() ||
    (decodedToken.iss && /oauth2\/default$/.test(decodedToken.iss))
  ) {
    removeAuth()

    return false
  }

  return {...userInfo, token}
}

export const updateToken = (token) => {
  const {exp} = getDecodedToken(token)

  const options = {
    expires: !exp ? 1 : new Date(exp * 1000)
  }
  if (process.env.NODE_ENV === 'production') {
    options.secure = true
    options.samesite = 'None'
  }

  cookies.set('token', token, options)
}

export const setAuth = (auth) => {
  if (typeof auth !== 'undefined') {
    localStorage.setItem('user', JSON.stringify(auth))
    if (auth.token) {
      const options = {
        expires:
          typeof auth.exp === 'undefined' || !auth.exp
            ? 1
            : new Date(auth.exp * 1000)
      }

      if (process.env.NODE_ENV === 'production') {
        options.secure = true
        options.samesite = 'None'
      }
      cookies.set('token', auth.token, options)
      cookies.set('tokenTimestamp', `${Date.now()}`, {path: '/'})
    }
  }
}

export const removeAuth = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('cookie')
  localStorage.removeItem('okta-token-storage')
  cookies.erase('token')
  cookies.erase('tokenTimestamp')
  cookies.erase(JUMPCLOUD_USER)
  cookies.erase(JUMPCLOUD_ID_TOKEN)
  cookies.erase(JUMPCLOUD_ACCESS_TOKEN)
  // FIXME: this does not seem to remove all the cookies correctly
  document.cookie = null
}

export const removeGoogleAuth = () => {
  cookies.erase('googleAccessToken')
  cookies.erase('googleIdToken')
  cookies.erase('google-client-type')
}

export const getCookie = () => {
  return localStorage.getItem('cookie')
}

export const getToken = () => {
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))
  const jumpCloudToken = cookies.get(JUMPCLOUD_ACCESS_TOKEN)

  const token =
    (oktaTokens && oktaTokens.idToken && oktaTokens.idToken.idToken) ||
    cookies.get('token') ||
    jumpCloudToken

  if (!token) {
    removeAuth()

    return false
  }

  return token
}

export const getOktaToken = () => {
  try {
    const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (oktaTokens && oktaTokens.idToken) {
      return oktaTokens.idToken
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export const getDecodedToken = (token) => {
  if (!token) {
    return null
  }

  const payload = token && token.split('.')[1]

  try {
    return JSON.parse(window.atob(payload))
  } catch (e) {
    return null
  }
}

export const setCookie = (name, val, days) => {
  if (val) {
    cookies.set(name, val, {expires: days})
  } else {
    // TODO: we should add different method for erasing cookies
    cookies.erase(name)
  }
}

export const getGoogleOAuthTokens = () => {
  const accessToken = cookies.get('googleAccessToken')
  const token = cookies.get('googleIdToken')
  return {
    accessToken,
    token
  }
}
