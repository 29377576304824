import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {translate} from 'react-i18next'
import Room from '@material-ui/icons/Room'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import Gavel from '@material-ui/icons/Gavel'
import Phone from '@material-ui/icons/Phone'
import Currency from './../../components/Format/Currency'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled(Link)`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
`

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
`

const Id = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.33;
  letter-spacing: 2px;
  margin-bottom: 8px;
`

const InfoIcon = (icon) => styled(icon)`
  color: #747474;
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
`

const Span = styled.span`
  color: #7d7d7d;
`

const getConcentValue = (props) => {
  const isNewsLetterAccepted = props.data.metaInfo.isNewsLetterAccepted

  if (isNewsLetterAccepted === null) {
    return null
  }

  return isNewsLetterAccepted ? props.t('global.yes') : props.t('global.no')
}
const NewsletterConsent = (props) => {
  const isNewsLetterAccepted = props?.data?.metaInfo?.isNewsLetterAccepted

  // For countries other than PL, set this value to undefined
  if (typeof isNewsLetterAccepted === 'undefined') {
    return null
  }

  return (
    <Content>
      <Span data-test='newsletter-consent'>
        {props.t('dealer.page.field.isNewsLetterAccepted')}:{' '}
        {getConcentValue(props)}
      </Span>
    </Content>
  )
}

const Address = (props) => {
  const {street, city, zipCode} = props.data.metaInfo

  if (props.isScrollSectionVisible || !street || !city || !zipCode) {
    return null
  }

  if (street || city || zipCode) {
    const RoomIcon = InfoIcon(Room)

    return (
      <Content>
        <RoomIcon />
        <Span>
          {street || ''}
          {street && (city || zipCode) ? ', ' : ''}
          {city || ''}
          {(street || city) && zipCode ? ', ' : ''}
          {zipCode || ''}
        </Span>
      </Content>
    )
  }
}

const InternalId = (props) => {
  if (!props.data.internalId && !props.data.metaInfo.internalId) {
    return null
  }

  const label = props.t('dealerList.placeholder.internalId')
  const internalId = props.data.metaInfo.internalId || props.data.internalId

  return <Id data-test='field-internal-id'>{`${label}: ${internalId}`}</Id>
}

const HeaderTitle = (props) => {
  if (!props.data.firstname || !props.data.lastname || !props.userId) {
    return null
  }

  return (
    <Title to={`/dealers/edit/${props.userId}`} data-test='field-name'>
      {props.data.firstname} {props.data.lastname}
    </Title>
  )
}

const Status = (props) => {
  if (!props.data.metaInfo.status || !props.userId) {
    return null
  }
  const VerifiedUserIcon = InfoIcon(VerifiedUser)

  return (
    <Content>
      <VerifiedUserIcon />
      <Span data-test='field-status'>
        {props.t(`dealer.page.field.status.${props.data.metaInfo.status}`)}
      </Span>
    </Content>
  )
}

const DealerManager = (props) => {
  if (
    !props.data.dealerManager ||
    (!props.data.dealerManager.firstname &&
      !props.data.dealerManager.lastname) ||
    !props.userId
  ) {
    return null
  }

  const SupervisorAccountIcon = InfoIcon(SupervisorAccount)
  const {firstname = '', lastname = ''} = props.data.dealerManager
  const supervisor = `${firstname} ${lastname}`

  return (
    <Content>
      <SupervisorAccountIcon />
      <Span data-test='field-email'>{supervisor}</Span>
    </Content>
  )
}

const MaxBidAmount = (props) => {
  if (!props.data.metaInfo.maxAmountBids || !props.userId) {
    return null
  }

  const GavelIcon = InfoIcon(Gavel)

  return (
    <Content>
      <GavelIcon />
      <Span>
        <Currency
          amount={props.data.metaInfo.maxAmountBids}
          withDecimals={true}
        />
      </Span>
    </Content>
  )
}

const PhoneContent = (props) => {
  if (!props.data.metaInfo.phone || props.isScrollSectionVisible) {
    return null
  }

  const PhoneIcon = InfoIcon(Phone)

  return (
    <Content>
      <PhoneIcon />
      <Span data-test='field-phone'>{props.data.metaInfo.phone}</Span>
    </Content>
  )
}

const Phone2Content = (props) => {
  if (!props.data.metaInfo.phone2 || props.isScrollSectionVisible) {
    return null
  }
  const PhoneIcon = InfoIcon(Phone)

  return (
    <Content>
      <PhoneIcon />
      <Span>{props.data.metaInfo.phone2}</Span>
    </Content>
  )
}

const LeftHeader = (props) => (
  <Wrapper>
    <InternalId {...props} />
    <HeaderTitle {...props} />
    <Status {...props} />
    <DealerManager {...props} />
    <MaxBidAmount {...props} />
    <Address {...props} />
    <PhoneContent {...props} />
    <Phone2Content {...props} />
    <NewsletterConsent {...props} />
  </Wrapper>
)

export default translate()(LeftHeader)
