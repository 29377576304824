import cloneDeep from 'lodash/cloneDeep'

const getChildRequiredConfig = (childId, requiredFields) =>
  requiredFields.find((field) => field === childId || field.field === childId)

const getFormFieldNames = ({children, id}) => {
  if (!children) {
    return [id]
  }

  return children.reduce((acc, child) => {
    if (child.children) {
      return [...acc, ...getFormFieldNames(child)]
    }

    if (child.id) {
      return [...acc, child.id]
    }

    return acc
  }, [])
}

const getDisabledPrefixFields = (countryConfig, formName) => {
  return countryConfig.disabledPrefixFields &&
    countryConfig.disabledPrefixFields[formName]
    ? countryConfig.disabledPrefixFields[formName]
    : []
}

export const formWithCountryOptions = (config, formName, options, data) => {
  const applyConfigForChild = (child, countryConfig, formNameKey) => {
    const childClone = cloneDeep(child)
    const childId = childClone.id

    if (countryConfig.hidden.includes(child.id)) {
      return null
    }

    if (countryConfig.dependencies && countryConfig.dependencies[childId]) {
      childClone.dependencies = [...countryConfig.dependencies[childClone.id]]
    }

    if (!childClone.children && countryConfig.required) {
      const requiredFields = countryConfig.required.reduce((acc, key) => {
        if (key.field) {
          return [...acc, key]
        }

        return acc
      }, [])

      const childRequiredConfig = getChildRequiredConfig(
        child.id,
        requiredFields
      )

      if (childRequiredConfig) {
        childClone.required = true
      }
    }

    if (
      !childClone.children &&
      typeof countryConfig.disabledFields !== 'undefined'
    ) {
      if (
        options &&
        options.page &&
        formNameKey &&
        Array.isArray(
          countryConfig.disabledFields[options.page][formNameKey]
        ) &&
        countryConfig.disabledFields[options.page][formNameKey].includes(
          child.id
        )
      ) {
        childClone.disabled = true
      } else if (typeof child.disabled !== 'function') {
        childClone.disabled = false
      }
    }

    if (
      typeof childClone.children === 'undefined' &&
      countryConfig.required &&
      countryConfig.required.includes(childClone.id)
    ) {
      return {
        ...childClone,
        required: true
      }
    }

    if (typeof childClone.children !== 'undefined') {
      const children = childClone.children.reduce((acc, child) => {
        if (!countryConfig.hidden.includes(child.id) || !childClone.id) {
          const configuredChild = applyConfigForChild(
            child,
            countryConfig,
            formNameKey
          )

          if (configuredChild) {
            return [...acc, configuredChild]
          }
        }

        return acc
      }, [])

      return {
        ...childClone,
        children
      }
    }

    if (countryConfig.dynamic && countryConfig.dynamic[childClone.id]) {
      return {
        ...childClone,
        ...countryConfig.dynamic[childClone.id]
      }
    }

    return childClone
  }

  const getConfigForCountry = (formConfig, data, formNameKey) => {
    const countryConfig = data[formNameKey]
    let children = null
    const formFields = getFormFieldNames(formConfig.form)
    const requiredFields = formFields.filter((field) =>
      countryConfig.required.find((requiredField) => {
        if (requiredField !== null && typeof requiredField === 'object') {
          return requiredField.field === field
        }

        return requiredField === field
      })
    )

    if (typeof countryConfig !== 'undefined') {
      children = formConfig.form.children.reduce((prev, child) => {
        const item = applyConfigForChild(
          typeof child === 'function' ? child(data) : child,
          countryConfig,
          formNameKey
        )

        return item ? [...prev, item] : prev
      }, [])
    }

    if (children !== null) {
      return {
        formFields,
        requiredFields,
        countryConfig,
        formDetails: {
          stepTitle: config.stepTitle,
          description: config.description
        },
        form: {
          children
        },
        disabledPrefixFields: getDisabledPrefixFields(countryConfig, formName)
      }
    }

    return config
  }

  return getConfigForCountry(config, data, formName)
}
