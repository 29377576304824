import DocumentConfigEdit from '../../../pages/DocumentConfig/Editor'
import {default as documentConfigFormConfig} from './documentConfigFormConfig'

export default {
  path: 'document-config/edit/:id',
  key: 'documentConfigEdit',
  component: DocumentConfigEdit,
  label: 'documentConfigEdit',
  ...documentConfigFormConfig
}
