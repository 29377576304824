import React from "react";

import TableHead from '@material-ui/core/TableHead';
import TableRowWrapped from "./TableRow";
import {UploadHistoryHeaders} from '../../config/constants'

const TableHeaderWrapped=(props:{cells: Array<String>}):JSX.Element=>{
    const {cells=[], }=props
    return(
        <TableHead>
            <TableRowWrapped cells={UploadHistoryHeaders} variant={'head'}/>
        </TableHead>
    )
}

export default TableHeaderWrapped;
