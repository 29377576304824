import {Fragment} from 'react'

import Date from './DateFormat'
import Currency from './Currency'
import Number from './NumberFormat'
import String from './String'

const FormatTypes = {
  array: ({data}) => data,
  email: ({data, cut}) => (cut === true ? data.split('@')[0] : data),
  composite: ({data}) => data.children.map((child) => format(child)).concat(),
  boolean: ({data}) => data,
  date: ({data, time, format, dateFormat}) => (
    <Date data={data} time={time} dateFormat={format} />
  ),
  number: ({data, currency, ...props}) =>
    currency === true ? (
      <Currency amount={data} {...props} />
    ) : (
      <Number amount={data} {...props} />
    ),
  string: (props) => <String {...props} />,
  link: ({data}) => data,
  sort: ({data}) => data
}

function format({type, ...props}) {
  const output =
    typeof FormatTypes[type] === 'function'
      ? FormatTypes[type](props)
      : props.data

  return typeof props.key !== 'undefined' ? (
    <Fragment key={props.key}>{output || null}</Fragment>
  ) : (
    <Fragment>{output || null}</Fragment>
  )
}

export default format
