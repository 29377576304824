import {PureComponent, Fragment} from 'react'
import {connect} from 'react-redux'
import {containsField} from '../Utils/ComplexFormComponentHelper'
import {getCountryConfig} from '../../../config/country'
import keys from 'lodash/keys'
import {fetchCountryStates, fetchStateCities} from '../../store/config/actions'
import {StateSelect, CitySelect, WrappedTextInput} from './Inputs'
import {CITY_ID, STATE_ID, CITY, extractValue} from './helper'
import {getCountryCities, getCountryStates} from '../../store/config/selectors'

class DealerLocationPickerBase extends PureComponent {
  constructor(props) {
    super(props)

    const {hidden, required} = getCountryConfig().dealerDetails

    this.hiddenFields = hidden
    this.requiredFields = required

    this.fieldDependencies = {
      [STATE_ID]: STATE_ID,
      [CITY_ID]: CITY_ID,
      [CITY]: CITY
    }
    this.fields = keys(this.fieldDependencies).filter(
      (field) => !this.hiddenFields.includes(field)
    )

    this.state = {
      selected: {
        [STATE_ID]: this.props.data[STATE_ID] || '',
        [CITY_ID]: this.props.data[CITY_ID] || '',
        [CITY]: this.props.data[CITY] || ''
      }
    }

    this.selectStateId = this.onChange(STATE_ID)
    this.selectCityId = this.onChange(CITY_ID)
    this.onChangeCity = this.onChange(CITY)
  }

  async componentDidMount() {
    await this.fetchData()
  }

  shouldFetchCityData = () => {
    if (this.isVisible(STATE_ID) && this.props.data[STATE_ID]) {
      return true
    }

    if (!this.isVisible(STATE_ID) && this.isVisible(CITY_ID)) {
      return true
    }

    return false
  }

  shouldFetchCountryState = () =>
    this.isVisible(STATE_ID) &&
    (!this.props.countryStates || this.props.countryStates.length === 0)

  fetchData = async () => {
    if (this.shouldFetchCountryState()) {
      await this.props.fetchCountryStates()
    }

    if (this.shouldFetchCityData()) {
      await this.props.fetchStateCities(this.props.data[STATE_ID])
    }
  }

  isFieldRequired = (field) => {
    return !this.props.disabled && containsField(this.requiredFields, field)
  }

  isRequired = (field) => this.isFieldRequired(field)

  isVisible = (field) => this.fields.includes(field)

  isCitySelectDisabled = () =>
    this.props.disabled ||
    (!this.props.disabled && this.isVisible(STATE_ID)
      ? !this.state.selected[STATE_ID]
      : false)

  fetchStateCities = (state) => {
    this.props.fetchStateCities(state)
  }

  onChange = (prop) => (e) => {
    const value = extractValue(e)

    this.setState(
      {
        selected: {
          ...this.state.selected,
          [prop]: value
        }
      },
      () => {
        if (prop === STATE_ID) {
          this.selectCityId()
          this.props.updateMetaField(value, STATE_ID)
          this.fetchStateCities(value)
        }

        if (prop === CITY_ID) {
          this.props.updateMetaField(value, CITY_ID)
        }

        if (prop === CITY) {
          this.props.updateMetaField(value, CITY)
        }
      }
    )
  }

  render() {
    const {Container} = this.props

    return (
      <Fragment>
        <WrappedTextInput
          fullWidth
          id={CITY}
          value={this.props.data[CITY]}
          label='dealer.page.field.city'
          margin='normal'
          required={this.isRequired(CITY)}
          visible={this.isVisible(CITY)}
          onChange={this.onChangeCity}
          container={Container}
          disabled={this.props.disabled}
        />
        <StateSelect
          filterable
          id={STATE_ID}
          state={this.state.selected[STATE_ID]}
          options={this.props.countryStates}
          required={this.isRequired(STATE_ID)}
          visible={this.isVisible(STATE_ID)}
          container={Container}
          onChange={this.selectStateId}
          onBlur={this.selectStateId}
          data-test={STATE_ID}
          disabled={this.props.disabled}
        />
        <CitySelect
          filterable
          id={CITY_ID}
          city={this.state.selected[CITY_ID]}
          options={this.props.stateCities}
          required={this.isRequired(CITY_ID)}
          visible={this.isVisible(CITY_ID)}
          container={Container}
          disabled={this.isCitySelectDisabled()}
          onChange={this.selectCityId}
          onBlur={this.selectCityId}
          data-test={CITY_ID}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  countryStates: getCountryStates(state),
  stateCities: getCountryCities(state)
})
const mapDispatchToProps = (dispatch) => ({
  fetchCountryStates: () => dispatch(fetchCountryStates()),
  fetchStateCities: (state) => dispatch(fetchStateCities(state))
})

const DealerLocationPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerLocationPickerBase)

DealerLocationPicker.type = 'DealerLocationPicker'

export default DealerLocationPicker
