import React from "react";
import { Button } from "@material-ui/core";

const UploadButton = (props: { selectedFile: string, uploadFile: Function }) => {
    const { selectedFile, uploadFile } = props;

    return (
        <Button
            className="btn-upload"
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFile}
            onClick={() => { uploadFile() }}
            style={{
                width: '152px',
                height: '40px',
                margin: '18px 53px 56px 0',
                padding: '0 16px',
                borderRadius: '4px',
                backgroundColor: '#3f51b5',
                color: 'white',
                fontSize: '14px',
                fontWeight: 'bold',
                lineHeight: '2.86',
                letterSpacing: '0.6px',
                textAlign: 'center',
            }}
        >
            Submit
        </Button >
    )
}

export default UploadButton