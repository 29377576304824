import {createSelector} from 'reselect'
import {getListSelectors} from '../common/listSelectors'

const selectorPath = (state) => state.countryOption
const selectors = getListSelectors(selectorPath)

export default {
  ...selectors,
  getCountryOptionList: (state) => selectorPath(state).countryOptionList,
  getFormData: (state) => selectorPath(state).form,
  getCountryOptionType: (state) => selectorPath(state).list.type,
  getSelectedCountryOption: (state) => selectorPath(state).data,
  getSelectedCountryOptionId: (state) => selectorPath(state).selectedWebhookId,
  getCreateDialogState: (state) => selectorPath(state).showCreateDialog,
  getParsedFilterForQuery: createSelector(selectors.getFilters, (filters) => {
    if (filters && Array.isArray(filters)) {
      return filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]: filter.value
        }),
        {}
      )
    }

    return {}
  })
}
