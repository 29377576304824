import {carDataListConfig} from './carDataListConfig'
import {carDataFilesConfig} from './carDataFiles'
import Container from '../../../pages/CarData/Container'

export const carDataConfig = {
  label: 'menu.carData',
  key: 'carData',
  path: 'cardata',
  component: Container,
  children: [carDataFilesConfig, carDataListConfig],
  CarDataList: {
    fields: carDataListConfig.fields
  },
  CarDataFileList: {
    fields: carDataFilesConfig.fields
  }
}
