import * as types from './types'
import {getInitialState, categorySchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  // Category list
  [types.fetchCategoryList]: (state) => ({
    ...state,
    isFetchingCategoryList: true
  }),
  [types.fetchCategoryListSuccess]: (state, action) => ({
    ...state,
    isFetchingCategoryList: false,
    categoryList: action.payload,
    count: action.payload.length
  }),
  [types.fetchCategoryListError]: (state, action) => ({
    ...state,
    isFetchingCategoryList: false,
    error: action.payload
  }),

  // Download category list item
  [types.downloadCategoryListItem]: (state) => ({
    ...state,
    isDownloadingCategoryItem: true
  }),

  [types.downloadCategoryListItemSuccess]: (state) => ({
    ...state,
    isDownloadingCategoryItem: false
  }),

  [types.downloadCategoryListItemError]: (state, action) => ({
    ...state,
    isDownloadingCategoryItem: false,
    downloadItemError: action.payload.e
  }),

  // Download category data
  [types.downloadCategoryData]: (state) => ({
    ...state,
    isDownloadingCategory: true
  }),

  [types.downloadCategoryDataSuccess]: (state) => ({
    ...state,
    isDownloadingCategory: false
  }),

  [types.downloadCategoryDataError]: (state, action) => ({
    ...state,
    isDownloadingCategory: false,
    downloadError: action.payload.e
  }),

  // Upload Category File Data
  [types.uploadCategoryData]: (state) => ({
    ...state,
    isUploadingCategoryData: true,
    uploadError: null
  }),
  [types.uploadCategoryDataSuccess]: (state) => ({
    ...state,
    isUploadingCategoryData: false,
    uploadError: null
  }),
  [types.uploadCategoryDataError]: (state, action) => ({
    ...state,
    isUploadingCategoryData: false,
    uploadError: action.payload
  }),

  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: categorySchema
  })
}
