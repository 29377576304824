import isEqual from 'lodash/isEqual'

import * as types from './types'
import * as AuthApi from '../../api/auth/requests'
import * as signalTypes from '../signals/types'
import {showSnackbarNotification} from '../signals/actions'
import localStorage from '../../utils/localStorage'
import {getUserInfo} from './selectors'
import {identifyUser} from '../../utils/segment'
import {getToken, getGoogleOAuthTokens} from '../../utils/authToken'
import {API_STATUS} from '../../appConstants'

const userInfoAdapter = localStorage('userInfo')

export const getCredentials = ({username, token}) => ({
  type: types.getCredentials,
  payload: {username, token}
})

export const connectOktaApi = (auth) => {
  return {
    type: types.connectOktaApi,
    payload: auth
  }
}

export const displayAuthError = (source, error) => {
  return {
    type: types.displayAuthError,
    payload: {source, error}
  }
}

export const googleLoginStatus = (status) => {
  return {
    type: types.googleLoginStatus,
    payload: {status}
  }
}

export const authenticateOktaToken = (token, clientId, nonce) => (dispatch) => {
  AuthApi.authenticateOktaToken(token, clientId, nonce)
    .then((data) => {
      dispatch(setAuthenticated(data))
      dispatch(checkUserInfo())
      dispatch(checkUserStatus())
    })
    .catch(() => dispatch(logout()))
}

export const authenticateJumpCloudToken = ({
  accessToken,
  idToken,
  clientId
}) => (dispatch) => {
  AuthApi.authenticateJumpCloudToken(accessToken, idToken, clientId)
    .then((data) => {
      dispatch(setAuthenticated(data))
      dispatch(checkUserInfo())
      dispatch(checkUserStatus())
    })
    .catch(() => dispatch(logout()))
}

export const handleOAuthLogin = (payload) => ({
  type: types.oauth2Request,
  payload
})

export const manualLogin = (data) => (dispatch) => {
  return AuthApi.login(data)
    .then((user) => {
      if (user && user.user && user.user.metaInfo.status === 'blocked') {
        dispatch(logout())
        dispatch(
          showSnackbarNotification({
            variant: signalTypes.variantTypes.error,
            message: 'Invalid User',
            open: true
          })
        )
      } else {
        dispatch(setAuthenticated(user))
        dispatch(checkUserInfo())
        dispatch(redirect(getRedirectUrl()))
        dispatch(setCrmUserStatus(true))
      }

      return true
    })
    .catch((error) => {
      dispatch(displayAuthError('internal', error))
    })
}

export const checkUserStatus = (isLoggedIn) => (dispatch, getState) => {
  const {authReducer} = getState()

  AuthApi.getUserStatus()
    .then((res) => {
      if (res.userInfo && res.userInfo.metaInfo.status === 'blocked') {
        dispatch(logout())
        dispatch(
          showSnackbarNotification({
            variant: signalTypes.variantTypes.error,
            message: 'Invalid User',
            open: true
          })
        )

        return false
      } else if (!isLoggedIn) {
        dispatch(redirect(getRedirectUrl()))
      } else {
        dispatch(
          updateUserInfoAction({
            ...authReducer.userInfo,
            ...res.userInfo
          })
        )
        dispatch({
          type: types.setCrmUserStatus,
          payload: res.userInfo.isCheckedIn
        })
      }
    })
    .catch((error) => {
      dispatch(
        showSnackbarNotification({
          variant: signalTypes.variantTypes.error,
          message: error.message,
          open: true
        })
      )
    })
}

export const authenticateGoogleToken = () => (dispatch) => {
  const {token, accessToken} = getGoogleOAuthTokens()
  dispatch(googleLoginStatus(API_STATUS.PENDING))
  return (
    AuthApi.sendGoogleCreds({googleToken: token})
      .then((response) => {
        const data = {
          ...response.data,
          googleToken: token
        }
        dispatch(setAuthenticated(data))
        dispatch(checkUserStatus())
        dispatch(checkUserInfo())
        dispatch(setCrmUserStatus(true))
        dispatch(googleLoginStatus(API_STATUS.SUCCESS))

        return response
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        dispatch(googleLoginStatus(API_STATUS.FAILED))
        console.error(err)
        dispatch(logout())
      })
  )
}

export const setAuthenticated = (payload) => {
  return {
    type: types.isAuthenticated,
    payload
  }
}

export const logout = (payload) => async (dispatch, getState) => {
  const {email} = payload || {}
  await Promise.all([userInfoAdapter.remove({name: 'userInfo'})])

  await dispatch(setCrmUserStatus(false))
  if (email) {
    await AuthApi.logoutRequest({email})
  }

  dispatch({
    type: types.logout,
    payload: true
  })
  // window.location.reload()
}

export const setCrmUserStatus = (newStatus) => (dispatch) => {
  const token = getToken()

  if (token) {
    if (newStatus) {
      AuthApi.setUserCheckInStatus()
        .then((response) => {
          if (response && response.checkIn) {
            dispatch({
              type: types.setCrmUserStatus,
              payload: true
            })
          }
        })
        .catch((err) => console.error(err))
    } else {
      AuthApi.setUserCheckOutStatus().then((response) => {
        if (response && response.checkOut) {
          dispatch({
            type: types.setCrmUserStatus,
            payload: false
          })
        }
      })
    }
  }
}

export const redirect = (redirect = true) => ({
  type: types.redirect,
  payload: redirect
})

export const redirectReset = () => ({
  type: types.redirectReset,
  payload: false
})

export const setUserInfo = (payload) => ({
  type: types.setUserInfo,
  payload
})

export const callOktak2Login = (payload) => ({
  type: types.callOktak2Login,
  payload
})

export const callGoogleK2Login = (payload) => ({
  type: types.callGoogleK2Login,
  payload
})

const updateUserInfo = async (dispatch, getState) => {
  const {config, authReducer: auth} = getState()

  if (!auth.isAuthenticated) {
    return
  }

  let userInfo = await userInfoAdapter.retrieve({name: 'userInfo'})
  let shouldUpdate = false

  if (!userInfo) {
    shouldUpdate = true
  } else if (userInfo && userInfo.lastUpdate) {
    shouldUpdate = userInfo.lastUpdate - Date.now() > config.userInfoTimeout
  }

  if (shouldUpdate) {
    const {data: response} = await AuthApi.getUserInfo()
    userInfo = response.data.userInfo
  }

  dispatch(updateUserInfoAction(userInfo))
}

const updateUserInfoAction = (userInfo) => (dispatch, getState) => {
  const data = userInfo.data || userInfo
  const {authReducer} = getState()

  userInfoAdapter.save({
    name: 'userInfo',
    value: {
      data,
      lastUpdate: Date.now()
    }
  })

  if (isEqual(data, getUserInfo(getState()))) {
    return
  }

  if (authReducer.isAuthenticated && authReducer.isAuthenticated.user) {
    identifyUser({...data, ...authReducer.isAuthenticated.user})
  }

  dispatch(setUserInfo(data))
}

export const checkUserInfo = () => async (dispatch, getState) => {
  const {config} = getState()
  updateUserInfo(dispatch, getState)
  setInterval(() => {
    updateUserInfo(dispatch, getState)
  }, config.userInfoTimeout)
}

export const setOAuthParams = (payload) => ({
  type: types.setOAuthParams,
  payload
})

const getRedirectUrl = () => {
  const returnUrl = window.sessionStorage.getItem('returnUrl')
  window.sessionStorage.removeItem('returnUrl')

  return returnUrl || ''
}
