import {
  TICKET_ENTITY_REQUEST_PROP_TYPES,
  PAYMENT_TYPES
} from '../common/helpers'

export const CURRENT_LOCATION = 'CURRENT'

export const getTicketEntitiesParams = ({
  documents,
  selectedPurchases,
  dealerInfo,
  documentMetaInfo
}) => {
  let ticketEntityParams = []

  ticketEntityParams = selectedPurchases.reduce(
    (acc, purchase) => [
      ...acc,
      {
        entityType: TICKET_ENTITY_REQUEST_PROP_TYPES.CAR,
        entityId: purchase.id,
        entityInternalId: purchase.internalId,
        metaInfo: {
          type: PAYMENT_TYPES.PAYMENT_PROOF,
          amount: purchase.amount
        }
      }
    ],
    ticketEntityParams
  )

  ticketEntityParams = documents.reduce(
    (acc, document) => [
      ...acc,
      {
        entityType: TICKET_ENTITY_REQUEST_PROP_TYPES.DOCUMENT,
        entityId: document.id,
        metaInfo: documentMetaInfo[document.id]
      }
    ],
    ticketEntityParams
  )

  ticketEntityParams.push({
    entityType: TICKET_ENTITY_REQUEST_PROP_TYPES.USER,
    entityId: dealerInfo.dealer,
    entityInternalId: dealerInfo.dealerInternalId
  })

  return ticketEntityParams
}

export const getPaymentInputValue = (value, dueAmount) => {
  if (!value) {
    return 0
  }

  if (
    value &&
    ((dueAmount > 0 && value <= dueAmount) ||
      (dueAmount < 0 && value >= dueAmount))
  ) {
    return value
  }

  if (value && value > dueAmount) {
    return null
  }

  return dueAmount
}
