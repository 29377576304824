import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const crm = merge(
  {
    homepage: '/crm/home',
    menuItems: {
      hiddenFields: [
        'inspectionReports',
        'dealers',
        'locations',
        'documentConfig',
        'carData',
        'payslips',
        'carOwnershipTransfer',
        'crmusergroups',
        'leadAllocation',
        'transitJobs',
        'locationManager',
        'resourceManager',
        'wms',
        'attendanceManager',
        'documentmanagement',
        'tms'
      ]
    },
    permissions: {
      auction: []
    },
    fieldFilters: {
      contact: []
    }
  },
  defaultConfig
)
