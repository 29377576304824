import styled from 'styled-components'
import noop from 'lodash/noop'
import PageTableBase from '../../../../components/PageTableBase'
import {Content, NoContent, EmptyTablePlaceholder} from '../../commons'
import Receipt from '@material-ui/icons/Receipt'
import {getAA1LegacyRoute} from '../../../../utils/navigation'
import {SectionTitle} from '../common'
import TextField from '@material-ui/core/TextField'
import {TextfieldWithoutPlaceholder} from '@fcg/tars'
import {isTicketInModifiableState} from '../../../../store/payslips/list/selectors'
import Select from '../../../../components/Select/Select'
import {useCallback} from 'react'
import {getPaymentInputValue} from '../../../../store/payslips/create/helpers'
import * as selectors from '../../../../store/payslips/list/selectors'
import {connect} from 'react-redux'

const editableCellTypes = {
  string: TextfieldWithoutPlaceholder,
  number: TextfieldWithoutPlaceholder,
  currency: TextfieldWithoutPlaceholder,
  select: Select
}
const editableCellOptions = {
  editableCellTypes,
  defaultEditableField: TextField,
  editableCellLabelStyles: {
    paddingLeft: 0
  }
}
const canEditCell = ({permissions = {}, rowData = {}}) =>
  isTicketInModifiableState(rowData) && permissions.canModifyPayslips
const getEditableCellOptions = (props) => ({
  ...editableCellOptions,
  canEditCell: canEditCell(props)
})

const PaymentSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 10px;
`
const NoImageText = styled.div`
  margin: 10px 0;
  font-weight: 500;
`

const PaymentSummary = ({
  data,
  addCarPaymentSelection,
  carPaymentReceiptsSelection,
  updateTicketEntity,
  locale,
  t,
  summaryData,
  payslipSubtableFields,
  ...props
}) => {
  const onSelect = (e) => {
    const ticket = data[e.rowIndex]
    const ticketId = ticket.ticketId
    const carReceiptId = ticket.entityId

    addCarPaymentSelection({ticketId, carReceiptId})
  }

  const onEntityChange = useCallback(
    ({row, value, field}) => {
      const {key} = field
      const {id} = row
      if (key === 'category') {
        updateTicketEntity({
          id,
          metaInfo: {
            amount: parseInt(row.amount),
            category: value,
            type: row.type
          }
        })
      }
    },
    [updateTicketEntity]
  )

  const onEntityEdit = ({row, value, field}) => {
    const {key} = field
    const {id, currentAmount, outstanding, type} = row
    const formattedAmountValue = getPaymentInputValue(
      parseInt(value),
      outstanding
    )
    if (
      key === 'amount' &&
      formattedAmountValue &&
      formattedAmountValue !== currentAmount
    ) {
      updateTicketEntity({
        id,
        metaInfo: {
          amount: parseInt(value),
          type
        }
      })
    }
  }

  const linkBase = {
    car: getAA1LegacyRoute(`/${locale}/car/edit`)
  }

  return (
    <PaymentSummaryContainer>
      <SectionTitle>{t('payslips.subtable.title')}</SectionTitle>
      {data && data.length ? (
        <PageTableBase
          fields={payslipSubtableFields}
          pageConfig={{}}
          data={data}
          fetchData={noop}
          multisort={false}
          visibility={false}
          selectable={false}
          withVisibility={false}
          noToolbar
          hasFooter={false}
          tableHeight='sm'
          fixedHeight={props.fixedHeight}
          selected={carPaymentReceiptsSelection}
          onCellBlur={onEntityEdit}
          onCellChange={onEntityChange}
          onSelect={onSelect}
          linkBase={linkBase}
          openLinkInNewTab
          showSumFooter
          summaryFields={props.summaryFields}
          summaryData={summaryData}
          editableCellOptions={getEditableCellOptions(props)}
          noHeaderLeftElement
          expandableTable={false}
          emptyTableSize='sm'
          emptyTablePlaceholder={
            <EmptyTablePlaceholder
              t={t}
              colspan={payslipSubtableFields.length}
              show={!data.length}
              showIcon={false}
            />
          }
        />
      ) : (
        <Content>
          <NoContent>
            <Receipt color='disabled' fontSize='large' />
            <NoImageText>{t('payslips.subtable.noPaymentSummary')}</NoImageText>
          </NoContent>
        </Content>
      )}
    </PaymentSummaryContainer>
  )
}

const mapStateToProps = (state) => ({
  payslipSubtableFields: selectors.getSubfields(state)
})

export default connect(mapStateToProps)(PaymentSummary)
