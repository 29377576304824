import moment from 'moment'

const yearMonthDayFormat = 'YYYY-MM-DD'

export const parseMetaInfoChange = ({field, value}) =>
  field === 'date' ? moment(value).unix() : value

export const formatMetaInfoChange = ({field, value}) => {
  if (field === 'date' && value) {
    return moment(value * 1000).format(yearMonthDayFormat)
  }

  return value
}
