export function convertToCSV(
  objArray: string,
  columnNames?: Record<string, string> | undefined
) {
  let dataObject = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
  if (!columnNames && dataObject[0]) {
    columnNames = Object.keys(dataObject[0]).reduce(
      (prevItem, item) => ({...prevItem, [item]: item}),
      {}
    )
    dataObject.unshift(columnNames)
  }
  let str = ''
  let dataArray = Object.keys(dataObject).map((key) => {
    return dataObject[key]
  })
  for (let i = 0; i < dataArray.length; i++) {
    let line = ''
    for (let column in columnNames) {
      if (line != '') line += ','

      line += dataArray[i][column]
    }

    str += line + '\r\n'
  }
  return str
}

export function exportCSVFile(items: string, fileTitle = ``) {
  var csv = convertToCSV(items)

  var exportedFilenmae = `rcm-${fileTitle + (new Date()).toLocaleDateString()}.csv`

  var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    var link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
