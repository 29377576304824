import {Component} from 'react'
import {Grid, Button} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import {GroupList} from './GroupsList'
import {
  loadAllRoles,
  loadGroupUsers,
  addUserToRole,
  removeUserFromRole,
  setSelectedGroup,
  resetSelectedGroup,
  deleteUserGroup
} from '../../../store/crmUserGroups/actions'
import {connect} from 'react-redux'
import {Route, Link} from 'react-router-dom'
import {GroupUsers} from './GroupUsers'
import Editor from './Editor'
import ActionButton from '../../../components/layout/ActionButton'
import LoadingState from '../../../components/LoadingState'
import ConfirmDelete from './ConfirmDelete'

class List extends Component {
  state = {
    isEditVisible: false,
    selected: -1,
    showConfirmDelete: false,
    deleteId: null,
    filteredUser: []
  }

  componentDidMount() {
    this.props.loadList()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.list !== nextProps.list){
    this.setState({
        filteredUser: nextProps.list
      })
    }
  }

  onGroupItemClick = (id) => {
    this.props.history.push(`${this.props.match.url}/group/${id}`)
  }

  initiateDelete = (id, e) => {
    e.stopPropagation()

    this.setState({
      showConfirmDelete: true,
      deleteId: id
    })
  }
  cancelDelete = () => {
    this.setState({
      showConfirmDelete: false,
      deleteId: null
    })
  }
  deleteGroup = () => {
    if (
      this.props.selected === this.state.deleteId ||
      this.props.list.length === 1
    ) {
      this.props.resetSelectedGroup()
      this.props.history.replace(`${this.props.match.url}`)
    }

    this.props.deleteGroup(this.state.deleteId)
    this.setState({
      deleteId: null,
      showConfirmDelete: false
    })
  }

  getSelectedRoleName = (id) => {
    if (id) {
      const selectedRole = this.props.list.find((item) => item.id === id)
      
      if (selectedRole) {
        return selectedRole.name
      }
    }

    return ''
  }

  onGroupEdit = (id) => {
    this.props.history.push(`${this.props.match.url}/group/${id}/edit`)
  }

  addNewUser = (id) => {
    this.props.history.push(`${this.props.match.url}/group/${id}/add-new-user`)
  }

  onCloseGroupEdit = (id) => {
    if (id) {
      this.props.history.replace(`${this.props.match.url}/group/${id}`)
    } else {
      this.props.history.replace(`${this.props.match.url}`)
    }
  }

  searchList = (key) => {
    this.setState({
      filteredUser: this.props.list.filter(user => user.name.toLowerCase().includes(key.toLowerCase()))
    })
  }
  

  render() {
    const {
      className,
      list,
      selected,
      users,
      setSelectedGroup,
      resetSelectedGroup,
      loading,
      loadingUsers,
      pageConfig
    } = this.props
    
    if (loading) {
      return <LoadingState data-testid='loadingState'  />
    }

    const createUserGroupCta = {
      padding: '16px',
      width: '100%',
      backgroundColor: '#3f51b5',
      color: 'white',
      fontWeight: 600,
      marginBottom: "20px"
    }

    const userGroupText ={
      color: 'rgb(63, 81, 181)',
      fontSize: '20px',
      marginBottom: '20px',
      fontWeight: '500'
    }

    const groupSearchInput = {
      width: '100%',
      padding: '16px',
      border: 'none',
      backgroundColor:' #f1f1f1',
      borderRadius: '4px'
    }
    
    return (
      
      <div className={className}>
        {/* <Grid container direction='row' style={{padding: '0 24px'}}> */}
        <Grid container direction='row'>
          <Grid md={4} sm={4} xs={12} item style={{padding: '24px', backgroundColor: 'white'}}>
            <Button
              style={createUserGroupCta}
              component={Link}
              to={`${this.props.match.path}/create`}
              data-testid='createUserGroupCta'
            >
              {/* {t('crmUserGroup.createUserGroup')} */}
              <Add data-testid='createUserGroupCtaIcon' /> Create User Group
            </Button>
            <div  data-testid='userGroupsHeading' style={userGroupText}>User Groups</div>
            <div>
              <input placeholder='Search user groups...' style={groupSearchInput} type='text' onChange={(event)=> this.searchList(event.target.value)} />
            </div>
            {loading 
              ? <div data-testid='groupLoader'>Loading...</div> 
              :
              <div data-testid='groupListWrapper'>
                <GroupList
                  items={this.state.filteredUser}
                  selected={selected}
                  onClick={this.onGroupItemClick}
                  onDeleteClick={this.initiateDelete}
                /> 
              </div>
              
              }
            
          </Grid>
          <Grid md={8} sm={8} xs={12} item style={{padding: '24px', backgroundColor: '#eceef7'}}>
            <div data-testid='rightNavWrapper'>
            <Route
              path={`${this.props.match.url}/group/:id`}
              render={({match}) => (
                <GroupUsers
                  loading={loadingUsers}
                  setSelectedGroup={setSelectedGroup}
                  resetSelectedGroup={resetSelectedGroup}
                  selectedRoleName={this.getSelectedRoleName(match.params.id)}
                  selectedId={match.params.id}
                  users={users}
                  loadUsers={this.props.loadUsers}
                  addUserToRole={this.props.addUserToRole}
                  removeUserFromRole={this.props.removeUserFromRole}
                  onGroupEdit={this.onGroupEdit}
                  addNewUser={this.addNewUser}
                  tableConfig={pageConfig.fields}
                />
              )}
            />
            </div>
            
          </Grid>
        </Grid>
        {/* <ActionButton>
          <Button
            variant='fab'
            color='primary'
            component={Link}
            to={`${this.props.match.path}/create`}
          >
            <Add />
          </Button>
        </ActionButton> */}
        <Route
          exact
          path={`${this.props.match.url}/group/:id/add-new-user`}
          render={({match}) => (
            <Editor
              handleClose={this.onCloseGroupEdit}
              selectedRoleId={match.params.id}
              history={this.props.history}
              addNewUserFlag
              isOpen
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/group/:id/edit`}
          render={({match}) => (
            <Editor
              handleClose={this.onCloseGroupEdit}
              selectedRoleId={match.params.id}
              history={this.props.history}
              isOpen
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/create`}
          render={() => (
            <Editor
              handleClose={this.onCloseGroupEdit}
              history={this.props.history}
              isOpen
              isCreate
            />
          )}
        />
        {this.state.showConfirmDelete ? (
          <ConfirmDelete
            onCancel={this.cancelDelete}
            onConfirm={this.deleteGroup}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    groups: list,
    loading,
    selected,
    users,
    loadingUsers
  } = state.crmUserGroups
  
  return {list, loading, selected, users, loadingUsers}
}

const mapDispatchTtoProps = (dispatch) => ({
  loadList: () => dispatch(loadAllRoles()),
  loadUsers: (id) => dispatch(loadGroupUsers(id)),
  addUserToRole: (roleId, userId) => dispatch(addUserToRole(roleId, userId)),
  removeUserFromRole: (roleId, userId) =>
    dispatch(removeUserFromRole(roleId, userId)),
  setSelectedGroup: (id) => dispatch(setSelectedGroup(id)),
  resetSelectedGroup: () => dispatch(resetSelectedGroup()),
  deleteGroup: (id) => dispatch(deleteUserGroup(id))
})

export default connect(mapStateToProps, mapDispatchTtoProps)(List)
