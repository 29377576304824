import * as types from './types'
import {getInitialState, actionScheduleSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'

export const reducerFns = {
  [types.fetchAuctionSchedulesList]: (state) => ({
    ...state,
    fetching: true,
    isFetchingList: true,
    list: {
      ...state.list,
      expandedRows: []
    }
  }),
  [types.fetchAuctionSchedulesListSuccess]: (state, action) => ({
    ...state,
    fetching: false,
    isFetchingList: false,
    error: false,
    fetchedData: true,
    list: {
      ...state.list,
      data: action.payload || {}
    }
  }),
  [types.fetchAuctionSchedulesListError]: (state) => ({
    ...state,
    fetching: false,
    isFetchingList: false,
    error: true,
    list: {
      ...state.list
    }
  }),
  [types.toggleRow]: (state, action) => {
    const expandedRows =
      !action.payload ||
      typeof action.payload.id === 'undefined' ||
      state.list.expandedRows.includes(action.payload.id)
        ? []
        : [action.payload.id]

    return {
      ...state,
      list: {
        ...state.list,
        expandedRows
      }
    }
  },
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.createAuctionFlow]: (state, action) => ({
    ...state,
    create: {
      ...state.create,
      created: false,
      isSubmitting: true
    }
  }),
  [types.createAuctionFlowSuccess]: (state, action) => ({
    ...state,
    create: {
      ...state.create,
      created: true,
      isSubmitting: false
    }
  }),
  [types.createAuctionFlowError]: (state, action) => ({
    ...state,
    create: {
      ...state.create,
      created: false,
      isSubmitting: false
    }
  }),
  [types.cleanUpDialog]: (state, action) => ({
    ...state,
    create: {
      ...state.create,
      created: false,
      isSubmitting: false
    }
  })
}

export const reducers = {
  ...reducerFns,
  ...tableDecorators.withDataLoading(types.dataLoadingTypes, 'list'),
  ...tableDecorators.withSingleSort(types.sortTypes, 'list'),
  ...tableDecorators.withColumnVisibility(types.columnTypes, 'list'),
  schema: actionScheduleSchema,
  getInitialState
}

export default reducers
