export default (Component) => (props) => {
  const {errorMessages, ..._props} = props
  const isError =
    typeof errorMessages !== 'undefined' && errorMessages.length > 0

  return (
    <Component
      {..._props}
      error={isError}
      helperText={
        Array.isArray(errorMessages) && errorMessages.length > 0
          ? errorMessages.join(', ')
          : null
      }
    />
  )
}
