import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../../store/utils/typesUtil'

const resourceName = 'driver'

export const dataLoaded = 'drivers/DATA_LOADED'

export const loadDriver = `${resourceName}/LOAD_DRIVER`

export const fetchDriversList = `${resourceName}/FETCH_DRIVER_LIST`
export const fetchDriversListSuccess = `${resourceName}/FETCH_DRIVER_LIST_SUCCESS`
export const fetchDriversListError = `${resourceName}/FETCH_DRIVER_LIST_ERROR`
export const updateDriversField = `${resourceName}/UPDATE_DRIVER_FIELD`

export const fetchDriverImage = `${resourceName}/FETCH_DRIVER_IMAGE`
export const setDriverImage = `${resourceName}/SET_DRIVER_IMAGE`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
export const toggleField = `${resourceName}/TOGGLE_FIELD`

export const createDriver = `${resourceName}/CREATE_DRIVER`
export const createDriverSuccess = `${resourceName}/CREATE_DRIVER_SUCCESS`
export const createDriverError = `${resourceName}/CREATE_DRIVER_ERROR`
export const saveDriver = `${resourceName}/SAVE_DRIVER`

export const formTypes = getFormTypes(resourceName)
export const sortTypes = getSortTypes(resourceName)
export const columnTypes = getColumnTypes(resourceName)
export const dataLoadingTypes = getDataLoadingTypes(resourceName)

export const updateVisitedPage = `${resourceName}/UDPATE_VISITED_PAGE`
export const resetDriver = `${resourceName}/RESET_DRIVER`
export const updateDriver = `${resourceName}/UPDATE_DRIVER`

export const driverSet = `${resourceName}/DRIVER_SET`

export const fetchDriverOptions = `${resourceName}/FETCH_DRIVER_OPTIONS`
export const fetchDriverOptionsSuccess = `${resourceName}/FETCH_DRIVER_OPTIONS_SUCCESS`
// form types
export const formError = 'driverForm/ERROR'
export const formSuccess = 'driverForm/SUCCESS'
export const formPending = 'driverForm/PENDING'
