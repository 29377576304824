import {RenderIf} from '../RenderIf'
import ValidatedSelect from '../FormValidation/ValidatedSelectField'
import {ValidatedTextField} from '../FormValidation'
import {translate} from 'react-i18next'

export const getWrappedFormElement = (Component) => ({
  visible,
  container: Container,
  ...rest
}) => (
  <RenderIf if={visible}>
    <Container>
      <Component fullWidth margin='normal' {...rest} />
    </Container>
  </RenderIf>
)
const WrappedSelect = getWrappedFormElement(ValidatedSelect)

export const WrappedTextInput = translate()(({t, label, ...rest}) =>
  getWrappedFormElement(ValidatedTextField)({
    ...rest,
    t: t,
    label: t(label)
  })
)

export const StateSelect = ({id, onChange, state, ...rest}) => (
  <WrappedSelect
    id={id}
    name={id}
    label='dealer.page.field.state'
    onChange={onChange}
    selected={state}
    value={state}
    filterable
    {...rest}
  />
)
export const CitySelect = ({id, onChange, city, ...rest}) => (
  <WrappedSelect
    id={id}
    name={id}
    label='dealer.page.field.city'
    onChange={onChange}
    selected={city}
    value={city}
    filterable
    {...rest}
  />
)
