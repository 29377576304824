const dealerDetails = require('./dealerManagement/pk')
const crmDetails = require('./CRM/pk')
const bulkuploadDetails = require('./bulkUpload/pk')
const carDataDetails = require('./carData/pk')
const locationDetails = require('./locationManagement/pk')
const payslipDetails = require('./payslips/pk')
const carOwnership = require('./carOwnership/pk')
const businessPartnersDetails = require('./businessPartnersDetails/pk')

module.exports = {
  url: 'carfirst.com',
  name: 'Pakistan',
  countryCode: 'PK',
  theme: 'pakistan',
  language: {
    selected: 'en-pk',
    options: [{value: 'en-pk', label: 'EN'}]
  },
  locales: ['en-pk'],
  currency: {code: 'PKR', symbol: 'Rs'},
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  fallbackLocale: 'en',
  carDuplicatedCheckField: 'vin',
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 92, secondaryCode: '+92'},
  avoidDefaultPhonePrefix: ['referralCode'],
  googleKey:
    '1093620694543-6mn04eaio0djras40tbafm89iia7dn3m.apps.googleusercontent.com',
  pages: {
    contactDetail: {
      header: {
        phone: {
          canLink: true,
          link: [
            'https://carfirst.contegris.com:4002/api/originate?',
            'username=intellicon&',
            'password=c@rf!$rt&',
            'originator=:email',
            '&destination=:phone&c2c=1'
          ]
        }
      }
    }
  },
  createCarOnCreateInspection: true,
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'category',
    'bulkUploadV2',
    'wms',
    'documentManagement',
    'tms',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
