import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'
import {Grid} from '@material-ui/core'

const CountryOptionForm = (props) => {
  const {data, t, handleTextInput, options} = props

  if (!data) return null

  return (
    <Grid container>
      <ConfigContext.Consumer>
        {(config) => (
          <FormBuilder
            data={data}
            options={options}
            form={props.pageConfig.form}
            onChangeHandlers={{
              handleTextInput
            }}
            config={config}
            t={t}
          />
        )}
      </ConfigContext.Consumer>
    </Grid>
  )
}

export default CountryOptionForm
