import ReactDOM from 'react-dom'
import {Slideshow} from '@fcg/image-viewer'
import {
  fileAlternativeRenderer,
  galleryFileAlternativeRendererWrapper,
  getThumbnailListAlternativeRender
} from './utils'
import merge from 'lodash/merge'

const sliderStyles = {
  sliderWrapperStyles: {
    padding: '100px 56px 0px'
  },
  slideStyles: {
    imageContainerStyles: {
      maxHeight: '100%'
    },
    imageWrapperStyles: {
      alignSelf: 'flex-end',
      marginBottom: '25px'
    }
  }
}
const styles = {
  sliderStyles
}

export const DocumentSlideshow = ({
  images,
  onClose,
  HeaderChild,
  selected,
  onSelect,
  Sidebar,
  ...rest
}) =>
  ReactDOM.createPortal(
    <Slideshow
      images={images}
      show
      onClose={onClose}
      alternativeRenderers={fileAlternativeRenderer}
      galleryAlternativeRendererWrappers={galleryFileAlternativeRendererWrapper}
      alternativeRendererChooser={getThumbnailListAlternativeRender}
      styles={merge(styles, rest.styles)}
      HeaderChild={HeaderChild}
      selectedImageIndex={selected}
      onSelect={onSelect}
      hideRotateButton
      Sidebar={Sidebar}
    />,
    document.getElementById('app-portal')
  )
