const validator = (item, validations) => {
  const errors = {}

  Object.keys(validations).map((field) => {
    const validationFields = validations[field]
    const fieldErrors = []

    Object.keys(validationFields).map((validation) => {
      const regEx = new RegExp(validationFields[validation])

      switch (validation) {
        case 'required':
          if (
            item[field] === undefined ||
            item[field] === null ||
            item[field] === ''
          ) {
            fieldErrors.push({
              message: `error.text.${field}.required`
            })
          }
          break

        case 'regex':
          if (
            item[field] !== undefined &&
            item[field] !== null &&
            !regEx.test(item[field])
          ) {
            fieldErrors.push({
              message: `bulkUpload.creating.car.${field}.errors`
            })
          }
          break
      }
    })

    if (fieldErrors.length !== 0) {
      errors[field] = fieldErrors
    }
  })

  item.errors = Object.keys(errors).length === 0 ? null : errors

  return item
}

export default validator
