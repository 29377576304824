import {Component} from 'react'
import {connect} from 'react-redux'
import {initialize, destroy, getActions, reset} from './store/actions'
import CalendarViewer from './CalendarViewer'

export const CalendarContainer = (Calendar) => {
  return class extends Component {
    state = {initialized: false, data: null}

    componentDidMount() {
      const {calendarId} = this.props
      const data = this.props.getById(calendarId)

      if (typeof data === 'undefined' || data === null) {
        this.props.initialize(this.props)
      } else {
        this.setState({data})
      }

      Calendar.displayName = 'wrappedCalendar'
    }

    componentWillUnmount() {
      this.props.destroy(this.props.calendarId)
    }

    componentDidCatch(e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    render() {
      if (this.props.open === false) {
        return null
      }

      const data =
        this.state.data !== null
          ? this.state.data
          : this.props.getById(this.props.calendarId)

      if (typeof data === 'undefined' || data === null) {
        return null
      }

      return <Calendar {...this.props} {...data} {...this.props.actions} />
    }
  }
}

const mapStateToProps = ({calendar}) => {
  return {
    getById: (id) => {
      return calendar[id]
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialize: (config) => dispatch(initialize(config)),
  destroy: (id) => dispatch(destroy(id)),
  reset: (id) => dispatch(reset(id)),
  actions: {
    ...getActions(ownProps.calendarId)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer(CalendarViewer))
