import {IconButton} from '@material-ui/core'
import {
  IndeterminateCheckBox,
  CheckBoxOutlineBlank,
  CheckBox
} from '@material-ui/icons'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  text-align: right;
  padding-left: 3em;
`

const AllSelected = styled(CheckBox)`
  color: #000;
`

const PartialSelected = styled(IndeterminateCheckBox)`
  color: #000;
`

const NoneSelected = styled(CheckBoxOutlineBlank)`
  color: rgba(0, 0, 0, 0.5);
`

const VisibilityButton = ({
  onChange,
  permission,
  subPermission,
  selectedPermissions
}) => {
  const checkSelected = () => {
    const main =
      selectedPermissions &&
      subPermission.type &&
      subPermission.type.every(
        (v) =>
          selectedPermissions[permission] &&
          selectedPermissions[permission].includes(v)
      )
    const mainSome =
      selectedPermissions &&
      subPermission.type &&
      subPermission.type.some(
        (v) =>
          selectedPermissions[permission] &&
          selectedPermissions[permission].includes(v)
      )
    const sub =
      subPermission &&
      subPermission.extraPermissions &&
      subPermission.extraPermissions.every((v) =>
        Array.isArray(v.type)
          ? selectedPermissions[v.permission] &&
            v.type.every((t) => selectedPermissions[v.permission].includes(t))
          : selectedPermissions[v.permission] &&
            selectedPermissions[v.permission].includes(v.type)
      )
    const subSome =
      subPermission &&
      subPermission.extraPermissions &&
      subPermission.extraPermissions.some(
        (v) =>
          selectedPermissions[v.permission] &&
          selectedPermissions[v.permission].includes(v.type)
      )

    if (Object.keys(subPermission).includes('extraPermissions')) {
      if (
        (subPermission.type && main && !sub) ||
        (subPermission.type && !main && sub)
      ) {
        return 'partial'
      } else if (
        (!main &&
          !sub &&
          subPermission.extraPermissions.length > 1 &&
          subSome) ||
        (!main &&
          !sub &&
          subPermission.type &&
          subPermission.type.length > 1 &&
          mainSome)
      ) {
        return 'partial'
      } else if (
        (!subPermission.type && sub) ||
        (subPermission.type && main && sub)
      ) {
        return 'all'
      } else {
        return 'none'
      }
    } else {
      if (main) {
        return 'all'
      } else if (
        subPermission.type &&
        subPermission.type.length > 1 &&
        mainSome
      ) {
        return 'partial'
      } else {
        return 'none'
      }
    }
  }

  const _onChange = (event) => {
    event.stopPropagation()

    onChange()
  }

  const renderButton = () => {
    if (checkSelected() === 'all') {
      return <AllSelected />
    } else if (checkSelected() === 'partial') {
      return <PartialSelected />
    } else {
      return <NoneSelected />
    }
  }

  return (
    <Container>
      <IconButton onClick={_onChange}>{renderButton()}</IconButton>
    </Container>
  )
}

export default VisibilityButton
