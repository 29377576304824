export const businessPartnerFields = {
  id: {
    key: 'businessPartnerId',
    label: 'businessPartners.id.text',
    type: 'string',
    isFilterable: false,
    isSortable: true
  },
  name: {
    key: 'legalName',
    label: 'businessPartners.name.text',
    type: 'string',
    isFilterable: false,
    isSortable: true
  }
}
