import {getCountryConfig} from '../config/country'

export const isPageHiddenByConfig = (pageName) => {
  const envName = environment === 'production' ? 'production' : 'staging'
  const stgHostAllowed = ['elfin', 'otoplus']
  const countryConfig = getCountryConfig()

  // page should be hidden if any of these two conditions suffice:
  // 1. If the page hiding property(hideFromMenu) is not available or is set to be hidden in the country config.
  // 2. If the app is running in an environment other than production and staging host instance is other than the allowed host instances.
  const isPageHidden =
    (countryConfig.hideFromMenu && countryConfig.hideFromMenu[pageName]) ||
    (envName === 'staging' &&
      !stgHostAllowed.find((host) => location.host.includes(host)))

  return !!isPageHidden
}
