import * as types from './types'
import {ApiClient} from '@fcg/admin-api-client'
import {apiError} from '../global/actions'
import {
  query,
  getDeleteResponsePayload,
  findObjectKey,
  TIMESTAMP_SEPARATOR
} from './utils'
import {getCollection, selectorPath} from './selectors'
import * as globalActions from '../global/actions'

export const mergeResources = (data, options) => ({
  type: types.mergeResources,
  payload: {
    data,
    options
  }
})

export const removeResource = (payload) => ({
  type: types.removeResources,
  payload
})

export const removeAllDataFromResource = (payload) => ({
  type: types.removeAllDataFromResource,
  payload
})

export const deleteDataFromCollection = (payload) => ({
  type: types.deleteDataFromCollection,
  payload
})

// TODO: Add classification of errors for better error handling
export const dataError = (error) => ({
  type: types.dataError,
  payload: error
})

export const unknownError = (error) => ({
  type: types.unknownError,
  payload: error
})

export const networkError = (error) => ({
  type: types.networkError,
  payload: error
})

export const serverError = (error) => ({
  type: types.serverError,
  payload: error
})

export const clientError = (error) => ({
  type: types.clientError,
  payload: error
})

const handleError = (error) => (dispatch) => {
  dispatch(apiError(error))
}

// Request = {
//   client: !ApiClient Instance,
//   queryType: !'query/mutation',
//   endpoint: !'',
//   requestType: !'CREATE/DELETE'
// }

// ! = required
// Options = {
//   collectionOptions: {
//     name: !String,
//     reset: Boolean,
//     will add more properties on the way if required
//   },
//   Actions (!SuccessAction, ErrorAction, ...)
// }

// sync (resource: [Fields], request: Request, options: Options)

export const sync = (resource, request = {}, options = {}) => (
  dispatch,
  getState
) => {
  const errorHandler = (error) => {
    dispatch(handleError(error))

    return Promise.reject(error)
  }

  const responseHandler = (response) => {
    const responseParser = async (res) => {
      if (res && res instanceof Error) {
        return Promise.reject()
      }

      return res
    }

    return responseParser(response)
      .catch((error) => {
        dispatch(apiError(error))
      })
      .then((payload) => {
        if (response && !(response instanceof Error)) {
          if (request.requestType === 'DELETE') {
            return dispatch(
              removeResource(
                getDeleteResponsePayload(payload, request, response, options)
              )
            )
          }

          if (options.successAction) {
            dispatch(options.successAction())
          }

          if (payload) {
            if (
              Object.prototype.hasOwnProperty.call(options, 'mergeIf') &&
              !options.mergeIf(payload)
            ) {
              dispatch(
                globalActions.apiError(new Error('Action was not successful'))
              )
              dispatch(
                globalActions.error({
                  message: 'global.action.update',
                  entity: 'appointment'
                })
              )

              return
            }

            payload = options.modifyPayloadBeforeMerge
              ? options.modifyPayloadBeforeMerge(payload)
              : payload

            let collectionName = options.collectionOptions.name
            const type = request.resourceType || request.endpoint

            // TODO: Remove this from sync()
            // TODO: Move it to action creators
            if (
              options.collectionOptions &&
              options.collectionOptions.cacheable
            ) {
              const existingCollectionKey = findObjectKey(
                selectorPath(selectorPath(getState())),
                type,
                'collections',
                collectionName
              )

              if (!existingCollectionKey) {
                const mergeTime = new Date().getTime()
                collectionName = `${collectionName}${TIMESTAMP_SEPARATOR}${mergeTime}`
              }
            }

            dispatch(
              mergeResources(payload, {
                type,
                ...options.collectionOptions,
                name: collectionName
              })
            )
          }

          return Promise.resolve()
        }
      })
  }

  if (request.client instanceof ApiClient) {
    if (request.queryType === 'query') {
      const collectionType = options.collectionOptions.type
        ? options.collectionOptions.type
        : request.endpoint
      let existingCollection

      if (!options.forceFetch) {
        existingCollection = getCollection(
          collectionType,
          options.collectionOptions.name,
          options.collectionOptions.cacheable,
          true
        )(getState())
      }

      if (existingCollection) {
        dispatch(options.successAction())

        return existingCollection
      }

      return query(request, resource)
        .then(responseHandler)
        .catch(errorHandler)
    } else if (request.queryType === 'mutation') {
      request.client
        .mutation(request.endpoint, request.params, resource)
        .then(responseHandler)
        .catch(errorHandler)
    }
  }
}
