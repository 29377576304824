import React, {useState} from 'react'
import AddLocation from '@material-ui/icons/AddLocation'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import SectionWrapper from '../../../components/Sections/SectionWrapper'
import DialogTemplate from '../../../components/Dialog/Dialog'
import AreaRow from './AreaRow'
import AddAreaModel from './LotAreaPicker/AreaPicker'
import AreaModel from './LotAreaPicker/LotPicker'
import NoData from '../../../components/NoData/NoData'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import useLotAreasActions from './useLotAreasActions'
import AreaObject from '../../../types/AreaObject'
import LotObject from '../../../types/LotObject'

const LotAreas = ({isMaster}: {isMaster: boolean}) => {
  const classes = useCommonStyles()
  const {areas, addOrUpdateArea, deleteArea, deleteLot} = useLotAreasActions()

  const [showAddAreaModel, setShowAddAreaModel] = useState<boolean>(false)
  const [showAreaModel, setShowAreaModel] = useState<boolean>(false)
  const [isEditingAreaDetails, setEditingAreaDetails] = useState<boolean>(false)
  const [lotsForSelectedArea, setLotsForSelectedArea] = useState<LotObject[]>(
    []
  )
  const [selectedArea, setSelectedArea] = useState<AreaObject | undefined>(
    undefined
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )

  const getArea = (id: string): AreaObject => {
    return areas.find((area: AreaObject) => area.id === id)!
  }

  const selectRow = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedArea(getArea(id))
    setShowAreaModel(true)
  }

  const onAddOrUpdate = (newArea: AreaObject) => {
    addOrUpdateArea(
      newArea,
      isEditingAreaDetails,
      lotsForSelectedArea,
      closeAddAreaModel
    )
  }

  const showDeleteConfirmationDialog = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedArea(getArea(id))
    setShowDeleteConfirmation(true)
  }

  const onDelete = () => {
    deleteArea(selectedArea!.id!, deleteCallback)
  }

  const deleteCallback = () => {
    setShowDeleteConfirmation(false)
    closeLotPicker()
  }

  const closeAddAreaModel = () => {
    setShowAddAreaModel(false)
    setSelectedArea(undefined)
    setEditingAreaDetails(false)
  }

  const editAreaModel = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedArea(getArea(id))
    setShowAddAreaModel(true)
    setEditingAreaDetails(true)
  }

  const closeLotPicker = () => {
    setSelectedArea(undefined)
    setShowAreaModel(false)
  }

  const subtitle = isMaster ? '' : ''

  return (
    <SectionWrapper title='Lot Areas' subtitle={subtitle} fullWidth>
      <Grid container>
        {areas && areas?.length === 0 ? (
          <NoData
            buttonText='ADD LOT AREA'
            onClick={() => {
              setShowAddAreaModel(true)
            }}
          />
        ) : (
          areas &&
          areas.map((area) => {
            return (
              <AreaRow
                key={area.id}
                {...area}
                onDelete={showDeleteConfirmationDialog}
                onSelect={selectRow}
                editArea={editAreaModel}
              />
            )
          })
        )}
      </Grid>
      <Button
        variant='outlined'
        color='primary'
        className={classes.absoluteRightTop}
        startIcon={<AddLocation />}
        onClick={() => {
          setShowAddAreaModel(true)
        }}
        data-test='btn-add-area'
      >
        ADD AREA
      </Button>

      {showAddAreaModel && (
        <AddAreaModel
          open={showAddAreaModel}
          onCancel={closeAddAreaModel}
          onConfirm={onAddOrUpdate}
          selectedArea={selectedArea}
          isEditingAreaDetails={isEditingAreaDetails}
          setLotsForSelectedArea={setLotsForSelectedArea}
        />
      )}

      {showAreaModel && (
        <AreaModel
          open={showAreaModel}
          onCancel={closeLotPicker}
          selectedArea={selectedArea}
          // onConfirm={deleteLot}
        />
      )}

      {showDeleteConfirmation && (
        <DialogTemplate
          open={showDeleteConfirmation}
          title={`Are you sure you want to delete ${selectedArea!.name}`}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel='YES, DELETE'
          dataTest='delete-area-confirm'
          onConfirm={onDelete}
        ></DialogTemplate>
      )}
    </SectionWrapper>
  )
}

export default LotAreas
