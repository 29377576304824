import {Component} from 'react'
import styled from 'styled-components'
import {translate} from 'react-i18next'

import {File, Image} from '@fcg/image-viewer'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import * as signalTypes from '../../store/signals/types'

import Flex from '../Flex'

const Wrapper = styled(Flex)`
  max-height: 100%;
`

const SaveButton = styled(Button)`
  && {
    margin-left: 16px;
  }
`

const Text = styled(TextField)`
  && {
    margin-top: 18px;
  }
`

class PreviewModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileName: this.props.fileName,
      comment: this.props.comment
    }
  }

  handleChange = (e) => {
    const {name, value} = e.target

    this.setState({
      [name]: value
    })
  }

  handleSave = () => {
    if (this.state.fileName) {
      this.props.updateDocument({
        fileName: this.state.fileName,
        id: this.props.id,
        comment: this.state.comment,
        reference: this.props.reference
      })
      this.props.handleClose()
    } else {
      this.props.showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.document.fileName.error',
        open: true
      })
    }
  }

  renderFile = () => {
    if (!this.props.documentPreviewUrl) {
      return null
    }

    if (this.props.fileType === 'pdf') {
      return (
        <File
          src={this.props.documentPreviewUrl}
          fileType={this.props.fileType}
        />
      )
    } else {
      return <Image imageUrl={this.props.documentPreviewUrl} />
    }
  }

  render() {
    return (
      <Dialog
        PaperProps={{
          style: {
            height: '80%'
          }
        }}
        open={this.props.showPreview}
        onClose={this.props.handleClose}
        maxWidth='md'
        fullWidth
      >
        <Flex
          justify='space-between'
          alignItems='center'
          padding='12px 12px 0 0 !important'
        >
          <DialogTitle>{this.props.fileName}</DialogTitle>
          <IconButton aria-label='close' onClick={this.props.handleClose}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <Wrapper height='calc(100% - 80px)'>
          <Flex
            width='70%'
            height='100%'
            padding={this.props.fileType === 'pdf' ? '24px 24px 8px' : 0}
          >
            {this.renderFile()}
          </Flex>
          <Flex
            direction='column'
            justify='space-between'
            padding='0 32px 32px 0'
            width='30%'
          >
            <Flex direction='column'>
              <TextField
                label={this.props.t('imagePreview.title')}
                value={this.state.fileName}
                name='fileName'
                onChange={this.handleChange}
              />
              <Text
                multiline
                rows={4}
                label={this.props.t('imagePreview.comments')}
                value={this.state.comment}
                name='comment'
                onChange={this.handleChange}
              />
            </Flex>
            <Flex justify='flex-end'>
              <Button onClick={this.props.handleClose}>
                {this.props.t('button.cancel')}
              </Button>
              <SaveButton
                onClick={this.handleSave}
                variant='raised'
                color='primary'
                data-test='save-preview-document-info'
              >
                {this.props.t('button.save')}
              </SaveButton>
            </Flex>
          </Flex>
        </Wrapper>
      </Dialog>
    )
  }
}

export default translate()(PreviewModal)
