import {colors} from '../Table/common'

export const buttonBorder = `1px solid ${colors.borderColor}`
export const buttonHeight = '42px'
export const rootButtonStyles = {
  'min-width': '40px',
  'max-width': '40px',
  'min-height': '42px',
  border: buttonBorder
}
