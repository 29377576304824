import * as types from './types'
import {getHistory} from '../../utils/history'

export const fetchDriversList = (payload) => ({
  type: types.fetchDriversList,
  payload
})

export const fetchDriversListSuccess = (payload) => ({
  type: types.fetchDriversListSuccess,
  payload
})

export const fetchDriversListError = (error) => ({
  type: types.fetchDriversListError,
  payload: error
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const saveDriver = (payload) => ({
  type: types.saveDriver,
  payload
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const setDataLoaded = ({data = {}, page, filters, sort, limit}) => {
  return {
    type: types.dataLoaded,
    payload: {data, page, filters, sort, limit}
  }
}

export const updateDriver = (payload) => ({
  type: types.updateDriver,
  payload
})

export const updateVisitedPage = (payload) => ({
  type: types.updateVisitedPage,
  payload
})

export const resetDriver = () => ({
  type: types.resetDriver
})

export const fetchDriverImage = (payload) => ({
  type: types.fetchDriverImage,
  payload
})

export const setDriverImage = (payload) => ({
  type: types.setDriverImage,
  payload
})

export const loadDriver = (payload) => ({
  type: types.loadDriver,
  payload
})

export const driverSet = (payload) => ({
  type: types.driverSet,
  payload: payload
})

export const fetchDriverOptions = (payload) => ({
  type: types.fetchDriverOptions,
  payload
})

export const fetchDriverOptionsSuccess = (payload) => ({
  type: types.fetchDriverOptionsSuccess,
  payload
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})

export const setFormSuccess = (driverId) => () => {
  getHistory().replace(`/drivers/${driverId}`)
}

export const setFormError = () => ({
  type: types.formError,
  payload: true
})
