import {createSelector} from 'reselect'

const selectorPath = (state) => state.category

export const getCount = (state) => selectorPath(state).count
export const getError = (state) => selectorPath(state).error
export const getCategoryList = (state) => selectorPath(state).categoryList
export const isFetchingList = (state) =>
  selectorPath(state).isFetchingCategoryList
export const isDownloadingCategoryItem = (state) =>
  selectorPath(state).isDownloadingCategoryItem
export const isDownloadingCategory = (state) =>
  selectorPath(state).isDownloadingCategory
export const isUploadingCategoryData = (state) =>
  selectorPath(state).isUploadingCategoryData
export const hasUploadError = (state) =>
  Boolean(selectorPath(state).uploadError)
export const getFields = (state) => selectorPath(state).list.fields

export const getLimit = (state) => {
  const {limit} = selectorPath(state).list

  return limit ? limit : 25
}

export const getPage = (state) => {
  const {page} = selectorPath(state).list

  return page ? page : 1
}
