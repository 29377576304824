import {bulkUploadConfig} from './bulkUploadConfig'
import {countryOptionConfig} from '../countryOption/countryOptionConfig'

export const b2bDealConfig = {
  path: 'b2bdeal',
  label: 'menu.b2bDeal',
  key: 'bulkUpload',
  children: [bulkUploadConfig, countryOptionConfig],
  Bulkupload: {
    fields: bulkUploadConfig.fields
  },
  countryOption: {
    type: countryOptionConfig.type,
    fields: countryOptionConfig.fields
  },
  permissions: {
    entity: 'web.admin.ui.car.bulkUpload',
    types: ['READ']
  }
}
