import {orderContactIdField} from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import {formatCountry} from './../common/helpers'
import {getCountryConfig} from '../../../../app/config/country'

const client = new ApiClient('order')

export function createOrderContactWithBPId(orderId, businessPartnerId) {
  const country = getCountryConfig().countryCode

  return client
    .withTransform([formatCountry])
    .mutation(
      'createOrderContact',
      {orderId, businessPartnerId, country},
      orderContactIdField
    )
}
