const dealerDetails = require('./dealerManagement/ar')
const crmDetails = require('./CRM/ar')
const bulkuploadDetails = require('./bulkUpload/ar')
const carDataDetails = require('./carData/ar')
const locationDetails = require('./locationManagement/ar')
const payslipDetails = require('./payslips/ar')
const carOwnership = require('./carOwnership/ar')
const businessPartnersDetails = require('./businessPartnersDetails/ar')

module.exports = {
  url: 'olxautos.com.ar',
  isMTCMSEnabled: true,
  siteCode: 'olxar',
  apiDomain: 'api.olx.com.ar',
  name: 'Argentina',
  countryCode: 'AR',
  theme: 'argentina',
  language: {
    selected: 'es-ar',
    options: [
      {value: 'es-ar', label: 'ES'},
      {value: 'en-ar', label: 'EN'}
    ]
  },
  locales: ['es-ar', 'en-ar'],
  currency: {code: 'ARS', symbol: '$'},
  number: {decimalSep: ',', unitSep: '.', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 54},
  referralCodeType: 'input',
  readOnlyPaths: ['dealers'],
  googleKey:
    '940673976550-naj8k2mhp404ljudl23fsgmug4vr9bhf.apps.googleusercontent.com',
  createCarViaAppointment: true,
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'licensePlate',
  bulkuploadDetails,
  locationDetails,
  carDataDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'locations',
    'resourceManager',
    'bulkUpload',
    'wms',
    'attendanceManager',
    'documentManagement',
    'tms',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: false
  },
  callingCode: '+54',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  bpBasedTaxInfoConditions: businessPartnersDetails.bpBasedTaxInfoConditions,
  categoryId: 378,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: true,
  disableOtherFreeTextInDropdown: true,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  isNotificationsEnabled: false
}
