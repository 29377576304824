import DocumentConfigEdit from '../../../pages/DocumentConfig/Editor'
import {default as documentConfigFormConfig} from './documentConfigFormConfig'

export default {
  path: 'document-config/create',
  key: 'documentConfigCreate',
  component: DocumentConfigEdit,
  label: 'documentConfigCreate',
  ...documentConfigFormConfig
}
