import styled from 'styled-components'
import {translate} from 'react-i18next'
import DocumentList from './List'
import Upload from './index'
import {RenderIf} from '../RenderIf'

const ManageDocuments = styled.div`
  width: 100%;
`
const Title = styled.h4`
  color: #242424;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
`
const Divider = styled.hr`
  border: 1px solid #bbbbbb;
`
const Uploader = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DocumentUpload = (props) => (
  <ManageDocuments data-test='document-upload-form'>
    <RenderIf if={props.title}>
      <Title>{props.t(props.title)}</Title>
      <Divider />
    </RenderIf>
    <Uploader>
      <DocumentList
        listWidth={props.listWidth}
        dealerFileTypeOptions={props.fileTypeOptions}
        reference={props.reference}
        fetchOnlyCurrentDocs={props.fetchOnlyCurrentDocs}
        hasPreviewMode={false}
        customColumns={props.customColumns}
        hiddenColumns={props.hiddenColumns}
        rowHeight={props.rowHeight}
        tableHeight={props.tableHeight}
        documentErrors={props.documentErrors}
        onDocumentDelete={props.onDocumentDelete}
      />
      <Upload
        uploadAreaWidth={props.uploadAreaWidth}
        entity={props.entity}
        reference={props.reference}
        visibility={props.visibility}
        status={props.status}
        isUploading={props.isUploading}
        uploadDocument={props.uploadDocument}
        setUploading={props.setUploading}
        topMargin={false}
        leftMargin
        maxFileSize={props.maxFileSize}
      />
    </Uploader>
  </ManageDocuments>
)

export default translate()(DocumentUpload)
