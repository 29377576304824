import DDayBookings from '../../pages/DDayBookings'

export default {
  path: 'dday-Bookings/',
  exact: false,
  label: 'D-Day Bookings',
  key: 'ddayBookings',
  component: DDayBookings,
  fields: {}
}
