import {call, put, select, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as CountryOptionApi from '../../api/countryOption/requests'
import gql from '@fcg/lib-gql/gql'
import pickBy from 'lodash/pickBy'
import {default as selectors} from './selectors'
import {loadOptions} from '../../store/crm/actions'

function* fetchCountryOptionListSaga() {
  try {
    const countryCode = yield select(getCountryCode)
    const type = yield select(selectors.getCountryOptionType)
    let sort = yield select(selectors.getSort)

    sort =
      sort && Array.isArray(sort) && sort.length
        ? {...sort[0], order: new gql.EnumType(sort[0].order)}
        : null

    const options = {
      type,
      sort,
      country: new gql.EnumType(countryCode)
    }
    const countryOptionList = yield call(CountryOptionApi.getCountryOptions, {
      ...pickBy(options)
    })

    yield put({
      type: types.fetchCountryOptionListSuccess,
      payload: countryOptionList.countryOptions
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchCountryOptionListError,
      payload: e
    })
  }
}

function* deleteCountryOptionSaga(action) {
  try {
    yield call(CountryOptionApi.deleteCountryOption, {id: action.payload.id})

    yield put({
      type: types.deleteCountryOptionSuccess
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: action.payload.message,
        open: true
      })
    )
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* createCountryOptionSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const type = yield select(selectors.getCountryOptionType)
    const options = {
      type,
      country: new gql.EnumType(countryCode),
      ...action.payload
    }

    yield call(CountryOptionApi.createCountryOption, options)

    yield put(loadOptions())

    yield put({
      type: types.createCountryOptionSuccess
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.errors ? error.errors[0].message : error.message,
        open: true
      })
    )

    yield put({
      type: types.createCountryOptionError,
      error: error
    })
  }
}

export const sagas = [
  takeLatest(types.fetchCountryOptionList, fetchCountryOptionListSaga),
  takeLatest(types.createCountryOptionSuccess, fetchCountryOptionListSaga),
  takeLatest(types.createCountryOption, createCountryOptionSaga),
  takeLatest(types.deleteCountryOptionSuccess, fetchCountryOptionListSaga),
  takeLatest(types.deleteCountryOption, deleteCountryOptionSaga),
  takeLatest(types.toggleSort, fetchCountryOptionListSaga)
]
