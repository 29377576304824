import {PureComponent, createRef} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {FlexCell, colors} from './common'
import TableCell from './Cell'
import {RenderIf} from '../../../../components/RenderIf'

const TimeslotCell = styled(FlexCell).attrs(() => ({
  width: 'xl'
}))`
  z-index: 2;
  display: flex;
  position: ${({sticky}) => (sticky ? 'sticky' : 'static')};
  left: ${({sticky}) => (sticky ? 0 : 'auto')};
  justify-content: center;
  align-items: flex-start;
  border-right: solid 1px ${() => colors.cellBorder};
  font-size: 12px;
  font-weight: 500;
  color: #3c4043;
  letter-spacing: 0.2px;
  background-color: #fafafa;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 48px;
  position: relative;
`
const Timeline = styled.span`
  position: absolute;
  top: ${({top}) => top}px;
  width: 100%;
  background-color: ${colors.defaultCardColor};
  display: inline-block;
  height: 2px;
`
const ONE_MINUTE = 1000 * 60

export class TableRowBase extends PureComponent {
  constructor(props) {
    super(props)

    this.rowRef = createRef()
    this.state = {timelineOffset: this.getTimelineOffset()}
  }

  componentDidMount = () => {
    if (!this.props.showTimeline) {
      return
    }

    this.setupInterval()
  }

  componentDidUpdate = () => {
    if (!this.props.showTimeline && this.state.intervalRef) {
      clearTimeout(this.state.intervalRef)
    }

    if (this.props.showTimeline && !this.state.intervalRef) {
      this.setupInterval()
    }
  }

  componentWillUnmount = () => {
    if (this.state.intervalRef) {
      this.clearInterval()
      this.setState({
        intervalRef: null
      })
    }
  }

  getTimelineOffset = () => {
    if (!this.rowRef || !this.rowRef.current) {
      return 0
    }

    const rowRect = this.rowRef.current.getBoundingClientRect()

    return Number(rowRect.height * (new Date().getMinutes() / 60)).toFixed(2)
  }

  clearInterval = () => {
    if (!this.state.intervalRef) {
      return
    }

    clearInterval(this.state.intervalRef)
  }

  setupInterval = () => {
    this.clearInterval()

    const intervalRef = setInterval(() => {
      if (!this.state.intervalRef) {
        return
      }

      this.setState({
        timelineOffset: this.getTimelineOffset()
      })
    }, ONE_MINUTE)

    this.setState({
      intervalRef,
      timelineOffset: this.getTimelineOffset()
    })

    return intervalRef
  }

  onCellClick = (cellIndex) => {
    if (typeof this.props.onCellClick !== 'function') {
      return
    }

    this.props.onCellClick(this.props.rowId, cellIndex)
  }

  render() {
    const {rowId, cells, isOddRow, showTimeline} = this.props

    return (
      <Row ref={this.rowRef}>
        <TimeslotCell key={`timeslot-${rowId}`} sticky>
          {rowId.slice(0, 5)}
        </TimeslotCell>
        {cells.map((cell, i) => (
          <TableCell
            key={`${rowId}-cell-${i}`}
            cell={cell}
            rowId={rowId}
            cellIndex={i}
            isInOddRow={isOddRow}
            onCellClick={this.onCellClick}
          />
        ))}
        <RenderIf if={showTimeline}>
          <Timeline top={this.state.timelineOffset} />
        </RenderIf>
      </Row>
    )
  }
}

TableRowBase.propTypes = {
  showTimeline: PropTypes.bool,
  rowId: PropTypes.string,
  cells: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.objectOf(
          PropTypes.shape({
            appointments: PropTypes.array
          })
        ),
        header: PropTypes.string
      })
    ),
    PropTypes.array
  ]),
  isOddRow: PropTypes.bool
}

export default TableRowBase
