import {NumberInput} from '@fcg/form-builder'
import withConfig from '../providers/withConfig'
import omit from 'lodash/omit'

export const NumberWithConfig = withConfig(['number'], (props) => {
  const newProps = omit(props, ['number', 'dispatch'])
  const {number} = props

  return <NumberInput config={{number}} {...newProps} />
})
