import * as types from './types'

export const fetchParticipants = (payload) => ({
  type: types.fetchParticipants,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const addParticipant = (payload) => ({
  type: types.addParticipant,
  payload
})

export const removeParticipant = (payload) => ({
  type: types.removeParticipant,
  payload
})

export const updateParticipant = (payload) => ({
  type: types.updateParticipant,
  payload
})
