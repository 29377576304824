export const CAR = 'CAR'
export const TRUCK = 'TRUCK'
export const TRANSIT = 'transit'
export const JOB_TYPE = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff'
}
export const ErrorTypes = {
  required: 'form.message.validation.required',
  unique: 'form.message.validation.unique'
}

export const STATUS_TYPE = {
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  FINISHED: 'finished',
  RUNNING: 'running',
  ABORTED: 'aborted',
  REJECTED: 'rejected'
}

export const hasUpdatePermission = (allowedStatus, currentStatus) => {
  if (!allowedStatus || !currentStatus || !Array.isArray(allowedStatus)) {
    return false
  }

  return allowedStatus.includes(currentStatus)
}

export const statusTransitJobs = Object.values(STATUS_TYPE)

const options = statusTransitJobs.reduce(
  (acc, optionType) => ({
    ...acc,
    [`${optionType}Option`]: {
      value: optionType,
      label: `transitJobs.field.status.${optionType}`
    }
  }),
  {}
)

const {
  pendingOption,
  scheduledOption,
  finishedOption,
  runningOption,
  abortedOption,
  rejectedOption
} = options

export const statusOptions = {
  pending: [pendingOption, scheduledOption, abortedOption, rejectedOption],
  rejected: [rejectedOption, pendingOption, abortedOption],
  scheduled: [scheduledOption, runningOption, abortedOption],
  running: [runningOption, finishedOption, abortedOption],
  aborted: [abortedOption],
  finished: [finishedOption]
}
