const extractReason = (reason) => ({label: reason, value: reason})

export const getFormAppointmentOptions = (formOptions, data) => {
  let rejectionReasons = []
  let cancellationReason = []
  let newFormOptions = {...formOptions}

  if (formOptions.appointmentStatus && data) {
    const formOptionStatus = formOptions.appointmentStatus.find(
      (appointment) => {
        if (data && data.status) {
          return appointment.label.toLowerCase() === data.status.toLowerCase()
        }

        return false
      }
    )

    if (formOptionStatus?.metaInfo) {
      if (formOptionStatus.metaInfo.rejectionReason) {
        rejectionReasons = formOptionStatus.metaInfo.rejectionReason.map(
          extractReason
        )
      }

      if (formOptionStatus.metaInfo.cancellationReason) {
        cancellationReason = formOptionStatus.metaInfo.cancellationReason.map(
          extractReason
        )
      }
    }
  }

  if (rejectionReasons?.length > 0) {
    newFormOptions = {
      ...newFormOptions,
      rejectionReasons
    }
  }

  if (cancellationReason?.length > 0) {
    newFormOptions = {
      ...newFormOptions,
      cancellationReason
    }
  }

  return newFormOptions
}
