import {
  STATUS_ARRAY,
  VALUES,
  dealerSize,
  personType
} from '../../config/entities/user'

const status = STATUS_ARRAY.map((status) => ({
  value: status,
  label: `dealer.page.field.status.${status}`
}))

const invoicingType = ['FULL_VAT_INVOICE', 'EMARKET_SERVICE_INVOICE'].map(
  (type) => ({
    value: type,
    label: `dealer.page.field.invoicingType.${type}`
  })
)

const storageTime = ['2_Days', '2-4_Days', '4-10_Days', '10+_Days'].map(
  (value) => ({
    value,
    label: `dealer.page.field.storageTime.${value}`
  })
)

const expectedDelivery = [
  'Abu_Dhabi',
  'Dubai',
  'Sharjah',
  'Ajman',
  'Others'
].map((value) => ({
  value,
  label: `dealer.page.field.expectedDelivery.${value}`
}))

const expectedCarCare = [
  'Car_Wash',
  'Car_Polish',
  'RTA_Shamel',
  'Tyre_change'
].map((value) => ({
  value,
  label: `dealer.page.field.expectedCarCare.${value}`
}))

const expectedFinancing = [
  'One_Week',
  'Two_Weeks',
  'One_Month',
  'Two_Months'
].map((value) => ({
  value,
  label: `dealer.page.field.expectedFinancing.${value}`
}))

const dealerAppsChannels = ['Android', 'IOS', 'Web'].map((channel) => ({
  value: channel,
  label: `dealer.page.field.dealerAppsChannels.${channel}`
}))

const onboardingRejectionReason = ['DUPLICATE', 'TEST', 'INCOMPLETE_DATA'].map(
  (reason) => ({
    value: reason,
    label: `dealer.page.field.onboardingRejectionReason.${reason}`
  })
)

const isNewsLetterAccepted = [
  {value: VALUES.YES, label: 'global.yes'},
  {value: VALUES.NO, label: 'global.no'}
]

export const state = {
  fetching: false,
  data: null,
  error: null,
  dealerOptions: {
    dealerSize,
    blockedReason: [],
    inactiveReason: [],
    rejectedReason: [],
    onboardingStatus: [],
    dealerManager: [],
    status,
    personType,
    invoicingType,
    onboardingAgentName: [],
    storageTime,
    expectedDelivery,
    expectedCarCare,
    expectedFinancing,
    dealerAppsChannels,
    onboardingRejectionReason,
    isNewsLetterAccepted,
    dealerFarmer: [],
    dealerHunter: [],
    dealerBidManager: [],
    companyType: []
  },
  dealer: {
    loaded: false,
    id: null,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    internalId: '',
    metaInfo: {
      companyName: '',
      legalCompanyName: '',
      bankDetails: '',
      businessType: '',
      businessCategory: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      zipCode: '',
      city: '',
      district: '',
      personType: '',
      paymentTerms: '',
      registrationId: '',
      taxId: '',
      taxOffice: null,
      onboardingAgentName: null,
      carPreferences: null,
      dealerCategory: '',
      dealerManager: null,
      dealerManagerName: null,
      dealerSize: null,
      dealerLocations: null,
      comments: '',
      status: null,
      maxAmountBids: null,
      authorizedPickupPersons: null,
      blockedReason: null,
      legalRepresentativeName: '',
      legalRepresentativeLastName: '',
      legalRepresentativeNationality: '',
      legalRepresentativeProfesion: '',
      legalRepresentativeId: '',
      dealerTaxOfficeCommune: '',
      dealerTaxOfficeCity: '',
      dealersPublicDeedIncorporationDate: '',
      dealersPublicDeedNotary: '',
      dealersPublicDeedNotaryCity: '',
      virtualAccountOrWalletId: '',
      competitors: '',
      guarantees: '',
      keyContacts: '',
      averageStock: null,
      idealStock: null,
      legallyRegistered: null,
      taxIdExpiryDate: '',
      nationality: '',
      phone2: '',
      companyTradeLicense: '',
      companyTaxId: '',
      companyTransportAuthorityNo: '',
      systemSetCreditLimit: '',
      systemSetDepositAmount: '',
      deposit: '',
      registrationType: '',
      invoicingType: '',
      storageTime: '',
      expectedDelivery: '',
      expectedCarCare: '',
      expectedFinancing: '',
      legalRepresentativePhone: '',
      legalRepresentativeEmail: '',
      legalAdress: '',
      employeeCount: '',
      carPreferencesPrice: '',
      monthlyTurnover: '',
      estimatedMargin: '',
      dealerAppsChannels: '',
      bankAccountNumber: '',
      bankAccountOwner: '',
      onboardingDecisionDate: '',
      onboardingRejectionReason: '',
      dealerFarmer: null,
      dealerFarmerName: null,
      internalId: '',
      dealerHunter: null,
      dealerHunterName: null,
      dealerBidManager: null,
      dealerBidManagerName: null,
      depositBalance: 0,
      numberPurchasableCar: 0,
      isNewsLetterAccepted: null,
      companyTypeId: null,
      regon: null,
      postCode: null
    }
  },
  pageSetup: null,
  limit: null,
  page: null,
  fields: null,
  filters: [],
  orderBy: null,
  visitedPage: [],
  readOnlyPath: false
}

export const dealerProfileInitalState = {
  firstname: '',
  lastname: '',
  email: '',
  internalId: '',
  metaInfo: {
    status: '',
    phone: '',
    phone2: '',
    street: '',
    city: '',
    zipCode: '',
    internalId: ''
  }
}

export const getInitialState = (config, preferences) => ({
  ...state,
  dealerOptions: {
    ...state.dealerOptions,
    registrationType: getRegistrationTypes(config.countryCode),
    businessCategory: getBusinessCategories(config.countryCode)
  },
  pageSetup: config.pages.dealers,
  fields: Object.values(config.pages.dealers.dealerListConfig.fields),
  limit: config.pages.dealers.dealerListConfig.limit,
  sort: config.pages.dealers.dealerListConfig.sort,
  page: config.pages.dealers.dealerListConfig.page,
  dealerProfile: {
    data: {...dealerProfileInitalState},
    fetching: false,
    error: null
  },
  ...preferences
})

// TODO: It has to move to country options database for Emirates and Indonesia
const getRegistrationTypes = (country) => {
  let types = []

  if (country === 'ID') {
    types = ['KTP', 'SIM', 'PASSPORT']
  } else if (country === 'AE') {
    types = ['DIRECT_HEYAZA', 'NON_DIRECT']
  }

  return types.map((type) => ({
    value: type,
    label: `dealer.page.field.registrationType.${type}`
  }))
}

export const getBusinessCategories = (country) => {
  const businessCategories = [
    {value: 'dealer', label: 'dealer.page.businessCategory.dealer'},
    {value: 'broker', label: 'dealer.page.businessCategory.broker'}
  ]

  if (country === 'PK') {
    return [
      ...businessCategories,
      {value: 'merchant', label: 'dealer.page.businessCategory.merchant'},
      {value: 'investor', label: 'dealer.page.businessCategory.investor'}
    ]
  }

  if (country === 'AE') {
    return [
      ...businessCategories,
      {
        value: 'oemDealership',
        label: 'dealer.page.businessCategory.oemDealership'
      },
      {
        value: 'internalUser',
        label: 'dealer.page.businessCategory.internalUser'
      }
    ]
  }

  return businessCategories
}
