import {Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  fetchCarDataFilesList,
  toggleField,
  toggleSort,
  updateFilters
} from '../../../store/cardata/carDataFiles/actions'
import PageTableBase from '../../../components/PageTableBase'
import * as selectors from '../../../store/cardata/carDataFiles/selectors'
import styled from 'styled-components'
import DateFormat from '../../../components/Format/DateFormat'
import {downloadDocument} from '../../../store/documentConfig/actions'

const CarDataFilesWrapper = styled.div`
  tr {
    td:first-child,
    th:first-child {
      padding-left: 24px;
    }
  }
`

function CarDataFilesList(props) {
  return (
    <Fragment>
      <CarDataFilesWrapper className='page'>
        <PageTableBase
          {...props}
          onRowClick={props.downloadDocument}
          multisort={false}
          title='entity.carDatafiles'
          extraComponents={{DateFormat}}
          rowsPerPageOptions={[25, 50, 100]}
          selectable={false}
          filterable
        />
      </CarDataFilesWrapper>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  data: selectors.getCarDataFilesList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  sort: selectors.getSort(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (options) => dispatch(fetchCarDataFilesList(options)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  onFilterChange: ({filters}) => dispatch(updateFilters(filters)),
  downloadDocument: (e, document = {}) => {
    dispatch(downloadDocument(document.id))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CarDataFilesList))
