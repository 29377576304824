import * as superadmin from './superadmin'
import * as crm from './crm'
import * as dealer from './dealer'
import * as locationmanager from './locationmanager'
import * as locationmanagerreadonly from './locationmanagerreadonly'
import * as printPreferences from './printPreferences'
import * as usermanager from './usermanager'
import * as cardata from './cardata'
import * as sales from './sales'
import * as cars from './cars'
import * as resourcemanager from './resourcemanager'
import * as stockyardmanager from './stockyardmanager'
import * as cityhead from './cityhead'
import * as carbuymanager from './carbuymanager'
import * as ams from './ams'
import * as wms from './wms'
import * as tms from './tms'
import * as documentmanagement from './documentmanagement'

export default {
  superadmin,
  crm,
  dealer,
  locationmanager,
  locationmanagerreadonly,
  printPreferences,
  usermanager,
  cardata,
  sales,
  cars,
  resourcemanager,
  stockyardmanager,
  cityhead,
  carbuymanager,
  ams,
  wms,
  documentmanagement,
  tms
}
