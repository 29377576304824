import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'
import {translate} from 'react-i18next'

export const FilterSaveDialog = (props) => {
  const {t} = props

  return (
    <Dialog open={props.isOpen} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>
        {t('filters.save.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('filters.save.dialog.info')}</DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='saved_filter_name'
          label={t('filters.save.dialog.filtername.label')}
          type='text'
          onChange={props.handleChange}
          value={props.value}
          fullWidth
          data-test='save-filter-name'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close}>{t('button.cancel')}</Button>
        <Button
          onClick={props.save}
          variant='raised'
          color='primary'
          data-test='save-current-filter'
        >
          {t('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default translate()(FilterSaveDialog)
