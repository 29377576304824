const contactFields = ['firstname', 'lastname', 'phone']

const appointmentDependencies = [
  'type',
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'mileage',
  'transmission'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]

const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  },
  'VINnumber'
]
const carDataPicker = {
  dependencies: {
    location: ['make', 'model', 'year', 'trim'],
    mileage: ['make', 'model', 'year', 'trim']
  }
}
const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

const purchaseCoordinatorInput = {
  key: 'group',
  queryValue: {include: ['CMS', 'INSPECTOR']}
}

module.exports = {
  required: [
    {
      field: 'type',
      dependencies: appointmentDependencies
    },
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation
  ],
  hidden: [
    'lead.bodyType',
    'lead.faceLift',
    'lead.seat',
    'lead.engine',
    'lead.wheelDrive',
    'tierAppointmentStatus',
    'tierLocation',
    'externalLeadId',
    'city',
    'postcode',
    'lead.location',
    'ownerName',
    'ownerPhone',
    'selfInspectionPrice',
    'purchaseCoordinator',
    'purchaseCoordinatorPhone'
  ],
  landingPage: ['tasks', 'leads'],
  hiddenColumns: [
    'tierAppointmentStatus',
    'tierLocation',
    'plate',
    'city',
    'postcode',
    'lead.status',
    'leadResponsibleName',
    'updated',
    'taxId',
    'VINnumber',
    'selfInspectionExists',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'leadCity',
    'sourcePathVariant',
    'carInfoExtraFields',
    'carInfoExtraFieldsAppointment'
  ],
  hiddenFilters: [
    'tierAppointmentStatus',
    'tierLocation',
    'tierMake',
    'city',
    'postcode',
    'taxId',
    'tierLeadSource',
    'tierLeadStatus',
    'appointmentAddress',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'sourcePathVariant'
  ],
  hiddenFeatures: [],
  disabledFields: {walkin: {leadForm: ['price']}},
  carDataPicker,
  purchaseCoordinatorInput
}
