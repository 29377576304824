export const draftNew = 'dealers/DRAFT_NEW'
export const fetching = 'dealers/FETCHING'
export const error = 'dealers/ERROR'
export const dataLoaded = 'dealers/DATA_LOADED'
export const optionsAdded = 'dealers/OPTIONS_ADDED'
export const loadDealer = 'dealers/LOAD_DEALER'
export const dealerSet = 'dealers/DEALER_SET'
export const fetchDealerMetaData = 'dealer/FETCH_DEALER_META_DATA'
export const findUserFromService = 'dealer/FIND_USER_FROM_SERVICE'
export const dealerManagerNameSet = 'dealers/DEALERMANAGER_SET'
export const onboardingAgentNameSet = 'dealers/ONBOARDINGMANAGER_SET'
export const dealerFarmerNameSet = 'dealers/DEALERFARMER_SET'
export const dealerHunterNameSet = 'dealers/DEALERHUNTER_SET'
export const dealerBidManagerNameSet = 'dealers/DEALERBIDMANAGER_SET'
export const toggleField = 'dealers/TOGGLE_FIELD'
export const changePage = 'dealers/CHANGE_PAGE'
export const toggleSort = 'dealers/TOGGLE_SORT'
export const updateFilters = 'dealers/UPDATE_FILTER'
export const dealerUpdateSuccess = 'dealers/DEALER_UPDATE_SUCCESS'
export const resetDealer = 'dealers/RESET_DEALER'
export const fetchDealerProfile = 'dealers/DEALER_PROFILE_REQUEST'
export const fetchDealerProfileSuccess = 'dealers/DEALER_PROFILE_SUCCESS'
export const fetchDealerProfileError = 'dealers/DEALER_PROFILE_ERROR'
export const resetDealerProfileData = 'dealers/RESET_DEALER_PROFILE_DATA'
export const updateVisitedPage = 'dealers/UDPATE_VISITED_PAGE'
export const updateDealer = 'dealers/UPDATE_DEALER'
export const setDealerLoading = 'dealer/SET_DEALER_LOADING'
export const setPathMode = 'dealer/SET_PATH_MODE'
export const setPathReadOnly = 'dealer/SET_PATH_READ_ONLY'
