import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Upload from '../../components/Upload'
import DocumentList from '../../components/Upload/List'

import {
  uploadDocumentDriver,
  setUploading,
  fetchDocumentList
} from '../../store/documentConfig/actions'
import {default as selectors} from '../../store/driver/selectors'
import * as documentSelectors from '../../store/documentConfig/selectors'
import styled from 'styled-components'

const DocumentContainer = styled.div`
  display: flex;
  max-width: 1220px;
  flex-wrap: wrap;
  margin: 54px auto;
  justify-content: space-evenly;
`

class RightPanelDocuments extends Component {
  componentDidMount() {
    const {driverId} = this.props

    if (driverId) {
      this.props.fetchDocumentList({entity: 'driver', reference: [driverId]})
    }
  }

  render() {
    return (
      <DocumentContainer>
        <DocumentList
          userId={this.props.userId}
          dealerFileTypeOptions={this.props.dealerFileTypeOptions}
        />
        <Upload
          reference='driver'
          visibility='internal'
          entity='driver'
          status='reviewPending'
          isUploading={this.props.isUploading}
          uploadDocument={this.props.uploadDocument}
          setUploading={this.props.setUploading}
        />
      </DocumentContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  isUploading: documentSelectors.isUploading(state),
  driverId: selectors.getDriverId(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchDocumentList: (payload) => dispatch(fetchDocumentList(payload)),
  uploadDocument: (payload) => dispatch(uploadDocumentDriver(payload)),
  setUploading: (payload) => dispatch(setUploading(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(RightPanelDocuments))
