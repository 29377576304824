import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {Snackbar, withStyles} from '@material-ui/core'
import {resetNotification} from '../../../store/global/actions'
import ExpandErrorAction from './ExpandErrorAction'
import styles from './notificationStyles'

export const Notification = (props) => {
  const {t, message, classes, action, entity, type} = props
  const types = {
    success: t('global.success.title'),
    error: t('global.error.dialog.title'),
    regular: ''
  }
  const className = classes[type]
  const entityLabel = typeof entity !== 'undefined' ? t(`entity.${entity}`) : ''
  const notification = (
    <span className={className}>
      {`${types[type]}: ${entityLabel} ${t(message)}`}
    </span>
  )

  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      autoHideDuration={4000}
      onClose={props.close}
      message={notification}
      action={action}
    />
  )
}

Notification.defaultProps = {
  type: 'regular',
  message: '',
  action: null
}

const mapStateToProps = ({global}) => {
  const {notification} = global

  if (notification === false) {
    return {open: false}
  }

  const {message, entity, action, type} = notification

  return {
    message,
    entity,
    action:
      typeof action === 'undefined' && type === 'error' ? (
        <ExpandErrorAction />
      ) : (
        action
      ),
    type,
    open: message !== ''
  }
}

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(resetNotification())
})

const translated = translate()(withStyles(styles)(Notification))
export default connect(mapStateToProps, mapDispatchToProps)(translated)
