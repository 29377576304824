export default () => (
  <svg width='88' height='64' viewBox='0 0 88 64'>
    <defs>
      <path id='prefix__a' d='M0 0.409L87.525 0.409 87.525 51.901 0 51.901z' />
      <path
        id='prefix__c'
        d='M0.723 0.567L67.247 0.567 67.247 12.222 0.723 12.222z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(0 .406)'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <path
          fill='silver'
          d='M10.893 51.9c-4.13 0-6.68-2.54-6.68-6.678V30.13c0-3.218 1.539-5.475 4.172-6.32H4.18c-2.3 0-4.18-1.88-4.18-4.18v-1.313c0-2.298 1.88-4.18 4.18-4.18h4.348c1.388 0 2.625.685 3.386 1.739l1.997-8.787c.911-4.03 2.543-6.68 6.68-6.68h46.342c4.139 0 5.769 2.65 6.689 6.68l1.99 8.787c.76-1.054 1.998-1.739 3.386-1.739h4.346c2.3 0 4.181 1.882 4.181 4.18v1.313c0 2.3-1.881 4.18-4.18 4.18h-4.197c2.633.845 4.172 3.102 4.172 6.32v15.092c0 4.137-2.55 6.679-6.688 6.679H10.893z'
          mask='url(#prefix__b)'
        />
      </g>
      <g transform='translate(9.778 51.74)'>
        <mask id='prefix__d' fill='#fff'>
          <use xlinkHref='#prefix__c' />
        </mask>
        <path
          fill='#696969'
          d='M16.207.567v7.475c0 2.299-1.882 4.18-4.181 4.18H4.903c-2.299 0-4.18-1.881-4.18-4.18V.567h15.484zm35.557 0v7.475c0 2.299 1.88 4.18 4.18 4.18h7.122c2.3 0 4.18-1.881 4.18-4.18V.567H51.765z'
          mask='url(#prefix__d)'
        />
      </g>
      <path
        fill='#FEFEFE'
        d='M18.242 44.28c4.264 0 7.743-3.478 7.743-7.74 0-4.264-3.479-7.742-7.743-7.742s-7.741 3.478-7.741 7.741 3.477 7.742 7.741 7.742'
      />
      <path
        fill='#696969'
        d='M18.242 41.505c2.743 0 4.975-2.231 4.975-4.966 0-2.743-2.232-4.975-4.975-4.975-2.733 0-4.965 2.232-4.965 4.975 0 2.735 2.232 4.966 4.965 4.966M33.89 43.157l-4.024-1.131c1.534-5.456 7.421-9.267 14.319-9.267 6.902 0 12.788 3.812 14.311 9.27l-4.027 1.125c-1.005-3.601-5.33-6.213-10.284-6.213-4.953 0-9.282 2.613-10.295 6.216'
      />
      <path
        fill='#FEFEFE'
        d='M69.283 44.28c4.264 0 7.741-3.478 7.741-7.74 0-4.264-3.477-7.742-7.741-7.742-4.264 0-7.742 3.478-7.742 7.741s3.478 7.742 7.742 7.742'
      />
      <path
        fill='#696969'
        d='M69.283 41.505c2.742 0 4.974-2.231 4.974-4.966 0-2.743-2.232-4.975-4.974-4.975-2.734 0-4.966 2.232-4.966 4.975 0 2.735 2.232 4.966 4.966 4.966'
      />
      <path
        fill='#E9E9E9'
        d='M36.66 23.848H72.01L69.14 10.17c-.844-4.047-1.546-4.006-5.676-4.006H24.07c-4.139 0-4.833-.041-5.685 4.006l-2.86 13.677H36.66z'
      />
      <path
        fill='#FFF'
        d='M31.577 6.165L25.065 6.165 21.444 23.848 27.949 23.848z'
      />
    </g>
  </svg>
)
