import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const sales = merge(
  {
    homepage: '/login',
    menuItems: {
      hiddenFields: [
        'inspection',
        'crm',
        'locations',
        'documentConfig',
        'carData',
        'dealerCreate',
        'dealersList',
        'dealerDetail',
        'dealerEdit',
        'driversList',
        'driverDetail',
        'driverEdit',
        'carOwnershipTransfer',
        'crmusergroups',
        'leadAllocation',
        'transitJobs',
        'locationManager',
        'resourceManager',
        'wms',
        'attendanceManager',
        'documentmanagement',
        'ddayBookings',
        'tms'
      ]
    },
    permissions: {
      sales: new Set(['read'])
    }
  },
  defaultConfig
)

export const supervisor = merge(
  {
    ...sales,
    permissions: {
      sales: new Set(['read', 'create', 'update', 'remove'])
    }
  },
  defaultConfig
)

export default sales
