import {Component} from 'react'
import {Grid} from '@material-ui/core'
import {FormBuilder} from '@fcg/form-builder'

import Handlers from '../Handlers'
import {ConfigContext} from '../../../components/providers/withConfig'

export class TierEdit extends Component {
  static displayName = 'Tier Editor'

  static defaultProps = {
    creation: false
  }

  render() {
    const {t, data, options} = this.props
    const handlers = Handlers(this.props)

    if (!data) {
      return null
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <ConfigContext.Consumer>
              {(config) => (
                <FormBuilder
                  t={t}
                  data={{...data}}
                  options={options}
                  form={this.props.config.form}
                  onChangeHandlers={{
                    handleChange: handlers.handleChange,
                    handleSelectChange: handlers.handleSelectChange,
                    handleToggleChange: handlers.handleToggleChange,
                    handleTextInput: handlers.handleTextInput
                  }}
                  config={{...config, ...this.props.config}}
                  disabled={this.props.disabled}
                />
              )}
            </ConfigContext.Consumer>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default TierEdit
