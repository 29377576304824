import {call, put, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import * as signalTypes from '../signals/types'
import * as types from './types'
import {setDocumentPreviewUrl} from '../../store/documentConfig/actions'
import * as DocumentApi from '../../api/document/requests'

function* previewDocumentSaga(action) {
  try {
    if (!action.payload) {
      yield put(setDocumentPreviewUrl([]))
    } else {
      const {documentDownloadLink} = yield call(
        DocumentApi.documentDownloadLink,
        {
          documentIds: [action.payload]
        }
      )
      yield put(setDocumentPreviewUrl(documentDownloadLink))
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

export const sagas = [takeLatest(types.previewDocument, previewDocumentSaga)]
