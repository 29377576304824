export const getFormTypes = (entity) => ({
  updateForm: `${entity}/UPDATE`,
  hydrateForm: `${entity}/HYDRATE`,
  resetForm: `${entity}/RESET`,
  locationChange: `${entity}/LOCATION_CHANGE`,
  loadingSlots: `${entity}/LOADING_SLOTS`,
  formError: `${entity}/ERROR`,
  formSuccess: `${entity}/SUCCESS`,
  formPending: `${entity}/PENDING`
})

export const getSortTypes = (entity) => ({
  toggleSort: `${entity}/TOGGLE_SORT`
})

export const getColumnTypes = (entity) => ({
  toggleField: `${entity}/TOGGLE_FIELD`
})

export const getFilterTypes = (entity) => ({
  updateFilters: `${entity}/UPDATE_FILTER`
})

export const getDataLoadingTypes = (entity) => ({
  fetching: `${entity}/FETCHING`,
  error: `${entity}/ERROR`,
  dataLoaded: `${entity}/DATA_LOADED`,
  updateOne: `${entity}/UPDATE_ONE`
})
