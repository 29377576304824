import * as types from './types'
import * as tableDecorators from '../utils/tableDecorators'
import {state as initialState, dealerProfileInitalState} from './initialState'

const reducers = {
  [types.error]: (state, action) => {
    return {
      ...state,
      error: action.payload
    }
  },
  [types.dealerSet]: (state, action) => {
    const dealer = action.payload.user.list[0]

    if (dealer.metaInfo === null) {
      dealer.metaInfo = state.dealer.metaInfo
    }

    return {
      ...state,
      dealer: {loaded: false, password: '', confirmPassword: '', ...dealer}
    }
  },
  [types.setDealerLoading]: (state, action) => ({
    ...state,
    dealer: {
      ...state.dealer,
      loaded: action.payload
    }
  }),
  [types.updateDealer]: (state, action) => ({
    ...state,
    dealer: {...action.payload}
  }),
  [types.dealerManagerNameSet]: (state, action) => {
    const dealerManager = action.payload.user.list[0]
    const dealerManagerName = dealerManager
      ? `${dealerManager.firstname} ${dealerManager.lastname}`
      : ''

    return {
      ...state,
      dealer: {
        ...state.dealer,
        metaInfo: {
          ...state.dealer.metaInfo,
          dealerManagerName
        }
      }
    }
  },
  [types.onboardingAgentNameSet]: (state, action) => {
    const onboardingAgent = action.payload.user.list[0]
    const onboardingAgentName = onboardingAgent
      ? `${onboardingAgent.firstname} ${onboardingAgent.lastname}`
      : ''

    return {
      ...state,
      dealer: {
        ...state.dealer,
        metaInfo: {
          ...state.dealer.metaInfo,
          onboardingAgentName
        }
      }
    }
  },
  [types.dealerFarmerNameSet]: (state, action) => {
    const dealerFarmer = action.payload.user.list[0]
    const dealerFarmerName = dealerFarmer
      ? `${dealerFarmer.firstname} ${dealerFarmer.lastname}`
      : ''

    return {
      ...state,
      dealer: {
        ...state.dealer,
        metaInfo: {
          ...state.dealer.metaInfo,
          dealerFarmerName
        }
      }
    }
  },
  [types.dealerHunterNameSet]: (state, action) => {
    const dealerHunter = action.payload.user.list[0]
    const dealerHunterName = dealerHunter
      ? `${dealerHunter.firstname} ${dealerHunter.lastname}`
      : ''

    return {
      ...state,
      dealer: {
        ...state.dealer,
        metaInfo: {
          ...state.dealer.metaInfo,
          dealerHunterName
        }
      }
    }
  },
  [types.dealerBidManagerNameSet]: (state, action) => {
    const dealerBidManager = action.payload.user.list[0]
    const dealerBidManagerName = dealerBidManager
      ? `${dealerBidManager.firstname} ${dealerBidManager.lastname}`
      : ''

    return {
      ...state,
      dealer: {
        ...state.dealer,
        metaInfo: {
          ...state.dealer.metaInfo,
          dealerBidManagerName
        }
      }
    }
  },
  [types.resetDealer]: (state) => ({
    ...state,
    dealer: {
      ...initialState.dealer
    },
    visitedPage: []
  }),
  [types.optionsAdded]: (state, {payload}) => {
    return {
      ...state,
      dealerOptions: {
        ...state.dealerOptions,
        ...payload
      }
    }
  },
  [types.fetchDealerProfile]: (state) => ({
    ...state,
    dealerProfile: {
      ...state.dealerProfile,
      fetching: true,
      error: null
    }
  }),
  [types.fetchDealerProfileSuccess]: (state, action) => ({
    ...state,
    dealerProfile: {
      ...state.dealerProfile,
      fetching: false,
      error: null,
      data: action.payload
    }
  }),
  [types.fetchDealerProfileError]: (state, action) => ({
    ...state,
    dealerProfile: {
      ...state.dealerProfile,
      data: {},
      fetching: false,
      error: action.payload
    }
  }),
  [types.resetDealerProfileData]: (state) => ({
    ...state,
    dealerProfile: {
      data: {...dealerProfileInitalState},
      fetching: false,
      error: null
    }
  }),
  [types.updateVisitedPage]: (state, action) => ({
    ...state,
    visitedPage: [...state.visitedPage, action.payload]
  }),
  [types.setPathReadOnly]: (state, action) => ({
    ...state,
    readOnlyPath: action.payload
  })
}

export default {
  ...reducers,
  ...tableDecorators.withDataLoading(types),
  ...tableDecorators.withColumnVisibility(types),
  ...tableDecorators.withFilters(types),
  ...tableDecorators.withSingleSort(types)
}
