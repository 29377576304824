import gql from '@fcg/lib-gql/gql'
import {createSelector} from 'reselect'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'

import {getCountryConfig} from '../../../config/country'
import {getPhoneLink} from '../../pages/CRM/common/helpers'
import {getUser} from '../auth/selectors'

const pageSelectorPath = (state) => state.config.pages
const detailSelectorPath = (state) => state.detail.detail

export const checkConfigSelector = ({
  targetPath,
  test,
  testValue,
  propName,
  alternativeSource
}) => (state) => {
  let destVal = get(state, targetPath)

  if (!destVal) {
    destVal = get(alternativeSource(), targetPath)

    // For dev
    if (!destVal) {
      throw new Error('Check your sources')
    }
  }

  return {
    [propName]: test(destVal, testValue)
  }
}

export const checkMultiConfigSelector = (configs) => (state) =>
  configs.reduce((acc, tally) => {
    let destVal = get(state, tally.targetPath)

    if (!destVal) {
      destVal = get(tally.alternativeSource(), tally.targetPath)

      // For dev
      if (!destVal) {
        throw new Error('Check your sources')
      }
    }

    return {
      ...acc,
      [tally.propName]: tally.test(destVal, tally.testValue)
    }
  }, {})

export const testArrayAndValue = (foundVal, desiredVal) => {
  if (Array.isArray(desiredVal) && Array.isArray(foundVal)) {
    return desiredVal.some((v) => foundVal.includes(v))
  }

  if (Array.isArray(desiredVal) && !Array.isArray(foundVal)) {
    return desiredVal.includes(foundVal)
  }

  if (!Array.isArray(desiredVal) && Array.isArray(foundVal)) {
    return foundVal.includes(desiredVal)
  }

  return isEqual(foundVal, desiredVal)
}

// Implementation
const pagesConfigPath = ['pages', 'contactDetail', 'header', 'phone', 'canLink']
const checkForHiddenFeaturesPath = ['crmDetails', 'hiddenFeatures']
const checkForGroupPath = ['authReducer', 'isAuthenticated', 'user', 'groups']

const checkForFeatures = ['deleteButton']
const checkForGroups = ['usermanager.ae', 'superadmin']

const checkForCountryProp = 'isDeleteBtnDisabled'
const checkForGroupProp = 'isInSelectedGroup'

export const checkForCountrySelector = checkConfigSelector({
  targetPath: checkForHiddenFeaturesPath,
  test: testArrayAndValue,
  testValue: checkForFeatures,
  propName: checkForCountryProp,
  alternativeSource: getCountryConfig
})

export const checkForGroupSelector = checkConfigSelector({
  targetPath: checkForGroupPath,
  test: testArrayAndValue,
  testValue: checkForGroups,
  propName: checkForGroupProp,
  alternativeSource: () => set({}, checkForGroupPath, {})
})

export const checkForLinkablePhoneSelector = checkConfigSelector({
  targetPath: pagesConfigPath,
  test: testArrayAndValue,
  testValue: true,
  propName: 'canLinkPhone',
  alternativeSource: () => set({}, pagesConfigPath, {})
})

export const contactDetailSelector = (state) =>
  pageSelectorPath(state).contactDetail

export const getContactDetailHeaderConfig = (state) => {
  const detail = contactDetailSelector(state)

  if (!detail || !detail.header) {
    return {phone: {link: []}}
  }

  return detail.header
}

export const getContactDetailLinkConfig = (state) => {
  const user = getUser(state)
  const phone = detailSelectorPath(state).phone
  const link = getContactDetailHeaderConfig(state).phone.link

  return getPhoneLink(link)(user.email, phone)
}

export const contactDetailPhoneLinkConfigSelector = (state) => ({
  ...checkForLinkablePhoneSelector(state.config),
  link: getContactDetailLinkConfig(state)
})

export const getPhoneLinkConfig = (state) => ({
  ...checkForLinkablePhoneSelector(state.config),
  link: getContactDetailHeaderConfig(state).phone.link
})

// const groupSelector = (groups, prop) =>
//   checkConfigSelector({
//     targetPath: checkForGroupPath,
//     test: testArrayAndValue,
//     testValue: groups,
//     propName: prop,
//     alternativeSource: () =>
//       checkForGroupPath.reduce(
//         (acc, tally) => ({
//           ...acc,
//           [tally]: {}
//         }),
//         {}
//       )
//   })

const pathSelector = (path, defaultVal = []) => (state) => {
  const data = get(state, path)

  return data || defaultVal
}

const hiddenFeaturesSelector = pathSelector(
  ['config', 'crmDetails', 'hiddenFeatures'],
  []
)

const userGroupsSelector = pathSelector(
  ['authReducer', 'isAuthenticated', 'user', 'groups'],
  []
)

const conditionHandlers = {
  hiddenExceptFor: (groups) =>
    createSelector(
      userGroupsSelector,
      (usersGroups) => !usersGroups.some((g) => groups.includes(g))
    )
}

const checkConditions = (conditions, state) =>
  Object.keys(conditions).reduce((acc, conditionName) => {
    const props = conditions[conditionName]

    return acc && conditionHandlers[conditionName](props)(state)
  }, true)

export const getHiddenFeatures = (state) => {
  const hiddenFeatures = hiddenFeaturesSelector(state)

  return hiddenFeatures.reduce(
    (acc, tally) => ({
      ...acc,
      [tally.name]: checkConditions(tally.conditions, state)
    }),
    {}
  )
}

const enumerableFilters = ['status']

/** Ticket filter selectors */
export const transformTicketFilters = (filters) => {
  if (filters && Array.isArray(filters)) {
    return filters.reduce((acc, filter) => {
      if (filter.key == 'carInternalId' || filter.key == 'carId') {
        const filterKey = filter.key == 'carInternalId' ? 'internalId' : 'id'

        return {
          entity: {
            type: new gql.EnumType('CAR'),
            [filterKey]: filter.value
          }
        }
      }

      if (filter.key == 'dealerId') {
        return {
          entity: {
            type: new gql.EnumType('USER'),
            internalId: filter.value
          }
        }
      }

      return {
        ...acc,
        [filter.key]:
          Array.isArray(filter.value) && enumerableFilters.includes(filter.key)
            ? filter.value.map((v) => new gql.EnumType(v))
            : filter.value
      }
    }, [])
  }

  return []
}
