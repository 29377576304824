import {takeLatest, call, put, select} from 'redux-saga/effects'
import cookies from 'browser-cookies'
import * as types from './types'
import * as selectors from './selectors'
import * as OAuthApi from '../../api/oauth/requests'

function* oauth2LoginRequestSaga(action) {
  try {
    const {options, type} = action.payload
    const oauthResponse = yield call(OAuthApi.oauthLogin, {...options, type})
    const {redirectURI} =
      type === 'okta' || type === 'google'
        ? JSON.parse(localStorage.getItem('oAuthParams'))
        : yield select(selectors.getOAuthParams)

    if (!oauthResponse.response) {
      const {state, code, country_code} = oauthResponse.data

      yield put({
        type: types.oauth2LoginSuccess
      })

      let uri = `${redirectURI}?code=${code}&state=${state}`
      if (country_code) {
        uri = `${uri}&country_code=${country_code}`
      }
      // For debugging purpose only
      // console.log(uri)
      // Redirect to K2
      window.location.href = uri
    } else if (oauthResponse?.response?.data) {
      yield put({
        type: types.oauth2LoginError,
        payload: oauthResponse.response.data.error_message
      })
    }
  } catch (err) {
    yield put({type: types.oauth2LoginError, payload: err})
  }
}

function* handleOktak2Login(action) {
  const {payload} = action
  const type = 'okta'
  const {token} = payload
  try {
    const oauthParams = JSON.parse(localStorage.getItem('oAuthParams'))
    yield put({
      type: types.oauth2LoginRequest,
      payload: {
        options: {
          googleToken: token.idToken.idToken,
          client_id: oauthParams.clientId,
          response_type: 'code',
          redirect_uri: oauthParams.redirectURI,
          state: oauthParams.state,
          country_code: oauthParams.countryCode
        },
        type
      }
    })
  } catch (err) {
    yield put({
      type: types.displayAuthError,
      payload: {
        source: types.oauthLoginTypes.OKTA,
        error: err
      }
    })
  }
}

function* handleGoogleK2Login(action) {
  const type = 'google'
  const token = cookies.get('googleIdToken')

  try {
    const oauthParams = JSON.parse(localStorage.getItem('oAuthParams'))
    yield put({
      type: types.oauth2LoginRequest,
      payload: {
        options: {
          googleToken: token,
          client_id: oauthParams.clientId,
          response_type: 'code',
          redirect_uri: oauthParams.redirectURI,
          state: oauthParams.state,
          country_code: oauthParams.countryCode
        },
        type
      }
    })
  } catch (err) {
    yield put({
      type: types.displayAuthError,
      payload: {
        source: types.oauthLoginTypes.GOOGLE,
        error: err
      }
    })
  }
}

function* handleOAuthLoginSaga(action) {
  const {type} = action.payload

  try {
    const oauthParams = yield select(selectors.getOAuthParams)

    if (type === types.oauthLoginTypes.MANUAL) {
      yield put({
        type: types.oauth2LoginRequest,
        payload: {
          options: {
            username: action.payload.email,
            password: action.payload.password,
            client_id: oauthParams.clientId,
            grant_type: 'password',
            client_secret: oauthParams.clientSecret,
            response_type: 'code',
            redirect_uri: oauthParams.redirectURI,
            state: oauthParams.state,
            country_code: oauthParams.countryCode
          },
          type
        }
      })
    }
  } catch (err) {}
}

export const sagas = [
  takeLatest(types.oauth2LoginRequest, oauth2LoginRequestSaga),
  takeLatest(types.oauth2Request, handleOAuthLoginSaga),
  takeLatest(types.callOktak2Login, handleOktak2Login),
  takeLatest(types.callGoogleK2Login, handleGoogleK2Login)
]
