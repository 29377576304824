const state = {
  fetching: false,
  data: null,
  error: null,
  pageSetup: {},
  filters: [],
  limit: null,
  page: null,
  fields: [],
  orderBy: null
}

export const getInitialState = (overrides = {}) => {
  const setup = {
    ...state,
    ...overrides
  }
  // [CONVERSION] [note: this is how we get fields for column visibility into state]
  const fields = Object.values(setup.pageSetup.fields)

  return {...setup, fields}
}

// pageSetup: config.pages.inspectionReports,
// filters: config.pages.inspectionReports.filters,
// limit: config.pages.inspectionReports.limit,
// page: config.pages.inspectionReports.page,
