import {
  ValidatedTextField,
  ValidatedColorPickerField,
  ValidatedSelectField
} from '../../../components/FormValidation'

export default {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            fullWidth: true,
            component: ValidatedTextField,
            id: 'name',
            label: 'documentConfigFormConfig.page.name',
            margin: 'normal',
            onChangeHandler: 'updateField',
            required: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        className: 'documentConfigColors',
        children: [
          {
            component: ValidatedColorPickerField,
            fullWidth: true,
            id: 'groupBackgroundColor',
            label: 'documentConfigFormConfig.page.groupBackgroundColor',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            required: true
          },
          {
            component: ValidatedColorPickerField,
            fullWidth: true,
            id: 'groupFontColor',
            label: 'documentConfigFormConfig.page.groupFontColor',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            required: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        className: 'documentPrintCBE',
        children: [
          {
            component: 'SwitchField',
            id: 'printCBE',
            label: 'documentConfigFormConfig.page.printCBE',
            margin: 'normal',
            name: 'printCBE',
            value: 'printCBE',
            checked: 'printCBE',
            onChangeHandler: 'handleToggleChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        className: 'documentPrintCBE',
        children: [
          {
            component: ValidatedSelectField,
            id: 'displayCBEAtStart',
            label: 'documentConfigFormConfig.page.imagePositionOfCBE',
            margin: 'normal',
            name: 'displayCBEAtStart',
            value: 'displayCBEAtStart',
            filterable: false,
            onChangeHandler: 'handleSelectFieldChange',
            disabled: (props) =>
              !(
                props &&
                props.data &&
                props.data.metaInfo &&
                props.data.metaInfo.printCBE === true
              )
          }
        ]
      }
    ]
  }
}
