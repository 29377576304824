import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import {HANDLERS} from './common'

export default {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'tierNameBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'tierName',
            label: 'leadallocation.tier.form.name',
            margin: 'normal',
            name: 'tierName',
            onChangeHandler: HANDLERS.TEXT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'leadSourceBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'tierLeadSource',
            label: 'lead.leadSource.label',
            margin: 'normal',
            name: 'tierLeadSource',
            options: 'leadSource',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'userGroupBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'tierUserGroup',
            label: 'leadallocation.tier.form.userGroup',
            margin: 'normal',
            name: 'tierUserGroup',
            options: 'userGroup',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'statusBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'tierLeadStatus',
            label: 'leadallocation.tier.form.leadStatus',
            margin: 'normal',
            name: 'tierLeadStatus',
            options: 'leadStatus',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'maxLeadsBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            isNumber: true,
            id: 'tierMaxLeads',
            label: 'leadallocation.tier.form.maxLeads',
            margin: 'normal',
            name: 'tierMaxLeads',
            onChangeHandler: HANDLERS.TEXT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'makeBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'tierMake',
            label: 'carData.column.make',
            margin: 'normal',
            name: 'tierMake',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'weightBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            isNumber: true,
            id: 'tierWeight',
            label: 'leadallocation.tier.form.weight',
            margin: 'normal',
            name: 'tierWeight',
            onChangeHandler: HANDLERS.TEXT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'appointmentStatusBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            multiple: true,
            id: 'tierAppointmentStatus',
            label: 'appointment.status.label',
            margin: 'normal',
            name: 'tierAppointmentStatus',
            options: 'appointmentStatus',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'locationBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'tierLocation',
            label: 'location.name.label',
            margin: 'normal',
            name: 'tierLocation',
            options: 'locations',
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'emailBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'tierEmail',
            label: 'contact.email.label',
            margin: 'normal',
            name: 'tierEmail',
            onChangeHandler: HANDLERS.TEXT,
            type: 'email'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'phoneBlock',
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            isNumber: true,
            id: 'tierPhone',
            label: 'contact.phone.label',
            margin: 'normal',
            name: 'tierPhone',
            validator: {required: true, type: 'phone'},
            onChangeHandler: HANDLERS.SELECT
          }
        ]
      }
    ]
  }
}
