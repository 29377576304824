import styled from 'styled-components'
import {FileTile} from '@fcg/image-viewer'
import {
  getThumbnailListAlternativeRender,
  calculateThumbnailWrapper
} from './utils'

const GalleryImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 110px;
  margin-bottom: 10px;
  height: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    font-size: 80px;
  }
`

export const ThumbnailList = ({
  images,
  selectImage,
  selected,
  showSelectedImageInList = false,
  customStyles = {},
  availableAdornements,
  useAdornement
}) =>
  images.map((image, index) => {
    if (index === selected && !showSelectedImageInList) {
      return null
    }

    const thumbnailAlternativeRenderer = getThumbnailListAlternativeRender(
      image
    )
    const thumbnailWrapper = calculateThumbnailWrapper({
      useAdornement,
      availableAdornements,
      image,
      adornementSize: 'xs'
    })

    return (
      <GalleryImageWrapper key={`gallery-image-${index}-${image.id}`}>
        <FileTile
          {...image}
          selected={index === selected}
          alternativeRenderer={thumbnailAlternativeRenderer}
          wrapper={thumbnailWrapper}
          onClick={() => selectImage(index)}
          selectedColor={customStyles.selectedTileColor}
        />
      </GalleryImageWrapper>
    )
  })
