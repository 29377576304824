import appointmentForm from './appointmentFormConfig'
import leadForm from './leadFormConfig'
import contactForm from './contactFormConfig'
import tierFormConfig from './tierFormConfig'

export {
  appointmentForm,
  leadForm,
  contactForm,
  tierFormConfig
}
