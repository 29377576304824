import values from 'lodash/values'
import cloneDeep from 'lodash/cloneDeep'
import union from 'lodash/union'
import toPairs from 'lodash/toPairs'
import difference from 'lodash/difference'
import keys from 'lodash/keys'
import moment from 'moment'

export const CACHE_INVALIDATION_VALUE = 30
export const CACHE_INVALIDATION_BASE = 'minutes'
export const TIMESTAMP_SEPARATOR = '___'
export const CACHE_INVALIDATION_VALUE_MILISECS =
  CACHE_INVALIDATION_VALUE * 60 * 1000

export const getObjects = (source, type, namespace) =>
  source && source[type] ? source[type][namespace] : {}

export const getObject = (state, type, namespace, id) =>
  getObjects(state.resources, type, namespace)[id]

export const findObject = (state, type, namespace, id) => {
  const object = getObjects(state, type, namespace)

  return keys(object).reduce((acc, key) => {
    if (!acc && key.includes(id)) {
      return object[key]
    }

    return acc
  }, null)
}

export const findObjectKey = (state, type, namespace, id) =>
  keys(getObjects(state, type, namespace)).reduce((acc, key) => {
    if (!acc && key.includes(id)) {
      return key
    }

    return acc
  }, null)

export const findObjectKeys = (state, type, namespace, id) =>
  keys(getObjects(state, type, namespace)).reduce((acc, key) => {
    if (key.includes(id)) {
      return [...acc, key]
    }

    return acc
  }, [])

export const getCollectionInfo = (collectionName) => {
  if (!collectionName) {
    return {
      filter: null,
      time: null
    }
  }

  const pieces = collectionName.split(TIMESTAMP_SEPARATOR)

  return {
    filter: pieces[0],
    time: Number(pieces[1])
  }
}

export const isCacheValid = (timestamp) => {
  if (!timestamp) {
    return false
  }

  const diff = moment().diff(moment(timestamp), CACHE_INVALIDATION_BASE, true)

  return diff <= CACHE_INVALIDATION_VALUE
}

// Create collection or adds a new resource
export const mergeResources = (resources, data, options) => {
  const clonedResources = cloneDeep(resources)
  const {name, type, reset} = options
  const dataArray = values(data[type])
  let resetCollection = reset === true

  if (dataArray && !dataArray.length && name && type && clonedResources[type]) {
    clonedResources[type].collections[name] = []

    return clonedResources
  }

  return dataArray.reduce((acc, resource) => {
    const {id} = resource

    if (!acc[type]) {
      acc[type] = {all: {}, collections: {}}
    }

    const exisitingResource = acc[type].all[id]

    const existing = {
      ...exisitingResource,
      ...resource
    }

    acc[type].all[id] = {
      id,
      _resourceType: type,
      ...existing
    }

    if (name) {
      if (!acc[type].collections[name] || resetCollection) {
        resetCollection = false
        acc[type].collections[name] = []
      }

      acc[type].collections[name] = union(acc[type].collections[name], [id])
    }

    return acc
  }, clonedResources)
}

// removes the resource from the state and the data from the all
export const removeResource = (resources, resourceReference) => {
  const clonedResources = cloneDeep(resources)

  if (resourceReference) {
    const {_resourceType: type, id} = resourceReference

    if (clonedResources[type] && clonedResources[type].all) {
      delete clonedResources[type].all[id]
    }

    if (
      clonedResources[type].collections &&
      toPairs(clonedResources[type].collections).length
    ) {
      toPairs(clonedResources[type].collections).forEach((collection) => {
        collection[1] = difference(collection[1], [id])
      })
    }
  }

  return clonedResources
}

export const removeDataFromResource = (resources, type, id) => {
  Object.keys(resources[type].collections).map((collection) => {
    resources[type].collections[collection] = difference(
      resources[type].collections[collection],
      id
    )
  })

  return resources
}

export const query = (request, resource) =>
  resource
    ? request.client.query(request.endpoint, request.params, resource)
    : request.client.query(request.endpoint, request.params)

export const getDeleteResponsePayload = (payload, request, response, options) =>
  options &&
  options.responseOptions &&
  options.responseOptions.responseTransform
    ? options.responseOptions.responseTransform(request, response)
    : payload
