import gql from '@fcg/lib-gql'

import {globalPlaceConfigurationTemplate, DAYS} from '../../config/constants'
import ServiceType from '../../types/ServiceType'
import OpeningTime from '../../types/OpeningTime'
import Country from '../../types/Country'
import GlobalPlaceConfiguration from '../../types/GlobalPlaceConfiguration'
import ServiceConfiguration from '../../types/ServiceConfiguration'
import GlobalStateResponse from '../../types/GlobalStateResponse'
import Location from '../../types/Location'
import Area from '../../types/Area'
import Lot from '../../types/Lot'

import {jsonDateToDate} from '../../config/utils'
import {
  createServiceName,
  createDefaultOpeningTime
} from '../../pages/LocationPage/Services/utils'
import ServicesObject from '../../types/ServicesObject'
import OpeningHours from '../../types/OpeningHours'
import ServicesConfigurationObject from '../../types/ServicesConfigurationObject'
import Holiday from '../../types/Holiday'
import HolidayObject from '../../types/HolidayObject'

export const formatCountry = (request: {country: string}) => {
  request.country =
    typeof request.country === 'string'
      ? new gql.EnumType(request.country)
      : request.country

  return request
}

export const formatUserClass = (request: {userClass: string}) => {
  request.userClass =
    typeof request.userClass === 'string'
      ? new gql.EnumType(request.userClass)
      : request.userClass

  return request
}

/**
 * Converts data into usable form, and adds defaults for the missing data
 */
export const formatGlobalTemplateData = (response: {
  aggregateGlobalConfiguration: GlobalStateResponse
}) => {
  // replaces null fields with template
  return {
    globalPlaceConfiguration:
      response.aggregateGlobalConfiguration.place ||
      globalPlaceConfigurationTemplate,
    globalServices: formatOpeningTimes(
      response.aggregateGlobalConfiguration.openingTimes || []
    ),
    globalServicesConfiguration: createConfigurationObject(
      response.aggregateGlobalConfiguration.servicesConfiguration
    ),
    globalHolidays: formatHolidayDates(
      response.aggregateGlobalConfiguration.specialOpening
    ) // default []
  }
}

const formatDates = (holiday: Holiday): HolidayObject => {
  return {
    ...holiday,
    from: jsonDateToDate(holiday.from),
    to: jsonDateToDate(holiday.to)
  }
}

/**
 * formats the holiday's from and to attr
 * @param holiday
 */
const formatHolidayDates = (
  holidays: Holiday | Holiday[]
): HolidayObject | HolidayObject[] => {
  return Array.isArray(holidays)
    ? holidays.map(formatDates)
    : formatDates(holidays)
}

const createService = (openingTimes: OpeningTime[], key: string) => {
  return {
    key: key,
    type: openingTimes[0].bookingType as ServiceType[],
    openingHours: reShapeOpeningTimes(openingTimes),
    name: createServiceName(openingTimes[0].bookingType)
  }
}

/**
 * Creates services and returns services array
 * @param openingTimes
 */
export const formatOpeningTimes = (
  openingTimes: {openingTime: OpeningTime[]}[]
): ServicesObject => {
  let services: ServicesObject = {}
  openingTimes.forEach((serviceOpeningTime) => {
    const key = serviceOpeningTime.openingTime[0].bookingType.sort().join('_')
    if (isServiceActive(serviceOpeningTime.openingTime)) {
      services[key] = createService(serviceOpeningTime.openingTime, key)
    }
  })
  return services
}

const isServiceActive = (openingTime: OpeningTime[]) => {
  let isOpen = false
  Object.values(openingTime).map((day) => {
    if (isOpen) return false
    if (!day.closed) {
      isOpen = true
    }
  })

  return isOpen
}

const reShapeOpeningTimes = (openingTimes: OpeningTime[]): OpeningHours => {
  const totalOpeningTimes = openingTimes.concat()
  totalOpeningTimes.sort((a, b) =>
    a.weekday === b.weekday ? 1 : a.weekday < b.weekday ? -1 : 1
  ) //needs to be here till sort is added to global aggregate function by BE
  let openingHours: {[day: number]: OpeningTime[]} = {}
  DAYS.map((day) => {
    openingHours[day] = openingTimes.filter(
      (openingTime) => openingTime.weekday === day
    )

    if (openingHours[day]!.length > 0) {
      sortTimeRanges(openingHours[day]!)
    } else {
      openingHours[day] = createDefaultOpeningTime(
        day,
        openingTimes[0].bookingType
      )
    }
  })
  return openingHours as OpeningHours
}

const sortTimeRanges = (dayRanges: OpeningTime[]) => {
  dayRanges.sort((a, b) =>
    a.openingTime === b.openingTime ? 1 : a.openingTime < b.openingTime ? -1 : 1
  )
  return dayRanges
}

const createConfigurationObject = (
  configurations: ServiceConfiguration[] = []
): ServicesConfigurationObject => {
  return configurations.reduce((configurationObject, config) => {
    const key = config.type.sort().join('_')
    return {...configurationObject, [key]: config}
  }, {} as ServicesConfigurationObject)
}

export const returnGlobalPlaceResponse = (
  response: {
    [key in
      | 'updateGlobalPlaceConfiguration'
      | 'createGlobalPlaceConfiguration']: GlobalPlaceConfiguration
  }
) => {
  return (
    response.updateGlobalPlaceConfiguration ||
    response.createGlobalPlaceConfiguration
  )
}

export const returnServicesConfigurationResponse = (
  response: {
    [key in
      | 'updateGlobalServicesConfiguration'
      | 'createGlobalServicesConfiguration']: ServiceConfiguration[]
  }
) => {
  return response.updateGlobalServicesConfiguration
    ? response.updateGlobalServicesConfiguration[0]
    : response.createGlobalServicesConfiguration[0]
}

//this is for one Service Response - just needs to be reshaped
export const returnOpeningTimesResponse = (
  response: {
    [key in
      | 'setGlobalOpeningTime'
      | 'setOpeningTime'
      | 'openingTime']: OpeningTime[]
  }
) => {
  const openingHours =
    response.setGlobalOpeningTime ||
    response.setOpeningTime ||
    response.openingTime ||
    []

  return openingHours.length ? reShapeOpeningTimes(openingHours) : []
}

const groupOpeningTimesByService = (
  openingTimes: OpeningTime[]
): {[key: string]: OpeningTime[]} => {
  return openingTimes.reduce((configurationObject, openingTime) => {
    if (openingTime.bookingType) {
      const key = openingTime?.bookingType?.join('_')
      configurationObject[key] = configurationObject[key]
        ? (configurationObject[key] = [
            ...configurationObject[key],
            openingTime
          ])
        : [openingTime]
    }

    return configurationObject
  }, {} as {[key: string]: OpeningTime[]})
}

//this is for all services response - needs to be formatted and shaped
export const createLocationsServicesObject = (response: {
  openingTime: OpeningTime[]
}) => {
  const openingTimesGroupedByService: {
    [key: string]: OpeningTime[]
  } = groupOpeningTimesByService(response.openingTime)

  return Object.keys(openingTimesGroupedByService).reduce(
    (services, service) => ({
      ...services,
      [service]: createService(openingTimesGroupedByService[service], service)
    }),
    {} as ServicesObject
  )
}

export const returnSpecialOpeningResponse = (response: any) => {
  const data: Holiday =
    response.updateGlobalSpecialOpening ||
    response.createGlobalSpecialOpening ||
    response.updateSpecialOpening ||
    response.createSpecialOpening

  return formatHolidayDates(data)
}

export const returnSpecialOpeningListResponse = (response: {
  specialOpeningTime: {list: Holiday[]}
}): HolidayObject[] => {
  return formatHolidayDates(response.specialOpeningTime.list) as HolidayObject[]
}

export const returnCountryOptionsResponse = (response: {
  countryOptions: {list: {name: string}[]}
}): string[] => {
  return response?.countryOptions?.list?.map((type) => type.name)
}

export const formatPlaceResponse = (
  response: {[key in 'updatePlace' | 'createPlace']: Location}
) => {
  return response.updatePlace || response.createPlace
}

export const formatAreaResponse = (
  response: {[key in 'updateArea' | 'createArea']: Area}
) => {
  return response.updateArea || response.createArea
}

export const formatLotResponse = (
  response: {[key in 'updateLot' | 'createLot']: Lot}
) => {
  return response.updateLot || response.createLot
}

export const formatSort = (request: any) => {
  if (typeof request.sort === 'undefined') {
    return request
  }

  request.sort = Array.isArray(request.sort)
    ? request.sort.map(convertSort)
    : convertSort(request.sort)

  return request
}

export const convertSort = (sort: any) => {
  if (sort.order && typeof sort.order === 'string') {
    sort.order = new gql.EnumType(sort.order)
  } else if (sort.order instanceof gql.EnumType === false) {
    sort.order = new gql.EnumType(sort.order.value)
  }

  return sort
}

export const formatServiceConfigurationRequest = (
  configuration: ServiceConfiguration,
  countryCode: Country
) => {
  return {
    globalServicesConfiguration: {
      ...configuration,
      minAllowedTimeUnit: new gql.EnumType(configuration.minAllowedTimeUnit),
      maxAllowedTimeUnit: new gql.EnumType(configuration.maxAllowedTimeUnit),
      country: new gql.EnumType(countryCode),
      type: configuration.type.map((type) => new gql.EnumType(type))
    }
  }
}

export const formatOpeningHoursRequest = (
  openingHours: OpeningTime[],
  countryCode: Country,
  placeId: string
) => {
  const formattedOpeningHours = openingHours.map((timeRange) => {
    delete timeRange.id
    const requestTimeRange = {
      ...timeRange,
      bookingType: [new gql.EnumType(timeRange.bookingType)],
      country: new gql.EnumType(countryCode),
      slots: Number(timeRange.slots),
      weekday: Number(timeRange.weekday)
    }
    if (placeId !== 'master') requestTimeRange.placeId = placeId

    return requestTimeRange
  })
  const requestType = placeId !== 'master' ? 'openingTime' : 'globalOpeningTime'

  return {
    [requestType]: formattedOpeningHours
  }
}

export const formatCreateUpdateSpecialOpeningRequest = (
  holidayData: any, //holiday Data but from & to string
  countryCode: Country
) => {
  const formattedData = {
    ...holidayData,
    from: formatSpecialDateString(holidayData.from as string),
    to: formatSpecialDateString(holidayData.to as string),
    country: new gql.EnumType(countryCode)
  }
  if (formattedData.applyToAllCities) {
    formattedData.cities = []
  }

  if (formattedData.openingTimes?.length > 0) {
    formattedData.openingTimes = formattedData.openingTimes
      .filter((openingTime: OpeningTime) => !openingTime.closed)
      .map((openingTime: OpeningTime) => {
        if (Array.isArray(openingTime.bookingType)) {
          openingTime.bookingType =
            typeof openingTime.bookingType[0] === 'string'
              ? [new gql.EnumType(openingTime.bookingType[0])]
              : openingTime.bookingType
        }

        delete openingTime.closed

        return openingTime
      })
  }

  return formattedData
}

const formatSpecialDateString = (dateString: string) => {
  const date = new Date(dateString)

  return date.toLocaleDateString('fr-CA')
}
