import styled from 'styled-components'
import {
  BANNER_SIZE,
  setProp,
  DEFAULT_BORDER_BOTTOM_COLOR,
  getPosition
} from './util'

/** Banner wrappers */
export const BannerWrapper = styled.div`
  height: ${setProp('height', 'WRAPPER', BANNER_SIZE.sm.WRAPPER.height)};
  width: ${setProp('width', 'WRAPPER', BANNER_SIZE.sm.WRAPPER.width)};
  position: relative;
  top: -10px;
`
export const BannerBody = styled.div`
  width: 0;
  height: ${setProp('height', 'BODY', BANNER_SIZE.sm.BODY.height)};
  border-right: ${setProp('border', 'BODY', BANNER_SIZE.sm.BODY.border)} solid
    ${(props) => props.color || DEFAULT_BORDER_COLOR};
  border-left: ${setProp('border', 'BODY', BANNER_SIZE.sm.BODY.border)} solid
    ${(props) => props.color || DEFAULT_BORDER_COLOR};
  border-bottom: ${setProp(
      'borderBottom',
      'BODY',
      BANNER_SIZE.sm.BODY.borderBottom
    )}
    solid ${DEFAULT_BORDER_BOTTOM_COLOR};
  position: static;
  border-top-right-radius: ${setProp(
    'borderTopRadius',
    'BODY',
    BANNER_SIZE.sm.BODY.borderTopRadius
  )};
  border-top-left-radius: ${setProp(
    'borderTopRadius',
    'BODY',
    BANNER_SIZE.sm.BODY.borderTopRadius
  )};
`

/** Icon */
export const Icon = (icon) => styled(icon)`
  && {
    display: flex;
    align-self: center;
    top: ${setProp('top', 'ICON', BANNER_SIZE.sm.ICON.top)};
    left: ${setProp('left', 'ICON', BANNER_SIZE.sm.ICON.left)};
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    font-size: ${setProp('fontSize', 'ICON', BANNER_SIZE.sm.ICON.fontSize)};
    fill: white;
  }
`

export const getAdornementPositionSize = ({size}) => {
  if (size === 'single') {
    return `
      height: 70%;
      width: 100%;
      max-height: 395px;
      max-width: 395px;
    `
  }

  if (size === 'xs') {
    return `
      width: 100%;
      max-width: 110px;
      min-width: 100px;
      height: 70px;
    `
  }

  return `
    height: 100%;
    width: 100%;
    max-height: 395px;
    max-width: 395px;
  `
}

/** Wrapper */
export const AdornementPosition = styled.div`
  display: flex;
  ${getAdornementPositionSize}
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const AdornementPositioner = styled.div`
  display: flex;
  ${getPosition}
  width: ${({size}) => (size === 'sm' ? '55%' : '75%')};
  height: 100%;
  position: absolute;
`
