import Editor from '../../../pages/BulkUpload/Editor'
import bulkUploadFormConfig from './bulkUploadFormConfig'

export const bulkUploadConfig = {
  path: 'bulkUpload',
  label: 'menu.bulkUpload',
  key: 'bulkUpload',
  sort: [],
  limit: 25,
  filters: [],
  presets: [],
  page: 1,
  form: bulkUploadFormConfig,
  hiddenFields: [],
  component: Editor,
  permissions: {
    entity: 'web.admin.ui.car.bulkUpload',
    types: ['READ']
  }
}
