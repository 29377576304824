import 'date-fns'
import {get, sortBy} from 'lodash'
import React, {useState, useEffect, Fragment, useContext} from 'react'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import {getLotsList, getCar} from '../../../../api/car/requests'
import {MainContext} from '../../../../context/MainContext'

import DialogTemplate from '../../../../components/Dialog/Dialog'
import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import useLotAreasActions from '../useLotAreasActions'
import RowDescription from '../../../../components/Form/RowDescription'

import AreaObject from '../../../../types/AreaObject'
import LotObject from '../../../../types/LotObject'
import CarObject from '../../../../types/CarObject'

interface LotPickerProps {
  open: boolean
  selectedArea?: AreaObject
  onCancel: () => void
  // onConfirm: (id: string) => void
  onEmptyLot: (id: string) => void
}

const defaultArea: AreaObject = {
  id: '',
  name: ''
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return {name, calories, fat, carbs, protein}
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
]

const LotPicker = ({
  open,
  selectedArea,
  // onConfirm
  onCancel
}: LotPickerProps) => {
  const classes = useCommonStyles()
  const {locationLots} = useContext(MainContext)
  const {updateLot} = useLotAreasActions()

  const [lots, setLots] = useState<LotObject[]>([])
  const [cars, setCars] = useState<CarObject[]>([])

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )

  const [selectedLot, setSelectedLot] = useState<LotObject | undefined>(
    undefined
  )

  const getlots = (areaId: string) => {
    const req = {areaId}
    getLotsList(req).then((lots: LotObject[]) => {
      setLots(lots)
    })
  }

  useEffect(() => {
    if (selectedArea) {
      const {id} = selectedArea
      if (id) getlots(id)
    }
  }, [locationLots])

  const getCarData = (carId: [string]) => {
    const req = {id: carId}
    getCar(req).then((cars: CarObject[]) => {
      setCars(cars)
    })
  }

  const onEmptyLot = () => {
    updateLot(selectedLot!.id!, null, deleteCallback)
  }

  const deleteCallback = () => {
    setShowDeleteConfirmation(false)
  }

  const getLot = (id: string): LotObject => {
    return lots.find((lot: LotObject) => lot.id === id)!
  }

  const getLotsIds = (lots: any) => lots.map((lot: LotObject) => lot.carId)

  useEffect(() => {
    if (lots.length) {
      getCarData(getLotsIds(lots))
    }
  }, [lots])

  const filterCarData = (carId: string) =>
    cars.filter((car) => car.id === carId)

  const showDeleteConfirmationDialog = (id?: string) => {
    if (!id) {
      throw new Error('id undefined')
    }

    setSelectedLot(getLot(id))
    setShowDeleteConfirmation(true)
  }

  return (
    <DialogTemplate
      open={open}
      title='Lot Area'
      onCancel={onCancel}
      dataTest='save-area-btn'
      maxWidth='md'
      confirmLabel='SAVE'
      onConfirm={onCancel}
    >
      <RowDescription title='Occupied lots' />
      <Fragment>
        <TableContainer>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Lot</TableCell>
                  <TableCell>Car ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>License Plate</TableCell>
                  <TableCell>Body Color</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortBy(lots, (lot) => lot.progressiveId).map((lot) => (
                  <TableRow key={lot.id}>
                    <TableCell component='th' scope='row'>
                      {lot.progressiveId}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {get(filterCarData(lot.carId)[0], 'internalId')}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {get(filterCarData(lot.carId)[0], 'make')}
                      {get(filterCarData(lot.carId)[0], 'model')}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {get(filterCarData(lot.carId)[0], 'licensePlate')}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {get(filterCarData(lot.carId)[0], 'color')}
                    </TableCell>
                    <IconButton
                      aria-label='delete'
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault()
                        e.stopPropagation()
                        showDeleteConfirmationDialog(lot.id)
                      }}
                      data-test='delete-lot-btn'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainer>
      </Fragment>

      {showDeleteConfirmation && (
        <DialogTemplate
          open={showDeleteConfirmation}
          title={`Are you sure you want to empty lot ${
            selectedLot!.progressiveId
          }`}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel='YES, EMPTY'
          dataTest='delete-lot-confirm'
          onConfirm={onEmptyLot}
        ></DialogTemplate>
      )}
    </DialogTemplate>
  )
}

export default LotPicker
