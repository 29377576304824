import {createElement, PureComponent} from 'react'
import {connect} from 'react-redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import {setCookie} from '../utils/authToken'
import InternalLogin from './Login/InternalLogin'
import {checkUserStatus, checkUserInfo} from '../store/auth/actions'
import Guard from '../components/Permissions/Guard'
import {getCountryConfig} from '../../config/country'
import LoadGenesysScript from '../components/Dialer/LoadGenesysScript'

const manualLoginRoute = (
  <Route
    exact
    key={`route_InternalLogin`}
    path={`/login-internal`}
    className='page'
    render={(props) =>
      createElement(InternalLogin, {
        ...props,
        standalone: true,
        className: 'page'
      })
    }
  />
)

const shouldShowInternalLogin = (env, countryConfig) => {
  const isEnabledForCountry =
    countryConfig && countryConfig.loginInternalPageAvailable

  return env !== 'production' || (env === 'production' && isEnabledForCountry)
}

export class ConfiguredRoute extends PureComponent {
  componentDidMount() {
    this.interval = window.setInterval(this.checkStatus, 30000)
    this.props.checkUserInfo()

    if (this.props.country) {
      setCookie('countryCode', this.props.country.countryCode, 365)
      setCookie('locale', this.props.country.language.selected, 365)
    } else {
      setCookie('countryCode', null)
      setCookie('locale', null)
    }

    this.checkStatus()
  }

  buildRoutes = (
    page,
    prefix = '',
    exact = true,
    countryConfig = null,
    parentKey = null
  ) => {
    let collection = []

    if (
      Object.prototype.hasOwnProperty.call(page, 'children') &&
      page.children.length
    ) {
      const subroutes = page.children.map((subroute) =>
        this.buildRoutes(
          subroute,
          `${prefix}${page.path}/`,
          false,
          countryConfig,
          page.key
        )
      )

      collection = [...collection, ...subroutes]
    }

    if (shouldShowInternalLogin(window.environment, countryConfig)) {
      collection.push(manualLoginRoute)
    }

    collection.push(
      <Route
        exact={exact}
        key={`route_${page.label}`}
        path={`/${prefix}${page.path}`}
        className='page'
        render={(props) => (
          <Guard {...page} pageKey={page.key} parentKey={parentKey}>
            {getCountryConfig().showDialerToolbar === true &&
              page.path !== 'detail/:id' && <LoadGenesysScript />}
            {createElement(page.component, {
              ...props,
              pageConfig: page,
              className: 'page',
              permissions: this.props.permissions,
              countryCode: this.props.country?.countryCode
            })}
          </Guard>
        )}
      />
    )

    return collection
  }

  checkStatus = () => {
    if (this.props.isAuthenticated) {
      this.props.checkUserStatus(true)
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  render() {
    const routes = this.props.pages.map((page) =>
      this.buildRoutes(
        page,
        '',
        page.exact || typeof page.exact === 'undefined',
        this.props.country
      )
    )

    return (
      <Switch>
        {/* Avoid users from getting a blank page after the route was changed */}
        <Route exact path='/dealers'>
          <Redirect to='/dealers/list' />
        </Route>
        {[...routes]}
        {this.props.children}
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  const {config, authReducer} = state

  return {
    pages: Object.values(config.pages),
    country: {
      ...config,
      loginInternalPageAvailable: config.loginInternalPageAvailable
    },
    isAuthenticated: authReducer.isAuthenticated,
    permissions: authReducer?.userInfo?.permissions
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkUserStatus: (isLoggedIn) => dispatch(checkUserStatus(isLoggedIn)),
  checkUserInfo: () => dispatch(checkUserInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguredRoute)
