export const number = /^[0-9]*$/

/* eslint-disable max-len */
// eslint-disable-next-line no-useless-escape
export const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const escapeSpecialChars = (text = '') =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

/* eslint-disable max-len */
// eslint-disable-next-line no-useless-escape
export const devInstancePattern = /^((https:\/\/)(admin2-[a-zA-Z0-9-]+\.)(dev\.)(\w+\.\w+).*)$/
