export const OPTION_OTHER = {value: 'OTHER', label: 'form.select.other'}
export const dealerSize = [
  {value: 'small', label: 'dealer.page.dealerSize.small'},
  {value: 'medium', label: 'dealer.page.dealerSize.medium'},
  {value: 'large', label: 'dealer.page.dealerSize.large'},
  {value: 'extraLarge', label: 'dealer.page.dealerSize.extraLarge'}
]

export const statusDependentFieldEnums = {
  BLOCKED_REASON: 'blockedReason',
  INACTIVE_REASON: 'inactiveReason',
  REJECTED_REASON: 'rejectedReason',
  ONBOARDING_STATUS: 'onboardingStatus'
}
export const statusDependentFieldsArray = [
  statusDependentFieldEnums.BLOCKED_REASON,
  statusDependentFieldEnums.INACTIVE_REASON,
  statusDependentFieldEnums.REJECTED_REASON,
  statusDependentFieldEnums.ONBOARDING_STATUS
]

export const personType = [
  {value: 'natural', label: 'dealer.page.personType.natural'},
  {value: 'foreigner', label: 'dealer.page.personType.foreigner'},
  {value: 'legal', label: 'dealer.page.personType.legal'}
]

export const STATUS_VALUES = {
  APPLIED: 'applied',
  ONBOARDING: 'onboarding',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  BLOCKED: 'blocked',
  REJECTED: 'rejected'
}
export const STATUS_SUB_FIELDS = {
  [STATUS_VALUES.ONBOARDING]: [statusDependentFieldEnums.ONBOARDING_STATUS],
  [STATUS_VALUES.INACTIVE]: [statusDependentFieldEnums.INACTIVE_REASON],
  [STATUS_VALUES.REJECTED]: [statusDependentFieldEnums.REJECTED_REASON],
  [STATUS_VALUES.BLOCKED]: [statusDependentFieldEnums.BLOCKED_REASON]
}
export const STATUS_ARRAY = [
  STATUS_VALUES.APPLIED,
  STATUS_VALUES.ONBOARDING,
  STATUS_VALUES.ACTIVE,
  STATUS_VALUES.INACTIVE,
  STATUS_VALUES.BLOCKED,
  STATUS_VALUES.REJECTED
]
export const VALUES = {
  YES: 'yes',
  NO: 'no'
}
