import {Typography} from '@material-ui/core'
import {translate} from 'react-i18next'
import AppointmentForm from '../Appointment/Edit'
import ContactForm from '../Contact/Edit'
import LeadForm from '../Lead/Edit'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {appointmentForm} from '../../../config/pages/crm/forms'

const AppointmentFormWithCountryConfig = formWithCountryOptions(
  appointmentForm,
  'crmDetails'
)(AppointmentForm)

export const WalkinForm = (props) => {
  const {t, classes, contact, lead, appointment, options} = props

  return (
    <div className='Walkin'>
      <div className={classes.dialogSubtitle}>
        <Typography variant='title'>{t('entity.contact')}</Typography>
        <ContactForm
          t={t}
          creation
          classes={classes}
          data={contact}
          update={props.updateContact}
          config={props.config.contactForm}
        />
      </div>
      <div className={classes.dialogSubtitle}>
        <Typography variant='title'>{t('entity.lead')}</Typography>
        <LeadForm
          t={t}
          creation
          classes={classes}
          data={lead}
          ignoreEmptyDependencies
          options={options}
          users={props.leadUsers}
          setUserOptions={props.setLeadUserOptions}
          setDiscardReasonOptions={props.setDiscardReasonOptions}
          update={props.updateLead}
          config={props.config.leadForm}
        />
      </div>
      <div className={classes.dialogSubtitle}>
        <Typography variant='title'>{t('entity.appointment')}</Typography>
        <AppointmentFormWithCountryConfig
          t={t}
          creation
          classes={classes}
          data={appointment}
          users={props.appointmentUsers}
          setUserOptions={props.setAppointmentUserOptions}
          setRejectReasonOptions={props.setRejectReasonOptions}
          update={props.updateAppointment}
          config={props.config.appointmentForm}
          options={options}
          locationChange={props.locationChange}
          loadingSlots={props.loadingSlots}
          dateFormat={props.dateFormat}
          appointmentTypeChange={props.appointmentTypeChange}
        />
      </div>
    </div>
  )
}

export default translate()(WalkinForm)
