import {Component} from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import keys from 'lodash/keys'
import get from 'lodash/get'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog,
  IconButton
} from '@material-ui/core'
import {Edit} from '@material-ui/icons'
import EventAvailable from '@material-ui/icons/EventAvailable'
import {SlideUp} from '../../../components/Animations/Slides'
import AppointmentForm from './Edit'
import dialogStyles from '../common/dialogStyles'
import {connect} from 'react-redux'
import {
  createAppointment,
  updateAppointment,
  hydrateAppointmentForm,
  resetAppointmentForm,
  updateAppointmentForm
} from '../../../store/crm/appointment/actions'
import {
  reactToLocationChange,
  getLocationOptions,
  getPurchaseCoordinatorOptions
} from '../../../store/config/actions'
import {loadOptions, setRejectReasonOptions} from '../../../store/crm/actions'
import {ValidatedForm} from '@fcg/formvalidation'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {appointmentForm} from '../../../config/pages/crm/forms'
import {getAppointment} from '../../../store/crm/appointment/selectors'
import {getSelectedAppointment} from '../../../store/calendar/selectors'
import {updateCalendarAppointment} from '../../../store/calendar/actions'
import PermissionWrapper from '../../../components/Permissions/PermissionWrapper'
import {getFormAppointmentOptions} from './helpers'

const omitFields = [
  'inspectionPointCity',
  'inspectionPointName',
  'cancelledAt',
  'id',
  'slot',
  'participants',
  'purchaseCoordinatorPhone'
]

export class AppointmentDialog extends Component {
  static defaultProps = {
    creation: false
  }

  state = {users: {options: [], count: 0}}

  componentDidMount() {
    const {creation, isCalendar} = this.props

    if (creation !== true && this.getId()) {
      this.props.loadData({id: this.getId()})
    }

    this.props.loadOptions()
    this.setState({
      isReadOnly: isCalendar,
      data: {...this.props.data}
    })

    if (isCalendar) {
      const selectedType = this.props.options.appointmentType.find(
        ({value}) => value === this.props.data.type
      )

      if (selectedType) {
        this.props.appointmentTypeChange(selectedType.metaInfo.locationType)
      }
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      this.props.data.id !== nextProps.data.id ||
      this.props.data.purchaseCoordinator !== nextProps.data.purchaseCoordinator
    ) {
      this.setState({
        data: {...nextProps.data}
      })
      const appointmentType = get(nextProps, 'options.appointmentType')

      let selectedType = {}

      if (appointmentType) {
        selectedType = appointmentType.find(
          ({value}) => value === nextProps.data.type
        )
      }

      if (selectedType) {
        this.props.appointmentTypeChange(
          get(selectedType, 'metaInfo.locationType')
        )
      }
    }
  }

  update = ({name, value}) => {
    this.setState(
      (state) => ({
        data: {
          ...state.data,
          [name]: value
        }
      }),
      () => {
        this.props.updateAppointmentForm({name, value})
      }
    )
  }

  updateRejectionOption = ({data}) => {
    this.props.setRejectReasonOptions(data)
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  getId = () => {
    const {id, isCalendar, match} = this.props

    if (match && match.params && match.params.id) {
      return match.params.id
    }

    if (id && isCalendar) {
      return id
    }
  }

  isAssignmentSupported = () =>
    ['assignedTo', 'assignedToName'].every(
      (value) => !this.props.config.countryConfig.hidden.includes(value)
    )

  getAssignedUser = () => {
    if (this.isAssignmentSupported()) {
      return {
        assignedTo: this.state.data.assignedTo,
        assignedToName: this.state.data.assignedToName
      }
    }

    if (this.props.isCalendar) {
      return {
        assignedTo: null,
        assignedToName: null
      }
    }
  }

  handleSubmit = () => {
    const {leadId, creation} = this.props
    const {data} = this.state

    if (!creation && this.getId()) {
      const assigned = this.getAssignedUser()

      this.props.save({
        id: this.getId(),
        data: {
          ...omit(data, omitFields),
          ...assigned
        }
      })
    } else {
      const params = omit(data, ['bookingId', ...omitFields])

      this.props.create({
        leadId,
        data: params
      })
    }
  }

  handleCancel = () => {
    this.props.handleCancel({id: this.props.match.params.id})
  }

  handleClose = () => {
    if (this.props.reset) {
      this.props.reset()
    }

    this.props.handleClose()
  }

  handleCancelAppointment = () => {
    if (!this.props.onCancelAppointment && this.handleClose) {
      return this.handleClose()
    }

    this.props.onCancelAppointment()
  }

  toggleEditMode = () =>
    this.setState({
      isReadOnly: !this.state.isReadOnly
    })

  isSubmitDisabled = () => this.state.isReadOnly || this.props.editState.pending

  render() {
    const {
      t,
      creation,
      classes,
      editState,
      isCalendar,
      shouldShowContactButton
    } = this.props
    const dialogTitle =
      !!creation === true
        ? `${t('global.action.create')} ${t('entity.appointment')}`
        : `${t('global.action.update')} ${t('entity.appointment')}`
    const buttonText =
      !!creation === true
        ? t('global.action.create')
        : t('global.action.update')
    const formOptions = getFormAppointmentOptions(
      this.props.formOptions,
      this.state.data
    )

    if (editState.success === true) {
      this.props.handleSubmit()
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={SlideUp}
      >
        <ValidatedForm onSubmit={this.handleSubmit} t={t}>
          <DialogTitle disableTypography>
            <div className='flex flex--between'>
              <Typography variant='title' className={classes.title}>
                <EventAvailable className={classes.icon} />
                {dialogTitle}
              </Typography>
              {isCalendar && (
                <PermissionWrapper
                  entity='web.admin.ui.crm.appointments2'
                  types={['UPDATE']}
                >
                  <IconButton
                    aria-label='delete'
                    className={classes.margin}
                    size='small'
                    onClick={this.toggleEditMode}
                    disabled={editState.pending}
                    data-test='calendar-appointment-edit'
                  >
                    <Edit fontSize='inherit' />
                  </IconButton>
                </PermissionWrapper>
              )}
              {isCalendar && shouldShowContactButton && (
                <PermissionWrapper entity='web.admin.ui.crm.contacts'>
                  <Button
                    color='secondary'
                    className={classes.titleAction}
                    onClick={this.props.handleShowContact}
                    disabled={editState.pending}
                  >
                    {t('appointmentCalendar.dialog.showContact')}
                  </Button>
                </PermissionWrapper>
              )}
              {creation === false &&
                this.props.data.status !== 'cancel' &&
                shouldShowContactButton && (
                  <PermissionWrapper entity='web.admin.ui.crm.cancelAppointment'>
                    <Button
                      color='secondary'
                      disabled={editState.pending}
                      className={classes.titleAction}
                      onClick={this.handleCancelAppointment}
                    >
                      {`${t('button.cancel')} ${t('entity.appointment')}`}
                    </Button>
                  </PermissionWrapper>
                )}
            </div>
          </DialogTitle>
          <DialogContent>
            {editState.error !== false && (
              <Typography variant='body2' color='error'>
                {t('form.message.error.occurred')}
              </Typography>
            )}
            <AppointmentForm
              {...this.props}
              data={this.state.data}
              update={this.update}
              options={formOptions}
              users={this.state.users}
              setUserOptions={this.setUserOptions}
              disabled={this.state.isReadOnly}
              originalBookingSource={this.props.data.bookingSource}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='default'>
              {t('button.cancel')}
            </Button>
            <PermissionWrapper
              entity='web.admin.ui.crm.appointments2'
              types={[creation ? 'CREATE' : 'UPDATE']}
            >
              <Button
                variant='raised'
                disabled={this.isSubmitDisabled()}
                type='submit'
                color='primary'
              >
                {buttonText}
              </Button>
            </PermissionWrapper>
          </DialogActions>
        </ValidatedForm>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, {isCalendar}) => {
  const {config, appointment, crm} = state
  const mappedAppointment = getAppointment(appointment)

  const selectedAppointment = isCalendar
    ? pick(getSelectedAppointment(state), keys(mappedAppointment.form))
    : null

  const formData = isCalendar
    ? {...selectedAppointment, ...mappedAppointment.form}
    : mappedAppointment.form

  const data = omit(formData, ['leadId', ...config.crmDetails.hidden])

  const {editState} = mappedAppointment
  const {options} = crm
  const dateFormat = config.dateFormat
  const loadingSlots = mappedAppointment.loadingSlots
  const formOptions = {
    ...appointment.options,
    ...crm.options,
    ...config.options
  }

  return {
    data,
    editState,
    options,
    dateFormat,
    loadingSlots,
    formOptions
  }
}

const mapDispatchToProps = (dispatch, {isCalendar}) => ({
  create: (params) => dispatch(createAppointment(params)),
  save: (params) =>
    isCalendar
      ? dispatch(updateCalendarAppointment(params))
      : dispatch(updateAppointment(params)),
  loadData: ({id}) => dispatch(hydrateAppointmentForm({id})),
  loadOptions: () => {
    dispatch(loadOptions())
    dispatch(getPurchaseCoordinatorOptions())
  },
  appointmentTypeChange: (type) => dispatch(getLocationOptions(type)),
  updateAppointmentForm: (data) => dispatch(updateAppointmentForm(data)),
  setRejectReasonOptions: (data) => dispatch(setRejectReasonOptions(data)),
  locationChange: (location, target, type) =>
    dispatch(reactToLocationChange(location, target, null, type)),
  reset: () => dispatch(resetAppointmentForm())
})

const Editor = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  formWithCountryOptions(
    appointmentForm,
    'crmDetails'
  )(translate()(AppointmentDialog))
)

export default withMobileDialog()(withStyles(dialogStyles)(Editor))
