import {Component} from 'react'
import {connect} from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {translate} from 'react-i18next'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import {SlideUp} from '../../../components/Animations/Slides'
import Form from './Edit'
import {ValidatedForm} from '@fcg/formvalidation'
import ControlledDialogContent from '../../../components/ControlledDialogContent'
import {
  loadOptions,
  setDiscardReasonOptions,
  setRejectReasonOptions
} from '../../../store/crm/actions'
import {
  getLocationOptions,
  reactToLocationChange,
  getPurchaseCoordinatorOptions
} from '../../../store/config/actions'
import styles from '../common/dialogStyles'
import pick from 'lodash/pick'
import omit from 'lodash/omit'

import {
  updateWalkinForm,
  createWalkin,
  resetWalkinForm
} from '../../../store/crm/walkin/actions'
import * as formConfigs from '../../../config/pages/crm/forms'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {getFormAppointmentOptions} from '../Appointment/helpers'

const fieldsFromState = ['placeId', 'date', 'time', 'slot', 'type']

export class WalkinDialog extends Component {
  static defaultProps = {
    withTransition: true,
    fullScreenDialog: true,
    maxWidth: 'md'
  }

  state = {
    appointmentUsers: {options: [], count: 0},
    leadUsers: {options: [], count: 0}
  }

  componentDidMount() {
    this.props.loadOptions()
    /** In order to force validation in Lead form
     * lead form required fields are passed
     */
    this.setState({
      contact: this.props.contact,
      appointment: this.props.appointment,
      lead: this.props.lead,
      forcedFieldValidation: this.props.config.leadForm.requiredFields.reduce(
        (acc, key) => ({...acc, [key]: true}),
        {}
      )
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.appointment &&
      (nextProps.appointment.placeId || nextProps.appointment.type) &&
      (this.state.appointment.slot !== nextProps.appointment.slot ||
        nextProps.appointment.placeId !== this.state.appointment.placeId ||
        nextProps.appointment.type !== this.state.appointment.type)
    ) {
      this.setState({
        appointment: {
          ...this.state.appointment,
          ...pick(nextProps.appointment, ['slot', 'time', 'date', 'type']),
          placeId: nextProps.appointment.placeId
        }
      })
    }
  }

  setAppointmentUserOptions = ({options, count}) => {
    this.setState({appointmentUsers: {options, count}})
  }

  setLeadUserOptions = ({options, count}) => {
    this.setState({leadUsers: {options, count}})
  }

  submitForm = (event) => {
    event.preventDefault()

    const walkin = pick(this.state, ['contact', 'lead', 'appointment'])

    walkin.appointment = omit(walkin.appointment, ['slot'])

    this.form.validate([], this.state.forcedFieldValidation).then((success) => {
      if (success === true) {
        this.props.createWalkin(walkin)
      } else {
        this.content.scrollTop()
      }
    })
  }

  updateContact = ({name, value}) => {
    this.setState((state) => ({
      contact: {
        ...state.contact,
        [name]: value
      }
    }))
  }

  updateAppointment = ({name, value}) => {
    if (fieldsFromState.includes(name)) {
      this.props.updateAppointment({name, value})
    } else {
      this.setState((state) => ({
        appointment: {
          ...state.appointment,
          [name]: value
        }
      }))
    }
  }

  updateLead = ({name, value}) => {
    this.setState((state) => ({
      lead: {
        ...state.lead,
        [name]: value
      }
    }))
  }

  updateDiscardedOption = ({data}) => {
    this.props.setDiscardReasonOptions(data)
  }

  updateRejectionOption = ({data}) => {
    this.props.setRejectReasonOptions(data)
  }

  render() {
    const {classes, editState, t, withTransition} = this.props
    const Transition = withTransition ? SlideUp : undefined
    const formOptions = getFormAppointmentOptions(
      this.props.formOptions,
      this.state.appointment
    )

    if (editState.success !== false) {
      const id = editState.success.slice()
      this.props.reset()
      this.props.handleSubmit(id)
    }

    return (
      <div>
        <Dialog
          fullScreen={this.props.fullScreenDialog}
          maxWidth={this.props.maxWidth}
          open={this.props.isOpen}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle disableTypography className={classes.dialogHeadline}>
            <Typography
              variant='title'
              className={classes.title}
              color='inherit'
            >
              <DirectionsRun className={classes.icon} />
              {t('crm.forms.createWalkIn.heading')}
            </Typography>
          </DialogTitle>
          <ControlledDialogContent ref={(ref) => (this.content = ref)}>
            {editState.error !== false && (
              <Typography variant='body2' color='error'>
                {'error'}
              </Typography>
            )}
            <ValidatedForm ref={(ref) => (this.form = ref)} t={t}>
              <Form
                {...this.props}
                contact={this.state.contact}
                appointment={this.state.appointment}
                lead={this.state.lead}
                options={formOptions}
                updateContact={this.updateContact}
                updateAppointment={this.updateAppointment}
                updateLead={this.updateLead}
                appointmentUsers={this.state.appointmentUsers}
                leadUsers={this.state.leadUsers}
                setLeadUserOptions={this.setLeadUserOptions}
                setAppointmentUserOptions={this.setAppointmentUserOptions}
              />
            </ValidatedForm>
          </ControlledDialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color='default'>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='raised'
              color='primary'
              onClick={this.submitForm}
              disabled={editState.pending === true}
            >
              {t('button.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({config, appointment, walkin, crm}) => {
  const {contact, lead, editState} = walkin
  const contactSubset = omit(contact, config.crmDetails)
  const leadSubset = omit(lead, config.crmDetails)
  const appointmentSubset = omit(walkin.appointment, config.crmDetails)
  const {options} = crm
  const dateFormat = config.dateFormat
  const loadingSlots = appointment.loadingSlots
  const formOptions = {
    ...appointment.options,
    ...crm.options,
    ...config.options
  }

  return {
    contact: contactSubset,
    lead: leadSubset,
    appointment: appointmentSubset,
    options,
    editState,
    dateFormat,
    loadingSlots,
    formOptions
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateAppointment: ({name, value}) =>
    dispatch(updateWalkinForm('appointment', {name, value})),
  updateLead: ({name, value}) =>
    dispatch(updateWalkinForm('lead', {name, value})),
  updateContact: ({name, value}) =>
    dispatch(updateWalkinForm('contact', {name, value})),
  createWalkin: (walkin) => dispatch(createWalkin(walkin)),
  loadOptions: () => {
    dispatch(loadOptions())
    dispatch(getPurchaseCoordinatorOptions())
  },
  appointmentTypeChange: (type) => dispatch(getLocationOptions(type)),
  locationChange: (location, target, type) =>
    dispatch(reactToLocationChange(location, target, null, type)),
  reset: () => dispatch(resetWalkinForm()),
  setDiscardReasonOptions: (data) => dispatch(setDiscardReasonOptions(data)),
  setRejectReasonOptions: (data) => dispatch(setRejectReasonOptions(data))
})

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, {name: 'WalkinDialog'})(
      formWithCountryOptions(formConfigs, 'crmDetails', true, {page: 'walkin'})(
        translate()(WalkinDialog)
      )
    )
  )
)
