import {Component} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import Button from '@material-ui/core/Button'

import {
  Add,
  Edit,
  Cancel,
  Delete,
  Pageview,
  PhoneInTalk
} from '@material-ui/icons'
import throttle from 'lodash/throttle'
import noop from 'lodash/noop'
import {translate} from 'react-i18next'
import {getCountryConfig} from '../../../../config/country'

// State
import {
  getContact,
  entityActions,
  getLeadData,
  getLogs,
  clearLogsOnLoad
} from '../../../store/crm/detail/actions'
import {deleteLead} from '../../../store/crm/lead/actions'
import {deleteAppointment} from '../../../store/crm/appointment/actions'
import {deleteComment} from '../../../store/crm/comment/actions'
import {loadOptions} from '../../../store/crm/actions'
import {getLocationOptions} from '../../../store/config/actions'
import * as changeSelectors from '../../../store/crm/changes/selectors'
import {getLeadFields} from '../../../store/crm/lead/selectors'
import {getAppointmentsFromLeads} from '../../../store/crm/detail/selectors'

// UI
import ContactInfo from './Info'
import Editor from './Editor'
import LeadEditor from '../Lead/Editor'
import AppointmentEditor from '../Appointment/Editor'
import CancelDialog from '../Appointment/Cancel'
import CommentEditor from '../Comment/Editor'
import CommentPreview from '../Comment/Preview'
import PanelTableBase from '../../../components/PanelTableBase'
import ActionButton from '../../../components/layout/ActionButton'
import {Panel, Tabs, DeleteDialog} from '../common'
import '../CRM.styl'
import {
  contactDetailPhoneLinkConfigSelector,
  getHiddenFeatures,
  checkForCountrySelector
} from '../../../store/common/selectors'
import {
  alwaysTrue,
  getVisibleActions,
  isDeleteButtonVisible
} from '../common/helpers'
import {getUser, checkUserPermissions} from '../../../store/auth/selectors'
import {PermissionWrapper} from '../../../components/Permissions'

import AppointmentCount from '../../../components/AppointmentCount'
import {SubTable} from '@fcg/tars'
import {SelfInspectionDialog} from './SelfInspectionDialog/Dialog'
import LoadGenesysScript from '../../../components/Dialer/LoadGenesysScript.jsx'
import styled from 'styled-components'
import {
  isLoggedInUserDialerAgent,
  isOldEntity,
  orangeColorRow
} from '../helpers'
import {sortAppointmentsForLeads} from './utils'

const deleteInitialState = {
  open: false,
  handleSubmit: noop,
  handleCancel: noop,
  entity: null
}

const StyledDialerWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-bottom: 30px;
`

const ToolbarSectionWrapper = styled.div`
  width: 85%;
  float: right;
  padding-top: ${({headerBuffer}) => (headerBuffer ? '70px' : '0')};
`

const SectionWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: ${({headerBuffer}) => (headerBuffer ? '70px' : '0')};
`

export class ContactDetail extends Component {
  static displayName = 'CRM ContactDetail'

  state = {
    sectionMap: {},
    activeSection: 'leads',
    hasFullHeader: true,
    initialHeaderHeight: 0,
    delete: {...deleteInitialState},
    selfInspectionDialogOpen: false,
    selfInspectionData: null,
    selectedLeadIdForAppointment: null
  }

  topBuffer = 100

  constructor(props) {
    super(props)
    this.handleScroll = throttle(this.handleScroll, 200).bind(this)
    this.header = null
    this.sections = {}
  }

  componentDidMount() {
    const contactId = this.props.match.params.id

    if (typeof contactId === 'undefined') {
      this.props.history.back()
    } else {
      this.props.loadOptions()
      this.props.getData(contactId)
      this.props.clearLogsData()
      this.recalculateOffsets()
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    const contactId = this.props.match.params.id

    if (contactId !== prevProps.match.params.id) {
      this.props.loadOptions()
      this.props.getData(contactId)
    }
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action([id], this.handleUpdate)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  routeEdit = () => {
    this.props.history.replace(`${this.props.match.url}/edit`)
  }

  routeLeadCreate = () => {
    this.props.history.replace(`${this.props.match.url}/lead`)
  }

  routeLeadEdit = ({id}) => {
    this.props.history.replace(`${this.props.match.url}/lead/${id}/edit`)
  }

  routeCommentCreate = () => {
    this.props.history.replace(`${this.props.match.url}/comment`)
  }

  routeCommentEdit = ({id}) => {
    this.props.history.replace(`${this.props.match.url}/comment/${id}/edit`)
  }

  routeTaskCreate = () => {
    this.props.history.replace(`${this.props.match.url}/task`)
  }

  routeTaskEdit = ({id}) => {
    this.props.history.replace(`${this.props.match.url}/task/${id}/edit`)
  }

  routeAppointmentEdit = ({id}) => {
    this.props.history.replace(`${this.props.match.url}/appointment/${id}`)
  }

  routeAppointmentCancel = ({id, leadId}) => {
    this.setState({selectedLeadIdForAppointment: leadId})

    this.props.history.replace(
      `${this.props.match.url}/appointment/${id}/cancel`
    )
  }

  routeAppointmentCreate = ({id}) => {
    this.props.history.replace(`${this.props.match.url}/lead/${id}/appointment`)
  }

  loadLogsOnClick = () => {
    const contactId = this.props.match.params.id
    this.props.getLogsData(contactId)
  }

  handleScroll() {
    if (
      this.state.hasFullHeader &&
      this.header !== null &&
      window.scrollY > this.header.clientHeight * 0.75
    ) {
      this.setState({hasFullHeader: false}, this.recalculateOffsets)
    } else if (
      !this.state.hasFullHeader &&
      this.header !== null &&
      window.scrollY <= this.header.clientHeight * 0.75
    ) {
      this.setState({hasFullHeader: true}, this.recalculateOffsets)
    } else {
      this.findActiveSection()
    }
  }

  recalculateOffsets = () => {
    const sectionMap = {}

    Object.keys(this.sections).map((section) => {
      sectionMap[section] = this.sections[section].offsetTop
    })
    this.setState({sectionMap}, this.findActiveSection)
  }

  findActiveSection = () => {
    const scrollPos = window.scrollY + this.getHeaderHeight() + this.topBuffer

    Object.keys(this.state.sectionMap).map((section) => {
      if (this.state.sectionMap[section] <= scrollPos) {
        this.highlightSection(section)
      }
    })
  }

  highlightSection = (section) => {
    this.setState({activeSection: section})
  }

  scrollToSection = (section) => {
    const contactId = this.props.match.params.id
    if (typeof this.sections[section] === 'undefined') {
      return
    }

    if (section === 'changes') {
      this.props.getLogsData(contactId)
      this.sections[section].scrollIntoView({
        block: 'start',
        behaviour: 'smooth'
      })
    } else {
      this.sections[section].scrollIntoView({
        block: 'end',
        behaviour: 'smooth'
      })
    }
  }

  getHeaderHeight = () => {
    return this.header === null ? 0 : this.header.clientHeight
  }

  handleUpdate = () => {
    const contactId = this.props.match.params.id

    if (typeof contactId === 'undefined') {
      this.props.history.back()
    } else {
      this.props.getData(contactId)
      this.routeBack()
    }
  }

  handleLeadUpdate = () => {
    this.props.leadActions.fetchData({onBeforeStore: getLeadData})
    this.routeBack()
  }

  handleCommentUpdate = () => {
    this.props.commentActions.fetchData()
    this.routeBack()
  }

  handleTaskUpdate = () => {
    this.props.taskActions.fetchData()
    this.routeBack()
  }

  routeBack = () => {
    this.props.history.replace(this.props.match.url)
  }

  renderAppointmentCancellation = (routeProps) => (
    <CancelDialog
      {...routeProps}
      appointments={this.props.appointmentsFromLeads}
      isOpen
      id={routeProps.match.params.id}
      handleSubmit={this.handleUpdate}
      handleClose={this.routeBack}
    />
  )

  renderAppointmentCommentPreview = ({match}) => {
    const appointment =
      match.params && match.params.commentId
        ? Object.values(this.props.detail.lead.data)
            .reduce((acc, lead) => [...acc, ...lead.appointments], [])
            .find((item) => item.id === match.params.commentId)
        : undefined

    const comment =
      typeof appointment !== 'undefined' ? appointment.comments : ''

    return (
      <CommentPreview isOpen comment={comment} handleClose={this.routeBack} />
    )
  }

  getTranslatedData = () => {
    const {changeLogData, t} = this.props
    const { countryCode } = getCountryConfig()
    let logList = []

    if (changeLogData) {
      logList = Object.values(changeLogData).map((log) => {
        const logType = log.type === 'carleadcallback' ? 'lead' : log.type
        const typePrefix = `entity.${logType}`
        const fieldPrefix = `${logType}.${log.field}.label`
        const operationPrefix = `history.document.${log.operation}`
        const hidePhoneNumber = ((log.field).toUpperCase() === 'PHONE' || (log.field).toUpperCase() === 'PHONE2' && countryCode === 'IN') ? "-" : log.value
        
        return {
          ...log,
          value: hidePhoneNumber,
          type: t(typePrefix) === typePrefix ? log.type : t(typePrefix),
          field: t(fieldPrefix) === fieldPrefix ? log.field : t(fieldPrefix),
          operation:
            t(operationPrefix) === operationPrefix
              ? log.operation
              : t(operationPrefix)
        }
      })
    }
    
    return logList
  }

  leadTableSingleSelectActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['UPDATE']
        }),
      action: {
        event: this.routeLeadEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'lead',
            id: item.id,
            action: this.props.deleteLead
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['CREATE']
        }),
      action: {
        event: this.routeAppointmentCreate,
        icon: <Add />,
        title: 'entity.appointment',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () => true,
      action: {
        event: (props) =>
          this.setState({
            selfInspectionData: props,
            selfInspectionDialogOpen: true
          }),
        icon: <Pageview />,
        title: 'crm.page.contact.leads.viewSelfInspection',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  leadTableSubTableActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['UPDATE']
        }),
      action: {
        event: this.routeAppointmentEdit,
        icon: <Edit />,
        title: `
        ${this.props.t('global.action.update')} ${this.props.t(
          'entity.appointment'
        )}
      `,
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'appointment',
            id: item.id,
            action: this.props.deleteAppointment
          })
        },
        icon: <Delete />,
        title: `
        ${this.props.t('global.action.delete')} ${this.props.t(
          'entity.appointment'
        )}
      `,
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.cancelAppointment',
          types: ['READ']
        }),
      action: {
        event: this.routeAppointmentCancel,
        icon: <Cancel />,
        title: 'global.action.cancelAppointment',
        hide: (item) => !isOldEntity(item) || item.cancelledAt !== null
      }
    }
  ]

  commentTableSingleSelectActions = [
    {
      test: () => !this.props.isDialerAgent,
      action: {
        event: this.routeCommentEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: isDeleteButtonVisible,
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'comment',
            id: item.id,
            action: this.props.deleteComment
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  taskTableSingleSelectActions = [
    {
      test: alwaysTrue,
      action: {
        event: this.routeTaskEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: isDeleteButtonVisible,
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'comment',
            id: item.id,
            action: this.props.deleteComment
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  getLeadTableSingleSelectActions = () =>
    getVisibleActions(this.leadTableSingleSelectActions, this.props)

  getLeadTableSubTableActions = () =>
    getVisibleActions(this.leadTableSubTableActions, this.props)

  getCommentTableSingleSelectActions = () =>
    getVisibleActions(this.commentTableSingleSelectActions, this.props)

  getTaskTableSingleSelectActions = () =>
    getVisibleActions(this.taskTableSingleSelectActions, this.props)

  phoneLinkHandler = () => {
    const {detail, pageConfig} = this.props
    const {showDialerToolbar, phone} = getCountryConfig()
    const phoneNumber = detail.detail.phone
    if (showDialerToolbar === true && window.doDial) {
      // remove leading zeros from phoneNumber
      let formattedPhoneNumber = Number(phoneNumber).toString()

      // add country code if it is not added
      if (formattedPhoneNumber.length === phone.length[0]) {
        formattedPhoneNumber = `${phone.code}${formattedPhoneNumber}`
      }

      // Add + sign to
      // send phone number in +<countryCode><mobileNumber> format to Genesys
      window.doDial(`+${formattedPhoneNumber}`)
    } else {
      const newWindow = window.open(pageConfig.phoneLink.link, '_blank')
      newWindow.focus()
    }
  }

  closeSelfInspectionDialog = () =>
    this.setState({
      selfInspectionDialogOpen: false,
      selfInspectionData: null
    })

  isFieldVisible = (filed) => {
    return !this.props.pageConfig.hidden[filed]
  }

  getDetailsSections = () => {
    const showDialerToolbar =
      getCountryConfig().showDialerToolbar === true &&
      this.props.hasPermissions({
        entity: 'web.admin.ui.crm.dialer',
        types: ['READ']
      })

    const {lead, comment, task, changes} = this.props.detail
    const {t, pageConfig, leadFields} = this.props
    const Wrapper = showDialerToolbar ? ToolbarSectionWrapper : SectionWrapper
    const headerBuffer =
      this.state.hasFullHeader === false
        ? {paddingTop: '70px', zIndex: 11}
        : {zIndex: 11}

    const data = sortAppointmentsForLeads(lead)

    const sections = (
      <Wrapper headerBuffer={this.state.hasFullHeader === false}>
        <section
          ref={(section) => {
            this.sections.leads = section
          }}
        >
          <Panel
            header={t('crm.page.contact.leads.heading')}
            actionButton={
              <PermissionWrapper
                entity='web.admin.ui.crm.leads'
                types={['CREATE']}
              >
                {isOldEntity({
                  createdAt: new Date()
                }) && (
                  <Button
                    variant='raised'
                    size='small'
                    color='primary'
                    disabled={this.props.fetching}
                    onClick={this.routeLeadCreate}
                  >
                    <Add />
                    {t('global.action.create')}
                  </Button>
                )}
              </PermissionWrapper>
            }
          >
            <PanelTableBase
              noToolbar
              expandSubTables
              {...lead}
              multisort={false}
              data={data}
              fields={leadFields}
              {...this.props.leadActions}
              singleSelectActions={this.getLeadTableSingleSelectActions()}
              subTableRowActions={this.getLeadTableSubTableActions()}
              LeftActions={AppointmentCount}
              ExpandableComponent={SubTable}
              rowStyle={orangeColorRow}
            />
          </Panel>
        </section>

        <section
          ref={(section) => {
            this.sections.comments = section
          }}
        >
          <Panel
            header={t('crm.page.contact.comments.heading')}
            actionButton={
              isOldEntity({
                createdAt: new Date()
              }) && (
                <Button
                  variant='raised'
                  size='small'
                  disabled={this.props.fetching}
                  color='primary'
                  onClick={this.routeCommentCreate}
                >
                  <Add />
                  {t('entity.comment')}
                </Button>
              )
            }
          >
            <PanelTableBase
              noToolbar
              {...comment}
              data={Object.values(comment.data)}
              {...this.props.commentActions}
              fields={Object.values(pageConfig.commentTable.fields)}
              singleSelectActions={this.getCommentTableSingleSelectActions()}
            />
          </Panel>
        </section>
        <section
          ref={(section) => {
            this.sections.tasks = section
          }}
        >
          <Panel
            header={t('crm.page.contact.tasks.heading')}
            actionButton={
              isOldEntity({
                createdAt: new Date()
              }) && (
                <Button
                  variant='raised'
                  size='small'
                  disabled={this.props.fetching}
                  color='primary'
                  onClick={this.routeTaskCreate}
                >
                  <Add />
                  {t('entity.task')}
                </Button>
              )
            }
          >
            <PanelTableBase
              noToolbar
              {...task}
              data={Object.values(task.data)}
              {...this.props.taskActions}
              fields={Object.values(pageConfig.taskTable.fields)}
              singleSelectActions={this.getTaskTableSingleSelectActions()}
            />
          </Panel>
        </section>
        {getCountryConfig().hasLogsInContactDetail ? (
          <section
            ref={(section) => {
              this.sections.changes = section
            }}
            style={{marginBottom: '100px'}}
          >
            <Panel
              header={t('crm.page.contact.changelog.heading')}
              actionButton={
                <Button
                  variant='raised'
                  size='small'
                  disabled={this.props.detail.fetchingLogs}
                  color='primary'
                  onClick={this.loadLogsOnClick}
                >
                  {t('entity.reloadlogs')}
                </Button>
              }
            >
              <PanelTableBase
                noToolbar
                {...changes}
                {...this.props.changesActions}
                data={this.getTranslatedData()}
                fields={Object.values(pageConfig.changesTable.fields)}
              />
            </Panel>
          </section>
        ) : null}

        <ActionButton>
          <PermissionWrapper
            entity='web.admin.ui.crm.contacts'
            types={['UPDATE']}
          >
            {isOldEntity({
              createdAt: new Date()
            }) && (
              <Button
                variant='fab'
                color='primary'
                disabled={this.props.fetching}
                onClick={this.routeEdit}
              >
                <Edit />
              </Button>
            )}
          </PermissionWrapper>
          {getCountryConfig().showDialerToolbar ? (
            <Button
              variant='fab'
              color='primary'
              title='Dialer'
              disabled={this.props.fetching}
              onClick={this.phoneLinkHandler}
            >
              <PhoneInTalk />
            </Button>
          ) : null}
        </ActionButton>
      </Wrapper>
    )

    return showDialerToolbar ? (
      <StyledDialerWrapper>
        {showDialerToolbar && <LoadGenesysScript styles={headerBuffer} />}
        {sections}
      </StyledDialerWrapper>
    ) : (
      sections
    )
  }

  render() {
    const {detail: contact} = this.props.detail
    const {t} = this.props

    if (
      typeof contact === 'undefined' ||
      contact.fetching === true ||
      typeof contact.fetching === 'undefined'
    ) {
      return null
    }

    const contactId = contact.id
    const contactDetailSections = this.getDetailsSections()

    return (
      <div className='page'>
        <section
          ref={(section) => {
            this.header = section
          }}
          className={`header ${
            this.state.hasFullHeader ? '' : 'header--sticky'
          }`}
        >
          <ContactInfo
            fieldAugment={{
              phone: {
                shouldAugment: this.props.pageConfig.phoneLink.canLinkPhone,
                onClick: this.phoneLinkHandler
              }
            }}
            {...contact}
            showFull={this.state.hasFullHeader}
            isVisible={this.isFieldVisible}
          />
          <Tabs
            sections={[
              {label: t('crm.page.contact.leads.heading'), key: 'leads'},
              {label: t('crm.page.contact.comments.heading'), key: 'comments'},
              {label: t('crm.page.contact.tasks.heading'), key: 'tasks'},
              {label: t('crm.page.contact.changelog.heading'), key: 'changes'}
            ]}
            handleSelect={this.scrollToSection}
            sticky={this.state.hasFullHeader}
            selected={this.state.activeSection}
          />
        </section>
        {contactDetailSections}
        <PermissionWrapper
          entity='web.admin.ui.crm.contacts'
          types={['UPDATE']}
        >
          <Route
            exact
            path={`${this.props.match.path}/edit`}
            render={(routeProps) => (
              <Editor
                {...routeProps}
                isOpen
                handleSubmit={this.handleUpdate}
                handleClose={this.routeBack}
              />
            )}
          />
        </PermissionWrapper>
        <PermissionWrapper entity='web.admin.ui.crm.leads' types={['UPDATE']}>
          <Route
            exact
            path={`${this.props.match.url}/lead/:leadId/edit`}
            render={(props) => (
              <LeadEditor
                {...props}
                isOpen
                handleClose={this.routeBack}
                handleSubmit={this.handleLeadUpdate}
              />
            )}
          />
        </PermissionWrapper>
        <PermissionWrapper entity='web.admin.ui.crm.leads' types={['CREATE']}>
          <Route
            exact
            path={`${this.props.match.url}/lead`}
            render={(props) => (
              <LeadEditor
                {...props}
                creation
                isOpen
                contactId={contactId}
                handleClose={this.routeBack}
                handleSubmit={this.handleLeadUpdate}
              />
            )}
          />
        </PermissionWrapper>
        <PermissionWrapper
          entity='web.admin.ui.crm.appointments2'
          types={['CREATE']}
        >
          <Route
            exact
            path={`${this.props.match.url}/lead/:leadId/appointment`}
            render={(props) => {
              const leadId = props.match.params.leadId

              return (
                <AppointmentEditor
                  creation
                  isOpen
                  leadId={leadId}
                  handleClose={this.routeBack}
                  handleSubmit={this.handleLeadUpdate}
                />
              )
            }}
          />
        </PermissionWrapper>
        <PermissionWrapper
          entity='web.admin.ui.crm.appointments2'
          types={['UPDATE']}
        >
          <Route
            exact
            path={`${this.props.match.url}/appointment/:id`}
            render={(props) => (
              <AppointmentEditor
                {...props}
                isOpen
                handleClose={this.routeBack}
                handleCancel={this.routeAppointmentCancel}
                handleSubmit={this.handleLeadUpdate}
              />
            )}
          />
        </PermissionWrapper>
        <PermissionWrapper
          entity='web.admin.ui.crm.cancelAppointment'
          types={['READ']}
        >
          <Route
            exact
            path={`${this.props.match.path}/appointment/:id/cancel`}
            render={this.renderAppointmentCancellation}
          />
        </PermissionWrapper>
        <SelfInspectionDialog
          t={this.props.t}
          isOpen={this.state.selfInspectionDialogOpen}
          onClose={this.closeSelfInspectionDialog}
          data={this.state.selfInspectionData}
        />
        <Route
          path={`${this.props.match.path}/appointment/comment/:commentId`}
          render={this.renderAppointmentCommentPreview}
        />
        <Route
          exact
          path={`${this.props.match.url}/comment`}
          render={(props) => (
            <CommentEditor
              {...props}
              creation
              type='comment'
              isOpen
              entity='contact'
              ids={[contact.id]}
              handleClose={this.routeBack}
              handleSubmit={this.handleCommentUpdate}
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/task`}
          render={(props) => (
            <CommentEditor
              {...props}
              creation
              type='task'
              entity='contact'
              isOpen
              ids={[contact.id]}
              handleClose={this.routeBack}
              handleSubmit={this.handleTaskUpdate}
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/comment/:id/edit`}
          render={(props) => (
            <CommentEditor
              {...props}
              isOpen
              type='comment'
              entity='contact'
              ids={[contactId]}
              handleClose={this.routeBack}
              handleSubmit={this.handleCommentUpdate}
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.url}/task/:id/edit`}
          render={(props) => (
            <CommentEditor
              {...props}
              isOpen
              type='task'
              entity='contact'
              ids={[contactId]}
              handleClose={this.routeBack}
              handleSubmit={this.handleTaskUpdate}
            />
          )}
        />
        <DeleteDialog {...this.state.delete} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    detail: state.detail,
    changeLogData: changeSelectors.getChangeLog(state.detail),
    user: getUser(state),
    leadFields: getLeadFields(state),
    pageConfig: {
      phoneLink: contactDetailPhoneLinkConfigSelector(state),
      hidden: {
        ...getHiddenFeatures(state)
      },
      ...checkForCountrySelector(state),
      ...ownProps.pageConfig
    },
    isDialerAgent: isLoggedInUserDialerAgent(state),
    hasPermissions: (permissions) => checkUserPermissions(state, permissions),
    appointmentsFromLeads: getAppointmentsFromLeads(
      state,
      ownProps.selectedLeadId
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const {lead, comment, task, changes} = entityActions(dispatch)

  return {
    loadOptions: () => {
      dispatch(getLocationOptions())
      dispatch(loadOptions())
    },
    getData: (id) => {
      dispatch(getContact({id}))
    },
    getLogsData: (id) => {
      dispatch(getLogs({id}))
    },
    clearLogsData: () => dispatch(clearLogsOnLoad()),
    leadActions: lead,
    commentActions: comment,
    taskActions: task,
    changesActions: changes,
    deleteAppointment: (ids, next) => dispatch(deleteAppointment({ids}, next)),
    deleteLead: (ids, next) => dispatch(deleteLead({ids}, next)),
    deleteComment: (ids, next) => dispatch(deleteComment(ids, next))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(ContactDetail))
