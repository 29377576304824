import {isNumber} from 'lodash'

const enumsToConvert = {
  allowedBidTypes: {
    REGULAR: 'Regular',
    BUY_NOW: 'Buy now',
    AUTO_BID: 'Auto bid',
    PRE_AUTO_BID: 'Pre auto bid',
    OFFER: 'Offer',
    REJECT: 'Reject',
    RESERVE: 'Reserve'
  }
}

export const onAuctionEndStatus = {
  AUTOCONFIRM: 'Autoconfirm',
  AUTORESERVE: 'Autoreserve',
  NONE: 'None'
}

const keySeparators = {
  duration: ' ',
  allowedBidTypes: ', '
}

const convertEnums = ({key, value}) => {
  if (enumsToConvert[key]) {
    return value.map((item) => enumsToConvert[key][item])
  }

  return value
}

export const getOnAuctionEnd = (
  autoConfirmList = [],
  autoReserveList = [],
  key
) => {
  const ret = []

  if (autoConfirmList.includes(key)) {
    ret.push(onAuctionEndStatus.AUTOCONFIRM)
  }

  if (autoReserveList.includes(key)) {
    ret.push(onAuctionEndStatus.AUTORESERVE)
  }

  return ret.length > 0
    ? ret.join(keySeparators.allowedBidTypes)
    : onAuctionEndStatus.NONE
}

const joinComplexValues = ({key, value}) =>
  convertEnums({key, value}).join(keySeparators[key])

export const normalizeFields = (scheduleObject) => {
  try {
    return Object.entries(scheduleObject).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? joinComplexValues({key, value}) : value
      }),
      {}
    )
  } catch (ex) {
    console.warn(ex)
    return {}
  }
}

export const normalizeScheduleItem = (schedules) => {
  if (!schedules || !Array.isArray(schedules)) {
    return []
  }

  return schedules.map((item) => normalizeFields(item))
}

const convertDiscountToPercentage = (value) => {
  return isNumber(value) ? `${Math.floor(value * 100).toFixed(2)} %` : value
}

const normalizeDiscounts = (items) => {
  return items.map((item) => {
    let newItem = {...item}

    if (item.discount) {
      newItem.discount = convertDiscountToPercentage(item.discount)
    }

    if (item.buyNowDiscount) {
      newItem.buyNowDiscount = convertDiscountToPercentage(item.buyNowDiscount)
    }

    return newItem
  })
}

export const getSchedulesForItem = (flowItem) => {
  if (Array.isArray(flowItem)) {
    return normalizeDiscounts([...flowItem])
  } else if (Object.keys(flowItem).includes('schedule')) {
    if (Array.isArray(flowItem.schedule)) {
      return normalizeDiscounts([...flowItem['schedule']])
    } else if (
      flowItem?.schedule?.schedule &&
      Array.isArray(flowItem.schedule.schedule)
    ) {
      return normalizeDiscounts([...flowItem['schedule']['schedule']])
    }
  }

  return []
}
