import {leadsAdId} from '../../../config/pages/crm/leads'

function AdId(props) {
  const {disabled, link} = leadsAdId

  const label = 'Ad link'

  if (props.data) {
    return disabled ? (
      <div>{props.data}</div>
    ) : (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`${link}/${props.data}`}
      >
        {label}
      </a>
    )
  }

  return ''
}

export default AdId
