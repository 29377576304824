const resourceName = 'category'

export const fetchCategoryList = `${resourceName}/FETCH_CATEGORY_LIST`
export const fetchCategoryListSuccess = `${resourceName}/FETCH_CATEGORY_LIST_SUCCESS`
export const fetchCategoryListError = `${resourceName}/FETCH_CATEGORY_LIST_ERROR`

export const downloadCategoryListItem = `${resourceName}/DOWNLOAD_CATEGORY_LIST_ITEM`
export const downloadCategoryListItemSuccess = `${resourceName}/DOWNLOAD_CATEGORY_LIST_ITEM_SUCCESS`
export const downloadCategoryListItemError = `${resourceName}/DOWNLOAD_CATEGORY_LIST_ITEM_ERROR`

export const downloadCategoryData = `${resourceName}/DOWNLOAD_CATEGORY_DATA`
export const downloadCategoryDataSuccess = `${resourceName}/DOWNLOAD_CATEGORY_DATA_SUCCESS`
export const downloadCategoryDataError = `${resourceName}/DOWNLOAD_CATEGORY_DATA_ERROR`

export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`

export const uploadCategoryData = `${resourceName}/UPLOAD_CATEGORY_DATA`
export const uploadCategoryDataSuccess = `${resourceName}/UPLOAD_CATEGORY_DATA_SUCCESS`
export const uploadCategoryDataError = `${resourceName}/UPLOAD_CATEGORY_DATA_ERROR`
