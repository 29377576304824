import {useState, useCallback} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {getCountryOptionsSelector} from '../../../store/crm/selectors'
import DialogTitleWithClose from '../../../components/DialogTitleWithClose/DialogTitleWithClose'

const countryOptionsSelector = getCountryOptionsSelector()

import './TagModal.styl'

const classes = {
  root: {
    backgroundColor: '#00bcd4',
    color: '#000'
  },
  label: {
    textTransform: 'uppercase'
  }
}

const SaveButton = withStyles(classes)(Button)

export function TagModal({t, onClose, tagInfo, saveTags, options}) {
  const [tagObj, setTagsValue] = useState(() => {
    const initialTagState = {}

    options.forEach((tagName) => {
      initialTagState[tagName] = {
        id: null,
        status: false
      }
    })

    if (tagInfo && tagInfo.length > 0) {
      tagInfo.forEach((tag) => {
        initialTagState[tag.tagName].id = tag.id
        initialTagState[tag.tagName].status = !tag.markDeleted
      })
    }

    return initialTagState
  })

  const onChangeHandler = useCallback(
    (evt, tagName) => {
      const selectedTag = tagObj[tagName]

      const updatedTag = {
        ...selectedTag,
        status: !selectedTag['status']
      }

      const updatedTags = {...tagObj, [tagName]: {...updatedTag}}
      setTagsValue(updatedTags)
    },
    [tagObj]
  )

  const onSaveTags = useCallback(() => {
    const formatedTags = Object.keys(tagObj)
      .map((tagKey) => {
        if (tagObj[tagKey]['id'] || tagObj[tagKey]['status'])
          return {
            tagName: tagKey,
            id: tagObj[tagKey]['id'],
            markDeleted: tagObj[tagKey]['id']
              ? !tagObj[tagKey]['status']
              : false
          }
      })
      .filter((ele) => ele !== undefined)

    saveTags(formatedTags)
    onClose()
  }, [onClose, saveTags, tagObj])

  return (
    <Dialog className='bpTagModal' open onClose={onClose} maxWidth='md'>
      <DialogTitleWithClose className='addBPModal__title' onClose={onClose}>
        <h2 className='bpTagModal__title'>
          {t('businessPartners.tag.heading')}
        </h2>
      </DialogTitleWithClose>
      <DialogContent>
        <Grid container className='bpTagModal__content'>
          {Object.keys(tagObj).map((keyValue) => {
            return (
              <FormControlLabel
                className='bpTagModal__items'
                key={keyValue}
                id={keyValue}
                control={
                  <Checkbox
                    checked={tagObj[keyValue]['status']}
                    onChange={(evt) => onChangeHandler(evt, keyValue)}
                  />
                }
                label={t(`businessPartners.tag.${keyValue}`)}
              />
            )
          })}
        </Grid>

        <SaveButton
          className='bpTagModal__button'
          color='primary'
          variant='contained'
          onClick={onSaveTags}
        >
          {t('button.save')}
        </SaveButton>
      </DialogContent>
    </Dialog>
  )
}

TagModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  saveTags: PropTypes.func,
  options: PropTypes.array
}

export default compose(
  translate(),
  connect((state, ownProps) => ({
    options: countryOptionsSelector(state, ownProps)
  }))
)(TagModal)
