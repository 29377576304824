import {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import {translate} from 'react-i18next'

import Store from '../../../utils/localStorage'
// RichTable
import {FilterBar} from '../../../components/RichTable/FilterBar'
import FilterSave from '../../../components/RichTable/FilterBar/FilterSave'
// Filters
import {getFilterConfig} from './Filters/filterConfig'
import DayPicker from './Filters/DayPicker'
// Redux
import {
  fetchLocationOptions,
  onSavedFilterChange,
  setDay,
  modifyDayBy,
  onDateFilterUpdate,
  updateFilters,
  setCalendarId,
  onPresetSelect
} from '../../../store/calendar/actions'
// Selectors
import * as selectors from '../../../store/calendar/selectors'
import {isOpen} from '../../../components/Pickers/Calendar/store/selectors'
import {toggleOpen} from '../../../components/Pickers/Calendar/store/actions'
import {Refresh} from './Filters/Refresh'

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 10px 5px 0px;
  flex-direction: row;
  justify-content: space-between;
`
const FilterActions = styled.div`
  display: flex;
`
const CalendarActions = styled.div`
  display: flex;
`

class AppointmentFilter extends Component {
  constructor(props) {
    super(props)

    this.storage = null
  }

  componentDidMount() {
    if (this.storage === null) {
      this.initializeStorage()
    }

    this.props.setCalendarId(this.props.calendarId)
    this.goToToday()
  }

  initializeStorage = async () => {
    this.storage = await new Store('appointmentCalendar')
    const filter = await this.storage.retrieve({name: 'saved_filters'})

    if (filter) {
      this.props.onSavedFilterChange(filter)
    }
  }

  handleStateSave = async ({filter}) => {
    const {savedFilters} = this.props

    savedFilters.push(filter)
    await this.storage.save({
      name: 'saved_filters',
      value: savedFilters
    })

    if (savedFilters) {
      this.props.onSavedFilterChange(savedFilters)
    }
  }

  handleFilterRemove = async (filter) => {
    const savedFilters = this.props.savedFilters.filter(
      (saved) => saved.id !== filter.id
    )

    await this.storage.save({
      name: 'saved_filters',
      value: savedFilters
    })

    if (savedFilters) {
      this.props.onSavedFilterChange(savedFilters)
    }
  }

  onFilterChange = ({filters}) => {
    const dateFilter = this.props.filters.filter(
      (filter) => filter.key === 'date'
    )

    this.props.updateFilters([...dateFilter, ...filters])
  }

  goBack = () => this.props.modifyDayBy(-1)
  goForward = () => this.props.modifyDayBy(1)
  goToToday = () => this.props.setDay(moment().format(), true)

  render() {
    return (
      <FilterContainer>
        <FilterActions key={'appointment-filter-filters'}>
          <FilterBar
            fields={getFilterConfig()}
            filters={this.props.filters}
            savedStates={this.props.savedFilters}
            presets={[]}
            onSavedFilterRemove={this.handleFilterRemove}
            onFilterChange={this.onFilterChange}
            onPresetSelect={this.props.onPresetSelect}
            show
          />
          <FilterSave
            show
            key='tool-stateSave'
            filters={this.props.filters}
            save={this.handleStateSave}
          />
        </FilterActions>
        <CalendarActions key={'appointment-filter-calendar'}>
          <Refresh
            isLoading={this.props.isCalendarLoading}
            key={'appointment-filter-refresh'}
            onClick={this.props.refreshData}
            t={this.props.t}
          />
          <DayPicker
            key={'appointment-filter-day-picker'}
            date={this.props.date}
            handleSave={this.props.dateFilterUpdate}
            calendarId={this.props.calendarId}
            handleLeftButton={this.goBack}
            handleRightButton={this.goForward}
            goToToday={this.goToToday}
            isLoading={this.props.isCalendarLoading}
            isOpen={this.props.isCalendarOpen}
            toggleCalendar={this.props.toggleCalendar}
          />
        </CalendarActions>
      </FilterContainer>
    )
  }
}

const mapStateToProps = (state, {calendarId}) => ({
  date: selectors.getDateFilterValue(state),
  filters: selectors.getSelectedFilters(state),
  savedFilters: selectors.getSavedFilters(state),
  isCalendarLoading: selectors.isCalendarLoading(state),
  isCalendarOpen: isOpen(calendarId)(state)
})

const mapDispatchToProps = (dispatch, {calendarId}) => ({
  setCalendarId: (id) => dispatch(setCalendarId(id)),
  fetchLocationOptions: () => dispatch(fetchLocationOptions()),
  updateFilters: (filters) => dispatch(updateFilters(filters)),
  onSavedFilterChange: (savedFilter) =>
    dispatch(onSavedFilterChange(savedFilter)),
  setDay: (date, keepOpen) => dispatch(setDay(date, keepOpen)),
  dateFilterUpdate: () => dispatch(onDateFilterUpdate()),
  modifyDayBy: (operand) => dispatch(modifyDayBy(operand)),
  onPresetSelect: ({filters}) => dispatch(onPresetSelect(filters)),
  toggleCalendar: () => dispatch(toggleOpen(calendarId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(AppointmentFilter))
