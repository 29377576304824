import Select from '../../../components/Select/Select'

const status = ['applied', 'onboarding', 'active', 'inactive', 'blocked'].map(
  (status) => ({
    value: status,
    label: `dealer.page.field.status.${status}`
  })
)

const StatusBase = (props) => {
  return (
    <Select
      {...props}
      withTranslate
      id={props.key}
      label={props.label}
      options={status}
      selected={props.value}
      onChange={props.handleChange(props.filterName)}
    />
  )
}

export default StatusBase
