import React, {Component} from 'react'
import UploadDialog from '../../../components/Upload/UploadDialog'
import StatefulInput from '../../../components/StatefulInput/StatefulInput'

const hiddenColumns = {
  uploader: true
}

const getCommentColumn = ({onCommentBlur}) => (state) => [
  {
    key: 'metaInfoComment',
    header: 'documentList.comment',
    cell: StatefulInput,
    withWrapper: true,
    wrapperProps: {
      className: 'row__metaInfoComment'
    },
    props: {
      nativeInput: true,
      inputType: 'textarea',
      placeholder: 'payslips.document.placeholder.comment',
      onBlur: onCommentBlur,
      type: 'text'
    },
    defaultData: '',
    getInitialData: (documentId) => state[documentId]
  }
]

export default class OwnershipDocumentUploadDialog extends Component {
  constructor(props) {
    super(props)

    /** [documentId]: 'comment' */
    this.state = {}

    this.customColumns = getCommentColumn({onCommentBlur: this.onCommentBlur})
  }

  onCommentBlur = ({documentId, event}) =>
    this.setState({[documentId]: event?.target?.value})

  onDialogClose = () => {
    this.setState({}, this.props.onDialogClose)
  }

  onDialogConfirm = () => {
    this.props.onDialogConfirm(this.state)
  }

  render() {
    return (
      <UploadDialog
        maxWidth='lg'
        listWidth='flex'
        t={this.props.t}
        title='carOwnershipTransfer.uploadDocument.dialogTitle'
        isOpen={this.props.isOpen}
        onDialogClose={this.onDialogClose}
        onUploadDocument={this.props.onUploadDocument}
        onDialogConfirm={this.onDialogConfirm}
        isLoading={this.props.isLoading}
        hiddenColumns={hiddenColumns}
        rowHeight='xl'
        tableHeight='lg'
        customColumns={this.customColumns(this.state)}
      />
    )
  }
}
