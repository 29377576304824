import * as types from './types'
import {logout} from '../auth/actions'
import {setErrorHandlingAction} from '../../utils/errorHandler'
import {
  InvalidTokenError,
  NotFoundError,
  BadRequestError,
  AuthNoPermissionsError
} from '../../utils/errors'

setErrorHandlingAction(InvalidTokenError, () => [
  logout(),
  error({
    apiError: true,
    errorMessage: InvalidTokenError,
    showErrorDialog: true
  })
])

setErrorHandlingAction(NotFoundError, () => ({
  type: types.apiError,
  payload: {
    apiError: true,
    errorMessage: NotFoundError,
    showErrorDialog: true
  }
}))

setErrorHandlingAction(BadRequestError, () => ({
  type: types.apiError,
  payload: {
    apiError: true,
    errorMessage: BadRequestError,
    showErrorDialog: true
  }
}))

setErrorHandlingAction(AuthNoPermissionsError, () => ({
  type: types.apiError,
  payload: {
    apiError: true,
    errorMessage: AuthNoPermissionsError,
    showErrorDialog: true
  }
}))

export const userNotLoggedIn = () => ({
  type: types.userNotLoggedIn,
  payload: null
})

export const authError = () => ({
  type: types.authError,
  payload: null
})

export const success = (payload) => ({
  type: types.success,
  payload
})

export const error = (payload) => ({
  type: types.error,
  payload
})

export const resetNotification = () => ({
  type: types.reset,
  payload: null
})

export const toggleMenu = (control) => ({
  type: types.toggleMenu,
  payload: control || null
})

export const apiError = (e) => {
  // eslint-disable-next-line no-console
  console.error(e)

  return {
    type: types.apiError,
    payload: {
      apiError: true,
      errorMessage: e,
      showErrorDialog: true,
      errorType: e.errorType
    }
  }
}

export const showErrorDialog = () => ({
  type: types.showErrorDialog,
  payload: true
})

export const dismissApiError = () => ({
  type: types.apiError,
  payload: {showErrorDialog: false, apiError: false, errorMessage: ''}
})
