import {useMemo, useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import {RichTable} from '@fcg/tars'
import {BP_SEARCH_RESULT_HEADER_OPTIONS} from '../../constants/businessPartners'

export function BPSearchResults({
  t,
  onCreateNewBp,
  onSelectingBp,
  resultData,
  anySearchTriggered
}) {
  const [selectedBpIdx, selectBpIdx] = useState(null)

  const fields = useMemo(
    () =>
      BP_SEARCH_RESULT_HEADER_OPTIONS.map((headerId) => {
        return {
          key: headerId,
          label:
            headerId === 'action'
              ? '  '
              : t(`businessPartners.${headerId}.text`),
          type: 'string',
          isFilterable: false
        }
      }),
    [t]
  )

  const onRadioButtonClick = useCallback((evt) => {
    evt.persist()
    selectBpIdx((currSelectedBpIdx) => {
      if (currSelectedBpIdx === evt.target.value) {
        evt.target.checked = false

        return null
      }

      return evt.target.value
    })
  }, [])

  const data = useMemo(
    () =>
      resultData.map((value, idx) => {
        return {
          legalName: value.legalName,
          email: value.email,
          phone: value.phone,
          id: value.id,
          action: (
            <input
              type='radio'
              name='business-partner'
              value={idx}
              onClick={onRadioButtonClick}
            />
          )
        }
      }),
    [resultData, onRadioButtonClick]
  )

  const searchResultHeading =
    data.length > 0
      ? `${data.length} ${t('businessPartners.search.resultFound.text')}`
      : `0 ${t('businessPartners.search.resultFound.text')} ${t(
          'businessPartners.search.searchAgain.text'
        )}`

  return (
    <div className='bpSearchResults'>
      <div className='bpSearchResults__recordCount'>
        {anySearchTriggered && searchResultHeading}
      </div>

      <RichTable
        className='bpSearchResults__richtable'
        data={data}
        fields={fields}
        noFooter
        noToolbar
      />

      <div className='buttonContainer'>
        <Button
          className='createNewBtn'
          variant='contained'
          color='primary'
          disabled={selectedBpIdx !== null}
          onClick={onCreateNewBp}
        >
          {t('businessPartners.search.createNew.text')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          className='selectBpBtn'
          onClick={() => onSelectingBp(selectedBpIdx)}
          disabled={selectedBpIdx === null}
        >
          {t('businessPartners.search.addSelected.text')}
        </Button>
      </div>
    </div>
  )
}

BPSearchResults.propTypes = {
  t: PropTypes.func.isRequired,
  resultData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string
    })
  ),
  onCreateNewBp: PropTypes.func,
  onSelectingBp: PropTypes.func
}

BPSearchResults.defaultProps = {
  onCreateNewBp: () => {},
  onSelectingBp: () => {},
  resultData: [],
  t: () => {}
}

export default translate()(BPSearchResults)
