import 'date-fns'
import React, {useState} from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'

import DialogTemplate from '../../../../components/Dialog/Dialog'
import FormRow from '../../../../components/Form/FormRow'
import RowDescription from '../../../../components/Form/RowDescription'
import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import {returnHolidayDateString} from '../../../../config/utils'
import HolidayPickerCities from './HolidayPickerCities'
import HolidayPickerServices from './HolidayPickerServices'
import OpeningTime from '../../../../types/OpeningTime'
import HolidayObject from '../../../../types/HolidayObject'

interface HolidayPickerProps {
  open: boolean
  selectedHoliday?: HolidayObject
  isMaster: boolean
  onCancel: () => void
  onConfirm: (holiday: HolidayObject) => void
  onDelete: (id: string) => void
}

const minDate=new Date()

const defaultHoliday: HolidayObject = {
  title: '',
  from: moment(minDate),
  to: moment(minDate),
  cities: [],
  applyToAllCities: true,
  openingTimes: []
}

const HolidayPicker = ({
  open,
  isMaster,
  selectedHoliday,
  onCancel,
  onConfirm,
  onDelete
}: HolidayPickerProps) => {
  const classes = useCommonStyles()
  const [holiday, setHoliday] = useState<HolidayObject>(
    selectedHoliday || defaultHoliday
  )

  const handleChange = (
    field: string,
    value: boolean | string | OpeningTime[] | Date
  ) => {
    setHoliday((prevHoliday) => {
      return {
        ...prevHoliday,
        [field]: value
      }
    })
  }

  const deleteButton =
    selectedHoliday && selectedHoliday.id && !selectedHoliday.global ? (
      <Button
        variant='outlined'
        color='primary'
        className={classes.absoluteLeftBottom}
        onClick={() => {
          onDelete(selectedHoliday!.id!)
        }}
        data-test='delete-holiday-btn'
      >
        DELETE HOLIDAY
      </Button>
    ) : null

  const returnHolidayPickerCities = () => {
    return (
      <HolidayPickerCities
        appliedCities={holiday.cities!}
        applyToAllCities={holiday.applyToAllCities!}
        onChange={handleChange}
      />
    )
  }

  const returnHolidayPickerServices = () => {
    const openingTimes = (holiday.openingTimes || [])
      .filter((openingTime) => !openingTime.closed)
      .sort((a, b) =>
        a.openingTime === b.openingTime
          ? 1
          : a.openingTime < b.openingTime
          ? -1
          : 1
      )

    return (
      <HolidayPickerServices
        serviceOpeningTimes={openingTimes}
        onChange={handleChange}
      />
    )
  }

  const dialogTitle =
    selectedHoliday && selectedHoliday.id
      ? `Edit holiday ${selectedHoliday.title} (${returnHolidayDateString(
          selectedHoliday.from,
          selectedHoliday.to
        )})`
      : 'Add a new holiday'
  const disabled = holiday && holiday.title === ''

  return (
    <DialogTemplate
      open={open}
      title={dialogTitle}
      onCancel={onCancel}
      onConfirm={() => onConfirm(holiday)}
      confirmLabel='SAVE'
      dataTest='save-holiday-btn'
      disabled={disabled}
      footerButtons={deleteButton}
      maxWidth='md'
    >
      <form className={classes.pickerFormWidth}>
        <FormRow>
          <RowDescription title='Holiday title' />
          <Grid item xs={9}>
            <TextField
              fullWidth
              label='Name'
              required
              onChange={(e: any) => {
                handleChange('title', e.target.value)
              }}
              value={holiday?.title || ''}
              data-test='holiday-name'
            />
          </Grid>
        </FormRow>
        <FormRow>
          <RowDescription title='Date' />
          <Grid item xs={9}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify='center' spacing={2}>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled={holiday.global}
                    fullWidth
                    minDate={minDate}
                    format='dd/MM/yyyy'
                    margin='none'
                    id='date-picker-start'
                    label='Start date'
                    value={holiday?.from}
                    onChange={(date: Date) => {
                      handleChange('from', date)
                      handleChange('to', date)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={holiday?.from}
                    disabled={holiday.global}
                    fullWidth
                    format='dd/MM/yyyy'
                    margin='none'
                    id='date-picker-end'
                    label='End date'
                    value={holiday?.to}
                    onChange={(date: Date) => {
                      handleChange('to', date)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </FormRow>
        {isMaster ? returnHolidayPickerCities() : returnHolidayPickerServices()}
      </form>
    </DialogTemplate>
  )
}

export default HolidayPicker
