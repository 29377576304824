import styled from 'styled-components'
import {File} from '@fcg/image-viewer'
import {ThumbnailList} from './ThumbnailList'
import {
  fileAlternativeRenderer,
  getAlternativeRenderer,
  calculateFileWrapper
} from './utils'

const DocumentSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const GalleryImageContainer = styled.div`
  display: flex;
  margin: 10px 20px 0 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-height: 180px;
  overflow: scroll;
  padding-top: 10px;
`
const SelectedImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  position: relative;
`

export const DocumentSelector = ({
  images,
  selected,
  openGallery,
  selectImage,
  showSelectedImageInList,
  customStyles = {},
  useAdornement,
  availableAdornements,
  showMetaInfo = false,
  metaInfoInputs
}) => {
  if (images.length === 1) {
    const image = images[0]
    const wrapper = calculateFileWrapper({
      useAdornement,
      availableAdornements,
      image,
      adornementSize: 'single',
      showMetaInfo
    })
    const alternativeRenderer = fileAlternativeRenderer[image.fileType]

    return (
      <File
        {...image}
        alternativeRenderer={alternativeRenderer}
        wrapper={wrapper}
        onClick={openGallery}
        metaInfoInputs={metaInfoInputs}
      />
    )
  }

  const selectedImage = images.find((image, index) => index === selected)
  const selectedImageAlternativeRenderer = getAlternativeRenderer(selectedImage)
  const selectedImageWrapper = calculateFileWrapper({
    useAdornement,
    availableAdornements,
    image: selectedImage,
    showMetaInfo
  })

  return (
    <DocumentSelectorContainer>
      <SelectedImageContainer>
        <File
          {...selectedImage}
          wrapper={selectedImageWrapper}
          alternativeRenderer={selectedImageAlternativeRenderer}
          onClick={openGallery}
          metaInfoInputs={metaInfoInputs}
        />
      </SelectedImageContainer>
      <GalleryImageContainer>
        <ThumbnailList
          useAdornement={useAdornement}
          availableAdornements={availableAdornements}
          images={images}
          selected={selected}
          selectImage={selectImage}
          showSelectedImageInList={showSelectedImageInList}
          customStyles={customStyles.thumbnailList}
        />
      </GalleryImageContainer>
    </DocumentSelectorContainer>
  )
}
