import * as types from './types'
import * as AuthAPI from '../../api/auth/requests'
import {handleAPIErrors, formatParams} from '../utils'
import omit from 'lodash/omit'
import {getHistory} from '../../utils/history'
import * as globalActions from '../global/actions'
import {getCountryCode} from '../config/selectors'
import {getCountryConfig} from '../../../config/country'

import {isPathEnabled} from '../../utils/renderingHelpers'

export const getList = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching())
  const currentState = getState().userReducer

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page
  const filterParams = formatParams(filters)
  const params = {limit, ...filterParams, sort, page}

  try {
    const response = await AuthAPI.getDealerList(params)
    const data = response.user

    dispatch(setDataLoaded({data, page, filters, sort, limit}))
  } catch (err) {
    dispatch(setFetching(false))
    dispatch(globalActions.apiError(err))
  }
}

export const saveDealer = (dealer) => {
  return async (dispatch, getState) => {
    try {
      let response = null
      const metaInfo = {
        ...dealer.metaInfo,
        firstName: dealer.firstname,
        lastName: dealer.lastname
      }

      delete metaInfo.companyType

      if (dealer.id !== null && typeof dealer.id !== 'undefined') {
        response = await AuthAPI.updateDealer({
          ...omit(dealer, ['password', 'confirmPassword']),
          metaInfo
        })
        const {email, password: newPassword} = dealer

        if (
          newPassword !== '' &&
          typeof newPassword !== 'undefined' &&
          newPassword !== null
        ) {
          await AuthAPI.changePassword({email, newPassword})
        }
      } else {
        const country = getCountryCode(getState()).toLowerCase()

        response = await AuthAPI.createDealer({
          ...omit(dealer, ['id', 'confirmPassword']),
          country,
          metaInfo
        })
      }

      const userId =
        response && response.createUser && response.createUser.id
          ? response.createUser.id
          : dealer.id

      dispatch(setDealerUpdateSuccess(response))
      getHistory().replace(`/dealers/${userId}`)
    } catch (err) {
      dispatch(setFetching(false))
      dispatch(globalActions.apiError(err))
    }
  }
}

export const setDealerUpdateSuccess = (data) => {
  return {
    type: types.dealerUpdateSuccess,
    payload: data
  }
}

export const fetchDealerProfile = (id) => ({
  type: types.fetchDealerProfile,
  payload: id
})

export const fetchDealerProfileSuccess = () => ({
  type: types.fetchDealerProfileSuccess
})

export const fetchDealerProfileError = () => ({
  type: types.fetchDealerProfileError
})

export const setDataLoaded = ({data = {}, page, filters, sort, limit}) => {
  return {
    type: types.dataLoaded,
    payload: {data, page, filters, sort, limit}
  }
}

export const setDealer = (data = {}) => {
  return {
    type: types.dealerSet,
    payload: data
  }
}

export const setDealerManagerName = (data = {}) => {
  return {
    type: types.dealerManagerNameSet,
    payload: data
  }
}

export const setOnboardingAgentName = (data = {}) => {
  return {
    type: types.onboardingAgentNameSet,
    payload: data
  }
}

export const setDealerFarmerName = (data = {}) => ({
  type: types.dealerFarmerNameSet,
  payload: data
})

export const setDealerHunterName = (data = {}) => ({
  type: types.dealerHunterNameSet,
  payload: data
})

export const setDealerBidManagerName = (data = {}) => ({
  type: types.dealerBidManagerNameSet,
  payload: data
})

export const setError = (error = '') => {
  return {
    type: types.error,
    payload: error
  }
}

export const setFetching = (fetching = true) => {
  return {
    type: types.fetching,
    payload: fetching
  }
}

export const toggleField = (label) => {
  return {
    type: types.toggleField,
    payload: label
  }
}

export const toggleSort = (sort) => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(getList())
}

export const updateFilters = (filter) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filter
  })
  dispatch(getList())
}

export const resetDealer = () => ({
  type: types.resetDealer
})

export const deleteDealer = (email) => async (dispatch, getState) => {
  const currentState = getState().userReducer

  dispatch(setFetching())
  try {
    await AuthAPI.deleteUser({email})
    dispatch(getList(currentState))
  } catch (e) {
    const error = handleAPIErrors(e, true)

    dispatch(globalActions.apiError(error))
  } finally {
    dispatch(setFetching(false))
  }
}

export const resetDealerProfileData = () => ({
  type: types.resetDealerProfileData
})

export const updateVisitedPage = (payload) => ({
  type: types.updateVisitedPage,
  payload
})

export const updateDealer = (payload) => ({
  type: types.updateDealer,
  payload
})

export const setDealerLoading = (payload) => ({
  type: types.setDealerLoading,
  payload
})

export const loadDealer = (payload) => ({
  type: types.loadDealer,
  payload
})

export const setPathMode = ({path}) => (dispatch) => {
  const countryConfig = getCountryConfig()

  dispatch({
    type: types.setPathReadOnly,
    payload: !isPathEnabled({
      countryConfig,
      path,
      cookies: document.cookie,
      env: window.environment
    })
  })
}
