import {PureComponent} from 'react'

import {ArrowDropDown, Clear} from '@material-ui/icons'
import FilterMenuHeading from './FilterMenuHeading'
import FilterButton from './FilterButton'
import FilterMenuItem from './FilterMenuItem'
import FilterDropdown from './FilterDropdown'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'

export class QuickFilters extends PureComponent {
  state = {isOpen: false}
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }
  close = () => {
    this.setState({isOpen: false})
  }
  handleItemClick = (item) => {
    this.props.activate(item)
    this.close()
  }
  handleSavedFilterRemove = (e, item) => {
    e.stopPropagation()
    this.props.onSavedFilterRemove(item)
  }
  render() {
    const {t, presets, saved, active} = this.props
    const menuTitle = this.props.shortTitle
      ? t('filters.predefinedFilters.select')
      : t('filters.predefinedFilters.selectShort')

    return (
      <div
        ref={(el) => {
          this.container = el
        }}
      >
        <FilterDropdown
          Anchor={(props) => (
            <FilterButton
              onClick={this.toggle}
              {...props}
              iconRight
              data-test='predefined-filters'
            >
              {menuTitle}
              <ArrowDropDown />
            </FilterButton>
          )}
          anchorEl={this.container}
          isOpen={this.state.isOpen}
          close={this.close}
          toggle={this.toggle}
          reference='quickFilters'
        >
          <div>
            <FilterMenuHeading
              content={t('filters.predefinedFilters.presets.title')}
            />
            {presets && presets.length > 0 ? (
              presets.map((preset, index) => (
                <FilterMenuItem
                  key={`${preset.id}-${index}`}
                  label={t(preset.name)}
                  selected={active === preset.id}
                  onClick={() => this.handleItemClick(preset)}
                />
              ))
            ) : (
              <FilterMenuItem label={t('global.message.noOptions')} disabled />
            )}
          </div>
          <div>
            <FilterMenuHeading
              content={t('filters.predefinedFilters.saved.title')}
            />
            {saved && saved.length > 0 ? (
              saved.map((filter, index) => (
                <FilterMenuItem
                  key={`${filter.id}-${index}`}
                  label={filter.name}
                  selected={active === filter.id}
                  onClick={() => this.handleItemClick(filter)}
                  Icon={(prop) => {
                    const props = omit(prop, 't')

                    return (
                      <Clear
                        {...props}
                        onClick={(e) => this.handleSavedFilterRemove(e, filter)}
                      />
                    )
                  }}
                  data-test={`saved-filters-${filter.id}`}
                />
              ))
            ) : (
              <FilterMenuItem
                label={t('global.message.noOptions')}
                disabled
                data-test='no-options-available'
              />
            )}
          </div>
        </FilterDropdown>
      </div>
    )
  }
}

export default translate()(QuickFilters)
