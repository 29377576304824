module.exports = {
  dynamic: {
    companyName: {
      required: ({data}) =>
        data.businessCategory === 'dealer' ||
        data.businessCategory === 'oemDealership'
    }
  },
  required: [
    'city',
    'dealerManager',
    'onboardingAgent',
    'dealerSize',
    'email',
    'firstname',
    'lastname',
    'metaInfoEmail',
    'nationality',
    'phone',
    'status',
    'onboardingDate',
    'businessCategory',
    'onboardingStatus',
    'blockedReason',
    'inactiveReason',
    'rejectedReason'
  ],
  hidden: [
    'averageStock',
    'businessType',
    'businessTypeSelect',
    'carPreferences',
    'competitors',
    'dealerLocations',
    'dealersPublicDeedIncorporationDate',
    'dealersPublicDeedNotary',
    'dealersPublicDeedNotaryCity',
    'dealerTaxOfficeCity',
    'dealerTaxOfficeCommune',
    'district',
    'guarantees',
    'idealStock',
    'internalId',
    'keyContacts',
    'legalCompanyName',
    'legallyRegistered',
    'legalRepresentativeId',
    'legalRepresentativeLastName',
    'legalRepresentativeName',
    'legalRepresentativeNationality',
    'legalRepresentativeProfesion',
    'metaInfoInternalId',
    'personType',
    'registrationId',
    'street',
    'taxOffice',
    'zipCode',
    'legalRepresentativePhone',
    'legalRepresentativeEmail',
    'legalAdress',
    'leadSource',
    'employeeCount',
    'carPreferencesPrice',
    'monthlyTurnover',
    'estimatedMargin',
    'dealerAppsChannels',
    'bankAccountNumber',
    'bankAccountOwner',
    'onboardingDecisionDate',
    'onboardingRejectionReason',
    'dealerFarmer',
    'dealerHunter',
    'dealerBidManager',
    'depositBalance',
    'numberPurchasableCar',
    'cityId',
    'stateId',
    'isNewsLetterAccepted',
    'deposit',
    'companyTypeId',
    'regon',
    'postCode'
  ]
}
