import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'unset'};
  align-items: ${(props) => props.alignItems || 'unset'};
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: ${(props) => props.wrap || 'unset'};
  width: ${(props) => props.width || 'unset'};
  height: ${(props) => props.height || 'unset'};
  padding: ${(props) => props.padding || 'unset'};
  margin: ${(props) => props.margin || 'unset'};
`

export default Flex
