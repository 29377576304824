import styled from 'styled-components'
import {translate} from 'react-i18next'

const colorMap = {
  OPEN: {
    background: '#ffeabf',
    text: '#e67c00'
  },
  IN_PROGRESS: {
    background: '#ffeabf',
    text: '#e67c00'
  },
  APPROVED: {
    background: '#e7ffda',
    text: '#74994f'
  },
  REJECTED: {
    background: '#ffe4e7',
    text: '#c81f34'
  },
  DONE: {
    background: '#e7ffda',
    text: '#74994f'
  }
}

const Container = styled.div`
  align-items: 'center';
  justify-content: ${(props) => props.align || 'center'};
  display: flex;
`

const Pill = styled.div`
  display: flex;
  justify-content: center;
  min-width: 56px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) =>
    colorMap[props.value]
      ? colorMap[props.value].background
      : colorMap.OPEN.background};
  text-align: center;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    colorMap[props.value] ? colorMap[props.value].text : colorMap.OPEN.text};
`

const TicketStatusPill = ({t, ...props}) => {
  const label = `${props.field.labelRoot ? `${props.field.labelRoot}.` : ''}${
    props.data
  }`

  return (
    <Container {...props}>
      <Pill value={props.data}>{t(label)}</Pill>
    </Container>
  )
}

export default translate()(TicketStatusPill)
