/* eslint-disable no-console */
import isEqual from 'lodash/isEqual'

const retrieveByName = async (driver, id, {name}) => {
  try {
    const item = await driver.getItem(`${id}_${name}`)

    return item === null ? null : JSON.parse(item)
  } catch (e) {
    console.error(e)
  }
}

const saveState = (driver) => (id) => async ({name, value}) => {
  try {
    await driver.setItem(`${id}_${name}`, JSON.stringify(value))
  } catch (e) {
    console.error(e)
  }
}

const loadState = (driver) => (id) => (params) =>
  Array.isArray(params)
    ? Promise.all(params.map((param) => retrieveByName(driver, id, param)))
    : retrieveByName(driver, id, params)

const updateState = (driver) => (id, load, set) => {
  const loadState = load(driver)(id)
  const setState = set(driver)(id)

  return async ({name, value}) => {
    try {
      const item = await loadState({name})
      let newValue

      if (item !== null) {
        const index = item.findIndex((stored) => isEqual(stored, value))

        newValue = index === -1 ? (newValue = [...item, value]) : item
      } else {
        newValue = [value]
      }

      await setState({name, value: newValue})
    } catch (e) {
      console.error(e)
    }
  }
}

const removeState = (driver) => (id) => async ({name}) => {
  try {
    await driver.removeItem(`${id}_${name}`)
  } catch (e) {
    console.error(e)
  }
}

export const store = (driver) => {
  return function(id) {
    return {
      save: (value) => saveState(driver)(id)(value),
      remove: (value) => removeState(driver)(id)(value),
      update: (value) => updateState(driver)(id, loadState, saveState)(value),
      retrieve: (value) => loadState(driver)(id)(value)
    }
  }
}

export default store(window.localStorage)
