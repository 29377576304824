import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../../utils/typesUtil'

export const resourceName = 'payslipList'

export const fetchPayslips = `${resourceName}/FETCH_PAYSLIPS`
export const fetchPayslipsStart = `${resourceName}/FETCH_PAYSLIPS_START`
export const fetchPayslipsSuccess = `${resourceName}/FETCH_PAYSLIPS_SUCCESS`
export const fetchPayslipsError = `${resourceName}/FETCH_PAYSLIPS_ERROR`
export const fetchCurrentPage = `${resourceName}/FETCH_CURRENT_PAGE`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`

export const addCarPaymentSelection = `${resourceName}/ADD_CAR_PAYMENT_SELECTION`
export const updateTicketEntity = `${resourceName}/UPDATE_TICKET_ENTITY`
export const updateTicketEntitySuccess = `${resourceName}/UPDATE_TICKET_ENTITY_SUCCESS`
export const updateTicketEntityFailure = `${resourceName}/UPDATE_TICKET_ENTITY_ERROR`

export const updateTicketStart = `${resourceName}/UPDATE_TICKET_START`
export const updateTicket = `${resourceName}/UPDATE_TICKET`
export const updateTicketSuccess = `${resourceName}/UPDATE_TICKET_SUCCESS`
export const updateTicketError = `${resourceName}/UPDATE_TICKET_ERROR`
export const approveTicket = `${resourceName}/APPROVE_TICKET`

export const fetchPayslipOptions = `${resourceName}/FETCH_PAYSLIP_OPTIONS`
export const setPayslipOptions = `${resourceName}/SET_PAYSLIP_OPTIONS`
export const rejectPayslip = `${resourceName}/REJECT_PAYSLIP`
export const selectPayslip = `${resourceName}/SELECT_PAYSLIP`

export const toggleRow = `${resourceName}/TOGGLE_ROW`
export const toggleRowSelection = `${resourceName}/TOGGLE_ROW_SELECTION`
export const fetchRowData = `${resourceName}/ROW_DATA_FETCH`
export const fetchRowDataSuccess = `${resourceName}/ROW_DATA_FETCH_SUCCESS`
export const fetchRowDataError = `${resourceName}/ROW_DATA_FETCH_ERROR`

export const formTypes = getFormTypes(resourceName)
export const sortTypes = getSortTypes(resourceName)
export const columnTypes = getColumnTypes(resourceName)
export const dataLoadingTypes = getDataLoadingTypes(resourceName)

export const metaInfoChange = `${resourceName}/METAINFO_CHANGE`
export const persistMetaInfoChange = `${resourceName}/PERSIS_METAINFO_CHANGE`
