/* eslint-disable no-console */
import * as AuthApi from '../../api/auth/requests'
import * as types from './types'
import differenceBy from 'lodash/differenceBy'
import {NECCESSARY_PERMISSIONS} from './permissionsConfig'
import * as global from '../global/actions'
import {getCountryCode} from '../config/selectors'

const deletingRole = (payload) => ({
  type: types.groups_deleting,
  payload
})
const loadingRoles = (payload) => ({
  type: types.groups_fetching,
  payload
})

const laodingUsers = (payload) => ({
  type: types.users_fetching,
  payload
})

const userAdding = (payload) => ({
  type: types.users_adding,
  payload
})

const laodingAllPermissions = (payload) => ({
  type: types.permissions_fetching,
  payload
})

const laodingGroupPermissions = (payload) => ({
  type: types.rolePermissions_fetching,
  payload
})

export const resetRolePermissions = () => ({
  type: types.rolePermissions_reset
})

export const setSelectedGroup = (payload) => ({
  type: types.groups_setSelected,
  payload
})

export const resetSelectedGroup = () => ({
  type: types.groups_resetSelected
})

export const loadAllRoles = () => async (dispatch, getState) => {
  dispatch(loadingRoles(true))

  try {
    const {data: payload} = await AuthApi.allRoles({
      country: getCountryCode(getState()),
      roleType: 'CRM'
    })

    dispatch({
      type: types.groups_loaded,
      payload: payload.data.role.list
    })
  } catch (e) {
    console.warn(e)
    dispatch(loadingRoles(false))
  }
}

export const loadGroupUsers = (id) => async (dispatch, getState) => {
  dispatch(laodingUsers(true))

  try {
    const {data: payload} = await AuthApi.roleUsers({
      country: getCountryCode(getState()),
      roleType: 'CRM',
      id: [id]
    })

    dispatch({
      type: types.users_loaded,
      payload: payload.data.role.list.length
        ? payload.data.role.list[0].users
        : []
    })
  } catch (e) {
    console.warn(e)
    dispatch(laodingUsers(false))
  }
}

export const addUserToRole = (roleId, userId) => async (dispatch) => {
  dispatch(userAdding({flag: 'requested', userId}))
  try {
    await AuthApi.addUserToRole({roleId, userIds: [userId]})
    dispatch(userAdding({flag:'resolved', userId}))
    dispatch(loadGroupUsers(roleId))
  } catch (e) {
    console.warn(e)
    dispatch(userAdding({flag:'error', userId}))
  }
}

export const removeUserFromRole = (roleId, userId) => async (dispatch) => {
  try {
    await AuthApi.removeUsersFromRole({roleId, userIds: [userId]})
    dispatch(loadGroupUsers(roleId))
  } catch (e) {
    console.warn(e)
  }
}

export const getAllPermissions = () => async (dispatch, getState) => {
  dispatch(laodingAllPermissions(true))

  try {
    const {data: paylaod} = await AuthApi.allPermissions({
      country: getCountryCode(getState()),
      label: [
        'appointments',
        'search',
        'leads',
        'walkin',
        'contacts',
        'crm',
        'tasks',
        'cancelAppointment',
        'dialer'
      ]
    })

    dispatch({
      type: types.permissions_loaded,
      payload: paylaod.data.permission.list
    })
  } catch (e) {
    dispatch(laodingAllPermissions(false))
    console.warn(e)
  }
}

export const getRolePermissions = (id) => async (dispatch, getState) => {
  dispatch(laodingGroupPermissions(true))

  try {
    const {data: paylaod} = await AuthApi.rolePermissions({
      id,
      country: getCountryCode(getState()),
      roleType: 'CRM'
    })

    dispatch({
      type: types.rolePermissions_loaded,
      payload: {
        list: paylaod.data.role.list[0].permissions,
        initialLoad: true
      }
    })
  } catch (e) {
    console.warn(e)
    dispatch(laodingGroupPermissions(false))
  }
}

export const onChange = (entity, type) => async (dispatch, getState) => {
  const {
    crmUserGroups: {rolePermissions, permissions}
  } = getState()
  const cleanPermissions = type
    ? rolePermissions.filter(
        (item) => !(item.entity === entity && item.type === type)
      )
    : rolePermissions.filter((item) => item.entity !== entity)
  const hasPermission = rolePermissions.find(
    (item) => item.entity === entity && item.type === type
  )
  let newPermissions = []

  if (!hasPermission && type) {
    newPermissions = permissions.filter(
      (item) =>
        item.entity === entity &&
        NECCESSARY_PERMISSIONS[type].indexOf(item.type) > -1
    )
  }

  dispatch({
    type: types.rolePermissions_loaded,
    payload: {list: [...cleanPermissions, ...newPermissions]}
  })
}

export const save = (roleId, onComplete) => async (dispatch, getState) => {
  try {
    dispatch(laodingGroupPermissions(true))
    const {
      crmUserGroups: {rolePermissions, rolePermissionsOriginal}
    } = getState()
    const requests = []

    const toAdd = differenceBy(rolePermissions, rolePermissionsOriginal, 'id')
    const toDelete = differenceBy(
      rolePermissionsOriginal,
      rolePermissions,
      'id'
    )

    if (toDelete.length > 0) {
      requests.push(
        AuthApi.removePermissionsFromRole({
          roleId,
          permissionIds: toDelete.map((item) => item.id)
        })
      )
    }

    if (toAdd.length > 0) {
      requests.push(
        AuthApi.addPermissionsToRole({
          roleId,
          permissionIds: toAdd.map((item) => item.id)
        })
      )
    }

    await Promise.all(requests)
    dispatch(laodingGroupPermissions(false))
    dispatch(resetRolePermissions())

    if (typeof onComplete === 'function') {
      onComplete()
    }
  } catch (e) {
    dispatch(global.apiError(e))
  }
}

export const create = (name, onComplete) => async (dispatch, getState) => {
  try {
    dispatch(laodingGroupPermissions(true))
    const {
      crmUserGroups: {rolePermissions},
      config
    } = getState()

    const {data} = await AuthApi.createRole({
      name,
      permissionIds: rolePermissions.map((item) => item.id),
      country: config.countryCode,
      roleType: 'CRM'
    })

    dispatch({
      type: types.groups_addNew,
      payload: data.data.createRole
    })
    dispatch(laodingGroupPermissions(false))
    dispatch(resetRolePermissions())

    if (typeof onComplete === 'function') {
      onComplete(data.data.createRole.id)
    }
  } catch (e) {
    dispatch(global.apiError(e))
  }
}

export const deleteUserGroup = (id) => async (dispatch) => {
  dispatch(deletingRole(true))

  try {
    await AuthApi.deleteRole({
      roleId: id
    })

    dispatch({
      type: types.groups_delete_success
    })
    dispatch(loadAllRoles())
  } catch (e) {
    console.warn(e)
    dispatch({
      type: types.groups_delete_failure
    })
  }
}
