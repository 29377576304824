export const hasTableData = (props) => props.data && props.data.selfInspection
export const hasInspectionImages = (props) => props.data && props.data.documents
export const isSelfInspectionType = (inspectionType) =>
  inspectionType === 'SELFINSPECTION'
export const isSelfInspection = (props) =>
  props.data &&
  props.data.inspectionType &&
  isSelfInspectionType(props.data.inspectionType)
export const hasSelfInspectionData = (props) =>
  isSelfInspection(props) && hasTableData(props) && hasInspectionImages(props)
