import Select from '../../../components/Select/Select'
import {status} from '../../../store/driver/initialState'

const DriverStatusFilter = (props) => {
  return (
    <Select
      {...props}
      withTranslate
      id={props.fieldName}
      label={props.label}
      options={status}
      selected={props.value}
      onChange={props.handleChange(props.fieldName)}
    />
  )
}

export default DriverStatusFilter
