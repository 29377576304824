export const webhookFields = {
  name: {
    key: 'name',
    label: 'webhook.column.name',
    type: 'string',
    isFilterable: false
  },
  description: {
    key: 'description',
    label: 'webhook.column.description',
    type: 'string',
    isFilterable: false
  },
  events: {
    key: 'events',
    label: 'webhook.column.events',
    type: 'array',
    isFilterable: false,
    isSortable: false
  },
  createdAt: {
    key: 'createdAt',
    label: 'webhook.column.createdAt',
    type: 'date',
    time: true,
    isSortable: true
  },
  createdByName: {
    key: 'createdByName',
    label: 'webhook.column.createdByName',
    type: 'string'
  }
}
