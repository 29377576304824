import {PureComponent} from 'react'
import {translate} from 'react-i18next'

import {MenuItem, ListItemText, IconButton} from '@material-ui/core'
import {ArrowBack, ArrowForward} from '@material-ui/icons'

export class SelectPagination extends PureComponent {
  render() {
    const {
      t,
      page,
      total,
      hasPrevPage,
      hasNextPage,
      fetching,
      params
    } = this.props

    return (
      <MenuItem disabled={fetching}>
        {hasPrevPage && (
          <IconButton
            disabled={fetching}
            title={t('global.action.prev')}
            onClick={() => this.props.requestPrevPage(params)}
          >
            <ArrowBack />
          </IconButton>
        )}
        <ListItemText inset primary={`${page} / ${total}`} />
        {hasNextPage && (
          <IconButton
            disabled={fetching}
            title={t('global.action.next')}
            onClick={() => this.props.requestNextPage(params)}
          >
            <ArrowForward />
          </IconButton>
        )}
      </MenuItem>
    )
  }
}

export default translate()(SelectPagination)
