export const driverSchema = {
  firstname: null,
  email: '',
  metaInfo: {
    email: ''
  },
  loaded: false,
  driver: {}
}

export const status = ['active', 'blocked'].map((status) => ({
  value: status,
  label: `driver.page.field.status.${status}`
}))

const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  initialData: {...driverSchema},
  driverList: [],
  driverStatusOptions: {
    status
  },
  count: null,
  list: {
    page: 0,
    limit: 25,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: []
  },
  uploadError: null,
  driver: {
    loaded: false,
    firstname: '',
    lastname: '',
    email: '',
    driver: {},
    metaInfo: {}
  },
  editState: {error: false, success: false, pending: false},
  driverOptions: []
}

export const getInitialState = (config) => ({
  options: {},
  form: {...driverSchema},
  ...state,
  visitedPage: [],
  pageSetup: config.formConfig,
  list: {
    ...state.list,
    ...config,
    fields: Object.values(config.fields)
  }
})
