import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {StoreObject} from '../../types'

interface SelectProps {
  title: string,
  isLoading: boolean
  required?: boolean
  options: Array<StoreObject>
  onChange: (value: Array<StoreObject>) => void,
  maxSelectionCount?: number
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: StoreObject) => option.locationDescription || '',
});

export default function SelectField({
  title,
  isLoading=true,
  options = [],
  onChange,
  maxSelectionCount= 5
}: SelectProps) {
  const [selected, setSelected] = useState<Array<StoreObject>>([])

  const handleOnchange = (event:ChangeEvent<{}> ,values: Array<StoreObject>) => {
      setSelected([...values])
  }

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const labelId = `${title}-select-label`

  return (
    <Autocomplete
      multiple
      disablePortal
      id={labelId}
      options={options}
      onChange={handleOnchange}
      filterOptions={filterOptions}
      disabled={isLoading}
      getOptionDisabled= {()=> selected.length >= maxSelectionCount}
      getOptionLabel={(option) => option.locationDescription || ''}
      renderInput={(params) => <TextField
          {...params}
          label={isLoading ? 'Loading, please wait...' : title}
          // variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />}
    />
  );
}
