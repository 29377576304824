import {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import styled from 'styled-components'
import {AppBar, withStyles} from '@material-ui/core'
import translate from 'react-i18next/dist/commonjs/translate'
import {checkUserInfo, setCrmUserStatus} from '../../store/auth/actions'
import MainMenu from './MainMenu/MainMenu'
import LanguagePicker from '../Pickers/LanguagePicker'
import CheckinToggle from './CheckInToggle'
import {toggleMenu} from '../../store/global/actions'
import {setCookie} from '../../utils/authToken'
import {RenderIf} from '../RenderIf'
import {AppointmentLegendHeaderIcon} from '../../pages/Calendar/components/Legend/AppointmentLegendHeaderIcon'
import {toggleVisibility} from '../../store/calendar/actions'
import OlxLogo from '../../assets/images/icons/olxAutosLogo.svg'
import {Icon, Button} from 'motofin-ui'
import UserInfo from './Info/UserInfo'
import {Notifications} from '../../pages/ModulePages/Notifications'

const HeaderActions = styled.div`
  min-width: 100px;
  align-items: center;
  width: auto !important;
`

const AppBarContainer = styled(AppBar)`
  background-color: ${(props) => props.bgColor} !important;
  color: ${(props) => props.color};
`

const colors = {
  default: {
    bgColor: '#fff',
    color: 'black'
  },
  checkedOut: {
    bgColor: '#C5CBE9',
    color: 'black'
  }
}

export class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleVisibilityLegend = this.props.toggleVisibility('legend')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {country} = this.props

    if (country.language.selected !== nextProps.country.language.selected) {
      setCookie('locale', nextProps.country.language.selected, 365)
    }
  }

  onCheckinToggleChange = (e) => {
    this.props.setCrmUserStatus(e.target.checked)
  }

  getRolePermission = () => {
    const {
      roles,
      country: {countryCode}
    } = this.props

    if (Array.isArray(roles)) {
      const hasCRMPermission = roles.filter(
        (role) =>
          role.includes(`crm.${countryCode.toLowerCase()}`) ||
          role.includes('superadmin')
      )

      return Boolean(hasCRMPermission.length)
    }
  }

  render() {
    const isCrmPage = this.props.location.pathname.includes('crm')
    const HeaderColorMapping =
      !this.props.isCheckedIn && isCrmPage ? colors.checkedOut : colors.default

    if (this.props.location.pathname.includes('login')) {
      return null
    }

    return (
      <AppBarContainer
        bgColor={HeaderColorMapping.bgColor}
        color={HeaderColorMapping.color}
        position='static'
      >
        <div
          style={{
            display: 'flex',
            padding: '10px 20px',
            height: '60px',
            zIndex: '1',
            boxShadow: '0px 3px 6px rgb(95 70 95 / 12%)'
          }}
        >
          <Button
            type='tertiary'
            disabled={this.props.isAuthorized === false}
            style={{border: 'none', marginRight: '6px', marginTop: '-4px'}}
            testId='main-menu-button'
            onClick={this.props.toggleMenu}
          >
            {this.props.isAuthorized !== false ? (
              <Icon icon='hamburger' size={24} viewBox='0 0 20 20' />
            ) : null}
          </Button>
          <OlxLogo color='#002F34' fontSize={14} height={40} width={100} />

          <MainMenu
            key='header-toolbar-main-menu'
            open={this.props.isMenuOpen}
            handleClose={this.props.toggleMenu}
            header
          />

          <HeaderActions className={this.props.classes.picker}>
            {isCrmPage && (
              <CheckinToggle
                handleChange={this.onCheckinToggleChange}
                value={this.props.isCheckedIn}
                color={HeaderColorMapping.color}
                isCheckedIn={this.props.isCheckedIn}
              />
            )}

            <LanguagePicker
              key='header-toolbar-language-picker'
              className={this.props.classes.picker}
            />

            {this.props.country.isNotificationsEnabled && <Notifications />}

            <UserInfo handleClose={this.props.toggleMenu} />
            <RenderIf if={this.props.isLegendIconShown}>
              <AppointmentLegendHeaderIcon
                key='header-toolbar-appointment-legend-icon'
                className={this.props.classes.picker}
                t={this.props.t}
                onClick={this.toggleVisibilityLegend}
              />
            </RenderIf>
          </HeaderActions>
        </div>
      </AppBarContainer>
    )
  }
}

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  picker: {
    width: '45px',
    position: 'relative',
    color: '#fff',
    display: 'inline-flex',
    marginLeft: 'auto'
  }
})

const mapStateToProps = ({
  global,
  config,
  authReducer,
  appointmentCalendar
}) => ({
  appTitle: global.appTitle,
  isMenuOpen: global.isMenuOpen,
  country: config,
  isAuthorized: authReducer.isAuthenticated,
  isCheckedIn: authReducer.isCheckedIn,
  roles: authReducer.userInfo.groups,
  isLegendIconShown: appointmentCalendar.visible.legendIcon
})

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => dispatch(toggleMenu()),
  toggleVisibility: (feature) => () => dispatch(toggleVisibility(feature)),
  checkUserInfo: () => dispatch(checkUserInfo()),
  setCrmUserStatus: (status) => dispatch(setCrmUserStatus(status))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  withStyles(styles, {name: 'AppHeader'})
)(Header)
