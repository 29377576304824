import {getCountryConfig} from '../../config/country'

const country = getCountryConfig()

export const trackPage = () => {
  window.analytics.page('', '', {country: country.countryCode})
}

export const identifyUser = (user) => {
  const {id, roles, groups} = user

  window.analytics.identify(id, {roles, groups})
}
