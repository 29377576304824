const contactFields = ['firstname', 'lastname', 'phone']

const appointmentDependencies = [
  'type',
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'faceLift',
  'seat',
  'engine',
  'wheelDrive',
  'bodyType',
  'transmission',
  'mileage'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]
const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  },
  {
    field: 'VINnumber',
    dependencies: []
  }
]
const carDataPicker = {
  dependencies: {
    make: [],
    model: ['make'],
    year: ['make', 'model'],
    trim: ['make', 'model', 'year'],
    bodyType: ['make', 'model', 'year', 'trim'],
    faceLift: ['make', 'model', 'year', 'trim', 'bodyType'],
    seat: ['make', 'model', 'year', 'trim', 'bodyType', 'faceLift'],
    engine: ['make', 'model', 'year', 'trim', 'bodyType', 'faceLift', 'seat'],
    wheelDrive: [
      'make',
      'model',
      'year',
      'trim',
      'bodyType',
      'faceLift',
      'seat',
      'engine'
    ],
    transmission: [
      'make',
      'model',
      'year',
      'trim',
      'bodyType',
      'faceLift',
      'seat',
      'engine',
      'wheelDrive'
    ],
    // location: ['make', 'model', 'year', 'trim', 'transmission'],
    mileage: [
      'make',
      'model',
      'year',
      'trim'
      // 'transmission',
      // 'bodyType',
      // 'location'
    ]
  }
}

const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

const requiredFields = ['make', 'model', 'year', 'trim']

const purchaseCoordinatorInput = {
  key: 'group',
  queryValue: {include: ['CMS', 'INSPECTOR']}
}

module.exports = {
  required: [
    {
      field: 'type',
      dependencies: appointmentDependencies
    },
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation,
    ...requiredFields
  ],
  hidden: [
    'minPrice',
    'maxPrice',
    'externalLeadId',
    'city',
    'postcode',
    'lead.location',
    'VINnumber',
    'selfInspectionPrice',
    'purchaseCoordinator',
    'purchaseCoordinatorPhone'
  ],
  landingPage: ['tasks', 'leads'],
  hiddenColumns: [
    'plate',
    'postcode',
    'city',
    'lead.status',
    'leadResponsibleName',
    'updated',
    'taxId',
    'VINnumber',
    'selfInspectionExists',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'leadCity',
    'sourcePathVariant',
    'carInfoExtraFieldsAppointment'
  ],
  hiddenFilters: [
    'tierAppointmentStatus',
    'tierLocation',
    'tierMake',
    'postcode',
    'city',
    'taxId',
    'tierLeadSource',
    'tierLeadStatus',
    'appointmentAddress',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'sourcePathVariant',
    'carInfoExtraFieldsAppointment'
  ],
  hiddenFeatures: [],
  disabledFields: {walkin: {leadForm: ['price']}},
  carDataPicker,
  purchaseCoordinatorInput
}
