export const listMetaFields = ['status', 'companyName', 'email', 'phone']

export const driversFields = [
  'id',
  'firstname',
  'lastname',
  'email',
  'createdAt',
  'updatedAt',
  'internalId',
  ['driver', ['isAvailable', 'isCarDriver', 'isTruckDriver', 'documentId']],
  ['metaInfo', [...listMetaFields]]
]

export const driversFormFields = ['id', 'name']

export const driverList = ['count', ['list', driversFields]]
