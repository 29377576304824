import {Button, Dialog, DialogContent, DialogActions} from '@material-ui/core'
import {translate} from 'react-i18next'
import styled from 'styled-components'

const NotificationDialogStyled = styled(Dialog)`
  padding: 10px;
`

export const NotificationDialog = (props) => {
  const {t, open} = props

  return (
    <NotificationDialogStyled open={open} onClose={props.handleClose}>
      <DialogContent>{t('bulkupload.notification.title')}</DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color='primary'
          data-test='cars-with-errors-got-it-cancel'
        >
          {t('button.cancel')}
        </Button>
        <Button
          onClick={props.handleSubmit}
          color='primary'
          data-test='cars-with-errors-got-it-accept'
        >
          {t('global.action.gotit')}
        </Button>
      </DialogActions>
    </NotificationDialogStyled>
  )
}

export default translate()(NotificationDialog)
