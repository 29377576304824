import {call, put, select, takeLatest} from 'redux-saga/effects'
import {showSnackbarNotification} from '../../signals/actions'
import * as signalTypes from '../../signals/types'
import {getCountryCode} from '../../config/selectors'
import * as types from './types'
import * as DocumentApi from '../../../api/document/requests'
import * as selectors from './selectors'
import pickBy from 'lodash/pickBy'
import {formatParams} from '../../utils'

function* fetchCarDataFilesListSaga(action) {
  try {
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)
    const carDataFiles =
      action.payload && action.payload.carDataFiles
        ? action.payload.carDataFiles
        : yield select(selectors.getParsedFilterForQuery)
    const filters =
      action.payload && action.payload.filters
        ? formatParams(action.payload.filters)
        : {}
    const countryCode = yield select(getCountryCode)
    const sort = yield select(selectors.getSort)

    const options = {
      limit,
      page,
      sort,
      entity: ['carDataFile'],
      reference: [countryCode],
      ...carDataFiles,
      ...filters
    }

    const carDataFilesList = yield call(DocumentApi.documentList, {
      ...pickBy(options)
    })

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchCarDataFilesListSuccess,
      payload: carDataFilesList.document
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchCarDataFilesListError,
      payload: e
    })
  }
}

function* updateFiltersSaga() {
  try {
    const filters = yield select(selectors.getParsedFilterForQuery)

    yield put({
      type: types.fetchCarDataFilesList,
      payload: {
        carDataFiles: filters,
        page: 1
      }
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.updateFilters, updateFiltersSaga),
  takeLatest(types.toggleSort, fetchCarDataFilesListSaga),
  takeLatest(types.fetchCarDataFilesList, fetchCarDataFilesListSaga)
]
