import * as types from './types'
import {commentSchema, getInitialState} from './initialState'
import * as formDecorators from '../../utils/formDecorators'

const reducer = {
  [types.setFetching]: (state, action) => ({
    ...state,
    fetching: action.payload
  }),
  [types.listComments]: (state, action) => {
    return {
      ...state,
      fetching: false,
      list: action.payload
    }
  },
  [types.userListLoaded]: (state, action) => {
    return {
      ...state,
      userList: action.payload.map((user) => ({
        value: user.id,
        label: `${user.firstname} ${user.lastname}`
      }))
    }
  }
}

export default {
  ...reducer,
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: commentSchema
  })
}
