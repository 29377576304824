import {LocationManager} from '../../pages/ModulePages/LocationManager'

export default {
  path: 'location-manager',
  exact: false,
  label: 'Location Manager',
  key: 'locationManager',
  component: LocationManager,
  fields: {}
}
