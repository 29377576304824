import {ValidatedTextField} from '../../../../components/FormValidation'

export default {
  stepNum: 2,
  stepTitle: 'driver.mainContact',
  description: 'driver.panel.description.mainContact',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedTextField,
            id: 'metaInfoEmail',
            fullWidth: true,
            label: 'driver.page.field.email',
            margin: 'normal',
            type: 'email',
            onChangeHandler: 'updateMetaField'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            id: 'phone',
            label: 'dealer.page.field.phone',
            margin: 'normal',
            required: true,
            isNumber: true,
            onChangeHandler: 'updateMetaField'
          }
        ]
      }
    ]
  }
}
