import {
  Content,
  NoContent,
  NoImageText,
  SubtableErrorContainer,
  ErrorBox,
  SubtableLoadingContainer,
  EmptyTablePlaceholderWrapper
} from './styles'
import Document from '@material-ui/icons/Description'
import Button from '@material-ui/core/Button'
import LoadingState from '../LoadingState'
import {RenderIf} from '../RenderIf'
import Search from '@material-ui/icons/Search'

export const getNoContentComponent = (Icon, description) => (props) => (
  <Content fullWidth={props.fullWidth}>
    <NoContent>
      <Icon color='disabled' fontSize='large' />
      <NoImageText>{props.t(description)}</NoImageText>
    </NoContent>
  </Content>
)

export const NoDocumentMessage = getNoContentComponent(
  Document,
  'documentList.empty'
)

export const ErrorContent = ({error, ...props}) => {
  const errorMessage =
    error.error && error.error.message
      ? error.error.message
      : JSON.stringify(error.error)

  return (
    <SubtableErrorContainer>
      <h4>{props.t('car.page.error.occurred')}</h4>
      <ErrorBox>{errorMessage}</ErrorBox>
      <Button onClick={() => props.fetchRowData({id: props.rowId})}>
        {props.t('button.retry')}
      </Button>
    </SubtableErrorContainer>
  )
}

export const SubtableLoading = () => (
  <SubtableLoadingContainer>
    <LoadingState />
  </SubtableLoadingContainer>
)

export const EmptyTablePlaceholder = ({
  colspan,
  show,
  t,
  message = 'payslips.dialog.table.placeholder',
  showIcon = true
}) => (
  <RenderIf if={show}>
    <EmptyTablePlaceholderWrapper>
      <td colSpan={colspan}>
        {showIcon ? <Search /> : null}
        <div>{t(message)}</div>
      </td>
    </EmptyTablePlaceholderWrapper>
  </RenderIf>
)
