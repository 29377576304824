import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as selectors from '../../store/payslips/list/selectors'
import {
  toggleField,
  toggleSort,
  fetchPayslips,
  addCarPaymentSelection,
  updateTicketEntity,
  approvePayment,
  fetchPayslipOptions,
  selectPayslip,
  toggleRow,
  toggleRowSelection,
  fetchRowData,
  updateFilters,
  onMedaInfoChange
} from '../../store/payslips/list/actions'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import CustomSnackbar from '../../components/Snackbar'
import CreateReceipt from './CreateReceipt'
import {ExpandableComponent} from './ExpandableComponent'
import {ticketStatuses} from '../../config/pages/payslips/list/payslipConfig'
import RejectReceipt from './RejectReceipt'
import {RenderIf} from '../../components/RenderIf'
import {hasUserPermissions} from '../../store/auth/selectors'
import {isPageHidden} from '../../store/config/selectors'
import {getCreatePayslipFilterConfig} from '../../store/payslips/create/selectors'
import {cleanUp} from '../../store/documentConfig/actions'

const isPayslipsHidden = isPageHidden('payslips')

export class Payslips extends Component {
  constructor(props) {
    super(props)

    this.props.fetchRejectionReasons()
    this.props.cleanupDocumentState()

    this.decidedTicketStates = [
      ticketStatuses.APPROVED,
      ticketStatuses.REJECTED
    ]
    this.state = {
      isRejectOpen: false,
      isCreateOpen: false
    }
  }

  handleDialogClose = () => {
    this.setState({
      isCreateOpen: false,
      isRejectOpen: false
    })
  }

  shouldShowApproveBtn = ({status}) =>
    !this.decidedTicketStates.includes(status) && this.props.canModifyPayslips

  onRejectReceipt = ({ticketId}) => () => {
    if (!this.props.canModifyPayslips) {
      return
    }

    this.props.selectPayslip(ticketId)
    this.setState({
      isCreateOpen: false,
      isRejectOpen: true
    })
  }

  openCreateDialog = () => {
    this.props.collapseAllRows()
    this.setState({
      isCreateOpen: true,
      isRejectOpen: false
    })
  }

  render() {
    if (!this.props.canReadPayslips) {
      return null
    }

    if (!this.props.hasUserInfo && !this.props.canReadPayslips) {
      return <CircularProgress />
    }

    const {className, locale, ...tableProps} = this.props

    const expandableComponentProps = {
      // Functions
      addCarPaymentSelection: this.props.addCarPaymentSelection,
      t: this.props.t,
      onApprovePayment: this.props.onApprovePayment,
      onRejectReceipt: this.onRejectReceipt,
      shouldShowRejectBtn: this.shouldShowApproveBtn,
      shouldShowApproveBtn: this.shouldShowApproveBtn,
      updateTicketEntity: this.props.updateTicketEntity,
      onRowToggle: this.props.onRowToggle,
      fetchRowData: this.props.fetchRowData,
      onMetaInfoChange: this.props.onMetaInfoChange,
      // Data
      carPaymentReceiptsSelection: this.props.carPaymentReceiptsSelection,
      subtableSummaryFields: this.props.subtableSummaryFields,
      locale: locale,
      fixedHeight: false,
      expandedRows: this.props.expandedRows,
      rowFetching: this.props.rowFetching,
      rowFetchingErrors: this.props.rowFetchingErrors,
      permissions: {
        canModifyPayslips: this.props.canModifyPayslips
      }
    }

    return (
      <div className={className}>
        <PageTableBase
          {...tableProps}
          filterable={false}
          showFilters
          noToolbar={false}
          withSaveFilter={false}
          withQuickFilter={false}
          limit={this.props.limit}
          title='menu.payslips.sectionHeadline'
          multisort={false}
          withVisibility={false}
          extendExpand={2}
          subfields={this.props.subfields}
          expandableComponentProps={expandableComponentProps}
          ExpandableComponent={ExpandableComponent}
          expandableTable
          scrollOnOpen
          noHeaderLeftElement
          expandedRows={this.props.expandedRows}
          onFilterChange={this.props.onFilterChange}
          urlParametersEnabled
        />
        <RenderIf if={this.props.canCreatePayslips}>
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openCreateDialog}
              data-test='create-new-payslips-button'
            >
              <Add />
            </Button>
          </ActionButton>
        </RenderIf>
        <CustomSnackbar />
        <RenderIf if={this.props.canCreatePayslips}>
          <CreateReceipt
            isOpen={this.state.isCreateOpen}
            handleClose={this.handleDialogClose}
            fields={this.props.pageConfig.newPayslip}
            filterFields={this.props.createReceiptFilters}
            summaryFields={this.props.pageConfig.newPayslipSummaryFields}
          />
        </RenderIf>
        <RenderIf if={this.props.canModifyPayslips}>
          <RejectReceipt
            isOpen={this.state.isRejectOpen}
            handleClose={this.handleDialogClose}
            canReject={this.props.canModifyPayslips}
          />
        </RenderIf>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  data: selectors.getListData(state),
  fetching: selectors.isFetching(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  createReceiptFilters: getCreatePayslipFilterConfig(props),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  subfields: selectors.getSubfields(state),
  carPaymentReceiptsSelection: selectors.getCarPaymentReceiptsSelection(state),
  locale: selectors.getLocale(state),
  subtableSummaryFields: selectors.getSubtableSummaryFields(state),
  rowFetching: selectors.getRowFetchingStatus(state),
  rowFetchingErrors: selectors.getRowErrorStatus(state),
  expandedRows: selectors.getExpandedRows(state),
  canReadPayslips: selectors.canAccessPayslips(state),
  canModifyPayslips: selectors.canModifyPayslips(state),
  canCreatePayslips: selectors.canCreatePayslips(state),
  hasUserInfo: hasUserPermissions(state),
  isPageHidden: isPayslipsHidden(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(fetchPayslips(args)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  addCarPaymentSelection: ({ticketId, carReceiptId}) =>
    dispatch(addCarPaymentSelection({ticketId, carReceiptId})),
  updateTicketEntity: (data) => dispatch(updateTicketEntity(data)),
  onApprovePayment: (data) => () => dispatch(approvePayment(data)),
  fetchRejectionReasons: () => dispatch(fetchPayslipOptions()),
  selectPayslip: (id) => dispatch(selectPayslip(id)),
  collapseAllRows: () => dispatch(toggleRowSelection()),
  onRowToggle: (rowData) => dispatch(toggleRow(rowData)),
  fetchRowData: (rowData) => dispatch(fetchRowData(rowData)),
  cleanupDocumentState: () => dispatch(cleanUp()),
  onFilterChange: (filters) => dispatch(updateFilters(filters)),
  onMetaInfoChange: (event) => dispatch(onMedaInfoChange(event))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(Payslips))
