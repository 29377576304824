// task list types
export const fetching = 'taskList/LOADING'
export const dataLoaded = 'taskList/LOADED'
export const toggleField = 'taskList/TOGGLE_FIELD'
export const changePage = 'taskList/CHANGE_PAGE'
export const toggleSort = 'taskList/TOGGLE_SORT'
export const setSort = 'taskList/SET_SORT'
export const updateFilters = 'taskList/UPDATE_FILTER'

// form types
export const updateForm = 'taskForm/UPDATE'
export const resetForm = 'taskForm/RESET'
export const hydrateForm = 'taskForm/HYDRATED'
export const formError = 'taskForm/ERROR'
export const formSuccess = 'taskForm/SUCCESS'
export const formPending = 'taskForm/PENDING'
