import React from 'react'

export default function NoDataIcon() {
  return (
    <svg width='82' height='80' viewBox='0 0 82 80'>
      <defs>
        <path
          id='prefix__a'
          d='M0 39.69c0 21.92 17.709 39.69 39.553 39.69 21.8 0 39.48-17.696 39.553-39.553v-.274C79.033 17.695 61.353 0 39.553 0 17.708 0 0 17.77 0 39.69'
        />
      </defs>
      <g fill='none' fillRule='evenodd' transform='translate(1.02)'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <use fill='#FFCE32' xlinkHref='#prefix__a' />
        <g mask='url(#prefix__b)'>
          <g transform='translate(11.333 12)'>
            <path
              fill='#D2B982'
              d='M.367 68c0-13.798 11.169-24.966 24.966-24.966C39.131 43.034 50.3 54.224 50.3 68'
            />
            <circle cx='25.333' cy='27.798' r='19.258' fill='#F8FBCF' />
            <path
              fill='#002D39'
              d='M19.603 1.326C15.221 2.584 8.929 6.202 6.21 3.843c-.72 2.674-.27 5.505 1.708 7.663-1.528 2.606-2.472 6.045-2.472 10.606v6.63c0 3.685.404 9.595 5.01 14.224 1.866 2.113 7.686 6.292 15.462 6.292 5.775 0 12.292-4.629 12.292-4.629 6.314-4.764 7.146-11.775 7.146-15.887v-8.63C45.356-3.393 24.03.045 19.603 1.326zm23.28 27.73c0 5.416-3.1 10.81-9.01 12.382-1.079-2.741-4.382-4.741-8.27-4.741-3.798 0-7.034 1.91-8.18 4.561-6.09-1.46-9.528-6.719-9.528-12.202V22.54c0-1.415.562-4.651 3.191-6.876.562-.472 1.124-.899 1.73-1.259 3.596 1.08 8.697 1.169 15.64-.382 6.45-1.438 8.743-.471 11.237 1.64 2.63 2.203 3.19 5.462 3.19 6.877v6.517z'
            />
            <path
              fill='#002F34'
              fillRule='nonzero'
              d='M27.603 29.528c0-1.888 1.528-3.416 3.416-3.416 1.887 0 3.415 1.528 3.415 3.416h-6.831zM16.232 29.528c0-1.888 1.528-3.416 3.416-3.416s3.416 1.528 3.416 3.416h-6.832z'
            />
          </g>
        </g>
        <g mask='url(#prefix__b)'>
          <g>
            <path
              fill='#D2B982'
              d='M31.655 10.267c3.031 7.49.144 15.905-6.466 20.06l4.781 11.81-.367.869-1.678 3.968-2.816-1.196-2.018-.856-4.92-12.155c-7.11.76-14.15-3.218-16.967-10.175-3.403-8.409.654-17.984 9.063-21.388 8.409-3.403 17.985.654 21.388 9.063z'
              transform='translate(33.333 25.161)'
            />
            <path
              fill='#F8FBCF'
              d='M20.8 44.913c0-4.56 3.696-8.257 8.256-8.257 4.56 0 8.257 3.697 8.257 8.257s-3.697 8.257-8.257 8.257-8.257-3.696-8.257-8.257M24.69 6.714c5.365 4.562 6.017 12.61 1.455 17.976-4.562 5.365-12.61 6.017-17.976 1.455-5.365-4.562-6.017-12.61-1.454-17.976 4.562-5.365 12.61-6.017 17.975-1.455'
              transform='translate(33.333 25.161)'
            />
            <path
              fill='#002F34'
              d='M23.185 16.731c0 3.623-2.937 6.56-6.56 6.56-3.622 0-6.559-2.937-6.559-6.56 0-3.622 2.937-6.56 6.56-6.56 3.622 0 6.56 2.938 6.56 6.56'
              transform='translate(33.333 25.161)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
