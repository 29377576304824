const resourceName = 'signals'

export const showSnackbarNotification = `${resourceName}/SHOW_SNACKBAR_NOTIFICATION`
export const closeSnackbarNotification = `${resourceName}/CLOSE_SNACKBAR_NOTIFICATION`

export const variantTypes = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info'
}
