import {compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {translate} from 'react-i18next'
import {getCountryOptionsSelector} from '../../store/crm/selectors'

import './CountryOptionsSelect.styl'

import {ValidatedSelectField} from '../../components/FormValidation'

const countryOptionsSelector = getCountryOptionsSelector()
export function CountryOptionsSelect({
  t,
  options,
  optionsLabelPrefix,
  disableOnNoOtherOptions,
  notRequiredOnNoOptions,
  placeholderForNoOption,
  name,
  value,
  ...props
}) {
  const optionsWithLabel = options.map((optName) => {
    return {
      value: optName,
      label: t(`${optionsLabelPrefix || 'businessPartners'}.${name}.${optName}`)
    }
  })

  return (
    <ValidatedSelectField
      options={optionsWithLabel}
      selected={value}
      value={value}
      name={name}
      {...props}
      disabled={
        props.disabled || (disableOnNoOtherOptions && options.length < 2)
      }
      required={
        notRequiredOnNoOptions && !optionsWithLabel.length
          ? false
          : props.required
      }
      placeholder={
        placeholderForNoOption && !optionsWithLabel.length
          ? placeholderForNoOption
          : props.placeholder
      }
    />
  )
}

CountryOptionsSelect.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionsLabelPrefix: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChangeHandler: PropTypes.func,
  required: PropTypes.bool
}

CountryOptionsSelect.defaultProps = {
  onChangeHandler: () => {}
}

const mapStateToProps = (state, ownProps) => ({
  options: countryOptionsSelector(state, ownProps)
})

export default compose(
  translate(),
  connect(mapStateToProps, null, null, {pure: false})
)(CountryOptionsSelect)
