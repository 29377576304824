import React from 'react'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import {Add, Delete} from '@material-ui/icons'

import {
  fetchAuctionSchedulesList,
  toggleRow,
  setPageOptions,
  deleteAuctionFlow
} from '../../../store/auctionSchedules/actions'
import selectors from '../../../store/auctionSchedules/selectors'
import {checkUserPermissions} from '../../../store/auth/selectors'
import {ConfigContext} from '../../../components/providers/withConfig'

import ExpandableComponent from './ExpandableComponent'
import PageTableBase from '../../../components/PageTableBase'
import ActionButton from '../../../components/layout/ActionButton'
import CustomSnackbar from '../../../components/Snackbar'
import CreateSchedule from './CreateSchedule'
import {DeleteDialog} from '../../CRM/common'
import {PermissionWrapper} from '../../../components/Permissions'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

class Schedules extends React.Component {
  constructor() {
    super()

    this.state = {
      isCreateOpen: false,
      delete: {...deleteInitialState}
    }
  }

  handleDialogClose = () => {
    this.setState({
      isCreateOpen: false
    })
  }

  openCreateDialog = () => {
    this.setState({
      isCreateOpen: true
    })
  }

  fetchData = (e) => {
    if (!this.props.isFetched) {
      this.props.fetchData()
    } else {
      this.props.setPageOptions(e)
    }
  }

  getSingleSelectActions = () =>
    this.props.canDeleteSchedules ? this.singleSelectTableActions() : []

  singleSelectTableActions = () => [
    {
      event: (item) => {
        this.openDeleteDialog({
          entity: 'auctionFlow',
          flowId: item.id,
          action: this.props.deleteAuctionFlow
        })
      },
      icon: <Delete />,
      title: 'global.action.delete'
    }
  ]

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, flowId, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(flowId)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  render() {
    return (
      <React.Fragment>
        <PageTableBase
          {...this.props}
          multisort={false}
          filterable={false}
          selectable={false}
          title='auctionSchedules.title'
          withVisibility={false}
          limit={this.props.limit}
          fetchData={this.fetchData}
          extendExpand={2}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          ExpandableComponent={ExpandableComponent}
          onRowToggle={this.props.onRowToggle}
          scrollOnOpen
          count={this.props.count}
          singleSelectActions={this.getSingleSelectActions()}
        />

        <PermissionWrapper
          entity='web.admin.ui.auctionsManagement.schedules'
          types={['CREATE']}
        >
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openCreateDialog}
              data-test='create-new-schedule-button'
            >
              <Add />
            </Button>
          </ActionButton>
          <CustomSnackbar />
          <ConfigContext.Consumer>
            {(config) => (
              <CreateSchedule
                isOpen={this.state.isCreateOpen}
                handleClose={this.handleDialogClose}
                previewOffset={config?.auctionPreviewOffset}
              />
            )}
          </ConfigContext.Consumer>
        </PermissionWrapper>
        <PermissionWrapper
          entity='web.admin.ui.auctionsManagement.schedules'
          types={['DELETE']}
        >
          <DeleteDialog {...this.state.delete} />
        </PermissionWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getSchedulesPage(state),
  fields: selectors.getFields(state),
  subfields: selectors.getSubFields(state),
  expandedRows: selectors.getExpandedRows(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getSchedulesCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  isFetched: selectors.getIsFetched(state),
  canDeleteSchedules: checkUserPermissions(state, {
    entity: 'web.admin.ui.auctionsManagement.schedules',
    types: ['DELETE']
  })
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (options) => dispatch(fetchAuctionSchedulesList(options)),
  onRowToggle: (rowData) => dispatch(toggleRow(rowData)),
  setPageOptions: (options) => dispatch(setPageOptions(options)),
  deleteAuctionFlow: (options) => dispatch(deleteAuctionFlow(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedules)
