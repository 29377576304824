import * as types from './types'
import {getInitialState, countryOptionSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  [types.fetchCountryOptionList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchCountryOptionListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    countryOptionList: action.payload.list
  }),
  [types.fetchCountryOptionListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.openCreateDialog]: (state) => ({
    ...state,
    showCreateDialog: true
  }),
  [types.createCountryOptionSuccess]: (state) => ({
    ...state,
    isLoading: false,
    isFetchingList: true,
    form: {},
    showCreateDialog: false
  }),
  [types.createCountryOptionError]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  [types.hideCreateDialog]: (state) => ({
    ...state,
    edit: false,
    showCreateDialog: false,
    form: {}
  }),
  [types.updateCountryOptionField]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      [action.payload.name]: action.payload.value
    }
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: countryOptionSchema
  })
}
