import isEqual from 'lodash/isEqual'

export const wrapFilter = (WrappedComponent, extend = {}) => (props) => {
  const {className, handleChange, ...rest} = {...props, ...extend}
  const compareValue = (key) => (value, mask = null, next) => {
    if (isEqual(value, props.value)) {
      return false
    } else {
      return handleChange({key, value, mask}, next)
    }
  }

  let options = props.options

  if (typeof props.t === 'function' && Array.isArray(props.options)) {
    const prefix =
      typeof extend.prefix !== 'undefined' ? `${extend.prefix}.` : ''

    options = props.options.map((item) => ({
      ...item,
      label: props.t(`${prefix}${item.label}`)
    }))
  }

  const handleKeyUp = (e) => {
    const code = e.keyCode || e.which

    return code === 13 ? props.handleSave() : undefined
  }

  return (
    <WrappedComponent
      {...rest}
      options={options}
      className={[className, 'Filter'].join(' ')}
      onBlur={compareValue}
      onKeyUp={handleKeyUp}
      handleChange={compareValue}
    />
  )
}

export default wrapFilter
