import axios from 'axios'
import {
  InvalidCredError,
  InvalidTokenError,
  NoResponseError
} from './utils/errors'
import * as urls from './config/api'
import {
  getToken,
  getOktaToken,
  removeAuth,
  removeGoogleAuth
} from './utils/authToken'
import merge from 'lodash/merge'
import {RequestLogger} from '@fcg/client-logger'
import {logData, errors} from './api/helper'

axios.defaults.method = 'POST'
axios.defaults.withCredentials = true

const defaultInterceptor = {
  request: [
    (config) => {
      config.requestLogger = new RequestLogger()

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  ],
  response: [
    (response) => {
      logData(response)

      return response.data
    },
    (error) => {
      let customError = error.response
        ? error.response.data
        : new NoResponseError()
      if (error.response) {
        logData(error.response)

        const CustomException = errors[error.response.status]

        if (CustomException) {
          const message =
            error.response.data?.message ||
            error.response.data.errors[0].message
          customError = new CustomException(message)
        }

        if (customError instanceof InvalidCredError) {
          const loginPath = location.pathname
            ? `/login?returnUrl=${location.pathname}${location.search}`
            : '/login'
          removeAuth()
          removeGoogleAuth()
          window.location.href = loginPath
        }
      } else {
        logData(error)
      }

      return Promise.reject(customError)
    }
  ]
}

class SimpleApiClient {
  constructor(domain, additionalHeaders = {}) {
    const baseURL = urls[`${domain}ApiUrl`]

    if (typeof baseURL === 'undefined') {
      throw new Error('Provide a valid domain name')
    }

    this.domain = domain
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        ...additionalHeaders
      }
    })
    this.client.interceptors.request.use(...defaultInterceptor.request)
    this.client.interceptors.response.use(...defaultInterceptor.response)

    this.requestInterceptors = []
    this.transform = []
    this.increaseTimeout = false
    this.fields = {}
    this.additionalRequestConfigs = []
    this.extractPureData = false
    this.extractList = false
  }

  apiCall = async (
    path,
    data,
    ignoreLoggingRequestBody = false,
    method = 'post'
  ) => {
    try {
      const token = getToken()
      const oktaToken = getOktaToken()

      const requestData = {
        method: method,
        url: path,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
          'x-increase-timeout': this.increaseTimeout
        },
        ignoreLoggingRequestBody,
        ...this.calculateAdditionalConfigs()
      }

      if (!token) {
        throw new InvalidTokenError()
      }

      if (token) {
        if (oktaToken) {
          requestData.headers['x-okta-client-id'] = oktaToken.clientId
          requestData.headers['x-okta-nonce'] =
            oktaToken.claims && oktaToken.claims.nonce
        }
        requestData.headers.Authorization = `Bearer ${token}`
      }

      const response = await this.client.request(requestData)

      this.clearHelpers()
      this.clearInterceptors()
      this.clearTransform()
      this.clearRequestConfigs()

      return response
    } catch (error) {
      this.clearHelpers()

      throw error
    }
  }

  clearHelpers = () => {
    this.clearInterceptors()
    this.clearTransform()
    this.clearIncreasedTimeout()
  }

  calculateAdditionalConfigs = () =>
    this.additionalRequestConfigs.length
      ? this.additionalRequestConfigs.reduce(
          (acc, config) => merge(acc, config()),
          {}
        )
      : {}

  query = (path, data, ignoreLoggingRequestBody = false, method = 'post') => {
    const response = this.apiCall(path, data, ignoreLoggingRequestBody, method)
    this.clearTransform()

    return response
  }

  useInterceptor = ({response, error}) =>
    this.client.interceptors.response.use(response, error)

  ejectInterceptor = (interceptor) =>
    this.client.interceptors.response.eject(interceptor)

  clearInterceptors = () => {
    this.requestInterceptors.forEach((interceptor) =>
      this.ejectInterceptor(interceptor)
    )

    this.requestInterceptors = []

    return this
  }

  withInterceptor = (interceptor) => {
    const interceptorId = this.useInterceptor(interceptor)

    this.requestInterceptors.push(interceptorId)

    return this
  }

  withAdditionalRequestConfig = (requestConfigs) => {
    this.additionalRequestConfigs = Array.isArray(requestConfigs)
      ? [...this.additionalRequestConfigs, ...requestConfigs]
      : [...this.additionalRequestConfigs, requestConfigs]

    return this
  }

  clearTransform = () => {
    this.transform = []

    return this
  }

  clearFieldFilter = () => {
    this.fieldFilter = null
  }

  withIncreasedTimeout = () => {
    this.increaseTimeout = true

    return this
  }

  clearIncreasedTimeout = () => {
    this.increaseTimeout = false

    return this
  }

  clearRequestConfigs = () => {
    this.additionalRequestConfigs = []
  }
}

export default SimpleApiClient
