import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const statusToggleSwitchStyle = {
  position: 'absolute',
  right: 0,
  top: '-52px',
  width: '50%',
  textAlign: 'right'
}

const StatusToggleSwitch = ({
  status,
  onLocationStatusChange
}: {
  status: boolean
  onLocationStatusChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => (
  <div style={statusToggleSwitchStyle}>
    <FormControlLabel
      control={
        <Switch
          checked={status}
          onChange={onLocationStatusChange}
          value='status'
          color='primary'
        />
      }
      label={status ? 'Deactivate location' : 'Activate Location'}
      labelPlacement='start'
    />
  </div>
)

export default StatusToggleSwitch
