import {ValidatedTextField} from '../../../../components/FormValidation'
import {ValidatedUserPicker as UserPickerField} from '../../../../components/Pickers/UserPicker'
import {disabledCallback} from './utils'

export default {
  stepNum: 5,
  stepTitle: 'dealer.responsibles',
  description: 'dealer.panel.description.responsibles',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            debounce: true,
            id: 'dealerManager',
            label: 'dealer.page.field.dealerManager',
            margin: 'normal',
            name: 'dealerManager',
            withTranslate: false,
            onChangeHandler: 'updateMetaField',
            userClass: 'DEALER',
            onLoad: 'setDealerOptions',
            placeholder: (props) => props.data.dealerManagerName,
            limit: 25,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            debounce: true,
            id: 'dealerHunter',
            label: 'dealer.page.field.dealerHunter',
            margin: 'normal',
            name: 'dealerHunter',
            users: 'dealerHunters',
            withTranslate: false,
            onChangeHandler: 'updateMetaField',
            userClass: 'INTERNAL',
            onLoad: 'setDealerHunterOptions',
            placeholder: (props) => props.data.dealerHunterName,
            limit: 25,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            debounce: true,
            id: 'onboardingAgent',
            users: 'onboardingAgents',
            label: 'dealer.page.field.onboardingAgent',
            margin: 'normal',
            name: 'onboardingAgent',
            withTranslate: false,
            onChangeHandler: 'updateMetaField',
            userClass: 'INTERNAL',
            onLoad: 'setOnboardingAgentOptions',
            placeholder: (props) => props.data.onboardingAgentName,
            limit: 25,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            debounce: true,
            id: 'dealerFarmer',
            label: 'dealer.page.field.dealerFarmer',
            margin: 'normal',
            name: 'dealerFarmer',
            users: 'dealerFarmers',
            withTranslate: false,
            onChangeHandler: 'updateMetaField',
            userClass: 'INTERNAL',
            onLoad: 'setDealerFarmerOptions',
            placeholder: (props) => props.data.dealerFarmerName,
            limit: 25,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            debounce: true,
            id: 'dealerBidManager',
            label: 'dealer.page.field.dealerBidManager',
            margin: 'normal',
            name: 'dealerBidManager',
            users: 'dealerBidManagers',
            withTranslate: false,
            onChangeHandler: 'updateMetaField',
            userClass: 'INTERNAL',
            onLoad: 'setDealerBidManagerOptions',
            placeholder: (props) => props.data.dealerBidManagerName,
            limit: 25,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            id: 'leadSource',
            label: 'dealer.page.field.leadSource',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            fullWidth: true,
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
