import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Edit from './Edit'
import {ValidatedForm} from '@fcg/formvalidation'
import {default as selectors} from '../../store/webhook/selectors'
import {
  hideCreateDialog,
  createWebhook,
  fetchWebhookEventsList,
  updateWebhookField
} from '../../store/webhook/actions'
import styled from 'styled-components'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}

const EventsWrapper = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin: 30px 0px 20px;
  width: 100%;
`

const EventsWrapperError = styled.div`
  color: #f44336;
  div {
    font-size: 14px;
    margin: 10px 0px 0px 20px;
  }
`

const WebhookDescription = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
`

class WebhookEditDialog extends Component {
  state = {isValid: true}

  componentDidMount() {
    const {fetchWebhookEventsList} = this.props

    fetchWebhookEventsList()
  }

  validateEvents = () => {
    const {form} = this.props

    return form.events && form.events.length > 0
  }

  handleSubmit = () => {
    const {createWebhook, form} = this.props
    const eventsAreValid = this.validateEvents()

    this.setState({
      isValid: eventsAreValid
    })

    this.form.validate().then((success) => {
      if (success && eventsAreValid) {
        createWebhook(form)
      }
    })
  }

  handleInput = (e, name) => {
    this.props.updateWebhookField({
      name,
      value: e.target.value
    })
  }

  handleCheckbox = (e) => {
    let {events = []} = this.props.form
    const value = e.target.value

    if (e.target.checked) {
      events.push(value)
    } else {
      events = events.filter(function(event) {
        return event !== value
      })
    }

    this.props.updateWebhookField({
      name: 'events',
      value: events
    })

    this.setState({
      isValid: true
    })
  }

  handleCancel = () => {
    this.props.hideCreateDialog()
  }

  render() {
    const {t, form, options, events} = this.props

    return (
      <Dialog
        open={true}
        maxWidth={'md'}
        fullWidth={true}
        onClose={this.handleCancel}
      >
        <ValidatedForm ref={(ref) => (this.form = ref)} t={t}>
          <DialogTitle id='form-dialog-title'>
            {t('webhook.create.title')}
            <WebhookDescription>
              {t('webhook.create.description')}
            </WebhookDescription>
          </DialogTitle>
          <DialogContent>
            <Edit
              {...this.props}
              data={form}
              options={options}
              update={this.update}
              handleTextInput={this.handleInput}
            />
            <Grid container spacing={3}>
              <EventsWrapper>
                {this.state.isValid ? (
                  t('webhook.select.events')
                ) : (
                  <EventsWrapperError>
                    {t('webhook.select.events')}
                    <div>{t('webhook.events.error')}</div>
                  </EventsWrapperError>
                )}
              </EventsWrapper>
              {events.map((event) => (
                <Grid item xs={6} key={`${event}-item`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={event}
                        color='primary'
                        onChange={this.handleCheckbox}
                      />
                    }
                    label={event}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions style={dialogActionsStyles}>
            <Button onClick={this.handleCancel} color='primary'>
              {t('button.cancel')}
            </Button>
            <Button
              variant='raised'
              type='submit'
              onClick={this.handleSubmit}
              color='primary'
            >
              {t('global.action.create')}
            </Button>
          </DialogActions>
        </ValidatedForm>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  form: selectors.getFormData(state),
  events: selectors.getEvents(state)
})

const mapDispatchToProps = (dispatch) => ({
  hideCreateDialog: () => dispatch(hideCreateDialog()),
  createWebhook: (webhook) => dispatch(createWebhook(webhook)),
  updateWebhookField: (payload) => dispatch(updateWebhookField(payload)),
  fetchWebhookEventsList: () => dispatch(fetchWebhookEventsList())
})

const WebhookEditorForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(WebhookEditDialog))

export default WebhookEditorForm
