import {Component} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import * as selectors from '../../store/payslips/list/selectors'
import Select from '../../components/Select/Select'
import {rejectPayslip} from '../../store/payslips/list/actions'
import {showErrorMessage} from '../../store/signals/actions'
import {getRejectionMessage} from './util'
import {ValidatedTextField} from '../../components/FormValidation'

const COMMENT_MAX_LEN = 120
const RejectionComment = styled.div`
  margin-top: 10px;
`

class RejectReceipt extends Component {
  state = {
    reason: null,
    comment: null
  }

  onDialogClose = () => {
    this.props.handleClose()
  }

  onReasonSelect = (reason) =>
    this.setState({
      reason
    })

  onCommentChange = (e) => {
    const comment = e.target.value

    this.setState({
      comment
    })
  }

  onRejectPayslip = () => {
    if (!this.state.reason) {
      this.props.showErrorMessage(
        this.props.t('payslips.error.noReasonSelected')
      )

      return
    }

    if (this.state.comment && this.state.comment.length > COMMENT_MAX_LEN) {
      this.props.showErrorMessage(
        this.props.t('payslips.error.rejectCommentTooLong', {
          maxLength: COMMENT_MAX_LEN
        })
      )

      return
    }

    this.props.onRejectPayslip(
      getRejectionMessage({
        t: this.props.t,
        ...this.state
      })
    )
  }

  componentDidUpdate() {
    if (
      !this.props.canReject ||
      !this.props.getSelectedPayslip ||
      (this.props.hasUpdated && !this.props.isUpdating)
    ) {
      this.props.handleClose()
    }
  }

  isSaveBtnDisabled = () => this.props.isUpdating && !this.props.hasUpdated

  isCancelBtnDisabled = () => this.props.isUpdating

  render() {
    const {t} = this.props

    return (
      <Dialog open={this.props.isOpen} maxWidth='md' fullWidth>
        <DialogTitle id='form-dialog-title'>
          {this.props.t('payslips.dialog.reject.title')}
        </DialogTitle>
        <DialogContent>
          <div>
            <Select
              filterable={false}
              label='payslips.dialog.reject.reason'
              selected={this.state.reason}
              options={this.props.rejectionReasons}
              onChange={this.onReasonSelect}
              data-test='reject-reason'
            />
          </div>
          <RejectionComment>
            <ValidatedTextField
              minLength={0}
              maxLength={COMMENT_MAX_LEN}
              fullWidth
              multiline
              rows={2}
              label='payslips.dialog.reject.comments'
              onChange={this.onCommentChange}
              data-test='reject-comment'
            />
          </RejectionComment>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onDialogClose}
            color='primary'
            disabled={this.isCancelBtnDisabled()}
            data-test='reject-cancel'
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant='raised'
            color='primary'
            type='submit'
            disabled={this.isSaveBtnDisabled()}
            onClick={this.onRejectPayslip}
            data-test='reject-confirm'
          >
            {t('button.reject')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  getSelectedPayslip: selectors.getSelectedPayslip(state),
  rejectionReasons: selectors.getRejectionReasons(state),
  hasUpdated: selectors.hasUpdated(state),
  isUpdating: selectors.isTicketUpdating(state)
})

const mapActionsToProps = (dispatch) => ({
  onRejectPayslip: (data) => dispatch(rejectPayslip(data)),
  showErrorMessage: (message) => dispatch(showErrorMessage(message))
})

export default translate()(
  connect(mapStateToProps, mapActionsToProps)(RejectReceipt)
)
