import React from 'react'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

const FormRow = ({
  children,
  noBorderBottom = false
}: {
  children: React.ReactNode
  noBorderBottom?: boolean
}) => {
  const classes = useCommonStyles()

  return (
    <Grid
      container
      style={{minHeight: '86px'}}
      className={cx(
        {[classes.borderBottom]: !noBorderBottom},
        classes.paddingY2
      )}
      justify='center'
    >
      {children}
    </Grid>
  )
}

export default FormRow
