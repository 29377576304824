import gql from '@fcg/lib-gql/gql'
import * as fields from './fields'

const queryMap = {
  comment: {
    endpoint: 'comment',
    param: 'commentData',
    structure: fields.commentList
  },
  changes: {
    endpoint: 'history',
    param: 'historyData',
    structure: fields.changesList
  },
  task: {
    endpoint: 'comment',
    param: 'commentData',
    structure: fields.commentList
  },
  lead: {
    endpoint: 'carLeadCallback',
    param: 'carLeadData',
    structure: fields.leadList
  },
  appointment: {
    endpoint: 'appointment',
    param: 'appointmentData',
    structure: fields.appointmentList,
    withoutContactId: true
  }
}

export function formatSort(request) {
  if (typeof request.sort === 'undefined') {
    return request
  }

  request.sort = Array.isArray(request.sort)
    ? request.sort.map(convertSort)
    : convertSort(request.sort)

  return request
}

export const convertCarLeadCallback = (object) => {
  if (typeof object['carLeadCallback'] === 'undefined') {
    return object
  }

  const replacement = {...object}

  replacement['lead'] = replacement['carLeadCallback']
  delete replacement['carLeadCallback']

  return replacement
}

export const convertCarLeadCreate = (object) => {
  const replacement = {...object}

  if (Array.isArray(replacement.responsible)) {
    replacement.responsible =
      replacement.responsible.length > 0 ? replacement.responsible[0] : ''
  }

  if (Array.isArray(replacement.responsibleName)) {
    replacement.responsibleName =
      replacement.responsibleName.length > 0
        ? replacement.responsibleName[0]
        : ''
  }

  return replacement
}

export function convertSort(sort) {
  if (sort.order && typeof sort.order === 'string') {
    sort.order = new gql.EnumType(sort.order)
  } else if (sort.order instanceof gql.EnumType === false) {
    sort.order = new gql.EnumType(sort.order.value)
  }

  return sort
}

export const convertCarLeadInterceptor = {
  response: (data) => convertCarLeadCallback(data)
}

export const sortInterceptor = {
  request: (params) => formatSort(params)
}

export function buildContactSubquery({contactId, subfield, args}) {
  const {endpoint, param, structure, withoutContactId} = queryMap[subfield]

  const params = {...(args && args[param] ? args[param] : {})}

  if (!withoutContactId) {
    params.contactId = contactId
  }

  return {
    [subfield]: {
      endpoint,
      query: {
        ...args,
        [param]: params
      },
      structure
    }
  }
}

export function formatAppointmentService(request) {
  if (typeof request.service === 'undefined') {
    return request
  }

  request.service = new gql.EnumType(request.service)

  return request
}
