import styled from 'styled-components'
import {SectionTitle} from '../../../../components/Subtable/styles'
import {EmptyTablePlaceholder} from '../../../../components/Subtable/components'
import {PageTableBase} from '../../../../components/PageTableBase'
import {leadSelfInspectionFields} from '../../../../config/pages/crm/leads'
import {isSelfInspectionType} from './util'

const noop = () => {}

const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 10px;
`

export const Table = (props) => (
  <DocumentListContainer>
    <SectionTitle>
      {props.t('crm.page.contact.leads.selfInspection')}
    </SectionTitle>
    <PageTableBase
      fields={leadSelfInspectionFields}
      pageConfig={{}}
      data={props.data}
      fetchData={noop}
      multisort={false}
      visibility={false}
      selectable={false}
      withVisibility={false}
      noToolbar
      hasFooter={false}
      tableHeight='sm'
      fixedHeight={props.fixedHeight}
      openLinkInNewTab
      emptyTablePlaceholder={
        <EmptyTablePlaceholder
          t={props.t}
          colspan={leadSelfInspectionFields.length}
          show={!isSelfInspectionType(props.inspectionType)}
          title='crm.page.contact.leads.noSelfInspection'
        />
      }
    />
  </DocumentListContainer>
)
