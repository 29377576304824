import {
  SellingStatusFilter,
  CarIDSearchFilter
} from '../../../../pages/Payslips/PayslipFilters'

export const fontWeight = 500

export const createPayslipFields = {
  internalId: {
    key: 'internalId',
    label: 'reports.column.internalId',
    type: 'string',
    isSortable: false,
    isIdField: true,
    filter: CarIDSearchFilter
  },
  make: {
    key: 'make',
    label: 'car.page.field.make',
    type: 'string',
    isSortable: false
  },
  model: {
    key: 'model',
    label: 'car.page.field.model',
    type: 'string',
    isSortable: false
  },
  year: {
    key: 'year',
    label: 'car.page.field.year',
    type: 'string',
    isSortable: false
  },
  sellingStatus: {
    key: 'sellingStatus',
    label: 'car.page.field.sellingStatus',
    type: 'string',
    isSortable: false,
    translate: true,
    show: true,
    filter: SellingStatusFilter
  },
  currentLocation: {
    key: 'currentLocation',
    label: 'car.page.field.currentLocation',
    type: 'string',
    isSortable: false
  },
  payment: {
    key: 'processingAmount',
    label: 'payslips.table.processingPayment',
    type: 'number',
    isSortable: false
  },
  outstanding: {
    key: 'outstanding',
    label: 'payslips.table.outstanding',
    type: 'number',
    isSortable: false
  },
  amount: {
    key: 'amount',
    label: 'payslips.table.amount',
    type: 'number',
    isSortable: false,
    isEditable: true
  }
}

export const newPayslipSummaryFields = {
  internalId: {
    type: 'string',
    label: 'total',
    key: 'total',
    value: 'global.total.label',
    style: {fontWeight},
    translate: true
  },
  make: {
    key: 'make',
    label: 'car.page.field.make',
    type: 'string',
    isSortable: false
  },
  model: {
    key: 'model',
    label: 'car.page.field.model',
    type: 'string',
    isSortable: false
  },
  year: {
    key: 'year',
    label: 'car.page.field.year',
    type: 'string',
    isSortable: false
  },
  sellingStatus: {
    key: 'sellingStatus',
    label: 'car.page.field.sellingStatus',
    type: 'string',
    isSortable: false,
    translate: true
  },
  currentLocation: {
    key: 'currentLocation',
    label: 'car.page.field.currentLocation',
    type: 'string',
    isSortable: false
  },
  payment: {
    key: 'processingAmount',
    label: 'payslips.table.processingPayment',
    type: 'number',
    isSortable: false
  },
  outstanding: {
    key: 'outstanding',
    label: 'payslips.table.outstanding',
    type: 'number',
    isSortable: false,
    summaryTransform: (acc = 0, current = 0) => acc + current
  },
  amount: {
    key: 'amount',
    label: 'payslips.table.amount',
    type: 'number',
    style: {fontWeight},
    summaryTransform: (acc = 0, current = 0) => acc + current
  }
}
