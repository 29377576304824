import {put, select, takeLatest} from 'redux-saga/effects'
import {showSnackbarNotification} from '../signals/actions'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as CategoryApi from '../../api/category/requests'
import * as Helper from '../../api/category/helpers'
import * as selectors from './selectors'
import * as global from '../global/actions'
import {getErrorFromResponse} from './utils/getErrorFromResponse'

function* fetchCategoryListSaga(action) {
  try {
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)

    console.log('calling category api')

    let categoryList = yield CategoryApi.getCategoryList()

    if (categoryList.error || categoryList === 'error') {
      throw categoryList.message || 'Request failure'
    }

    categoryList = categoryList.data.map((item) => {
      item['id'] = item.fileName
      return item
    })

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchCategoryListSuccess,
      payload: categoryList || []
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e,
        open: true
      })
    )

    yield put({
      type: types.fetchCategoryListError,
      payload: e
    })
  }
}

function* downloadItemSaga(action) {
  try {
    const fileName = action.payload
    const downloadResponse = yield CategoryApi.downloadCategoryListItem(
      fileName
    )

    if (downloadResponse.error || downloadResponse === 'error') {
      throw downloadResponse.message || 'Download failure'
    }
    Helper.convertAndDownload(downloadResponse.data, fileName)
    yield put({
      type: types.downloadCategoryListItemSuccess
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e,
        open: true
      })
    )
    yield put({
      type: types.downloadCategoryListItemError,
      payload: e
    })
  }
}

function* downloadSaga() {
  try {
    const fileName = 'CarCategoryData.xlsx'
    const downloadResponse = yield CategoryApi.downloadCategoryFile()

    if (downloadResponse.error || downloadResponse === 'error') {
      throw downloadResponse.message || 'Download failure'
    }
    Helper.convertAndDownload(downloadResponse.data, fileName)
    yield put({
      type: types.downloadCategoryDataSuccess
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e,
        open: true
      })
    )

    yield put({
      type: types.downloadCategoryDataError,
      payload: e
    })
  }
}

function* uploadCategoryDataSaga(action) {
  const defaultErrorMsg = 'Upload failure'

  try {
    const file = action.payload
    const uploadResponse = yield CategoryApi.uploadCategoryFile(file)

    const error = getErrorFromResponse(uploadResponse)
    if (error) {
      throw error
    }

    yield put({
      type: types.uploadCategoryDataSuccess
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'Upload successful',
        open: true
      })
    )
  } catch (e) {
    yield put({
      type: types.uploadCategoryDataError,
      payload: e
    })
    if (Array.isArray(e) && e.length) {
      yield put(
        global.apiError({
          errorType: 'category',
          errors: e.map((e) => ({message: e}))
        })
      )
    } else {
      yield put(
        showSnackbarNotification({
          variant: signalTypes.variantTypes.error,
          message: e,
          open: true
        })
      )
    }
  }
}

export const sagas = [
  takeLatest(types.fetchCategoryList, fetchCategoryListSaga),
  takeLatest(types.downloadCategoryListItem, downloadItemSaga),
  takeLatest(types.downloadCategoryData, downloadSaga),
  takeLatest(types.uploadCategoryData, uploadCategoryDataSaga)
]
