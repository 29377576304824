import React from "react";

export const No_Available_Notifications_Image = (props: { svgClass?: string }) => {
    const { svgClass } = props;
    return (
        <svg width="111" height="114" viewBox="0 0 111 114" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg" data-testid="No-Available-Notifications-Image">
            <path d="M51.0851 114.001C73.3272 114.001 98.0805 105.89 104.179 66.0445C108.365 38.6983 92.6993 7.49416 51.0851 13.8568C18.7983 18.7934 -0.796337 40.5141 3.73106 67.8076C9.1123 100.249 22.9509 114.001 51.0851 114.001Z" fill="#20C3F3" />
            <path d="M33.5368 97.9305C30.4175 93.3673 25.7433 90.3008 20.5706 89.2687C19.1681 89.0122 15.659 88.1408 14.9844 90.0856C14.5649 91.4739 15.7577 92.4245 17.1206 93.0041C16.5984 93.1958 16.0307 93.25 15.5084 93.4417C14.7251 93.7294 13.8171 94.5256 14.181 95.6263C14.4995 96.5893 15.5044 96.5289 16.2481 96.5644C16.8158 96.5102 17.5596 96.5457 18.2579 96.4436C17.7812 96.7729 17.435 97.0543 17.1343 97.4733C16.4874 98.1737 16.1076 99.2389 16.6871 100.106C17.8008 101.703 20.1053 100.702 21.9272 99.5705C21.9331 100.031 21.9845 100.629 22.2515 100.994C22.8766 101.999 24.0575 102.028 24.9259 101.555C26.3166 100.89 26.8606 98.9933 28.6548 99.106C30.0118 99.2249 31.1986 99.7148 32.6348 99.1874L32.7653 99.1395C33.3785 99.2229 33.8948 98.5705 33.5368 97.9305Z" fill="#004BBE" />
            <path d="M39.6896 93.5122C39.0113 88.3613 39.9115 83.5565 42.1865 80.2526C42.7879 79.4146 44.3309 76.9966 45.6601 78.36C46.7224 79.3585 46.5641 80.6509 46.0537 81.764C46.4453 81.6202 46.7065 81.5243 47.0981 81.3805C47.7964 81.2784 48.5857 81.4514 48.9496 82.5521C49.268 83.5152 48.8368 83.9821 48.36 84.3114C48.0139 84.5928 47.6677 84.8742 47.1455 85.066C47.5826 85.0597 47.9347 85.239 48.2413 85.2807C48.9 85.5017 49.6952 86.1355 49.8831 87.1465C50.1679 88.8933 48.3796 89.2414 46.7615 89.2184C47.0286 89.5832 47.2105 90.1335 47.347 90.5462C47.5349 91.5572 47.0186 92.2097 46.1898 92.3597C45.0543 92.4681 43.7706 91.2423 42.9081 92.1762C42.2612 92.8765 41.8813 93.9417 40.7914 94.1876L40.6608 94.2356C40.4452 94.469 39.7865 94.2481 39.6896 93.5122Z" fill="#004BBE" />
            <path d="M29.784 98.5357L28.1364 96.2093C28.1364 96.2093 28.4371 95.7903 29.0444 95.413C29.6516 95.0358 43.0873 89.1767 43.0873 89.1767C43.0873 89.1767 43.564 88.8474 43.922 89.4873C44.28 90.1273 47.065 95.8933 47.065 95.8933L47.2529 96.9043L34.0625 104.833L31.3881 104.272L30.854 103.543L29.784 98.5357Z" fill="#004BBE" />
            <path d="M32.3785 96.195C33.2983 96.3201 34.2636 96.5828 35.1835 96.7079C36.1033 96.833 37.1992 97.0478 38.1586 96.8498C39.3791 96.5559 39.844 95.3052 40.3999 94.3297C40.8252 93.4021 41.296 92.612 41.7213 91.6844C42.1922 90.8944 42.6175 89.9668 43.1338 89.3144C41.039 89.6207 31.7416 94.2688 29.0909 95.5507C30.1413 95.6279 31.2826 95.9802 32.3785 96.195Z" fill="white" />
            <path d="M47.0656 95.8935C45.9401 93.3754 44.4566 90.2172 43.9226 89.4876C43.0205 90.7446 42.4251 92.0432 41.8296 93.3418C41.6595 93.7129 41.4043 94.2694 41.2341 94.6405C43.1193 95.0283 45.0499 95.5537 47.0656 95.8935Z" fill="white" />
            <path d="M40.8086 95.5688C40.2528 96.5444 39.5208 97.4303 38.6069 97.7659C37.5625 98.1494 36.4666 97.9346 35.3707 97.7199C35.3707 97.7199 35.4162 97.8574 35.2856 97.9054C34.2589 99.6709 33.4538 101.664 32.2055 103.202C31.9048 103.621 31.5586 103.902 31.3885 104.273C31.883 105.326 32.2865 106.104 32.5535 106.469C32.9116 107.108 46.2048 100.376 47.8565 99.6152C48.1176 99.5193 47.8843 98.3708 47.2078 96.7677C45.0616 96.4758 42.9549 95.8608 40.8086 95.5688Z" fill="white" />
            <path d="M31.6312 102.794C32.834 101.118 33.5541 99.3108 34.5808 97.5453C33.9676 97.4619 33.4849 97.3305 32.8717 97.2471C31.3387 97.0386 29.7146 96.5549 28.1361 96.2088C28.051 96.3943 28.1025 96.9926 28.3299 97.6805C28.8303 99.1939 29.9558 101.712 30.8538 103.542C31.1999 103.261 31.4156 103.027 31.6312 102.794Z" fill="white" />
            <path d="M57.5929 28.8624C57.5243 20.8222 54.4685 13.1773 49.2374 7.4742C47.8008 5.9552 44.3863 1.88619 42.0757 3.57019C40.4989 4.82632 41.1471 6.97339 42.2844 8.8352C41.5246 8.60495 40.8193 8.1745 40.0595 7.94425C38.9196 7.59887 37.236 7.73895 36.799 9.34045C36.4167 10.7418 37.6372 11.5451 38.4778 12.2333C39.183 12.6637 40.0236 13.3519 40.9188 13.8399C40.1043 13.8098 39.4798 13.8373 38.8007 14.065C37.497 14.3202 36.2194 15.2335 36.217 16.75C36.2667 19.5827 39.7408 20.4186 42.7542 20.6815C42.4003 21.2245 41.9919 21.9677 42.018 22.6258C41.9609 24.3424 43.3168 25.4035 44.7012 25.6062C46.8454 26.0392 48.966 24.2977 50.9725 25.9894C52.4638 27.3082 53.4658 28.9123 55.5554 29.5454L55.7454 29.603C56.396 30.2336 57.5097 29.9209 57.5929 28.8624Z" fill="#004BBE" />
            <path d="M68.2339 29.0526C71.4755 22.4489 76.2891 17.6221 81.5323 15.743C82.8906 15.2876 86.5856 13.8064 87.0699 16.554C87.5281 18.6435 86.3313 20.0148 84.8637 20.8705C85.4336 21.0432 85.8136 21.1583 86.3835 21.331C87.2787 21.819 88.0647 22.7074 87.6277 24.3089C87.2453 25.7102 86.3762 25.8803 85.5617 25.8503C84.9371 25.8778 84.3126 25.9053 83.5527 25.675C84.068 26.0479 84.3387 26.5634 84.664 26.8787C85.26 27.7095 85.6922 29.1409 85.1198 30.4847C84.0844 32.7718 81.7241 31.6231 79.8529 30.1892C79.879 30.8473 79.6605 31.648 79.4966 32.2486C78.9243 33.5923 77.8106 33.9051 76.7254 33.3595C75.3149 32.4987 74.776 29.9512 73.0377 30.2915C71.734 30.5466 70.4564 31.4599 68.9913 30.7993L68.8014 30.7417C68.3668 30.8268 67.7708 29.9959 68.2339 29.0526Z" fill="#004BBE" />
            <path d="M52.7368 26.3058L52.6348 22.1569C52.6348 22.1569 53.314 21.9292 54.3185 22.0168C55.3229 22.1045 75.5973 26.9474 75.5973 26.9474C75.5973 26.9474 76.4118 26.9775 76.3287 28.036C76.2455 29.0945 74.9818 38.2481 74.9818 38.2481L74.4095 39.5919L52.8005 37.3789L50.1173 34.3984L50.0651 33.0822L52.7368 26.3058Z" fill="#004BBE" />
            <path d="M57.5968 25.8292C58.5727 26.7751 59.494 27.9213 60.4699 28.8672C61.4459 29.8132 62.5572 31.0169 63.8323 31.62C65.4874 32.3382 67.0096 31.2823 68.4225 30.6267C69.6455 29.9136 70.8138 29.4007 72.0368 28.6876C73.2051 28.1746 74.4281 27.4615 75.5418 27.1487C72.8561 25.6847 58.3615 23.0266 54.2629 22.2181C55.4288 23.2216 56.4855 24.6255 57.5968 25.8292Z" fill="white" />
            <path d="M74.9811 38.2496C75.6391 34.3309 76.3801 29.3537 76.328 28.0374C74.2905 28.7205 72.5783 29.7189 70.8662 30.7172C70.377 31.0025 69.6432 31.4304 69.1541 31.7156C71.0513 33.8077 72.8939 36.0999 74.9811 38.2496Z" fill="white" />
            <path d="M67.9319 32.4271C66.519 33.0826 64.9707 33.4805 63.6409 33.0775C62.1212 32.617 61.0099 31.4133 59.8986 30.2096C59.8986 30.2096 59.844 30.4098 59.654 30.3523C57.0728 31.5208 54.5722 33.1472 51.9103 33.8577C51.2311 34.0854 50.6066 34.1129 50.1174 34.3982C49.8704 36.0572 49.7326 37.3159 49.7587 37.9741C49.6755 39.0326 70.4676 42.732 72.9918 43.2801C73.3718 43.3952 73.9987 41.8513 74.4642 39.3915C72.1871 37.1843 70.2091 34.6343 67.9319 32.4271Z" fill="white" />
            <path d="M51.5577 32.8838C54.2743 31.973 56.5303 30.4892 59.1115 29.3207C58.4609 28.6901 58.0003 28.117 57.3496 27.4864C55.7231 25.9098 54.2058 23.9329 52.6338 22.1561C52.3892 22.2988 51.9808 23.042 51.7077 24.0429C51.1068 26.245 50.4489 30.1637 50.0641 33.0814C50.6886 33.0539 51.1232 32.9688 51.5577 32.8838Z" fill="white" />
            <path d="M64.5809 69.8706C61.5403 58.384 54.3533 48.206 44.5404 42.0992C41.9144 40.4998 35.2803 35.847 32.6543 39.482C30.7194 42.0992 32.5161 44.7164 35.0039 47.0428C33.8982 46.8974 32.6543 46.752 31.5487 46.752C29.6137 46.752 27.4023 47.6244 27.4023 50.0962C27.4023 52.2772 29.3373 53.0042 30.9958 53.5858C32.3779 54.022 33.6218 54.4582 35.0039 54.749C33.8982 55.0398 32.9307 55.3306 31.9633 55.7668C30.1665 56.6392 28.508 58.384 29.1991 60.565C30.3048 64.6362 35.695 64.3454 40.1177 63.6184C39.703 64.6362 39.5648 65.654 39.8413 66.8172C40.3941 69.289 42.6055 70.3068 44.8168 70.1614C48.1339 69.8706 50.4834 66.5264 53.9387 68.2712C56.7029 69.5798 58.6378 71.6154 61.8167 71.6154C61.9549 71.6154 62.0931 71.6154 62.2313 71.6154C63.337 72.3424 64.9955 71.3246 64.5809 69.8706Z" fill="#004BBE" />
            <path d="M80.1997 65.6541C82.4111 54.6037 87.5248 45.7343 94.5736 40.9361C96.3703 39.6275 101.069 35.9925 103.004 39.9183C104.248 42.6809 103.004 45.1527 101.346 47.0429C102.175 47.0429 103.004 47.0429 103.834 47.1883C105.216 47.4791 106.736 48.6423 106.736 50.9687C106.736 53.1497 105.354 53.5859 104.11 54.0221C103.143 54.3129 102.175 54.4583 101.208 54.7491C101.899 55.1853 102.728 55.6215 103.419 56.2031C104.663 57.2209 105.769 59.2565 105.354 61.2921C104.525 65.0725 100.655 64.2001 97.6142 62.8915C97.8906 63.9093 98.0288 64.9271 97.7524 66.0903C97.3378 68.4167 95.8175 69.1437 94.1589 68.7075C91.8094 67.9805 90.1508 64.4909 87.663 65.6541C85.7281 66.6719 84.2078 68.2713 81.9964 67.9805C81.8582 67.9805 81.8582 67.9805 81.72 67.8351C81.1672 68.2713 79.9233 67.2535 80.1997 65.6541Z" fill="#004BBE" />
            <path d="M56.8421 68.1249L55.1836 62.1635C55.1836 62.1635 56.0129 61.4365 57.5332 61.4365C59.0535 61.2911 90.1508 59.8371 90.1508 59.8371C90.1508 59.8371 91.2565 59.5463 91.5329 61.1457C91.8093 62.7451 93.4678 76.7035 93.4678 76.7035L93.1914 78.7391L60.9884 84.2643L56.0129 81.2109L55.46 79.4661L56.8421 68.1249Z" fill="#004BBE" />
            <path d="M63.614 65.3632C65.2725 66.5264 67.0693 67.5442 68.866 68.562C70.6627 69.7252 72.5977 70.8884 74.6708 71.1792C77.1586 71.47 79.2318 69.4344 81.0285 67.835C82.5488 66.381 84.0691 64.927 85.5894 63.473C86.9715 62.1644 88.4918 60.7104 90.0122 59.6926C85.4512 58.6748 63.614 60.7104 57.3945 61.292C59.4677 62.6006 61.5408 64.0546 63.614 65.3632Z" fill="white" />
            <path d="M93.3278 76.5597C93.0514 70.7437 91.9457 63.183 91.3929 61.002C88.7669 62.7468 86.5555 65.0732 84.3442 67.2542C83.6531 67.9812 82.9621 68.7082 82.1328 69.4352C86.0027 71.6162 89.7344 74.0879 93.3278 76.5597Z" fill="white" />
            <path d="M81.0273 70.8876C81.0273 70.8876 80.8891 70.8876 80.8891 70.7422C79.2306 72.1962 77.2956 73.5048 75.0843 73.3594C72.7347 73.214 70.6615 72.0508 68.5884 70.7422C68.5884 70.8876 68.4502 71.033 68.312 71.033C64.9949 73.7956 62.0925 76.9944 58.3608 79.3208C57.5316 79.9024 56.7023 80.3386 55.873 80.7748C56.1495 83.2466 56.4259 85.2822 56.5641 86.1546C56.8405 87.754 88.4907 84.5552 92.2223 84.4098C92.9134 84.4098 93.0516 81.7926 92.9134 78.303C89.0435 75.8312 85.1736 73.214 81.0273 70.8876Z" fill="white" />
            <path d="M57.3945 78.0135C60.988 75.6871 63.8904 72.4883 67.2074 69.7257C66.1018 68.9987 65.1343 68.2717 64.1668 67.6901C61.1262 65.9453 58.362 63.7643 55.1831 62.0195C54.9067 62.4557 54.6303 63.6189 54.6303 65.2183C54.4921 68.8533 54.9067 74.5239 55.4596 79.1767C56.0124 78.8859 56.7035 78.4497 57.3945 78.0135Z" fill="white" />
        </svg>
    );
};

export const ErrorImageSvg = () => {
    return (
        <svg fill="none" height="239" viewBox="0 0 219 219" width="219" xmlns="http://www.w3.org/2000/svg" data-testid="Notifications-Api-Failure-Image">
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="-7.68303" x2="-7.68303" y1="26.9167" y2="211.387">
                <stop offset="0" stopColor="#fdc40f" />
                <stop offset="1" stopColor="#ffb000" />
            </linearGradient>
            <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="38.8342" x2="119.69" y1="93.6037" y2="249.283">
                <stop offset="0" stopColor="#ffce33" />
                <stop offset="1" stopColor="#ffb000" />
            </linearGradient>
            <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="192.868" x2="192.868" y1="174.021" y2="114.077">
                <stop offset="0" stopColor="#4052b6" />
                <stop offset="1" stopColor="#4f67c1" />
            </linearGradient>
            <g clipRule="evenodd" fillRule="evenodd">
                <path d="m96.3729 54.7303c3.3327-5.5191 11.3371-5.5191 14.6701 0l64.184 106.2937c3.449 5.71-.663 12.997-7.335 12.997h-128.3686c-6.6711 0-10.7833-7.287-7.335-12.997z" fill="url(#a)" />
                <path d="m59.8672 174.022c1.5206-3.017 2.6692-5.555 3.4793-6.897l53.5515-103.3063 58.343 97.1513c3.449 5.743-.687 13.052-7.386 13.052z" fill="url(#b)" />
                <path d="m109.863 107.58h3.903l2.007 2.01 2.008-2.01h3.903v3.911l-2.006 2.008 2.006 2.009v3.911h-3.903l-2.008-2.01-2.007 2.01h-3.903v-3.911l2.006-2.009-2.006-2.008z" fill="#fffbef" />
                <path d="m81.5977 107.58h3.9031l2.0071 2.01 2.007-2.01h3.9032v3.911l-2.0058 2.008 2.0058 2.009v3.911h-3.9032l-2.007-2.01-2.0071 2.01h-3.9031v-3.911l2.0057-2.009-2.0057-2.008z" fill="#fffbef" />
            </g>
            <path d="m81.5977 135.745h39.8652v7.22188h-39.8652z" fill="#fffbef" />
            <ellipse cx="162.787" cy="142.245" fill="url(#c)" rx="31.8922" ry="31.7763" stroke="#fffbef" strokeWidth="7.17967" />
            <path clipRule="evenodd" d="m159.516 143.98v-17.262h6.523v17.262l-3.262 2.959z" fill="#fffbef" fillRule="evenodd" />
            <ellipse cx="162.422" cy="154.883" fill="#fffbef" rx="3.2617" ry="3.24985" />
        </svg>
    );
};