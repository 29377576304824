import {InputAdornment, withStyles, CircularProgress} from '@material-ui/core'
import {Clear, ArrowDropDown, ArrowDropUp} from '@material-ui/icons'
import noop from 'lodash/noop'

export const SelectAdornment = ({
  classes,
  disableClear,
  selectedLabel,
  clear,
  isFocused,
  isFetching,
  disabled
}) => {
  const clearFn = disabled ? noop : clear
  const adornment =
    isFetching === true ? (
      <CircularProgress size={20} />
    ) : selectedLabel.length > 0 && disableClear !== true ? (
      <Clear onClick={clearFn} disabled={disabled} />
    ) : isFocused === false ? (
      <ArrowDropDown disabled={disabled} />
    ) : (
      <ArrowDropUp disabled={disabled} />
    )

  return (
    <InputAdornment disabled={disabled} position='end' classes={classes}>
      {adornment}
    </InputAdornment>
  )
}

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({root: {marginLeft: '1px'}})

export default withStyles(styles, {name: 'SelectAdornment'})(SelectAdornment)
