import * as types from './types'
import * as ContactApi from '../../../api/contact/requests'
import * as AuthApi from '../../../api/auth/requests'
import * as globalActions from '../../global/actions'
import gql from '@fcg/lib-gql/gql'
import moment from 'moment'
import noop from 'lodash/noop'
import {getCountryCode} from '../../config/selectors'

const dueDateFormat = 'YYYY-MM-DD HH:mm:ss'

export const getUserList = () => (dispatch) => {
  return AuthApi.getUserList().then((response) => {
    dispatch(userListLoaded(response.user))

    return true
  })
}

export const getComments = (params = {}) => (dispatch) => {
  dispatch(setFetching())

  return ContactApi.getCommentList(params).then((response) => {
    dispatch(commentsLoaded(response.comment))
  })
}

export const createComment = (input = {}) => (dispatch, getState) => {
  dispatch(setFormPending())
  const params = {...input}
  const country = getCountryCode(getState())

  if (typeof params.status !== 'undefined') {
    params.status = new gql.EnumType(params.status)
  }

  if (typeof params.entity !== 'undefined') {
    params.entity = new gql.EnumType(params.entity)
  }

  if (typeof params.dueDate !== 'undefined') {
    params.dueDate = moment(params.dueDate).format(dueDateFormat)
  }

  return ContactApi.createComment({...params, country})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(
        globalActions.success({
          message: 'global.message.create',
          entity: 'comment'
        })
      )
      dispatch(resetCommentForm())

      return true
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(
        globalActions.error({
          message: 'global.message.create',
          entity: 'comment'
        })
      )
    })
}

export const updateComment = (id, input = {}, next = noop) => (dispatch) => {
  dispatch(setFormPending())
  const params = {...input}

  if (typeof params.status !== 'undefined') {
    params.status = new gql.EnumType(params.status)
  }

  if (typeof params.country !== 'undefined') {
    params.country = new gql.EnumType(params.country)
  }

  if (typeof params.dueDate !== 'undefined') {
    params.dueDate = moment.isMoment(params.dueDate)
      ? params.dueDate.format(dueDateFormat)
      : moment(params.dueDate).format(dueDateFormat)
  }

  return ContactApi.updateComment({id, commentData: params})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(
        globalActions.success({
          message: 'global.message.update',
          entity: 'comment'
        })
      )
      dispatch(resetCommentForm())

      next()
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(
        globalActions.error({
          message: 'global.message.update',
          entity: 'comment'
        })
      )
    })
}

export const postponeTask = (task, next) => (dispatch) => {
  const {id, dueDate} = task
  const nextDate = moment(dueDate).add(1, 'day')
  dispatch(updateComment(id, {dueDate: nextDate}, next))
}

export const deleteComment = (ids = [], next = noop) => (dispatch) => {
  return ContactApi.deleteComment({ids})
    .then(() => {
      dispatch(
        globalActions.success({
          message: 'global.action.delete',
          entity: 'comment'
        })
      )

      next()
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.delete',
          entity: 'comment'
        })
      )
    })
}

export const commentsLoaded = (data) => ({
  type: types.listComments,
  payload: data
})

export const userListLoaded = (data) => ({
  type: types.userListLoaded,
  payload: data.list || []
})

export const updateCommentForm = (payload) => ({
  type: types.updateForm,
  payload
})

export const hydrateCommentForm = (params) => (dispatch) => {
  return ContactApi.getCommentList({commentData: {...params}, limit: 1})
    .then((response) => {
      const comment = response.comment.list

      return dispatch({
        type: types.hydrateForm,
        payload: comment[0]
      })
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}

export const setFetching = () => ({
  type: types.setFetching,
  payload: true
})

export const resetCommentForm = () => ({
  type: types.resetForm,
  payload: true
})

export const setFormError = (payload) => ({
  type: types.formError,
  payload
})

export const setFormSuccess = (payload) => ({
  type: types.formSuccess,
  payload
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})
