const regex = {
  phone: '^([0-9]{10})$'
}
const countryCode = 'MX'
const bpRecordFormConfig = [
  {
    id: 'contacts',
    fields: [
      {
        type: 'select',
        optionsSourceType: 'BP_Contact_Type',
        id: 'type',
        required: true
      },
      {
        type: 'text',
        id: 'phone',
        regex: regex.phone
      },
      {
        type: 'email',
        id: 'email'
      },
      {
        type: 'text',
        id: 'name',
        maxLength: 80
      },
      {
        type: 'date',
        id: 'dateOfBirth',
        max: 'currentDate'
      },
      {
        type: 'text',
        id: 'relationType',
        maxLength: 20
      },
      {
        type: 'select',
        optionsSourceType: 'BP_Gender_Type',
        id: 'gender'
      }
    ]
  },
  {
    id: 'address',
    fields: [
      {
        type: 'select',
        id: 'type',
        optionsSourceType: 'BP_Address_Type',
        required: true
      },
      {
        type: 'text',
        id: 'addressLine',
        maxLength: 160
      },
      {
        type: 'text',
        id: 'city',
        required: true,
        maxLength: 40
      },
      {
        type: 'select',
        id: 'state',
        optionsSourceType: 'BP_Address_State_Type',
        maxLength: 40
      },
      {
        type: 'text',
        id: 'zipCode',
        maxLength: 5,
        minLength: 5,
        required: true
      },
      {
        type: 'text',
        id: 'country',
        required: true,
        readOnly: true,
        defaultValue: countryCode
      },
      {
        type: 'text',
        id: 'phone',
        regex: regex.phone
      },
      {
        type: 'email',
        id: 'email'
      }
    ]
  },
  {
    id: 'taxInfo',
    fields: [
      {
        type: 'select',
        id: 'type',
        optionsSourceType: 'BP_Tax_Type',
        required: true
      },
      {
        type: 'text',
        id: 'taxNumber',
        required: true,
        maxLength: 20
      },
      {
        type: 'text',
        id: 'withHoldingTaxType',
        readOnly: true,
        maxLength: 20
      },
      {
        type: 'text',
        id: 'withHoldingTaxCode',
        readOnly: true,
        maxLength: 20
      },
      {
        type: 'text',
        id: 'withHoldingTaxIdentificationNumber',
        maxLength: 16
      }
    ]
  },
  {
    id: 'bankDetails',
    fields: [
      {
        id: 'accountType',
        type: 'select',
        optionsSourceType: 'BP_Bank_Account_Type',
        maxLength: 40
      },
      {
        type: 'text',
        id: 'accountNumber',
        required: true,
        maxLength: 20
      },
      {
        type: 'text',
        id: 'bankContactDetails',
        maxLength: 60
      },
      {
        type: 'text',
        id: 'accountOwner',
        required: true,
        maxLength: 60
      },
      {
        type: 'text',
        id: 'accountOwnerTaxId',
        maxLength: 20
      },
      {
        type: 'text',
        id: 'bankCode',
        required: true,
        maxLength: 15
      },
      {
        type: 'text',
        id: 'bankName',
        required: true,
        maxLength: 40
      },
      {
        type: 'text',
        id: 'IbanNumber',
        maxLength: 34
      },
      {
        type: 'text',
        id: 'bankCountryKey',
        readOnly: true,
        defaultValue: countryCode
      }
    ]
  },
  {
    id: 'payment',
    inMetaInfo: true,
    contentType: 'form',
    fields: [
      {
        id: 'paymentTerms',
        type: 'select',
        optionsSourceType: 'BP_Payment_Terms'
      },
      {
        id: 'paymentMethods',
        type: 'select',
        optionsSourceType: 'BP_Payment_Method'
      }
    ]
  }
]

const bpAddFormConfig = {
  requiredFields: ['legalName', 'email', 'type'],
  defaultValues: {
    zipCode: '00000'
  }
}

const valueBasedRequiredConditions = {
  'contacts.type.primary': ['contacts.email'],
  'address.type.standard': ['address.email']
}

const bpSearchByOptions = ['legalName', 'phone', 'email']

module.exports = {
  regex,
  bpRecordFormConfig,
  bpAddFormConfig,
  valueBasedRequiredConditions,
  bpSearchByOptions
}
