import {Component} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography, Paper, Collapse, withStyles} from '@material-ui/core'
import {translate} from 'react-i18next'
import DateFormat from '../../../components/Format/DateFormat'
import style from './infoStyle'

const augmentedFieldProps = (props, propName, classes) => {
  if (
    !props.fieldAugment ||
    !props.fieldAugment[propName] ||
    !props.fieldAugment[propName].shouldAugment
  ) {
    return {}
  }

  let newProps = {
    className: classes
  }

  if (props.fieldAugment[propName].onClick) {
    newProps = {
      ...newProps,
      onClick: props.fieldAugment[propName].onClick
    }
  }

  return newProps
}

const UserInfo = ({t, isVisible, name, classes, children}) => {
  if (!isVisible(name)) {
    return null
  }

  return (
    <div data-test={name} className={classes.columnElement}>
      <Typography variant='caption' color='inherit'>
        {t(`contact.${name}.label`)}
      </Typography>
      {children}
    </div>
  )
}

export const UserInfoFull = ({t, ...props}) => {
  return (
    <Paper className={props.classes.full}>
      <Grid container>
        <Grid item xs={12} md={3}>
          <UserInfo
            name='olxUserId'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            {props.olxUserId && (
              <Typography variant='title' color='inherit'>
                {props.olxUserId}
              </Typography>
            )}
          </UserInfo>
          <UserInfo
            name='createdAt'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            {props.createdAt && (
              <DateFormat
                color='inherit'
                data={props.createdAt}
                variant='body1'
                time
              />
            )}
          </UserInfo>
          <UserInfo
            name='updatedAt'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            {props.updatedAt && (
              <DateFormat
                color='inherit'
                data={props.updatedAt}
                variant='body1'
                time
              />
            )}
          </UserInfo>
        </Grid>
        <Grid item xs={12} md={3} className={props.classes.column}>
          <UserInfo
            name='firstname'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography variant='title' color='inherit'>
              {props.firstname}
            </Typography>
          </UserInfo>
          <UserInfo
            name='lastname'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography variant='title' color='inherit'>
              {props.lastname}
            </Typography>
          </UserInfo>
        </Grid>
        <Grid item xs={12} md={3} className={props.classes.column}>
          <UserInfo
            name='phone'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography
              variant='title'
              color='inherit'
              {...augmentedFieldProps(props, 'phone', props.classes.clickable)}
            >
              {props.phone}
            </Typography>
          </UserInfo>
          <UserInfo
            name='email'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography variant='title' color='inherit'>
              {props.email}
            </Typography>
          </UserInfo>
        </Grid>
        <Grid item xs={12} md={3} className={props.classes.column}>
          <UserInfo
            name='phone2'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography variant='title' color='inherit'>
              {props.phone2}
            </Typography>
          </UserInfo>
          <UserInfo
            name='address'
            t={t}
            classes={props.classes}
            isVisible={props.isVisible}
          >
            <Typography variant='title' color='inherit'>
              {props.address}
            </Typography>
          </UserInfo>
        </Grid>
      </Grid>
    </Paper>
  )
}

export const UserInfoSmall = (props) => {
  const {t, classes} = props

  return (
    <Paper className={classes.small}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant='title' className={classes.inlineHeading}>
            {props.firstname} {props.lastname}
          </Typography>
        </Grid>
        {props.isVisible('phone') && (
          <Grid item xs={12} sm={3}>
            <Typography
              variant='body1'
              color='inherit'
              {...augmentedFieldProps(props, 'phone', classes.clickable)}
            >
              {t('contact.phone.label')} {props.phone}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Typography variant='body1' color='inherit'>
            {t('contact.email.label')} {props.email}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export class UserInfoTemplate extends Component {
  static propTypes = {
    showFull: PropTypes.bool,
    created: PropTypes.string,
    updated: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }

  static defaultProps = {
    showFull: true,
    isVisible: () => true
  }

  render() {
    const showFull = this.props.showFull === true && window.innerWidth > 768

    return (
      <div>
        <Collapse in={showFull}>
          <UserInfoFull {...this.props} />
        </Collapse>
        {showFull === false && <UserInfoSmall {...this.props} />}
      </div>
    )
  }
}

export default withStyles(style)(translate()(UserInfoTemplate))
