import {PureComponent} from 'react'
import {withValidation, validatorFields} from '@fcg/formvalidation'
import isObjectLike from 'lodash/isObjectLike'
import isEmpty from 'lodash/isEmpty'
import Select from '../Select/Select'
import errorMessageAdapter from './errorMessageAdapter'
import {translate} from 'react-i18next'
import {empty} from 'ramda'

class ValidatedSelect extends PureComponent {
  onChange = (selected) => {
    this.props.onChange({
      target: {
        value: selected
      }
    })
  }

  getOptions = () => {
    const {options, t} = this.props
    // some components send object instead of array hereso this check is needed so the page doesn't break
    let isArrayFlag = !(
      typeof options === 'object' &&
      !Array.isArray(options) &&
      options !== null
    )
    if (isArrayFlag) {
      return (options || []).map((option) => {
        return {
          ...option,
          // We aren't sure if other countries have added the translation strings
          // in phrase app. If we don't have this check then translation strings
          // will appear as label
          label:
            option.translationKey &&
            t(option.translationKey) !== option.translationKey
              ? t(option.translationKey)
              : option.label
        }
      })
    } else {
      return {}
    }
  }

  render() {
    const selected =
      isObjectLike(this.props.selected) &&
      isEmpty(Object.values(this.props.selected).join(''))
        ? ''
        : this.props.selected

    return (
      <Select
        {...this.props}
        options={this.getOptions()}
        selected={selected}
        onChange={this.onChange}
      />
    )
  }
}

const ValidatedSelectField = withValidation(validatorFields.select)(
  errorMessageAdapter(translate()(ValidatedSelect))
)

ValidatedSelectField.type = 'ValidatedSelectField'

export default ValidatedSelectField
