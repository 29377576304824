import WarehouseManagement from '../../pages/ModulePages/WarehouseManagement'

export default {
  path: 'wms',
  exact: false,
  label: 'WMS',
  key: 'wms',
  component: WarehouseManagement,
  fields: {}
}
