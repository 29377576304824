const ticketFields = [
  'id',
  'title',
  'description',
  'assigneeId',
  'assigneeName',
  'internalId',
  'country',
  'status',
  'type',
  'metaInfo',
  'createdAt',
  'updatedAt',
  'createdById',
  'createdByName',
  'updatedById',
  'updatedByName'
]

const additionalFields = ['ticketEntity', 'ticketComment']

const ticketComment = [
  'id',
  'description',
  'ticketId',
  'createdAt',
  'updatedAt',
  'createdById',
  'createdByName',
  'updatedById',
  'updatedByName'
]

const ticketEntity = [
  'id',
  'ticketId',
  'entityId',
  'entityType',
  'entityInternalId',
  'createdAt',
  'updatedAt',
  'createdById',
  'createdByName',
  'updatedById',
  'updatedByName',
  'status',
  'metaInfo'
]

const extendedTicketFields = [
  ...ticketFields,
  ['ticketComment', ticketComment],
  ['ticketEntity', ticketEntity]
]

const extendedTicketList = ['count', ['list', extendedTicketFields]]
const ticketList = ['count', ['list', ticketFields]]
const ticketCommentList = ['list', ticketComment]
const ticketEntityList = ['list', ticketEntity]

exports.ticketFields = ticketFields
exports.additionalFields = additionalFields
exports.ticketComment = ticketComment
exports.ticketEntity = ticketEntity
exports.extendedTicketFields = extendedTicketFields
exports.ticketList = ticketList
exports.ticketCommentList = ticketCommentList
exports.ticketEntityList = ticketEntityList
exports.extendedTicketList = extendedTicketList
