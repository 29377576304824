import {RenderIf} from '../../../../components/RenderIf'
import {translate} from 'react-i18next'
import styled from 'styled-components'

const DocumentCategoryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.69);
`
const FileName = styled.span`
  font-size: 20px;
`

const DocumentInfoBase = ({currentImage, t}) => (
  <DocumentCategoryWrapper>
    <RenderIf if={currentImage.category}>
      <FileName>{t(currentImage.category)}</FileName>
    </RenderIf>
  </DocumentCategoryWrapper>
)

export const DocumentInfo = translate()(DocumentInfoBase)
