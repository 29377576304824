import {PureComponent, Fragment} from 'react'
import {withValidation, validatorFields} from '@fcg/formvalidation'
import {TextField} from '@material-ui/core'
import errorMessageAdapter from './errorMessageAdapter'
import styled from 'styled-components'

const CounterWrapper = styled.span`
  float: right;
  display: block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
`

class ValidatedTextFiledWithCounter extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      text: ''
    }
  }

  onChange = (e) => {
    this.setState({
      text: e.target.value
    })

    this.props.onChange(e)
  }

  render() {
    const {maxLength = 250} = this.props

    return (
      <Fragment>
        <TextField {...this.props} onChange={this.onChange} />
        <CounterWrapper>
          {this.state.text.length} / {maxLength}
        </CounterWrapper>
      </Fragment>
    )
  }
}

const ValidatedTextFieldWithCounter = withValidation(validatorFields.input)(
  errorMessageAdapter(ValidatedTextFiledWithCounter)
)

ValidatedTextFieldWithCounter.type = 'ValidatedTextField'

export default ValidatedTextFieldWithCounter
