import * as types from './types'

const reducers = {
  [types.groups_fetching]: (state, action) => ({
    ...state,
    loading: action.payload
  }),
  [types.groups_loaded]: (state, action) => ({
    ...state,
    loading: false,
    groups: action.payload
  }),
  [types.groups_addNew]: (state, action) => ({
    ...state,
    groups: [action.payload, ...state.groups]
  }),
  [types.users_fetching]: (state, action) => ({
    ...state,
    loadingUsers: action.payload
  }),
  [types.users_loaded]: (state, action) => ({
    ...state,
    users: action.payload,
    loadingUsers: false
  }),
  [types.permissions_fetching]: (state, action) => ({
    ...state,
    loadingPermissions: action.payload
  }),
  [types.permissions_loaded]: (state, action) => ({
    ...state,
    loadingPermissions: false,
    permissions: action.payload
  }),
  [types.rolePermissions_fetching]: (state, action) => ({
    ...state,
    loadingRolePermissions: action.payload
  }),
  [types.rolePermissions_loaded]: (state, action) => ({
    ...state,
    rolePermissionsLoading: false,
    rolePermissions: action.payload.list,
    rolePermissionsOriginal: action.payload.initialLoad
      ? action.payload.list
      : state.rolePermissionsOriginal,
    loadingRolePermissions: false
  }),
  [types.groups_setSelected]: (state, action) => ({
    ...state,
    selected: action.payload
  }),
  [types.groups_resetSelected]: (state) => ({
    ...state,
    selected: -1
  }),
  [types.rolePermissions_reset]: (state) => ({
    ...state,
    rolePermissionsOriginal: [],
    rolePermissions: []
  })
}

export default reducers
