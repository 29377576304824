import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {SlideUp} from '../../../components/Animations/Slides'
import Assign from './Assign'
import dialogStyles from '../common/dialogStyles'

import {
  hydrateLeadForm,
  bulkLoadLeads,
  updateLeadForm,
  resetLeadForm,
  updateLead
} from '../../../store/crm/lead/actions'
import {loadOptions} from '../../../store/crm/actions'

export class AssignmentDialog extends Component {
  state = {
    leads: [],
    users: {options: [], count: 0}
  }

  componentWillUnmount() {
    this.props.reset()
  }

  componentDidUpdate(prevProps) {
    const {isOpen, leadIds} = this.props

    if (leadIds.length !== prevProps.leadIds.length && isOpen === true) {
      this.populateLeads()
    }
  }

  populateLeads = async () => {
    await this.props.loadBulkData(this.props.leadIds)
    this.setState({leads: this.props.bulkForms})
  }

  handleCancel = () => {
    this.props.reset()
    this.props.handleClose()
  }

  handleSubmit = async () => {
    const {handleSubmit, data} = this.props
    const {leads} = this.state
    const keys = [...Object.keys(data), 'id']

    await Promise.all(
      leads.map(async ({id, ...data}) => {
        await this.props.save({data: pick(data, keys), id})
      })
    )

    handleSubmit()
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  render() {
    const {t} = this.props
    const dialogTitle = `${t('global.action.update')} ${t('entity.lead')}`
    const buttonText = t('global.action.update')

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth='sm'
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={SlideUp}
        >
          <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Assign
              {...this.props}
              users={this.state.users}
              setUserOptions={this.setUserOptions}
              leads={this.state.leads}
              onUpdateLeads={(leads) => this.setState({leads})}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color='primary'>
              {t('button.cancel')}
            </Button>
            <Button
              variant='raised'
              onClick={this.handleSubmit}
              color='primary'
            >
              {buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({lead, crm}) => {
  const data = omit(lead.form, ['contactId'])
  const {options} = crm
  const bulkForms = lead.bulkForms

  return {data, options, bulkForms}
}

const mapDispatchToProps = (dispatch) => ({
  loadOptions: () => dispatch(loadOptions()),
  update: ({name, value}) => dispatch(updateLeadForm({name, value})),
  save: (params) => dispatch(updateLead(params)),
  reset: () => dispatch(resetLeadForm()),
  loadData: ({id}) => dispatch(hydrateLeadForm({id})),
  loadBulkData: (leads) => dispatch(bulkLoadLeads(leads))
})

const LeadForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(AssignmentDialog))

export default withMobileDialog()(withStyles(dialogStyles)(LeadForm))
