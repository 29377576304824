import {Component} from 'react'

import {withStyles} from '@material-ui/core'
import EmptyState from './EmptyState'
import {RichTable} from '@fcg/tars'
import Store from '../utils/localStorage'
import noop from 'lodash/noop'

import Format from './Format/Format'
import Currency from './Format/Currency'
import DateFormat from './Format/DateFormat'
import NumberFormat from './Format/NumberFormat'
import Calendar from './Pickers/Calendar'
import DatePicker from './Pickers/DatePicker'
import withRoles from './providers/withRoles'
import {NumberWithConfig} from './FilterInput/NumberFilterWithConfig'

const extraComponents = {
  Calendar,
  DateFormat,
  DatePicker,
  Format,
  NumberFormat,
  NumberWithConfig,
  withRoles,
  Currency
}

const style = (theme) => ({
  tableFooter: {
    boxShadow: `0 -3px 3px #eae9e9`,
    fontSize: theme.typography.body1.fontSize,
    height: `${theme.mixins.toolbar.minHeight}px`
  }
})

export class PanelTableBase extends Component {
  state = {savedStates: []}
  storage = null
  static defaultProps = {
    toggleSort: noop,
    fetchData: noop,
    updateSort: noop
  }

  componentDidMount() {
    if (
      this.storage === null &&
      this.props.config &&
      typeof this.props.config.label !== 'undefined'
    ) {
      this.initializeStorage()
    }
  }

  initializeStorage = () => {
    this.storage = new Store(this.props.config.label)
    this.storage.retrieve({name: 'saved_filters'}).then((savedStates) => {
      if (savedStates !== null) {
        this.setState({savedStates})
      }
    })
  }

  handleSortChange = (sort) => {
    this.props.updateSort({sort})
  }

  handleSortToggle = (sort) => {
    this.props.toggleSort({sort})
  }

  handlePageChange = (page) => {
    this.props.fetchData({page})
  }

  handleCountChange = (limit) => {
    this.props.fetchData({limit})
  }

  handleFieldChange = (field) => {
    return this.props.toggleField(field)
  }

  handleStateSave = ({filter}) => {
    const savedStates =
      typeof this.state.savedStates !== 'undefined'
        ? this.state.savedStates
        : []

    savedStates.push(filter)
    this.storage
      .save({name: 'saved_filters', value: savedStates})
      .then(() => this.setState({savedStates}))
  }

  handleFilterRemove = (filter) => {
    const savedStates = this.state.savedStates.filter(
      (saved) => saved.id !== filter.id
    )

    this.storage
      .save({name: 'saved_filters', value: savedStates})
      .then(() => this.setState({savedStates}))
  }

  handleStateChange = ({filters, sort}) => {
    this.props.fetchData({filters, sort})
  }

  render() {
    const {fields, error} = this.props

    if (error === true || fields.length === 0) {
      return <EmptyState />
    }

    return (
      <RichTable
        nik_id='panel-table'
        {...this.props}
        hasSave={typeof this.props.config !== 'undefined'}
        presets={this.props.presets}
        savedStates={this.state.savedStates}
        onStateSave={this.handleStateSave}
        onStateChange={this.handleStateChange}
        onSavedFilterRemove={this.handleFilterRemove}
        className={this.props.classes.table}
        handleDataChange={this.props.fetchData}
        handleChangeFields={this.handleFieldChange}
        handleCountChange={this.handleCountChange}
        handlePageChange={this.handlePageChange}
        handleSortChange={this.handleSortChange}
        handleSortToggle={this.handleSortToggle}
        extraComponents={extraComponents}
      />
    )
  }
}

export default withStyles(style)(PanelTableBase)
