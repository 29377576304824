import * as types from './types'
import {getInitialState, carDataSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  [types.fetchCarDataList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchCarDataListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    carDataList: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchCarDataListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.resetCarData]: (state) => ({
    ...state,
    data: {...carDataSchema}
  }),
  [types.resetError]: (state) => ({
    ...state,
    error: null
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, filters: action.payload}
  }),
  [types.updateCardDataField]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      [action.payload.name]: action.payload.value
    }
  }),
  [types.updateCarData]: (state) => ({
    ...state,
    isLoading: true
  }),
  [types.updateCarDataSuccess]: (state) => ({
    ...state,
    isFetchingList: true,
    isLoading: false,
    form: {},
    showCreateEditDialog: false
  }),
  [types.updateCarDataError]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  [types.createCarDataSuccess]: (state) => ({
    ...state,
    isLoading: false,
    isFetchingList: true,
    form: {},
    showCreateEditDialog: false
  }),
  [types.createCarDataError]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  [types.openCreateDialog]: (state) => ({
    ...state,
    edit: false,
    showCreateEditDialog: true
  }),
  [types.openEditDialog]: (state, action) => ({
    ...state,
    showCreateEditDialog: true,
    edit: true,
    form: {
      ...state.form,
      ...action.payload
    }
  }),
  [types.hideCreateEditDialog]: (state) => ({
    ...state,
    edit: false,
    showCreateEditDialog: false,
    form: {}
  }),
  [types.uploadCarData]: (state) => ({
    ...state,
    isUploadingCarData: true,
    uploadError: null
  }),
  [types.uploadCarDataSuccess]: (state) => ({
    ...state,
    isUploadingCarData: false,
    error: null
  }),
  [types.uploadCarDataError]: (state, action) => ({
    ...state,
    isUploadingCarData: false,
    uploadError: action.payload
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: carDataSchema
  })
}
