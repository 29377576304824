import {Component, Fragment} from 'react'
import {Route, Link} from 'react-router-dom'
import {connect} from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'

import LoadingState from '@components/LoadingState'
import ActionButton from '@components/layout/ActionButton'
import EmptyState from '@components/EmptyState'
import {GroupList} from './GroupsList'
import {GroupUsers} from './GroupUsers'
import Editor from './Editor'
import ConfirmDelete from './ConfirmDelete'

import {
  loadAllRoles,
  loadGroupUsers,
  addUserToRole,
  removeUserFromRole,
  setSelectedGroup,
  resetSelectedGroup,
  deleteUserGroup
} from '@store/financingUserGroups/actions'

class List extends Component {
  state = {
    isEditVisible: false,
    selected: -1,
    showConfirmDelete: false,
    deleteId: null
  }

  componentDidMount() {
    this.props.loadList()
  }

  onGroupItemClick = (id) => {
    this.props.history.push(`${this.props.match.url}/group/${id}`)
  }

  initiateDelete = (id, e) => {
    e.stopPropagation()

    this.setState({
      showConfirmDelete: true,
      deleteId: id
    })
  }

  cancelDelete = () => {
    this.setState({
      showConfirmDelete: false,
      deleteId: null
    })
  }

  handleDeleteGroup = () => {
    if (
      this.props.selected === this.state.deleteId ||
      this.props.list.length === 1
    ) {
      this.props.handleResetSelectedGroup()
      this.props.history.replace(`${this.props.match.url}`)
    }

    this.props.handleDeleteGroup(this.state.deleteId)
    this.setState({
      deleteId: null,
      showConfirmDelete: false
    })
  }

  getSelectedRoleName = (id) => {
    if (id) {
      const selectedRole = this.props.list.find((item) => item.id === id)

      if (selectedRole) {
        return selectedRole.name
      }
    }

    return ''
  }

  onGroupEdit = (id) => {
    this.props.history.push(`${this.props.match.url}/group/${id}/edit`)
  }

  onCloseGroupEdit = (id) => {
    if (id) {
      this.props.history.replace(`${this.props.match.url}/group/${id}`)
    } else {
      this.props.history.replace(`${this.props.match.url}`)
    }
  }

  render() {
    const {
      className,
      list,
      selected,
      users,
      handleSetSelectedGroup,
      handleResetSelectedGroup,
      loading,
      loadingUsers,
      pageConfig
    } = this.props

    if (loading) {
      return <LoadingState />
    }

    return (
      <div className={className}>
        <Typography variant='title' style={{padding: '20px 0 0 24px'}}>
          Financing User Groups
        </Typography>

        <Grid container direction='row' style={{height: '100%'}}>
          {list.length === 0 ? (
            <EmptyState />
          ) : (
            <Fragment>
              <Grid md={4} sm={4} xs={12} item>
                <GroupList
                  items={list}
                  selected={selected}
                  onClick={this.onGroupItemClick}
                  onDeleteClick={this.initiateDelete}
                />
              </Grid>

              <Grid
                md={8}
                sm={8}
                xs={12}
                item
                style={{padding: '0 24px', height: '100%'}}
              >
                <Route
                  path={`${this.props.match.url}/group/:id`}
                  render={({match}) => (
                    <GroupUsers
                      loading={loadingUsers}
                      setSelectedGroup={handleSetSelectedGroup}
                      resetSelectedGroup={handleResetSelectedGroup}
                      selectedRoleName={this.getSelectedRoleName(
                        match.params.id
                      )}
                      selectedId={match.params.id}
                      users={users}
                      loadUsers={this.props.loadUsers}
                      addUserToRole={this.props.addUserToRole}
                      removeUserFromRole={this.props.removeUserFromRole}
                      onGroupEdit={this.onGroupEdit}
                      tableConfig={pageConfig.fields}
                    />
                  )}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>

        <ActionButton>
          <Button
            variant='fab'
            color='primary'
            component={Link}
            to={`${this.props.match.path}/create`}
          >
            <Add />
          </Button>
        </ActionButton>

        <Route
          exact
          path={`${this.props.match.url}/group/:id/edit`}
          render={({match}) => (
            <Editor
              handleClose={this.onCloseGroupEdit}
              selectedRoleId={match.params.id}
              history={this.props.history}
              isOpen
            />
          )}
        />

        <Route
          exact
          path={`${this.props.match.url}/create`}
          render={() => (
            <Editor
              handleClose={this.onCloseGroupEdit}
              history={this.props.history}
              isOpen
              isCreate
            />
          )}
        />

        {this.state.showConfirmDelete ? (
          <ConfirmDelete
            onCancel={this.cancelDelete}
            onConfirm={this.handleDeleteGroup}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    groups: list,
    loading,
    selected,
    users,
    loadingUsers
  } = state.financingUserGroups

  return {list, loading, selected, users, loadingUsers}
}

const mapDispatchTtoProps = (dispatch) => ({
  loadList: () => dispatch(loadAllRoles()),
  loadUsers: (id) => dispatch(loadGroupUsers(id)),
  addUserToRole: (roleId, userId) => dispatch(addUserToRole(roleId, userId)),
  removeUserFromRole: (roleId, userId) =>
    dispatch(removeUserFromRole(roleId, userId)),
  handleSetSelectedGroup: (id) => dispatch(setSelectedGroup(id)),
  handleResetSelectedGroup: () => dispatch(resetSelectedGroup()),
  handleDeleteGroup: (id) => dispatch(deleteUserGroup(id))
})

export default connect(mapStateToProps, mapDispatchTtoProps)(List)
