import * as types from './types'

const reducers = {
  [types.showSnackbarNotification]: (state, action) => ({
    ...state,
    open: true,
    message: action.payload.message,
    variant: action.payload.variant,
    extras: action.payload.extras
  }),
  [types.closeSnackbarNotification]: (state) => ({
    ...state,
    open: false
  })
}

export default {...reducers}
