import * as types from './types'
import {getInitialState, businessPartnerSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'
import {
  BP_DETAILS_TABS_KEYS,
  SUPPLIER_B2B_TAG
} from './../../pages/BusinessPartners/constants/businessPartners'

function sanitizeBPInfoSections(bpInfoObj) {
  const sanitizedBPInfo = BP_DETAILS_TABS_KEYS.reduce((obj, sectionName) => {
    if (!(sectionName in obj) || !obj[sectionName]) {
      obj[sectionName] = []
    }

    return obj
  }, bpInfoObj)

  if (!('metaInfo' in sanitizedBPInfo)) {
    sanitizedBPInfo.metaInfo = {}
  }

  return sanitizedBPInfo
}

const _isSupplierB2BExit = (tagInfo) => {
  if (Array.isArray(tagInfo) && tagInfo.length > 0) {
    const findResult = tagInfo.find(({tagName}) => tagName === SUPPLIER_B2B_TAG)

    return typeof findResult !== 'undefined'
  }
}

const reducersFunc = {
  [types.fetchBusinessPartnerList]: (state) => ({
    ...state,
    isFetchingList: true,
    businessPartnerList: []
  }),
  [types.fetchBusinessPartnerListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    businessPartnerList: action.payload.list.map((newBp) =>
      sanitizeBPInfoSections(newBp)
    ),
    count: action.payload.count,
    totalItems: action.payload.totalItems,
    totalPages: action.payload.totalPages,
    currentPage: action.payload.currentPage
  }),

  [types.deleteBusinessPartnerSuccess]: (state, action) => {
    const newBpList = state.businessPartnerList.filter(
      ({businessPartnerId}) =>
        businessPartnerId !== action.payload.businessPartnerId
    )

    return {
      ...state,
      businessPartnerList: newBpList,
      count: newBpList.length
    }
  },

  [types.fetchBusinessPartnerListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),

  [types.updateBusinessPartner]: function(state, {payload}) {
    const {businessPartnerId: id, tagInfo} = payload
    const bpIdx = state.businessPartnerList.findIndex(
      ({businessPartnerId}) => id === businessPartnerId
    )

    const sanatizedPayload = _isSupplierB2BExit(tagInfo)
      ? [sanitizeBPInfoSections(payload)]
      : []

    return {
      ...state,
      businessPartnerList: [
        ...state.businessPartnerList.slice(0, bpIdx),
        ...sanatizedPayload,
        ...state.businessPartnerList.slice(bpIdx + 1)
      ]
    }
  }
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: businessPartnerSchema
  })
}
