import {SimpleApiClient} from '@fcg/admin-api-client'
import getQueryString from '../helpers/queryParamUtil'
import { getAdditionalHeaders } from './utils'
import Country from '../types/Country'

export const downloadReservationCSV = (
  countryCode: Country,
  startDate: string,
  endDate: string,
  storeIds: string,
  callBack: (data:{csv: string, status:Number}) => void
) => {
  const params = {
    startDate,
    endDate,
    storeIds
  }

  const downloadClient = new SimpleApiClient('logistics', getAdditionalHeaders(countryCode))
  return downloadClient
    .query(`reservations/reservation-data-csv?${getQueryString(params)}`, '', false, 'GET')
    .then((data:{csv: string, status:Number}) => {
      callBack(data)
    })
}
