import {useCallback, useState, useRef, useContext} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import {translate} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import {ValidatedForm} from '@fcg/formvalidation'
import {ConfigContext} from '../../../../components/providers/withConfig'
import {
  RegexField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import CountryOptionsSelect from '../../../../components/CountryOptionsSelect/CountryOptionsSelect'

export function BPSearchFields({t, onLookUp, disableFields}) {
  const [formData, setFormData] = useState({
    type: null,
    searchType: null,
    searchValue: ''
  })
  const formRef = useRef()

  const handleTextInput = useCallback(
    (evt, name) => {
      const key = name
      const value = evt.target.value

      setFormData({...formData, [key]: value})
    },
    [formData]
  )

  const _lookupClickHandler = useCallback(async () => {
    const success = await formRef.current.validate()
    if (success) {
      onLookUp(formData)
    }
  }, [onLookUp, formData])

  const isSearchTypePhone = formData.searchType === 'phone'

  const {
    validations,
    callingCode,
    disbableCallingCodeInBP,
    bpSearchByOptions
  } = useContext(ConfigContext)

  const getFieldRegex = (_searchType) => {
    if (_searchType === 'phone') {
      return validations.phone
    } else if (_searchType === 'taxNumber') {
      return validations.taxNumber
    }
  }

  const getSearchOptions = (options) =>
    options.map((key) => {
      return {
        value: key,
        label: `businessPartners.${key}.text`
      }
    })

  const getFieldId = () => {
    let fieldId = 'searchValue'
    if (formData.searchType === 'phone') {
      fieldId = 'phoneNumber'
    } else if (formData.searchType === 'taxNumber') {
      fieldId = 'taxNumber'
    }

    return fieldId
  }

  return (
    <div className='searchFields'>
      <ValidatedForm ref={formRef} t={t}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <CountryOptionsSelect
              label={t('businessPartners.type.label')}
              selected={formData.type}
              value={formData.type}
              name='type'
              id='bpSearch-type'
              countryOptionType='businessPartnerType'
              optionLabelPrefix='businessPartners'
              margin='normal'
              required
              onChange={(evt) => handleTextInput(evt, 'type')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ValidatedSelectField
              label={t('businessPartners.searchType.label')}
              selected={formData.searchType}
              value={formData.searchType}
              margin='normal'
              required
              onChange={(evt) => handleTextInput(evt, 'searchType')}
              fullWidth
              options={getSearchOptions(bpSearchByOptions)}
              id='searchBP-searchType'
              name='searchType'
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RegexField
              label={t('businessPartners.searchValue.label')}
              value={formData.searchValue || ''}
              id={getFieldId()}
              InputProps={
                isSearchTypePhone && !disbableCallingCodeInBP
                  ? {
                      startAdornment: (
                        <InputAdornment position='start'>
                          {callingCode}
                        </InputAdornment>
                      )
                    }
                  : null
              }
              margin='normal'
              name='searchValue'
              required
              fullWidth
              maxLength={80}
              regex={getFieldRegex(formData.searchType)}
              onChange={(evt) => handleTextInput(evt, 'searchValue')}
            />
          </Grid>
        </Grid>

        <Button
          className='lookupButton'
          variant='outlined'
          color='primary'
          disabled={disableFields}
          onClick={_lookupClickHandler}
        >
          {t('businessPartners.search.lookup.text')}
        </Button>
      </ValidatedForm>
    </div>
  )
}

BPSearchFields.propTypes = {
  t: PropTypes.func.isRequired,
  onLookUp: PropTypes.func,
  disableFields: PropTypes.bool
}

BPSearchFields.defaultProps = {
  onLookUp: () => {},
  t: () => {}
}

export default translate()(BPSearchFields)
