import Coutry from '../types/Country'
export const getUser = () => {
    try {
        const user = localStorage.getItem('user')
        return user === null ? false : JSON.parse(user)
    } catch (error) {
        return false
    }
}

export const getUserEmail = () => {
    const { user : { email = '' } = {email: ''} } = getUser()
    return email
}

export const getAdditionalHeaders = (countryCode: Coutry) => {
    return {
        'x-panamera-user': getUserEmail() || 'bar',
        'x-panamera-siteCode': `olx${countryCode.toLowerCase()}`,
        'x-increase-timeout': true
    }
}
