import React from 'react'
import ReactDOM from 'react-dom'
import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles'

import Country from './types/Country'
import MainContextProvider from './context/MainContext'
import PageRouter from './pages/PageRouter'

const generateClassName = createGenerateClassName({
  productionPrefix: 'lm'
})

const ResourceManager = ({countryCode}: {countryCode: Country}) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MainContextProvider >
        <PageRouter country={countryCode} />
      </MainContextProvider>
    </StylesProvider>
  )
}

export const renderResourceManagerToDom = (
  id: string,
  countryCode: Country
) => {
  ReactDOM.render(
    <ResourceManager countryCode={countryCode} />,
    document.getElementById(id)
  )
}

export const unmount = (node: React.ReactNode) => {
  ReactDOM.unmountComponentAtNode(node)
}
