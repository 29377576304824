import React from 'react'

import {ImageUpload} from '@fcg/form-builder'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'

const ProfileImageWrapper = styled(ImageUpload)`
  text-align: center;
  .dropzone {
    width: 180px;
    margin: auto;
    outline: none;
    cursor: pointer;
  }

  .image-upload-btn-upload-img {
    color: #fff;
    background-color: #3f51b5;
    font-size: 14px;
    padding: 9px;
    border-radius: 3px;
    text-align: center;
    margin: auto;
    display: inline-block;
  }

  .image-upload-btn-remove-img {
    color: #3f51b5;
    font-size: 14px;
    padding: 9px;
    border-radius: 3px;
    text-align: center;
    margin: 3px auto;
    cursor: pointer;
  }

  img.image-upload-uploaded {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px auto 10px;
  }
`

const AvatarDriver = styled(Avatar)`
  padding: 50px;
  margin: 20px auto 10px;
  svg {
    font-size: 50px;
  }
`

const NoImagePlaceholder = (
  <AvatarDriver>
    <Person />
  </AvatarDriver>
)

const ProfileImage = (props) => (
  <ProfileImageWrapper {...props} NoImagePlaceholder={NoImagePlaceholder} />
)

ProfileImage.type = 'ImageUpload'

export default ProfileImage
