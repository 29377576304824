import {Component} from 'react'
import {translate} from 'react-i18next'

import FilterSpecification from './FilterSpecification'
import FilterOptions from './FilterOptions'
import FilterButton from '../FilterButton'
import {Popover} from '@material-ui/core'
import {Add} from '@material-ui/icons'

import omit from 'lodash/omit'

class FilterCreator extends Component {
  state = {isEditor: false, isOpen: false}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.active !== null) {
      this.setState({isEditor: true, isOpen: true})
    }
  }

  componentDidCatch(e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  toggle = () => this.setState({isOpen: !this.state.isOpen})

  close = () => {
    this.props.onClose()
    this.setState({isEditor: false, isOpen: false})
  }

  reset = () => {
    this.props.onClose()
    this.setState({isEditor: false})
  }

  selectOption = (option) => {
    option.value = typeof option.value === 'undefined' ? '' : option.value

    this.props.onSelect({...option})
  }

  updateValue = (filter, next) => {
    const active = {...this.props.active, ...filter}

    this.props.onSelect({...active}, next)
  }

  handleSave = () => {
    this.props.addFilter({...this.props.active})
    this.close()
  }

  getPosition = () => {
    if (typeof this.container !== 'undefined' && this.container !== null) {
      const rightOffset = this.container.getBoundingClientRect().right
      const boundary = window.innerWidth * 0.75

      return rightOffset >= boundary ? 'right' : 'left'
    }

    return 'left'
  }

  render() {
    const rest = omit(this.props, 't')
    const position = this.getPosition()

    return (
      <div
        className='FilterCreator'
        ref={(el) => {
          this.container = el
        }}
      >
        <FilterButton onClick={this.toggle} data-test='filter-add'>
          <Add className='icon' />
          {this.props.t('button.addFilter')}
        </FilterButton>
        <Popover
          open={this.state.isOpen}
          onClose={this.close}
          anchorEl={this.container}
          anchorReference='anchorEl'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: position
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: position
          }}
          action={({updatePosition}) => updatePosition()}
        >
          <FilterOptions
            {...rest}
            options={this.props.options}
            selectOption={this.selectOption}
            close={this.close}
            show={this.props.active === null}
          />
          <FilterSpecification
            {...rest}
            close={this.close}
            reset={this.reset}
            updateValue={this.updateValue}
            handleSave={this.handleSave}
            active={this.props.active}
            show={this.props.active !== null}
          />
        </Popover>
      </div>
    )
  }
}

export default translate()(FilterCreator)
