const prefix = 'CALENDAR/'

export const fetchAppointmentSlots = `${prefix}FETCH_APPOINTMENT`
export const fetchAppointmentSlotsSuccess = `${prefix}FETCH_APPOINTMENT_SUCCESS`
export const fetchAppointmentSlotsError = `${prefix}FETCH_APPOINTMENT_ERROR`

// Legend icon and popup
export const toggleVisibility = `${prefix}CHANGE_VISIBILITY`

// Location options - filter
export const fetchLocationOptions = `${prefix}fetchLocationOptions`
export const fetchLocationOptionsSuccess = `${prefix}fetchLocationOptionsSuccess`
export const fetchLocationOptionsError = `${prefix}fetchLocationOptionsError`

export const onFilterChange = `${prefix}FILTER_CHANGE`
export const onSavedFilterChange = `${prefix}SAVED_FILTER_CHANGE`

export const selectAppointment = `${prefix}SELECT_APPOINTMENT`
export const selectedAppointmentLoading = `${prefix}SELECTED_APPOINTMENT_LOADING`
export const selectedAppointmentError = `${prefix}SELECTED_APPOINTMENT_ERROR`

export const setCalendarId = `${prefix}SET_CALENDAR_ID`

export const setRefreshInterval = `${prefix}SET_REFRESH_INTERVAL`
