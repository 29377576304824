import {Component, Fragment} from 'react'
import PanelTableBase from '../../../components/PanelTableBase'
import Panel from '../../CRM/common/Panel'
import {Delete, Edit} from '@material-ui/icons'
import {withStyles, Button} from '@material-ui/core'
import {DeleteDialog} from '../../CRM/common'
import UserPicker from '../../../components/Pickers/UserPicker'
import LoadingState from '../../../components/LoadingState'
import {translate} from 'react-i18next'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  entity: null
}

class Users extends Component {
  state = {
    delete: {...deleteInitialState},
    users: {options: [], count: 0},
    page: 1,
    all: {}
  }

  componentDidMount() {
    const {selectedId} = this.props

    this.props.loadUsers(selectedId)
    this.props.setSelectedGroup(selectedId)
  }

  componentWillUnmount() {
    this.props.resetSelectedGroup()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedId !== this.props.selectedId) {
      this.props.setSelectedGroup(nextProps.selectedId)
      this.props.loadUsers(nextProps.selectedId)
    }
  }

  setUserOptions = ({options, count, page, all}) => {
    if (page === 1) {
      options = [...options]
    }

    this.setState({users: {options, count}, page, all})
  }

  onUserSelect = (id) => {
    this.props.addUserToRole(this.props.selectedId, id)
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(this.props.selectedId, id)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  renderPanelActions = () => (
    <Button onClick={() => this.props.onGroupEdit(this.props.selectedId)}>
      <Edit />
    </Button>
  )

  render() {
    const {
      classes,
      users,
      removeUserFromRole,
      selectedRoleName,
      loading,
      tableConfig,
      t
    } = this.props

    return (
      <Panel
        header={`${selectedRoleName} ${t(
          'crmUserGroups.usersAndPermissions.label'
        )}`}
        actionButton={this.renderPanelActions()}
      >
        {loading ? (
          <LoadingState />
        ) : (
          <Fragment>
            <UserPicker
              {...this.state.users}
              inputPlaceholder={t('crmUserGroups.searchUsers.label')}
              fullWidth
              filterable
              classes={classes}
              disableUnderline
              withStartAdornment
              withEndAdornment={false}
              debounce={true}
              selected={[]}
              onLoad={this.setUserOptions}
              onChange={this.onUserSelect}
              id='userPicker-select'
            />
            <PanelTableBase
              noToolbar
              data={users}
              fields={Object.values(tableConfig)}
              hasFooter={false}
              singleSelectActions={[
                {
                  event: (item) => {
                    this.openDeleteDialog({
                      entity: 'user',
                      action: removeUserFromRole,
                      id: item.id
                    })
                  },
                  icon: <Delete />,
                  title: 'global.action.delete'
                }
              ]}
            />
          </Fragment>
        )}

        <DeleteDialog {...this.state.delete} />
      </Panel>
    )
  }
}

export const GroupUsers = withStyles({
  selectInput: {
    background: 'rgb(244, 244, 244)',
    borderRadius: '4px',
    padding: '8px 16px'
  }
})(translate()(Users))
