import SubPermission from './SubPermissions'

const PermissionDetails = ({t, permission, config, data, onChange}) => {
  // If there's no extra permissions to show, return null
  if (!config[permission]) {
    return null
  }

  // AllPermissions initial array
  const allPermissions = []

  // Check if permission object has a `type` key, it's not null and then push it to allPermissions array
  if (config[permission].type && config[permission].type.length) {
    allPermissions.push(
      ...config[permission].type.map((item, i) => (
        <SubPermission
          key={`subPermission_${permission}_${item}_${i}`}
          permission={permission}
          type={item}
          t={t}
          selected={
            (data && data[permission] && data[permission].includes(item)) ||
            false
          }
          onChange={onChange}
        />
      ))
    )
  }

  // Check if permission object has an `extraPermissions` key and it's not null
  if (
    config[permission].extraPermissions &&
    config[permission].extraPermissions.length
  ) {
    // If it's not null, check if the `type` key for each extra permission is an array and then push it to the allPermissions array in the correct structure
    config[permission].extraPermissions.forEach((item, i) => {
      if (Array.isArray(item.type)) {
        allPermissions.push(
          ...item.type.map((extraTypes, index) => (
            <SubPermission
              key={`subPermission_${item.permission}_${extraTypes}_${index}`}
              permission={item.permission}
              type={extraTypes}
              t={t}
              selected={
                (data &&
                  data[item.permission] &&
                  data[item.permission].includes(extraTypes)) ||
                false
              }
              onChange={onChange}
            />
          ))
        )
      } else {
        // If it's not null and it's an array, push it to the allPermissions array in the correct structure
        allPermissions.push(
          <SubPermission
            key={`subPermission_${item.permission}_${item.type}_${i}`}
            permission={item.permission}
            type={item.type}
            t={t}
            selected={
              (data &&
                data[item.permission] &&
                data[item.permission].includes(item.type)) ||
              false
            }
            onChange={onChange}
          />
        )
      }
    })
  }

  // Return new array with all the permissions
  return allPermissions
}

export default PermissionDetails
