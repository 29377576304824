export default (theme) => ({
  full: {
    padding: theme.spacing.unit * 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  small: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  inlineHeading: {
    display: 'inline-block',
    color: 'inherit'
  },
  column: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  },
  columnElement: {
    [theme.breakpoints.down('md')]: {
      flexBasis: 0,
      flexGrow: 1
    }
  },
  clickable: {
    cursor: 'pointer'
  }
})
