import React from 'react'
import GlobalPlaceConfiguration from '../types/GlobalPlaceConfiguration'
import GlobalTemplate from '../types/GlobalTemplate'
import ServicesObject from '../types/ServicesObject'
import ServicesConfigurationObject from '../types/ServicesConfigurationObject'
import HolidayObject from '../types/HolidayObject'

const initialState: GlobalTemplate = {
  globalPlaceConfiguration: null,
  globalHolidays: [],
  globalServices: {},
  globalServicesConfiguration: {}
}

type Action =
  | {type: 'SET_GLOBAL_TEMPLATE'; value: GlobalTemplate}
  | {type: 'SET_GLOBAL_PLACE_CONFIGURATION'; value: GlobalPlaceConfiguration}
  | {type: 'SET_GLOBAL_HOLIDAYS'; value: HolidayObject[]}
  | {type: 'SET_GLOBAL_SERVICES'; value: ServicesObject}
  | {type: 'DELETE_HOLIDAY'; value: string}
  | {type: 'ADD_OR_UPDATE_HOLIDAY'; value: HolidayObject}
  | {type: 'SET_SERVICES_CONFIGURATION'; value: ServicesConfigurationObject}

function reducer(state: GlobalTemplate, action: Action): GlobalTemplate {
  let newHolidays = null
  switch (action.type) {
    case 'SET_GLOBAL_TEMPLATE':
      return action.value
    case 'SET_GLOBAL_PLACE_CONFIGURATION':
      return {...state, globalPlaceConfiguration: action.value}
    case 'SET_GLOBAL_SERVICES':
      return {...state, globalServices: action.value}
    case 'SET_SERVICES_CONFIGURATION':
      return {...state, globalServicesConfiguration: action.value}
    case 'SET_GLOBAL_HOLIDAYS':
      return {...state, globalHolidays: action.value}
    case 'DELETE_HOLIDAY':
      newHolidays = state.globalHolidays.filter(
        (holiday) => holiday.id !== action.value
      )

      return {...state, globalHolidays: newHolidays}
    case 'ADD_OR_UPDATE_HOLIDAY':
      newHolidays = state.globalHolidays.filter(
        (holiday) => holiday.id !== action.value.id
      )
      newHolidays.push(action.value)

      return {...state, globalHolidays: newHolidays}
    default:
      return state
  }
}

type ContextValue = GlobalTemplate & {
  setGlobalTemplate(value: GlobalTemplate): void
  setGlobalPlaceConfiguration(value: GlobalPlaceConfiguration): void
  setGlobalHolidays(value: HolidayObject[]): void
  setGlobalServices(value: ServicesObject): void
  setGlobalServicesConfiguration(value: ServicesConfigurationObject): void
  addOrUpdateGlobalHoliday(value: HolidayObject): void
  deleteGlobalHoliday(value: string): void
}

export const GlobalContext = React.createContext<ContextValue>(null!)
GlobalContext.displayName = 'GlobalContext'

export default ({children}: {children: React.ReactNode}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: ContextValue = {
    globalPlaceConfiguration: state.globalPlaceConfiguration,
    globalHolidays: state.globalHolidays,
    globalServices: state.globalServices,
    globalServicesConfiguration: state.globalServicesConfiguration,
    setGlobalTemplate: (value) =>
      dispatch({
        type: 'SET_GLOBAL_TEMPLATE',
        value: value
      }),
    setGlobalPlaceConfiguration: (value) =>
      dispatch({
        type: 'SET_GLOBAL_PLACE_CONFIGURATION',
        value: value
      }),
    setGlobalHolidays: (value) =>
      dispatch({
        type: 'SET_GLOBAL_HOLIDAYS',
        value: value
      }),
    addOrUpdateGlobalHoliday: (value) =>
      dispatch({
        type: 'ADD_OR_UPDATE_HOLIDAY',
        value: value
      }),
    deleteGlobalHoliday: (value) =>
      dispatch({
        type: 'DELETE_HOLIDAY',
        value: value
      }),
    setGlobalServices: (value) =>
      dispatch({
        type: 'SET_GLOBAL_SERVICES',
        value: value
      }),
    setGlobalServicesConfiguration: (value) =>
      dispatch({
        type: 'SET_SERVICES_CONFIGURATION',
        value: value
      })
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
