import {Component} from 'react'
import {connect} from 'react-redux'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import {translate} from 'react-i18next'
import {ConfigProvider} from '../../../components/providers/withConfig'
import {ValidatedForm} from '@fcg/formvalidation'
import {
  hydrateContactForm,
  createContact,
  updateContact,
  resetContactForm
} from '../../../store/crm/contact/actions'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Typography,
  withMobileDialog
} from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import {SlideUp} from '../../../components/Animations/Slides'
import ContactForm from './Edit'
import styles from '../common/dialogStyles'
import {contactForm} from '../../../config/pages/crm/forms'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {isLoggedInUserDialerAgent} from '../helpers'

function prefixCountryCodeAndZerosInPhoneNumber(
  phoneLength = [10],
  countryCode,
  phoneNumber
) {
  if (
    phoneNumber.length === phoneLength[0] &&
    !phoneNumber.startsWith(countryCode)
  ) {
    return `00${countryCode}${phoneNumber}`
  } else {
    return `00${phoneNumber}`
  }
}

const contactFormRequiredFields = [
  'id',
  'salutation',
  'firstname',
  'lastname',
  'address',
  'phone',
  'phone2',
  'email',
  'smsMarketing',
  'emailMarketing',
  'city',
  'postcode'
]

export class ContactDialog extends Component {
  static displayName = 'Contact Dialog'

  state = {data: {}}

  componentDidMount() {
    const {creation} = this.props

    if (creation !== true && this.getId()) {
      this.props.hydrate({id: this.getId()})
    }

    this.setState({data: this.props.data})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editState.success) {
      this.props.handleSubmit()
    }

    if (this.props.data.id !== nextProps.data.id) {
      this.setState({
        data: {...nextProps.data}
      })
    }
  }

  getId = () => {
    const {match} = this.props

    if (match && match.params && match.params.id) {
      return match.params.id
    }
  }

  submit = () => {
    const {creation, match} = this.props
    const {data} = this.state

    if (creation === true) {
      this.props.create(omit(data, ['id']))
    } else if (match.params.id) {
      this.props.save(match.params.id, omit(data, ['id']))
    } else {
      // eslint-disable-next-line no-console
      console.error('could not save data')
    }
  }

  update = ({name, value}) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  handleClose = () => {
    this.props.handleClose()
    this.props.reset()
  }

  render() {
    const {t, creation, editState, country, isDialerAgent} = this.props
    const dialogTitle =
      !!creation === true
        ? `${t('global.action.create')} ${t('entity.contact')}`
        : `${t('global.action.update')} ${t('entity.contact')}`
    const buttonText =
      !!creation === true
        ? t('global.action.create')
        : t('global.action.update')

    return (
      <div>
        <ConfigProvider country={country}>
          <Dialog
            open={this.props.isOpen}
            onClose={this.handleClose}
            TransitionComponent={SlideUp}
          >
            <ValidatedForm onSubmit={this.submit} t={t}>
              <DialogTitle id='form-dialog-title'>
                <Person />
                {dialogTitle}
              </DialogTitle>
              <DialogContent>
                {editState.error !== false && (
                  <Typography variant='body2' color='error'>
                    {t('form.message.error.occurred')}
                  </Typography>
                )}
                <ContactForm
                  {...this.props}
                  update={this.update}
                  data={this.state.data}
                  options={{
                    isDialerAgent,
                    creation
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color='primary'>
                  {t('button.cancel')}
                </Button>
                <Button
                  disabled={Boolean(editState.pending)}
                  variant='raised'
                  color='primary'
                  type='submit'
                  data-test='button-save'
                >
                  {buttonText}
                </Button>
              </DialogActions>
            </ValidatedForm>
          </Dialog>
        </ConfigProvider>
      </div>
    )
  }
}

const mapStateToProps = (state, {location}) => {
  const {
    contact,
    config: {crmDetails, phone = {}}
  } = state

  const prefilledNumber =
    location && location.state && location.state.phoneNumber
      ? location.state.phoneNumber
      : null
  const data = omit(
    pick(contact.form, contactFormRequiredFields),
    crmDetails.hidden
  )

  if (prefilledNumber) {
    data.phone = prefixCountryCodeAndZerosInPhoneNumber(
      phone.length,
      phone.code || '',
      prefilledNumber
    )
  }
  const country = contact.form.country

  return {
    data,
    editState: contact.editState,
    country,
    isDialerAgent: isLoggedInUserDialerAgent(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  hydrate: ({id}) => dispatch(hydrateContactForm({id})),
  create: (data) => dispatch(createContact({data})),
  save: (id, data) => dispatch(updateContact({id, data})),
  reset: () => dispatch(resetContactForm())
})

const Editor = connect(
  mapStateToProps,
  mapDispatchToProps
)(formWithCountryOptions(contactForm, 'crmDetails')(translate()(ContactDialog)))

export default withMobileDialog()(withStyles(styles)(Editor))
