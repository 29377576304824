import {useState, useEffect, useCallback, Fragment} from 'react'
import {getCountryConfig} from '../../../config/country'
import PhoneCallPopup from '../../pages/CRM/Contact/PhoneCallPopup'
import styled from 'styled-components'
import {connect} from 'react-redux'

const StyledDialerFrameWrapper = styled.div`
  position: fixed;
  left: 10px;
`

function LoadGenesysScript({styles, userEmail}) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  const createScript = useCallback((url) => {
    const scriptId = 'genesys-integration-id'

    if (document.getElementById(scriptId)) {
      setIsScriptLoaded(true)
    } else {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = url
      script.async = true

      script.onload = () => {
        setIsScriptLoaded(true)
      }

      script.onerror = () => {
        console.error('Failed to load genesys script')
      }

      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    const {genesysIntegrationScriptSrc} = getCountryConfig()
    if (typeof genesysIntegrationScriptSrc === 'string') {
      createScript(genesysIntegrationScriptSrc)
    }
  }, [createScript])

  useEffect(() => {
    if (isScriptLoaded && userEmail) {
      initialize()
    }
  }, [isScriptLoaded, userEmail])

  const initialize = useCallback(() => {
    try {
      if (window.GenesysToolbarInit) {
        window.GenesysToolbarInit(userEmail)
      }
    } catch (error) {
      console.log({error})
    }
  }, [])

  if (isScriptLoaded) {
    return (
      <Fragment>
        <PhoneCallPopup />
        <StyledDialerFrameWrapper id='genesysIframeDiv' style={styles} />
      </Fragment>
    )
  }

  return null
}

LoadGenesysScript.defaultProps = {
  styles: {
    display: 'none'
  }
}

const mapStateToProps = (state) => {
  const {authReducer} = state

  return {
    userEmail: authReducer.userInfo ? authReducer.userInfo.email : null
  }
}

export default connect(mapStateToProps)(LoadGenesysScript)
