import React, {useState, useEffect, useContext} from 'react'
import cx from 'classnames'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import {history} from '../../../../history'

import {MainContext} from '../../context/MainContext'
import {ValidationContext} from '../../context/ValidationContext'

import SidebarListItem from './SidebarListItem'
import SearchField from '../Search/SearchField'
import StatusLabel from '../StatusLabel/index'

import {DEFAULT_LOCATIONS} from '../../config/constants'
import {
  scrollElementToTop,
  scrollElementIntoView,
  getTitle
} from '../../config/utils'
import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

import Location from '../../types/Location'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: 'calc(100vh - 64px)',
    position: 'relative',
    overflowY: 'hidden'
  },
  listHeight: {
    height: 'calc(100vh - 234px)',
    overflowY: 'auto'
  },
  search: {
    padding: theme.spacing(3)
  },
  searchIcon: {
    top: 0,
    right: 0,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const Sidebar = () => {
  const classes = useStyles()
  const commonStyles = useCommonStyles()
  const [searchValue, setSearchValue] = useState('')
  const {
    locationList,
    showMaster,
    selectedLocation,
    setSelectedLocation,
    setSelectedLocationById
  } = useContext(MainContext)
  const [filteredList, setFilteredList] = useState<Location[]>(locationList)
  useEffect(() => {
    setFilteredList(locationList)
    if (selectedLocation) {
      setTimeout(() => {
        scrollElementIntoView(selectedLocation.id)
      }, 1000)
    }
  }, [locationList])

  useEffect(() => {
    if (selectedLocation) {
      scrollElementIntoView(selectedLocation.id)
    }
  }, [])

  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    null
  )
  const {dataHasChanged, setRemindSave, remindSave} = useContext(
    ValidationContext
  )
  useEffect(() => {
    if (!remindSave && selectedLocationId) {
      setLocation(selectedLocationId)
      setSelectedLocationId((prevId) => {
        return null
      })
    }
  }, [remindSave])

  useEffect(() => {
    filterLocationList()
  }, [searchValue])

  const filterLocationList = () => {
    const results = locationList.filter((location: Location) =>
      location.location.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredList(results)
  }

  const handleSelect = (id: string) => {
    if (id === selectedLocation!.id) return
    if (dataHasChanged) {
      setRemindSave(true)
      setSelectedLocationId(id)

      return false
    }
    setLocation(id)
    const path = `/location-manager/${id}`
    history.push(path)
  }

  const setLocation = (id: string) => {
    if (id === 'master') {
      setSelectedLocation(DEFAULT_LOCATIONS.master)
    } else {
      setSelectedLocationById(id)
    }
    scrollElementToTop('scrollable-page-content')
  }

  return (
    <Drawer
      variant='permanent'
      classes={{paper: classes.paper}}
      className={commonStyles.smoothScroll}
    >
      <div className={classes.search}>
        <SearchField searchValue={searchValue} onChange={setSearchValue} />
      </div>
      <Divider />
      {showMaster && (
        <SidebarListItem
          id='master'
          selected={selectedLocation?.id === DEFAULT_LOCATIONS.master.id}
          key={DEFAULT_LOCATIONS.master.id}
          onClick={() => handleSelect('master')}
        >
          <Typography variant='h6' className={commonStyles.colorTitle}>
            {getTitle(DEFAULT_LOCATIONS.master)}
          </Typography>
        </SidebarListItem>
      )}
      <List
        className={cx(classes.listHeight, {
          [commonStyles.borderTop]:
            selectedLocation?.id !== DEFAULT_LOCATIONS.master.id
        })}
      >
        {filteredList.map(({id, location, city, active}) => {
          const selected = id === selectedLocation?.id

          return (
            <SidebarListItem
              selected={selected}
              key={id}
              id={id}
              onClick={() => {
                handleSelect(id)
              }}
            >
              <ListItemText primary={location} secondary={city} />
              <StatusLabel
                label={active ? 'Active' : 'Inactive'}
                theme={active ? 'active' : 'inactive'}
              />
            </SidebarListItem>
          )
        })}
        {filteredList.length === 0 && (
          <ListItem key='noResult'>
            <ListItemText
              primary='No results'
              secondary={`We could not find any results for "${searchValue}"`}
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  )
}

export default Sidebar
