import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'

const Edit = ({
  updateField,
  t,
  options,
  pageConfig,
  setUserOptions,
  users,
  data
}) => (
  <ConfigContext.Consumer>
    {(config) => (
      <FormBuilder
        t={t}
        data={data}
        users={users}
        options={options}
        form={pageConfig}
        setUserOptions={setUserOptions}
        onChangeHandlers={{
          updateField
        }}
        config={config}
      />
    )}
  </ConfigContext.Consumer>
)

export default Edit
