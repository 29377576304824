import {Typography} from '@material-ui/core'

export const AppointmentDetail = ({data}) => (
  <Typography variant='body2'>
    {`${data.inspectionPointName || '/'}, ${data.inspectionPointCity || '/'};
      ${data.date || '/'} ${data.time || '/'}`}
  </Typography>
)

export default AppointmentDetail
