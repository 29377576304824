import {Component} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'

import {
  Add,
  Cancel,
  Check,
  Edit,
  Snooze,
  Delete,
  Assignment
} from '@material-ui/icons'

import {getLocationOptions} from '../../store/config/actions'
import {getCountryConfig} from '../../../config/country'

import {
  getData,
  toggleSort,
  setSort,
  updateFilters,
  loadOptions
} from '../../store/crm/actions'
import {deleteLead} from '../../store/crm/lead/actions'
import {deleteAppointment} from '../../store/crm/appointment/actions'
import {updateComment, postponeTask} from '../../store/crm/comment/actions'

import AppointmentEditor from './Appointment/Editor'
import CancelDialog from './Appointment/Cancel'
import LeadEditor from './Lead/Editor'
import CommentEditor from './Comment/Editor'

import {Panel, DeleteDialog} from './common'
import PanelTableBase from '../../components/PanelTableBase'
import './CRM.styl'

import * as taskSelectors from '../../store/crm/task/selectors'
import * as leadSelectors from '../../store/crm/lead/selectors'
import {checkUserPermissions, getUser} from '../../store/auth/selectors'
import {getPhoneLinkConfig} from '../../store/common/selectors'
import {
  addDataToEveryItemWith,
  addPhoneLink,
  isOldEntity,
  orangeColorRow
} from './helpers'
import AppointmentCount from '../../components/AppointmentCount'
import {SubTable} from '@fcg/tars'
import {alwaysTrue, getVisibleActions} from './common/helpers'

const deleteInitialState = {
  open: false,
  entity: null
}

export class Landing extends Component {
  static displayName = 'CRM Home'

  state = {delete: {...deleteInitialState}}

  componentDidMount() {
    this.props.loadOptions()
    this.props.getLeads()
    this.props.getTasks()
  }

  routeBack = () => {
    this.props.history.push('/crm/home')
  }

  handleUpdate = () => {
    this.props.getLeads()
    this.props.getTasks()
    this.routeBack()
  }

  routeLeadEdit = ({id}) => {
    this.props.history.push(`${this.props.match.url}/lead/${id}/edit`)
  }

  routeAppointmentCancel = ({id}) => {
    this.props.history.push(`${this.props.match.url}/appointment/${id}/cancel`)
  }

  routeAppointmentEdit = ({id}) => {
    this.props.history.push(`${this.props.match.url}/appointment/${id}/edit`)
  }

  routeAppointmentCreate = ({id}) => {
    this.props.history.push(`${this.props.match.url}/lead/${id}/appointment`)
  }

  renderLeadEditor = (props) => (
    <LeadEditor
      {...props}
      isOpen
      handleClose={this.routeBack}
      handleSubmit={this.handleUpdate}
    />
  )

  renderAppointmentCreate = (routeProps) => {
    const leadId = routeProps.match.params.leadId

    return (
      <AppointmentEditor
        creation
        isOpen
        leadId={leadId}
        handleClose={this.routeBack}
        handleSubmit={this.handleUpdate}
      />
    )
  }

  renderAppointmentCancel = (routeProps) => (
    <CancelDialog
      {...routeProps}
      isOpen
      id={routeProps.match.params.id}
      handleSubmit={() => {
        this.routeBack()
        this.props.getLeads()
      }}
      handleClose={this.routeBack}
    />
  )

  renderAppointmentEditor = (routeProps) => (
    <AppointmentEditor
      {...routeProps}
      isOpen
      handleCancel={this.routeAppointmentCancel}
      handleSubmit={this.handleUpdate}
      handleClose={this.routeBack}
    />
  )

  renderTaskEditor = (routeProps) => (
    <CommentEditor
      {...routeProps}
      type='task'
      isOpen
      handleSubmit={this.handleUpdate}
      handleClose={this.routeBack}
    />
  )

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action([id])
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  singleSelectMyTasksActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.tasks',
          types: ['READ']
        }),
      action: {
        event: this.routeAssign,
        icon: <Assignment />,
        title: 'button.assign'
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: (item) => {
          this.props.history.push(
            `${this.props.match.url}/task/${item.id}/edit`
          )
        },
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.props.markTaskAsDone,
        icon: <Check />,
        title: 'global.action.markDone',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.props.postponeTask,
        icon: <Snooze />,
        title: 'global.button.postpone',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectMyLeadsActions = [
    {
      test: alwaysTrue,
      action: {
        event: this.routeLeadEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'lead',
            id: item.id,
            action: this.props.deleteLead
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.routeAppointmentCreate,
        icon: <Add />,
        title: 'entity.appointment',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectMyLeadsAppointmentsActions = [
    {
      test: alwaysTrue,
      action: {
        event: this.routeAppointmentEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'appointment',
            id: item.id,
            action: this.props.deleteAppointment
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.routeAppointmentCancel,
        icon: <Cancel />,
        title: 'global.action.cancelAppointment',
        hide: (item) => !isOldEntity(item) || item.cancelledAt !== null
      }
    }
  ]

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  singleSelectTableMyTasksActions = () =>
    getVisibleActions(this.singleSelectMyTasksActions, this.props)

  singleSelectTableMyLeadsActions = () =>
    getVisibleActions(this.singleSelectMyLeadsActions, this.props)

  singleSelectSubTableMyLeadsActions = () =>
    getVisibleActions(this.singleSelectMyLeadsAppointmentsActions, this.props)

  render() {
    let tasks = Object.values(this.props.tasks.data)

    if (this.props.page.phone.canLinkPhone) {
      tasks = addDataToEveryItemWith(
        {
          link: this.props.page.phone.link,
          email: this.props.user.email
        },
        addPhoneLink
      )(tasks)
    }

    const sections = {
      tasks: (
        <section key='tasks' data-test='section-myTasks'>
          <Panel header={`crm.page.myCRM.myTasks.heading`}>
            {typeof this.props.tasks === 'undefined' ? null : (
              <PanelTableBase
                filterable
                multisort
                {...this.props.tasks}
                data={tasks}
                config={{label: 'myCRM_tasks'}}
                fields={Object.values(this.props.tasks.fields)}
                presets={Object.values(this.props.tasks.presets)}
                fetchData={this.props.getTasks}
                onFilterChange={this.props.filterTasks}
                updateSort={this.props.sortTasks}
                toggleSort={this.props.toggleTasksSort}
                singleSelectActions={this.singleSelectTableMyTasksActions()}
                rowStyle={orangeColorRow}
              />
            )}
          </Panel>
        </section>
      ),
      leads: (
        <section key='leads' data-test='section-myLeads'>
          <Panel header={`crm.page.myCRM.myLeads.heading`}>
            {typeof this.props.leads === 'undefined' ? null : (
              <PanelTableBase
                filterable
                multisort
                unselectableAligned
                fields={Object.values(this.props.pageConfig.leadTable.fields)}
                {...this.props.leads}
                data={Object.values(this.props.leads.data)}
                config={{label: 'myCRM_leads'}}
                fetchData={this.props.getLeads}
                onFilterChange={this.props.filterLeads}
                updateSort={this.props.sortLeads}
                toggleSort={this.props.toggleLeadsSort}
                singleSelectActions={this.singleSelectTableMyLeadsActions()}
                subTableRowActions={this.singleSelectSubTableMyLeadsActions()}
                title=''
                LeftActions={AppointmentCount}
                ExpandableComponent={SubTable}
                rowStyle={orangeColorRow}
              />
            )}
          </Panel>
        </section>
      )
    }

    const countrySections = getCountryConfig().crmDetails.landingPage

    return (
      <div className='CRM'>
        {countrySections.map((section) => {
          return sections[section]
        })}
        <Route
          exact
          path={`${this.props.match.url}/lead/:leadId/edit`}
          render={this.renderLeadEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/lead/:leadId/appointment`}
          render={this.renderAppointmentCreate}
        />
        <Route
          exact
          path={`${this.props.match.url}/appointment/:id/edit`}
          render={this.renderAppointmentEditor}
        />
        <Route
          exact
          path={`${this.props.match.url}/appointment/:id/cancel`}
          render={this.renderAppointmentCancel}
        />
        <Route
          exact
          path={`${this.props.match.url}/task/:id/edit`}
          render={this.renderTaskEditor}
        />
        <DeleteDialog {...this.state.delete} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {leads, tasks} = state.crm

  return {
    user: getUser(state),
    page: {
      phone: getPhoneLinkConfig(state)
    },
    leads: {
      ...leads,
      filterFields: leadSelectors.getLeadFilters(state),
      fields: leadSelectors.getLeadFields(state)
    },
    tasks: {
      ...tasks,
      filterFields: taskSelectors.taskFields(state),
      fields: taskSelectors.getTaskFields(state)
    },
    hasPermissions: (permissions) => checkUserPermissions(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => {
  const getTasks = (params) => dispatch(getData.tasks(params))
  const getLeads = (params) => dispatch(getData.leads(params))

  return {
    getLeads,
    getTasks,
    loadOptions: () => {
      dispatch(getLocationOptions())
      dispatch(loadOptions())
    },
    deleteLead: (ids) => dispatch(deleteLead({ids}, getLeads)),
    deleteAppointment: (ids) => dispatch(deleteAppointment({ids}, getLeads)),
    filterLeads: (filter) => dispatch(updateFilters('leads')(filter)),
    filterTasks: (filter) => dispatch(updateFilters('tasks')(filter)),
    toggleLeadsSort: (sort) => dispatch(toggleSort('leads')(sort)),
    sortLeads: (sort) => dispatch(setSort('leads')(sort)),
    toggleTasksSort: (sort) => dispatch(toggleSort('tasks')(sort)),
    sortTasks: (sort) => dispatch(setSort('tasks')(sort)),
    markTaskAsDone: ({id}) => {
      dispatch(updateComment(id, {status: 'CLOSE'}, getTasks))
    },
    postponeTask: (task) => {
      dispatch(postponeTask(task, getTasks))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
