import {PureComponent, createRef} from 'react'
import TableRow from './Row'
import moment from 'moment'

export class TableBody extends PureComponent {
  constructor(props) {
    super(props)

    this.scrollRef = createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.handleScrollToRow()
    }
  }

  handleScrollToRow() {
    this.scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  isCurrentHour = (id) =>
    moment()
      .startOf('hour')
      .format('HH:mm:ss') === id

  shouldShowTimeline = (id) => this.isCurrentHour(id) && !this.props.isLoading

  render() {
    const {rows} = this.props

    return rows.map((row, index) => (
      <div
        ref={row.id === '08:00:00' ? this.scrollRef : null}
        key={`row-${index}`}
      >
        <TableRow
          cells={row.cells}
          rowId={row.id}
          isOddRow={index % 2 !== 0}
          showTimeline={this.shouldShowTimeline(row.id)}
          onCellClick={this.props.onCellClick}
        />
      </div>
    ))
  }
}
