import HelpOutline from '@material-ui/icons/HelpOutline'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

export const AppointmentLegendHeaderIcon = ({
  onClick,
  t,
  className: classes
}) => (
  <Tooltip title={t('header.appointmentCalendar.tooltip')}>
    <Button onClick={onClick} className={classes}>
      <HelpOutline />
    </Button>
  </Tooltip>
)
