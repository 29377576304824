import {SimpleApiClient} from '@fcg/admin-api-client'
import getQueryString from '../helpers/queryParamUtil'
import {getAdditionalHeaders} from './utils'
import Country from '../types/Country'

export const uploadDocument = (
  data: FormData,
  countryCode: Country,
  callback: Function
) => {
  const params = {
    deletedUser: false,
    userClass: 'INTERNAL',
    country: countryCode
  }
const UploadCSVClient = new SimpleApiClient('logistics', getAdditionalHeaders(countryCode))
  return UploadCSVClient.query(
    `rcm/csv-upload?${getQueryString(params)}`,
    data,
    false,
    'POST'
  ).then((response: Response) => callback(response))
}
