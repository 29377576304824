import InspectionReports from '../../pages/InspectionReports/InspectionReports'

export default {
  path: 'inspectionReports',
  label: 'InspectionReports',
  key: 'inspectionReports',
  component: InspectionReports,
  sort: [{field: 'createdAt', order: 'DESC'}],
  limit: 25,
  filters: [],
  page: 1,
  title: 'Inspection Reports',
  fields: {
    id: {
      label: 'reports.column.id',
      key: 'id',
      type: 'string',
      isSortable: true,
      shorten: 8
    },
    purchased: {
      label: 'reports.column.isPurchased',
      key: 'purchased',
      type: 'string',
      show: true
    },
    carDetails: {
      label: 'reports.column.car',
      key: 'carDetails',
      type: 'string',
      show: false
    },
    'report.price': {
      label: 'reports.column.price',
      key: 'report.price',
      type: 'currency'
    },
    'report.email': {
      label: 'reports.column.email',
      key: 'report.email',
      type: 'string'
    },
    customer: {
      label: 'reports.column.customer',
      key: 'customer',
      type: 'composite',
      separator: ' ',
      children: [
        {
          label: 'Title',
          key: 'report.title',
          type: 'string'
        },
        {
          label: 'First name',
          key: 'report.forename',
          type: 'string'
        },
        {
          label: 'Last name',
          key: 'report.surname',
          type: 'string'
        }
      ]
    },
    createdAt: {
      label: 'Created at',
      key: 'createdAt',
      type: 'date',
      isSortable: true
    },
    damages: {
      label: 'Damages',
      key: 'damages',
      type: 'table',
      fields: {
        id: {
          label: 'ID',
          key: 'id',
          type: 'string'
        },
        description: {
          label: 'Description',
          key: 'description',
          type: 'string'
        }
      }
    },
    status: {
      label: 'Status',
      key: 'status',
      type: 'boolean'
    }
  },
  hiddenFields: [
    'customerDetails',
    'carDetails',
    'purchased',
    'photos',
    'comments'
  ]
}
