import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  BannerWrapper,
  BannerBody,
  Icon,
  AdornementPosition,
  AdornementPositioner
} from './styles'
import {green, red, yellow} from './util'

export const AdornementWrapper = ({
  Adornement,
  position,
  size = 'sm',
  onClick
}) => (
  <AdornementPosition size={size} onClick={onClick}>
    <AdornementPositioner position={position} size={size}>
      <Adornement size={size} />
    </AdornementPositioner>
  </AdornementPosition>
)

const Banner = (props) => (
  <BannerWrapper size={props.size}>
    <BannerBody color={props.color} size={props.size}>
      {props.children}
    </BannerBody>
  </BannerWrapper>
)

const InProgressIcon = Icon(AccessTimeIcon)
const ApprovedIcon = Icon(CheckCircleIcon)
const RejectedIcon = Icon(CancelIcon)

/** Complete banner */
export const InProgressBanner = (props) => (
  <Banner {...props} color={yellow}>
    <InProgressIcon size={props.size} />
  </Banner>
)
export const ApprovedBanner = (props) => (
  <Banner {...props} color={green}>
    <ApprovedIcon size={props.size} />
  </Banner>
)
export const RejectedBanner = (props) => (
  <Banner {...props} color={red}>
    <RejectedIcon size={props.size} />
  </Banner>
)
