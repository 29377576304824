export default (theme) => ({
  Paper: {
    paddingLeft: 0,
    color: 'inherit',
    minWidth: '100%',
    width: '100%'
  },
  filterable: {
    borderBottomWidth: '2px',
    position: 'sticky',
    top: '0',
    zIndex: 2,
    backgroundColor: theme.palette.common.white,
    paddingBottom: '5px'
  },
  selectInput: {}
})
