import styled from 'styled-components'
import {translate} from 'react-i18next'

const DEFAULT_COLOR = '#6082e8'

const ColorTile = styled.div`
  width: 12px;
  height: 40px;
  border-radius: 2px;
  background-color: ${(props) => props.color || DEFAULT_COLOR};
`
const Tile = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 10px;
`
const Text = styled.span`
  font-size: 14px;
  height: 100%;
  margin-left: 15px;
`

const LegendTile = ({name, color}) => (
  <Tile>
    <ColorTile color={color} />
    <Text>{name}</Text>
  </Tile>
)

export default translate()(LegendTile)
