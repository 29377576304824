import DriverPage from '../../../pages/Driver/List'
import {StatusFilter} from '../../../pages/Driver/Filters'
import driverDetailsFormConfig from './driverDetailsFormConfig'

export default {
  path: 'drivers',
  label: 'Drivers',
  key: 'drivers',
  sort: [{field: 'firstname', order: 'DESC'}],
  limit: 25,
  filters: [],
  permissions: {
    entity: 'web.admin.ui.car.drivers',
    types: ['READ']
  },
  page: 1,
  fields: {
    internalId: {
      key: 'internalId',
      label: 'driverList.placeholder.internalId',
      type: 'string',
      isSortable: false,
      isFilterable: true
    },
    nameBlock: {
      type: 'composite',
      key: 'name',
      layout: 'inline',
      separator: ' ',
      label: 'driverList.columns.name',
      children: [
        {
          label: 'driverList.placeholder.firstName',
          key: 'firstname',
          type: 'string',
          isFilterable: true,
          isSortable: true
        },
        {
          label: 'driverList.placeholder.lastName',
          key: 'lastname',
          type: 'string',
          isFilterable: true,
          isSortable: true
        }
      ]
    },
    login: {
      label: 'driverList.placeholder.login',
      key: 'email',
      type: 'string',
      isFilterable: true,
      isSortable: true
    },
    phone: {
      label: 'driverList.placeholder.phone',
      key: 'metaInfo.phone',
      type: 'string',
      isSortable: false
    },
    companyName: {
      label: 'driverList.placeholder.companyName',
      key: 'metaInfo.companyName',
      type: 'string',
      isFilterable: true,
      isSortable: false
    },
    isTruckDriver: {
      label: 'driverList.placeholder.isTruckDriver',
      type: 'boolean',
      key: 'driver.isTruckDriver',
      isSortable: false,
      isFilterable: true
    },
    isCarDriver: {
      label: 'driverList.placeholder.isCarDriver',
      type: 'boolean',
      isFilterable: true,
      key: 'driver.isCarDriver',
      isSortable: false
    },
    available: {
      label: 'driverList.placeholder.available',
      type: 'boolean',
      isFilterable: true,
      key: 'driver.isAvailable',
      isSortable: false
    },
    statusBlock: {
      type: 'composite',
      key: 'status',
      separator: '\n',
      layout: 'rows',
      children: [
        {
          label: 'driverList.placeholder.status',
          key: 'metaInfo.status',
          filter: StatusFilter,
          filterName: 'metaInfo.status.include',
          isSortable: false
        },
        {
          type: 'date',
          key: 'createdAt',
          label: 'global.createdAt.label',
          time: true,
          isFilterable: false,
          isSortable: false
        }
      ]
    }
  },
  formConfig: {...driverDetailsFormConfig},
  hiddenFields: [],
  component: DriverPage
}
