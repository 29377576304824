import { SimpleApiClient } from '@fcg/admin-api-client'

type PUTData = {
    status: {
        [key: string]: string
    }
}

const notificationsClient = new SimpleApiClient('notifications')

export const getLast20Notifications = (before: number, after: number) => {
    return notificationsClient.query(`/v1/messages?limit=20&before=${before}&after=${after}`, {}, false, 'get')
}

export const markNotificationsAsReadOrUnread = (data: PUTData) => {
    return notificationsClient.query(`/v1/messages`, data, false, 'put')
}

export const getLastSeen = () => {
    return notificationsClient.query(`/v1/messages/lastseen`, {}, false, 'put')
}