export const carDataFields = [
  'id',
  'price',
  'make',
  'model',
  'trim',
  'year',
  'transmission',
  'minprice',
  'maxprice',
  'minmarketprice',
  'maxmarketprice',
  'minpriceb',
  'maxpriceb',
  'priceb',
  'location',
  'priceText',
  'bodytype',
  'sellable'
]

export const carDataList = [['list', carDataFields], 'count']

export const listName = [['list', ['name']]]

export const year = ['year_list']

export const list = ['list']

export const listNameWithCode = [['list', ['name', 'code']]]

export const quote = ['quote']
