import * as CarApi from '../../api/car/requests'
import * as types from './types'
import {formatParams} from '../utils'
import omit from 'lodash/omit'
import noop from 'lodash/noop'
import {getDifference} from '../../utils/objectOperations'
import {metaSchema} from './initialState'
import {getCountryCode} from '../config/selectors'

export const loadOptions = () => async (dispatch, getState) => {
  const country = getCountryCode(getState())

  try {
    const res = await CarApi.getCountryOptions(
      null,
      {country},
      {
        city: {type: 'locationCity'},
        type: {type: 'locationType'}
      }
    )

    dispatch(setOptions(res))

    return res
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({e})
  }

  return null
}

export const setOptions = (payload) => ({
  type: types.setOptions,
  payload
})

export const getList = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching())

  const state = getState()
  const country = getCountryCode(state)
  const currentState = state.locationManagement.list

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page

  const params = {
    ...formatParams(filters),
    country,
    sort,
    page
  }
  if (limit !== 'NO_LIMIT') {
    params.limit = limit
  }

  try {
    const res = await CarApi.getLocationList(params)

    dispatch(
      setDataLoaded({
        data: res.place,
        filters,
        sort,
        limit,
        page
      })
    )
  } catch (e) {
    dispatch(setError(e))
  }
}

export const setFetching = (payload) => ({
  type: types.fetching,
  payload
})

export const setDataLoaded = (payload) => ({
  type: types.dataLoaded,
  payload
})

export const setError = (payload) => ({
  type: types.fetchError,
  payload
})

export const hydrateLocationForm = (params) => (dispatch) => {
  dispatch(resetLocationForm())

  return CarApi.getLocation({...params})
    .then((location) => {
      const payload = {...location}

      if (
        payload.metaInfo === null ||
        typeof payload.metaInfo === 'undefined'
      ) {
        payload.metaInfo = {...metaSchema}
      }
      dispatch({type: types.hydrateForm, payload})
      dispatch({type: types.setOriginalForm, payload})
    })
    .catch((e) => dispatch({type: types.formError, payload: e}))
}

export const setFormError = () => ({
  type: types.formError,
  payload: true
})

export const setFormSuccess = () => ({
  type: types.formSuccess,
  payload: true
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})

export const updateLocationForm = (payload) => ({
  type: types.updateForm,
  payload
})

export const resetLocationForm = (payload) => ({
  type: types.resetForm,
  payload
})

export const setOriginalForm = (payload) => ({
  type: types.resetOriginalForm,
  payload
})

export const resetOriginalForm = () => ({
  type: types.resetOriginalForm,
  payload: null
})

export const activateLocation = (id, active) => (dispatch) => {
  dispatch(setDeletePending(id))

  CarApi.updateLocation({id, active}).then(() => {
    dispatch(getList())
    dispatch(setDeleteSuccess())
  })
}

export const setDeleteSuccess = (payload) => ({
  type: types.deleteSuccess,
  payload
})

export const setDeletePending = (payload) => ({
  type: types.deletePending,
  payload
})

export const createLocation = (params, next = noop) => (dispatch, getState) => {
  dispatch(setFormPending())

  const state = getState()
  const {form} = state.locationManagement
  const country = getCountryCode(state)
  const data = omit(form, ['createdAt', 'updatedAt'])

  return CarApi.createLocation({...data, country}).then(({id}) => {
    const success = typeof id !== 'undefined'

    if (success === true) {
      dispatch(successHandler(success))
      next()
    }
  })
}

export const updateItemInList = (data) => ({
  type: types.updateListItem,
  payload: data
})

export const deleteItemFromList = (id) => ({
  type: types.deleteListItem,
  payload: id
})

export const updateLocation = (id, next = noop) => (dispatch, getState) => {
  dispatch(setFormPending())
  const {form, defaultForm} = getState().locationManagement
  const data = {...omit(form, ['createdAt', 'updatedAt']), id}
  const metaUpdates = getDifference(defaultForm.metaInfo, form.metaInfo)

  if (metaUpdates === null) {
    delete data.metaInfo
  } else {
    data.metaInfo = metaUpdates
  }

  return CarApi.updateLocation(data).then(({id}) => {
    const success = typeof id !== 'undefined'

    dispatch(successHandler(success))
    dispatch(updateItemInList(data))
    next()
  })
}

const successHandler = (success) => (dispatch) => {
  dispatch(setFormSuccess(success))
}

export const updateFilters = (filters) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(getList())
}

export const toggleSort = (sort) => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(getList())
}

export const setSort = (sort) => (dispatch) => {
  dispatch({
    type: types.setSort,
    payload: sort
  })
  dispatch(getList())
}

export const toggleField = (field) => (dispatch) => {
  dispatch({
    type: types.toggleField,
    payload: field
  })
}
