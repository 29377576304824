import React from "react";
import {
    withStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import TableRow from '@material-ui/core/TableRow';
import TableCellWrapped from "./TableCell";
import { CellVariant } from '../../types'

const getStyledTableRow =(variant: CellVariant)=>withStyles(() =>
        createStyles({
            root: {
                "&:nth-of-type(odd)": {
                    backgroundColor: variant === 'body' ? '#fff' : '',
                },
                "::-webkit-scrollbar": {
                    width: "7px"
                },
                "::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "rgba(0, 0, 0, .5)",
                }
            }
        })
    )(TableRow);

const TableRowWrapped = (props: { cells: Array<String | undefined>, variant: CellVariant }): JSX.Element => {
    const { cells = [], variant = 'body' } = props
    const StyledTableRow= getStyledTableRow(variant)
    return (
        <StyledTableRow>
            {cells.map((cellData, index) => {
                return (
                    <TableCellWrapped key={index} variant={variant} value={cellData} />
                )
            })
            }
        </StyledTableRow>
    )
}

export default TableRowWrapped;
