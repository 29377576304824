// taken from dealer-web
import i18n from 'i18next'

i18n.init({
  fallbackLng: 'en',
  ns: ['admin', 'inspector'],
  defaultNS: 'admin',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
