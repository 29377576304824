import * as pages from './pages/index'

const pageArray = Object.values(pages)
const menuItems = {
  allFields: [
    pageArray.map(({label, path, key, children, permissions}) => {
      const item = {label, path, key, permissions}

      if (children && children.length) {
        item.subItems = children.map(({label, path, key, permissions}) => ({
          label,
          path,
          key,
          permissions
        }))
      }

      return item
    })
  ],
  hiddenFields: [
    'dealerDetail',
    'settings',
    'inspectionReports',
    'dealerCreate',
    'documentConfigEdit',
    'documentConfigCreate',
    'carDataEdit',
    'carDataCreate',
    'dealerEdit',
    'driverEdit',
    'driverCreate',
    'driverDetail',
    'transitReview',
    'transitJobEdit',
    'transitJobDetail',
    'transitJobCreate',
    'walkin',
    'participants'
  ]
}

export default {
  pages,
  options: {},
  menuItems,
  country: {
    selected: 'en-us'
  },
  /* eslint-disable max-len */
  googleKey:
    '142848285869-3m7s8eanu28d1a7omncshm84bosmdr4n.apps.googleusercontent.com',
  currency: '$',
  appTitle: 'Admin App v.2'
}
