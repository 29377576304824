import React from 'react'
import cx from 'classnames'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import NoDataIcon from './NoDataIcon'

export default function NoData({
  buttonText,
  onClick
}: {
  buttonText?: string
  onClick?: () => void
}) {
  const classes = useCommonStyles()

  return (
    <div
      className={cx(
        classes.fullWidth,
        classes.textCenter,
        classes.flex,
        classes.flexColumn,
        classes.paddingY2
      )}
    >
      <div className={cx(classes.marginXAuto, classes.fullWidth)}>
        <NoDataIcon />
      </div>
      <Typography
        variant='h6'
        gutterBottom
        align='center'
        className={classes.colorTitle}
      >
        There is nothing here yet…
      </Typography>
      <Typography
        variant='subtitle1'
        gutterBottom
        align='center'
        className={classes.colorTitle}
      >
        But that can change! Start creating the first piece of data right now.
      </Typography>
      {buttonText && (
        <div className={classes.justifyCenter}>
          <Button
            onClick={onClick}
            variant='contained'
            color='primary'
            data-test='no-data'
            startIcon={<AddIcon />}
          >
            {buttonText.toUpperCase()}
          </Button>
        </div>
      )}
    </div>
  )
}
