export const countryOptionSchema = {
  name: null
}

const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  selectedWebhookId: null,
  initialData: {...countryOptionSchema},
  countryOptionList: [],
  count: null,
  list: {
    page: 0,
    limit: 25,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: []
  }
}

export const getInitialState = (config) => ({
  options: {},
  form: {...countryOptionSchema},
  ...state,
  list: {
    ...state.list,
    ...config,
    fields: Object.values(config.fields)
  }
})
