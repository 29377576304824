export const FileIcon = () => (
  <svg width='55' height='53' viewBox='0 0 55 53'>
    <g fill='#DCDCDC' fillRule='nonzero' transform='translate(-.452)'>
      <path d='M11.91 53h32.084c6.328 0 11.458-5.158 11.458-11.522v-2.304a2.298 2.298 0 0 0-2.292-2.304h-9.166V6.913C43.994 3.095 40.916 0 37.119 0H7.327C3.53 0 .452 3.095.452 6.913v34.565C.452 47.842 5.582 53 11.91 53zm32.084-4.609H21.077a11.448 11.448 0 0 0 2.292-6.913h27.5c0 3.818-3.078 6.913-6.875 6.913zM5.035 6.913a2.298 2.298 0 0 1 2.292-2.304H37.12a2.298 2.298 0 0 1 2.291 2.304V36.87H21.077a2.298 2.298 0 0 0-2.292 2.304v2.304c0 3.818-3.078 6.913-6.875 6.913s-6.875-3.095-6.875-6.913V6.913z' />
      <path d='M12.811 25.545a2.334 2.334 0 1 0 2.077 4.178l1.54-.77a12.7 12.7 0 0 1 11.176 0l1.54.77a2.333 2.333 0 0 0 2.077-4.178l-1.528-.77a17.493 17.493 0 0 0-15.353 0l-1.529.77z' />
      <circle cx='14.952' cy='15.5' r='3.5' />
      <circle cx='28.952' cy='15.5' r='3.5' />
    </g>
  </svg>
)
