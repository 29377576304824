import moment from 'moment'
const format = 'D MMM YYYY'

export const today = () => {
  const formatted = moment().format(format)

  return {
    composite: false,
    title: `Today: ${formatted}`,
    label: 'calendar.range.today',
    name: 'today',
    start: {
      timestamp: moment().startOf('day')
    },
    end: {
      timestamp: moment().endOf('day')
    },
    text: formatted
  }
}

export const yesterday = () => {
  const formatted = moment()
    .subtract(1, 'day')
    .format(format)

  return {
    composite: false,
    title: `Yesterday: ${formatted}`,
    label: 'calendar.range.yesterday',
    name: 'yesterday',
    subtitle: "Does not include today's data",
    start: {
      timestamp: moment()
        .subtract(1, 'day')
        .startOf('day')
    },
    end: {
      timestamp: moment()
        .subtract(1, 'day')
        .endOf('day')
    },
    text: formatted
  }
}

export const last7Days = () => {
  const endDate = moment()
    .subtract(1, 'd')
    .endOf('day')
  const startDate = moment(endDate)
    .subtract(7, 'd')
    .startOf('day')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `Last 7 days: ${formatted}`,
    label: 'calendar.range.lastDays',
    labelRender: (t) => t('calendar.range.lastDays').replace('%dayNum', '7'),
    name: '7',
    subtitle: "Does not include today's data",
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}

export const last14Days = () => {
  const endDate = moment()
    .subtract(1, 'day')
    .endOf('day')
  const startDate = moment(endDate)
    .subtract(14, 'day')
    .startOf('day')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `Last 14 days: ${formatted}`,
    label: 'calendar.range.lastDays',
    labelRender: (t) => t('calendar.range.lastDays').replace('%dayNum', '14'),
    name: '14',
    subtitle: "Does not include today's data",
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}

export const thisWeek = () => {
  const startDate = moment().startOf('week')
  const endDate = moment().endOf('week')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `This week: ${formatted}`,
    name: 'thisWeek',
    label: 'calendar.range.thisWeek',
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}

export const thisMonth = () => {
  const startDate = moment().startOf('month')
  const endDate = moment().endOf('month')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `This month: ${formatted}`,
    name: 'thisMonth',
    label: 'calendar.range.thisMonth',
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}

export const lastMonth = () => {
  const startDate = moment()
    .subtract(1, 'month')
    .startOf('month')
  const endDate = moment(startDate).endOf('month')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `Last month: ${formatted}`,
    name: 'lastMonth',
    label: 'calendar.range.lastMonth',
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}

export const last30Days = () => {
  const endDate = moment().subtract(1, 'd')
  const startDate = moment(endDate).subtract(30, 'd')
  const formatted = `${startDate.format(format)} - ${endDate.format(format)}`

  return {
    composite: true,
    title: `Last 30 Days: ${formatted}`,
    name: '30',
    label: 'calendar.range.lastDays',
    labelRender: (t) => t('calendar.range.lastDays').replace('%dayNum', '30'),
    subtitle: "Does not include today's data",
    start: {
      timestamp: startDate,
      text: startDate.format(format)
    },
    end: {
      timestamp: endDate,
      text: endDate.format(format)
    }
  }
}
