import {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Link} from 'react-router-dom'
import {Button} from '@material-ui/core'
import {Add, DirectionsRun, Edit, Delete, Comment} from '@material-ui/icons'
import Editor from './Editor'
import {DeleteDialog} from '../common'
import ActionButton from '../../../components/layout/ActionButton'
import PageTableBase from '../../../components/PageTableBase'
import {
  getContactList,
  deleteContact,
  toggleSort,
  setSort,
  toggleField,
  updateFilters
} from '../../../store/crm/contact/actions'
import CommentEditor from '../Comment/Editor'
import WalkinForm from '../Walkin/Editor'
import * as selectors from '../../../store/crm/contact/selectors'
import {getHiddenFeatures} from '../../../store/common/selectors'
import {
  getVisibleActions,
  alwaysTrue,
  isDeleteButtonVisible
} from '../common/helpers'
import {PermissionWrapper} from '../../../components/Permissions'
import {checkUserPermissions} from '../../../store/auth/selectors'
import {isOldEntity, orangeColorRow} from '../helpers'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  entity: null
}

export class ContactList extends Component {
  state = {
    delete: {...deleteInitialState},
    activeContacts: [],
    showCommentEditor: false
  }

  handleUpdate = () => {
    this.props.fetchData()
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action([id])
          this.closeDeleteDialog()
          this.handleUpdate()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  routeBack = () => this.props.history.push(this.props.match.path)

  routeEdit = ({id}) => {
    this.props.history.push(`${this.props.match.path}/edit/${id}`)
  }

  renderContactCreate = (routeProps) => (
    <Editor
      creation
      isOpen
      {...routeProps}
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleClose={this.routeBack}
    />
  )

  renderContactEdit = (routeProps) => (
    <Editor
      {...routeProps}
      isOpen
      handleSubmit={() => {
        this.props.fetchData()
        this.routeBack()
      }}
      handleClose={this.routeBack}
    />
  )

  renderWalkinCreate = (routeProps) => (
    <WalkinForm
      {...routeProps}
      isOpen
      handleClose={this.routeBack}
      handleSubmit={(id) => {
        this.props.history.push(`/crm/contacts/detail/${id}`)
      }}
    />
  )

  openBulkCommentEditor = (ids) => {
    this.setState({activeContacts: Array.from(ids), showCommentEditor: true})
  }

  closeBulkCommentEditor = () => {
    this.setState({activeContacts: [], showCommentEditor: false})
  }

  singleSelectActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.contacts',
          types: ['UPDATE']
        }),
      action: {
        event: this.routeEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.contacts',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'contact',
            id: item.id,
            action: this.props.deleteContact
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  multiSelectActions = isOldEntity({createdAt: new Date()})
    ? [
        {
          test: () =>
            this.props.hasPermissions({
              entity: 'web.admin.ui.crm.tasks',
              types: ['READ']
            }),
          action: {
            event: this.openBulkCommentEditor,
            icon: <Comment />,
            title: 'entity.comment'
          }
        }
      ]
    : []

  multiSelectTableActions = () =>
    getVisibleActions(this.multiSelectActions, this.props)

  render() {
    return (
      <PermissionWrapper entity='web.admin.ui.crm.contacts' withDescription>
        <div className={this.props.className}>
          <PageTableBase
            {...this.props}
            title='entity.contact'
            filterable
            selectable
            multiSelectActions={this.multiSelectTableActions()}
            singleSelectActions={this.singleSelectTableActions()}
            rowStyle={orangeColorRow}
          />
          <ActionButton>
            <PermissionWrapper entity='web.admin.ui.crm.walkin2'>
              {isOldEntity({
                createdAt: new Date()
              }) && (
                <Button
                  variant='fab'
                  component={Link}
                  to={`${this.props.match.path}/walkin`}
                >
                  <DirectionsRun />
                </Button>
              )}
            </PermissionWrapper>
            <PermissionWrapper
              entity='web.admin.ui.crm.contacts'
              types={['CREATE']}
            >
              {isOldEntity({
                createdAt: new Date()
              }) && (
                <Button
                  variant='fab'
                  color='primary'
                  component={Link}
                  to={`${this.props.match.path}/create`}
                  data-test='contact-create'
                >
                  <Add />
                </Button>
              )}
            </PermissionWrapper>
          </ActionButton>
          <PermissionWrapper entity='web.admin.ui.crm.walkin2'>
            <Route
              exact
              path={`${this.props.match.path}/walkin`}
              render={this.renderWalkinCreate}
            />
          </PermissionWrapper>
          <PermissionWrapper
            entity='web.admin.ui.crm.contacts'
            types={['CREATE']}
          >
            <Route
              exact
              path={`${this.props.match.path}/create`}
              render={this.renderContactCreate}
            />
          </PermissionWrapper>
          <Route
            exact
            path={`${this.props.match.path}/edit/:id`}
            render={this.renderContactEdit}
          />
          <DeleteDialog {...this.state.delete} />
          <CommentEditor
            isOpen={this.state.showCommentEditor}
            creation
            type='task'
            entity='contact'
            ids={this.state.activeContacts}
            handleSubmit={this.closeBulkCommentEditor}
            handleClose={this.closeBulkCommentEditor}
          />
        </div>
      </PermissionWrapper>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const {list} = state.contact

  return {
    ...list,
    data: Object.values(list.data),
    fields: selectors.getContactFields(state),
    filterFields: selectors.getContactFilters(state),
    currentPage: list.page,
    error: false,
    pageConfig: {
      hidden: {
        ...getHiddenFeatures(state)
      },
      ...ownProps.pageConfig
    },
    hasPermissions: (permissions) => checkUserPermissions(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(getContactList(args, 'contactListPage')),
  deleteContact: (ids) => dispatch(deleteContact(ids)),
  toggleSort: (sort) => dispatch(toggleSort(sort, 'contactListPage')),
  onFilterChange: (filter) =>
    dispatch(updateFilters(filter, 'contactListPage')),
  toggleField: ({key}) => dispatch(toggleField(key)),
  updateSort: (sort) => dispatch(setSort(sort, 'contactListPage'))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactList)
