// lead list types
export const fetching = 'leadList/LOADING'
export const dataLoaded = 'leadList/LOADED'
export const toggleField = 'leadList/TOGGLE_FIELD'
export const changePage = 'leadList/CHANGE_PAGE'
export const toggleSort = 'leadList/TOGGLE_SORT'
export const setSort = 'leadList/SET_SORT'
export const updateFilters = 'leadList/UPDATE_FILTER'
export const setCarId = 'leadList/CAR_ID'
export const inspectionLoaded = 'inspection/LOADED'

// form types
export const updateForm = 'leadForm/UPDATE'
export const resetForm = 'leadForm/RESET'
export const hydrateForm = 'leadForm/HYDRATED'
export const bulkLoadLeads = 'leadForm/BULK_LOAD_LEADS'
export const formError = 'leadForm/ERROR'
export const formSuccess = 'leadForm/SUCCESS'
export const formPending = 'leadForm/PENDING'
