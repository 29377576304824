const resourceName = 'cardatafiles'

export const fetchCarDataFilesList = `${resourceName}/FETCH_CAR_DATA_LIST`
export const fetchCarDataFilesListSuccess = `${resourceName}/FETCH_CAR_DATA_FILES_LIST_SUCCESS`
export const fetchCarDataFilesListError = `${resourceName}/FETCH_CAR_DATA_FILES_LIST_ERROR`
export const updateCardDataFilesField = `${resourceName}/UPDATE_CAR_DATA_FILES_FIELD`

export const resetCarDataFiles = `${resourceName}/RESET_CAR_DATA_FILES`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
