import auctionSchedules from './schedules/schedulesConfig'

export default {
  path: 'auctionsManagement',
  label: 'menu.auctionsManagement',
  key: 'auctionsManagement',
  children: [auctionSchedules],
  auctionSchedules,
  permissions: {
    entity: 'web.admin.ui.auctionsManagement.schedules',
    types: ['READ']
  }
}
