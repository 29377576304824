import {Typography, withStyles} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    fontWeight: 600,
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`
  }
})
const FilterMenuHeading = ({classes, content}) => (
  <Typography className={classes.root} variant='caption'>
    {content}
  </Typography>
)

export default withStyles(styles)(FilterMenuHeading)
