import React from 'react'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'

import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'

import selectors from '../../../store/auctionSchedules/selectors'
import {getCountryCode} from '../../../store/config/selectors'
import * as config from '../../../config/pages/auctionsManagement/schedules/config/createSchedule'
import {
  createAuctionFlow,
  cleanUpDialog
} from '../../../store/auctionSchedules/actions'
import {
  auctionTypeConfig,
  formFields,
  optionStatus,
  ON_AUCTION_END,
  BID_TYPE
} from '../../../config/pages/auctionsManagement/schedules/config/createSchedule'

import {Container, Row, RowItem, Span} from './createScheduleStyled'

import {
  setOnAuctionEndDisabledOptions,
  setOnAuctionEndVisible,
  getAllHelperTexts,
  getDefaultValue
} from './helpers'

const OPERATORS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
}

const styles = () => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: 2
  },
  dialogTitle: {
    paddingBottom: 40
  },
  onAuctionEndSection: {
    marginTop: 100
  },
  regularRow: {
    marginBottom: 10
  }
})

const defaultState = {
  flow: {
    name: '',
    auctionType: null,
    duration: null,
    durationKind: null,
    infiniteDuration: false,
    onAuctionEnd: null,
    bidTypes: null,
    auctionPreviewOffset: ''
  },
  errors: [],
  bidTypesStatus: {},
  bidTypeVisible: true,
  onAuctionEndVisible: true,
  onAuctionEndDisabledOptions: [],
  errorMessages: {},
  dirty: false
}

const skipValidateOnInfiniteDuration = [
  formFields.DURATION.name,
  formFields.DURATION_KIND.name
]

const reduceBidTypes = (availableBidTypes, auctionType) => {
  return availableBidTypes.reduce((acc, item) => {
    return {
      ...acc,
      [item]: auctionTypeConfig[auctionType]?.bidTypeStatus
        ? auctionTypeConfig[auctionType]?.bidTypeStatus[item]
        : false
    }
  }, {})
}

class CreateSchedule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...defaultState
    }
  }

  saveDisabled = () => this.props.isSubmitting

  initializeDialog = () => {
    const defaultAuctionType = getDefaultValue({
      fieldKey: formFields.AUCTION_TYPE.name,
      config
    })
    const availableBidTypes =
      auctionTypeConfig[defaultAuctionType].availableBidTypes

    this.setState({
      flow: {
        ...this.state.flow,
        auctionType: defaultAuctionType,
        durationKind: getDefaultValue({
          fieldKey: formFields.DURATION_KIND.name,
          config
        }),
        onAuctionEnd: getDefaultValue({
          fieldKey: formFields.ON_AUCTION_END_GROUP.name,
          config,
          countryCode: this.props.countryCode
        })
      },
      bidTypesStatus: availableBidTypes
        ? reduceBidTypes(availableBidTypes, defaultAuctionType)
        : {},
      onAuctionEndVisible: setOnAuctionEndVisible(defaultAuctionType),
      onAuctionEndDisabledOptions: setOnAuctionEndDisabledOptions(
        defaultAuctionType
      ),
      errorMessages: getAllHelperTexts()
    })
  }

  componentDidMount() {
    this.initializeDialog()
  }

  componentDidUpdate = () => {
    if (this.props.created && this.state.dirty) {
      this.onDialogClose()
    }
  }

  onDialogClose = () => {
    this.props.handleClose()
    this.cleanUpDialog(this.initializeDialog)
  }

  cleanUpDialog = (callback) => {
    this.setState(
      {
        ...defaultState
      },
      callback
    )
  }

  clearBidType = () => {
    this.setState({
      bidTypesStatus: {}
    })
  }

  clearOnAuctionEnd = () => {
    this.setState({
      flow: {
        ...this.state.flow,
        onAuctionEnd: null
      }
    })
  }

  formatOptions = (option, flowName) => {
    const optionType =
      option === ON_AUCTION_END.AUTOCONFIRM.value
        ? 'autoConfirmEnabled'
        : option === ON_AUCTION_END.AUTORESERVE.value
        ? 'autoReserveEnabled'
        : null

    return optionType
      ? {
          [optionType]: [flowName]
        }
      : ''
  }

  onSave = () => {
    const flow = this.state.flow
    let data
    const defaultPreviewOffset = 24 * 60

    flow.bidTypes = Object.entries(this.state.bidTypesStatus).reduce(
      (acc, [key, value]) => (value ? [...acc, key] : acc),
      []
    )

    const errors = Object.entries(formFields).reduce(
      (acc2, [fieldName, fieldConfig]) => {
        if (
          fieldConfig.required &&
          typeof fieldConfig.validate === 'function' &&
          !fieldConfig.validate(this.state.flow[fieldConfig.key])
        ) {
          return [...acc2, fieldName]
        } else {
          return [...acc2]
        }
      },
      []
    )

    if (this.state.flow.infiniteDuration) {
      skipValidateOnInfiniteDuration.map((item) => {
        if (errors.includes(item)) {
          errors.splice(errors.indexOf(item), 1)
        }
      })
    }

    if (errors.length > 0) {
      this.setState({
        errors: errors
      })

      return
    } else {
      data = {
        name: flow.name,
        configId: this.props.configId,
        configOptions: JSON.stringify(
          this.formatOptions(flow.onAuctionEnd, flow.name)
        ),
        options: {
          previewOffset: Number(flow.auctionPreviewOffset)
            ? Number(flow.auctionPreviewOffset)
            : this.props.previewOffset ?? defaultPreviewOffset
        },
        schedule: [
          {
            kind: flow.auctionType,
            duration: this.state.flow.infiniteDuration
              ? 'infinite'
              : [flow.duration, flow.durationKind],
            allowedBidTypes: flow.bidTypes
          }
        ]
      }

      this.setState({
        dirty: true
      })

      this.props.createFlow(data)
    }
  }

  setError = (op, field) => {
    if (op == OPERATORS.ADD) {
      if (!this.state.errors.includes(field)) {
        this.setState({
          errors: [...this.state.errors, field]
        })
      }
    }

    if (op === OPERATORS.REMOVE) {
      if (this.state.errors.includes(field)) {
        this.setState({
          errors: this.state.errors.filter((item) => item !== field)
        })
      }
    }
  }

  validateField = (field, value) => {
    if (
      this.state.flow.infiniteDuration &&
      skipValidateOnInfiniteDuration.includes(field)
    ) {
      return
    }

    this.setError(
      formFields[field].validate(value) ? OPERATORS.REMOVE : OPERATORS.ADD,
      field
    )
  }

  onAuctionTypeChange = (e) => {
    const auctionTypeValue = e.target.value

    this.clearBidType()
    this.clearOnAuctionEnd()

    const stateObject = {
      flow: {
        ...this.state.flow,
        auctionType: auctionTypeValue,
        infiniteDuration: false
      }
    }

    stateObject.flow.onAuctionEnd = getDefaultValue({
      fieldKey: formFields.ON_AUCTION_END_GROUP.name,
      config,
      countryCode: this.props.countryCode
    })

    if (auctionTypeConfig[auctionTypeValue].defaultValues) {
      Object.assign(
        stateObject.flow,
        auctionTypeConfig[auctionTypeValue].defaultValues
      )
    }

    stateObject.bidTypeVisible = !(
      auctionTypeConfig[auctionTypeValue].availableBidTypes &&
      auctionTypeConfig[auctionTypeValue].availableBidTypes ===
        config.NOT_APPLICABLE
    )

    if (stateObject.bidTypeVisible) {
      stateObject.bidTypesStatus = reduceBidTypes(
        auctionTypeConfig[auctionTypeValue].availableBidTypes,
        auctionTypeValue
      )
    } else {
      stateObject.bidTypesStatus = {}
    }

    stateObject.onAuctionEndVisible = setOnAuctionEndVisible(auctionTypeValue)

    stateObject.onAuctionEndDisabledOptions = setOnAuctionEndDisabledOptions(
      auctionTypeValue
    )

    this.setState({
      ...stateObject
    })
  }

  onDurationKindChange = (e) => {
    this.setState({
      flow: {
        ...this.state.flow,
        durationKind: e.target.value
      }
    })
  }

  onInfiniteDurationChecked = (e) => {
    this.setState({
      flow: {
        ...this.state.flow,
        infiniteDuration: !this.state.flow.infiniteDuration
      }
    })

    if (e.target.checked) {
      skipValidateOnInfiniteDuration.map((item) => {
        this.setError(OPERATORS.REMOVE, item)
      })
    }
  }

  onBidTypeChecked = (e) => {
    const {value, checked} = e.target

    this.setState({
      bidTypesStatus: {
        ...this.state.bidTypesStatus,
        [value]: checked
      }
    })
  }

  onChangeOnAuctionEnd = (e) => {
    this.setState({
      flow: {
        ...this.state.flow,
        onAuctionEnd: e.target.value
      }
    })
  }

  onNameChange = (e) => {
    this.setState({
      flow: {
        ...this.state.flow,
        name: e.target.value
      }
    })
  }

  onDurationChange = (e) => {
    this.setState({
      flow: {
        ...this.state.flow,
        duration: e.target.value
      }
    })
  }

  onAuctionPreviewOffsetChange = ({target: {value}}) => {
    const {validate} = formFields.AUCTION_PREVIEW_OFFSET
    if (validate(value)) {
      this.setState({
        flow: {
          ...this.state.flow,
          auctionPreviewOffset: value
        }
      })
    }
  }

  onFieldBlur = (field) => {
    this.validateField(field, this.state.flow[formFields[field].key])
  }

  isBidTypeVisible = () => {
    return this.state.bidTypeVisible
  }

  isOnAuctionEndVisible = () => {
    return this.state.onAuctionEndVisible
  }

  isBidTypeChecked = (type) => {
    return this.state.bidTypesStatus[type]
  }

  isError = (field) => {
    return this.state.errors.includes(field)
  }

  isOnAuctionEndOptionDisabled = (optionValue) => {
    return this.state.onAuctionEndDisabledOptions.includes(optionValue)
  }

  getHelperTextForField = (field) => {
    return this.state.errorMessages[formFields[field].name]
  }

  getErrorFields = () => {
    return this.state.errors.reduce((acc, item) => {
      return {
        ...acc,
        [item]: this.getHelperTextForField(item)
      }
    }, {})
  }

  renderSelectOptions = (field, t) => {
    const items = Object.values(config[field]).map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {t(item.label)}
      </MenuItem>
    ))

    return items
  }

  renderBidTypes = (t) => {
    if (!this.state.flow.auctionType) {
      return
    }

    const availableBidTypes =
      auctionTypeConfig[this.state.flow.auctionType].availableBidTypes

    if (availableBidTypes === config.NOT_APPLICABLE) {
      return
    }

    return availableBidTypes.map((bidType) => {
      return (
        <RowItem width={2} key={bidType}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.isBidTypeChecked(bidType)}
                onChange={this.onBidTypeChecked}
                name='bidType-check'
                value={BID_TYPE[bidType].value}
                color='primary'
              />
            }
            label={t(BID_TYPE[bidType].label)}
          />
        </RowItem>
      )
    })
  }

  renderOnAuctionEnd = (t) => {
    const items = Object.values(ON_AUCTION_END).map((item) => {
      return (
        <FormControlLabel
          key={item.label}
          value={item.value}
          control={<Radio />}
          label={t(item.label)}
          disabled={this.isOnAuctionEndOptionDisabled(item.value)}
        />
      )
    })

    return items
  }

  render() {
    const {classes} = this.props
    const {t} = this.props
    const errorFields = this.getErrorFields()

    return (
      <Dialog open={this.props.isOpen} maxWidth='md' fullWidth>
        <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
          {t('auctionManagement.schedules.create.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <Row className={classes.regularRow}>
              <RowItem width={2}>
                <Span>{t('auctionSchedules.page.field.name')}: </Span>
              </RowItem>
              <RowItem width={6}>
                <TextField
                  onChange={this.onNameChange}
                  variant='outlined'
                  error={this.isError(formFields.NAME.name)}
                  helperText={t(errorFields[formFields.NAME.name])}
                  data-test={`modal-${formFields.NAME.key}`}
                  onBlur={() => this.onFieldBlur(formFields.NAME.name)}
                >
                  {this.state.flow.name}
                </TextField>
              </RowItem>
            </Row>

            <Row className={classes.regularRow}>
              <RowItem width={2}>
                <Span>{t('auctionSchedules.page.field.type')}:</Span>
              </RowItem>
              <RowItem width={2}>
                <FormControl
                  className={classes.formControl}
                  error={this.isError(formFields.AUCTION_TYPE.name)}
                >
                  <Select
                    labelId='auction-type-select'
                    id='auction-type-select'
                    data-test={`modal-${formFields.AUCTION_TYPE.key}`}
                    value={this.state.flow.auctionType}
                    onChange={this.onAuctionTypeChange}
                  >
                    {this.renderSelectOptions(formFields.AUCTION_TYPE.name, t)}
                  </Select>
                  {this.isError(formFields.AUCTION_TYPE.name) && (
                    <FormHelperText>
                      {t(errorFields[formFields.AUCTION_TYPE.name])}
                    </FormHelperText>
                  )}
                </FormControl>
              </RowItem>
            </Row>

            <Row className={classes.regularRow}>
              <RowItem width={2}>
                <Span>{t('auctionSchedules.page.field.duration')}:</Span>
              </RowItem>
              <RowItem width={2}>
                <TextField
                  onChange={this.onDurationChange}
                  variant='outlined'
                  error={this.isError(formFields.DURATION.name)}
                  helperText={t(errorFields[formFields.DURATION.name])}
                  data-test={`modal-${formFields.DURATION.key}`}
                  onBlur={() => this.onFieldBlur(formFields.DURATION.name)}
                  disabled={this.state.flow.infiniteDuration}
                >
                  {this.state.flow.duration}
                </TextField>
              </RowItem>
              <RowItem width={2}>
                <FormControl
                  className={classes.formControl}
                  error={this.isError(formFields.DURATION_KIND.name)}
                >
                  <Select
                    labelId='duration-label'
                    id='duration'
                    data-test={`modal-${formFields.DURATION_KIND.key}`}
                    value={this.state.flow.durationKind}
                    onChange={this.onDurationKindChange}
                    disabled={this.state.flow.infiniteDuration}
                  >
                    {this.renderSelectOptions(formFields.DURATION_KIND.name, t)}
                  </Select>
                  {this.isError(formFields.DURATION_KIND.name) && (
                    <FormHelperText>
                      {t(errorFields[formFields.DURATION_KIND.name])}
                    </FormHelperText>
                  )}
                </FormControl>
              </RowItem>
              <RowItem width={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.flow.infiniteDuration}
                      onChange={this.onInfiniteDurationChecked}
                      data-test='infiniteDuration'
                      name='infiniteDuration'
                      color='primary'
                    />
                  }
                  label='Infinite'
                />
              </RowItem>
            </Row>

            <Row className={classes.regularRow}>
              <RowItem width={2}>
                <Span>{t('auctionSchedules.page.field.auctionOffset')}:</Span>
              </RowItem>
              <RowItem width={2}>
                <TextField
                  onChange={this.onAuctionPreviewOffsetChange}
                  variant='outlined'
                  value={this.state.flow.auctionPreviewOffset}
                  helperText={t(
                    errorFields[formFields.AUCTION_PREVIEW_OFFSET.name]
                  )}
                  data-test={`modal-${formFields.AUCTION_PREVIEW_OFFSET.key}`}
                  onBlur={() =>
                    this.onFieldBlur(formFields.AUCTION_PREVIEW_OFFSET.name)
                  }
                >
                  {this.state.flow.auctionPreviewOffset}
                </TextField>
              </RowItem>
              <RowItem width={2}>
                <FormControl className={classes.formControl}>
                  <Span>{t('time.minutes')}</Span>
                </FormControl>
              </RowItem>
            </Row>

            {this.isBidTypeVisible() && (
              <Row
                className={classes.regularRow}
                data-test={`modal-${formFields.BID_TYPE.key}`}
              >
                <RowItem width={2}>
                  <Span>{t('auctionSchedules.page.field.bidType')}:</Span>
                </RowItem>
                {this.renderBidTypes(t)}
              </Row>
            )}

            {this.isOnAuctionEndVisible() && (
              <Row className={classes.onAuctionEndSection}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>
                    {t('auctionSchedules.page.field.onAuctionEnd')}
                  </FormLabel>
                  <RadioGroup
                    aria-label='gender'
                    name='gender1'
                    value={this.state.flow.onAuctionEnd}
                    data-test={`modal-${formFields.ON_AUCTION_END_GROUP.key}`}
                    onChange={this.onChangeOnAuctionEnd}
                    row
                  >
                    {this.renderOnAuctionEnd(t)}
                  </RadioGroup>
                </FormControl>
              </Row>
            )}
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onDialogClose}
            color='primary'
            data-test='cancel-schedule-button'
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant='raised'
            color='primary'
            type='submit'
            onClick={this.onSave}
            disabled={this.saveDisabled()}
            data-test='submit-schedule-button'
          >
            {t('button.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  configId: selectors.getConfigId(state),
  isSubmitting: selectors.isFormSubmitting(state),
  created: selectors.isCreated(state),
  countryCode: getCountryCode(state)
})

const mapDispatchToProps = (dispatch) => ({
  createFlow: (data) => dispatch(createAuctionFlow(data)),
  cleanUpDialog: () => dispatch(cleanUpDialog())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(withStyles(styles, {withTheme: true})(CreateSchedule)))
