const dealerDetails = require('./dealerManagement/za')
const crmDetails = require('./CRM/za')
const bulkuploadDetails = require('./bulkUpload/za')
const carDataDetails = require('./carData/za')
const locationDetails = require('./locationManagement/za')
const payslipDetails = require('./payslips/za')
const carOwnership = require('./carOwnership/za')
const businessPartnersDetails = require('./businessPartnersDetails/za')

module.exports = {
  url: 'cars45.za',
  name: 'South Africa',
  countryCode: 'ZA',
  theme: 'southAfrica',
  language: {
    selected: 'en-za',
    options: [{value: 'en-za', label: 'EN'}]
  },
  locales: ['en-za'],
  currency: {code: 'ZAR', symbol: 'R'},
  number: {decimalSep: ',', unitSep: '.', units: [1000]},
  fallbackLocale: 'en-za',
  dateFormat: 'YYYY/MM/DD',
  phone: {length: [8, 10], code: 27},
  referralCodeType: 'input',
  googleKey:
    '34555433962-iabg59sjpg5j04bjau31muged3fdgc8s.apps.googleusercontent.com',
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  crmDetails,
  carDuplicatedCheckField: 'vin',
  auctionPreviewOffset: 30,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'resourceManager',
    'category',
    'wms',
    'bulkUploadV2',
    'tms',
    'attendanceManager',
    'documentManagement',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
