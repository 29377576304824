import React from 'react'
import ResponseType from '../types/ResponseType'
interface ResponseBar {
  type?: ResponseType
  message?: string
}
interface State {
  hasErrors: boolean
  dataHasChanged: boolean
  remindSave: boolean
  responseBar: ResponseBar
}

const initialState:State = {
  hasErrors: false,
  dataHasChanged: false,
  remindSave: false,
  responseBar: {}
}

type Action = {type: "SET_ERROR_STATE", value: boolean}
            | {type: "SET_DATA_HAS_CHANGED", value: boolean}
            | {type: "SET_REMIND_SAVE", value: boolean}
            | {type: "SET_RESPONSE_BAR", value: ResponseBar}
            | {type: "RESET"}

function reducer(state:State, action:Action) {
  switch (action.type) {
    case "SET_ERROR_STATE":
      return {...state, hasErrors: action.value}
    case "SET_DATA_HAS_CHANGED":
      return {...state, dataHasChanged: action.value}
    case "SET_REMIND_SAVE":
      return {...state, remindSave: action.value}
    case "SET_RESPONSE_BAR":
      return {...state, responseBar: action.value}
    case "RESET":
      return {...initialState}
    default:
      return state
  }
}

type ContextValue = State & {
  setErrorState(value: boolean): void
  setDataHasChanged(value: boolean): void
  setRemindSave(value: boolean): void
  setResponseBar(value: ResponseBar): void
  resetValidationContext(): void
}

export const ValidationContext = React.createContext<ContextValue>(null!)
ValidationContext.displayName = 'ValidationContext'

export default ({children}:{children:React.ReactNode})=> {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value:ContextValue = {
    hasErrors: state.hasErrors,
    dataHasChanged: state.dataHasChanged,
    remindSave: state.remindSave,
    responseBar: state.responseBar,
    setErrorState: (value) =>
      dispatch({
        type: "SET_ERROR_STATE",
        value: value
      }),
    setDataHasChanged: (value) =>
      dispatch({
        type: "SET_DATA_HAS_CHANGED",
        value: value
      }),
    setRemindSave: (value) =>
      dispatch({
        type: "SET_REMIND_SAVE",
        value: value
      }),
    setResponseBar: (value) =>
      dispatch({
        type: "SET_RESPONSE_BAR",
        value: value
      }),
    resetValidationContext: () =>
      dispatch({
        type: "RESET"
      })
  }

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  )
}
