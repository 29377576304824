const React = require('react')
const Styled = require('styled-components')

const LinkWrapper = Styled.default.a`
  color: #f00;
  background-color: #f2dede;
  padding: 5px;
`

const RichTableCellInternalIds = (props) => {
  const {data} = props

  if (!data) {
    return null
  }

  return data.map((car) => (
    <LinkWrapper key={`menu-item-${car.id}`} href={car.cmsLink} target='_blank'>
      <div>{car.internalId}</div>
    </LinkWrapper>
  ))
}

module.exports = RichTableCellInternalIds
