const state = {
  fetching: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  selectedPreferenceId: null,
  preferenceData: {
    name: '',
    country: '',
    metaInfo: {
      groupBackgroundColor: '',
      groupFontColor: '',
      headerLogo: '',
      printCBE: true,
      displayCBEAtStart: true
    }
  },
  isUploading: false,
  documentList: [],
  documentFetching: false,
  documentPreviewUrl: '',
  dealerId: null,
  reference: null,
  dealerFileTypeOptions: []
}

export const pdfPreferenceSchema = {
  name: '',
  country: '',
  metaInfo: {
    groupBackgroundColor: '',
    groupFontColor: '',
    headerLogo: '',
    printCBE: true,
    displayCBEAtStart: true
  }
}

export const getInitialState = (config) => ({
  options: {},
  form: {...pdfPreferenceSchema},
  ...config,
  ...state
})
