import React, {useState, useEffect} from 'react'
import update from 'immutability-helper'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add'

import TimeRangePicker from './TimeRangePicker'
import OverBookings from './OverBookings'
import StatusLabel from '../../../../components/StatusLabel'
import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import {
  defaultTimeRange,
  weekdayNames,
  serviceTypes
} from '../../../../config/constants'
import ServiceType from '../../../../types/ServiceType'

const addButtonStyle = {
  minWidth: 0,
  padding: 5
}
const types = {services: serviceTypes, days: weekdayNames}

interface ServiceOpeningHoursRowProps {
  noBorder?: boolean
  type?: 'days' | 'services'
  serviceType: ServiceType[]
  rowKey: number | ServiceType
  rowData: any
  onChange: (rowKey: number | ServiceType, value: any) => void
}

const ServiceOpeningHoursRow = ({
  noBorder = false,
  type = 'days',
  serviceType,
  rowKey,
  rowData,
  onChange = () => {}
}: ServiceOpeningHoursRowProps) => {
  const classes = useCommonStyles()
  const [openingTimeRowData, setOpeningTimeRowData] = useState(rowData)

  useEffect(() => {
    setOpeningTimeRowData(rowData)
  }, [rowData])

  const handleDataChange = (
    timeRangeIndex: number,
    field: string,
    value: any
  ) => {
    let newRowData
    if (timeRangeIndex === -1) {
      newRowData = openingTimeRowData.map((timeRange: object) => {
        return { ...timeRange, closed: value }
      })
    } else {
      newRowData = update(openingTimeRowData, {
      [timeRangeIndex]: {[field]: {$set: value}}
    })
    }
     
    onChange(rowKey, newRowData)
  }

  const addTimeRange = () => {
    const newTimeRange = {
      ...defaultTimeRange,
      bookingType: serviceType
    }
    if (type === 'days') newTimeRange.weekday = rowKey
    const newRowData = [...openingTimeRowData, {...newTimeRange}]
    onChange(rowKey, newRowData)
  }

  const removeTimeRange = (timeRangeIndex: number) => {
    const newRowData = openingTimeRowData.filter(
      (timeRange: any, i: number) => {
        if (i !== timeRangeIndex) return timeRange
      }
    )

    onChange(rowKey, newRowData)
  }

  return (
    <Grid
      id={rowKey}
      container
      className={cx(
        {[classes.borderBottomLight]: !noBorder},
        classes.paddingTop2
      )}
    >
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!openingTimeRowData[0].closed}
              onChange={(e: any) => {
                //it is not closed when it is checked
                handleDataChange(-1, 'closed', !e.target.checked)
              }}
              value={types[type][rowKey]}
              color='primary'
            />
          }
          label={types[type][rowKey]}
        />
      </Grid>
      {!openingTimeRowData[0].closed ? (
        <>
          <Grid item xs={8}>
            {openingTimeRowData.map((timeRange: any, i: number) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={i}
                  className={cx(
                    {
                      [classes.borderBottom]: i < openingTimeRowData.length - 1
                    },
                    classes.paddingBottom2
                  )}
                >
                  <TimeRangePicker
                    timeRangeIndex={i}
                    timeRange={[timeRange.openingTime, timeRange.closingTime]}
                    onDelete={removeTimeRange}
                    onChange={handleDataChange}
                  />
                  <OverBookings
                    timeRangeIndex={i}
                    onChange={handleDataChange}
                    slots={timeRange.slots}
                  />
                </Grid>
              )
            })}
          </Grid>
          <Grid item xs={1} className={classes.textRight}>
            <Button
              variant='outlined'
              color='primary'
              style={addButtonStyle}
              onClick={() => {
                addTimeRange()
              }}
            >
              <AddIcon />
            </Button>
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={9}
          className={cx(classes.textCenter, classes.paddingBottom2)}
        >
          <StatusLabel label='Unavailable' theme='inactive' />
        </Grid>
      )}
    </Grid>
  )
}

export default ServiceOpeningHoursRow
