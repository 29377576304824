const state = {
  showErrorDialog: false,
  apiError: false,
  errorMessage: null,
  authError: false,
  appError: false,
  notification: false,
  isMenuOpen: false,
  options: {
    locations: []
  }
}

export const getInitialState = (preferences = {}) => ({
  ...state,
  ...preferences
})
