import filter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import Select from '../../../components/Select/Select'
import {connect} from 'react-redux'

const base = (props) => (
  <Select
    {...props}
    id={`UserGroups_${props.fieldName}`}
    label={props.fieldName}
    options={props.options}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

const mapStateToProps = (state) => ({
  options: state.crmUserGroups.groups.map((item) => ({
    label: item.name,
    value: item.id
  }))
})

export const UserGroupFilter = connect(mapStateToProps)(filter(base))
