import Editor from '../../../pages/TransitJobs/Editor'

export default {
  path: 'transit-job/create',
  key: 'transitJobCreate',
  component: Editor,
  label: 'transitJobCreate',
  permissions: {
    entity: 'web.admin.ui.car.transitJobs',
    types: ['CREATE']
  }
}
