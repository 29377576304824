import styled from 'styled-components'
import PaymentSummary from './Summary/PaymentSummary'
import Button from '@material-ui/core/Button'
import {RenderIf} from '../../../components/RenderIf'
import LoadingState from '../../../components/LoadingState'
import {Documents} from './ReceiptDocuments/Documents'
import {ButtonWrapper} from '../../../components/Subtable/styles'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import colours from './../../../utils/color'

const PayslipContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const PayslipLoadingContainer = styled(PayslipContainer)`
  padding: 20px;
`
const PayslipErrorContainer = styled(PayslipContainer)`
  height: 130px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ErrorBox = styled.div`
  height: 50px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`
const PayslipContentLeft = styled.div`
  display: flex;
  width: 40%;
`
const PayslipContentRight = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
`
const NoDocumentData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & svg {
    font-size: 60px;
    margin: 15px;
    color: ${colours.appBlue};
  }
`
const ErrorDetails = styled.div`
  margin-top: 30px;

  & > p {
    margin-top: 5px;
    text-align: center;
  }
`

export const isRowLoading = ({id, rowFetching}) =>
  rowFetching && rowFetching.find((rowId) => id === rowId)
export const getRowError = ({id, rowFetchingErrors}) =>
  rowFetchingErrors && rowFetchingErrors.find((rowId) => id === rowId.id)

export const extractError = (error) => {
  if (!error) {
    return null
  }

  return error.error && error.error.message
    ? error.error.message
    : JSON.stringify(error.error)
}

const identity = (a) => a

export const ErrorContent = ({error, t = identity, ...props}) => {
  const errorMessage = extractError(error)

  return (
    <PayslipErrorContainer data-test='payslip-error-container'>
      <h4>{t('car.page.error.occurred')}</h4>
      <ErrorBox data-test='error-content-message'>{errorMessage}</ErrorBox>
      <Button onClick={() => props.fetchRowData({id: props.rowId})}>
        {t('button.retry')}
      </Button>
    </PayslipErrorContainer>
  )
}

/**
 * Check if array is empty or if array contains empty objects
 * @param {array} data
 *
 * @returns {Boolean}
 */
export const isDataArrayEmpty = (data) =>
  Array.isArray(data) &&
  (data.length === 0 ||
    data.every((document) => Object.keys(document).length === 0))

export const NoDocumentsAvailable = ({error, t = identity}) => {
  const errorMessage = extractError(error)

  return (
    <NoDocumentData data-test='no-document-data'>
      <BrokenImageIcon />
      {t('payslips.error.noDocuments')}
      {errorMessage ? (
        <ErrorDetails>
          {t('payslips.error.errorDetails')}
          <p data-test='no-document-data-error-message'>{errorMessage}</p>
        </ErrorDetails>
      ) : null}
    </NoDocumentData>
  )
}
export const PayslipContent = (props) => {
  if (isRowLoading(props)) {
    return (
      <PayslipLoadingContainer data-test='payslip-loading-container'>
        <LoadingState />
      </PayslipLoadingContainer>
    )
  }

  const documentList =
    props.data && props.data.documentList ? props.data.documentList : []
  const documentData = props.data && props.data.data ? props.data.data : []
  const summaryData =
    props.data && props.data.subtableSummaryData
      ? props.data.subtableSummaryData
      : {}
  const rowError = getRowError(props)
  const hasNoDocumentData = isDataArrayEmpty(documentList)

  /**
   * Show an error which happened while having all data
   */
  if (rowError && !hasNoDocumentData) {
    return <ErrorContent {...props} error={rowError} />
  }

  return (
    <PayslipContainer data-test='payslip-content'>
      <PayslipContentLeft>
        {hasNoDocumentData ? (
          <NoDocumentsAvailable {...props} error={rowError} />
        ) : (
          <Documents
            data={documentList}
            t={props.t}
            onMetaInfoChange={props.onMetaInfoChange}
          />
        )}
      </PayslipContentLeft>
      <PayslipContentRight>
        <PaymentSummary
          {...props}
          t={props.t}
          updateTicketEntity={props.updateTicketEntity}
          carPaymentReceiptsSelection={props.carPaymentReceiptsSelection}
          data={documentData}
          summaryData={summaryData}
          summaryFields={props.subtableSummaryFields}
          addCarPaymentSelection={props.addCarPaymentSelection}
          locale={props.locale}
          rowId={props.rowId}
        />
        <ButtonWrapper>
          <RenderIf if={props.shouldShowRejectBtn(props.rowData)}>
            <Button
              onClick={props.onRejectReceipt({
                ticketId: props.rowId
              })}
              data-test='reject-payment'
            >
              {props.t('button.reject')}
            </Button>
          </RenderIf>
          <RenderIf if={props.shouldShowApproveBtn(props.rowData)}>
            <Button
              color='primary'
              variant='raised'
              onClick={props.onApprovePayment({
                ticketId: props.rowId,
                summaryData: summaryData,
                rowData: props.rowData
              })}
              data-test='approve-payment'
            >
              {props.t('menu.payslipts.approvePayment')}
            </Button>
          </RenderIf>
        </ButtonWrapper>
      </PayslipContentRight>
    </PayslipContainer>
  )
}
