import {all} from 'redux-saga/effects'
import {sagas as carDataSagas} from '../cardata'
import {sagas as webhookSagas} from '../webhook'
import {sagas as carDataFilesSagas} from '../cardata/carDataFiles'
import {sagas as categorySagas} from '../category'
import {sagas as userSagas} from '../users'
import {sagas as bulkUploadSagas} from '../bulkUpload'
import {sagas as payslipsSagas} from '../payslips/list/sagas'
import {sagas as newPayslipsSagas} from '../payslips/create/sagas'
import {sagas as countryOptionSagas} from '../countryOption/sagas'
import {sagas as driverSagas} from '../driver/sagas'
import {sagas as transitJobSagas} from '../transitJob/sagas'
import {sagas as documentConfigSagas} from '../documentConfig/sagas'
import {sagas as ownershipTransferSagas} from '../carOwnership/sagas'
import {sagas as tierSagas} from '../crm/leadAllocation'
import {sagas as authSagas} from '../auth'
import {sagas as participantSagas} from '../crm/participant'
import {sagas as auctionSchedulesSagas} from '../auctionSchedules'
import {sagas as businessPartnerSagas} from '../businessPartners'

export function* rootSagas() {
  yield all([
    ...carDataSagas,
    ...userSagas,
    ...bulkUploadSagas,
    ...webhookSagas,
    ...categorySagas,
    ...carDataFilesSagas,
    ...payslipsSagas,
    ...newPayslipsSagas,
    ...countryOptionSagas,
    ...driverSagas,
    ...transitJobSagas,
    ...documentConfigSagas,
    ...ownershipTransferSagas,
    ...tierSagas,
    ...authSagas,
    ...participantSagas,
    ...auctionSchedulesSagas,
    ...businessPartnerSagas
  ])
}
