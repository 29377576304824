import moment from 'moment'
import {getMonthValues} from './helpers'

export const calendarSchema = () => {
  const lastMonth = moment().subtract(1, 'month')
  const thisMonth = moment()

  return {
    isOpen: false,
    selected: null,
    selectedRange: null,
    activePreset: null,
    activeRange: {from: null, to: null},
    visibleRange: {
      from: lastMonth.startOf('month'),
      to: thisMonth.endOf('month')
    },
    isBeingChanged: false,
    months: [getMonthValues(lastMonth), getMonthValues(thisMonth)]
  }
}

export const getInitialState = (overrides = {}) => ({
  ...overrides
})

export default getInitialState
