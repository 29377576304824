export const businessPartnerFields = [
  'businessPartnerId',
  'type',
  'legalName',
  'dealerName',
  'subType',
  'salesforceId',
  'status',
  ['tagInfo', ['id', 'tagName']],
  [
    'contacts',
    [
      'id',
      'name',
      'type',
      'email',
      'phone',
      'gender',
      'dateOfBirth',
      'relationType',
      'legacyId',
      'userId'
    ]
  ],
  [
    'taxInfo',
    [
      'taxNumber',
      'type',
      'id',
      'gstLiable',
      'distributionType',
      'gstStateCode',
      'taxSubType',
      'withHoldingTaxType',
      'withHoldingTaxCode',
      'withHoldingTaxIdentificationNumber'
    ]
  ],
  [
    'address',
    [
      'addressLine',
      'city',
      'country',
      'type',
      'zipCode',
      'id',
      'state',
      'email',
      'phone'
    ]
  ],
  [
    'bankDetails',
    [
      'accountNumber',
      'accountType',
      'id',
      'accountOwner',
      'accountOwnerTaxId',
      'bankCode',
      'bankContactDetails',
      'bankName',
      'bankCountryKey',
      'IbanNumber'
    ]
  ],
  ['metaInfo', ['paymentTerms', 'paymentMethods']]
]

export const businessPartnerFormFields = ['businessPartnerId', 'legalName']

export const businessPartnerCreateFields = ['businessPartnerId', 'legalName']

export const businessPartnerList = [
  'count',
  'totalPages',
  'totalItems',
  'currentPage',
  ['list', businessPartnerFields]
]
