import {PureComponent} from 'react'
import {translate} from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import {connect} from 'react-redux'
import {cancelAppointment} from '../../../store/crm/appointment/actions'
import {SlideUp} from '../../../components/Animations/Slides'
import Select from '../../../components/Select/Select'
import dialogStyles from '../common/dialogStyles'
import {cancelCalendarAppointment} from '../../../store/calendar/actions'
import {getFormAppointmentOptions} from './helpers'

export class CancelAppointment extends PureComponent {
  state = {cancellationReason: null, cancelledInStatus: null}

  update = (fieldName) => (value) => this.setState({[fieldName]: value})

  handleBack = () => {
    if (!this.props.goBack) {
      return this.props.handleClose()
    }

    this.props.goBack()
  }

  getAppointmentById = (id) => {
    const {appointment, appointments} = this.props

    if (appointment?.list?.data?.length) {
      const appointmentData = Object.values(appointment.list.data)

      return appointmentData.find((item) => item.id === id)
    } else if (appointments?.length) {
      return appointments.find((item) => item.id === id)
    }

    return null
  }

  render() {
    const {t, classes, options, ...props} = this.props
    const appointmentData = this.getAppointmentById(props.id)
    const {cancellationReason} = getFormAppointmentOptions(
      options,
      appointmentData
    )

    return (
      <div>
        <Dialog
          open={props.isOpen}
          onClose={props.handleClose}
          TransitionComponent={SlideUp}
        >
          <DialogTitle disableTypography>
            <Typography variant='title' className={classes.title}>
              <Cancel className={classes.icon} />
              {t('appointment.cancel.dialog.title')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {t('appointment.cancel.dialog.confirmation')}
            <Select
              withOther
              fullWidth
              label={t('appointment.cancellationReason.label')}
              id='cancellationReason'
              onChange={this.update('cancellationReason')}
              options={cancellationReason}
              selected={this.state.cancellationReason}
            />
            <Select
              fullWidth
              label={t('appointment.cancelledInStatus.label')}
              id='cancelledInStatus'
              onChange={this.update('cancelledInStatus')}
              options={options.cancelledInStatus}
              selected={this.state.cancelledInStatus}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleBack}>{t('button.back')}</Button>
            <Button
              onClick={() => {
                props.confirm(
                  {
                    appointmentId: props.id,
                    cancellationReason: this.state.cancellationReason,
                    cancelledInStatus: this.state.cancelledInStatus
                  },
                  props.handleSubmit
                )
              }}
              variant='raised'
              color='primary'
              disabled={props.pending === true}
            >
              {t('button.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({crm, appointment}) => ({
  options: crm.options,
  appointment,
  pending: appointment.cancelState.pending
})

const mapDispatchToProps = (dispatch, {isCalendar}) => ({
  confirm: (params, next) =>
    isCalendar
      ? dispatch(cancelCalendarAppointment(params, next))
      : dispatch(cancelAppointment(params, next))
})

const styled = withMobileDialog()(
  withStyles(dialogStyles, {name: 'CancelAppointment'})(
    translate()(CancelAppointment)
  )
)

export default connect(mapStateToProps, mapDispatchToProps)(styled)
