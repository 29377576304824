import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '../../../../components/Select/Select'
import {translate} from 'react-i18next'
import {paymentReceiptTypeOptions} from '../../../../config/pages/payslips/list/payslipFields'

export class MetaInfoInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comment: this.props.comment,
      date: this.props.date,
      category: this.props.category
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.comment !== prevProps.comment ||
      this.props.date !== prevProps.date ||
      this.props.category !== prevProps.category
    ) {
      this.setState({
        comment: this.props.comment,
        date: this.props.date,
        category: this.props.category
      })
    }
  }

  onFieldChange = (field) => (event) => {
    const value = event.target.value

    this.setState({
      ...this.state,
      [field]: value
    })
  }

  onCommentChange = this.onFieldChange('comment')
  onDateChange = this.onFieldChange('date')
  onCategoryChange = (value) => {
    this.setState({category: value})
    this.props.onDocumentCategoryChange({
      target: {value}
    })
  }

  render() {
    return [
      <TextField
        label={this.props.t('documentList.date')}
        key='documentDate'
        type='date'
        value={this.state.date}
        onChange={this.onDateChange}
        onBlur={this.props.onDocumentDateChange}
      />,
      <Select
        withTranslate
        withEndAdornment
        pereventPersistSelectedOption
        persistChoice={false}
        fullWidth
        label={this.props.t('payslips.table.paymentReceiptType')}
        key='documentType'
        type='text'
        options={paymentReceiptTypeOptions}
        selected={this.state.category}
        value={this.state.category}
        onChange={this.onCategoryChange}
        onBlur={this.props.onDocumentCategoryChange}
      />,
      <TextField
        label={this.props.t('documentList.comment')}
        key='documentComment'
        type='text'
        placeholder={this.props.t('payslips.document.placeholder.comment')}
        multiline
        rows='4'
        value={this.state.comment}
        onChange={this.onCommentChange}
        onBlur={this.props.onDocumentCommentChange}
      />
    ]
  }
}

export default translate()(MetaInfoInput)
