import React from 'react'
import {connect} from 'react-redux'

import PageTableBase from '../../../components/PageTableBase'
import selectors from '../../../store/auctionSchedules/selectors'
import styled from 'styled-components'

//90 is the height of table header + bottom padding
const TableWrapper = styled.div`
  .RichTable__tableWrapper {
    padding: 0px 30px 30px;
    height: ${(props) => props.numberOfRows * 48 + 90}px;
    background-color: #fff;

    tbody {
      background-color: #fafafa;
    }

    .RichTable__subtable {
      box-shadow: none;
    }
  }
`

export const SchedulesList = (props) => {
  const {rowData} = props
  const data = rowData.schedules || []

  return (
    <TableWrapper className='page' numberOfRows={data.length || 1}>
      <PageTableBase
        fetching={false}
        fetchData={() => {}}
        data={data}
        fields={props.fields}
        hasFooter={false}
        pageConfig={{}}
        noToolbar
        multisort={false}
      />
    </TableWrapper>
  )
}

const mapStateToProps = (state) => ({
  fields: selectors.getSubFields(state)
})

export default connect(mapStateToProps, null)(SchedulesList)
