import Walkin from '../../../pages/CRM/Walkin/Walkin'

export default {
  path: 'walkin',
  label: 'menu.crm.walkin',
  key: 'walkin',
  component: Walkin,
  permissions: {
    entity: 'web.admin.ui.crm.walkin2',
    types: ['READ']
  }
}
