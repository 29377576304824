import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'

import TextField from '@material-ui/core/TextField'
import {getSelectedB2BBusinessPartner} from './../../store/bulkUpload/selectors'
import {translate} from 'react-i18next'

import './B2BBusinessPartnersTextField.styl'

B2BBusinessPartnersTextField.propTypes = {
  t: PropTypes.func.isRequired,
  selectedBp: PropTypes.shape({
    businessPartnerId: PropTypes.number.isRequired,
    legalName: PropTypes.string.isRequired
  })
}

B2BBusinessPartnersTextField.defaultProps = {}

export function B2BBusinessPartnersTextField({t, selectedBp}) {
  const {businessPartnerId, legalName} = selectedBp || {}
  const selectedBpValue =
    businessPartnerId && legalName
      ? `${selectedBp.businessPartnerId} - ${selectedBp.legalName}`
      : ''

  return (
    <TextField
      className='b2bBusinessPartnerDropdown important'
      label={t('businessPartner.selectBusinessPartner')}
      value={selectedBpValue}
      disabled
    />
  )
}

const mstp = (state) => ({
  selectedBp: getSelectedB2BBusinessPartner(state)
})

export default compose(translate(), connect(mstp))(B2BBusinessPartnersTextField)
