import TicketStatusPill from '../../../../components/TicketStatusPill'
import {fontWeight} from '../create/createPayslipFields'
import {TicketStatusFilter} from '../../../../pages/Payslips/components/filters/StatusFilter'
import {PAYMENT_RECEIPT_TYPE} from '../../../entities/ticket'

const summaryTransformAddition = (acc = 0, current = 0) => acc + current

export const paymentReceiptTypeOptions = [
  PAYMENT_RECEIPT_TYPE.BANK_GUARANTEE_CHEQUE,
  PAYMENT_RECEIPT_TYPE.PAYSLIP
]

export const payslipFields = {
  status: {
    key: 'status',
    label: 'payslips.table.status',
    type: 'custom',
    isSortable: false,
    Component: TicketStatusPill,
    isFilterable: true,
    labelRoot: 'ticket.status',
    ignoreEmptyFilter: true,
    filter: TicketStatusFilter
  },
  dealer: {
    type: 'composite',
    key: 'dealer',
    layout: 'rows',
    separator: '\n',
    label: 'payslips.table.dealer',
    children: [
      {
        label: 'dealerList.placeholder.fullName',
        key: 'fullName',
        type: 'string',
        isFilterable: false
      },
      {
        label: 'dealerList.placeholder.companyName',
        key: 'companyName',
        type: 'string',
        isFilterable: false
      },
      {
        label: 'dealerList.placeholder.legalCompanyName',
        key: 'legalCompanyName',
        type: 'string',
        isFilterable: false
      },
      {
        label: 'dealer.page.field.taxId',
        key: 'taxId',
        type: 'string',
        isFilterable: false
      },
      {
        label: 'dealerList.placeholder.phone',
        key: 'phone',
        type: 'string',
        isFilterable: false
      }
    ]
  },
  carLicencePlate: {
    type: 'composite',
    key: 'dealer',
    layout: 'rows',
    separator: '\n',
    label: 'payslips.table.dealer',
    children: [
      {
        key: 'carInternalId',
        label: 'carStatistics.page.field.carIds',
        type: 'string',
        isSortable: false
      },
      {
        key: 'carLicencePlate',
        label: 'car.page.field.plate',
        type: 'string',
        isSortable: false,
        isFilterable: false
      }
    ]
  },
  amount: {
    key: 'amount',
    label: 'payslips.table.amount',
    type: 'number',
    isSortable: false,
    isFilterable: false
  },
  dealerManager: {
    key: 'dealerManager',
    label: 'dealer.page.field.dealerManager',
    type: 'string',
    isSortable: false,
    isFilterable: false
  },
  submitted: {
    key: 'createdAt',
    label: 'payslips.table.submitted',
    type: 'date',
    time: true,
    isSortable: false,
    isFilterable: false
  },
  dealerId: {
    key: 'dealerId',
    label: 'dealer.page.field.dealerId',
    type: 'string',
    isSortable: false,
    isIdField: true
  },
  subtable: {
    type: 'table',
    key: 'subtableData'
  }
}

export const payslipSubtableFields = [
  {
    key: 'internalId',
    label: 'carStatistics.page.field.carIds',
    type: 'link',
    alternativeKey: 'car',
    externalUrl: true,
    openLinkInNewTab: true
  },
  {
    key: 'make',
    label: 'car.page.field.make',
    type: 'string'
  },
  {
    key: 'model',
    label: 'car.page.field.model',
    type: 'string'
  },
  {
    key: 'year',
    label: 'car.page.field.year',
    type: 'string'
  },
  {
    key: 'category',
    label: 'payslips.table.paymentReceiptType',
    type: 'select',
    alwaysEditable: true,
    editableFieldType: 'select',
    isEditable: true,
    filterable: false,
    withTranslate: true,
    withEndAdornment: true,
    pereventPersistSelectedOption: true,
    disableClear: true,
    fullWidth: false,
    disableUnderline: true,
    cursor: 'pointer',
    options: paymentReceiptTypeOptions,
    persistChoice: false,
    translateLabels: true
  },
  {
    key: 'outstanding',
    label: 'payslips.table.outstanding',
    type: 'number'
  },
  {
    key: 'amount',
    label: 'payslips.table.amount',
    type: 'number',
    isEditable: true
  }
]

export const payslipSubtableSummaryFields = [
  {
    type: 'string',
    label: 'total',
    key: 'total',
    value: 'global.total.label',
    translate: true,
    style: {fontWeight}
  },
  {
    key: 'make',
    label: 'car.page.field.make',
    type: 'string'
  },
  {
    key: 'model',
    label: 'car.page.field.model',
    type: 'string'
  },
  {
    key: 'year',
    label: 'car.page.field.year',
    type: 'string'
  },
  {
    key: 'category',
    label: 'payslips.subtable.paymentReceiptType.label',
    type: 'string'
  },
  {
    key: 'outstanding',
    label: 'payslips.subtable.receipts.outstanding',
    type: 'number',
    style: {fontWeight},
    summaryTransform: summaryTransformAddition
  },
  {
    key: 'amount',
    label: 'payslips.subtable.receipts.amount',
    type: 'number',
    style: {fontWeight},
    summaryTransform: summaryTransformAddition
  }
]
