const dealerDetails = require('./dealerManagement/cl')
const crmDetails = require('./CRM/cl')
const bulkuploadDetails = require('./bulkUpload/cl')
const carDataDetails = require('./carData/cl')
const locationDetails = require('./locationManagement/cl')
const payslipDetails = require('./payslips/cl')
const carOwnership = require('./carOwnership/cl')
const {sfGoLiveDate} = require('./CRM/cl')
const businessPartnersDetails = require('./businessPartnersDetails/cl')

module.exports = {
  url: 'vendenostuauto.cl',
  siteCode: 'olxcl',
  brand: 'olx-autos',
  apiDomain: 'api.olxautos.cl',
  name: 'Chile',
  countryCode: 'CL',
  theme: 'chile',
  language: {
    selected: 'es-cl',
    options: [
      {value: 'es-cl', label: 'ES'},
      {value: 'en-cl', label: 'EN'}
    ]
  },
  locales: ['es-cl', 'en-cl'],
  currency: {code: 'CLP', symbol: '$'},
  number: {decimalSep: ',', unitSep: ',', units: [1000]},
  fallbackLocale: 'es-cl',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [9], code: 56},
  useExactPhoneNumberSearch: true,
  referralCodeType: 'input',
  googleKey:
    '940673976550-naj8k2mhp404ljudl23fsgmug4vr9bhf.apps.googleusercontent.com',
  okta: {
    domain: 'olxgroup.okta-emea.com',
    clientId: '0oa83j70dqycbydKB0i7'
  },
  dealerDetails,
  disabledBookingSources: ['Web Lead'],
  crmDetails,
  carDuplicatedCheckField: 'licensePlate',
  auctionPreviewOffset: 60,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      licensePlate: {
        regex: '^[A-Z]{2}[0-9]{4}|[A-Z]{4}[0-9]{2}$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'bulkUploadV2',
    'attendanceManager',
    'documentManagement',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  isLeadGatewayEnabled: true,
  salesForceGoLiveDate: sfGoLiveDate,
  hideFromMenu: {
    category: false
  },
  callingCode: '+56',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  categoryId: 84,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: true,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  isNotificationsEnabled: true
}
