import AuctionSchedules from '../../../../pages/AuctionsManagement/Schedules/List'
import {schedulesFields, schedulesSubtableFields} from './schedulesFields'

export default {
  path: 'schedules',
  label: 'menu.auctionsManagement.schedules',
  key: 'schedules',
  sort: [{field: 'name', order: 'ASC'}],
  limit: 25,
  filters: {},
  page: 1,
  fields: {...schedulesFields},
  subtableFields: {...schedulesSubtableFields},
  hiddenFields: ['id'],
  hidden: {},
  permissions: {
    entity: 'web.admin.ui.auctionsManagement.schedules',
    types: ['READ']
  },
  component: AuctionSchedules
}
