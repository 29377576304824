import * as types from './types'
// Category list
export const fetchCategoryList = (payload) => ({
  type: types.fetchCategoryList,
  payload
})

export const fetchCategoryListSuccess = (payload) => ({
  type: types.fetchCategoryListSuccess,
  payload
})

export const fetchCategoryListError = (error) => ({
  type: types.fetchCategoryListError,
  payload: error
})

// Downloading category list item
export const downloadCategoryListItem = (payload) => ({
  type: types.downloadCategoryListItem,
  payload
})

export const downloadCategoryListItemSuccess = (payload) => ({
  type: types.downloadCategoryListItemSuccess,
  payload
})

export const downloadCategoryListItemError = (payload) => ({
  type: types.downloadCategoryListItemError,
  payload
})

// Download category data
export const downloadCategoryData = (payload) => ({
  type: types.downloadCategoryData,
  payload
})

export const downloadCategoryDataSuccess = (payload) => ({
  type: types.downloadCategoryDataSuccess,
  payload
})

export const downloadCategoryDataError = (error) => ({
  type: types.downloadCategoryDataError,
  payload: error
})

// Upload category file data
export const uploadCategoryData = (payload) => ({
  type: types.uploadCategoryData,
  payload
})

export const uploadCategoryDataSuccess = (payload) => ({
  type: types.uploadCategoryDataSuccess,
  payload
})

export const uploadCategoryDataError = (error) => ({
  type: types.uploadCategoryDataError,
  payload: error
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})
