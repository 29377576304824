import * as types from './types'
import moment from 'moment'
import {calendarSchema} from './initialState'
import {getMonthValues} from './helpers'

const initialize = (state, action) => {
  const {calendarId, isRange, selected} = action.payload
  const schema = calendarSchema()

  if (typeof selected !== 'undefined') {
    if (isRange === true) {
      schema.activeRange = selected
    }
    schema.selected = selected
  }

  return {
    ...state,
    [calendarId]: {
      ...schema,
      isRange
    }
  }
}

export default {
  [types.initialize]: initialize,
  [types.selectDay]: (state, action) => {
    const {calendarId, day} = action.payload
    const currentMonth = getMonthValues(moment(day))
    const lastMonth = getMonthValues(moment(day).subtract(1, 'month'))

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        selected: day,
        activeRange: {from: day, to: day},
        months: [lastMonth, currentMonth]
      }
    }
  },
  [types.destroy]: (state, action) => {
    const {calendarId} = action.payload
    const stateReplica = {...state}

    if (typeof stateReplica[calendarId] !== 'undefined') {
      delete stateReplica[calendarId]
    }

    return {...stateReplica}
  },
  [types.applyRangeChange]: (state, action) => {
    const {calendarId} = action.payload
    const selected =
      state[calendarId].isRange === true
        ? {...state[calendarId].selectedRange}
        : state[calendarId].selectedRange.from

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        activeRange: selected,
        isBeingChanged: false,
        selected
      }
    }
  },
  [types.reset]: (state, action) => {
    const {calendarId} = action.payload

    return {
      ...state,
      [calendarId]: {
        ...calendarSchema()
      }
    }
  },
  [types.fullRangeChange]: (state, action) => {
    const {calendarId, startDate, endDate} = action.payload
    const from = moment(startDate).startOf('day')
    const to = moment(endDate).endOf('day')

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isBeingChanged: false,
        activeRange: {from, to},
        selected: {from, to}
      }
    }
  },
  [types.startRangeChange]: (state, action) => {
    const {calendarId, startDate} = action.payload
    const to = moment(startDate).endOf('day')
    const from = moment(startDate).startOf('day')

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isBeingChanged: true,
        activeRange: {from, to},
        selected: {from, to}
      }
    }
  },
  [types.completeRangeChange]: (state, action) => {
    const {calendarId, endDate} = action.payload
    const selected = {...state[calendarId].selected, to: endDate.endOf('day')}

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isBeingChanged: false,
        activeRange: {...selected},
        selected
      }
    }
  },
  [types.cancelRangeChange]: (state, action) => {
    const {calendarId} = action.payload

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isBeingChanged: false,
        selected: {...state[calendarId].activeRange}
      }
    }
  },
  [types.applyRangePreset]: (state, action) => {
    const {calendarId, dates, label} = action.payload
    const selected =
      state[calendarId].isRange === true ? {...dates} : dates.from

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isBeingChanged: false,
        activeRange: dates,
        activePreset: label,
        selected
      }
    }
  },
  [types.changeVisibleMonths]: (state, action) => {
    const {calendarId, difference} = action.payload
    const currentVisible = [...state[calendarId].months]
    const nextVisible =
      difference > 0
        ? currentVisible.map((month) =>
            getMonthValues(month.value.add(Math.abs(difference), 'month'))
          )
        : currentVisible.map((month) =>
            getMonthValues(month.value.subtract(Math.abs(difference), 'month'))
          )

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        months: [...nextVisible]
      }
    }
  },
  [types.toggleOpen]: (state, action) => {
    const {calendarId} = action.payload

    return {
      ...state,
      [calendarId]: {
        ...state[calendarId],
        isOpen: !state[calendarId].isOpen
      }
    }
  }
}
