import {Component} from 'react'
import styled from 'styled-components'

import {withStyles} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import {green} from '@material-ui/core/colors'
import {CAR, JOB_TYPE} from '../TransitJobs/constants'

const GreenCheckbox = withStyles({
  root: {
    color: green[600]
  },
  checked: {}
})((props) => <Checkbox color='default' {...props} />)

const TileWrapper = styled.div`
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
`

const Tile = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 500;
  color: ${(props) => (props.isChecked ? '#000' : '#959595')};
  font-size: 13px;
`

const Info = styled.span`
  color: ${(props) => (props.isChecked ? '#6a6a6a' : '#d7d7d7')};
  font-size: 13px;
`

class TransitJobTile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: true,
      endMileage: this.props.endMileage || null
    }
  }

  handleChange = () => {
    if (!this.props.isTransitJobFinished) {
      this.setState(
        {
          checked: !this.state.checked
        },
        () => {
          if (!this.state.checked) {
            this.setState({endMileage: this.props.endMileage || null})
          }

          this.props.onSelect(this.props.id, this.state.checked)
        }
      )
    }
  }

  updateEndMileage = (e) => {
    const {value} = e.target

    this.setState(
      {
        endMileage: value ? parseInt(value) : null
      },
      () => {
        this.props.updateMileage(this.props.id, {
          carId: this.props.carId,
          endMileage: this.state.endMileage
        })
      }
    )
  }

  render() {
    const {countryCode, car} = this.props
    const {make, model, year, licensePlate, internalId} = car

    return (
      <TileWrapper>
        <Tile onClick={this.handleChange}>
          <Heading>
            <Title isChecked={this.state.checked}>
              {`${make} ${model} ${year}`}
            </Title>
            <Info isChecked={this.state.checked}>
              {`${licensePlate} · ${countryCode}-${internalId}`}
            </Info>
          </Heading>
          <GreenCheckbox checked={this.state.checked} />
        </Tile>
        {this.state.checked &&
        this.props.type === JOB_TYPE.DROPOFF &&
        this.props.transitType === CAR ? (
          <TextField
            type='number'
            label={this.props.t('transitList.placeholder.end.mileage')}
            value={this.state.endMileage}
            onChange={this.updateEndMileage}
            name='endMileage'
          />
        ) : (
          ''
        )}
      </TileWrapper>
    )
  }
}

export default TransitJobTile
