import StatusTransitJobs from '../../../pages/TransitJobs/Status'
import {
  PlacesFilterCurrent,
  DriversFilter,
  StatusFilter
} from '../../../pages/TransitJobs/Filters/Filters'

export const transitJobsField = {
  status: {
    key: 'status',
    label: 'transitJobsList.placeholder.status',
    type: 'custom',
    Component: StatusTransitJobs,
    filter: StatusFilter,
    isSortable: true,
    isFilterable: true
  },
  jobId: {
    type: 'string',
    key: 'internalId',
    label: 'transitJobsList.placeholder.jobId',
    isSortable: false,
    isFilterable: false
  },
  startPlace: {
    label: 'transitJobsList.placeholder.startPlace',
    key: 'startPlace',
    type: 'string',
    isFilterable: true,
    isSortable: true,
    filter: PlacesFilterCurrent
  },
  endPlace: {
    label: 'transitJobsList.placeholder.endPlace',
    key: 'endPlace',
    type: 'string',
    isFilterable: true,
    isSortable: true,
    filter: PlacesFilterCurrent
  },
  startTime: {
    label: 'transitJobsList.placeholder.startTime',
    key: 'startTime',
    type: 'date',
    time: true,
    isFilterable: true,
    isSortable: true
  },
  endTime: {
    label: 'transitJobsList.placeholder.endTime',
    key: 'endTime',
    type: 'date',
    time: true,
    isFilterable: true,
    isSortable: true
  },
  driver: {
    label: 'transitJobsList.placeholder.driver',
    key: 'driver',
    type: 'string',
    isFilterable: true,
    isSortable: true,
    filter: DriversFilter
  },
  cars: {
    label: 'transitJobsList.placeholder.cars',
    key: 'numberOfCars',
    type: 'number',
    isFilterable: false,
    isSortable: false
  }
}

export const transitJobsCarSubtableFields = {
  status: {
    key: 'status',
    label: 'transitJobsList.placeholder.status',
    type: 'custom',
    Component: StatusTransitJobs,
    isSortable: false,
    isFilterable: true
  },
  id: {
    type: 'string',
    key: 'internalId',
    label: 'transitJobsList.placeholder.carId',
    isSortable: false,
    isFilterable: true
  },
  licensePlate: {
    key: 'licensePlate',
    label: 'transitJobsList.placeholder.licensePlate',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  carDetails: {
    key: 'carDetails',
    label: 'transitJobsList.placeholder.carDetails',
    type: 'string',
    isFilterable: false,
    isSortable: false
  }
}
