export const placeFields = [
  'id',
  'bIMapping',
  'region',
  'city',
  'country',
  'address1',
  'location',
  'lat',
  'lng',
  'metaInfo',
  'active',
  'type',
  'timezone',
  'timezoneOffset',
  'areaManager',
  'areaManagerName',
  'sapPlantId',
  'sapStorageLocationId'
]

export const areaFields = ['id', 'placeId', 'name', 'type', 'floor']
export const areasListFields = [['list', [...areaFields]]]

export const listFields = [['list', [...placeFields]]]

export const countryOptionsListFields = [['list', ['name']]]

export const lotFields = ['id', 'carId', 'progressiveId']
export const lotsListFields = [['list', [...lotFields]]]

export const carFields = ['id', 'color', 'licensePlate', 'make', 'model', 'internalId']
export const carsListFields = [['list', [...carFields]]]
