import react, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Table, Dropdown} from 'motofin-ui'
import './index.styl'
import * as CarApi from '../../api/car/requests'
import * as ContactApi from '../../api/contact/requests'
import {error} from '../../store/global/actions'
import {format} from 'date-fns'

const DEFAULT__CITY = [
  {
    key: '',
    title: 'All Cities',
    value: ''
  }
]

const DDayBookings = ({countryCode, setError}) => {
  const [tableData, setTableData] = useState([])
  const [cityList, setCityList] = useState([...DEFAULT__CITY])
  const [selectedCity, setSelectedCity] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [sort, setSort] = useState({})

  const fetchCityList = () => {
    CarApi.getCountryOptions(
      null,
      {country: countryCode},
      {city: {type: 'locationCity'}}
    )
      .then((res) => {
        setCityList([
          ...(res?.city?.list &&
            res?.city?.list.map(({name}) => ({
              key: name,
              title: name,
              value: name
            }))),
          ...DEFAULT__CITY
        ])
      })
      .catch((error) => {
        setError(error)
      })
  }

  const fetchTableData = () => {
    setIsLoading(true)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(18, 0, 0, 0)
    const createdAt = {
      from: yesterday.toISOString(),
      to: new Date().toISOString()
    }
    const currentDate = new Date().toISOString().slice(0, 10)
    const sortData = Object.keys(sort).map((key) => ({
      field: key,
      order: sort[key]
    }))
    ContactApi.getAppointmentList({
      appointmentData: {
        country: countryCode,
        inspectionPointCity: selectedCity,
        createdAt,
        date: {
          from: currentDate,
          to: currentDate
        },
        createdByUserEmail: 'api-user-panamera',
        type: 'purchaseInspectionHome'
      },
      sort: sortData
    })
      .then((res) => {
        setTableData(res?.appointment?.list)
        setIsLoading(false)
      })
      .catch((error) => {
        setTableData([])
        setError(error)
        setIsLoading(false)
      })
  }

  const formattedDate = (value) => {
    const date = new Date(value)
    const dateStr = format(date, 'dd MMM yyyy')
    const timeStr = format(date, 'hh:mm a')
    return (
      <span>
        {dateStr}
        <br />
        <strong>{timeStr}</strong>
      </span>
    )
  }

  const handleSorting = async (key, sortType) => {
    switch (true) {
      case sortType === 'DEFAULT':
        break
      case key === 'date':
        setSort({date: sortType, time: sortType})
        break
      default:
        setSort({[key]: sortType})
        break
    }
  }

  const handleCityChange = ({key}) => {
    setSelectedCity(key)
  }

  const renderCityDropdown = () => {
    return (
      <Dropdown
        category='white'
        onChange={handleCityChange}
        defaultValue={selectedCity}
        options={cityList}
        conatinerClass='cityDropdown'
        dropdownClass='cityDropdownPicker'
        dropdownContentClass='cityDropdownPickerOptions'
        test-id='cityDropdown'
      />
    )
  }

  useEffect(() => {
    fetchCityList()
  }, [])

  useEffect(() => {
    fetchTableData()
  }, [sort, selectedCity])

  const columns = [
    {
      key: 'id',
      render: (_, data) => data?.lead?.externalLeadId,
      title: 'Lead ID'
    },
    {
      key: 'date',
      render: (_, data) =>
        data?.date &&
        data?.time &&
        formattedDate(`${data?.date} ${data?.time}`),
      title: 'Appointment Time',
      sortable: true
    },
    {
      key: 'inspectionPointCity',
      title: 'City',
      headingRender: renderCityDropdown
    },
    {
      key: 'address',
      title: 'Address',
      render: (_, data) => (
        <div className='address'>
          <p>{data?.address}</p>
          <span>{data?.address}</span>
        </div>
      )
    },
    {
      key: 'status',
      title: 'Booking Status',
      type: 'string'
    },
    {
      key: 'assignedToName',
      title: 'Assigned To',
      type: 'string'
    },
    {
      key: 'createdByUserEmail',
      title: 'Created By',
      type: 'string'
    },
    {
      key: 'createdAt',
      title: 'Created Time',
      render: (_, data) => formattedDate(data?.createdAt),
      sortable: true
    }
  ]
  return (
    <div className='DDayBookings'>
      <Table
        columns={columns}
        data={tableData}
        handleSort={handleSorting}
        hidePagination
        loading={isLoading}
        type='zebra'
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setError: (params) => dispatch(error(params))
})

export default connect(null, mapDispatchToProps)(DDayBookings)
