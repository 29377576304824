import {Component} from 'react'
import omit from 'lodash/omit'

const externalCache = {}

export const withAsyncSource = (config) => (WrappedComponent) => {
  // TODO: Move to default props
  if (typeof config.queryParams === 'undefined') {
    config.queryParams = {limit: 100, page: 1}
  }

  class AsyncList extends Component {
    state = {
      ...config.queryParams,
      fetching: false,
      error: false
    }

    _isMounted = false
    lastValue = null
    /**
     * Cache structure:
     * {
     *   searchTerm: {
     *     pageX: {},
     *     ...
     *   },
     *   ...
     * }
     */

    componentDidMount() {
      this._isMounted = true

      if (this._isMounted) {
        this.makeApiCall({})
      }
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const nextParams = JSON.stringify(nextProps.queryParams)
      const currentParams = JSON.stringify(this.props.queryParams)

      if (nextParams !== currentParams) {
        this.updateQuery(nextProps.queryParams)
      }
    }

    cacheData(data, {anyName, page}, setCacheCallback) {
      const name = anyName || ''

      if (!externalCache[name]) {
        externalCache[name] = {[page]: data}
      }

      if (externalCache[name] && !externalCache[name][page]) {
        externalCache[name][page] = data
      }

      setCacheCallback()
    }

    // {limit, page, deletedUser, group: {exclude, include}, anyName}
    shouldUseCachedData = (cache, props, {anyName, page}) => {
      const name = anyName || ''

      if (!props.useCaching || !cache[name] || !cache[name][page]) {
        return false
      }

      return true
    }

    getCache = (cache, {anyName, page}) => {
      const name = anyName || ''

      if (typeof page === 'undefined') {
        return cache
      }

      return cache[name][page]
    }

    updateQuery = (params) => {
      this.setState({page: 1}, () => this.makeApiCall(params))
    }

    requestPrevPage = (params = {}) => {
      if (this.state.page > 1) {
        this.setState({page: this.state.page - 1}, () =>
          this.makeApiCall({params: {...params, page: this.state.page}})
        )
      }
    }

    requestNextPage = (params = {}) => {
      const hasNextPage =
        typeof this.props.count !== 'undefined'
          ? Math.ceil(this.props.count / this.state.limit) > this.state.page
          : false

      if (hasNextPage === true) {
        this.setState({page: this.state.page + 1}, () =>
          this.makeApiCall({params: {...params, page: this.state.page}})
        )
      }
    }

    makeApiCall = ({params = {}, value}) => {
      if (this.state.fetching && this.props.debounceRequest) {
        this.lastValue = value

        return
      }

      const args =
        typeof value !== 'undefined' && value !== null
          ? this.props.queryBuilder({...this.props.queryParams, value})
          : this.props.queryBuilder({...this.props.queryParams})

      const queryParams = {...config.queryParams, ...args, ...params}

      return new Promise((resolve, reject) => {
        this.setState({fetching: true, error: false}, () => {
          // TODO: Temporary! Improve upon this with a general solution
          const shouldUseCachedData = this.shouldUseCachedData(
            externalCache,
            this.props,
            queryParams
          )

          if (shouldUseCachedData) {
            const allCacheForName = this.getCache(externalCache, {
              anyName: queryParams.anyName
            })
            const currentPageCache = this.getCache(externalCache, queryParams)

            this.props.onLoad({
              ...currentPageCache,
              all: allCacheForName,
              page: this.state.page
            })
            this.setState({fetching: false, error: false})

            return
          }

          config
            .loadData(queryParams, {formatLabel: this.props.formatLabel})
            .then((response) => {
              if (this.props.useCaching) {
                this.cacheData(response, queryParams, () => {
                  const allCacheForName = this.getCache(externalCache, {
                    anyName: queryParams.anyName
                  })
                  const currentPageCache = this.getCache(
                    externalCache,
                    queryParams
                  )

                  this.props.onLoad({
                    ...currentPageCache,
                    all: allCacheForName,
                    page: this.state.page
                  })
                })

                return
              }

              this.props.onLoad({
                ...response,
                all: null,
                page: this.state.page
              })
            })
            .then(() => {
              this.setState({fetching: false, error: false}, () => {
                if (this.lastValue) {
                  this.makeApiCall({params, value: this.lastValue})
                  this.lastValue = null
                }
              })
              resolve()
            })
            .catch((e) => {
              this.setState({fetching: false, error: true})
              this.lastValue = null
              reject(e)
            })
        })
      })
    }

    render() {
      const rest = omit(this.props, ['loadData', 'onLoad'])
      const total = Math.ceil(this.props.count / this.state.limit)
      const paginationOptions = {
        fetching: this.state.fetching,
        page: this.state.page,
        total,
        makeApiCall: this.makeApiCall,
        updateQuery: this.updateQuery,
        hasNextPage: this.state.page < total,
        hasPrevPage: this.state.page > 1,
        requestNextPage: this.requestNextPage,
        requestPrevPage: this.requestPrevPage
      }

      return (
        <WrappedComponent
          {...rest}
          withAsyncSource
          isFetching={this.state.fetching}
          paginationOptions={paginationOptions}
        />
      )
    }
  }

  AsyncList.defaultProps = {
    queryBuilder: config.queryBuilder,
    queryParams: config.queryParams
  }

  AsyncList.displayName = 'AsyncList'

  return AsyncList
}

export default withAsyncSource
