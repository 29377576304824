import {SimpleApiClient} from '@fcg/admin-api-client'
import getQueryString from '../helpers/queryParamUtil'
import {getAdditionalHeaders} from './utils'
import Country from '../types/Country'

export const downloadCSV = (
  countryCode: Country,
  dateFrom: string,
  dateTo: string,
  locationName: string,
  callBack: (data: {csv: string}) => void
) => {
  const params = {
    deletedUser: false,
    userClass: 'INTERNAL',
    country: countryCode,
    dateFrom,
    dateTo,
    locationName,
    date: Date.now()
  }
 const downloadClient = new SimpleApiClient('logistics', getAdditionalHeaders(countryCode))
  return downloadClient
    .query(`rcm/csv-download?${getQueryString(params)}`, '', false, 'GET')
    .then((response: Response) => {
      callBack(response)
    })
}
