import React, {useContext} from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'

import {GlobalContext} from '../../../context/GlobalContext'

import SectionWrapper from '../../../components/Sections/SectionWrapper'
import TextFieldWithErrorMessage from '../../../components/TextField/TextFieldWithErrorMessage'
import SaveButton from './components/SaveButton'
import RemindSaveDialog from './components/RemindSaveDialog'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import {formGlobalState} from '../../../hooks/useValidation/validationHelpers'
import useValidation from '../../../hooks/useValidation/useValidation'
import useConfigurationActions from './useConfigurationActions'

const GlobalConfiguration = () => {
  const {onSave} = useConfigurationActions({global: true})
  const classes = useCommonStyles()
  const {globalPlaceConfiguration} = useContext(GlobalContext)
  const initialState = formGlobalState(globalPlaceConfiguration!)
  const {state, disable, onChange, handleOnSubmit} = useValidation(
    initialState,
    onSave
  )
  const {email, image} = state

  return (
    <SectionWrapper
      twoColumn
      title='General Information'
      title2='Default location image'
    >
      <Grid container>
        <form
          autoComplete='off'
          className={cx(classes.flex, classes.fullWidth, classes.marginBottom6)}
        >
          <Grid container justify='center' spacing={2}>
            <Grid item xs={6}>
              <TextFieldWithErrorMessage
                error={email.error}
                type='email'
                label='Email address'
                // label={t('search.button')}
                onChange={(value) => {
                  onChange('email', value)
                }}
                dataTest='email-address'
                value={email.value}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithErrorMessage
                error={image.error}
                label='Image Source'
                // label={t('search.button')}
                onChange={(value) => {
                  onChange('image', value)
                }}
                dataTest='image-source'
                value={image.value}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={cx(classes.absolute, classes.rightBottom)}
          >
            <SaveButton onSubmit={handleOnSubmit} disabled={disable} dataTest='save-button'/>
          </Grid>
        </form>
      </Grid>
      {/* For now `RemindSaveDialog` is both here and `PlaceConfiguration` for consistency,
      but it can also be carried parent component and directly wrapped with `withActions` */}
      <RemindSaveDialog onSave={handleOnSubmit} />
    </SectionWrapper>
  )
}

export default GlobalConfiguration
