import gql from '@fcg/lib-gql/gql'
import List from '../../../pages/CRM/LeadAllocation/List'
import tierFormConfig from '../crm/forms/tierFormConfig'
import {NumberInputFilter} from '../../../pages/CarData/Filters'
import {UserGroupFilter} from '../../../pages/CRM/LeadAllocation/Filters'

export const tierActions = ['ROUND_ROBIN']
export const tierActionConst = tierActions.reduce(
  (acc, action) => ({
    ...acc,
    [action]: action
  }),
  {}
)
export const tierActionsReqConst = tierActions.reduce(
  (acc, action) => ({
    ...acc,
    [action]: new gql.EnumType(action)
  }),
  {}
)

export const leadAllocationListFields = {
  tierName: {
    key: 'tierName',
    label: 'leadallocation.list.name',
    type: 'string',
    filterKey: 'name'
  },
  tierLeadSource: {
    key: 'tierLeadSource',
    label: 'leadallocation.list.leadSource',
    type: 'string',
    filterKey: 'leadSource'
  },
  tierUserGroupLabel: {
    key: 'tierUserGroupLabel',
    label: 'leadallocation.list.userGroup',
    type: 'string',
    filter: UserGroupFilter,
    filterKey: 'userRoleId'
  },
  tierLeadStatus: {
    key: 'tierLeadStatus',
    label: 'leadallocation.list.status',
    type: 'string',
    filterKey: 'leadStatus'
  },
  tierMake: {
    key: 'tierMake',
    label: 'carData.column.make',
    type: 'string',
    columnName: 'tierMake',
    filterKey: 'tierMake'
  },
  tierAppointmentStatus: {
    key: 'tierAppointmentStatus',
    label: 'appointment.status.label',
    type: 'string',
    columnName: 'tierAppointmentStatus',
    filterKey: 'tierAppointmentStatus'
  },
  tierMaxLeads: {
    key: 'tierMaxLeads',
    label: 'leadallocation.list.maxLeads',
    type: 'number',
    filter: NumberInputFilter,
    filterKey: 'maxAssignments'
  },
  tierLocation: {
    key: 'tierLocation',
    label: 'location.name.label',
    type: 'string',
    columnName: 'tierLocation',
    filterKey: 'tierLocation'
  }
}

export default {
  path: 'leadAllocation',
  label: 'menu.crm.leadAllocation',
  component: List,
  sort: null,
  fields: {
    ...leadAllocationListFields
  },
  form: {...tierFormConfig}
}
