import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../../utils/typesUtil'

export const resourceName = 'allocation'

export const fetchTiers = `${resourceName}/FETCH_TIERS`
export const createTier = `${resourceName}/CREATE_TIER`
export const updateTier = `${resourceName}/UPDATE_TIER`
export const deleteTier = `${resourceName}/DELETE_TIER`
export const createTierSuccess = `${resourceName}/CREATE_TIER_SUCCESS`
export const createTierError = `${resourceName}/CREATE_TIER_ERROR`
export const updateTierSuccess = `${resourceName}/UPDATE_TIER_SUCCESS`
export const udpateTierError = `${resourceName}/UPDATE_TIER_ERROR`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`

export const formTypes = getFormTypes(resourceName)
export const sortTypes = getSortTypes(resourceName)
export const columnTypes = getColumnTypes(resourceName)
export const dataLoadingTypes = getDataLoadingTypes(resourceName)
