export const carDataInterceptor = {
  response: (data) => {
    const fields = [
      'make',
      'model',
      'trim',
      'transmission',
      'year',
      'location',
      'bodytype',
      'bodyType'
    ]
    const field = fields.find((key) => typeof data[key] !== 'undefined')

    if (typeof data[field] !== 'undefined') {
      return data[field].list
    }

    return data
  }
}
