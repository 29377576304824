import {withValidation, validatorFields} from '@fcg/formvalidation'
import {TextField} from '@material-ui/core'
import errorMessageAdapter from './errorMessageAdapter'
import {translate} from 'react-i18next'

const textFieldValidator = {
  test: () => true,
  validate: (value, props) => {
    if (!value && !props.required) {
      return true
    }

    const regex = new RegExp(props.regex)

    return regex.test(value)
  },
  defaultErrorMessage: (props) => {
    return props.t(`global.error.inputType.${props.id}`)
  },
  errorMessageProp: (props) => `${props.id}Error`
}

const ValidatedTextField = translate()(
  withValidation([textFieldValidator, ...validatorFields.input])(
    errorMessageAdapter(TextField)
  )
)

ValidatedTextField.type = 'ValidatedTextField'

export default ValidatedTextField
