import {Component} from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import {extendMoment} from 'moment-range'
import Month from './Month'
import './Calendar.styl'

const moment = extendMoment(Moment)
moment.updateLocale('en', {
  week: {
    dow: 1
  }
})

const defaultProps = {
  layout: 'double',
  mode: 'month',
  months: null,
  showFuture: true,
  showPast: true,
  isRange: true,
  selected: null,
  onUpdate: () => {},
  selectDay: () => {},
  completeRangeChange: () => {},
  startRangeChange: () => {},
  changeVisibleMonths: () => {}
}

const propTypes = {
  layout: PropTypes.oneOf(['single', 'double']).isRequired,
  mode: PropTypes.oneOf(['week', 'month', 'year']),
  showFuture: PropTypes.bool,
  showPast: PropTypes.bool,
  activeRange: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  isRange: PropTypes.bool,
  months: PropTypes.array,
  onUpdate: PropTypes.func,
  selectDay: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  completeRangeChange: PropTypes.func,
  startRangeChange: PropTypes.func,
  changeVisibleMonths: PropTypes.func
}

export class Calendar extends Component {
  static defaultProps = {...defaultProps}
  static propTypes = {...propTypes}

  state = {
    activeRange: null
  }

  componentDidUpdate(prevProps) {
    const hasNewRange = this.props.activeRange !== prevProps.activeRange
    const hasNewMonths = this.props.months !== prevProps.months

    if (hasNewRange || hasNewMonths) {
      this.setup()
    }
  }

  componentDidMount() {
    this.setup()
  }

  setup() {
    const activeRange =
      typeof this.props.activeRange !== 'undefined' &&
      this.props.activeRange.from !== null
        ? moment.range(this.props.activeRange.from, this.props.activeRange.to)
        : null

    this.setState({activeRange})
  }

  buildMonth = (date) => {
    const baseRange = moment(date).range('month')
    const {start, end} = baseRange
    const startOfWeek = moment(start).startOf('week')
    const endOfWeek = moment(end).endOf('week')
    const totalRange = moment.range(startOfWeek, endOfWeek)

    return {totalRange, baseRange}
  }

  handleDayClick = (day) => {
    if (this.props.isRange === false) {
      const date = moment(day)
      this.props.dispatch(this.props.selectDay(date))
      this.props.onUpdate(date)
    } else {
      const {selected, completeRangeChange, startRangeChange} = this.props
      const from = moment(day).startOf('day')
      const to = moment(day).endOf('day')

      if (this.props.isBeingChanged === true) {
        this.props.dispatch(completeRangeChange(day))
        this.props.onUpdate({...selected, to})
      } else {
        this.props.dispatch(startRangeChange(day))
        this.props.onUpdate({from, to})
      }
    }
  }

  render() {
    const {layout, innerRef, changeVisibleMonths, selected, months} = this.props
    const {activeRange} = this.state

    if (!months) {
      return null
    }

    if (layout === 'single') {
      return (
        <div className='calendar' ref={innerRef}>
          <Month
            {...this.buildMonth(months[1].value)}
            label={months[1].label}
            activeRange={activeRange}
            handleDayClick={this.handleDayClick}
            selected={selected}
            direction='both'
            isRange={this.props.isRange}
            changeVisibleMonths={(params) => {
              this.props.dispatch(changeVisibleMonths(params))
            }}
          />
        </div>
      )
    }

    return (
      <div className='calendar' ref={innerRef}>
        <div className='months__wrapper'>
          {months.map((month, index) => (
            <Month
              key={index}
              isRange={this.props.isRange}
              isBeingChanged={this.props.isBeingChanged}
              activeRange={activeRange}
              direction={index === months.length - 1 ? 'forward' : 'back'}
              label={month.label}
              handleDayClick={this.handleDayClick}
              selected={selected}
              changeVisibleMonths={(params) => {
                this.props.dispatch(changeVisibleMonths(params))
              }}
              {...this.buildMonth(month.value)}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default Calendar
