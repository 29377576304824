import {compose} from 'redux'
import {useAuth} from 'react-oidc-context'
import {withStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'

const styles = () => ({
  containerDiv: {
    width: '100%'
  },
  button: {
    backgroundColor: '#012a7a',
    color: '#fff',
    width: '100%',
    height: '36px',
    fontSize: '16px',
    outline: 'none',
    border: 'none',
    boxShadow: '2px 2px 2px 1px #888',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '8px auto 0',

    '&:hover': {
      backgroundColor: '#012a5a',
      boxShadow: 'inset 0px 0px 6px 0px #222'
    }
  }
})

export function JumpCloudLoginButton(props) {
  const auth = useAuth()
  const {classes} = props

  const handleLoginJumpCloud = () => {
    auth.signinRedirect()
  }

  return (
    <div className={classes.containerDiv}>
      <Button className={classes.button} onClick={handleLoginJumpCloud}>
        Login with Jumpcloud
      </Button>
    </div>
  )
}

export default compose(withStyles(styles))(JumpCloudLoginButton)
