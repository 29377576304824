import React, {useState, useEffect, useContext} from 'react'

import Dialog from '../../../../components/Dialog/Dialog'
import {ValidationContext} from '../../../../context/ValidationContext'

const RemindSaveDialog = ({onSave = () => {}}) => {
  const {remindSave, resetValidationContext} = useContext(ValidationContext)
  const [open, setOpen] = useState(remindSave)

  useEffect(() => {
    setOpen(remindSave)
  }, [remindSave])

  return (
    <Dialog
      open={open}
      title={'Existing without saving'}
      message={'You are trying to exit the page without saving your changes'}
      onCancel={resetValidationContext}
      confirmLabel='EXIT AND SAVE'
      cancelLabel="DON'T SAVE"
      onConfirm={onSave}
      maxWidth='xs'
    />
  )
}
export default RemindSaveDialog
