import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'

const Edit = ({
  data,
  metaInfo,
  driver,
  updateField,
  updateMetaField,
  updateDriverInfo,
  updateDriverDocumentId,
  updateProfileImage,
  form,
  t,
  options
}) => {
  return (
    <ConfigContext.Consumer>
      {(config) => (
        <FormBuilder
          t={t}
          data={{
            ...metaInfo,
            ...data,
            ...driver,
            metaInfoEmail: metaInfo.email || ''
          }}
          options={options}
          form={form}
          onChangeHandlers={{
            updateField,
            updateMetaField,
            updateProfileImage,
            updateDriverInfo,
            updateDriverDocumentId
          }}
          config={config}
        />
      )}
    </ConfigContext.Consumer>
  )
}

export default Edit
