import {call, put, select, takeLatest} from 'redux-saga/effects'
import omit from 'lodash/omit'
import gql from '@fcg/lib-gql/gql'
import pickBy from 'lodash/pickBy'

import {showSnackbarNotification} from '../signals/actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as DriverApi from '../../api/driver/requests'
import * as DocumentApi from '../../api/document/requests'
import selectors from './selectors'
import {flattenResponse, formatParams} from '../../store/utils'
import {
  setFormSuccess,
  setFormPending,
  setFormError
} from '../../store/driver/actions'
import * as DocumentSelector from '../../store/documentConfig/selectors'
import * as AuthApi from '../../api/auth/requests'

function* saveDriverSaga(action) {
  try {
    let response = {}
    const driver = omit(action.payload, 'imageUpload')
    const profileImage = action.payload.imageUpload
    const driverImage = yield select(selectors.getDriverImage)
    let documents = yield select(DocumentSelector.documentList)
    const metaInfo = {
      ...driver.metaInfo,
      firstName: driver.firstname,
      lastName: driver.lastname
    }

    yield put(setFormPending())

    if (driver.id !== null && typeof driver.id !== 'undefined') {
      yield call(DriverApi.updateDriver, {
        ...omit(driver, [
          'loaded',
          'password',
          'confirmPassword',
          'createdAt',
          'updatedAt',
          'internalId'
        ]),
        metaInfo
      })

      const {email, password: newPassword} = driver

      if (newPassword) {
        yield call(AuthApi.changePassword, {email, newPassword})
      }
    } else {
      const countryCode = yield select(getCountryCode)
      response = yield call(DriverApi.createDriver, {
        ...omit(driver, ['id', 'confirmPassword', 'loaded']),
        country: new gql.EnumType(countryCode),
        metaInfo
      })
    }

    const driverId =
      response && response.createDriver && response.createDriver.id
        ? response.createDriver.id
        : driver.id

    if (profileImage && profileImage.file) {
      yield call(DocumentApi.uploadDocument, {
        documents: [
          {
            visibility: new gql.EnumType('internal'),
            entity: 'driver-profile-image',
            reference: driverId,
            fileName: profileImage.fileName,
            fileType: profileImage.fileType,
            file: profileImage.file.split('base64,')[1]
          }
        ]
      })
    } else {
      if (!profileImage && driverImage) {
        yield call(DocumentApi.deleteDocument, {documentIds: [driverImage.id]})
      }
    }

    if (documents.length > 0) {
      documents = documents.reduce((acc, docObj) => {
        return [
          ...acc,
          {
            entity: 'driver',
            id: docObj.id,
            reference: driverId
          }
        ]
      }, [])

      yield call(DocumentApi.updateDocument, {documents})
    }
    yield put(setFormSuccess(driverId))
  } catch (e) {
    yield put(setFormError())
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

function* fetchDriverListSaga(action) {
  try {
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const filters =
      action.payload && action.payload.filters
        ? action.payload.filters
        : yield select(selectors.getFilters)
    const filterParams = formatParams(filters)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)
    let sort = yield select(selectors.getSort)

    sort =
      sort && Array.isArray(sort) && sort.length
        ? {...sort[0], order: sort[0].order}
        : null

    const options = {
      limit,
      sort,
      page,
      ...{user: filterParams}
    }

    const driverList = yield call(DriverApi.getDrivers, {...pickBy(options)})

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchDriversListSuccess,
      payload: {
        ...driverList.driver,
        list: Object.values(flattenResponse(driverList.driver.list, 2))
      }
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchDriversListError,
      payload: e
    })
  }
}

function* fetchDriverImageSaga(action) {
  try {
    const options = {
      reference: action.payload,
      entity: 'driver-profile-image'
    }
    const response = yield call(DocumentApi.documentList, options)
    const document = response.document.list

    if (document && document.length > 0) {
      const {documentDownloadLink} = yield call(
        DocumentApi.documentDownloadLink,
        {
          documentIds: [document[0].id]
        }
      )

      yield put({
        type: types.setDriverImage,
        payload: documentDownloadLink[0]
      })
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )
  }
}

function* loadDriverSaga(action) {
  try {
    const data = yield call(AuthApi.getDriver, action.payload)

    yield put({
      type: types.driverSet,
      payload: {...data, loaded: true}
    })
  } catch (e) {
    showSnackbarNotification({
      variant: signalTypes.variantTypes.error,
      message: e.message,
      open: true
    })
  }
}

function* fetchDriverOptionsSaga() {
  try {
    const driversList = yield call(DriverApi.getDrivers, {
      user: {
        driver: {isAvailable: true},
        metaInfo: {status: 'active'}
      },
      limit: 500
    })

    yield put({
      type: types.fetchDriverOptionsSuccess,
      payload: driversList.driver.list
    })
  } catch (error) {
    showSnackbarNotification({
      variant: signalTypes.variantTypes.error,
      message: error.message,
      open: true
    })
  }
}

export const sagas = [
  takeLatest(types.saveDriver, saveDriverSaga),
  takeLatest(types.fetchDriverImage, fetchDriverImageSaga),
  takeLatest(types.updateFilters, fetchDriverListSaga),
  takeLatest(types.fetchDriversList, fetchDriverListSaga),
  takeLatest(types.toggleSort, fetchDriverListSaga),
  takeLatest(types.loadDriver, loadDriverSaga),
  takeLatest(types.fetchDriverOptions, fetchDriverOptionsSaga)
]
