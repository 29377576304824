const resourceName = 'bulkUpload'

export const setUploading = `${resourceName}/SET_UPLOADING`

export const uploadDataError = `${resourceName}/UPLOAD_DATA_ERROR`

export const toggleBulkUploadDialogVisibility = `${resourceName}/TOGGLE_BULK_UPLOAD_DIALOG_VISIBILITY`

export const toggleBulkUploadNotificationDialog = `${resourceName}/TOGGLE_BULK_UPLOAD_NOTIFICATION_DIALOG_VISIBILITY`

export const saveChangedData = `${resourceName}/SAVE_CHANGED_DATA`

export const fetchBulkUploadDataList = `${resourceName}/FETCH_BULKUPLOAD_DATA_LIST`
export const fetchBulkUploadDataListSuccess = `${resourceName}/FETCH_BULKUPLOAD_DATA_LIST_SUCCESS`

export const createDuplicateCars = `${resourceName}/CREATE_DUPLICATE_CAR_ANYWAY_REQUEST`

export const selectB2BBusinessPartnerForBulkUpload = `${resourceName}/SELECT_B2B_BP_FOR_BULK_UPLOAD`

export const saveBulkUploadRequest = `${resourceName}/SAVE_BULK_UPLOAD_REQUEST`
export const saveBulkUploadSuccess = `${resourceName}/SAVE_BULK_UPLOAD_SUCCESS`
export const saveBulkUploadError = `${resourceName}/SAVE_BULK_UPLOAD_ERROR`

export const checkDuplication = `${resourceName}/CHECK_DUPLICATION`
export const checkDuplicationSuccess = `${resourceName}/CHECK_DUPLICATION_SUCCESS`

export const updateBulkUploadListVisibilityType = `${resourceName}/UPDATE_LIST_VISIBILITY_TYPE`

export const updateSuccessRequestCounter = `${resourceName}/UPDATE_SUCCESS_REQUEST_COUNTER`
export const updateDuplicateRequestCounter = `${resourceName}/UPDATE_DUPLICATE_REQUEST_COUNTER`
export const updateFailRequestCounter = `${resourceName}/UPDATE_FAIL_REQUEST_COUNTER`
export const uploadCar = `${resourceName}/UPLOAD_CAR`
export const updateFormData = `${resourceName}/UPDATE_FORM_DATA`
export const resetBulkUpload = `${resourceName}/RESET_BULK_UPLOAD`

export const searchCar = `${resourceName}/SEARCH_CAR`

export const updateStep = `${resourceName}/STEP`

export const deleteCar = `${resourceName}/DELETE_CAR`
export const deleteCarSuccess = `${resourceName}/DELETE_CAR_SUCCESS`
export const deleteCarError = `${resourceName}/DELETE_CAR_ERROR`
