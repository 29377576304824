export default () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Icons / Selfinspection / Edit</title>
    <g
      id='Icons-/-Selfinspection-/-Edit'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <path
        d='M21.04,12.63 C21.18,12.63 21.31,12.69 21.42,12.8 L22.7,14.08 C22.92,14.29 22.92,14.64 22.7,14.85 L21.7,15.85 L19.65,13.8 L20.65,12.8 C20.76,12.69 20.9,12.63 21.04,12.63 M19.07,14.38 L21.12,16.43 L15.06,22.5 L13,22.5 L13,20.44 L19.07,14.38 M19,3.5 C20.1,3.5 21,4.4 21,5.5 L21,9.5 L11,19.5 L11,21.5 L5,21.5 C3.9,21.5 3,20.6 3,19.5 L3,5.5 C3,4.4 3.9,3.5 5,3.5 L9.18,3.5 C9.6,2.34 10.7,1.5 12,1.5 C13.3,1.5 14.4,2.34 14.82,3.5 L19,3.5 M12,3.5 C11.45,3.5 11,3.95 11,4.5 C11,5.05 11.45,5.5 12,5.5 C12.55,5.5 13,5.05 13,4.5 C13,3.95 12.55,3.5 12,3.5 Z'
        id='Combined-Shape'
        fill='rgba(0, 0, 0, 0.54)'
      ></path>
      <path
        d='M10.5,15.5 L10.5,17.5 L6.5,17.5 L6.5,15.5 L10.5,15.5 Z M12.5,11.5 L12.5,13.5 L6.5,13.5 L6.5,11.5 L12.5,11.5 Z M16.5,7.5 L16.5,9.5 L6.5,9.5 L6.5,7.5 L16.5,7.5 Z'
        id='Combined-Shape'
        fill='#FFFFFF'
      ></path>
    </g>
  </svg>
)
