import {call, put, takeLatest} from 'redux-saga/effects'
import * as ContactApi from '../../../api/contact/requests'
import {showSnackbarNotification} from '../../signals/actions'
import * as signalTypes from '../../signals/types'
import * as types from './types'

function* fetchParticipantSaga(action) {
  try {
    const response = yield call(ContactApi.getParticipant, {
      appointmentData: {id: action.payload.id}
    })

    const data = response.appointment.list[0].participants

    yield put({
      type: types.fetchParticipantsSuccess,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.fetchParticipantsError,
      payload: error
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* addParticipantSaga(action) {
  try {
    yield call(ContactApi.addParticipant, action.payload)
    yield put({
      type: types.fetchParticipants,
      payload: {
        id: action.payload.appointmentId
      }
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.appointment.participant.updated',
        open: true
      })
    )
  } catch (error) {
    yield put({
      type: types.addParticipantError,
      payload: error
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* removeParticipantSaga(action) {
  try {
    yield call(ContactApi.removeParticipant, action.payload)
    yield put({
      type: types.fetchParticipants,
      payload: {
        id: action.payload.appointmentId
      }
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.appointment.participant.deleted',
        open: true
      })
    )
  } catch (error) {
    yield put({
      type: types.removeParticipantError,
      payload: error
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* updateParticipantSaga(action) {
  try {
    const {role, participantId, appointmentId} = action.payload

    yield call(ContactApi.removeParticipant, {
      participant: [participantId],
      appointmentId: appointmentId
    })

    yield call(ContactApi.addParticipant, {
      appointmentId,
      participant: [{id: participantId, role}]
    })

    yield put({
      type: types.fetchParticipants,
      payload: {
        id: appointmentId
      }
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.appointment.participant.updated',
        open: true
      })
    )
  } catch (error) {
    yield put({
      type: types.addParticipantError,
      payload: error
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchParticipants, fetchParticipantSaga),
  takeLatest(types.addParticipant, addParticipantSaga),
  takeLatest(types.removeParticipant, removeParticipantSaga),
  takeLatest(types.updateParticipant, updateParticipantSaga)
]
