import LocationList from '../../pages/LocationManagement/List'
import Container from '../../pages/LocationManagement/Container'
import * as Filters from '../../pages/LocationManagement/Filters'

export const locationFields = {
  bIMapping: {
    key: 'bIMapping',
    label: 'BI Mapping',
    type: 'string'
  },
  city: {
    key: 'city',
    label: 'City',
    type: 'string',
    filter: Filters.City
  },
  location: {
    key: 'location',
    label: 'Location',
    type: 'string',
    bold: true
  },
  type: {
    key: 'type',
    label: 'Type',
    type: 'array',
    prefix: 'car.page.field.locationType',
    filter: Filters.Type
  },
  active: {
    key: 'active',
    label: 'location.active.status',
    type: 'boolean',
    filter: Filters.Active,
    rowModifier: (props) => props.active === true
  }
}

export default {
  path: 'locations',
  label: 'menu.locations.sectionHeadline',
  key: 'locations',
  component: Container,
  children: [
    {
      path: 'home',
      label: 'menu.locations.list',
      key: 'locationList',
      fields: locationFields,
      component: LocationList
    }
  ],
  LocationList: {
    fields: locationFields
  }
}
