import TicketStatusPill from '../../../components/TicketStatusPill'
import {TICKET_ENTITY_STATUS} from '../../entities/ticket'
import {getFileStatusKey} from '../../../store/carOwnership/selectorHelpers'

export const tableFields = {
  status: {
    key: 'status',
    label: 'payslips.table.status',
    type: 'custom',
    isSortable: false,
    isFilterable: false,
    Component: TicketStatusPill
  },
  carInternalId: {
    key: 'carInternalId',
    label: 'carOwnershipTransfer.list.carInternalId',
    type: 'link',
    alternativeKey: 'carId',
    externalUrl: true,
    openLinkInNewTab: true,
    isSortable: false,
    isFilterable: true
  },
  carId: {
    key: 'carId',
    label: 'car.page.field.id',
    type: 'string',
    alternativeKey: 'carId',
    isFilterable: true
  },
  dealer: {
    type: 'composite',
    key: 'dealer',
    layout: 'rows',
    separator: '\n',
    isFilterable: false,
    label: 'payslips.table.dealer',
    children: [
      {
        label: 'dealerList.placeholder.fullName',
        key: 'fullName',
        type: 'string'
      },
      {
        label: 'dealerList.placeholder.phone',
        key: 'phone',
        type: 'string'
      }
    ]
  },
  dealerLogin: {
    key: 'dealerLogin',
    isFilterable: false,
    label: 'carOwnershipTransfer.list.dealerLogin',
    type: 'string',
    isSortable: false
  },
  dealerManager: {
    key: 'dealerManager',
    isFilterable: false,
    label: 'dealer.page.field.dealerManager',
    type: 'string',
    isSortable: false
  },
  createdAt: {
    key: 'createdAt',
    isFilterable: false,
    label: 'carOwnershipTransfer.list.createdAt',
    type: 'date',
    time: true,
    isSortable: false
  }
}

const statusOptions = [
  TICKET_ENTITY_STATUS.APPROVED,
  TICKET_ENTITY_STATUS.REJECTED
].map((option) => ({
  value: option,
  label: getFileStatusKey(option)
}))

export const subtableFields = [
  {
    key: 'fileName',
    label: 'documentList.documentName',
    type: 'string',
    isSortable: false
  },
  {
    key: 'createdAtFormatted',
    label: 'documentList.uploadedAt',
    type: 'string',
    isSortable: false
  },
  {
    key: 'createdByName',
    label: 'documentList.uploader',
    type: 'string',
    isSortable: false
  },
  {
    key: 'category',
    label: 'car.page.field.category',
    type: 'select',
    alwaysEditable: true,
    editableFieldType: 'select',
    optionsType: 'saleDocumentCategory',
    isEditable: true,
    filterable: false,
    withTranslate: true,
    withEndAdornment: true,
    disableClear: true,
    disableLabel: true,
    isSortable: false,
    fullWidth: false,
    disableUnderline: true,
    cursor: 'pointer',
    persistChoice: false,
    translateLabels: true
  },
  {
    key: 'status',
    label: 'global.status.label',
    type: 'select',
    alwaysEditable: true,
    editableFieldType: 'select',
    isEditable: true,
    filterable: false,
    withTranslate: true,
    withEndAdornment: true,
    disableClear: true,
    disableLabel: true,
    isSortable: false,
    fullWidth: false,
    disableUnderline: true,
    cursor: 'pointer',
    options: statusOptions,
    persistChoice: false,
    translateLabels: true
  },
  {
    key: 'comment',
    label: 'car.page.field.comment',
    type: 'string'
  }
]
