import {webhookFields} from './webhookFields'
import WebhookList from '../../../pages/Webhook/List'
import {webhookFormConfig} from './webhookFormConfig'

export const webhookConfig = {
  path: 'webhook',
  label: 'menu.webhook',
  key: 'webhook',
  sort: [],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...webhookFields},
  hiddenFields: [],
  component: WebhookList,
  ...webhookFormConfig,
  permissions: {
    entity: 'web.admin.ui.car.webhooks',
    types: ['READ']
  },
  hidden: {}
}
