import {Component, Fragment} from 'react'
import moment from 'moment'
import {translate} from 'react-i18next'
import {DateInput} from '@fcg/form-builder'

export class CalendarInput extends Component {
  state = {
    from: '',
    to: '',
    value: '',
    changed: false
  }

  componentDidMount() {
    this.setup(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setup(nextProps)
  }

  setup = (props) => {
    const from =
      props.activeRange && props.activeRange.from
        ? moment(props.activeRange.from)
        : ''
    const to =
      props.activeRange && props.activeRange.to
        ? moment(props.activeRange.to)
        : ''

    this.setState({from, to})
  }

  update = (name) => (value) => {
    this.setState({[name]: value, changed: true})
  }

  startRange = (from) => {
    const {fullRangeChange} = this.props

    let {to} = this.state

    if (moment(from).isAfter(to) || moment.isMoment(to) === false) {
      to = from
    }

    this.setState({from})
    this.props.dispatch(fullRangeChange({from, to}))
    this.props.onUpdate({from, to})
  }

  completeRange = (to, complete = false) => {
    const {fullRangeChange} = this.props
    const {from} = this.state

    if (moment(to).isBefore(from) || moment.isMoment(from) === false) {
      to = from
    }
    const next = complete === true ? this.props.onComplete : null

    this.setState({to})
    this.props.dispatch(fullRangeChange({from, to}))
    this.props.onUpdate({from, to}, next)
  }

  sendUpdate = () => {
    const {from, to} = this.state

    if (this.props.isRange === false) {
      this.props.dispatch(this.props.selectDay(from))
      this.props.onUpdate([from])
    } else {
      this.props.onUpdate({from, to})
    }
  }

  sendComplete = (value) => {
    this.completeRange(value, true)
  }

  render() {
    const {t, dateFormat, isRange, format} = this.props
    const fromLabel = moment.isMoment(this.state.from, dateFormat)
      ? this.state.from.format(dateFormat)
      : ''
    const toLabel = moment.isMoment(this.state.to, dateFormat)
      ? this.state.to.format(dateFormat)
      : ''

    return (
      <div className='date-picker__input'>
        {isRange === true ? (
          <Fragment>
            <DateInput
              label={t('filters.range.from.label')}
              className='input--inline'
              color='secondary'
              value={fromLabel}
              placeholder={dateFormat}
              format={format}
              onChange={this.startRange}
              onSubmit={this.sendUpdate}
              data-test='filter-date-from'
            />
            <DateInput
              label={t('filters.range.to.label')}
              className='input--inline'
              color='secondary'
              value={toLabel}
              placeholder={dateFormat}
              format={format}
              onChange={this.completeRange}
              onSubmit={this.sendComplete}
              data-test='filter-date-to'
              isEndDate
            />
          </Fragment>
        ) : (
          <DateInput
            placeholder={dateFormat}
            format={format}
            className='input--inline'
            color='secondary'
            value={this.state.value}
            onChange={this.update('value')}
            onBlur={this.sendUpdate}
          />
        )}
      </div>
    )
  }
}

export default translate()(CalendarInput)
