import {Fragment, Component} from 'react'
import {Route, Link} from 'react-router-dom'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Edit from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import ActionButton from '../../../components/layout/ActionButton'
import PageTableBase from '../../../components/PageTableBase'
import {
  getSort,
  getLimit,
  getTiers,
  getVisibleFilters,
  getLeadAllocationFields
} from '../../../store/crm/leadAllocation/selectors'
import {
  fetchTiers,
  deleteTier,
  updateFilters
} from '../../../store/crm/leadAllocation/actions'
import Editor from './Editor'
import {loadAllRoles} from '../../../store/crmUserGroups/actions'
import {DeleteDialog} from '../common'
import {checkUserPermissions} from '../../../store/auth/selectors'
import {getVisibleActions} from '../common/helpers'
import {PermissionWrapper} from '../../../components/Permissions'
import {isOldEntity, orangeColorRow} from '../helpers'
import {getList} from '../../../store/locationManagement/actions'

const deleteInitialState = {
  open: false,
  entity: null
}

export class LeadAllocationList extends Component {
  state = {
    delete: {...deleteInitialState}
  }
  deleteAction = null

  componentDidMount() {
    this.props.fetchLocations({limit: 'NO_LIMIT'})
    this.props.loadAllRoles()
  }

  routeBack = () => {
    this.props.history.push(this.props.match.path)
  }

  renderTierForm = (routeProps) => (
    <Editor
      {...routeProps}
      isOpen
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleClose={this.routeBack}
    />
  )

  goToEdit = ({id}) => {
    this.props.history.push(`${this.props.match.path}/edit/${id}`)
  }

  onDelete = () => {
    if (this.state.delete.id) {
      this.deleteAction([...this.state.delete.id])
      this.closeDeleteDialog()
    }
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        id,
        handleCancel: this.closeDeleteDialog
      }
    })

    this.deleteAction = action
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
    this.deleteAction = null
  }

  singleSelectActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['UPDATE']
        }),
      action: {
        event: this.goToEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'leadAllocation',
            id: [item.id],
            action: () => this.props.deleteTier(item.id, item.tierCriteriaId)
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  render() {
    return (
      <Fragment>
        <PageTableBase
          title='entity.leadAllocation'
          filterable
          selectable
          fields={this.props.fields}
          multisort={false}
          singleSelectActions={this.singleSelectTableActions()}
          rowStyle={orangeColorRow}
          {...this.props}
        />
        <PermissionWrapper
          entity='web.admin.ui.crm.leads'
          types={['UPDATE']}
          withDescription
        >
          {isOldEntity({
            createdAt: new Date()
          }) && (
            <ActionButton>
              <Button
                variant='fab'
                color='primary'
                component={Link}
                to={`${this.props.match.path}/create`}
              >
                <Add />
              </Button>
            </ActionButton>
          )}
        </PermissionWrapper>
        <Route
          exact
          path={`${this.props.match.path}/create`}
          render={this.renderTierForm}
        />
        <Route
          exact
          path={`${this.props.match.path}/edit/:id`}
          render={this.renderTierForm}
        />
        <DeleteDialog handleSubmit={this.onDelete} {...this.state.delete} />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  // eslint-disable-next-line no-unused-vars
  const {page: currentPage, ...props} = state.leadAllocation.list

  return {
    ...props,
    sort: getSort(state),
    limit: getLimit(state),
    data: getTiers(state),
    filterFields: getVisibleFilters(state),
    hasPermissions: (permissions) => checkUserPermissions(state, permissions),
    filterFields: getVisibleFilters(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAllRoles: () => dispatch(loadAllRoles()),
  fetchLocations: (params) => dispatch(getList(params)),
  fetchData: () => dispatch(fetchTiers()),
  deleteTier: (id, tierCriteriaId) => dispatch(deleteTier(id, tierCriteriaId)),
  onFilterChange: (filter) => dispatch(updateFilters(filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeadAllocationList)
