import CRMContainer from '../../pages/CRM/Container'
import LandingPage from './crm/landingPage'
import ContactPage from './crm/contactPage'
import AppointmentPage from './crm/appointments'
import LeadPage from './crm/leads'
import TaskPage from './crm/tasks'
import Calendar from './crm/calendar'
import WalkinPage from './crm/walkin'
import CRMUserGroupPage from './crm/crmUserGroups'
import LeadAllocation from './crm/leadAllocation'

export default {
  path: 'crm',
  key: 'crm',
  label: 'menu.crm.sectionHeadline',
  component: CRMContainer,
  children: [
    LandingPage,
    ContactPage,
    LeadPage,
    AppointmentPage,
    TaskPage,
    Calendar,
    WalkinPage,
    CRMUserGroupPage,
    LeadAllocation
  ],
  Leads: LeadPage,
  Contacts: ContactPage,
  Appointments: AppointmentPage,
  Landing: LandingPage,
  Task: TaskPage,
  Calendar: Calendar,
  Walkin: WalkinPage,
  CRMUserGroups: CRMUserGroupPage,
  LeadAllocation
}
