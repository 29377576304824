import moment from 'moment'

export const convertToUTC = (value) => {
  return value.from && value.to
    ? {
        from: moment(value.from)
          .utc()
          .format(),
        to: moment(value.to)
          .utc()
          .format()
      }
    : moment(value)
        .utc()
        .format()
}

export const formatDate = (value, format) =>
  moment.isMoment(value)
    ? value.format(format)
    : moment(value).isValid()
    ? moment(value).format(format)
    : value
