const resourceName = 'participants'

export const fetchParticipants = `${resourceName}/FETCH_PARTICIPANTS_REQUEST`
export const fetchParticipantsSuccess = `${resourceName}/FETCH_PARTICIPANTS_SUCCESS`
export const fetchParticipantsError = `${resourceName}/FETCH_PARTICIPANTS_ERROR`

export const addParticipant = `${resourceName}/ADD_PARTICIPANT_REQUEST`
export const addParticipantSuccess = `${resourceName}/ADD_PARTICIPANT_SUCCESS`
export const addParticipantError = `${resourceName}/ADD_PARTICIPANT_ERROR`

export const removeParticipant = `${resourceName}/REMOVE_PARTICIPANT_REQUEST`
export const removeParticipantSuccess = `${resourceName}/REMOVE_PARTICIPANT_SUCCESS`
export const removeParticipantError = `${resourceName}/REMOVE_PARTICIPANT_ERROR`

export const updateParticipant = `${resourceName}/UPDATE_PARTICIPANT_REQUEST`
export const updateParticipantSuccess = `${resourceName}/UPDATE_PARTICIPANT_SUCCESS`
export const updateParticipantError = `${resourceName}/UPDATE_PARTICIPANT_ERROR`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
