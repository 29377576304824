const contactFields = ['firstname', 'lastname', 'email', 'phone']
const sfGoLiveDate = '2018-04-12'

const appointmentDependencies = [
  'type',
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'mileage',
  'transmission'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]
const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  },
  {
    field: 'VINnumber',
    dependencies: []
  }
]
const carDataPicker = {
  dependencies: {
    location: ['make', 'model', 'year', 'trim'],
    mileage: ['make', 'model', 'year', 'trim']
  }
}
const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

const requiredFields = ['make', 'model']

const purchaseCoordinatorInput = {
  key: 'group',
  queryValue: {include: ['CMS', 'INSPECTOR']}
}

const hiddenColumns = [
  'tierAppointmentStatus',
  'tierLocation',
  'lead.status',
  'zipCode',
  'dealerLocations',
  'carPreferences',
  'authorizedPickupPersons',
  'maxAmountBids',
  'paymentTerms',
  'bankDetails',
  'virtualAccountOrWalletId',
  'postcode',
  'city',
  'plate',
  'leadResponsibleName',
  'updated',
  'VINnumber',
  'selfInspectionExists',
  'selfInspectionPriceExists',
  'leadCity',
  'sourcePathVariant',
  'carInfoExtraFields',
  'carInfoExtraFieldsAppointment'
]

if (sfGoLiveDate) {
  hiddenColumns.push('rejectionReasons')
}

module.exports = {
  sfGoLiveDate: sfGoLiveDate,
  required: [
    {
      field: 'type',
      dependencies: appointmentDependencies
    },
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation,
    ...requiredFields
  ],
  hidden: [
    'lead.bodyType',
    'lead.faceLift',
    'lead.seat',
    'lead.engine',
    'lead.wheelDrive',
    'tierAppointmentStatus',
    'tierLocation',
    'externalLeadId',
    'city',
    'postcode',
    'lead.location',
    'VINnumber',
    'ownerName',
    'ownerPhone',
    'purchaseCoordinator',
    'purchaseCoordinatorPhone'
  ],
  landingPage: ['tasks', 'leads'],
  hiddenColumns,
  hiddenFilters: [
    'tierAppointmentStatus',
    'tierLocation',
    'tierMake',
    'zipCode',
    'dealerLocations',
    'carPreferences',
    'authorizedPickupPersons',
    'maxAmountBids',
    'paymentTerms',
    'bankDetails',
    'virtualAccountOrWalletId',
    'postcode',
    'city',
    'tierLeadSource',
    'tierLeadStatus',
    'appointmentAddress',
    'selfInspectionPriceExists',
    'sourcePathVariant'
  ],
  hiddenFeatures: [],
  disabledFields: {walkin: {leadForm: ['price']}},
  carDataPicker,
  purchaseCoordinatorInput
}
