import {compose, path, map, reduce, keys, pick} from 'ramda'

export const getCountryOptions = (data, withLabelPrefix = null) =>
  compose(
    reduce(
      (acc, key) => ({
        ...acc,
        [key]: compose(
          map(
            compose(
              ({name, ...rest}) => ({
                value: name,
                label:
                  typeof withLabelPrefix === 'function'
                    ? withLabelPrefix(name)
                    : name,
                ...rest
              }),
              pick(['name', 'type'])
            )
          ),
          path([key, 'list'])
        )(data)
      }),
      {}
    ),
    keys
  )(data)

export const getFullNameFromUser = (user) => {
  const {firstname = '', lastname = ''} = user || {}

  return `${firstname || ''} ${lastname || ''}`.trim()
}
