const dealerDetails = require('./dealerManagement/gl')
const crmDetails = require('./CRM/gl')
const bulkuploadDetails = require('./bulkUpload/gl')
const carDataDetails = require('./carData/gl')
const locationDetails = require('./locationManagement/gl')
const payslipDetails = require('./payslips/gl')
const carOwnership = require('./carOwnership/gl')
const businessPartnersDetails = require('./businessPartnersDetails/gl')

module.exports = {
  url: 'sikubiili.com',
  name: 'Greenland',
  countryCode: 'GL',
  theme: 'greenland',
  language: {
    selected: 'en-gl',
    options: [
      {value: 'en-gl', label: 'EN'},
      {value: 'kl-gl', label: 'KL'}
    ]
  },
  locales: ['kl-gl', 'en-gl'],
  currency: {code: 'DKK', symbol: 'kr'},
  number: {decimalSep: ',', unitSep: '.', units: [1000]},
  fallbackLocale: 'en-gl',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [8], code: 299},
  referralCodeType: 'input',
  googleKey:
    '142848285869-fhit9kqp0al6ob24ao8nj9q248ae317e.apps.googleusercontent.com',
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  crmDetails,
  carDuplicatedCheckField: 'vin',
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'category',
    'bulkUploadV2',
    'wms',
    'documentManagement',
    'tms',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
