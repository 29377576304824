import * as types from './types'

export const fetchAuctionSchedulesList = (payload) => ({
  type: types.fetchAuctionSchedulesList,
  payload
})
export const fetchAuctionSchedulesListSuccess = (data) => ({
  type: types.fetchAuctionSchedulesListSuccess,
  payload: data
})

export const fetchAuctionSchedulesListError = (error) => ({
  type: types.fetchAuctionSchedulesListError,
  payload: error
})
export const toggleRow = (payload) => ({
  type: types.toggleRow,
  payload
})
export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})
export const createAuctionFlow = (payload) => ({
  type: types.createAuctionFlow,
  payload
})

export const deleteAuctionFlow = (payload) => ({
  type: types.deleteAuctionFlow,
  payload
})

export const deleteAuctionFlowSuccess = (payload) => ({
  type: types.deleteAuctionFlowSuccess,
  payload
})

export const deleteAuctionFlowError = (payload) => ({
  type: types.deleteAuctionFlowError,
  payload
})

export const cleanUpDialog = () => ({
  type: types.cleanUpDialog,
  payload: true
})
