import styled from 'styled-components'
import {Label} from '@fcg/tars'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'

import {getLeftPanelConfig, getVisitedPage} from '../../store/users/selectors'

const LabelWrapper = styled.div`
  padding: 24px;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
`

const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  z-index: 10;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const Content = styled.span`
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
`

const VerticalLine = styled.div`
  border-right: 1px solid #ebebeb;
  height: ${(props) => (props.configLength - 1) * 58 - 34}px;
  width: 1px;
  position: absolute;
  margin-top: 34px;
  margin-left: 17px;
`

const LeftPanel = (props) => {
  const handleStepSelect = (data) => {
    if (
      props.selected > data.stepNum ||
      props.visitedPages.includes(data.stepNum) ||
      props.isEdit
    ) {
      props.updateSelection(data.stepNum)
    }
  }

  const LeftPanelData = () =>
    props.leftPanelConfig.map((data) => {
      let disabled = false
      let type = 'success'

      if (props.selected < data.stepNum) {
        disabled = true
        type = 'ghost'
      }

      if (props.visitedPages.includes(data.stepNum) || props.isEdit) {
        disabled = false
        type = 'success'
      }

      return (
        <LabelContainer
          key={data.stepNum}
          onClick={() => handleStepSelect(data)}
          disabled={disabled}
          data-test={`step-number-${data.stepNum}`}
        >
          <Label val={data.stepNum} type={type} />
          <Content selected={data.stepNum === props.selected}>
            {props.t(data.data.label)}
          </Content>
        </LabelContainer>
      )
    })

  return (
    <LabelWrapper>
      <LeftPanelData />
      <VerticalLine configLength={props.leftPanelConfig.length} />
    </LabelWrapper>
  )
}

const mapStateToProps = (state) => ({
  leftPanelConfig: getLeftPanelConfig(state),
  visitedPages: getVisitedPage(state)
})

export default connect(mapStateToProps, null)(translate()(LeftPanel))
