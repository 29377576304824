import {Component} from 'react'
import {Popover, TextField} from '@material-ui/core'
import Calendar from './Calendar'
import {DateInput} from '@fcg/form-builder'
import moment from 'moment'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

export class DatePicker extends Component {
  state = {open: false}
  static defaultProps = {
    id: 'date',
    label: 'Date',
    format: 'DD/MM/YYYY',
    value: moment(),
    onChange: noop
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
  }

  contains = (node) => {
    return (
      node === this.input ||
      node === this.calendar ||
      this.input.contains(node) === true ||
      (this.calendar && this.calendar.contains(node) === true)
    )
  }

  handleClick = (e) => {
    e.stopPropagation()

    if (this.state.open === true && this.contains(e.target) !== true) {
      this.close()
    } else if (this.contains(e.target) === true && this.state.open === false) {
      this.open()
    }
  }

  handleChange = (value) => {
    this.props.onChange(value)
    this.close()
  }

  handleInput = (value) => {
    this.props.onChange(moment(value).format(this.props.format))
    this.close()
  }

  toggleCalendar = () => {
    this.setState({open: !this.state.open})
  }

  close = () => {
    this.setState({open: false})
  }

  open = () => {
    this.setState({open: true})
  }

  render() {
    const {format, value, placeholder, fieldName} = this.props
    const fieldProps = omit(this.props, [
      'format',
      'value',
      'onChange',
      'placeholder',
      'fieldName'
    ])
    const label = moment.isMoment(value)
      ? value.format(placeholder)
      : moment(value).format(placeholder)

    return (
      <div className='datepicker' onClick={this.handleClick}>
        <TextField
          {...fieldProps}
          value={label}
          onClick={this.open}
          onChange={noop}
          inputRef={(el) => {
            this.input = el
          }}
        />
        <Popover
          anchorEl={this.input}
          open={this.state.open}
          handleClose={this.close}
        >
          <div
            ref={(el) => {
              this.calendar = el
            }}
          >
            <DateInput
              withIcon
              value={value}
              format={format}
              placeholder={placeholder}
              onChange={this.handleChange}
              onSubmit={this.handleInput}
            />
            <Calendar
              calendarId={fieldName}
              layout='single'
              selected={value}
              format='YYYY-MM-DD'
              update={this.handleChange}
              showFooter={false}
            />
          </div>
        </Popover>
      </div>
    )
  }
}

export default DatePicker
