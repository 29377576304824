import {call, put, select, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as WebhookApi from '../../api/webhook/requests'
import selectors from './selectors'
import gql from '@fcg/lib-gql/gql'
import pickBy from 'lodash/pickBy'

function* fetchWebhookListSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)
    let sort = yield select(selectors.getSort)

    sort =
      sort && Array.isArray(sort) && sort.length
        ? {...sort[0], order: new gql.EnumType(sort[0].order)}
        : null

    const options = {
      limit,
      sort,
      page,
      country: new gql.EnumType(countryCode)
    }

    const webhookList = yield call(WebhookApi.getWebhooks, {...pickBy(options)})

    webhookList.webhooks.list.map((webhook) => {
      webhook.events = webhook.events.map((events) => events.event)
    })

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchWebhookListSuccess,
      payload: webhookList.webhooks
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchWebhookListError,
      payload: e
    })
  }
}

function* fetchWebhookEventsListSaga() {
  try {
    const webhookEvents = yield call(WebhookApi.getEvents)

    yield put({
      type: types.fetchWebhookEventsListSuccess,
      payload: webhookEvents.events
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchWebhookEventsListError,
      payload: e
    })
  }
}

function* updateFiltersSaga() {
  try {
    const filters = yield select(selectors.getParsedFilterForQuery)

    yield put({
      type: types.fetchWebhookList,
      payload: {
        carData: filters,
        page: 1
      }
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* deleteWebhookSaga(action) {
  try {
    yield call(WebhookApi.deleteWebhook, {
      id: action.payload
    })

    yield put({
      type: types.deleteWebhookSuccess
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.webhook.delete.success',
        open: true
      })
    )
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* createWebhookSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const events = action.payload.events.map((event) => new gql.EnumType(event))
    const webhookNonce = yield call(WebhookApi.createWebhook, {
      country: new gql.EnumType(countryCode),
      ...action.payload,
      events
    })

    yield put({
      type: types.createWebhookSuccess
    })

    yield put({
      type: types.openNonceDialog,
      ...webhookNonce
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.errors ? error.errors[0].message : error.message,
        open: true
      })
    )

    yield put({
      type: types.createWebhookError,
      error: error
    })
  }
}

export const sagas = [
  takeLatest(types.fetchWebhookList, fetchWebhookListSaga),
  takeLatest(types.createWebhookSuccess, fetchWebhookListSaga),
  takeLatest(types.createWebhook, createWebhookSaga),
  takeLatest(types.fetchWebhookEventsList, fetchWebhookEventsListSaga),
  takeLatest(types.updateFilters, updateFiltersSaga),
  takeLatest(types.toggleSort, fetchWebhookListSaga),
  takeLatest(types.deleteWebhookSuccess, fetchWebhookListSaga),
  takeLatest(types.deleteWebhook, deleteWebhookSaga)
]
