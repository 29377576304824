import * as ContactApi from '../../../api/contact/requests'
import * as types from './types'
import {formatParams} from '../../utils'
import * as globalActions from '../../global/actions'
import {fetchDocumentDownloadLinks} from '../../../api/document/requests'
import {isSelfInspectionType} from '../../../pages/CRM/Contact/SelfInspectionDialog/util'
import {getCountryConfig} from '../../../../config/country'

const entities = {
  comment: {
    entity: 'comment',
    fetch: ContactApi.getCommentList,
    prepareQuery: (state) => ({
      commentData: {
        type: 'comment',
        contactId: state.id
      }
    }),
    parseResponse: (res) => res.comment
  },
  task: {
    entity: 'task',
    fetch: ContactApi.getCommentList,
    prepareQuery: (contact) => ({
      commentData: {
        type: 'task',
        contactId: contact.id
      }
    }),
    parseResponse: (res) => res.comment
  },
  changes: {
    entity: 'history',
    fetch: ContactApi.getChangesList,
    prepareQuery: (contact) => ({
      historyData: {
        contactId: contact.id
      }
    }),
    parseResponse: (res) => res.history
  },
  lead: {
    entity: 'lead',
    fetch: ContactApi.getLeadList,
    prepareQuery: (contact) => ({
      carLeadData: {
        contactId: contact.id
      }
    }),
    parseResponse: (res) => res.lead
  }
}

const getTableParams = ({limit, page}) => ({limit, page})

export const getContact = (params) => (dispatch, getState) => {
  dispatch(contactDetailFetching())
  const {id} = params
  const {
    detail: {comment, task, lead, changes},
    config
  } = getState()
  const subfields = {
    comment: {...getTableParams(comment), commentData: {type: 'comment'}},
    task: {...getTableParams(task), commentData: {type: 'task'}},
    lead: getTableParams(lead),
    appointment: {
      appointmentData: {participant: {id}, country: config.countryCode}
    }
  }

  // if (getCountryConfig().hasLogsInContactDetail === true) {
  //   subfields.changes = getTableParams(changes)
  // }

  return ContactApi.getContactDetail({id, subfields, contactData: {...params}})
    .then(async (response) => {
      const list = response.contact.list

      if (list.length === 0) {
        return dispatch(contactDataNotFound({params}))
      }
      const detail = list[0]
      try {
        console.info('Trying to measure performance of Show CRM Flow')
        performance.mark('CONTACT_DETAIL_DATA_LOADED')
        const metric = performance.measure(
          'SHOW_CRM_FLOW',
          'SHOW_CRM_EVENT_RECEIVED',
          'CONTACT_DETAIL_DATA_LOADED'
        )
        console.info(
          `SHOW_CRM_CALLED ${metric.duration / 1000} second(s) ago.`,
          {metric}
        )
      } catch (error) {}

      dispatch(contactDetailLoaded(detail))
      dispatch(setLoaded('task')({...task, data: response.task}))
      dispatch(setLoaded('comment')({...comment, data: response.comment}))
      // if (getCountryConfig().hasLogsInContactDetail === true) {
      //   dispatch(setLoaded('changes')({...changes, data: response.changes}))
      // }

      if (response.lead) {
        const leadData = await getLeadData(response)

        leadData.lead.list = leadData.lead.list.map((lead) => {
          const appointments = lead.appointments.map((appointment) => {
            let role = 'SELLER'

            if (appointment.participants) {
              const participant = appointment.participants.find(
                (participant) => id === participant.id
              )

              if (participant) {
                role = participant.role
              }
            }

            return {
              ...appointment,
              role
            }
          })

          return {
            ...lead,
            appointments
          }
        })

        dispatch(setLoaded('lead')({...lead, data: leadData.lead}))
      }

      return response
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}

export const getLogs = (params) => (dispatch, getState) => {
  dispatch(logsFetching())
  const {id} = params
  const {
    detail: {changes}
  } = getState()
  const subfields = {
    changes: getTableParams(changes)
  }

  return ContactApi.getContactDetail({id, subfields, contactData: {...params}})
    .then(async (response) => {
      const list = response.contact.list

      if (list.length === 0) {
        return dispatch(logsDataNotFound({params}))
      }
      dispatch(logsLoaded(response.changes))
      dispatch(setLoaded('changes')({...changes, data: response.changes}))

      return response
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}

export const clearLogsOnLoad = () => (dispatch, getState) => {
  const {
    detail: {changes}
  } = getState()
  let temp = {
    count: 0,
    list: []
  }
  dispatch(logsLoaded(temp))
  dispatch(setLoaded('changes')({...changes, data: temp}))
}

export const fetchData = (entity) => ({
  limit,
  filters,
  sort,
  page,
  onBeforeStore
} = {}) => {
  const {prepareQuery, parseResponse, fetch} = entities[entity]
  const onLoad = setLoaded(entity)
  const onFetch = setFetching(entity)

  return async (dispatch, getState) => {
    dispatch(onFetch())
    const contact = getState().detail
    const currentState = contact[entity]
    limit = limit || currentState.limit
    filters = filters || currentState.filters
    sort = sort || currentState.sort
    page = page || currentState.page
    const params = {
      limit,
      sort,
      page,
      ...formatParams(filters),
      ...prepareQuery(contact.detail)
    }

    try {
      let response = await fetch(params)

      if (onBeforeStore) {
        response = await onBeforeStore(response)
      }

      dispatch(
        onLoad({
          data: parseResponse(response),
          limit,
          sort,
          page,
          filters
        })
      )
    } catch (e) {
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.fetch',
          entity
        })
      )
    }
  }
}

export const setLoaded = (entity) => (payload) => ({
  type: types[entity].dataLoaded,
  payload
})

export const setFetching = (entity) => (payload) => ({
  type: types[entity].fetching,
  payload
})

export const toggleField = (entity) => (label) => ({
  type: types[entity].toggleField,
  payload: label
})

export const toggleSort = (entity) => (sort) => (dispatch) => {
  dispatch({
    type: types[entity].toggleSort,
    payload: sort
  })
  dispatch(fetchData(entity)())
}

export const setSort = (entity) => (sort) => (dispatch) => {
  dispatch({
    type: types[entity].setSort,
    payload: sort
  })
  dispatch(fetchData(entity)())
}

export const entityActions = (dispatch) =>
  Object.keys(entities).reduce(
    (acc, entity) => ({
      ...acc,
      [entity]: {
        fetchData: (params) => dispatch(fetchData(entity)(params)),
        setLoaded: (params) => dispatch(setLoaded(entity)(params)),
        setFetching: (params) => dispatch(setFetching(entity)(params)),
        toggleField: (params) => dispatch(toggleField(entity)(params)),
        toggleSort: (params) => dispatch(toggleSort(entity)(params))
      }
    }),
    {}
  )

export const dismissError = (dispatch) => dispatch(contactDataNotFound(false))

export const contactDetailFetching = () => ({
  type: types.contactDetailFetching,
  payload: null
})

export const logsFetching = () => ({
  type: types.logsFetching,
  payload: null
})

export const contactDetailLoaded = (payload) => ({
  type: types.contactDetailLoaded,
  payload
})

export const logsLoaded = (payload) => ({
  type: types.logsLoaded,
  payload
})

export const contactDataNotFound = (op, payload) => ({
  type: types.contactDataNotFound,
  payload
})

export const logsDataNotFound = (op, payload) => ({
  type: types.logsDataNotFound,
  payload
})

export const getLeadData = async (response) => {
  try {
    response.appointment.list.forEach((appointment) => {
      const leadId = response.lead.list.find(
        ({id}) => id === appointment.leadId
      )

      if (leadId) {
        if (Array.isArray(response.lead.list.appointments)) {
          response.lead.list.appointments.push(appointment)
        } else {
          response.lead.list.appointments = [appointment]
        }
      } else {
        const {lead, ...appointmentWithoutLead} = appointment
        appointment.lead.appointments = [appointmentWithoutLead]
        response.lead.list.push(appointment.lead)
      }
    })
    const leadIds = response.lead.list
      .filter((l) => isSelfInspectionType(l.inspectionType))
      .map((l) => l.id)

    if (leadIds && !leadIds.length) {
      return response
    }

    const documents = await fetchDocumentDownloadLinks({
      reference: leadIds,
      entity: 'carLeadCallback'
    })

    response.lead.list = response.lead.list.map((l) => ({
      ...l,
      documents: documents
        .filter((doc) => doc.reference === l.id)
        .map((doc) => ({
          ...doc,
          src: doc.link
        }))
    }))

    return response
  } catch (error) {
    return response
  }
}
