import React, {useContext} from 'react'
import cx from 'classnames'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'

import {resetLocation} from '../../../../api/booking/requests'
import Dialog from '../../../../components/Dialog/Dialog'

import {MainContext} from '../../../../context/MainContext'
import {GlobalContext} from '../../../../context/GlobalContext'
import {ValidationContext} from '../../../../context/ValidationContext'

import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'

import Service from '../../../../types/Service'

const ResetLocation = () => {
  const {
    countryCode,
    selectedLocation,
    locationsHolidays,
    locationsServices,
    setLocationsServices,
    setLocationsHolidays
  } = useContext(MainContext)
  const {globalServices} = useContext(GlobalContext)
  const {setResponseBar} = useContext(ValidationContext)

  const classes = useCommonStyles()
  const title = 'What you want to reset?'
  const message =
    'Resetting a location will set everything back to the template. You will lose all your information. Resetting is irreversible.'

  const [form, setForm] = React.useState({services: false, holidays: false})
  const [open, setOpen] = React.useState(false)

  const handleSelect = (checkbox: 'services' | 'holidays') => {
    setForm((prevForm) => {
      return {...prevForm, [checkbox]: !prevForm[checkbox]}
    })
  }
  const handleConfirm = () => {
    const data = {
      country: countryCode!,
      placeId: selectedLocation!.id!,
      resetOpeningTimes: form.services,
      resetSpecialOpenings: form.holidays
    }
    resetLocation(data).then(
      (response: boolean) => {
        if (response) {
          setOpen(false)
          data.resetOpeningTimes && setLocationsServices(globalServices)
          data.resetSpecialOpenings && setLocationsHolidays([])
          setForm({services: false, holidays: false})
          setResponseBar({
            type: 'info',
            message: 'Location is reset'
          })
        }
      },
      (e) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const holidaysCheckDisabled = locationsHolidays.length === 0

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        startIcon={<RefreshIcon />}
        data-test='reset-location'
        onClick={() => {
          setOpen(true)
        }}
      >
        RESET LOCATION
      </Button>
      <Dialog
        open={open}
        title={title}
        message={message}
        onCancel={() => {
          setOpen(false)
        }}
        dataTest='reset-btn'
        confirmLabel='RESET'
        cancelLabel="DON'T RESET"
        onConfirm={handleConfirm}
        maxWidth='xs'
        disabled={!form.services && !form.holidays}
      >
        <Typography
          color='textSecondary'
          className={cx(classes.marginTop4, classes.marginBottom2)}
        >
          Please select what you want to reset.
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.services}
                data-test='services-cbx'
                onChange={() => {
                  handleSelect('services')
                }}
                color='primary'
              />
            }
            label='Services'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form.holidays}
                data-test='holidays-cbx'
                onChange={() => handleSelect('holidays')}
                color='primary'
                disabled={holidaysCheckDisabled}
              />
            }
            label='Holidays'
          />
        </FormGroup>
      </Dialog>
    </>
  )
}
export default ResetLocation
