import EmptyCar from './Permissions/svg/EmptyCar'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const GuardContent = styled.div`
  color: #9b9b9b;
  text-align: center;
`
const GuardTitle = styled.div`
  font-size: 18px;
  color: #9b9b9b;
  font-weight: bold;
  margin: 15px 0;
`
const GuardContact = styled.div`
  color: #a6a6a6;
`
const GuardElement = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`
const BackButton = styled(Button)`
  && {
    margin: 20px auto;
    display: block;
  }
`

function Empty(props) {
  return (
    <GuardElement data-test='guard-element'>
      <GuardContent>
        <EmptyCar />
        <GuardTitle>{props.title}</GuardTitle>
        <GuardContact>{props.subtitle}</GuardContact>
      </GuardContent>
      <BackButton onClick={props.onClick} variant='raised' color='primary'>
        {props.buttonText}
      </BackButton>
    </GuardElement>
  )
}

Empty.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string
}

export default Empty
