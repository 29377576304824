import styled from 'styled-components'
import {translate} from 'react-i18next'
import {HeaderTitle} from './HeaderTitle'
import Status from './Status'
import Flex from '../../components/Flex'

const Wrapper = styled(Flex)`
  font-size: 14px;
`

const Id = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.33;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
`

const Header = styled.span`
  display: flex;
`

const TransitJobId = (props) => {
  if (!props.data.internalId) {
    return null
  }

  const label = props.t('Job ID')
  const transitJobId = props.data.internalId

  return <Id data-test='field-transitJob-id'>{`${label}: ${transitJobId}`}</Id>
}

const TransitJobDriver = (props) => {
  if (!props.data.driver) {
    return null
  }

  const transitJobDriver = props.data.driver

  return <Id data-test='field-transitJob-id'>{transitJobDriver}</Id>
}

const LeftHeader = (props) =>
  props.data && (
    <Wrapper
      direction='column'
      width='100%'
      alignItems='start'
      justify='flex-start'
      margin='0 0 8px 5px'
    >
      <TransitJobId {...props} />
      <Header>
        <HeaderTitle {...props} />
        <Status
          data={props.data.status}
          onClickHandler={props.openStatusChangeDialog}
          height='fit-content'
        />
      </Header>
      <TransitJobDriver {...props} />
    </Wrapper>
  )

export default translate()(LeftHeader)
