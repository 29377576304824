import {createMuiTheme} from '@material-ui/core/styles'
import {grey} from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

export const mainTheme = createMuiTheme({
  palette: {
    secondary: {
      main: grey[700],
      light: grey[50],
      inactive: grey[500]
    }
  }
})

declare function makeStyles<T>(gen: () => T): () => {[name in keyof T]: string}

export const useCommonStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer'
  },
  block: {
    display: 'block'
  },
  flex: {
    display: 'flex'
  },
  flexColumn: {
    flexDirection: 'column'
  },
  flexGrow: {
    flexGrow: 1
  },
  fixed: {
    position: 'fixed'
  },
  absolute: {
    position: 'absolute'
  },
  relative: {
    position: 'relative'
  },
  overFlowYAuto: {
    overflowY: 'auto'
  },
  smoothScroll: {
    scrollBehavior: 'smooth'
  },
  fullWidth: {
    width: '100%'
  },
  contentHeight: {
    height: 'calc(100vh - 64px)'
  },
  textCenter: {
    textAlign: 'center'
  },
  textRight: {
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  verticalMiddle: {
    verticalAlign: 'middle'
  },
  itemsCenter: {
    alignItems: 'center'
  },
  selfCenter: {
    alignSelf: 'center'
  },
  selfEnd: {
    alignSelf: 'flex-end'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  },
  backgroundLightGray: {
    backgroundColor: mainTheme.palette.secondary.light
  },
  hoverBackground: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  borderTop: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  borderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  borderY: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  borderBottomDark: {
    borderBottom: '1px solid rgba(0, 45, 57, 0.8)' // #002d39 - charcoal
  },
  borderTopDark: {
    borderTop: '1px solid rgba(0, 45, 57, 0.8)' // #002d39 - charcoal
  },
  borderBottomLight: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)'
  },
  padding2: {
    padding: 16
  },
  padding3: {
    padding: 24
  },
  padding4: {
    padding: 32
  },
  paddingY1: {
    paddingTop: 8,
    paddingBottom: 8
  },
  paddingY2: {
    paddingTop: 16,
    paddingBottom: 16
  },
  paddingX2: {
    paddingRight: 16,
    paddingLeft: 16
  },
  paddingTop1: {
    paddingTop: 8
  },
  paddingTop0: {
    paddingTop: 0
  },
  paddingTop2: {
    paddingTop: 16
  },
  paddingBottom1: {
    paddingBottom: 8
  },
  paddingBottom2: {
    paddingBottom: 16
  },
  paddingBottom3: {
    paddingBottom: 24
  },
  paddingBottom8: {
    paddingBottom: 64
  },
  paddingLeft1: {
    paddingLeft: 8
  },
  paddingLeft2: {
    paddingLeft: 16
  },
  paddingRight0: {
    paddingRight: 0
  },
  paddingRight1: {
    paddingRight: 8
  },
  paddingRight2: {
    paddingRight: 16
  },
  paddingRight3: {
    paddingRight: 24
  },
  marginY1: {
    marginTop: 8,
    marginBottom: 8
  },
  marginY2: {
    marginTop: 16,
    marginBottom: 16
  },
  marginX1: {
    marginRight: 8,
    marginLeft: 8
  },
  marginXAuto: {
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  marginTop0: {
    marginTop: 0
  },
  marginTop_1: {
    marginTop: -8
  },
  marginTop1: {
    marginTop: 8
  },
  marginTop2: {
    marginTop: 16
  },
  marginTop4: {
    marginTop: 32
  },
  marginBottom1: {
    marginBottom: 8
  },
  marginBottom2: {
    marginBottom: 16
  },
  marginBottom3: {
    marginBottom: 24
  },
  marginBottom4: {
    marginBottom: 32
  },
  marginBottom6: {
    marginBottom: 48
  },
  marginRight1: {
    marginRight: 8
  },
  marginRight2: {
    marginRight: 16
  },
  marginLeft1: {
    marginLeft: 8
  },
  marginLeft2: {
    marginLeft: 16
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: grey[500]
  },
  absoluteRightTop: {
    position: 'absolute',
    top: 16,
    right: 16
  },
  absoluteLeftBottom: {
    position: 'absolute',
    bottom: 16,
    left: 16
  },
  rightBottom: {
    bottom: 16,
    right: 16
  },
  rightBottomOut: {
    bottom: -52,
    right: 0
  },
  pickerFormWidth: {
    width: 850
  },
  pickerMinWidth: {
    minWidth: 500
  },
  letterSpacingNormal: {
    letterSpacing: 'normal'
  },
  colorTitle: {
    color: grey[700]
  },
  colorGreyButton: {
    color: '#7f9799'
  },
  colorInactive: {
    color: mainTheme.palette.secondary.inactive
  },
  colorDisabled: {
    color: mainTheme.palette.secondary.light
  },
  bold: {
    fontWeight: 700
  }
}))
