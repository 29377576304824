const selectorPath = (state) => state.changes

export const getChangeLog = (state) => {
  const log = selectorPath(state)

  if (log && log.data) {
    return Object.values(log.data)
  }

  return []
}
