export const defaultErrorMsg = 'Upload failure'

export function getErrorFromResponse(response) {
  // Unhandled or code errors
  if (response === 'error') {
    return defaultErrorMsg
  }

  // Manage proxy http 400 errors (validation)
  if (response.error) {
    return response.message
  }

  // Manage proxy http 500 errors
  const errors = response.errors
  if (errors && errors.length > 0) {
    return errors[0].message || defaultErrorMsg
  }

  return null
}
