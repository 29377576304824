import * as types from './types'

export const fetchCountryOptionList = (payload) => ({
  type: types.fetchCountryOptionList,
  payload
})

export const fetchCountryOptionListSuccess = (payload) => ({
  type: types.fetchCountryOptionListSuccess,
  payload
})

export const fetchCountryOptionListError = (error) => ({
  type: types.fetchCountryOptionListError,
  payload: error
})

export const deleteCountryOption = (payload) => ({
  type: types.deleteCountryOption,
  payload
})

export const deleteCountryOptionSuccess = () => ({
  type: types.deleteCountryOptionSuccess
})

export const deleteCountryOptionError = (error) => ({
  type: types.deleteCountryOptionError,
  payload: error
})

export const hideCreateDialog = () => ({
  type: types.hideCreateDialog
})

export const openCreateDialog = () => ({
  type: types.openCreateDialog
})

export const createCountryOption = (payload) => ({
  type: types.createCountryOption,
  payload
})

export const createCountryOptionSuccess = () => ({
  type: types.createCountryOptionSuccess
})

export const createCountryOptionError = () => ({
  type: types.createCountryOptionError
})

export const updateCountryOptionField = (payload) => ({
  type: types.updateCountryOptionField,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})
