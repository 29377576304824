import React from "react";
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import Notifications from "./components/Notifications/Notifications";
import { I18nextProvider } from "react-i18next";
import { Loader } from "motofin-ui";

export const renderNotificationsToDom = (i18n: any, id = "notifications") => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <React.Suspense fallback={<Loader />}>
        <Notifications />
      </React.Suspense>
    </I18nextProvider>, document.getElementById(id)
  )
};

export const unmount = (node: Element | DocumentFragment) => {
  unmountComponentAtNode(node);
};

