export const getVisibleActions = (actions, props) =>
  actions.reduce(
    (acc, tally) => (tally.test(props) ? [...acc, tally.action] : acc),
    []
  )

export const alwaysTrue = () => true
export const isDeleteButtonVisible = (props) =>
  !props.pageConfig.hidden.deleteButton
export const getPhoneLink = (link) => (email, phone) =>
  (link || [])
    .join('')
    .replace(':email', email)
    .replace(':phone', phone)

export function setNestedProp(obj = {}, [first, ...rest], value) {
  return {
    ...obj,
    [first]: rest.length ? setNestedProp(obj[first], rest, value) : value
  }
}

export function getNestedProp(obj, args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
}
