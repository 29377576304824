const dealerDetails = require('./dealerManagement/cb')
const crmDetails = require('./CRM/cb')
const bulkuploadDetails = require('./bulkUpload/cb')
const carDataDetails = require('./carData/cb')
const locationDetails = require('./locationManagement/cb')
const payslipDetails = require('./payslips/cb')
const carOwnership = require('./carOwnership/cb')
const businessPartnersDetails = require('./businessPartnersDetails/cb')

module.exports = {
  url: 'carsbazr.store',
  name: 'CB',
  countryCode: 'CB',
  theme: 'cb',
  language: {
    selected: 'en-cb',
    options: [
      {value: 'en-cb', label: 'EN'},
      {value: 'ar-cb', label: 'AR'}
    ]
  },
  locales: ['ar-cb', 'en-cb'],
  currency: {code: 'SAR', symbol: 'SAR'},
  number: {decimalSep: '.', unitSep: ',', units: [10000000, 100000, 1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 966},
  referralCodeType: 'input',
  googleKey:
    '543555054723-hj1p9vc3agdrrgmvr94h79j2617is0l0.apps.googleusercontent.com',
  dealerDetails,
  disabledBookingSources: ['Web Lead'],
  crmDetails,
  carDuplicatedCheckField: 'vin',
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'bulkUploadV2',
    'category',
    'wms',
    'documentManagement',
    'tms',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
