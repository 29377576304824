import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  IconButton
} from '@material-ui/core'
import {Delete} from '@material-ui/icons'
import EmptyState from '../../../components/EmptyState'

const listItemStyle = (theme) => ({
  selected: {
    backgroundColor: '#f9faff',
    border: '1px solid #434fae',
    color: '#4350ae',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#dfe4ff'
    }
  },
  textSelected: {
    color: '#4350ae',
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  iconSelected: {
    color: '#4350ae',
  },
  listhover: {
    '&:hover': {
      backgroundColor: '#dfe4ff'
    }
  }
})

const GroupListItem = withStyles(listItemStyle)(
  ({name, onClick, selected, classes, onDeleteClick}) => (
    <ListItem
      button
      onClick={onClick}
      className={selected ? classes.selected : ''}
    >
      <ListItemText
        primary={name}
        // classes={{primary: classes.text}}
        className={selected ? classes.textSelected : ''}
      ></ListItemText>
      <IconButton onClick={onDeleteClick} className={`${selected ? classes.iconSelected : ''}`}>
        <Delete />
      </IconButton>
    </ListItem>
  )
)

// eslint-disable-next-line no-unused-vars
const listStyle = (theme) => ({
  list: {
    margin: '0px',
    height: '85vh',
    overflowX: 'hidden',
    overflowY: 'scroll'
  }
})

const groupListSection = (items, onClick, selected, classes, onDeleteClick) => {
  if(items.length === 0){
    return <div style={{minHeight: '600px', display: 'flex'}}><EmptyState /></div>
  }
  return  <List className={classes.list}>
  {items.map((item) => (
    <GroupListItem
      data-testid='groupListItem'
      key={`GroupListItem_${item.id}`}
      name={item.name}
      onClick={() => onClick(item.id)}
      onDeleteClick={(e) => onDeleteClick(item.id, e)}
      selected={item.id === selected}
    />
  ))}
</List>
}

export const GroupList = withStyles(listStyle)(
  ({items, onClick, selected, classes, onDeleteClick}) => (
    groupListSection(items, onClick, selected, classes, onDeleteClick)
  )
)