import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import {SlideUp} from '../../components/Animations/Slides'
import {uploadCategoryData} from '../../store/category/actions'
import Upload from '../../components/Upload'
import * as selectors from '../../store/category/selectors'

const Title = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 0 24px !important;
`

const UploadWrapper = styled.div`
  margin: 0 24px;
`

class CategoryUploadDialog extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isUploading &&
      this.props.isUploading !== prevProps.isUploading &&
      !this.props.hasUploadError
    ) {
      this.props.handleClose()
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.isUploading ? true : this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={SlideUp}
      >
        <Title disableTypography>
          <Typography variant='h1'>
            {this.props.t('Category Data Upload')}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={this.props.handleClose}
            disabled={this.props.isUploading}
          >
            <CloseIcon />
          </IconButton>
        </Title>
        <UploadWrapper>
          <Upload
            multiple={false}
            isUploading={this.props.isUploading}
            uploadDocument={this.props.uploadCategoryData}
            isCategory={this.props.isCategory}
          />
        </UploadWrapper>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            color='default'
            disabled={this.props.isUploading}
          >
            {this.props.t('button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  isUploading: selectors.isUploadingCategoryData(state)
  //   hasUploadError: selectors.hasUploadError(state)
})

const mapDispatchToProps = (dispatch) => ({
  //   setUploading: (payload) => dispatch(setUploading(payload)),
  uploadCategoryData: (payload) => dispatch(uploadCategoryData(payload))
})

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(CategoryUploadDialog)
)
