import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal } from 'motofin-ui';
import '../../Notifications.scss';
import { DateGroupsObject, NotificationItem, NotificationsApiReponse } from './Notifications.types';
import { getLast20Notifications, getLastSeen, markNotificationsAsReadOrUnread } from '../../api/api';
import { last30DaysTemplateObject, getLocalDateString } from '../../utilities/dateCalculation';
import updateLocalListingData from '../../utilities/updateLocalListingData';
import { NotificationsApiCrashedError } from './NotificationsError';
import NotificationsListingHeaders from './NotificationsListingHeaders';
import NotificationsModalFooter from './NotificationsModalFooter';
import NoAvailableNotificationsSection from './NoAvailableNotificationsSection';
import NotificationRow from './NotificationRow';
import { useInterval } from '../../hooks/useInterval';
import ShowMoreNotifications from './ShowMoreNotifications';

const dateGroupsFunction = () => {
  return last30DaysTemplateObject(Date.now());
}

const getNotificationsData = async (beforeTimestamp: number, afterTimestamp: number) => {
  let after = 0;
  if (afterTimestamp !== 0) {
    after = afterTimestamp;
  } else {
    const dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 30);
    dateObj.setHours(0, 0, 0, 0);
    after = dateObj.getTime();
  }

  try {
    const data: NotificationsApiReponse = await getLast20Notifications(beforeTimestamp, after);
    return data;
  } catch (err) {
    console.log(err)
    return null;
  }
}

const shouldPollingContinueFn = (result: any) => {
  //Return true to continue and false to stop permanently
  return true;
};

const visibilityChangeDetect = () => {
  return (document.visibilityState === 'visible')
}

const Notifications = () => {
  const { t: translate } = useTranslation();
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
  const [notificationsData, setNotificationsData] = useState<NotificationsApiReponse>({ data: [], meta: { new: 0, total: 0, unseen: 0 } });
  const [notificationsError, setNotificationsError] = useState(false);
  const [showMoreNotificationsButton, setShowMoreNotificationsButton] = useState(true);
  const [moreNotificationsLoading, setMoreNotificationsLoading] = useState(false);
  const [errorLoadingMoreNotifications, setErrorLoadingMoreNotifications] = useState(false);
  const [dateGroupsObject, setDateGroupsObject] = useState<DateGroupsObject>({});

  const groupNotificationsByDate = () => {
    if (notificationsData.data.length) {
      const tempDateGroupObject = dateGroupsFunction();
      notificationsData.data.forEach((item: NotificationItem) => {
        let dateObj = new Date(item.created);
        const localDateString = getLocalDateString(dateObj.setDate(dateObj.getDate()));
        if (localDateString in tempDateGroupObject) {
          tempDateGroupObject[localDateString].notificationsList.push(item);
        }
      })
      setDateGroupsObject(tempDateGroupObject)
    }
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeDetect);
  }, [])

  const updateNotificationsData = () => {
    const afterTimestamp = notificationsData.data.length ? notificationsData.data[0].created + 1 : 0;
    getNotificationsData(Date.now(), afterTimestamp).then(responseData => {
      if (responseData && responseData.data.length) {
        setNotificationsError(false);
        const tempNotificationsData = {
          data: responseData.data.concat(notificationsData.data),
          meta: { ...responseData.meta }
        }
        setNotificationsData(tempNotificationsData)
        if (!notificationsData.data.length && responseData.data.length < 20) {
          setShowMoreNotificationsButton(false); //Don't display Show More Notifications button when no. of notifications returned for initial load is less than 20
        }
      }
      else if (responseData && !responseData.data.length && notificationsData.data.length) {
        setNotificationsError(false);
        setNotificationsData({ ...notificationsData, meta: { ...responseData.meta } })
      }
      else if (!responseData && !notificationsData.data.length) {
        setNotificationsError(true);  //Update error state to show notifications api has crashed UI
      }
    })
  }

  useInterval(() => { //Polling logic
    if (visibilityChangeDetect()) {
      updateNotificationsData();
    }
  }, shouldPollingContinueFn, 45 * 1000); //Should poll after every 45 seconds interval only

  useEffect(() => {
    if (notificationsData.data.length) {
      groupNotificationsByDate();
      // const documentTitle = document.title.toLowerCase().startsWith("(") ? document.title.split(")")[1] : document.title; //notification counter in page title
      // document.title = (notificationsData.meta.unseen === 0 ? documentTitle : `(${notificationsData.meta.unseen}) ${documentTitle}`);
    }
  }, [notificationsData])

  const renderDateGroups = () => {
    let dateGroupListing = [];
    let key = 0;
    for (let dateGroup in dateGroupsObject) {
      let translatedDate = dateGroupsObject[dateGroup].relativeDate;
      if (dateGroupsObject[dateGroup].relativeDate.includes("ui.notifications.timeGrouping")) {
        translatedDate = translate(dateGroupsObject[dateGroup].relativeDate);
      }
      dateGroupsObject[dateGroup].notificationsList.length && dateGroupListing.push(
        <li className='dateGroupWrapper' key={++key}>
          <div className='dateGroupHeading paddingLeftRight24'>{translatedDate}</div>
          {
            dateGroupsObject[dateGroup].notificationsList.map((item, index) => {
              return <NotificationRow translate={translate} handleMarkReadUnreadBtnClick={handleMarkReadUnreadBtnClick} rowItem={item} key={item.messageId} />
            })
          }
        </li>
      )
    }
    return dateGroupListing;
  }

  const handleNotificationsIconClick = async () => {
    setShowNotificationsPopup(!showNotificationsPopup);
    if (!showNotificationsPopup) {
      await getLastSeen();
      updateNotificationsData();
    }
  }

  const handleMarkReadUnreadBtnClick = async (actionType: string, notificationItem: NotificationItem) => {
    const data = {
      status: { [notificationItem.messageId]: actionType }
    }
    try {
      const updatedNotificationItem = { ...notificationItem, status: actionType }
      const updatedListingData = updateLocalListingData(
        notificationsData,
        updatedNotificationItem,
        actionType
      );
      setNotificationsData(updatedListingData);
      const response = await markNotificationsAsReadOrUnread(data);
      console.log(response);
    }
    catch (error) {
      console.log("Some error occurred while updating task status", error);
    }
  }

  const handleMarkAllAsReadBtnClick = async () => {
    const data: { status: { [key: string]: string } } = { status: {} }
    notificationsData.data.forEach((item) => { if (item.status.toLowerCase() !== "opened") data.status[item.messageId] = "opened" })
    try {
      const updatedNotificationsList = notificationsData.data.map((item) => {
        return { ...item, status: "opened" }
      })
      const updatedListingData = { data: updatedNotificationsList, meta: { ...notificationsData.meta, new: 0 } };
      setNotificationsData(updatedListingData);
      const response = await markNotificationsAsReadOrUnread(data);
      console.log(response);
    }
    catch (error) {
      console.log("Some error occurred while updating task statuses", error);
    }
  }

  const handleShowMoreNotificationsClick = () => {
    setMoreNotificationsLoading(true);
    setErrorLoadingMoreNotifications(false);
    const currentOldestNotificationTimestamp = notificationsData.data[notificationsData.data.length - 1].created;
    getNotificationsData(currentOldestNotificationTimestamp - 1, 0).then(responseData => {
      setMoreNotificationsLoading(false);
      if (responseData) {
        if (responseData.data.length < 20) { //Don't display Show More Notifications button when no. of notifications returned is less than 20
          setShowMoreNotificationsButton(false);
        }
        if (responseData.data.length) {
          const tempNotificationsData = { data: [...notificationsData.data], meta: { ...responseData.meta } }
          responseData.data.forEach((item) => {
            tempNotificationsData.data.push(item);
          })
          setNotificationsData(tempNotificationsData)
        }
      }
      else {
        setErrorLoadingMoreNotifications(true);
      }
    })
  }

  const modalTitle = <span className="notificationsCountWrapper">
    {translate('ui.notifications.title.notifications')}
    <span className="newNotificationsCount">
      {notificationsData.meta.unseen}
    </span>
  </span>

  const notificationsListing = <>
    <NotificationsListingHeaders translate={translate} />
    <div className={`notificationsListingWrapper ${notificationsData.meta.new > 0 ? "notificationsListingWrapperHeightSmall" : "notificationsListingWrapperHeightFull"}`}>
      {renderDateGroups().map(item => item)}
      {showMoreNotificationsButton && <ShowMoreNotifications translate={translate} moreNotificationsLoading={moreNotificationsLoading} errorLoadingMoreNotifications={errorLoadingMoreNotifications} handleShowMoreNotificationsClick={handleShowMoreNotificationsClick} />}
    </div>
    {notificationsData.meta.new > 0 && <NotificationsModalFooter translate={translate} handleMarkAllAsReadBtnClick={handleMarkAllAsReadBtnClick} />}
  </>

  return (
    <div data-testid='Notifications'>
      <picture
        data-testid='NotificationsIconWrapper'
        className='notificationsIcon flexCenter'
        id='notificationsIcon'
        onClick={handleNotificationsIconClick}>

        <div className="base">
          <Icon icon="notifications" size={32} test-id="NotificationsIcon" />
          {notificationsData.meta.unseen > 0 && (
            <div className='noti_count' role="status" data-testid="NotificationsIconCount">
              <span className='noti_count_text'>
                {notificationsData.meta.unseen > 9 ? "9+" : notificationsData.meta.unseen}
              </span>
            </div>
          )
          }
        </div>

      </picture>

      {
        showNotificationsPopup && <Modal
          test-id='notificationsWrapperModal'
          visible={showNotificationsPopup}
          shouldCloseOnEsc={true}
          onClose={handleNotificationsIconClick}
          modalClass={"notificationsModalWrapper"}
          modalOverlayClass={"notificationsModalOverlay"}
          crossIconSize={18}
          closeBtnClass="modalCrossIcon"
          title={modalTitle}
          titleClass='modalHeading'
          contentClass='modalInnerWrapper'
        >
          {
            notificationsError ?
              <NotificationsApiCrashedError translate={translate} />
              : (
                !notificationsData.data.length ?
                  <NoAvailableNotificationsSection text={translate("ui.notifications.listing.noNotificationsInLast30Days")} svgClass="noNotificationsImageScaleUp" />
                  : notificationsListing
              )
          }
        </Modal>
      }
    </div>
  )
}

export default Notifications
