import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import {ValidatedForm} from '@fcg/formvalidation'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {withStyles} from '@material-ui/core/styles'
import CustomSnackbar from '../../components/Snackbar'

import Edit from './Edit'
import {default as selectors} from '../../store/transitJob/selectors'
import {getCountryCode} from '../../store/config/selectors'
import {CAR, TRUCK, TRANSIT} from './constants'
import styles from '../CRM/common/dialogStyles'
import {
  updateFormData,
  saveTransit,
  hydrateTransitForm,
  resetTransitForm,
  setHydrateFormStatus
} from '../../store/transitJob/actions'
import {fetchDriverOptions} from '../../store/driver/actions'
import LoadingState from '../../components/LoadingState'
import TransitJobTile from './TransitJobTile'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}

const RadioButtonType = withStyles({
  root: {
    color: '#00796b',
    '&$checked': {
      color: '#00796b'
    }
  }
})((props) => <Radio color='default' {...props} />)

const LoaderWrapper = styled.div`
  min-height: 200px;
  display: flex;
`

const removeNullFromArray = (arr) => arr.filter((val) => val)

class TransitEditDialog extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      hasLoaded: false,
      isValid: true,
      transitType: CAR,
      transits: {
        0: {
          carId: '',
          startMileage: null
        }
      },
      selectedCars: {},
      fieldWithErrorType: {},
      creation: this.props.match.path.includes('/transit-job/create')
    }
  }

  componentDidMount() {
    this._isMounted = true

    if (this._isMounted) {
      const {match} = this.props
      const {creation} = this.state

      this.props.fetchDriverOptions()

      if (!creation && match.params.id) {
        this.props.loadData({id: match.params.id})
      }

      this.setState({data: this.props.data})
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.form = null

    this.props.setHydrateFormStatus(false)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const isCreateUrl = nextProps.match.path.includes('/transit-job/create')

    if (isCreateUrl) {
      return null
    }

    if (
      !prevState.hasLoaded &&
      nextProps.form !== null &&
      nextProps.form.transits !== null &&
      nextProps.form.transits.length > 0
    ) {
      let transitType = nextProps.form.transitType

      if (!transitType) {
        transitType = nextProps.form.transits.length > 1 ? TRUCK : CAR
      }

      return {
        transits: nextProps.form.transits.reduce((acc, transit, index) => {
          if (transit) {
            return {
              ...acc,
              [index]: {
                carId: transit.carId,
                startMileage: transit.startMileage
              }
            }
          }

          return acc
        }, {}),
        selectedCars: nextProps.form.transits.reduce((acc, transit, index) => {
          if (transit) {
            return {
              ...acc,
              [index]: transit.carId
            }
          }

          return acc
        }, {}),
        transitType,
        hasLoaded: true
      }
    }

    return null
  }

  componentDidUpdate(prevProps) {
    if (this.props.editState.success && !prevProps.editState.success) {
      this.handleSubmit()
    }
  }

  updateTransitType = (event) => {
    this.setState({
      transitType: event.target.value
    })

    this.handleInput(null, 'driverId')
  }

  checkFieldsForError = () => {
    const transits = removeNullFromArray(Object.values(this.state.transits))
    const carIdsArray = transits.map((car) => car.carId)
    let fieldWithErrorType = {}
    let transitHasError = false

    Object.values(this.state.transits).forEach((transit, index) => {
      if (transit !== null) {
        let errorType = 'form.message.validation.required'
        let hasError = false
        let hasErrorOnMileage = false

        if (!transit.carId) {
          hasError = true
        }

        if (transit.startMileage && transit.startMileage < 0) {
          hasErrorOnMileage = true
        }

        if (this.state.transitType === TRUCK && transit.carId) {
          const intersectionArray = carIdsArray.filter(
            (id) => id === transit.carId
          )

          if (intersectionArray.length > 1) {
            errorType = 'form.message.validation.unique'
            hasError = true
          }
        }

        if ((hasError || hasErrorOnMileage) && !transitHasError) {
          transitHasError = true
        }

        fieldWithErrorType = {
          ...fieldWithErrorType,
          [index]: {
            carId: hasError ? errorType : null,
            startMileage: hasErrorOnMileage
              ? 'form.message.validation.incorrectValue'
              : null
          }
        }
      }
    })

    return {transitHasError, fieldWithErrorType}
  }

  handleSubmit = async () => {
    const {match, form} = this.props
    const transitsArr = removeNullFromArray(Object.values(this.state.transits))
    const transits =
      this.state.transitType === CAR ? [transitsArr[0]] : transitsArr
    const data = {...form, transitType: this.state.transitType, transits}
    const {transitHasError, fieldWithErrorType} = this.checkFieldsForError()
    const isValid = await this.form.validate()

    this.setState({
      isValid: !transitHasError,
      fieldWithErrorType
    })

    if (isValid && !transitHasError) {
      if (this.state.creation) {
        this.props.save(data)
      } else if (match.params.id) {
        this.props.save({...data, ...{id: match.params.id}})
      }
    }
  }

  updateDriver = (e, name) => {
    const value = e && e.target ? e.target.value : e
    const driver = this.props.formOptions.driverOptions.find(
      (driver) => driver.value == value
    )

    this.handleInput(e, name)
    this.props.updateFormData({
      name: 'driver',
      value: driver && driver.label ? driver.label : null
    })
  }

  handleInput = (e, name) => {
    const value = e && e.target ? e.target.value : e

    this.props.updateFormData({
      name,
      value
    })
  }

  updateSelectedTransit = (value, index, type) => {
    if (type === TRANSIT) {
      const {transits} = this.state
      const currValues = transits[index] || {}

      this.setState(
        {
          transits: {
            ...transits,
            [index]: {
              ...currValues,
              ...value
            }
          }
        },
        () => {
          if (!this.state.isValid) {
            const {
              transitHasError,
              fieldWithErrorType
            } = this.checkFieldsForError()

            this.setState({
              isValid: !transitHasError,
              fieldWithErrorType
            })
          }
        }
      )
    } else {
      this.setState(
        {
          selectedCars: {
            ...this.state.selectedCars,
            [index]: value
          }
        },
        () => {
          if (!this.state.isValid) {
            const {
              transitHasError,
              fieldWithErrorType
            } = this.checkFieldsForError()

            this.setState({
              isValid: !transitHasError,
              fieldWithErrorType
            })
          }
        }
      )
    }
  }

  removeSelectedTransit = (index) => {
    this.setState(
      {
        transits: {
          ...this.state.transits,
          [index]: null
        },
        selectedCars: {
          ...this.state.selectedCars,
          [index]: null
        }
      },
      () => {
        if (!this.state.isValid) {
          const {
            transitHasError,
            fieldWithErrorType
          } = this.checkFieldsForError()

          this.setState({
            isValid: !transitHasError,
            fieldWithErrorType
          })
        }
      }
    )
  }

  addNewTransit = (index) => {
    this.setState({
      transits: {
        ...this.state.transits,
        [index]: {
          carId: '',
          startMileage: null
        }
      }
    })
  }

  renderTransitJobs = () => {
    const {
      transits,
      selectedCars,
      transitType,
      fieldWithErrorType,
      isValid
    } = this.state

    const values = Object.values(transits)
    const transitArr =
      transitType === CAR ? [removeNullFromArray(values)[0]] : values

    if (transitType)
      return (
        <div>
          {transitArr.map((transit, index) => {
            if (transit) {
              return (
                <TransitJobTile
                  selectedCars={selectedCars}
                  updateSelectedTransit={this.updateSelectedTransit}
                  removeSelectedTransit={this.removeSelectedTransit}
                  addNewTransit={this.addNewTransit}
                  t={this.props.t}
                  index={index}
                  transits={transitArr}
                  transitType={transitType}
                  transit={transit}
                  key={`job-tile-${index}`}
                  isValid={isValid}
                  fieldWithErrorType={fieldWithErrorType}
                />
              )
            }
          })}
        </div>
      )
  }

  handleClose = () => {
    this.props.reset()
    this.props.history.goBack()
  }

  render() {
    const {t, form, formOptions} = this.props

    const dialogTitle = this.state.creation
      ? `${t('global.action.create')} ${t('entity.transitsJobs')}`
      : `${t('global.action.update')} ${t('entity.transitsJobs')}`
    const buttonText = this.state.creation
      ? t('global.action.create')
      : t('global.action.update')

    return (
      <Fragment>
        <Dialog
          open={true}
          maxWidth={'md'}
          fullWidth={true}
          onClose={this.handleClose}
          handleSubmit={this.handleSubmit}
        >
          {this.props.isFetchingFormData ? (
            <LoaderWrapper>
              <LoadingState />
            </LoaderWrapper>
          ) : (
            <ValidatedForm ref={(ref) => (this.form = ref)} t={t}>
              <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
              <DialogContent>
                <RadioGroup
                  name='type'
                  row
                  value={this.state.transitType}
                  onChange={this.updateTransitType}
                >
                  <FormControlLabel
                    display='inline'
                    control={
                      <RadioButtonType
                        value={CAR}
                        checked={this.state.transitType === CAR}
                      />
                    }
                    label={t('transitList.placeholder.isDriver')}
                  />
                  <FormControlLabel
                    display='inline'
                    control={
                      <RadioButtonType
                        value={TRUCK}
                        checked={this.state.transitType === TRUCK}
                      />
                    }
                    label={t('transitList.placeholder.isTruck')}
                  />
                </RadioGroup>
                <Edit
                  {...this.props}
                  data={form}
                  options={formOptions}
                  handleTextInput={this.handleInput}
                  updateDriver={this.updateDriver}
                  t={this.props.t}
                />
                {this.renderTransitJobs()}
              </DialogContent>
              <DialogActions style={dialogActionsStyles}>
                <Button onClick={this.handleClose} color='primary'>
                  {t('button.cancel')}
                </Button>
                <Button
                  variant='raised'
                  type='submit'
                  onClick={this.handleSubmit}
                  color='primary'
                >
                  {buttonText}
                </Button>
              </DialogActions>
            </ValidatedForm>
          )}
        </Dialog>
        <CustomSnackbar />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {config} = state
  const locationOptions = config.options.locationOptions || {}
  const formOptions = {
    locationOptions: locationOptions['CURRENT'],
    driverOptions: selectors.getDriverOptionsWithType(state)
  }

  return {
    form: selectors.getFormData(state),
    editState: selectors.getEditState(state),
    countryCode: getCountryCode(state),
    formOptions,
    isFetchingFormData: selectors.isFetchingFormData(state),
    pageConfig: config?.pages?.transit
  }
}

const mapDispatchToProps = (dispatch) => ({
  save: (payload) => dispatch(saveTransit(payload)),
  updateFormData: (payload) => dispatch(updateFormData(payload)),
  loadData: ({id}) => dispatch(hydrateTransitForm({id})),
  reset: () => dispatch(resetTransitForm()),
  setHydrateFormStatus: () => dispatch(setHydrateFormStatus()),
  fetchDriverOptions: () => dispatch(fetchDriverOptions())
})

const TransitEditorForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(TransitEditDialog))

export default withMobileDialog()(
  withStyles(styles)(withRouter(TransitEditorForm))
)
