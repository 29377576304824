import * as types from './types'
import {appointmentSchema, getInitialState} from './initialState'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'

const reducer = {
  [types.loadingSlots]: (state, {payload = true}) => {
    return {
      ...state,
      loadingSlots: payload
    }
  },
  [types.locationChange]: (state, action) => {
    return {
      ...state,
      loadingSlots: false,
      options: {
        ...state.options,
        appointmentSlots: action.payload.map((a) => ({
          ...a,
          value: `${a.value.date} ${a.value.time}`
        }))
      }
    }
  },
  [types.cancelPending]: (state) => {
    return {
      ...state,
      cancelState: {
        pending: true
      }
    }
  },
  [types.cancelSuccess]: (state) => {
    return {
      ...state,
      cancelState: {
        pending: false
      }
    }
  },
  [types.setCarLink]: (state, action) => {
    return {
      ...state,
      carLink: action.payload
    }
  },
  [types.showCarCreatedDialogBox]: (state) => {
    return {
      ...state,
      isCarCreatedDialogBoxVisible: true
    }
  },
  [types.hideCarCreatedDialogBox]: (state) => {
    return {
      ...state,
      isCarCreatedDialogBoxVisible: false
    }
  }
}

export default {
  ...reducer,
  ...tableDecorators.generateReducer(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: appointmentSchema
  })
}
