import pick from 'lodash/pick'

export const withDefaultForms = (types, initialState) => ({
  [types.updateForm]: (state, action) => {
    const {name, value} = action.payload

    return {
      ...state,
      form: {
        ...state.form,
        [name]: value
      }
    }
  },
  [types.resetForm]: (state) => {
    const {form, editState} = initialState.getInitialState()

    return {
      ...state,
      form,
      editState
    }
  },
  [types.hydrateForm]: (state, action) => {
    const form = pick(action.payload, Object.keys(initialState.schema))

    return {
      ...state,
      form: {...state.form, ...form},
      editState: {...state.editState, pending: false}
    }
  },
  [types.formSuccess]: (state) => {
    return {
      ...state,
      editState: {
        pending: false,
        error: false,
        success: true
      }
    }
  },
  [types.formError]: (state, action) => {
    return {
      ...state,
      editState: {
        pending: false,
        error: action.payload,
        success: false
      }
    }
  }
})

export const withFormPending = (types) => ({
  [types.formPending]: (state) => {
    return {
      ...state,
      editState: {
        ...state.editState,
        pending: true
      }
    }
  }
})

export const generateReducer = (types, initialState) => ({
  ...withDefaultForms(types, initialState),
  ...withFormPending(types)
})
