import {fieldSelectorCreator} from '../../utils/selectorHelpers'
import {createSelector} from 'reselect'
import {
  view,
  pickAll,
  lensPath,
  zipObj,
  concat,
  reduce,
  path,
  values,
  map,
  compose
} from 'ramda'

import {getRoles, getLocations} from '../../crmUserGroups/selectors'
import {getCountryConfig} from '../../../../config/country'

const defaultLimit = 25

const selectorPath = (state) => state.leadAllocation
const listSelectorPath = (state) => selectorPath(state).list

export const leadAllocationFields = (state) => {
  const {list} = selectorPath(state)
  if (list.fields) {
    return Object.values(list.fields)
  }
}

export const childrenReducer = (fields, hiddenFields, hiddenFieldPropName) => {
  return fields.reduce((acc, field) => {
    if (!(field && hiddenFields.includes(field[hiddenFieldPropName]))) {
      if (
        field.type === 'composite' &&
        field.children &&
        field.children.length
      ) {
        acc.push({
          ...field,
          children: childrenReducer(
            field.children,
            hiddenFields,
            hiddenFieldPropName
          )
        })
      } else if (field.type === 'table') {
        acc.push({
          ...field,
          fields: removeHiddenColumnsFromTableField(
            field.fields || {},
            hiddenFields,
            hiddenFieldPropName
          )
        })
      } else {
        acc.push(field)
      }
    }

    return acc
  }, [])
}

export function removeHiddenColumnsFromTableField(
  obj,
  hiddenColumns,
  hiddenFieldPropName
) {
  const res = {}

  Object.entries(obj).forEach(([key, value]) => {
    res[key] = {
      ...value,
      ...(value.children
        ? {
            children: childrenReducer(
              value.children,
              hiddenColumns,
              hiddenFieldPropName
            )
          }
        : {})
    }
  })

  return res
}

export const getLimit = (state) => {
  const {limit} = listSelectorPath(state)

  return limit !== null ? limit : defaultLimit
}
export const getSort = (state) => listSelectorPath(state).sort
export const getPage = (state) => {
  const {page} = listSelectorPath(state)

  return page !== null ? page : 1
}

export const allocationFields = (fieldName) => (state) => {
  const fields = listSelectorPath(state)[fieldName]

  if (fields) {
    return Object.values(fields).map((item) => ({[item.filterKey]: item.value}))
  }
}

export const getFilters = fieldSelectorCreator(
  allocationFields('filters'),
  'crmDetails',
  'hiddenFilters',
  'filterName'
)
export const getFields = fieldSelectorCreator(
  allocationFields('fields'),
  'crmDetails',
  'hiddenColumns',
  'columnName'
)

export const getVisibleFilters = createSelector(selectorPath, (config) => {
  const {crmDetails} = getCountryConfig()

  return config.list.fields.filter(
    (item) => !crmDetails.hiddenFilters.includes(item.key)
  )
})

export const getLeadAllocationFields = createSelector(
  leadAllocationFields,
  (fields) => {
    const {crmDetails: config} = getCountryConfig()
    if (!config.hiddenColumns && fields) {
      return fields
    }
    if (fields && fields.length) {
      return childrenReducer(fields, config.hiddenColumns, 'columnName')
    }
    return []
  }
)

const rolesLookup = reduce(
  (acc, {value, label}) => ({...acc, [value]: label}),
  {}
)
const leadSourceLens = lensPath(['tierCriteria', '0', 'value', 'leadSource'])
const statusLens = lensPath(['tierCriteria', '0', 'value', 'status'])
const makeLens = lensPath(['tierCriteria', '0', 'value', 'make'])
const appointmentStatus = lensPath([
  'tierCriteria',
  '0',
  'value',
  'appointmentStatus'
])
const emailLens = lensPath(['tierCriteria', '0', 'value', 'email'])
const phoneLens = lensPath(['tierCriteria', '0', 'value', 'phone'])
const criteriaIdLens = lensPath(['tierCriteria', '0', 'id'])
const locationLens = lensPath(['tierCriteria', '0', 'value', 'placeId'])

const tierFields = [
  'id',
  'tierName',
  'tierMaxLeads',
  'tierLeadSource',
  'tierLeadStatus',
  'tierMake',
  'tierEmail',
  'tierPhone',
  'tierCriteriaId',
  'tierUserGroup',
  'tierUserGroupLabel',
  'tierWeight',
  'tierAppointmentStatus',
  'tierLocation'
]

const extractTierFields = (roles, locations) => (tier) => {
  let appointmentStatusValue = view(appointmentStatus)(tier)
  appointmentStatusValue =
    appointmentStatusValue && appointmentStatusValue.join(', ')
  let tierLocation = ''
  if (locations) {
    tierLocation = view(locationLens)(tier)
    tierLocation = locations[tierLocation] || ''
  }

  return concat(values(pickAll(['id', 'name', 'maxAssignments'], tier)), [
    view(leadSourceLens)(tier),
    view(statusLens)(tier),
    view(makeLens)(tier),
    view(emailLens)(tier),
    view(phoneLens)(tier),
    view(criteriaIdLens)(tier),
    tier.userRoleId,
    roles[tier.userRoleId],
    tier.weight,
    appointmentStatusValue,
    tierLocation
  ])
}

export const getTiers = createSelector(
  listSelectorPath,
  getRoles,
  getLocations,
  (list, roles, locations) => {
    const lookupRoles = rolesLookup(roles)

    locations = locations.reduce((acc, {label, value}) => {
      acc[value] = label
      return acc
    }, {})

    return compose(
      map(
        compose(zipObj(tierFields), extractTierFields(lookupRoles, locations))
      ),
      values,
      path(['data'])
    )(list)
  }
)

const getSelectedTierId = (props) => view(lensPath(['params', 'id']))(props)
const initialTier = {
  id: '',
  tierCriteriaId: '',
  tierEmail: '',
  tierLeadSource: '',
  tierLeadStatus: '',
  tierMake: '',
  tierMaxLeads: '',
  tierName: '',
  tierPhone: '',
  tierUserGroup: '',
  tierUserGroupLabel: '',
  tierWeight: ''
}

const getStringifiedProperty = (data) => (Array.isArray(data) ? data[0] : data)

export const getSelectedTier = (props) =>
  createSelector(getTiers, (tiers) => {
    const selectedId = getSelectedTierId(props)
    let tier = null

    if (selectedId) {
      tier = tiers.find(({id}) => id === selectedId)
    }

    //TODO: Should be handled from backend
    if (tier) {
      tier = {
        ...tier,
        tierEmail: getStringifiedProperty(tier.tierEmail),
        tierLeadSource: getStringifiedProperty(tier.tierLeadSource),
        tierLeadStatus: getStringifiedProperty(tier.tierLeadStatus),
        tierMake: getStringifiedProperty(tier.tierMake),
        tierPhone: getStringifiedProperty(tier.tierPhone)
      }
    }

    return tier || initialTier
  })
