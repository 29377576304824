import * as types from './types'
import {getInitialState, pdfPreferenceSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'
import differenceWith from 'lodash/differenceWith'

const reducers = {
  [types.fetchPreferenceListSuccess]: (state, action) => ({
    ...state,
    data: action.data.list,
    count: action.data.count
  }),
  [types.loadPdfPreference]: (state, action) => ({
    ...state,
    selectedPreferenceId: action.id
  }),
  [types.setSelectedPreferenceData]: (state, action) => ({
    ...state,
    preferenceData: {
      ...state.preferenceData,
      ...action.data
    }
  }),
  [types.deleteDocumentSuccess]: (state, action) => ({
    ...state,
    documentFetching: false,
    documentList: differenceWith(
      state.documentList,
      action.payload.documentIds,
      (a, b) => a.id === b
    )
  }),
  [types.updatePdfPreferenceField]: (state, action) => ({
    ...state,
    preferenceData: {
      ...state.preferenceData,
      [action.name]: action.value
    }
  }),
  [types.updatePdfPreferenceMetaField]: (state, action) => ({
    ...state,
    preferenceData: {
      ...state.preferenceData,
      metaInfo: {
        ...state.preferenceData.metaInfo,
        [action.name]: action.value
      }
    }
  }),
  [types.resetPreferenceData]: (state) => ({
    ...state,
    preferenceData: {
      ...pdfPreferenceSchema
    }
  }),
  [types.setUploading]: (state, action) => ({
    ...state,
    isUploading: action.payload
  }),
  [types.fetchDocumentListSuccess]: (state, action) => ({
    ...state,
    documentList: action.payload
  }),
  [types.setDocumentFetching]: (state, action) => ({
    ...state,
    documentFetching: action.payload
  }),
  [types.setPreviewDocumentUrl]: (state, action) => ({
    ...state,
    documentPreviewUrl: action.payload
  }),
  [types.setReferenceId]: (state, action) => {
    return {
      ...state,
      referenceId: action.payload
    }
  },
  [types.setDealerFileTypeOptions]: (state, action) => ({
    ...state,
    dealerFileTypeOptions: action.payload
  }),
  [types.setReference]: (state, action) => ({
    ...state,
    reference: action.payload
  }),
  [types.cleanUp]: (state) => ({
    ...state,
    documentList: [],
    reference: null,
    userId: null
  })
}

export default {
  ...reducers,
  ...tableDecorators.withSort(types),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: pdfPreferenceSchema
  })
}
