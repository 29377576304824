import {Component} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {Check, Edit, Snooze} from '@material-ui/icons'
import PageTableBase from '../../../components/PageTableBase'
import {updateComment, postponeTask} from '../../../store/crm/comment/actions'
import {
  fetchList,
  toggleSort,
  setSort,
  toggleField,
  updateFilters
} from '../../../store/crm/task/actions'
import {loadOptions} from '../../../store/crm/actions'
import CommentEditor from '../Comment/Editor'
import * as selectors from '../../../store/crm/task/selectors'
import {PermissionWrapper} from '../../../components/Permissions'
import omit from 'lodash/omit'
import {alwaysTrue, getVisibleActions} from '../common/helpers'
import {isOldEntity, orangeColorRow} from '../helpers'

export class List extends Component {
  componentDidMount() {
    this.props.loadOptions()
  }

  routeBack = () => {
    this.props.history.push('/crm/tasks')
  }

  handleUpdate = () => {
    this.props.fetchData()
    this.routeBack()
  }

  singleSelectActions = [
    {
      test: alwaysTrue,
      action: {
        event: (item) => {
          this.props.history.push(`${this.props.match.url}/${item.id}/edit`)
        },
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.props.markTaskAsDone,
        icon: <Check />,
        title: 'global.action.markDone',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: this.props.postponeTask,
        icon: <Snooze />,
        title: 'global.button.postpone',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  renderTaskEditor = (routeProps) => (
    <CommentEditor
      {...routeProps}
      type='task'
      isOpen
      handleClose={this.routeBack}
      handleSubmit={this.handleUpdate}
    />
  )
  render() {
    const {className} = this.props
    const tableProps = omit(this.props, ['match', 'history', 'className'])

    return (
      <PermissionWrapper entity='web.admin.ui.crm.tasks' withDescription>
        <div className={className}>
          <PageTableBase
            {...tableProps}
            filterable
            title='entity.task'
            page={this.props.currentPage}
            data={this.props.data}
            fields={this.props.fields}
            singleSelectActions={this.singleSelectTableActions()}
            rowStyle={orangeColorRow}
          />
          <Route
            exact
            path={`${this.props.match.url}/:id/edit`}
            render={this.renderTaskEditor}
          />
        </div>
      </PermissionWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  const {page, ...taskList} = state.task.list

  return {
    ...taskList,
    data: Object.values(taskList.data),
    // [CONVERSION]
    filterFields: selectors.taskFields(state),
    fields: selectors.getTaskFields(state),
    currentPage: page,
    error: false
  }
}

const mapDispatchToProps = (dispatch) => {
  const loadList = () => {
    dispatch(fetchList())
  }

  return {
    loadOptions: () => dispatch(loadOptions()),
    fetchData: (params) => dispatch(fetchList(params)),
    onFilterChange: (filter) => dispatch(updateFilters(filter)),
    markTaskAsDone: ({id}) => {
      dispatch(updateComment(id, {status: 'CLOSE'}, loadList))
    },
    toggleSort: (sort) => dispatch(toggleSort(sort)),
    updateSort: (sort) => dispatch(setSort(sort)),
    toggleField: ({key}) => dispatch(toggleField(key)),
    postponeTask: (task) => dispatch(postponeTask(task, loadList))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
