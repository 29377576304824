import {PureComponent} from 'react'
import {
  SubtableContainer,
  SubtableContentRight,
  SubtableContentLeft
} from '../../../../components/Subtable/styles'
import {DocumentPreview} from '../../../../components/DocumentPreview/DocumentPreview'
import {translate} from 'react-i18next'
import {Table} from './Table'
import {hasSelfInspectionData} from './util'
import {DocumentInfo} from './DocumentInfo'

class SelfInspectionDialogBody extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedImageIndex: 0
    }
  }

  setIndex = (index) => this.setState({selectedImageIndex: index})

  render() {
    if (!hasSelfInspectionData(this.props)) {
      return (
        <div>{this.props.t('crm.page.contact.leads.noSelfInspection')}</div>
      )
    }

    return (
      <SubtableContainer>
        <SubtableContentLeft width={50}>
          <DocumentPreview
            selectedImageIndex={this.state.selectedImageIndex}
            data={this.props.data.documents}
            t={this.props.t}
            HeaderChild={DocumentInfo}
            showSelectedImageInList
            customStyles={{}}
            onSelectImage={this.setIndex}
          />
        </SubtableContentLeft>
        <SubtableContentRight width={50}>
          <Table
            {...this.props}
            data={this.props.data.selfInspection}
            onSelectImage={this.setIndex}
          />
        </SubtableContentRight>
      </SubtableContainer>
    )
  }
}

export const DialogBody = translate()(SelfInspectionDialogBody)
