export const DEALER_BP_TYPE = 'dealer'

export const MOCK_ORDER_ID = '12345-6789'

export const PRIMARY_CONTACT_TYPE = 'primary'

export const BP_SEARCH_RESULT_HEADER_OPTIONS = [
  'action',
  'id',
  'legalName',
  'phone',
  'email'
]
export const BP_DETAILS_TABS_KEYS = [
  'contacts',
  'address',
  'taxInfo',
  'bankDetails'
]

export const PAYMENT_PROPERTIES_IN_METAINFO = ['paymentMethods', 'paymentTerms']

export const BP_TYPES = [
  {
    value: 'dealer',
    label: 'dealer'
  },
  {
    value: 'individual',
    label: 'individual'
  },
  {
    value: 'govt-agency',
    label: 'govt-agency'
  },
  {
    value: 'company',
    label: 'Company'
  }
]

export const BP_STATUS = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'Inactive',
    label: 'Inactive'
  }
]
export const BP_SEARCH_OPTIONS = [
  {
    value: 'legalName',
    label: 'businessPartners.legalName.text'
  },
  {
    value: 'phone',
    label: 'businessPartners.phone.text'
  },
  {
    value: 'email',
    label: 'businessPartners.email.text'
  }
]

export const PERMISSION_ENTITY = 'web.admin.ui.car.bbDealsBusinessPartner'

export const SUPPLIER_B2B_TAG = 'SupplierB2B'
