import gql from '@fcg/lib-gql/gql'
import axios from 'axios'
import {getToken} from '../../utils/authToken'
import {InvalidTokenError} from '../../utils/errors'

export function transformEmptyValues(data = {}, replacement = null) {
  Object.keys(data).forEach((key) => {
    if (data && data[key] && typeof data[key] === 'object') {
      data[key] = transformEmptyValues(data[key])

      return
    }

    data[key] = data[key] === '' ? replacement : data[key]
  })

  return data
}

export function transformToString(data = {}, fields = []) {
  Object.keys(data).forEach((key) => {
    if (data[key] && fields.indexOf(key) >= 0) {
      data[key] = data[key].toString()
    }
  })

  return data
}

export const removeEmptyValues = (data = {}) =>
  Object.keys(data).reduce((acc, key) => {
    if (data && data[key] && typeof data[key] === 'object') {
      acc[key] = removeEmptyValues(data[key])

      return acc
    }

    if (data && data[key]) {
      acc[key] = data[key]
    }

    return acc
  }, {})

export const fetchAdditionalData = () => ({
  params: {
    fetchAdditionalData: true
  }
})

export const fetchDataForPayslips = () => ({
  params: {
    fetchDataForPayslips: true
  }
})

export const fetchAdditonalDataForCountry = (country) => () => ({
  params: {
    country
  }
})

export const formatCountry = (request) => {
  request.country =
    typeof request.country === 'string'
      ? new gql.EnumType(request.country)
      : request.country

  return request
}

function isValidToken() {
  const token = getToken()
  if (token === false) {
    throw new InvalidTokenError()
  }

  return token
}

export const makeRestApiCall = async (
  baseUrl,
  {
    method,
    responseType,
    formData,
    params,
    isMultipart,
    hasIncreaseTimeout,
    isTokenRequired
  }
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const token = isValidToken()

    let headers = {
      'content-type': isMultipart ? 'multipart/form-data' : 'application/json',
      'x-increase-timeout': hasIncreaseTimeout ? true : false
    }

    const authHeader = isTokenRequired ? {Authorization: `Bearer ${token}`} : {}

    headers = {...headers, ...authHeader}

    const config = {
      params,
      method,
      url: baseUrl,
      headers: headers,
      timeout: hasIncreaseTimeout ? 600000 : 300000,
      responseType: responseType,
      data: formData
    }

    const response = await axios(config)
    if (response?.data?.statusCode === 200) {
      return response
    } else throw new Error('Something went wrong!!')
  } catch (err) {
    throw err
  }
}
