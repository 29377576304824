import {Component} from 'react'
import gql from '@fcg/lib-gql'
import UserPicker from '../../../components/Pickers/UserPicker'

const formatDealerLabel = (item) => {
  const phone =
    item.metaInfo && item.metaInfo.phone ? `( ${item.metaInfo.phone} )` : ''
  const companyName =
    item.metaInfo && item.metaInfo.companyName ? item.metaInfo.companyName : '/'

  return `#${item.internalId} ${item.firstname} ${item.lastname} ${phone} - ${companyName}`
}

export class DealerPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      count: 0
    }
    this.userPickerQuery = {
      userClass: 'DEALER',
      country: new gql.EnumType(props.countryCode),
      metaInfo: {status: {include: ['active']}}
    }
  }

  onOptionsLoaded = (response) => {
    const {options, count} = response

    this.setState({
      options,
      count
    })
  }

  render() {
    const {count, options} = this.state

    return (
      <UserPicker
        withTranslate={false}
        label='Dealer'
        queryParams={this.userPickerQuery}
        limit={25}
        filterable
        debounce
        fullWidth
        count={count}
        options={options}
        onLoad={this.onOptionsLoaded}
        onChange={this.props.onSelect}
        formatLabel={formatDealerLabel}
        data-test={this.props['data-test'] || 'dealer-picker'}
      />
    )
  }
}
