import React from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

interface PageHeaderProps {
  title:string
  showBackButton?: boolean
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>)=>void
}

const PageHeader = ({title, showBackButton = true, onBackClick = () => {}}:PageHeaderProps) => {
  const classes = useCommonStyles()

  return (
    <Grid container className={cx(classes.marginBottom2, classes.colorTitle)}>
      {showBackButton && (
        <Button
          className={classes.marginRight1}
          variant='outlined'
          color='primary'
          startIcon={<ArrowBackIosIcon fontSize='small' />}
          onClick={onBackClick}
        >
          BACK
        </Button>
      )}
      <Typography variant='h5' className={classes.selfCenter} data-test='location-title'>
        {title}
      </Typography>
    </Grid>
  )
}

export default PageHeader
