export const fetchAdditionalData = () => ({
  params: {
    fetchAdditionalData: true
  }
})

export const fetchDataForPayslips = () => ({
  params: {
    fetchDataForPayslips: true
  }
})

export const fetchDataForOwnershipTransfer = () => ({
  params: {
    fetchDataForOwnershipTransfer: true
  }
})
