import omit from 'lodash/omit'

import TransitCarList from './TransitCarList'

export const ExpandableComponent = (props) => {
  const prop = omit(props, [
    'rowIndex',
    'data',
    'rowId',
    'expandableComponentProps'
  ])

  const {data, rowId, expandableComponentProps} = props

  return (
    <TransitCarList
      {...prop}
      {...expandableComponentProps}
      data={data}
      locale={expandableComponentProps.locale}
      rowId={rowId}
      t={expandableComponentProps.t}
    />
  )
}
