import React from 'react';
import { ErrorImageSvg } from './NotificationsIconSvg';
import '../../Notifications.scss';
import { Icon } from 'motofin-ui';


export const NotificationsApiCrashedError = (props: { translate: any }) => {
    const { translate } = props;
    return <div className={`noAvailableNotifications flexCenter`} data-testid="NotificationsCrashedErrorWrapper">
        <ErrorImageSvg />
        <p className="notificationsCrashedErrorTextPrimary">{translate('ui.notifications.error.notificationsApiFailure')}</p>
        <p className="notificationsCrashedErrorTextSecondary">{translate('tms.error.pleaseTryRefreshingThisPage')}</p>
    </div>
}

export const ErrorLoadingMoreNotifications = (props: { translate: any }) => {
    const { translate } = props;
    return <div className='errorLoadingMoreNotificationsStrip' data-testid="Error-Loading-More-Notifications-Strip">
        <Icon icon='warning' color='messageError' size={16}/>
        <span>{translate("ui.notifications.error.loadingMoreNotificationsFailure")}</span>
    </div>
}