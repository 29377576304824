import * as types from './types'
import * as globalActions from '../../global/actions'
import * as ContactApi from '../../../api/contact/requests'
import {formatParams} from '../../utils'
import {getCountryCode} from '../../config/selectors'

export const fetchList = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching())

  const state = getState()
  const country = getCountryCode(state)
  const currentState = state.task.list

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page
  const commentData = {...formatParams(filters), country, type: 'task'}
  const params = {limit, commentData, ...sort[0], page}

  try {
    const res = await ContactApi.getCommentList(params)
    const {count} = res.comment
    const isOutOfRange = count > 0 && (page - 1) * limit >= count
    if (!isOutOfRange) {
      dispatch(tasksLoaded({data: res.comment, limit, sort, page, filters}))
    } else {
      // There is no data at the server for this page.
      // Go back to previous page
      // https://frontiercargroup.atlassian.net/browse/IO-2649
      dispatch(fetchList({page: page - 1}))
    }
  } catch (e) {
    dispatch(globalActions.apiError(e))
  }
}

export const tasksLoaded = (data) => {
  return {
    type: types.dataLoaded,
    payload: data
  }
}

export const setFetching = (fetching = true) => {
  return {
    type: types.fetching,
    payload: fetching
  }
}

export const toggleSort = (sort) => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(fetchList())
}

export const setSort = (sort) => (dispatch) => {
  dispatch({
    type: types.setSort,
    payload: sort
  })
  dispatch(fetchList())
}

export const toggleField = (field) => (dispatch) => {
  dispatch({
    type: types.toggleField,
    payload: field
  })
}

export const updateFilters = (filters) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(fetchList())
}
