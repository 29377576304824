import {getListSelectors} from '../common/listSelectors'
import {createSelector} from 'reselect'

const selectorPath = (state) => state.driver
const selectors = getListSelectors(selectorPath)
const panelCountryOptions = (state) => {
  const {config} = selectorPath(state).pageSetup
  let index = 0

  const driverFormConfig = config.reduce((acc, curr) => {
    index = index + 1

    return [
      ...acc,
      {
        ...curr,
        stepNum: index
      }
    ]
  }, [])

  return driverFormConfig
}

const getFormLength = createSelector([panelCountryOptions], (config) => {
  return config.length
})

const getDriverStatusOptions = (state) =>
  selectorPath(state).driverStatusOptions

const getLeftPanelConfig = createSelector([panelCountryOptions], (config) => {
  if (config) {
    return config.reduce(
      (acc, formConfig) => [
        ...acc,
        {
          stepNum: formConfig.stepNum,
          data: {
            label: formConfig.stepTitle
          }
        }
      ],
      []
    )
  }

  return []
})

const getDriverFormConfig = createSelector(
  [panelCountryOptions, (state, props) => props],
  (config, id) => {
    const formConfig = config.find((current) => current.stepNum === id)

    if (formConfig) {
      return formConfig
    }

    return {}
  }
)

const getVisitedPage = (state) => selectorPath(state).visitedPage

const getDriverId = createSelector(selectorPath, (state) => state.driver.id)

const getDriverImage = (state) =>
  selectorPath(state).driverImage ? selectorPath(state).driverImage : null

export default {
  ...selectors,
  panelCountryOptions,
  getDriverFormConfig,
  getLeftPanelConfig,
  getFormLength,
  getVisitedPage,
  getDriverId,
  getDriverStatusOptions,
  getDriverImage,
  getDriverData: (state) => selectorPath(state).driver,
  getDriverList: (state) => selectorPath(state).driverList,
  getFormData: (state) => selectorPath(state).form,
  getDriverOptions: (state) => selectorPath(state).driverOptions,
  getEditState: (state) => selectorPath(state).editState
}
