import * as types from './types'
import * as tableDecorators from '../../utils/tableDecorators'

const reducersFunc = {
  [types.fetchParticipants]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: true
    }
  }),
  [types.fetchParticipantsSuccess]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      data: action.payload
    }
  }),
  [types.fetchParticipantsError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      error: action.payload
    }
  }),
  [types.addParticipant]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: true
    }
  }),
  [types.addParticipantError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      error: action.payload
    }
  }),
  [types.removeParticipantError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      error: action.payload
    }
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list')
}
