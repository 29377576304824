import {Component} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'

import {Add, Assignment, Cancel, Delete, Edit} from '@material-ui/icons'
import SelfInspectionCreateIcon from '../../../components/RichTable/svg/SelfInspectionCreate'
import SelfInspectionEditIcon from '../../../components/RichTable/svg/SelfInspectionEdit'

import Editor from './Editor'
import AssignmentEditor from './AssignmentEditor'
import AppointmentEditor from '../Appointment/Editor'
import CancelDialog from '../Appointment/Cancel'
import CommentPreview from '../Comment/Preview'
import PageTableBase from '../../../components/PageTableBase'
import {DeleteDialog} from '../common'
import {getAA1LegacyRoute, navigateToAA1} from '../../../utils/navigation'
import {
  fetchList,
  deleteLead,
  toggleSort,
  setSort,
  toggleField,
  updateFilters,
  getCarIdFromBooking,
  setSelectedCarId
} from '../../../store/crm/lead/actions'
import {getLocale} from '../../../store/carOwnership/selectors'
import {deleteAppointment} from '../../../store/crm/appointment/actions'
import {loadOptions} from '../../../store/crm/actions'
import {getLocationOptions} from '../../../store/config/actions'
import {getCountryConfig} from '../../../../config/country'
import * as selectors from '../../../store/crm/lead/selectors'
import {getHiddenFeatures} from '../../../store/common/selectors'
import {getVisibleActions, isDeleteButtonVisible} from '../common/helpers'
import {PermissionWrapper} from '../../../components/Permissions'
import {
  checkUserPermissions,
  checkSelfInspectionPermission
} from '../../../store/auth/selectors'
import AppointmentCount from '../../../components/AppointmentCount'
import {SubTable} from '@fcg/tars'
import omit from 'lodash/omit'
import {
  convertLeadToInspectionQuery,
  isOldEntity,
  orangeColorRow
} from '../helpers'

import CustomSnackbar from '../../../components/Snackbar'

const deleteInitialState = {
  open: false,
  entity: null
}

export class LeadList extends Component {
  state = {
    activeLeadIds: [],
    showAssignEditor: false,
    delete: {...deleteInitialState}
  }

  componentDidMount() {
    this.props.loadOptions()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carId !== this.props.carId && this.props.carId) {
      const id = this.props.carId
      navigateToAA1(
        `/${this.props.locale}/car/edit/${id}`,
        false,
        '_blank',
        null
      )
      this.resetCarId()
    }
  }

  routeBack = () => {
    this.props.history.push(this.props.match.path)
  }

  routeEdit = ({id}) => this.props.history.push(`/crm/leads/edit/${id}`)

  routeAssign = (ids) => {
    this.setState({activeLeadIds: Array.from(ids), showAssignEditor: true})
  }

  routeAssignBack = () => {
    this.setState({activeLeadIds: [], showAssignEditor: false})
  }

  routeAppointmentEdit = ({id}) => {
    this.props.history.push(`${this.props.match.url}/appointment/${id}`)
  }

  routeAppointmentCreate = ({id}) => {
    this.props.history.push(`${this.props.match.url}/${id}/appointment`)
  }

  routeAppointmentCancel = ({id}) => {
    this.props.history.push(`${this.props.match.url}/appointment/${id}/cancel`)
  }

  getCarId = ({latestAppointment}) => {
    this.props.getCarIdFromBooking(latestAppointment?.bookingId || null)
  }

  resetCarId = () => {
    this.props.setSelectedCarId(null)
  }

  renderAppointmentCreator = (routeProps) => (
    <AppointmentEditor
      creation
      isOpen
      leadId={routeProps.match.params.leadId}
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleClose={this.routeBack}
    />
  )

  renderAppointmentEditor = (routeProps) => (
    <AppointmentEditor
      isOpen
      {...routeProps}
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleCancel={this.routeAppointmentCancel}
      handleClose={this.routeBack}
    />
  )

  renderLeadEditor = (routeProps) => (
    <Editor
      isOpen
      {...routeProps}
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleClose={this.routeBack}
    />
  )

  renderAppointmentCancellation = (routeProps) => (
    <CancelDialog
      {...routeProps}
      isOpen
      id={routeProps.match.params.id}
      handleSubmit={() => {
        this.routeBack()
        this.props.fetchData()
      }}
      handleClose={this.routeBack}
    />
  )

  renderAppointmentCommentPreview = ({match}) => {
    const appointment =
      match.params && match.params.commentId
        ? Object.values(this.props.data)
            .reduce((acc, lead) => [...acc, ...lead.appointments], [])
            .find((item) => item.id === match.params.commentId)
        : undefined

    const comment =
      typeof appointment !== 'undefined' ? appointment.comments : ''

    return (
      <CommentPreview isOpen comment={comment} handleClose={this.routeBack} />
    )
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action([id])
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  singleSelectActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['UPDATE']
        }),
      action: {
        event: this.routeEdit,
        icon: <Edit />,
        title: 'button.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['CREATE']
        }),
      action: {
        event: this.routeAppointmentCreate,
        icon: <Add />,
        title: 'entity.appointment',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'lead',
            id: item.id,
            action: this.props.deleteLead
          })
        },
        icon: <Delete />,
        title: 'button.delete',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.leads',
          types: ['UPDATE']
        }),
      action: {
        event: this.getCarId,
        icon: <Edit />,
        title: 'button.carId',
        hide: () => {
          const config = getCountryConfig().hiddenLeadDropdown

          return config.includes('carId')
        }
      }
    },
    {
      test: () => this.props.hasSelfInspectionPermission(),
      action: {
        hide: (item) => !getCountryConfig().hasSelfInspection || !isOldEntity(item),
        event: (lead) => {
          window.open(
            getAA1LegacyRoute(
              `/${this.props.locale}/inspection/create/?group=CONSUMER&leadId=` +
                lead.id +
                '&' +
                convertLeadToInspectionQuery(lead)
            ),
            '_blank'
          )
        },
        icon: <SelfInspectionCreateIcon />,
        title: 'global.action.createSelfInspection'
      }
    },
    {
      test: () => this.props.hasSelfInspectionPermission(),
      action: {
        hide: (lead) =>
          !getCountryConfig().hasSelfInspection ||
          typeof this.props.inspections[lead.id] == 'undefined' ||
          !isOldEntity(lead),
        event: (lead) => {
          this.props.inspections[lead.id].length > 1
            ? window.open(
                getAA1LegacyRoute(
                  `/${this.props.locale}/self-inspection?leadId=` + lead.id
                ),
                '_blank'
              )
            : window.open(
                getAA1LegacyRoute(
                  `/${this.props.locale}/inspection/show/` +
                    this.props.inspections[lead.id][0].id
                ),
                '_blank'
              )
        },
        icon: <SelfInspectionEditIcon />,
        title: 'global.action.editSelfInspection'
      }
    }
  ]

  subTableActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['UPDATE']
        }),
      action: {
        event: this.routeAppointmentEdit,
        icon: <Edit />,
        title: 'button.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.cancelAppointment',
          types: ['READ']
        }),
      action: {
        event: this.routeAppointmentCancel,
        icon: <Cancel />,
        title: 'global.action.cancelAppointment',
        hide: (item) => !isOldEntity(item) || item.cancelledAt !== null
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'appointment',
            id: item.id,
            action: this.props.deleteAppointment
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  subTableRowActions = () => getVisibleActions(this.subTableActions, this.props)

  multiSelectActions = isOldEntity({createdAt: new Date()})
    ? [
        {
          test: () =>
            this.props.hasPermissions({
              entity: 'web.admin.ui.crm.tasks',
              types: ['READ']
            }),
          action: {
            event: this.routeAssign,
            icon: <Assignment />,
            title: 'button.assign'
          }
        }
      ]
    : []

  getTableData = () => {
    const {data, inspections, fields} = this.props

    const inspectionCityField = fields.find(
      (field) => field.key === 'inspectionCity'
    )

    if (inspectionCityField && Object.keys(inspections || {}).length > 0) {
      return data.map((lead) => {
        const inspection =
          inspections[lead.id] && inspections[lead.id][0]
            ? inspections[lead.id][0]
            : null
        const inspectionCity =
          inspection && inspection.report && inspection.report.itemLocationName
            ? inspection.report.itemLocationName
            : '-'

        return {
          ...lead,
          inspectionCity
        }
      })
    }

    return data
  }

  multiSelectTableActions = () =>
    getVisibleActions(this.multiSelectActions, this.props)

  render() {
    const tableProps = omit(this.props, ['match', 'history', 'className'])
    const {className} = this.props
    const countryConfig = getCountryConfig().crmDetails

    return (
      <PermissionWrapper entity='web.admin.ui.crm.leads' withDescription>
        <div className={className}>
          <PageTableBase
            {...tableProps}
            data={this.getTableData()}
            config={{countryConfig}}
            page={this.props.currentPage}
            title='entity.lead'
            filterable
            selectable
            multiSelectActions={this.multiSelectTableActions()}
            LeftActions={AppointmentCount}
            singleSelectActions={this.singleSelectTableActions()}
            subTableRowActions={this.subTableRowActions()}
            ExpandableComponent={SubTable}
            rowStyle={orangeColorRow}
          />
          <AssignmentEditor
            isOpen={this.state.showAssignEditor}
            handleSubmit={() => {
              this.routeAssignBack()
              this.props.fetchData()
            }}
            leadIds={this.state.activeLeadIds}
            handleClose={this.routeAssignBack}
          />
          <PermissionWrapper entity='web.admin.ui.crm.leads' types={['UPDATE']}>
            <Route
              exact
              path={`${this.props.match.url}/edit/:leadId`}
              render={this.renderLeadEditor}
            />
          </PermissionWrapper>
          <PermissionWrapper
            entity='web.admin.ui.crm.appointments2'
            types={['UPDATE']}
          >
            <Route
              exact
              path={`${this.props.match.url}/appointment/:id`}
              render={this.renderAppointmentEditor}
            />
          </PermissionWrapper>
          <PermissionWrapper
            entity='web.admin.ui.crm.appointments2'
            types={['CREATE']}
          >
            <Route
              exact
              path={`${this.props.match.url}/:leadId/appointment`}
              render={this.renderAppointmentCreator}
            />
          </PermissionWrapper>
          <PermissionWrapper
            entity='web.admin.ui.crm.cancelAppointment'
            types={['READ']}
          >
            <Route
              exact
              path={`${this.props.match.path}/appointment/:id/cancel`}
              render={this.renderAppointmentCancellation}
            />
          </PermissionWrapper>
          <Route
            path={`${this.props.match.path}/appointment/comment/:commentId`}
            render={this.renderAppointmentCommentPreview}
          />
          <DeleteDialog {...this.state.delete} />
        </div>
        <CustomSnackbar />
      </PermissionWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  const {page: currentPage, ...leadList} = state.lead.list

  const fields = selectors.getLeadFields(state)

  return {
    ...leadList,
    data: Object.values(leadList.data),
    carId: selectors.getCarId(state),
    fields: fields.map((item) =>
      item.key === 'appointments'
        ? {
            ...item,
            fields: omit(item.fields, 'role')
          }
        : item
    ),
    filterFields: selectors.getLeadFilters(state),
    inspections: selectors.getListOfInspections(state),
    currentPage,
    error: false,
    pageConfig: {
      hidden: {
        ...getHiddenFeatures(state)
      }
    },
    locale: getLocale(state),
    hasPermissions: (permissions) => checkUserPermissions(state, permissions),
    hasSelfInspectionPermission: (permissions) =>
      checkSelfInspectionPermission(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => {
  const fetchData = (args = {}) => dispatch(fetchList(args, 'leadListPage'))

  return {
    fetchData,
    loadOptions: () => {
      dispatch(getLocationOptions())
      dispatch(loadOptions())
    },
    deleteLead: (ids) => dispatch(deleteLead({ids}, fetchData)),
    deleteAppointment: (ids) => dispatch(deleteAppointment({ids}, fetchData)),
    toggleSort: (sort) => dispatch(toggleSort(sort, 'leadListPage')),
    updateSort: (sort) => dispatch(setSort(sort, 'leadListPage')),
    onFilterChange: (filter) => dispatch(updateFilters(filter, 'leadListPage')),
    toggleField: ({key}) => dispatch(toggleField(key)),
    getCarIdFromBooking: (bookingId) =>
      dispatch(getCarIdFromBooking(bookingId)),
    setSelectedCarId: (payload) => dispatch(setSelectedCarId(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadList)
