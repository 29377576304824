import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import {disabledCallback} from './utils'

export default {
  stepNum: 7,
  stepTitle: 'dealer.bankAndPayment',
  description: 'dealer.panel.description.bankAndPayment',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            multiline: true,
            id: 'paymentTerms',
            label: 'dealer.page.field.paymentTerms',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'NumberInput',
            id: 'deposit',
            label: 'dealer.page.field.deposit',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'guarantees',
            multiline: true,
            label: 'dealer.page.field.guarantees',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'invoicingType',
            label: 'dealer.page.field.invoicingType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'virtualAccountOrWalletId',
            label: 'dealer.page.field.virtualAccountOrWalletId',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            multiline: true,
            id: 'bankDetails',
            label: 'dealer.page.field.bankDetails',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            id: 'bankAccountNumber',
            label: 'dealer.page.field.bankAccountNumber',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            id: 'bankAccountOwner',
            label: 'dealer.page.field.bankAccountOwner',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'NumberInput',
            id: 'depositBalance',
            fullWidth: true,
            label: 'dealer.page.field.depositBalance',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            isNumber: true,
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'NumberInput',
            id: 'numberPurchasableCar',
            fullWidth: true,
            label: 'dealer.page.field.numberPurchasableCar',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            isNumber: true,
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
