import Editor from '../../../pages/BulkUpload/Editor'
import bulkUploadFormConfigV2 from './bulkUploadFormConfigV2'

export const bulkUploadConfigV2 = {
  path: 'bulkUpload',
  label: 'menu.bulkUploadV2',
  key: 'bulkUploadV2',
  sort: [],
  limit: 25,
  filters: [],
  presets: [],
  page: 1,
  form: bulkUploadFormConfigV2,
  hiddenFields: [],
  component: Editor,
  permissions: {
    entity: 'web.admin.ui.car.bulkUpload',
    types: ['READ']
  }
}
