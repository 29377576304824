import {FormControlLabel, Switch} from '@material-ui/core'
import {translate} from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CheckedInWrapper = styled.div`
  display: flex;
  color: ${(props) => props.color}
  font-size: 14px;
  align-items: center;
`

const CheckedInText = styled.p`
  padding-right: 18px;
`

const CheckedInSubHeader = styled.span`
  font-size: 12px;
  opacity: 0.6;
`

const CheckInToggle = ({value, handleChange, color, isCheckedIn, t}) => {
  return (
    <CheckedInWrapper color={color}>
      <CheckedInText>
        {isCheckedIn ? t('header.checkIn') : t('header.checkOut')}
        <br />
        {isCheckedIn && (
          <CheckedInSubHeader>{t('header.checkIn.subText')}</CheckedInSubHeader>
        )}
      </CheckedInText>
      <FormControlLabel
        control={
          <Switch checked={value} onChange={handleChange} name='isCheckedIn' />
        }
        label=''
      />
    </CheckedInWrapper>
  )
}

CheckInToggle.propTypes = {
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  isCheckedIn: PropTypes.bool.isRequired
}

export default translate()(CheckInToggle)
