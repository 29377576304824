import {Component} from 'react'
import styled from 'styled-components'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import remove from 'lodash/remove'
import omit from 'lodash/omit'
import values from 'lodash/values'

import CheckCircle from '@material-ui/icons/CheckCircle'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import Dialog from '@material-ui/core/Dialog'
import {withStyles} from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import styles from '../CRM/common/dialogStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import DriverProfile from './DriverProfile'
import TransitInfo from './TransitInfo'
import EmptyState from '@components/EmptyState'
import CustomSnackbar from '../../components/Snackbar'
import {
  fetchTransitJob,
  finishTransitJob,
  startTransitJob
} from '../../store/transitJob/actions'
import selectors from '../../store/transitJob/selectors'
import LoadingState from '../../components/LoadingState'
import {getCountryCode} from '../../store/config/selectors'
import {JOB_TYPE} from '../TransitJobs/constants'

const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding: 32px 24px;
  margin: 0 auto;
  font-size: 13px;

  @media (max-width: 768px) {
    padding: 24px 12px;
  }
`

const ContentContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`

const Footer = styled.div`
  position: fixed;
  display: flex;
  max-width: 976px;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  bottom: 0;
  padding: 24px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 24px 12px;
    width: calc(100% - 24px);
  }
`

const StyledButton = styled(Button)`
  max-width: 368px;
  width: 100%;
  height: 40px;
`

const DriverGrid = styled(Grid)`
  margin-bottom: 92px;
`

const CheckCircleIcon = styled(CheckCircle)`
  font-size: 104px !important;
  color: #43a047;
  margin-bottom: 8px;
`

const Hint = styled.span`
  font-size: 12px;
  color: #616161;
  margin-bottom: 4px;
`
const DirectionsRunIcon = styled(DirectionsRun)`
  font-size: 104px !important;
  color: #43a047;
  margin-bottom: 8px;
`

const TextSpan = styled.span`
  font-size: 16px;
  line-height: 24px;
`

const LoadingScreen = () => (
  <Dialog fullScreen={true} maxWidth='md' open>
    <ContentContainer>
      <LoadingState />
    </ContentContainer>
  </Dialog>
)

const FinishScreen = (props) => (
  <Dialog fullScreen={true} maxWidth='md' open>
    <ContentContainer>
      <CheckCircleIcon />
      <TextSpan data-test='transit-finish-message'>
        {props.t('transitReview.transitFinishMessage')}
      </TextSpan>
    </ContentContainer>
  </Dialog>
)

const RunningScreen = (props) => (
  <Dialog fullScreen={true} maxWidth='md' open>
    <ContentContainer>
      <DirectionsRunIcon />
      <TextSpan data-test='transit-running-message'>
        {props.t('transitReview.transitRunningMessage')}
      </TextSpan>
    </ContentContainer>
  </Dialog>
)

class TransitReview extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      transits: [],
      hasLoaded: false,
      hasSubmit: true,
      type: null,
      transitData: {}
    }
  }

  componentDidMount() {
    this._isMounted = true

    if (
      this._isMounted &&
      this.props.match.params &&
      this.props.match.params.transitJobId
    ) {
      this.props.fetchTransitJob({
        id: this.props.match.params.transitJobId
      })
      const query = new URLSearchParams(this.props.location.search)

      this.setState({
        type: query.get('type')
      })
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {data} = nextProps

    if (data?.transits?.length && !nextProps.hasLoaded) {
      return {
        transits: data.transits.map((transit) => transit.id),
        hasLoaded: true
      }
    }

    return null
  }

  handleSelect = (id, value) => {
    if (value === true) {
      this.setState({
        transits: [...this.state.transits, id]
      })
    } else {
      const arr = this.state.transits
      let data = this.state.transitData

      if (data[id]) {
        data = omit(data, id)
      }

      remove(arr, (transitId) => {
        if (id === transitId) {
          return true
        }
      })

      this.setState({transits: arr, transitData: data})
    }
  }

  updateMileage = (id, data) => {
    const {transitData} = this.state

    this.setState({
      transitData: {
        ...transitData,
        [id]: data
      }
    })
  }

  handleSubmit = () => {
    if (this.state.type === JOB_TYPE.PICKUP) {
      this.props.startTransitJob({
        id: this.props.data.id,
        startTransits: this.state.transits
      })
    }

    if (this.state.type === JOB_TYPE.DROPOFF) {
      this.props.finishTransitJob({
        id: this.props.data.id,
        finishedTransits: this.state.transits,
        transits: values(this.state.transitData)
      })
    }

    this.setState({
      hasSubmit: true
    })
  }

  ReviewScreen = () => {
    let typeString
    let hintStr

    if (this.state.type === JOB_TYPE.PICKUP) {
      typeString = 'transitReview.confirmPickup'
      hintStr = 'transitReview.pickupHint'
    } else if (this.state.type === JOB_TYPE.DROPOFF) {
      typeString = 'transitReview.confirmDropoff'
      hintStr = 'transitReview.dropoffHint'
    }

    return (
      <Dialog fullScreen={true} maxWidth='md' open>
        <Container>
          <DriverGrid>
            <DriverProfile
              driverId={this.props.data.driverId}
              driver={this.props.data.driver}
              t={this.props.t}
            />
            <TransitInfo
              transits={this.props.data.transits}
              t={this.props.t}
              handleSelect={this.handleSelect}
              updateMileage={this.updateMileage}
              countryCode={this.props.countryCode}
              type={this.state.type}
              transitType={this.props.data.transitType}
            />
          </DriverGrid>
          {typeString ? (
            <Footer>
              {hintStr ? <Hint>{this.props.t(hintStr)}</Hint> : null}
              <StyledButton
                variant='contained'
                color='primary'
                disabled={!this.state.transits.length}
                onClick={this.handleSubmit}
                data-test='confirm-transit-job'
              >
                {this.props.t(typeString)}
              </StyledButton>
            </Footer>
          ) : null}
        </Container>
        <CustomSnackbar />
      </Dialog>
    )
  }

  render() {
    if (this.props.fetching || !this.props.data) {
      return <LoadingScreen />
    }

    if (!this.props.data.transits) {
      return (
        <Dialog fullScreen={true} maxWidth='md' open>
          <Container>
            <EmptyState />
          </Container>
        </Dialog>
      )
    }

    if (this.props.data.status === 'finished') {
      return <FinishScreen t={this.props.t} />
    }

    if (this.props.data.status === 'running' && this.state.type === 'pickup') {
      return <RunningScreen t={this.props.t} />
    }

    return <this.ReviewScreen />
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getTransitJob(state),
  fetching: selectors.isFetchingTransitJob(state),
  submiting: selectors.isSubmittingTransitJob(state),
  countryCode: getCountryCode(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchTransitJob: (options) => dispatch(fetchTransitJob(options)),
  finishTransitJob: (payload) => dispatch(finishTransitJob(payload)),
  startTransitJob: (payload) => dispatch(startTransitJob(payload))
})

export default withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate()(withStyles(styles)(withRouter(TransitReview))))
)
