import 'date-fns'
import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import DialogTemplate from '../../../../components/Dialog/Dialog'
import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import FormRow from '../../../../components/Form/FormRow'
import RowDescription from '../../../../components/Form/RowDescription'
import AreaObject from '../../../../types/AreaObject'
import LotObject from '../../../../types/LotObject'
import {getLotsList} from '../../../../api/car/requests'

interface AreaPickerProps {
  open: boolean
  isEditingAreaDetails: boolean
  selectedArea?: AreaObject
  onCancel: () => void
  onConfirm: (area: AreaObject) => void
  setLotsForSelectedArea: any
}

const AreaPicker = ({
  open,
  isEditingAreaDetails,
  selectedArea,
  onCancel,
  onConfirm,
  setLotsForSelectedArea
}: AreaPickerProps) => {
  const classes = useCommonStyles()
  const defaultArea: AreaObject = isEditingAreaDetails
    ? {
        name: '',
        type: '',
        floor: 0,
        availableLots: 0
      }
    : {
        name: '',
        type: ''
      }

  const [area, setArea] = useState<AreaObject>(defaultArea)

  const handleChange = (field: string, value: number | string) => {
    setArea((prevArea) => {
      return {
        ...prevArea,
        [field]: value
      }
    })
  }

  const getlotCount = (areaId: string) => {
    const req = {areaId}
    return getLotsList(req).then((lots: LotObject[]) => {
      return lots
    })
  }

  const setAreaDetails = async (selectedArea: AreaObject) => {
    const {id} = selectedArea
    if (!id) {
      throw new Error('id undefined')
    }
    const availableLots = await getlotCount(id)
    setLotsForSelectedArea(availableLots)
    setArea({
      ...selectedArea,
      availableLots: availableLots.length,
      prevLotCount: availableLots.length
    })
  }

  useEffect(() => {
    if (selectedArea) {
      setAreaDetails(selectedArea)
    } else setArea(defaultArea)
  }, [selectedArea])

  const dialogTitle = isEditingAreaDetails ? 'Edit Lot Area' : 'Add Lot Area'

  return (
    <DialogTemplate
      open={open}
      title={dialogTitle}
      onCancel={onCancel}
      onConfirm={() => onConfirm(area)}
      confirmLabel='SAVE'
      dataTest='save-area-btn'
      maxWidth='md'
    >
      <RowDescription title='General settings' />
      <form className={classes.pickerFormWidth}>
        <FormRow>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Name'
                required
                onChange={(e: any) => {
                  handleChange('name', e.target.value)
                }}
                value={area?.name || ''}
                data-test='area-name'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Type'
                onChange={(e: any) => {
                  handleChange('type', e.target.value)
                }}
                value={area?.type || ''}
                data-test='area-type'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Floor'
                onChange={(e: any) => {
                  handleChange('floor', parseInt(e.target.value))
                }}
                value={area?.floor}
                type='number'
                data-test='area-floor'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Available lots'
                onChange={(e: any) => {
                  handleChange('availableLots', parseInt(e.target.value))
                }}
                value={area?.availableLots}
                type='number'
                data-test='area-available-lots'
              />
            </Grid>
          </Grid>
        </FormRow>
      </form>
    </DialogTemplate>
  )
}

export default AreaPicker
