import {Component} from 'react'
import PropTypes from 'prop-types'
import UserPicker from './UserPicker'

const emptyOption = {value: null, label: 'form.select.none'}

class ResponsibleBasePicker extends Component {
  state = {users: {options: [], count: 0}, page: 1, all: {}}

  setUserOptions = ({options, count, page, all}) => {
    if (page === 1) {
      options = [emptyOption, ...options]
    }

    this.setState({users: {options, count}, page, all})
  }

  getOptions = () => {
    if (!this.state.all) {
      return this.state.users.options
    }

    return Object.keys(this.state.all).reduce((acc, searchTerm) => {
      const o = Object.keys(this.state.all[searchTerm]).reduce((acc, page) => {
        if (page === '1') {
          return [
            ...acc,
            emptyOption,
            ...this.state.all[searchTerm][page].options
          ]
        }

        return [...acc, ...this.state.all[searchTerm][page].options]
      }, [])

      return [...acc, ...o]
    }, [])
  }

  handleChange = (val) => {
    const labels = val.reduce((acc, id) => {
      const foundOption = this.getOptions().find(({value}) => value === id)

      if (foundOption && foundOption.label) {
        acc.push(foundOption.label)
      }

      return acc
    }, [])

    this.props.handleChange(this.props.fieldName)(val, labels)
  }

  render() {
    const options = this.getOptions()

    return (
      <UserPicker
        {...this.props}
        {...this.state.users}
        allOptions={options}
        allOptionsCount={options.length}
        visibleOptions={this.state.users.options}
        filterable
        withNull
        debounce={true}
        label={this.props.label || this.props.fieldName}
        id={this.props.fieldName}
        selected={this.props.value}
        userClass='INTERNAL'
        queryParams={{limit: 100}}
        onLoad={this.setUserOptions}
        onChange={this.handleChange}
        multiple
        debounceRequest
      />
    )
  }
}

ResponsibleBasePicker.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
  useCaching: PropTypes.bool
}

export default ResponsibleBasePicker
