const dealerDetails = require('./dealerManagement/tr')
const crmDetails = require('./CRM/tr')
const bulkuploadDetails = require('./bulkUpload/tr')
const carDataDetails = require('./carData/tr')
const locationDetails = require('./locationManagement/tr')
const carOwnership = require('./carOwnership/tr')
const businessPartnersDetails = require('./businessPartnersDetails/tr')
const payslipDetails = require('./payslips/tr')

module.exports = {
  url: 'otoplus.com',
  isMTCMSEnabled: true,
  siteCode: 'olxtr',
  apiDomain: 'api.olx.com.tr',
  name: 'Turkey',
  countryCode: 'TR',
  brand: 'otoplus',
  theme: 'turkey',
  language: {
    selected: 'tr-TR',
    options: [
      {value: 'tr-TR', label: 'TR'},
      {value: 'en-tr', label: 'EN'}
    ]
  },
  locales: ['tr-TR', 'en-tr'],
  currency: {code: 'TL', symbol: '₺'},
  number: {decimalSep: ',', unitSep: '.', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 90},
  useExactPhoneNumberSearch: true,
  referralCodeType: 'input',
  googleKey:
    '1093620694543-uanf32lcueopf2c066hcivogr32bhiav.apps.googleusercontent.com',
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'licensePlate',
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      licensePlate: {
        regex: '^[A-Z]{2}[0-9]{4}|[A-Z]{4}[0-9]{2}$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'bulkUpload',
    'resourceManager',
    'wms',
    'attendanceManager',
    'auctionsManagement',
    'payslips',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  carOwnership,
  payslipDetails,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: false
  },
  callingCode: '+90',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  categoryId: 84,
  useGrootApi: ['make', 'model', 'year', 'variant', 'bodyType', 'transmission'],
  leadStatusDiscardedReasonMapping: true,
  appointmentStatusRejectionReasonMapping: true,
  isPlantAndStorageIdRequired: true,
  plantAndStorageIdNoRequiredForLocationTypes: [
    'REFURBISHMENT',
    'CUSTOMER',
    'DEALER'
  ],
  isNotificationsEnabled: false
}
