import {PureComponent} from 'react'
import {connect} from 'react-redux'
import {loadOptions} from '../../store/crm/actions'

export class Container extends PureComponent {
  componentDidMount() {
    this.props.loadOptions()
  }
  render() {
    return this.props.children
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadOptions: () => dispatch(loadOptions())
})

export default connect(null, mapDispatchToProps)(Container)
