import {SimpleApiClient} from '@fcg/admin-api-client'
import getQueryString from '../helpers/queryParamUtil'
import {getAdditionalHeaders} from './utils'
import Country from '../types/Country'

export const getUploadHistory = (countryCode: Country, pageSize: Number, page: Number, orderBy: {},filterBy:{}, callBack: (data: Object)=>void) => {
  const params = {
    deletedUser: false,
    userClass: 'INTERNAL',
    country: countryCode,
    pageSize,
    page,
    orderBy,
    filterBy,
    date: Date.now()
  }
const uploadHistroyClient = new SimpleApiClient('logistics', getAdditionalHeaders(countryCode))
  return uploadHistroyClient
    .query(`rcm/upload-history?${getQueryString(params)}`,'', params, 'GET')
    .then((response: Response) =>{
      callBack(response);
    }
    )
}
