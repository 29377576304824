import * as types from './types'
import {mergeResources, removeResource, removeDataFromResource} from './utils'
import toPairs from 'lodash/toPairs'

const reducers = {
  [types.mergeResources]: (state, action) => {
    let {entities} = action.payload.data
    const {options} = action.payload

    if (!entities && options) {
      const data = action.payload.data[options.type]

      entities = {
        [options.type]: data.list.reduce(
          (acc, val) => ({
            ...acc,
            [val.id]: val
          }),
          {}
        )
      }
    }

    let resources = state.resources

    const entityPairs = toPairs(entities)

    if (entities && entityPairs.length > 1) {
      entityPairs.forEach((entity) => {
        if (entity[0] === action.payload.options.type) {
          resources = {
            ...resources,
            ...mergeResources(
              resources,
              {[options.type]: entity[1]},
              action.payload.options
            )
          }
        } else {
          resources = {
            ...resources,
            ...mergeResources(
              resources,
              {[entity[0]]: entity[1]},
              {
                type: entity[0]
              }
            )
          }
        }
      })
    } else {
      resources = mergeResources(resources, entities, {
        type: options.type,
        name: options.name
      })
    }

    return {
      ...state,
      resources
    }
  },
  [types.removeResources]: (state, action) => ({
    resources: removeResource(state.resources, action.payload.resource)
  }),
  [types.removeAllDataFromResource]: (state, action) => {
    delete state.resources[action.payload]

    return state
  },
  [types.deleteDataFromCollection]: (state, action) => ({
    resources: removeDataFromResource(
      state.resources,
      action.payload.resourceType,
      action.payload.ids
    )
  })
}

export default reducers
