import {connect} from 'react-redux'
import {translate} from 'react-i18next'

import {checkUserPermissions} from '../../store/auth/selectors'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NoPermission = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PermissionWrapper = ({
  hasPermissions,
  entity,
  children,
  types,
  t,
  withDescription
}) => {
  if (!hasPermissions({entity, types: types || ['READ']})) {
    if (withDescription) {
      return <NoPermission>{t('general.noPermissionError')}</NoPermission>
    }

    return null
  }

  return children
}

const mapStateToProps = (state) => ({
  hasPermissions: (permissions) => checkUserPermissions(state, permissions)
})

PermissionWrapper.propTypes = {
  entity: PropTypes.string.isRequired
}

export default translate()(connect(mapStateToProps)(PermissionWrapper))
