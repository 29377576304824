import { DateGroupsObject } from "../components/Notifications/Notifications.types";

const monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

//Returns date in DD/MM/YYYY format
export const getLocalDateString = (timestamp: number) => {
    return new Date(timestamp).toLocaleString().split(',')[0]
}

//Returns object to with last 30 dates as keys and empty array as values to hold notifications for each date
export const last30DaysTemplateObject = (timestamp: number) => {
    const dateObj = new Date(timestamp);
    const newObj: DateGroupsObject = {}
    for (let i = 0; i < 30; i++) {
        const localDateString = getLocalDateString(
            dateObj.setDate(dateObj.getDate() - (i === 0 ? 0 : 1))
        )
        const formattedDateMMDDYYYY = localDateString.split("/");
        let relativeDate = monthsArray[Number(formattedDateMMDDYYYY[1]) - 1] + " " + formattedDateMMDDYYYY[0] + ", " + formattedDateMMDDYYYY[2]
        if (i === 0) relativeDate = 'ui.notifications.timeGrouping.today'
        else if (i === 1)
            relativeDate = 'ui.notifications.timeGrouping.yesterday'

        newObj[localDateString] = {
            relativeDate: relativeDate,
            notificationsList: []
        }
    }
    return newObj
}
