import Button from '@material-ui/core/Button'
import {withStyles} from '@material-ui/core/styles'

function OutlinedButton({classes, children, ...props}) {
  return (
    <Button variant='outlined' classes={classes} {...props}>
      {children}
    </Button>
  )
}

export default withStyles({
  root: {
    color: '#00BCD4',
    textTransform: 'uppercase',
    marginTop: '10px'
  }
})(OutlinedButton)
