import {ValidatedSelectField} from '../../../components/FormValidation'

export const transitJobFormConfig = {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 4,
            children: [
              {
                component: 'DateInput',
                fullWidth: true,
                id: 'startTime',
                label: 'transitList.placeholder.startTime',
                margin: 'normal',
                hasTime: true,
                placeholder: 'DD/MM/YYYY HH:mm',
                required: true,
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'startPlace',
            label: 'transitList.placeholder.startPlace',
            margin: 'normal',
            required: true,
            options: 'locationOptions',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'endPlace',
            options: 'locationOptions',
            label: 'transitList.placeholder.endPlace',
            margin: 'normal',
            required: true,
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 6,
            sm: 4,
            children: [
              {
                component: ValidatedSelectField,
                fullWidth: true,
                id: 'driverId',
                options: 'driverOptions',
                label: 'transitList.placeholder.driver',
                margin: 'normal',
                required: true,
                onChangeHandler: 'updateDriver'
              }
            ]
          }
        ]
      }
    ]
  }
}
