module.exports = {
  carDataPicker: {
    dependencies: {
      make: [],
      model: ['make'],
      year: ['make', 'model'],
      trim: ['make', 'model', 'year'],
      transmission: ['make', 'model', 'year', 'trim'],
      location: ['make', 'model', 'year', 'trim', 'transmission'],
      mileage: ['make', 'model', 'year', 'trim', 'transmission', 'location']
    }
  }
}
