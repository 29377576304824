import {Component} from 'react'
import {connect} from 'react-redux'
import translate from 'react-i18next/dist/commonjs/translate'
import PageTableBase from '../../components/PageTableBase'
import * as selectors from '../../store/carOwnership/selectors'
import {
  toggleField,
  toggleSort,
  updateTicketEntity,
  toggleRow,
  toggleRowSelection,
  fetchRowData,
  fetchData,
  approveTicket,
  attachDocumentsToTicket,
  cleanUpDocuments,
  clearDocumentDialog,
  updateFilters,
  fetchCountryOptions,
  updateDocumentCategory
} from '../../store/carOwnership/actions'
import {hasUserPermissions} from '../../store/auth/selectors'
import {
  fetchDealerFileTypes,
  uploadPayslipDocument
} from '../../store/documentConfig/actions'
import {getAA1LegacyRoute} from '../../utils/navigation'
import {ExpandableComponent} from './ExpandableComponent'
import {pick} from 'ramda'
import {RenderIf} from '../../components/RenderIf'
import {ApproveTicketDialog} from './ApproveTicket'
import {TICKET_STATUS} from '../../config/entities/ticket'
import CustomSnackbar from '../../components/Snackbar'
import {showErrorMessage} from '../../store/signals/actions'
import UploadDialog from './components/UploadDialog'

const tablePropFields = [
  'count',
  'page',
  'fetching',
  'sort',
  'error',
  'filters',
  'limit',
  'data',
  'fetchData',
  'toggleSort',
  'toggleField',
  'pageConfig',
  'fields',
  'subfields',
  'expandedRows',
  'collapseAllRows',
  'onRowToggle',
  'fetchRowData',
  'rowFetching',
  'rowFetchingErrors',
  'onFilterChange',
  'filterFields'
]
const isDialogLoading = ({rowsLoading = []}, {ticketId = ''}) =>
  rowsLoading.includes(ticketId)
const approvableTicketStatuses = [
  TICKET_STATUS.APPROVED,
  TICKET_STATUS.REJECTED
]
const cannotApprove = (data) =>
  approvableTicketStatuses.includes(data.status) || !data?.allDocumentsReviewed
const cannotAddDocuments = (data) =>
  approvableTicketStatuses.includes(data.status)
const canViewUploadDialog = ({props, state}) =>
  props.hasCreateAccess && state.uploadDocumentDialogOpen

export class CarOwnership extends Component {
  state = {
    approveDialogOpen: false,
    uploadDocumentDialogOpen: false,
    ticketId: null
  }

  showApproveDialog = (data) => {
    if (!this.props.hasFullAccess) {
      return
    }

    if (cannotApprove(data)) {
      return this.props.cannotApproveTicketError(
        'carOwnershipTransfer.error.approveDenied'
      )
    }

    this.setState({
      approveDialogOpen: true,
      uploadDocumentDialogOpen: false,
      ticketId: data.id
    })
  }

  showDocumentUpload = (data) => {
    if (!this.props.hasCreateAccess) {
      return
    }

    if (cannotAddDocuments(data)) {
      return this.props.cannotApproveTicketError(
        'carOwnershipTransfer.error.uploadDocumentDenied'
      )
    }

    this.setState({
      approveDialogOpen: false,
      uploadDocumentDialogOpen: true,
      ticketId: data.id
    })
  }

  onAttachDocuments = (documentComments) =>
    this.props.attachDocumentsToTicket({
      ticketId: this.state.ticketId,
      documentComments
    })

  onCloseDocumentDialog = () => {
    this.props.cleanupDocumentState()
    this.closeDialogs()
  }

  closeDialogs = () => {
    this.setState({
      approveDialogOpen: false,
      uploadDocumentDialogOpen: false,
      ticketId: null
    })
  }

  componentDidMount = () => {
    this.props.fetchFileTypes()
    this.props.fetchCountryOptions()
  }

  componentDidUpdate = (prevProps) => {
    if (!this.props.rowsLoading.length && prevProps.rowsLoading.length) {
      return this.closeDialogs()
    }

    if (!this.props.attachingDocuments && this.props.documentsAttached) {
      this.props.clearDocumentDialog()

      return this.closeDialogs()
    }
  }

  render() {
    const {className} = this.props

    if (this.props.isPageHidden) {
      this.props.history.push('/')

      return null
    }

    const expandableComponentProps = {
      // fn
      onDocumentStatusChange: this.props.updateTicketEntity,
      onDocumentCategoryChange: this.props.updateDocumentCategory,
      fetchRowData: this.props.fetchRowData,
      onDocumentApprove: this.showApproveDialog,
      showDocumentUpload: this.showDocumentUpload,

      // vars
      canAddDocuments: this.props.hasCreateAccess,
      allowedToChangeDocumentState: this.props.hasFullAccess,
      canApproveTicket: this.props.hasFullAccess,
      categoryOptions: this.props.options
    }
    const linkBase = {
      carId: getAA1LegacyRoute(`/${this.props.locale}/car/edit`)
    }
    const tableProps = pick(tablePropFields, this.props)

    return (
      <div className={className}>
        <PageTableBase
          {...tableProps}
          title='carOwnershipTransfer.sectionHeadline'
          multisort={false}
          filterable
          withVisibility={false}
          extendExpand={2}
          expandableComponentProps={expandableComponentProps}
          ExpandableComponent={ExpandableComponent}
          expandableTable
          scrollOnOpen
          showFilters
          noHeaderLeftElement
          urlParametersEnabled
          linkBase={linkBase}
        />
        <RenderIf if={this.props.hasFullAccess && this.state.approveDialogOpen}>
          <ApproveTicketDialog
            t={this.props.t}
            isOpen={this.state.approveDialogOpen}
            onDialogClose={this.closeDialogs}
            onApproveTicket={this.props.onApproveTicket}
            isLoading={isDialogLoading(this.props, this.state)}
          />
        </RenderIf>
        <RenderIf if={canViewUploadDialog(this)}>
          <UploadDialog
            t={this.props.t}
            title='carOwnershipTransfer.uploadDocument.dialogTitle'
            isOpen={this.state.uploadDocumentDialogOpen}
            onDialogClose={this.onCloseDocumentDialog}
            onUploadDocument={this.props.uploadDocument}
            onDialogConfirm={this.onAttachDocuments}
            isLoading={this.props.attachingDocuments}
          />
        </RenderIf>
        <CustomSnackbar />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // Table data
  data: selectors.getListData(state),
  fetching: selectors.isFetching(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  filterFields: selectors.filterFields(state),
  subfields: selectors.getSubfields(state),
  locale: selectors.getLocale(state),
  hasUserInfo: hasUserPermissions(state),
  options: selectors.getOptions(state),
  // State transition
  rowFetching: selectors.getRowFetchingStatus(state),
  rowFetchingErrors: selectors.getRowErrorStatus(state),
  expandedRows: selectors.getExpandedRows(state),
  rowsLoading: selectors.getUpdatingStatus(state),
  // Permissions
  canAccessPage: selectors.canAccessPage(state),
  hasCreateAccess: selectors.hasCreateAccess(state),
  hasFullAccess: selectors.hasFullAccess(state),
  isPageHidden: selectors.isOwnershipListHidden(state),
  // Status of attaching documents to a ticket
  attachingDocuments: selectors.attachingDocuments(state),
  documentsAttached: selectors.documentsAttached(state),
  documentsAttachError: selectors.documentsAttachError(state)
})
const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(fetchData(args)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  updateTicketEntity: (data) => dispatch(updateTicketEntity(data)),
  collapseAllRows: () => dispatch(toggleRowSelection()),
  onRowToggle: (rowData) => dispatch(toggleRow(rowData)),
  fetchRowData: (rowData) => dispatch(fetchRowData(rowData)),
  cleanupDocumentState: () => dispatch(cleanUpDocuments()),
  onApproveTicket: () => dispatch(approveTicket()),
  cannotApproveTicketError: (message) => dispatch(showErrorMessage(message)),
  fetchFileTypes: () => dispatch(fetchDealerFileTypes()),
  uploadDocument: (payload) => dispatch(uploadPayslipDocument(payload)),
  attachDocumentsToTicket: (payload) =>
    dispatch(attachDocumentsToTicket(payload)),
  clearDocumentDialog: () => dispatch(clearDocumentDialog()),
  onFilterChange: ({filters}) => dispatch(updateFilters(filters)),
  fetchCountryOptions: () => dispatch(fetchCountryOptions()),
  updateDocumentCategory: (data) => dispatch(updateDocumentCategory(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CarOwnership))
