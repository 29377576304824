import * as types from './types'

export const fetchDataStart = () => ({
  type: types.fetchDataStart
})
export const fetchData = ({page, limit}) => ({
  type: types.fetchData,
  payload: {page, limit}
})

export const fetchCurrentPage = (options) => ({
  type: types.fetchCurrentPage,
  payload: options
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})
export const fetchCountryOptions = () => ({
  type: types.fetchPageOptions
})
export const updateDocumentCategory = (data) => ({
  type: types.updateDocumentCategory,
  payload: data
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const updateTicketEntity = (payload) => ({
  type: types.updateTicketEntity,
  payload
})

export const updateTicketEntityError = (payload) => ({
  type: types.updateTicketEntityError,
  payload
})

export const updateTicketEntitySuccess = (payload) => ({
  type: types.updateTicketEntitySuccess,
  payload
})

export const approveTicket = () => ({
  type: types.approveTicket
})

export const updateTicketStart = (payload) => ({
  type: types.updateTicketStart,
  payload
})

export const updateTicket = (data) => ({
  type: types.updateTicket,
  payload: data
})

export const updateTicketSuccess = (payload) => ({
  type: types.updateTicketSuccess,
  payload
})

export const updateTicketError = (payload) => ({
  type: types.updateTicketError,
  payload
})

export const toggleRow = (payload) => ({
  type: types.toggleRow,
  payload
})

export const toggleRowSelection = (payload) => ({
  type: types.toggleRowSelection,
  payload
})

export const fetchRowData = (payload) => ({
  type: types.fetchRowData,
  payload
})

export const fetchRowDataSuccess = (payload) => ({
  type: types.fetchRowDataSuccess,
  payload
})

export const fetchRowDataError = (payload) => ({
  type: types.fetchRowDataError,
  payload
})

export const attachDocumentsToTicket = (payload) => ({
  type: types.attachDocumentsToTicket,
  payload
})

export const createTicketEntitiesStart = (payload) => ({
  type: types.createTicketEntityStart,
  payload
})

export const createTicketEntitiesSuccess = (payload) => ({
  type: types.createTicketEntitySuccess,
  payload
})

export const createTicketEntitiesError = (payload) => ({
  type: types.createTicketEntityError,
  payload
})

export const cleanUpDocuments = () => ({
  type: types.cleanUpDocuments
})

export const clearDocumentDialog = () => ({
  type: types.clearDocumentDialog
})

export const fetchDataError = () => ({
  type: types.fetchDataError
})
