import {Component} from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {Header} from '@fcg/tars'

import CustomSnackbar from '../../components/Snackbar'
import Upload from '../../components/Upload'
import DocList from '../../components/Upload/List'
import LoadingState from '../../components/LoadingState'
import {checkUserPermissions} from '../../store/auth/selectors'
import LeftHeader from './LeftHeader'
import RightHeader from './RightHeader'
import UpdateStatusDialog from './UpdateStatusDialog'
import {Tabs} from '../CRM/common'
import {STATUS_TYPE, hasUpdatePermission} from './constants'

import {
  uploadDocument,
  setUploading,
  fetchDocumentList,
  setReferenceId
} from '../../store/documentConfig/actions'
import {getLocationOptions} from '../../store/config/actions'
import {fetchTransitJobList} from '../../store/transitJob/actions'
import {fetchDriverOptions} from '../../store/driver/actions'
import * as documentSelectors from '../../store/documentConfig/selectors'
import transitJobSelectors from '../../store/transitJob/selectors'
import color from '../../utils/color'

const DocumentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: space-evenly;
`
const Section = styled.div`
  width: ${(props) => props.width};
  border-left: ${(props) => props.border};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 32px;
  overflow: hidden;

  @media(max-width: 525px) {
    display: ${(props) => (props.border ? 'flex' : 'none')}
    border: 0;
    width: 100%;
    padding: 0px;
  }
`

class TransitJobDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: '',
      showUpdateStatusDialog: false,
      activeSection: 'documents',
      hasFullHeader: true
    }
  }

  componentDidMount() {
    const {id} = this.props.match.params

    if (id) {
      this.setState({id})
      this.props.setReferenceId(id)
      this.props.fetchDocumentList({reference: [this.props.match.params.id]})
      this.props.setReferenceId(this.props.match.params.id)
      this.props.fetchTransitJobData()
      this.props.loadOptions()
    }
  }

  leftSection = (props) => (
    <Section width='55%'>
      <LeftHeader
        data={this.props.transitJobData}
        {...props}
        openStatusChangeDialog={this.openStatusChangeDialog}
        checkPermission={this.checkPermission}
      />
    </Section>
  )

  hasUpdateStatusPermission = (allowedStatus) => {
    return (
      this.checkPermission('UPDATE') &&
      hasUpdatePermission(allowedStatus, this.props.transitJobData?.status)
    )
  }

  rightSection = (props) => (
    <Section width='45%' border={`1px solid ${color.gainsboroWhite}`}>
      <RightHeader
        data={this.props.transitJobData}
        {...props}
        hasPermission={this.hasUpdateStatusPermission([
          STATUS_TYPE.PENDING,
          STATUS_TYPE.REJECTED
        ])}
      />
    </Section>
  )

  openStatusChangeDialog = () => {
    if (
      this.hasUpdateStatusPermission([
        STATUS_TYPE.PENDING,
        STATUS_TYPE.SCHEDULED,
        STATUS_TYPE.RUNNING,
        STATUS_TYPE.REJECTED
      ])
    ) {
      this.setState({showUpdateStatusDialog: true})
    }
  }

  closeStatusChangeDialog = () => {
    this.setState({showUpdateStatusDialog: false})
  }

  sectionConfig = () => [
    {label: this.props.t('dealer.documents.heading'), key: 'documents'}
  ]

  updateSelection = (e) => {
    this.setState({
      activeSection: e
    })
  }

  checkPermission = (type) =>
    this.props.hasPermissions({
      entity: 'web.admin.ui.car.transitJobs',
      types: [type]
    })

  render() {
    if (this.props.isFetching) {
      return <LoadingState />
    }

    return (
      <div className='page'>
        <Header
          leftSection={this.leftSection}
          rightSection={this.rightSection}
          containerSelector='.app-content'
          headerHeight={220}
        />
        <Tabs
          sections={this.sectionConfig()}
          handleSelect={this.updateSelection}
          sticky={this.state.hasFullHeader}
          selected={this.state.activeSection}
        />
        <DocumentContainer>
          <DocList
            reference={[this.props.match.params.id]}
            entity='transit-job'
            hasPreviewMode
          />
          <Upload
            classes={['document--widthFull']}
            entity='transit-job'
            reference={this.props.match.params.id}
            visibility='internal'
            status='reviewPending'
            isUploading={this.props.isUploading}
            uploadDocument={this.props.uploadDocument}
            setUploading={this.props.setUploading}
          />
        </DocumentContainer>
        <CustomSnackbar />
        {this.state.showUpdateStatusDialog ? (
          <UpdateStatusDialog
            isOpen={this.state.showUpdateStatusDialog}
            closeDialog={this.closeStatusChangeDialog}
            t={this.props.t}
            transitJobId={this.state.id}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isUploading: documentSelectors.isUploading(state),
  data: transitJobSelectors.getTransitListWithPlaces(state),
  isFetching: transitJobSelectors.isFetchingTransitJob(state),
  transitJobData: transitJobSelectors.getTransitJobByIdWithPlaces(state, {
    id: ownProps.match.params.id
  }),
  hasPermissions: (permissions) => checkUserPermissions(state, permissions)
})

const mapDispatchToProps = (dispatch) => ({
  fetchTransitJobData: (payload) => dispatch(fetchTransitJobList(payload)),
  fetchDocumentList: (payload) => dispatch(fetchDocumentList(payload)),
  uploadDocument: (payload) => dispatch(uploadDocument(payload)),
  setUploading: (payload) => dispatch(setUploading(payload)),
  setReferenceId: (payload) => dispatch(setReferenceId(payload)),
  loadOptions: () => {
    dispatch(getLocationOptions('CURRENT'))
    dispatch(fetchDriverOptions())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(TransitJobDetail))
