import {ValidatedTextField} from '../../../../components/FormValidation'
import {disabledCallback} from './utils'

export default {
  stepNum: 9,
  stepTitle: 'dealer.comments',
  description: 'dealer.panel.description.comments',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            multiline: true,
            id: 'comments',
            label: 'dealer.page.field.comments',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
