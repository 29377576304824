/** Dev note:
 * Pages where this Select component is used.
 * Please do a quick test after modifying
 *
 * - Car Ownership Transfer ticket details
 * - Walkin form
 *    * Booking time slot
 *    * Make
 *    * Model
 *    * Trim,....
 * - Dealer form
 *    * Status field
 *    * Dealer manager
 * - List filters
 *    * Status filter (Dealers)
 *    * Repsonsible (Leads)
 */
/* eslint-disable no-console */
import {Component} from 'react'
import PropTypes from 'prop-types'
import {
  Menu,
  MenuItem,
  Checkbox,
  FormControl,
  ListItemText,
  withStyles,
  FormHelperText
} from '@material-ui/core'
import difference from 'lodash/difference'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import {translate} from 'react-i18next'

import FilterInput from './FilterInput'
import DisplayInput from './DisplayInput'
import OtherInput from './OtherInput'
import Pagination from './Pagination'
import styles from './MenuProps'
import {store} from '../../utils/localStorage'
import {escapeSpecialChars} from '../../utils/regex'

const debounceTime = 800

const findBy = (needle, haystack, param) =>
  haystack.findIndex((item) => isEqual(needle[param], item[param]))

const Storage = store(window.sessionStorage)

export class Select extends Component {
  state = {
    selected: [],
    selectedLabel: '',
    visibleOptions: [],
    options: [],
    currentFilter: '',
    loading: false,
    isOpen: false
  }
  _isMounted = false

  constructor(props) {
    super(props)
    this.store = new Storage(this.props.id)
    this.saveCustomOption = debounce(this.saveCustomOption, 200, {
      trailing: true
    })
    this.filterOptionsDebounced = debounce(this.filterOptions, debounceTime, {
      trailing: true
    })
    this.filterOptionsFn = this.props.debounce
      ? this.filterOptionsDebounced
      : this.filterOptions
  }

  componentDidMount() {
    this._isMounted = true

    if (this._isMounted) {
      this.setOptions()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.filterOptionsFn = null
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.options.length === 0 ||
      this.props.options.length !== nextProps.options.length ||
      this.props.selected !== nextProps.selected ||
      this.props.isFetching !== nextProps.isFetching ||
      this.props.placeholder !== nextProps.placeholder ||
      !isEqual(this.props.options, nextProps.options)
    ) {
      this.setOptions()
    }
  }

  open = () => {
    if (this.props.disabled === false) {
      this.setState({isOpen: true})
    }
  }

  close = () => {
    this.setState({isOpen: false}, () => this.displayNode.blur())
  }

  getDisplayWidth = () =>
    typeof this.container !== 'undefined'
      ? this.container.getBoundingClientRect().width
      : 'auto'

  setOptions = async () => {
    const {t, withTranslate} = this.props
    this.setState({loading: true}, async () => {
      const selected =
        this.props.multiple === true
          ? [...this.props.selected]
          : this.props.selected
      const customOptions = this.props.pereventPersistSelectedOption
        ? []
        : (await this.retrieveCustomOption()) || []
      const tempOptions = new Set([
        ...(Array.isArray(this.props.options) ? this.props.options : []),
        ...customOptions
      ])
      let options = []

      Array.from(tempOptions).map((option) => {
        if (findBy(option, options, 'value') === -1) {
          let label = ''

          if (option && option.label) {
            label =
              withTranslate === true
                ? t(option.label.toString())
                : option.label.toString()
          }

          options.push({...option, label})
        }
      })

      if (
        this.props.withAsyncSource !== true &&
        !this.props.pereventPersistSelectedOption
      ) {
        const saved =
          (await this.persistSelectedOption(selected, options)) || []
        const updates = saved.filter(
          (savedOption) => findBy(savedOption, options, 'value') === -1
        )
        options = options.concat(updates)
      }

      const {withPagination, hideSelectedFromList} = this.props
      let selectedIndex = options.findIndex((item) => item.value === selected)

      selectedIndex = selectedIndex > 5 ? selectedIndex - 5 : 0

      const currentPage = Math.floor(selectedIndex / this.props.pageSize)
      let visibleOptions = withPagination
        ? options.slice(selectedIndex, selectedIndex + this.props.pageSize)
        : options

      if (hideSelectedFromList) {
        visibleOptions = visibleOptions.filter(({value}) =>
          Array.isArray(selected)
            ? !selected.includes(value)
            : selected !== value
        )
      }

      this.setState(
        {
          visibleOptions,
          currentPage,
          total: Math.floor(options.length / this.props.pageSize),
          selected,
          options
        },
        this.renderSelectedLabel
      )
    })
  }

  nextPage = () => {
    const {options} = this.props

    this.setState((state) => {
      const currentPage = state.currentPage + 1
      const selectedPosition = currentPage * this.props.pageSize

      return {
        currentPage,
        visibleOptions: options.slice(
          selectedPosition,
          selectedPosition + this.props.pageSize
        )
      }
    })
  }

  prevPage = () => {
    const {options} = this.props

    this.setState((state) => {
      const currentPage = state.currentPage - 1
      const selectedPosition = currentPage * this.props.pageSize

      return {
        currentPage,
        visibleOptions: options.slice(
          selectedPosition,
          selectedPosition + this.props.pageSize
        )
      }
    })
  }

  addOrRemove = (selection) => {
    const {selected} = this.state
    const index = selected.findIndex((value) => isEqual(value, selection.value))

    if (index === -1) {
      selected.push(selection.value)
    } else {
      selected.splice(index, 1)
    }

    return selected
  }

  handleSelect = (selection) => {
    const selected =
      this.props.multiple === true
        ? this.addOrRemove(selection)
        : selection.value

    this.setState(
      {
        selected,
        isOpen: !!this.props.multiple
      },
      async () => {
        await this.renderSelectedLabel()
        this.props.onChange(selected, this.state.selectedLabel.split(', '))
        this.props.onSelect(selection, this.state.selectedLabel.split(', '))
      }
    )
  }

  setFilter = (e) => {
    this.setState({currentFilter: e.target.value}, this.filterOptionsFn)
  }

  clear = (e) => {
    e && e.stopPropagation()
    this.setState(
      {
        currentFilter: '',
        selected: this.props.multiple === true ? [] : null
      },
      () => {
        this.renderSelectedLabel()
        this.props.onChange(this.state.selected)
        this.props.onSelect(this.state.selected)
        this.filterOptions()
      }
    )
  }

  clearFilter = (e) => {
    e.stopPropagation()
    this.setState({currentFilter: ''}, this.filterOptions)
  }

  filterOptions = () => {
    const {currentFilter, options} = this.state
    const {withPagination} = this.props

    if (this.props.withAsyncSource === true) {
      return this.props.paginationOptions.updateQuery({value: currentFilter})
    }

    const pattern =
      typeof currentFilter !== 'undefined'
        ? new RegExp(escapeSpecialChars(currentFilter), 'gi')
        : ''
    const filteredOptions = options.filter(
      (option) =>
        typeof option.label === 'string' && option.label.match(pattern) !== null
    )

    this.setState({
      visibleOptions: withPagination
        ? filteredOptions.slice(0, this.props.pageSize)
        : filteredOptions,
      currentPage: 0,
      total: Math.floor(filteredOptions.length / this.props.pageSize)
    })
  }

  hasMaxSelected = () => {
    const {max, selected} = this.props

    return max === true && selected.length <= max
  }

  getLabel = (item) => {
    const {options} = this.state
    const source =
      this.props.allOptions && this.props.allOptions.length
        ? this.props.allOptions
        : options
    const res = source.find((option) => isEqual(option.value, item))
    const placeholder = this.props.placeholder || ''

    return typeof res !== 'undefined' && res.label ? res.label : placeholder
  }

  buildLabel = (value) => {
    if (!value) {
      return ''
    }

    if (typeof value === 'object') {
      return Array.isArray(value)
        ? value.join(', ')
        : Object.values(value)
            .map((value) => value || '')
            .join('')
    } else {
      return String(value)
    }
  }

  renderSelectedLabel = () => {
    const {selected} = this.state
    const {withNull, multiple, isFetching, placeholder} = this.props
    const empty = placeholder || ''

    if (isFetching === true) {
      this.setState({selectedLabel: empty, loading: true})

      return
    }

    if (
      (withNull === false && selected === null) ||
      selected === '' ||
      (multiple === true && selected.length === 0)
    ) {
      this.setState({selectedLabel: empty, loading: false})

      return
    }

    const selectedLabel =
      multiple !== true
        ? this.getLabel(selected)
        : selected.map((item) => this.getLabel(item)).join(', ')

    this.setState({selectedLabel, loading: false})
  }

  valueExists = (value, arr) => {
    return arr.findIndex((option) => isEqual(option.value, value)) > -1
  }

  retrieveCustomOption = async () => {
    if (!this.props.persistChoice) {
      return []
    }

    const {id} = this.props
    try {
      const option = await this.store.retrieve({name: `${id}_other`})

      return option
    } catch (e) {
      console.error(e)
    }
  }

  saveCustomOption = async (value) => {
    if (!this.props.persistChoice) {
      return false
    }

    const {id} = this.props
    const customOption = {label: this.buildLabel(value), value}
    const optionExists = this.valueExists(value, this.state.options)

    if (optionExists) {
      return false
    }

    try {
      await this.store
        .update({name: `${id}_other`, value: customOption})
        .then(() => {
          this.setState(
            {
              options: [...this.state.options, customOption],
              visibleOptions: [...this.state.visibleOptions, customOption]
            },
            () => this.handleSelect(customOption)
          )

          return true
        })
    } catch (err) {
      console.error(err)
    }
  }

  removeCustomOption = async (value) => {
    const {id} = this.props
    const filterValues = (option) => option.label !== value

    try {
      const customOptions = await this.retrieveCustomOption()
      const updated = customOptions.filter(filterValues)
      this.store.save({name: `${id}_other`, value: updated})

      this.setState((prev) => ({
        options: prev.options.filter(filterValues),
        visibleOptions: prev.visibleOptions.filter(filterValues)
      }))
    } catch (err) {
      console.error(err)
    }
  }

  persistSelectedOption = async (selected, options) => {
    try {
      if (selected && !this.props.placeholder) {
        const values = Object.values(options).map(({value}) => value)

        if (Array.isArray(selected)) {
          const newValues = difference(selected, values)

          if (newValues.length > 0) {
            return Promise.all(newValues.map(this.storeSelected))
          }
        } else {
          if (values.indexOf(selected) === -1) {
            return Promise.all([this.storeSelected(selected)])
          }
        }
      } else {
        return []
      }
    } catch (e) {
      console.error(e)
    }
  }

  storeSelected = async (value) => {
    const {id} = this.props
    const customOption = {label: this.buildLabel(value), value}

    return this.store
      .update({name: `${id}_other`, value: customOption})
      .then(() => customOption)
  }

  checkIfSelected = (multiple, selected, value) => {
    if (multiple) {
      return Array.isArray(selected)
        ? selected.some((item) => isEqual(item, value))
        : selected.includes(value)
    } else {
      return isEqual(selected, value)
    }
  }

  renderVisibleOptions = ({visibleOptions, multiple, selected, t}) => {
    if (!visibleOptions.length) {
      return (
        <MenuItem disabled>
          <ListItemText primary={t('global.message.noOptions')} />
        </MenuItem>
      )
    }

    return visibleOptions.map((option, index) => {
      const isSelected = this.checkIfSelected(multiple, selected, option.value)
      const listItemLabel =
        this.props.translateLabels && t ? t(option.label) : option.label

      return (
        <MenuItem
          onClick={() => this.handleSelect(option)}
          key={index}
          value={option.value}
          selected={isSelected}
          data-test={`option-${option['data-test'] || index}`}
        >
          {multiple && <Checkbox checked={isSelected} />}
          <ListItemText primary={listItemLabel} />
        </MenuItem>
      )
    })
  }

  render() {
    const {
      id,
      t,
      type,
      filterable,
      multiple,
      disableClear,
      disableUnderline,
      color,
      label,
      fullWidth,
      withOther,
      classes,
      className,
      margin,
      withAsyncSource,
      isFetching,
      disabled,
      error,
      helperText,
      required,
      inputPlaceholder,
      withStartAdornment,
      withEndAdornment,
      disableLabel,
      cursor,
      translateLabels
    } = this.props
    const {
      isOpen,
      selected,
      selectedLabel,
      visibleOptions,
      currentFilter,
      loading
    } = this.state
    const dataTest = this.props['data-test'] || this.props.key || this.props.id

    const input = filterable && (
      <FilterInput
        withAsyncSource={withAsyncSource}
        isFetching={isFetching || loading}
        onChange={this.setFilter}
        className={classes.filterable}
        currentFilter={currentFilter}
        filterable={filterable}
        clear={this.clearFilter}
        selectedLabel={selectedLabel}
        withEndAdornment={withEndAdornment}
        withStartAdornment={withStartAdornment}
        data-test={`filter-input-${dataTest}`}
      />
    )
    const otherInput = withOther && (
      <OtherInput
        onSave={this.saveCustomOption}
        onRemove={this.removeCustomOption}
        multiple={multiple}
        type={type}
        t={t}
        data-test={`other-input-${dataTest}`}
      />
    )
    const inputSelectedLabel =
      translateLabels && t && !Array.isArray(selectedLabel) && selectedLabel
        ? t(selectedLabel)
        : selectedLabel

    return (
      <div
        ref={(el) => {
          this.container = el
        }}
        className={className}
      >
        <FormControl
          fullWidth={fullWidth}
          style={this.props.style}
          error={error}
        >
          <DisplayInput
            id={id}
            disabled={disabled}
            color={color}
            label={t(label)}
            isFetching={isFetching}
            selectedLabel={inputSelectedLabel}
            placeholder={inputPlaceholder}
            clear={this.clear}
            inputRef={(el) => {
              this.displayNode = el
            }}
            open={isOpen}
            margin={margin}
            disableClear={disableClear}
            disableUnderline={disableUnderline}
            onClick={this.open}
            error={error}
            required={required}
            withStartAdornment={withStartAdornment}
            withEndAdornment={withEndAdornment}
            className={classes.selectInput}
            data-test={`select-${dataTest}`}
            fullWidth={fullWidth}
            disableLabel={disableLabel}
            cursor={cursor}
          />
          <Menu
            anchorEl={this.container}
            open={this.state.isOpen}
            className={classes.Paper}
            PaperProps={{
              style: {
                minWidth: this.getDisplayWidth(),
                maxHeight: 300
              }
            }}
            onClose={this.close}
            data-test={`options-${dataTest}`}
          >
            {input}
            {this.renderVisibleOptions({
              visibleOptions,
              multiple,
              selected,
              t
            })}
            {otherInput}
            {withAsyncSource && (
              <Pagination
                {...this.props.paginationOptions}
                params={
                  currentFilter !== '' ? {anyName: currentFilter} : undefined
                }
              />
            )}
            {this.props.withPagination && (
              <Pagination
                page={this.state.currentPage + 1}
                total={this.state.total + 1}
                requestPrevPage={this.prevPage}
                requestNextPage={this.nextPage}
                hasPrevPage={this.state.currentPage > 0}
                hasNextPage={this.state.currentPage < this.state.total}
                params={
                  currentFilter !== '' ? {anyName: currentFilter} : undefined
                }
              />
            )}
          </Menu>
          {helperText && helperText.length > 0 ? (
            <FormHelperText>{t(helperText)}</FormHelperText>
          ) : null}
        </FormControl>
      </div>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  filterable: PropTypes.bool,
  multiple: PropTypes.bool,
  withOther: PropTypes.bool,
  withTranslate: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
        PropTypes.array
      ])
    })
  ),
  allOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ])
    })
  ),
  placeholder: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  pageSize: PropTypes.number,
  withPagination: PropTypes.bool,
  persistChoice: PropTypes.bool,
  translateLabels: PropTypes.bool
}

Select.defaultProps = {
  withOther: false,
  autoWidth: false,
  fullWidth: false,
  multiple: false,
  withTranslate: true,
  withNull: false,
  native: false,
  filterable: true,
  label: '',
  selected: [],
  options: [],
  allOptions: [],
  onSelect: () => {},
  disabled: false,
  pageSize: 20,
  persistChoice: true,
  hideSelectedFromList: false,
  translateLabels: false
}

const SelectField = withStyles(styles, {name: 'Select'})(translate()(Select))

SelectField.type = 'Select'

export default SelectField
