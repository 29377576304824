import {PureComponent} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  updateDocument,
  deleteDocument,
  downloadDocument,
  previewDocument
} from '../../store/documentConfig/actions'
import * as selectors from '../../store/documentConfig/selectors'
import './document.styl'
import {FileIcon} from './FileIcon'
import Row from './Row'
import CircularProgress from '@material-ui/core/CircularProgress'
import {showSnackbarNotification} from '../../store/signals/actions'
import {DeleteDialog} from '../../pages/CRM/common'
import {Header, List} from './documentUploadStyle'
import {isColumnShown} from './helper'

const deleteInitialState = {
  open: false,
  entity: null
}

export class DocumentList extends PureComponent {
  state = {
    delete: {...deleteInitialState}
  }

  openDeleteDialog = ({entity, id, action, name}) => {
    const {t} = this.props
    const content = t('dealerGroups.delete.alert').replace('%groupName%', name)

    this.setState({
      delete: {
        open: true,
        entity,
        content,
        handleSubmit: () => {
          action({documentIds: [id]})

          if (typeof this.props.onDocumentDelete === 'function') {
            this.props.onDocumentDelete({documentIds: [id]})
          }

          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  updateDocument = (data) => {
    const params = this.props.fetchOnlyCurrentDocs
      ? {...data, documentIds: this.props.documentIds}
      : data

    this.props.updateDocument(params)
  }

  renderRows = () => {
    if (this.props.isFetching) {
      return (
        <div className='empty'>
          <CircularProgress color='inherit' />
        </div>
      )
    } else {
      if (!this.props.documents.length) {
        return (
          <div className='empty'>
            <FileIcon />
            <span>{this.props.t('documentList.empty')}</span>
          </div>
        )
      }

      let rowContainerClasses = 'row-container'

      if (this.props.listWidth && this.props.listWidth === 'flex') {
        rowContainerClasses = `${rowContainerClasses} ${this.props.listWidth}`
      }

      return (
        <div className={rowContainerClasses}>
          {this.props.documents.map((data, index) => (
            <Row
              key={data.id}
              document={data}
              updateDocument={this.updateDocument}
              downloadDocument={this.props.downloadDocument}
              previewDocument={this.props.previewDocument}
              hasPreviewMode={this.props.hasPreviewMode}
              deleteDocument={(document) =>
                this.openDeleteDialog({
                  entity: 'document',
                  id: document,
                  action: this.props.deleteDocument,
                  name: data.fileName
                })
              }
              showSnackbarNotification={this.props.showSnackbarNotification}
              dealerFileTypeOptions={this.props.dealerFileTypeOptions}
              reference={this.props.reference}
              data-test={index}
              deleteDocumentEnabled={this.props.deleteDocumentEnabled}
              customColumns={this.props.customColumns}
              hiddenColumns={this.props.hiddenColumns}
              documentErrors={this.props.documentErrors}
            />
          ))}
          <DeleteDialog {...this.state.delete} />
        </div>
      )
    }
  }

  renderFileTypeHeader = () => {
    if (
      !this.props.dealerFileTypeOptions ||
      !this.props.dealerFileTypeOptions.length
    ) {
      return null
    }

    return (
      <span className='header__file-type'>{this.props.t('File type')}</span>
    )
  }

  renderCustomHeaders = () => {
    if (!this.props.customColumns || !this.props.customColumns.length) {
      return
    }

    return this.props.customColumns.map((column) => (
      <span className={`header__${column.key}`} key={column.key}>
        {this.props.t(column.header)}
      </span>
    ))
  }

  render() {
    let documentListClass = 'document-list'

    if (this.props.listWidth) {
      documentListClass = `${documentListClass} ${this.props.listWidth}`
    }

    return (
      <List
        className={documentListClass}
        rowHeight={this.props.rowHeight}
        tableHeight={this.props.tableHeight}
      >
        <Header className='header'>
          <span className='header__file-name'>
            {this.props.t('documentList.documentName')}
          </span>
          <span className='header__date'>
            {this.props.t('documentList.uploadedAt')}
          </span>
          {isColumnShown(this.props, 'uploader') ? (
            <span className='header__uploader'>
              {this.props.t('documentList.uploader')}
            </span>
          ) : null}
          {this.renderCustomHeaders()}
          {this.renderFileTypeHeader()}
          <div className='header__icon-wrapper' />
        </Header>
        {this.renderRows()}
      </List>
    )
  }
}

DocumentList.defaultProps = {
  reference: null,
  fetchOnlyCurrentDocs: false
}

const mapStateToProps = (state) => {
  const documents = selectors.documentList(state)
  const documentIds = documents.map(({id}) => id)

  return {
    documents,
    documentIds,
    isFetching: selectors.isDocumentFetching(state)
  }
}

const mapDisptachToProps = (dispatch) => ({
  updateDocument: (payload) => dispatch(updateDocument(payload)),
  downloadDocument: (payload) => dispatch(downloadDocument(payload)),
  deleteDocument: (payload) => dispatch(deleteDocument(payload)),
  previewDocument: (payload) => dispatch(previewDocument(payload)),
  showSnackbarNotification: (payload) =>
    dispatch(showSnackbarNotification(payload))
})

const connected = connect(mapStateToProps, mapDisptachToProps)(DocumentList)

export default translate()(connected)
