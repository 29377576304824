import {Component} from 'react'
import {connect} from 'react-redux'
import {
  deleteDataFromCollection,
  mergeResources
} from '../../store/resources/actions'
import PageTableBase from '../../components/PageTableBase'
import * as selectors from '../../store/bulkUpload/selectors'
import styled from 'styled-components'
import ValidatedTextField from '../../components/FormValidation/ValidatedTextField'
import TextField from '@material-ui/core/TextField'
import validator from '../../store/bulkUpload/validator'
import {getCountryConfig} from '../../../config/country'
import {
  BULK_UPLOAD_NOT_VALID,
  BULK_UPLOAD_VALID,
  BULK_UPLOAD,
  SECOND_STEP
} from './constants'
import {translate} from 'react-i18next'

const noLabelStyle = {style: {display: 'none'}}
const TextfieldWithoutPlaceholder = (props) => (
  <TextField {...props} InputLabelProps={noLabelStyle} />
)
const editableCellTypes = {
  string: TextfieldWithoutPlaceholder,
  number: TextfieldWithoutPlaceholder,
  currency: TextfieldWithoutPlaceholder
}
const editableValidatedCellTypes = {
  string: ValidatedTextField,
  number: ValidatedTextField,
  currency: ValidatedTextField
}
const editableCellOptions = {
  editableCellTypes,
  editableValidatedCellTypes,
  defaultEditableField: TextField
}
const BulkUploadPage = styled.div`
  .RichTable__tableWrapper {
    height: calc(
      100vh - ${(props) => (props.step === SECOND_STEP ? '256px' : '312px')}
    );
  }

  td:first-of-type {
    svg {
      color: ${(props) => (props.step === SECOND_STEP ? '' : '#5d7e31')};
    }
  }
  td {
    a {
      display: table-cell;
      vertical-align: middle;
      svg {
        display: block;
        font-size: 20px;
      }
    }
  }

  .has-error {
    td:first-of-type {
      svg {
        color: #7d3031;
      }
    }
  }
`

export class BulkUploadList extends Component {
  state = {active: true}

  handleChange = ({row, field = {}, value}) => {
    const {createdCount, notCreatedCount} = this.props
    const carValidation = getCountryConfig().validation.carCreate

    row[field.key] = value
    row = validator(row, carValidation)

    const collection =
      createdCount || notCreatedCount || row.errors
        ? BULK_UPLOAD_NOT_VALID
        : BULK_UPLOAD_VALID

    this.props.deleteDataFromCollection({
      ids: [row.id],
      resourceType: BULK_UPLOAD
    })

    this.props.mergeResources(
      {
        'bulk-upload': {
          list: [row]
        }
      },
      {
        type: BULK_UPLOAD,
        name: collection
      }
    )
  }

  render() {
    const {
      bulkUploadFields,
      filters,
      showErrorIcon = true,
      selectable = false,
      step
    } = this.props

    return (
      <BulkUploadPage className='page' step={step}>
        <PageTableBase
          {...this.props}
          limit={this.props.data.length}
          selectable={selectable}
          filter={filters}
          fields={bulkUploadFields}
          editableCellOptions={editableCellOptions}
          onCellBlur={this.handleChange}
          showErrorIcon={showErrorIcon}
        />
      </BulkUploadPage>
    )
  }
}
const mapStateToProps = (state) => ({
  error: selectors.getError(state),
  filters: selectors.getFilters(state),
  bulkUploadFields: selectors.getBulkUploadFields(state),
  pageConfig: selectors.getPageConfig(state),
  createdCount: selectors.getCreatedCount(state),
  notCreatedCount: selectors.getNotCreatedCount(state),
  activeListType: selectors.getActiveListType(state),
  step: selectors.getStep(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {},
  mergeResources: (data, options) => dispatch(mergeResources(data, options)),
  deleteDataFromCollection: (row) => dispatch(deleteDataFromCollection(row))
})

BulkUploadList.defaultProps = {
  filterable: false,
  withVisibility: false,
  hasFooter: false,
  noToolbar: true,
  multisort: false
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(BulkUploadList))
