const resourceName = 'cardata'

export const fetchCarDataList = `${resourceName}/FETCH_CAR_DATA_LIST`
export const fetchCarDataListSuccess = `${resourceName}/FETCH_CAR_DATA_LIST_SUCCESS`
export const fetchCarDataListError = `${resourceName}/FETCH_CAR_DATA_LIST_ERROR`
export const updateCardDataField = `${resourceName}/UPDATE_CAR_DATA_FIELD`

export const updateCarData = `${resourceName}/UPDATE_CAR_DATA`
export const updateCarDataSuccess = `${resourceName}/UPDATE_CAR_DATA_SUCCESS`
export const updateCarDataError = `${resourceName}/UPDATE_CAR_DATA_ERROR`

export const createCarData = `${resourceName}/CREATE_CAR_DATA`
export const createCarDataSuccess = `${resourceName}/CREATE_CAR_DATA_SUCCESS`
export const createCarDataError = `${resourceName}/CREATE_CAR_DATA_ERROR`

export const deleteCarData = `${resourceName}/DELETE_CAR_DATA`
export const deleteCarDataSuccess = `${resourceName}/DELETE_CAR_DATA_SUCCESS`
export const deleteCarDataError = `${resourceName}/DELETE_CAR_DATA_ERROR`

export const resetCarData = `${resourceName}/RESET_CAR_DATA`
export const resetError = `${resourceName}/RESET_ERROR`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`

export const uploadCarData = `${resourceName}/UPLOAD_CAR_DATA`
export const uploadCarDataSuccess = `${resourceName}/UPLOAD_CAR_DATA_SUCCESS`
export const uploadCarDataError = `${resourceName}/UPLOAD_CAR_DATA_ERROR`

export const setUploading = `${resourceName}/SET_UPLOADING`
export const openCreateDialog = `${resourceName}/OPEN_CREATE_DIALOG`
export const openEditDialog = `${resourceName}/OPEN_EDIT_DIALOG`
export const hideCreateEditDialog = `${resourceName}/HIDE_CREATE_EDIT_DIALOG`
