import PropTypes from 'prop-types'
import {Paper, Typography, Grid, withStyles} from '@material-ui/core'
import {translate} from 'react-i18next'
import styles from './panelStyles'

export const Panel = translate()((props) => {
  const {t, pageName} = props
  
  const pageFlag = pageName === 'userPermissionPage'
  const tableWrapper =  pageFlag ? {
    marginTop: '-6px', boxShadow: 'none', paddingTop: '14px'
  } : {}
  
  const className =
    props.noMargin === false
      ? props.classes.root
      : [props.classes.root, props.classes.noMargin].join(' ')

  const header = (
    <Typography variant='title' className={props.classes.header}>
      {t(props.header)}
    </Typography>
  )
  const subheader = props.subheader && (
    <Typography variant='caption' className={props.classes.subheader}>
      {t(props.subheader)}
    </Typography>
  )

  return (
    <Paper className={className} elevation={3} style={tableWrapper}>
      <Grid container className={props.classes.headerContainer}>
        <Grid item xs={pageFlag ? 12 : 8} md={pageFlag ? 6 : 10}>
          {header}
          {subheader}
        </Grid>
        <Grid item xs={pageFlag ? 12 : 4} md={pageFlag ? 6 : 2} className={props.classes.action}>
          {props.actionButton || null}
        </Grid>
      </Grid>
      <div className={props.classes.container}>{props.children}</div>
    </Paper>
  )
})

Panel.displayName = 'CRMPanel'

Panel.defaultProps = {
  noMargin: false
}

Panel.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  actionButton: PropTypes.node,
  noMargin: PropTypes.bool
}

export default withStyles(styles)(Panel)
