import {Button, IconButton, withStyles} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    boxShadow: theme.shadows[3],
    '& .icon': {
      fontSize: '18px'
    }
  },
  isIcon: {
    borderRadius: '3px',
    fontSize: '18px',
    marginLeft: theme.spacing.unit,
    width: '36px',
    height: '36px'
  },
  iconRight: {
    paddingLeft: theme.spacing.unit
  }
})

const FilterIconBase = ({classes, children, ...rest}) => (
  <IconButton {...rest} className={[classes.root, classes.isIcon].join(' ')}>
    {children}
  </IconButton>
)

export const FilterIconButton = withStyles(styles)(FilterIconBase)

const FilterButton = ({classes, iconRight, children, ...rest}) => {
  const className = [
    classes.root,
    iconRight === true ? classes.iconRight : ''
  ].join(' ')

  return (
    <Button className={className} {...rest} tabIndex='-1'>
      {children}
    </Button>
  )
}

export default withStyles(styles)(FilterButton)
