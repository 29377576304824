import {ApiClient} from '@fcg/admin-api-client'

import {
  listFields,
  placeFields,
  countryOptionsListFields,
  areasListFields,
  areaFields,
  lotsListFields,
  carsListFields,
  lotFields
} from './fields'

import {
  formatCountry,
  formatSort,
  formatPlaceResponse,
  formatAreaResponse,
  formatLotResponse,
  returnCountryOptionsResponse
} from '../helpers/transformHelpers'
import Country from '../../types/Country'
import Location from '../../types/Location'
import Area from '../../types/Area'
import Lot from '../../types/Lot'
import Car from '../../types/Car'

const client = new ApiClient('car')

export const getPlacesList = (country: Country) => {
  const params = {
    country: country,
    sort: [
      {order: 'DESC', field: 'active'},
      {order: 'ASC', field: 'location'}
    ],
    cache: false
  }

  return client
    .withTransform([formatCountry, formatSort])
    .query('place', {...params, cache: false}, listFields)
    .then((res: {place: {list: Location[]}}) => res.place.list)
}

export const createOrUpdatePlace = (place: Location) => {
  const mutation = `${place.id ? 'update' : 'create'}Place`

  return client
    .withTransform(formatCountry)
    .mutation(mutation, place, placeFields)
    .then(formatPlaceResponse)
}

export const getCities = (country: Country) => {
  const params = {
    type: 'locationCity',
    country: country
  }

  return client
    .withTransform(formatCountry)
    .query('countryOptions', params, countryOptionsListFields)
    .then(returnCountryOptionsResponse)
}

export const getLocationTypes = (country: Country) => {
  const params = {
    type: 'locationType',
    country: country
  }

  return client
    .withTransform(formatCountry)
    .query('countryOptions', params, countryOptionsListFields)
    .then(returnCountryOptionsResponse)
}

export const getCar = (car: Car) => {
  const {id} = car

  const params = {
    id
  }

  return client
    .query('car', params, carsListFields)
    .then((res: {car: {list: Car[]}}) => res.car.list)
}

export const getLotsList = (lot: Lot) => {
  const {areaId} = lot

  const params = {
    areaId: areaId || null
  }

  return client
    .query('lot', params, lotsListFields)
    .then((res: {lot: {list: Lot[]}}) => res.lot.list)
}

export const getAreasList = (area: Area) => {
  const {id, placeId, name} = area

  const params = {
    id: id || null,
    placeId: placeId || null,
    name: name || null
  }

  return client
    .query('area', params, areasListFields)
    .then((res: {area: {list: Area[]}}) => res.area.list)
}

export const createOrUpdateArea = (area: Area) => {
  const mutation = `${area.id ? 'update' : 'create'}Area`

  return client.mutation(mutation, area, areaFields).then(formatAreaResponse)
}

export const createOrUpdateLot = (lot: Lot) => {
  const mutation = `${lot.id ? 'update' : 'create'}Lot`

  return client.mutation(mutation, lot, lotsListFields).then(formatLotResponse)
}

export const deleteAreaById = (id: string) => {
  const mutation = 'deleteArea'

  return client.mutation(mutation, {id: id})
}

export const deleteLots = (ids: string[]) => {
  const mutation = 'deleteLot'

  return client.mutation(mutation, {ids: ids})
}

export const emptyLot = (id: string, carId: string | null) => {
  const mutation = 'updateLot'

  return client.mutation(mutation, {id: id, carId}, lotFields).then(formatLotResponse)
}