import {createSelector} from 'reselect'
import {hiddenFieldsPath} from '../global/selectors'

export const selectorPath = (state) => state.config
const hiddenPagesPath = (state) => selectorPath(state).hiddenPages
const filterCountryOptionByCountry = (options, countryCode) => {
  if (!options || (options && options.length === 0)) {
    return []
  }

  return options.filter((city) => city.country === countryCode)
}

export const getCountryCode = (state) => selectorPath(state).countryCode
export const getPhone = (state) => selectorPath(state).phone
export const getPhoneNumberExactMatch = (state) =>
  selectorPath(state).useExactPhoneNumberSearch

export const getCurrencyConfig = (state) => selectorPath(state).currency
export const getCities = (state) => selectorPath(state).options.stateCities
export const getStates = (state) => selectorPath(state).options.countryStates
export const getBiMappingCountryCodes = (state) =>
  selectorPath(state).biMappingCountryCodes

export const getHiddenPages = createSelector(
  [hiddenPagesPath, hiddenFieldsPath],
  (hiddenPages, hiddenFields) => {
    const pages = hiddenPages || []
    const fields = hiddenFields || []

    return Array.from(new Set([...fields, ...pages]))
  }
)

export const isPageHidden = (page) =>
  createSelector(getHiddenPages, (hiddenPages) => hiddenPages.includes(page))

export const getCountryCities = createSelector(
  [getCities, getCountryCode],
  filterCountryOptionByCountry
)
export const getCountryStates = createSelector(
  [getStates, getCountryCode],
  filterCountryOptionByCountry
)
export const getLocations = (state) =>
  selectorPath(state).options.locationOptions

export const getPhoneConfiguration = (state) => {
  if (!getPhoneNumberExactMatch(state)) {
    return
  }
  let {length, code} = getPhone(state) || {}
  length = (length && (length[0] || length.min)) || 0

  length = Number(length)
  if (!code || !length) {
    return
  }
  code = ('0000' + code).slice(-4)
  if (getPhone(state).length.max) {
    let phoneMaxLength = getPhone(state).length.max
    return {code, length, phoneMaxLength}
  }
  return {code, length}
}
