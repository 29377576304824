const AA1PORT = 8080

/**
 * The function is intended to redirect the user to
 * Admin App 1 (Legacy App). This also handles whether
 * the app is to be open in new window or redirect in the same.
 * @param {string} path - this is the path to redirect to in legacy app
 * @param {boolean} replace - if push or replace history function to be used
 * @param {string | undefined} target- the target window to redirect to
 * @param {legacyHost} legacyHost - the hostname to redirect to
 * @return {undefined} - there is not return value expected
 */

export const navigateToAA1 = (
  path,
  replace = false,
  target = '_blank',
  legacyHost = null
) => {
  let url = ''
  if (legacyHost) {
    url = location.hostname.replace('admin2', legacyHost)
  } else {
    url = location.hostname.replace('admin2', 'admin')
  }

  if (process.env.NODE_ENV !== 'production') {
    url = `${url}:${AA1PORT}`
  }

  url = `${location.protocol}//${url}${path}`

  if (replace) {
    window.location.replace(url)
  } else {
    window.open(url, target)
  }
}

export const getAA1LegacyRoute = (path) => {
  let url = location.hostname.replace('admin2', 'admin')

  if (process.env.NODE_ENV !== 'production') {
    url = `${url}:${AA1PORT}`
  }

  url = `${location.protocol}//${url}${path}`

  return url
}
