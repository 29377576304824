import axios from 'axios'

const fieldsToConvertInKeyValues = [
  'make',
  'model',
  'transmission',
  'trim',
  'location',
  'bodyType',
  'faceLift',
  'seat',
  'engine',
  'wheelDrive'
]

const trimFields = ['face_lift', 'seat', 'engine', 'wheel_drive', 'variant']

function transformTrimDetails(data) {
  fieldsToConvertInKeyValues.forEach((field) => {
    const value = data[field]
    if (value) {
      data[field] = {}
      data[field].key = value
      data[field].value = value
    }
  })

  if (data.bodyType) {
    data.body_type = data.bodyType
  }

  if (data.trim) {
    data.variant = data.trim
  }

  if (data.faceLift) {
    data.face_lift = data.faceLift
  }

  if (data.wheelDrive) {
    data.wheel_drive = data.wheelDrive
  }

  delete data.trim // trim is not needed/supported in DS API.
  // It is split into multiple values

  delete data.bodyType // bodyType is body_type in DS pricing API
  delete data.faceLift
  delete data.wheelDrive

  return data
}

export const getLeadPrice = async (data) => {
  const dataInDSWrapperFormat = transformTrimDetails(data)
  const res = await axios.post('/api/getLeadPrice', dataInDSWrapperFormat)

  return res.data
}
