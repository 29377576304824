import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../../config/country'

const selectorPath = (state) => state.appointment

export const appointmentFields = (state) => {
  const {list} = selectorPath(state)

  if (list.fields) {
    return Object.values(list.fields)
  }
}

const childrenReducer = (field, fields, propName) => {
  if (field && fields && fields.includes(field[propName])) {
    return -1
  }

  if (field.type === 'composite' && field.children && field.children.length) {
    field = {
      ...field,
      children: field.children.reduce((reducedChildren, child) => {
        const c = childrenReducer(child, fields, propName)

        if (c === -1) {
          return reducedChildren
        }

        return [...reducedChildren, c]
      }, [])
    }
  }

  return field
}

export const getAppointmentFilters = createSelector(
  appointmentFields,
  (fields) => {
    const {crmDetails: config} = getCountryConfig()

    if (!config.hiddenFilters && fields) {
      return fields
    }

    if (fields && fields.length) {
      return fields.map((field) =>
        childrenReducer(field, config.hiddenFilters, 'filterName')
      )
    }

    return []
  }
)

export const getAppointmentFields = createSelector(
  appointmentFields,
  (fields) => {
    const {crmDetails: config} = getCountryConfig()

    if (!config.hiddenColumns && fields) {
      return fields
    }

    if (fields && fields.length) {
      return fields.map((field) =>
        childrenReducer(field, config.hiddenColumns, 'columnName')
      )
    }

    return []
  }
)

export const getAppointment = createSelector(
  (appointment) => appointment,
  (appointment) => {
    if (appointment && appointment.appointmentSlots) {
      return {
        ...appointment,
        appointmentSlots: appointment.appointmentSlots.map((item) => ({
          ...item,
          slot: `${item.data} ${item.time}`
        }))
      }
    }

    return appointment
  }
)
