export const appointmentUpdateFields = [
  'id',
  [
    'appointmentData',
    [
      'bookingId',
      'date',
      'time',
      'status',
      'comments',
      'placeId',
      'bookingSource',
      'rejectionReasons',
      'cancellationReason',
      'callStatus',
      'address'
    ]
  ]
]

export const appointmentFields = [
  'id',
  'bookingId',
  'leadId',
  'date',
  'time',
  'inspectionPointCity',
  'inspectionPointName',
  'rejectionReasons',
  'bookingSource',
  'createdAt',
  'createdByUserEmail',
  'placeId',
  'status',
  'comments',
  'cancellationReason',
  'callStatus',
  'cancelledInStatus',
  'cancelledAt',
  'assignedTo',
  'assignedToName',
  'type',
  'address'
]

export const leadFields = [
  'id',
  'country',
  'make',
  'model',
  'leadSource',
  'externalLeadId',
  'serviceType',
  'trim',
  'plate',
  'transmission',
  'year',
  'mileage',
  'price',
  'minPrice',
  'maxPrice',
  'responsible',
  'responsibleName',
  'status',
  'rejectionReasons',
  'createdAt',
  'createdByUserEmail',
  'discardedReason',
  'referralCode',
  'ownerName',
  'ownerPhone',
  ['appointments', appointmentFields],
  'inspectionType'
]

export const contactFields = [
  'id',
  'country',
  'locale',
  'salutation',
  'firstname',
  'lastname',
  'email',
  'phone',
  'phone2',
  'address',
  'createdAt',
  'updatedAt',
  'smsMarketing',
  'emailMarketing',
  'createdByUserEmail',
  'city',
  'postcode'
]

export const contactWithLeads = [...contactFields, ['leads', leadFields]]

export const commentFields = [
  'id',
  'contactId',
  'type',
  'subtype',
  'subject',
  'status',
  'dueDate',
  'priority',
  'responsible',
  'responsibleName',
  'description',
  'createdAt',
  'createdByUserEmail'
]

export const placeFields = [
  'id',
  'country',
  'city',
  'location',
  'type',
  'inspectionPointId',
  'active'
]

export const leadWithContactFields = [...leadFields, ['contact', contactFields]]

export const appointmentAllFields = [
  ...appointmentFields,
  'createdAt',
  'createdByUserEmail',
  ['lead', leadWithContactFields]
]

export const appointmentList = ['count', ['list', appointmentAllFields]]
export const placeList = ['count', ['list', placeFields]]
