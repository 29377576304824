import {connect} from 'react-redux'
import filter from '../../../../components/RichTable/FilterBar/Filter/wrapFilter'
import Select from '../../../../components/Select/Select'
import * as selectors from '../../../../store/calendar/selectors'
import {getAppointmentStatusesFilter} from '../../../../store/crm/selectors'

const FilterBase = (type) => (props) => (
  <Select
    multiple
    {...props}
    id={props.fieldName}
    label={props.filterLabel || props.label}
    selected={props.value}
    onChange={props.handleChange(type)}
  />
)

const getLocations = (state) => ({
  options: selectors.getLocations(state)
})

const getCities = (state) => ({
  options: selectors.getCities(state)
})

const getStatuses = (state) => ({
  options: getAppointmentStatusesFilter(state)
})

export const LocationFilter = connect(getLocations)(
  filter(FilterBase('placeId'))
)

export const CityFilter = connect(getCities)(filter(FilterBase('placeId')))

export const StatusFilter = connect(getStatuses)(filter(FilterBase('status')))
