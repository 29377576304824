import {Dialog, DialogContent, DialogActions, Button} from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {translate} from 'react-i18next'
import SlideUp from '../../../components/Animations/Slides'

const ConfirmDelete = ({onConfirm, onCancel, t}) => {
  return (
    <Dialog open onClose={onCancel} TransitionComponent={SlideUp} maxWidth='sm'>
      <DialogTitle>Delete confirmation</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('global.message.confirmDelete')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('button.back')}</Button>
        <Button onClick={onConfirm} variant='raised' color='primary'>
          {t('button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default translate()(ConfirmDelete)
