import wrapFilter from './wrapFilter'
import {TextField, withStyles} from '@material-ui/core'
import styles from './styles'

export const FilterString = withStyles(styles)((props) => {
  const {value, handleChange, onKeyUp, classes} = props
  const extractValue = (name) => (e) => handleChange(name)(e.target.value)

  return (
    <TextField
      fullWidth
      className={props.className || 'FilterString'}
      InputProps={{className: classes.input}}
      value={value}
      onKeyUp={onKeyUp}
      onChange={extractValue(props.fieldName)}
    />
  )
})

export default wrapFilter(FilterString)
