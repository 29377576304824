import * as types from './types'
import * as tableDecorators from '../utils/tableDecorators'
import {options} from '../config/types'
import {getCountryConfig} from '../../../config/country'

const {
  appointmentStatusRejectionReasonMapping,
  leadStatusDiscardedReasonMapping
} = getCountryConfig()

const prepareCountryOptions = ({name, metaInfo, translationKey}) => ({
  label: name,
  value: name,
  translationKey,
  metaInfo
})

const reducer = {
  [types.optionsLoading]: (state) => ({
    ...state,
    optionsLoading: true
  }),
  [types.optionsLoaded]: (state, action) => {
    const options = Object.keys(action.payload).reduce((acc, key) => {
      acc[key] = action.payload[key].list.map(prepareCountryOptions)

      return acc
    }, {})

    const temp = {
      ...state,
      options,
      optionsLoading: false
    }
    if (appointmentStatusRejectionReasonMapping) {
      temp.options.allRejectionReasons = options.rejectionReasons
      temp.options.rejectionReasons = []
    }

    if (leadStatusDiscardedReasonMapping) {
      temp.options.allDiscardedReasons = options.discardedReason
      temp.options.discardedReason = []
    }

    return temp
  },
  [types.setRejectionReasonOptions]: (state, action) => {
    return {
      ...state,
      options: {
        ...state.options,
        rejectionReasons: action.payload
      }
    }
  },
  [types.setDiscardedReasonOptions]: (state, action) => {
    return {
      ...state,
      options: {
        ...state.options,
        discardedReason: action.payload
      }
    }
  }
}

export default {
  ...reducer,
  ...tableDecorators.generateReducer(types.tasks, 'tasks'),
  ...tableDecorators.generateReducer(types.leads, 'leads')
}
