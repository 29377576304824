import {
  ADD_NEW_RECORD,
  DELETE_RECORD,
  UPDATE_FIELD,
  UPDATE_RECORD,
  UPDATE_PAYMENT_INFO
} from '../actions/bpDetailsActions'

export const bpDetailsReducer = function bpDetailsReducer(
  state = {},
  {type, payload}
) {
  const {section, recordIdx, fieldName, value} = payload
  switch (type) {
    case ADD_NEW_RECORD:
      return {
        ...state,
        [section]: [...state[section], value]
      }

    case DELETE_RECORD: {
      const deletedRow = state[section][recordIdx].id
        ? [{...state[section][recordIdx], markDeleted: true}]
        : []
      return {
        ...state,
        [section]: [
          ...state[section].slice(0, recordIdx),
          ...deletedRow,
          ...state[section].slice(recordIdx + 1)
        ]
      }
    }
    case UPDATE_RECORD:
      return {
        ...state,
        [section]: [
          ...state[section].slice(0, recordIdx),
          value,
          ...state[section].slice(recordIdx + 1)
        ]
      }
    case UPDATE_PAYMENT_INFO:
      return {
        ...state,
        metaInfo: {
          ...state.metaInfo,
          [fieldName]: value ? [value] : []
        }
      }
    case UPDATE_FIELD:
      return {
        ...state,
        [fieldName]: value
      }
    case 'UPDATE_PAYMENT_INFO':
      return {
        ...state,
        metaInfo: {
          ...state.metaInfo,
          [fieldName]: value ? [value] : []
        }
      }
    default:
      return state
  }
}
