import React, {useState, useContext, useEffect} from 'react'
import cx from 'classnames'
import update from 'immutability-helper'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import DialogTemplate from '../../../../components/Dialog/Dialog'
import RowDescription from '../../../../components/Form/RowDescription'
import ServiceOpeningHoursRow from './ServiceOpeningHoursRow'
import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import GlobalServiceConfiguration from './GlobalServiceConfiguration'

import {GlobalContext} from '../../../../context/GlobalContext'

import {DAYS, serviceTypes} from '../../../../config/constants'
import Service from '../../../../types/Service'
import ServiceConfiguration from '../../../../types/ServiceConfiguration'
import ServiceType from '../../../../types/ServiceType'

import {addDefaultOpeningTimes, returnServiceConfiguration} from '../utils'
import ServicesObject from '../../../../types/ServicesObject'
import ConfirmationDialog from '../ConfirmationDialog'

const defaultService: Service = {
  name: '',
  key: '',
  type: [],
  openingHours: addDefaultOpeningTimes()
}

interface SPProps {
  isMaster: boolean
  selectedService?: Service
  services: ServicesObject
  open: boolean
  onCancel: () => void
  onSave: (service: Service, deleted?: boolean, closeExisting?: boolean) => void
  saveServiceConfiguration: (
    configuration: ServiceConfiguration,
    withConfirmation: boolean
  ) => void
}

const ServicePicker = ({
  isMaster = true,
  selectedService,
  services,
  open,
  onCancel = () => {},
  onSave = () => {},
  saveServiceConfiguration = () => {}
}: SPProps) => {
  const classes = useCommonStyles()
  const [
    hasServiceConfigurationChanged,
    setHasServiceConfigurationChanged
  ] = useState(false)
  const [hasServiceDataChanged, setHasServiceDataChanged] = useState(false)
  const [serviceTypeChanged, setServiceTypeChanged] = useState(false)
  const {globalServicesConfiguration} = useContext(GlobalContext)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [service, setService] = useState<Service>(
    selectedService || defaultService
  )
  const [
    configuration,
    setServiceConfiguration
  ] = useState<ServiceConfiguration | null>(
    isMaster
      ? returnServiceConfiguration(
          globalServicesConfiguration,
          selectedService?.type
        )
      : null
  )

  useEffect(() => {
    setServiceTypeChanged(!service.openingHours[1][0].id)
  }, [])

  const handleDayOpeningDataChange = (rowKey: number, value: any) => {
    setHasServiceDataChanged(true)
    const updatedService = update(service, {
      openingHours: {
        [rowKey]: {$set: value}
      }
    })
    setService(updatedService)
  }

  const handleGlobalServiceConfigurationChange = (
    field: keyof ServiceConfiguration,
    value: number | string
  ) => {
    setHasServiceConfigurationChanged(true)
    setServiceConfiguration((prevConfiguration) => {
      return {
        ...prevConfiguration,
        [field]: value
      }
    })
  }

  const handleConfirm = (confirmed = false) => {
    const newKey = service.type.join('_')
    if (!confirmed && newKey !== selectedService?.key && services[newKey]) {
      setShowConfirmation(true)
    } else {
      if (hasServiceConfigurationChanged || isMaster) {
        const data = {
          ...configuration!,
          type: service.type
        }
        saveServiceConfiguration(data, !hasServiceDataChanged)
      }
      //if a service type changes for an existing service,
      // we need to closed existing one first and create the new one.
      if (hasServiceDataChanged || isMaster) {
        onSave(service, serviceTypeChanged, serviceTypeChanged)
      }
    }
  }

  const setServiceType = (services: ServiceType[]) => {
    //if a service type changes for an existing service,
    // we need to closed existing one first and create the new one.
    const newKey = services.sort().join('_')
    setServiceTypeChanged(!!service.name && service.key !== newKey)
    setHasServiceDataChanged(true)

    services.sort()

    const updatedService = {
      ...service,
      type: services,
      key: !!service.name ? service.key : newKey
    }
    setService(updatedService)
    if (isMaster) {
      if (services.length > 1) {
        setHasServiceConfigurationChanged(false)
      } else {
        setServiceConfiguration(
          returnServiceConfiguration(globalServicesConfiguration, services)
        )
      }
    }
  }

  const title = selectedService
    ? `Edit ${selectedService.name} Service`
    : 'Add a Service'

  return (
    <>
      <DialogTemplate
        open={open}
        title={title}
        onCancel={onCancel}
        onConfirm={() => handleConfirm()}
        confirmLabel='SAVE'
        dataTest='save-service'
        //only validation is needed to be checked to continue with save.
        disabled={
          (!hasServiceDataChanged && !configuration) ||
          (!!configuration &&
            !hasServiceConfigurationChanged &&
            !hasServiceDataChanged) ||
          (!!configuration &&
            hasServiceConfigurationChanged &&
            !configuration.maxAllowedTime)
        }
        maxWidth='md'
      >
        <div className={classes.borderBottom}>
          <RowDescription title='General settings' />
          <FormControl className={cx(classes.fullWidth, classes.marginBottom2)}>
            <InputLabel>Services</InputLabel>
            <Select
              fullWidth
              multiple
              value={service.type}
              input={<Input />}
              onChange={(e: any) => setServiceType(e.target.value)}
              renderValue={(selected: string[]) =>
                selected
                  .map((type) => serviceTypes[type as ServiceType])
                  .join(', ')
              }
              dataTest='service-types-field'
            >
              {Object.entries(serviceTypes).map((option) => (
                <MenuItem key={option} value={option[0]}>
                  <Checkbox
                    color='primary'
                    checked={
                      (service.type || []).indexOf(option[0] as ServiceType) >
                      -1
                    }
                  />
                  <ListItemText primary={option[1]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.marginTop2}>
          <RowDescription
            title='Service time'
            description='When does the service run?'
          />
          <form className={classes.pickerFormWidth}>
            {DAYS.map((day) => {
              return (
                <ServiceOpeningHoursRow
                  serviceType={service.type}
                  key={day}
                  rowKey={day}
                  rowData={service.openingHours[day]}
                  onChange={handleDayOpeningDataChange}
                />
              )
            })}
            {isMaster && service.type.length < 2 && (
              <GlobalServiceConfiguration
                configuration={configuration!}
                onChange={handleGlobalServiceConfigurationChange}
              />
            )}
          </form>
        </div>
      </DialogTemplate>
      {showConfirmation && (
        <ConfirmationDialog
          open={showConfirmation}
          sendResponse={(confirmed) => {
            confirmed ? handleConfirm(true) : onCancel()
          }}
          service={service.type.join('-')}
          type='override'
        />
      )}
    </>
  )
}

export default ServicePicker
