import {put, call, takeLatest, select, all} from 'redux-saga/effects'
import * as CarApi from '../../../api/car/requests'
import * as AuthApi from '../../../api/auth/requests'
import * as TicketApi from '../../../api/ticket/requests'
import {
  setCars,
  createTicketSuccess,
  createTicketError,
  setFilters
} from './actions'
import * as types from './types'
import {
  getSelectedPurchases,
  getDealerId,
  getStatusFilters,
  getDealer,
  getCarIdFilter
} from './selectors'
import {getCountryCode} from '../../config/selectors'
import gql from '@fcg/lib-gql'
import {documentList} from '../../documentConfig/selectors'
import {showErrorMessage, showSuccessMessage} from '../common/sagas.js'
import {getTicketEntitiesParams} from './helpers'
import {fetchCurrentPage} from '../list/actions'
import {canCreatePayslips} from '../list/selectors'
import {TICKET_TYPES_ENUM} from '../../../config/entities/ticket'

export function* setDealerSaga(action) {
  try {
    if (!action.payload.id) {
      return
    }

    const statusFilters = yield select(getStatusFilters)
    const carIdFilter = yield select(getCarIdFilter)

    let cars = []
    let dealer = ''

    const query = {
      soldTo: action.payload.id,
      status: statusFilters,
      internalId: carIdFilter,
      limit: 100
    }

    if (!carIdFilter) {
      delete query.internalId
    }

    try {
      const [carsResponse, dealerResponse] = yield all([
        call(CarApi.getCarPayments, query),
        call(AuthApi.getDealer, action.payload.id)
      ])

      cars = carsResponse.car.list
      dealer = Array.isArray(dealerResponse.user.list)
        ? dealerResponse.user.list[0].internalId
        : ''
      // ignore errors, values have default values
      // eslint-disable-next-line no-empty
    } catch (ignore) {}

    yield put(setCars(cars))
    yield put({type: types.setDealerInternalId, payload: dealer})
  } catch (error) {
    yield showErrorMessage(error)
  }
}

export function* attemptTicketCreation(action) {
  try {
    const canCreate = yield select(canCreatePayslips)

    if (!canCreate) {
      return
    }

    const {t, documentMetaInfo, documentsWithErrors} = action.payload

    const [selectedPurchases, documents, dealerInfo] = yield all([
      select(getSelectedPurchases),
      select(documentList),
      select(getDealer)
    ])

    if (!dealerInfo || !dealerInfo.dealer) {
      throw new Error(t('payslips.error.noDealerSelected'))
    }

    if (!documents || !documents.length) {
      throw new Error(t('payslips.error.noDocumentsAdded'))
    }

    if (!selectedPurchases || !selectedPurchases.length) {
      throw new Error(t('payslips.error.noPurchasesSelected'))
    }

    // Show error if there are some documents without payment date
    if (Object.keys(documentsWithErrors).length) {
      throw new Error(t('payslips.error.missingDocumentDates'))
    }

    const createTicketParams = {
      selectedPurchases,
      documents,
      dealerInfo,
      documentMetaInfo
    }

    yield put({type: types.createTicket})
    yield call(createTicket, createTicketParams)
  } catch (error) {
    yield showErrorMessage(error)
    yield put(createTicketError(error))
  }
}

export function* createTicket(createTicketParams) {
  try {
    const countryCode = yield select(getCountryCode)
    const newTicketParams = {
      title: '',
      country: new gql.EnumType(countryCode),
      type: TICKET_TYPES_ENUM.PAYMENT_PROOF,
      entities: getTicketEntitiesParams(createTicketParams)
    }

    yield call(TicketApi.createTicket, newTicketParams)

    yield showSuccessMessage()
    yield put(createTicketSuccess())
    yield put(fetchCurrentPage())
  } catch (error) {
    yield showErrorMessage(error)
    yield put(createTicketError(error))
  }
}

export function* onFilterChange(action) {
  try {
    yield put(setFilters(action.payload))

    const dealerId = yield select(getDealerId)

    yield call(setDealerSaga, {payload: {id: dealerId}})
  } catch (error) {
    yield showErrorMessage(error)
  }
}

export const sagas = [
  takeLatest(types.setDealer, setDealerSaga),
  takeLatest(types.attemptTicketCreation, attemptTicketCreation),
  takeLatest(types.onFilterChange, onFilterChange)
]
