import {Component} from 'react'
import {Grid} from '@material-ui/core'
import {FormBuilder} from '@fcg/form-builder'

import Handlers from '../Handlers'
import {ConfigContext} from '../../../components/providers/withConfig'

export class Edit extends Component {
  static displayName = 'Lead Editor'

  static defaultProps = {
    creation: false
  }

  render() {
    const {data, options, t} = this.props
    const handlers = Handlers(this.props)

    if (typeof data === 'undefined' || data === null) {
      return null
    }

    Object.keys(data).forEach((key) => {
      data[key] = data[key] === null ? '' : data[key]
    })
    // console.log('lead me', options)
    return (
      <Grid container spacing={16} style={{paddingTop: '24px'}}>
        <ConfigContext.Consumer>
          {(config) => (
            <FormBuilder
              t={t}
              data={{...data}}
              options={options}
              form={this.props.config.form}
              setUserOptions={this.props.setUserOptions}
              users={this.props.users}
              onChangeHandlers={{
                handleChange: handlers.handleChange,
                handleSelectChange: handlers.handleSelectChange,
                handleTextInput: handlers.handleTextInput,
                handleResponsible: handlers.handleResponsible,
                handleNumberInput: handlers.handleNumberInput
              }}
              ignoreEmptyDependencies
              config={{...config, ...this.props.config}}
            />
          )}
        </ConfigContext.Consumer>
      </Grid>
    )
  }
}

export default Edit
