import {call, takeLatest, put, select, takeEvery} from 'redux-saga/effects'
import gql from '@fcg/lib-gql/gql'
import omit from 'lodash/omit'
import * as types from './types'
import {convertFileDataToArray} from '../utils/convertFileDataToArray'
import {getCountryConfig} from '../../../config/country'
import {getCountryCode} from '../config/selectors'
import {types as resourceTypes} from '../resources'
import * as selectors from './selectors'
import * as carApi from '../../api/car/requests'
import * as orderApi from './../../api/order/requests'
import * as searchApi from '../../api/search/requests'
import validator from './validator'
import {
  BULK_UPLOAD_NOT_VALID,
  BULK_UPLOAD_VALID,
  BULK_UPLOAD,
  NOT_VALID,
  BULK_UPLOAD_DUPLICATE,
  CREATE_CAR_PRIMARY_FIELD,
  THIRD_STEP
} from '../../pages/BulkUpload/constants'
import {getAA1LegacyRoute} from '../../utils/navigation'

const formatPaymentforApi = (car) => {
  const {price, additionalFees} = car
  const {bulkuploadDetails} = getCountryConfig()
  const {hiddenColumns = []} = bulkuploadDetails
  const payments = []

  payments.push({
    type: 'PURCHASE',
    subtype: 'Car',
    amount: price
  })

  if (hiddenColumns && !hiddenColumns.includes('additionalFees')) {
    if (additionalFees !== null && typeof additionalFees !== 'undefined') {
      payments.push({
        type: 'PURCHASE',
        subtype: bulkuploadDetails.feeType,
        amount: additionalFees
      })
    }
  }

  return payments
}

const formatDataforApi = (car, details) => {
  const countryConfig = getCountryConfig()
  const carData = omit(car, 'price')
  const payments = formatPaymentforApi(car)
  const location = []

  if (details.currentLocation) {
    location.push({
      locationType: new gql.EnumType(details.currentLocation.type),
      location: details.currentLocation.label,
      placeId: details.currentLocation.id
    })
  }

  if (details.purchaseLocation) {
    location.push({
      locationType: new gql.EnumType(details.purchaseLocation.type),
      location: [details.purchaseLocation.label],
      placeId: [details.purchaseLocation.id]
    })
  }

  const contacts = {
    type: details.contactType && new gql.EnumType(details.contactType),
    subtype: details.contactSubType,
    bankName: details.bankName,
    company: details.company,
    bankCode: details.bankCode,
    bankAccountOwner: details.bankAccountOwner,
    bankAccountNumber: details.bankAccountNumber,
    firstName: details.firstName,
    lastName: details.lastName,
    email: details.email,
    phone: details.phone,
    street: details.street,
    zip: details.zip,
    city: details.city,
    taxId: details.taxId,
    bankAccountOwnerTaxId: details.bankAccountOwnerTaxId
  }

  const financialDetails = {
    purchaseChannel: details.purchaseChannel,
    boughtAt: details.boughtAt || null,
    b2bDeal: details.B2BDeal || null,
    purchasedBy: details.purchasedBy
  }

  if (location.length) {
    return {
      ...omit(carData, ['cmsLink', 'duplicateInternalds', 'additionalFees']),
      type: details.carType,
      financialDetails: {
        ...financialDetails,
        carPricing: payments
      },
      ...(!countryConfig.isMTCMSEnabled ? {contacts: [{...contacts}]} : ''),
      location
    }
  }

  return {
    ...carData,
    type: details.carType,
    financialDetails: {
      ...financialDetails,
      carPricing: payments
    },
    ...(!countryConfig.isMTCMSEnabled ? {contacts: [{...contacts}]} : '')
  }
}

function* fetchBulkUploadDataSaga(action) {
  try {
    const countryConfig = getCountryConfig()
    const {bulkuploadDetails = {}} = countryConfig
    const {hiddenColumns = []} = bulkuploadDetails
    const columns = countryConfig.bulkUpload.bulkUploadColumn.filter(
      (column) => column !== 'cmsLink' && !hiddenColumns.includes(column)
    )
    const data = yield call(convertFileDataToArray, action.payload, columns)
    const carValidation = getCountryConfig().validation.carCreate
    const validCars = []
    const notValidCars = []

    data.map((car, index) => {
      car.id = `car-${index}`
      car = validator(car, carValidation)

      if (car.errors) {
        notValidCars.push(car)
      } else {
        validCars.push(car)
      }
    })

    data.map((car, index) => (car.id = `car-${index}`))

    yield put({
      type: resourceTypes.mergeResources,
      payload: {
        data: {
          [BULK_UPLOAD]: {
            list: validCars
          }
        },
        options: {
          type: BULK_UPLOAD,
          name: BULK_UPLOAD_VALID
        }
      }
    })

    yield put({
      type: resourceTypes.mergeResources,
      payload: {
        data: {
          [BULK_UPLOAD]: {
            list: notValidCars
          }
        },
        options: {
          type: BULK_UPLOAD,
          name: BULK_UPLOAD_NOT_VALID
        }
      }
    })

    yield put({
      type: types.fetchBulkUploadDataListSuccess
    })
  } catch (error) {
    yield put({
      type: types.uploadDataError,
      payload: error
    })
  }
}

function* bulkUploadRequestSaga() {
  try {
    const countryConfig = getCountryConfig()
    const createdCount = yield select(selectors.getCreatedCount)
    const notCreatedCount = yield select(selectors.getNotCreatedCount)
    const uploadListType =
      createdCount || notCreatedCount
        ? selectors.getBulkUploadNotValidDataList
        : selectors.getBulkUploadValidDataList
    const uploadList = yield select(uploadListType)
    const countryCode = yield select(getCountryCode)
    const carDetails = yield select(selectors.getFormDataForApi)

    yield put({
      type: types.resetBulkUpload,
      payload: true
    })

    let selectedB2B_BP, selectedB2BBPId

    if (countryConfig.isMTCMSEnabled) {
      selectedB2B_BP = yield select(selectors.getSelectedB2BBusinessPartner)
      selectedB2BBPId = selectedB2B_BP && selectedB2B_BP.businessPartnerId
    }

    for (const car in uploadList) {
      const createCarResponse = yield call(
        uploadCarSaga,
        {...uploadList[car], country: new gql.EnumType(countryCode)},
        carDetails
      )
      if (
        countryConfig.isMTCMSEnabled &&
        createCarResponse?.financialDetails?.id
      ) {
        yield call(
          createOrderContact,
          createCarResponse.financialDetails.id,
          selectedB2BBPId
        )
      }
    }

    yield put({
      type: types.saveBulkUploadSuccess
    })

    yield put({
      type: types.updateStep,
      payload: THIRD_STEP
    })
  } catch (error) {
    yield put({
      type: types.saveBulkUploadError,
      payload: error
    })
  }

  yield put({
    type: types.updateBulkUploadListVisibilityType,
    payload: NOT_VALID
  })
}

function* createOrderContact(orderId, bpId) {
  try {
    const resp = yield call(orderApi.createOrderContactWithBPId, orderId, bpId)

    return resp
  } catch (err) {
    return null
  }
}

function* uploadCarSaga(car, carDetails) {
  try {
    const data = omit(car, ['id', '_resourceType', 'errors'])
    const {id} = car
    const locale = yield select(selectors.getLocale)
    const formattedData = formatDataforApi(data, carDetails)
    const {createCar} = yield call(carApi.createCar, {...formattedData})
    const cmsLink = getAA1LegacyRoute(`/${locale}/car/edit/${createCar.id}`)
    const newCar = {
      ...car,
      mainId: createCar.id,
      cmsLink,
      financialDetails: createCar.financialDetails
    }

    yield put({
      type: types.updateSuccessRequestCounter,
      payload: id
    })

    yield put({
      type: resourceTypes.deleteDataFromCollection,
      payload: {
        ids: [car.id],
        resourceType: BULK_UPLOAD
      }
    })

    yield put({
      type: resourceTypes.mergeResources,
      payload: {
        data: {
          [BULK_UPLOAD]: {
            list: [newCar]
          }
        },
        options: {
          type: BULK_UPLOAD,
          name: BULK_UPLOAD_VALID
        }
      }
    })

    return newCar
  } catch (error) {
    yield put({
      type: resourceTypes.deleteDataFromCollection,
      payload: {
        ids: [car.id],
        resourceType: BULK_UPLOAD
      }
    })

    yield put({
      type: resourceTypes.mergeResources,
      payload: {
        data: {
          [BULK_UPLOAD]: {
            list: [
              {
                ...car,
                errors: {
                  message: error.message
                }
              }
            ]
          }
        },
        options: {
          type: BULK_UPLOAD,
          name: BULK_UPLOAD_NOT_VALID
        }
      }
    })

    yield put({
      type: types.updateFailRequestCounter,
      payload: car.id
    })

    return null
  }
}

function* checkDuplicateCarsSaga() {
  try {
    const uploadList = yield select(selectors.getBulkUploadValidDataList)
    let duplicateCars = []

    for (const car in uploadList) {
      yield call(searchCarSaga, {uploadList: [...uploadList], carIndex: car})
    }

    yield put({
      type: types.checkDuplicationSuccess,
      payload: true
    })

    duplicateCars = yield select(selectors.getDuplicateBulkUploadDataListIds)

    if (duplicateCars.length === 0) {
      yield put({
        type: types.saveBulkUploadRequest
      })
    }
  } catch (error) {
    return null
  }
}

function* createDuplicateCarsSaga(action) {
  try {
    if (action.payload.length > 0) {
      yield put({
        type: resourceTypes.mergeResources,
        payload: {
          data: {
            [BULK_UPLOAD]: {
              list: action.payload
            }
          },
          options: {
            type: BULK_UPLOAD,
            name: BULK_UPLOAD_VALID
          }
        }
      })
    }

    yield put({
      type: types.saveBulkUploadRequest
    })
  } catch (e) {
    return null
  }
}

function* searchCarSaga({uploadList, carIndex}) {
  try {
    const car = uploadList[carIndex]
    const countryConfig = getCountryConfig()
    const duplicateField = countryConfig[CREATE_CAR_PRIMARY_FIELD]
    const duplicateCars = yield call(searchApi.searchCars, {
      [duplicateField]: car[duplicateField]
    })
    const cmsDuplicateLinks = []
    const locale = yield select(selectors.getLocale)

    if (duplicateCars.car && duplicateCars.car.list.length > 0) {
      duplicateCars.car.list.forEach((car) => {
        cmsDuplicateLinks.push({
          cmsLink: getAA1LegacyRoute(`/${locale}/car/edit/${car.id}`),
          internalId: car.internalId
        })
      })

      yield put({
        type: resourceTypes.deleteDataFromCollection,
        payload: {
          ids: [car.id],
          resourceType: BULK_UPLOAD
        }
      })

      yield put({
        type: resourceTypes.mergeResources,
        payload: {
          data: {
            [BULK_UPLOAD]: {
              list: [
                {
                  ...car,
                  duplicateInternalds: cmsDuplicateLinks
                }
              ]
            }
          },
          options: {
            type: BULK_UPLOAD,
            name: BULK_UPLOAD_DUPLICATE
          }
        }
      })
    }
  } catch (error) {
    return null
  }
}

export const sagas = [
  takeLatest(types.fetchBulkUploadDataList, fetchBulkUploadDataSaga),
  takeLatest(types.saveBulkUploadRequest, bulkUploadRequestSaga),
  takeEvery(types.checkDuplication, checkDuplicateCarsSaga),
  takeEvery(types.uploadCar, uploadCarSaga),
  takeEvery(types.searchCar, searchCarSaga),
  takeEvery(types.createDuplicateCars, createDuplicateCarsSaga)
]
