import * as types from './types'
import * as authTypes from '../auth/types'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'
import {getInitialState} from './initialState'
import {
  useWith,
  find,
  compose,
  propEq,
  path,
  values,
  map,
  reduce,
  addIndex,
  set,
  lensPath,
  pathEq
} from 'ramda'
import get from 'lodash/get'

const reduceWithIndex = addIndex(reduce)
const findByEntityId = (entity) => find(pathEq(['id'], entity.entityId))
const updatedTicketWithData = ({documentData}) =>
  compose(
    map((entity) => {
      const foundDocData = findByEntityId(entity)(documentData)

      if (foundDocData) {
        entity.data = foundDocData
      }

      return entity
    }),
    path(['ticketEntity'])
  )
const updateDocumentCategory = (documentData) =>
  compose(
    map((entity) => {
      if (entity.entityId === documentData.id) {
        entity.data.category = documentData.category
      }

      return entity
    }),
    path(['ticketEntity'])
  )

export const reducerFns = {
  [types.fetchDataStart]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: true,
      carPaymentReceiptsSelection: {},
      expandedRows: [],
      rowsFetching: [],
      rowsFetchingErrors: []
    }
  }),
  [types.fetchDataSuccess]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      data: action.payload.list,
      count: action.payload.count
    }
  }),
  [types.fetchRowDataError]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false
    }
  }),
  [types.fetchDataError]: (state) => ({
    ...state,
    list: {
      ...state.list,
      data: [],
      count: 0,
      fetching: false,
      carPaymentReceiptsSelection: {},
      expandedRows: [],
      rowsFetching: [],
      rowsFetchingErrors: []
    }
  }),
  [types.updateTicketEntitySuccess]: (state, action) => {
    const ticket = useWith(find, [
      compose(propEq('id'), path(['payload', 'ticketId'])),
      compose(values, path(['list', 'data']))
    ])(action, state)
    const entities = ticket.ticketEntity
    const {
      id,
      ticketId,
      status,
      updatedAt,
      updatedById,
      updatedByName
    } = action.payload

    const updatedEntities = entities.map((entity) => {
      if (entity.id === id) {
        return {
          ...entity,
          status,
          updatedAt,
          updatedById,
          updatedByName
        }
      }

      return entity
    })

    const updatedData = compose(
      reduceWithIndex(
        (acc, tally, index) => ({
          ...acc,
          [index]: tally
        }),
        {}
      ),
      map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            ticketEntity: updatedEntities
          }
        }

        return ticket
      }),
      values,
      path(['list', 'data'])
    )(state)

    return {
      ...state,
      list: {
        ...state.list,
        data: updatedData
      }
    }
  },
  [types.toggleRowSelection]: (state, action) => {
    const rowId = get(action, ['payload', 'id'])
    const expandedRows =
      !rowId || state.list.expandedRows.includes(rowId)
        ? []
        : [action.payload.id]

    return {
      ...state,
      updateInProgress: false,
      updateCompleted: false,
      list: {
        ...state.list,
        expandedRows
      }
    }
  },
  [types.fetchRowData]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsFetching: [...state.list.rowsFetching, action.payload],
      rowsFetchingErrors: state.list.rowsFetchingErrors.filter(
        (status) => status.id !== action.payload.id
      )
    }
  }),
  [types.fetchRowDataSuccess]: (state, action) => {
    const updatedData = compose(
      reduceWithIndex(
        (acc, ticket, index) => ({
          ...acc,
          [index]: set(
            lensPath(['ticketEntity']),
            updatedTicketWithData({
              documentData: action.payload.documentData
            })(ticket),
            ticket
          )
        }),
        {}
      ),
      values
    )(state.list.data)
    const dataLens = lensPath(['list', 'data'])
    const rowFetchLens = lensPath(['list', 'rowsFetching'])

    return compose(
      set(dataLens, updatedData),
      set(
        rowFetchLens,
        state.list.rowsFetching.filter(
          (status) => status.id !== action.payload.id
        )
      )
    )(state)
  },
  [types.fetchRowDataError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsFetching: state.list.rowsFetching.filter(
        (status) => status.id !== action.payload.id
      ),
      rowsFetchingErrors: [...state.list.rowsFetchingErrors, action.payload]
    }
  }),
  [types.updateTicketStart]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsUpdating: [...state.list.rowsUpdating, action.payload],
      rowUpdateErrors: state.list.rowUpdateErrors.filter(
        ({id}) => id !== action.payload
      )
    }
  }),
  [types.updateTicketSuccess]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsUpdating: state.list.rowsUpdating.filter(
        (id) => id !== action.payload
      )
    }
  }),
  [types.updateTicketError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsUpdating: state.list.rowsUpdating.filter(
        (id) => id !== action.payload.id
      ),
      rowUpdateErrors: [...state.list.rowUpdateErrors, action.payload]
    }
  }),
  [types.createTicketEntityStart]: (state) => ({
    ...state,
    documents: {
      isUploading: true,
      uploaded: false,
      error: null
    }
  }),
  [types.createTicketEntitySuccess]: (state) => ({
    ...state,
    documents: {
      ...state.documents,
      isUploading: false,
      uploaded: true,
      error: null
    }
  }),
  [types.createTicketEntityError]: (state, action) => ({
    ...state,
    documents: {
      isUploading: false,
      uploaded: false,
      error: action.payload
    }
  }),
  [types.clearDocumentDialog]: (state) => ({
    ...state,
    documents: {
      isUploading: false,
      uploaded: false,
      error: null
    }
  }),
  [authTypes.logout]: (state) => ({
    ...state,
    list: {
      ...state.list,
      data: [],
      carPaymentReceiptsSelection: {},
      expandedRows: [],
      rowsFetching: [],
      rowsFetchingErrors: []
    }
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, filters: action.payload}
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    options: {
      ...state.options,
      ...action.payload
    }
  }),
  [types.updateDocumentCategorySuccess]: (state, action) => {
    const ticketEntityPath = lensPath(['ticketEntity'])
    const dataLens = lensPath(['list', 'data'])
    const updatedData = compose(
      reduceWithIndex(
        (acc, ticket, index) => ({
          ...acc,
          [index]: set(
            ticketEntityPath,
            updateDocumentCategory(action.payload)(ticket),
            ticket
          )
        }),
        {}
      ),
      values
    )(state.list.data)

    return set(dataLens, updatedData, state)
  }
}

export const reducers = {
  ...reducerFns,
  ...tableDecorators.withDataLoading(types.dataLoadingTypes, 'list'),
  ...tableDecorators.withSingleSort(types.sortTypes, 'list'),
  ...tableDecorators.withColumnVisibility(types.columnTypes, 'list'),
  ...formDecorators.generateReducer(types.formTypes, {
    getInitialState
  })
}

export default reducers
