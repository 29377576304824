import {SimpleApiClient} from '@fcg/admin-api-client'
import getQueryString from '../helpers/queryParamUtil'
import {getAdditionalHeaders} from './utils'
import Country from '../types/Country'
import { StoreObject } from '../types'

export const getAllstores = (
    countryCode: Country,
    callBack: (data: {status: Number,data: Array<StoreObject>}) => void,
    filter?: {columName: string, value: string}
) => {
  const params = filter? {
    filter: `${filter.columName}:${filter.value}`
  }: {}

  const downloadClient = new SimpleApiClient('logistics', getAdditionalHeaders(countryCode))
  return downloadClient
    .query(`store/v1?${getQueryString(params)}`, '', false, 'GET')
      .then((response: {status: Number, data: Array<StoreObject>}) => {
      callBack(response)
    })
}
