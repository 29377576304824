import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {SlideUp} from '../../../components/Animations/Slides'
import Form from './Edit'
import dialogStyles from '../common/dialogStyles'

import {
  createComment,
  updateComment,
  deleteComment,
  updateCommentForm,
  resetCommentForm,
  hydrateCommentForm
} from '../../../store/crm/comment/actions'

// [TODO]: No hardcoded options in component files

const priority = [
  {label: 'car.page.taskPriority.1', value: 1},
  {label: 'car.page.taskPriority.2', value: 2},
  {label: 'car.page.taskPriority.3', value: 3}
]

const commentType = [
  {label: 'entity.task', value: 'task'},
  {label: 'entity.comment', value: 'comment'}
]

const statusOptions = [
  {label: 'task.status.OPEN', value: 'OPEN'},
  {label: 'task.status.CLOSE', value: 'CLOSE'}
]

export class CommentDialog extends Component {
  static defaultProps = {
    creation: false
  }

  state = {users: {options: [], count: 0}}

  componentDidMount() {
    const {match, creation} = this.props

    if (!creation && match.params.id) {
      this.props.loadData({id: match.params.id})
    }

    if (this.props.type) {
      this.props.update('type')(this.props.type)
    }
  }

  handleCancel = () => {
    this.props.reset()
    this.props.handleClose()
  }

  componentDidCatch(e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  componentWillUnmount() {
    this.props.reset()
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {ids, entity, match, creation, data} = this.props

    if (!creation && match.params.id) {
      this.props.save({data, id: match.params.id}, this.props.handleSubmit)
    } else {
      this.props.create({entity, ids, ...data}, this.props.handleSubmit)
    }
  }

  render() {
    const {t, creation, type, editState} = this.props
    const dialogTitle =
      !!creation === true
        ? `${t('global.action.create')} ${t(`entity.${type}`)}`
        : `${t('global.action.update')} ${t(`entity.${type}`)}`
    const buttonText =
      !!creation === true
        ? t('global.action.create')
        : t('global.action.update')

    if (editState.success === true) {
      this.props.handleSubmit()
      this.props.reset()
    }

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          onClose={this.props.handleClose}
          TransitionComponent={SlideUp}
          maxWidth='sm'
          fullWidth
        >
          <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
          <DialogContent>
            {editState.error !== false && (
              <Typography variant='body2' color='error'>
                {t('form.message.error.occurred')}
              </Typography>
            )}
            <Form
              {...this.props}
              type={type}
              users={this.state.users}
              setUserOptions={this.setUserOptions}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color='primary'>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='raised'
              disabled={editState.pending === true}
              onClick={this.handleSubmit}
              color='primary'
            >
              {buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({comment, crm, config: {crmDetails}}) => ({
  data: omit(comment.form, omit(crmDetails.hidden, ['type'])),
  editState: comment.editState,
  options: {
    commentType,
    subtypeOptions:
      comment.form.type === 'comment'
        ? crm.options.CRMCommentSubtype || []
        : crm.options.CRMTaskType || [],
    priority,
    statusOptions
  }
})

const mapDispatchToProps = (dispatch) => ({
  update: (name) => (value) => dispatch(updateCommentForm({name, value})),
  create: (params, next) => dispatch(createComment(params, next)),
  save: ({id, data}, next) => dispatch(updateComment(id, data, next)),
  delete: (id) => dispatch(deleteComment(id)),
  reset: () => dispatch(resetCommentForm()),
  loadData: ({id}) => dispatch(hydrateCommentForm({id}))
})

const CommentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CommentDialog))

export default withMobileDialog()(withStyles(dialogStyles)(CommentForm))
