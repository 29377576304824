import React from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import {formatServiceOpeningTimesString} from './utils'
import Service from '../../../types/Service'
import ServiceType from '../../../types/ServiceType'

const tooltipTitles: {[key in ServiceType]?: string} = {
  INSPECTION: 'Inspection times display the opening times on the website',
  INSPECTIONCRM:
    'Walk-in times define flexible opening times for the CRM (INSPECTIONCRM)'
}

const infoStyle = {
  height: '16px',
  width: '16px',
  color: '#fff',
  backgroundColor: '#000',
  opacity: '0.6',
  borderRadius: '50%',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '14px',
  alignSelf: 'center'
}

const ServiceRow = ({
  service,
  onSelect = () => {}
}: {
  service: Service
  onSelect: (name: string, deleteHoliday?: boolean) => void
}) => {
  const classes = useCommonStyles()
  if (!service) return null
  return (
    <Grid
      container
      className={cx(
        classes.borderBottom,
        classes.paddingY1,
        classes.hoverBackground,
        classes.itemsCenter,
        classes.paddingX2,
        classes.pointer
      )}
      id={service.type.join('-')}
      onClick={() => {
        onSelect(service.key)
      }}
      dataTest='service-row'
    >
      <Grid item xs={4} className={classes.flex}>
        {service.name}
        {service.type.length === 1 && tooltipTitles[service.type[0]] && (
          <Tooltip
            title={tooltipTitles[service.type[0]]}
            className={classes.marginLeft2}
          >
            <div style={infoStyle}>?</div>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={7}>
        <span
          style={{fontSize: '14px'}}
          className={(classes.marginRight1, classes.verticalMiddle)}
        >
          {formatServiceOpeningTimesString(service?.openingHours)}
        </span>
      </Grid>
      <Grid item xs={1} className={classes.textRight}>
        <IconButton
          aria-label='delete'
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            e.stopPropagation()
            onSelect(service.key, true) //deleteHoliday = true
          }}
          data-test='delete-holiday-btn'
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ServiceRow
