import {Component} from 'react'
import gql from '@fcg/lib-gql'

import withAsyncSource from '../providers/withAsyncSource'
import * as SearchApi from '../../api/search/requests'
import Select from '../Select/Select'
import {withValidation, validatorFields} from '@fcg/formvalidation'

const CarPicker = withAsyncSource({
  loadData: async function(query = {}) {
    if (!query.licensePlate && !query.anyName && !query.id) {
      return {
        options: {},
        count: 0
      }
    }

    const params = {
      limit: query.limit,
      page: query.page || 1,
      country: new gql.EnumType(query.country)
    }

    if (query.licensePlate || query.anyName) {
      params.licensePlate = query.licensePlate || query.anyName
    }

    if (query.id) {
      params.id = query.id
    }

    const response = await SearchApi.searchCarsByPlateId(params)

    const options =
      response && response.car && response.car.list ? response.car.list : {}

    return {
      options,
      count: response.car.count
    }
  },
  queryBuilder: (props) => {
    if (props.value) {
      return {
        licensePlate: props.value,
        limit: props.limit,
        country: props.country
      }
    }

    return {
      ...props
    }
  }
})(Select)

class ValidatedCarPickerField extends Component {
  onChange = (selected, label) => {
    const joinLabel = Array.isArray(label) ? label.join(', ') : label

    this.props.onChange({target: {value: selected, label: joinLabel}})
  }

  render() {
    return <CarPicker {...this.props} onChange={this.onChange} />
  }
}

export const ValidatedCarPicker = withValidation(validatorFields.select)(
  ValidatedCarPickerField
)

CarPicker.type = 'CarPicker'
ValidatedCarPickerField.type = 'CarPicker'
export default CarPicker
