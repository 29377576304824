import {Component} from 'react'
import {Slideshow} from '@fcg/image-viewer'
import ReactDOM from 'react-dom'

class Slide extends Component {
  constructor() {
    super()

    this.element = null
  }

  UNSAFE_componentWillMount() {
    if (!document.getElementById('app-portal')) {
      this.element = document.createElement('div')
      this.element.setAttribute('id', 'app-portal')
      document.body.appendChild(this.element)
    }
  }

  componentWillUnmount() {
    if (this.element) {
      this.element.remove()
    }
  }

  render() {
    return ReactDOM.createPortal(
      <Slideshow
        images={[this.props.image]}
        show
        onClose={this.props.onClose}
      />,
      document.getElementById('app-portal')
    )
  }
}

export default Slide
