import {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {translate} from 'react-i18next'

import {IconButton} from '@material-ui/core'
// import {getCountryConfig} from '../../../../../app/config/country'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import {UserConsumer} from '../../providers/withAccessManagement'
import {isOldEntity} from '../../../pages/CRM/helpers'

import {
  checkUserPermissions,
  checkUserManagerPermission
} from '../../../store/auth/selectors'
import {getHiddenPages} from '../../../store/config/selectors'
import omit from 'lodash/omit'
import {isPageHiddenByConfig} from '../../../../helper/isPageHidden'
import {CollapsibleSidebar, Drawer} from 'motofin-ui'

import './MainMenu.styl'
export class MainMenuBase extends Component {
  state = {
    componentsWithSubMenu: []
  }

  static defaultProps = {
    header: true
  }

  componentDidMount() {
    const {t, items} = this.props
    items.map((item) => {
      const {subItems} = item

      if (subItems && subItems.length > 0) {
        this.setState((prevState) => ({
          componentsWithSubMenu: [...prevState.componentsWithSubMenu, item.key]
        }))
      }
    })
  }

  transformItem = (item) => {
    const {t} = this.props
    const {label, key, subItems, path: parentPath} = item
    const updatedMenuItem = {
      id: key,
      icon: 'document',
      iconSize: 24,
      iconViewBox: '0 0 24 24',
      name: t(label),
      routePath: parentPath
    }
    let verticalSubOptions = []
    if (subItems && subItems.length > 0) {
      const menuChildren = subItems.filter((subItem) => {
        if (
          subItem.path === 'crmusergroups' &&
          !this.props.hasUserManagerPermission
        ) {
          return false
        }

        // Hide walkin for salesforce enabled countries
        if (
          subItem.label === 'menu.crm.walkin' &&
          !isOldEntity({createdAt: new Date()})
        ) {
          return false
        }

        return !(
          subItem.permissions && !this.props.hasPermissions(subItem.permissions)
        )
      })
      verticalSubOptions = menuChildren.map((subItem) => {
        const {label, path} = subItem

        return {
          id: `${label}_submenu`,
          icon: '',
          name: t(label),
          routePath: parentPath + '/' + path
        }
      })
    }

    updatedMenuItem['verticalSubOptions'] = verticalSubOptions

    return updatedMenuItem
  }

  listMenuItems = () => {
    const {items, hiddenFields, user} = this.props

    const shouldHideCategory = isPageHiddenByConfig('category')
    const itemsList = items.filter((item) =>
      item.key === 'category' ? !shouldHideCategory : true
    )
    const userHiddenFields =
      typeof user !== 'undefined' && user.menuItems
        ? user.menuItems.hiddenFields || []
        : []

    return itemsList
      .filter(
        (field) =>
          hiddenFields.indexOf(field.key) === -1 &&
          Array.from(userHiddenFields).indexOf(field.key) === -1
      )
      .filter(
        (item) =>
          !(item.permissions && !this.props.hasPermissions(item.permissions))
      )
      .map((item) => {
        // console.log('Item', item, user, hiddenFields)
        return this.transformItem(item)
      })
  }

  tabClickHandler = (hTab, tabSelected) => {
    const {open} = this.props
    const componentHasSubMenu = this.state.componentsWithSubMenu.find(
      (component) => component == tabSelected.id
    )

    if (open && !componentHasSubMenu) {
      if (tabSelected.routePath !== '') {
        this.props.history.push(`/${tabSelected.routePath}`)
      }
      this.props.handleClose()
    }
  }

  render() {
    const {open, user} = this.props

    if (typeof user === 'undefined') {
      return null
    }

    const menuItems = this.listMenuItems()

    return (
      <div className='MainMenu__container'>
        <Drawer
          anchor='left'
          variant='separateLayer'
          isOpen={open}
          wrapperClass={'drawerWrapper'}
          drawerClass={'drawerClass'}
          testId='menu-drawer'
        >
          <IconButton onClick={this.props.handleClose}>
            <ChevronLeft />
          </IconButton>
          <CollapsibleSidebar
            hasHorizontalTabs={false}
            verticalSelectedTabBackground
            verticalSelectedTabBackgroundColor={'backgroundWhite'}
            data={menuItems}
            onTabClick={this.tabClickHandler}
            autoSelectFirstSubOption={false}
            showVerticalTitle={true}
            sidebarClassName={'fruit'}
          />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {global} = state
  const {menuItems, appTitle} = global

  return {
    items: menuItems.allFields[0],
    hiddenFields: getHiddenPages(state),
    appTitle,
    hasPermissions: (permissions) => checkUserPermissions(state, permissions),
    hasUserManagerPermission: checkUserManagerPermission(state)
  }
}

const MainMenu = connect(mapStateToProps)(translate()(withRouter(MainMenuBase)))

export default (props) => UserConsumer(MainMenu, props)
