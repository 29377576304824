import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  fetchPreferenceList,
  deletePdfPreference,
  toggleSort,
  toggleField
} from '../../store/documentConfig/actions'
import {DeleteDialog} from '../CRM/common'
import {Button} from '@material-ui/core'
import {Add, Delete} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import {Link} from 'react-router-dom'
import * as selectors from '../../store/documentConfig/selectors'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

class DocumentConfigList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delete: {
        open: false,
        handleSubmit: () => {},
        handleCancel: () => {},
        entity: null
      }
    }
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, preferenceId, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(preferenceId)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  routeToPdfPreference = (e, preference) => {
    this.props.history.push(`document-config/edit/${preference.id}`)
  }

  render() {
    return (
      <div className='page'>
        <PageTableBase
          {...this.props}
          multisort={false}
          title='entity.documentConfig'
          onRowClick={this.routeToPdfPreference}
          rowsPerPageOptions={[25, 50, 100]}
          singleSelectActions={[
            {
              event: (item) => {
                this.openDeleteDialog({
                  entity: 'pdfPreference',
                  preferenceId: item.id,
                  action: this.props.deletePdfPreference
                })
              },
              icon: <Delete />,
              title: 'global.action.delete'
            }
          ]}
        />
        <ActionButton>
          <Button
            variant='fab'
            color='primary'
            component={Link}
            to={`${this.props.match.path}/create`}
          >
            <Add />
          </Button>
        </ActionButton>
        <DeleteDialog {...this.state.delete} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getPreferenceList(state),
  fetching: selectors.isFetching(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchPreferenceList()),
  deletePdfPreference: (preferenceId) =>
    dispatch(deletePdfPreference(preferenceId)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  toggleField: ({key}) => dispatch(toggleField(key))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(DocumentConfigList))
