import List from '../../../pages/CRM/Participant/List'

export default {
  label: 'menu.participants',
  key: 'participants',
  path: 'appointment/:id/participants',
  sort: [],
  limit: 25,
  filters: [],
  page: 1,
  component: List,
  fields: Object.values({
    role: {
      key: 'role',
      label: 'participant.column.role',
      type: 'select',
      isEditable: true,
      cursor: 'pointer',
      editableFieldType: 'select',
      alwaysEditable: true,
      filterable: false,
      withTranslate: true,
      withEndAdornment: true,
      disableClear: true,
      disableLabel: true,
      isSortable: false,
      fullWidth: false,
      disableUnderline: true,
      persistChoice: false,
      translateLabels: true
    },
    name: {
      key: 'name',
      type: 'composite',
      children: [
        {
          type: 'string',
          key: 'firstname',
          label: 'participant.column.name'
        },
        {
          type: 'string',
          key: 'lastname'
        }
      ]
    },
    email: {
      key: 'email',
      label: 'participant.column.email',
      type: 'string'
    },
    phone: {
      key: 'phone',
      label: 'participant.column.phone',
      type: 'string'
    }
  })
}
