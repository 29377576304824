const dealerDetails = require('./dealerManagement/ke')
const crmDetails = require('./CRM/ke')
const bulkuploadDetails = require('./bulkUpload/ke')
const carDataDetails = require('./carData/ke')
const locationDetails = require('./locationManagement/ke')
const payslipDetails = require('./payslips/ke')
const carOwnership = require('./carOwnership/ke')
const businessPartnersDetails = require('./businessPartnersDetails/ke')

module.exports = {
  url: 'cars45.co.ke',
  name: 'Kenya',
  countryCode: 'KE',
  theme: 'kenya',
  language: {
    selected: 'en-ke',
    options: [{value: 'en-ke', label: 'KE'}]
  },
  locales: ['en-ke'],
  currency: {code: 'KSh', symbol: 'KSh'},
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [9], code: 254},
  referralCodeType: 'input',
  createCarViaAppointment: true,
  googleKey:
    '876497148135-i4mm9mbecqul68vo6i81ggpj158kj49o.apps.googleusercontent.com',
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'vin',
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      vin: {
        required: true,
        regex: '^.{1,17}$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'category',
    'wms',
    'bulkUploadV2',
    'tms',
    'documentManagement',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
