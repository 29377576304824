import gql from '@fcg/lib-gql/gql'
import moment from 'moment'
import {getCountryConfig} from '../../../config/country'

const formatSlotOptions = (response) => {
  const slots = response.availableSlots.slots
  const format = getCountryConfig().dateFormat

  return slots.reduce((acc, item) => {
    const date = Object.keys(item)[0]
    const appointments = item[date].map((time) => {
      const timeParts = time.split(':')
      timeParts.splice(2)
      const timeLabel = timeParts.join(':')

      return {
        value: {date, time},
        label: `${moment(date).format(format)}: ${timeLabel}`
      }
    })

    acc = acc.concat(appointments)

    return acc
  }, [])
}

export function formatSlotService(request) {
  if (typeof request.type === 'undefined') {
    return request
  }

  request.type = new gql.EnumType(request.type)

  return request
}

export const formatSlotOptionsInterceptor = {
  response: (data) => formatSlotOptions(data)
}
