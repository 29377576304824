import * as types from './types'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'
import {getInitialState} from './initialState'

export default {
  [types.createTier]: (state) => ({
    ...state,
    editState: {
      error: false,
      success: false,
      pending: true
    }
  }),
  [types.createTierSuccess]: (state) => ({
    ...state,
    editState: {
      ...state.editState,
      success: true,
      pending: false
    }
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, filters: action.payload}
  }),
  ...tableDecorators.withDataLoading(types.dataLoadingTypes, 'list'),
  ...tableDecorators.generateReducer(types, 'list'),
  ...formDecorators.generateReducer(types.formTypes, {
    getInitialState
  })
}
