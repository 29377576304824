export const listMetaFields = [
  'groupBackgroundColor',
  'groupFontColor',
  'headerLogo',
  'footerLogo',
  'firstPageLogo',
  'lastPageLogo',
  'printCBE',
  'displayCBEAtStart'
]

const preference = ['id', 'name']

export const preferenceData = [
  'id',
  'name',
  'country',
  ['metaInfo', [...listMetaFields]]
]

export const fetchPreference = [['list', preferenceData]]

export const pdfPreferenceList = [['list', preference], 'count']

export const documentData = [
  'id',
  'category',
  'comment',
  'entity',
  'fileName',
  'fileType',
  'fileSize',
  'status',
  'createdByName',
  'visibility',
  'createdAt',
  'updatedAt',
  'updatedByName'
]

export const documentDownloadLink = ['id', 'link']

export const assetData = ['id', 'publicLink']

export const documentList = [['list', documentData], 'count']
