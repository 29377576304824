import {ApiClient} from '@fcg/admin-api-client'

import {
  formatSort,
  formatCountry,
  returnGlobalPlaceResponse,
  formatGlobalTemplateData,
  formatOpeningHoursRequest,
  formatServiceConfigurationRequest,
  returnServicesConfigurationResponse,
  returnOpeningTimesResponse,
  createLocationsServicesObject,
  formatCreateUpdateSpecialOpeningRequest,
  returnSpecialOpeningListResponse,
  returnSpecialOpeningResponse
} from '../helpers/transformHelpers'

import {
  globalTemplateFields,
  globalPlaceFields,
  globalSpecialOpeningFields,
  servicesConfigurationFields,
  specialOpeningListFields,
  specialOpeningFields,
  openingTimeFields
} from './fields'
import Country from '../../types/Country'
import OpeningTime from '../../types/OpeningTime'
import ServiceConfiguration from '../../types/ServiceConfiguration'
import HolidayObject from '../../types/HolidayObject'

const client = new ApiClient('booking')

export const getGlobalTemplate = (countryCode: Country) => {
  const data = {
    country: countryCode
  }

  return client
    .withTransform(formatCountry)
    .query('aggregateGlobalConfiguration', data, globalTemplateFields)
    .then(formatGlobalTemplateData)
}

export const createOrUpdateGlobalPlaceConfiguration = (
  data = {},
  requestType: string
) => {
  const mutation = `${requestType}GlobalPlaceConfiguration`

  return client
    .withTransform(formatCountry)
    .mutation(mutation, data, globalPlaceFields)
    .then(returnGlobalPlaceResponse)
}

//Global service configuration requests
export const createOrUpdateGlobalServicesConfiguration = (
  configuration: ServiceConfiguration,
  countryCode: Country
) => {
  const requestType = configuration.id ? 'update' : 'create'
  const mutation = `${requestType}GlobalServicesConfiguration`
  const requestData = formatServiceConfigurationRequest(
    configuration,
    countryCode
  )

  return client
    .mutation(mutation, requestData, servicesConfigurationFields)
    .then(returnServicesConfigurationResponse)
}

//Service requests
export const setOpeningTimes = (
  data: OpeningTime[],
  countryCode: Country,
  placeId: string
) => {
  const requestData = formatOpeningHoursRequest(data, countryCode, placeId)
  const requestType =
    placeId === 'master' ? 'setGlobalOpeningTime' : 'setOpeningTime'

  return client
    .mutation(requestType, requestData, openingTimeFields)
    .then(returnOpeningTimesResponse)
}

export const setInitialOpeningTimes = (
  data: OpeningTime[],
  countryCode: Country,
  placeId: string
) => {
  const requestData = formatOpeningHoursRequest(data, countryCode, placeId)

  return client.mutation('setOpeningTime', requestData, openingTimeFields)
}

export const getOpeningTimes = (data: {country: Country; placeId: string}) => {
  const params = {
    ...data,
    closed: false,
    sort: [
      {order: 'ASC', field: 'bookingType'},
      {order: 'ASC', field: 'weekday'}
    ]
  }

  return client
    .withTransform([formatCountry, formatSort])
    .query('openingTime', params, openingTimeFields)
    .then(createLocationsServicesObject)
}

//currently unused
export const deleteOpeningTime = (serviceOpeningTimes: any) => {
  const requestData = serviceOpeningTimes // some function here

  return client.mutation('deleteOpeningTime', requestData)
}

//Holiday requests
export const getSpecialOpenings = (data: {
  country: Country
  placeId: string
}) => {
  return client
    .withTransform(formatCountry)
    .query('specialOpeningTime', data, specialOpeningListFields)
    .then(returnSpecialOpeningListResponse)
}

export const createOrUpdateSpecialOpening = (
  isMaster: boolean,
  holidayData: HolidayObject,
  countryCode: Country
) => {
  const mutation = `${holidayData.id ? 'update' : 'create'}${
    isMaster ? 'Global' : ''
  }SpecialOpening`
  const requestData = formatCreateUpdateSpecialOpeningRequest(
    holidayData,
    countryCode
  )
  const fields = isMaster ? globalSpecialOpeningFields : specialOpeningFields

  return client
    .mutation(mutation, requestData, fields)
    .then(returnSpecialOpeningResponse)
}

export const deleteSpecialOpenings = (isMaster: boolean, id: string[]) => {
  const holidayType = isMaster ? 'Global' : ''
  const mutation = `delete${holidayType}SpecialOpening`

  return client.mutation(mutation, {id: id})
}

export const resetLocation = (data: {
  country: Country
  placeId: string
  resetOpeningTimes: boolean
  resetSpecialOpenings: boolean
}) => {
  return client.withTransform(formatCountry).mutation('resetOpenings', data)
}
