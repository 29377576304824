import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {Header} from '@fcg/tars'
import styled from 'styled-components'

import LoadingState from '../../components/LoadingState'
import Upload from '../../components/Upload'
import DocumentList from '../../components/Upload/List'
import LeftHeader from './LeftHeader'
import RightHeader from './RightHeader'
import {Tabs} from '../CRM/common'
import CustomSnackbar from '../../components/Snackbar'

import {
  fetchDealerProfile,
  resetDealerProfileData,
  setPathMode
} from '../../store/users/actions'
import {
  uploadDocument,
  setUploading,
  fetchDocumentList,
  setReferenceId,
  fetchDealerFileTypes
} from '../../store/documentConfig/actions'
import * as selectors from '../../store/users/selectors'
import * as documentSelectors from '../../store/documentConfig/selectors'
import {getCountryCode} from '../../store/config/selectors'

const DocumentContainer = styled.div`
  display: flex;
  max-width: 1220px;
  flex-wrap: wrap;
  margin: 54px auto;
  justify-content: space-evenly;
`

const Section = styled.div`
  width: ${(props) => props.width};
  border-left: ${(props) => props.border};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 32px;
  overflow: hidden;

  @media(max-width: 525px) {
    display: ${(props) => (props.border ? 'flex' : 'none')}
    border: 0;
    width: 100%;
    padding: 0px;
  }
`

class DealerDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: 'documents',
      hasFullHeader: true,
      userId: ''
    }
  }

  componentDidMount() {
    const {userId} = this.props.match.params

    if (userId) {
      this.setState({userId})
      this.props.fetchDealerProfile(userId)
      this.props.setReferenceId(userId)
      this.props.fetchDealerFileTypes()
      this.props.fetchDocumentList({reference: [userId]})
    }

    this.props.setPathMode({path: 'dealers'})
  }

  componentWillUnmount() {
    this.props.resetDealerProfileData()
  }

  updateSelection = (e) => {
    this.setState({
      activeSection: e
    })
  }

  sectionConfig = () => [
    {label: this.props.t('dealer.documents.heading'), key: 'documents'}
  ]

  renderUploadDocuments = () =>
    !this.props.readOnlyPath && (
      <Upload
        entity='dealer'
        reference={this.state.userId}
        visibility='internal'
        status='reviewPending'
        isUploading={this.props.isUploading}
        uploadDocument={this.props.uploadDocument}
        setUploading={this.props.setUploading}
      />
    )

  renderManageDocuments = () => {
    const {activeSection} = this.state

    if (activeSection !== 'documents') {
      return null
    }

    return (
      <DocumentContainer>
        <DocumentList
          userId={this.props.userId}
          dealerFileTypeOptions={this.props.dealerFileTypeOptions}
          deleteDocumentEnabled={!this.props.readOnlyPath}
        />
        {this.renderUploadDocuments()}
      </DocumentContainer>
    )
  }

  leftSection = (props) => (
    <Section width='55%'>
      <LeftHeader
        data={this.props.data}
        userId={this.state.userId}
        {...props}
      />
    </Section>
  )

  rightSection = (props) => (
    <Section width='45%' border='1px solid #E4E4E4'>
      <RightHeader
        data={this.props.data}
        userId={this.state.userId}
        dealerEnabled={!this.props.readOnlyPath}
        search={this.props.location.search}
        {...props}
      />
    </Section>
  )

  render() {
    const {data, isFetching} = this.props

    if ((!data || isFetching) && this.state.userId) {
      return <LoadingState />
    }

    return (
      <div className='page'>
        <section>
          <Header
            leftSection={this.leftSection}
            rightSection={this.rightSection}
            containerSelector='.app-content'
            headerHeight={190}
          />
          <Tabs
            sections={this.sectionConfig()}
            handleSelect={this.updateSelection}
            sticky={this.state.hasFullHeader}
            selected={this.state.activeSection}
          />
          {this.renderManageDocuments()}
          <CustomSnackbar />
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dealerFileTypeOptions: documentSelectors.getDealerFileTypeOptions(state),
  isUploading: documentSelectors.isUploading(state),
  data: selectors.getDealerProfileData(state),
  isFetching: selectors.isDealerProfileFetching(state),
  countryCode: getCountryCode(state),
  readOnlyPath: selectors.isPathReadOnly(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchDealerProfile: (userId) => dispatch(fetchDealerProfile(userId)),
  setReferenceId: (payload) => dispatch(setReferenceId(payload)),
  fetchDocumentList: (payload) => dispatch(fetchDocumentList(payload)),
  fetchDealerFileTypes: () => dispatch(fetchDealerFileTypes()),
  uploadDocument: (payload) => dispatch(uploadDocument(payload)),
  setUploading: (payload) => dispatch(setUploading(payload)),
  resetDealerProfileData: () => dispatch(resetDealerProfileData()),
  setPathMode: (pathConfig) => dispatch(setPathMode(pathConfig))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(DealerDetails))
