import {useState, useContext, useCallback} from 'react'

import {ValidationContext} from '../../context/ValidationContext'
import {
  validationSchema as defaultValidationSchema,
  ERROR_MESSAGES
} from './validationHelpers'

function useValidation(
  initialState,
  submitCallback,
  validationSchemaAsArg = null
) {
  const {setDataHasChanged} = useContext(ValidationContext)
  const [disable, setDisable] = useState(true)
  const [state, setState] = useState(initialState)
  const validationSchema = validationSchemaAsArg || defaultValidationSchema

  const doValidation = (name, value) => {
    if (!validationSchema[name]) return undefined
    let error = ''
    if (validationSchema[name]?.required) {
      if (!value) {
        error = ERROR_MESSAGES.required
      }
    }

    if (
      validationSchema[name].validator !== null &&
      typeof validationSchema[name].validator === 'object'
    ) {
      if (
        validationSchema[name]?.validator !== null &&
        typeof validationSchema[name].validator === 'object'
      ) {
        if (value) {
          const regEx = new RegExp(validationSchema[name].validator.regEx, 'g')
          if (!regEx.test(value)) {
            error = validationSchema[name].validator.error
          }
        }
      }
    }

    return error
  }

  // Used to handle every changes in every input
  const onChange = useCallback(
    (name, value) => {
      const error = doValidation(name, value)
      setDataHasChanged(true)
      setDisable(false)
      setState((prevState) => ({
        ...prevState,
        [name]: {value, error}
      }))
    },
    [validationSchema]
  )

  const addErrorStateToFields = () => {
    Object.keys(state).forEach((key) => {
      if (!validationSchema[key] || !validationSchema[key].required) return
      setState((prevState) => ({
        ...prevState,
        [key]: {...state[key], error: doValidation(key, state[key].value)}
      }))
    })
  }

  const validateState = () => {
    const hasErrorInState = Object.keys(state).some((key) => {
      if (!validationSchema[key]) return
      const isInputFieldRequired = validationSchema[key].required
      const stateValue = state[key].value // state value
      const stateError = state[key].error // state error

      return (isInputFieldRequired && !stateValue) || stateError
    })

    return hasErrorInState
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()
    if (!validateState()) {
      submitCallback(state)
      setDisable(true)
    } else {
      addErrorStateToFields()
    }
  }

  return {state, disable, onChange, handleOnSubmit}
}

export default useValidation
