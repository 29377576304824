import './ControlledDialogContent.styl'
import {Component} from 'react'
class ControlledDialogContent extends Component {
  contentRef = null

  scrollTop = () => {
    if (this.contentRef !== null) {
      this.contentRef.scrollTo(0, 0)
    }
  }

  scrollTo = (x, y) => {
    if (this.contentRef !== null) {
      this.contentRef.scrollTo(x, y)
    }
  }

  render() {
    const {children, className} = this.props

    return (
      <div
        className={`controllerDialogContent ${className}`}
        ref={(ref) => (this.contentRef = ref)}
      >
        {children}
      </div>
    )
  }
}

export default ControlledDialogContent
