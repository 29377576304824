import {Component} from 'react'

import * as rangeOptions from './RangeOptions'
import throttle from 'lodash/throttle'
import {Button} from '@material-ui/core'
import {DateRange, Close} from '@material-ui/icons'
import {translate} from 'react-i18next'

class RangePicker extends Component {
  state = {open: false}
  constructor(props) {
    super(props)
    this.closeOnSmallScreens = throttle(
      this.closeOnSmallScreens.bind(this),
      200
    )
  }
  componentDidMount() {
    window.addEventListener('resize', this.closeOnSmallScreens)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.closeOnSmallScreens)
  }
  closeOnSmallScreens() {
    if (window.clientWidth <= 768) {
      this.setState({open: false})
    }
  }
  toggle = () => {
    this.setState({open: !this.state.open})
  }
  applyPreset = (preset) => {
    this.props.dispatch(
      this.props.applyRangePreset({
        name: preset.name,
        from: preset.start.timestamp,
        to: preset.end.timestamp
      })
    )
    const output = {
      from: preset.start.timestamp,
      to: preset.end.timestamp
    }

    this.props.onUpdate(output)
  }
  render() {
    const {t, activePreset} = this.props
    const baseClass = ['calendar-presets', 'date-picker__sidebar']
    const activeClass =
      this.state.open === true
        ? [...baseClass, 'date-picker__sidebar--expanded']
        : baseClass

    const baseItemClass = 'date-picker__sidebar__item'
    const optionsData = Object.values(rangeOptions).map((option) => option())
    const options = optionsData.map((option) => {
      const activeClass =
        activePreset === option.name ? `${baseItemClass}--active` : ''

      return (
        <li
          key={option.name}
          className={[baseItemClass, activeClass].join(' ')}
          onClick={() => this.applyPreset(option)}
          data-test={`filter-calendar-item-${option.name}`}
        >
          {typeof option.labelRender === 'function'
            ? option.labelRender(t)
            : t(option.label)}
        </li>
      )
    })

    return (
      <ul className={activeClass.join(' ')}>
        <div className='button'>
          <Button
            variant='fab'
            color={this.state.open ? 'default' : 'secondary'}
            mini
            onClick={this.toggle}
          >
            {this.state.open ? <Close /> : <DateRange />}
          </Button>
        </div>
        <div className='content'>{options}</div>
      </ul>
    )
  }
}

export default translate()(RangePicker)
