import * as types from './types'
import omit from 'lodash/omit'
import {state as defaultState} from './initialState'

export const reducers = {
  [types.setDealer]: (state, {payload}) => ({
    ...state,
    dealer: payload.id,
    cars: [],
    filters: []
  }),
  [types.setDealerInternalId]: (state, {payload}) => ({
    ...state,
    dealerInternalId: payload
  }),
  [types.setCars]: (state, {payload}) => ({
    ...state,
    cars: [...payload.cars]
  }),
  [types.setAmount]: (state, {payload}) => {
    const amountChanges =
      payload.amount === null
        ? omit(state.amountChanges, payload.id)
        : {
            ...state.amountChanges,
            [payload.id]: payload.amount
          }

    return {
      ...state,
      amountChanges
    }
  },
  [types.selectRow]: (state, {payload}) => ({
    ...state,
    selectedRows: payload
  }),
  [types.cleanUp]: () => ({
    ...defaultState
  }),
  [types.createTicket]: (state) => ({
    ...state,
    create: {
      ...state.create,
      isSubmitting: true
    }
  }),
  [types.createTicketSuccess]: (state) => ({
    ...state,
    create: {
      ...state.create,
      created: true,
      isSubmitting: false
    }
  }),
  [types.createTicketError]: (state, action) => ({
    ...state,
    create: {
      error: action.payload,
      created: false,
      isSubmitting: false
    }
  }),
  [types.onFilterChange]: (state, action) => ({
    ...state,
    filters: action.payload
  })
}
