import React from 'react';
import { NotificationItem } from './Notifications.types';
import { Button, Icon } from 'motofin-ui';
import '../../Notifications.scss';
import { createBrowserHistory } from 'history';

// import { Redirect, useHistory } from "react-router-dom";

interface NotificationRowProps {
    translate: any;
    rowItem: NotificationItem;
    handleMarkReadUnreadBtnClick: (actionType: string, rowItem: NotificationItem) => void;
}

const NotificationRow = (props: NotificationRowProps) => {
    const {
        translate,
        rowItem,
        handleMarkReadUnreadBtnClick
    } = props;

    const history = createBrowserHistory({ forceRefresh: true })

    let redirectionUrl = rowItem.content.action.value;
    // redirectionUrl = redirectionUrl.includes('https://') ? redirectionUrl : `https://${redirectionUrl}`;
    const newPathname = new URL(redirectionUrl).pathname;
    const searchParams = new URL(redirectionUrl).search;
    const pathnameArray = newPathname.split("/").filter(item => item)
    let isPathnameValid = pathnameArray.includes("tms") ? pathnameArray.length > 1 && !pathnameArray.includes("null") && true : false;

    const handleNotificationItemClick = () => {
        // if (window.location.pathname.includes("/tms")) {
        if (isPathnameValid) {
            rowItem.status.toLowerCase() !== "opened" && handleMarkReadUnreadBtnClick("opened", rowItem);
            history.push(newPathname + searchParams);
        }
        // }
        // window.open(redirectPath, "_blank"); //to open tms in a new tab and filter the particular task
    }

    const notificationText = rowItem.content.text;
    const taskType = notificationText.split("{")[1]?.split("}")[0];
    const translatedText = notificationText.replace("{" + taskType + "}", translate(taskType))

    return (
        <div className={`notificationRow ${rowItem.status !== "new" ? "backgroundTint75" : ""}`} data-testid="NotificationRow">
            <span className={`notificationsTableHeaderItem_message notificationMessageTimeText`} onClick={handleNotificationItemClick} style={{ cursor: isPathnameValid ? "pointer" : "default" }} data-testid="Notification-Text">
                {translatedText}
            </span>

            <span className={`notificationsTableHeaderItem_time notificationMessageTimeText`}>
                {new Date(rowItem.created).toLocaleString([], { hour: 'numeric', minute: 'numeric', hour12: true }).toLocaleUpperCase()}
            </span>

            <span className={`notificationsTableHeaderItem_actions`}>
                {rowItem.status === "new" ?
                    <Button
                        size="medium"
                        type="secondary"
                        test-id={`notificationsActionBtn_${rowItem.messageId}`}
                        className="notificationsActionBtn"
                        onClick={() => handleMarkReadUnreadBtnClick("opened", rowItem)}
                    >
                        <Icon icon='okHaired' size={24}></Icon>
                        {translate('ui.notifications.actions.markAsRead')}
                    </Button>
                    : <Button
                        size="medium"
                        type="secondary"
                        test-id={`notificationsActionBtn_${rowItem.messageId}`}
                        className="notificationsActionBtn"
                        onClick={() => handleMarkReadUnreadBtnClick("new", rowItem)}
                    >
                        <Icon icon='sms' size={24}></Icon>
                        {translate('ui.notifications.actions.markUnread')}
                    </Button>
                }
            </span>
        </div>
    )
}

export default NotificationRow;