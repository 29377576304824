import moment from 'moment'

const formatRange = ({from, to}) => ({
  from: from ? moment(from).format() : '',
  to: to ? moment(to).format() : ''
})

const selectorPath = (state) => state.calendar

export const selectedDate = (calendarId) => (state) => {
  const calendar = selectorPath(state)[calendarId]

  if (!calendar) {
    return ''
  }

  return moment(calendar.selected).format()
}

export const selectRange = (calendarId) => (state) => {
  const calendar = selectorPath(state)[calendarId]

  if (!calendar) {
    return formatRange()
  }

  return formatRange(formatRange.activeRange)
}

export const isOpen = (calendarId) => (state) => {
  const calendar = selectorPath(state)[calendarId]

  if (!calendar) {
    return false
  }

  return calendar.isOpen
}
