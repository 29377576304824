import {withValidation, validatorFields} from '@fcg/formvalidation'
import ColorPicker from 'material-ui-color-picker'
import errorMessageAdapter from './errorMessageAdapter'

function ValidatedColorPicker(props) {
  const onChange = (color) => {
    if (color) {
      props.onChange(color)
    }
  }

  return (
    <ColorPicker
      name='color'
      label={props.label}
      defaultValue={props.defaultColor}
      value={props.value}
      onChange={onChange}
    />
  )
}

ValidatedColorPicker.defaultProps = {
  defaultColor: '#000'
}

const ValidatedColorPickerField = withValidation(validatorFields.select)(
  errorMessageAdapter(ValidatedColorPicker)
)

ValidatedColorPickerField.type = 'ValidatedTextField'

export default ValidatedColorPickerField
