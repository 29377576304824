import {showSnackbarNotification} from './actions'
import * as signalTypes from './types'
import {put} from 'redux-saga/effects'

export const parseError = (error) => {
  let errorMessage = ''

  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.reduce(
      (acc, tally) => `${acc}\n${tally.message}`,
      ''
    )
  }

  return errorMessage || error.message
}

export const showErrorMessage = function*(error) {
  yield put(
    showSnackbarNotification({
      variant: signalTypes.variantTypes.error,
      message: parseError(error),
      open: true
    })
  )
}
export const showSuccessMessage = function*(message, extras = null) {
  yield put(
    showSnackbarNotification({
      variant: signalTypes.variantTypes.success,
      message: message || 'snackbar.notification.payslips.new.success',
      open: true,
      extras
    })
  )
}
