import {createSelector} from 'reselect'

const selectorPath = (state) => state.documentConfig

const preferenceList = (state) => selectorPath(state).data

export const getPreferenceList = createSelector(preferenceList, (data) =>
  data !== null && typeof data !== 'undefined' ? Object.values(data) : []
)

export const getSelectedPreferenceId = (state) =>
  selectorPath(state).selectedPreferenceId

export const getDealerFileTypeOptions = (state) => {
  const options = selectorPath(state).dealerFileTypeOptions

  return options.map((option) => ({
    value: option.name,
    label: option.name
  }))
}

export const getCount = (state) => selectorPath(state).count
export const getError = (state) => selectorPath(state).error
export const isFetching = (state) => selectorPath(state).fetching
export const getFilters = (state) => selectorPath(state).filters
export const getFields = (state) =>
  selectorPath(state).fields ? Object.values(selectorPath(state).fields) : []
export const getLimit = (state) => {
  const {limit} = selectorPath(state)

  return limit !== null ? limit : 25
}
export const getSort = (state) => selectorPath(state).sort
export const getPage = (state) => {
  const {page} = selectorPath(state)

  return page !== null ? page : 1
}

export const getPdfPreferenceData = (state) =>
  selectorPath(state).preferenceData

export const isUploading = (state) => selectorPath(state).isUploading
export const documentList = (state) => selectorPath(state).documentList
export const isDocumentFetching = (state) =>
  selectorPath(state).documentFetching

export const getDocumentPreviewUrl = (state) => {
  const documentPreviewUrl = selectorPath(state).documentPreviewUrl

  return documentPreviewUrl.length < 1 ? '' : documentPreviewUrl[0].link
}

export const getReferenceId = (state) => selectorPath(state).referenceId
export const getDocumentReference = (state) => selectorPath(state).reference
export const hasAddedDocuments = createSelector(
  documentList,
  (docs) => docs.length > 0
)
