import * as types from './types'
import {showSnackbarNotification} from '../../store/signals/actions'
import * as signalTypes from '../../store/signals/types'

export const setUploading = (uploading = true) => ({
  type: types.setUploading,
  payload: uploading
})

export const uploadDocument = () => (dispatch) => {
  dispatch(setUploading())
  dispatch(
    showSnackbarNotification({
      variant: signalTypes.variantTypes.success,
      message: 'snackbar.notification.bulkupload.upload.success',
      open: true
    })
  )
}

export const fetchBulkUploadData = (payload) => ({
  type: types.fetchBulkUploadDataList,
  payload
})

export const saveChangedData = (payload) => ({
  type: types.saveChangedData,
  payload
})

export const fetchBulkUploadDataListSuccess = () => ({
  type: types.fetchBulkUploadDataListSuccess
})

export const uploadDataError = (error) => ({
  type: types.uploadDataError,
  payload: error
})

export const toggleBulkUploadDialog = (payload) => ({
  type: types.toggleBulkUploadDialogVisibility,
  payload
})

export const saveBulkUploadRequest = () => ({
  type: types.saveBulkUploadRequest
})

export const checkDuplication = () => ({
  type: types.checkDuplication
})

export const createDuplicateCars = (payload) => ({
  type: types.createDuplicateCars,
  payload
})

export const checkDuplicationSuccess = () => ({
  type: types.checkDuplicationSuccess
})

export const saveBulkUploadSuccess = () => ({
  type: types.saveBulkUploadSuccess
})

export const saveBulkUploadError = (payload) => ({
  type: types.saveBulkUploadError,
  payload
})

export const updateBulkUploadListVisibilityType = (payload) => ({
  type: types.updateBulkUploadListVisibilityType,
  payload
})

export const uploadCar = () => ({
  type: types.uploadCar
})

export const updateFormData = (payload) => ({
  type: types.updateFormData,
  payload
})

export const resetBulkUpload = () => ({
  type: types.resetBulkUpload
})

export const updateStep = (payload) => ({
  type: types.updateStep,
  payload
})

export const toggleBulkUploadNotificationDialog = (payload) => ({
  type: types.toggleBulkUploadNotificationDialog,
  payload
})

export const deleteCar = (payload) => ({
  type: types.deleteCar,
  payload
})

export const selectBusinessPartnerForBulkUpload = (payload) => ({
  type: types.selectB2BBusinessPartnerForBulkUpload,
  payload
})
