import {createSelector} from 'reselect'
import gql from '@fcg/lib-gql/gql'
import values from 'lodash/values'
import get from 'lodash/get'
import {defaultLimit} from './initialState'
import {compose, values as ramdaValues, find, propEq} from 'ramda'
import {checkUserPermissions} from '../../auth/selectors'
import {
  NECCESSARY_PERMISSIONS,
  PAYSLIP_PERMISSION
} from '../../crmUserGroups/permissionsConfig'
import {transformPayslipList} from './selectorHelpers'
import {TICKET_ENTITY_TYPES} from '../common/helpers'
import {TICKET_TYPES, TICKET_STATUS} from '../../../config/entities/ticket'
import {
  isPageHidden,
  selectorPath as configSelectorPath
} from '../../config/selectors'
import {transformTicketFilters} from '../../common/selectors'

const modifiableTicketStatuses = [TICKET_STATUS.OPEN, TICKET_STATUS.IN_PROGRESS]

const isPayslipsHidden = isPageHidden('payslips')

const selectorPath = (state) => state.payslips
const listSelectorPath = (state) => selectorPath(state).list

export const getHiddenSubTableColumns = createSelector(
  configSelectorPath,
  (config) => get(config, 'payslipDetails.hiddenSubtableColumns', [])
)

export const getCount = (state) => listSelectorPath(state).count
export const getError = (state) => listSelectorPath(state).error
export const isFetching = (state) => listSelectorPath(state).fetching
export const getFilters = (state) =>
  listSelectorPath(state).fields.map((field) => field.isFilterable)

export const selectedFiltersPath = (state) => listSelectorPath(state).filters
export const getSelectedFilters = createSelector(
  selectedFiltersPath,
  transformTicketFilters
)
const getPayslipConfig = createSelector(
  configSelectorPath,
  (config) => config.payslipDetails
)
const fieldsPathSelector = (state) => Object.values(selectorPath(state).fields)

const filteredPayslipFields = (
  payslipFields = [],
  payslipConfig = {},
  excludedColumnKey = ''
) => {
  if (
    Array.isArray(payslipConfig[excludedColumnKey]) &&
    payslipConfig[excludedColumnKey].length
  ) {
    return payslipFields.reduce((acc, field) => {
      const fieldHidden = payslipConfig[excludedColumnKey].includes(field.key)

      if (fieldHidden) {
        return acc
      }

      if (field.type === 'composite') {
        const filteredFields = field.children.filter(
          (childField) =>
            !payslipConfig[excludedColumnKey].includes(childField.key)
        )

        return [...acc, {...field, children: filteredFields}]
      }

      return [...acc, field]
    }, [])
  }

  return payslipFields
}

export const getFields = createSelector(
  fieldsPathSelector,
  getPayslipConfig,
  (payslipFields, payslipConfig) => {
    return filteredPayslipFields(payslipFields, payslipConfig, 'hiddenColumns')
  }
)
const subTableFieldsPathSelector = (state) =>
  Object.values(selectorPath(state).subtableFields)

export const getSubfields = createSelector(
  subTableFieldsPathSelector,
  getPayslipConfig,
  (subtableFields, payslipConfig) => {
    return filteredPayslipFields(
      subtableFields,
      payslipConfig,
      'hiddenSubtableColumns'
    )
  }
)

const subTableSummaryFieldsPathSelector = (state) =>
  Object.values(selectorPath(state).subtableSummaryFields)
export const getSubtableSummaryFields = createSelector(
  subTableSummaryFieldsPathSelector,
  getPayslipConfig,
  (subtableSummaryFields, payslipConfig) => {
    if (
      Array.isArray(payslipConfig.hiddenSubtableColumns) &&
      payslipConfig.hiddenSubtableColumns.length
    ) {
      return subtableSummaryFields.reduce((acc, field) => {
        const fieldHidden = payslipConfig.hiddenSubtableColumns.includes(
          field.key
        )

        if (fieldHidden) {
          return acc
        }

        return [...acc, field]
      }, [])
    }

    return subtableSummaryFields
  }
)

export const getLimit = (state) => {
  const {limit} = listSelectorPath(state)

  return limit !== null ? limit : defaultLimit
}
export const sortPath = (state) => listSelectorPath(state).sort
export const getSort = createSelector(sortPath, (sort) =>
  sort && Array.isArray(sort) && sort.length
    ? {...sort[0], order: new gql.EnumType(sort[0].order)}
    : null
)
export const getPage = (state) => {
  const {page} = listSelectorPath(state)

  return page !== null ? page : 1
}
export const getLocale = (state) => {
  return state.config.country.selected
}
export const getOptions = (state) => selectorPath(state).options
export const getRejectionReasons = (state) =>
  selectorPath(state).options.payslipRejectionReasons
export const getSelectedPayslipId = (state) =>
  selectorPath(state).selectedPayslip
export const hasUpdated = (state) => selectorPath(state).updateCompleted
export const isTicketUpdating = (state) => selectorPath(state).updateInProgress

export const getCarPaymentReceiptsSelection = (state) =>
  listSelectorPath(state).carPaymentReceiptsSelection

export const getPayslips = createSelector(selectorPath, (payslips) => {
  return !payslips || !payslips.list || !payslips.list.data
    ? []
    : values(payslips.list.data).filter(
        ({type}) => type === TICKET_TYPES.PAYMENT_PROOF
      )
})

export const getListData = createSelector(
  [getPayslips, getSubtableSummaryFields],
  (payslips, subtableSummaryFields) =>
    transformPayslipList(payslips, subtableSummaryFields)
)

export const getSelectedPayslip = createSelector(
  getSelectedPayslipId,
  getPayslips,
  (payslipId, payslips) =>
    compose(find(propEq('id', payslipId)), ramdaValues)(payslips)
)

export const getRowFetchingStatus = createSelector(listSelectorPath, (list) =>
  list.rowsFetching.map((status) => status.id)
)

export const getRowErrorStatus = createSelector(listSelectorPath, (list) =>
  list.rowsFetchingErrors.map((status) => status)
)

export const getExpandedRows = (state) => listSelectorPath(state).expandedRows
export const isRowExpanded = createSelector(
  getExpandedRows,
  (state, props) => props,
  (rows, rowId) => rows.includes(rowId)
)

export const getPayslipById = createSelector(
  listSelectorPath,
  (state, props) => props,
  (list, props) => {
    const payslip = Object.values(list.data).find(({id}) => id === props.id)

    return payslip || null
  }
)

export const getPayslipEntity = (entityType) =>
  createSelector(getPayslipById, (payslip) =>
    payslip && payslip.ticketEntity
      ? payslip.ticketEntity.filter(
          (entity) => entity.entityType === entityType
        )
      : []
  )
export const getEntityIds = (getEntities) =>
  createSelector(getEntities, (entities) =>
    entities.map(({entityId}) => entityId)
  )
export const getPayslipCars = getPayslipEntity(TICKET_ENTITY_TYPES.CAR)
export const getPayslipDocuments = getPayslipEntity(
  TICKET_ENTITY_TYPES.DOCUMENT
)

export const getPayslipCarIds = getEntityIds(getPayslipCars)
export const getPayslipDocumentIds = getEntityIds(getPayslipDocuments)

export const rowHasData = createSelector(getPayslipById, (payslip) =>
  payslip ? payslip.ticketEntity.every((entity) => entity.data) : false
)

const checkPermissionSelector = (permission, type) => (state) =>
  checkUserPermissions(state, {
    entity: permission,
    types: type
  })

export const canCreatePayslips = checkPermissionSelector(
  PAYSLIP_PERMISSION,
  NECCESSARY_PERMISSIONS.CREATE
)

export const canModifyPayslips = checkPermissionSelector(
  PAYSLIP_PERMISSION,
  NECCESSARY_PERMISSIONS.all
)

export const canReadPermission = checkPermissionSelector(
  PAYSLIP_PERMISSION,
  NECCESSARY_PERMISSIONS.READ
)

export const canAccessPayslips = createSelector(
  [isPayslipsHidden, canReadPermission],
  (payslipsHidden, canReadPayslips) => !payslipsHidden && canReadPayslips
)

export const isTicketInModifiableState = (ticket) =>
  ticket.status && modifiableTicketStatuses.includes(ticket.status)
