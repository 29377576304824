export default (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    minHeight: '80vh',
    position: 'relative'
  }),
  headerContainer: {
    padding: '14px 0 24px 0'
  },
  header: {
    display: 'inline-block',
    marginRight: theme.spacing.unit
  },
  subheader: {
    display: 'inline-block'
  },
  noMargin: {
    marginTop: 0
  },
  action: {
    textAlign: 'right'
  },
  container: {
    overflowX: 'auto'
  }
})
