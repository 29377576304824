import styled from 'styled-components'
import {colors} from './common'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  min-width: 104px;
  max-width: 104px;
  min-height: 32px;
`
const HeaderCell = styled(Header)`
  padding: 0
  border: solid 1px ${() => colors.cellBorder};
  border-left: 0;
  background-color: ${() => colors.headerBackground};
  overflow: hidden;
  font-size: 10px;
  font-weight: 500;
  height: 38px;
  align-items: center;
  color: ${() => colors.headerTextColor};

  > span {
    display: block;
    padding: 0 8px;
    overflow: hidden;
  }
`
const HeaderEmptyCell = styled(Header)`
  border-right: solid 1px ${() => colors.cellBorder};
  background-color: ${() => colors.backgroundColorDefault};
  display: block;
`

export const TableHeader = ({columns}) => {
  return (
    <Header>
      <HeaderEmptyCell />
      {columns.map((place, index) => (
        <HeaderCell key={`header-${index}-${place.value}`}>
          <span>{place.label}</span>
        </HeaderCell>
      ))}
    </Header>
  )
}
