import {createSelector} from 'reselect'
import {
  normalizeScheduleItem,
  autoBidStatus,
  getSchedulesForItem,
  getOnAuctionEnd
} from './selectorHelpers'
import {getListSelectors} from '../common/listSelectors'

import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'

const selectorPath = (state) => state.auctionSchedules
const listSelectorPath = (state) => selectorPath(state).list
const dataSelectorPath = (state) => listSelectorPath(state).data
const flowsSelectorPath = (state) =>
  dataSelectorPath(state).flows || dataSelectorPath(state).auctionflows

const listSelectors = getListSelectors(selectorPath)

const getExpandedRows = (state) => listSelectorPath(state).expandedRows
const getFields = (state) => listSelectorPath(state).fields
const getSubFields = (state) => listSelectorPath(state).subfields
const getIsFetched = (state) => selectorPath(state).fetchedData
const getSort = (state) => listSelectorPath(state).sort
const getPage = (state) => listSelectorPath(state).page
const getLimit = (state) => listSelectorPath(state).limit

const getConfigId = (state) => dataSelectorPath(state).id
const createSelectorPath = (state) => selectorPath(state).create
const isFormSubmitting = (state) => createSelectorPath(state).isSubmitting
const isCreated = (state) => createSelectorPath(state).created

const getUnformattedList = createSelector(
  [dataSelectorPath, flowsSelectorPath],
  (data, flow) => {
    if (!flow || !data || flow.length === 0) {
      return []
    }

    return Object.entries(flow).map(([flowKey, flowItem]) => {
      let schedulesForItem = getSchedulesForItem(flowItem)

      return {
        name: flowItem.name,
        id: flowItem.id,
        onAuctionEnd: getOnAuctionEnd(
          data.options.autoConfirmEnabled,
          data.options.autoReserveEnabled,
          flowItem.name
        ),
        previewOffset: flowItem.options?.previewOffset,
        schedules: schedulesForItem,
        allowExpand: schedulesForItem.length > 1
      }
    })
  }
)

const getFormattedList = createSelector([getUnformattedList], (list) =>
  list.map((item) => ({
    ...item,
    schedules: normalizeScheduleItem(item.schedules),
    previewOffset: item?.previewOffset ?? '-'
  }))
)

const getSortedList = createSelector(
  [getFormattedList, getSort],
  (list, sort) => {
    const keys = sort.reduce((acc, item) => item.field, [])
    const orders = sort.reduce((acc, item) => item.order, [])
    return orderBy(list, keys, orders)
  }
)

const getAllSchedules = createSelector([getSortedList], (list) => {
  return list.map((item) => {
    if (item.schedules.length === 1) {
      return {
        ...omit(item, ['schedules']),
        ...item['schedules'][0]
      }
    } else {
      return item
    }
  })
})

const getSchedulesPage = createSelector(
  [getAllSchedules, getPage, getLimit],
  (list, page, limit) => {
    return list.slice((page - 1) * limit, (page - 1) * limit + limit)
  }
)

const getSchedulesCount = createSelector(
  [getUnformattedList],
  (list) => list.length
)

export default {
  ...listSelectors,
  getFields,
  getSubFields,
  getExpandedRows,
  getAllSchedules,
  getSchedulesCount,
  getIsFetched,
  getSchedulesPage,
  getConfigId,
  isFormSubmitting,
  isCreated
}
