import {formatDate} from '../../../../utils/dateFormatting'
import {
  ValidatedSelectField,
  ValidatedTextField
} from '../../../../components/FormValidation'
import UserPickerField from '../../../../components/Pickers/UserPicker'

export default {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'typeBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            withOther: true,
            id: 'type',
            label: 'appointment.type.label',
            margin: 'normal',
            name: 'type',
            prefix: 'appointment.type',
            options: 'appointmentType',
            onChangeHandler: 'handleAppointmentTypeChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'locationBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'placeId',
            label: 'appointment.inspectionPointName.label',
            margin: 'normal',
            name: 'placeId',
            filterable: true,
            onChangeHandler: 'handleLocationChange',
            validator: {required: true, type: 'string'},
            options: 'locationOptions',
            required: (props) => props,
            disabled: (props) =>
              props.disabled || props.loadingSlots || !props.data.type
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'slotBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'slot',
            label: 'appointment.slot.label',
            margin: 'normal',
            name: 'slot',
            onChangeHandler: 'handleSlotChange',
            options: 'appointmentSlots',
            filterable: true,
            withTranslate: false,
            isFetching: 'loadingSlots',
            validator: {required: true, type: 'string'},
            withPagination: true,
            pageSize: 50,
            disabled: (props) => {
              if (props.disabled) {
                return true
              }

              return (
                props.loadingSlots === true ||
                typeof props.options.appointmentSlots === 'undefined' ||
                props.options.appointmentSlots.length === 0
              )
            },
            required: (props) => props,
            placeholder: (props) =>
              typeof props.data.slot !== 'undefined' &&
              props.data.slot !== null &&
              props.data.slot !== ''
                ? props.data.slot
                : `${formatDate(props.data.date, props.dateFormat)} ${props.data
                    .time || ''}`
          }
        ]
      },
      (props) =>
        !props.creation &&
        props.crmDetails &&
        props.crmDetails.hideBookingSourceForUpdate
          ? {}
          : {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 4,
              id: 'bookingSourceBlock',
              children: [
                {
                  component: ValidatedSelectField,
                  fullWidth: true,
                  id: 'bookingSource',
                  label: 'appointment.bookingSource.label',
                  margin: 'normal',
                  name: 'bookingSource',
                  onChangeHandler: 'handleSelectChange',
                  validator: {required: true, type: 'string'},
                  required: (props) => props,
                  disabled: (props) => {
                    if (props.disabled) {
                      return true
                    }

                    return (
                      props.config &&
                      Array.isArray(props.config.disabledBookingSources) &&
                      props.config.disabledBookingSources.includes(
                        props.config.originalBookingSource
                      ) &&
                      !props.isCreation
                    )
                  }
                }
              ]
            },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'callStatusBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'callStatus',
            label: 'appointment.callStatus.label',
            margin: 'normal',
            name: 'callStatus',
            onChangeHandler: 'handleSelectChange'
          }
        ]
      },
      (props) =>
        props.data.cancelledAt === null
          ? {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 4,
              id: 'statusBlock',
              children: [
                {
                  component: ValidatedSelectField,
                  fullWidth: true,
                  id: 'status',
                  label: 'appointment.status.label',
                  margin: 'normal',
                  name: 'status',
                  options: 'appointmentStatus',
                  onChangeHandler: 'handleAppointmentStatusChange'
                }
              ]
            }
          : {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 4,
              id: 'statusBlock',
              children: [
                {
                  component: 'ValidatedTextField',
                  fullWidth: true,
                  id: 'status',
                  label: 'global.status.label',
                  margin: 'normal',
                  name: 'status',
                  onChangeHandler: 'handleTextInput',
                  disabled: true
                }
              ]
            },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'rejectionReasonsBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'rejectionReasons',
            label: 'lead.rejectionReasons.label',
            margin: 'normal',
            name: 'rejectionReasons',
            onChangeHandler: 'handleSelectChange',
            pereventPersistSelectedOption: false
          }
        ]
      },
      (props) =>
        props.data.cancelledAt !== null
          ? {
              component: 'Grid',
              item: true,
              xs: 12,
              sm: 4,
              id: 'cancellationReasonBlock',
              children: [
                {
                  component: ValidatedSelectField,
                  fullWidth: true,
                  withOther: true,
                  id: 'cancellationReason',
                  label: 'appointment.cancellationReason.label',
                  margin: 'normal',
                  name: 'cancellationReason',
                  onChangeHandler: 'handleSelectChange'
                }
              ]
            }
          : {},
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'assignedToBlock',
        children: [
          {
            component: UserPickerField,
            fullWidth: true,
            filterable: true,
            id: 'assignedTo',
            label: 'appointment.assignedTo.label',
            margin: 'normal',
            name: 'assignedTo',
            withTranslate: false,
            queryParams: (props, componentProps) => {
              const args = {userClass: 'INTERNAL'}
              const dependencies = Array.isArray(componentProps.dependencies)
                ? componentProps.dependencies
                : []

              if (
                props.data.placeId !== '' &&
                props.data.placeId !== null &&
                typeof props.data.placeId !== 'undefined' &&
                dependencies.indexOf('placeId') > -1
              ) {
                args.metaInfo = {
                  places: [props.data.placeId]
                }
              }

              return args
            },
            onChangeHandler: 'handleSelectChange',
            onLoad: 'setUserOptions',
            onSelect: 'handleAssignedTo',
            placeholder: (props) => props.data.assignedToName,
            limit: 25,
            debounce: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        id: 'purchaseCoordinatorBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'purchaseCoordinator',
            label: 'appointment.purchaseCoordinator.label',
            margin: 'normal',
            name: 'purchaseCoordinator',
            withTranslate: false,
            onChangeHandler: 'handlePurchaseCoordinatorChange',
            options: 'purchaseCoordinatorOptions',
            placeholder: (props) => props.data.purchaseCoordinator,
            limit: 25,
            debounce: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'purchaseCoordinatorPhoneBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'purchaseCoordinatorPhone',
            label: 'appointment.purchaseCoordinatorPhone.label',
            margin: 'normal',
            name: 'purchaseCoordinatorPhone',
            disabled: () => true,
            validator: {required: false, type: 'phone'}
          }
        ]
      },
      (props = {}) => {
        const appointmentType = props.data?.type
        const appointmentTypesForWhichAddressIsRequired =
          props.crmDetails?.appointmentTypesForWhichAddressIsRequired || []

        const isAddressRequired = appointmentTypesForWhichAddressIsRequired.includes(
          appointmentType
        )

        return {
          component: 'Grid',
          item: true,
          xs: 12,
          sm: 12,
          id: 'addressBlock',
          children: [
            {
              component: 'ValidatedTextField',
              fullWidth: true,
              id: 'appointmentAddress',
              label: 'appointment.address.label',
              margin: 'normal',
              name: 'appointmentAddress',
              required: isAddressRequired,
              onChangeHandler: 'handleTextInput'
            }
          ]
        }
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'newCarSalesName',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'newCarSalesName',
            // label: 'appointment.newCarSalesName.label',todo: remove this hardcode if aws s3 successfully updated
            label: 'New Car Sales Name',
            margin: 'normal',
            name: 'newCarSalesName',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'newCarSalesEmail',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'newCarSalesEmail',
            //label: 'appointment.newCarSalesEmail.label',todo: remove this hardcode if aws s3 successfully updated
            label: 'New Car Sales Email',
            margin: 'normal',
            name: 'newCarSalesEmail',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'newCarSalesPhoneNumber',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'newCarSalesPhoneNumber',
            //label: 'appointment.newCarSalesPhoneNumber.label',todo: remove this hardcode if aws s3 successfully updated
            label: 'New Car Sales Phone Number',
            margin: 'normal',
            name: 'newCarSalesPhoneNumber',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'newcar',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'newCarSalesNRP',
            //label: 'appointment.newCarSalesNRP.label',todo: remove this hardcode if aws s3 successfully updated
            label: 'New Car Sales NRP',
            margin: 'normal',
            name: 'newCarSalesNRP',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'commentsBlock',
        children: [
          {
            component: 'ValidatedTextField',
            fullWidth: true,
            id: 'comments',
            label: 'appointment.comments.label',
            margin: 'normal',
            name: 'comments',
            onChangeHandler: 'handleTextInput',
            multiline: true
          }
        ]
      }
    ]
  }
}
