import {connect} from 'react-redux'
import filter from '../../components/RichTable/FilterBar/Filter/wrapFilter'
import Select from '../../components/Select/Select'
import {translate} from 'react-i18next'

const map = (nest) => ({locationManagement}) => {
  const options = locationManagement.options[nest]

  return {options}
}

const base = (props) => (
  <Select
    {...props}
    id={`LocationFilter_${props.fieldName}`}
    label={props.fieldName}
    options={props.options}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

const checkBase = (props) => (
  <Select
    {...props}
    id={`LocationFilter_${props.fieldName}`}
    label={props.fieldName}
    options={[
      {label: 'car.page.field.active', value: true},
      {label: 'car.page.field.notActive', value: false}
    ]}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const Type = connect(map('type'))(
  translate()(filter(base, {prefix: 'car.page.field.locationType'}))
)
export const City = connect(map('city'))(filter(base))
export const Active = connect(map('active'))(translate()(filter(checkBase)))
