import {connect} from 'react-redux'
import Select from '../../../components/Select/Select'
import {getCountryCode} from '../../../store/config/selectors'
import {getBusinessCategories} from '../../../store/users/initialState'

const BusinessCategoryBase = (props) => (
  <Select
    {...props}
    withTranslate
    id={props.key}
    label={props.label}
    options={getBusinessCategories(props.country)}
    selected={props.value}
    onChange={props.handleChange(props.filterName)}
  />
)

const mapStateToProps = (state) => ({
  country: getCountryCode(state)
})

export default connect(mapStateToProps, null)(BusinessCategoryBase)
