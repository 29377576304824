import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  margin-bottom: 20px;
  background: #f4f4f4;
`
export const SectionTitle = styled.h3`
  font-weight: 500;
`
export const Content = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`
export const NoContent = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
`
export const NoImageText = styled.div`
  margin: 10px 0;
  font-weight: 500;
`

export const SubtableContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
export const SubtableContentLeft = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '40')}%;
`
export const SubtableContentRight = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: ${(props) => props.justify || 'space-between'};
  flex-direction: column;
  background-color: #fff;
`
export const SubtableLoadingContainer = styled(SubtableContainer)`
  padding: 20px;
`
export const SubtableErrorContainer = styled(SubtableContainer)`
  height: 130px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const ErrorBox = styled.div`
  height: 50px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({align}) => align || 'center'};
  justify-content: ${({justify}) => justify || 'space-evenly'};
  width: 100%;
  padding: 12px;
  background: white;
  flex: ${({grow}) => (grow ? '2' : '0')};
`
export const EmptyTablePlaceholderWrapper = styled.tr`
  width: 100%;
  height: 100px;
  text-align: center;
`
export const isRowLoading = ({id, rowFetching}) =>
  rowFetching && rowFetching.find((rowId) => id === rowId)
export const getRowError = ({id, rowFetchingErrors}) =>
  rowFetchingErrors && rowFetchingErrors.find((rowId) => id === rowId.id)
