import {useReducer} from 'react'

import {BPDetailsDispatchProvider} from '../context/BPDetailsDispatchContext'
import {BPDetailsProvider, bpProviderInit} from '../context/BPDetailsContext'
import {bpDetailsReducer} from '../reducer/bpDetailsReducer'
import BPDetailsModalUI from './BPDetailsModalUI'

function BPDetailsModal({businessPartnerInfo, onClose}) {
  const [bpState, dispatch] = useReducer(
    bpDetailsReducer,
    businessPartnerInfo,
    bpProviderInit
  )

  return (
    <BPDetailsProvider value={bpState}>
      <BPDetailsDispatchProvider value={dispatch}>
        <BPDetailsModalUI onClose={onClose} />
      </BPDetailsDispatchProvider>
    </BPDetailsProvider>
  )
}

export default BPDetailsModal
