import {createContext} from 'react'

export const AppointmentContext = createContext({
  toggleVisibilityEdit: () => {}
})

export const AppointmentContextProvider = (props) => (
  <AppointmentContext.Provider {...props} value={props}>
    {props.children}
  </AppointmentContext.Provider>
)

export const AppointmentContextConsumer = (WrappedComponent) => (props) => (
  <AppointmentContext.Consumer>
    {(context) => <WrappedComponent {...props} {...context} />}
  </AppointmentContext.Consumer>
)
