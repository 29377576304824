import ReactDOM from 'react-dom'
import {Slideshow} from '@fcg/image-viewer'
import {
  fileAlternativeRenderer,
  galleryFileAlternativeRendererWrapper
} from '../common'
const sliderStyles = {
  sliderWrapperStyles: {
    padding: '100px 56px 0px'
  },
  slideStyles: {
    imageContainerStyles: {
      maxHeight: '100%'
    },
    imageWrapperStyles: {
      alignSelf: 'flex-end',
      marginBottom: '25px'
    }
  }
}
const styles = {
  sliderStyles
}

export const DocumentSlideshow = ({
  images,
  selected,
  selectedImage,
  onClose,
  onSelect,
  Sidebar
}) =>
  ReactDOM.createPortal(
    <Slideshow
      images={images}
      show
      onClose={onClose}
      alternativeRenderers={fileAlternativeRenderer}
      galleryAlternativeRendererWrappers={galleryFileAlternativeRendererWrapper}
      styles={styles}
      onSelect={onSelect}
      selectedImageIndex={selected}
      selectedImage={selectedImage}
      Sidebar={Sidebar}
    />,
    document.getElementById('app-portal')
  )
