import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SvgIcon from '@material-ui/core/SvgIcon'

import SectionWrapper from '../../../components/Sections/SectionWrapper'
import ServiceRow from './ServiceRow'
import ServicePicker from './ServicePicker/ServicePicker'
import ConfirmationDialog from './ConfirmationDialog'
import NoData from '../../../components/NoData/NoData'

import useServiceActions from './useServiceActions'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import Service from '../../../types/Service'
import ServiceConfiguration from '../../../types/ServiceConfiguration'

const ServiceIcon = () => (
  <SvgIcon>
    <path d='M6 4c-.66 0-1.22.42-1.42 1L2.5 11v8c0 .552.448 1 1 1h1c.552 0 1-.448 1-1v-1h5.3c-.196-.649-.297-1.322-.3-2 .003-2.46 1.298-4.738 3.41-6H4.5L6 5.5h11l1.18 3.53c.613.064 1.215.208 1.79.43L18.42 5c-.2-.58-.76-1-1.42-1H6m10.5 7c-.13 0-.24.09-.26.21l-.19 1.32c-.3.13-.59.29-.85.47l-1.24-.5c-.11 0-.24 0-.31.13l-1 1.73c-.06.11-.04.24.06.32l1.06.82c-.02.17-.03.33-.03.5 0 .17.01.33.03.5l-1.06.82c-.09.08-.12.21-.06.32l1 1.73c.06.13.19.13.31.13l1.24-.5c.26.18.54.35.85.47l.19 1.32c.02.12.12.21.26.21h2c.11 0 .22-.09.24-.21l.19-1.32c.3-.13.57-.29.84-.47l1.23.5c.13 0 .26 0 .33-.13l1-1.73c.06-.11.03-.24-.06-.32l-1.07-.82c.02-.17.04-.33.04-.5 0-.17-.01-.33-.04-.5l1.06-.82c.09-.08.12-.21.06-.32l-1-1.73c-.06-.13-.19-.13-.32-.13l-1.23.5c-.27-.18-.54-.35-.85-.47l-.19-1.32c-.01-.12-.12-.21-.23-.21h-2M6 12c.828 0 1.5.672 1.5 1.5S6.828 15 6 15s-1.5-.672-1.5-1.5S5.172 12 6 12m11.5 2.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.84 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5z' />
  </SvgIcon>
)

const Services = ({isMaster = false}) => {
  const {
    services,
    saveServiceChanges,
    saveServiceConfigurationChanges
  } = useServiceActions(isMaster)
  const [selectedService, setSelectedService] = useState<Service | undefined>(
    undefined
  )
  const [showPicker, setShowPicker] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )
  const classes = useCommonStyles()

  const selectRow = (key: string, deleteHoliday = false) => {
    setSelectedService(services[key])
    deleteHoliday ? setShowDeleteConfirmation(true) : setShowPicker(true)
  }

  //delete is actually closing the service
  const saveChanges = (
    service: Service,
    closeExisting = false,
    createNew = false
  ) => {
    saveServiceChanges(service, closeExisting, createNew, saveCallBack)
  }

  const saveConfigurationChanges = (
    configuration: ServiceConfiguration,
    withConfirmation: boolean
  ) => {
    const callback = withConfirmation ? saveCallBack : () => {}

    saveServiceConfigurationChanges(configuration, withConfirmation, callback)
  }

  const saveCallBack = () => {
    setShowPicker(false)
    setSelectedService(undefined)
  }

  const handleClose = () => {
    setSelectedService(undefined)
    setShowPicker(false)
  }

  const handleConfirmationResponse = (confirmed: boolean) => {
    setShowDeleteConfirmation(false)
    confirmed
      ? saveChanges(selectedService!, confirmed)
      : setSelectedService(undefined)
  }

  return (
    <SectionWrapper title='Services' fullWidth>
      <Grid container>
        {Object.keys(services).length === 0 && (
          <NoData
            buttonText='Add Service'
            onClick={() => {
              setShowPicker(true)
            }}
          />
        )}
        {Object.values(services).map((service, i) => {
          return (
            <ServiceRow
              key={service.key}
              service={service}
              onSelect={selectRow}
            />
          )
        })}
      </Grid>
      <Button
        variant='outlined'
        color='primary'
        className={classes.absoluteRightTop}
        startIcon={<ServiceIcon />}
        onClick={() => {
          setShowPicker(true)
        }}
        data-test='btn-add-service'
      >
        ADD SERVICE
      </Button>
      {showPicker && (
        <ServicePicker
          services={services}
          isMaster={isMaster}
          open={showPicker}
          onCancel={handleClose}
          selectedService={selectedService}
          onSave={saveChanges}
          saveServiceConfiguration={saveConfigurationChanges}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          open={showDeleteConfirmation}
          sendResponse={handleConfirmationResponse}
          service={selectedService!.name}
          type='delete'
        />
      )}
    </SectionWrapper>
  )
}

export default Services
