const state = {
  isLoading: false,
  visible: {
    cancel: false,
    edit: false,
    legend: false,
    legendIcon: false
  },
  dayPicker: {
    id: null
  },
  selectedAppointment: {
    id: null,
    isLoading: false,
    error: null
  },
  error: null,
  locationOptions: {
    isLoading: false,
    error: null
  },
  filters: [],
  savedFilters: [],
  refreshHandler: null
}

export const getInitialState = () => ({
  ...state
})
