import wrapFilter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import ResponsibleBasePicker from '../../../components/Pickers/ResponsibleBasePicker'
import Select from '../../../components/Select/Select'
import {connect} from 'react-redux'

const map = (property) => ({crm}) => ({options: crm.options[property]})

const StatusBase = (props) => (
  <Select
    multiple
    {...props}
    withTranslate
    id={props.fieldName}
    label={props.fieldName}
    options={[
      {label: 'task.status.OPEN', value: 'OPEN'},
      {label: 'task.status.CLOSE', value: 'CLOSE'}
    ]}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const Priority = wrapFilter((props) => (
  <Select
    multiple
    {...props}
    id={props.fieldName}
    label={props.fieldName}
    options={[
      {label: 'car.page.taskPriority.1', value: 1},
      {label: 'car.page.taskPriority.2', value: 2},
      {label: 'car.page.taskPriority.3', value: 3}
    ]}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
))

export const StatusFilter = wrapFilter(StatusBase)

const SubtypeBase = (props) => (
  <Select
    {...props}
    id={props.fieldName}
    label={props.label}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const SubtypeFilter = connect(map('CRMTaskType'))(
  wrapFilter(SubtypeBase)
)

export const Responsible = wrapFilter((props) => (
  <ResponsibleBasePicker {...props} fieldName='responsible' useCaching={true} />
))
