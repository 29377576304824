import PropTypes from 'prop-types'
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import styled from 'styled-components'
import {default as LegendTileBase} from '../common/LegendTile'
import {colors} from '../Table/common'

const styles = {
  root: {
    display: 'flex',
    'justify-content': 'flex-end'
  }
}
const DialogTitleWrapper = (props) => (
  <DialogTitle className={props.classes.root}>
    <IconButton aria-label='close' onClick={props.close}>
      <Close />
    </IconButton>
  </DialogTitle>
)
const DialogTitleBar = withStyles(styles)(DialogTitleWrapper)
const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 300px;
  min-width: 500px;
`

const LegendTile = ({value, metaInfo}) => {
  const color =
    metaInfo && metaInfo.color ? metaInfo.color : colors.defaultCardColor

  return <LegendTileBase color={color} name={value} />
}

const LegendDialog = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitleBar close={props.close} />
      <DialogContent>
        <LegendContainer>
          {props.config.map(({value, metaInfo}, index) => (
            <LegendTile
              value={value}
              metaInfo={metaInfo}
              key={`legend-color-${index}`}
            />
          ))}
        </LegendContainer>
      </DialogContent>
    </Dialog>
  )
}

LegendDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  closeDialog: PropTypes.func,
  config: PropTypes.array
}

LegendDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  closeDialog: () => {},
  config: []
}

export default LegendDialog
