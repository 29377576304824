import {createContext} from 'react'
import {getCountryConfig, getCountryConfigByCode} from '../../../config/country'
import {connect} from 'react-redux'

export const ConfigContext = createContext(getCountryConfig())

export const configSelector = (params, propName) => ({config}) => {
  return {
    [propName]: params.reduce(
      (acc, tally) => ({
        ...acc,
        [tally]: config[tally]
      }),
      {}
    )
  }
}

const withConfig = (params, WrappedComponent) => {
  const mapConfigToProps = ({config}) => {
    const settings = {}
    params.map((param) => {
      settings[param] = config[param]
    })

    return settings
  }

  const WithConfig = (props) => {
    return <WrappedComponent {...props} />
  }

  WithConfig.displayName = WrappedComponent.displayName

  return connect(mapConfigToProps)(WithConfig)
}

export const ConfigProvider = (props) => {
  const relay =
    typeof props.country !== 'undefined' && props.country !== ''
      ? {...getCountryConfigByCode(props.country)}
      : {...getCountryConfig()}

  return <ConfigContext.Provider {...props} value={relay} />
}

export const ConfigConsumer = (WrappedComponent, props) => (
  <ConfigContext.Consumer>
    {(config) => <WrappedComponent {...props} config={config} />}
  </ConfigContext.Consumer>
)

export default withConfig
