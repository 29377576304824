import {createSelector} from 'reselect'
import omit from 'lodash/omit'

import {getCountryConfig} from '../../../config/country'
import {fieldSelectorCreator} from '../utils/selectorHelpers'
import {formWithCountryOptions} from '../utils/panelFormConfigHelper'

const selectorPath = (state) => state.userReducer

export const userList = (state) => selectorPath(state).data

export const getListData = createSelector(userList, (data) => {
  let listData = []

  if (data !== null) {
    listData = Object.values(data).map((user) => {
      if (user.dealerManager && user.dealerManager.firstname) {
        return {
          ...user,
          dealerManagerName:
            user.dealerManager.firstname + ' ' + user.dealerManager.lastname
        }
      }

      return user
    })
  }

  return listData
})

export const getDealerFilterOptions = createSelector(userList, (data) => {
  let dealerList = []

  if (data !== null) {
    dealerList = Object.values(data).reduce((acc, user) => {
      if (user.dealerManager) {
        acc.push({
          value: user.dealerManager.id,
          label:
            user.dealerManager.firstname + ' ' + user.dealerManager.lastname
        })
      }

      return acc
    }, [])
  }

  return dealerList
})

export const getCount = (state) => selectorPath(state).count
export const getError = (state) => selectorPath(state).error
export const isFetching = (state) => selectorPath(state).fetching
export const getFilters = (state) => selectorPath(state).filters
export const getFields = (state) => selectorPath(state).fields
export const getLimit = (state) => {
  const {limit} = selectorPath(state)

  return limit !== null ? limit : 25
}
export const getSort = (state) => selectorPath(state).sort
export const getPage = (state) => {
  const {page} = selectorPath(state)

  return page !== null ? page : 1
}

// Dealers
export const dealerFields = (fieldName) => (state) => {
  const fields = selectorPath(state)[fieldName]

  if (fields) {
    return Object.values(fields)
  }
}
export const getDealerFilters = fieldSelectorCreator(
  dealerFields('filters'),
  'crmDetails',
  'hiddenFilters',
  'filterName'
)
export const getDealerFields = fieldSelectorCreator(
  dealerFields('fields'),
  'crmDetails',
  'hiddenColumns',
  'columnName'
)

export const getDealerProfileData = (state) => {
  const data = selectorPath(state).dealerProfile.data

  if (!data) {
    return data
  }

  const {dealerDetails} = state.config
  const isNewsLetterAccepted =
    !dealerDetails.hidden.includes('isNewsLetterAccepted') && data.metaInfo
      ? data.metaInfo.isNewsLetterAccepted
      : undefined

  return {
    ...data,
    metaInfo: {
      ...data.metaInfo,
      isNewsLetterAccepted
    }
  }
}

export const isDealerProfileFetching = (state) =>
  selectorPath(state).dealerProfile.fetching

/**
  A resursive function to check if the field section is visible or hidden to
  remove the section from the Panel Configuration.
  * @param {Object} form - A form object with children which are components.
  * @param {Array<string>} countryConfig - Array of hidden fields.
  * @return {Array<boolean>} preVal - Array of boolean value to check if a
  * field is present for a particular country.
  */

const checkConfig = (form, countryConfig, preVal = []) => {
  if (form && Array.isArray(form.children)) {
    return form.children.reduce((acc, child) => {
      if (child.id) {
        return countryConfig.hidden.includes(child.id)
      }

      return [...acc, checkConfig(child, countryConfig, acc)]
    }, preVal)
  } else if (form.form) {
    return checkConfig(form.form, countryConfig, preVal)
  } else if (
    !form.form &&
    !Object.prototype.hasOwnProperty.call(form, 'id') &&
    typeof form.children === 'undefined'
  ) {
    return [...preVal, true]
  }
}

export const panelCountryOptions = (state) => {
  const {config} = selectorPath(state).pageSetup.dealerListConfig.formConfig
  const countryConfig = getCountryConfig()
  let index = 0

  const dealerFormConfig = config.reduce((acc, curr) => {
    const check = checkConfig(curr, countryConfig['dealerDetails'])
    if (check.includes(false)) {
      index = index + 1

      return [
        ...acc,
        {
          ...curr,
          stepNum: index
        }
      ]
    }

    return acc
  }, [])

  return dealerFormConfig
}

export const getDealerFormConfig = createSelector(
  [panelCountryOptions, (state, props) => props],
  (config, id) => {
    const formConfig = config.find((current) => current.stepNum === id)

    if (formConfig) {
      return formConfig
    }

    return {}
  }
)

export const getDealerData = (state) => {
  const {dealerDetails} = state.config
  const {dealer} = selectorPath(state)
  let data = omit(dealer, dealerDetails.hidden)

  data = {
    ...data,
    metaInfo: {
      ...data.metaInfo,
      businessTypeSelect: data.metaInfo.businessType
    }
  }

  return data
}

export const getFormLength = createSelector([panelCountryOptions], (config) => {
  return config.length
})

export const getLeftPanelConfig = createSelector(
  [panelCountryOptions],
  (config) => {
    if (config) {
      return config.reduce(
        (acc, formConfig) => [
          ...acc,
          {
            stepNum: formConfig.stepNum,
            data: {
              label: formConfig.stepTitle
            }
          }
        ],
        []
      )
    }

    return []
  }
)

const getOptionValue = (options, item) => {
  const result = options.find((itemWithId) => itemWithId.label === item.label)

  return result ? result.value : ''
}

const getCompanyType = (state) => {
  const companyType = state.crm?.options?.companyType || []
  const companyTypeWithId = state.config?.options?.companyType || []

  return companyType.map((item) => ({
    ...item,
    value: getOptionValue(companyTypeWithId, item)
  }))
}

const mapTranslationToLabel = (option) => ({
  ...option,
  label: option.translationKey
})

export const getDealerOptions = (state) => {
  let options = selectorPath(state).dealerOptions
  const countryOptions = state.crm.options
  const businessType = Array.isArray(countryOptions.businessType)
    ? state.crm.options.businessType
    : []
  const {dealerDetails} = state.config
  const {hiddenValues} = dealerDetails
  const hiddenStatusValues = hiddenValues ? hiddenValues.status : []

  const rejectedReason = countryOptions.rejectedReason || []
  const inactiveReason = countryOptions.inactiveReason || []
  const onboardingStatus = countryOptions.onboardingStatus || []
  const blockedReason = countryOptions.blockedReason || []
  const status = options.status.filter(
    ({value}) => !hiddenStatusValues.includes(value)
  )
  //const companyType = countryOptions.companyType || []
  const companyType = getCompanyType(state)

  options = {
    ...options,
    status,
    blockedReason: blockedReason.map(mapTranslationToLabel),
    rejectedReason: rejectedReason.map(mapTranslationToLabel),
    inactiveReason: inactiveReason.map(mapTranslationToLabel),
    onboardingStatus: onboardingStatus.map(mapTranslationToLabel),
    companyTypeId: companyType.map(mapTranslationToLabel),
    ...{
      businessTypeSelect: businessType.map((option) => ({
        label: option.translationKey,
        value: option.value
      }))
    }
  }

  return options
}

export const getFormConfig = createSelector(
  [getDealerFormConfig, getDealerData],
  (dealerFormConfig, dealerData) => {
    const config = getCountryConfig()
    const data = {
      ...config,
      data: dealerData
    }

    const form = formWithCountryOptions(
      dealerFormConfig,
      'dealerDetails',
      {},
      data
    )

    return form
  }
)

export const isPathReadOnly = (state) => selectorPath(state).readOnlyPath

export const getVisitedPage = (state) => selectorPath(state).visitedPage

export const getHiddenDealerFields = (state) => {
  const {dealerDetails} = state.config

  return dealerDetails.hidden
}

export const getIgnoredFields = (state) => {
  const {dealerDetails} = state.config

  return dealerDetails.ignoreOnSave || []
}
