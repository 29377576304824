import styled from 'styled-components'

import TransitJobTile from './TransitJobTile'

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
`

const Title = styled.span`
  font-size: 13px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 500;
`

const Info = styled.span`
  font-size: 13px;
  color: #6a6a6a;
`

const TransitInfo = (props) => (
  <div>
    <Heading>
      <Title>{props.t('transitReview.infoHeading')}</Title>
      <Info>{props.t('transitReview.description')}</Info>
    </Heading>
    {props.transits.map((transit, index) => (
      <TransitJobTile
        key={`job-tile-${index}`}
        onSelect={props.handleSelect}
        updateMileage={props.updateMileage}
        countryCode={props.countryCode}
        t={props.t}
        transitType={props.transitType}
        type={props.type}
        {...transit}
      />
    ))}
  </div>
)

export default TransitInfo
