import {Component, Fragment} from 'react'
import Dropzone from 'react-dropzone'
import CloudUpload from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {showSnackbarNotification} from '../../store/signals/actions'
import * as signalTypes from '../../store/signals/types'
import styled from 'styled-components'
import {toMBs} from '../../utils/units'

const FileDropzone = styled.div`
  width: ${({uploadAreaWidth}) => {
    if (uploadAreaWidth === 'md') {
      return '330px'
    }

    return '468px'
  }};
  height: 254px;
  border-width: 1px;
  border-color: #bababa;
  border-style: dashed;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  margin-top: ${({topMargin}) => (topMargin ? '24px' : '0px')};
  margin-left: ${({leftMargin}) => (leftMargin ? '24px' : '0px')};
  margin-bottom: 24px;
`
const FileUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`
const Text = styled.p`
  color: #616161;
`
const UploadIcon = styled(CloudUpload)`
  && {
    font-size: 76px;
    fill: #ababab;
  }
`

class Upload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: []
    }
  }

  filesAreTooBig = (files) =>
    this.props.maxFileSize &&
    files.some((file) => toMBs(file.size) >= this.props.maxFileSize)

  getBase64 = (file) => {
    const fr = new window.FileReader()

    return new Promise((resolve, reject) => {
      fr.readAsDataURL(file)
      fr.onload = () => resolve(fr.result.split('base64,')[1])
      fr.onerror = (error) => reject(error)
    })
  }

  onDrop = (file) => {
    if (this.filesAreTooBig(file)) {
      this.props.setUploading(false)
      this.props.showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.document.E_REQUEST_SIZE',
        open: true,
        extras: {
          size: this.props.maxFileSize
        }
      })

      return
    }

    if (this.props.isCategory) {
      this.props.uploadDocument(file[0])

      return
    } else if (this.props.isMultipartUpload) {
      this.props.uploadDocument({
        category: this.props.category || '',
        comment: this.props.comment || '',
        entity: this.props.entity || null,
        reference: this.props.reference || null,
        file: file[0],
        visibility: this.props.visibility || '',
        fileName: file[0].name,
        status: this.props.status || '',
        isMultipart: true
      })

      return
    }

    this.setState(
      {
        files: [...file]
      },
      () => {
        Promise.all(
          this.state.files.map((file) => {
            return this.getBase64(file).then((data) => {
              return {
                category: this.props.category || '',
                comment: this.props.comment || '',
                entity: this.props.entity || null,
                reference: this.props.reference || null,
                file: data,
                visibility: this.props.visibility || '',
                fileName: file.name,
                status: this.props.status || ''
              }
            })
          })
        )
          .then((files) => {
            this.props.uploadDocument({documents: files})
          })
          // eslint-disable-next-line handle-callback-err
          .catch(() => {
            this.props.setUploading(false)
            this.props.showSnackbarNotification({
              variant: signalTypes.variantTypes.error,
              message: 'snackbar.notification.document.error',
              open: true
            })
          })
      }
    )
  }

  onCancel = () => {
    this.setState({
      files: []
    })
  }

  render() {
    return (
      <Dropzone
        onDrop={this.props.onDrop || this.onDrop}
        disabled={this.props.isUploading}
        accept={this.props.accept}
        multiple={this.props.multiple || true}
      >
        {({getRootProps, getInputProps}) => (
          <FileDropzone
            {...getRootProps()}
            width={this.props.uploadAreaWidth}
            topMargin={this.props.topMargin}
            leftMargin={this.props.leftMargin}
          >
            <input {...getInputProps()} data-test='document-upload-input' />
            <FileUpload className='upload'>
              {this.props.isUploading === true ? (
                <CircularProgress />
              ) : (
                <Fragment>
                  <UploadIcon />
                  <Text>{this.props.t('upload.content')}</Text>
                </Fragment>
              )}
            </FileUpload>
          </FileDropzone>
        )}
      </Dropzone>
    )
  }
}

Upload.defaultProps = {
  topMargin: false,
  leftMargin: false,
  maxFileSize: null
}

const mapDispatchToProps = (dispatch) => ({
  showSnackbarNotification: (payload) =>
    dispatch(showSnackbarNotification(payload))
})

const connected = connect(null, mapDispatchToProps)(Upload)

export default translate()(connected)
