import * as types from './types'
import {leadSchema, getInitialState} from './initialState'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'

const reducer = {
  [types.bulkLoadLeads]: (state, action) => {
    return {
      ...state,
      bulkForms: action.payload,
      editState: {...state.editState, pending: false}
    }
  },
  [types.setCarId]: (state, action) => {
    return {
      ...state,
      carId: action?.payload?.id || ''
    }
  },
  [types.inspectionLoaded]: (state, action) => {
    return {
      ...state,
      inspections: action.payload.inspections.reduce((acc, inspection) => {
        acc[inspection.leadId] = acc[inspection.leadId]
          ? [...acc[inspection.leadId], inspection]
          : [inspection]

        return acc
      }, {})
    }
  }
}

export default {
  ...reducer,
  ...tableDecorators.generateReducer(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: leadSchema
  })
}
