import {Button} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import './Actions.styl'
import withRoles from '../providers/withRoles'
import omit from 'lodash/omit'

const ActionButton = (prop) => {
  const props = omit(prop, 'userRoles')

  return (
    <aside className='app-actions Actions'>
      {props.children ? (
        props.children
      ) : (
        <Button
          variant='fab'
          color='primary'
          {...props}
          title={props.title || 'Add'}
        >
          {props.icon || <Add />}
        </Button>
      )}
    </aside>
  )
}

export default withRoles(ActionButton)
