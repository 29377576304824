import React, { useContext, useEffect } from "react";
import { TableContainer, Table, Button } from "@material-ui/core";

import TableHeader from './TableHeader'
import TableBody from './TableBody'
import { UploadHistoryHeaders, history, statusColor } from '../../config/constants'
import { getUploadHistory } from '../../api/getUploadHistory'
import { MainContext } from "../../context/MainContext";
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import Country from "../../types/Country";

const TableWrapped = ({country}: {country: Country}): JSX.Element => {
    const { uploadHistoryData = history, setUploadHistoryData, setResponseBar } = useContext(MainContext)

    useEffect(() => {
        fetchuploadHistory()
    }, [])

    const fetchuploadHistory = () => {
        getUploadHistory(country, 20, 1, { "uploadTime": "DESC", "uploadType": "SLOT_UPLOAD" }, {}, getHisotryCallback)
    }

    const getHisotryCallback = (response) => {
        if (response?.statusCode) {
            setResponseBar({
                type: 'error',
                message: response?.message
            })
        } else {
            Array.isArray(response) && setUploadHistoryData(response.map(item => {
                const timestampObj=moment.unix(item?.uploadTime)
                const formatedDateTime = `${timestampObj.format('DD/MM/YYYY | HH/mm A')}`  // this is fixed formate but with (L) and (LT we get localized formates)
                const fileStatus = <><div style={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: statusColor[item?.status], display: 'inline-block' }}></div> &nbsp;&nbsp; {item?.status}</>
                return ({ ...item, formatedDateTime, fileStatus })
            }))
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 style={{
                    height: '28px',
                    fontSize: '24px',
                    color: '#616161',
                }}>Upload history</h1>
                <Button
                    onClick={fetchuploadHistory}
                    style={{
                        width: '128px',
                        height: '48px',
                        background: 'transparent',
                        border: '1px solid #002f34',
                        textTransform: 'capitalize',
                        fontSize: '13px'
                    }}><RefreshIcon style={{ width: '17px' }} /> &nbsp;&nbsp;&nbsp;Refresh</Button>
            </div>
            <TableContainer style={{ maxHeight: 320 }}>

                <Table stickyHeader aria-label="sticky table">
                    <TableHeader cells={UploadHistoryHeaders} />
                    <TableBody rows={uploadHistoryData} />
                </Table>
            </TableContainer >
        </>
    )
}

export default TableWrapped
