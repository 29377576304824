const contactFields = ['firstname', 'lastname', 'phone']

const appointmentDependencies = [
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'mileage',
  'transmission'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]

const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  }
]
const carDataPicker = {
  dependencies: {
    location: ['make', 'model', 'year', 'trim'],
    mileage: ['make', 'model', 'year', 'trim']
  }
}

const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

module.exports = {
  required: [
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation
  ],
  hidden: [
    'type',
    'assignedTo',
    'assignedToName',
    'externalLeadId',
    'city',
    'postcode',
    'lead.location',
    'isNewsLetterAccepted',
    'inactiveReason',
    'onboardingStatus',
    'rejectedReason',
    'onboardingDate',
    'offboardingDate',
    'companyTypeId',
    'regon',
    'postCode'
  ],
  landingPage: ['tasks', 'leads'],
  hiddenColumns: [
    'plate',
    'city',
    'postcode',
    'lead.status',
    'leadResponsibleName'
  ],
  hiddenFilters: ['city', 'postcode', 'tierLeadSource', 'tierLeadStatus'],
  hiddenFeatures: [],
  disabledFields: {walkin: {leadForm: ['price']}},
  carDataPicker,
  ignoreOnSave: ['businessTypeSelect'],
  hiddenValues: {
    status: ['rejected']
  }
}
