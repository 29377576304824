export const carDataFilesSchema = {
  fileName: null,
  createdAt: null,
  createdByName: null
}

const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  initialData: {...carDataFilesSchema},
  carDataFilesList: [],
  count: null,
  list: {
    page: 1,
    limit: 25,
    fetching: false,
    sort: [{field: 'createdAt', order: 'DESC'}],
    data: [],
    fields: [],
    filters: []
  }
}

export const getInitialState = (config) => {
  return {
    options: {},
    ...state,
    list: {
      ...state.list,
      ...config,
      fields: Object.values(config.fields)
    }
  }
}
