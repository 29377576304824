import {createSelector} from 'reselect'
import gql from '@fcg/lib-gql/gql'
import {T} from 'ramda'
import {CURRENT_LOCATION} from './helpers'
import {
  SELLING_STATUS,
  SELLING_PAYMENT_ENUM,
  SELLING_PAYMENT_STATUSES_ENUM,
  SELLING_STATUS_ENUM
} from '../../../config/entities/car'

const selectorPath = (state) => state.payslipsNew

export const carsSelector = createSelector(selectorPath, (data) => data.cars)
export const changesSelector = createSelector(
  selectorPath,
  (data) => data.amountChanges
)
export const selectedRowsSelector = createSelector(
  selectorPath,
  (data) => data.selectedRows
)
export const hasSelectedRows = createSelector(
  selectedRowsSelector,
  (rows) => rows.length > 0
)
export const alreadySelectedRow = (id) =>
  createSelector(selectedRowsSelector, (selectedRows) =>
    selectedRows.find((carId) => carId === id)
  )

const getAmount = (item, userChange) => {
  let amount = 0

  if (userChange && !isNaN(userChange)) {
    amount = userChange
  } else if (item.financialDetails) {
    amount =
      item.financialDetails.dueAmount >= 0 ? item.financialDetails.dueAmount : 0
  }

  return amount
}

const getPaymentLabel = (amount, outstanding) => {
  let payment = 'payslips.paymentType.full'

  if (amount === 0) {
    payment = 'payslips.paymentType.none'
  } else if (amount < outstanding) {
    payment = 'payslips.paymentType.partial'
  }

  return payment
}

const getSellingStatus = (item) => {
  const sellingStatus = item.status.find(
    ({statusType}) => statusType === SELLING_STATUS
  )

  return sellingStatus ? `car.page.statusValue.${sellingStatus.status}` : ''
}

export const normalizedPurcases = createSelector(
  [carsSelector, changesSelector],
  (cars, changes) =>
    cars.map((item) => {
      const outstanding = item.financialDetails
        ? item.financialDetails.dueAmount || 0
        : 0
      const amountChange = item.financialDetails
        ? Number(changes[item.financialDetails.id])
        : 0
      const amount = getAmount(item, amountChange)
      const payment = getPaymentLabel(amount, outstanding)

      const processingAmount = item.financialDetails
        ? item.financialDetails.processingAmount
        : 0

      const currentLocation = item.location.find(
        ({locationType}) => locationType === CURRENT_LOCATION
      )

      return {
        ...item,
        payment,
        outstanding,
        amount,
        processingAmount,
        currentLocation: currentLocation ? currentLocation.location : '',
        sellingStatus: getSellingStatus(item)
      }
    })
)

export const getDealer = createSelector(selectorPath, (state) => ({
  dealer: state.dealer,
  dealerInternalId: state.dealerInternalId
}))
export const getDealerId = createSelector(selectorPath, (state) => state.dealer)

export const newPayslipSummaryFields = (state) => {
  const {newPayslipSummaryFields} = selectorPath(state)

  return newPayslipSummaryFields
    ? newPayslipSummaryFields.filter((field) => !field.show)
    : []
}

export const transformCurrentRow = (
  fields,
  selectedPurchases,
  ignoreEmptySelectedPurchases
) => (prev, current) =>
  fields.reduce((acc, field) => {
    if (
      ignoreEmptySelectedPurchases ||
      (selectedPurchases && selectedPurchases.length)
    ) {
      const isSelected = selectedPurchases.find(
        (purchase) => purchase.id === current.id
      )

      if (!isSelected) {
        return acc
      }
    }

    if (field.value) {
      return {
        ...acc,
        [field.key]: field.value
      }
    }

    if (field.summaryTransform) {
      return {
        ...acc,
        [field.key]: field.summaryTransform(acc[field.key], current[field.key])
      }
    }

    return acc
  }, prev)

export const getSummaryRowData = (
  data,
  summaryRowFields,
  selectedPurchases = null,
  ignoreEmptySelectedPurchases = false
) =>
  data.reduce(
    transformCurrentRow(
      summaryRowFields,
      selectedPurchases,
      ignoreEmptySelectedPurchases
    ),
    {}
  )

export const getSelectedPurchases = createSelector(
  [normalizedPurcases, selectedRowsSelector],
  (rows, selectedRows) => rows.filter(({id}) => selectedRows.includes(id))
)

export const getSummaryData = createSelector(
  [normalizedPurcases, newPayslipSummaryFields, getSelectedPurchases, T],
  getSummaryRowData
)

export const createStatusSelector = (state) => selectorPath(state).create

export const getFilters = (state) => selectorPath(state).filters

const mandatoryCarPaymentFilters = [
  {
    statusType: SELLING_PAYMENT_ENUM,
    status: SELLING_PAYMENT_STATUSES_ENUM
  }
]

export const getStatusFilters = createSelector(getFilters, (filters) => {
  const tableFilters = filters.reduce((acc, f) => {
    if (f.key === 'sellingStatus') {
      return [
        ...acc,
        {
          statusType: SELLING_STATUS_ENUM,
          status: new gql.EnumType(f.value)
        }
      ]
    }

    return acc
  }, [])

  return [...tableFilters, ...mandatoryCarPaymentFilters]
})

export const getCarIdFilter = createSelector(getFilters, (filters) => {
  const carIdFilter = filters.filter((f) => f.key === 'internalId')

  return (carIdFilter.length && carIdFilter[0].value) || ''
})

export const getCreatePayslipFilterConfig = (props) =>
  props.pageConfig.newPayslip.filter((item) => item.filter)
