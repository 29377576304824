import {Component, createRef} from 'react'
import WMS, {render as renderWMS, unmount} from '@logistics/wms'
import styled from 'styled-components'
import { getCountryCode } from '../../store/config/selectors'
import { connect } from 'react-redux'
import {getCountryConfig} from '../../../config/country'
import merge from 'lodash/merge'
import defaultConfig from '../../config/default'
import { createTranslation, formatLocale } from '../../config/translation'
const ID_NAME = 'warehouse-management'
const Wrapper = styled.div`
  height: calc(100vh - 64px);
`;
const exportConfig = (overrides = {}) => {
  return merge({...defaultConfig}, overrides)
}
const config = exportConfig(getCountryConfig())
const i18n = createTranslation(config)
class WarehouseManagement extends Component {
  render() {
    
    return (
      <Wrapper>
        <WMS id={ID_NAME} countryCode={this.props.countryCode} basePath='/wms' selectedLanguage={this.props.selectedLanguage || 'en' } i18n={i18n}/>
      </Wrapper>
    )
  }
}
const mapStateToProps = (state) => ({
  countryCode: getCountryCode(state),
  selectedLanguage: state.config.language.selected
});
export default connect(mapStateToProps)(WarehouseManagement)