import wrapFilter from './wrapFilter'
import {FormControlLabel, Switch} from '@material-ui/core'

export const FilterToggle = (props) => {
  const {value, fieldName, label, handleChange} = props
  const extractValue = (name) => (e) => handleChange(name)(e.target.checked)
  const className = ['Filter', 'Filter--toggle', ...props.className].join(' ')

  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={extractValue(fieldName)}
            value={fieldName}
          />
        }
        label={label}
      />
    </div>
  )
}

FilterToggle.defaultProps = {
  className: ''
}

export default wrapFilter(FilterToggle)
