import {Component} from 'react'
import {
  withStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Divider
} from '@material-ui/core'
import {translate} from 'react-i18next'
import isEqual from 'lodash/isEqual'

const styles = (theme) => ({
  paper: {
    position: 'relative',
    maxWidth: 300
  },
  search: {
    padding: theme.spacing.unit * 3
  },
  buttonHome: {
    position: 'absolute',
    top: 0,
    left: theme.spacing.unit
  }
})

export const LocationDrawer = withStyles(styles, {name: 'LocationDrawer'})(
  translate()(({t, data, count, filter, onFilter, classes, ...props}) => (
    <Drawer variant='permanent' classes={{paper: classes.paper}}>
      <div className={classes.search}>
        <TextField
          fullWidth
          label={t('search.button')}
          onChange={onFilter}
          value={filter}
        />
        <Typography>{`${data.length} / ${count}`}</Typography>
      </div>
      <Divider />
      <List>
        {data.map(({location, city, id}) => (
          <ListItem button key={id} onClick={() => props.goToEdit({id})}>
            <ListItemText primary={location} secondary={city} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  ))
)

class FilteredLocations extends Component {
  state = {filter: '', visibleOptions: []}

  componentDidMount() {
    this.filter()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {data: oldData} = this.props
    const {data} = nextProps

    if (!isEqual(oldData, data)) {
      this.filter(data)
    }
  }

  updateFilter = (e) => this.setState({filter: e.target.value}, this.filter)

  filter = (source = null) => {
    const data = source || this.props.data
    const {filter} = this.state
    const regex = new RegExp(filter, 'gi')
    const visibleOptions =
      filter === ''
        ? [...data]
        : data.filter(({location}) => location.match(regex) !== null)

    this.setState({visibleOptions})
  }
  render() {
    const {filter, visibleOptions} = this.state

    return (
      <LocationDrawer
        data={visibleOptions}
        onFilter={this.updateFilter}
        filter={filter}
        count={this.props.count}
        goToEdit={this.props.goToEdit}
      />
    )
  }
}

export default FilteredLocations
