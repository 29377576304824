import React from 'react'
import {Loader} from 'motofin-ui'

const App = React.lazy(() =>
  import(/* webpackChunkName:"TMS" */ '../../../pages/TMS/TMS')
)

const TMSExport = () => {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Loader />
        </div>
      }
    >
      <App />
    </React.Suspense>
  )
}

export default {
  path: 'tms/',
  exact: false,
  label: 'TMS',
  key: 'tms',
  component: TMSExport,
  fields: {}
}
