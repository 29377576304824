import {useCallback, useState, useRef, useContext, useMemo} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import {translate} from 'react-i18next'
import {ValidatedForm} from '@fcg/formvalidation'
import DialogTitleWithClose from '../../../../components/DialogTitleWithClose/DialogTitleWithClose'
import {
  PRIMARY_CONTACT_TYPE,
  DEALER_BP_TYPE
} from '../../constants/businessPartners'
import {fetchBusinessPartnerList} from '../../../../store/businessPartners/actions'
import * as BusinessPartnerApi from '../../../../api/businessPartners/requests'
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../../store/signals/actions'
import {ConfigContext} from '../../../../components/providers/withConfig'
import {
  ValidatedTextField,
  ValidatedSelectField,
  RegexField
} from '../../../../components/FormValidation'
import {getCountryOptionsSelector} from '../../../../store/crm/selectors'
import TaxInfoFields from './TaxInfoFields'

import './BPAddModal.styl'

const countryOptionsSelector = getCountryOptionsSelector()

export function BPAddModal({
  t,
  onClose,
  onBPCreate,
  _fetchBusinessPartnerList,
  _showErrorMessage,
  _showSuccessMessage,
  businessPartnerTypes
}) {
  const [formData, setFormData] = useState({
    legalName: '',
    type: null,
    email: '',
    phone: '',
    taxInfo: {
      taxNumber: '',
      type: '',
      taxSubType: '',
      distributionType: ''
    }
  })
  const {
    countryCode,
    validations,
    bpAddFormConfig,
    callingCode,
    disbableCallingCodeInBP,
    bpBasedTaxInfoConditions
  } = useContext(ConfigContext)
  const {includeDealerType = false} = bpAddFormConfig
  const businessPartnerTypeDropdownOpts = useMemo(
    () =>
      businessPartnerTypes
        .filter((type) => type !== DEALER_BP_TYPE || includeDealerType)
        .map((bpTypeValue) => ({
          label: t(`businessPartners.type.${bpTypeValue}`),
          value: bpTypeValue
        })),
    [businessPartnerTypes, includeDealerType, t]
  )

  const formRef = useRef()

  const defaultZipCode = bpAddFormConfig?.defaultValues?.zipCode || 'n/a'
  const additionalFields = bpAddFormConfig.additionalFields

  const handleTextInput = useCallback(
    (evt, key) => {
      const {value} = evt.target

      setFormData((currFormData) => {
        const newFormData = {
          ...currFormData,
          [key]: value
        }

        if (
          key === 'type' &&
          currFormData.type !== value &&
          bpBasedTaxInfoConditions &&
          Object.keys(bpBasedTaxInfoConditions).length
        ) {
          const matchedConditions =
            value === 'individual'
              ? bpBasedTaxInfoConditions.individualBpFormFields
              : bpBasedTaxInfoConditions.nonIndividualBpFormFields

          for (const field in matchedConditions) {
            newFormData.taxInfo[field] =
              matchedConditions[field].length === 1
                ? matchedConditions[field][0]
                : ''
          }
        }

        return newFormData
      })
    },
    [bpBasedTaxInfoConditions]
  )

  const handleAdditionalFieldChange = useCallback((value, key, section) => {
    setFormData((currFormData) => ({
      ...currFormData,
      [section]: {
        ...currFormData[section],
        [key]: value
      }
    }))
  }, [])

  const submitHandler = useCallback(async () => {
    const success = await formRef.current.validate()
    if (success) {
      const bpInfo = {
        type: formData.type,
        legalName: formData.legalName,
        status: 'Active',
        contacts: [
          {
            phone:
              formData.phone && !disbableCallingCodeInBP
                ? `${callingCode}${formData.phone}`
                : formData.phone,
            email: formData.email,
            type: PRIMARY_CONTACT_TYPE,
            name: formData.legalName
          }
        ],
        address: [
          {
            addressLine: 'n/a',
            city: 'n/a',
            country: countryCode,
            type: 'standard',
            zipCode: defaultZipCode,
            procurementCity: 'n/a',
            email: formData.email,
            phone:
              formData.phone && !disbableCallingCodeInBP
                ? `${callingCode}${formData.phone}`
                : formData.phone
          }
        ]
      }

      if (bpBasedTaxInfoConditions && formData.taxInfo.taxNumber) {
        bpInfo.taxInfo = [formData.taxInfo]
      }

      try {
        const createdBP = await BusinessPartnerApi.createBusinessPartner({
          country: countryCode,
          ...bpInfo
        })

        onBPCreate(createdBP)
        onClose({closeSearchModal: true})
        _fetchBusinessPartnerList()
        _showSuccessMessage(t(`businessPartners.b2bBP.create.success`))
      } catch (error) {
        _showErrorMessage(
          error.errors ? error.errors[0].message : error.message
        )
      }
    }
  }, [
    formData.type,
    formData.legalName,
    formData.phone,
    formData.email,
    formData.taxInfo,
    disbableCallingCodeInBP,
    callingCode,
    countryCode,
    defaultZipCode,
    bpBasedTaxInfoConditions,
    onBPCreate,
    onClose,
    _fetchBusinessPartnerList,
    _showSuccessMessage,
    t,
    _showErrorMessage
  ])

  return (
    <Dialog
      className='addBPModal'
      open
      maxWidth='md'
      onClose={onClose}
      fullWidth
    >
      <ValidatedForm ref={formRef} t={t}>
        <DialogTitleWithClose className='addBPModal__title' onClose={onClose}>
          <h3>{t('businessPartners.addForm.heading.text')}</h3>
        </DialogTitleWithClose>
        <DialogContent className='addBPModal__content'>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <ValidatedSelectField
                label={t('businessPartners.type.label')}
                selected={formData.type}
                value={formData.type}
                margin='normal'
                required={bpAddFormConfig.requiredFields.includes('type')}
                onChange={(evt) => handleTextInput(evt, 'type')}
                fullWidth
                options={businessPartnerTypeDropdownOpts}
                id='addBP-type'
                name='type'
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ValidatedTextField
                label={t('businessPartners.legalName.label')}
                value={formData.legalName || ''}
                id='legalName'
                margin='normal'
                required={bpAddFormConfig.requiredFields.includes('legalName')}
                fullWidth
                maxLength={80}
                onChange={(evt) => handleTextInput(evt, 'legalName')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <RegexField
                label={t('businessPartners.phone.label')}
                value={formData.phone}
                id='phoneNumber'
                margin='normal'
                required={bpAddFormConfig.requiredFields.includes('phone')}
                fullWidth
                onChange={(evt) => handleTextInput(evt, 'phone')}
                regex={validations?.phone}
                validator={{required: true, type: 'string'}}
                InputProps={
                  disbableCallingCodeInBP
                    ? null
                    : {
                        startAdornment: (
                          <InputAdornment position='start'>
                            {callingCode}
                          </InputAdornment>
                        )
                      }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ValidatedTextField
                label={t('businessPartners.email.label')}
                value={formData.email || ''}
                id='email'
                margin='normal'
                type='email'
                fullWidth
                required={bpAddFormConfig.requiredFields.includes('email')}
                onChange={(evt) => handleTextInput(evt, 'email')}
              />
            </Grid>

            {Array.isArray(additionalFields) && additionalFields.length
              ? additionalFields.map(({id, fields}) => {
                  if (id === 'taxInfo') {
                    return (
                      <TaxInfoFields
                        key='taxInfoFields'
                        fields={fields}
                        bpType={formData.type}
                        formData={formData.taxInfo}
                        formValidator={formRef}
                        onChange={handleAdditionalFieldChange}
                      />
                    )
                  }
                })
              : null}
          </Grid>

          <div className='buttonContainer'>
            <Button
              variant='contained'
              color='primary'
              className='saveBtn'
              onClick={submitHandler}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </ValidatedForm>
    </Dialog>
  )
}

const mapDispatchToProps = {
  _fetchBusinessPartnerList: fetchBusinessPartnerList,
  _showErrorMessage: showErrorMessage,
  _showSuccessMessage: showSuccessMessage
}

BPAddModal.propTypes = {
  t: PropTypes.func.isRequired,
  _fetchBusinessPartnerList: PropTypes.func,
  _showErrorMessage: PropTypes.func,
  _showSuccessMessage: PropTypes.func,
  onClose: PropTypes.func,
  onBPCreate: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
  businessPartnerTypes: countryOptionsSelector(state, ownProps)
})

BPAddModal.defaultProps = {
  t: () => {},
  onClose: () => {},
  onBPCreate: () => {}
}

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(BPAddModal)
