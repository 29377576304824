import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const ams = merge(
  {
    homepage: '/attendance-manager/attendance',
    menuItems: {
      hiddenFields: [
        'inspection',
        'crm',
        'dealers',
        'locations',
        'documentConfig',
        'carData',
        'payslips',
        'carOwnershipTransfer',
        'crmusergroups',
        'leadAllocation',
        'transitJobs',
        'locationManager',
        'resourceManager',
        'category',
        'wms',
        'documentmanagement',
        'ddayBookings',
        'tms'
      ]
    }
  },
  defaultConfig
)
