import PropTypes from 'prop-types'
import {formatWithDecimals, formatByUnit} from '../../utils/numberOperations'
import withConfig from '../providers/withConfig'

export const NumberFormat = ({amount, format, withDecimals, number}) => {
  if (format === false) {
    return amount
  }

  /* Using more robust check instead of something like 'amount && !isNaN(amount)'
    because 0(zero) is a regular value that needs to pass-through the check
  */
  return amount !== '' &&
    amount !== null &&
    typeof amount !== 'undefined' &&
    !isNaN(amount)
    ? withDecimals === true
      ? formatWithDecimals(Number(amount), number)
      : formatByUnit(Number(amount), number)
    : '-'
}

NumberFormat.defaultProps = {
  asCurrency: false,
  withDecimals: false
}

NumberFormat.propTypes = {
  amount: PropTypes.any,
  asCurrency: PropTypes.bool,
  withDecimals: PropTypes.bool,
  number: PropTypes.shape({
    decimalSep: PropTypes.string.isRequired,
    unitSep: PropTypes.string.isRequired,
    units: PropTypes.array
  }).isRequired
}

export default withConfig(['number'], NumberFormat)
