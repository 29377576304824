import React, {useState, useEffect, useContext} from 'react'
import {Map as MapsReact, Marker} from 'google-maps-react'
import axios from 'axios'

import {MainContext} from '../../context/MainContext'
import {ValidationContext} from '../../context/ValidationContext'
import {COUNTRY_CONFIG} from '../../config/countryConfig'

const Map = ({
  lat,
  lng,
  onChange
}: {
  lat: number
  lng: number
  onChange: (field: string, value: number) => void
}) => {
  const {countryCode} = useContext(MainContext)
  const {setResponseBar} = useContext(ValidationContext)
  const getMapCenter = () => {
    return lat && lng && window.google
      ? new window.google.maps.LatLng(lat, lng)
      : COUNTRY_CONFIG.mapCenter[countryCode]
  }
  const [mapCenter, setMapCenter] = useState(getMapCenter())
  const zoom = lat && lng ? 12 : 7

  useEffect(() => {
    if (lat && lng && window.google) {
      const center = new window.google.maps.LatLng(lat, lng)
      setMapCenter(center)
    }
  }, [lat, lng, window.google])

  const getCoordinatesFromMarker = (props, marker, e) => {
    const lat = marker.position.lat()
    const lng = marker.position.lng()
    checkMarkerPosition(lat, lng)
  }

  const moveMarkerOnClick = (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat()
    const lng = clickEvent.latLng.lng()
    checkMarkerPosition(lat, lng)
  }

  const checkMarkerPosition = (lat: number, lng: number) => {
    const request = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${COUNTRY_CONFIG.mapApiKey[countryCode]}`
    axios.get(request).then((response) => {
      if (
        response.data.status == google.maps.GeocoderStatus.OK &&
        response.data.results.length
      ) {
        const countryComponent = response.data.results[0][
          'address_components'
        ].find((comp) => comp.types[0] === 'country')
        if (countryComponent['short_name'] === countryCode) {
          onChange('lat', lat)
          onChange('lng', lng)
        } else {
          setResponseBar({
            type: 'warning',
            message: 'Please place the marker in the country territories.'
          })
        }
      } else {
        onChange('lat', lat)
        onChange('lng', lng)
      }
    })
  }

  if (window.google) {
    return (
      <MapsReact
        google={window.google}
        initialCenter={mapCenter}
        center={mapCenter}
        containerStyle={{
          height: '237px',
          position: 'relative',
          width: '100%'
        }}
        zoom={zoom}
        zoomControl
        mapTypeControl={false}
        onClick={moveMarkerOnClick}
      >
        <Marker
          position={mapCenter}
          draggable
          onDragend={getCoordinatesFromMarker}
        />
      </MapsReact>
    )
  } else {
    return null
  }
}

export default Map
