import * as types from './types'
import * as DocumentApi from '../../api/document/requests'
import * as globalActions from '../global/actions'
import * as selectors from './selectors'
import * as configSelectors from '../config/selectors'
import omit from 'lodash/omit'
import * as signalTypes from '../signals/types'
import gql from '@fcg/lib-gql/gql'
import * as CarApi from '../../api/car/requests'
import {toMBs} from '../../utils/units'
import {
  createDocRequestParams,
  showDocumentSuccessNotification,
  showDocumentErrorNotification
} from './helper'

export const savePdfPreference = () => (dispatch, getState) => {
  const pdfPreference = selectors.getPdfPreferenceData(getState())
  const country = configSelectors.getCountryCode(getState())

  dispatch({type: types.savePdfPreference})

  DocumentApi.createPrintPreference({...pdfPreference, country})
    .then(() => {
      dispatch({type: types.resetPreferenceData})
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const deletePdfPreference = (id) => (dispatch) => {
  dispatch({
    type: types.deletePdfPreference,
    id
  })

  DocumentApi.deletePrintPreference({id})
    .then(() => {
      dispatch(fetchPreferenceList())
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const fetchDealerFileTypes = () => (dispatch, getState) => {
  const country = configSelectors.getCountryCode(getState())

  CarApi.getCountryOptionsForType(
    {dealerFileTypes: {type: 'dealerFileTypes'}},
    {country}
  )
    .then((res) => {
      if (
        res['dealerFileTypes'] &&
        res['dealerFileTypes'].list &&
        res['dealerFileTypes'].list.length
      ) {
        dispatch(setDealerFileTypeOptions(res['dealerFileTypes'].list))
      }
    })
    .catch((e) => {
      dispatch(global.apiError(e))
    })
}

export const setDealerFileTypeOptions = (fileTypeList) => ({
  type: types.setDealerFileTypeOptions,
  payload: fileTypeList
})

export const fetchPreferenceList = () => (dispatch, getState) => {
  const countryCode = configSelectors.getCountryCode(getState())

  dispatch({type: types.fetchPreferenceList})
  DocumentApi.fetchPreferenceList({countries: [countryCode]})
    .then((list) => {
      dispatch({
        type: types.fetchPreferenceListSuccess,
        data: list.printPreference
      })
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const fetchPreferenceListSuccess = (data) => ({
  type: types.fetchPreferenceListSuccess,
  data
})

export const updatePdfPreference = () => (dispatch, getState) => {
  const pdfPreference = selectors.getPdfPreferenceData(getState())

  dispatch({type: types.updatePdfPreference})

  DocumentApi.updatePrintPreference(omit(pdfPreference, ['country']))
    .then(() => {
      dispatch({type: types.resetPreferenceData})
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const updatePdfPreferenceField = (name, value) => ({
  type: types.updatePdfPreferenceField,
  name,
  value
})

export const setSelectedPreferenceData = (data) => ({
  type: types.setSelectedPreferenceData,
  data
})

export const loadPdfPreference = (preferenceId) => (dispatch) => {
  dispatch({
    type: types.loadPdfPreference,
    preferenceId
  })
  DocumentApi.loadPdfPreference({id: preferenceId})
    .then((response) => {
      dispatch({
        type: types.setSelectedPreferenceData,
        data: response.printPreference.list[0]
      })
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
    })
}

export const updatePdfPreferenceMetaField = (name, value) => ({
  type: types.updatePdfPreferenceMetaField,
  name,
  value
})

export const resetPreferenceData = () => ({
  type: types.resetPreferenceData
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const setSort = (sort) => ({
  type: types.setSort,
  payload: sort
})

export const toggleField = (label) => ({
  type: types.toggleField,
  payload: label
})

export const setFetching = (fetching = true) => ({
  type: types.fetching,
  payload: fetching
})

export const setUploading = (uploading = true) => ({
  type: types.setUploading,
  payload: uploading
})

export const uploadDocument = (doc) => (dispatch, getState) => {
  dispatch(setUploading())
  const options = doc.documents.reduce(
    (acc, docs) => [
      {
        ...docs,
        visibility: new gql.EnumType(docs.visibility)
      },
      ...acc
    ],
    []
  )

  DocumentApi.uploadDocument({
    documents: options
  })
    .then(() => {
      dispatch(setUploading(false))

      const userId = selectors.getReferenceId(getState())
      dispatch(fetchDocumentList({reference: [userId]}))

      dispatch(showDocumentSuccessNotification())
    })
    .catch((err) => {
      dispatch(setUploading(false))
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.error,
          message: err.properValue
            ? `snackbar.notification.document.${err.code}`
            : 'snackbar.notification.document.error',
          open: true,
          extras: {size: toMBs(err.properValue)}
        }
      })
    })
}

export const uploadDocumentDriver = ({documents}) => (dispatch, getState) => {
  dispatch(setUploading())

  const options = documents.reduce(
    (acc, docs) => [
      {
        ...docs,
        visibility: new gql.EnumType(docs.visibility)
      },
      ...acc
    ],
    []
  )

  DocumentApi.uploadDocument({
    documents: options
  })
    .then(({uploadDocument}) => {
      dispatch(setUploading(false))

      const currentDocuments = selectors.documentList(getState())
      const currentDocumentIds = currentDocuments.map(({id}) => id)
      const ids = uploadDocument.map(({id}) => id)

      dispatch(fetchDocumentList({id: [...currentDocumentIds, ...ids]}))
      dispatch(showDocumentSuccessNotification())
    })
    .catch(() => {
      dispatch(setUploading(false))
      dispatch(showDocumentErrorNotification())
    })
}

export const uploadPayslipDocument = ({documents}) => (dispatch, getState) => {
  dispatch(setUploading())

  DocumentApi.uploadDocument({
    documents: createDocRequestParams(documents)
  })
    .then(({uploadDocument}) => {
      dispatch(setUploading(false))

      const currentDocuments = selectors.documentList(getState())
      const currentDocumentIds = currentDocuments.map(({id}) => id)
      const ids = uploadDocument.map(({id}) => id)

      dispatch(fetchDocumentList({id: [...currentDocumentIds, ...ids]}))
      dispatch(showDocumentSuccessNotification())
    })
    .catch((error) => {
      console.log(error)
      dispatch(setUploading(false))
      dispatch(showDocumentErrorNotification())
    })
}

export const uploadAsset = (doc, name) => async (dispatch, getState) => {
  dispatch(setUploading())

  const country = configSelectors.getCountryCode(getState())
  const options = doc.documents.reduce((acc, docs) => {
    const fileName = docs.fileName.split('.') || []

    return [
      {
        country: new gql.EnumType(country),
        comment: '',
        label: '',
        file: docs.file,
        fileType: fileName[1],
        fileName: fileName[0]
      },
      ...acc
    ]
  }, [])

  try {
    const response = await DocumentApi.uploadAsset({
      assets: options
    })
    dispatch(setUploading(false))
    dispatch(
      updatePdfPreferenceMetaField(name, response.uploadAsset[0].publicLink)
    )

    dispatch({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.document.success',
        open: true
      }
    })
  } catch (error) {
    dispatch(setUploading(false))
    dispatch({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.error,
        message: error.properValue
          ? `snackbar.notification.document.${error.code}`
          : 'snackbar.notification.document.error',
        open: true,
        extras: {size: toMBs(error.properValue)}
      }
    })
  }
}

export const downloadDocument = (options) => (dispatch) => {
  DocumentApi.downloadDocument({
    documentIds: [options]
  })
    .then((response) => {
      window.open(response.downloadDocument, '_blank')
    })
    .catch(() => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.error,
          message: 'snackbar.notification.document.download.error',
          open: true
        }
      })
    })
}

export const setDocumentPreviewUrl = (payload) => ({
  type: types.setPreviewDocumentUrl,
  payload
})

export const previewDocument = (payload) => ({
  type: types.previewDocument,
  payload
})

export const deleteDocument = (options) => (dispatch) => {
  dispatch(setDocumentFetching(true))

  const {documentIds, doNotFetch} = options

  DocumentApi.deleteDocument({
    documentIds
  })
    .then(() => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.success,
          message: 'snackbar.notification.document.delete.success',
          open: true
        }
      })

      dispatch({
        type: types.deleteDocumentSuccess,
        payload: {
          documentIds
        }
      })

      if (doNotFetch) {
        dispatch(setDocumentFetching(false))

        return
      }
    })
    .catch(() => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.error,
          message: 'snackbar.notification.document.delete.error',
          open: true
        }
      })
    })
}

export const updateDocument = (options) => (dispatch, getState) => {
  dispatch(setDocumentFetching(true))

  const {reference, documentIds, ...documentOptions} = options

  DocumentApi.updateDocument({
    documents: [documentOptions]
  })
    .then(() => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.success,
          message: 'snackbar.notification.document.update.success',
          open: true
        }
      })

      const documentReference =
        reference || selectors.getReferenceId(getState())
      const params = documentIds
        ? {id: documentIds}
        : {
            reference: Array.isArray(documentReference)
              ? documentReference
              : [documentReference]
          }

      dispatch(fetchDocumentList(params))
    })
    .catch(() => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.error,
          message: 'snackbar.notification.document.update.error',
          open: true
        }
      })
    })
}

export const setDocumentFetching = (payload) => ({
  type: types.setDocumentFetching,
  payload
})

export const fetchDocumentList = (options) => (dispatch) => {
  dispatch(setDocumentFetching(true))

  DocumentApi.documentList({
    ...options
  })
    .then((response) => {
      dispatch({
        type: types.fetchDocumentListSuccess,
        payload: response.document.list
      })
      dispatch(setDocumentFetching(false))
    })
    .catch((error) => {
      dispatch({
        type: signalTypes.showSnackbarNotification,
        payload: {
          variant: signalTypes.variantTypes.error,
          message: error.errors[0].message,
          open: true
        }
      })
      dispatch(setDocumentFetching(false))
    })
}

export const setReferenceId = (payload) => ({
  type: types.setReferenceId,
  payload
})

export const setReference = (payload) => ({
  type: types.setReference,
  payload
})

export const cleanUp = () => ({
  type: types.cleanUp
})
