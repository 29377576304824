import {ValidatedTextField} from '../../../components/FormValidation'

export const countryOptionFormConfig = {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'Grid',
            item: true,
            xs: 12,
            sm: 8,
            children: [
              {
                component: ValidatedTextField,
                fullWidth: true,
                id: 'name',
                label: 'countryOption.column.name',
                margin: 'normal',
                required: true,
                onChangeHandler: 'handleTextInput'
              }
            ]
          }
        ]
      }
    ]
  }
}
