import {call, put, select, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as BusinessPartnerApi from '../../api/businessPartners/requests'
import {SUPPLIER_B2B_TAG} from '../../pages/BusinessPartners/constants/businessPartners'
import selectors from './selectors'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

function* fetchBusinessPartnerListSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)

    const options = {
      limit,
      page: page - 1,
      country: countryCode,
      tagName: SUPPLIER_B2B_TAG
    }

    const businessPartnerList = yield call(
      BusinessPartnerApi.getAllBusinessPartners,
      {...omitBy(options, isNil)}
    )

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchBusinessPartnerListSuccess,
      payload: businessPartnerList.businessPartners
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchBusinessPartnerListError,
      payload: e
    })
  }
}

function* updateFiltersSaga() {
  try {
    const filters = yield select(selectors.getParsedFilterForQuery)

    yield put({
      type: types.fetchBusinessPartnerList,
      payload: {
        carData: filters,
        page: 1
      }
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* deleteBusinessPartnerSaga(action) {
  const {businessPartnerId, country, t} = action.payload
  try {
    yield call(BusinessPartnerApi.deleteBusinessPartner, {
      businessPartnerId,
      country
    })

    yield put({
      type: types.deleteBusinessPartnerSuccess,
      payload: {businessPartnerId}
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: t('businessPartners.b2bBP.delete.success'),
        open: true
      })
    )
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchBusinessPartnerList, fetchBusinessPartnerListSaga),
  takeLatest(types.updateFilters, updateFiltersSaga),
  takeLatest(types.deleteBusinessPartner, deleteBusinessPartnerSaga)
]
