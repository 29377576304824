import {useContext, useState, useEffect} from 'react'
import {
  createOrUpdateGlobalServicesConfiguration,
  setOpeningTimes,
  getOpeningTimes
} from '../../../api/booking/requests'

import {GlobalContext} from '../../../context/GlobalContext'
import {MainContext} from '../../../context/MainContext'
import {ValidationContext} from '../../../context/ValidationContext'

import OpeningTime from '../../../types/OpeningTime'
import Service from '../../../types/Service'
import {createServiceName} from './utils'
import OpeningHours from '../../../types/OpeningHours'
import ServicesObject from '../../../types/ServicesObject'
import ServiceConfiguration from '../../../types/ServiceConfiguration'

export default function useServiceActions(isMaster: boolean) {
  const {
    setGlobalServices,
    globalServices,
    globalServicesConfiguration,
    setGlobalServicesConfiguration
  } = useContext(GlobalContext)
  const {
    countryCode,
    selectedLocation,
    locationsServices,
    setLocationsServices
  } = useContext(MainContext)
  const {setResponseBar} = useContext(ValidationContext)
  const getLocationsServices = () => {
    const req = {country: countryCode, placeId: selectedLocation!.id}
    getOpeningTimes(req).then((openingHours: ServicesObject) => {
      setLocationsServices(openingHours)
    })
  }
  const [services, setServices] = useState<ServicesObject>(globalServices)

  useEffect(() => {
    if (isMaster) {
      setServices(globalServices)
    } else {
      getLocationsServices()
    }
  }, [selectedLocation])

  useEffect(() => {
    setServices(isMaster ? globalServices : locationsServices)
  }, [locationsServices, globalServices])

  const saveServiceChanges = (
    service: Service,
    closeExisting: boolean,
    createNew: boolean,
    callback: () => void
  ) => {
    //BE cannot update the services
    //when types changed we need to close existing one and create a new one.
    const openingHours: OpeningTime[] = []
    Object.values(service.openingHours).forEach((day) => {
      day.forEach((timeRange) => {
        if (!createNew) {
          openingHours.push({
            ...timeRange,
            bookingType: closeExisting ? timeRange.bookingType : service.type,
            closed: closeExisting ? true : timeRange.closed
          })
        } else {
          openingHours.push({
            ...timeRange,
            bookingType: service.type
          })
        }
        timeRange.bookingType = service.type
      })
    })

    setOpeningTimes(openingHours, countryCode, selectedLocation!.id!).then(
      (newOpeningHours: OpeningHours) => {
        const newServices = {...services}
        delete newServices[service.key]
        if (createNew === closeExisting) {
          const newKey = service.type.join('_')
          newServices[newKey] = {
            ...service,
            name: createServiceName(service.type),
            key: newKey
          }
        }
        isMaster
          ? setGlobalServices(newServices)
          : setLocationsServices(newServices)
        callback()
        setResponseBar({
          type: 'info',
          message: closeExisting ? 'Service deleted' : 'Services updated'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const saveServiceConfigurationChanges = (
    configuration: ServiceConfiguration,
    withConfirmation: boolean,
    callback: () => void
  ) => {
    createOrUpdateGlobalServicesConfiguration(configuration, countryCode).then(
      (newConfiguration: ServiceConfiguration) => {
        const key = newConfiguration.type.join('_')
        setGlobalServicesConfiguration({
          ...globalServicesConfiguration,
          [key]: newConfiguration
        })

        callback()
        withConfirmation &&
          setResponseBar({
            type: 'info',
            message: 'Services updated'
          })
      },
      (e: any) => console.log(e) // TODO
    )
  }

  return {services, saveServiceChanges, saveServiceConfigurationChanges}
}
