import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {Header} from '@fcg/tars'
import styled from 'styled-components'
import LoadingState from '../../components/LoadingState'
import Upload from '../../components/Upload'
import DocumentList from '../../components/Upload/List'
import LeftHeader from './LeftHeader'
import RightHeader from './RightHeader'
import {Tabs} from '../CRM/common'
import CustomSnackbar from '../../components/Snackbar'
import {
  fetchDriverImage,
  loadDriver,
  resetDriver
} from '../../store/driver/actions'
import {
  uploadDocumentDriver,
  setUploading,
  fetchDocumentList,
  setReferenceId
} from '../../store/documentConfig/actions'
import * as selectors from '../../store/users/selectors'
import * as documentSelectors from '../../store/documentConfig/selectors'
import driverSelectors from '../../store/driver/selectors'

const DocumentContainer = styled.div`
  display: flex;
  max-width: 1220px;
  flex-wrap: wrap;
  margin: 54px auto;
  justify-content: space-evenly;

  > div {
    margin-top: 0px;
  }
`

const Section = styled.div`
  width: ${(props) => props.width};
  border-left: ${(props) => props.border};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 32px;
  overflow: hidden;

  @media(max-width: 525px) {
    display: ${(props) => (props.border ? 'flex' : 'none')}
    border: 0;
    width: 100%;
    padding: 0px;
  }
`

class DriverDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: 'documents',
      hasFullHeader: true,
      userId: ''
    }
  }

  componentDidMount() {
    const {userId} = this.props.match.params

    if (userId) {
      this.setState({userId})
      this.props.loadDriver(userId)
      this.props.fetchDriverImage(userId)
      this.props.setReferenceId(userId)
      this.props.fetchDocumentList({reference: [userId], entity: ['driver']})
    }
  }

  componentWillUnmount() {
    this.props.resetDriver()
  }

  updateSelection = (e) => {
    this.setState({
      activeSection: e
    })
  }

  sectionConfig = () => [
    {label: this.props.t('driver.documents.heading'), key: 'documents'}
  ]

  renderManageDocuments = () => {
    const {activeSection} = this.state

    if (activeSection !== 'documents') {
      return null
    }

    return (
      <DocumentContainer>
        <DocumentList userId={this.props.userId} entity='driver' />
        <Upload
          entity='driver'
          reference={this.state.userId}
          visibility='internal'
          status='reviewPending'
          isUploading={this.props.isUploading}
          uploadDocument={this.props.uploadDocumentDriver}
          setUploading={this.props.setUploading}
        />
      </DocumentContainer>
    )
  }

  leftSection = (props) => (
    <Section width='55%'>
      <LeftHeader
        driverImage={this.props.driverImage}
        data={this.props.data}
        userId={this.state.userId}
        {...props}
      />
    </Section>
  )

  rightSection = (props) => (
    <Section width='45%' border='1px solid #E4E4E4'>
      <RightHeader
        data={this.props.data}
        userId={this.state.userId}
        {...props}
      />
    </Section>
  )

  render() {
    const {data, isFetching} = this.props

    if ((!data || isFetching) && this.state.userId) {
      return <LoadingState />
    }

    return (
      <div className='page'>
        <section>
          <Header
            leftSection={this.leftSection}
            rightSection={this.rightSection}
            containerSelector='.app-content'
            headerHeight={220}
          />
          <Tabs
            sections={this.sectionConfig()}
            handleSelect={this.updateSelection}
            sticky={this.state.hasFullHeader}
            selected={this.state.activeSection}
          />
          {this.renderManageDocuments()}
          <CustomSnackbar />
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dealerFileTypeOptions: documentSelectors.getDealerFileTypeOptions(state),
  isUploading: documentSelectors.isUploading(state),
  data: driverSelectors.getDriverData(state),
  isFetching: selectors.isDealerProfileFetching(state),
  driverImage: driverSelectors.getDriverImage(state)
})

const mapDispatchToProps = (dispatch) => ({
  loadDriver: (userId) => dispatch(loadDriver(userId)),
  setReferenceId: (payload) => dispatch(setReferenceId(payload)),
  fetchDocumentList: (payload) => dispatch(fetchDocumentList(payload)),
  uploadDocumentDriver: (payload) => dispatch(uploadDocumentDriver(payload)),
  setUploading: (payload) => dispatch(setUploading(payload)),
  resetDriver: () => dispatch(resetDriver()),
  fetchDriverImage: (payload) => dispatch(fetchDriverImage(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(DriverDetails))
