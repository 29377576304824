import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  toggleSort,
  toggleField,
  deleteCountryOption,
  openCreateDialog,
  fetchCountryOptionList
} from '../../store/countryOption/actions'
import {DeleteDialog} from '../CRM/common'
import {Button} from '@material-ui/core'
import {Add, Delete} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import selectors from '../../store/countryOption/selectors'
import CustomSnackbar from '../../components/Snackbar'
import styled from 'styled-components'
import {getVisibleActions, isDeleteButtonVisible} from '../CRM/common/helpers'
import Editor from './Editor'

const CountryOptionWrapper = styled.div`
  tr {
    td:first-child,
    th:first-child {
      padding-left: 24px;
    }
  }
`

const deleteInitialState = {
  open: false
}

class CountryOptionList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delete: {...deleteInitialState}
    }

    this.openDeleteDialog = this.openDeleteDialog.bind(this)
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this)
    this.submitDeleteHandle = this.submitDeleteHandle.bind(this)
    this.openCreateDialog = this.openCreateDialog.bind(this)
  }

  openCreateDialog = () => {
    this.props.openCreateDialog()
  }

  closeDeleteDialog = () => {
    this.setState({delete: {open: false}})
  }

  submitDeleteHandle = () => {
    const {deleteCountryOption, t, countryOptionType} = this.props

    this.closeDeleteDialog()
    deleteCountryOption({
      id: this.state.delete.item.id,
      message: t('snackbar.notification.countryOption.delete.success', {
        type: countryOptionType
      })
    })
  }

  openDeleteDialog = ({item}) => {
    const {t} = this.props

    this.setState({
      delete: {
        open: true,
        item,
        heading: t('countryOption.delete.title'),
        content: t('countryOption.delete.body', {name: item.name})
      }
    })
  }

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  singleSelectActions = [
    {
      test: (props) => isDeleteButtonVisible(props),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            item: item,
            action: this.props.deleteCountryOption
          })
        },
        icon: <Delete />,
        title: this.props.t('global.action.delete')
      }
    }
  ]

  render() {
    const {countryOptionType} = this.props

    return (
      <Fragment>
        {this.props.showCreateDialog ? <Editor {...this.props} /> : null}
        <CountryOptionWrapper className='page'>
          <PageTableBase
            {...this.props}
            multisort={false}
            title={`countryOptions.${countryOptionType}.entity.options`}
            filterable={false}
            hasFooter={false}
            singleSelectActions={this.singleSelectTableActions()}
          />
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openCreateDialog}
            >
              <Add />
            </Button>
          </ActionButton>
          <DeleteDialog
            open={this.state.delete.open}
            handleSubmit={this.submitDeleteHandle}
            handleCancel={this.closeDeleteDialog}
            entity={'B2BDeal'}
          />
          <CustomSnackbar />
        </CountryOptionWrapper>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getCountryOptionList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  fields: selectors.getFields(state),
  showCreateDialog: selectors.getCreateDialogState(state),
  countryOptionType: selectors.getCountryOptionType(state)
})

const mapDispatchToProps = (dispatch) => ({
  openCreateDialog: () => dispatch(openCreateDialog()),
  fetchData: (options) => dispatch(fetchCountryOptionList(options)),
  deleteCountryOption: (countryOptionId) =>
    dispatch(deleteCountryOption(countryOptionId)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  toggleField: ({key}) => dispatch(toggleField(key))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CountryOptionList))
