import {useState, useContext, useEffect} from 'react'
import update from 'immutability-helper'

import Location from '../../types/Location'
import {MainContext} from '../../context/MainContext'

export type Filter=  {title:string,options:string[], index:number, selectedFilters:string[]}
export type Filters ={
  city: Filter
  bIMapping:  Filter
  active: Filter
  location: Filter
  type: Filter
}


const formFilters = (locationList: Location[], locationTypes:string[]) => {
  const cities: string[] = []
  const names: string[] = []
  const bIMapping: string[] = []

  locationList.map((location: Location) => {
    bIMapping.push(location.bIMapping)
    cities.push(location.city)
    names.push(location.location)
  })

  return {
    location: {options:Array.from(new Set(names)).filter((option) => option), title:'Name', selectedFilters:[], index:-1},
    city: {options:Array.from(new Set(cities)).filter((option) => option), title:'City', selectedFilters:[], index:-1},
    type: {options:[...locationTypes], title:'Type', selectedFilters:[], index:-1},
    bIMapping:{options: Array.from(new Set(bIMapping)).filter((option) => option), title:'BI Mapping', selectedFilters:[], index:-1},
    active: {options:['active', 'inactive'], title:'Status', selectedFilters:[], index:-1}
  }
}

let initialFilters:Filters

function useFilters(searchValue: string) {
  const {locationList, locationTypes} = useContext(MainContext)
  const [filters, setFilters] = useState<Filters>(initialFilters)
  useEffect(()=>{
    initialFilters = formFilters(locationList, locationTypes)
    setFilters(initialFilters)
  },[locationList])
  useEffect(()=>{
    if(searchValue!==''){
      clearFilters()
      filterLocationListWithSearchValue(searchValue)
    }else{
      setFilteredLocationList([locationList])
    }
  }, [searchValue])
  const [filteredLocationList, setFilteredLocationList] = useState<Location[][]>([locationList])

  const clearFilters =()=>{
    setFilters(initialFilters)
    setFilteredLocationList([locationList])
  }

  const filterLocationListWithSearchValue = (searchValue: string)=>{
    const newList = locationList.filter((loc:Location)=>loc.location.toLowerCase().includes(searchValue.toLowerCase()))
    setFilteredLocationList([newList])
  }

  const filterLocationList = (newFilters:Filters) =>{
    const selectedFilters:(Filter & {key:keyof Location})[] = []
    const filterKeys = Object.keys(newFilters) as (keyof Filters)[]
    filterKeys.forEach(filter=>{
      if(newFilters[filter].index > -1){
        selectedFilters.push({...newFilters[filter], key:filter})
      }
    })
    const newList = selectedFilters.sort((a,b)=>a.index - b.index).reduce((newFilteredList, filter,i)=>{

       const filterList = newFilteredList[i].filter(location=>{
        switch(filter.key){
          case 'type':
            if((location.type ||[]).some((type:string)=>filter.selectedFilters.includes(type))){
              return location
            }
            break
          default:
            const value = filter.key === 'active'? (location.active? 'active' : 'inactive') : location[filter.key]
            if(filter.selectedFilters.includes(value as string)) return location
            break
        }
      })
      newFilteredList = [...newFilteredList,filterList]
      return newFilteredList
    },[[...locationList]])

    setFilteredLocationList(newList)
  }

  const returnBiggestIndex = () =>{
    const filterKeys = Object.keys(filters) as (keyof Filters)[]
    const indices = filterKeys.map(filter => filters[filter].index )
    return Math.max(...indices)
  }

  const updateFilters =(field: keyof Filters, selectedOptions: string[]) => {

    const index = selectedOptions.length > 0 ? (filters[field].index > -1 ? filters[field].index : returnBiggestIndex()+1) : -1

    const newFilters = update(filters,{
      [field]:{selectedFilters: {$set:selectedOptions}, index: {$set:index}}
    })
    setFilters(newFilters)
    filterLocationList(newFilters)
  }

  return {filters, filteredLocationList, updateFilters, clearFilters}
}

export default useFilters
