import {bulkUploadConfigV2} from './bulkUploadConfigV2'
import {businessPartnerConfig} from '../businessPartner/businessPartnerConfig'
import {countryOptionConfig} from '../countryOption/countryOptionConfig'

export const b2bDealConfigV2 = {
  path: 'b2bdeal/v2',
  label: 'menu.b2bDealV2',
  key: 'bulkUploadV2',
  children: [bulkUploadConfigV2, businessPartnerConfig],
  Bulkupload: {
    fields: bulkUploadConfigV2.fields
  },
  businessPartner: {
    type: businessPartnerConfig.type,
    fields: businessPartnerConfig.fields
  },
  countryOption: {
    type: countryOptionConfig.type,
    fields: countryOptionConfig.fields
  },
  permissions: {
    entity: 'web.admin.ui.car.bulkUpload',
    types: ['READ']
  }
}
