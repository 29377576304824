import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import gql from '@fcg/lib-gql/gql'
import {bpResponseInceptor} from './helpers'

import {PAYMENT_PROPERTIES_IN_METAINFO} from '../../pages/BusinessPartners/constants/businessPartners'

const client = new ApiClient('order', [bpResponseInceptor], null, true)

const transformOptions = ({
  country,
  status,
  tagInfo,
  type,
  contacts,
  bankDetails,
  taxInfo,
  address,
  metaInfo,
  tagName,
  ...options
}) => {
  if (typeof country !== 'undefined') {
    options.country = new gql.EnumType(country)
  }

  if (type) {
    options.type = new gql.EnumType(type)
  }

  if (status) {
    options.status = new gql.EnumType(status)
  }

  if (typeof tagName !== 'undefined') {
    options.tagName = new gql.EnumType(tagName)
  }

  if (tagInfo && Array.isArray(tagInfo)) {
    options.tagInfo = tagInfo.map(({tagName, ...tagInfoData}) => ({
      ...tagInfoData,
      tagName: new gql.EnumType(tagName)
    }))
  }

  if (contacts && Array.isArray(contacts)) {
    options.contacts = contacts.map(({type, gender, ...contactData}) => {
      const genderObj = gender ? {gender: new gql.EnumType(gender)} : {}

      return {
        ...contactData,
        type: new gql.EnumType(type),
        ...genderObj
      }
    })
  }

  if (address && Array.isArray(address)) {
    options.address = address.map(({type, ...addressObj}) => ({
      ...addressObj,
      type: new gql.EnumType(type),
      country: new gql.EnumType(country)
    }))
  }

  if (bankDetails && Array.isArray(bankDetails)) {
    options.bankDetails = bankDetails.map(({accountType, ...bankDetailObj}) => {
      const _accountType = accountType
        ? {accountType: new gql.EnumType(accountType)}
        : {}

      return {
        ...bankDetailObj,
        bankCountryKey: new gql.EnumType(country),
        ..._accountType
      }
    })
  }

  if (taxInfo && Array.isArray(taxInfo)) {
    options.taxInfo = taxInfo.map(
      ({type, distributionType, taxSubType, ...taxInfoObj}) => {
        const _distributionType = distributionType
          ? {distributionType: new gql.EnumType(distributionType)}
          : {}

        const _taxSubType = taxSubType
          ? {taxSubType: new gql.EnumType(taxSubType)}
          : {}

        return {
          ...taxInfoObj,
          type: new gql.EnumType(type),
          ..._distributionType,
          ..._taxSubType
        }
      }
    )
  }

  if (metaInfo) {
    options.metaInfo = {...metaInfo}
    PAYMENT_PROPERTIES_IN_METAINFO.forEach((property) => {
      if (property in metaInfo && Array.isArray(metaInfo[property])) {
        options.metaInfo[property] = metaInfo[property].map(
          (val) => new gql.EnumType(val)
        )
      }
    })
  }

  if (tagInfo && Array.isArray(tagInfo)) {
    options.tagInfo = tagInfo.map((tagInfoObj) => ({
      ...tagInfoObj,
      tagName: new gql.EnumType(tagInfoObj.tagName)
    }))
  }

  if (status) {
    options.status = new gql.EnumType(status)
  }

  if (metaInfo) {
    options.metaInfo = {...metaInfo}

    PAYMENT_PROPERTIES_IN_METAINFO.forEach((prop) => {
      if (prop in metaInfo && Array.isArray(metaInfo[prop])) {
        options.metaInfo[prop] = metaInfo[prop].map(
          (val) => new gql.EnumType(val)
        )
      }
    })
  }

  return options
}

export function getBusinessPartner(params = {}) {
  return client.query(
    'businessPartners',
    transformOptions(params),
    fields.businessPartnerList
  )
}

export function getAllBusinessPartners(params = {}) {
  return client.query(
    'businessPartners',
    transformOptions(params),
    fields.businessPartnerList
  )
}

export function updateBusinessPartner(params) {
  return client.mutation(
    'updateBBDealsBusinessPartner',
    transformOptions(params),
    fields.businessPartnerFields
  )
}

export async function createBusinessPartner(params) {
  const {createBBDealsBusinessPartner} = await client.mutation(
    'createBBDealsBusinessPartner',
    transformOptions(params),
    fields.businessPartnerCreateFields
  )

  return createBBDealsBusinessPartner
}

export function deleteBusinessPartner(params) {
  return client.mutation(
    'deleteBBDealsBusinessPartner',
    transformOptions(params)
  )
}
