export const transitSchema = {
  driver: '',
  startTime: new Date().toISOString(),
  endPlace: '',
  startPlace: '',
  driverId: '',
  transits: [],
  transitType: 'CAR'
}

const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  initialData: {...transitSchema},
  transitList: [],
  count: null,
  list: {
    page: 0,
    limit: 25,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: [],
    expandedRows: [],
    rowsFetching: [],
    rowsFetchingErrors: []
  },
  carIdOptions: {},
  isFetchingFormData: false,
  transitReview: {
    transitJob: null,
    fetching: false,
    error: null,
    submiting: false,
    driverImageUrl: '',
    driverDetails: {}
  }
}

export const getInitialState = (config) => ({
  options: {},
  ...state,
  form: {...transitSchema},
  editState: {success: false, error: false, pending: false},
  list: {
    ...state.list,
    ...config
  }
})
