import {call, put, select, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import {fetchAuctionSchedulesList} from './actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as AuctionApi from '../../api/auction/requests'
import pickBy from 'lodash/pickBy'

const getErrorMessage = (error) => {
  let errorMessage = ''

  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.reduce(
      (acc, tally) => `${acc}\n${tally.message}`,
      ''
    )
  }

  return errorMessage || error.message
}

function* fetchAuctionSchedulesListSaga() {
  try {
    const countryCode = yield select(getCountryCode)

    const options = {
      country: countryCode
    }

    const auctionSchedulesList = yield call(AuctionApi.getAuctionSchedules, {
      ...pickBy(options)
    })

    yield put({
      type: types.fetchAuctionSchedulesListSuccess,
      payload: auctionSchedulesList.auctionFlowConfigDB
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e,
        open: true
      })
    )

    yield put({
      type: types.fetchAuctionSchedulesListError,
      payload: e
    })
  }
}

function* createAuctionFlowSaga(action) {
  try {
    const auctionFlow = yield call(AuctionApi.createAuctionFlow, action.payload)

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.auctionFlow.new.success',
        open: true
      })
    )

    yield put({
      type: types.createAuctionFlowSuccess,
      payload: auctionFlow
    })

    yield put(fetchAuctionSchedulesList())
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: getErrorMessage(e),
        open: true
      })
    )

    yield put({
      type: types.createAuctionFlowError,
      payload: e
    })
  }
}

function* deleteAuctionFlowSaga(action) {
  try {
    const result = yield call(AuctionApi.deleteAuctionFlow, {
      id: action.payload
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.auctionFlow.delete.success',
        open: true
      })
    )

    yield put({
      type: types.deleteAuctionFlowSuccess,
      payload: result
    })

    yield put(fetchAuctionSchedulesList())
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: getErrorMessage(e),
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchAuctionSchedulesList, fetchAuctionSchedulesListSaga),
  takeLatest(types.createAuctionFlow, createAuctionFlowSaga),
  takeLatest(types.deleteAuctionFlow, deleteAuctionFlowSaga)
]
