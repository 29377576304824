import {PureComponent} from 'react'
import {Button} from '@material-ui/core'
import {translate} from 'react-i18next'
import {toggleOpen} from './store/actions'
import {RenderIf} from '../../RenderIf'

class CalendarInfo extends PureComponent {
  render() {
    const {
      t,
      submit,
      reset,
      calendarId,
      selected,
      showClear,
      showCancel
    } = this.props

    return (
      <footer className='date-picker__footer'>
        <section className='date-picker__info'></section>
        <section className='date-picker__actions'>
          <RenderIf if={showCancel}>
            <Button onClick={() => toggleOpen(calendarId)}>
              {t('button.cancel')}
            </Button>
          </RenderIf>
          <RenderIf if={showClear}>
            <Button onClick={() => reset(calendarId)}>
              {t('button.clear')}
            </Button>
          </RenderIf>
          <Button
            variant='raised'
            color='secondary'
            onClick={submit}
            disabled={!selected}
            data-test='filter-apply'
          >
            {t('button.apply')}
          </Button>
        </section>
      </footer>
    )
  }
}

export default translate()(CalendarInfo)
