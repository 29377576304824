import {ApiClient} from '@fcg/admin-api-client'

const client = new ApiClient('auth')
import {formatUserClass, formatCountry} from '../helpers/transformHelpers'
import User from '../../types/User'
import Country from '../../types/Country'

const userListFields = [['list', ['id', 'firstname', 'lastname']]]

export const getUsers = (countryCode: Country) => {
  const params = {
    deletedUser: false,
    userClass: 'INTERNAL',
    country: countryCode
  }

  return client
    .withTransform([formatUserClass, formatCountry])
    .query('user', params, userListFields)
    .then((response: {user: {list: User[]}}) =>
      response?.user?.list?.filter(
        (user) => user.firstname !== '' && user.lastname !== ''
      )
    )
}
