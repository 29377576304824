import styled from 'styled-components'

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  margin: 0 8px 8px 0;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
`

export const HeaderTitle = (props) => {
  if (!props.data.id) {
    return null
  }

  const numOfCar = props.data.transits.length
  const isPlural = props.data.transits.length > 1
  const startPlace = props.data.startPlace

  return (
    <Title>{`${numOfCar} car${isPlural ? 's' : ''} from ${startPlace}`}</Title>
  )
}
