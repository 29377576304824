let cmsLink = null
let duplicateInternalds = null

if (typeof window !== 'undefined') {
  cmsLink = require('../../../src/pages/BulkUpload/CmsLink')
  duplicateInternalds = require('../../../src/pages/BulkUpload/DuplicateInternalds')
}

module.exports = {
  bulkUploadColumn: [
    'make',
    'model',
    'year',
    'trim',
    'mileage',
    'color',
    'vin',
    'licensePlate',
    'engineNumber',
    'price',
    'additionalFees',
    'cmsLink'
  ],
  bulkUploadExtraFields: {
    duplicateInternalds: {
      key: 'duplicateInternalds',
      hideTitle: true,
      label: 'carData.column.internalId',
      Component: duplicateInternalds,
      type: 'custom',
      isFilterable: false,
      isEditable: false
    }
  },
  bulkUploadFields: {
    make: {
      key: 'make',
      label: 'carData.column.make',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    model: {
      key: 'model',
      label: 'carData.column.model',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    year: {
      key: 'year',
      label: 'carData.column.year',
      type: 'number',
      format: false,
      isFilterable: false,
      isEditable: true
    },
    trim: {
      key: 'trim',
      label: 'car.page.field.trim',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    mileage: {
      key: 'mileage',
      label: 'global.mileage.label',
      type: 'number',
      format: false,
      isFilterable: false,
      isEditable: true
    },
    color: {
      key: 'color',
      label: 'car.page.field.color',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    vin: {
      key: 'vin',
      label: 'car.page.field.vin',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    licensePlate: {
      key: 'licensePlate',
      label: 'car.page.field.licensePlate',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    engineNumber: {
      key: 'engineNumber',
      label: 'car.page.field.engineNumber',
      type: 'string',
      isFilterable: false,
      isEditable: true
    },
    price: {
      key: 'price',
      label: 'car.page.field.marketplacePrice',
      type: 'currency',
      currency: true,
      isFilterable: false,
      isEditable: true
    },
    additionalFees: {
      key: 'additionalFees',
      label: 'car.page.field.additionalFees',
      type: 'number',
      format: false,
      isFilterable: false,
      isEditable: true
    },
    cmsLink: {
      key: 'cmsLink',
      label: '',
      type: 'custom',
      isSortable: false,
      Component: cmsLink,
      isFilterable: false,
      isEditable: false,
      align: 'left'
    }
  },
  hidden: {}
}
