import {connect} from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import {
  getContactList,
  toggleSort,
  setSort,
  toggleField,
  updateFilters
} from '../../../store/crm/contact/actions'
import * as selectors from '../../../store/crm/contact/selectors'
import {getHiddenFeatures} from '../../../store/common/selectors'
import PageTableBase from '../../../components/PageTableBase'

const Contact = (props) => {
  const {open, handleClose, ...tableProps} = props

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='contacts-table'>
      <DialogContent>
        <PageTableBase
          hasSave={false}
          withVisibility={false}
          multisort={false}
          {...tableProps}
          filterable
          hasFooter={false}
          onRowClick={props.addParticipants}
          title='entity.contact'
        />
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {list} = state.contact

  return {
    ...list,
    data: Object.values(list.data),
    fields: selectors.getContactFields(state),
    filterFields: selectors.getContactFilters(state),
    currentPage: list.page,
    error: false,
    pageConfig: {
      hidden: {
        ...getHiddenFeatures(state)
      },
      ...ownProps.pageConfig
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(getContactList(args)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  onFilterChange: (filter) => dispatch(updateFilters(filter)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  updateSort: (sort) => dispatch(setSort(sort))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
