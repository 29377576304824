import React, {useContext} from 'react'
import {Router, Route, Switch} from 'react-router'

import {history} from '../../../history'

import ValidationContextProvider from '../context/ValidationContext'
import {MainContext} from '../context/MainContext'
import {GlobalContext} from '../context/GlobalContext'

import {getPlacesList, getCities, getLocationTypes} from '../api/car/requests'
import {getGlobalTemplate} from '../api/booking/requests'

import LocationPage from './LocationPage/LocationPage'
import LocationList from './LocationList/LocationList'

import {setValidationSchema} from '../hooks/useValidation/validationHelpers'

import Country from '../types/Country'
import Location from '../types/Location'
import GlobalTemplate from '../types/GlobalTemplate'
import {COUNTRY_CONFIG} from '../config/countryConfig'
import {getUsers} from '../api/auth/requests'
import User from '../types/User'

export const PageRoutes = ({country}: {country: Country}) => {
  const {
    cities,
    locationTypes,
    areaManagers,
    countryCode,
    locationList,
    setLocationList,
    setCountryCode,
    setCities,
    setLocationTypes,
    setAreaManagers
  } = useContext(MainContext)
  const {globalPlaceConfiguration, setGlobalTemplate} = useContext(
    GlobalContext
  )

  const fetchGlobalTemplate = (country: Country) => {
    getGlobalTemplate(country).then(
      (globalTemplate: GlobalTemplate) => {
        setGlobalTemplate(globalTemplate)
      },
      (e: Error) => {
        console.log(e)
      }
    )
  }

  React.useEffect(() => {
    if (country) {
      if (!countryCode) {
        setCountryCode(country)
        setValidationSchema(country)
      }

      if (!globalPlaceConfiguration) {
        fetchGlobalTemplate(country)
      }

      if (locationList.length === 0) {
        getPlacesList(country).then(
          (locationList: Location[]) => {
            setLocationList(locationList)
          },
          (e: Error) => console.log(e) // TODO ??
        )
      }

      if (
        COUNTRY_CONFIG.showAreaManagerField[country] &&
        areaManagers.length === 0
      ) {
        getUsers(country).then((userList: User[]) => setAreaManagers(userList))
      }

      if (cities.length === 0) {
        getCities(country).then((cities: string[]) => {
          setCities(cities)
        })
      }

      if (locationTypes.length === 0) {
        getLocationTypes(country).then((types: string[]) => {
          setLocationTypes(types)
        })
      }
    }
  }, [country])
  if (locationList.length === 0) return null

  return (
    <Switch>
      <Route exact path='/location-manager'>
        <LocationList />
      </Route>
      <Route path='/location-manager/:id'>
        <ValidationContextProvider>
          <LocationPage />
        </ValidationContextProvider>
      </Route>
    </Switch>
  )
}

const PageRouter = ({country}: {country: Country}) => (
  <Router history={history}>
    <PageRoutes country={country} />
  </Router>
)

export default PageRouter
