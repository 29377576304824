import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

const RowDescription = ({
  title,
  description,
  xs = 3
}: {
  title: string
  description?: string
  xs?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}) => {
  const classes = useCommonStyles()

  return (
    <Grid item xs={xs}>
      <Typography variant='body1' gutterBottom classes={{body1: classes.bold}}>
        {title}
      </Typography>
      {description && (
        <Typography
          color='textSecondary'
          variant='body2'
          gutterBottom
          className={(classes.letterSpacingNormal, classes.paddingRight2)}
        >
          {description}
        </Typography>
      )}
    </Grid>
  )
}

export default RowDescription
