import * as fields from './fields'
import axios from 'axios'
import gql from '@fcg/lib-gql'
import {getCountryConfig} from '../../../../app/config/country'

const {
  siteCode: sitecode,
  countryCode,
  apiDomain,
  categoryId: category_id,
  fetchPriceQuoteFromAIP: fetchCodeForAip
} = getCountryConfig()

const grootUrlStaging = ['test', 'staging', 'production'].includes(environment)
  ? apiDomain
  : 'api.olxautos.com'

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const paramFilter = (params) => {
  let result = {category_id}
  if (params['trim']) {
    params['variant'] = params['trim']
    delete params['trim']
  }
  for (let item in params) {
    if (params[item] !== undefined && item !== 'country') {
      result[camelToSnakeCase(item)] =
        item === 'year' ? params[item].toString() : params[item]
    }
  }

  return result
}

function query(method, args, responseStructure) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://${grootUrlStaging}/groot/api/v1/category/value`,
        gql(method, args, responseStructure),
        {
          headers: {
            'content-type': 'application/graphql'
          }
        }
      )
      .then((_data) => {
        const {data} = _data
        const list = data[Object.keys(data)[0]] // data.make
        let result = Object.values(list[Object.keys(list)[0]])
        let keyName = Object.keys(list)[0]
        let arr = []
        for (let item of result[0]) {
          if (
            keyName === 'make' ||
            keyName === 'model' ||
            keyName === 'variant'
          ) {
            arr.push(item)
          } else {
            arr.push(item.name ? item.name : Number(item))
          }
        }

        resolve(arr)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function getCarDataMake(params = {}) {
  const filter = paramFilter(params)
  return query(
    'make',
    {
      sitecode,
      filter
    },
    fetchCodeForAip ? fields.listNameWithCode : fields.listName
  )
}

export function getCarDataModel(params = {}) {
  const filter = paramFilter(params)
  if (countryCode === 'IN') {
    filter.buyable = new gql.EnumType('BUYABLE')
  }
  return query(
    'model',
    {
      sitecode,
      filter
    },
    fetchCodeForAip ? fields.listNameWithCode : fields.listName
  )
}

export function getCarDataYear(params = {}) {
  const filter = paramFilter(params)
  if (countryCode === 'IN') {
    filter.buyable = new gql.EnumType('BUYABLE')
  }
  return query(
    'year',
    {
      sitecode,
      filter
    },
    fields.year
  )
}

export function getCarDataBodyType(params = {}) {
  let filter = paramFilter(params)
  return query(
    'bodyType',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}

export function getCarDataFaceLift(params = {}) {
  let filter = paramFilter(params)
  return query(
    'faceLift',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}

export function getCarDataSeat(params = {}) {
  let filter = paramFilter(params)
  return query(
    'seat',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}

export function getCarDataEngine(params = {}) {
  let filter = paramFilter(params)
  return query(
    'engine',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}

export function getCarDataWheelDrive(params = {}) {
  let filter = paramFilter(params)
  return query(
    'wheelDrive',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}

export function getCarDataTrim(params = {}) {
  const filter = paramFilter(params)
  if (countryCode === 'IN') {
    filter.buyable = new gql.EnumType('BUYABLE')
  }
  return query(
    'variant',
    {
      sitecode,
      filter
    },
    fetchCodeForAip ? fields.listNameWithCode : fields.listName
  )
}
export function getCarDataTransmission(params = {}) {
  let filter = paramFilter(params)
  return query(
    'transmission',
    {
      sitecode,
      filter
    },
    fields.listName
  )
}
