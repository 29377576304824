const dealerDetails = require('./dealerManagement/pe')
const crmDetails = require('./CRM/pe')
const bulkuploadDetails = require('./bulkUpload/pe')
const carDataDetails = require('./carData/pe')
const locationDetails = require('./locationManagement/pe')
const payslipDetails = require('./payslips/pe')
const carOwnership = require('./carOwnership/pk')
const businessPartnersDetails = require('./businessPartnersDetails/pe')

module.exports = {
  url: 'autos.olx.com.pe',
  siteCode: 'olxpe',
  apiDomain: 'api.olx.com.pe',
  name: 'Peru',
  countryCode: 'PE',
  theme: 'peru',
  language: {
    selected: 'es-pe',
    options: [
      {value: 'es-pe', label: 'ES'},
      {value: 'en-pe', label: 'EN'}
    ]
  },
  locales: ['es-pe', 'en-pe'],
  currency: {code: 'USD', symbol: '$'},
  number: {decimalSep: ',', unitSep: '.', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 51},
  referralCodeType: 'input',
  googleKey:
    '940673976550-naj8k2mhp404ljudl23fsgmug4vr9bhf.apps.googleusercontent.com',
  okta: {
    domain: 'olxgroup.okta-emea.com',
    clientId: '0oa83j26xzGIx5HnW0i7'
  },
  createCarViaAppointment: true,
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  carDuplicatedCheckField: 'licensePlate',
  auctionPreviewOffset: 6 * 60,
  crmDetails,
  bulkuploadDetails,
  locationDetails,
  carDataDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      },
      vin: {
        regex: '^.{1,17}$'
      },
      licensePlate: {
        regex: '^.{1,6}$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'wms',
    'tms',
    'bulkUploadV2',
    'attendanceManager',
    'documentManagement',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: false
  },
  callingCode: '+51',
  disbableCallingCodeInBP: true,
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  categoryId: 378,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
