export const task = {
  fetching: 'crm/detail/tasks/LOADING',
  dataLoaded: 'crm/detail/tasks/LOADED',
  toggleField: 'crm/detail/tasks/TOGGLE_FIELD',
  changePage: 'crm/detail/tasks/CHANGE_PAGE',
  toggleSort: 'crm/detail/tasks/TOGGLE_SORT',
  setSort: 'crm/detail/tasks/SET_SORT',
  updateFilters: 'crm/detail/tasks/UPDATE_FILTER'
}

export const lead = {
  fetching: 'crm/detail/leads/LOADING',
  dataLoaded: 'crm/detail/leads/LOADED',
  toggleField: 'crm/detail/leads/TOGGLE_FIELD',
  changePage: 'crm/detail/leads/CHANGE_PAGE',
  toggleSort: 'crm/detail/leads/TOGGLE_SORT',
  setSort: 'crm/detail/leads/SET_SORT',
  updateFilters: 'crm/detail/leads/UPDATE_FILTER'
}

export const comment = {
  fetching: 'crm/detail/comments/LOADING',
  dataLoaded: 'crm/detail/comments/LOADED',
  toggleField: 'crm/detail/comments/TOGGLE_FIELD',
  changePage: 'crm/detail/comments/CHANGE_PAGE',
  toggleSort: 'crm/detail/comments/TOGGLE_SORT',
  setSort: 'crm/detail/comments/SET_SORT',
  updateFilters: 'crm/detail/comments/UPDATE_FILTER'
}

export const changes = {
  fetching: 'crm/detail/changes/LOADING',
  dataLoaded: 'crm/detail/changes/LOADED',
  toggleField: 'crm/detail/changes/TOGGLE_FIELD',
  changePage: 'crm/detail/changes/CHANGE_PAGE',
  toggleSort: 'crm/detail/changes/TOGGLE_SORT',
  setSort: 'crm/detail/changes/SET_SORT',
  updateFilters: 'crm/detail/changes/UPDATE_FILTER'
}

export const contactDetailLoaded = 'crm/detail/CONTACT_LOADED'
export const contactDetailFetching = 'crm/detail/CONTACT_LOADING'
export const contactDataNotFound = 'crm/detail/NOT_FOUND'

export const logsLoaded = 'crm/logs/LOGS_LOADED'
export const logsFetching = 'crm/logs/LOGS_LOADING'
export const logsDataNotFound = 'crm/logs/NOT_FOUND'
