import React, {useState, useEffect} from 'react'
import update from 'immutability-helper'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'
import RowDescription from '../../../../components/Form/RowDescription'
import {convertMinToHours} from '../utils'
import {minMaxAllowedTimeOptions, AllowedTimeOption} from '../../../../config/constants'
import ServiceConfiguration from '../../../../types/ServiceConfiguration'

const GlobalServiceConfiguration = ({
  configuration,
  onChange = () => {}
}: {
  configuration: ServiceConfiguration
  onChange: (field:keyof ServiceConfiguration, value: number|string) => void
}) => {
  const classes = useCommonStyles()
  const findIndex = (label:string) => {
    return minMaxAllowedTimeOptions.findIndex((option) => {
      return option.label === label
    })
  }

  const filterUnavailableOptions = (selected:AllowedTimeOption) => {
    const optionIndex = findIndex(selected.label)

    return update(minMaxAllowedTimeOptions, {
      $splice: [[0, optionIndex]]
    })
  }

  const returnTimeOption = (key: 'max'| 'min'):AllowedTimeOption => {
    const valueKey = `${key}AllowedTime` as 'minAllowedTime'|'maxAllowedTime'
    const unitKey = `${key}AllowedTimeUnit` as 'minAllowedTimeUnit'|'maxAllowedTimeUnit'

    return minMaxAllowedTimeOptions.find((option:AllowedTimeOption) => {
      return (
        option.value === configuration[valueKey] &&
        option.unit === configuration[unitKey]
      )
    })!
  }
  const minAllowedTimeValue = returnTimeOption('min')

  const maxAllowedTimeValue = returnTimeOption('max')

  const maxAllowedTimeInitialOptions = filterUnavailableOptions(
    minAllowedTimeValue
  )
  const [maxAllowedTimeOptions, setMaxAllowedTimeOptions] = useState<AllowedTimeOption[]>(
    maxAllowedTimeInitialOptions
  )

  useEffect(() => {
    const newOptions = filterUnavailableOptions(minAllowedTimeValue)
    setMaxAllowedTimeOptions(newOptions)
  }, [configuration])

  const durationObject = convertMinToHours(configuration.duration)
  const intervalObject = convertMinToHours(configuration.interval)

  const handleMinMaxAllowedTimeChange = (e:React.ChangeEvent<{ value: AllowedTimeOption, name:'minAllowedTime'|'maxAllowedTime' }>) => {
    const selected:AllowedTimeOption = e.target.value
    const name = e.target.name
    if (name === 'minAllowedTime') {
      const minIndex = findIndex(selected.label)
      const maxIndex = findIndex(maxAllowedTimeValue?.label) || minIndex
      if (maxIndex < minIndex) {
        //maxAllowedTime cannot be smaller then minAllowedTime, we send null to remove no more invalid option - type error can be ignored
        onChange('maxAllowedTime', null)
      }
    }
    onChange(name, selected.value)
    onChange((`${name}Unit` as 'minAllowedTimeUnit'|'maxAllowedTimeUnit'), selected.unit)
  }

  const handleChange = (e:React.ChangeEvent<{value:string, name:'duration'|'interval'}>, constValue:number, type:'hours'|'minutes') => {
    if (e.target.value.length > 2) return false

    let value = parseInt(e.target.value || '1') //parseInt(NaN) === NaN
    const name = e.target.name
    if (type === 'hours') {
      //constValue => minutes
      value = constValue + value * 60
    } else {
      //constValue => hours
      value = constValue * 60 + value
    }
    onChange(name, value)
  }

  return (
    <>
      <Grid
        container
        className={cx(
          classes.borderBottom,
          classes.paddingY2,
          classes.itemsCenter
        )}
      >
        <RowDescription
          title='Service duration'
          description='How long does the service last?'
        />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6} className={classes.paddingRight2}>
              <TextField
                label='Hours'
                type='number'
                name='duration'
                className={classes.fullWidth}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 12,
                    maxLength: 2,
                    value: durationObject.hours
                  }
                }}
                inputProps={{
                  maxLength: 2
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e:React.ChangeEvent<{value:string, name:'duration'|'interval'}>) => {
                  handleChange(e, durationObject.minutes, 'hours')
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.paddingLeft2}>
              <TextField
                label='Minutes'
                type='number'
                name='duration'
                className={classes.fullWidth}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 55,
                    step: 5,
                    maxLength: 2,
                    value: durationObject.minutes
                  }
                }}
                inputProps={{
                  maxLength: 2
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e:React.ChangeEvent<{value:string, name:'duration'|'interval'}>) => {
                  handleChange(e, durationObject.hours, 'minutes')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid
        container
        className={cx(
          classes.borderBottom,
          classes.paddingY2,
          classes.itemsCenter
        )}
      >
        <RowDescription
          title='Service interval'
          description='How much time is there in between the slots?'
        />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6} className={classes.paddingRight2}>
              <TextField
                label='Hours'
                type='number'
                name='interval'
                className={classes.fullWidth}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 12,
                    maxLength: 2,
                    value: intervalObject.hours
                  }
                }}
                inputProps={{
                  maxLength: 2
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e:React.ChangeEvent<{value:string, name:'duration'|'interval'}>) => {
                  handleChange(e, intervalObject.minutes, 'hours')
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.paddingLeft2}>
              <TextField
                label='Minutes'
                type='number'
                name='interval'
                className={classes.fullWidth}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 55,
                    step: 5,
                    maxLength: 2,
                    value: intervalObject.minutes
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e:React.ChangeEvent<{value:string, name:'duration'|'interval'}>) => {
                  handleChange(e, intervalObject.hours, 'minutes')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid
        container
        className={cx(
          classes.borderBottom,
          classes.paddingY2,
          classes.itemsCenter
        )}
      >
        <RowDescription
          title='Slot visibility'
          description='From when until when will the slots be visible to the client?'
        />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6} className={classes.paddingRight2}>
              <FormControl className={classes.fullWidth}>
                <InputLabel>Minimum allowed service</InputLabel>
                <Select
                  fullWidth
                  name='minAllowedTime'
                  value={minAllowedTimeValue}
                  onChange={handleMinMaxAllowedTimeChange}
                >
                  {minMaxAllowedTimeOptions.map((option) => {
                    return (
                      <MenuItem key={option.label} value={option}>
                        {option.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.paddingLeft2}>
              <FormControl className={classes.fullWidth}>
                <InputLabel>Maximum allowed service</InputLabel>
                <Select
                  fullWidth
                  error={!maxAllowedTimeValue}
                  name='maxAllowedTime'
                  value={maxAllowedTimeValue}
                  onChange={handleMinMaxAllowedTimeChange}
                >
                  {maxAllowedTimeOptions.map((option:any) => {
                    return (
                      <MenuItem key={option.label} value={option}>
                        {option.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  )
}

export default GlobalServiceConfiguration
