import {withValidation, validatorFields} from '@fcg/formvalidation'
import errorMessageAdapter from './errorMessageAdapter'
import {DatePicker} from '@fcg/form-builder'

const ValidatedDatePicker = withValidation(validatorFields.dateInput)(
  errorMessageAdapter(DatePicker)
)

ValidatedDatePicker.type = 'ValidatedDatePicker'

export default ValidatedDatePicker
