export const csvHeaders = [
    'locationName',
    'resourceName',
    'date',
    'startTime',
    'endTime',
    'maxCapacity'
]

export const UploadHistoryHeaders = [
    'Date/time',
    'Filename',
    'Uploaded By',
    'Status'
]

export const statusColor = {
    'PARTIALLY':'#e9a53d',
    'SUCCESS':'#8ddc55',
    'DUPLICATE':'#8ddc55',
    'FAIL':'#dc6355'
}
