import {createSelector} from 'reselect'
import differenceWith from 'lodash/differenceWith'

import {getCountryConfig} from '../../../config/country'
import {getCountryCode} from '../config/selectors'

const selectorPath = (state) => state.authReducer
const getGroups = (state) => {
  if (getUser(state)) {
    return getUser(state).groups
  }

  return []
}

export const getUser = (state) => selectorPath(state).isAuthenticated.user
export const getUserInfo = (state) => selectorPath(state).userInfo

export const getMetaField = (field) =>
  createSelector(getUserInfo, (user) => {
    if (user && user.metaInfo && user.metaInfo[field]) {
      return user.metaInfo[field]
    }

    return null
  })

export const userPermissions = (state, {entity, types}) => {
  let userGroups = []
  const {user} = state.authReducer.isAuthenticated

  if (user && Array.isArray(user.groups)) {
    userGroups = user.groups
  }

  return {
    permissions: state.authReducer.userInfo.permissions,
    userGroups,
    entity,
    types
  }
}

export const checkUserPermissions = createSelector(
  userPermissions,
  ({permissions, userGroups, entity, types}) => {
    const requiredPermissions = types.map((type) => ({
      entity,
      type
    }))

    const missingPermissions = differenceWith(
      requiredPermissions,
      permissions,
      (a, b) => a.entity === b.entity && a.type === b.type
    )

    return (
      missingPermissions.length === 0 || userGroups.indexOf('superadmin') > -1
    )
  }
)

export const checkUserManagerPermission = createSelector(
  [getGroups, getCountryCode],
  (group, countryCode) => {
    if (
      group.length &&
      (group.includes(`usermanager.${countryCode.toLowerCase()}`) ||
        group.includes('usermanager.all') ||
        group.includes('superadmin'))
    ) {
      return true
    }

    return false
  }
)

export const checkSelfInspectionPermission = createSelector(
  [getGroups, getCountryCode],
  (group, countryCode) =>
    group.length &&
    (group.includes(`inspector.${countryCode.toLowerCase()}.consumer`) ||
      group.includes(`inspector.${countryCode.toLowerCase()}`) ||
      group.includes('superadmin'))
)

export const checkPermission = (permission) =>
  createSelector([getGroups, getCountryCode], (group, countryCode) => {
    if (
      group.length &&
      (group.includes(`${permission}.${countryCode.toLowerCase()}`) ||
        group.includes(`${permission}.all`))
    ) {
      return true
    }

    return false
  })

export const hasUserPermissions = createSelector(getUserInfo, (userInfo) =>
  Boolean(userInfo.permissions && userInfo.permissions.length)
)

export const isSubmitting = (state) => selectorPath(state).isSubmitting
export const getError = (state) => selectorPath(state).error
export const getOAuthParams = (state) => selectorPath(state).oauthParams
export const getGoogleAuthFunc = (state) => selectorPath(state).googleAuth
export const getOktaAuthFunc = (state) => selectorPath(state).oktaAuth
export const getCountryName = () => getCountryConfig().name
