import Weekday from '../types/Weekday'
import GlobalPlaceConfiguration from '../types/GlobalPlaceConfiguration'
import ServiceConfiguration from '../types/ServiceConfiguration'
import AllowedTimeUnit from '../types/AllowedTimeUnit'
import ServiceType from '../types/ServiceType'
import Location from '../types/Location'

export const serviceKeys: ServiceType[] = [
  'INSPECTIONCRM',
  'INSPECTION',
  'INSPECTIONCARPRO',
  'PICKUP',
  'INSPECTIONVIDEO',
  'INSPECTIONVIDEOCRM',
  'INSPECTIONHOME',
  'INSPECTIONHOMECRM',
  'DEALERINSPECTIONHOME',
  'DEALERINSPECTIONHOMECRM',
  'DEALERINSPECTIONRETAIL',
  'DEALERINSPECTIONRETAILCRM',
  'DEALERINSPECTIONVIDEO',
  'DEALERINSPECTIONVIDEOCRM',
  'C2CMEETINGHOME',
  'C2CMEETINGHOMECRM',
  'C2CMEETINGRETAIL',
  'C2CMEETINGRETAILCRM',
  'C2CMEETINGVIDEO',
  'C2CMEETINGVIDEOCRM'
]

export const serviceTypes: {[type in ServiceType]: string} = Object.freeze({
  INSPECTIONCRM: 'Walk-in',
  INSPECTION: 'Inspection',
  INSPECTIONCARPRO: 'Inspection CarPro',
  PICKUP: 'Pick up',
  INSPECTIONVIDEO: 'Video Inspection',
  INSPECTIONVIDEOCRM: 'Video Inspection Call Center',
  INSPECTIONHOME: ' Home Inspections',
  INSPECTIONHOMECRM: 'Home Inspection Call Center',
  DEALERINSPECTIONHOME: 'Dealer Home Inspection',
  DEALERINSPECTIONHOMECRM: 'Dealer Home Inspection Call Center',
  DEALERINSPECTIONRETAIL: 'Dealer Retail Inspection',
  DEALERINSPECTIONRETAILCRM: 'Dealer Retail Inspection Call Center',
  DEALERINSPECTIONVIDEO: 'Dealer Video Inspection',
  DEALERINSPECTIONVIDEOCRM: 'Dealer Video Inspection Call Center',
  C2CMEETINGHOME: 'C2C Meeting Home',
  C2CMEETINGHOMECRM: 'C2C Meeting Home Call Center',
  C2CMEETINGRETAIL: 'C2C Meeting Retail',
  C2CMEETINGRETAILCRM: 'C2C Meeting Call Center',
  C2CMEETINGVIDEO: 'C2C Meeting Video',
  C2CMEETINGVIDEOCRM: 'C2C Meeting Call Center'
})

export const DAYS: Weekday[] = [1, 2, 3, 4, 5, 6, 7]

export const weekdayNames: {[day in Weekday]: string} = Object.freeze({
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
})

export const weekdayShortString: {[day in Weekday]: string} = {
  1: 'Mo',
  2: 'Tu',
  3: 'We',
  4: 'Th',
  5: 'Fr',
  6: 'Sa',
  7: 'Su'
}

export const defaultTimeRange = {
  openingTime: '10:00:00',
  closingTime: '18:00:00',
  closed: false,
  slots: 2
}

export type AllowedTimeOption = {
  value: number
  unit: AllowedTimeUnit
  label: string
}

export const minMaxAllowedTimeOptions: AllowedTimeOption[] = [
  {value: 1, unit: 'MINUTE', label: '1 minute'},
  {value: 5, unit: 'MINUTE', label: '5 minutes'},
  {value: 10, unit: 'MINUTE', label: '10 minutes'},
  {value: 15, unit: 'MINUTE', label: '15 minutes'},
  {value: 20, unit: 'MINUTE', label: '20 minutes'},
  {value: 30, unit: 'MINUTE', label: '30 minutes'},
  {value: 1, unit: 'HOUR', label: '1 hours'},
  {value: 2, unit: 'HOUR', label: '2 hours'},
  {value: 3, unit: 'HOUR', label: '3 hours'},
  {value: 4, unit: 'HOUR', label: '4 hours'},
  {value: 5, unit: 'HOUR', label: '5 hours'},
  {value: 6, unit: 'HOUR', label: '6 hours'},
  {value: 7, unit: 'HOUR', label: '7 hours'},
  {value: 8, unit: 'HOUR', label: '8 hours'},
  {value: 9, unit: 'HOUR', label: '9 hours'},
  {value: 10, unit: 'HOUR', label: '10 hours'},
  {value: 11, unit: 'HOUR', label: '11 hours'},
  {value: 12, unit: 'HOUR', label: '12 hours'},
  {value: 1, unit: 'DAY', label: '1 day'},
  {value: 2, unit: 'DAY', label: '2 days'},
  {value: 3, unit: 'DAY', label: '3 days'},
  {value: 4, unit: 'DAY', label: '4 days'},
  {value: 5, unit: 'DAY', label: '5 days'},
  {value: 6, unit: 'DAY', label: '6 days'},
  {value: 1, unit: 'WEEK', label: '1 week'},
  {value: 2, unit: 'WEEK', label: '2 weeks'}
]

export const serviceConfigurationTemplate: ServiceConfiguration = {
  type: [],
  duration: 45,
  interval: 10,
  minAllowedTime: 1,
  minAllowedTimeUnit: 'MINUTE',
  maxAllowedTime: 2,
  maxAllowedTimeUnit: 'WEEK'
}

export const globalServiceConfigurationTemplate: ServiceConfiguration[] = [
  {
    type: ['INSPECTIONCARPRO'],
    duration: 45,
    interval: 10,
    minAllowedTime: 1,
    minAllowedTimeUnit: 'MINUTE',
    maxAllowedTime: 2,
    maxAllowedTimeUnit: 'WEEK'
  },
  {
    type: ['PICKUP'],
    duration: 45,
    interval: 10,
    minAllowedTime: 1,
    minAllowedTimeUnit: 'MINUTE',
    maxAllowedTime: 2,
    maxAllowedTimeUnit: 'WEEK'
  },
  {
    type: ['INSPECTIONCRM'],
    duration: 45,
    interval: 10,
    minAllowedTime: 1,
    minAllowedTimeUnit: 'MINUTE',
    maxAllowedTime: 2,
    maxAllowedTimeUnit: 'WEEK'
  },
  {
    type: ['INSPECTION'],
    duration: 45,
    interval: 10,
    minAllowedTime: 1,
    minAllowedTimeUnit: 'MINUTE',
    maxAllowedTime: 2,
    maxAllowedTimeUnit: 'WEEK'
  }
]

export const globalPlaceConfigurationTemplate: GlobalPlaceConfiguration = {
  email: '',
  image: ''
}

export const DEFAULT_LOCATIONS: {
  [key: string]: Location | GlobalPlaceConfiguration
} = {
  master: {
    id: 'master',
    email: '',
    image: ''
  },
  newLocation: {
    id: 'newLocation',
    place: '',
    location: '',
    city: '',
    type: [],
    bIMapping: '',
    sapPlantId: '',
    sapStorageLocationId: '',
    lat: '',
    lng: '',
    active: true,
    region: '',
    address1: '',
    metaInfo: {
      locationPhone: '',
      locationEmail: '',
      imageUrl: '',
      metaTitle: '',
      metaDescription: '',
      optionalText: '',
      mapUrl: ''
    }
  }
}
