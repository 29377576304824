import React from 'react'
import {connect} from 'react-redux'
import {redirectToContact} from '../../../store/crm/contact/actions'

class DialerEventListener extends React.Component {
  static displayName = 'Incoming Call Dialog'

  componentDidMount() {
    window.showCrm = this.customShowCrm.bind(this)
  }

  customShowCrm(phoneNumber) {
    if (phoneNumber) {
      console.log('SHOW_CRM_CALLED', Date.now())
      try {
        performance.mark('SHOW_CRM_EVENT_RECEIVED')
      } catch (e) {}
      this.props.redirect(phoneNumber)
    }
  }

  render() {
    return null
  }
}

const mapDispatchToProps = (dispatch) => ({
  redirect: (phone) => {
    return dispatch(
      redirectToContact({
        // remove all the characters except digits
        phone: (phone || '').replace(/[^0-9]/g, '')
      })
    )
  }
})

export default connect(null, mapDispatchToProps)(DialerEventListener)
