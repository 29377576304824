import {Component, Fragment} from 'react'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Warning from '@material-ui/icons/Warning'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {connect} from 'react-redux'

import ValidatedSelect from '../../components/FormValidation/ValidatedSelectField'
import selectors from '../../store/transitJob/selectors'
import {updateTransitJobStatus} from '../../store/transitJob/actions'
import LoadingState from '../../components/LoadingState'
import {statusOptions, STATUS_TYPE} from './constants'

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 150px;
`

const Alert = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border: 1px solid #ff9800;
  border-radius: 4px;
  color: #673d00;
  font-size: 14px;
  margin: 12px 0 4px;
  align-items: center;
`

const WarningIcon = styled(Warning)`
  color: #ff9800;
  font-size: 20px !important;
  margin-right: 8px;
`

const getStatusOptions = (status) => {
  return statusOptions[status]
}

class UpdateStatusDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: null,
      hasFetched: false,
      showAlert: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetched && nextProps?.transitJob?.status) {
      return {
        status: nextProps.transitJob.status,
        hasFetched: true
      }
    }
  }

  handleChange = (e) => {
    if (
      this.state.showAlert &&
      ![STATUS_TYPE.FINISHED, STATUS_TYPE.ABORTED].includes(e.target.value)
    ) {
      this.setState({
        showAlert: false
      })
    }

    this.setState({status: e.target.value})
  }

  resetState = () => {
    this.setState({
      status: '',
      hasFetched: false,
      showAlert: false
    })
  }

  updateTransitJobStatus = () => {
    if (
      ![STATUS_TYPE.FINISHED, STATUS_TYPE.ABORTED].includes(
        this.state.status
      ) ||
      this.state.showAlert
    ) {
      this.props.updateTransitJobStatus({
        status: this.state.status,
        id: this.props.transitJobId,
        transits: this.props.transitJob.transits
      })
      this.props.closeDialog()
      this.resetState()
    } else {
      this.setState({
        showAlert: true
      })
    }
  }

  handleClose = () => {
    this.props.closeDialog()
    this.resetState()
  }

  render() {
    const {t, isOpen} = this.props
    let alertMessage = 'transitJob.status.update.warning.abortedJob'
    let buttonText = 'button.update'

    if (this.state.status === STATUS_TYPE.FINISHED) {
      alertMessage = 'transitJob.status.update.warning.finishedJob'
    }

    if (this.state.showAlert) {
      buttonText = 'button.updateAnyway'
    }

    return (
      <Dialog open={isOpen} maxWidth='sm' fullWidth>
        {!this.state.hasFetched ? (
          <LoaderWrapper>
            <LoadingState />
          </LoaderWrapper>
        ) : (
          <Fragment>
            <DialogTitle id='form-dialog-title'>
              {t('transitJob.status.update.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>{t('transitJob.status.update.info')}</div>
                {this.state.showAlert ? (
                  <Alert>
                    <WarningIcon />
                    <span>{this.props.t(alertMessage)}</span>
                  </Alert>
                ) : null}
                <ValidatedSelect
                  filterable
                  fullWidth
                  margin='normal'
                  id='status'
                  name='status'
                  required
                  label='transitJobsList.placeholder.status'
                  onChange={this.handleChange}
                  selected={this.state.status}
                  value={this.state.status}
                  options={getStatusOptions(this.props.transitJob.status)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>{t('button.cancel')}</Button>
              <Button
                onClick={this.updateTransitJobStatus}
                variant='raised'
                color='primary'
                data-test='update-transit-status'
                disabled={
                  !this.state.status ||
                  this.state.status === this.props.transitJob.status
                }
              >
                {t(buttonText)}
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  transitJob: selectors.getTransitJobById(state, {id: ownProps.transitJobId})
})

const mapDispatchToProps = (dispatch) => ({
  updateTransitJobStatus: (payload) => dispatch(updateTransitJobStatus(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStatusDialog)
