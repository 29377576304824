import * as types from './types'
import {getInitialState} from './initialState'

export default {
  [types.updateForm]: (state, action) => {
    const {entity, name, value} = action.payload

    return {
      ...state,
      [entity]: {
        ...state[entity],
        [name]: value
      }
    }
  },
  [types.resetForm]: () => {
    const initialState = getInitialState()

    return {
      ...initialState
    }
  },
  [types.formPending]: (state) => {
    return {
      ...state,
      editState: {
        ...state.editState,
        pending: true
      }
    }
  },
  [types.formSuccess]: (state, action) => {
    return {
      ...state,
      editState: {
        pending: false,
        error: false,
        success: action.payload
      }
    }
  },
  [types.formError]: (state, action) => {
    return {
      ...state,
      editState: {
        pending: false,
        error: action.payload,
        success: false
      }
    }
  }
}
