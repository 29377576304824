import {call, put, select, takeLatest} from 'redux-saga/effects'
import {showSnackbarNotification, showErrorMessage} from '../signals/actions'
import * as signalTypes from '../signals/types'
import * as types from './types'
import {STATUS_TYPE} from '../../pages/TransitJobs/constants'
import * as CarApi from '../../api/car/requests'
import * as DocumentApi from '../../api/document/requests'
import * as AuthApi from '../../api/auth/requests'
import selectors from './selectors'
import pickBy from 'lodash/pickBy'
import gql from '@fcg/lib-gql/gql'
import {getCountryCode} from '../config/selectors'
import {
  saveTransitSuccess,
  cancelTransitJobSuccess,
  resetTransitForm
} from './actions'
import {formatParams} from '../utils'
import omit from 'lodash/omit'
import {values} from 'ramda'

function* fetchTransitJobListSaga(action) {
  try {
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const filters =
      action.payload && action.payload.filters
        ? action.payload.filters
        : yield select(selectors.getFilters)
    const filterParams = formatParams(filters)
    const filterStatus = filterParams.status
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)
    const sort = yield select(selectors.getGqlSort)

    if (filterStatus) {
      filterParams.status = new gql.EnumType(filterStatus.toUpperCase())
    }

    let options = {
      limit,
      sort,
      page
    }

    if (values(filterParams).length) {
      options = {
        ...options,
        transitJob: filterParams
      }
    }

    const transitList = yield call(CarApi.getTransitsJobs, {
      ...pickBy(options)
    })

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchTransitJobListSuccess,
      payload: {
        ...transitList.transitJob,
        list: transitList.transitJob.list
      }
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchTransitJobListError,
      payload: e
    })
  }
}

function* cancelTransitJobSaga(action) {
  const transitJob = {id: action.payload.id}

  try {
    yield call(CarApi.cancelTransitsJob, action.payload)
    yield put(cancelTransitJobSuccess(transitJob))
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.transitJob.cancel.success',
        open: true
      })
    )
  } catch (e) {
    yield put(showErrorMessage(e.message))
  }
}

function* saveTransitJobSaga(action) {
  try {
    const transitJob = omit(action.payload, 'driver')
    let transitJobId
    let response
    const countryCode = yield select(getCountryCode)
    const transitType = new gql.EnumType(transitJob.transitType)

    if (transitJob.id !== null && typeof transitJob.id !== 'undefined') {
      yield call(CarApi.updateTransitsJob, {...transitJob, transitType})
    } else {
      response = yield call(CarApi.createTransitJob, {
        ...transitJob,
        transitType,
        country: countryCode
      })

      transitJobId =
        response && response.createTransitJob && response.createTransitJob.id
          ? response.createTransitJob.id
          : transitJob.id
    }

    yield put(saveTransitSuccess(transitJobId))
    yield put(resetTransitForm())
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: transitJob.id
          ? 'snackbar.notification.transitJob.update.success'
          : 'snackbar.notification.transitJob.create.success',
        open: true
      })
    )
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

function* hydrateTransitFormSaga(action) {
  try {
    yield put({
      type: types.setHydrateFormStatus,
      payload: true
    })
    const transitJobList = yield call(CarApi.getTransitsJobs, {
      transitJob: action.payload
    })

    yield put({
      type: types.hydrateForm,
      payload: transitJobList.transitJob.list[0]
    })
    yield put({
      type: types.setHydrateFormStatus,
      payload: false
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

// Transit Review
function* fetchTransitJobSaga(action) {
  try {
    const response = yield call(CarApi.getTransitsJobs, {
      transitJob: action.payload
    })

    yield put({
      type: types.fetchTransitJobSuccess,
      payload: response.transitJob.list[0]
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

function* finishTransitJobSaga(action) {
  try {
    const response = yield call(CarApi.finishTransitJob, {
      ...action.payload
    })

    if (response && response.finishTransitJob) {
      yield put({
        type: types.finishTransitJobSuccess,
        payload: response.finishTransitJob
      })

      yield put(
        showSnackbarNotification({
          variant: signalTypes.variantTypes.success,
          message: 'snackbar.notification.transitJob.finish.success',
          open: true
        })
      )
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

function* fetchDriverImageSaga(action) {
  try {
    const options = {
      reference: action.payload,
      entity: 'driver-profile-image'
    }
    const response = yield call(DocumentApi.documentList, options)
    const document = response.document.list

    if (document && document.length > 0) {
      const {documentDownloadLink} = yield call(
        DocumentApi.documentDownloadLink,
        {
          documentIds: [document[0].id]
        }
      )

      yield put({
        type: types.fetchDriverImageSuccess,
        payload: documentDownloadLink[0].link
      })
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )
  }
}

function* fetchDriverDetailsSaga(action) {
  try {
    const data = yield call(AuthApi.getDriverDetails, action.payload)

    yield put({
      type: types.fetchDriverDetailsSuccess,
      payload: {...data.user.list[0]}
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )
  }
}

function* startTransitJobSaga(action) {
  try {
    const response = yield call(CarApi.startTransitJob, {
      ...action.payload
    })

    if (response && response.startTransitJob) {
      yield put({
        type: types.startTransitJobSuccess,
        payload: response.startTransitJob
      })

      yield put(
        showSnackbarNotification({
          variant: signalTypes.variantTypes.success,
          message: 'snackbar.notification.transitJob.start.success',
          open: true
        })
      )
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

function* updateTransitJobStatusSaga(action) {
  try {
    const {status, id, transits} = action.payload
    let response

    // Pending
    if (status === STATUS_TYPE.PENDING) {
      const transitData = transits.map((transit) => ({
        carId: transit.carId
      }))

      response = yield call(CarApi.updateTransitsJob, {
        id: id,
        transits: transitData
      })
    }
    // SCHEDULED
    else if (status === STATUS_TYPE.SCHEDULED) {
      response = yield call(CarApi.confirmTransitJob, {id})
    }
    // RUNNING
    else if (status === STATUS_TYPE.RUNNING) {
      const startTransits = transits.map((transit) => transit.id)

      response = yield call(CarApi.startTransitJob, {
        id,
        startTransits: startTransits
      })
    }
    // REJECTED BY DRIVER
    else if (status === STATUS_TYPE.REJECTED) {
      response = yield call(CarApi.rejectTransitJob, {id})
    }
    // ABORTED
    else if (status === STATUS_TYPE.ABORTED) {
      response = yield call(CarApi.cancelTransitsJob, {id})
    }
    // FINISHED
    else if (status === STATUS_TYPE.FINISHED) {
      const finishedTransits = transits.map((transit) => transit.id)

      response = yield call(CarApi.finishTransitJob, {
        id,
        finishedTransits: finishedTransits
      })
    }

    if (response) {
      yield put({
        type: types.updateTransitJobStatusSuccess
      })
    }
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message:
          e.errors && e.errors.length > 0 ? e.errors[0].message : e.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchTransitJobList, fetchTransitJobListSaga),
  takeLatest(types.saveTransitSuccess, fetchTransitJobListSaga),
  takeLatest(types.saveTransit, saveTransitJobSaga),
  takeLatest(types.updateFilters, fetchTransitJobListSaga),
  takeLatest(types.toggleSort, fetchTransitJobListSaga),
  takeLatest(types.cancelTransitJob, cancelTransitJobSaga),
  takeLatest(types.cancelTransitJobSuccess, fetchTransitJobListSaga),
  takeLatest(types.hydrateTransitForm, hydrateTransitFormSaga),
  takeLatest(types.finishTransitJob, finishTransitJobSaga),
  takeLatest(types.startTransitJob, startTransitJobSaga),
  takeLatest(types.fetchTransitJob, fetchTransitJobSaga),
  takeLatest(types.fetchDriverImage, fetchDriverImageSaga),
  takeLatest(types.fetchDriverDetails, fetchDriverDetailsSaga),
  takeLatest(types.updateTransitJobStatus, updateTransitJobStatusSaga),
  takeLatest(types.updateTransitJobStatusSuccess, fetchTransitJobListSaga)
]
