import {Children} from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

const ifPropType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.func
])

const getOnlyChild = (props) => {
  try {
    if (isFunction(props.children)) {
      return props.children
    }

    return Children.only(props.children)
  } catch (ignore) {
    return null
  }
}
const renderChild = (child) => (isFunction(child) ? child() : child)

export const RenderIf = (props) => {
  let onlyChild = null

  if (props.if || (props.if && isFunction(props.if) && props.if())) {
    onlyChild = getOnlyChild(props)

    // Render only child
    if (props.children && onlyChild) {
      return renderChild(onlyChild)
    }

    // If contains multiple children, render first one
    if (props.children && !onlyChild) {
      const myChildren = Children.toArray(props.children)

      return renderChild(myChildren[0])
    }
  }

  // If contains multiple children, render second one
  if (props.children && !isFunction(props.children) && !onlyChild) {
    const myChildren = Children.toArray(props.children)

    if (myChildren.length > 1) {
      return renderChild(myChildren[1])
    }

    return null
  }

  return null
}

RenderIf.propTypes = {
  if: ifPropType
}

RenderIf.defaultProps = {
  if: false
}

/**
 * A simpler version of RenderIf
 */
export const RenderIfElse = (props) => {
  if (props.if && props.then) {
    return props.then
  }

  return props.else ? props.else : null
}

RenderIfElse.propTypes = {
  if: ifPropType,
  then: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  else: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

RenderIfElse.defaultProps = {
  if: false,
  then: null,
  else: null
}
