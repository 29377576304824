import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {logout} from '../../../store/auth/actions'
import {toggleMenu} from '../../../store/global/actions'
import {withAuth as withJumpCloudAuth} from 'react-oidc-context'
import {upperCase} from 'lodash'
import {styled} from '@mui/material/styles'
import {Popup} from 'motofin-ui'
import cookies from 'browser-cookies'

import './UserInfo.styl'
import {
  JUMPCLOUD_USER,
  JUMPCLOUD_ID_TOKEN,
  JUMPCLOUD_ACCESS_TOKEN
} from '../../../appConstants'
import {getUserName, getUserEmail} from './utils/UserInfoUtil'

export class UserInfo extends Component {
  logout = async () => {
    const {email} = this.props

    if (this.props.auth && this.props.auth.isAuthenticated) {
      cookies.erase(JUMPCLOUD_USER)
      cookies.erase(JUMPCLOUD_ID_TOKEN)
      cookies.erase(JUMPCLOUD_ACCESS_TOKEN)
      this.props.auth.removeUser()
    }
    this.props.logout({email})
  }

  handleChange = (event) => {
    if (event.value === 'logout') {
      this.logout()
    }
    this.props.handleClose()
  }

  render() {
    const {username} = this.props
    const user = username?.split(/\s+/) || ''
    return (
      <div ref={this.wrapperRef} style={{display: 'flex', marginRight: '20px'}}>
        <Avatar>
          {user.length > 1
            ? upperCase(user[0].charAt(0) + user[1].charAt(0))
            : ''}
        </Avatar>
        <Popup
          options={[
            {
              key: '1',
              title: 'Logout',
              value: 'logout'
            }
          ]}
          testId='profilePopup'
          IconContainerClass='IconStyle'
          ContainerClass='Container'
          OptionContainerClass='Options'
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export const mapStateToProps = ({authReducer}) => {
  return {
    username: getUserName(authReducer),
    email: getUserEmail(authReducer)
  }
}

const Avatar = styled('div')`
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  background-color: #3a77ff;
  border-radius: 50%;
  margin-left: 10px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    logout: ({email}) => {
      dispatch(toggleMenu(false))
      dispatch(logout({email}))
    }
  }
}

export default compose(
  withRouter,
  withJumpCloudAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(UserInfo)
