import React from 'react'
import ReactDOM from 'react-dom'
import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles'

import MainContextProvider from './context/MainContext'
import GlobalContextProvider from './context/GlobalContext'

import Country from './types/Country'

import PageRouter from './pages/PageRouter'

const generateClassName = createGenerateClassName({
  productionPrefix: 'lm'
})

const LocationManager = ({countryCode}: {countryCode: Country}) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MainContextProvider>
        <GlobalContextProvider>
          <PageRouter country={countryCode} />
        </GlobalContextProvider>
      </MainContextProvider>
    </StylesProvider>
  )
}

export const renderLocationManagerToDom = (
  id: string,
  countryCode: Country
) => {
  ReactDOM.render(
    <LocationManager countryCode={countryCode} />,
    document.getElementById(id)
  )
}

export const unmount = (node: React.ReactNode) => {
  ReactDOM.unmountComponentAtNode(node)
}
