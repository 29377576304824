import isNil from 'lodash/isNil'
import * as types from './types'
import * as global from '../global/actions'
import * as ContactApi from '../../api/contact/requests'
import * as CarApi from '../../api/car/requests'
import {formatParams} from '../utils'
import {getCountryCode} from '../config/selectors'

export const getUserTasks = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching('tasks'))
  const authData = getState().authReducer.isAuthenticated

  if (isNil(authData)) {
    return dispatch(global.authError)
  }

  const {user} = authData
  const currentState = getState().crm.tasks

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page
  const commentData = {
    responsible: user.id,
    type: 'task',
    ...formatParams(filters)
  }
  const params = {limit, sort, page, commentData}

  try {
    const res = await ContactApi.getCommentList(params)

    dispatch(setTasksLoaded({data: res.comment, limit, sort, page, filters}))
  } catch (e) {
    dispatch(global.apiError(e))
    dispatch(
      global.error({
        entity: 'task',
        message: 'global.error.fetch'
      })
    )
    dispatch(setFetching('tasks', false))
  }
}

export const getUserLeads = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching('leads'))
  const authData = getState().authReducer.isAuthenticated

  if (isNil(authData)) {
    return dispatch(global.authError)
  }

  const currentState = getState().crm.leads

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page
  const carLeadData = {
    responsible: authData.user.id,
    ...formatParams(filters)
  }
  const params = {limit, sort, page, carLeadData}

  try {
    const res = await ContactApi.getLeadList(params)

    dispatch(
      setLeadsLoaded({
        data: res.lead,
        limit,
        sort,
        page,
        filters
      })
    )
  } catch (e) {
    dispatch(global.apiError(e))
    dispatch(
      global.error({
        entity: 'lead',
        message: 'global.error.fetch'
      })
    )
    dispatch(setFetching('leads', false))
  }
}

export const getData = {
  tasks: getUserTasks,
  leads: getUserLeads
}

export const setOptionsLoading = () => ({
  type: types.optionsLoading
})

export const loadOptions = (types = null) => async (dispatch, getState) => {
  const country = getCountryCode(getState())
  dispatch(setOptionsLoading())

  try {
    const res = await CarApi.getCountryOptions(types, {country})
    dispatch(setOptions(res))

    return res
  } catch (e) {
    dispatch(global.apiError(e))
  }

  return null
}

export const setOptions = (options) => ({
  type: types.optionsLoaded,
  payload: options
})

export const setFetching = (entity, payload = true) => ({
  type: types[entity].fetching,
  payload
})

export const setTasksLoaded = (data) => ({
  type: types.tasks.dataLoaded,
  payload: data
})

export const setLeadsLoaded = (data) => ({
  type: types.leads.dataLoaded,
  payload: data
})

export const toggleField = (entity) => (label) => ({
  type: types[entity].toggleField,
  payload: label
})

export const toggleSort = (entity) => (sort) => (dispatch) => {
  dispatch({
    type: types[entity].toggleSort,
    payload: sort
  })
  dispatch(getData[entity]())
}

export const setSort = (entity) => (sort) => (dispatch) => {
  dispatch({
    type: types[entity].setSort,
    payload: sort
  })
  dispatch(getData[entity]())
}

export const updateFilters = (entity) => (filter) => (dispatch) => {
  dispatch({
    type: types[entity].updateFilters,
    payload: filter
  })
  dispatch(getData[entity]())
}

export const setRejectReasonOptions = (data) => {
  return {
    type: types.setRejectionReasonOptions,
    payload: data.data
  }
}

export const setDiscardReasonOptions = (data) => {
  return {
    type: types.setDiscardedReasonOptions,
    payload: data.data
  }
}
