import * as types from './types'
import {getInitialState, transitSchema} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'

const reducersFunc = {
  [types.fetchTransitJobList]: (state) => ({
    ...state,
    isFetchingList: true
  }),
  [types.fetchTransitJobListSuccess]: (state, action) => ({
    ...state,
    isFetchingList: false,
    transitList: action.payload.list,
    count: action.payload.count
  }),
  [types.fetchTransitJobListError]: (state, action) => ({
    ...state,
    isFetchingList: false,
    error: action.payload
  }),
  [types.setPageOptions]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.updateFilters]: (state, action) => ({
    ...state,
    list: {...state.list, ...action.payload}
  }),
  [types.updateFormData]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      [action.payload.name]: action.payload.value
    }
  }),
  [types.toggleRow]: (state, action) => {
    const expandedRows =
      !action.payload ||
      typeof action.payload.id === 'undefined' ||
      state.list.expandedRows.includes(action.payload.id)
        ? []
        : [action.payload.id]

    return {
      ...state,
      list: {
        ...state.list,
        expandedRows
      }
    }
  },
  [types.setOptionsForCarId]: (state, action) => {
    const options = [
      ...(state.carIdOptions.options || []),
      ...action.payload.options
    ]

    return {
      ...state,
      carIdOptions: {
        ...state.carIdOptions,
        options,
        count: options.count
      }
    }
  },
  [types.setHydrateFormStatus]: (state, action) => ({
    ...state,
    isFetchingFormData: action.payload
  }),
  [types.fetchTransitJob]: (state) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      transitJob: null,
      fetching: true,
      error: null
    }
  }),
  [types.fetchTransitJobSuccess]: (state, action) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      transitJob: action.payload,
      fetching: false,
      error: null
    }
  }),
  [types.finishTransitJob]: (state) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      submiting: true
    }
  }),
  [types.finishTransitJobSuccess]: (state, action) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      submiting: false,
      transitJob: {
        ...state.transitReview.transitJob,
        ...action.payload
      }
    }
  }),
  [types.fetchDriverImage]: (state) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      driverImageUrl: '',
      isFetchingImage: true
    }
  }),
  [types.fetchDriverImageSuccess]: (state, action) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      driverImageUrl: action.payload,
      isFetchingImage: false
    }
  }),
  [types.fetchDriverDetailsSuccess]: (state, action) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      driverDetails: {
        ...action.payload
      }
    }
  }),
  [types.startTransitJob]: (state) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      submiting: true
    }
  }),
  [types.startTransitJobSuccess]: (state, action) => ({
    ...state,
    transitReview: {
      ...state.transitReview,
      submiting: false,
      transitJob: {
        ...state.transitReview.transitJob,
        ...action.payload
      }
    }
  })
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: transitSchema
  })
}
