import * as types from './types'
import {getInitialState, locationSchema, metaSchema} from './initialState'
import * as formDecorators from '../utils/formDecorators'
import * as tableDecorators from '../utils/tableDecorators'

const prepareCountryOptions = ({name}) => ({
  label: name,
  value: name
})

const reducers = {
  [types.deletePending]: (state) => ({
    ...state,
    deleteState: {
      pending: true,
      success: false
    }
  }),
  [types.deleteSuccess]: (state) => ({
    ...state,
    deleteState: {
      pending: false,
      success: true
    }
  }),
  [types.deleteListItem]: (state, action) => {
    const data = state.list.data.filter((item) => item.id !== action.payload)

    return {
      ...state,
      list: {
        ...state.list,
        data
      }
    }
  },
  [types.setOptions]: (state, action) => {
    const payload = Object.keys(action.payload).reduce(
      (acc, key) => ({
        ...acc,
        [key]: action.payload[key].list.map(prepareCountryOptions)
      }),
      {}
    )

    return {
      ...state,
      options: {
        ...state.options,
        ...payload
      }
    }
  },
  [types.updateListItem]: (state, action) => {
    const data = Object.values(state.list.data).map((item) =>
      item.id === action.payload.id ? action.payload : item
    )

    return {
      ...state,
      list: {
        ...state.list,
        data
      }
    }
  }
}

export default {
  ...tableDecorators.generateReducer(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState,
    schema: {...locationSchema, metaInfo: {...metaSchema}}
  }),
  ...reducers
}
