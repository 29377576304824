import {
  useContext,
  useCallback,
  useMemo,
  useState,
  Fragment,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import {translate} from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {compose} from 'redux'
import {connect} from 'react-redux'

import {ValidatedForm} from '@fcg/formvalidation'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import {ConfigContext} from '../../../components/providers/withConfig'

import {ADD_NEW_RECORD, UPDATE_RECORD} from '../actions/bpDetailsActions'
import {BPDetailsDispatchContext} from '../context/BPDetailsDispatchContext'
import CountryOptionsSelect from '../../../components/CountryOptionsSelect/CountryOptionsSelect'
import {
  ValidatedTextField,
  RegexField
} from '../../../components/FormValidation'
import {DatePicker} from '@fcg/form-builder'
import CalendarContainer from '../../../components/Pickers/Calendar'
import {showErrorMessage} from '../../../store/signals/actions'

import './EditRecordModal.styl'
import {InputAdornment} from '@material-ui/core'

export function EditRecordModal({
  t,
  onClose,
  tabInfoId,
  record,
  recordIdx,
  action,
  bpType,
  _showErrorMessage
}) {
  const DATE_FORMAT = 'YYYY-MM-DD'
  const formRef = useRef()
  const {
    businessPartnersDetails = [],
    bpvalueBasedRequiredConditions,
    bpBasedTaxInfoConditions,
    callingCode,
    disbableCallingCodeInBP
  } = useContext(ConfigContext)
  const dispatch = useContext(BPDetailsDispatchContext)
  const callingCodeRegEx = useMemo(() => new RegExp(`^\\${callingCode}`), [
    callingCode
  ])

  const fieldsConfig = useMemo(
    () => businessPartnersDetails.find(({id}) => id === tabInfoId),
    [businessPartnersDetails, tabInfoId]
  )

  const [formData, setFormData] = useState(() => {
    let defaultFields = {}
    if (fieldsConfig && Array.isArray(fieldsConfig.fields)) {
      defaultFields = fieldsConfig.fields.reduce((acc, fieldConfigObj) => {
        if ('defaultValue' in fieldConfigObj) {
          acc[fieldConfigObj.id] = fieldConfigObj.defaultValue
        }

        return acc
      }, {})
    }

    return {
      ...record,
      ...defaultFields
    }
  })

  const onChangeHandler = useCallback(
    (evt, {type, id, prefix}) => {
      setFormData((formDataObj) => {
        const {checked} = evt.target || {}
        let {value} = evt.target || {}

        if (id === 'phone' && value.length && !disbableCallingCodeInBP) {
          value = `${callingCode}${value}`
        } else if (prefix && value.length) {
          value = `${prefix}${value}`
        }

        return {
          ...formDataObj,
          [id]: type === 'checkbox' ? !!checked : value
        }
      })
    },
    [callingCode, disbableCallingCodeInBP]
  )

  const onChangeDate = useCallback((evt, {id}) => {
    const timestampRegex = /^[0-9]+$/

    if (timestampRegex.test(evt)) {
      const parsedData = Number(evt)

      setFormData((formDataObj) => {
        const dateString = moment.isMoment(parsedData)
          ? parsedData.format(DATE_FORMAT)
          : moment(parsedData).format(DATE_FORMAT)

        return {
          ...formDataObj,
          [id]: dateString
        }
      })
    }
  }, [])

  const onSaveHandler = useCallback(
    async (evt) => {
      evt.stopPropagation()
      const success = await formRef.current.validate()
      let errorMsg = null

      Object.keys(bpvalueBasedRequiredConditions).forEach((key) => {
        const [tabName, dependentField, dependentValue] = key.split('.')
        if (
          tabInfoId === tabName &&
          formData[dependentField] === dependentValue
        ) {
          bpvalueBasedRequiredConditions[key].forEach((fieldStr) => {
            const fieldName = fieldStr.split('.')[1]
            if (!formData[fieldName]) {
              errorMsg = `${fieldName} is mandatory if ${dependentField} is ${dependentValue}`
            }
          })
        }
      })

      if (success && !errorMsg) {
        const type = action === 'add' ? ADD_NEW_RECORD : UPDATE_RECORD

        dispatch({
          type,
          payload: {
            section: tabInfoId,
            value: formData,
            recordIdx
          }
        })
        onClose()
      } else if (success && errorMsg) {
        _showErrorMessage(errorMsg)
      }
    },
    [
      dispatch,
      action,
      tabInfoId,
      recordIdx,
      formData,
      onClose,
      _showErrorMessage,
      bpvalueBasedRequiredConditions
    ]
  )

  const getInputProps = (field) => {
    let inputJSX = null

    if (field.id === 'phone') {
      inputJSX = {
        startAdornment: (
          <InputAdornment position='start'>{callingCode}</InputAdornment>
        )
      }
    } else if (field.prefix) {
      inputJSX = {
        startAdornment: (
          <InputAdornment position='start'>{field.prefix}</InputAdornment>
        )
      }
    }

    return inputJSX
  }

  const getFieldValue = (field) => {
    let value = formData[field.id] || field.defaultValue || ''

    if (field.id === 'phone') {
      value = formData[field.id]?.replace(callingCodeRegEx, '')
    } else if (field.prefix) {
      value = formData[field.id]?.replace(field.prefix, '')
    }

    return value
  }

  const isSelectFieldDisable = (tabId, fieldId) => {
    if (tabId === 'address' && fieldId === 'type' && action === 'edit') {
      return true
    } else {
      return false
    }
  }

  return (
    <Dialog onClose={onClose} maxWidth='md' open fullWidth>
      <ValidatedForm ref={formRef} t={t}>
        <div className='editRecordModal'>
          <h2 className='editRecordModal__title'>
            {t(`businessPartners.${tabInfoId}`)}
          </h2>
          <div className='editRecordModal__fields'>
            {fieldsConfig.fields.map((field) => (
              <Fragment key={field.id}>
                {field.type === 'select' && (
                  <CountryOptionsSelect
                    label={t(`businessPartners.${tabInfoId}.${field.id}.label`)}
                    name={field.id}
                    countryOptionType={field.optionsSourceType}
                    bpType={bpType}
                    value={formData[field.id] || ''}
                    tabId={tabInfoId}
                    bpBasedTaxInfoConditions={bpBasedTaxInfoConditions}
                    optionsLabelPrefix={`businessPartners.${tabInfoId}`}
                    margin='normal'
                    required={field.required}
                    onChange={(e) => onChangeHandler(e, field)}
                    fullWidth
                    disableOnNoOtherOptions={
                      tabInfoId === 'taxInfo' && bpBasedTaxInfoConditions
                    }
                    placeholderForNoOption={t('not_applicable')}
                    notRequiredOnNoOptions
                    id={`${tabInfoId}-${field.id}`}
                    disabled={
                      field.disabled ||
                      isSelectFieldDisable(tabInfoId, field.id)
                    }
                  />
                )}

                {['email', 'text', 'number'].includes(field.type) &&
                  typeof field.regex === 'undefined' && (
                    <ValidatedTextField
                      label={t(
                        `businessPartners.${tabInfoId}.${field.id}.label`
                      )}
                      id={`${tabInfoId}-${field.id}`}
                      value={formData[field.id] || field.defaultValue || ''}
                      name={field.id}
                      margin='normal'
                      disabled={field.readOnly}
                      maxLength={field.maxLength}
                      minLength={field.minLength}
                      type={field.type}
                      onChange={(e) => onChangeHandler(e, field)}
                      required={field.required}
                    />
                  )}
                {['number', 'text', 'email'].includes(field.type) &&
                  typeof field.regex !== 'undefined' && (
                    <RegexField
                      label={t(
                        `businessPartners.${tabInfoId}.${field.id}.label`
                      )}
                      id={
                        field.id === 'phone'
                          ? 'phoneNumber'
                          : `${tabInfoId}-${field.id}`
                      }
                      InputProps={
                        field.id === 'phone' && !disbableCallingCodeInBP
                          ? {
                              startAdornment: (
                                <InputAdornment position='start'>
                                  {callingCode}
                                </InputAdornment>
                              )
                            }
                          : null
                      }
                      value={
                        field.id === 'phone' && !disbableCallingCodeInBP
                          ? formData[field.id]?.replace(callingCodeRegEx, '')
                          : formData[field.id] || field.defaultValue || ''
                      }
                      margin='normal'
                      disabled={field.disabled}
                      maxLength={field.maxLength}
                      onChange={(e) => onChangeHandler(e, field)}
                      required={field.required}
                      regex={field.regex}
                      name={field.id}
                    />
                  )}
                {field.type === 'date' && (
                  <DatePicker
                    label={t(`businessPartners.${tabInfoId}.${field.id}.label`)}
                    id={`${tabInfoId}-${field.id}`}
                    value={formData[field.id] || field.defaultValue || ''}
                    margin='normal'
                    disabled={field.disabled}
                    onChange={(e) => onChangeDate(e, field)}
                    required={field.required}
                    name={field.id}
                    type='date'
                    config={{
                      dateFormat: DATE_FORMAT,
                      CalendarComponent: CalendarContainer
                    }}
                  />
                )}
                {field.type === 'checkbox' && (
                  <FormControlLabel
                    margin='normal'
                    disabled={field.disabled}
                    control={
                      <Checkbox
                        id={`${tabInfoId}-${field.id}`}
                        name={field.id}
                        checked={formData[field.id]}
                        onChange={(e) => onChangeHandler(e, field)}
                      />
                    }
                    label={t(`businessPartners.${tabInfoId}.${field.id}.label`)}
                  />
                )}
              </Fragment>
            ))}
          </div>
          <div className='editRecordModal__buttons'>
            <Button
              color='primary'
              onClick={onClose}
              data-test={`bp-${tabInfoId}-cancel-btn`}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={onSaveHandler}
              data-test={`bp-${tabInfoId}-save-btn`}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatedForm>
    </Dialog>
  )
}

EditRecordModal.propTypes = {
  t: PropTypes.func.isRequired
}

EditRecordModal.defaultProps = {
  onClose: () => {}
}

const mdtp = {
  _showErrorMessage: showErrorMessage
}

export default compose(translate(), connect(null, mdtp))(EditRecordModal)
