import merge from 'lodash/merge'

export const state = {
  selectedRows: [],
  filters: [],
  fields: {},
  list: {
    fetching: true,
    carPaymentReceiptsSelection: {},
    expandedRows: [],
    rowsFetching: [],
    rowsFetchingErrors: [],
    rowsUpdating: [],
    rowUpdateErrors: [],
    filters: []
  },
  documents: {
    isUploading: false,
    uploaded: false,
    error: null
  },
  options: {}
}

export const getInitialState = (preferences = {}) => merge(state, preferences)
