import {Fragment} from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import Editor from '../CountryOption/Editor'
import selectors from '../../store/countryOption/selectors'
import {openCreateDialog} from '../../store/countryOption/actions'
import {connect} from 'react-redux'
import {countryOptionFormConfig} from '../../config/pages/countryOption/countryOptionFormConfig'
import ReactDOM from 'react-dom'

const B2BAddInlineCountryOptionWrapper = styled.div`
  margin-top: 40px;
  color: #3f51b5;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
`

const Portal = (props) =>
  ReactDOM.createPortal(
    <Editor {...props} />,
    document.getElementById('app-portal')
  )

const B2BAddInlineCountryOption = (props) => {
  const openCreateDialog = () => {
    props.openCreateDialog()
  }

  return (
    <Fragment>
      {props.showCreateDialog ? <Portal {...props} /> : null}
      <B2BAddInlineCountryOptionWrapper onClick={openCreateDialog}>
        {props.t('countryOption.add.new.b2b')}
      </B2BAddInlineCountryOptionWrapper>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  showCreateDialog: selectors.getCreateDialogState(state),
  pageConfig: countryOptionFormConfig
})

const mapDispatchToProps = (dispatch) => ({
  openCreateDialog: () => dispatch(openCreateDialog())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(B2BAddInlineCountryOption))
