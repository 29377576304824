const React = require('react')
const OpenInNew = require('@material-ui/icons/OpenInNew')

const RichTableCellCmsLink = (props) => {
  const {data} = props

  return data ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={data} target='_blank'>
      <OpenInNew.default />
    </a>
  ) : null
}

module.exports = RichTableCellCmsLink
