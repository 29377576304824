import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import styled from 'styled-components'
import {ImageWithMetaInfoInputs} from '../../../components/DocumentPreview/utils'

export const SectionTitle = styled.h3`
  font-weight: 500;
`
const showMetaInfoInput = ({showMetaInfo}) =>
  showMetaInfo ? 'justify-content: center;' : ''
export const SelectedImage = styled.div`
  display: flex;
  background-color: #d8d8d8;
  min-height: 100px;
  max-height: 395px;
  min-width: 100px;
  max-width: 395px;
  cursor: pointer;
  ${showMetaInfoInput};

  > img {
    height: inherit;
    width: inherit;
    max-height: inherit;
    max-width: inherit;
  }
`
export const SelectedPdf = styled(SelectedImage)`
  > svg {
    font-size: 200px;
    cursor: pointer;
  }
`

export const pdfTile = styled.div`
  display: flex;
  border-radius: 3px;
  border-style: solid;
  border-color: ${(props) => (props.selected ? '#fff' : '#979797')};
  width: 100%;
  max-width: 110px;
  min-width: 100px;
  height: 70px;
  max-height: 395px;
  margin-right: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    font-size: 60px;
    cursor: pointer;
  }
`
export const fileAlternativeRendererWrapper = {
  pdf: SelectedPdf
}
export const galleryFileAlternativeRendererWrapper = {
  pdf: pdfTile
}
export const fileAlternativeRenderer = {
  pdf: PictureAsPdfIcon
}

export const getFileWrapper = (file, showMetaInfo) => {
  let wrapper = null

  if (file && file.fileType && fileAlternativeRendererWrapper[file.fileType]) {
    wrapper = fileAlternativeRendererWrapper[file.fileType]
  } else {
    wrapper = showMetaInfo ? ImageWithMetaInfoInputs : SelectedImage
  }

  return wrapper
}

export const getAlternativeRenderer = (file) =>
  file && file.fileType ? fileAlternativeRenderer[file.fileType] : null
