const dealerDetails = require('./dealerManagement/sm')
const crmDetails = require('./CRM/sm')
const bulkuploadDetails = require('./bulkUpload/sm')
const carDataDetails = require('./carData/sm')
const locationDetails = require('./locationManagement/sm')
const payslipDetails = require('./payslips/sm')
const carOwnership = require('./carOwnership/sm')
const businessPartnersDetails = require('./businessPartnersDetails/sm')

module.exports = {
  url: 'sanmarinomotors.cl',
  name: 'SanMarino',
  countryCode: 'SM',
  theme: 'sanMarino',
  language: {
    selected: 'es-cl',
    options: [
      {value: 'es-cl', label: 'ES'},
      {value: 'en-cl', label: 'EN'}
    ]
  },
  locales: ['es-cl', 'en-cl'],
  currency: {code: 'CLP', symbol: '$'},
  number: {decimalSep: ',', unitSep: ',', units: [1000]},
  fallbackLocale: 'es-cl',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [8, 9], code: 56},
  referralCodeType: 'input',
  googleKey:
    '983612896227-4buhtimu58n5ng50b6goeoupvd7eiags.apps.googleusercontent.com',
  okta: {
    domain: 'olxgroup.okta-emea.com',
    clientId: '0oa99py1ewaA6yN3r0i7'
  },
  disabledBookingSources: ['Web Lead'],
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'licensePlate',
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      licensePlate: {
        regex: '^[A-Z]{2}[0-9]{4}|[A-Z]{4}[0-9]{2}$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  biMappingCountryCodes: ['CL'],
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'category',
    'bulkUploadV2',
    'wms',
    'tms',
    'documentManagement',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions
}
