import {Fragment} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import DateFormat from '../../components/Format/DateFormat'

const DriverProfileLabelWidth = 1 / 3
const DriverPersonalInfowidth = 2 / 3

const DriverLabel = styled.span`
  color: #7d7d7d;
  margin-bottom: 4px;
`

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 525px) {
    justify-content: space-evenly;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 525px) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? `${props.width * 100}%` : '100%')};
  font-size: 14px;
`

const Span = styled.span`
  color: ${(props) => props.color || '#bcbcbc'};
  margin-bottom: 4px;
`

const RightHeader = (props) => (
  <Container>
    <Wrapper isScrollSectionVisible={props.isScrollSectionVisible}>
      <Content width={DriverProfileLabelWidth}>
        <Span>{props.t('driver.page.field.firstName')}:</Span>
        <Span>{props.t('driver.page.field.lastName')}:</Span>
        <Span>{props.t('driver.page.field.phone')}:</Span>
        {!props.isScrollSectionVisible ? (
          <Fragment>
            <Span>{props.t('global.createdAt.label')}:</Span>
            <Span>{props.t('global.updatedAt.label')}:</Span>
          </Fragment>
        ) : null}
      </Content>
      <Content width={DriverPersonalInfowidth}>
        <DriverLabel data-test='field-driver-first-name'>
          {props.data.firstname}
        </DriverLabel>
        <DriverLabel data-test='field-driver-last-name'>
          {props.data.lastname}
        </DriverLabel>
        <DriverLabel data-test='field-driver-phone'>
          {props.data.metaInfo.phone}
        </DriverLabel>
        {!props.isScrollSectionVisible ? (
          <Fragment>
            <DriverLabel data-test='field-driver-created-at'>
              <DateFormat data={props.data.createdAt} time />
            </DriverLabel>
            <DriverLabel data-test='field-driver-updated-at'>
              <DateFormat data={props.data.updatedAt} time />
            </DriverLabel>
          </Fragment>
        ) : null}
      </Content>
    </Wrapper>
    {!props.isScrollSectionVisible || !props.userId ? (
      <ButtonWrapper to={`/drivers/edit/${props.userId}`}>
        <Button variant='raised' color='primary' data-test='driver-edit'>
          {props.t('button.edit')}
        </Button>
      </ButtonWrapper>
    ) : null}
  </Container>
)

export default translate()(RightHeader)
