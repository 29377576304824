import gql from '@fcg/lib-gql/gql'
import pickBy from 'lodash/pickBy'
import {call, put, select, takeLatest, cancel} from 'redux-saga/effects'
import * as signalTypes from '../../signals/types'
import * as types from './types'
import * as TicketApi from '../../../api/ticket/requests'
import * as CarApi from '../../../api/car/requests'
import {showSnackbarNotification} from '../../signals/actions'
import {
  fetchPayslips as fetchPayslipsAction,
  fetchCurrentPage as fetchCurrentPageAction,
  setPayslipOptions,
  updateTicketSuccess,
  updateTicketStart,
  updateTicketError,
  fetchRowDataSuccess,
  fetchRowDataError,
  toggleRowSelection,
  fetchRowData as fetchRowDataAction,
  fetchPayslipsStart,
  persistMetaInfoChange
} from './actions'
import * as selectors from './selectors'
import {getCountryCode, getCurrencyConfig} from '../../config/selectors'
import {getPaymentInputValue} from '../create/helpers'
import {showErrorMessage, showSuccessMessage} from '../common/sagas.js'
import {ticketStatusesRequest} from '../../../config/pages/payslips/list/payslipConfig'
import {TICKET_TYPES_ENUM} from '../../../config/entities/ticket'
import {getCountryOptions} from '../../utils/helpers'

const REJECTION_REASON = (reason) => `REJECTION REASON: ${reason}`
const REJECTION_COMMENT = (comment) => `REJECTION COMMENT: ${comment}`

function* fetchPayslips(action) {
  try {
    const canFetch = yield select(selectors.canAccessPayslips)

    if (!canFetch) {
      return
    }

    yield put(fetchPayslipsStart())

    const countryCode = yield select(getCountryCode)
    const defaultLimit = yield select(selectors.getLimit)

    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : defaultLimit
    const page = action.payload && action.payload.page ? action.payload.page : 1
    const requestFilters = yield select(selectors.getSelectedFilters)
    const filters = yield select(selectors.selectedFiltersPath)
    const sort = yield select(selectors.getSort)

    const options = {
      limit,
      page,
      sort,
      type: TICKET_TYPES_ENUM.PAYMENT_PROOF,
      country: new gql.EnumType(countryCode),
      ...requestFilters
    }

    const payslipsList = yield call(TicketApi.getTicketList, {
      ...pickBy(options)
    })

    yield put({
      type: types.dataLoadingTypes.dataLoaded,
      payload: {
        data: payslipsList.ticket,
        limit,
        sort,
        page,
        filters,
        flatten: 1
      }
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchPayslipsError,
      error: e
    })
  }
}

/** Used when updating amount on CAR ticketEntity */
function* updateTicketEntity(action) {
  try {
    const {id, metaInfo} = action.payload
    const {updateTicketEntity} = yield call(TicketApi.updateTicketEntity, {
      id,
      metaInfo
    })
    yield put({
      type: types.updateTicketEntitySuccess,
      payload: {
        ticketId: updateTicketEntity.ticketId,
        entityId: updateTicketEntity.id,
        metaInfo: updateTicketEntity.metaInfo
      }
    })
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'Car Payment Entity Updated'
      })
    )
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )
    yield put({
      type: types.updateTicketEntityFailure,
      error: e
    })
  }
}

export function* updateTicket({payload}) {
  return yield call(TicketApi.updateTicket, payload)
}

export function* approveTicket({payload}) {
  try {
    const canModifyPayslips = yield selectors.canModifyPayslips
    const hiddenSubTableColumns = yield select(
      selectors.getHiddenSubTableColumns
    )
    const {
      params,
      additionalData: {subTableData = [], summaryData = {}, rowData = {}}
    } = payload
    const shallShowCategory = !hiddenSubTableColumns.includes('category')
    const isCategoryMissing =
      shallShowCategory && subTableData.some(({category}) => !category)

    if (!canModifyPayslips) {
      return
    }

    if (isCategoryMissing) {
      yield put(
        showSnackbarNotification({
          variant: signalTypes.variantTypes.error,
          message: 'payslip.document.categoryRequired',
          open: true
        })
      )
      return
    }

    yield call(updateTicket, {payload: params})

    if (summaryData) {
      const currency = yield select(getCurrencyConfig)
      const amountWithCurrency = `${currency.symbol} ${summaryData.amount}`

      yield showSuccessMessage('payslips.approve.success', {
        value: amountWithCurrency,
        manager: rowData.dealerManager
      })
    }

    yield put(fetchCurrentPageAction())
  } catch (error) {
    yield showErrorMessage(error)
  }
}

export function* fetchCurrentPage() {
  try {
    const canFetch = yield select(selectors.canAccessPayslips)

    if (!canFetch) {
      return
    }

    yield put(toggleRowSelection())
    const currentPage = yield select(selectors.getPage)
    const currentLimit = yield select(selectors.getLimit)

    yield put(
      fetchPayslipsAction({
        page: currentPage,
        limit: currentLimit
      })
    )
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchPayslipsError,
      error: e
    })
  }
}

export function* fetchPayslipOptions() {
  try {
    const country = yield select(getCountryCode)

    const data = yield call(
      CarApi.getCountryOptionsForType,
      {payslipRejectionReasons: {type: 'payslipRejectionReasons'}},
      {country}
    )

    if (
      !data ||
      !data.payslipRejectionReasons ||
      !data.payslipRejectionReasons.list
    ) {
      throw new Error('No rejection reasons available.')
    }

    yield put(setPayslipOptions(getCountryOptions(data)))
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchPayslipsError,
      error: e
    })
  }
}

export function* rejectPayslip(action) {
  try {
    const canModifyPayslips = yield selectors.canModifyPayslips

    if (!canModifyPayslips) {
      return
    }

    yield put(updateTicketStart())
    const {id: ticketId} = yield select(selectors.getSelectedPayslip)

    if (!ticketId) {
      yield put(updateTicketError())

      throw new Error('No payslip selected')
    }

    const {reason, comment} = action.payload

    // Create a reason as a separate comment
    const reasonCommentParams = {
      ticketId,
      description: REJECTION_REASON(reason)
    }

    yield call(TicketApi.createTicketComment, reasonCommentParams)

    // Create rejection comment as a comment if added
    if (comment) {
      const commentParams = {
        ticketId,
        description: REJECTION_COMMENT(comment)
      }

      yield call(TicketApi.createTicketComment, commentParams)
    }

    // Reject ticket
    const updateTicketParams = {
      id: ticketId,
      status: ticketStatusesRequest.REJECTED
    }

    yield call(updateTicket, {payload: updateTicketParams})
    yield put(updateTicketSuccess())

    yield put(fetchCurrentPageAction())
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put(updateTicketError(e))
  }
}

function* onRowToggle({payload}) {
  const isRowClosing = yield select(selectors.isRowExpanded, payload.id)

  if (isRowClosing) {
    yield put(toggleRowSelection(payload))

    return
  }

  let fetchHandler

  try {
    const isRowDataFetched = yield select(selectors.rowHasData, payload)

    if (!isRowDataFetched) {
      fetchHandler = yield put(fetchRowDataAction(payload))
    }

    yield put(toggleRowSelection(payload))
  } catch (error) {
    yield cancel(fetchHandler)
  }
}

function* fetchRowData({payload}) {
  try {
    const ticketCarIds = yield select(selectors.getPayslipCarIds, {
      id: payload.id
    })
    const ticketDocumentIds = yield select(selectors.getPayslipDocumentIds, {
      id: payload.id
    })

    const {carData, documentData} = yield call(TicketApi.fetchPayslipDetails, {
      carIds: ticketCarIds,
      documentIds: ticketDocumentIds
    })

    yield put(fetchRowDataSuccess({...payload, carData, documentData}))
  } catch (error) {
    yield put(fetchRowDataError({id: payload.id, error}))
  }
}

/** Updates document entity metaInfo */
function* onMetaInfoChange({payload}) {
  try {
    yield put(persistMetaInfoChange(payload))
    const documentEntityParam = {id: payload.ticketId}

    const documentEntities = yield select(
      selectors.getPayslipDocuments,
      documentEntityParam
    )

    const document = documentEntities.find(
      (entity) => entity.id === payload.ticketEntityId
    )

    const updateTicketEntityParams = {
      id: payload.ticketEntityId,
      metaInfo: document.metaInfo
    }

    const {updateTicketEntity} = yield call(
      TicketApi.updateTicketEntity,
      updateTicketEntityParams
    )

    yield put({
      type: types.updateTicketEntitySuccess,
      payload: {
        ticketId: updateTicketEntity.ticketId,
        entityId: updateTicketEntity.id,
        metaInfo: updateTicketEntity.metaInfo
      }
    })
    yield showSuccessMessage('payslips.document.updated')
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchPayslips, fetchPayslips),
  takeLatest(types.updateTicketEntity, updateTicketEntity),
  takeLatest(types.approveTicket, approveTicket),
  takeLatest(types.updateTicket, updateTicket),
  takeLatest(types.fetchCurrentPage, fetchCurrentPage),
  takeLatest(types.fetchPayslipOptions, fetchPayslipOptions),
  takeLatest(types.rejectPayslip, rejectPayslip),
  takeLatest(types.fetchRowData, fetchRowData),
  takeLatest(types.toggleRow, onRowToggle),
  takeLatest(types.metaInfoChange, onMetaInfoChange)
]
