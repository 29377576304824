import gql from '@fcg/lib-gql/gql'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'
// Selectors
import {
  getFiltersWithoutDate,
  getAppointmentSlotsRequestParams,
  getAppointmentCollectionName,
  getCalendarId,
  getRefreshHandler,
  getSelectedAppointment
} from './selectors'
import {
  selectedDate,
  isOpen
} from '../../components/Pickers/Calendar/store/selectors'
import {getCountryCode} from '../config/selectors'
// Types
import * as types from './types'
// Actions
import {
  setCancelPending,
  setCancelComplete,
  setFormSuccess,
  setFormError,
  setFormPending,
  resetAppointmentForm
} from '../crm/appointment/actions'
import {sync} from '../resources/actions'
import {
  selectDay,
  toggleOpen
} from '../../components/Pickers/Calendar/store/actions'
import * as globalActions from '../global/actions'
import {loadOptions as loadCities} from '../locationManagement/actions'
import {loadOptions} from '../crm/actions'
// Fields
import * as fields from './fields'
// Api
import {ApiClient} from '@fcg/admin-api-client'
// Config
import {dateRequestFormat} from '../../pages/Calendar/components/Filters/filterConfig'
import {CACHE_INVALIDATION_VALUE_MILISECS} from '../resources/utils'

const client = new ApiClient('calendar')
const carClient = new ApiClient('car')

export const FETCH_LIMIT = 4000
export const CANCEL_STATUS = 'cancel'

export const fetchAppointmentSlotsError = (error) => ({
  type: types.fetchAppointmentSlotsError,
  payload: error
})

export const fetchAppointmentSlotsSuccess = () => ({
  type: types.fetchAppointmentSlotsSuccess
})

const fetchAppointmentSuccess = () => (dispatch) => {
  dispatch(fetchAppointmentSlotsSuccess())
  dispatch(setRefreshInterval())
}

export const setCalendarId = (id) => ({
  type: types.setCalendarId,
  payload: id
})

export const fetchAppointmentSlots = (forceFetch = false) => (
  dispatch,
  getState
) => {
  dispatch({type: types.fetchAppointmentSlots})
  dispatch(clearRefreshInterval())

  const appointmentData = getAppointmentSlotsRequestParams(getState())
  const name = getAppointmentCollectionName(getState())
  const options = {
    appointmentData,
    limit: FETCH_LIMIT
  }

  const request = {
    client,
    queryType: 'query',
    endpoint: 'appointment',
    requestType: 'READ',
    params: {
      ...options
    }
  }

  try {
    return dispatch(
      sync(fields.appointmentList, request, {
        forceFetch,
        collectionOptions: {
          name,
          cacheable: true
        },
        successAction: fetchAppointmentSuccess
      })
    )
  } catch (error) {
    dispatch({type: types.fetchAppointmentSlotsError, payload: error})
  }
}

export const fetchLocationOptions = (_, collection) => (dispatch, getState) => {
  dispatch({type: types.fetchLocationOptions})

  const request = {
    client: carClient,
    queryType: 'query',
    endpoint: 'place',
    requestType: 'READ',
    params: {
      type: new gql.EnumType('INSPECTIONCRM'),
      country: new gql.EnumType(getCountryCode(getState())),
      active: new gql.EnumType(true),
      sort: [{field: 'location'}],
      cache: false
    }
  }
  const collectionName =
    collection && collection.name ? collection.name : 'place'

  try {
    return dispatch(
      sync(fields.placeList, request, {
        collectionOptions: {
          name: collectionName
        },
        successAction: fetchLocationOptionsSuccess
      })
    )
  } catch (error) {
    dispatch({type: types.fetchLocationOptionsError, payload: error})
  }
}

export const fetchLocationOptionsSuccess = () => (dispatch) => {
  dispatch({type: types.fetchLocationOptionsSuccess})
}

export const fetchLocationOptionsError = (error) => ({
  type: types.fetchLocationOptionsError,
  payload: error
})

export const onFilterChange = (filters) => ({
  type: types.onFilterChange,
  payload: filters
})

export const onSavedFilterChange = (savedFilters) => ({
  type: types.onSavedFilterChange,
  payload: savedFilters
})

export const onPresetSelect = (filters) => (dispatch, getState) => {
  // Update calendar store
  const dateFilter = filters.find((filter) => filter.key === 'date')
  const date = moment(dateFilter.value, dateRequestFormat).format()

  dispatch(selectDay(getCalendarId(getState()))(date))

  // Update appointment calendar filters
  dispatch(updateFilters(filters))
}

export const toggleVisibility = (feature) => ({
  type: types.toggleVisibility,
  payload: feature
})

const FEATURES = {
  CANCEL: 'cancel',
  EDIT: 'edit',
  LEGEND: 'legend',
  LEGEND_ICON: 'legendIcon'
}

export const toggleVisibilityCancel = () => toggleVisibility(FEATURES.CANCEL)
export const toggleVisibilityEdit = () => toggleVisibility(FEATURES.EDIT)
export const toggleVisibilityLegend = () => toggleVisibility(FEATURES.LEGEND)
export const toggleVisibilityLegendIcon = () =>
  toggleVisibility(FEATURES.LEGEND_ICON)

export const selectAppointment = (id = null) => ({
  type: types.selectAppointment,
  payload: id
})

export const onSelectedAppointmentLoading = (isLoading) => ({
  type: types.selectedAppointmentLoading,
  payload: isLoading
})

export const onSelectedAppointmentError = (error) => ({
  type: types.selectedAppointmentError,
  payload: error
})

const getDateFilterObject = (date) => ({
  id: `date_${date}`,
  key: 'date',
  placeholder: 'appointmentCalendar.placeholder.date',
  mask: null,
  label: 'appointmentCalendar.placeholder.date',
  showChip: false,
  type: 'string',
  value: date
})

export const updateDateFilter = (date) => (dispatch, getState) => {
  const formattedDate = moment(date, dateRequestFormat).format(
    dateRequestFormat
  )

  dispatch(
    onFilterChange([
      ...getFiltersWithoutDate(getState()),
      getDateFilterObject(formattedDate)
    ])
  )
}

export const dateFilterUpdate = (calendarId) => (dispatch, getState) =>
  dispatch(updateDateFilter(selectedDate(calendarId)(getState())))

export const setDay = (date, keepOpen = false) => (dispatch, getState) => {
  const calendarId = getCalendarId(getState())

  if (!keepOpen && isOpen(calendarId)(getState())) {
    dispatch(toggleOpen(calendarId))
  }

  dispatch(selectDay(calendarId)(date))
  dispatch(dateFilterUpdate(calendarId))
}

export const modifyDayBy = (operand) => (dispatch, getState) => {
  const calendarId = getCalendarId(getState())
  const date = selectedDate(calendarId)(getState())
  const newDate = moment(date).add(operand, 'days')

  if (isOpen(calendarId)(getState())) {
    dispatch(toggleOpen(calendarId))
  }

  dispatch({type: types.fetchAppointmentSlots})
  dispatch(selectDay(calendarId)(newDate))
  dispatch(updateDateFilter(newDate.format()))

  setTimeout(() => {
    dispatch(fetchAppointmentSlots())
  }, 0)
}

export const updateFilters = (filters) => (dispatch) => {
  dispatch({type: types.fetchAppointmentSlots})
  dispatch(onFilterChange(filters))

  setTimeout(() => {
    dispatch(fetchAppointmentSlots())
  }, 0)
}

export const onDateFilterUpdate = () => (dispatch, getState) => {
  const calendarId = getCalendarId(getState())

  dispatch({type: types.fetchAppointmentSlots})
  dispatch(dateFilterUpdate(calendarId))

  setTimeout(() => {
    dispatch(fetchAppointmentSlots())
  }, 0)
}

export const updateCalendarAppointment = ({id, data}) => (dispatch) => {
  dispatch(onSelectedAppointmentLoading(true))
  dispatch(setFormPending())

  const purchaseCoordinatorId = get(data, 'purchaseCoordinatorId')

  const {appointmentAddress: address, ...appointmentData} = omit(
    data,
    'purchaseCoordinatorId'
  )

  if (purchaseCoordinatorId) {
    appointmentData.purchaseCoordinator = purchaseCoordinatorId
  }

  const request = {
    client,
    queryType: 'query',
    endpoint: 'updateAppointment',
    requestType: 'UPDATE',
    params: {
      id,
      appointmentData: {
        address,
        ...appointmentData,
        assignedToName: data.assignedTo === null ? null : data.assignedToName
      }
    }
  }

  try {
    return dispatch(
      sync(fields.appointmentFields, request, {
        collectionOptions: {
          name: 'appointment',
          type: 'appointment'
        }
      })
    )
      .then((response) => {
        dispatch(setFormSuccess(response))
        dispatch(
          globalActions.success({
            message: 'global.action.update',
            entity: 'appointment'
          })
        )
        dispatch(selectAppointment())
        dispatch(toggleVisibilityEdit())
        dispatch(resetAppointmentForm())
      })
      .catch((e) => {
        dispatch(setFormError(e))
        dispatch(globalActions.apiError(e))
        dispatch(
          globalActions.error({
            message: 'global.action.update',
            entity: 'appointment'
          })
        )
      })
  } catch (error) {
    dispatch({type: types.selectedAppointmentError, payload: error})
  }
}

export const cancelCalendarAppointment = (params) => (dispatch, getState) => {
  dispatch(onSelectedAppointmentLoading(true))
  dispatch(setCancelPending())

  const request = {
    client,
    queryType: 'query',
    endpoint: 'cancelAppointment',
    requestType: 'UPDATE',
    resourceType: 'appointment',
    params
  }

  try {
    return dispatch(
      sync(null, request, {
        collectionOptions: {
          name: 'appointment'
        },
        mergeIf: (payload) => payload.entities.appointment,
        /**
         * Since https://frontiercargroup.atlassian.net/browse/COS-539
         * is ON HOLD we are mutating the appointment ourselves
         */
        modifyPayloadBeforeMerge: () => {
          const appointment = getSelectedAppointment(getState())

          return {
            entities: {
              appointment: {
                [appointment.id]: {
                  ...appointment,
                  status: CANCEL_STATUS,
                  cancellationReason: params.cancellationReason,
                  cancelledAt: moment().format(),
                  lead: appointment.leadId
                }
              }
            }
          }
        }
      })
    )
      .then(() => {
        dispatch(setCancelComplete())
        dispatch(selectAppointment())
        dispatch(
          globalActions.success({
            message: 'global.action.cancelAppointment',
            entity: 'appointment'
          })
        )
        dispatch(toggleVisibilityCancel())
      })
      .catch((e) => {
        dispatch(globalActions.apiError(e))
        dispatch(
          globalActions.error({
            message: 'global.action.cancelAppointment',
            entity: 'appointment'
          })
        )
      })
  } catch (error) {
    dispatch({type: types.selectedAppointmentError, payload: error})
  }
}

export const initAppointmentCalendar = () => async (dispatch) => {
  dispatch(toggleVisibilityLegendIcon())

  try {
    await Promise.all([
      dispatch(loadOptions()),
      dispatch(loadCities()),
      dispatch(fetchLocationOptions())
    ])
  } catch (ignore) {
    // eslint-disable-next-line no-console
    console.log('Ignore')
  }

  dispatch(fetchAppointmentSlots())
}

export const clearRefreshInterval = () => (dispatch, getState) => {
  const existingHandler = getRefreshHandler(getState())

  if (existingHandler) {
    window.clearTimeout(existingHandler)

    dispatch({
      type: types.setRefreshInterval,
      payload: null
    })
  }
}

export const setRefreshInterval = () => (dispatch) => {
  dispatch(clearRefreshInterval())

  const handler = window.setTimeout(() => {
    dispatch(fetchAppointmentSlots(true))
  }, CACHE_INVALIDATION_VALUE_MILISECS)

  dispatch({
    type: types.setRefreshInterval,
    payload: handler
  })
}
