import merge from 'lodash/merge'

export const state = {
  fetching: false,
  options: {},
  optionsLoading: false,
  tasks: {
    page: 1,
    limit: 25,
    count: 0,
    fetching: false,
    filters: [],
    sort: [{field: 'dueDate', order: 'ASC'}],
    data: []
  },
  leads: {
    page: 1,
    limit: 25,
    count: 0,
    fetching: false,
    filters: [],
    sort: [{field: 'createdAt', order: 'DESC'}],
    data: []
  }
}

export const getInitialState = (preferences = {}) => {
  const merged = merge(state, preferences)
  const defaultPreset =
    merged.tasks.presets &&
    merged.tasks.presets.find((item) => item.default === true)

  if (defaultPreset) {
    merged.tasks = {...merged.tasks, filters: [...defaultPreset.filters]}
  }

  return merged
}
