export const inspectionFields = [
  'id',
  'country',
  'inspector',
  'configId',
  'originalInspection',
  'qcScore',
  'nonMatchingFields',
  'onlineDraftId',
  [
    'car',
    [
      'make',
      'model',
      'trim',
      'year',
      'mileage',
      'fuel',
      'chassisColor',
      'horsepower',
      'transmission'
    ]
  ],
  'report',
  'createdAt',
  'startTime',
  ['damages', ['id', 'description']],
  'purchased',
  'inAuction',
  'carId',
  'leadId'
]

export const inspectionList = [['list', inspectionFields], 'count']
