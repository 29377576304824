import {withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import {ArrowBack, ArrowForward} from '@material-ui/icons'

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    ...theme.typography.button,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2em'
  },
  icon: {
    position: 'absolute',
    height: '100%',
    fontSize: '18px',
    top: 0
  },
  forward: {
    right: 0
  },
  back: {
    left: 0
  }
})

export const IconButton = (props) => {
  const {direction, classes, label, onClick, ...rest} = props
  const buttonForward = direction !== 'none' && (
    <ArrowForward
      className={`${classes.icon} ${classes.forward}`}
      onClick={() => onClick('forward')}
    />
  )
  const buttonBack = direction !== 'none' && (
    <ArrowBack
      className={`${classes.icon} ${classes.back}`}
      onClick={() => onClick('back')}
    />
  )

  return (
    <div {...rest} className={classes.root}>
      {direction !== 'forward' && buttonBack}
      {label}
      {direction !== 'back' && buttonForward}
    </div>
  )
}

IconButton.defaultProps = {
  direction: 'both',
  label: ''
}

IconButton.propTypes = {
  direction: PropTypes.oneOf(['back', 'forward', 'both', 'none']),
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default withStyles(styles)(IconButton)
