import {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'

import Add from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import {TRUCK} from './constants'
import {default as selectors} from '../../store/transitJob/selectors'
import {setOptionsForCarId} from '../../store/transitJob/actions'
import {ValidatedCarPicker} from '../../components/Pickers/CarPicker'
import {getCountryCode} from '../../store/config/selectors'

const CarPickerWrapper = styled.div`
  margin-top: 8px;
`

const TransitWrapper = styled(Grid)`
  margin-top: 16px;

  &:last-of-type {
    .add-btn {
      display: ${(props) => (props.transitType === TRUCK ? 'block' : 'none')};
    }
  }
`

const Text = styled(TextField)`
  width: 100%;
  padding: 8px 16px 8px 0px !important;
`

const AddCar = styled(Button)`
  && {
    display: none;
    width: 36px;
    height: 30px;
    margin: 24px 0px 0px 10px;
  }
`

const RemoveCar = styled(Button)`
  && {
    width: 36px;
    height: 36px;
    margin: 24px 12px 0px 10px;
  }
`

const AddIcon = styled(Add)`
  vertical-align: middle;
`
class TransitJobTile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      carId: '',
      startMileage: '',
      selectedCar:
        this.props.selectedCars[this.props.index] ||
        this.props.transit.licensePlate ||
        ''
    }
  }

  handleCarSelection = (e) => {
    const {target} = e

    if (target) {
      const {value, label} = target

      this.setState({
        carId: value,
        selectedCar: label
      })

      this.props.updateSelectedTransit(
        {carId: value},
        this.props.index,
        'transit'
      )
      this.props.updateSelectedTransit(label, this.props.index)
    }
  }

  transitFieldChange = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    })

    this.props.updateSelectedTransit(
      {[e.target.name]: parseInt(e.target.value)},
      this.props.index,
      'transit'
    )
  }

  removeTransit = () => {
    this.props.removeSelectedTransit(this.props.index)
  }

  addNewTransit = () => {
    this.props.addNewTransit(this.props.index + 1)
  }

  isDeleteDisabled = () => {
    return this.props.transits.filter((val) => val).length === 1
  }

  render() {
    const {t} = this.props
    const {isValid, index, transit} = this.props
    const carValue = this.state.selectedCar
    const errorType = this.props.fieldWithErrorType[index]

    return (
      <TransitWrapper
        container
        key={`car-transit-${index}`}
        spacing={8}
        transitType={this.props.transitType}
      >
        <Grid item xs={4}>
          <CarPickerWrapper>
            <ValidatedCarPicker
              fullWidth
              id={`carId-${index}`}
              label={t('car.field.licensePlate')}
              limit={50}
              placeholder={carValue}
              options={this.props.carIdOptions}
              count={this.props.carIdOptionsCount}
              selected={carValue}
              onLoad={this.props.setOptionsForCarId}
              onChange={this.handleCarSelection}
              queryParams={{
                limit: 50,
                page: 1,
                country: this.props.country,
                id: carValue
              }}
              helperText={
                !isValid && errorType && errorType.carId
                  ? t(errorType.carId)
                  : ''
              }
              error={!isValid && Boolean(errorType && errorType.carId)}
              debounce
              required
              value={carValue}
              name={`carId_${carValue}`}
            />
          </CarPickerWrapper>
        </Grid>
        <Grid item xs={3}>
          <Text
            label={t('transitList.placeholder.start.mileage')}
            value={transit.startMileage}
            type='number'
            onChange={this.transitFieldChange}
            helperText={
              !isValid && errorType && errorType.startMileage
                ? t(errorType.startMileage)
                : ''
            }
            error={!isValid && Boolean(errorType && errorType.startMileage)}
            name='startMileage'
          />
        </Grid>
        {this.props.transitType === TRUCK && (
          <RemoveCar
            variant='fab'
            onClick={this.removeTransit}
            disabled={this.isDeleteDisabled()}
            data-test='remove-transit-car'
          >
            <DeleteIcon />
          </RemoveCar>
        )}
        <AddCar
          variant='fab'
          color='primary'
          onClick={this.addNewTransit}
          className='add-btn'
          data-test='add-transit-car'
        >
          <AddIcon />
        </AddCar>
      </TransitWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  carIdOptions: selectors.getCarIdOptions(state),
  carIdOptionsCount: selectors.getCarIdOptionsCount(state),
  country: getCountryCode(state)
})

const mapDispatchToProps = (dispatch) => ({
  setOptionsForCarId: (options) => dispatch(setOptionsForCarId(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransitJobTile)
