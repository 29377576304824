export const isFetching = 'crmHome/IS_FETCHING'
export const optionsLoading = 'crmHome/OPTIONS_LOADING'
export const optionsLoaded = 'crmHome/OPTIONS_LOADED'
export const setRejectionReasonOptions = 'crmHome/SET_REJECTION_REASON_OPTIONS'
export const setDiscardedReasonOptions = 'crmHome/SET_DISCARDED_REASON_OPTION'

export const tasks = {
  fetching: 'crmHome/tasks/LOADING',
  dataLoaded: 'crmHome/tasks/LOADED',
  toggleField: 'crmHome/tasks/TOGGLE_FIELD',
  changePage: 'crmHome/tasks/CHANGE_PAGE',
  toggleSort: 'crmHome/tasks/TOGGLE_SORT',
  setSort: 'crmHome/tasks/SET_SORT',
  updateFilters: 'crmHome/tasks/UPDATE_FILTER'
}

export const leads = {
  fetching: 'crmHome/leads/LOADING',
  dataLoaded: 'crmHome/leads/LOADED',
  toggleField: 'crmHome/leads/TOGGLE_FIELD',
  changePage: 'crmHome/leads/CHANGE_PAGE',
  toggleSort: 'crmHome/leads/TOGGLE_SORT',
  setSort: 'crmHome/leads/SET_SORT',
  updateFilters: 'crmHome/leads/UPDATE_FILTER'
}
