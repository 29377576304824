import * as types from './types'

export const fetchWebhookList = (payload) => ({
  type: types.fetchWebhookList,
  payload
})

export const fetchWebhookListSuccess = (payload) => ({
  type: types.fetchWebhookListSuccess,
  payload
})

export const fetchWebhookListError = (error) => ({
  type: types.fetchWebhookListError,
  payload: error
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const deleteWebhook = (payload) => ({
  type: types.deleteWebhook,
  payload
})

export const deleteWebhookSuccess = () => ({
  type: types.deleteWebhookSuccess
})

export const deleteWebhookError = (error) => ({
  type: types.deleteWebhookError,
  payload: error
})
export const hideCreateDialog = () => ({
  type: types.hideCreateDialog
})

export const openCreateDialog = () => ({
  type: types.openCreateDialog
})

export const createWebhook = (payload) => ({
  type: types.createWebhook,
  payload
})

export const createWebhookSuccess = () => ({
  type: types.createWebhookSuccess
})

export const createWebhookError = () => ({
  type: types.createWebhookError
})

export const fetchWebhookEventsList = (payload) => ({
  type: types.fetchWebhookEventsList,
  payload
})

export const fetchWebhookEventsListSuccess = () => ({
  type: types.fetchWebhookEventsListSuccess
})

export const fetchWebhookEventsListError = () => ({
  type: types.fetchWebhookEventsListError
})

export const updateWebhookField = (payload) => ({
  type: types.updateWebhookField,
  payload
})

export const hideNonceDialog = () => ({
  type: types.hideNonceDialog
})

export const openNonceDialog = () => ({
  type: types.openNonceDialog
})
