import styled from 'styled-components'
import {Loader, colors} from './common'
import {RenderIf} from '../../../../components/RenderIf'

const Overlay = styled.div`
  z-index: 3;
  background-color: ${() => colors.overlayColor};
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const LoaderOverlay = ({isLoading}) => (
  <RenderIf if={isLoading}>
    <Overlay>
      <Loader />
    </Overlay>
  </RenderIf>
)
