import dealerListConfig from './dealersList'
import financingUserGroupsConfig from './financingUserGroups'

export default {
  path: 'dealers',
  label: 'menu.dealers',
  key: 'dealers',
  children: [dealerListConfig, financingUserGroupsConfig],
  dealerListConfig,
  financingUserGroupsConfig
}
