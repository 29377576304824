const contactFields = ['firstname', 'lastname', 'email', 'phone']

const appointmentDependencies = [
  'type',
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const appointmentFields = ['purchaseCoordinator']

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'mileage',
  'transmission'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]
const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  },
  {
    field: 'VINnumber',
    dependencies: []
  }
]
const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

const requiredFields = ['make', 'model']

const purchaseCoordinatorInput = {
  key: 'roleId',
  // TODO: fetch it dynamically from query
  queryValue: '51d803d2-bbf4-4ef4-afd1-0ffdd599eaba'
}

module.exports = {
  required: [
    {
      field: 'type',
      dependencies: appointmentDependencies
    },
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...appointmentFields,
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation,
    ...requiredFields
  ],
  hidden: [
    'tierAppointmentStatus',
    'tierLocation',
    'lead.bodyType',
    'lead.faceLift',
    'lead.seat',
    'lead.engine',
    'lead.wheelDrive',
    'externalLeadId',
    'postcode',
    'city',
    'VINnumber'
  ],
  landingPage: ['tasks', 'leads'],
  hiddenColumns: [
    'tierAppointmentStatus',
    'tierLocation',
    'plate',
    'postcode',
    'city',
    'lead.status',
    'leadResponsibleName',
    'updated',
    'taxId',
    'VINnumber',
    'selfInspectionExists',
    'selfInspectionPriceExists',
    'sourcePathVariant',
    'carInfoExtraFields',
    'carInfoExtraFieldsAppointment'
  ],
  hiddenFilters: [
    'tierAppointmentStatus',
    'tierLocation',
    'tierMake',
    'postcode',
    'city',
    'taxId',
    'tierLeadSource',
    'tierLeadStatus',
    'appointmentAddress',
    'selfInspectionPrice',
    'sourcePathVariant'
  ],
  hiddenFeatures: [],
  appointmentTypesForWhichAddressIsRequired: [
    'c2cMeetingHome',
    'dealerInspectionHome',
    'purchaseInspectionHome'
  ],
  disabledFields: {walkin: {leadForm: ['price']}},
  purchaseCoordinatorInput,
  hasSelfInspection: true
}
