import React from 'react'
import { Grid, IconButton, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { Edit as EditIcon, Close, CircularProgress } from '@material-ui/icons'
import {ValidatedForm} from '@fcg/formvalidation'
import { addUserToRole } from '../../../store/crmUserGroups/actions'
import {connect} from 'react-redux'
import * as AuthApi from '../../../api/auth/requests'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import LoadingState from '../../../components/LoadingState'
import EmptyState from '../../../components/EmptyState'

const SearchUser = (props) => {
    const [searchUserName, setSearchUserName] = useState(null)
    const [userClickedId, setUserClickedId] = useState(null)
    const [searchUserList, setSearchUserList] = useState([])
    const [searchFlag, setSearchFlag] = useState(false)
    const { t, selected, loadingUsers, userAddedToGroup } = props
    const searchUserInputDebounce = _.debounce((term) => searchUserInput(term), 800)
    const searchUserInput = (key) => {
        setSearchUserName(key)
    }
    
    const addUserToGroupAction = (value) => {
        setUserClickedId(value)
        props.addUserToRole(selected, value)
    }

    useEffect(() => {
        // move this to redux
        if(searchUserName !== null && searchUserName !== ''){
            setSearchFlag(true)
            const loadOptions = {formatLabel :undefined}
            const query = {limit: 100, page: 1, deletedUser: false, userClass: 'INTERNAL', ...(searchUserName !== null ? {anyName: searchUserName} : {})}
            AuthApi.getUserList({...query}).then((response) => {
                const options =
                  response.user && response.user.list
                    ? response.user.list.map((user) => ({
                        label:
                          loadOptions && loadOptions.formatLabel
                            ? loadOptions.formatLabel(user)
                            : `${user.firstname} ${user.lastname}`,
                        value: user.id,
                        flag: null
                      }))
                    : []
                setSearchUserList([...options])
                setSearchFlag(false)
              })
        }else{
            setSearchUserList([])
        }
    }, [searchUserName])

    useEffect(() => {
        let newList = searchUserList.map((item) => {
            // if(item.value === userClickedId){
            //     return {...item, flag: userAddedToGroup[item.value]}
            // }
            // else{
            //     return {...item}
            // }
            return {...item, flag: userAddedToGroup[item.value]}
        })
        setSearchUserList([...newList])
    }, [userAddedToGroup])


    const listSection = () => {
        if(searchUserList.length === 0){
            return <div style={{minHeight: '200px', display:'flex'}}><EmptyState data-testid='noUserState' /></div>
        }else{
            return <div style={{minHeight: '200px'}}>
            {
                searchFlag ? <div style={{ margin: '40px'}}><LoadingState data-testid='searchUserLoader' /></div> : searchUserList.map((item) => {
                    return (
                    <div key={item.value}>
                        <Grid  container direction='row' style={{marginTop: '20px', marginBottom: '6px'}}>
                            <Grid md={8} sm={8} xs={8} item>
                                <p data-testid='searchedUserName' style={{marginTop: '10px'}}>{item.label}</p>
                            </Grid>
                            <Grid md={4} sm={4} xs={4} item style={{textAlign: 'right'}}>
                                {item.flag === 'requested' ? <p style={{color: '#3f51b5', marginBottom: '0px'}}>Adding...</p>
                                : item.flag === 'resolved' ? <p style={{color: 'green', marginBottom: '0px'}}>User Added Successfully!</p>
                                : item.flag === 'error' ? <p style={{color: 'red', marginBottom: '0px'}}>Something went wrong!</p>
                                : <Button disabled={item.flag === 'requested' ? true : false} data-testid='addUserButton' onClick={() => addUserToGroupAction(item.value)} color='primary'>
                                Add
                            </Button>}
                                
                            </Grid>
                            
                        </Grid>
                        <hr style={{border: '0.5px solid #e5e5e5'}} />
                    </div>)
                    
                })
            }
            </div>
        }
    }

    // styles

    const searchUserWrapper = {
        margin: '20px',
    }

    const searchInput = {
        width: '100%',
        padding: '16px',
        border: 'none',
        backgroundColor:' #f1f1f1',
        borderRadius: '4px'
    }

    const titleContainer = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '24px'
    }

    const title = {
        color: 'rgba(55, 55, 55, 0.8)',
        fontWeight: 'bold',
        letterSpacing: '0px',
        display: 'flex'
    }

    const closeButton = {
        width: 'auto',
        height: 'auto'
    }
      
  return (
    <>
        <div style={searchUserWrapper} data-testid='searchUserWrapper'>
            <Grid item md={12}>
                <Grid container direction='row' style={titleContainer}>
                    <Grid md={11} item style={title}>
                        <span data-testid='addNewUserModalHeading'>
                            {`${props.name}`} 
                        </span>
                    </Grid>
                    <Grid md={1} item style={{textAlign: 'right'}}>
                        <IconButton
                            style={closeButton}
                            onClick={props.onClose}
                            data-testid='addNewUserModalCloseBtn'
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <ValidatedForm t={props.t}>
                <DialogContent>
                <div>
                    <input placeholder='Search users...' style={searchInput} type='text' onChange={(event)=> searchUserInputDebounce(event.target.value)} />
                </div>
                {listSection()}
                </DialogContent>
                <DialogActions>
                <Button onClick={props.onClose} color='primary'>
                    {t('button.cancel')}
                </Button>
                {/* <Button variant='raised' type='submit' color='primary'>
                    {t('global.action.add')}
                </Button> */}
                </DialogActions>
            </ValidatedForm>
        </div>
    </>
  )
}

const mapStateToProps = (state) => {
    const {
      groups: list,
      loading,
      selected,
      users,
      loadingUsers,
      userAddedToGroup
    } = state.crmUserGroups
    
    return {list, loading, selected, users, loadingUsers, userAddedToGroup}
  }
  
  const mapDispatchTtoProps = (dispatch) => ({
    addUserToRole: (roleId, userId) => dispatch(addUserToRole(roleId, userId))
  })
  
export default connect(mapStateToProps, mapDispatchTtoProps)(SearchUser)
  