import {Component} from 'react'
import {Dialog, DialogContent, DialogActions, Button} from '@material-ui/core'
import {SlideUp} from '../../../components/Animations/Slides'
import {ValidatedForm} from '@fcg/formvalidation'
import Edit from './Edit'
import {
  getAllPermissions,
  getRolePermissions,
  onChange,
  save,
  create,
  resetRolePermissions
} from '../../../store/crmUserGroups/actions'
import {
  getPermissionsConfig,
  getValues
} from '../../../store/crmUserGroups/selectors'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import LoadingState from '../../../components/LoadingState'
import SearchUser from './SearchUser'

class Editor extends Component {
  state = {
    name: ''
  }

  static getDerivedStateFromProps(props, state) {
    if (state.name === '' && props.name !== '') {
      return {
        name: props.name
      }
    }

    return null
  }

  componentDidMount() {
    this.props.getAllPermissions()

    if (this.props.selectedRoleId) {
      this.props.getRolePermissions(this.props.selectedRoleId)
    }
  }

  handleCancel = (roleId) => {
    let id = this.props.selectedRoleId || roleId
    if (typeof id !== 'string') {
      id = undefined
    }

    this.props.resetRolePermissions()
    this.props.handleClose(id)
  }

  handleSubmit = () => {
    if (this.props.selectedRoleId) {
      this.props.save(this.props.selectedRoleId, this.handleCancel)
    } else {
      this.props.create(this.state.name, this.handleCancel)
    }
  }

  onNameChange = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  render() {
    const {
      t,
      permissionsConfig,
      values,
      onChange,
      isCreate,
      loadingRolePermissions,
      loadingPermissions,
      addNewUserFlag
    } = this.props
    
    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={SlideUp}
          maxWidth='md'
          fullWidth
        >
          {addNewUserFlag 
          ? 
          <SearchUser
            data-testid='searchWarpperInModal' 
            t={t}
            value
            name={'Add new user to group'}
            onClose={this.handleCancel}
           /> 
          : 
          <ValidatedForm onSubmit={this.handleSubmit} t={t}>
            <DialogContent>
              {loadingRolePermissions || loadingPermissions ? (
                <LoadingState />
              ) : (
                <Edit
                  permissionsConfig={permissionsConfig}
                  t={t}
                  data={values}
                  onChange={onChange}
                  onNameChange={this.onNameChange}
                  value
                  isCreate={isCreate}
                  name={this.state.name}
                  onClose={this.handleCancel}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color='primary'>
                {t('button.cancel')}
              </Button>
              <Button variant='raised' type='submit' color='primary'>
                {isCreate
                  ? t('global.action.create')
                  : t('global.action.update')}
              </Button>
            </DialogActions>
          </ValidatedForm>
          }
          
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const permissionsConfig = getPermissionsConfig(state)
  const values = getValues(state)
  const {
    loadingRolePermissions,
    loadingPermissions,
    groups,
    selected
  } = state.crmUserGroups
  let name = ''

  if (selected !== -1 && groups.length) {
    const selectedGroup = groups.find(({id}) => id === selected)

    if (selectedGroup) {
      name = selectedGroup.name
    }
  }

  return {
    permissionsConfig,
    values,
    loadingRolePermissions,
    loadingPermissions,
    name
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllPermissions: () => dispatch(getAllPermissions()),
  getRolePermissions: (roleId) => dispatch(getRolePermissions(roleId)),
  onChange: (type, value) => dispatch(onChange(type, value)),
  save: (id, onComplete) => dispatch(save(id, onComplete)),
  create: (name, onComplete) => dispatch(create(name, onComplete)),
  resetRolePermissions: () => dispatch(resetRolePermissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Editor))
