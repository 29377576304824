import React, {useContext} from 'react'
import update from 'immutability-helper'

import {history} from '../../../../../history'

import {ValidationContext} from '../../../context/ValidationContext'
import {MainContext} from '../../../context/MainContext'
import {GlobalContext} from '../../../context/GlobalContext'

import {
  createOrUpdateGlobalPlaceConfiguration,
  setInitialOpeningTimes
} from '../../../api/booking/requests'
import {createOrUpdatePlace} from '../../../api/car/requests'

import {
  initialServiceDataWithGlobal,
  convertToRequestData
} from '../../../hooks/useValidation/validationHelpers'

import GlobalPlaceConfiguration from '../../../types/GlobalPlaceConfiguration'
import Location from '../../../types/Location'

export default function useConfigurationActions({
  global,
  isNewLocation
}: {
  global?: boolean
  isNewLocation?: boolean
}) {
  const {resetValidationContext, setResponseBar} = useContext(ValidationContext)

  const {
    selectedLocation,
    setSelectedLocation,
    locationList,
    setLocationList,
    countryCode,
    setLocationsServices
  } = useContext(MainContext)
  const {
    globalPlaceConfiguration,
    setGlobalPlaceConfiguration,
    globalServices
  } = useContext(GlobalContext)

  const setInitialServices = (
    newLocationId: string,
    withConfirmation?: boolean
  ) => {
    const serviceData = initialServiceDataWithGlobal(globalServices)

    setInitialOpeningTimes(serviceData, countryCode, newLocationId).then(
      () => {
        setLocationsServices(globalServices)
        const path = `/location-manager/${newLocationId}`
        history.push(path)
      },
      (e: any) => {
        console.log(e)
        withConfirmation &&
          setResponseBar({
            type: 'error',
            message: e.toString()
          })
      }
    )
  }

  const sendRequest = (requestData: any, requestType: string) => {
    createOrUpdatePlace(requestData).then(
      (place: Location) => {
        setSelectedLocation(place)
        resetValidationContext()
        if (requestType === 'create') {
          const newList = [...locationList, place]
          newList.sort((a, b) => a.location.localeCompare(b.location))
          setLocationList(newList)
          setInitialServices(place.id)
        } else {
          const locationIndex = locationList.findIndex(
            (loc) => loc.id === selectedLocation!.id
          )
          const newLocationList = update(locationList, {
            [locationIndex]: {$set: place}
          })
          setLocationList(newLocationList)
        }
        setResponseBar({
          type: 'info',
          message:
            requestType === 'create'
              ? 'New location is created'
              : 'Location is updated'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const toggleLocationStatus = () => {
    const requestData = {
      ...selectedLocation,
      active: !(selectedLocation as Location)!.active,
      metaInfo: JSON.stringify((selectedLocation as Location)!.metaInfo)
    }
    sendRequest(requestData, 'update')
  }

  const saveLocationConfiguration = (placeData: any) => {
    const type = isNewLocation ? 'create' : 'update'
    const newLocationData = convertToRequestData(
      selectedLocation as Location,
      placeData,
      countryCode === 'PL'
    )
    newLocationData.bIMapping === '' && delete newLocationData.bIMapping
    if (isNewLocation) {
      delete newLocationData.id
      newLocationData.country = countryCode
    }
    const requestData = {
      ...newLocationData,
      metaInfo: JSON.stringify(newLocationData.metaInfo)
    }
    requestData.bIMapping === '' && delete requestData.bIMapping
    sendRequest(requestData, type)
  }

  const saveGlobalPlaceConfiguration = (placeData: any) => {
    const data = {
      ...globalPlaceConfiguration,
      email: placeData.email.value,
      image: placeData.image.value,
      country: countryCode
    }
    const type = globalPlaceConfiguration!.id ? 'update' : 'create'
    createOrUpdateGlobalPlaceConfiguration(data, type).then(
      (newConfiguration: GlobalPlaceConfiguration) => {
        resetValidationContext()
        setGlobalPlaceConfiguration(newConfiguration)
        setResponseBar({
          type: 'info',
          message:
            type === 'update'
              ? 'Master template is updated'
              : 'Master template is created'
        })
      },
      (e: any) => {
        console.log(e)
        setResponseBar({
          type: 'error',
          message: e.toString()
        })
      }
    )
  }

  const onSave = (placeData: Location | GlobalPlaceConfiguration) => {
    global
      ? saveGlobalPlaceConfiguration(placeData)
      : saveLocationConfiguration(placeData)
  }

  return {onSave, toggleLocationStatus}
}
