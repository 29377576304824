import * as types from './types'

export const showSnackbarNotification = (payload) => ({
  type: types.showSnackbarNotification,
  payload
})

export const closeSnackbarNotification = () => ({
  type: types.closeSnackbarNotification
})

export const showErrorMessage = (message) => ({
  type: types.showSnackbarNotification,
  payload: {
    variant: types.variantTypes.error,
    message,
    open: true
  }
})

export const showSuccessMessage = (message, extras) => ({
  type: types.showSnackbarNotification,
  payload: {
    variant: types.variantTypes.success,
    message,
    open: true,
    extras
  }
})
