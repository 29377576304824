import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import wrapFilter from './wrapFilter'
import Input from '@material-ui/core/Input'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import {getPhoneConfiguration} from '../../../../store/config/selectors'

export const FilterPhone = (props) => {
  const {
    onBlur,
    onKeyUp,
    handleChange,
    t,
    value,
    phoneConfig: {length, code, phoneMaxLength}
  } = props
  let ifMaxLength = phoneMaxLength ? phoneMaxLength : length
  const [warn, setWarn] = useState()
  let methodProps = {
    onChange: (e) => handleChange(props.fieldName)(e.target.value),
    onBlur: (e) => onBlur(props.fieldName)(e.target.value, null, true)
  }

  if (length) {
    const modifyValue = (value) => {
      const isPlusAdded = value.startsWith('+')
      value = value.replace(/\D+/g, '')
      if (isPlusAdded) {
        value = '+' + value
      }
      return value
    }
    methodProps = {
      inputProps: {maxLength: Number(code.length + ifMaxLength)},
      onChange: (e) => {
        let value = e.target.value
        value = modifyValue(value)
        handleChange(props.fieldName)(value)
      },
      onBlur: (e) => {
        let value = e.target.value
        value = modifyValue(value)
        onBlur(props.fieldName)(value, null, true)
      }
    }
    useEffect(() => {
      if (value.length && value.length < length) {
        setWarn(t('contact.phone.lengthValidation', {length}))
      } else {
        setWarn()
      }
    }, [value])
  }

  return (
    <>
      <Input type='text' onKeyUp={onKeyUp} value={value} {...methodProps} />
      {warn && <h5 style={{color: 'red'}}>{warn}</h5>}
    </>
  )
}

FilterPhone.propTypes = {
  handleChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func
}

FilterPhone.defaultProps = {
  handleChange: () => {},
  onKeyUp: () => {},
  onBlur: () => {}
}

const mapStateToProps = (state) => ({
  phoneConfig: getPhoneConfiguration(state) || {}
})

export default connect(mapStateToProps)(translate()(wrapFilter(FilterPhone)))
