import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useContext, useState } from "react";
import UploadButton from "../Buttons/Upload";
import { uploadDocument } from "../../api/upload";
import { exportCSVFile } from '../../helpers/jsonTocsv'
import { MainContext } from "../../context/MainContext";
import Dropzone from 'react-dropzone'

const FileInput = (): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState();
  const selectFile = (files) => {
    setSelectedFile(files[0]);
  }
  const { setUpdateSlots, countryCode, setResponseBar } = useContext(MainContext)

  const UploadFile = () => {
    let data = new FormData();
    selectedFile && data.append('file', selectedFile, selectedFile.name as string)
    uploadDocument(data, countryCode, handleUploadResponse); //TBH with countryCode
  }

  const handleUploadResponse = useCallback((response) => {
    if (response?.statusCode) {
      setResponseBar({
        type: 'error',
        message: response?.message
      })
    } else {
      setUpdateSlots(response)
      exportCSVFile(response.slots, 'upload')
      setResponseBar({
        type: 'success',
        message: 'File uploaded successfully'
      })
    }
  }, [selectedFile])

  return (
    <>
      <h1 style={{
        width: '155px',
        height: '28px',
        fontSize: '24px',
        color: '#616161',
        paddingBottom: '27px'
      }}>Upload File</h1>
      <Dropzone
        onDrop={selectFile}
        disabled={false}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{
            height: '70px',
            borderRadius: '2.6px',
            border: 'dashed rgb(0, 47, 52, 0.2)',
            display: "flex",
            alignItems: "center",
            padding: '12px 19px'
          }}>
            <input {...getInputProps({"accept": ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv, .numbers"})} />
            <PublishIcon style={{ width: '48px' }} />
            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
              <span style={{ fontSize: '18.4px', fontWeight: 600, color: '#616161' }}>Browse</span>
              <span style={{ fontSize: '12px', color: '#616161', opacity: '0.6' }}>Format .csv file allowed</span>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="file-name">
        {selectedFile ? selectedFile.name : null}
      </div>
      <UploadButton
        selectedFile={selectedFile}
        uploadFile={UploadFile}
      />
    </>
  )
}

export default FileInput