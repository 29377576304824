import * as fields from './fields'
import {ApiClient, SimpleApiClient} from '@fcg/admin-api-client'
import {
  fetchAdditionalData,
  fetchDataForPayslips,
  fetchDataForOwnershipTransfer
} from './helpers'

const client = new ApiClient('tickets')
const ticketsSimpleClient = new SimpleApiClient('tickets')

const TICKET_QUERIES = {
  ticket: {
    GET: 'ticket',
    CREATE: 'createTicket',
    UPDATE: 'updateTicket',
    CREATE_ENTITY: 'createTicketEntity',
    UPDATE_ENTITY: 'updateTicketEntity',
    CREATE_COMMENT: 'createTicketComment'
  },
  ownership: {
    GET: 'ownershipTicket',
    UPDATE: 'updateOwnershipTicket',
    CREATE_ENTITY: 'createOwnershipTicketEntity',
    UPDATE_ENTITY: 'updateOwnershipTicketEntity'
  }
}

export const getTicketListFn = (query = TICKET_QUERIES.ticket.GET) => (
  params = {}
) =>
  client
    .withAdditionalRequestConfig(fetchAdditionalData)
    .query(query, {...params}, fields.extendedTicketList)

export const getCreateTicketFn = (query = TICKET_QUERIES.ticket.CREATE) => (
  params = {}
) => client.mutation(query, params, fields.ticketFields)

export const getUpdateTicketFn = (query = TICKET_QUERIES.ticket.UPDATE) => (
  params = {}
) => client.mutation(query, params, fields.ticketFields)

export const getCreateTicketEntitiesFn = (
  query = TICKET_QUERIES.ticket.CREATE_ENTITY
) => (params = {}) => client.aliasedMutation(query, params, fields.ticketEntity)

export const getUpdateTicketEntityFn = (
  query = TICKET_QUERIES.ticket.UPDATE_ENTITY
) => (params) => client.mutation(query, {...params}, fields.ticketEntity)

export const getCreateTicketCommentFn = (
  query = TICKET_QUERIES.ticket.CREATE_COMMENT
) => (params = {}) => client.mutation(query, params, fields.ticketComment)

// Payslips
export const getTicketList = getTicketListFn()
export const createTicket = getCreateTicketFn()
export const updateTicket = getUpdateTicketFn()
export const createTicketEntities = getCreateTicketEntitiesFn()
export const updateTicketEntity = getUpdateTicketEntityFn()
export const createTicketComment = getCreateTicketCommentFn()

// Ownership
export const getOwnershipTicketList = getTicketListFn(
  TICKET_QUERIES.ownership.GET
)
export const updateOwnershipTicket = getUpdateTicketFn(
  TICKET_QUERIES.ownership.UPDATE
)
export const createOwnershipTicketEntity = getCreateTicketEntitiesFn(
  TICKET_QUERIES.ownership.CREATE_ENTITY
)
export const updateOwnershipTicketEntity = getUpdateTicketEntityFn(
  TICKET_QUERIES.ownership.UPDATE_ENTITY
)

export const fetchPayslipDetails = async (data) => {
  try {
    return ticketsSimpleClient
      .withAdditionalRequestConfig(fetchDataForPayslips)
      .query('/ticket-data', data)
  } catch (error) {
    return null
  }
}

export const fetchCarOwnershipTicketDetails = async (data) => {
  try {
    return ticketsSimpleClient
      .withAdditionalRequestConfig(fetchDataForOwnershipTransfer)
      .query('/ticket-data', data)
  } catch (error) {
    return null
  }
}
