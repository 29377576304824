import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'

import {showSnackbarNotification} from '../signals/actions'
import {getCountryCode} from '../config/selectors'
import * as signalTypes from '../signals/types'
import * as types from './types'
import * as CarDataApi from '../../api/cardata/requests'
import * as selectors from './selectors'
import * as global from '../global/actions'
import gql from '@fcg/lib-gql/gql'
import pickBy from 'lodash/pickBy'
import * as documentApi from '../../api/document/requests'
import {formatParams} from '../utils'

function* fetchCarDataListSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const limit =
      action.payload && action.payload.limit
        ? action.payload.limit
        : yield select(selectors.getLimit)
    const page =
      action.payload && action.payload.page
        ? action.payload.page
        : yield select(selectors.getPage)
    let sort = yield select(selectors.getSort)
    const carData =
      action.payload && action.payload.carData
        ? action.payload.carData
        : yield select(selectors.getParsedFilterForQuery)

    const filters =
      action.payload && action.payload.filters
        ? formatParams(action.payload.filters)
        : {}
    sort =
      sort && Array.isArray(sort) && sort.length
        ? {...sort[0], order: new gql.EnumType(sort[0].order)}
        : null

    const options = {
      limit,
      page,
      sort,
      country: countryCode,
      carData: {...carData, ...filters}
    }

    const carDataList = yield call(CarDataApi.getCarData, {...pickBy(options)})

    yield put({
      type: types.setPageOptions,
      payload: {page, limit}
    })

    yield put({
      type: types.fetchCarDataListSuccess,
      payload: carDataList.getCarData
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )

    yield put({
      type: types.fetchCarDataListError,
      payload: e
    })
  }
}

function* deleteCarDataSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)

    yield call(CarDataApi.deleteCarData, {
      country: countryCode,
      ids: action.payload
    })

    yield put({
      type: types.deleteCarDataSuccess
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.cardata.delete.success',
        open: true
      })
    )
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* updateFiltersSaga() {
  try {
    const filters = yield select(selectors.getParsedFilterForQuery)
    let carData = filters

    if (filters.sellable) {
      const sellable = new gql.EnumType(filters.sellable)
      carData = {...filters, sellable}
    }

    yield put({
      type: types.fetchCarDataList,
      payload: {
        carData,
        page: 1
      }
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

function* createCarDataSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    let carData = action.payload

    if (action.payload.sellable) {
      const sellable = new gql.EnumType(action.payload.sellable)
      carData = {...action.payload, sellable}
    }

    yield call(CarDataApi.createCarData, {
      country: countryCode,
      carData
    })

    yield put({
      type: types.createCarDataSuccess
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )

    yield put({
      type: types.createCarDataError,
      error: error
    })
  }
}

function* updateCarDataSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)
    let carData = action.payload

    if (action.payload.sellable) {
      const sellable = new gql.EnumType(action.payload.sellable)
      carData = {...action.payload, sellable}
    }

    yield call(CarDataApi.updateCarData, {
      country: countryCode,
      carData
    })

    yield put({
      type: types.updateCarDataSuccess
    })
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )

    yield put({
      type: types.updateCarDataError,
      error: error
    })
  }
}

function* uploadCarDataSaga(action) {
  try {
    const countryCode = yield select(getCountryCode)

    if (action.payload?.isMultipart) {
      const {file, isMultipart, ...document} = action.payload

      yield call(CarDataApi.uploadCarDataMultipart, {
        carData: file,
        country: countryCode
      })

      yield call(documentApi.uploadDocumentMultipart, {
        fileData: file,
        ...document,
        visibility: 'internal',
        entity: 'carDataFile',
        reference: countryCode
      })
    } else {
      const {file} = action.payload.documents[0]

      yield call(CarDataApi.uploadCarData, {
        carData: file,
        country: countryCode
      })

      yield call(documentApi.uploadDocument, {
        documents: [
          {
            ...action.payload.documents[0],
            visibility: new gql.EnumType('internal'),
            entity: 'carDataFile',
            reference: countryCode
          }
        ]
      })
    }

    yield put({
      type: types.uploadCarDataSuccess
    })

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.cardata.upload.success',
        open: true
      })
    )
  } catch (error) {
    yield put({
      type: types.uploadCarDataError,
      payload: error
    })

    // TODO: Will be removed once we agreed error structure with service
    if (Array.isArray(error.errors) && error.errors.length) {
      yield put(
        global.apiError({
          ...error,
          errors: error.errors.map((e) => ({...e, message: e.properValue}))
        })
      )

      return
    }

    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message
          ? error.message
          : 'snackbar.notification.cardata.upload.error',
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchCarDataList, fetchCarDataListSaga),
  takeEvery(types.deleteCarData, deleteCarDataSaga),
  takeLatest(types.deleteCarDataSuccess, fetchCarDataListSaga),
  takeLatest(types.updateFilters, updateFiltersSaga),
  takeLatest(types.toggleSort, fetchCarDataListSaga),
  takeLatest(types.uploadCarData, uploadCarDataSaga),
  takeLatest(types.uploadCarDataSuccess, fetchCarDataListSaga),
  takeLatest(types.updateCarData, updateCarDataSaga),
  takeLatest(types.createCarData, createCarDataSaga),
  takeLatest(types.updateCarDataSuccess, fetchCarDataListSaga),
  takeLatest(types.createCarDataSuccess, fetchCarDataListSaga)
]
