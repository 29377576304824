export const locationSchema = {
  place: '',
  location: '',
  city: '',
  type: [],
  bIMapping: '',
  inspectionPointId: '',
  address1: '',
  address2: '',
  zipcode: '',
  timezone: '',
  timezoneOffset: null,
  lat: '',
  lng: '',
  active: true,
  createdAt: null,
  updatedAt: null,
  metaInfo: null,
  region: '',
  areaManager: null,
  areaManagerName: null
}

export const metaSchema = {
  mapUrl: '',
  locationPhoneUrl: '',
  locationPhone: '',
  locationEmail: '',
  imageUrl: '',
  weekdayTimingsTitle: '',
  weekdayTimingsTitle2: '',
  weekdayTimingsTime: '',
  saturdayTimingsTitle: '',
  saturdayTimingsTitle2: '',
  saturdayTimingsTime: '',
  sundayTimingsTitle: '',
  sundayTimingsTitle2: '',
  sundayTimingsTime: '',
  optionalText: '',
  metaTitle: '',
  metaDescription: ''
}

export const state = {
  list: {
    data: [],
    count: 0,
    page: 1,
    limit: 25,
    fields: [],
    fetching: false,
    sort: [
      {field: 'city', order: 'DESC'},
      {field: 'location', order: 'DESC'}
    ]
  },
  form: {...locationSchema, metaInfo: {...metaSchema}},
  defaultForm: {...locationSchema, metaInfo: {...metaSchema}},
  options: {},
  editState: {error: null, pending: false, success: false},
  deleteState: {error: null, pending: false, success: false}
}

export const getInitialState = (config = {}) => ({
  ...state,
  list: {
    ...state.list,
    fields: Object.values(config.fields || {}),
    metaInfo: Object.keys(metaSchema)
  }
})

export default getInitialState
