import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import {SlideUp} from '../../Animations/Slides'
import {dismissApiError} from '../../../store/global/actions'

export const ServerErrorDialog = ({t, ...props}) => {
  const hasErrors = props.errorMessage !== null

  const handleCopyToClipboard = () => {
    const errorList = props.errorMessage
      .map((error) => error?.message)
      .filter(Boolean)
    navigator.clipboard.writeText(errorList.join('\r\n'))
  }

  return (
    <Dialog
      fullWidth
      open={props.showErrorDialog}
      onClose={props.dismiss}
      TransitionComponent={SlideUp}
      maxWidth='sm'
    >
      <DialogTitle>{t('global.error.dialog.title')}</DialogTitle>
      <DialogContent>
        {hasErrors ? (
          <ul>
            {props.errorMessage.map(({message}, index) => (
              <li key={index}>
                {props.errorType === 'category' ? message : t(message)}
              </li>
            ))}
          </ul>
        ) : null}
      </DialogContent>
      <DialogActions>
        {hasErrors && (
          <Button
            variant='outlined'
            color='primary'
            onClick={handleCopyToClipboard}
          >
            {t('button.copyToClipboard')}
          </Button>
        )}
        <Button onClick={props.dismiss} variant='raised' color='primary'>
          {t('dialog.button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({global}) => ({
  showErrorDialog: global.showErrorDialog,
  apiError: global.apiError,
  errorMessage: global.errorMessage,
  errorType: global.errorType
})

const mapDispatchToProps = (dispatch) => ({
  dismiss: () => dispatch(dismissApiError())
})

ServerErrorDialog.defaultProps = {
  errorMessage: ''
}

const translated = translate()(ServerErrorDialog)

export default connect(mapStateToProps, mapDispatchToProps)(translated)
