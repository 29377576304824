import axios from 'axios'

function transformDetails(data) {
  return {...data, car_condition: 'all', laquesis_flag: ''}
}

export const getAipPricingQuote = async (data) => {
  const dataInAipPricingFormat = transformDetails(data)
  const res = await axios.post(
    '/api/getAipPricingQuote',
    dataInAipPricingFormat
  )
  let filterGoodCondition = res.data.data.filter(
    (item) => item.condition === 'good'
  )
  let quote = {
    quote: {
      quote:
        filterGoodCondition.length !== 0
          ? [filterGoodCondition[0].min_price, filterGoodCondition[0].max_price]
          : [0, 0]
    }
  }
  return quote
}
