import {ValidatedSelectField} from '../../../../components/FormValidation'
import {disabledCallback} from './utils'

export default {
  stepNum: 4,
  stepTitle: 'dealer.decision',
  description: 'dealer.panel.description.decision',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'DateInput',
            fullWidth: true,
            id: 'onboardingDecisionDate',
            label: 'dealer.page.field.onboardingDecisionDate',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            config: {dateFormat: 'YYYY-MM-DD HH:mm'},
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            withOther: true,
            filterable: true,
            multiple: false,
            id: 'onboardingRejectionReason',
            label: 'dealer.page.field.onboardingRejectionReason',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
