import {Fragment} from 'react'
import {withStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import {buttonBorder, buttonHeight} from './common'

const styles = {
  root: {
    border: buttonBorder,
    'min-width': buttonHeight,
    'max-height': buttonHeight,
    'margin-right': '5px'
  }
}
const RefreshButton = withStyles(styles)(Button)
export const Refresh = ({onClick, t, isLoading}) => (
  <RefreshButton onClick={onClick} disabled={isLoading}>
    <Fragment>
      <RefreshIcon />
      <span>{t('calendar.placeholder.refresh')}</span>
    </Fragment>
  </RefreshButton>
)
