import Day from './Day'

const DayList = (props) => {
  const {days, isRange, activeRange} = props

  return days.map((day) => {
    const dayValue = day.valueOf()
    const isSelected =
      isRange !== true
        ? props.selected !== null && day.isSame(props.selected, 'day')
        : props.selected !== null &&
          (day.isSame(props.selected.from, 'day') ||
            day.isSame(props.selected.to, 'day'))
    const isInRange =
      isRange === false
        ? false
        : activeRange !== null && day.within(activeRange)
    const isOutOfScope = !day.within(props.baseRange)

    return (
      <Day
        key={dayValue}
        isInRange={isInRange}
        isSelected={isSelected}
        isOutOfScope={isOutOfScope}
        handleDayClick={props.handleDayClick}
        value={day}
      />
    )
  })
}

export default DayList
