import {useCallback, useContext} from 'react'
import {
  RegexField,
  ValidatedTextField
} from '../../../../components/FormValidation'
import {ConfigContext} from '../../../../components/providers/withConfig'
import CountryOptionsSelect from '../../../../components/CountryOptionsSelect/CountryOptionsSelect'
import {Grid} from '@material-ui/core'
import {translate} from 'react-i18next'

// import { Fragment } from "react"
export function TaxInfoFields({t, fields, bpType, formData, onChange}) {
  const {bpBasedTaxInfoConditions} = useContext(ConfigContext)

  const handleFieldChange = useCallback(
    (e, key) => {
      onChange(e.target.value, key, 'taxInfo')
    },
    [onChange]
  )

  return (
    <>
      {fields.map(
        ({
          id,
          type,
          optionsSourceType,
          required,
          disabled,
          regex,
          maxLength
        }) => (
          <Grid key={`taxInfo.${id}`} item xs={12} sm={12}>
            {type === 'select' ? (
              <CountryOptionsSelect
                id={id}
                name={id}
                value={formData[id]}
                label={t(`businessPartners.taxInfo.${id}.label`)}
                bpType={bpType}
                optionsLabelPrefix='businessPartners.taxInfo'
                bpBasedTaxInfoConditions={bpBasedTaxInfoConditions}
                tabId='taxInfo'
                countryOptionType={optionsSourceType}
                onChange={(e) => handleFieldChange(e, id)}
                disabled={disabled}
                required={required}
                disableOnNoOtherOptions
                placeholderForNoOption={t('not_applicable')}
                notRequiredOnNoOptions
                fullWidth
                margin='normal'
              />
            ) : regex ? (
              <RegexField
                id={id}
                name={id}
                value={formData[id]}
                label={t(`businessPartners.taxInfo.${id}.label`)}
                margin='normal'
                fullWidth
                required={required}
                disabled={disabled}
                regex={regex}
                onChange={(e) => handleFieldChange(e, id)}
              />
            ) : (
              <ValidatedTextField
                id={id}
                name={id}
                label={t(`businessPartners.taxInfo.${id}.label`)}
                value={formData[id]}
                margin='normal'
                fullWidth
                required={required}
                disabled={disabled}
                maxLength={maxLength}
                onChange={(e) => handleFieldChange(e, id)}
              />
            )}
          </Grid>
        )
      )}
    </>
  )
}

export default translate()(TaxInfoFields)
