import {Component} from 'react'
import UserPicker from '../../../components/Pickers/UserPicker'

class DealerBase extends Component {
  state = {users: {options: [], count: 0}}
  setUserOptions = ({options, count}) => {
    this.setState({users: {options: [...options], count}})
  }
  render() {
    const fieldName = this.props.filterName

    return (
      <UserPicker
        {...this.props}
        {...this.state.users}
        filterable
        withTranslate
        label={this.props.label || fieldName}
        id={fieldName}
        selected={this.props.value}
        userClass='INTERNAL'
        onLoad={this.setUserOptions}
        onChange={this.props.handleChange(this.props.filterName)}
        debounce
      />
    )
  }
}

export default DealerBase
