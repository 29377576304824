export function sortAppointmentsForLeads(leadData) {
  return Object.values(leadData.data).map((item) => {
    const appointments = item.appointments;

    item.appointments = (appointments || []).sort(function(a, b) {
      if (a.createdAt === b.createdAt) {
        return 0;
      }

      return a.createdAt < b.createdAt ? 1 : -1;
    });

    return item;
  });
}
