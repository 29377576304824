import React from 'react'
import omit from 'lodash/omit'

import ComplexSchedulesList from './ComplexSchedulesList'

export const ExpandableComponent = (props) => {
  const prop = omit(props, [
    'rowIndex',
    'data',
    'rowId',
    'expandableComponentProps',
    'subfields'
  ])

  return (
    <ComplexSchedulesList
      {...prop}
      data={props.data}
      locale={props.locale}
      rowId={props.rowId}
      t={props.t}
      fields={props.subfields}
    />
  )
}

export default ExpandableComponent
