export default () => (
  <svg width='24px' height='24px' viewBox='0 0 24 24'>
    <title>Icons / Selfinspection / Add</title>
    <g
      id='Icons-/-Selfinspection-/-Add'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <path
        d='M18.5,3.5 C19.6045695,3.5 20.5,4.3954305 20.5,5.5 L20.5,13.84 C19.86,13.61 19.18,13.5 18.5,13.5 C15.18,13.5 12.5,16.19 12.5,19.5 C12.5,20.18 12.61,20.86 12.84,21.5 L4.5,21.5 C3.3954305,21.5 2.5,20.6045695 2.5,19.5 L2.5,5.5 C2.5,4.3954305 3.3954305,3.5 4.5,3.5 L8.68,3.5 C9,2.66 9.64,2 10.5,1.7 C12.03,1.14 13.75,1.94 14.32,3.5 L18.5,3.5 M11.5,3.5 C10.9477153,3.5 10.5,3.94771525 10.5,4.5 C10.5,5.05228475 10.9477153,5.5 11.5,5.5 C12.0522847,5.5 12.5,5.05228475 12.5,4.5 C12.5,3.94771525 12.0522847,3.5 11.5,3.5 M10.5,17.5 L10.5,15.5 L6.5,15.5 L6.5,17.5 L10.5,17.5 M12.5,13.5 L12.5,11.5 L6.5,11.5 L6.5,13.5 L12.5,13.5 M16.5,9.5 L16.5,7.5 L6.5,7.5 L6.5,9.5 L16.5,9.5 M17,14.5 L19,14.5 L19,17.5 L22,17.5 L22,19.5 L19,19.5 L19,22.5 L17,22.5 L17,19.5 L14,19.5 L14,17.5 L17,17.5 L17,14.5'
        id='Shape'
        fill='rgba(0, 0, 0, 0.54)'
      />
    </g>
  </svg>
)
