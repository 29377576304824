import {connect} from 'react-redux'
import filter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import FilterCalendar from '../../../components/RichTable/FilterBar/Filter/FilterCalendar'
import Select from '../../../components/Select/Select'
import ResponsibleBasePicker from '../../../components/Pickers/ResponsibleBasePicker'

const map = (nest) => ({crm}) => ({options: crm.options[nest]})

export const inspectionBase = () => (props) => (
  <Select
    multiple
    {...props}
    id={props.fieldName}
    label={props.filterLabel || props.label}
    selected={props.value}
    onChange={props.handleChange('placeId')}
  />
)

const inspectionLocationSetup = ({config}) => ({
  options: config.options.locationOptions.map((option) => ({
    value: option.value,
    label: option.label
  }))
})

const base = (type) => (props) => (
  <Select
    multiple={type === 'multiselect'}
    {...props}
    id={props.fieldName}
    label={props.filterLabel || props.label}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const AppointmentSlot = (props) => (
  <FilterCalendar {...props} format='YYYY-MM-DD' />
)

export const AppointmentStatus = connect(map('appointmentStatus'))(
  filter(base('multiselect'))
)

export const BookingSource = connect(map('bookingSource'))(
  filter(base('multiselect'))
)

export const SubType = connect(map('CRMCommentSubtype'))(filter(base()))

export const CancellationReason = connect(map('cancellationReason'))(
  filter(base('multiselect'))
)

export const RejectionReasons = connect(map('rejectionReasons'))(
  filter(base('multiselect'))
)

export const CallStatus = connect(map('callStatus'))(filter(base()))

export const CancelledInStatus = connect(map('cancelledInStatus'))(
  filter(base())
)

export const AppointmentType = connect(map('appointmentType'))(filter(base()))

export const ServiceType = connect(map('serviceType'))(filter(base()))

export const LeadSource = connect(map('leadSource'))(filter(base()))

export const InspectionPointName = connect(inspectionLocationSetup)(
  filter(inspectionBase())
)

export const Responsible = filter((props) => (
  <ResponsibleBasePicker
    {...props}
    fieldName='lead.responsible'
    useCaching={true}
  />
))
