import React from 'react'
import styled from 'styled-components'

const NativeTextArea = styled.textarea`
  font-size: 12px;
  font-family: Roboto;
  resize: none;
  margin-top: 5px;
  margin-bottom: 5px;
`
const NativeInput = styled.input`
  font-size: 12px;
  font-family: Roboto;
`

export default class StatefulInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.initialValue ?? '',
      error: this.props.hasError
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasError !== prevProps.hasError) {
      this.setState({error: this.props.hasError})
    }
  }

  onChange = (event) => {
    const value = event.target.value

    this.setState({value, error: false})
  }

  render() {
    const {
      inputElement: Input,
      inputType,
      nativeInput,
      textareaRows = 6,
      ...otherProps
    } = this.props

    const inputClass = this.state.error ? 'error' : ''

    if (nativeInput) {
      return inputType === 'textarea' ? (
        <NativeTextArea
          defaultValue={this.state.value}
          {...otherProps}
          rows={textareaRows}
          className={inputClass}
        />
      ) : (
        <NativeInput
          defaultValue={this.state.value}
          {...otherProps}
          className={inputClass}
        />
      )
    }

    return <Input {...otherProps} />
  }
}
