import {Component, createRef} from 'react'
import {renderNotificationsToDom, unmount} from 'admin-v2-notifications'

import {getCountryConfig} from '../../../config/country'
import merge from 'lodash/merge'
import defaultConfig from '../../config/default'
import {createTranslation} from '../../config/translation'

const ID_NAME = 'notifications'

const exportConfig = (overrides = {}) => {
  return merge({...defaultConfig}, overrides)
}

const config = exportConfig(getCountryConfig())
const i18n = createTranslation(config)

export class Notifications extends Component {
  componentDidMount() {
    renderNotificationsToDom(i18n, ID_NAME)
  }

  container = createRef()

  componentWillUnmount() {
    unmount(this.container.current)
  }

  render() {
    return <div id={ID_NAME} style={{width: '100%'}} ref={this.container} />
  }
}
