import * as types from './types'

const reducers = {
  [types.fetchAppointmentSlots]: (state) => ({
    ...state,
    isLoading: true
  }),
  [types.fetchAppointmentSlotsSuccess]: (state) => ({
    ...state,
    isLoading: false
  }),
  [types.fetchAppointmentSlotsError]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }),
  [types.toggleVisibility]: (state, action) => ({
    ...state,
    visible: {
      ...state.visible,
      [action.payload]: !state.visible[action.payload]
    }
  }),
  [types.fetchLocationOptions]: (state) => ({
    ...state,
    locationOptions: {
      ...state.locationOptions,
      isLoading: true
    }
  }),
  [types.fetchLocationOptionsSuccess]: (state) => ({
    ...state,
    locationOptions: {
      ...state.locationOptions,
      isLoading: false
    }
  }),
  [types.fetchLocationOptionsError]: (state, action) => ({
    ...state,
    locationOptions: {
      isLoading: false,
      error: action.payload
    }
  }),
  [types.onFilterChange]: (state, action) => ({
    ...state,
    filters: action.payload
  }),
  [types.onSavedFilterChange]: (state, action) => ({
    ...state,
    savedFilters: action.payload
  }),
  [types.selectAppointment]: (state, action) => ({
    ...state,
    selectedAppointment: {
      id: action.payload,
      loading: false,
      error: null
    }
  }),
  [types.selectAppointmentLoading]: (state, action) => ({
    ...state,
    selectedAppointment: {
      ...state.selectedAppointment,
      loading: action.payload
    }
  }),
  [types.selectAppointmentError]: (state, action) => ({
    ...state,
    selectedAppointment: {
      ...state.selectedAppointment,
      error: action.payload
    }
  }),
  [types.setCalendarId]: (state, action) => ({
    ...state,
    dayPicker: {
      ...state.dayPicker,
      id: action.payload
    }
  }),
  [types.setRefreshInterval]: (state, action) => ({
    ...state,
    refreshHandler: action.payload
  })
}

export default reducers
