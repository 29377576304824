import {Component} from 'react'
import {connect} from 'react-redux'
import {
  Grid,
  Button,
  Typography,
  withStyles,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {translate} from 'react-i18next'

import {ValidatedTextField, TextInput, DateInput} from '@fcg/form-builder'
import {RegexField} from '../../components/FormValidation'
import Select from '../../components/Select/Select'
import {
  hydrateLocationForm,
  createLocation,
  updateLocation,
  updateLocationForm,
  resetLocationForm
} from '../../store/locationManagement/actions'
import style from './style'
import {ValidatedForm} from '@fcg/formvalidation'
import ControlledDialogContent from '../../components/ControlledDialogContent'
import {getValueOrDefault} from '../../utils/objectOperations'
import withConfig from '../../components/providers/withConfig'
import {
  getCountryCode,
  getBiMappingCountryCodes
} from '../../store/config/selectors'
import {ValidatedUserPicker} from '../../components/Pickers/UserPicker'
export class LocationEditor extends Component {
  static defaultProps = {biMappingCountryCodes: []}

  state = {
    isEditMode: false,
    disableBIMapping: false,
    ulidRegex: '',
    users: {options: [], count: 0}
  }

  componentDidMount() {
    const countryCodes = [
      ...this.props.biMappingCountryCodes,
      this.props.countryCode
    ].join('|')

    this.loadData()
    this.setState({
      ulidRegex: `^[L, H, W].[${countryCodes}][0-9]{3}$`
    })
  }

  componentWillUnmount() {
    this.props.reset()
  }

  loadData = () => {
    const {match} = this.props
    const isEditMode = (match && match.params && match.params.id) || false

    if (isEditMode) {
      this.props.hydrate({id: match.params.id})
    }

    this.setState({isEditMode})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData()
    }

    const regex = new RegExp(this.state.ulidRegex)

    if (
      !prevProps.data.location &&
      this.props.data.location &&
      this.state.isEditMode
    ) {
      this.setState({
        disableBIMapping: regex.test(this.props.data.bIMapping)
      })
    }
  }

  routeHome = () => {
    this.props.history.push('/locations/home')
  }

  handleToggle = (name) => (e) => {
    this.update(name)(e.target.checked)
  }

  update = (name) => (event) => {
    if (!event) {
      return
    }

    const value =
      typeof event.target !== 'undefined' ? event.target.value : event

    this.props.update({name, value})
  }

  updateAreaManager = ({target}) => {
    this.props.update({name: 'areaManager', value: target.value || ''})
    this.props.update({name: 'areaManagerName', value: target.label || ''})
  }

  updateMetaField = (field) => (input) => {
    const metaInfo = (this.props.data && this.props.data.metaInfo) || {}
    const value = {...metaInfo, [field]: input}

    this.props.update({name: 'metaInfo', value})
  }

  submit = () => {
    this.form.validate().then((success) => {
      if (success === true) {
        const {isEditMode} = this.state

        return isEditMode
          ? this.props.edit(this.props.match.params.id, this.routeHome)
          : this.props.create(null, this.routeHome)
      } else {
        this.formContent.scrollTop()
      }
    })
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  getTypeOptions = (options = []) =>
    options.map((item) => ({
      ...item,
      label: this.props.t(`car.page.field.locationType.${item.value}`)
    }))

  render() {
    const {data, metaInfo, options, t, classes} = this.props
    const {isEditMode} = this.state

    const buttonLabel = isEditMode
      ? 'global.action.update'
      : 'global.action.create'
    const titleLayout = isEditMode ? 'space-between' : 'flex-start'

    return (
      <Grid container direction='column' wrap='nowrap' className='LocationEdit'>
        <Grid item className='LocationEdit__item'>
          <Grid container justify={titleLayout} alignItems='center'>
            <Button variant='outlined' onClick={this.routeHome}>
              <ArrowBack className={classes.buttonIcon} />
              {t('button.back')}
            </Button>
            <Grid item className={classes.flexGrow}>
              <Typography className={classes.title} variant='title'>
                {`${t(buttonLabel)} ${t('entity.location')}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          classes={{item: classes.form}}
          className='LocationEdit__item'
        >
          <ControlledDialogContent
            ref={(ref) => {
              this.formContent = ref
            }}
            className='LocationEdit__formContent'
          >
            <ValidatedForm
              t={t}
              ref={(ref) => {
                this.form = ref
              }}
            >
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <ValidatedTextField
                    fullWidth
                    onChange={this.update('location')}
                    label={t('location.name.label')}
                    value={data.location}
                    margin='normal'
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    multiple
                    id='type'
                    onChange={this.update('type')}
                    label={t('location.type.label')}
                    selected={data.type}
                    options={this.getTypeOptions(options.type)}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    onChange={this.update('address1')}
                    label={t('location.address.label')}
                    value={data.address1}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    onChange={this.update('address2')}
                    label={t('location.address2.label')}
                    value={data.address2}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6}>
                  <RegexField
                    id='bIMapping'
                    fullWidth
                    onChange={this.update('bIMapping')}
                    label={t('location.bIMapping.label')}
                    value={data.bIMapping === null ? '' : data.bIMapping}
                    disabled={this.state.disableBIMapping}
                    margin='normal'
                    regex={this.state.ulidRegex}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    filterable
                    id='locationCity'
                    onChange={this.update('city')}
                    label={t('location.city.label')}
                    selected={data.city}
                    options={options.city}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    onChange={this.update('zipcode')}
                    label={t('location.zipcode.label')}
                    value={data.zipcode}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    onChange={this.update('region')}
                    label={t('location.region.label')}
                    value={data.region}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={data.active}
                        onChange={this.handleToggle('active')}
                        value='active'
                        margin='normal'
                      />
                    }
                    label={t('dealer.page.field.status.active')}
                  />
                </Grid>
                {/* Read-only created, updated and deleted values */}
                <Grid item xs={4}>
                  <DateInput
                    fullWidth
                    disabled
                    label={t('location.createdAt.label')}
                    value={data.createdAt}
                    margin='normal'
                    config={{dateFormat: 'YYYY-MM-DD HH:mm'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    fullWidth
                    disabled
                    label={t('location.updatedAt.label')}
                    value={data.updatedAt}
                    margin='normal'
                    config={{dateFormat: 'YYYY-MM-DD HH:mm'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateInput
                    fullWidth
                    disabled
                    label={t('location.deletedAt.label')}
                    value={data.deletedAt}
                    margin='normal'
                    config={{dateFormat: 'YYYY-MM-DD HH:mm'}}
                  />
                </Grid>
                {/* Timezone & location */}
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    disabled
                    label={t('location.timezone.label')}
                    value={data.timezone}
                    onChange={() => {}}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextInput
                    fullWidth
                    disabled
                    label={t('location.timezoneOffset.label')}
                    value={getValueOrDefault(data.timezoneOffset)}
                    onChange={() => {}}
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={2}>
                  <ValidatedTextField
                    fullWidth
                    label={t('location.lat.label')}
                    value={data.lat || ''}
                    onChange={this.update('lat')}
                    margin='normal'
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <ValidatedTextField
                    fullWidth
                    label={t('location.lng.label')}
                    value={data.lng || ''}
                    onChange={this.update('lng')}
                    margin='normal'
                    required
                  />
                </Grid>
                {this.renderExtraField()}
                <Grid item xs={12}>
                  <Grid container spacing={16}>
                    {metaInfo.map(this.renderMetaInfoField)}
                  </Grid>
                </Grid>
              </Grid>
            </ValidatedForm>
          </ControlledDialogContent>
        </Grid>
        {/* Actions */}
        <Grid item className='LocationEdit__item'>
          <Grid container justify='flex-end'>
            <Button onClick={this.routeHome}>{t('button.cancel')}</Button>
            <Button variant='raised' color='primary' onClick={this.submit}>
              {t(buttonLabel)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderExtraField = () => {
    const {locationDetails, t, data} = this.props
    const hiddenFields = locationDetails.hidden

    return hiddenFields.includes('areaManager') ? null : (
      <Grid item xs={6}>
        <ValidatedUserPicker
          fullWidth
          userClass='INTERNAL'
          options={this.state.users.options}
          onLoad={this.setUserOptions}
          label={t('location.areaManager.label')}
          value={data.areaManager || ''}
          placeholder={data.areaManagerName}
          onChange={this.updateAreaManager}
          margin='normal'
        />
      </Grid>
    )
  }

  renderMetaInfoField = (metaField) => {
    const {data, t} = this.props
    const hiddenFields =
      this.props.language.options.length === 1
        ? [
            'saturdayTimingsTitle2',
            'sundayTimingsTitle2',
            'weekdayTimingsTitle2'
          ]
        : []

    let props = {
      fullWidth: true,
      label: t(`location.metaInfo.${metaField}.label`),
      value: data.metaInfo[metaField],
      onChange: this.updateMetaField(metaField),
      margin: 'normal'
    }

    const multiLineFields = ['metaDescription', 'optionalText']
    props = multiLineFields.includes(metaField)
      ? {
          ...props,
          multiline: true,
          rowsMax: 4
        }
      : props

    return hiddenFields.includes(metaField) === true ? null : (
      <Grid item xs={6} key={metaField}>
        <TextInput {...props} />
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const {locationManagement, config} = state

  return {
    data: locationManagement.form,
    locationDetails: config.locationDetails,
    metaInfo: locationManagement.list.metaInfo,
    options: locationManagement.options,
    countryCode: getCountryCode(state),
    biMappingCountryCodes: getBiMappingCountryCodes(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  hydrate: (params) => dispatch(hydrateLocationForm(params)),
  create: (params, next) => dispatch(createLocation(params, next)),
  update: (params) => dispatch(updateLocationForm(params)),
  edit: (id, next) => dispatch(updateLocation(id, next)),
  reset: () => dispatch(resetLocationForm())
})

const connected = connect(mapStateToProps, mapDispatchToProps)(LocationEditor)

export default withConfig(
  ['language'],
  withStyles(style, {name: 'LocationEdit'})(translate()(connected))
)
