import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'

const OverBookings = ({
  timeRangeIndex,
  slots = 2,
  onChange = () => {}
}: {
  timeRangeIndex: number
  slots: number
  onChange: (timeRangeIndex:number, field:string, value: number) => void
}) => {
  const classes = useCommonStyles()

  return (
    <Grid container>
      <Grid item xs={6} className={classes.paddingRight2}>
        <FormControlLabel
          control={
            <Switch
              checked={slots > 1}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                const slot = e.target.checked ? 2 : 1
                onChange(timeRangeIndex, 'slots', slot)
              }}
              value='allowOverBookings'
              color='primary'
            />
          }
          label='Allow overbookings'
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingLeft2}>
        <TextField
          label='Overbookings per slot'
          type='number'
          disabled={slots === 1}
          className={classes.fullWidth}
          InputProps={{inputProps: {min: 1, max: 10, value: slots-1}}}
          InputLabelProps={{
            shrink: true
          }}
          onWheel={(e: React.WheelEvent<HTMLDivElement>) => e.target.blur()}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
            onChange(timeRangeIndex, 'slots', parseInt(e.target.value)+1)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default OverBookings
