import {Component} from 'react'
import {
  withStyles,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@material-ui/core'
import {ValidatedTextField} from '@fcg/form-builder'
import {
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
  Close
} from '@material-ui/icons'
import styled from 'styled-components'

const VisibleNotVisibleContainer = styled.div`
  flex: 1;
  text-align: right;
  padding-left: 3em;
`

const VisibleIcon = styled(Visibility)`
  color: #000;
`
const VisibilityOffIcon = styled(VisibilityOff)`
  color: rgba(0, 0, 0, 0.5);
`

const VisibilityButton = ({onChange, visible}) => {
  const _onChange = (event) => {
    event.stopPropagation()

    onChange()
  }

  return (
    <VisibleNotVisibleContainer>
      <IconButton onClick={_onChange}>
        {visible ? <VisibleIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </VisibleNotVisibleContainer>
  )
}

const SubpermissionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-right: 24px;
  padding-left: 24px;
`

const SubpermissionLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
`

const Subpermission = withStyles({
  root: {
    margin: 0,
    width: '100%'
  },
  mainText: {
    fontSize: '14px'
  },
  descritpionText: {
    fontSize: '12px',
    opacity: 0.6
  },
  checkbox: {
    color: '#000',
    '&$checked': {
      color: '#000'
    }
  },
  checked: {}
})(({classes, permission, type, t, selected, onChange}) => (
  <FormGroup classes={{root: classes.root}}>
    <FormControlLabel
      className={classes.root}
      control={
        <SubpermissionContainer>
          <SubpermissionLabel>
            <Typography className={classes.mainText}>
              {t(`${permission}.${type}.title`)}
            </Typography>
            <Typography className={classes.descritpionText}>
              {t(`${permission}.${type}.description`)}
            </Typography>
          </SubpermissionLabel>
          <Checkbox
            checked={selected}
            onChange={() => onChange(permission, type)}
            classes={{root: classes.checkbox, checked: classes.checked}}
          />
        </SubpermissionContainer>
      }
    />
  </FormGroup>
))

const Heading = styled(Typography)`
  align-self: center;
  font-weight: ${({expanded}) => (expanded ? 'bold' : 500)} !important;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

const PermissionDetails = ({t, permission, config, data, onChange}) => {
  if (!config[permission]) {
    return null
  }

  const children = []

  if (config[permission].type && config[permission].type.length) {
    children.push(
      ...config[permission].type.map((item) => (
        <Subpermission
          key={`subpermission_${permission}_${item}`}
          permission={permission}
          type={item}
          t={t}
          selected={data[permission] && data[permission].includes(item)}
          onChange={onChange}
        />
      ))
    )
  }

  if (
    config[permission].extraPermissions &&
    config[permission].extraPermissions.length
  ) {
    children.push(
      ...config[permission].extraPermissions.map(({permission, type}) => (
        <Subpermission
          key={`subpermission_${permission}_${type}`}
          permission={permission}
          type={type}
          t={t}
          selected={data[permission] && data[permission].includes(type)}
          onChange={onChange}
        />
      ))
    )
  }

  return children
}

class Edit extends Component {
  state = {
    expanded: ''
  }

  onChange = (item, isVisible) => {
    if (!isVisible) {
      return
    }

    this.setState((state) => ({
      expanded: state.expanded !== item ? item : ''
    }))
  }

  onVisiblitiChange = (item, permissionVisible) => {
    if (permissionVisible) {
      this.setState({expanded: ''})
    }

    this.props.onChange(item, permissionVisible ? '' : 'READ')
  }

  render() {
    const {props} = this

    return (
      <Grid>
        {props.isCreate ? (
          <Grid item xs={12}>
            <ValidatedTextField
              required
              fullWidth
              label={props.t('crmUserGroups.name.label')}
              name='GroupName'
              id='group_name'
              value={props.name}
              onChange={props.onNameChange}
            />
          </Grid>
        ) : null}
        <div className={props.classes.section}>
          <Grid item md={12}>
            <Grid
              item
              container
              direction='row'
              className={props.classes.titleContainer}
            >
              <span className={props.classes.title}>
                {`${props.name} ${props.t(
                  'crmUserGroups.generalPermissions.label'
                )}`}
              </span>
              <IconButton
                className={props.classes.closeButton}
                onClick={props.onClose}
              >
                <Close />
              </IconButton>
            </Grid>
            {props.permissionsConfig.mainPermissions.map((item) => {
              const permissionVisible = Boolean(props.data[item])
              const editEnabled =
                props.permissionsConfig.subPermisssions[item] &&
                permissionVisible

              return (
                <ExpansionPanel
                  key={`permission_${item}`}
                  expanded={this.state.expanded === item}
                  onChange={() => this.onChange(item, editEnabled)}
                  classes={{
                    root: props.classes.extensionPanel,
                    expanded: props.classes.expandedPanel
                  }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<EditIcon />}
                    classes={{expandIcon: props.classes.expandIcon}}
                    IconButtonProps={{
                      disabled: !editEnabled,
                      className: props.classes.icon
                    }}
                  >
                    <Heading
                      expanded={this.state.expanded === item}
                      disabled={!permissionVisible}
                    >
                      {props.t(item)}
                    </Heading>
                    <VisibilityButton
                      visible={permissionVisible}
                      onChange={() =>
                        this.onVisiblitiChange(item, permissionVisible)
                      }
                    />
                  </ExpansionPanelSummary>
                  {editEnabled ? (
                    <ExpansionPanelDetails
                      classes={{root: props.classes.detailsRoot}}
                    >
                      <PermissionDetails
                        t={props.t}
                        permission={item}
                        config={props.permissionsConfig.subPermisssions}
                        data={props.data}
                        onChange={props.onChange}
                      />
                    </ExpansionPanelDetails>
                  ) : null}
                </ExpansionPanel>
              )
            })}
          </Grid>
        </div>
      </Grid>
    )
  }
}

export default withStyles({
  section: {
    paddingTop: '24px',
    width: '100%'
  },
  horizontalLine: {
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    marginTop: '24px'
  },
  selectWrapper: {
    padding: '16px 24px'
  },
  expandIcon: {
    transform: 'translateY(-50%) rotate(0deg) !important'
  },
  detailsRoot: {
    paddingRight: 0,
    paddingLeft: 0,
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '24px'
  },
  title: {
    color: 'rgba(55, 55, 55, 0.8)',
    fontWeight: 'bold',
    letterSpacing: '0px',
    display: 'flex'
  },
  closeButton: {
    display: 'flex',
    width: 'auto',
    height: 'auto'
  },
  extensionPanel: {
    boxShadow: 'none',
    '&:before': {
      bottom: 0,
      top: 'auto'
    }
  },
  expandedPanel: {
    backgroundColor: 'rgb(247, 247, 247)',
    margin: 0
  },
  icon: {
    color: '#000'
  }
})(Edit)
