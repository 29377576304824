import {Route} from 'react-router-dom'

import Login from './Login/Login'
import OAuth from './Login/OAuth'
import ConfiguredRoute from './ConfiguredRoute'
import Auth from '../components/providers/Auth'
import JumpCloudLoginCallback from './Login/JumpCloudLoginCallback'
import {UserConsumer} from '../components/providers/withAccessManagement'

export const RouteContainer = (props) => {
  return (
    <Auth {...props}>
      <ConfiguredRoute {...props} className='page'>
        <Route component={OAuth} exact path='/k2-login' />
        <Route
          exact
          path='/login/callback'
          component={JumpCloudLoginCallback}
        />
        <Route component={Login} />
      </ConfiguredRoute>
    </Auth>
  )
}

export default (props) => UserConsumer(RouteContainer, props)
