import React, {Fragment, useState, useContext, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
// import Checkbox from '@material-ui/core/Checkbox'

import {history} from '../../../../history'

import StatusLabel from '../../components/StatusLabel'
import {MainContext} from '../../context/MainContext'

import Location from '../../types/Location'

type Field = {
  id: keyof Location
  label: string
  sortType: 'locale' | 'array' | 'numeric'
}

const headCells: Field[] = [
  {id: 'location', label: 'Name', sortType: 'locale'},
  {id: 'city', label: 'City', sortType: 'locale'},
  {id: 'region', label: 'Region', sortType: 'locale'},
  {id: 'type', label: 'Services', sortType: 'array'},
  {id: 'sapPlantId', label: 'SAP Plant ID', sortType: 'locale'},
  {
    id: 'sapStorageLocationId',
    label: 'SAP Storage Location ID',
    sortType: 'locale'
  },
  {id: 'bIMapping', label: 'BI Mapping', sortType: 'locale'},
  {id: 'active', label: 'Status', sortType: 'numeric'}
]

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (field: Field) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

export function EnhancedTableHead({
  classes,
  // onSelectAllClick,
  order,
  orderBy,
  // numSelected,
  // rowCount,
  onRequestSort
}: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                onRequestSort(headCell)
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  pagination: {
    width: 400,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  pointer: {
    cursor: 'pointer'
  }
}))

export const sortArray = (
  array: Location[],
  field: Field,
  isAsc: boolean
): Location[] => {
  let sortedList
  const key = field.id
  switch (field.sortType) {
    case 'locale':
      sortedList = array.sort((a, b) =>
        (a[key] || '').localeCompare(b[key] || '')
      )
      break
    case 'array':
      sortedList = array.sort((a, b) =>
        ((a[key] || [])[0] || '').localeCompare((b[key] || [])[0] || '')
      )
      break
    default:
      sortedList = array.sort((a, b) => a[key] - b[key])
  }
  !isAsc && sortedList.reverse()

  return sortedList
}

const LocationList = ({locationList = []}: {locationList: Location[]}) => {
  const classes = useStyles()
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Location>('location')
  const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    locationList.length < 15 ? 10 : 25
  )
  const {setSelectedLocationById} = useContext(MainContext)
  const [sortedList, setSortedList] = useState<Location[]>(locationList)

  useEffect(() => {
    setSortedList(locationList)
  }, [locationList])

  const handleRequestSort = (field: Field) => {
    const isAsc = orderBy === field.id && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(field.id)
    setSortedList(sortArray(locationList, field, isAsc))
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = locationList.map((n) => n.id)

      setSelected(newSelecteds)

      return
    }
    setSelected([])
  }

  const handleSelect = (event: React.MouseEvent<unknown>, id: string) => {
    setSelectedLocationById(id)
    const path = `/location-manager/${id}`
    history.push(path)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, locationList.length - page * rowsPerPage)

  return (
    <Fragment>
      <TableContainer>
        <Table
          aria-labelledby='tableTitle'
          size='medium'
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={locationList.length}
          />
          <TableBody>
            {sortedList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown>) =>
                      handleSelect(event, row.id)
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    className={classes.pointer}
                  >
                    {/* <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{'aria-labelledby': labelId}}
                        />
                      </TableCell> */}
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.location}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.region}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {(row.type || []).reduce((typeString, type) => {
                        const str = type.charAt(0) + type.slice(1).toLowerCase()

                        return typeString === '' ? str : typeString + ', ' + str
                      }, '')}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.sapPlantId}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.sapStorageLocationId}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={!row.active ? {color: '#9e9e9e'} : {}}
                    >
                      {row.bIMapping}
                    </TableCell>
                    <TableCell align='left'>
                      <StatusLabel
                        label={row.active ? 'Active' : 'Inactive'}
                        theme={row.active ? 'active' : 'inactive'}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{height: 53 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={locationList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  )
}

export default LocationList
