export const API_STATUS = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  STALE: 'STALE'
}

export const JUMPCLOUD_USER = 'jumpcloud-user'
export const JUMPCLOUD_ID_TOKEN = 'jumpcloud-id-token'
export const JUMPCLOUD_CLIENT_ID = 'jumpcloud-client-id'
export const JUMPCLOUD_ACCESS_TOKEN = 'jumpcloud-access-token'
