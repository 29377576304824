import {Component} from 'react'
import styled from 'styled-components'
import i18next from 'i18next'
import Button from '@material-ui/core/Button'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'
import {withRouter} from 'react-router'
import {ValidatedStepForm} from '@fcg/formvalidation'
import Close from '@material-ui/icons/Close'

import {loadOptions} from '../../store/crm/actions'
import {fetchCompanyTypes} from '../../store/config/actions'
import {
  updateDealer,
  saveDealer,
  resetDealer,
  findDealerManager,
  findOnboardingAgent,
  updateVisitedPage,
  setPathMode
} from '../../store/users/actions'
import * as selectors from '../../store/users/selectors'
import {
  VALUES,
  statusDependentFieldsArray,
  STATUS_SUB_FIELDS
} from '../../config/entities/user'

import LoadingState from '../../components/LoadingState'
import Edit from './Edit'

const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
`

const FormContainer = styled.div`
  padding: 24px 40px;
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
`

const Title = styled.div`
  font-size: 24px;
  color: #000;
  display: flex;
  justify-content: space-between;
`

const CloseIcon = styled(Close)`
  align-self: center;
  cursor: pointer;
`

const Content = styled.span`
  max-width: 486px;
  margin-top: 8px;
  font-size: 14px;
  white-space: pre-line;
  color: #7e7e7e;
  letter-spacing: 0.25px;
  line-height: 20px;
`

const Footer = styled.div`
  width: 100%;
  height: 80px;
  border: 0;
  bottom: 0;
  border-top: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  flex-direction: row-reverse;
`

const ActionButton = styled(Button)`
  height: 36px;
  padding: 8px;
`

const isValidNewsletterValue = (value) =>
  typeof value !== 'undefined' && value !== null

class RightPanel extends Component {
  constructor(props) {
    super(props)

    this.link = '/dealers'
    this.state = {
      data: {},
      metaInfo: {},
      users: {options: [], count: 0},
      onboardAgents: {options: [], count: 0},
      userId: null
    }
  }

  async componentDidMount() {
    const {userId} = this.props.match.params

    this.loadDealerData(this.props)
    this.props.loadOptions()
    await this.props.fetchCompanyTypes()

    if (typeof userId !== 'undefined') {
      this.setState({userId})
    }

    this.props.setPathMode({path: 'dealers'})
  }

  // eslint-disable-next-line react/no-deprecated
  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.shouldProceed && nextProps.shouldProceed) {
      const canProceed = await this.canUserProceed()

      this.props.updateProceedStatus(canProceed)
    }
  }

  componentWillUnmount() {
    this.props.resetDealer()
  }

  loadDealerData = (props) => {
    const {metaInfo, ...data} = props.data

    if (isValidNewsletterValue(metaInfo.isNewsLetterAccepted)) {
      metaInfo.isNewsLetterAccepted = metaInfo.isNewsLetterAccepted
        ? VALUES.YES
        : VALUES.NO
    }

    this.setState({data, metaInfo})
  }

  updateField = (e, fieldName) => {
    const value =
      e !== null && typeof e.target !== 'undefined' ? e.target.value : e

    this.setState({
      data: {
        ...this.state.data,
        [fieldName]: fieldName === 'email' ? value.replace(/\s/g, '') : value
      }
    })
  }

  updateMetaField = (e, fieldName) => {
    if (
      this.props.hiddenDealerFields &&
      this.props.hiddenDealerFields.includes(fieldName)
    ) {
      return
    }

    let value = e !== null && e.target ? e.target.value : e

    let field = fieldName

    if (fieldName === 'metaInfoEmail') {
      field = 'email'
    }

    if (fieldName === 'metaInfoInternalId') {
      field = 'internalId'
    }

    value = fieldName === 'businessCategory' && value === null ? '' : value

    value =
      (fieldName === 'averageStock' || fieldName === 'idealStock') &&
      value === ''
        ? null
        : value

    const extraFields = {}

    const extraFieldsNames = [
      'dealerManager',
      'onboardingAgent',
      'dealerFarmer',
      'dealerHunter',
      'dealerBidManager'
    ]

    if (extraFieldsNames.includes(fieldName)) {
      extraFields[`${fieldName}Name`] = e.target.label || null
    }

    if (fieldName === 'status') {
      statusDependentFieldsArray.forEach((field) => {
        extraFields[field] = null
      })

      const subFields = STATUS_SUB_FIELDS[value] ? STATUS_SUB_FIELDS[value] : []
      const otherFields = statusDependentFieldsArray.filter(
        (field) => !subFields.includes(field)
      )

      if (this.form?.form) {
        this.form.form.setIgnoredFieldIds(otherFields)
      }
    }

    if (statusDependentFieldsArray.includes(fieldName)) {
      const otherFields = statusDependentFieldsArray.filter(
        (field) => field !== fieldName
      )

      otherFields.forEach((field) => {
        extraFields[field] = null
      })

      if (this.form?.form) {
        this.form.form.setIgnoredFieldIds(otherFields)
      }
    }

    if (fieldName === 'businessTypeSelect') {
      field = 'businessType'

      this.setState({
        metaInfo: {
          ...this.state.metaInfo,
          [field]: value,
          businessTypeSelect: value,
          ...extraFields
        }
      })
    } else {
      this.setState({
        metaInfo: {...this.state.metaInfo, [field]: value, ...extraFields}
      })
    }
  }

  validate = async () => {
    return this.props.readOnlyPath ? true : await this.form.validate()
  }

  goNext = async () => {
    const value = await this.validate()

    if (value && this.props.formLength > this.props.selected) {
      const data = {...this.state.data, metaInfo: this.state.metaInfo}

      this.props.updateDealer(data)
      this.props.updateVisitedPage(this.props.selected)
      this.props.updateSelection(this.props.selected + 1)
    }
  }

  canUserProceed = async () => {
    const value = await this.validate()

    const data = {...this.state.data, metaInfo: this.state.metaInfo}

    this.props.updateDealer(data)

    return value
  }

  goPrevious = () => {
    if (this.props.selected > 1) {
      const data = {...this.state.data, metaInfo: this.state.metaInfo}

      this.props.updateDealer(data)
      this.props.updateSelection(this.props.selected - 1)
    }
  }

  setDealerOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  setOnboardingAgentOptions = ({options, count}) => {
    this.setState({onboardingAgents: {options, count}})
  }

  setDealerFarmerOptions = ({options, count}) => {
    this.setState({dealerFarmers: {options, count}})
  }

  setDealerHunterOptions = ({options, count}) => {
    this.setState({dealerHunters: {options, count}})
  }

  setDealerBidManagerOptions = ({options, count}) => {
    this.setState({dealerBidManagers: {options, count}})
  }

  save = () => {
    const user = omit(this.state.data, ['loaded', 'groups'])
    const data = {
      ...user,
      metaInfo: {
        ...omit(this.state.metaInfo, [
          ...this.props.hiddenDealerFields,
          ...this.props.ignoreOnSave
        ])
      }
    }

    if (this.props.ignoreOnSave.includes('businessTypeSelect')) {
      data['metaInfo'].businessType = this.state.metaInfo['businessTypeSelect']
    }

    if (isValidNewsletterValue(this.state.metaInfo.isNewsLetterAccepted)) {
      data.metaInfo.isNewsLetterAccepted =
        data.metaInfo.isNewsLetterAccepted === VALUES.YES
    }

    this.props.saveDealer(data)
  }

  handleClose = () => {
    this.props.history.goBack()
  }

  render() {
    const {metaInfo, data} = this.state
    const {formConfig, options} = this.props

    if (
      (this.props.data === null || this.props.data.loaded === false) &&
      this.state.userId
    ) {
      return <LoadingState />
    }

    return (
      <FormWrapper>
        <ValidatedStepForm
          t={this.props.t}
          ref={(ref) => (this.form = ref)}
          stepNum={this.props.selected}
          resetValidationOnStepChange
        >
          <FormContainer>
            <Title>
              {this.props.t(formConfig.formDetails.stepTitle)}
              <CloseIcon onClick={this.handleClose} />
            </Title>
            {i18next.exists(formConfig.formDetails.description) ? (
              <Content>
                {this.props.t(formConfig.formDetails.description)}
              </Content>
            ) : (
              ''
            )}
            <Edit
              {...this.props}
              t={this.props.t}
              data={data}
              users={this.state.users}
              onboardingAgents={this.state.onboardingAgents}
              setDealerOptions={this.setDealerOptions}
              setOnboardingAgentOptions={this.setOnboardingAgentOptions}
              setDealerFarmerOptions={this.setDealerFarmerOptions}
              setDealerBidManagerOptions={this.setDealerBidManagerOptions}
              setDealerHunterOptions={this.setDealerHunterOptions}
              dealerFarmers={this.state.dealerFarmers}
              dealerHunters={this.state.dealerHunters}
              dealerBidManagers={this.state.dealerBidManagers}
              form={formConfig.form}
              metaInfo={metaInfo}
              updateField={this.updateField}
              updateMetaField={this.updateMetaField}
              options={options}
            />
          </FormContainer>
        </ValidatedStepForm>
        <Footer>
          {this.props.selected < this.props.formLength && (
            <ActionButton
              variant='contained'
              color='primary'
              onClick={this.goNext}
              data-test='button-next'
            >
              {this.props.t('button.next')}
            </ActionButton>
          )}
          {this.props.selected === this.props.formLength &&
            !this.props.readOnlyPath && (
              <ActionButton
                variant='contained'
                color='primary'
                onClick={this.save}
                data-test='button-save'
              >
                {this.props.t('button.save')}
              </ActionButton>
            )}
          {this.props.selected > 1 && (
            <ActionButton variant='outlined' onClick={this.goPrevious}>
              {this.props.t('button.previous')}
            </ActionButton>
          )}
        </Footer>
      </FormWrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  formConfig: selectors.getFormConfig(state, ownProps.selected),
  data: selectors.getDealerData(state),
  formLength: selectors.getFormLength(state),
  options: selectors.getDealerOptions(state),
  hiddenDealerFields: selectors.getHiddenDealerFields(state),
  ignoreOnSave: selectors.getIgnoredFields(state),
  readOnlyPath: selectors.isPathReadOnly(state)
})

const mapDispatchToProps = (dispatch) => ({
  saveDealer: (data) => dispatch(saveDealer(data)),
  loadOptions: () => dispatch(loadOptions()),
  fetchCompanyTypes: () => dispatch(fetchCompanyTypes()),
  resetDealer: () => dispatch(resetDealer()),
  updateDealer: (data) => dispatch(updateDealer(data)),
  findDealerManager: (userId) => dispatch(findDealerManager(userId)),
  findOnboardingAgent: (userId) => dispatch(findOnboardingAgent(userId)),
  updateVisitedPage: (payload) => dispatch(updateVisitedPage(payload)),
  setPathMode: (pathConfig) => dispatch(setPathMode(pathConfig))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(withRouter(RightPanel)))
