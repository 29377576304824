import styled from 'styled-components'
import {translate} from 'react-i18next'
import {RenderIf} from '../../../components/RenderIf'

const FileInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.69);
`
const FileName = styled.span`
  font-size: 20px;
`

export const FileInfo = ({currentImage, t}) => (
  <FileInfoWrapper>
    <RenderIf if={currentImage.category}>
      <FileName>{t(currentImage.category)}</FileName>
    </RenderIf>
    <div>
      <span>{t(currentImage.status)}</span>{' '}
      <span>{currentImage.statusTime}</span>
    </div>
  </FileInfoWrapper>
)

export default translate()(FileInfo)
