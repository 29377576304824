import CarOwnership from '../../../pages/CarOwnership/List'
import {tableFields, subtableFields} from './listConfig'
import {
  OWNERSHIP_PERMISSION,
  NECCESSARY_PERMISSIONS
} from '../../../store/crmUserGroups/permissionsConfig'

export const sort = [{field: 'createdAt', order: 'DESC'}]
export const filters = []
export const list = {
  page: 1,
  limit: 25
}

export default {
  path: 'carOwnershipTransfer',
  key: 'carOwnershipTransfer',
  label: 'carOwnershipTransfer.sectionHeadline',
  sort: [],
  filters,
  list,
  fields: {...tableFields},
  subtableFields: {...subtableFields},
  subfields: [{key: 'subtableData'}],
  component: CarOwnership,
  permissions: {
    entity: OWNERSHIP_PERMISSION,
    types: NECCESSARY_PERMISSIONS.READ
  }
}
