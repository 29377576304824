import React from 'react'
import Location from '../types/Location'
import Country from '../types/Country'
import GlobalPlaceConfiguration from '../types/GlobalPlaceConfiguration'
import ServicesObject from '../types/ServicesObject'
import HolidayObject from '../types/HolidayObject'
import User from '../types/User'
import AreaObject from '../types/AreaObject'
import LotObject from '../types/LotObject'

interface State {
  locationList: Location[]
  cities: string[]
  areaManagers: User[]
  locationTypes: string[]
  countryCode: Country
  showMaster: boolean //needs to be set according to the user permission
  selectedLocation: Location | null | GlobalPlaceConfiguration
  locationsHolidays: HolidayObject[]
  locationsServices: ServicesObject
  locationAreas: AreaObject[]
  locationLots: LotObject[]
}

const initialState: State = {
  locationList: [],
  cities: [],
  locationTypes: [],
  areaManagers: [],
  countryCode: null!, //initially null
  showMaster: true, //needs to be set according to the user permission
  selectedLocation: null,
  locationsHolidays: [],
  locationsServices: {},
  locationAreas: [],
  locationLots: []
}

type Action =
  | {type: 'SET_LOCATION_LIST'; value: Location[]}
  | {type: 'SET_CITIES'; value: string[]}
  | {type: 'SET_LOCATION_TYPES'; value: string[]}
  | {type: 'SET_AREA_MANAGERS'; value: User[]}
  | {
      type: 'SET_SELECTED_LOCATION'
      value: Location | null | GlobalPlaceConfiguration
    }
  | {type: 'SET_SELECTED_LOCATION_BY_ID'; value: string}
  | {type: 'SET_COUNTRY_CODE'; value: Country}
  | {type: 'SET_SHOW_MASTER'; value: boolean}
  | {type: 'SET_SERVICES'; value: ServicesObject}
  | {type: 'SET_HOLIDAYS'; value: HolidayObject[]}
  | {type: 'ADD_OR_UPDATE_HOLIDAY'; value: HolidayObject}
  | {type: 'DELETE_HOLIDAY'; value: string}
  | {type: 'ADD_OR_UPDATE_AREA'; value: AreaObject}
  | {type: 'DELETE_AREA'; value: string}
  | {type: 'DELETE_LOT'; value: string}
  | {type: 'UPDATE_LOT'; value: LotObject}

function reducer(state: State, action: Action): State {
  let newHolidays = null
  let newAreas = null
  let newLots = null
  switch (action.type) {
    case 'SET_LOCATION_LIST':
      return {...state, locationList: action.value}
    case 'SET_CITIES':
      return {...state, cities: action.value}
    case 'SET_LOCATION_TYPES':
      return {...state, locationTypes: action.value}
    case 'SET_AREA_MANAGERS':
      return {...state, areaManagers: action.value}
    case 'SET_SELECTED_LOCATION':
      return {
        ...state,
        selectedLocation: action.value
      }
    case 'SET_SELECTED_LOCATION_BY_ID':
      return {
        ...state,
        selectedLocation: state.locationList.find(
          (location) => location.id === action.value
        )!,
        locationsHolidays: [],
        locationsServices: {}
      }
    case 'SET_COUNTRY_CODE':
      return {...state, countryCode: action.value}
    case 'SET_SHOW_MASTER':
      return {...state, showMaster: action.value}
    case 'SET_SERVICES':
      return {...state, locationsServices: action.value}
    case 'SET_HOLIDAYS':
      return {...state, locationsHolidays: action.value}
    case 'DELETE_HOLIDAY':
      newHolidays = state.locationsHolidays.filter(
        (holiday) => holiday.id !== action.value
      )

      return {...state, locationsHolidays: newHolidays}
    case 'ADD_OR_UPDATE_HOLIDAY':
      newHolidays = state.locationsHolidays.filter(
        (holiday) => holiday.id !== action.value.id
      )
      newHolidays.push(action.value)

      return {...state, locationsHolidays: newHolidays}

    case 'DELETE_AREA':
      newAreas = state.locationAreas.filter((area) => area.id !== action.value)

      return {...state, locationAreas: newAreas}
    case 'ADD_OR_UPDATE_AREA':
      newAreas = state.locationAreas.filter(
        (area) => area.id !== action.value.id
      )
      newAreas.push(action.value)

      return {...state, locationAreas: newAreas}
    case 'DELETE_LOT':
      newLots = state.locationLots.filter((lot) => lot.id !== action.value)

      return {...state, locationLots: newLots}
      case 'UPDATE_LOT':
        newLots = state.locationLots.filter((lot) => lot.id !== action.value.id)

        newLots.push(action.value)
  
        return {...state, locationLots: newLots}

    default:
      return state
  }
}

type ContextValue = State & {
  setCountryCode(value: string): void
  setLocationList(value: Location[]): void
  setCities(value: string[]): void
  setLocationTypes(value: string[]): void
  setAreaManagers(value: User[]): void
  setSelectedLocation(value: Location | null | GlobalPlaceConfiguration): void
  setSelectedLocationById(value: string): void
  setLocationsServices(value: ServicesObject): void
  deleteFromLocationsHolidays(value: string): void
  setLocationsHolidays(value: HolidayObject[]): void
  addOrUpdateLocationsHoliday(value: HolidayObject): void
  deleteAreaFromLotAllocation(value: string): void
  deleteLotFromLotAllocation(value: string): void
  addOrUpdateAreaInLotAllocation(value: AreaObject): void
  updateLotFromLotAllocation(value: LotObject): void
}

export const MainContext = React.createContext<ContextValue>(null!)
MainContext.displayName = 'MainContext'

export default ({children}: {children: React.ReactNode}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: ContextValue = {
    locationList: state.locationList,
    countryCode: state.countryCode,
    cities: state.cities,
    locationTypes: state.locationTypes,
    areaManagers: state.areaManagers,
    showMaster: state.showMaster,
    selectedLocation: state.selectedLocation,
    locationsHolidays: state.locationsHolidays,
    locationsServices: state.locationsServices,
    locationAreas: state.locationAreas,
    locationLots: state.locationLots,
    setCountryCode: (value: Country) =>
      dispatch({
        type: 'SET_COUNTRY_CODE',
        value: value
      }),
    setLocationList: (value) =>
      dispatch({
        type: 'SET_LOCATION_LIST',
        value: value
      }),
    setCities: (value) =>
      dispatch({
        type: 'SET_CITIES',
        value: value
      }),
    setLocationTypes: (value) =>
      dispatch({
        type: 'SET_LOCATION_TYPES',
        value: value
      }),
    setAreaManagers: (value) =>
      dispatch({
        type: 'SET_AREA_MANAGERS',
        value: value
      }),
    setSelectedLocation: (value) =>
      dispatch({
        type: 'SET_SELECTED_LOCATION',
        value: value
      }),
    setSelectedLocationById: (value) =>
      dispatch({
        type: 'SET_SELECTED_LOCATION_BY_ID',
        value: value
      }),
    setLocationsServices: (value) =>
      dispatch({
        type: 'SET_SERVICES',
        value: value
      }),
    setLocationsHolidays: (value) =>
      dispatch({
        type: 'SET_HOLIDAYS',
        value: value
      }),
    deleteFromLocationsHolidays: (value) =>
      dispatch({
        type: 'DELETE_HOLIDAY',
        value: value
      }),
    addOrUpdateLocationsHoliday: (value) =>
      dispatch({
        type: 'ADD_OR_UPDATE_HOLIDAY',
        value: value
      }),
    deleteAreaFromLotAllocation: (value) =>
      dispatch({
        type: 'DELETE_AREA',
        value
      }),
    addOrUpdateAreaInLotAllocation: (value) =>
      dispatch({
        type: 'ADD_OR_UPDATE_AREA',
        value
      }),
    deleteLotFromLotAllocation: (value) =>
      dispatch({
        type: 'DELETE_LOT',
        value
      }),
      updateLotFromLotAllocation: (value) =>
        dispatch({
          type: 'UPDATE_LOT',
          value
        })
  }

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}
