import {Link} from 'react-router-dom'
import styled from 'styled-components'

const Title = styled(Link)`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
`

export const HeaderTitle = (props) => {
  if (!props.data.firstname || !props.data.lastname || !props.userId) {
    return null
  }

  return (
    <Title to={`/drivers/edit/${props.userId}`} data-test='field-driver-name'>
      {props.data.firstname} {props.data.lastname}
    </Title>
  )
}
