const resourceName = 'countryOption'

export const fetchCountryOptionList = `${resourceName}/FETCH_CountryOption_LIST`
export const fetchCountryOptionListSuccess = `${resourceName}/FETCH_CountryOption_LIST_SUCCESS`
export const fetchCountryOptionListError = `${resourceName}/FETCH_CountryOption_LIST_ERROR`

export const deleteCountryOption = `${resourceName}/DELETE_CountryOption`
export const deleteCountryOptionSuccess = `${resourceName}/DELETE_CountryOption_SUCCESS`
export const deleteCountryOptionError = `${resourceName}/DELETE_CountryOption_ERROR`
export const createCountryOption = `${resourceName}/CREATE_CountryOption`
export const createCountryOptionSuccess = `${resourceName}/CREATE_CountryOption_SUCCESS`
export const createCountryOptionError = `${resourceName}/CREATE_CountryOption_ERROR`

export const openCreateDialog = `${resourceName}/OPEN_CREATE_DIALOG`
export const hideCreateDialog = `${resourceName}/HIDE_CREATE_DIALOG`

export const updateCountryOptionField = `${resourceName}/UPDATE_COUNTRY_OPTION_FIELD`

export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const toggleSort = `${resourceName}/TOGGLE_SORT`
