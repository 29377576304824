import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import * as signalSelectors from '../../store/signals/selectors'
import {closeSnackbarNotification} from '../../store/signals/actions'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const snackbarContentComponentStyles = (theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
})

const SnackbarContentComponent = (props) => {
  const {classes, className, message, onClose, variant, ...other} = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {(message | '').toString()}
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

const SnackbarContentWrapper = withStyles(snackbarContentComponentStyles)(
  SnackbarContentComponent
)

const customSnackbarStyles = (theme) => ({
  margin: {
    margin: theme.spacing.unit
  }
})

const CustomSnackbar = (props) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    props.closeSnackbarNotification()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={props.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContentWrapper
        variant={props.variant}
        message={props.message}
        onClose={handleClose}
      />
    </Snackbar>
  )
}

const mapDispatchToProps = (dispatch) => ({
  closeSnackbarNotification: () => dispatch(closeSnackbarNotification())
})

const mapStateToProps = (state) => ({
  variant: signalSelectors.getVariantType(state),
  message: signalSelectors.getMessage(state),
  open: signalSelectors.isOpen(state),
  extras: signalSelectors.getExtras(state)
})

const connected = connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar)
export default withStyles(customSnackbarStyles)(translate()(connected))
