import DocumentManagement from '../../pages/ModulePages/DocumentManagement'

export default {
  path: 'document-management/',
  exact: false,
  label: 'menu.documentManagement',
  key: 'documentManagement',
  component: DocumentManagement,
  fields: {},
  permissions: {
    entity: 'web.admin.ui.document.documentManagement',
    types: ['READ']
  }
}
