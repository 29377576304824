import {ValidatedSelectField} from '../../../../components/FormValidation'
import FormDividerField from '../../../../components/FormDivider.jsx'

export const carDetail = [
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 12,
    children: [
      {
        component: FormDividerField,
        fullWidth: true,
        id: 'carDetail',
        label: 'car.page.title.CarsDetailsForm',
        margin: 'normal'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 12,
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 4,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'carType',
            label: 'car.page.field.carType',
            margin: 'normal',
            onChangeHandler: 'updateField'
          }
        ]
      }
    ]
  }
]
