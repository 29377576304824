import * as types from './types'
import {getInitialState} from './initialState'
import * as tableDecorators from '../utils/tableDecorators'
import * as formDecorators from '../utils/formDecorators'
import {DUPLICATE, SECOND_STEP} from '../../pages/BulkUpload/constants'

const reducersFunc = {
  [types.setUploading]: (state, action) => ({
    ...state,
    isUploading: action.payload,
    uploadError: null
  }),
  [types.uploadDataError]: (state, action) => ({
    ...state,
    isUploading: false,
    uploadError: action.payload
  }),
  [types.updateStep]: (state, action) => ({
    ...state,
    step: action.payload
  }),
  [types.updateBulkUploadListVisibilityType]: (state, action) => ({
    ...state,
    activeListType: action.payload
  }),
  [types.fetchBulkUploadDataListSuccess]: (state) => ({
    ...state,
    isFetchingList: false,
    isUploading: false,
    dialogVisibility: false
  }),
  [types.toggleBulkUploadDialogVisibility]: (state, action) => ({
    ...state,
    edit: false,
    dialogVisibility: action.payload
  }),
  [types.toggleBulkUploadNotificationDialog]: (state, action) => ({
    ...state,
    dialogNotificationVisibility: action.payload
  }),
  [types.updateSuccessRequestCounter]: (state, action) => ({
    ...state,
    upload: {
      ...state.upload,
      uploadSuccess: [...state.upload.uploadSuccess, action.payload]
    }
  }),
  [types.updateDuplicateRequestCounter]: (state, action) => ({
    ...state,
    upload: {
      ...state.upload,
      duplicateCar: [...state.upload.duplicateCar, action.payload]
    }
  }),
  [types.updateFailRequestCounter]: (state, action) => ({
    ...state,
    upload: {
      ...state.upload,
      uploadFail: [...state.upload.uploadFail, action.payload]
    }
  }),
  [types.saveBulkUploadRequest]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: true
    }
  }),
  [types.checkDuplication]: (state) => ({
    ...state,
    isCheckingDuplicateCars: true
  }),
  [types.checkDuplicationSuccess]: (state) => ({
    ...state,
    isCheckingDuplicateCars: false,
    step: SECOND_STEP,
    activeListType: DUPLICATE
  }),
  [types.saveBulkUploadSuccess]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false
    }
  }),
  [types.saveBulkUploadError]: (state, action) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false,
      error: action.payload.error
    }
  }),
  [types.updateFormData]: (state, action) => ({
    ...state,
    form: {
      ...state.form,
      ...action.payload
    }
  }),
  [types.resetBulkUpload]: (state, action) => ({
    ...state,
    upload: {
      uploadSuccess: [],
      uploadFail: [],
      duplicateCar: [],
      loading: action.payload
    }
  }),
  [types.selectB2BBusinessPartnerForBulkUpload]: function(state, action) {
    return {
      ...state,
      selectedBusinessPartner: action.payload.businessPartner
    }
  }
}

export const reducers = {
  ...reducersFunc,
  ...tableDecorators.withSingleSort(types, 'list'),
  ...tableDecorators.withColumnVisibility(types, 'list'),
  ...formDecorators.generateReducer(types, {
    getInitialState
  })
}
