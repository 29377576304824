import gql from '@fcg/lib-gql'
import values from 'lodash/values'
export const arrayToObjectEnums = (array) =>
  array.reduce((a, t) => ({...a, [t]: new gql.EnumType(t)}), {})

export const TICKET_STATUS = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DONE: 'DONE'
}
export const ticketStatusValues = values(TICKET_STATUS)
export const TICKET_STATUS_ENUM = arrayToObjectEnums(ticketStatusValues)

export const PAYMENT_RECEIPT_TYPE = {
  BANK_GUARANTEE_CHEQUE: {
    label: 'payslips.table.paymentReceiptType.bankGuaranteeCheck',
    value: 'BankGuaranteedCheque'
  },
  PAYSLIP: {
    label: 'payslips.table.paymentReceiptType.paySlip',
    value: 'Payslip'
  }
}

export const TICKET_TYPES = {
  PAYMENT_PROOF: 'PAYMENT_PROOF',
  CAR_CLAIM: 'CAR_CLAIM',
  USER_CLAIM: 'USER_CLAIM',
  OWNERSHIP: 'OWNERSHIP'
}
export const ticketTypeValues = values(TICKET_TYPES)
export const TICKET_TYPES_ENUM = arrayToObjectEnums(ticketTypeValues)

export const TICKET_ENTITY_TYPES = {
  CAR: 'CAR',
  USER: 'USER',
  DOCUMENT: 'DOCUMENT'
}
export const ticketEntityTypesValues = values(TICKET_ENTITY_TYPES)
export const TICKET_ENTITY_TYPES_ENUM = arrayToObjectEnums(
  ticketEntityTypesValues
)

export const TICKET_ENTITY_STATUS = {
  OPEN: 'OPEN',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}
export const ticketEntityStatusValues = values(TICKET_ENTITY_STATUS)
export const TICKET_ENTITY_STATUS_ENUM = arrayToObjectEnums(
  ticketEntityStatusValues
)
