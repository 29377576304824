import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const dealer = merge(
  {
    homepage: '/login',
    menuItems: {
      hiddenFields: [
        'inspection',
        'crm',
        'dealers',
        'locations',
        'documentConfig',
        'carData',
        'payslips',
        'carOwnershipTransfer',
        'crmusergroups',
        'leadAllocation',
        'transitJobs',
        'locationManager',
        'resourceManager',
        'wms',
        'ddayBookings',
        'tms',
        'attendanceManager'
      ]
    },
    permissions: {},
    fieldFilters: {
      contact: []
    }
  },
  defaultConfig
)

export const supervisor = merge(
  {
    ...dealer,
    homepage: '/dealers/list',
    menuItems: {
      hiddenFields: [
        'inspection',
        'crm',
        'locations',
        'documentConfig',
        'carData',
        'crmusergroups',
        'leadAllocation',
        'locationManager',
        'documentmanagement'
      ]
    },
    permissions: {
      auction: new Set(['read', 'create', 'update', 'remove'])
    }
  },
  defaultConfig
)

export default dealer
