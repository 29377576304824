import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import styled from 'styled-components'
import {AdornementWrapper} from './Banner/Banner'

export const DEFAULT_TILE_SELECTED_COLOR = '#FFF'
export const TILE_UNSELECTED_COLOR = '#979797'
export const COT_TILE_SELECT_BORDER = '#3f54af'

const tileBackgroundColor = (props) => {
  if (props.selected) {
    return props.selectedColor || DEFAULT_TILE_SELECTED_COLOR
  }

  return TILE_UNSELECTED_COLOR
}

export const SectionTitle = styled.h3`
  font-weight: 500;
`
const showMetaInfoInput = ({showMetaInfo}) =>
  showMetaInfo ? 'justify-content: center;' : ''
export const SelectedImage = styled.div`
  display: flex;
  background-color: #d8d8d8;
  min-height: 100%;
  min-width: 100%;
  max-height: 395px;
  max-width: 395px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  ${showMetaInfoInput};

  > img {
    height: inherit;
    width: inherit;
    max-height: inherit;
    max-width: inherit;
  }
`
export const SelectedImageWrapper = styled(SelectedImage)`
  min-height: auto;
  min-width: auto;
  max-height: auto;
  max-width: auto;
  height: 395px;
  width: 395px;
`
export const SelectedPdf = styled(SelectedImage)`
  > svg {
    font-size: 200px;
    cursor: pointer;
  }
`
export const pdfTile = styled.div`
  display: flex;
  border-radius: 3px;
  border-style: solid;
  border-color: ${tileBackgroundColor};
  width: 100%;
  max-width: 110px;
  min-width: 100px;
  height: 70px;
  max-height: 395px;
  margin-right: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    font-size: 60px;
    cursor: pointer;
  }
`
export const imageTile = styled.div`
  background: ${(props) => `url('${props.src}')`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 3px;
  border-style: solid;
  border-color: ${tileBackgroundColor};
  width: 100%;
  max-width: 110px;
  height: 70px;
  margin-right: 8px;
  cursor: pointer;

  @media (max-width: 922px) {
    max-width: 98px;
  }
`
export const MetaInfoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 400px;
`
export const ImageWithMetaInfoInputs = (props) => (
  <div>
    <SelectedImage showMetaInfo>{props.children}</SelectedImage>
    <MetaInfoPlaceholder>
      {props.metaInfoInputs ? props.metaInfoInputs : null}
    </MetaInfoPlaceholder>
  </div>
)

export const ImageTileWrapper = styled(imageTile)`
  border: none;
  background: none;
`
export const fileAlternativeRendererWrapper = {
  pdf: SelectedPdf
}
export const galleryFileAlternativeRendererWrapper = {
  pdf: pdfTile,
  image: ImageTileWrapper
}
export const fileAlternativeRenderer = {
  pdf: PictureAsPdfIcon,
  image: imageTile
}

/**
 * Returns a wrapper for selected document in document preview
 * */
export const getFileWrapper = (file, showMetaInfo) => {
  let wrapper = null

  if (file && file.fileType && fileAlternativeRendererWrapper[file.fileType]) {
    wrapper = fileAlternativeRendererWrapper[file.fileType]
  } else {
    wrapper = showMetaInfo ? ImageWithMetaInfoInputs : SelectedImage
  }

  return wrapper
}
/**
 * Returns an element for rendering selected document in  in document preview
 * */
export const getAlternativeRenderer = (file) =>
  file && file.fileType ? fileAlternativeRenderer[file.fileType] : null
/**
 * Returns an element for rendering a document in thumbnail list
 * */
export const getThumbnailListAlternativeRender = (file) =>
  file && file.fileType && fileAlternativeRenderer[file.fileType]
    ? fileAlternativeRenderer[file.fileType]
    : fileAlternativeRenderer.image
/**
 * Returns a wrapper for document thumbnail
 * */
export const getThumbnailWrapper = (file) =>
  file && file.fileType && galleryFileAlternativeRendererWrapper[file.fileType]
    ? galleryFileAlternativeRendererWrapper[file.fileType]
    : galleryFileAlternativeRendererWrapper.image

/** CoT Gallery file wrappers with banners */
export const getFileWrapperWithAdornements = ({
  file,
  adornement,
  adornementConfig,
  adornementSize,
  OutterWrapper
}) => (props) => {
  const key = file.documentKey || file.id
  const {children, ...otherProps} = props

  const elements = [
    <OutterWrapper key={`file-${key}`} {...otherProps}>
      {children}
    </OutterWrapper>
  ]

  if (adornement) {
    const adornementKey = `file-adornement-${key}`

    elements.push(
      <AdornementWrapper
        {...otherProps}
        key={adornementKey}
        Adornement={adornement}
        size={adornementSize}
        {...adornementConfig}
      />
    )
  }

  return elements
}

const defaultAdornement = {adornement: null, config: {}}

/**
 * Calculates the adornement for the file
 * TODO: Update if file needs to have more than one adornement
 * */
const getAdornement = (availableAdornements, image) => {
  const possibleAdornement = availableAdornements.find(({test}) => test(image))

  return possibleAdornement || defaultAdornement
}

/** Returns file's wrapper with or without adornements */
export const calculateFileWrapper = ({
  useAdornement,
  availableAdornements,
  image,
  adornementSize,
  showMetaInfo
}) => {
  if (useAdornement) {
    const {adornement, config} = getAdornement(availableAdornements, image)

    return getFileWrapperWithAdornements({
      file: image,
      adornement,
      adornementConfig: config,
      adornementSize,
      OutterWrapper: getFileWrapper(image, showMetaInfo)
    })
  }

  return getFileWrapper(image, showMetaInfo)
}

export const calculateThumbnailWrapper = ({
  useAdornement,
  availableAdornements,
  image,
  adornementSize
}) => {
  if (useAdornement) {
    const {adornement, config} = getAdornement(availableAdornements, image)

    return getFileWrapperWithAdornements({
      file: image,
      adornement,
      adornementConfig: config,
      adornementSize,
      OutterWrapper: getThumbnailWrapper(image)
    })
  }

  return getThumbnailWrapper(image)
}

export const OwnershipComment = styled.div`
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`
