export const categoryFields = {
  fileName: {
    key: 'fileName',
    label: 'File name',
    type: 'string',
    isFilterable: false,
    isSortable: false
  },
  uploadedAt: {
    key: 'uploadedAt',
    label: 'Uploaded at',
    type: 'date',
    isFilterable: false,
    isSortable: false
  },
  createdBy: {
    key: 'createdBy',
    label: 'Created by',
    type: 'string',
    isFilterable: false,
    isSortable: false
  }
}
