import keys from 'lodash/keys'
import pick from 'lodash/pick'
import {createSelector} from 'reselect'
import {filterTaxInfoCountryOptions} from './../../utils/businessPartners'

const selectorPath = (state) => state.crm
const optionsPath = (state) => selectorPath(state).options
export const optionsLoadingPath = (state) => selectorPath(state).optionsLoading
const pathByType = (type) => (state) => {
  const options = optionsPath(state)[type]

  if (!options) {
    return {}
  }

  return options
}

export const getOptionsArrayByType = (type) => (state) => {
  const options = pathByType(type)(state)

  return keys(options).map((key) => options[key].value)
}

export const getAppointmentStatusesByPath = (statusPaths) =>
  createSelector(pathByType('appointmentStatus'), (statuses) => {
    if (!statuses) {
      return []
    }

    return keys(statuses).map((key) => pick(statuses[key], statusPaths))
  })

const statusPathWithMeta = ['value', 'metaInfo']
const statusPath = ['value']
const filterStatusPath = ['label', 'value']
export const getAppointmentStatusesWithMeta = getAppointmentStatusesByPath(
  statusPathWithMeta
)
export const getAppointmentStatuses = getAppointmentStatusesByPath(statusPath)
export const getAppointmentStatusesFilter = getAppointmentStatusesByPath(
  filterStatusPath
)

export const getCountryOptionsSelector = () =>
  createSelector(
    ({crm}) => crm?.options,
    (_, {countryOptionType}) => countryOptionType,
    (_, {bpType}) => bpType,
    (_, {tabId}) => tabId,
    (_, {name}) => name,
    (_, {bpBasedTaxInfoConditions}) => bpBasedTaxInfoConditions,
    (
      countryOptions,
      countryOptionType,
      bpType,
      tabId,
      name,
      bpBasedTaxInfoConditions
    ) => {
      if (
        !countryOptions ||
        !(countryOptionType in countryOptions) ||
        !countryOptions[countryOptionType].length
      ) {
        return []
      }

      if (
        tabId === 'taxInfo' &&
        bpType &&
        bpBasedTaxInfoConditions &&
        Object.keys(bpBasedTaxInfoConditions).length > 0
      ) {
        const options = countryOptions[countryOptionType]

        return filterTaxInfoCountryOptions(
          name,
          bpType,
          options,
          bpBasedTaxInfoConditions
        )
      }

      return countryOptions[countryOptionType].map(
        (countryOpt) => countryOpt.value
      )
    }
  )
