import {convertToUTC} from '../../utils/dateFormatting'
import merge from 'lodash/merge'

export const createReducer = (prev, handlers) => (state = prev, action) => {
  if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
    return handlers[action.type](state, action)
  }

  return state
}

export const handleAPIErrors = (response, returnResponseCode = false) => {
  if (
    returnResponseCode &&
    response.response &&
    response.response.data &&
    response.response.data.errors &&
    Array.isArray(response.response.data.errors)
  ) {
    return {
      errors: response.response.data.errors.map((error) => ({
        message: `api.error.message.${error.code}`
      }))
    }
  }

  return response
}

export const extractResponseBody = (response) => {
  return response.data
}

export const flattenResponse = (response, level) => {
  const flatResponse = {...response}

  response.map((row, index) => {
    const fields = Object.keys(row)

    fields.map((field) => {
      if (row[field] instanceof Object) {
        Object.keys(row[field]).map((subfield) => {
          if (level === 1) {
            return subfield
          }

          if (row[field][subfield] instanceof Object) {
            Object.keys(row[field][subfield]).map((item) => {
              flatResponse[index][`${field}.${subfield}.${item}`] =
                row[field][subfield][item]
            })
          } else {
            flatResponse[index][`${field}.${subfield}`] = row[field][subfield]
          }
        })
      }
    })
  })

  return flatResponse
}

export const expand = (object) => {
  let key = {}
  let parts = {}
  let pointer = {}
  const expanded = {}

  for (let path in object) {
    const value = object[path]

    pointer = expanded

    if (path.indexOf('[') >= 0) {
      path = path.replace(/\[/g, '[.').replace(/]/g, '')
    }

    parts = path.split('.')

    while (parts.length - 1) {
      key = parts.shift()

      if (key.slice(-1) === '[') {
        key = key.slice(0, -1)
        pointer[key] = pointer[key] || []
      } else {
        pointer[key] = pointer[key] || {}
      }

      pointer = pointer[key]
    }
    pointer[parts.shift()] = value
  }

  return expanded
}

export const formatParams = (arr) =>
  typeof arr === 'undefined'
    ? {}
    : arr.reduce((acc, item) => {
        const key = item.key

        item.value =
          item.type === 'date' && item.convert !== false
            ? convertToUTC(item.value)
            : item.value

        if (key.indexOf('.') > -1) {
          const expanded = expand({[key]: item.value})

          acc = merge(acc, expanded)
        } else {
          acc[key] = item.value
        }

        return acc
      }, {})

export const parseFormNumbers = (obj, arr) => {
  const res = {...obj}

  arr.map((key) => {
    if (typeof res[key] !== 'undefined' && res[key] === '') {
      res[key] = null
    }
  })

  return res
}
