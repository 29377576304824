import {connect} from 'react-redux'
import wrapFilter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import ResponsibleBasePicker from '../../../components/Pickers/ResponsibleBasePicker'
import Select from '../../../components/Select/Select'

const map = (nest) => ({crm}) => ({options: crm.options[nest]})

const base = (type) => (props) => {
  return (
    <Select
      multiple={type === 'multiselect'}
      {...props}
      id={props.fieldName}
      label={props.label || props.fieldName}
      selected={props.value}
      onChange={props.handleChange(props.fieldName)}
    />
  )
}

export const Responsible = wrapFilter((props) => (
  <ResponsibleBasePicker {...props} fieldName='responsible' useCaching={true} />
))

export const LeadSource = connect(map('leadSource'))(
  wrapFilter(base('multiselect'))
)

export const ServiceType = connect(map('serviceType'))(
  wrapFilter(base('multiselect'))
)

export const LeadStatus = connect(map('leadStatus'))(
  wrapFilter(base('multiselect'))
)

export const RejectionReasons = connect(map('rejectionReasons'))(
  wrapFilter(base('multiselect'))
)

export const DiscardedReason = connect(map('discardedReason'))(
  wrapFilter(base())
)

export const InspectionType = connect(map('inspectionType'))(wrapFilter(base()))

export const adId = connect(map('adId'))(wrapFilter(base('string')))
