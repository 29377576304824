import React from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import StatusLabel from '../../../components/StatusLabel'
import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'

interface AreaRowProps {
  id?: string
  placeId?: string
  name?: string
  type?: string
  floor?: number
  onDelete: (id?: string) => void
  onSelect: (id?: string) => void
  editArea: (id?: string) => void
}

const AreaRow = ({
  id,
  name,
  type,
  floor,
  onSelect = () => {},
  onDelete = () => {},
  editArea = () => {}
}: AreaRowProps) => {
  const classes = useCommonStyles()

  let status: {label: string; theme: string} = {
    label: 'Free',
    theme: 'active'
  }
  let floorStr = floor === null ? 'N/A' : floor

  return (
    <Grid
      container
      className={cx(
        classes.borderBottom,
        classes.paddingY1,
        classes.hoverBackground,
        classes.itemsCenter,
        classes.paddingX2,
        classes.pointer
      )}
      onClick={() => {
        onSelect(id)
      }}
      dataTest='area-row'
    >
      <Grid item xs={3}>
        {name}
      </Grid>
      <Grid item xs={2}>
        {floorStr}
      </Grid>
      <Grid item xs={3}>
        {type || 'N/A'}
      </Grid>
      {/* <Grid item xs={2}>
        {'N/A'}
      </Grid> */}
      <Grid item xs={2}>
        <StatusLabel label={status.label} theme={status.theme} width={'50px'} />
      </Grid>
      <Grid item xs={2} className={classes.textRight}>
        {
          <>
            <IconButton
              aria-label='edit'
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                e.stopPropagation()
                editArea(id)
              }}
              data-test='edit-lot-btn'
            >
              <EditIcon />
            </IconButton>
             
            <IconButton
              aria-label='delete'
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete(id)
              }}
              data-test='delete-area-btn'
            >
              <DeleteIcon />
            </IconButton>
          </>
        }
      </Grid>
    </Grid>
  )
}

export default AreaRow
