export const initialState = {
  groups: [],
  selected: -1,
  loadingGroups: false,
  loadingUsers: false,
  userAddedToGroup: {},
  users: [],
  loadingPermissions: false,
  permissions: [],
  loadingRolePermissions: false,
  rolePermissions: [],
  rolePermissionsOriginal: []
}

export const getInitialState = () => initialState
