import moment from 'moment'

export const commentSchema = {
  responsible: null,
  responsibleName: null,
  subject: '',
  description: '',
  type: '',
  subtype: '',
  priority: 2,
  status: 'OPEN',
  dueDate: moment().add(1, 'day')
}

export const getInitialState = (overrides = {}) => ({
  list: [],
  fetching: false,
  userList: [],
  editState: {success: false, error: false, pending: false},
  form: {...commentSchema},
  ...overrides
})
