import {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {
  Edit,
  Cancel,
  Comment,
  Delete,
  CreateNewFolder
} from '@material-ui/icons'
import AppointmentEditor from './Editor'
import CancelDialog from './Cancel'
import CommentEditor from '../Comment/Editor'
import CommentPreview from '../Comment/Preview'
import PageTableBase from '../../../components/PageTableBase'
import {SuccessDialog, DeleteDialog} from '../common'
import {
  fetchList,
  toggleSort,
  setSort,
  toggleField,
  updateFilters,
  deleteAppointment,
  createCar,
  hideCarCreatedDialogBox
} from '../../../store/crm/appointment/actions'
import {loadOptions} from '../../../store/crm/actions'
import {getLocationOptions} from '../../../store/config/actions'
import withConfig from '../../../components/providers/withConfig'
import * as selectors from '../../../store/crm/appointment/selectors'
import {getHiddenFeatures} from '../../../store/common/selectors'
import {
  alwaysTrue,
  getVisibleActions,
  isDeleteButtonVisible
} from '../common/helpers'
import {PermissionWrapper} from '../../../components/Permissions'
import {checkUserPermissions} from '../../../store/auth/selectors'
import omit from 'lodash/omit'
import {isOldEntity, orangeColorRow} from '../helpers'

const deleteInitialState = {
  open: false,
  entity: null
}

export class AppointmentList extends PureComponent {
  state = {
    delete: {...deleteInitialState},
    activeAppointments: [],
    showCommentEditor: false
  }

  static defaultProps = {
    handleSubmit: () => {},
    handleCancel: () => {}
  }

  componentDidMount() {
    this.props.loadOptions()
  }

  routeBack = () => this.props.history.push(this.props.match.path)

  handleUpdate = () => {
    this.props.fetchData()
    this.routeBack()
  }

  goToEdit = ({id}) => {
    this.props.history.push(`${this.props.match.path}/edit/${id}`)
  }

  goToCancel = ({id}) => {
    this.props.history.push(`${this.props.match.path}/cancel/${id}`)
  }

  createNewCar = (item) => {
    this.props.createCar({
      appointmentId: item.id,
      country: item.lead.contact.country,
      locale: this.props.language.selected
    })
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action([...id])
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  openBulkCommentEditor = (id) => {
    this.setState({activeAppointments: Array.from(id), showCommentEditor: true})
  }

  closeBulkCommentEditor = () => {
    this.setState({activeAppointments: [], showCommentEditor: false})
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  renderCommentPreview = ({match}) => {
    const appointment =
      match.params && match.params.id
        ? this.props.data.find((item) => item.id === match.params.id)
        : undefined
    const comment =
      typeof appointment !== 'undefined' ? appointment.comments : ''

    return (
      <CommentPreview isOpen comment={comment} handleClose={this.routeBack} />
    )
  }

  multiSelectActions = isOldEntity({createdAt: new Date()})
    ? [
        {
          test: () =>
            this.props.hasPermissions({
              entity: 'web.admin.ui.crm.tasks',
              types: ['READ']
            }),
          action: {
            event: this.openBulkCommentEditor,
            icon: <Comment />,
            title: 'entity.comment'
          }
        },
        {
          test: (props) =>
            isDeleteButtonVisible(props) &&
            this.props.hasPermissions({
              entity: 'web.admin.ui.crm.appointments2',
              types: ['DELETE']
            }),
          action: {
            event: (IdsSet) => {
              this.openDeleteDialog({
                entity: 'appointment',
                id: Array.from(IdsSet),
                action: this.props.deleteAppointment
              })
            },
            icon: <Delete />,
            title: 'global.action.delete'
          }
        }
      ]
    : []

  singleSelectActions = [
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['UPDATE']
        }),
      action: {
        event: this.goToEdit,
        icon: <Edit />,
        title: 'global.action.update',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: () => this.props.createCarViaAppointment === true,
      action: {
        event: (item) => this.createNewCar(item),
        icon: <CreateNewFolder />,
        title: 'global.action.CreateCar'
      }
    },
    {
      test: () =>
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.cancelAppointment',
          types: ['READ']
        }),
      action: {
        event: this.goToCancel,
        icon: <Cancel />,
        title: 'global.action.cancelAppointment',
        hide: (item) => !isOldEntity(item) || item.cancelledAt !== null
      }
    },
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.crm.appointments2',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'appointment',
            id: [item.id],
            action: this.props.deleteAppointment
          })
        },
        icon: <Delete />,
        title: 'global.action.delete',
        hide: (item) => !isOldEntity(item)
      }
    },
    {
      test: alwaysTrue,
      action: {
        event: (item) => {
          this.props.history.push(`/appointment/${item.id}/participants/`)
        },
        icon: <Edit />,
        title: 'appointment.participantsEdit',
        hide: (item) => !isOldEntity(item)
      }
    }
  ]

  multiSelectTableActions = () =>
    getVisibleActions(this.multiSelectActions, this.props)

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  reduceTableFields = (fields) => {
    const tableFields = []

    let hidden = false

    fields.forEach((field) => {
      hidden = false

      if (field === -1) {
        return
      }

      field.children.forEach((child, i) => {
        if (this.props.crmDetails.hidden.indexOf(child.key) !== -1) {
          delete field.children[i]
          hidden = true
        } else {
          hidden = false
        }
      })

      if (hidden === false) {
        tableFields.push(field)
      }
    })

    return tableFields
  }

  renderAppointmentEdit = (routeProps) => (
    <AppointmentEditor
      {...routeProps}
      isOpen
      handleCancel={this.goToCancel}
      handleSubmit={this.handleUpdate}
      handleClose={this.routeBack}
    />
  )

  render() {
    const {className} = this.props
    const tableProps = omit(this.props, ['match', 'history', 'className'])
    tableProps.fields = this.reduceTableFields(tableProps.fields)

    return (
      <PermissionWrapper
        entity='web.admin.ui.crm.appointments2'
        withDescription
      >
        <div className={className}>
          <PageTableBase
            {...tableProps}
            page={this.props.currentPage}
            title='entity.appointment'
            filterable
            selectable
            multiSelectActions={this.multiSelectTableActions()}
            singleSelectActions={this.singleSelectTableActions()}
            rowStyle={orangeColorRow}
          />
          <PermissionWrapper
            entity='web.admin.ui.crm.appointments2'
            types={['UPDATE']}
          >
            <Route
              exact
              path={`${this.props.match.path}/edit/:id`}
              render={this.renderAppointmentEdit}
            />
          </PermissionWrapper>
          <PermissionWrapper
            entity='web.admin.ui.crm.cancelAppointment'
            types={['READ']}
          >
            <Route
              exact
              path={`${this.props.match.path}/cancel/:id`}
              render={(routeProps) => (
                <CancelDialog
                  {...routeProps}
                  isOpen
                  id={routeProps.match.params.id}
                  handleSubmit={this.handleUpdate}
                  handleClose={this.routeBack}
                />
              )}
            />
          </PermissionWrapper>
          <Route
            exact
            path={`${this.props.match.path}/appointment/comment/:id`}
            render={this.renderCommentPreview}
          />
          <CommentEditor
            isOpen={this.state.showCommentEditor}
            creation
            type='task'
            entity='appointment'
            ids={this.state.activeAppointments}
            handleSubmit={this.closeBulkCommentEditor}
            handleClose={this.closeBulkCommentEditor}
          />
          <DeleteDialog {...this.state.delete} />
          <SuccessDialog
            open={this.props.isCarCreatedDialogBoxVisible}
            url={this.props.carLink}
            handleCancel={this.props.hideCarCreatedDialogBox}
            submitActionName='general.action.openCar'
          />
        </div>
      </PermissionWrapper>
    )
  }
}

const mapStateToProps = (state, props) => {
  const {carLink, isCarCreatedDialogBoxVisible} = state.appointment
  const {page: currentPage, ...list} = state.appointment.list

  return {
    ...list,
    filterFields: selectors.getAppointmentFilters(state),
    fields: selectors.getAppointmentFields(state),
    data: list.data ? Object.values(list.data) : [],
    currentPage,
    carLink,
    isCarCreatedDialogBoxVisible,
    pageConfig: {
      hidden: {
        ...getHiddenFeatures(state)
      },
      presets: props.pageConfig.presets
    },
    hasPermissions: (permissions) => checkUserPermissions(state, permissions)
  }
}

const mapDispatchToProps = (dispatch) => {
  const fetchData = (args = {}) => dispatch(fetchList(args))

  return {
    fetchData,
    loadOptions: () => {
      dispatch(getLocationOptions())
      dispatch(loadOptions())
    },
    deleteAppointment: (ids) => dispatch(deleteAppointment({ids}, fetchData)),
    toggleSort: (sort) => dispatch(toggleSort(sort)),
    updateSort: (sort) => dispatch(setSort(sort)),
    onFilterChange: (filter) => dispatch(updateFilters(filter)),
    toggleField: ({key}) => dispatch(toggleField(key)),
    createCar: (options) => dispatch(createCar(options)),
    hideCarCreatedDialogBox: () => dispatch(hideCarCreatedDialogBox())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withConfig(
    ['language', 'countryCode', 'crmDetails', 'createCarViaAppointment'],
    AppointmentList
  )
)
