import React from 'react'
import cx from 'classnames'
import Button from '@material-ui/core/Button'
import Dialog, {DialogProps} from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

interface ExtendedDialogProps extends DialogProps {
  open: boolean
  disabled?: boolean
  hasCloseButton?: boolean
  title: string
  message?: string
  confirmLabel: string
  cancelLabel?: string
  onConfirm: () => void
  onCancel: () => void
  footerButtons?: React.ReactNode
  dataTest?: string
}

const DialogTemplate = ({
  maxWidth,
  open = false,
  hasCloseButton = true,
  title,
  message = '',
  onConfirm = () => {},
  onCancel = () => {},
  confirmLabel = 'Ok',
  cancelLabel = 'Cancel',
  children,
  disabled = false,
  footerButtons = null,
  dataTest
}: ExtendedDialogProps) => {
  const classes = useCommonStyles()

  return (
    <Dialog
      PaperProps={{square: true}}
      maxWidth={maxWidth}
      open={open}
      onClose={onCancel}
      classes={{
        paperScrollPaper: cx(classes.pickerMinWidth, classes.paddingBottom1)
      }}
      aria-labelledby='alert-dialog-title'
    >
      <div className={classes.paddingRight3}>
        <DialogTitle className={classes.colorTitle}>{title}</DialogTitle>
      </div>
      <DialogContent>
        {message && <DialogContentText>{message}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions classes={{root: classes.padding2}}>
        {footerButtons}
        <Button
          className={classes.marginRight1}
          variant='outlined'
          onClick={onCancel}
          color='primary'
        >
          {cancelLabel}
        </Button>
        <Button
          className={classes.marginRight1}
          onClick={onConfirm}
          variant='contained'
          disabled={disabled}
          color='primary'
          autoFocus
          data-test={dataTest}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
      {hasCloseButton && (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Dialog>
  )
}

export default DialogTemplate
