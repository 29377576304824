// appointment list types
export const fetching = 'appointmentList/LOADING'
export const dataLoaded = 'appointmentList/LOADED'
export const toggleField = 'appointmentList/TOGGLE_FIELD'
export const changePage = 'appointmentList/CHANGE_PAGE'
export const toggleSort = 'appointmentList/TOGGLE_SORT'
export const setSort = 'appointmentList/SET_SORT'
export const updateFilters = 'appointmentList/UPDATE_FILTER'
export const createCar = 'appointmentList/CREATE_CAR'
export const setCarLink = 'appointmentList/SET_CAR_LINK'
export const showCarCreatedDialogBox =
  'appointmentList/SHOW_CAR_CREATED_DIALOG_BOX'
export const hideCarCreatedDialogBox =
  'appointmentList/HIDE_CAR_CREATED_DIALOG_BOX'

// form types
export const updateForm = 'appointmentForm/UPDATE'
export const hydrateForm = 'appointmentForm/HYDRATE'
export const resetForm = 'appointmentForm/RESET'
export const locationChange = 'appointment/LOCATION_CHANGE'
export const loadingSlots = 'appointment/LOADING_SLOTS'
export const formError = 'appointmentForm/ERROR'
export const formSuccess = 'appointmentForm/SUCCESS'
export const formPending = 'appointmentForm/PENDING'

// cancel types
export const cancelSuccess = 'appointmentCancel/SUCCESS'
export const cancelPending = 'appointmentCancel/PENDING'
