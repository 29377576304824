const dealerDetails = require('./dealerManagement/pl')
const crmDetails = require('./CRM/pl')
const bulkuploadDetails = require('./bulkUpload/pl')
const carDataDetails = require('./carData/pl')
const locationDetails = require('./locationManagement/pl')
const payslipDetails = require('./payslips/pl')
const carOwnership = require('./carOwnership/pl')
const businessPartnersDetails = require('./businessPartnersDetails/pl')

module.exports = {
  url: '321sprzedane.pl',
  siteCode: 'olxpl',
  apiDomain: 'api.olxautos.com.pl',
  name: 'Poland',
  countryCode: 'PL',
  theme: 'poland',
  language: {
    selected: 'pl',
    options: [
      {value: 'pl', label: 'PL'},
      {value: 'en-pl', label: 'EN'}
    ]
  },
  currency: {code: 'PLN', symbol: 'zł'},
  number: {decimalSep: ',', unitSep: ' ', units: [1000]},
  locales: ['pl', 'en-pl'],
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [9], code: 48},
  referralCodeType: 'input',
  googleKey:
    '940914680813-tguclpsd4kj338pol7amg2dfdjp669nn.apps.googleusercontent.com',
  createCarViaAppointment: true,
  disabledBookingSources: ['Web Lead'],
  carDuplicatedCheckField: 'vin',
  auctionPreviewOffset: 12 * 60,
  dealerDetails,
  crmDetails,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      vin: {
        required: true,
        regex: '^.{1,17}$'
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'wms',
    'tms',
    'bulkUploadV2',
    'documentManagement',
    'attendanceManager',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: [],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  categoryId: 84,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hideFromMenu: {
    category: true
  },
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: false,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  isNotificationsEnabled: false
}
