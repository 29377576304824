import {TextField, withStyles, InputAdornment} from '@material-ui/core'
import {Search} from '@material-ui/icons'

const styles = (theme) => ({
  input: {
    padding: '0.5em',
    backgroundColor: theme.palette.background.default
  }
})

export const FilterInput = (props) => {
  const {
    id,
    className,
    label,
    currentFilter,
    onChange,
    classes,
    withStartAdornment,
    withEndAdornment
  } = props

  const startAdornment = withStartAdornment ? (
    <InputAdornment position='start'>
      <Search />
    </InputAdornment>
  ) : null
  const endAdornment =
    withEndAdornment && currentFilter.length > 0 ? (
      <InputAdornment position='end' />
    ) : null

  return (
    <TextField
      autoFocus
      fullWidth
      id={id}
      className={className}
      label={label}
      onChange={onChange}
      value={currentFilter}
      InputProps={{
        autoComplete: 'off',
        className: classes.input,
        startAdornment,
        endAdornment
      }}
    />
  )
}

FilterInput.defaultProps = {
  withStartAdornment: true,
  withEndAdornment: true
}

export default withStyles(styles, {name: 'FilterInput'})(FilterInput)
