import * as types from './types'
import * as globalActions from '../../global/actions'
import * as ContactApi from '../../../api/contact/requests'
import * as InspectionApi from '../../../api/inspection/requests'
import * as signalTypes from '../../signals/types'
import {showSnackbarNotification} from '../../signals/actions'
import {getCarDetails} from '../../../api/car/requests'
import {formatParams, parseFormNumbers} from '../../utils'
import noop from 'lodash/noop'
import {getCountryCode} from '../../config/selectors'
import {checkSelfInspectionPermission} from '../../auth/selectors'
import {filterPhone, normalizePhoneParams} from '../../../pages/CRM/helpers'

export const parseLeadInput = (data) =>
  parseFormNumbers(data, [
    'mileage',
    'plate',
    'price',
    'minPrice',
    'maxPrice',
    'year'
  ])

//flag is coming from lead list page
export const fetchList = (
  {limit, filters, sort, page} = {},
  flag = 'none'
) => async (dispatch, getState) => {
  dispatch(setFetching())
  const country = getCountryCode(getState())
  const hasSelfInspectionPermission = checkSelfInspectionPermission(getState())
  const currentState = getState().lead.list

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page

  const carLeadData = {...formatParams(filters), country}

  const {params, error} = normalizePhoneParams(
    {limit, carLeadData, sort, page},
    getState(),
    ['carLeadData', 'contact', 'phone']
  )

  const {filters: newFilters, isUpdate} = filterPhone(filters || [], getState())
  if (isUpdate) {
    dispatch({type: types.updateFilters, payload: newFilters})
  }
  if (error) {
    dispatch(
      setLeadsLoaded({
        data: {count: 0, list: []},
        limit,
        sort,
        page,
        filters
      })
    ) // to stop loader
    return dispatch(globalActions.error(error))
  }
  try {
    const leads = await ContactApi.getLeadList(params, flag)
    const leadIds = leads.lead.list.map((lead) => lead.id)
    const {count} = leads.lead
    const isOutOfRange = count > 0 && (page - 1) * limit >= count

    if (hasSelfInspectionPermission) {
      const inspectionsByLeads = await InspectionApi.getInspectionList({
        limit: 100,
        leadId: leadIds,
        onlineDrafts: null
      })

      dispatch(
        setLeadsInspectionLoaded({
          inspections: inspectionsByLeads.inspection.list
        })
      )
    }

    if (!isOutOfRange) {
      dispatch(setLeadsLoaded({data: leads.lead, limit, sort, page, filters}))
    } else {
      // There is no data at the server for this page.
      // Go back to previous page
      // https://frontiercargroup.atlassian.net/browse/IO-2649
      dispatch(fetchList({page: page - 1}))
    }
  } catch (e) {
    dispatch(globalActions.apiError(e))
  }
}

export const setLeadsLoaded = (payload) => ({
  type: types.dataLoaded,
  payload
})

export const setLeadsInspectionLoaded = (payload) => ({
  type: types.inspectionLoaded,
  payload
})

export const createLead = ({contactId, data}, next = noop) => (
  dispatch,
  getState
) => {
  dispatch(setFormPending())
  const country = getCountryCode(getState())

  return ContactApi.createLead({...parseLeadInput(data), contactId, country})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(resetLeadForm())
      dispatch(
        globalActions.success({
          message: 'global.action.create',
          entity: 'lead'
        })
      )
      next(response)

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.create',
          entity: 'lead'
        })
      )
    })
}

export const updateLead = ({id, data}, next = noop) => (dispatch) => {
  dispatch(setFormPending())
  return ContactApi.updateLead({id, carLeadData: parseLeadInput(data)})
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(resetLeadForm())
      dispatch(
        globalActions.success({
          message: 'global.action.update',
          entity: 'lead'
        })
      )
      next(response)

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.saved',
          entity: 'lead'
        })
      )
    })
}

export const deleteLead = ({ids}, next = noop) => (dispatch) => {
  return ContactApi.deleteLead(ids)
    .then((response) => {
      dispatch(
        globalActions.success({
          message: 'global.action.delete',
          entity: 'lead'
        })
      )

      next(response)
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.delete',
          entity: 'lead'
        })
      )
    })
}

export const setFetching = (fetching = true) => ({
  type: types.fetching,
  payload: fetching
})

export const toggleSort = (sort, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(fetchList({}, flag))
}

export const setSort = (sort, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.setSort,
    payload: sort
  })
  dispatch(fetchList({}, flag))
}

export const toggleField = (label) => ({
  type: types.toggleField,
  payload: label
})

export const updateFilters = (filters, flag = 'none') => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(fetchList({}, flag))
}

export const updateLeadForm = (payload) => ({
  type: types.updateForm,
  payload
})

export const resetLeadForm = (payload) => ({
  type: types.resetForm,
  payload
})

export const hydrateLeadForm = (params) => (dispatch) => {
  dispatch(setFormPending())

  return ContactApi.getLeadList({carLeadData: {...params}, limit: 1})
    .then((response) => {
      let lead = response.lead.list[0]
      lead.leadStatus = lead.status

      delete lead.status

      return dispatch({
        type: types.hydrateForm,
        payload: lead
      })
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}

export const bulkLoadLeads = (leads) => (dispatch) => {
  dispatch(setFormPending())

  const payload = []

  leads.map(async (id) => {
    const data = await ContactApi.getLeadList({carLeadData: {id}, limit: 1})

    return payload.push(data.lead.list[0])
  })

  return dispatch({
    type: types.bulkLoadLeads,
    payload
  })
}

export const setSelectedCarId = (payload) => ({
  type: 'leadList/CAR_ID',
  payload
})

export const getCarIdFromBooking = (bookingId) => (dispatch) => {
  if (bookingId) {
    return getCarDetails({bookingId})
      .then((response) => {
        const payload = response?.car?.list[0]

        dispatch({
          type: 'leadList/CAR_ID',
          payload
        })

        if (!payload) {
          dispatch(
            showSnackbarNotification({
              variant: signalTypes.variantTypes.error,
              message: 'snackbar.notification.lead.carId.empty',
              open: true
            })
          )
        }
      })
      .catch((e) => {
        dispatch(globalActions.apiError(e))
      })
  } else {
    dispatch(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.lead.appointment.empty',
        open: true
      })
    )
  }
}

export const setFormError = () => ({
  type: types.formError,
  payload: true
})

export const setFormSuccess = () => ({
  type: types.formSuccess,
  payload: true
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})
