import * as types from './types'
import * as authTypes from '../../auth/types'
import * as tableDecorators from '../../utils/tableDecorators'
import * as formDecorators from '../../utils/formDecorators'
import {payslipSchema, getInitialState} from './initialState'
import {
  useWith,
  find,
  compose,
  propEq,
  path,
  values,
  map,
  reduce,
  addIndex,
  set,
  lensPath,
  lensProp
} from 'ramda'
import {
  updatedTicketWithData,
  updateDocumentWithMetaData
} from './reducerHelper'

const reduceWithIndex = addIndex(reduce)
const dataLens = lensPath(['list', 'data'])

export const reducerFns = {
  [types.fetchPayslipsStart]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: true,
      carPaymentReceiptsSelection: {},
      expandedRows: [],
      rowsFetching: [],
      rowsFetchingErrors: []
    }
  }),
  [types.addCarPaymentSelection]: (state, action) => {
    let finalReceipts = []
    const selectedTicket = action.payload.ticketId
    const selectedReceiptId = action.payload.carReceiptId
    const paymentSelection = state.list.carPaymentReceiptsSelection
    const selectedReceipts = paymentSelection[selectedTicket]

    if (!selectedReceipts || !selectedReceipts.length) {
      finalReceipts = [selectedReceiptId]
    } else if (selectedReceipts.indexOf(selectedReceiptId) > -1) {
      finalReceipts = selectedReceipts.filter((_) => _ !== selectedReceiptId)
    } else {
      finalReceipts = [...selectedReceipts, selectedReceiptId]
    }

    return {
      ...state,
      list: {
        ...state.list,
        carPaymentReceiptsSelection: {
          ...paymentSelection,
          [selectedTicket]: finalReceipts
        }
      }
    }
  },
  [types.fetchPayslipsSuccess]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false,
      data: action.payload.list,
      count: action.payload.count
    }
  }),
  [types.fetchPayslipsError]: (state) => ({
    ...state,
    list: {
      ...state.list,
      fetching: false
    }
  }),
  [types.updateTicketEntitySuccess]: (state, action) => {
    const ticket = useWith(find, [
      compose(propEq('id'), path(['payload', 'ticketId'])),
      compose(values, path(['list', 'data']))
    ])(action, state)
    const entities = ticket.ticketEntity
    const {entityId, metaInfo, ticketId} = action.payload

    const updatedEntities = entities.map((entity) => {
      if (entity.id === entityId) {
        return {
          ...entity,
          metaInfo
        }
      }

      return entity
    })
    const updatedData = compose(
      reduceWithIndex(
        (acc, tally, index) => ({
          ...acc,
          [index]: tally
        }),
        {}
      ),
      map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            ticketEntity: updatedEntities
          }
        }

        return ticket
      }),
      values,
      path(['list', 'data'])
    )(state)

    return {
      ...state,
      list: {
        ...state.list,
        data: updatedData
      }
    }
  },
  [types.fetchPayslipOptions]: (state) => ({
    ...state,
    optionsLoading: true
  }),
  [types.setPayslipOptions]: (state, action) => ({
    ...state,
    options: {
      ...state.options,
      ...action.payload
    }
  }),
  [types.selectPayslip]: (state, action) => ({
    ...state,
    selectedPayslip: action.payload
  }),
  [types.updateTicketStart]: (state) => ({
    ...state,
    updateInProgress: true,
    updateCompleted: false
  }),
  [types.updateTicketSuccess]: (state) => ({
    ...state,
    updateInProgress: false,
    updateCompleted: true
  }),

  [types.toggleRowSelection]: (state, action) => {
    const expandedRows =
      !action.payload ||
      typeof action.payload.id === 'undefined' ||
      state.list.expandedRows.includes(action.payload.id)
        ? []
        : [action.payload.id]

    return {
      ...state,
      updateInProgress: false,
      updateCompleted: false,
      list: {
        ...state.list,
        expandedRows
      }
    }
  },
  [types.fetchRowData]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsFetching: [...state.list.rowsFetching, action.payload],
      rowsFetchingErrors: state.list.rowsFetchingErrors.filter(
        (status) => status.id !== action.payload.id
      )
    }
  }),
  [types.fetchRowDataSuccess]: (state, action) => {
    const updatedData = compose(
      reduceWithIndex(
        (acc, ticket, index) => ({
          ...acc,
          [index]: set(
            lensPath(['ticket', 'ticketEntity']),
            updatedTicketWithData({
              carData: action.payload.carData,
              documentData: action.payload.documentData
            })(ticket),
            ticket
          )
        }),
        {}
      ),
      values
    )(state.list.data)
    const rowFetchLens = lensPath(['list', 'rowsFetching'])

    return compose(
      set(dataLens, updatedData),
      set(
        rowFetchLens,
        state.list.rowsFetching.filter(
          (status) => status.id !== action.payload.id
        )
      )
    )(state)
  },
  [types.fetchRowDataError]: (state, action) => ({
    ...state,
    list: {
      ...state.list,
      rowsFetching: state.list.rowsFetching.filter(
        (status) => status.id !== action.payload.id
      ),
      rowsFetchingErrors: [...state.list.rowsFetchingErrors, action.payload]
    }
  }),
  [authTypes.logout]: (state) => ({
    ...state,
    list: {
      ...state.list,
      data: [],
      carPaymentReceiptsSelection: {},
      expandedRows: [],
      rowsFetching: [],
      rowsFetchingErrors: []
    }
  }),
  [types.persistMetaInfoChange]: (state, action) => {
    const updatedData = compose(
      reduceWithIndex(
        (acc, ticket, index) => ({
          ...acc,
          [index]: set(
            lensProp('ticketEntity'),
            updateDocumentWithMetaData(action.payload)(ticket),
            ticket
          )
        }),
        {}
      ),
      values
    )(state.list.data)

    return set(dataLens, updatedData, state)
  }
}

export const reducers = {
  ...reducerFns,
  ...tableDecorators.withDataLoading(types.dataLoadingTypes, 'list'),
  ...tableDecorators.withSingleSort(types.sortTypes, 'list'),
  ...tableDecorators.withColumnVisibility(types.columnTypes, 'list'),
  ...tableDecorators.withFilters(types, 'list'),
  ...formDecorators.generateReducer(types.formTypes, {
    getInitialState,
    schema: payslipSchema
  })
}

export default reducers
