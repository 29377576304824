import styled from 'styled-components'
import {File} from '@fcg/image-viewer'
import {
  fileAlternativeRenderer,
  getFileWrapper,
  getAlternativeRenderer
} from '../common'
import {ThumbnailList} from './ThumbnailList'

const DocumentSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const GalleryImageContainer = styled.div`
  display: flex;
  margin: 10px 20px 0 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-height: 180px;
  overflow: scroll;
`
const SelectedImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
`

export const DocumentSelector = ({
  images,
  selected,
  selectedImage,
  openGallery,
  selectImage,
  showMetaInfo,
  metaInfoInputs
}) => {
  if (images.length === 1) {
    const wrapper = getFileWrapper(images[0], showMetaInfo)
    const alternativeRenderer = fileAlternativeRenderer[images[0].fileType]

    return (
      <File
        {...images[0]}
        alternativeRenderer={alternativeRenderer}
        wrapper={wrapper}
        onClick={openGallery}
        metaInfoInputs={metaInfoInputs}
      />
    )
  }

  const selectedImageWrapper = getFileWrapper(selectedImage, showMetaInfo)
  const selectedImageAlternativeRenderer = getAlternativeRenderer(selectedImage)

  return (
    <DocumentSelectorContainer>
      <SelectedImageContainer>
        <File
          {...selectedImage}
          wrapper={selectedImageWrapper}
          alternativeRenderer={selectedImageAlternativeRenderer}
          onClick={openGallery}
          metaInfoInputs={metaInfoInputs}
        />
      </SelectedImageContainer>
      <GalleryImageContainer>
        <ThumbnailList
          images={images}
          selected={selected}
          selectImage={selectImage}
        />
      </GalleryImageContainer>
    </DocumentSelectorContainer>
  )
}
