import {FormBuilder} from '@fcg/form-builder'
import {ConfigContext} from '../../components/providers/withConfig'

const CBE_REPORT_POSITION = [
  {value: true, label: 'documentConfigFormConfig.page.displayCBEAtStart'},
  {value: false, label: 'documentConfigFormConfig.page.displayCBEAtEnd'}
]

const Edit = ({
  data,
  t,
  handleToggleChange,
  handleSelectFieldChange,
  updateField,
  updateMetaField,
  ...props
}) => (
  <ConfigContext.Consumer>
    {(config) => (
      <FormBuilder
        data={data}
        form={props.pageConfig.form}
        onChangeHandlers={{
          updateField: updateField,
          updateMetaField: updateMetaField,
          handleToggleChange: handleToggleChange,
          handleSelectFieldChange: handleSelectFieldChange
        }}
        options={{displayCBEAtStart: CBE_REPORT_POSITION}}
        config={config}
        t={t}
      />
    )}
  </ConfigContext.Consumer>
)

export default Edit
