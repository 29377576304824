import merge from 'lodash/merge'
import omit from 'lodash/omit'

export const defaultLimit = 25

export const payslipSchema = {
  id: '',
  title: '',
  description: '',
  assigneeId: '',
  assigneeName: '',
  internalId: '',
  country: '',
  status: '',
  type: '',
  metaInfo: '',
  createdAt: '',
  updatedAt: '',
  createdById: '',
  createdByName: '',
  updatedById: '',
  updatedByName: '',
  ticketEntity: '',
  ticketComment: ''
}

export const state = {
  options: {},
  optionsLoading: false,
  selectedPayslip: null,
  updateInProgress: false,
  updateCompleted: false,
  form: {...payslipSchema},
  list: {
    page: 1,
    limit: defaultLimit,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    subfields: [],
    filters: [],
    carPaymentReceiptsSelection: {},
    expandedRows: [],
    rowsFetching: [],
    rowsFetchingErrors: []
  }
}

export const getInitialState = (preferences = {}) => {
  const listPreferences = omit(preferences, [
    'newPayslip',
    'newPayslipSummaryFields'
  ])
  const setup = merge(state, listPreferences)

  if (!setup.pages || !setup.pages.dealers) {
    return {
      ...setup,
      list: {
        ...setup.list
      }
    }
  }

  return {
    ...setup,
    limit: defaultLimit,
    list: {
      ...setup.list,
      fields: Object.values(setup.fields),
      limit: defaultLimit
    }
  }
}
