import {connect} from 'react-redux'
import {changeLanguage} from '../../store/config/actions'
import {Dropdown} from 'motofin-ui'
import './DropDown.styl'
const LanguagePicker = (props) => {
  const list = props.options.map((val, idx) => ({
    key: idx,
    title: val.label,
    value: val.value
  }))
  return props.options.length > 1 ? (
    <Dropdown
      onChange={props.changeLanguage}
      dropdownClass='Dropdown'
      dropdownContentClass='DropdownClass'
      options={list}
      defaultValue={list[0].value}
    />
  ) : null
}

const mapStateToProps = ({config}) => {
  const {language} = config
  const selected =
    typeof language.selected !== 'undefined'
      ? language.selected
      : language.options[0].value

  return {
    options: language.options,
    selected
  }
}

const styles = {
  dropdown: {
    backgroundColor: 'red'
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (code) => dispatch(changeLanguage(code.value))
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)
