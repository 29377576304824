import {createSelector} from 'reselect'

const selectorPath = (state) => state.cardata

export const getCount = (state) => selectorPath(state).count
export const getError = (state) => selectorPath(state).error
export const isFetchingList = (state) => selectorPath(state).isFetchingList
export const isLoading = (state) => selectorPath(state).isLoading
export const getFilters = (state) => selectorPath(state).list.filters
export const getFields = (state) => selectorPath(state).list.fields
export const getLimit = (state) => {
  const {limit} = selectorPath(state).list

  return limit ? limit : 25
}
export const getSort = (state) => selectorPath(state).list.sort
export const getPage = (state) => {
  const {page} = selectorPath(state).list

  return page ? page : 1
}
export const getCarDataList = (state) => selectorPath(state).carDataList

export const getSelectedCarData = (state) => selectorPath(state).data

export const getSelectedCarId = (state) => selectorPath(state).selectedCarDataId

export const getParsedFilterForQuery = createSelector(getFilters, (filters) => {
  if (filters && Array.isArray(filters)) {
    return filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.key]: filter.value
      }),
      {}
    )
  }

  return {}
})

export const isUploadingCarData = (state) =>
  selectorPath(state).isUploadingCarData

export const hasUploadError = (state) =>
  Boolean(selectorPath(state).uploadError)

export const getFormData = (state) => selectorPath(state).form

export const getCreateDialogState = (state) =>
  selectorPath(state).showCreateEditDialog

export const getEditState = (state) => selectorPath(state).edit
