import {carDataFilesFields} from './carDataFilesFields'
import CarDataFilesList from '../../../../pages/CarData/CarDataFiles/List'

export const carDataFilesConfig = {
  path: 'files',
  label: 'menu.carData.file',
  key: 'carDataFiles',
  sort: [{field: 'createdAt', order: 'DESC'}],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...carDataFilesFields},
  hiddenFields: [],
  component: CarDataFilesList
}
