import DayList from './DayList'
import IconButton from '../../Buttons/IconButton'
import {translate} from 'react-i18next'

// Todo: define DoW in the configs.
const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const getDifference = (direction) => (direction === 'forward' ? 1 : -1)

export const Month = (props) => {
  const {t, baseRange, isRange, totalRange, activeRange, selected} = props
  const baseClass = 'calendar__month'
  const rangeClass =
    isRange === true ? `${baseClass}--range` : `${baseClass}--simple`
  const className = [baseClass, rangeClass].join(' ')
  const days = Array.from(totalRange.by('days'))

  return (
    <div className={className}>
      <IconButton
        direction={props.direction}
        label={props.label}
        onClick={(direction) =>
          props.changeVisibleMonths(getDifference(direction))
        }
      />
      <div className='calendar__week'>
        {daysOfWeek.map((day) => (
          <span className='calendar__day calendar__day--heading' key={day}>
            {t(day)}
          </span>
        ))}
      </div>
      <DayList
        days={days}
        activeRange={activeRange}
        selected={selected}
        isRange={isRange}
        handleDayClick={props.handleDayClick}
        baseRange={baseRange}
      />
    </div>
  )
}

export default translate()(Month)
