import React from 'react'
import cx from 'classnames'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

interface TFProps {
  error?: string
  label: string
  value?: string
  type?: string
  required?: boolean
  disabled?: boolean
  fullWidth?: boolean
  multiline?: boolean
  inputProps?: any
  dataTest?: string
  className?: string
  placeholder?: string
  helperText?: string
  onChange: (value: string) => void
}

const TextFieldWithErrorMessage = ({
  error = '',
  label,
  value,
  type,
  required,
  onChange,
  fullWidth = true,
  inputProps = {},
  dataTest,
  className,
  disabled = false,
  placeholder,
  helperText,
  multiline = false
}: TFProps) => {
  const classes = useCommonStyles()

  return (
    <>
      {helperText && (
        <Typography
          variant='caption'
          display='block'
          className={classes.colorInactive}
          style={{marginTop: '-8px', letterSpacing: '.01rem'}}
        >
          {helperText}
        </Typography>
      )}
      <div
        className={className}
        style={!error || error === '' ? {marginBottom: '18px'} : {}} // to align different fields height on Location Configuration
      >
        <TextField
          required={required}
          error={!!error}
          type={type}
          fullWidth={fullWidth}
          label={label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
          }}
          value={value}
          data-test={dataTest}
          inputProps={inputProps}
          disabled={disabled}
          placeholder={placeholder}
          multiline={multiline}
          rowsMax={4}
        />
        <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
        >
          {error}
        </Typography>
      </div>
    </>
  )
}

export default TextFieldWithErrorMessage
