import {Component, Fragment} from 'react'
import {connect} from 'react-redux'

import {
  fetchCategoryList,
  downloadCategoryListItem,
  downloadCategoryData
} from '../../store/category/actions'
import styled from 'styled-components'
import * as selectors from '../../store/category/selectors'
import {Button} from '@material-ui/core'
import {GetApp, AttachFile} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import CategoryUploadDialog from './CategoryUploadDialog'
import CustomSnackbar from '../../components/Snackbar'
import {LoaderOverlay} from '../../../src/pages/Calendar/components/Table/LoaderOverlay'

const CategoryDataFilesWrapper = styled.div`
  tr {
    td:first-child,
    th:first-child {
      padding-left: 24px;
    }
  }
`

class Category extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUploadDialogVisible: false
    }
  }

  openUploadDialog = () => {
    this.setState({isUploadDialogVisible: true})
  }

  closeUploadDialog = () => {
    this.setState({isUploadDialogVisible: false})
  }

  render() {
    return (
      <Fragment>
        <CategoryDataFilesWrapper className='page'>
          <PageTableBase
            {...this.props}
            multisort={false}
            title='Category'
            onRowClick={this.props.downloadDocumentItem}
            rowsPerPageOptions={[25, 50, 100]}
            selectable={false}
            filterable={false}
            withVisibility={false}
          />
          <LoaderOverlay isLoading={this.props.isDownloading} />
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              onClick={this.openUploadDialog}
            >
              <AttachFile />
            </Button>
            <Button
              variant='fab'
              color='primary'
              onClick={this.props.downloadCategoryDocument}
            >
              <GetApp />
            </Button>
          </ActionButton>
          <CategoryUploadDialog
            open={this.state.isUploadDialogVisible}
            handleClose={this.closeUploadDialog}
            isCategory={true}
          />
          <CustomSnackbar />
        </CategoryDataFilesWrapper>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getCategoryList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  count: selectors.getCount(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  isDownloading:
    selectors.isDownloadingCategoryItem(state) ||
    selectors.isDownloadingCategory(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (options) => dispatch(fetchCategoryList(options)),
  downloadDocumentItem: (e, document = {}) => {
    dispatch(downloadCategoryListItem(document.fileName))
  },
  downloadCategoryDocument: () => {
    dispatch(downloadCategoryData())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Category)
