import React from 'react';
import { No_Available_Notifications_Image } from './NotificationsIconSvg';
import '../../Notifications.scss';

const NoAvailableNotificationsSection = (props: { text: string, svgClass?: string }) => {
  const { text, svgClass } = props;
  return (
    <div className={`noAvailableNotifications flexCenter`} data-testid="NoNotificationsInLast30Days">
      <No_Available_Notifications_Image svgClass={svgClass} />
      <div className='noAvailableNotificationsText'>{text}</div>
    </div>
  )
}

export default NoAvailableNotificationsSection;