import styled from 'styled-components'
import PropTypes from 'prop-types'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import {withStyles} from '@material-ui/core'

const CardTile = styled.span`
  width: 12px;
  height: 18px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #fff;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #c2c2c2;
`
const iconStyles = {
  root: {
    width: '10px',
    height: '17px'
  }
}
const IconDown = withStyles(iconStyles)(ArrowDropDown)
const IconUp = withStyles(iconStyles)(ArrowDropUp)

const CardExtend = (props) => (
  <CardTile data-test-id={props.extendKey} onClick={props.onClick}>
    {props.direction === 'up' ? <IconUp /> : <IconDown />}
  </CardTile>
)

CardExtend.propTypes = {
  placeId: PropTypes.string,
  onClick: PropTypes.func
}

export default CardExtend
