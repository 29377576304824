import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'

const client = new ApiClient('webhooks')

export function getWebhooks(params = {}) {
  return client.query('webhooks', params, fields.webhooksList)
}

export function getEvents(params = {}) {
  return client.query('events', params, fields.eventsList)
}

export function createWebhook(params = {}) {
  if (params.header) {
    params.header = JSON.parse(
      params.header.replace(/([a-zA-Z0-9-]+):/g, '"$1":')
    )
  }
  return client.mutation('createWebhook', params, fields.webhooksCreateFields)
}

export function deleteWebhook(params = {}) {
  return client.mutation('deleteWebhook', params)
}
