import {ValidatedTextField} from '../../../../components/FormValidation'

export default {
  stepNum: 3,
  stepTitle: 'driver.account',
  description: 'driver.panel.description.account',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'email',
            label: 'driver.page.field.username',
            margin: 'normal',
            type: 'text',
            required: true,
            onChangeHandler: 'updateField'
          }
        ]
      },
      (props) => ({
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'password',
            autoComplete: 'new-password',
            label: 'userProfile.field.password',
            margin: 'normal',
            onChangeHandler: 'updateField',
            type: 'password',
            required: props.data.id ? false : true
          }
        ]
      }),
      (props) => ({
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'confirmPassword',
            autoComplete: 'new-password',
            label: 'userProfile.field.confirmPassword',
            margin: 'normal',
            onChangeHandler: 'updateField',
            confirmPasswordRef: 'password',
            type: 'password',
            required: props.data.id ? false : true
          }
        ]
      })
    ]
  }
}
