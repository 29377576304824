const copyToClipboard = (text, container) => {
  const id = 'mycustom-clipboard-textarea-hidden-id'
  let existsTextarea = document.getElementById(id)
  const wrapper = container ? `#${container}` : 'body'

  if (!existsTextarea) {
    var textarea = document.createElement('textarea')
    textarea.id = id
    textarea.style.position = 'fixed'
    textarea.style.top = 0
    textarea.style.left = 0
    textarea.style.width = '1px'
    textarea.style.height = '1px'
    textarea.style.border = 'none'
    textarea.style.outline = 'none'
    textarea.style.boxShadow = 'none'
    document.querySelector(wrapper).appendChild(textarea)
    existsTextarea = document.getElementById(id)
  }

  existsTextarea.value = text
  existsTextarea.select()
  document.execCommand('copy')
}

export default copyToClipboard
