import omit from 'lodash/omit'
import {state as appointment} from '../appointment/initialState'
import {state as lead} from '../lead/initialState'
import {state as contact} from '../contact/initialState'

export const state = {
  contact: omit(contact.form, ['simplyBookCustomerId']),
  lead: lead.form,
  appointment: omit(appointment.form, [
    'leadId',
    'bookingId',
    'inspectionPointName',
    'inspectionPointCity'
  ]),
  options: {...appointment.options},
  editState: {
    pending: false,
    error: false,
    success: false
  }
}

export const getInitialState = () => state
