import {createSelector} from 'reselect'
import {getListSelectors} from '../common/listSelectors'

const selectorPath = (state) => state.businessPartner
const selectors = getListSelectors(selectorPath)

export default {
  ...selectors,
  getBusinessPartnerList: (state) =>
    selectorPath(state).businessPartnerList || [],
  getBusinessPartnerNamesList: createSelector(
    ({businessPartner}) =>
      businessPartner && businessPartner.businessPartnerList,
    (bpList) => {
      return bpList.map((bp) => bp.legalName)
    }
  ),
  getSelectedBusinessPartner: (state) => selectorPath(state).data,
  getSelectedBusinessPartnerId: (state) =>
    selectorPath(state).selectedBusinessPartnerId,
  getParsedFilterForQuery: createSelector(selectors.getFilters, (filters) => {
    if (filters && Array.isArray(filters)) {
      return filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]: filter.value
        }),
        {}
      )
    }

    return {}
  }),
  isCountryOptionsFetching: ({crm}) => crm?.optionsLoading,
  getCount: (state) => selectorPath(state).totalItems,
  getTotalPages: (state) => selectorPath(state).totalPages,
  getCurrentPage: (state) => selectorPath(state).currentPage + 1,
  getPage: (state) => selectorPath(state).currentPage + 1
}
