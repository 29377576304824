export const mergeResources = 'RESOURCES/MERGE_RESOURCES'
export const removeResources = 'RESOURCES/REMOVE_RESOURCE'

export const removeAllDataFromResource =
  'RESOURCES/REMOVE_ALL_DATA_FROM_RESOURCE'

export const serverError = 'SERVER_ERROR'
export const unknownError = 'UNKNOWN_ERROR'
export const networkError = 'NETWORK_ERROR'
export const dataError = 'DATA_ERROR'
export const clientError = 'CLIENT_ERROR'
export const deleteDataFromCollection = 'DELETE_FROM_COLLECTION'
