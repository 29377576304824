import {connect} from 'react-redux'
import {Button, withStyles} from '@material-ui/core'
import {translate} from 'react-i18next'
import {showErrorDialog} from '../../../store/global/actions'

const styles = (theme) => ({
  button: {
    color: theme.palette.primary.contrastText
  }
})

export const ExpandErrorAction = ({t, classes, ...props}) => {
  return (
    <Button className={classes.button} onClick={() => props.open()}>
      {t('global.button.seeMore')}
    </Button>
  )
}

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(showErrorDialog())
})

const styled = withStyles(styles, {name: 'ExpandError'})(ExpandErrorAction)
export default translate()(connect(null, mapDispatchToProps)(styled))
