import i18n from '../../i18n-client'
import gql from '@fcg/lib-gql/gql'
import omit from 'lodash/omit'
import * as types from './types'
import * as global from '../global/actions'
import * as CarApi from '../../api/car/requests'
import {getUserConfig} from '../../config/roles'
import {
  getPlacesList,
  getCountryOptionsWithBasicProps
} from '../../api/car/requests'
import {getLeadOptionList} from '../../api/contact/requests'
import {getUserList} from '../../api/auth/requests'
import * as CarDataApi from '../../api/cardata/requests'
import * as BookingApi from '../../api/booking/requests'
import moment from 'moment'
import {updateWalkinForm} from '../crm/walkin/actions'
import {getCountryCode} from './selectors'
import {getCountryConfig} from '../../../config/country'
import {getLeadPrice} from '../../api/cardata/car-pricing-ds-wrapper'
import {getAipPricingQuote} from '../../api/cardata/aip-pricing-quote-wrapper'

const STATE_TYPE = 'dealerState'
const CITY_TYPE = 'dealerCity'
const COMPANY_TYPE = 'companyType'

export const getLocationOptions = (type) => (dispatch, getState) => {
  const locationType = type || 'INSPECTIONCRM'
  const {locationOptions} = getState().config.options

  dispatch({
    type: `appointment/LOCATION_CHANGE`,
    payload: []
  })

  if (locationOptions && locationOptions[type]) {
    return null
  }

  const params = {
    country: new gql.EnumType(getCountryCode(getState())),
    active: new gql.EnumType(true)
  }

  const sort = [{field: 'location'}]

  return getPlacesList({type: locationType, ...params, sort})
    .then((response) => {
      dispatch({
        type: types.locationOptions,
        payload: {
          ...response,
          type
        }
      })
    })
    .catch((e) => dispatch(global.apiError(e)))
}

export const getLeadSourceOptions = () => (dispatch, getState) => {
  if (typeof getState().config.options.leadSourceOptions !== 'undefined') {
    return null
  }

  const country = getCountryCode(getState())

  return getLeadOptionList({country})
    .then((response) => {
      dispatch({
        type: types.leadSourceOptions,
        payload: response
      })
    })
    .catch((e) => dispatch(global.apiError(e)))
}

export const getCarDataOptions = ({key, selection}) => async (
  dispatch,
  getState
) => {
  const country = getCountryCode(getState())
  return {
    make: async () => {
      const makes = await CarDataApi.getCarDataMake({country})
      dispatch({type: types.carDataOptions, payload: {makes}})
    },
    locations: async () => {
      const locations = await CarDataApi.getCarDataLocation({country})

      dispatch({type: types.carDataOptions, payload: {locations}})
    },
    model: async (selection) => {
      const params = omit(selection, 'mileage')
      const models = await CarDataApi.getCarDataModel({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {models}})
    },
    trim: async (selection) => {
      const params = omit(selection, 'mileage')

      const trims = await CarDataApi.getCarDataTrim({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {trims}})
    },

    bodyType: async (selection) => {
      const params = omit(selection, 'mileage')

      const bodyTypes = await CarDataApi.getCarDataBodyType({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {bodyTypes}})
    },
    // spliting trim and creating 4 new fields - faceLift, seat, engine, wheelDrive
    faceLift: async (selection) => {
      const params = omit(selection, 'mileage')

      const faceLifts = await CarDataApi.getCarDataFaceLift({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {faceLifts}})
    },
    seat: async (selection) => {
      const params = omit(selection, 'mileage')

      const seats = await CarDataApi.getCarDataSeat({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {seats}})
    },
    engine: async (selection) => {
      const params = omit(selection, 'mileage')

      const engines = await CarDataApi.getCarDataEngine({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {engines}})
    },
    wheelDrive: async (selection) => {
      const params = omit(selection, 'mileage')

      const wheelDrives = await CarDataApi.getCarDataWheelDrive({
        ...params,
        country
      })

      dispatch({type: types.carDataOptions, payload: {wheelDrives}})
    },

    transmission: async (selection) => {
      const params =
        country === 'TR'
          ? omit(selection, 'mileage', 'bodyType')
          : omit(selection, 'mileage')
      let res = []
      if (country !== 'TR') {
        const resp = await CarApi.getCountryOptions('leadTransmissionType', {
          country
        })
        res = resp.leadTransmissionType.list.map((item) => item.name) || []
      } else {
        const resp = await CarDataApi.getCarDataTransmission({
          ...params,
          country
        })
        res = resp
      }
      dispatch({type: types.carDataOptions, payload: {transmissions: res}})
    },

    year: async (selection) => {
      const params = omit(selection, 'mileage')

      try {
        const years = await CarDataApi.getCarDataYear({...params, country})

        dispatch({type: types.carDataOptions, payload: {years}})
      } catch (error) {
        dispatch(global.apiError(error))
        dispatch(
          global.error({
            message: 'global.error.fetch',
            entity: 'carData'
          })
        )
      }
    },
    quote: async (selection) => {
      if (getCountryConfig().fetchPriceQuoteFromAIP) {
        if (
          selection.make_code &&
          selection.model_code &&
          selection.trim_code
        ) {
          const {quote} = await getAipPricingQuote(selection)
          dispatch({type: types.quote, payload: quote})
        } else {
          dispatch({type: types.quote, payload: {quote: []}})
        }
      } else {
        if (selection.make) {
          const valuation =
            country === 'TR'
              ? await getLeadPrice(selection)
              : await CarDataApi.getCarDataQuote({
                  ...selection,
                  country
                })

          dispatch({
            type: types.quote,
            payload:
              country === 'TR'
                ? {quote: [valuation[0].predicted_price]}
                : valuation.quote
          })
        } else {
          dispatch({type: types.quote, payload: {quote: []}})
        }
      }
    },
    location: async (selection) => {
      const params = omit(selection, 'transmission')

      const location = await CarDataApi.getCarDataLocation({
        ...params,
        country
      })

      dispatch({
        type: types.carDataOptions,
        payload: {
          location: location
        }
      })
    }
  }[key](selection)
}

export const getCarLocations = () => (dispatch, getState) =>
  getCarDataOptions({key: 'locations', selector: {}})(dispatch, getState)

const clearSlots = () => (dispatch) => {
  dispatch(updateWalkinForm('appointment', {name: 'date', value: ''}))
  dispatch(updateWalkinForm('appointment', {name: 'time', value: ''}))
  dispatch(updateWalkinForm('appointment', {name: 'slot', value: ''}))
}

const handleSlotSelection = (availableSlots, slot) => (dispatch) => {
  if (slot) {
    const startDate = moment(slot)
    const endDate = moment(startDate).add(1, 'hours')

    const slotIndex = availableSlots.findIndex((slot) => {
      const slotDate = moment(`${slot.value.date} ${slot.value.time}`)

      return slotDate < endDate && slotDate >= startDate
    })

    if (slotIndex > -1) {
      const {date, time} = availableSlots[slotIndex].value

      dispatch(updateWalkinForm('appointment', {name: 'date', value: date}))
      dispatch(updateWalkinForm('appointment', {name: 'time', value: time}))
      dispatch(
        updateWalkinForm('appointment', {
          name: 'slot',
          value: `${date} ${time}`
        })
      )
    } else {
      dispatch(clearSlots())
    }
  } else {
    dispatch(clearSlots())
  }
}

export const reactToLocationChange = (
  location = {},
  stateChunk,
  slot,
  type = 'INSPECTIONCRM'
) => async (dispatch) => {
  if (
    typeof location.inspectionPoint === 'undefined' &&
    typeof location.placeId === 'undefined'
  ) {
    // eslint-disable-next-line no-console
    console.warn(`reactToLocationChange:
      you must provide a location parameter (placeId or inspectionPoint
    `)

    return
  }
  dispatch({
    type: `${stateChunk}/LOADING_SLOTS`,
    payload: true
  })
  try {
    const availableSlots = await BookingApi.getAvailableSlots({
      ...location,
      type
    })

    dispatch({
      type: `${stateChunk}/LOCATION_CHANGE`,
      payload: availableSlots
    })

    dispatch(handleSlotSelection(availableSlots, slot))
  } catch (error) {
    dispatch(global.apiError(error))
    dispatch(
      global.error({
        message: 'global.error.fetch',
        entity: 'appointment'
      })
    )
    dispatch({type: `${stateChunk}/LOADING_SLOTS`, payload: false})
  }
}

export const changeLanguage = (code) => (dispatch) => {
  dispatch({
    type: types.languageChange,
    payload: code
  })
  i18n.changeLanguage(code)
}

export const hideField = (field, page) => (dispatch) => {
  dispatch({
    type: `${page}/HIDE_FIELD`,
    payload: field
  })
}
export const resetPageFields = (field, page) => (dispatch) => {
  dispatch({
    type: `${page}/RESET_FIELDS`,
    payload: field
  })
}

export const setUserConfig = () => {
  const userConfig = getUserConfig()

  return {
    type: types.userConfig,
    payload: userConfig
  }
}

const setCountryOptions = (data, optionsKey) => ({
  type: types.setCountryOptions,
  payload: {
    data,
    optionsKey
  }
})

export const fetchCountryStates = () => async (dispatch, getState) => {
  try {
    const country = getCountryCode(getState())

    const resp = await getCountryOptionsWithBasicProps(
      {countryStates: {type: STATE_TYPE}},
      {country}
    )

    if (resp && resp.countryStates && resp.countryStates.list) {
      dispatch(setCountryOptions(resp.countryStates.list, 'countryStates'))
    }
  } catch (error) {
    dispatch(global.apiError(error))
  }
}

const getStateCitiesQuery = (state) => {
  const query = {type: CITY_TYPE, biMapping: undefined}

  if (state) {
    query.biMapping = state
  }

  return query
}

export const fetchStateCities = (state) => async (dispatch, getState) => {
  try {
    const country = getCountryCode(getState())

    const resp = await getCountryOptionsWithBasicProps(
      {stateCities: getStateCitiesQuery(state)},
      {country}
    )

    if (resp && resp.stateCities && resp.stateCities.list) {
      dispatch(setCountryOptions(resp.stateCities.list, 'stateCities'))
    }
  } catch (error) {
    dispatch(global.apiError(error))
  }
}

const getTypeOfCompanyQuery = (state) => {
  const query = {type: COMPANY_TYPE, biMapping: undefined}

  if (state) {
    query.biMapping = state
  }

  return query
}

export const fetchCompanyTypes = (state) => async (dispatch, getState) => {
  try {
    const country = getCountryCode(getState())

    const resp = await getCountryOptionsWithBasicProps(
      {companyType: getTypeOfCompanyQuery(state)},
      {country}
    )

    if (resp && resp.companyType && resp.companyType.list) {
      dispatch(setCountryOptions(resp.companyType.list, 'companyType'))
    }
  } catch (error) {
    dispatch(global.apiError(error))
  }
}

export const getPurchaseCoordinatorOptions = () => async (
  dispatch,
  getState
) => {
  try {
    const {
      crmDetails: {
        purchaseCoordinatorInput: {key, queryValue}
      }
    } = getCountryConfig()

    const resp = await getUserList({
      country: new gql.EnumType(getCountryCode(getState())),
      [key]: queryValue,
      limit: 1000
    })

    if (resp && resp.user && resp.user.list) {
      dispatch({
        type: types.setPurchaseCoordinatorOptions,
        payload: resp.user.list
      })
    }
  } catch (error) {
    dispatch(global.apiError(error))
  }
}
