const resourceName = 'document'

export const fetchPreferenceList = `${resourceName}/FETCH_PREFERENCE_LIST`
export const fetchPreferenceListSuccess = `${resourceName}/FETCH_PREFERENCE_LIST_SUCCESS`
export const updatePdfPreference = `${resourceName}/UPDATE_PDF_PREFERENCE`
export const updatePdfPreferenceField = `${resourceName}/UPDATE_PDF_PREFERENCE_FIELD`
export const savePdfPreference = `${resourceName}/SAVE_PDF_PREFERENCE`
export const loadPdfPreference = `${resourceName}/LOAD_PDF_PREFERENCE`
export const deletePdfPreference = `${resourceName}/DELETE_PDF_PREFERENCE`
export const updatePdfPreferenceMetaField = `${resourceName}/UPDATE_PDF_PREFERENCE_META_FIELD`
export const setSelectedPreferenceData = `${resourceName}/SET_SELECTED_PREFERENCE_DATA`
export const resetPreferenceData = `${resourceName}/RESET_PREFERENCE_DATA`

export const setFooterLogo = `${resourceName}/SET_FOOTER_LOGO`
export const setHeaderLogo = `${resourceName}/SET_HEADER_LOGO`
export const setFirstPageLogo = `${resourceName}/SET_FIRST_PAGE_LOGO`
export const setLastPageLogo = `${resourceName}/SET_LAST_PAGE_LOGO`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const toggleField = `${resourceName}/TOGGLE_FIELD`
export const setSort = `${resourceName}/SET_SORT`
export const fetching = `${resourceName}/SET_FETCHING`

// Upload document
export const setUploading = `${resourceName}/SET_UPLOADING`
export const uploadDocument = `${resourceName}/UPLOAD_DOCUMENT`
export const uploadAsset = `${resourceName}/UPLOAD_ASSET`
export const downloadDocument = `${resourceName}/DOWNLOAD_DOCUMENT`
export const fetchDocumentList = `${resourceName}/FETCH_DOCUMENT_LIST`
export const fetchDocumentListSuccess = `${resourceName}/FETCH_DOCUMENT_LIST_SUCCESS`
export const setDocumentFetching = `${resourceName}/SET_DOCUMENT_FETCHING`

export const deleteDocument = `${resourceName}/DELETE_DOCUMENT`
export const deleteDocumentSuccess = `${resourceName}/DELETE_DOCUMENT_SUCCESS`

export const previewDocument = `${resourceName}/PREVIEW_DOCUMENT`
export const setPreviewDocumentUrl = `${resourceName}/SET_PREVIEW_DOCUMENT_URL`

export const updateDocument = `${resourceName}/UPDATE_DOCUMENT`
export const setReferenceId = `${resourceName}/SET_REFERENCE_ID`
// Dealer file type options
export const fetchDealerFileType = `${resourceName}/FETCH_DEALER_FILE_TYPE_OPTIONS`
export const fetchDealerFileTypeSuccess = `${resourceName}/FETCH_DEALER_FILE_TYPE_OPTIONS_SUCCESS`
export const setDealerFileTypeOptions = `${resourceName}/SET_DEALER_FILE_TYPE_OPTIONS`
// File upload
export const setReference = `${resourceName}/SET_REFERENCE`
export const cleanUp = `${resourceName}/CLEAN_UP`
