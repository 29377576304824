const dealerDetails = require('./dealerManagement/mx')
const crmDetails = require('./CRM/mx')
const bulkuploadDetails = require('./bulkUpload/mx')
const carDataDetails = require('./carData/mx')
const locationDetails = require('./locationManagement/mx')
const payslipDetails = require('./payslips/mx')
const carOwnership = require('./carOwnership/mx')
const businessPartnersDetails = require('./businessPartnersDetails/mx')

module.exports = {
  url: 'vendetuauto.com',
  siteCode: 'olxmx',
  apiDomain: 'api.olxautos.com.mx',
  name: 'Mexico',
  countryCode: 'MX',
  brand: 'olx-autos',
  theme: 'mexico',
  language: {
    selected: 'es-mx',
    options: [
      {value: 'es-mx', label: 'ES'},
      {value: 'en-mx', label: 'EN'}
    ]
  },
  currency: {code: 'MXN', symbol: '$'},
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  locales: ['es-mx', 'en-mx'],
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: [10], code: 52},
  useExactPhoneNumberSearch: true,
  referralCodeType: 'input',
  createCarViaAppointment: true,
  googleKey:
    '940673976550-naj8k2mhp404ljudl23fsgmug4vr9bhf.apps.googleusercontent.com',
  okta: {
    domain: 'olxgroup.okta-emea.com',
    clientId: '0oa83jhi2aYZsoOOk0i7'
  },
  disabledBookingSources: ['Web Lead'],
  auctionPreviewOffset: 3 * 60,
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'vin',
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'resourceManager',
    'bulkUploadV2',
    'wms',
    'attendanceManager',
    'tms',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: '',
    disabled: true
  },
  hideFromMenu: {
    category: false
  },
  callingCode: '+52',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  categoryId: 84,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: false,
  fetchLocationFromCountryOptions: false,
  fetchPriceQuoteFromAIP: true,
  disableOtherFreeTextInDropdown: true,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  isNotificationsEnabled: false
}
