import {Fragment} from 'react'

import {wrapInParentheses} from '../../utils/stringFormatting'
import {translate} from 'react-i18next'

export const StringFormat = ({data, parentheses, t, prefix, isHeader}) => {
  if (prefix && data && !isHeader) {
    data = `${prefix}.${data}`
  }

  const output = parentheses === true ? wrapInParentheses(t(data)) : t(data)

  return <Fragment>{output}</Fragment>
}

export default translate()(StringFormat)
