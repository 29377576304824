module.exports = {
  required: [
    'firstname',
    'email',
    'metaInfoEmail',
    'lastname',
    'status',
    'phone',
    'taxId',
    'isNewsLetterAccepted',
    'companyTypeId',
    'regon',
    'postCode',
    'street'
  ],
  hidden: [
    'taxOffice',
    'dealerTaxOfficeCommune',
    'dealerTaxOfficeCity',
    'legalRepresentativeName',
    'legalRepresentativeNationality',
    'legalRepresentativeProfesion',
    'legalRepresentativeId',
    'dealersPublicDeedIncorporationDate',
    'dealersPublicDeedNotary',
    'dealersPublicDeedNotaryCity',
    'personType',
    'legalRepresentativeLastName',
    'district',
    'phone2',
    'nationality',
    'taxIdExpiryDate',
    'companyTradeLicense',
    'companyTaxId',
    'companyTransportAuthorityNo',
    'systemSetCreditLimit',
    'systemSetDepositAmount',
    'deposit',
    'registrationType',
    'invoicingType',
    'storageTime',
    'expectedDelivery',
    'expectedCarCare',
    'expectedFinancing',
    'legalRepresentativePhone',
    'legalRepresentativeEmail',
    'legalAdress',
    'leadSource',
    'employeeCount',
    'carPreferencesPrice',
    'monthlyTurnover',
    'estimatedMargin',
    'dealerAppsChannels',
    'bankAccountNumber',
    'bankAccountOwner',
    'onboardingDecisionDate',
    'onboardingRejectionReason',
    'dealerFarmer',
    'dealerHunter',
    'dealerBidManager',
    'depositBalance',
    'numberPurchasableCar',
    'businessTypeSelect',
    'cityId',
    'stateId',
    'inactiveReason',
    'onboardingStatus',
    'rejectedReason',
    'onboardingDate',
    'offboardingDate'
  ],
  hiddenValues: {
    status: ['rejected']
  }
}
