import defaultConfig from './defaultConfig'
import merge from 'lodash/merge'

export const superadmin = merge(
  {
    homepage: '/crm/home',
    fieldFilters: {
      contact: []
    },
    permissions: {
      auction: []
    },
    menuItems: {
      hiddenFields: []
    }
  },
  defaultConfig
)
