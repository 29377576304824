import {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {translate} from 'react-i18next'
import moment from 'moment'
// Material UI
import {withStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
// lodash
import noop from 'lodash/noop'

import FilterCalendar from '../../../../components/RichTable/FilterBar/Filter/FilterCalendar'
import {rootButtonStyles, buttonBorder, buttonHeight} from './common'

const styles = {
  buttonLeft: {
    root: {
      ...rootButtonStyles,
      'border-top-right-radius': 0,
      'border-bottom-right-radius': 0
    }
  },
  buttonRight: {
    root: {
      ...rootButtonStyles,
      'border-left': 0,
      'border-top-left-radius': 0,
      'border-bottom-left-radius': 0
    }
  },
  pickerButton: {
    root: {
      'min-height': '42px',
      'border-top': buttonBorder,
      'border-bottom': buttonBorder,
      'border-right': buttonBorder,
      'border-radius': 0,
      'text-transform': 'capitalize'
    }
  }
}
const PickerButton = withStyles(styles.pickerButton)(Button)
const ButtonLeft = withStyles(styles.buttonLeft)(Button)
const ButtonRight = withStyles(styles.buttonRight)(Button)
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`
const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 41px;
  right: 40px;
`
const DayPickerWrapper = styled.div`
  display: flex;
  height: ${() => buttonHeight};
  position: relative;
`

class DayPicker extends PureComponent {
  handleSave = (props) => {
    this.props.handleSave(props)
    this.props.toggleCalendar()
  }

  render() {
    return (
      <DayPickerWrapper>
        <ButtonGroup>
          <ButtonLeft
            disabled={this.props.isLoading}
            onClick={this.props.handleLeftButton}
            data-test='button-left'
          >
            <ChevronLeft />
          </ButtonLeft>
          <PickerButton
            onClick={this.props.goToToday}
            disabled={this.props.isLoading}
            data-test='button-pick-today'
          >
            {this.props.t('appointmentCalendar.daypicker.today')}
          </PickerButton>
          <PickerButton
            onClick={this.props.toggleCalendar}
            disabled={this.props.isLoading}
            data-test='button-pick'
          >
            <span>{this.props.date}</span>
          </PickerButton>
          <ButtonRight
            disabled={this.props.isLoading}
            onClick={this.props.handleRightButton}
            data-test='button-right'
          >
            <ChevronRight />
          </ButtonRight>
        </ButtonGroup>
        <CalendarWrapper>
          <FilterCalendar
            selected={this.props.date ? moment(this.props.date) : {}}
            fieldName={this.props.calendarId}
            open={this.props.isOpen}
            isRange={false}
            handleChange={noop}
            withPresets={false}
            showInput={false}
            handleSave={this.handleSave}
            showClear={false}
          />
        </CalendarWrapper>
      </DayPickerWrapper>
    )
  }
}

DayPicker.propTypes = {
  chosen: PropTypes.string,
  isLoading: PropTypes.bool,
  handleLeftButton: PropTypes.func,
  handleRightButton: PropTypes.func,
  goToToday: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleCalendar: PropTypes.func
}

DayPicker.defaultProps = {
  isLoading: false,
  goToToday: () => {},
  isOpen: false,
  toggleCalendar: () => {}
}

export default translate()(DayPicker)
