import Tasks from '../../../pages/CRM/Task/List'
import * as filters from '../../../pages/CRM/Comment/Filters'
export const taskSort = [{field: 'dueDate', order: 'ASC'}]

export const responsible = {
  key: 'responsibleName',
  label: 'task.responsible.label',
  type: 'string',
  filter: filters.Responsible
}

export const taskFields = {
  status: {
    key: 'status',
    label: 'global.status.label',
    type: 'string',
    prefix: 'task.status',
    filter: filters.StatusFilter
  },
  type: {
    key: 'subtype',
    label: 'tasks.subtype.label',
    type: 'string',
    filter: filters.SubtypeFilter
  },
  subject: {
    key: 'subject',
    label: 'comment.subject.label',
    type: 'string'
  },
  description: {
    key: 'description',
    label: 'comment.description.label',
    type: 'string',
    shorten: 80
  },
  priority: {
    key: 'priority',
    label: 'task.priority.label',
    type: 'string',
    prefix: 'car.page.taskPriority',
    filter: filters.Priority
  },
  contact: {
    type: 'composite',
    key: 'contact',
    layout: 'rows',
    children: [
      {
        type: 'composite',
        key: 'contact.name',
        separator: ' ',
        link: {
          base: 'crm/contacts/detail',
          key: 'contact.id'
        },
        children: [
          {
            key: 'contact.firstname',
            label: 'contact.firstname.label',
            separator: ' ',
            type: 'string',
            isSortable: false
          },
          {
            key: 'contact.lastname',
            label: 'contact.lastname.label',
            type: 'string',
            isSortable: false
          }
        ]
      },
      {
        link: {
          newTab: true,
          key: 'phoneLink'
        },
        key: 'contact.phone',
        label: 'contact.phone.label',
        type: 'string',
        isSortable: false,
        columnName: 'phone'
      }
    ]
  },
  dueDate: {
    key: 'dueDate',
    label: 'task.dueDate.label',
    type: 'date',
    time: true,
    isSortable: true
  }
}

export default {
  path: 'tasks',
  label: 'menu.crm.task',
  key: 'crm/tasks',
  fields: {
    ...taskFields,
    dueDate: {
      key: 'dueDate_responsible',
      type: 'composite',
      layout: 'rows',
      children: [taskFields.dueDate, responsible]
    }
  },
  permissions: {
    entity: 'web.admin.ui.crm.tasks',
    types: ['READ']
  },
  component: Tasks
}
