import {createContext} from 'react'
import {getUserConfig, getUserConfigByRole} from '../../config/roles'
import {ConfigConsumer} from './withConfig'
import userResource from '../../utils/user'

const UserContext = createContext(getUserConfig())

const UserProviderBase = (props) => {
  const relay =
    typeof props.role !== 'undefined' && props.role !== ''
      ? {...getUserConfigByRole(props.role)}
      : {...getUserConfig(props?.config?.countryCode)}
  userResource.setUser(relay).getUser()

  return <UserContext.Provider {...props} value={relay} />
}

export const UserConsumer = (WrappedComponent, props) => (
  <UserContext.Consumer>
    {(user) => <WrappedComponent {...props} user={user} />}
  </UserContext.Consumer>
)

export const UserProvider = (props) => ConfigConsumer(UserProviderBase, props)
