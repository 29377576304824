import styled from 'styled-components'
import {FileTile} from '@fcg/image-viewer'
import {
  fileAlternativeRenderer,
  galleryFileAlternativeRendererWrapper
} from '../common'

const GalleryImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 110px;
  margin-bottom: 10px;
  height: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > svg {
    font-size: 80px;
  }
`

export const ThumbnailList = ({images, selectImage, selected}) =>
  images.map((image, index) => {
    if (index === selected) {
      return null
    }

    const alternativeRenderer = fileAlternativeRenderer[image.fileType]

    return (
      <GalleryImageWrapper key={`gallery-image-${index}`}>
        <FileTile
          {...image}
          alternativeRenderer={alternativeRenderer}
          wrapper={galleryFileAlternativeRendererWrapper[image.fileType]}
          onClick={() => selectImage(index)}
        />
      </GalleryImageWrapper>
    )
  })
