import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import {translate} from 'react-i18next'

export const DeleteDialog = (props) => {
  const {t, heading, content, open, active} = props
  const title =
    active === true
      ? t('location.deactivate.title')
      : t('location.activate.title')
  const alert =
    active === true
      ? t('location.deactivate.alert')
      : t('location.activate.alert')
  const buttonLabel =
    active === true ? t('location.deactivate') : t('location.activate')

  return (
    <Dialog open={open} onClose={props.handleClose}>
      <DialogTitle>{heading || title}</DialogTitle>
      <DialogContent>{content || alert}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color='primary'>
          {t('button.cancel')}
        </Button>
        <Button onClick={props.handleSubmit} variant='raised' color='primary'>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default translate()(DeleteDialog)
