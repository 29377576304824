import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {makeStyles, Theme} from '@material-ui/core/styles'

import SectionTitle from './SectionTitle'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(2),
    marginBottom: 24
  },
  fullWidth: {
    marginRight: -theme.spacing(2),
    marginLeft: -theme.spacing(2)
  }
}))
interface SectionWrapperProps {
  title: string
  subtitle?: string
  children?: React.ReactNode
  twoColumn?: boolean
  title2?: string
  subtitle2?: string
  fullWidth?: boolean
}

const SectionWrapper = ({
  title,
  subtitle,
  children,
  twoColumn = false,
  title2,
  subtitle2,
  fullWidth = false
}:SectionWrapperProps) => {
  const classes = useStyles()

  const Column = ({_title = title, _subtitle}:{_title?: string, _subtitle?:string}) => (
    <Grid item xs={twoColumn ? 6 : 12}>
      <SectionTitle title={_title} />
      {_subtitle && <SectionTitle variant='subtitle1' title={_subtitle} />}
    </Grid>
  )

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container justify='center' spacing={2}>
        <Column _subtitle={subtitle} />
        {twoColumn && <Column _title={title2} _subtitle={subtitle2} />}
      </Grid>
      <Grid className={fullWidth ? classes.fullWidth : null}>{children}</Grid>
    </Paper>
  )
}

export default SectionWrapper
