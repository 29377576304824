import 'date-fns'
import React, { useContext, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { addDays, compareAsc } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import {DownloadType} from '../../types'
import { MainContext } from '../../context/MainContext'

interface DatePickerProps {
  downloadType: DownloadType
}

const DateRangePicker = ({
  downloadType
}: DatePickerProps) => {
  const { selectedDateRange, setSelectedDateRange } = useContext(MainContext)
  
  const handleChange = (
    field: string,
    value: string | Date | null
  ) => {
    // Do not remove this check as it handles a bug of date picker wher on text input it retursn this text with object type.
    if (typeof value === 'object' && value !='Invalid Date') {
      setSelectedDateRange({ ...selectedDateRange, [field]: value })
    }
  }
  
  const {minDate, maxDate, MaxDateInToDate } = useMemo(() => {
    let minDate, maxDate, toMaxDate
    const currentDate = new Date()
    if (downloadType === DownloadType.capacity) {
    minDate = addDays(currentDate, -60)
    maxDate = addDays(currentDate, 60) 
  } else {
    let tempDate;
    minDate = addDays(currentDate, 0)
    maxDate = addDays(currentDate, 30) 
    tempDate = addDays(selectedDateRange.from, 7) 
    toMaxDate = compareAsc(tempDate, maxDate)>0? maxDate : tempDate 
    }
    const MaxDateInToDate = toMaxDate || maxDate
    return {minDate, maxDate, MaxDateInToDate}
  }, [downloadType, selectedDateRange.from])
  
  return (
    <>
      <form >
        <Grid item xs={9}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item style={{
                width: '100%',
                marginBottom: '24px',
                marginTop: '25px'
              }}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  format='dd/MM/yyyy'
                  margin='none'
                  id='date-picker-start'
                  label='Start date'
                  minDate={minDate}
                  maxDate={maxDate}
                  value={selectedDateRange.from}
                  onChange={date => handleChange('from', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  style={{}}
                />
              </Grid>
              <Grid item style={{
                width: '100%',
                marginBottom: '35px'
              }}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  format='dd/MM/yyyy'
                  margin='none'
                  id='date-picker-end'
                  label='End date'
                  minDate={selectedDateRange.from}
                  maxDate={MaxDateInToDate}
                  value={selectedDateRange?.to}
                  onChange={date => handleChange('to', date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </form>
    </>
  )
}

export default DateRangePicker
