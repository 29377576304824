import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import omit from 'lodash/omit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  withMobileDialog
} from '@material-ui/core'
import {SlideUp} from '../../../components/Animations/Slides'
import Form from './Edit'
import dialogStyles from '../common/dialogStyles'

import {
  hydrateLeadForm,
  createLead,
  updateLead,
  resetLeadForm
} from '../../../store/crm/lead/actions'
import {loadOptions, setDiscardReasonOptions} from '../../../store/crm/actions'
import {ValidatedForm} from '@fcg/formvalidation'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {leadForm} from '../../../config/pages/crm/forms'
import {getCountryConfig} from '../../../../config/country'

export class LeadsDialog extends Component {
  static defaultProps = {
    creation: false
  }

  state = {users: {options: [], count: 0}, data: {}}

  componentDidMount() {
    const {match, creation} = this.props
    this.props.loadOptions()
    if (!creation && match.params.leadId) {
      this.props.loadData({id: match.params.leadId})
    }

    this.setState({data: this.props.data})
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.data.id !== nextProps.data.id) {
      this.setState({
        data: {...nextProps.data}
      })
    }
  }

  update = ({name, value}) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  handleCancel = () => {
    this.props.reset()
    this.props.handleClose()
  }

  updateDiscardedOption = ({data}) => {
    this.props.setDiscardReasonOptions(data)
  }

  handleSubmit = () => {
    const {contactId, match, creation} = this.props
    const {data} = this.state
    let payload = omit(data, ['quote', 'leadStatus', 'id'])
    if (getCountryConfig().countryCode === 'TR') {
      const {faceLift, seat, engine, wheelDrive, trim} = data
      const combineTrim = `${faceLift || '$'}|${seat || '$'}|${engine ||
        '$'}|${wheelDrive || '$'}|${trim || '$'}`
      payload.trim = combineTrim
      payload = omit(payload, ['faceLift', 'seat', 'engine', 'wheelDrive'])
    }

    if (!creation && match.params.leadId) {
      this.props.save({
        data: {
          ...payload,
          status: data.leadStatus
        },
        id: match.params.leadId
      })
    } else {
      this.props.create({
        contactId,
        data: {
          ...payload,
          status: data.leadStatus
        }
      })
    }
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  render() {
    const {t, creation, editState} = this.props
    const dialogTitle =
      !!creation === true
        ? `${t('global.action.create')} ${t('entity.lead')}`
        : `${t('global.action.update')} ${t('entity.lead')}`
    const buttonText =
      !!creation === true
        ? t('global.action.create')
        : t('global.action.update')

    if (editState.success === true) {
      this.props.handleSubmit()
    }

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={SlideUp}
        >
          <ValidatedForm onSubmit={this.handleSubmit} t={t}>
            <DialogTitle id='form-dialog-title'>
              {dialogTitle}
              {creation === false && (
                <Typography variant='caption'>
                  {this.props.match.params.leadId}
                </Typography>
              )}
            </DialogTitle>
            <DialogContent>
              {editState.error !== false && (
                <Typography variant='body2' color='error'>
                  {t('form.message.error.occurred')}
                </Typography>
              )}
              <Form
                {...this.props}
                data={this.state.data}
                update={this.update}
                users={this.state.users}
                setUserOptions={this.setUserOptions}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color='primary'>
                {t('button.cancel')}
              </Button>
              <Button
                variant='raised'
                disabled={editState.pending === true}
                type='submit'
                color='primary'
              >
                {buttonText}
              </Button>
            </DialogActions>
          </ValidatedForm>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({lead, crm, config: {crmDetails}}) => {
  const data = omit(lead.form, ['contactId', ...crmDetails.hidden])
  const editState = lead.editState
  const {options} = crm

  return {data, editState, options}
}

const mapDispatchToProps = (dispatch) => ({
  loadOptions: () => dispatch(loadOptions()),
  create: (params) => dispatch(createLead(params)),
  save: (params) => dispatch(updateLead(params)),
  loadData: ({id}) => dispatch(hydrateLeadForm({id})),
  reset: () => dispatch(resetLeadForm()),
  setDiscardReasonOptions: (data) => dispatch(setDiscardReasonOptions(data))
})

const LeadForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(formWithCountryOptions(leadForm, 'crmDetails')(translate()(LeadsDialog)))

export default withMobileDialog()(withStyles(dialogStyles)(LeadForm))
