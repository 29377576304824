import {Component} from 'react'
import {withRouter} from 'react-router'
import WalkinForm from './Editor'
import {PermissionWrapper} from '../../../components/Permissions'
import {translate} from 'react-i18next'
import Empty from '../../../components/Empty'
import {isOldEntity} from '../helpers'

class Walkin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: true
    }
  }

  closeWalkinForm = () => {
    this.setState({
      isOpen: false
    })

    this.props.history.goBack()
  }

  render() {
    const {t, history} = this.props

    return (
      <PermissionWrapper entity='web.admin.ui.crm.walkin2' withDescription>
        {isOldEntity({createdAt: new Date()}) ? (
          <WalkinForm
            isOpen={this.state.isOpen}
            withTransition={false}
            handleClose={this.closeWalkinForm}
            handleSubmit={this.closeWalkinForm}
          />
        ) : (
          <Empty
            title={t('appointment.salesForceGoLive')}
            buttonText={t('button.go.back')}
            onClick={history.goBack}
          />
        )}
      </PermissionWrapper>
    )
  }
}

export default withRouter(translate()(Walkin))
