import {createContext} from 'react'

export const BPDetailsContext = createContext({})
export const BPDetailsProvider = BPDetailsContext.Provider
export const BPDetailsConsumer = BPDetailsContext.Consumer

export function bpProviderInit(bpInfoObj) {
  return {
    ...bpInfoObj
  }
}
