import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import DateFormat from '../../components/Format/DateFormat'
import Flex from '../../components/Flex'
import color from '../../utils/color'

const TransitJobLabelWidth = 1 / 3
const TransitJobInfowidth = 2 / 3

const TransitJobLabel = styled.span`
  color: ${color.grey};
  margin-bottom: 4px;
`

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
`

const Container = styled(Flex)`
  @media (max-width: 525px) {
    justify-content: space-evenly;
  }
`

const Wrapper = styled(Flex)`
  @media (max-width: 525px) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? `${props.width * 100}%` : '100%')};
  font-size: 14px;
  color: ${(props) => props.color || color.silver};
`

const Span = styled.span`
  color: ${(props) => props.color || color.cascadeGreen};
  margin-bottom: 4px;
`

const RightHeader = (props) =>
  props.data && (
    <Container direction='column' justify='space-between' height='100%'>
      <Wrapper
        isScrollSectionVisible={props.isScrollSectionVisible}
        direction='row'
        justify='space-between'
      >
        <Content width={TransitJobLabelWidth}>
          <Span>{props.t('Start Location')}:</Span>
          <Span>{props.t('Start Time')}:</Span>
          <Span>{props.t('End Location')}:</Span>
          <Span>{props.t('End Time')}:</Span>
        </Content>
        <Content width={TransitJobInfowidth} color={color.towerGray}>
          <TransitJobLabel>{props.data.startPlace}</TransitJobLabel>
          <DateFormat data={props.data.startTime} time />
          <TransitJobLabel>{props.data.endPlace}</TransitJobLabel>
          <DateFormat data={props.data.endTime} time />
        </Content>
      </Wrapper>
      {(props.hasPermission && !props.isScrollSectionVisible) ||
      !props.data.id ? (
        <ButtonWrapper to={`/transit-job/edit/${props.data.id}`}>
          <Button variant='raised' color='primary'>
            {props.t('button.edit')}
          </Button>
        </ButtonWrapper>
      ) : null}
    </Container>
  )

export default translate()(RightHeader)
