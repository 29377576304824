import FormDividerField from '../../../../components/FormDivider.jsx'
import {ValidatedSelectField} from '../../../../components/FormValidation'

export const locationDetail = [
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 12,
    children: [
      {
        component: FormDividerField,
        fullWidth: true,
        id: 'locationDetail',
        label: 'location.name.label',
        margin: 'normal'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'currentLocation',
        label: 'car.page.field.currentLocation',
        margin: 'normal',
        onChangeHandler: 'updateField',
        type: 'CURRENT'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'purchaseLocation',
        label: 'car.page.field.purchaseLocation',
        margin: 'normal',
        type: 'PURCHASE',
        onChangeHandler: 'updateField'
      }
    ]
  }
]
