import {Dialog, DialogContent, DialogActions, Button} from '@material-ui/core'
import {translate} from 'react-i18next'
import SlideUp from '../../../components/Animations/Slides'

const ConfirmDelete = ({onConfirm, onCancel, t}) => {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      TransitionComponent={SlideUp}
      maxWidth='md'
      fullWidth
    >
      <DialogContent>{t('global.message.confirmDelete')}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('button.back')}</Button>
        <Button onClick={onConfirm} variant='raised' color='primary'>
          {t('button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default translate()(ConfirmDelete)
