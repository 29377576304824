import wrapFilter from '../../../../components/RichTable/FilterBar/Filter/wrapFilter'
import Select from '../../../../components/Select/Select'
import {ticketStatusValues} from '../../../../config/entities/ticket'

const StatusFilterOptions = ticketStatusValues.map((t) => ({
  label: `ticket.status.${t}`,
  value: t
}))

const StatusBase = (props) => (
  <Select
    multiple
    {...props}
    withTranslate
    id={props.fieldName}
    label={props.fieldName}
    options={StatusFilterOptions}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const TicketStatusFilter = wrapFilter(StatusBase)
