import styled from 'styled-components'

const rowHeight = ({rowHeight}) => {
  if (rowHeight === 'xl') {
    return '100px'
  }

  if (rowHeight === 'lg') {
    return '60px'
  }

  return '40px'
}
const documentTableHeight = ({tableHeight}) => {
  if (tableHeight === 'lg') {
    return `
      min-height: 254px;
      max-height: 600px;
    `
  }

  return `height: 254px;`
}

const inputStyle = `
  >input {
    font-size: 12px;
    align-self: center;
    background-color: transparent;
    border: 0;
    outline: none;
    font-family: Roboto;
    max-width: 80%;
    color: #242424;

    &:focus {
      background-color: #EEEEEE;
      box-shadow: inset 0 -1px 0 0 rgba(119, 119, 119, 0.5);
    }
  }
`
export const List = styled.div`
  width: 100%;
  max-width: 580px;
  ${documentTableHeight}
  border: 1px solid #BABABA;
  color: #242424;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &.md {
    max-width 290px;
  }
  &.lg {
    max-width 550px;
  }
  &.flex {
    width: auto;
    max-width: none;
    flex-grow: 2;
  }

  .empty {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >span {
      margin-top: 16px;
    }
  }

  .row-container {
    height: 100%;
    overflow-y: scroll;

    &.flex {
      display: flex;
      align-items: initial;
      width: 100%;
      flex-direction: column;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      width: 100%;
      justify-content: space-between;
      height: ${rowHeight};
      padding: 0 4px;
      cursor: pointer;

      .row__file-name {
        // Do not break the style of the regular document import
        width: 28%;

        ${inputStyle}
      }

      .row__date {
        width: 14%;
        overflow: hidden;
      }

      .row__uploader {
        width: 24%;
        overflow: hidden;
      }

      .row__file-type {
        width: 26%;
        overflow: hidden;
        padding: 6px;
      }

      .row__metaInfoComment {
        width: 35%;
        height: 100%;
        overflow: hidden;

        > textarea {
          min-width: 90%;
          background-color: transparent;
          border: 0;
          outline: none;

          &:focus {
            background-color: #eeeeee;
            box-shadow: inset 0 -1px 0 0 rgba(119, 119, 119, 0.5);
          }
        }
      }

      .row__metaInfoDate {
        width: 15%;
        overflow: hidden;

        ${inputStyle}

        >input {
          max-width: 100%;

          &.error {
            border-bottom: 2px solid red;
          }
        }
      }

      .row__icon-wrapper {
        width: 10%;
        display: flex;
        justify-content: space-around;

        > a {
          text-transform: none;
          outline: none;
          cursor: pointer;
        }

        .icon {
          font-size: 18px;
          cursor: pointer;
        }
      }

      &:nth-child(even) {
        background-color: #ffffff;
      }

      &:nth-child(odd) {
        background-color: #f4f4f4;
      }
    }
  }
`

export const Header = styled.div`
  background-color: #f4f4f4;
  height: 34px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
  justify-content: space-between;
  border-bottom: 1px solid #bababa;

  .header__file-name {
    width: 28%;
    overflow: hidden;
  }

  .header__date {
    width: 14%;
    overflow: hidden;
  }

  .header__uploader {
    width: 24%;
    overflow: hidden;
  }

  .header__file-type {
    width: 26%;
    overflow: hidden;
  }

  .header__metaInfoComment {
    width: 35%;
    overflow: hidden;
  }

  .header__metaInfoDate {
    width: 15%;
    overflow: hidden;
  }

  .header__icon-wrapper {
    display: flex;
    width: 10%;
  }
`
