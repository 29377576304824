import cookies from 'browser-cookies'

const localStorage = window.localStorage

export const getUser = () => {
  const user = localStorage.getItem('user')

  return user === null ? false : JSON.parse(user)
}

export const checkAuth = () => {
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))
  const token =
    (oktaTokens && oktaTokens.idToken && oktaTokens.idToken.idToken) ||
    cookies.get('token')
  const userInfo = getUser()

  if (!token) {
    return false
  }
  if (userInfo === false) {
    return false
  }

  const payload = token.split('.')[1]

  if (typeof payload === 'undefined') {
    return false
  }

  const decodedToken = JSON.parse(atob(payload))
  const tokenExp = decodedToken.exp * 1000

  if (tokenExp < Date.now()) {
    return false
  }

  return {...userInfo, token}
}

export const setAuth = (auth) => {
  if (typeof auth !== 'undefined') {
    localStorage.setItem('user', JSON.stringify(auth))
    localStorage.setItem('cookie', auth.token)
    document.cookie = 'tokenTimestamp=' + Date.now() + ';path=/'
  }
}

export const removeAuth = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('cookie')
  localStorage.removeItem('okta-token-storage')
  cookies.erase('token')
  cookies.erase('tokenTimestamp')
  document.cookie = null
}

export const removeGoogleAuth = () => {
  cookies.erase('googleAccessToken')
  cookies.erase('googleIdToken')
  cookies.erase('google-client-type')
}

export const getCookie = () => {
  return localStorage.getItem('cookie')
}

export const getToken = () => {
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))

  const token =
    (oktaTokens && oktaTokens.idToken && oktaTokens.idToken.idToken) ||
    cookies.get('token')

  if (!token) {
    return false
  }

  const payload = token.split('.')[1]

  if (!payload) {
    return false
  }
  const decodedToken = JSON.parse(window.atob(payload))

  if (decodedToken.exp * 1000 <= Date.now()) {
    return false
  }

  return token
}

export const getOktaToken = () => {
  try {
    const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (oktaTokens && oktaTokens.idToken) {
      return oktaTokens.idToken
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export const setCookie = (name, val, days) => {
  if (val) {
    cookies.set(name, val, {expires: days})
  } else {
    cookies.erase(name)
  }
}
