import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import {transformToString} from '../common/helpers'
import {carDataStringFields} from '../car/fields'

const client = new ApiClient('search')

export function searchCars(data = {}) {
  const options = transformToString(data, carDataStringFields)

  return client.query('car', options, fields.carSearchList)
}

export function searchCarsByPlateId(options = {}) {
  return client.query('car', options, fields.carSearchList)
}
