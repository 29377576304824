import * as types from './types'
import * as InspectionApi from '../../api/inspection/requests'
import {handleAPIErrors, formatParams} from '../utils'

export const fetchData = ({limit, filters, sort, page} = {}) => {
  return async (dispatch, getState) => {
    dispatch(setFetching())
    const currentState = getState().inspectionReports

    limit = limit || currentState.limit
    filters = filters || currentState.filters
    sort = sort || currentState.sort
    page = page || currentState.page

    const filterParams = formatParams(filters)
    const params = {limit, ...filterParams, sort, page}

    try {
      InspectionApi.getInspectionList(params).then(({inspection: data}) => {
        dispatch(setDataLoaded(data, page, filters, sort, limit))
      })
    } catch (e) {
      const error = handleAPIErrors(e)
      dispatch(setError(error))
    }
  }
}

export const setDataLoaded = (data = [], page, filters, sort, limit) => {
  return {
    type: types.dataLoaded,
    payload: {data, page, filters, sort, limit}
  }
}

export const toggleSort = (sort) => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(fetchData())
}

export const updateFilters = (filters) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(fetchData())
}

export const setError = (error = '') => {
  return {
    type: types.error,
    payload: error
  }
}

export const setFetching = (fetching = true) => {
  return {
    type: types.fetching,
    payload: fetching
  }
}

export const toggleField = (label) => {
  return {
    type: types.toggleField,
    payload: label
  }
}
