import {ValidatedSelectField} from '../../../../components/FormValidation'
import FormDividerField from '../../../../components/FormDivider'
import UserPickerField from '../../../../components/Pickers/UserPicker'
import B2BAddInlineCountryOption from '../../../../pages/BulkUpload/B2BAddInlineCountryOption'

export const purchaseData = [
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 12,
    children: [
      {
        component: FormDividerField,
        fullWidth: true,
        id: 'purchaseData',
        label: 'car.page.tabs.purchase',
        margin: 'normal'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'purchaseChannel',
        label: 'carlist.columns.purchaseChannel',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: 'DateInput',
        fullWidth: true,
        id: 'boughtAt',
        label: 'car.page.field.boughtAt',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 6,
    sm: 3,
    children: [
      {
        component: ValidatedSelectField,
        fullWidth: true,
        id: 'B2BDeal',
        label: 'car.page.field.b2bDeal',
        margin: 'normal',
        onChangeHandler: 'updateField'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 6,
    sm: 1,
    children: [
      {
        component: B2BAddInlineCountryOption,
        fullWidth: true,
        id: 'B2BDeal',
        label: '',
        margin: 'normal'
      }
    ]
  },
  {
    component: 'Grid',
    item: true,
    xs: 12,
    sm: 4,
    children: [
      {
        component: UserPickerField,
        fullWidth: true,
        withTranslate: false,
        userClass: 'INTERNAL',
        onLoad: 'setUserOptions',
        id: 'purchasedBy',
        label: 'car.page.field.purchasedBy',
        margin: 'normal',
        onChangeHandler: 'updateField',
        debounce: true,
        limit: 25
      }
    ]
  }
]
