import {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'
import Paper from '@material-ui/core/Paper'

import {
  fetchDriverImage,
  fetchDriverDetails
} from '../../store/transitJob/actions'
import selectors from '../../store/transitJob/selectors'

const ProfileWrapper = styled(Paper)`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto;
  flex-direction: column;
`

const AvatarDriver = styled(Avatar)`
  padding: 40px;
  top: -40px;

  svg {
    font-size: 48px;
  }
`

const AvatarDriverImage = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin-top: -40px;
  margin-bottom: 32px;
`

const ProfileImage = (props) => {
  const {driverImageUrl} = props

  if (driverImageUrl) {
    return (
      <AvatarDriverImage src={driverImageUrl} data-test='driver-avatar-image' />
    )
  }

  return (
    <AvatarDriver data-test='driver-avatar-no-image'>
      <Person />
    </AvatarDriver>
  )
}

const Typography = styled.div`
  margin-top: ${(props) => props.marginTop || '4px'};
  display: flex;
  max-width: 320px;
  width: 100%;
  justify-content: space-between;

  :last-of-type {
    margin-bottom: 8px;
  }

  @media (max-width: 360px) {
    max-width: 100%;
    padding: 0 12px;
  }
`

const Span = styled.span`
  width: 100%;
  text-align: center;
  font-size: 20px;
`

class DriverProfile extends Component {
  componentDidMount() {
    this.props.fetchDriverImage(this.props.driverId)
    this.props.fetchDriver(this.props.driverId)
  }

  render() {
    return (
      <ProfileWrapper elevation={2}>
        <ProfileImage
          {...this.props}
          isFetchingImage={this.props.isFetchingImage}
        />
        <Typography marginTop='-12px'>
          <Span>
            {`${this.props.driverDetails.firstname}
              ${this.props.driverDetails.lastname}`}
          </Span>
        </Typography>
        <Typography>
          <span>{this.props.t('transitReview.driverId')}:</span>
          <span>{this.props.driverDetails.internalId}</span>
        </Typography>
        <Typography>
          <span>{this.props.t('transitReview.documentNum')}:</span>
          <span>
            {this.props.driverDetails.driver
              ? this.props.driverDetails.driver.documentId
              : '-'}
          </span>
        </Typography>
      </ProfileWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetchingImage: selectors.isFetchingDriverImage(state),
  driverImageUrl: selectors.getDriverImageUrl(state),
  driverDetails: selectors.getDriverDetails(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchDriverImage: (payload) => dispatch(fetchDriverImage(payload)),
  fetchDriver: (payload) => dispatch(fetchDriverDetails(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DriverProfile)
