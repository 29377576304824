import PropTypes from 'prop-types'
import styled from 'styled-components'
import {translate} from 'react-i18next'
import {colors} from './common'

const Wrap = styled.div`
  position: relative;
  display: inline;
`
const Tooltip = styled.div`
  width: 300px;
  min-height: 70px;
  position: absolute;
  font-size: 10px;
  border-radius: 3px;
  background-color: ${() => colors.tooltipBackground};
  color: ${() => colors.white};
  padding: 5px;
  line-height: 1.4;
  z-index: 10;
`
const TooltipList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

export const CardTooltipBase = ({appointment, t}) => {
  const leadInfo =
    appointment.lead && appointment.lead.make
      ? `${appointment.lead.make} ${appointment.lead.model}`
      : '/'

  return (
    <Wrap>
      <Tooltip>
        <TooltipList>
          <li>{`${t('appointmentCalendar.tooltip.lead')}: ${leadInfo}`}</li>
          <li>{`${t('appointmentCalendar.tooltip.inspection')}: ${
            appointment.time
          }`}</li>
          <li>{`${t('appointmentCalendar.tooltip.status')}: ${
            appointment.status
          }`}</li>
          <li>{`${t('appointmentCalendar.tooltip.bookingId')}: ${
            appointment.bookingId
          }`}</li>
        </TooltipList>
      </Tooltip>
    </Wrap>
  )
}

CardTooltipBase.propTypes = {
  appointment: PropTypes.object
}

export default translate()(CardTooltipBase)
