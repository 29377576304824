import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {
  getList,
  toggleField,
  toggleSort,
  updateFilters,
  deleteDealer,
  loadDealer,
  setPathMode
} from '../../store/users/actions'
import {Button} from '@material-ui/core'
import {Add, Delete} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import {Link} from 'react-router-dom'
import {DeleteDialog} from '../CRM/common'
import * as selectors from '../../store/users/selectors'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  entity: null
}

export class Dealers extends Component {
  static defaultProps = {selectable: false}
  state = {
    delete: {...deleteInitialState},
    activeContacts: [],
    showCommentEditor: false
  }

  componentDidMount() {
    this.props.setPathMode({path: 'dealers'})
  }

  routeToDealerProfile = (e, dealer) => {
    this.props.history.push(`detail/${dealer.id}${this.props.location.search}`)
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({entity, email, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        handleSubmit: () => {
          action(email)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  renderActionBox = () =>
    !this.props.readOnlyPath && (
      <Fragment>
        <ActionButton>
          <Button
            variant='fab'
            color='primary'
            component={Link}
            to={`/dealers/create${this.props.location.search}`}
            data-test='button-create'
          >
            <Add />
          </Button>
        </ActionButton>
        <DeleteDialog {...this.state.delete} />
      </Fragment>
    )

  renderSingleSelectAction = () =>
    !this.props.readOnlyPath && [
      {
        event: (item) => {
          this.openDeleteDialog({
            entity: 'dealer',
            email: item.email,
            action: this.props.deleteDealer
          })
        },
        icon: <Delete data-test='button-delete' />,
        title: 'global.action.delete'
      }
    ]

  render() {
    return (
      <div className='page'>
        <PageTableBase
          filterable
          {...this.props}
          multisort={false}
          title='Dealers'
          onRowClick={this.routeToDealerProfile}
          rowsPerPageOptions={[25, 50, 100]}
          singleSelectActions={this.renderSingleSelectAction()}
        />

        {this.renderActionBox()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getListData(state),
  fetching: selectors.isFetching(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getDealerFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getDealerFields(state),
  readOnlyPath: selectors.isPathReadOnly(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(getList(args)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  onFilterChange: (filter) => dispatch(updateFilters(filter)),
  deleteDealer: (dealer) => dispatch(deleteDealer(dealer)),
  loadDealer: (id) => dispatch(loadDealer(id)),
  setPathMode: (pathConfig) => dispatch(setPathMode(pathConfig))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dealers)
