import {createReducer} from '../utils'

import * as actions from './actions'
import {default as crmReducer} from './reducers'
import {getInitialState as crmState} from './initialState'

import {default as appointmentReducer} from './appointment/reducers'
import {getInitialState as appointmentState} from './appointment/initialState'

import {default as leadReducer} from './lead/reducers'
import {getInitialState as leadState} from './lead/initialState'

import {default as walkinReducer} from './walkin/reducers'
import {getInitialState as walkinState} from './walkin/initialState'

import {default as contactReducer} from './contact/reducers'
import {getInitialState as contactState} from './contact/initialState'

import {default as detailReducer} from './detail/reducers'
import {getInitialState as detailState} from './detail/initialState'

import {default as commentReducer} from './comment/reducers'
import {getInitialState as commentState} from './comment/initialState'

import {default as taskReducer} from './task/reducers'
import {getInitialState as taskState} from './task/initialState'

import {default as changesReducer} from './changes/reducers'
import {getInitialState as changesState} from './changes/initialState'

import {default as leadAllocationReducer} from './leadAllocation/reducers'
import {getInitialState as leadAllocationState} from './leadAllocation/initialState'

export {getInitialState} from './initialState'
export {actions}

export default ({
  Appointments,
  Leads,
  Task,
  Contacts,
  Landing,
  ContactDetail,
  Changes,
  LeadAllocation
}) => ({
  crm: createReducer(crmState({...Landing}), crmReducer),
  appointment: createReducer(
    appointmentState({list: Appointments}),
    appointmentReducer
  ),
  lead: createReducer(leadState({list: Leads}), leadReducer),
  walkin: createReducer(walkinState(), walkinReducer),
  contact: createReducer(contactState({list: Contacts}), contactReducer),
  detail: createReducer(detailState({detail: ContactDetail}), detailReducer),
  task: createReducer(taskState({list: Task}), taskReducer),
  changes: createReducer(changesState({list: Changes}), changesReducer),
  comment: createReducer(commentState(), commentReducer),
  leadAllocation: createReducer(
    leadAllocationState({list: LeadAllocation}),
    leadAllocationReducer
  )
})
