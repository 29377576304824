import { NotificationItem, NotificationsApiReponse } from "../components/Notifications/Notifications.types";

const updateLocalListingData = (listingData: NotificationsApiReponse, updatedItemData: NotificationItem, actionType: string) => {
    const updatedDataArray = listingData.data.map(obj => (updatedItemData.messageId === obj.messageId && updatedItemData) || obj);
    const updatedMetaObject = {
        ...listingData.meta,
        new: actionType==="new" ? (listingData.meta.new + 1) : (listingData.meta.new - 1)
    }
    return {data: updatedDataArray, meta: updatedMetaObject};
};

export default updateLocalListingData;
