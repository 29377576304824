export class InvalidTokenError extends Error {
  constructor() {
    super()
    this.type = 'InvalidTokenError'
    this.message = 'global.errors.invalidToken'
  }
}

export class AuthNoPermissionsError extends Error {
  constructor(message) {
    super()
    this.type = 'AuthNoPermissionsError'
    this.message = message || 'global.errors.noPermissions'
  }
}

export class NotFoundError extends Error {
  constructor(message) {
    super()
    this.type = 'NotFoundError'
    this.message = message || 'global.errors.notFound'
  }
}

export class BadRequestError extends Error {
  constructor(message) {
    super()
    this.type = 'BadRequestError'
    this.message = message || 'global.errors.badRequest'
  }
}

export class NoResponseError extends Error {
  constructor(message) {
    super()
    this.type = 'NoResponse'
    this.message = message || 'global.errors.noResponse'
  }
}
