import ValidatedSelect from './FormValidation/ValidatedSelectField'
import {connect} from 'react-redux'
import {getCarDataOptions} from '../store/config/actions'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import isFunction from 'lodash/isFunction'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import get from 'lodash/get'
import {getCountryConfig} from '../../config/country'
import {ValidatedTextField} from './FormValidation'
import {RenderIf} from './RenderIf'
import {Component, Fragment} from 'react'

const CONFIG_PREFIX = 'lead'

const priceFields = ['price', 'minPrice', 'maxPrice']
const ignoredFields = ['location']
const CarDataElement = (Component) => ({
  visible,
  container: Container,
  ...rest
}) => (
  <RenderIf if={visible}>
    <Container>
      <Component {...rest} />
    </Container>
  </RenderIf>
)
const CarDataSelect = CarDataElement(ValidatedSelect)
const CarDataTextField = CarDataElement(ValidatedTextField)

const config = getCountryConfig()

const hiddenFields = get(config, ['crmDetails', 'hidden']).reduce(
  (acc, field) =>
    field.includes(CONFIG_PREFIX)
      ? [...acc, field.replace(`${CONFIG_PREFIX}.`, '')]
      : acc,
  []
)

const fieldDependencies = get(config, [
  'crmDetails',
  'carDataPicker',
  'dependencies'
])

const fields = keys(fieldDependencies).filter(
  (field) => !hiddenFields.includes(field)
)

function removeItemsFromArray(valuesArr, itemsToBeRemoved) {
  const indexSet = new Set(itemsToBeRemoved)

  return valuesArr.filter((value) => !indexSet.has(value))
}

const fieldsToResetOnMakeChange = removeItemsFromArray(
  [
    'model',
    'year',
    'trim',
    'bodyType',
    'faceLift',
    'seat',
    'engine',
    'wheelDrive',
    'transmission',
    ...priceFields
  ],
  hiddenFields
)

const fieldsToResetOnModelChange = removeItemsFromArray(
  [
    'year',
    'trim',
    'bodyType',
    'faceLift',
    'seat',
    'engine',
    'wheelDrive',
    'transmission'
  ],
  hiddenFields
)

const fieldsToResetOnYearChange = removeItemsFromArray(
  [
    'trim',
    'bodyType',
    'faceLift',
    'seat',
    'engine',
    'wheelDrive',
    'transmission'
  ],
  hiddenFields
)

const fieldsToResetOnTrimChange = removeItemsFromArray(
  ['faceLift', 'seat', 'engine', 'wheelDrive', 'bodyType', 'transmission'],
  hiddenFields
)

const fieldsToResetOnFaceLiftChange = removeItemsFromArray(
  ['seat', 'engine', 'wheelDrive', 'transmission'],
  hiddenFields
)

const fieldsToResetOnSeatChange = removeItemsFromArray(
  ['engine', 'wheelDrive', 'transmission'],
  hiddenFields
)

const fieldsToResetOnEngineChange = removeItemsFromArray(
  ['wheelDrive', 'transmission'],
  hiddenFields
)

const fieldsToResetOnWheelDriveChange = removeItemsFromArray(
  ['transmission'],
  hiddenFields
)

const fieldsToResetOnBodyTypeChange = removeItemsFromArray(
  ['faceLift', 'seat', 'engine', 'wheelDrive', 'transmission'],
  hiddenFields
)

const Make = ({id, make, method, onChange, fetchQuote, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.make'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnMakeChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={make}
    value={make}
    {...rest}
  />
)

const Model = ({id, method, fetchQuote, onChange, model, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.model'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnModelChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={model}
    value={model}
    {...rest}
  />
)

const Year = ({id, method, fetchQuote, onChange, year, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    type='number'
    id={id}
    name={id}
    label='car.page.field.year'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnYearChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={year}
    value={year}
    {...rest}
  />
)

const Trim = ({id, method, fetchQuote, onChange, trim, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.trim'
    onChange={(selection) =>
      onChange(
        selection,
        method,
        getCountryConfig().countryCode === 'TR'
          ? fieldsToResetOnTrimChange
          : ['transmission'],
        {fetchQuote: fetchQuote}
      )
    }
    selected={trim}
    value={trim}
    {...rest}
  />
)

const FaceLift = ({id, method, fetchQuote, onChange, faceLift, ...rest}) => (
  <CarDataSelect
    filterable
    withOther
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.faceLift'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnFaceLiftChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={faceLift}
    value={faceLift}
    {...rest}
  />
)

const Seat = ({id, method, fetchQuote, onChange, seat, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.seat'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnSeatChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={seat}
    value={seat}
    {...rest}
  />
)

const Engine = ({id, method, fetchQuote, onChange, engine, ...rest}) => (
  <CarDataSelect
    filterable
    withOther={!getCountryConfig().disableOtherFreeTextInDropdown}
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.engine'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnEngineChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={engine}
    value={engine}
    {...rest}
  />
)

const WheelDrive = ({
  id,
  method,
  fetchQuote,
  onChange,
  wheelDrive,
  ...rest
}) => (
  <CarDataSelect
    filterable
    withOther
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.wheelDrive'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnWheelDriveChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={wheelDrive}
    value={wheelDrive}
    {...rest}
  />
)

const BodyType = ({id, method, fetchQuote, onChange, bodyType, ...rest}) => (
  <CarDataSelect
    filterable
    withOther
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='car.page.field.bodyType'
    onChange={(selection) =>
      onChange(selection, method, fieldsToResetOnBodyTypeChange, {
        fetchQuote: fetchQuote
      })
    }
    selected={bodyType}
    value={bodyType}
    {...rest}
  />
)

const Transmission = ({
  id,
  method,
  fetchQuote,
  onChange,
  transmission,
  ...rest
}) => (
  <CarDataSelect
    filterable
    withOther
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label='carData.column.transmission'
    onChange={(selection) =>
      onChange(selection, method, [], {fetchQuote: fetchQuote})
    }
    selected={transmission}
    value={transmission}
    {...rest}
  />
)

const Mileage = ({method, onChange, onBlur, t, mileage, ...rest}) => (
  <CarDataTextField
    label={t('global.mileage.label')}
    name='mileage'
    type='number'
    margin='normal'
    value={mileage}
    onChange={(e) => onChange(parseInt(e.target.value, 10))}
    onBlur={(e) => onBlur(parseInt(e.target.value, 10), method)}
    fullWidth
    {...rest}
  />
)

const Location = ({id, method, location, t, onChange, ...rest}) => (
  <CarDataSelect
    filterable
    fullWidth
    margin='normal'
    id={id}
    name={id}
    label={t('car.page.field.carlocation')}
    onChange={(selection) => onChange(selection, method)}
    selected={location}
    value={location}
    {...rest}
  />
)

const fieldEquality = (field) => (config) =>
  config === field || config.field === field
const getFieldConfig = (config, field) => config.find(fieldEquality(field))
const containsField = (config, field) => config.some(fieldEquality(field))
const formIncludesDependencies = (formFields) => (field, dependencies) =>
  dependencies.some(
    (dependency) =>
      !fieldEquality(field)(dependency) && formFields.includes(dependency)
  )
const getFieldDependencyObject = (formFields) =>
  keys(formFields).reduce((acc, field) => ({...acc, [field]: false}), {})
const carDataTransforms = {
  location: {
    propsKey: 'locations',
    transform: (data, propsData) => {
      const matched = propsData.find((d) => d.value === data)

      return matched ? matched.label : null
    }
  }
}
const transformData = (data, props) => {
  return keys(data).reduce((acc, key) => {
    if (carDataTransforms[key]) {
      const {propsKey, transform} = carDataTransforms[key]

      return {
        ...acc,
        [key]: transform(data[key], props[propsKey])
      }
    }

    return {
      ...acc,
      [key]: data[key]
    }
  }, {})
}

class CarDataPicker extends Component {
  constructor(props) {
    super(props)

    // Consider fields which starts with $CONFIG_PREFIX. eg. lead.status

    this.state = {
      walkin: false,
      Active: null,
      currentSelection: 'make',
      selected: {
        make: '',
        model: '',
        year: '',
        trim: '',
        faceLift: '',
        seat: '',
        engine: '',
        wheelDrive: '',
        bodyType: '',
        transmission: '',
        mileage: '',
        location: '',
        quote: []
      },
      id: {
        make: 'make',
        model: 'model',
        year: 'year',
        trim: 'trim',
        faceLift: 'faceLift',
        seat: 'seat',
        engine: 'engine',
        wheelDrive: '',
        bodyType: 'bodyType',
        transmission: 'transmission',
        location: 'location'
      },
      requiredFieldDependencies: {
        ...getFieldDependencyObject(fields)
      },
      disabledFieldDependencies: {
        ...getFieldDependencyObject(fields)
      }
    }
  }

  static defaultProps = {
    onComplete: () => {}
  }

  componentDidMount() {
    if (typeof this.props.makes === 'undefined') {
      this.getCarData('make')()
    }

    if (!isUndefined(this.props.selected)) {
      this.setState({selected: {...this.props.selected, location: ''}})
    }

    if (location.pathname.includes('walkin')) {
      this.setState({walkin: true})
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      fields.forEach((field) => {
        this.fillCarData(field)
        this.handleFields()
      })

      this.requiredDependentFilled()
      this.disabledDependentFilled()
    }
  }

  isDependencyActive = (dependencies) =>
    dependencies.every((dep) =>
      this.isVisible(dep)
        ? (this.props.lead[dep] && this.props.lead[dep].length !== 0) ||
          (this.state.selected[dep] && this.state.selected[dep].length !== 0)
        : true
    )

  requiredDependentFilled = () => {
    const {ignoreEmptyDependencies} = this.props
    const requiredFields = getCountryConfig().crmDetails.required
    const leadFields = keys(this.props.lead)
    const areFieldsInLead = formIncludesDependencies(leadFields)
    const requiredFieldDependencies = fields.reduce((acc, field) => {
      if (ignoreEmptyDependencies) {
        return {
          ...acc,
          [field]: true
        }
      }

      const config = getFieldConfig(requiredFields, field)

      if (!getFieldConfig(requiredFields, field)) {
        return {
          ...acc,
          [field]: false
        }
      }

      if (!areFieldsInLead(fields, config.dependencies)) {
        return {
          ...acc,
          [field]: true
        }
      }

      const dependenciesActive = this.isDependencyActive(config.dependencies)

      return {
        ...acc,
        [field]: dependenciesActive
      }
    }, {})

    this.setState({requiredFieldDependencies})
  }

  disabledDependentFilled = () => {
    const disabledFieldDependencies = keys(fieldDependencies).reduce(
      (acc, field) => ({
        ...acc,
        [field]: this.isDependencyActive(fieldDependencies[field])
      }),
      {}
    )

    this.setState({disabledFieldDependencies})
  }

  isFieldRequired = (field) =>
    containsField(getCountryConfig().crmDetails.required, field)

  areDependenciesFilled = (field) => this.state.requiredFieldDependencies[field]

  isRequired = (field) =>
    this.isFieldRequired(field) && this.areDependenciesFilled(field)

  fillPrice = async (quote) => {
    const price = quote.length === 1 && quote[0] !== 0 ? quote[0] : ''
    const minPrice = quote.length === 2 && quote[0] !== 0 ? quote[0] : ''
    const maxPrice = quote.length === 2 && quote[1] !== 0 ? quote[1] : ''
    await this.props.onChange(price, 'price')
    await this.props.onChange(minPrice, 'minPrice')
    await this.props.onChange(maxPrice, 'maxPrice')
  }

  fillCarData = (field) => {
    const {selected} = this.state
    const fetchTillNumber = getCountryConfig().fetchPriceQuoteFromAIP ? 4 : 3
    if (this.props[field] !== '' && this.state.selected[field] === '') {
      selected[field] = this.props[field] || ''
      this.setState({selected}, () => {
        if (fields.indexOf(field) < fetchTillNumber) {
          const newIndex = fields.indexOf(field) + 1
          const newField = fields[newIndex]

          this.getCarData(newField, fields.slice(0, newIndex))()
        }
      })
    }
  }

  getQuote = () => {
    const {selected} = this.state
    if (getCountryConfig().fetchPriceQuoteFromAIP) {
      if (this.state.currentSelection !== 'transmission') {
        this.getCarData(
          'quote',
          []
        )(() => {
          this.props.onChange([0], 'quote')
          this.fillPrice([0])
        })
        const isLocationNeeded = getCountryConfig().locationNeededForAip
          ? selected['make'] &&
            selected['model'] &&
            selected['trim'] &&
            selected['year'] &&
            selected['location'] &&
            selected['mileage']
          : selected['make'] &&
            selected['model'] &&
            selected['trim'] &&
            selected['year'] &&
            selected['mileage']

        if (isLocationNeeded) {
          this.getCarData(
            'quote',
            fields.filter((i) => selected[i])
          )(() => {
            this.props.onChange(this.props.quote, 'quote')
            this.fillPrice(this.props.quote)
          })
        }
      }
    } else {
      // Todo: check if this condition needs to be applied only for turkey
      if (selected['make'] && selected['model'] && selected['trim']) {
        this.getCarData(
          'quote',
          fields.filter((i) => selected[i])
        )(() => {
          this.props.onChange(this.props.quote, 'quote')
          this.fillPrice(this.props.quote)
        })
      }
    }
  }

  complete = () => {
    this.getQuote()
    this.props.onComplete(omit(this.state.selected, ignoredFields))
  }

  replaceValueWithCode = (valueObj, allProps) => {
    return {
      make_code:
        allProps.makes && allProps.makes.length
          ? allProps.makes.find((make) => make.label == valueObj.make)?.code ||
            ''
          : '',
      model_code:
        allProps.models && allProps.models.length
          ? allProps.models.find((model) => model.label == valueObj.model)
              ?.code || ''
          : '',
      trim_code:
        allProps.trims && allProps.trims.length
          ? allProps.trims.find((trim) => trim.label == valueObj.trim)?.code ||
            ''
          : '',
      mileage: valueObj.mileage,
      year: valueObj.year,
      lead_id: this.props.selectedLeadId
        ? this.props.selectedLeadId
        : 'adminapp2-<adminapp2-version>',
      location: valueObj.location ? valueObj.location : ''
    }
  }

  getCarData = (key, fields = []) => async (next) => {
    const selection = fields.length > 0 ? pick(this.state.selected, fields) : {}
    // if (Object.values(selection).indexOf('') === -1) {
    if (getCountryConfig().fetchPriceQuoteFromAIP) {
      if (
        !Object.entries(selection).filter(
          (val) => val[1] == '' && val[0] != 'mileage'
        ).length
      ) {
        const transformedSelection = transformData(selection, this.props)
        if (key === 'quote') {
          const replaceValueWithCodeObj = this.replaceValueWithCode(
            transformedSelection,
            this.props
          )
          await this.props.getCarData({key, selection: replaceValueWithCodeObj})
        } else {
          await this.props.getCarData({key, selection: transformedSelection})
        }

        if (isFunction(next)) {
          next()
        }
      }
    } else {
      if (Object.values(selection).indexOf('') === -1) {
        const transformedSelection = transformData(selection, this.props)
        await this.props.getCarData({key, selection: transformedSelection})

        if (isFunction(next)) {
          next()
        }
      }
    }
  }

  handleFields = () => {
    const p = []

    fields.forEach((field) => {
      const value = this.state.selected[field].toString()

      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.id[field] = `${field}_${p.join('_')}`
      p.push(`${field}_${value.replace(/\s/g, '')}`)
    })
  }

  select = (key) => (e, next, disable = [], options) => {
    const value = e.target ? e.target.value : e
    const selected = {
      ...this.state.selected,
      [key]: value === null ? '' : value
    }

    disable.map((disabled) => {
      selected[disabled] = ''

      if (typeof this.props[`${disabled}s`] !== 'undefined') {
        this.props[`${disabled}s`].length = 0
      }
    })

    this.handleFields()
    this.setState({currentSelection: key})
    this.setState({selected}, () => {
      if (isFunction(next)) {
        if (options && options.fetchQuote && options.fetchQuote(value)) {
          this.getQuote()
        }

        next()
        this.updateCarData(selected)
      }
    })
  }

  updateCarData = (carData) => {
    Object.keys(omit(carData, ignoredFields)).forEach(async (key) => {
      if (
        this.state.currentSelection === 'transmission' &&
        getCountryConfig().fetchPriceQuoteFromAIP
      ) {
        if (key !== 'minPrice' && key !== 'maxPrice') {
          await this.props.onChange(carData[key], key)
        }
      } else {
        await this.props.onChange(carData[key], key)
      }
    })
  }

  isDisabled = (field) => {
    if (!this.state.disabledFieldDependencies) {
      return true
    }

    return !this.state.disabledFieldDependencies[field]
  }

  getfetchQuoteHandler = () => {
    return !this.isFieldRequired('location') ? this.getQuote : null
  }

  isVisible = (field) => fields.includes(field)

  getSelectedValues = (index) => {
    return this.props.trim.split('|')[index]
  }

  render() {
    const {
      makes,
      models,
      trims,
      years,
      transmissions,
      locations,
      Container,
      selectedLeadId,
      bodyTypes,
      make,
      model,
      year,
      trim,
      bodyType,
      faceLifts,
      seats,
      engines,
      wheelDrives,
      faceLift,
      seat,
      engine,
      wheelDrive
    } = this.props

    return (
      <Fragment>
        <Make
          visible={this.isVisible('make')}
          options={makes}
          onChange={this.select('make')}
          method={this.getCarData('model', ['make'])}
          make={make}
          id={this.state.id.make}
          disabled={this.isDisabled('make')}
          required={this.isRequired('make')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='make'
        />
        <Model
          visible={this.isVisible('model')}
          options={models}
          onChange={this.select('model')}
          method={this.getCarData('year', fieldDependencies.year)}
          model={model}
          id={this.state.id.model}
          disabled={this.isDisabled('model')}
          required={this.isRequired('model')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='model'
        />
        <Year
          visible={this.isVisible('year')}
          options={years}
          onChange={this.select('year')}
          method={this.getCarData('trim', fieldDependencies.trim)}
          year={year}
          id={this.state.id.year}
          disabled={this.isDisabled('year')}
          required={this.isRequired('year')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='year'
        />
        {/* Splitting Trim fields in 5 different dropdowns for turkey -  */}
        {/* - Trim(know as variant), faceLift, seat, engine, wheelDrive */}
        <Trim
          visible={this.isVisible('trim')}
          options={trims}
          onChange={this.select('trim')}
          method={
            getCountryConfig().countryCode === 'TR'
              ? () => this.getCarData('bodyType', fieldDependencies.bodyType)()
              : () =>
                  this.getCarData(
                    'transmission',
                    fieldDependencies.transmission
                  )()
          }
          trim={
            getCountryConfig().countryCode === 'TR'
              ? trim === '$'
                ? ''
                : trim
              : this.props.trim
          }
          id={this.state.id.trim}
          required={this.isRequired('trim')}
          disabled={this.isDisabled('trim')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='trim'
        />
        <BodyType
          visible={this.isVisible('bodyType')}
          options={bodyTypes}
          onChange={this.select('bodyType')}
          method={this.getCarData('faceLift', fieldDependencies.faceLift)}
          bodyType={bodyType === '$' ? '' : bodyType}
          id={this.state.id.bodyType}
          required={this.isRequired('bodyType')}
          disabled={this.isDisabled('bodyType')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='bodyType'
        />
        <FaceLift
          visible={this.isVisible('faceLift')}
          options={faceLifts}
          onChange={this.select('faceLift')}
          method={this.getCarData('seat', fieldDependencies.seat)}
          faceLift={faceLift === '$' ? '' : faceLift}
          id={this.state.id.faceLift}
          required={this.isRequired('faceLift')}
          disabled={this.isDisabled('faceLift')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='faceLift'
        />
        <Seat
          visible={this.isVisible('seat')}
          options={seats}
          onChange={this.select('seat')}
          method={this.getCarData('engine', fieldDependencies.engine)}
          seat={seat === '$' ? '' : seat}
          id={this.state.id.seat}
          required={this.isRequired('seat')}
          disabled={this.isDisabled('seat')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='seat'
        />
        <Engine
          visible={this.isVisible('engine')}
          options={engines}
          onChange={this.select('engine')}
          method={this.getCarData('wheelDrive', fieldDependencies.wheelDrive)}
          engine={engine === '$' ? '' : engine}
          id={this.state.id.engine}
          required={this.isRequired('engine')}
          disabled={this.isDisabled('engine')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='engine'
        />
        <WheelDrive
          visible={this.isVisible('wheelDrive')}
          options={wheelDrives}
          onChange={this.select('wheelDrive')}
          method={this.getCarData(
            'transmission',
            fieldDependencies.transmission
          )}
          wheelDrive={wheelDrive === '$' ? '' : wheelDrive}
          id={this.state.id.wheelDrive}
          required={this.isRequired('wheelDrive')}
          disabled={this.isDisabled('wheelDrive')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='wheelDrive'
        />

        <Transmission
          visible={this.isVisible('transmission')}
          options={transmissions}
          onChange={this.select('transmission')}
          transmission={
            this.props.transmission !== ''
              ? this.props.transmission
              : this.state.selected.transmission
          }
          method={
            getCountryConfig().locationNeededForAip
              ? getCountryConfig().fetchLocationFromCountryOptions
                ? this.complete
                : this.getCarData('location', fieldDependencies.location)
              : this.complete
          }
          id={this.state.id.transmission}
          required={this.isRequired('transmission')}
          disabled={this.isDisabled('transmission')}
          fetchQuote={this.getfetchQuoteHandler()}
          container={Container}
          data-test='transmission'
        />
        <Location
          visible={this.isVisible('location')}
          options={locations}
          onChange={this.select('location')}
          onBlur={this.select('location')}
          method={this.complete}
          location={this.state.selected.location}
          t={this.props.t}
          id={this.state.id.location}
          disabled={this.isDisabled('location')}
          required={this.isRequired('location')}
          container={Container}
          data-test='location'
        />
        <Mileage
          visible={this.isVisible('mileage')}
          onChange={this.select('mileage')}
          onBlur={this.select('mileage')}
          method={this.complete}
          mileage={this.state.selected.mileage}
          t={this.props.t}
          isRequired={this.isRequired}
          disabled={this.isDisabled('mileage')}
          required={this.isRequired('mileage')}
          container={Container}
          data-test='mileage'
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({config, walkin, crm, lead}) => ({
  makes: config.options.makes,
  models: config.options.models,
  trims: config.options.trims,
  years: config.options.years,
  transmissions: config.options.transmissions,
  quote: config.options.quote,
  lead: walkin.lead,
  // locations: config.options.location,
  locations: getCountryConfig().fetchLocationFromCountryOptions
    ? crm.options.locations
    : config.options.location,
  selectedLeadId: lead.form.id,
  bodyTypes: config.options.bodyTypes,
  faceLifts: config.options.faceLifts,
  seats: config.options.seats,
  engines: config.options.engines,
  wheelDrives: config.options.wheelDrives
})

const mapDispatchToProps = (dispatch) => ({
  getCarData: (args) => dispatch(getCarDataOptions({...args}))
})

const CarDataPickerField = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarDataPicker)

CarDataPickerField.type = 'CarDataPickerField'

export default CarDataPickerField
