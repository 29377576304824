import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'

const client = new ApiClient('auth')

export function getDrivers(params = {}) {
  return client.query('driver', params, fields.driverList)
}

export function createDriver(params = {}) {
  return client.mutation('createDriver', params, fields.driversFields)
}

export async function updateDriver(params) {
  return client.mutation('updateDriver', params, fields.driversFields)
}
