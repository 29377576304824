const handlers = {}

export const setErrorHandlingAction = (error, action) => {
  handlers[error.name] = action
}

export const ejectErrorHandlingAction = (error) => {
  delete handlers[error.name]
}

export const getHandler = (error) => {
  return handlers[error.constructor.name]
}
