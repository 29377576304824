import {createRef} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import Edit from './Edit'
import {ValidatedForm} from '@fcg/formvalidation'
import {default as selectors} from '../../store/countryOption/selectors'
import {
  hideCreateDialog,
  createCountryOption,
  updateCountryOptionField
} from '../../store/countryOption/actions'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}

const CountryOptionCreateDialog = (props) => {
  const {t, form, options, countryOptionType} = props
  const formCountryOptionRef = createRef()

  const handleSubmit = () => {
    const {createCountryOption, form} = props

    formCountryOptionRef.current.validate().then((success) => {
      if (success) {
        createCountryOption(form)
      }
    })
  }

  const handleInput = (e, name) => {
    props.updateCountryOptionField({
      name,
      value: e.target.value
    })
  }

  const handleCancel = () => {
    props.hideCreateDialog()
  }

  return (
    <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={handleCancel}>
      <ValidatedForm ref={formCountryOptionRef} t={t} onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>
          {t('countryOption.create.title', {type: countryOptionType})}
        </DialogTitle>
        <DialogContent>
          <Edit
            {...props}
            data={form}
            options={options}
            handleTextInput={handleInput}
          />
        </DialogContent>
        <DialogActions style={dialogActionsStyles}>
          <Button onClick={handleCancel} color='primary'>
            {t('button.cancel')}
          </Button>
          <Button variant='raised' type='submit' color='primary'>
            {t('global.action.create')}
          </Button>
        </DialogActions>
      </ValidatedForm>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
  form: selectors.getFormData(state),
  countryOptionType: selectors.getCountryOptionType(state)
})

const mapDispatchToProps = (dispatch) => ({
  hideCreateDialog: () => dispatch(hideCreateDialog()),
  createCountryOption: (payload) => dispatch(createCountryOption(payload)),
  updateCountryOptionField: (payload) =>
    dispatch(updateCountryOptionField(payload))
})

const CountryOptionCreateForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(CountryOptionCreateDialog))

export default CountryOptionCreateForm
