import {Component, Fragment} from 'react'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'

import GetAppIcon from '@material-ui/icons/GetApp'
import Delete from '@material-ui/icons/Delete'

import DateFormat from '../Format/DateFormat'
import Select from '../Select/Select'
import * as signalTypes from '../../store/signals/types'
import * as selectors from '../../store/documentConfig/selectors'

import PreviewModal from './PreviewModal'
import './document.styl'
import {isColumnShown} from './helper'

class Row extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileName: this.props.document.fileName,
      category: this.props.document.category,
      showPreview: false
    }
  }

  handleBlur = () => {
    if (this.state.fileName) {
      this.props.updateDocument({
        fileName: this.state.fileName,
        id: this.props.document.id,
        reference: this.props.reference
      })
    } else {
      this.setState({
        fileName: this.props.document.fileName
      })
      this.props.showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.document.fileName.error',
        open: true
      })
    }
  }

  handleNameChange = (e) => {
    this.setState({
      fileName: e.target.value
    })
  }

  handleFileTypeSelect = (value) => {
    this.setState(
      {
        category: value
      },
      () => {
        this.props.updateDocument({
          category: this.state.category,
          id: this.props.document.id
        })
      }
    )
  }

  onDownloadClick = (e) => {
    e.stopPropagation()
    this.props.downloadDocument(this.props.document.id)
  }

  onDeleteClick = (e) => {
    e.stopPropagation()
    this.props.deleteDocument(this.props.document.id)
  }

  handlePreviewOpen = () => {
    if (this.props.hasPreviewMode) {
      this.props.previewDocument(this.props.document.id)
      this.setState({
        showPreview: true
      })
    }
  }

  handleClose = () => {
    this.props.previewDocument()
    this.setState({
      showPreview: false
    })
  }

  renderFileTypeCol = () => {
    if (
      !this.props.dealerFileTypeOptions ||
      !this.props.dealerFileTypeOptions.length
    ) {
      return null
    }

    return (
      <div className='row__file-type'>
        <Select
          fullWidth
          fieldName='category'
          options={this.props.dealerFileTypeOptions}
          selected={this.state.category}
          onChange={this.handleFileTypeSelect}
        />
      </div>
    )
  }

  renderDeleteAction = () =>
    (typeof this.props.deleteDocumentEnabled === 'undefined' ||
      this.props.deleteDocumentEnabled) && (
      <a onClick={this.onDeleteClick} data-test='delete'>
        <Delete className='icon' />
      </a>
    )

  renderCustomColumns = () => {
    if (!this.props.customColumns || !this.props.customColumns.length) {
      return
    }

    return this.props.customColumns.map((column) => {
      const Element = column.cell
      const initialValue =
        column?.getInitialData(this.props.document.id) ??
        column?.defaultData ??
        null
      const hasError =
        this.props.documentErrors &&
        this.props.documentErrors[this.props.document.id]

      const RenderedElement = (
        <Element
          key={column.key}
          {...column.props}
          onBlur={(event) =>
            column.props.onBlur({event, documentId: this.props.document.id})
          }
          disabled={this.props.isFetching}
          placeholder={this.props.t(column.props.placeholder)}
          initialValue={initialValue}
          hasError={hasError}
        />
      )

      if (column.withWrapper) {
        return <span {...column.wrapperProps}>{RenderedElement}</span>
      }

      return RenderedElement
    })
  }

  render() {
    return (
      <Fragment>
        <div
          className='row'
          data-test={`upload-document-row=${this.props['data-test']}`}
          onClick={this.handlePreviewOpen}
        >
          <div className='row__file-name'>
            <input
              type='text'
              onChange={this.handleNameChange}
              value={this.state.fileName}
              onBlur={this.handleBlur}
              disabled={this.props.isFetching || this.props.hasPreviewMode}
              data-test='name'
            />
            <span data-test='file-type'>.{this.props.document.fileType}</span>
          </div>
          <span className='row__date' data-test='date'>
            <DateFormat data={this.props.document.createdAt} />
          </span>
          {isColumnShown(this.props, 'uploader') ? (
            <span className='row__uploader' data-test='uploader'>
              {this.props.document.createdByName}
            </span>
          ) : null}

          {this.renderCustomColumns()}
          {this.renderFileTypeCol()}
          <div className='row__icon-wrapper'>
            <a onClick={this.onDownloadClick} data-test='download'>
              <GetAppIcon className='icon' />
            </a>
            {this.renderDeleteAction()}
          </div>
        </div>
        {this.state.showPreview ? (
          <PreviewModal
            {...this.props.document}
            handleRotationChange={this.handleRotationChange}
            documentPreviewUrl={this.props.documentPreviewUrl}
            showPreview={this.state.showPreview}
            handleClose={this.handleClose}
            updateDocument={this.props.updateDocument}
            showSnackbarNotification={this.props.showSnackbarNotification}
          />
        ) : (
          ''
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  documentPreviewUrl: selectors.getDocumentPreviewUrl(state)
})

export default translate()(connect(mapStateToProps)(Row))
