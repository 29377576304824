import {withStyles} from '@material-ui/core'
import IconButton from '../../../Buttons/IconButton'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '450px',
    overflow: 'auto'
  },
  heading: {
    textAlign: 'center',
    padding: theme.spacing.unit,
    color: theme.palette.grey[500],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    flexGrow: 0,
    alignSelf: 'flex-start',
    width: '100%',
    textTransform: 'uppercase'
  },
  body: {
    flexGrow: 1,
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  action: {
    flexGrow: 0,
    padding: theme.spacing.unit,
    width: '100%',
    alignSelf: 'flex-end',
    textAlign: 'center'
  }
})

export const DropdownContent = ({classes, Heading, Body, Action}) => {
  return (
    <div className={classes.root}>
      {Heading}
      {Body}
      {Action}
    </div>
  )
}

const DropdownBodyBase = (props) => (
  <div className={props.classes.body}>{props.children}</div>
)
export const DropdownBody = withStyles(styles)(DropdownBodyBase)

const DropdownHeadingBase = (props) => (
  <div className={props.classes.heading}>
    <IconButton
      direction={props.direction || 'none'}
      label={props.heading}
      onClick={props.onNavigate}
    />
  </div>
)
export const DropdownHeading = withStyles(styles)(DropdownHeadingBase)

const DropdownActionBase = (props) => (
  <div className={props.classes.action}>{props.children}</div>
)
export const DropdownAction = withStyles(styles)(DropdownActionBase)

export default withStyles(styles)(DropdownContent)
