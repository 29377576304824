export const resourceName = 'config'

export const getConfig = `${resourceName}/GET_CONFIG`
export const languageChange = `${resourceName}/LANGUAGE_CHANGE`
export const options = `${resourceName}/OPTIONS`
export const locationOptions = `${resourceName}/OPTIONS_LOCATION`
export const leadSourceOptions = `${resourceName}/OPTIONS_LEAD_SOURCE`
export const carDataOptions = `${resourceName}/OPTIONS_CAR_DATA`
export const quote = `${resourceName}/QUOTE_CAR_DATA`
export const carDataComplete = `${resourceName}/OPTIONS_CAR_DATA_COMPLETE`
export const setCache = `${resourceName}/SET_CACHE`
export const cityList = `${resourceName}/OPTIONS_CITY`
export const userConfig = `${resourceName}/SET_USER_CONFIG`
export const location = `${resourceName}/LOCATION_CAR_DATA`
export const setCountryOptions = `${resourceName}/SET_COUNTRY_OPTIONS`
export const setPurchaseCoordinatorOptions = `${resourceName}/SET_PURCHASE_COORDINATOR_OPTIONS`
