import {useState, useCallback, useContext} from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {translate} from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import {withStyles} from '@material-ui/core/styles'

import TabContent from '../Tabs/TabContent'
import TabContentForm from '../Tabs/TabContentForm'
import {ConfigContext} from '../../../components/providers/withConfig'

const BPTabs = withStyles({
  flexContainer: {
    'justify-content': 'space-evenly'
  },
  indicator: {
    backgroundColor: '#00BCD4',
    color: '#00BCD4'
  },
  selected: {
    color: '#00BCD4'
  }
})(Tabs)
function DetailTabs({t, disableEditOrAdd, bpType}) {
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const handleTabClick = useCallback((_, newValue) => {
    setActiveTabIdx(newValue)
  }, [])

  const config = useContext(ConfigContext)

  return (
    <div className='bpDetailsTabs'>
      <AppBar position='static' color='default'>
        <BPTabs
          value={activeTabIdx}
          onChange={handleTabClick}
          variant='fullWidth'
        >
          {config.businessPartnersDetails.map(({id}) => {
            return (
              <Tab
                key={id}
                label={t(`businessPartners.${id}.text`)}
                data-test={`bp-${id}-tab`}
              />
            )
          })}
        </BPTabs>
      </AppBar>
      <div className='bpDetailsTabs__content'>
        {config.businessPartnersDetails.map((bpTabInfo, idx) => {
          if (bpTabInfo.contentType === 'form') {
            return (
              <TabContentForm
                key={bpTabInfo.id}
                tabInfo={bpTabInfo}
                hidden={activeTabIdx !== idx}
                disableEditOrAdd={disableEditOrAdd}
              />
            )
          }

          return (
            <TabContent
              key={bpTabInfo.id}
              tabInfo={bpTabInfo}
              hidden={activeTabIdx !== idx}
              disableEditOrAdd={disableEditOrAdd}
              bpType={bpType}
            />
          )
        })}
      </div>
    </div>
  )
}
DetailTabs.propTypes = {
  t: PropTypes.func.isRequired,
  disableEditOrAdd: PropTypes.bool
}
DetailTabs.defaultProps = {
  t: () => {}
}

export default translate()(DetailTabs)
