export const countryOptionConfigFields = {
  name: {
    key: 'name',
    label: 'countryOption.column.name',
    type: 'string',
    isFilterable: false,
    isSortable: true
  },
  createdAt: {
    key: 'createdAt',
    label: 'countryOption.column.createdAt',
    type: 'date',
    isFilterable: false
  },
  createdByName: {
    key: 'createdByName',
    label: 'countryOption.column.createdBy',
    type: 'string',
    isFilterable: false,
    isSortable: true
  }
}
