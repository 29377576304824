import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import cookies from 'browser-cookies'
import {withAuth} from 'react-oidc-context'

import {
  JUMPCLOUD_USER,
  JUMPCLOUD_ID_TOKEN,
  JUMPCLOUD_ACCESS_TOKEN
} from '../../appConstants'
import {authenticateJumpCloudToken} from '../../store/auth/actions'

class JumpCloudLoginCallback extends React.Component {
  componentDidUpdate(prevProps, _prevState) {
    const {
      auth: {isAuthenticated: prevIsAuthenticated}
    } = prevProps
    const {
      authenticateJumpCloudToken,
      auth: {isAuthenticated, user}
    } = this.props

    if (prevIsAuthenticated !== isAuthenticated) {
      cookies.set(JUMPCLOUD_ID_TOKEN, user?.id_token)
      cookies.set(JUMPCLOUD_ACCESS_TOKEN, user?.access_token)
      cookies.set(JUMPCLOUD_USER, user?.profile?.email)

      authenticateJumpCloudToken({
        accessToken: user?.access_token,
        idToken: user?.id_token,
        clientId: window?.jumpCloudConfig?.clientId
      })
    }
  }

  render() {
    const isAuthenticated = this.props.auth.isAuthenticated

    return (
      <div>{isAuthenticated ? 'Success. Redirecting...' : 'Signing in...'}</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated
  }
}

const mapDispatchToProps = (dispatch) => ({
  authenticateJumpCloudToken: (payload) =>
    dispatch(authenticateJumpCloudToken(payload))
})

export default compose(
  withAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(JumpCloudLoginCallback)
