import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Fade from '@material-ui/core/Fade'

import FormRow from '../../../../components/Form/FormRow'
import RowDescription from '../../../../components/Form/RowDescription'
import {MainContext} from '../../../../context/MainContext'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 2,
    height: 24
  }
}))

interface HPCitiesProps {
  applyToAllCities: boolean
  appliedCities: null | string[]
  onChange: (field: string, value: boolean|string) => void
}

const HolidayPickerCities = ({applyToAllCities=true, appliedCities=[], onChange}:HPCitiesProps) => {
  const classes = useStyles()
  const {cities} = useContext(MainContext)

  return (
    <FormRow>
      <RowDescription title='Cities' />
      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Switch
              checked={applyToAllCities}
              onChange={(event) => {
                onChange('applyToAllCities', event.target.checked)
              }}
              value='applyToAllCities'
              color='primary'
            />
          }
          label='Apply to all cities'
        />
        <Fade in={!applyToAllCities}>
          <FormControl className={classes.formControl}>
            <InputLabel id='city-select-label'>Cities</InputLabel>
            <Select
              labelId='city-select-label'
              id='city-select'
              multiple
              value={appliedCities||[]}
              onChange={(event) => {
                onChange('cities', event.target.value)
              }}
              input={<Input id='city-select-input' />}
              renderValue={(selected:string[]) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {cities.map((city:string) => {
                return (
                  <MenuItem key={city} value={city}>
                    <Checkbox
                      checked={(appliedCities||[]).indexOf(city) > -1}
                      color='primary'
                    />
                    <ListItemText primary={city} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Fade>
      </Grid>
    </FormRow>
  )
}

export default HolidayPickerCities
