import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {withStyles} from '@material-ui/core/styles'

const styles = () => ({
  root: {
    margin: 0,
    padding: 20
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#9e9e9e'
  }
})

const DialogTitleWithClose = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props

  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

export default DialogTitleWithClose
