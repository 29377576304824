export const warnBeforeReloadInit = (t) => (event) => {
  event.preventDefault()

  return t('calendar.navigation.leave')
}

export const removeReloadEventHandlers = () => {
  window.onbeforeunload = null
}

export const onBeforeReloadListener = (warnBeforeReload) => {
  window.onbeforeunload = warnBeforeReload
}
