import * as types from './types'
import * as global from '../../global/actions'
import {parseLeadInput} from '../lead/actions'
import * as ContactApi from '../../../api/contact/requests'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import get from 'lodash/get'
import {getCountryCode} from '../../config/selectors'

export const createWalkin = (walkin) => (dispatch, getState) => {
  const {contact, lead, appointment} = walkin
  const country = getCountryCode(getState())
  const purchaseCoordinatorId = get(appointment, 'purchaseCoordinatorId')
  const appointmentType = appointment?.type
  
  // QUICK HACK TO FIX SERVICE TYPE MAPPING FROM PURCHASE HOME INSPECTION
  // TODO: CREATE MAPPING BETWEEN APPOINTMENT TYPE TO SERVICE TYPE
  let serviceType = 'INSPECTIONCRM'
  if (appointmentType == 'purchaseInspectionHome') {
    serviceType = 'INSPECTIONHOMECRM'
  }

  if (purchaseCoordinatorId) {
    appointment.purchaseCoordinator = purchaseCoordinatorId
  }

  const params = {
    ...contact,
    ...parseLeadInput(omit(lead, ['quote', 'leadStatus'])),
    ...omit(appointment, [
      'status',
      'purchaseCoordinatorId',
      'purchaseCoordinatorPhone'
    ]),
    country,
    status: appointment.status === '' ? lead.leadStatus : appointment.status
  }

  dispatch(setFormPending())

  return ContactApi.createWalkin({
    ...merge(omitBy(params, isEmpty), pickBy(params, isNumber)),
    service: serviceType
  })
    .then(({createCarLeadCallback}) => {
      dispatch(setFormSuccess(createCarLeadCallback.contactId))
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(global.apiError(e))
      dispatch(global.error({message: `error.walkin.create`}))
    })
}

export const setFormError = () => ({
  type: types.formError,
  payload: true
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})

export const setFormSuccess = (payload) => ({
  type: types.formSuccess,
  payload
})

export const updateWalkinForm = (entity, input) => ({
  type: types.updateForm,
  payload: {entity, ...input}
})

export const resetWalkinForm = (payload) => ({
  type: types.resetForm,
  payload
})
