import * as types from './types'
import {ticketStatusesRequest} from '../../../config/pages/payslips/list/payslipConfig'
import get from 'lodash/get'

export const fetchPayslipsStart = () => ({
  type: types.fetchPayslipsStart
})
export const fetchPayslips = ({page, limit}) => ({
  type: types.fetchPayslips,
  payload: {page, limit}
})

export const fetchCurrentPage = () => ({
  type: types.fetchCurrentPage
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const updateFilters = (payload) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload
  })
  dispatch(fetchPayslips({}))
}

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const addCarPaymentSelection = (payload) => ({
  type: types.addCarPaymentSelection,
  payload
})

export const updateTicketEntity = (payload) => ({
  type: types.updateTicketEntity,
  payload
})

export const approveTicket = (data) => ({
  type: types.approveTicket,
  payload: data
})

export const updateTicketStart = (data) => ({
  type: types.updateTicketStart,
  payload: data
})

export const updateTicket = (data) => ({
  type: types.updateTicket,
  payload: data
})

export const updateTicketSuccess = (payload) => ({
  type: types.updateTicketSuccess,
  payload
})

export const updateTicketError = (error) => ({
  type: types.updateTicketError,
  payload: error
})

export const approvePayment = ({ticketId, summaryData, rowData}) => (
  dispatch
) =>
  dispatch(
    approveTicket({
      params: {
        id: ticketId,
        status: ticketStatusesRequest.APPROVED
      },
      additionalData: {
        summaryData,
        rowData,
        subTableData: get(rowData, 'subtableData.data', [])
      }
    })
  )

export const fetchPayslipOptions = () => ({
  type: types.fetchPayslipOptions
})

export const setPayslipOptions = (options) => ({
  type: types.setPayslipOptions,
  payload: options
})
export const rejectPayslip = (payload) => ({
  type: types.rejectPayslip,
  payload
})
export const selectPayslip = (id) => ({
  type: types.selectPayslip,
  payload: id
})

export const toggleRow = (payload) => ({
  type: types.toggleRow,
  payload
})
export const toggleRowSelection = (payload) => ({
  type: types.toggleRowSelection,
  payload
})
export const fetchRowData = (payload) => ({
  type: types.fetchRowData,
  payload
})
export const fetchRowDataSuccess = (payload) => ({
  type: types.fetchRowDataSuccess,
  payload
})
export const fetchRowDataError = (payload) => ({
  type: types.fetchRowDataError,
  payload
})
export const onMedaInfoChange = (payload) => ({
  type: types.metaInfoChange,
  payload
})
export const persistMetaInfoChange = (payload) => ({
  type: types.persistMetaInfoChange,
  payload
})
