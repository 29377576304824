export const inspectionApiUrl = '/api/inspection'
export const authApiUrl = '/api/auth'
export const carApiUrl = '/api/car'
export const carDataApiUrl = '/api/cardata'
export const imageApiUrl = '/api/image'
export const auctionApiUrl = '/api/auction'
export const contactApiUrl = '/api/contact'
export const documentApiUrl = 'api/document'
export const bookingApiUrl = '/api/booking'
export const calendarApiUrl = '/api/calendar'
export const webhooksApiUrl = '/api/webhooks'
export const ticketApiUrl = '/api/ticket'
export const ticketsApiUrl = '/api/tickets'
export const orderApiUrl = '/api/order'
export const searchApiUrl = '/api/search'
export const rcmApiUrl = '/api/rcm'
export const storeApiUrl = '/api/store'
export const reservationsApiUrl = '/api/reservations'
