import {default as main} from './config/main'
import {default as mainContact} from './config/mainContact'
import {default as account} from './config/account'
import {default as decision} from './config/decision'
import {default as responsibles} from './config/responsibles'
import {default as legalAndAddress} from './config/legalAndAddress'
import {default as bankAndPayment} from './config/bankAndPayment'
import {default as carPreferencesAndProfiling} from './config/carPreferencesAndProfiling'
import {default as comments} from './config/comments'

export default {
  config: [
    main,
    mainContact,
    account,
    decision,
    responsibles,
    legalAndAddress,
    bankAndPayment,
    carPreferencesAndProfiling,
    comments
  ]
}
