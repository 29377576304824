import moment from 'moment'
import {compose, find, propEq, applySpec, path, join, map, filter} from 'ramda'
import {TICKET_ENTITY_TYPES} from '../common/helpers'
import {getSummaryRowData} from '../create/selectors'

export const filterDocuments = propEq(
  'entityType',
  TICKET_ENTITY_TYPES.DOCUMENT
)
export const filterCars = propEq('entityType', TICKET_ENTITY_TYPES.CAR)

export const getCarReceiptsFromEntities = (entities) =>
  entities.filter(filterCars)

export const getPaymentReceiptsFromEntities = (entities) =>
  entities.filter(filterDocuments).map(mapDocuments)

export const calculateAmount = (entities) =>
  entities.reduce(
    (acc, entity) =>
      acc +
      (entity.entityType === TICKET_ENTITY_TYPES.CAR &&
      entity.metaInfo &&
      !isNaN(entity.metaInfo.amount)
        ? entity.metaInfo.amount
        : 0),
    0
  )

export const calculateOutstandingAmountForCarReceipts = (entities) =>
  entities.reduce(
    (acc, entity) =>
      acc +
      (entity.entityType === TICKET_ENTITY_TYPES.CAR &&
      entity.data &&
      entity.data.financialDetails &&
      entity.data.financialDetails.outstandingAmount &&
      !isNaN(entity.data.financialDetails.outstandingAmount)
        ? entity.data.financialDetails.outstandingAmount
        : 0),
    0
  )

const extractMetaInfoDate = (metaInfo) =>
  metaInfo && metaInfo.date
    ? moment(new Date(metaInfo.date * 1000)).format('YYYY-MM-DD')
    : null

export const mapDocuments = (document) => {
  const {data, metaInfo, id, ticketId} = document

  if (!data) {
    return {}
  }

  return {
    id: data.id,
    src: data.link,
    fileType: data.fileType,
    fileName: data.fileName,
    metaInfo: {
      ...metaInfo,
      date: extractMetaInfoDate(metaInfo)
    },
    ticketEntityId: id,
    ticketId: ticketId
  }
}

export const getName = (data) =>
  data && data.firstname && data.lastname
    ? `${data.firstname} ${data.lastname}`
    : ''

export const extractDealerData = compose(
  applySpec({
    fullName: getName,
    dealerId: path(['internalId']),
    companyName: path(['metaInfo', 'companyName']),
    legalCompanyName: path(['metaInfo', 'legalCompanyName']),
    taxId: path(['metaInfo', 'taxId']),
    phone: path(['metaInfo', 'phone']),
    dealerManager: compose(getName, path(['dealerManager']))
  }),
  path(['data']),
  find(propEq('entityType', TICKET_ENTITY_TYPES.USER))
)

export const extractCarData = compose(
  applySpec({
    carInternalId: compose(join('\n'), map(path(['data', 'internalId']))),
    carLicencePlate: compose(join('\n'), map(path(['data', 'licensePlate'])))
  }),
  filter(propEq('entityType', TICKET_ENTITY_TYPES.CAR))
)

export const mapCarEntities = ({data, metaInfo, entityId, id, ticketId}) => {
  const hasFinancialDetails = data && data.financialDetails

  return {
    car: entityId,
    internalId: data ? data.internalId : '',
    make: data ? data.make : '',
    model: data ? data.model : '',
    year: data ? data.year : '',
    type: metaInfo?.type,
    amount: metaInfo?.amount,
    category: metaInfo?.category ?? 'Payslip',
    outstanding: hasFinancialDetails
      ? hasFinancialDetails.outstandingAmount
      : 0,
    financialDetails: hasFinancialDetails ? data.financialDetails : {},
    ticketId,
    id
  }
}

export const filterFinancialDetails = ({financialDetails}) => financialDetails
export const getPayslipPaymentData = (ticketEntities) =>
  getCarReceiptsFromEntities(ticketEntities)
    .map(mapCarEntities)
    .filter(filterFinancialDetails)

export const getAllSubtableData = (ticketEntity, subtableSummaryFields) => {
  const payslipPaymentData = getPayslipPaymentData(ticketEntity)

  return {
    data: payslipPaymentData,
    documentList: getPaymentReceiptsFromEntities(ticketEntity),
    subtableSummaryData: getSummaryRowData(
      payslipPaymentData,
      subtableSummaryFields
    )
  }
}

export const transformPayslipList = (data, subtableSummaryFields) =>
  data.map(({status, ticketEntity, createdAt, id}) => ({
    id,
    ...extractDealerData(ticketEntity),
    ...extractCarData(ticketEntity),
    status,
    createdAt,
    amount: calculateAmount(ticketEntity),
    totalOutstandingAmount: calculateOutstandingAmountForCarReceipts(
      ticketEntity
    ),
    subtableData: getAllSubtableData(ticketEntity, subtableSummaryFields)
  }))
