import gql from '@fcg/lib-gql'
export const TICKET_ENTITY_TYPES = {
  CAR: 'CAR',
  USER: 'USER',
  DOCUMENT: 'DOCUMENT'
}
export const TICKET_TYPES = {
  PAYMENT_PROOF: new gql.EnumType('PAYMENT_PROOF'),
  CAR_CLAIM: new gql.EnumType('CAR_CLAIM'),
  USER_CLAIM: new gql.EnumType('USER_CLAIM')
}
export const TICKET_ENTITY_REQUEST_PROP_TYPES = {
  CAR: new gql.EnumType(TICKET_ENTITY_TYPES.CAR),
  DOCUMENT: new gql.EnumType(TICKET_ENTITY_TYPES.DOCUMENT),
  USER: new gql.EnumType(TICKET_ENTITY_TYPES.USER)
}
export const PAYMENT_TYPES = {
  PAYMENT_PROOF: 'PAYMENT_PROOF'
}

export const getErrorMessage = (error) => {
  let errorMessage = ''

  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.reduce(
      (acc, tally) => `${acc}\n${tally.message}`,
      ''
    )
  }

  return errorMessage || error.message
}
