import isNil from 'lodash/isNil'

export const cleanInputData = (obj) => {
  const res = {...obj}

  Object.keys(res).forEach((key) => {
    if (res[key] && typeof res[key] === 'object') {
      res[key] = cleanInputData(res[key])
    } else if (isNil(res[key])) {
      delete res[key]
    }
  })

  return res
}

export const getDifference = (history, updates) => {
  if (typeof history !== typeof updates) {
    return updates
  }
  const diff = {}

  Object.keys(updates).map((key) => {
    if (
      typeof history[key] !== 'undefined' &&
      history[key] !== '' &&
      history[key] === updates[key]
    ) {
      return
    }

    diff[key] = updates[key]
  })

  return Object.keys(diff).length > 0 ? diff : null
}

export const getValueOrDefault = (val, defaultVal = '') =>
  isNil(val) === true ? defaultVal : val
