import {
  List,
  ListItem,
  ListItemText,
  withStyles,
  IconButton
} from '@material-ui/core'
import {Delete} from '@material-ui/icons'

const listItemStyle = (theme) => ({
  selected: {
    backgroundColor: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: theme.palette.grey[500]
    }
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
})

const GroupListItem = withStyles(listItemStyle)(
  ({name, onClick, selected, classes, onDeleteClick}) => (
    <ListItem
      button
      onClick={onClick}
      className={selected ? classes.selected : ''}
    >
      <ListItemText
        primary={name}
        classes={{primary: classes.text}}
      ></ListItemText>
      <IconButton onClick={onDeleteClick}>
        <Delete />
      </IconButton>
    </ListItem>
  )
)

// eslint-disable-next-line no-unused-vars
const listStyle = (theme) => ({
  list: {
    margin: '0 16px',
    height: '85vh',
    overflowX: 'hidden',
    overflowY: 'scroll'
  }
})

export const GroupList = withStyles(listStyle)(
  ({items, onClick, selected, classes, onDeleteClick}) => (
    <List className={classes.list}>
      {items.map((item) => (
        <GroupListItem
          key={`GroupListItem_${item.id}`}
          name={item.name}
          onClick={() => onClick(item.id)}
          onDeleteClick={(e) => onDeleteClick(item.id, e)}
          selected={item.id === selected}
        />
      ))}
    </List>
  )
)
