import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'

import SectionWrapper from '../../../components/Sections/SectionWrapper'
import SectionTitle from '../../../components/Sections/SectionTitle'
import TextFieldWithErrorMessage from '../../../components/TextField/TextFieldWithErrorMessage'
import SelectField from '../../../components/Select/SelectField'
import MultiSelectField from '../../../components/Select/MultiSelectField'
import Map from '../../../components/Map'
import SaveButton from './components/SaveButton'
import StatusToggleSwitch from './components/StatusToggleSwitch'
import RemindSaveDialog from './components/RemindSaveDialog'

import {MainContext} from '../../../context/MainContext'
import {GlobalContext} from '../../../context/GlobalContext'

import useValidation from '../../../hooks/useValidation/useValidation'
import useConfigurationActions from './useConfigurationActions'

import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import {
  formLocationState,
  setOrGetTimezoneOptions,
  validationSchema
} from '../../../hooks/useValidation/validationHelpers'
import {loadScript, getRegions} from '../../../config/utils'
import {COUNTRY_CONFIG} from '../../../config/countryConfig'
import User from '../../../types/User'
import {getCountryConfig} from '../../../../../app/config/country'
import clone from 'lodash/clone'

const titleContStyle = {
  height: '48px',
  display: 'flex',
  alignItems: 'flex-end'
}

const TitleContainer = ({title}: {title: string}) => (
  <div style={titleContStyle}>
    <SectionTitle title={title} />
  </div>
)

const validationSchemaKeys = ['sapStorageLocationId', 'sapPlantId']

const LocationConfiguration = ({isNewLocation}: {isNewLocation: boolean}) => {
  const countryConfig = getCountryConfig()
  const classes = useCommonStyles()
  const {onSave, toggleLocationStatus} = useConfigurationActions({
    isNewLocation: isNewLocation
  })
  const {
    cities,
    selectedLocation,
    countryCode,
    locationList,
    locationTypes,
    areaManagers
  } = useContext(MainContext)
  const regions = getRegions(locationList)
  const {globalPlaceConfiguration} = useContext(GlobalContext)
  const [mapScriptLoaded, setMapScriptLoaded] = useState(false)
  const mapKey = COUNTRY_CONFIG.mapApiKey[countryCode]
  const validationSchemaRef = useRef(clone(validationSchema))

  useEffect(() => {
    if (typeof window !== 'undefined' && !mapScriptLoaded) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`,
          'google-maps'
        ).then(() => {
          setMapScriptLoaded(true)
        })
      }
    }
  })

  const initialState = formLocationState(
    selectedLocation as any,
    globalPlaceConfiguration as any,
    countryCode
  )
  const {state, disable, onChange, handleOnSubmit} = useValidation(
    initialState,
    onSave,
    validationSchemaRef.current as any
  )

  const {
    name,
    address,
    city,
    region,
    email,
    phone,
    image,
    metaTitle,
    metaDescription,
    bIMapping,
    lat,
    lng,
    type,
    timezone,
    optionalText,
    mapUrl,
    areaManager,
    sapPlantId,
    sapStorageLocationId
  } = state

  const [isPlantAndStorageIDRequired, setPlantAndStorageIDRequired] = useState(
    () => {
      validationSchemaKeys.forEach((key: string) => {
        const validationSchema: any = validationSchemaRef.current
        if (validationSchema[key]) {
          validationSchema[
            key
          ].required = !!countryConfig.isPlantAndStorageIdRequired
        }
      })

      const locationTypes =
        countryConfig.plantAndStorageIdNoRequiredForLocationTypes
      if (
        locationTypes?.length &&
        type?.value?.length &&
        type?.value?.every((key: string) => locationTypes.includes(key))
      ) {
        validationSchemaKeys.forEach((key: string) => {
          const validationSchema: any = validationSchemaRef.current
          if (validationSchema[key]) {
            validationSchema[key].required = false
          }
        })
        return false
      }

      return !!countryConfig.isPlantAndStorageIdRequired
    }
  )

  const returnAreaManagerField = () => {
    const manager = areaManagers.find((user) => user.id === areaManager.value)

    return (
      <FormControl className={cx(classes.fullWidth, classes.marginBottom2)}>
        <InputLabel id='areaManager'>Area manager</InputLabel>
        <Select
          labelId='areaManager'
          fullWidth
          value={manager}
          onChange={(event) => {
            const value: User = event?.target.value
            onChangeHandler('areaManager', value.id)
            onChangeHandler(
              'areaManagerName',
              `${value.firstname} ${value.lastname}`
            )
          }}
          input={<Input />}
          renderValue={(selected: User) =>
            `${selected.firstname} ${selected.lastname}`
          }
          disabled={areaManagers.length === 0}
        >
          {areaManagers.map((option: User) => (
            <MenuItem key={option.id} value={option}>
              <ListItemText
                primary={`${option.firstname} ${option.lastname}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const onChangeHandler = useCallback(
    (key, value) => {
      const locationTypes =
        countryConfig.plantAndStorageIdNoRequiredForLocationTypes
      if (key === 'type' && locationTypes?.length) {
        const isRequired = !(
          value?.length &&
          value.every((key: string) => locationTypes.includes(key))
        )
        setPlantAndStorageIDRequired(isRequired)
        validationSchemaKeys.forEach((key: string) => {
          const validationSchema: any = validationSchemaRef.current
          if (validationSchema[key]) {
            validationSchema[key].required = isRequired
          }
        })
        onChange('sapPlantId', sapPlantId.value)
        onChange('sapStorageLocationId', sapStorageLocationId.value)
      }

      onChange(key, value)
    },
    [onChange, sapPlantId, sapStorageLocationId]
  )

  return (
    <SectionWrapper twoColumn title='General Information' title2='Location'>
      <Grid container>
        <form
          noValidate
          autoComplete='off'
          className={cx(classes.fullWidth, {
            [classes.marginBottom4]: !isNewLocation
          })}
        >
          <Grid container justify='center' spacing={2}>
            <Grid item xs={6}>
              <TextFieldWithErrorMessage
                value={name.value}
                required
                dataTest='location-name-field'
                label='Location Name'
                error={name.error}
                onChange={(value) => {
                  onChangeHandler('name', value)
                }}
              />
              <TextFieldWithErrorMessage
                value={address.value}
                label='Address'
                error={address.error}
                onChange={(value) => {
                  onChangeHandler('address', value)
                }}
                dataTest='address-field'
              />
              <TextFieldWithErrorMessage
                value={optionalText.value}
                label='Additional address information'
                onChange={(value) => {
                  onChangeHandler('optionalText', value)
                }}
              />
              <Grid
                container
                className={cx({
                  [classes.marginBottom2]: !city.error || city.error === ''
                })}
              >
                <Grid item xs={6} className={classes.paddingRight1}>
                  <SelectField
                    title='City'
                    options={cities}
                    value={city.value || ''}
                    error={city.error}
                    onChange={(value) => {
                      onChangeHandler('city', value)
                    }}
                  ></SelectField>
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft1}>
                  <SelectField
                    title='Region'
                    options={regions}
                    value={region.value}
                    onChange={(value) => {
                      onChangeHandler('region', value)
                    }}
                  ></SelectField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} className={classes.paddingRight1}>
                  <TextFieldWithErrorMessage
                    value={email.value}
                    error={email.error}
                    label='Email address'
                    type='email'
                    onChange={(value) => {
                      onChangeHandler('email', value)
                    }}
                    dataTest='email-field'
                  />
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft1}>
                  <TextFieldWithErrorMessage
                    value={phone.value}
                    error={phone.error}
                    label='Phone number'
                    onChange={(value) => {
                      onChangeHandler('phone', value)
                    }}
                    dataTest='phone-field'
                  />
                </Grid>
              </Grid>
              <TitleContainer title='Functional Information' />
              <div style={{marginTop: '-10px'}}>
                <Typography
                  variant='caption'
                  gutterBottom
                  align='left'
                  className={classes.colorInactive}
                >
                  Location-specific information to define functions within the
                  location.
                </Typography>
              </div>
              {COUNTRY_CONFIG.showAreaManagerField[countryCode] &&
                returnAreaManagerField()}
              <Grid container>
                <Grid item xs={6} className={classes.paddingRight1}>
                  <SelectField
                    required
                    title='Timezone'
                    options={setOrGetTimezoneOptions(countryCode)}
                    value={timezone.value}
                    error={timezone.error}
                    onChange={(value) => {
                      onChangeHandler('timezone', value)
                    }}
                    dataTest='timezone'
                  ></SelectField>
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft1}>
                  <TextFieldWithErrorMessage
                    value={bIMapping.value}
                    error={bIMapping.error}
                    disabled={!!selectedLocation!.bIMapping}
                    label='BI mapping'
                    onChange={(value) => {
                      onChangeHandler('bIMapping', value.toUpperCase())
                    }}
                    inputProps={{maxLength: 7}}
                    placeholder={`X${countryCode}000`}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <TextFieldWithErrorMessage
                    required={isPlantAndStorageIDRequired}
                    value={sapPlantId.value}
                    error={sapPlantId.error}
                    label='SAP Plant ID'
                    onChange={(value) => {
                      onChangeHandler('sapPlantId', value)
                    }}
                    inputProps={{maxLength: 4}}
                    placeholder='SAP Plant ID'
                  />
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft1}>
                  <TextFieldWithErrorMessage
                    required={isPlantAndStorageIDRequired}
                    value={sapStorageLocationId.value}
                    error={sapStorageLocationId.error}
                    label='SAP Storage Location ID'
                    onChange={(value) => {
                      onChangeHandler('sapStorageLocationId', value)
                    }}
                    inputProps={{maxLength: 4}}
                    placeholder='SAP Storage Location ID'
                  />
                </Grid>
              </Grid>
              <MultiSelectField
                title='Location types'
                value={type.value}
                onChange={(value) => {
                  onChangeHandler('type', value)
                }}
                options={locationTypes}
                optionsLowerCase
              />
            </Grid>
            <Grid item xs={6}>
              <div className={classes.marginTop_1}>
                <Typography
                  variant='caption'
                  gutterBottom
                  align='left'
                  className={classes.colorInactive}
                >
                  Location-specific information to define functions within the
                  location.
                </Typography>
                <Map lat={lat.value} lng={lng.value} onChange={onChange} />
                <TextFieldWithErrorMessage
                  value={mapUrl.value}
                  error={mapUrl.error}
                  className={classes.marginTop2}
                  label='Google maps url for exponea emails'
                  onChange={(value) => {
                    onChangeHandler('mapUrl', value)
                  }}
                />
                <TitleContainer title='Location Image' />
                <TextFieldWithErrorMessage
                  value={image.value}
                  error={image.error}
                  label='Image source'
                  onChange={(value) => {
                    onChangeHandler('image', value)
                  }}
                  helperText='Recommended size: —. Maximum size: 1MB in .jpg, .jpeg or .png.'
                />
                <TitleContainer title='Search Engine Optimization' />
                <TextFieldWithErrorMessage
                  value={metaTitle.value}
                  error={metaTitle.error}
                  label='Meta title'
                  onChange={(value) => {
                    onChangeHandler('metaTitle', value)
                  }}
                />
                <TextFieldWithErrorMessage
                  value={metaDescription.value}
                  error={metaDescription.error}
                  label='Meta description'
                  multiline
                  onChange={(value) => {
                    onChangeHandler('metaDescription', value)
                  }}
                />
                <Grid
                  item
                  xs={12}
                  className={cx(classes.absolute, {
                    [classes.rightBottom]: !isNewLocation,
                    [classes.rightBottomOut]: isNewLocation
                  })}
                >
                  <SaveButton
                    onSubmit={handleOnSubmit}
                    disabled={disable}
                    dataTest={'btn-save-location'}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {!isNewLocation && (
        /* Activate/Deactivate Switch for locations */
        <StatusToggleSwitch
          status={selectedLocation!.active}
          onLocationStatusChange={toggleLocationStatus}
        />
      )}
      {/* For now `RemindSaveDialog` is both here and `GlobalPlaceConfiguration` for consistency,
      but it can also be carried parent component and directly wrapped with `withActions` */}
      <RemindSaveDialog onSave={handleOnSubmit} />
    </SectionWrapper>
  )
}

export default LocationConfiguration
