import PropTypes from 'prop-types'
import * as filters from './index'

const FilterTypes = {
  array: filters.FilterString,
  boolean: filters.FilterToggle,
  currency: filters.FilterRange,
  date: filters.FilterDateRange,
  time: filters.FilterRange,
  number: filters.FilterRange,
  string: filters.FilterString,
  email: filters.FilterString,
  link: filters.FilterString,
  composite: filters.FilterString,
  select: filters.FilterSelect
}

const Filter = (props) => {
  const Filter =
    typeof props.filter === 'undefined' ? FilterTypes[props.type] : props.filter

  const filterProps = {...props}

  if (filterProps.type === 'date' && filterProps.value === '') {
    delete filterProps.value
  }

  return (
    <div className='FilterBody'>
      <Filter {...filterProps} />
    </div>
  )
}

Filter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object
  ]),
  type: PropTypes.oneOf([
    'array',
    'link',
    'composite',
    'boolean',
    'currency',
    'number',
    'date',
    'select',
    'string',
    'email',
    'time'
  ])
}

Filter.defaultProps = {value: '', type: 'string', handleChange: () => {}}

export default Filter
