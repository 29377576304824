import {connect} from 'react-redux'
import {NumberInput} from '@fcg/form-builder'
import {translate} from 'react-i18next'

import {CAR_TYPE} from './constants'
import Select from '../../components/Select/Select'
import withConfig from '../../components/providers/withConfig'
import filter from '../../components/RichTable/FilterBar/Filter/wrapFilter'

const FilterBase = (type) => (props) => (
  <Select
    {...props}
    id={props.fieldName}
    label={props.filterLabel || props.label}
    selected={props.value}
    onChange={props.handleChange(type || props.fieldName)}
  />
)

const InputFilter = withConfig(['number'], (props) => {
  const {number} = props
  const extractValue = (name) => (value) => props.handleChange(name)(value)

  return (
    <NumberInput
      config={{number}}
      label={props.t(props.label)}
      id={props.fieldName}
      value={props.value}
      onChange={extractValue(props.fieldName)}
    />
  )
})

const getType = () => {
  return {
    options: CAR_TYPE
  }
}

export const SellableFilter = connect(getType)(filter(FilterBase('sellable')))
export const NumberInputFilter = translate()(filter(InputFilter))
