import DocumentConfigList from '../../../pages/DocumentConfig/List'
import {default as documentConfigFields} from './documentConfigFields'

export default {
  path: 'document-config',
  label: 'menu.documentConfig',
  key: 'documentConfig',
  sort: [{field: 'name', order: 'DESC'}],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...documentConfigFields},
  hiddenFields: [],
  component: DocumentConfigList
}
