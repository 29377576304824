import {getPhoneLink, getNestedProp, setNestedProp} from './common/helpers'
import {userPermissions} from '../../store/auth/selectors'
import {getPhoneConfiguration} from '../../store/config/selectors'
import {getCountryConfig} from '../../../config/country'
import {rowStyleFactory} from '@fcg/tars'
import TableRow from '@material-ui/core/TableRow'
import styled from 'styled-components'

export const addDataToEveryItemWith = (newData, modifier) => (array) =>
  array.map((item) => ({...modifier(item, newData)}))

export const addPhoneLink = (existing, {link, email}) => ({
  ...existing,
  canLink: true,
  phoneLink: getPhoneLink(link)(email, existing.contact.phone)
})

export const convertLeadToInspectionQuery = (lead) => {
  const {
    make,
    model,
    trim,
    year,
    transmission,
    phone,
    email,
    price,
    mileage,
    leadSource: leadsource,
    country
  } = lead

  const inspectionData = {
    country,
    make,
    model,
    trim,
    transmission,
    year,
    phone,
    email,
    price,
    mileage,
    leadsource,
    inspectionType: 'selfInspection'
  }

  const returnValue = Object.keys(inspectionData)
    .filter(
      (key) =>
        typeof inspectionData[key] !== 'undefined' &&
        inspectionData[key] !== null &&
        inspectionData[key] !== ''
    )
    .map((key) => `${key}=${inspectionData[key]}`)
    .join('&')

  return encodeURIComponent(returnValue)
}

export const isLoggedInUserDialerAgent = (state) => {
  const assignedPermissions =
    userPermissions(state, {
      entity: 'web.admin.ui.crm.dialer',
      types: ['READ']
    }) || {}

  return (
    !assignedPermissions.userGroups.includes('superadmin') &&
    (assignedPermissions.permissions || [])
      .map((item) => item.entity)
      .includes('web.admin.ui.crm.dialer')
  )
}

export const filterByMetaInfo = (data, selectedValue) => {
  const filtered = data.reduce((acc, item) => {
    if (item.metaInfo !== null && item.metaInfo.dependent !== null) {
      if (item.metaInfo.dependent.includes(selectedValue)) {
        acc.push(item)
      }
    }

    return acc
  }, [])

  return filtered
}

export const filterPhone = (filters, state) => {
  const result = {filters}
  const config = getPhoneConfiguration(state)

  if (config) {
    let {length, code: codePrefix, phoneMaxLength} = config
    for (let item of filters) {
      if (
        item.key === 'phone' ||
        item.key === 'contact.phone' ||
        item.key === 'lead.contact.phone'
      ) {
        let phone = item.value
        if (phone) {
          let phoneLength = phone.length
          const ifphoneMaxLength = phoneMaxLength ? phoneMaxLength : length
          const finalMinLength = length + codePrefix.length
          const finalMaxLength = ifphoneMaxLength + codePrefix.length
          let finalLength = 0
          const isPrefixIncluded = phone.startsWith(codePrefix)
          if (
            !isPrefixIncluded &&
            phoneLength > length + 1 &&
            phone.startsWith('00')
          ) {
            phone = phone.slice(2)
            phoneLength = phone.length
          }
          if (
            isPrefixIncluded &&
            phoneLength <= finalMaxLength &&
            phoneLength >= finalMinLength
          ) {
            finalLength = phoneLength
          } else if (
            !isPrefixIncluded &&
            phoneLength <= ifphoneMaxLength &&
            phoneLength >= length
          ) {
            finalLength = phoneLength
          } else if (isPrefixIncluded) {
            finalLength = finalMinLength
          } else {
            finalLength = length
          }
          if (isPrefixIncluded) {
            break
          } else if (
            (!isPrefixIncluded || phoneLength >= finalLength) &&
            phoneLength >= length
          ) {
            const newValue =
              codePrefix.toString() +
              phone.slice(phone.length - finalLength).toString()
            if (phone !== newValue) {
              item.value = newValue
              result.isUpdate = true
            }
          }
        }
        break
      }
    }
  }

  return result
}

function getNormalizedPhoneNumber(params, config, phoneNumberPath) {
  let result = {params}
  let phone = getNestedProp(params, phoneNumberPath)
  if (phone && config) {
    let {length, code: codePrefix, phoneMaxLength} = config
    const ifphoneMaxLength = phoneMaxLength ? phoneMaxLength : length
    let phoneLength = phone.length
    const finalMinLength = length + codePrefix.length
    const finalMaxLength = ifphoneMaxLength + codePrefix.length
    let finalLength = 0
    const isPrefixIncluded = phone.startsWith(codePrefix)
    if (
      !isPrefixIncluded &&
      phoneLength > length + 1 &&
      phone.startsWith('00')
    ) {
      phone = phone.slice(2)
      phoneLength = phone.length
    }
    if (
      isPrefixIncluded &&
      phoneLength <= finalMaxLength &&
      phoneLength >= finalMinLength
    ) {
      finalLength = phoneLength
    } else if (
      !isPrefixIncluded &&
      phoneLength <= ifphoneMaxLength &&
      phoneLength >= length
    ) {
      finalLength = phoneLength
    } else if (isPrefixIncluded) {
      finalLength = finalMinLength
    } else {
      finalLength = length
    }
    if (isPrefixIncluded && phoneLength === finalLength) {
      result.filterPhone = phone.slice(phoneLength - finalLength).toString()
    } else if (
      (isPrefixIncluded && phoneLength < finalLength) ||
      phoneLength < length
    ) {
      result.error = {
        message: 'contact.phone.invalid'
      }
    } else {
      phone = phone.slice(phoneLength - finalLength).toString()
      result.normalizedPhone = codePrefix + phone
      result.params = setNestedProp(
        result.params,
        phoneNumberPath,
        codePrefix + phone
      )
      result.filterPhone = phone
    }
  }

  return result
}

export const normalizePhoneParams = (params, state, phoneNumberPath) => {
  const config = getPhoneConfiguration(state)
  return getNormalizedPhoneNumber(params, config, phoneNumberPath)
}

export function isOldEntity(entity) {
  const {isLeadGatewayEnabled, salesForceGoLiveDate} = getCountryConfig()

  const isOldEntity =
    entity && new Date(entity.createdAt) < new Date(salesForceGoLiveDate)

  if (!isLeadGatewayEnabled) {
    return true
  }

  return isOldEntity
}

const CustomRow = styled(TableRow)`
  && {
    background-color: ${(props) => {
      if (props.rowData) {
        return isOldEntity(props.rowData) ? 'inherit' : '#f1b595'
      }
      return 'inherit'
    }}
`

export const rowStyleConfig = [
  {
    test: () => true,
    rowStyle: CustomRow
  }
]

export const orangeColorRow = rowStyleFactory(rowStyleConfig)

export const getVisibleKeys = (list) => {
  let finalKeys = []
  function innerRec(arr) {
    for (let i = 0; i < arr.length; i++) {
      let keys = arr[i]
      if (keys.type === 'composite') {
        if (keys.children.length !== 0) {
          innerRec(keys.children)
        }
      } else {
        finalKeys.push(keys.key)
      }
    }
  }
  innerRec(list)
  return finalKeys
}
