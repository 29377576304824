import React from 'react'
import Typography from '@material-ui/core/Typography'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'

interface SectionTitleProps {
  title: string
  variant?: 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'srOnly'
  | 'inherit'
}

const SectionTitle = ({variant = 'h6', title}: SectionTitleProps) => {
  const classes = useCommonStyles()

  return (
    <Typography
      variant={variant}
      gutterBottom
      align='left'
      className={classes.colorTitle}
    >
      {title}
    </Typography>
  )
}
export default SectionTitle
