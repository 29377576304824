import {Component} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import noop from 'lodash/noop'
import isNil from 'lodash.isnil'
import difference from 'lodash.difference'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import {
  setDealer,
  updateDueAmount,
  selectRow,
  cleanUpDialog,
  attemptTicketCreation,
  selectAllRows,
  onFilterChange
} from '../../store/payslips/create/actions'
import {
  normalizedPurcases,
  getSummaryData,
  hasSelectedRows,
  createStatusSelector,
  getFilters,
  getDealer
} from '../../store/payslips/create/selectors'
import {documentList} from '../../store/documentConfig/selectors'
import PageTableBase from '../../components/PanelTableBase'
import {RenderIf} from '../../components/RenderIf'
import {
  fetchDealerFileTypes,
  setUploading,
  setReference,
  uploadPayslipDocument
} from '../../store/documentConfig/actions'
import * as documentSelectors from '../../store/documentConfig/selectors'
import DocumentUpload from '../../components/Upload/DocumentUpload'
import {EmptyTablePlaceholder} from './commons'
import {getCountryCode} from '../../store/config/selectors'
import {DealerPicker} from './components/DealerPicker'
import {
  mapId,
  editableCellOptions,
  TableContainer,
  isRowSelectable,
  getNewDocumentState,
  getCreateReceiptCustomDocumentColumns,
  getDocumentsWihtoutDates,
  getNewStateOnInputBlur,
  removeDeletedDocuments
} from './CreateReceiptHelper'
import {parseMetaInfoChange} from '../../utils/dataTransforms'

/**
 * Hides a column in new document list.
 * Uploader column is supported.
 * If column = true => column is hidden.
 */
const hiddenNewDocumentListColumns = {
  uploader: true
}

class CreateReceipt extends Component {
  constructor(props) {
    super(props)

    /**
     * Use state to hold data about document metaInfo:
     * documentMetaInfo: {[documentId]: {comment, date}}
     * documentsWithErrors: {[documentId]: Boolean}
     * */
    this.state = {documentMetaInfo: {}, documentsWithErrors: {}}
    this.customDocumentTableColumns = getCreateReceiptCustomDocumentColumns({
      onCommentBlur: this.onCommentBlur,
      onDateBlur: this.onDateBlur
    })
  }
  onDealerSelect = (id) => {
    this.props.setDealer(id)
    this.props.setReference(id)
  }

  saveDisabled = () => this.props.isSubmitting

  onRowSelect = ({id}) => this.props.selectRow(id)

  onDialogClose = () => {
    this.setState({documentMetaInfo: {}, documentsWithErrors: {}})
    this.props.handleClose()
    this.props.cleanUpDialog()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.created) {
      this.onDialogClose()
    }

    const changes = difference(this.props.documentIds, prevProps.documentIds)

    if (changes.length) {
      const newState = getNewDocumentState(this.props, this.state)

      this.setState((state) => ({...state, ...newState}))
    }
  }

  onCustomInputBlur = (field) => ({event, documentId}) => {
    const value = parseMetaInfoChange({field, value: event.target.value})
    const newState = getNewStateOnInputBlur(
      this.state,
      documentId,
      field,
      value
    )
    this.setState(newState)
  }

  onCommentBlur = this.onCustomInputBlur('comment')
  onDateBlur = this.onCustomInputBlur('date')

  createTicket = () => {
    const documentsWithErrors = getDocumentsWihtoutDates(
      this.state.documentMetaInfo
    )

    this.props.attemptTicketCreation({
      t: this.props.t,
      documentMetaInfo: this.state.documentMetaInfo,
      documentsWithErrors: documentsWithErrors
    })

    this.setState({documentsWithErrors})
  }

  onDocumentDelete = ({documentIds}) => {
    const metaInfo = removeDeletedDocuments({
      documentIds,
      documentMetaInfo: this.state.documentMetaInfo
    })
    const errors = getDocumentsWihtoutDates(metaInfo)

    this.setState({
      documentsWithErrors: errors,
      documentMetaInfo: metaInfo
    })
  }

  render() {
    const {t} = this.props

    return (
      <Dialog open={this.props.isOpen} maxWidth='lg' fullWidth>
        <DialogTitle id='form-dialog-title'>
          {this.props.t('payslips.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DealerPicker
            countryCode={this.props.countryCode}
            onSelect={this.onDealerSelect}
          />
          <TableContainer>
            <PageTableBase
              fields={this.props.fields}
              pageConfig={{}}
              data={this.props.data}
              fetchData={noop}
              multisort={false}
              visibility={false}
              selectable={!isNil(this.props.dealer)}
              withVisibility={false}
              noToolbar
              showFilters={Boolean(this.props.dealer)}
              filterFields={this.props.filterFields}
              filters={this.props.filters}
              onFilterChange={this.props.onFilterChange}
              hasFooter={false}
              tableHeight='sm'
              showSumFooter={this.props.showSummaryFooter}
              summaryFields={this.props.summaryFields}
              summaryData={this.props.summaryData}
              onCellChange={this.props.updateDueAmount}
              onCellBlur={this.props.updateDueAmount}
              onSelect={this.onRowSelect}
              onSelectAll={this.props.selectAllRows}
              count={this.props.data.length}
              limit={this.props.data.length}
              editableCellOptions={editableCellOptions}
              emptyTableSize={
                this.props.dealer && !this.props.data.length ? 'xs' : null
              }
              emptyTablePlaceholder={
                <EmptyTablePlaceholder
                  t={t}
                  colspan={this.props.fields.length + 1}
                  show={this.props.showTablePlaceholder}
                  showIcon={false}
                />
              }
              isRowSelectable={isRowSelectable}
            />
          </TableContainer>
          <RenderIf if={this.props.hasReference}>
            <DocumentUpload
              isUploading={this.props.isUploading}
              uploadDocument={this.props.uploadDocument}
              fileTypeOptions={this.props.fileTypeOptions}
              setUploading={this.props.setUploading}
              entity='dealer'
              listWidth='flex'
              uploadAreaWidth='md'
              reference={this.props.reference}
              visibility='internal'
              status='reviewPending'
              maxFileSize={10}
              fetchOnlyCurrentDocs
              customColumns={this.customDocumentTableColumns(
                this.state.documentMetaInfo
              )}
              documentErrors={this.state.documentsWithErrors}
              hiddenColumns={hiddenNewDocumentListColumns}
              rowHeight='lg'
              onDocumentDelete={this.onDocumentDelete}
            />
          </RenderIf>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onDialogClose}
            color='primary'
            data-test='cancel-receipt-button'
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant='raised'
            color='primary'
            type='submit'
            disabled={this.saveDisabled()}
            onClick={this.createTicket}
            data-test='submit-receipt-button'
          >
            {t('button.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const {created, isSubmitting} = createStatusSelector(state)
  const data = normalizedPurcases(state)
  const {dealer} = getDealer(state)
  const reference = documentSelectors.getDocumentReference(state)
  const showTablePlaceholder = !dealer || !data || !data.length
  const showSummaryFooter = data && data.length
  const documents = documentList(state)
  const documentIds = documents.map(mapId)

  return {
    summaryData: getSummaryData(state),
    fileTypeOptions: documentSelectors.getDealerFileTypeOptions(state),
    isUploading: documentSelectors.isUploading(state),
    addedDocuments: documentSelectors.hasAddedDocuments(state),
    rowsSelected: hasSelectedRows(state),
    hasReference: !!reference,
    countryCode: getCountryCode(state),
    filters: getFilters(state),
    data,
    dealer,
    created,
    reference,
    isSubmitting,
    showSummaryFooter,
    showTablePlaceholder,
    documentIds
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDealer: (id) => dispatch(setDealer(id)),
  fetchFileTypes: () => dispatch(fetchDealerFileTypes()),
  uploadDocument: (payload) => dispatch(uploadPayslipDocument(payload)),
  setUploading: (payload) => dispatch(setUploading(payload)),
  setReference: (id) => dispatch(setReference(id)),
  updateDueAmount: (data) => dispatch(updateDueAmount(data)),
  selectRow: (id) => dispatch(selectRow(id)),
  onFilterChange: ({filters}) => dispatch(onFilterChange(filters)),
  attemptTicketCreation: (event) => dispatch(attemptTicketCreation(event)),
  cleanUpDialog: () => dispatch(cleanUpDialog()),
  selectAllRows: (ids) => dispatch(selectAllRows(ids))
})

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(CreateReceipt)
)
