import React, { useContext, useEffect } from 'react'
import {Router, Route, Switch} from 'react-router'

import ReasourceManager from './ResourceManagerPage/index'
import {history} from '../../../history'
import Country from '../types/Country'
import {MainContext} from '../context/MainContext'

const PageRouter = ({country}: {country: Country}) => {

  const {setCountryCode} =useContext(MainContext)

  useEffect(()=>{
    if(country){
      setCountryCode(country)
    }
  },[country])
  
  return (
    <Router history={history}>
      <Switch>
      {/* <Route path='/'>  // Uncomment to render inside location manager
          <ReasourceManager />
        </Route> */}
        <Route path='/resource-manager'>
          <ReasourceManager country={ country}/>
        </Route>
      </Switch>
    </Router>
  )
}

export default PageRouter
