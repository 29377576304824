import DealerPage from '../../../pages/Dealer/List'
import {
  StatusFilter,
  DealerFilter,
  BusinessCategoryFilter
} from '../../../pages/Dealer/Filters'
import dealerDetailsFormConfig from './dealerDetailsFormConfig'

export default {
  path: 'list',
  label: 'menu.dealers.listAll',
  key: 'dealersList',
  sort: [{field: 'firstname', order: 'DESC'}],
  limit: 25,
  filters: {},
  page: 1,
  fields: {
    idBlock: {
      type: 'composite',
      key: 'idBlock',
      layout: 'rows',
      separator: '\n',
      children: [
        {
          key: 'internalId',
          label: 'dealerList.placeholder.dealerId',
          type: 'string'
        },
        {
          key: 'metaInfo.internalId',
          label: 'dealerList.placeholder.internalId',
          type: 'string'
        }
      ]
    },
    tax: {
      key: 'metaInfo.taxId',
      columnName: 'taxId',
      filterName: 'taxId',
      label: 'dealerList.placeholder.taxId',
      type: 'string'
    },
    nameBlock: {
      type: 'composite',
      key: 'name',
      layout: 'inline',
      separator: ' ',
      label: 'dealerList.columns.name',
      children: [
        {
          label: 'dealerList.placeholder.firstName',
          key: 'firstname',
          type: 'string',
          isSortable: true
        },
        {
          label: 'dealerList.placeholder.lastName',
          key: 'lastname',
          type: 'string'
        }
      ]
    },
    login: {
      label: 'dealerList.placeholder.login',
      key: 'email',
      type: 'string'
    },
    contactBlock: {
      type: 'composite',
      key: 'contact',
      separator: ' ',
      layout: 'rows',
      children: [
        {
          label: 'dealerList.placeholder.email',
          key: 'metaInfo.email',
          type: 'string'
        },
        {
          label: 'dealerList.placeholder.phone',
          key: 'metaInfo.phone',
          type: 'string'
        }
      ]
    },
    addressBlock: {
      type: 'composite',
      key: 'address',
      separator: '\n',
      layout: 'rows',
      children: [
        {
          label: 'dealerList.placeholder.street',
          key: 'metaInfo.street'
        },
        {
          label: 'dealerList.placeholder.city',
          key: 'metaInfo.city'
        }
      ]
    },
    companyBlock: {
      type: 'composite',
      key: 'company',
      separator: '\n',
      layout: 'rows',
      children: [
        {
          label: 'dealerList.placeholder.companyName',
          key: 'metaInfo.companyName',
          type: 'string'
        },
        {
          label: 'dealer.page.field.businessCategory',
          key: 'metaInfo.businessCategory',
          prefix: 'dealer.page.businessCategory',
          type: 'string',
          filter: BusinessCategoryFilter,
          filterName: 'metaInfo.businessCategory'
        }
      ]
    },
    legalCompanyName: {
      label: 'dealerList.placeholder.legalCompanyName',
      key: 'metaInfo.legalCompanyName',
      type: 'string'
    },
    dealerBlock: {
      type: 'composite',
      key: 'dealer',
      separator: '\n',
      layout: 'rows',
      children: [
        {
          label: 'dealerList.placeholder.dealerManager',
          key: 'dealerManagerName',
          filter: DealerFilter,
          filterName: 'metaInfo.dealerManager'
        },
        {
          label: 'dealer.page.field.maxAmountBids',
          key: 'metaInfo.maxAmountBids',
          type: 'number',
          columnName: 'maxAmountBids',
          filterName: 'maxAmountBids'
        }
      ]
    },
    statusBlock: {
      type: 'composite',
      key: 'status',
      separator: '\n',
      layout: 'rows',
      children: [
        {
          label: 'dealerList.placeholder.status',
          key: 'metaInfo.status',
          filter: StatusFilter,
          filterName: 'metaInfo.status.include'
        },
        {
          type: 'date',
          key: 'createdAt',
          label: 'global.createdAt.label',
          time: true
        }
      ]
    }
  },
  formConfig: {...dealerDetailsFormConfig},
  hiddenFields: [],
  component: DealerPage
}
