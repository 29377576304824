import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import cloneDeep from 'lodash/cloneDeep'
import withConfig from './providers/withConfig'

const getChildRequiredConfig = (childId, requiredFields) =>
  requiredFields.find((field) => field === childId || field.field === childId)

const getFormFieldNames = ({children, id}) => {
  if (!children) {
    return [id]
  }

  return children.reduce((acc, child) => {
    if (child.children) {
      return [...acc, ...getFormFieldNames(child)]
    }

    if (child.id) {
      return [...acc, child.id]
    }

    return acc
  }, [])
}

const formWithCountryOptions = (
  config,
  formName,
  multiple = false,
  options
) => (Component) => {
  class FormWithCountryOptions extends Component {
    applyConfigForChild = (
      child,
      countryConfig,
      multiple = false,
      formNameKey
    ) => {
      const childClone = cloneDeep(child)
      const childId = childClone.id

      if (countryConfig.hidden.includes(child.id)) {
        return null
      }

      if (countryConfig.dependencies && countryConfig.dependencies[childId]) {
        childClone.dependencies = [...countryConfig.dependencies[childClone.id]]
      }

      if (!childClone.children && countryConfig.required) {
        const requiredFields = countryConfig.required.reduce((acc, key) => {
          if (key.field) {
            return [...acc, key]
          }

          return acc
        }, [])

        const childRequiredConfig = getChildRequiredConfig(
          child.id,
          requiredFields
        )

        if (childRequiredConfig) {
          if (multiple === true) {
            childClone.dependencies = [...childRequiredConfig.dependencies]
            // Decide if the field will be required
            // based on config and dependencies
            // Flag 'ignoreEmptyDependencies' sets field as required without
            // checking values of depencencies
            childClone.requiredFn = (props, childConfig) => {
              const required = props.ignoreEmptyDependencies || false

              if (required) {
                return required
              }

              const data = props.dependencyData
                ? {...props.data, ...props.dependencyData}
                : props.data
              const dependenciesFilled = keys(data).some(
                (dataKey) =>
                  childConfig.dependencies.includes(dataKey) &&
                  !isNil(data[dataKey]) &&
                  !isEmpty(data[dataKey])
              )

              return dependenciesFilled
            }
          } else {
            childClone.required = true
          }
        }
      }

      if (
        !childClone.children &&
        typeof countryConfig.disabledFields !== 'undefined'
      ) {
        if (
          options &&
          options.page &&
          formNameKey &&
          Array.isArray(
            countryConfig.disabledFields[options.page][formNameKey]
          ) &&
          countryConfig.disabledFields[options.page][formNameKey].includes(
            child.id
          )
        ) {
          childClone.disabled = true
        } else if (typeof child.disabled !== 'function') {
          childClone.disabled = false
        }
      }

      if (
        typeof childClone.children === 'undefined' &&
        countryConfig.required &&
        countryConfig.required.indexOf(childClone.id) > -1
      ) {
        return {
          ...childClone,
          required: true
        }
      }

      if (typeof childClone.children !== 'undefined') {
        const children = childClone.children.reduce((acc, child) => {
          if (!countryConfig.hidden.includes(child.id) || !childClone.id) {
            const configuredChild = this.applyConfigForChild(
              child,
              countryConfig,
              multiple,
              formNameKey
            )

            if (configuredChild) {
              return [...acc, configuredChild]
            }
          }

          return acc
        }, [])

        return {
          ...childClone,
          children
        }
      }

      if (countryConfig.dynamic && countryConfig.dynamic[childClone.id]) {
        return {
          ...childClone,
          ...countryConfig.dynamic[childClone.id]
        }
      }

      return childClone
    }

    getConfigForCountry = (formConfig, data, multiple = false, formNameKey) => {
      const countryConfig = this.props[formName]
      let children = null
      const formFields = getFormFieldNames(formConfig.form)
      const requiredFields = formFields.filter((field) =>
        countryConfig.required.find((requiredField) => {
          if (typeof requiredField === 'object') {
            return requiredField.field === field
          }

          return requiredField === field
        })
      )

      if (typeof countryConfig !== 'undefined') {
        children = formConfig.form.children.reduce((prev, child) => {
          const item = this.applyConfigForChild(
            typeof child === 'function' ? child(data) : child,
            countryConfig,
            multiple,
            formNameKey
          )

          return item ? [...prev, item] : prev
        }, [])
      }

      if (children !== null) {
        return {
          formFields,
          requiredFields,
          countryConfig,
          form: {
            children
          }
        }
      }

      return config
    }

    getMultipleConfigForCountry = () => {
      const formConfigs = {}

      keys(config).forEach((key) => {
        const data = {data: this.props[key.split('Form').join('')]}

        formConfigs[key] = this.getConfigForCountry(
          config[key],
          data,
          true,
          key
        )
      })

      return formConfigs
    }

    render() {
      return (
        <Component
          {...this.props}
          config={
            multiple === true
              ? this.getMultipleConfigForCountry()
              : this.getConfigForCountry(config, this.props)
          }
        />
      )
    }
  }

  return withConfig([formName], FormWithCountryOptions)
}

export default formWithCountryOptions
