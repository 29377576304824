import noop from 'lodash/noop'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
// Types
import * as types from './types'
// Actions
import * as globalActions from '../../global/actions'
import {reactToLocationChange} from '../../config/actions'
// Api
import * as ContactApi from '../../../api/contact/requests'
import * as CarApi from '../../../api/car/requests'
import * as AuthApi from '../../../api/auth/requests'
// Utils
import {formatParams} from '../../utils'
import {getCountryCode} from '../../config/selectors'
import {getFullNameFromUser} from '../../utils/helpers'
import {filterPhone, normalizePhoneParams} from '../../../pages/CRM/helpers'

async function fetchPurchaseCoordinatorsAndMapToAppointments(appointments) {
  const appointmentPurchaseCoordinatorMapById = {}
  const authUserMapById = {}

  appointments.forEach((item) => {
    if (item.purchaseCoordinator) {
      appointmentPurchaseCoordinatorMapById[item.id] = item.purchaseCoordinator
    }
  })

  // send only unique userIds
  const uniquePurchaseCoordinatorIDs = [
    ...new Set(Object.values(appointmentPurchaseCoordinatorMapById))
  ]

  if (uniquePurchaseCoordinatorIDs.length > 0) {
    const purchaseCoordinatorData = await AuthApi.getUserList({
      id: uniquePurchaseCoordinatorIDs
    })

    purchaseCoordinatorData.user.list.forEach((user) => {
      authUserMapById[user.id] = user
    })

    return appointments.map((item) => {
      const user = authUserMapById[item.purchaseCoordinator]

      if (user) {
        return {
          ...item,
          purchaseCoordinator: getFullNameFromUser(user),
          purchaseCoordinatorPhone: get(user, 'metaInfo.phone') || ''
        }
      }

      return item
    })
  }

  return appointments
}

export const fetchList = ({limit, filters, sort, page} = {}) => async (
  dispatch,
  getState
) => {
  dispatch(setFetching())

  const country = getCountryCode(getState())
  const currentState = getState().appointment.list

  limit = limit || currentState.limit
  filters = filters || currentState.filters
  sort = sort || currentState.sort
  page = page || currentState.page

  const appointmentData = {...formatParams(filters), country}

  const {params, error} = normalizePhoneParams(
    {limit, appointmentData, sort, page},
    getState(),
    ['appointmentData', 'lead', 'contact', 'phone']
  )

  const {filters: newFilters, isUpdate} = filterPhone(filters || [], getState())
  if (isUpdate) {
    dispatch({type: types.updateFilters, payload: newFilters})
  }
  if (error) {
    dispatch(
      setAppointmentsLoaded({
        data: {count: 0, list: []},
        limit,
        sort,
        page,
        filters
      })
    ) // to stop loader
    return dispatch(globalActions.error(error))
  }

  try {
    const res = await ContactApi.getAppointmentList(params)
    const {count} = res.appointment
    const isOutOfRange = count > 0 && (page - 1) * limit >= count
    if (!isOutOfRange) {
      // fetch purchase coordinator name and phone by purchase coordinator id
      const appointmentList = get(res, 'appointment.list')
      const updatedAppointments = await fetchPurchaseCoordinatorsAndMapToAppointments(
        appointmentList
      )

      dispatch(
        setAppointmentsLoaded({
          data: {
            ...res.appointment,
            list: updatedAppointments
          },
          limit,
          sort,
          page,
          filters
        })
      )
    } else {
      // There is no data at the server for this page.
      // Go back to previous page
      // https://frontiercargroup.atlassian.net/browse/IO-2649
      dispatch(fetchList({page: page - 1}))
    }
  } catch (e) {
    dispatch(globalActions.apiError(e))
    dispatch(
      globalActions.error({
        message: 'global.error.fetch',
        entity: 'appointment'
      })
    )
  }
}

export const setAppointmentsLoaded = (payload) => ({
  type: types.dataLoaded,
  payload
})

export const createAppointment = ({leadId, data}, next = noop) => (
  dispatch
) => {
  dispatch(setFormPending())

  const purchaseCoordinatorId = get(data, 'purchaseCoordinatorId')
  const appointmentType = data?.type

  // QUICK HACK TO FIX SERVICE TYPE MAPPING FROM PURCHASE HOME INSPECTION
  // TODO: CREATE MAPPING BETWEEN APPOINTMENT TYPE TO SERVICE TYPE
  let serviceType = 'INSPECTIONCRM'
  if (appointmentType == 'purchaseInspectionHome') {
    serviceType = 'INSPECTIONHOMECRM'
  }

  const {appointmentAddress: address, ...appointment} = omitBy(
    omit(data, 'participants', 'purchaseCoordinatorId'),
    isNil
  )

  if (purchaseCoordinatorId) {
    appointment.purchaseCoordinator = purchaseCoordinatorId
  }

  return ContactApi.createAppointment({
    ...appointment,
    address,
    leadId,
    service: serviceType
  })
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(resetAppointmentForm())
      dispatch(
        globalActions.success({
          message: 'global.action.create',
          entity: 'appointment'
        })
      )
      next(response)

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.create',
          entity: 'appointment'
        })
      )
    })
}

export const updateAppointment = ({id, data}, next = noop) => (dispatch) => {
  dispatch(setFormPending())

  const purchaseCoordinatorId = get(data, 'purchaseCoordinatorId')
  const {appointmentAddress: address, ...appointmentData} = omit(
    data,
    'participants',
    'purchaseCoordinatorId'
  )

  if (data.assignedTo) {
    appointmentData.assignedTo = data.assignedTo
    appointmentData.assignedToName =
      data.assignedTo === null || !data.assignedToName
        ? null
        : data.assignedToName
  }

  if (purchaseCoordinatorId) {
    appointmentData.purchaseCoordinator = purchaseCoordinatorId
  }

  return ContactApi.updateAppointment({
    id,
    appointmentData: {...appointmentData, address}
  })
    .then((response) => {
      dispatch(setFormSuccess(response))
      dispatch(resetAppointmentForm())
      dispatch(
        globalActions.success({
          message: 'global.action.update',
          entity: 'appointment'
        })
      )
      next(response)

      return response
    })
    .catch((e) => {
      dispatch(setFormError(e))
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.update',
          entity: 'appointment'
        })
      )
    })
}

export const deleteAppointment = ({ids}, next = noop) => (dispatch) => {
  return ContactApi.deleteAppointment(ids)
    .then((response) => {
      dispatch(
        globalActions.success({
          message: 'global.action.delete',
          entity: 'appointment'
        })
      )
      next(response)
    })
    .catch((e) => {
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.delete',
          entity: 'appointment'
        })
      )
    })
}

export const cancelAppointment = (params, next = noop) => (dispatch) => {
  dispatch(setCancelPending())

  return ContactApi.cancelAppointment({...params})
    .then(() => {
      dispatch(
        globalActions.success({
          message: 'global.action.cancelAppointment',
          entity: 'appointment'
        })
      )
      dispatch(setCancelComplete())
      next()
    })
    .catch((e) => {
      dispatch(setCancelComplete())
      dispatch(globalActions.apiError(e))
      dispatch(
        globalActions.error({
          message: 'global.action.cancelAppointment',
          entity: 'appointment'
        })
      )
    })
}

export const setFetching = (fetching = true) => ({
  type: types.fetching,
  payload: fetching
})

export const toggleSort = (sort) => (dispatch) => {
  dispatch({
    type: types.toggleSort,
    payload: sort
  })
  dispatch(fetchList())
}

export const setSort = (sort) => (dispatch) => {
  dispatch({
    type: types.setSort,
    payload: sort
  })
  dispatch(fetchList())
}

export const toggleField = (field) => (dispatch) => {
  dispatch({
    type: types.toggleField,
    payload: field
  })
}

export const updateFilters = (filters) => (dispatch) => {
  dispatch({
    type: types.updateFilters,
    payload: filters
  })
  dispatch(fetchList())
}

export const updateAppointmentForm = (payload) => ({
  type: types.updateForm,
  payload
})

export const resetAppointmentForm = (payload) => ({
  type: types.resetForm,
  payload
})

export const hydrateAppointmentForm = (params) => async (dispatch) => {
  dispatch(setFormPending())
  let contactApiResponse
  try {
    contactApiResponse = await ContactApi.getAppointmentList({
      appointmentData: {...params},
      limit: 1
    })
  } catch (error) {
    dispatch(global.apiError(error))
  }

  const {
    address: appointmentAddress,
    ...firstAppointment
  } = contactApiResponse.appointment.list[0]

  const appointment = {
    ...firstAppointment,
    appointmentAddress,
    slot: `${firstAppointment.date} ${firstAppointment.time}`
  }

  if (appointment.placeId !== null) {
    dispatch(
      reactToLocationChange(
        {placeId: appointment.placeId},
        'appointment',
        null,
        appointment.service
      )
    )
  }

  if (appointment.purchaseCoordinator) {
    const purchaseCoordinatorId = appointment.purchaseCoordinator
    let userList
    try {
      userList = await AuthApi.getUserList({id: [purchaseCoordinatorId]})
    } catch (error) {
      dispatch(global.apiError(error))
    }

    appointment.purchaseCoordinator = `${get(
      userList,
      'user.list[0].firstname'
    )} ${get(userList, 'user.list[0].lastname')}`
    appointment.purchaseCoordinatorPhone = get(
      userList,
      'user.list[0].metaInfo.phone'
    )
    appointment.purchaseCoordinatorId = purchaseCoordinatorId
  }

  return dispatch({
    type: types.hydrateForm,
    payload: appointment
  })
}

export const setFormError = () => ({
  type: types.formError,
  payload: true
})

export const setFormSuccess = () => ({
  type: types.formSuccess,
  payload: true
})

export const setFormPending = (payload) => ({
  type: types.formPending,
  payload
})

export const requestCancel = (payload) => ({
  type: types.cancelPending,
  payload
})

export const setCancelPending = (payload) => ({
  type: types.cancelPending,
  payload
})

export const setCancelComplete = (payload) => ({
  type: types.cancelSuccess,
  payload
})

export const setCarLink = (payload) => ({
  type: types.setCarLink,
  payload
})

export const showCarCreatedDialogBox = () => ({
  type: types.showCarCreatedDialogBox
})

export const hideCarCreatedDialogBox = () => ({
  type: types.hideCarCreatedDialogBox
})

export const createCar = (payload) => (dispatch) => {
  dispatch({
    type: types.createCar,
    payload
  })

  const options = {
    appointmentId: payload.appointmentId,
    country: payload.country
  }

  dispatch(setCarLink(null))

  return CarApi.createCarViaAppointment(options)
    .then((response) => {
      if (response !== null && typeof response !== 'undefined') {
        const href = window.location.origin
          .replace(/(.*)\badmin2\./, '$1admin.')
          .replace(/:\d+$/, ':8080')

        const url = [
          href,
          payload.locale,
          'car',
          'edit',
          response.createCarViaAppointment.id
        ].join('/')

        dispatch(setCarLink(url))
        dispatch(showCarCreatedDialogBox())
      }
    })
    .catch((e) => dispatch(globalActions.apiError(e)))
}
