import {showSnackbarNotification} from '../../signals/actions'
import {getErrorMessage} from './helpers'
import * as signalTypes from '../../signals/types'
import {put} from 'redux-saga/effects'

export const showErrorMessage = function*(error) {
  yield put(
    showSnackbarNotification({
      variant: signalTypes.variantTypes.error,
      message: getErrorMessage(error),
      open: true
    })
  )
}
export const showSuccessMessage = function*(message, extras = null) {
  yield put(
    showSnackbarNotification({
      variant: signalTypes.variantTypes.success,
      message: message || 'snackbar.notification.payslips.new.success',
      open: true,
      extras
    })
  )
}
