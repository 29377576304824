import {SubType} from '../../../pages/CRM/Appointment/Filters'
export const commentSort = [{field: 'updatedAt', order: 'DESC'}]

export const commentFields = {
  subject: {
    key: 'subject',
    label: 'comment.subject.label',
    type: 'string'
  },
  subtype: {
    key: 'subtype',
    label: 'comment.subtype.label',
    type: 'string',
    filter: SubType
  },
  description: {
    key: 'description',
    label: 'comment.description.label',
    type: 'string',
    shorten: 80
  },
  created: {
    type: 'composite',
    key: 'created',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        type: 'date',
        time: true,
        key: 'createdAt',
        label: 'global.createdAt.label'
      },
      {
        type: 'email',
        key: 'createdByUserEmail',
        label: 'global.createdBy.label',
        cut: true
      }
    ]
  }
}
