import {categoryFields} from './categoryFields'
import Category from '../../../pages/Category/Category'

export default {
  path: 'category',
  label: 'Category',
  key: 'category',
  component: Category,
  fields: {...categoryFields}
}
