import {
  AuthNoPermissionsError,
  BadRequestError,
  NotFoundError,
  ServerFailureError,
  ResourceConflictError,
  UnprocessableEntityError,
  InvalidCredError
} from '../utils/errors'

export const errors = {
  403: AuthNoPermissionsError,
  404: NotFoundError,
  400: BadRequestError,
  409: ResourceConflictError,
  422: UnprocessableEntityError,
  440: InvalidCredError,
  500: ServerFailureError
}

export const logData = (response) => {
  const {config, headers, request, status, statusText} = response
  const aTag = document.createElement('a')
  const requestData = config.data
  const responseSize =
    headers && headers['content-length']
      ? parseInt(headers['content-length'], 10)
      : undefined
  const graphqlQueryNameRegex = /(\s|^|})[{}]([^{(,]+)(?=(\b[({}]))/g
  let graphqlQueryName =
    typeof requestData === 'string'
      ? requestData.match(graphqlQueryNameRegex)
      : null
  const bracketsRegex = /[{}]/g
  const {requestLogger} = config
  let errorBody
  let responseBody

  aTag.href = request.responseURL || request.baseURL

  if (graphqlQueryName !== null) {
    graphqlQueryName = graphqlQueryName.map((item) =>
      item.replace(bracketsRegex, '').trim()
    )
  } else {
    graphqlQueryName = undefined
  }

  if (
    typeof response.data !== 'undefined' &&
    typeof response.data.errors !== 'undefined'
  ) {
    errorBody = `${status} ${statusText}`
    responseBody = JSON.stringify(response.data)
  } else if (typeof response.data === 'undefined') {
    errorBody = response.message
    responseBody = response.stack
  }

  requestLogger.onResponse({
    url: aTag.href,
    method: config.method,
    requestSize:
      typeof requestData === 'string' ? requestData.length : undefined,
    requestBody:
      config.ignoreLoggingRequestBody !== true ? requestData : undefined,
    graphqlQueryName,
    errorBody,
    responseSize,
    responseCode: status,
    responseBody
  })
}
