import {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Link, withRouter} from 'react-router-dom'
import {Grid, Button} from '@material-ui/core'
import {Add, Edit, Check, Clear, Reorder} from '@material-ui/icons'
import LocationEdit from './Edit'
import Drawer from './Drawer'
import DeleteDialog from './DeleteDialog'
import ActionButton from '../../components/layout/ActionButton'
import PageTableBase from '../../components/PageTableBase'
import {
  getList,
  loadOptions,
  updateFilters,
  activateLocation,
  toggleSort,
  toggleField,
  setSort
} from '../../store/locationManagement/actions'
import './Locations.styl'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

export class LocationList extends Component {
  state = {
    delete: {...deleteInitialState},
    active: true
  }

  componentDidMount() {
    this.props.loadOptions()
  }

  handleUpdate = (props = {}) => {
    const filters =
      this.state.active === true
        ? {filters: [{key: 'active', value: true}]}
        : {filters: []}

    this.props.fetchData({...props, ...filters})
  }
  openDeleteDialog = ({id, active, action}) => {
    this.setState({
      delete: {
        open: true,
        active,
        handleSubmit: () => {
          action(id)
          this.closeDeleteDialog()
          this.handleUpdate()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }
  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }
  handleActive = (active) => async () => {
    if (this.state.active !== active) {
      await this.setState({active})

      this.handleUpdate()
    }
  }
  routeBack = () => this.props.history.push(this.props.match.path)
  routeEdit = ({id} = {id: null}) => {
    if (typeof id === 'string' && id.length > 0) {
      this.props.history.push(`/locations/home/edit/${id}`)
    } else {
      this.props.history.push(`/locations/home/create`)
    }
  }
  deactivateLocation = (id) => {
    this.props.activateLocation(id, false)
  }
  activateLocation = (id) => {
    this.props.activateLocation(id, true)
  }
  render() {
    const {history, pageConfig} = this.props
    const isHome =
      history.location.pathname.split('/').pop() === pageConfig.path
    const quickFilterContent = [
      {
        action: this.handleActive(true),
        text: 'location.filter.activeOnly',
        icon: <Check />
      },
      {
        action: this.handleActive(false),
        text: 'location.filter.allPlaces',
        icon: <Reorder />
      }
    ]
    const args =
      this.state.active === true
        ? {filters: [{key: 'active', value: true}]}
        : {filters: []}

    return (
      <div className={this.props.className}>
        <Grid container>
          {isHome ? (
            <PageTableBase
              {...this.props}
              title='entity.location'
              filterable
              sortable
              quickFilters
              quickFilterContent={quickFilterContent}
              args={args}
              fetchData={this.handleUpdate}
              singleSelectActions={[
                {
                  event: this.routeEdit,
                  icon: <Edit />,
                  title: 'global.action.update'
                },
                {
                  event: (item) => {
                    this.openDeleteDialog({
                      id: item.id,
                      active: item.active,
                      action: this.deactivateLocation
                    })
                  },
                  icon: <Clear />,
                  title: 'location.deactivate',
                  hide: (item) => item.active === false
                },
                {
                  event: (item) => {
                    this.openDeleteDialog({
                      id: item.id,
                      active: item.active,
                      action: this.activateLocation
                    })
                  },
                  icon: <Check />,
                  title: 'location.activate',
                  hide: (item) => item.active === true
                }
              ]}
            />
          ) : (
            <Grid item xs={3}>
              <Drawer {...this.props} goToEdit={this.routeEdit} />
            </Grid>
          )}
          <ActionButton userGroup='locationmanagerreadonly'>
            <Button
              variant='fab'
              color='primary'
              component={Link}
              to={`${this.props.match.path}/create`}
            >
              <Add />
            </Button>
          </ActionButton>
          <Route
            exact
            path={`${this.props.match.path}/create`}
            render={(routeProps) => (
              <Grid item xs={9} sm={8}>
                <LocationEdit {...routeProps} />
              </Grid>
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/edit/:id`}
            render={(routeProps) => (
              <Grid item xs={9} sm={8}>
                <LocationEdit {...routeProps} />
              </Grid>
            )}
          />
          <DeleteDialog {...this.state.delete} />
        </Grid>
      </div>
    )
  }
}
const mapStateToProps = ({locationManagement}) => {
  const {...list} = locationManagement.list

  return {
    ...list,
    data: Object.values(list.data)
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(getList(args)),
  loadOptions: () => dispatch(loadOptions()),
  activateLocation: (id, active) => dispatch(activateLocation(id, active)),
  onFilterChange: (filter) => dispatch(updateFilters(filter)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  updateSort: (sort) => dispatch(setSort(sort))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationList)
)
