export const yellow = 'rgb(220, 175, 0)'
export const green = 'rgb(65, 117, 5)'
export const red = 'rgb(208, 2, 27)'
export const DEFAULT_BORDER_COLOR = 'blue'
export const DEFAULT_BORDER_BOTTOM_COLOR = 'transparent'

export const BANNER_SIZE = {
  xs: {
    WRAPPER: {
      height: '35px',
      width: '25px'
    },
    BODY: {
      height: '35px',
      border: '12px',
      borderBottom: '9px',
      borderTopRadius: '7px'
    },
    ICON: {
      top: '15%',
      left: '15%',
      fontSize: '15px'
    }
  },
  sm: {
    WRAPPER: {
      height: '60px',
      width: '40px'
    },
    BODY: {
      height: '60px',
      border: '20px',
      borderBottom: '12px',
      borderTopRadius: '10px'
    },
    ICON: {
      top: '15%',
      left: '20%',
      fontSize: '25px'
    }
  },
  lg: {
    WRAPPER: {
      height: '100px',
      width: '60px'
    },
    BODY: {
      height: '100px',
      border: '40px',
      borderBottom: '30px'
    },
    ICON: {
      top: '15%',
      left: '20%',
      fontSize: '60px'
    }
  }
}

export const BANNER_POSITIONS = {
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_CENTER: 'BOTTOM_CENTER'
}
export const adornementPositions = {
  [BANNER_POSITIONS.TOP_RIGHT]: {
    alignItems: 'baseline',
    justifyContent: 'flex-end'
  },
  [BANNER_POSITIONS.BOTTOM_CENTER]: {
    alignItems: 'flex-end',
    justifyContent: 'center'
  }
  // Add more positions
}

export const setProp = (propName, component, defaultValue) => (props) => {
  let size = props.size || 'sm'
  size = size === 'single' ? 'sm' : size

  const prop = BANNER_SIZE[size][component][propName]

  return prop || defaultValue
}

export const getPositionStyle = ({alignItems, justifyContent}) =>
  `align-items: ${alignItems}; justify-content: ${justifyContent};`

export const getPosition = (props) => {
  if (!props.position || !adornementPositions[props.position]) {
    return getPositionStyle(adornementPositions[BANNER_POSITIONS.TOP_RIGHT])
  }

  return getPositionStyle(adornementPositions[props.position])
}
