import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../utils/typesUtil'

const resourceName = 'transitJob'

export const dataLoaded = 'drivers/DATA_LOADED'

export const fetchTransitJobList = `${resourceName}/FETCH_TRANSIT_JOB_LIST`
export const fetchTransitJobListSuccess = `${resourceName}/FETCH_TRANSIT_JOB_LIST_SUCCESS`
export const fetchTransitJobListError = `${resourceName}/FETCH_TRANSIT_JOB_LIST_ERROR`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
export const toggleField = `${resourceName}/TOGGLE_FIELD`

export const formTypes = getFormTypes(resourceName)
export const sortTypes = getSortTypes(resourceName)
export const columnTypes = getColumnTypes(resourceName)
export const dataLoadingTypes = getDataLoadingTypes(resourceName)

export const toggleRow = `${resourceName}/TOGGLE_ROW`

export const updateFormData = `${resourceName}/UPDATE_FORM_DATA`
export const saveTransit = `${resourceName}/SAVE_TRANSIT`
export const saveTransitSuccess = `${resourceName}/SAVE_TRANSIT_SUCCESS`

export const hydrateTransitForm = `${resourceName}/HYDRATE_TRANSIT_FORM`
export const hydrateForm = 'transitForm/HYDRATE'
export const resetForm = `transitForm/RESET`
export const setHydrateFormStatus = `${resourceName}/SET_HYDRATE_TRANSIT_FORM_STATUS`

export const cancelTransitJob = `${resourceName}/CANCEL_TRANSIT`
export const cancelTransitJobSuccess = `${resourceName}/CANCEL_TRANSIT_SUCCESS`

export const setOptionsForCarId = `${resourceName}/SET_OPTIONS_FOR_CAR_ID`

export const fetchTransitJob = 'transitReview/FETCH_TRANSIT_JOB'
export const fetchTransitJobSuccess = 'transitReview/FETCH_TRANSIT_JOB_SUCCESS'
export const finishTransitJob = 'transitReview/FINISH_TRANSIT_JOB'
export const finishTransitJobSuccess =
  'transitReview/FINISH_TRANSIT_JOB_SUCCESS'
export const fetchDriverImage = 'transitReview/FETCH_DRIVER_IMAGE'
export const fetchDriverImageSuccess =
  'transitReview/FETCH_DRIVER_IMAGE_SUCCESS'

export const startTransitJob = 'transitReview/START_TRANSIT_JOB'
export const startTransitJobSuccess = 'transitReview/START_TRANSIT_JOB_SUCCESS'

export const fetchDriverDetails = 'transitReview/FETCH_DRIVER_DETAILS'
export const fetchDriverDetailsSuccess =
  'transitReview/FETCH_DRIVER_DETAILS_SUCCESS'

export const updateTransitJobStatus = `${resourceName}/updateTransitJobStatus`
export const updateTransitJobStatusSuccess = `${resourceName}/updateTransitJobStatusSuccess`
