import {Component} from 'react'
import PropTypes from 'prop-types'
import {Tabs, Tab, Paper, withStyles} from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 0
  }
})

export class CRMTabs extends Component {
  static displayName = 'CRMTabs'

  render() {
    const {classes, sections, selected} = this.props
    const index = sections.findIndex((item) => item.key === selected)
    const selectedIndex = index > -1 ? index : 0

    return (
      <Paper className={classes.root} elevation={1}>
        <Tabs
          value={selectedIndex}
          values={sections}
          indicatorColor='primary'
          textColor='primary'
          fullWidth
        >
          {sections.map((section) => {
            return (
              <Tab
                key={section.key}
                label={section.label}
                data-test={section.test}
                onClick={() => this.props.handleSelect(section.key)}
              />
            )
          })}
        </Tabs>
      </Paper>
    )
  }
}

CRMTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired
}

export default withStyles(styles)(CRMTabs)
