import {useCallback, useState, Fragment, useContext} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {translate} from 'react-i18next'
import BPAddModal from './BPAddModal/BPAddModal'
import BPSearchFields from './BPSearchFields/BPSearchFields'
import BPSearchResults from './BPSearchResults/BPSearchResults'
import * as BusinessPartnerApi from '../../../api/businessPartners/requests'
import {fetchBusinessPartnerList} from '../../../store/businessPartners/actions'
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../store/signals/actions'
import {PRIMARY_CONTACT_TYPE} from '../constants/businessPartners'
import DialogTitleWithClose from '../../../components/DialogTitleWithClose/DialogTitleWithClose'
import {ConfigContext} from '../../../components/providers/withConfig'
import './BPSearchModal.styl'

export function SearchBPModal({
  t,
  onClose,
  onBPSelect,
  onBPCreate,
  _fetchBusinessPartnerList,
  _showErrorMessage,
  _showSuccessMessage
}) {
  const [disableAllFields, setFieldsDisabilityFlag] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [parsedSearchResult, setParsedSearchResult] = useState([])
  const [showCreateBPModal, setCreateBPFlag] = useState(false)
  const [anySearchTriggered, setSearchTriggerFlag] = useState(false)

  const {countryCode, callingCode, disbableCallingCodeInBP} = useContext(
    ConfigContext
  )

  /**
   * Create Order Contact on selecting BP.
   */
  const onSelectingBpIdx = useCallback(
    async (idx) => {
      try {
        const {businessPartnerId} = searchResult[idx]
        const bpInfo = await BusinessPartnerApi.createBusinessPartner({
          businessPartnerId,
          country: countryCode
        })

        onClose()
        onBPSelect(bpInfo)
        _fetchBusinessPartnerList()
        _showSuccessMessage(t(`businessPartners.b2bBP.create.success`))
      } catch (error) {
        _showErrorMessage(error.message)
      }
    },
    [
      searchResult,
      _fetchBusinessPartnerList,
      _showErrorMessage,
      onClose,
      onBPSelect,
      countryCode,
      _showSuccessMessage,
      t
    ]
  )

  const toggleCreateBPModal = (evt) => {
    if (evt?.closeSearchModal) {
      onClose()
    }
    setCreateBPFlag(!showCreateBPModal)
  }

  const parseResponse = (response) => {
    const outcome = response.map((record) => {
      const initial = {
        id: record.businessPartnerId,
        legalName: record.legalName
      }

      const contact = record.contacts.find(
        (contact) => contact.type === PRIMARY_CONTACT_TYPE
      )
      const final = {...initial, email: contact.email, phone: contact.phone}

      return final
    })
    setParsedSearchResult(outcome)
  }

  const handleBPLookUp = useCallback(
    ({type, searchType, searchValue}) => {
      const option = {
        type,
        [searchType]:
          searchType === 'phone' &&
          searchValue?.length &&
          !disbableCallingCodeInBP
            ? `${callingCode}${searchValue}`
            : searchValue
      }

      const searchBusinessPartners = async (searchParam) => {
        try {
          setFieldsDisabilityFlag(true)
          const response = await BusinessPartnerApi.getBusinessPartner(
            searchParam
          )

          if (response.businessPartners.count > 0) {
            setSearchResult(response.businessPartners.list)
            parseResponse(response.businessPartners.list)
          } else {
            setSearchResult([])
            setParsedSearchResult([])
          }
        } catch (error) {
          _showErrorMessage(
            error.errors ? error.errors[0].message : error.message
          )
        } finally {
          setSearchTriggerFlag(true)
          setFieldsDisabilityFlag(false)
        }
      }
      searchBusinessPartners({...option, country: countryCode})
    },
    [_showErrorMessage, callingCode, countryCode, disbableCallingCodeInBP]
  )

  //Create new BP Handler
  const handleCreateNewBp = useCallback(() => {
    setCreateBPFlag(true)
  }, [])

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        maxWidth='lg'
        open
        fullWidth
        className='searchBPModal'
      >
        <DialogTitleWithClose
          className='searchBPModal__title'
          onClose={onClose}
        >
          <h3>{t('businessPartners.search.heading')}</h3>
        </DialogTitleWithClose>
        <DialogContent className='searchBPModal__content'>
          <BPSearchFields
            onLookUp={handleBPLookUp}
            disableFields={disableAllFields}
          />
          <BPSearchResults
            onCreateNewBp={handleCreateNewBp}
            onSelectingBp={onSelectingBpIdx}
            resultData={parsedSearchResult}
            anySearchTriggered={anySearchTriggered}
          />
        </DialogContent>
      </Dialog>
      {showCreateBPModal && (
        <BPAddModal
          onClose={toggleCreateBPModal}
          onBPCreate={onBPCreate}
          countryOptionType='businessPartnerType'
        ></BPAddModal>
      )}
    </Fragment>
  )
}

const mapDispatchToProps = {
  _fetchBusinessPartnerList: fetchBusinessPartnerList,
  _showErrorMessage: showErrorMessage,
  _showSuccessMessage: showSuccessMessage
}

SearchBPModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelectingBP: PropTypes.func,
  onCreatingBP: PropTypes.func,
  _fetchBusinessPartnerList: PropTypes.func,
  _showErrorMessage: PropTypes.func,
  _showSuccessMessage: PropTypes.func
}
SearchBPModal.defaultProps = {
  t: () => {},
  onBPSelect: () => {},
  onBPCreate: () => {},
  _fetchBusinessPartnerList: () => {},
  _showErrorMessage: () => {}
}

export default connect(null, mapDispatchToProps)(translate()(SearchBPModal))
