export default (theme) => ({
  form: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  title: {
    display: 'inline-block',
    paddingLeft: theme.spacing.unit
  },
  flexGrow: {
    flexGrow: 1
  },
  buttonIcon: {
    fontSize: 18
  }
})
