import {withStyles} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey.dark,
    fontSize: `0.9em`,
    lineHeight: 2.5,
    width: `100%`,
    cursor: 'pointer',
    padding: `0 ${theme.spacing.unit * 2}px`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  disabled: {
    pointerEvents: 'none'
  },
  icon: {
    fontSize: theme.typography.body1.fontSize
  }
})

const FilterMenuItemBase = ({classes, label, Icon, ...rest}) => (
  <div
    className={[
      classes.root,
      rest.disabled === true ? classes.disabled : ''
    ].join(' ')}
    {...rest}
  >
    {label}
    {Icon && <Icon className={classes.icon} />}
  </div>
)

const FilterMenuItem = withStyles(styles)(FilterMenuItemBase)

FilterMenuItem.displayName = 'FilterMenuItem'

export default FilterMenuItem
