import PropTypes from 'prop-types'
import wrapFilter from './wrapFilter'
import Calendar from '../../../Pickers/Calendar'

export const FilterCalendar = (props) => {
  const {
    value,
    open,
    InputProps,
    handleSave,
    isRange,
    withPresets,
    showInput,
    showClear,
    showCancel
  } = props
  const extractValue = (name) => (value, next) => {
    props.handleChange(name)(value, null, next)
  }

  return (
    <Calendar
      className={props.className || 'FilterCalendar'}
      {...InputProps}
      withPresets={withPresets}
      isRange={isRange}
      showInput={showInput}
      layout='single'
      open={open}
      selected={value}
      calendarId={props.fieldName}
      format={props.format || 'YYYY-MM-DD HH:mm'}
      update={extractValue(props.fieldName)}
      submit={() => handleSave(props.fieldName)}
      showClear={showClear}
      showCancel={showCancel}
    />
  )
}

FilterCalendar.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.any,
  handleSave: PropTypes.func,
  format: PropTypes.string,
  isRange: PropTypes.bool,
  withPresets: PropTypes.bool,
  showInput: PropTypes.bool,
  showClear: PropTypes.bool,
  toggleOpen: PropTypes.func
}

FilterCalendar.defaultProps = {
  fieldName: '',
  value: {},
  handleSave: () => {},
  format: 'YYYY-MM-DD HH:mm',
  isRange: true,
  withPresets: true,
  showInput: true,
  showClear: false,
  toggleOpen: () => {}
}

export default wrapFilter(FilterCalendar)
