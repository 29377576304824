// table types
export const dataLoaded = 'contactList/CONTACTS_LOADED'
export const fetching = 'contactList/CONTACTS_LOADING'
export const toggleField = 'contactList/TOGGLE_FIELD'
export const changePage = 'contactList/CHANGE_PAGE'
export const toggleSort = 'contactList/TOGGLE_SORT'
export const setSort = 'contactList/SET_SORT'
export const updateFilters = 'contactList/UPDATE_FILTER'

// form types
export const hydrateForm = 'contactForm/HYDRATE'
export const updateForm = 'contactForm/UPDATE'
export const resetForm = 'contactForm/RESET'
export const formError = 'contactForm/ERROR'
export const formSuccess = 'contactForm/SUCCESS'
export const formPending = 'contactForm/PENDING'
