export const eventField = ['event']

export const webhooksFields = [
  'id',
  'name',
  'description',
  ['events', eventField],
  'createdAt'
]

export const webhooksFormFields = [
  'id',
  'name',
  'description',
  ['events', eventField]
]

export const webhooksCreateFields = [...webhooksFormFields, 'nonce']

export const webhooksList = ['count', ['list', webhooksFields]]

export const eventsList = ['count', 'list']
