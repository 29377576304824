import React from "react";
import { TableCell } from "@material-ui/core";

import { CellVariant } from '../../types'

const TableCellWrapped = (props: { variant: CellVariant, value?: String }): JSX.Element => {
    const { variant = 'body', value = '' } = props;
    return (
        <TableCell style={{ border: 'none' }} variant={variant}>
            {value}
        </TableCell>
    )
}

export default TableCellWrapped;