import moment from 'moment'
import values from 'lodash/values'
import {compose, find, propEq, applySpec, path, pathOr, identity} from 'ramda'
import {
  TICKET_ENTITY_TYPES,
  TICKET_ENTITY_STATUS,
  TICKET_STATUS
} from '../../config/entities/ticket'
import dateFormats from '../../config/dateFormats'
import isNil from 'lodash/isNil'

const NotAvailable = 'N/A'

export const getFileStatusKey = (status) => {
  const statusUppercase = (status || '').toUpperCase()

  if (statusUppercase === TICKET_ENTITY_STATUS.OPEN) {
    return `carOwnershipTransfer.document.status.UPLOADED`
  }

  return `carOwnershipTransfer.document.status.${statusUppercase}`
}
export const getStatusValue = (status) => ({
  value: status,
  label: getFileStatusKey(status)
})
export const filterDocuments = propEq(
  'entityType',
  TICKET_ENTITY_TYPES.DOCUMENT
)
export const sortDocuments = (docA, docB) => {
  if (!docA.createdAt) {
    return 1
  }

  if (!docB.createdAt) {
    return -1
  }

  return moment(docB.createdAt).diff(moment(docA.createdAt))
}

const getStatusTime = ({status, updatedAt, createdAt}) => {
  if (status === TICKET_ENTITY_STATUS.OPEN) {
    return moment(createdAt).format(dateFormats.dateCompact)
  }

  return moment(updatedAt).format(dateFormats.dateCompact)
}

export const mapDocuments = ({
  data,
  status,
  createdAt,
  id,
  metaInfo,
  ...rest
}) => {
  if (!data) {
    return null
  }

  const creatorData = data.creatorData ? data.creatorData : {}
  const createdAtFormatted = moment(createdAt).format(
    dateFormats.dateTimeWithSlashes
  )
  const createdByName =
    !creatorData.firstname || !creatorData.lastname
      ? NotAvailable
      : `${creatorData.firstname} ${creatorData.lastname}`

  return {
    id: data.id,
    src: data.link,
    fileType: data.fileType,
    fileName: data.fileName,
    createdAt,
    createdAtFormatted,
    fileStatus: status,
    status: getFileStatusKey(status),
    statusValue: status,
    statusTime: getStatusTime({status, createdAt, updatedAt: rest.updatedAt}),
    category: data.category || NotAvailable,
    createdByName,
    ticketEntityId: id,
    documentKey: `doc-${id}-${data.fileName}-${status}`,
    comment: metaInfo?.comment
  }
}
export const filterCars = propEq('entityType', TICKET_ENTITY_TYPES.CAR)

export const getCarReceiptsFromEntities = (entities) =>
  entities.filter(filterCars)

export const getDocumentFromEntities = (entities) =>
  entities
    .filter(filterDocuments)
    .map(mapDocuments)
    .filter(identity)
    .sort(sortDocuments)

export const getName = (data) =>
  data && data.firstname && data.lastname
    ? `${data.firstname} ${data.lastname}`
    : ''

export const extractDealerData = compose(
  applySpec({
    fullName: getName,
    dealerId: path(['internalId']),
    companyName: path(['metaInfo', 'companyName']),
    phone: path(['metaInfo', 'phone']),
    dealerManager: compose(getName, path(['dealerManager'])),
    dealerLogin: path(['email'])
  }),
  path(['data']),
  find(propEq('entityType', TICKET_ENTITY_TYPES.USER))
)
export const extractCarData = compose(
  applySpec({
    carInternalId: pathOr(NotAvailable, ['entityInternalId']),
    carId: path(['entityId'])
  }),
  find(propEq('entityType', TICKET_ENTITY_TYPES.CAR))
)

const ticketIsReviewed = ({statusValue}) =>
  statusValue !== TICKET_ENTITY_STATUS.OPEN
const onlyApproved = ({fileStatus}) => fileStatus === TICKET_STATUS.APPROVED
const hasCategory = ({category}) =>
  !isNil(category) && category !== NotAvailable

export const transformTicket = ({status, ticketEntity, createdAt, id}) => {
  const documentList = getDocumentFromEntities(ticketEntity)
  const allDocumentsReviewed = documentList.every(ticketIsReviewed)
  const allApprovedWithCategories = documentList
    .filter(onlyApproved)
    .every(hasCategory)

  return {
    id,
    status,
    createdAt,
    documentList,
    allDocumentsReviewed,
    allApprovedWithCategories,
    ...extractDealerData(ticketEntity),
    ...extractCarData(ticketEntity)
  }
}

export const transformList = (data) => values(data).map(transformTicket)
