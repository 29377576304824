import omit from 'lodash/omit'

import {PayslipContent} from './subtable/PayslipContent'

export const ExpandableComponent = (props) => {
  const prop = omit(props, [
    'rowIndex',
    'data',
    'rowId',
    'expandableComponentProps'
  ])

  const {data, rowId, expandableComponentProps} = props

  return (
    <PayslipContent
      {...prop}
      {...expandableComponentProps}
      updateTicketEntity={expandableComponentProps.updateTicketEntity}
      carPaymentReceiptsSelection={
        expandableComponentProps.carPaymentReceiptsSelection[rowId]
      }
      data={data}
      subtableSummaryFields={expandableComponentProps.subtableSummaryFields}
      addCarPaymentSelection={expandableComponentProps.addCarPaymentSelection}
      locale={expandableComponentProps.locale}
      rowId={rowId}
      t={expandableComponentProps.t}
    />
  )
}
