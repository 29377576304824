import axios from 'axios'
import gql from '@fcg/lib-gql'
import {
  InvalidCredError,
  InvalidTokenError,
  NoResponseError
} from './utils/errors'
import * as urls from './config/api'
import {
  getToken,
  getOktaToken,
  removeAuth,
  removeGoogleAuth
} from './utils/authToken'
import merge from 'lodash/merge'
import {RequestLogger} from '@fcg/client-logger'
import {logData, errors} from './api/helper'

axios.defaults.method = 'POST'
axios.defaults.withCredentials = true

const defaultInterceptor = {
  request: [
    (config) => {
      config.requestLogger = new RequestLogger()

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  ],
  response: [
    (response) => {
      logData(response)

      let data = response.data.data

      try {
        const {extractPureData, extractList, gqlMethod} = response.config

        if (data && extractPureData && gqlMethod) {
          data = data[gqlMethod]
        }

        if (extractList && data) {
          data = data.list
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return data
      }
    },
    (error) => {
      let customError =
        typeof error.response !== 'undefined'
          ? error.response.data
          : new NoResponseError()

      if (typeof error.response !== 'undefined') {
        logData(error.response)

        const CustomException = errors[error.response.status]

        if (typeof CustomException !== 'undefined') {
          const message =
            error.response.data?.message ||
            error.response.data.errors?.[0]?.message

          customError = new CustomException(message)
        }
        if (customError instanceof InvalidCredError) {
          const loginPath = location.pathname
            ? `/login?returnUrl=${location.pathname}${location.search}`
            : '/login'
          removeAuth()
          removeGoogleAuth()
          window.location.href = loginPath
        }
      } else {
        logData(error)
      }

      return Promise.reject(customError)
    }
  ]
}

class ApiClient {
  constructor(
    domain,
    interceptors = [],
    fields = null,
    disableDefaultResponseInterceptor = false
  ) {
    const baseURL = urls[`${domain}ApiUrl`]

    if (typeof baseURL === 'undefined') {
      throw new Error('Provide a valid domain name')
    }

    this.domain = domain
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/graphql'
      }
    })

    this.client.interceptors.request.use(...defaultInterceptor.request)
    if (!disableDefaultResponseInterceptor) {
      this.client.interceptors.response.use(...defaultInterceptor.response)
    }

    interceptors.forEach((interceptor) =>
      this.client.interceptors.response.use(
        interceptor.response,
        interceptor.error
      )
    )

    this.requestInterceptors = []
    this.transform = []
    this.increaseTimeout = false
    this.fields = {}
    this.additionalRequestConfigs = []
    this.extractPureData = false
    this.extractList = false

    if (fields !== null) {
      this.fields = {...fields}
    }
  }

  withTransform = (transformRequest) => {
    this.transform = Array.isArray(transformRequest)
      ? [...this.transform, ...transformRequest]
      : [...this.transform, transformRequest]

    return this
  }

  apiCall = async (data, ignoreLoggingRequestBody = false, gqlMethod = '') => {
    try {
      const requestData = {
        gqlMethod,
        data,
        headers: {
          'x-increase-timeout': this.increaseTimeout
        },
        ignoreLoggingRequestBody,
        ...this.calculateAdditionalConfigs(),
        extractPureData: this.extractPureData,
        extractList: this.extractList
      }

      const token = getToken()
      const oktaToken = getOktaToken && getOktaToken()

      if (!token) {
        throw new InvalidTokenError()
      }

      if (token) {
        if (oktaToken) {
          requestData.headers['x-okta-client-id'] = oktaToken.clientId
          requestData.headers['x-okta-nonce'] =
            oktaToken.claims && oktaToken.claims.nonce
        }
        requestData.headers.Authorization = `Bearer ${token}`
      }

      const response = await this.client.request(requestData)

      this.clearHelpers()
      this.clearInterceptors()
      this.clearTransform()
      this.clearRequestConfigs()
      this.clearPureData()
      this.clearExtractList()

      return response
    } catch (error) {
      this.clearHelpers()

      throw error
    }
  }

  clearHelpers = () => {
    this.clearInterceptors()
    this.clearTransform()
    this.clearIncreasedTimeout()
  }

  calculateAdditionalConfigs = () =>
    this.additionalRequestConfigs.length
      ? this.additionalRequestConfigs.reduce(
          (acc, config) => merge(acc, config()),
          {}
        )
      : {}

  prepare = (method, args, fields) => {
    const params =
      this.transform.length > 0
        ? this.transform.reduce(
            (args, transform) => ({...transform(args)}),
            args
          )
        : args
    const structure =
      this.fields && this.fields[fields] ? this.fields[fields] : fields

    return gql(method, params, structure)
  }

  mutation = (method, args, structure) => {
    const data = `mutation ${this.prepare(method, args, structure)}`
    const response = this.apiCall(data)
    this.clearTransform()

    return response
  }

  query = (method, args, structure, ignoreLoggingRequestBody = false) => {
    const response = this.apiCall(
      this.prepare(method, args, structure),
      ignoreLoggingRequestBody,
      method
    )
    this.clearTransform()

    return response
  }

  aliasedQuery = (method, alias, args, structure) => {
    const request = Object.keys(alias).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.prepare(method, {...args, ...alias[key]}, structure)
      }),
      {}
    )
    const data = gql.alias(request)

    const response = this.apiCall(data, structure)
    this.clearTransform()

    return response
  }

  alias = (queries) => {
    const request = Object.keys(queries).reduce((acc, key) => {
      const {endpoint, query, structure} = queries[key]

      return {
        ...acc,
        [key]: this.prepare(endpoint, query, structure)
      }
    }, {})
    const data = gql.alias(request)

    return this.apiCall(data)
  }

  aliasedMutation = (endpoint, queries, structure) => {
    const request = Object.keys(queries).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.prepare(endpoint, queries[key], structure)
      }),
      {}
    )
    const data = `mutation ${gql.alias(request)}`

    return this.apiCall(data)
  }

  useInterceptor = ({response, error}) =>
    this.client.interceptors.response.use(response, error)

  ejectInterceptor = (interceptor) =>
    this.client.interceptors.response.eject(interceptor)

  clearInterceptors = () => {
    this.requestInterceptors.forEach((interceptor) =>
      this.ejectInterceptor(interceptor)
    )

    this.requestInterceptors = []

    return this
  }

  withInterceptor = (interceptor) => {
    const interceptorId = this.useInterceptor(interceptor)

    this.requestInterceptors.push(interceptorId)

    return this
  }

  withAdditionalRequestConfig = (requestConfigs) => {
    this.additionalRequestConfigs = Array.isArray(requestConfigs)
      ? [...this.additionalRequestConfigs, ...requestConfigs]
      : [...this.additionalRequestConfigs, requestConfigs]

    return this
  }

  clearTransform = () => {
    this.transform = []

    return this
  }

  clearFieldFilter = () => {
    this.fieldFilter = null
  }

  withIncreasedTimeout = () => {
    this.increaseTimeout = true

    return this
  }

  clearIncreasedTimeout = () => {
    this.increaseTimeout = false

    return this
  }

  clearRequestConfigs = () => {
    this.additionalRequestConfigs = []
  }

  asPureData = () => {
    this.extractPureData = true

    return this
  }

  asList = () => {
    this.extractList = true

    return this
  }

  clearPureData = () => {
    this.extractPureData = false

    return this
  }

  clearExtractList = () => {
    this.extractList = false

    return this
  }
}

export default ApiClient

export {gql}
