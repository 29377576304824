import axios from 'axios'
import gql from '@fcg/lib-gql/gql'

import * as fields from './fields'
import * as DriverFields from '../driver/fields'
import {
  setAuth,
  getToken,
  getOktaToken,
  removeAuth,
  removeGoogleAuth
} from '../../utils/authToken'
import {formatDealerOptions, formatResponse, formatList} from './helpers'
import {authApiUrl} from '../../config/api'
import {upsertDealer} from '../auction/requests'
import {ApiClient} from '@fcg/admin-api-client'
import {transformEmptyValues} from '../common/helpers'

const client = new ApiClient('auth')
const DEALER_USER_CLASS = new gql.EnumType('DEALER')
const INTERNAL_USER_CLASS = new gql.EnumType('INTERNAL')

const Axios = axios.create()
Axios.interceptors.response.use(undefined, (error) => {
  const status = error?.response?.status
  if (status === 440) {
    const loginPath = location.pathname
      ? `/login?returnUrl=${location.pathname}${location.search}`
      : '/login'
    removeAuth()
    removeGoogleAuth()
    window.location.href = loginPath
  }
})

export async function query(
  method,
  args,
  responseStructure,
  ignoreLoggingRequestBody = false,
  options = {}
) {
  options = options || {}
  const request = {
    method: 'POST',
    url: authApiUrl,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/graphql'
    },
    data: gql(method, args, responseStructure),
    ignoreLoggingRequestBody,
    ...options
  }

  // Token will be ignored if login method is called
  if (method !== 'login') {
    const token = getToken()
    const oktaToken = getOktaToken()

    if (oktaToken) {
      request.headers['x-okta-client-id'] = oktaToken.clientId
      request.headers['x-okta-nonce'] =
        oktaToken.claims && oktaToken.claims.nonce
    }

    request.headers.Authorization = `Bearer ${token}`
  }

  try {
    return Axios(request)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)

    return e
  }
}

export async function authenticateJumpCloudToken(
  accessToken,
  idToken,
  clientId
) {
  if (!accessToken) {
    throw 'Access token not found. Re-login'
  }

  const result = await axios({
    url: '/auth',
    method: 'POST',
    contentType: 'application/json',
    data: {jumpCloud: {accessToken, idToken, clientId}}
  })

  if (result.data) {
    const {token: _, ...data} = result.data
    setAuth(result.data)

    const userList = await getUserList({id: result.data.user.id})
    const user = userList.user.list[0]

    return {...result.data, user}
  }
}

export async function authenticateOktaToken(idToken, clientId, nonce) {
  if (!idToken || !clientId || !nonce) {
    throw 'Id token not found. Re-login'
  }

  const result = await axios({
    url: '/auth',
    method: 'POST',
    contentType: 'application/json',
    data: {idToken, clientId, nonce}
  })

  if (result.data) {
    const {token: _, ...data} = result.data
    setAuth(data)

    return data
  }
}

export async function sendGoogleCreds(credentials) {
  try {
    let gToken = ''
    let decodedToken = {}
    const headers = {}

    const result = await axios({
      url: '/auth',
      method: 'POST',
      contentType: 'application/json',
      headers,
      data: credentials
    })

    if (result.data !== false) {
      gToken = credentials.googleToken.split('.')[1]
      decodedToken = JSON.parse(window.atob(gToken))

      result.data.token = credentials.googleToken
      result.data.exp =
        decodedToken && 'exp' in decodedToken ? decodedToken.exp : undefined

      setAuth({...result.data})
    }

    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getGoogleOAuthUrl({redirectUrl}) {
  try {
    const result = await axios({
      url: '/googleApi/oAuthUrl',
      method: 'POST',
      contentType: 'application/json',
      data: {redirectUrl}
    })
    return result
  } catch (e) {
    return e
  }
}

export async function login(params) {
  const result = await query(
    'login',
    {email: params.email, password: params.password},
    ['token'],
    false,
    {url: '/api/auth/login'}
  )
  const data = formatResponse(result)

  if (typeof data !== 'undefined' && typeof data.login !== 'undefined') {
    const {id, exp} = JSON.parse(window.atob(data.login.token.split('.')[1]))

    setAuth({...data.login, exp})
    const userList = await getUserList({id: [id]})
    const user = userList.user.list[0]

    return {...data.login, user, exp}
  } else {
    throw new Error({message: 'Empty response'})
  }
}

export async function createUser(options) {
  return query(
    'createUser',
    {
      ...options,
      country: new gql.EnumType(options.country),
      userClass: INTERNAL_USER_CLASS
    },
    fields.user.all,
    true
  )
}

export async function updateUser(options) {
  return query('updateUser', options, fields.user.all)
}

export async function deleteUser(options) {
  return query('deleteUser', options, fields.user.delete)
}

export async function getUserInfo(params) {
  return query('userInfo', params, fields.user.userInfo)
}

export async function getUserStatus(params) {
  const res = await query('userInfo', params, [
    'id',
    'isCheckedIn',
    'groups',
    ['metaInfo', fields.listMetaFields],
    ['permissions', fields.permissionFields]
  ])

  return formatResponse(res)
}

export async function getUserList(options) {
  if (options.userClass) {
    options.userClass = new gql.EnumType(options.userClass)
  }

  const res = await query('user', options, fields.user.list)

  return formatResponse(res)
}

export async function getRoleId(options) {
  const res = await query('role', options, fields.singleRoles.list)

  return formatResponse(res)
}

export async function getDealerList(options) {
  const res = await client.query(
    'user',
    {...options, userClass: new gql.EnumType('DEALER')},
    fields.dealer.list
  )

  return formatList(res, this.getDealerManager)
}

export async function getDealer(id) {
  return client.query('user', {id}, fields.dealer.individual)
}

export async function getDriver(id) {
  return client.query('user', {id}, DriverFields.driverList)
}

export async function getDriverDetails(id) {
  return client.query('user', {id}, fields.driverFields)
}

export async function getDealerManager(id) {
  return client.query('user', {id, deletedUser: true}, fields.dealer.individual)
}

export async function createDealer(params) {
  const {country, ...data} = params

  data.userClass = DEALER_USER_CLASS
  data.country = new gql.EnumType(country.toUpperCase())

  const res = await client
    .withTransform(transformEmptyValues)
    .query(
      'createUser',
      formatDealerOptions(data),
      ['email', 'id', ['metaInfo', ['maxAmountBids']]],
      true
    )
  const group = `dealer.${country}`

  await addGroup({user: res.createUser.email, group})

  const maxAmountBids =
    (res.createUser.metaInfo && res.createUser.metaInfo.maxAmountBids) || null

  await upsertDealer(res.createUser.id, maxAmountBids)

  return res
}

export async function updateDealer(options) {
  return client
    .withTransform(transformEmptyValues)
    .query('updateUser', formatDealerOptions(options), fields.dealer.all)
}

export async function getGroupList(options = {}) {
  return query('group', options, fields.group.all)
}

export async function addGroup(options) {
  return query('addGroup', options, fields.group.add)
}

export async function deleteGroup(options) {
  return query('removeGroup', options, fields.group.delete)
}

export async function changePassword(options) {
  return query('changePassword', options, fields.user.changePassword, true)
}

export async function allRoles(options = {}) {
  return query(
    'role',
    {
      ...options,
      country: new gql.EnumType(options.country),
      roleType: new gql.EnumType(options.roleType)
    },
    fields.role.list
  )
}

export async function roleUsers(options = {}) {
  return query(
    'role',
    {
      ...options,
      country: new gql.EnumType(options.country),
      roleType: new gql.EnumType(options.roleType)
    },
    fields.role.users
  )
}

export async function addUserToRole(options = {}) {
  return query('addUsersToRole', options)
}

export async function removeUsersFromRole(options = {}) {
  return query('removeUsersFromRole', options)
}

export async function allPermissions(options = {}) {
  return query(
    'permission',
    {
      ...options,
      country: new gql.EnumType(options.country),
      label: options.label
    },
    fields.permission.list
  )
}

export async function rolePermissions(options = {}) {
  return query(
    'role',
    {
      ...options,
      country: new gql.EnumType(options.country),
      roleType: new gql.EnumType(options.roleType)
    },
    fields.role.permissions
  )
}

export function removePermissionsFromRole(options) {
  return this.query('removePermissionsFromRole', options)
}

export function addPermissionsToRole(options) {
  return this.query('addPermissionsToRole', options)
}

export function createRole(options) {
  return this.query(
    'createRole',
    {
      ...options,
      country: new gql.EnumType(options.country),
      roleType: new gql.EnumType(options.roleType)
    },
    fields.role.singleRoleFields
  )
}

export function deleteRole(options) {
  return client.mutation('deleteRole', options)
}

export function getDealerProfileDetails(id) {
  return client.query('user', {id}, fields.dealer.profile)
}

export const setUserCheckInStatus = () => {
  return client.mutation('checkIn')
}

export const setUserCheckOutStatus = () => {
  return client.mutation('checkOut')
}

export async function logoutRequest({email}) {
  const token = getToken()
  const headers = {Authorization: `Bearer ${token}`}
  try {
    const result = await axios({
      url: '/logout',
      method: 'POST',
      headers,
      contentType: 'application/json',
      data: {email}
    })
  } catch (e) {
    return e
  }
}
