import {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'

// Table
import {TableBody} from './Table/Body'
import {TableHeader} from './Table/Header'
// Store
import * as selectors from '../../../store/calendar/selectors'
import {getCountryCode} from '../../../store/config/selectors'
import {LoaderOverlay} from './Table/LoaderOverlay'

const Calendar = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: absolute;
`
const ScrollableViewport = styled.div`
  height: ${({isLoading}) => (isLoading ? '100%' : 'auto')};
  width: ${({isLoading}) => (isLoading ? '100%' : 'auto')};
  overflow: ${({isLoading}) => (isLoading ? 'hidden' : 'visible')};
  position: absolute;
`

class AppointmentCalendar extends Component {
  onCellClick = (slot, placeIndex) => {
    if (typeof this.props.onCellClick === 'function') {
      const place = this.props.appointmentLocations[placeIndex]

      if (place && place.value) {
        this.props.onCellClick(place.value, slot)
      }
    }
  }

  render() {
    return (
      <Calendar>
        <LoaderOverlay isLoading={this.props.isLoading} />
        <ScrollableViewport isLoading={this.props.isLoading}>
          <TableHeader columns={this.props.appointmentLocations} />
          <TableBody
            rows={this.props.appointmentsSlots}
            isLoading={this.props.isLoading}
            onCellClick={this.onCellClick}
          />
        </ScrollableViewport>
      </Calendar>
    )
  }
}

const mapStateToProps = (state) => ({
  appointmentsSlots: selectors.getRowData(state),
  appointmentLocations: selectors.getSelectedLocations(state),
  isLoading: selectors.isCalendarLoading(state),
  config: {
    countryCode: getCountryCode(state)
  }
})

export default connect(mapStateToProps, null)(AppointmentCalendar)
