import {ResourceManager} from '../../pages/ModulePages/ResourceManager'

export default {
  path: 'resource-manager',
  exact: false,
  label: 'Resource Manager',
  key: 'resourceManager',
  component: ResourceManager,
  fields: {}
}
