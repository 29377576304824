import gql from '@fcg/lib-gql/gql'

export function formatCountry(request) {
  const country =
    typeof request.country === 'string'
      ? new gql.EnumType(request.country)
      : request.country

  return {...request, country}
}

export function formatCountryArray(request) {
  const countries =
    typeof request.countries !== 'string' && request.countries.length > 0
      ? request.countries.map((country) => new gql.EnumType(country))
      : request.countries

  return {...request, countries}
}
