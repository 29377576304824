export const categorySchema = {
  fileName: null,
  uploadedAt: null,
  createdBy: null
}

const state = {
  isFetchingCategoryList: false,
  isDownloadingCategoryItem: false,
  downloadItemError: null,
  isDownloadingCategory: false,
  downloadError: null,
  error: null,
  initialData: {...categorySchema},
  categoryList: [],
  count: null,
  list: {
    page: 1,
    limit: 25,
    fetching: false,
    sort: [{field: 'createdAt', order: 'DESC'}],
    fields: []
  },
  isUploadingCategoryData: false,
  uploadError: null
}

export const getInitialState = (config) => {
  return {
    options: {},
    ...state,
    list: {
      ...state.list,
      ...config,
      fields: Object.values(config.fields)
    }
  }
}
