import {compose} from 'redux'
import {connect} from 'react-redux'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'

const WithRoles = (Component) => (props) => {
  const rest = omit(props, ['readOnly', 'dispatch'])
  const {readOnly} = props

  return readOnly !== true ? <Component {...rest} /> : null
}

const mapStateToProps = ({userReducer, authReducer}, {userGroup}) => {
  let readOnly = false
  if (!isNil(authReducer.isAuthenticated.user.groups)) {
    const searchClump = authReducer.isAuthenticated.user.groups.toString()
    if (userGroup && searchClump.includes(userGroup)) {
      readOnly = true
    }
  }
  const userRoles = isNil(userReducer.currentUser)
    ? []
    : userReducer.currentUser.groups

  return {userRoles, readOnly}
}

const withRoles = compose(connect(mapStateToProps, null), WithRoles)

export default withRoles
