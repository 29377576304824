import React from 'react'
import Button from '@material-ui/core/Button'

const SaveButton = ({
  onSubmit,
  dataTest,
  disabled,
}: {
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled: boolean,
  dataTest: string
}) => {
  return (
    <Button
      onClick={onSubmit}
      variant='contained'
      color='primary'
      disabled={disabled}
      data-test={dataTest}
    >
      SAVE
    </Button>
  )
}

export default SaveButton
