import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'

const client = new ApiClient('car')

export function getCountryOptions(params = {}) {
  return client.query('countryOptions', params, fields.countryoptionList)
}

export function createCountryOption(params = {}) {
  return client.mutation(
    'createCountryOptions',
    params,
    fields.countryOptionFields
  )
}

export function deleteCountryOption(params = {}) {
  return client.mutation('deleteCountryOptions', params)
}
