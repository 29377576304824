import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import DocumentUpload from './DocumentUpload'
import {setUploading, setReference} from '../../store/documentConfig/actions'
import * as documentSelectors from '../../store/documentConfig/selectors'

const noop = () => {}
const LoaderWrapper = styled.div`
  text-align: center;
`
const Loader = () => (
  <LoaderWrapper data-test='loader'>
    <CircularProgress />
  </LoaderWrapper>
)

export const UploadDocumentDialog = ({
  isOpen = false,
  t = noop,
  onDialogClose = noop,
  onDialogConfirm = noop,
  onUploadDocument = noop,
  maxWidth = 'md',
  // Document store
  setUploading = noop,
  isUploading = false,
  fileTypeOptions = [],
  reference = null,
  // External loading
  // Useful for actions after uploading, attaching documents to a ticket
  isLoading = false,
  // Upload list style
  listWidth = 'lg',
  uploadAreaWidth = 'md',
  title = '',
  // Document config
  visibility = 'internal',
  status = 'reviewPending',
  maxFileSize = 10,
  fetchOnlyCurrentDocs = true,
  // Table customisation
  customColumns = [],
  rowHeight,
  tableHeight,
  hiddenColumns
}) => (
  <Dialog open={isOpen} maxWidth={maxWidth} fullWidth data-test='upload-dialog'>
    <DialogTitle id='upload-dialog-title' data-test='upload-dialog-title'>
      {t(title)}
    </DialogTitle>
    <DialogContent data-test='upload-dialog-content'>
      {isLoading ? (
        <Loader />
      ) : (
        <DocumentUpload
          isUploading={isUploading}
          uploadDocument={onUploadDocument}
          fileTypeOptions={fileTypeOptions}
          setUploading={setUploading}
          listWidth={listWidth}
          uploadAreaWidth={uploadAreaWidth}
          reference={reference}
          visibility={visibility}
          status={status}
          maxFileSize={maxFileSize}
          fetchOnlyCurrentDocs={fetchOnlyCurrentDocs}
          customColumns={customColumns}
          rowHeight={rowHeight}
          tableHeight={tableHeight}
          hiddenColumns={hiddenColumns}
        />
      )}
    </DialogContent>
    {!isUploading && !isLoading ? (
      <DialogActions data-test='upload-dialog-actions'>
        <Button onClick={onDialogClose} color='primary' data-test='button-no'>
          {t('global.no')}
        </Button>
        <Button
          variant='raised'
          color='primary'
          type='submit'
          data-test='button-yes'
          onClick={onDialogConfirm}
        >
          {t('global.yes')}
        </Button>
      </DialogActions>
    ) : null}
  </Dialog>
)

const mapStateToProps = (state) => ({
  reference: documentSelectors.getDocumentReference(state),
  fileTypeOptions: documentSelectors.getDealerFileTypeOptions(state),
  isUploading: documentSelectors.isUploading(state),
  addedDocuments: documentSelectors.hasAddedDocuments(state)
})
const mapDispatchToProps = (dispatch) => ({
  setUploading: (payload) => dispatch(setUploading(payload)),
  setReference: (id) => dispatch(setReference(id))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(UploadDocumentDialog))
