import {NumberInputFilter, SellableFilter} from '../../../pages/CarData/Filters'

export const carDataFields = {
  id: {
    key: 'id',
    label: 'carData.column.id',
    type: 'string',
    isFilterable: false
  },
  make: {
    key: 'make',
    label: 'carData.column.make',
    type: 'string'
  },
  model: {
    key: 'model',
    label: 'carData.column.model',
    type: 'string'
  },
  trim: {
    key: 'trim',
    label: 'carData.column.trim',
    type: 'string'
  },
  transmission: {
    key: 'transmission',
    label: 'carData.column.transmission',
    type: 'string'
  },
  location: {
    key: 'location',
    label: 'carData.column.location',
    type: 'string'
  },
  year: {
    // TODO: Should be a number field without any format
    key: 'year',
    label: 'carData.column.year',
    type: 'number',
    filter: NumberInputFilter
  },
  price: {
    key: 'price',
    label: 'carData.column.price',
    type: 'number',
    filter: NumberInputFilter
  },
  minprice: {
    key: 'minprice',
    label: 'carData.column.minprice',
    type: 'number',
    filter: NumberInputFilter
  },
  maxprice: {
    key: 'maxprice',
    label: 'carData.column.maxprice',
    type: 'number',
    filter: NumberInputFilter
  },
  priceb: {
    key: 'priceb',
    label: 'carData.column.priceb',
    type: 'number',
    filter: NumberInputFilter
  },
  minpriceb: {
    key: 'minpriceb',
    label: 'carData.column.minpriceb',
    type: 'number',
    filter: NumberInputFilter
  },
  maxpriceb: {
    key: 'maxpriceb',
    label: 'carData.column.maxpriceb',
    type: 'number',
    filter: NumberInputFilter
  },
  minmarketprice: {
    key: 'minmarketprice',
    label: 'carData.column.minmarketprice',
    type: 'number',
    filter: NumberInputFilter
  },
  maxmarketprice: {
    key: 'maxmarketprice',
    label: 'carData.column.maxmarketprice',
    type: 'number',
    filter: NumberInputFilter
  },
  priceText: {
    key: 'priceText',
    label: 'carData.column.priceText',
    type: 'string'
  },
  bodytype: {
    key: 'bodytype',
    label: 'carData.column.bodytype',
    type: 'string'
  },
  sellable: {
    key: 'sellable',
    label: 'carData.column.isSellable',
    type: 'select',
    prefix: 'carData.field.isSellable',
    filter: SellableFilter
  }
}
