import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import {formatSlotOptionsInterceptor, formatSlotService} from './helpers'
const client = new ApiClient('booking')

export function getAvailableSlots(params = {}) {
  return client
    .withTransform(formatSlotService)
    .withInterceptor(formatSlotOptionsInterceptor)
    .query('availableSlots', params, fields.bookingSlots)
}
