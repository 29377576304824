import moment from 'moment'
import {Landing} from '../../../pages/CRM'
import {taskFields} from './tasks'
import {leadFields} from './leads'
import * as filters from '../../../pages/CRM/Comment/Filters'

export default {
  path: 'home',
  label: 'menu.crm.myCRM',
  component: Landing,
  tasks: {
    fields: taskFields,
    sort: [{field: 'dueDate', order: 'ASC'}],
    presets: [
      {
        id: 'open_today',
        name: 'Open until today',
        default: true,
        filters: [
          {
            id: 'dueDate',
            label: 'task.dueDate.label',
            key: 'dueDate',
            type: 'date',
            value: {
              from: moment()
                .year(2010)
                .month(0)
                .date(1)
                .startOf('day'),
              to: moment().endOf('day')
            }
          },
          {
            id: 'status',
            label: 'global.status.label',
            mask: ['OPEN'],
            key: 'status',
            prefix: 'task.status',
            value: ['OPEN'],
            filter: filters.StatusFilter
          }
        ]
      },
      {
        id: 'all_open',
        name: 'All Open',
        filters: [
          {
            id: 'status',
            label: 'global.status.label',
            mask: ['OPEN'],
            key: 'status',
            prefix: 'task.status',
            value: ['OPEN'],
            filter: filters.StatusFilter
          }
        ]
      }
    ]
  },
  leadTable: {
    fields: leadFields,
    sort: [{field: 'createdAt'}]
  }
}
