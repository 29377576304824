import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../utils/typesUtil'

export const resourceName = 'auctionSchedules'

export const fetchAuctionSchedulesList = `${resourceName}/FETCH_${resourceName}_LIST`
export const fetchAuctionSchedulesListSuccess = `${resourceName}/FETCH_${resourceName}_LIST_SUCCESS`
export const fetchAuctionSchedulesListError = `${resourceName}/FETCH_${resourceName}_LIST_ERROR`

export const toggleRow = `${resourceName}/TOGGLE_ROW`

export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`

export const createAuctionFlow = `${resourceName}/CREATE_AUCTION_FLOW`
export const createAuctionFlowSuccess = `${resourceName}/CREATE_AUCTION_FLOW_SUCCESS`
export const createAuctionFlowError = `${resourceName}/CREATE_AUCTION_FLOW_ERROR`

export const deleteAuctionFlow = `${resourceName}/DELETE_AUCTION_FLOW`
export const deleteAuctionFlowSuccess = `${resourceName}/DELETE_AUCTION_FLOW_SUCCESS`
export const deleteAuctionFlowError = `${resourceName}/DELETE_AUCTION_FLOW_ERROR`

export const formTypes = getFormTypes(resourceName)
export const sortTypes = getSortTypes(resourceName)
export const columnTypes = getColumnTypes(resourceName)
export const dataLoadingTypes = getDataLoadingTypes(resourceName)

export const formError = `${resourceName}/FORM_ERROR`
export const formSuccess = `${resourceName}/FORM_SUCCESS`

export const cleanUpDialog = `${resourceName}/CLEAN_UP_DIALOG`
