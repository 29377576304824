import {createSelector} from 'reselect'
import values from 'lodash/values'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import flatten from 'lodash/flatten'
import merge from 'lodash/merge'
import moment from 'moment'
// Selectors
import {
  getResourcesOfType,
  getCollection,
  getCollectionKeys
} from '../resources/selectors'
import {getOptionsArrayByType} from '../crm/selectors'
import {getCountryCode} from '../config/selectors'
// config
import {
  dateRequestFormat,
  dateDisplayFormat,
  filterPriority
} from '../../pages/Calendar/components/Filters/filterConfig'
import {getMetaField} from '../auth/selectors'

const timeFormat = 'hh:mm:ss'
const timeSlotArray = [...new Array(24)]

const getUniqueValues = (filter) => {
  const uniqueVals = new Set(flatten(filter.value))

  return Array.from(uniqueVals)
}

const makeContactParam = (key, value) => ({
  lead: {contact: {[key]: value}}
})

const replaceSpaceWithDot = (value) => value.trim().replace(' ', '.')
const concatValues = (value) => value.join('_')

const filterFormats = {
  date: {
    request: (filter) => ({
      [filter.key]: {
        from: moment(filter.value).format(dateRequestFormat),
        to: moment(filter.value).format(dateRequestFormat)
      }
    }),
    collection: (filter) => {
      const date = moment(filter.value).format()

      return `${date}_${date}`
    }
  },
  placeId: {
    request: (filter) => ({[filter.key]: getUniqueValues(filter)}),
    collection: getUniqueValues
  },
  firstname: {
    request: (filter) => makeContactParam('firstname', filter.value),
    collection: (filter) => replaceSpaceWithDot(filter.value)
  },
  lastname: {
    request: (filter) => makeContactParam('lastname', filter.value),
    collection: (filter) => replaceSpaceWithDot(filter.value)
  },
  email: {
    request: (filter) => makeContactParam('email', filter.value),
    collection: (filter) => replaceSpaceWithDot(filter.value)
  },
  status: {
    request: (filter) => ({status: filter.value}),
    collection: (filter) => replaceSpaceWithDot(concatValues(filter.value))
  },
  bookingId: {
    request: (filter) => ({bookingId: filter.value}),
    collection: (filter) => filter.value
  }
}

const handlerMapping = {
  location: () => 'placeId',
  city: () => 'placeId'
}

const getHandler = (key) => {
  if (handlerMapping[key]) {
    return handlerMapping[key]()
  }

  return key
}

const collectionNameConfig = filterPriority.map((filterKey) => ({
  key: getHandler(filterKey),
  format: (filter) =>
    `${filterKey}:${filterFormats[getHandler(filterKey)].collection(filter)}`
}))

const appointmentForm = (state) => state.appointment.form
const selectorPath = (state) => state.appointmentCalendar
const getAllPlaces = (state) => getResourcesOfType('place')(state)
const getAllLeads = (state) => getResourcesOfType('lead')(state)
const getAllContacts = (state) => getResourcesOfType('contact')(state)
const getAllAppointments = (state) => getResourcesOfType('appointment')(state)
const getAppointmentsCollection = (state) =>
  getCollection('appointment', getAppointmentCollectionName(state), true)(state)
const getFilters = (state) => selectorPath(state).filters
export const getDayPicker = (state) => selectorPath(state).dayPicker

export const isCalendarLoading = (state) =>
  selectorPath(state).locationOptions.isLoading || selectorPath(state).isLoading

export const getSelectedFilters = (state) => selectorPath(state).filters || []

export const getSavedFilters = (state) => selectorPath(state).savedFilters || []

export const getSelectedAppointmentPath = (state) =>
  selectorPath(state).selectedAppointment

export const getFilterByKey = (key) => (state) => {
  const filters = getSelectedFilters(state)

  return filters.find((filter) => filter.key === key)
}

export const getCollectionName = createSelector(getFilters, (filters) => {
  if (!filters || !filters.length) {
    return ''
  }

  return collectionNameConfig
    .reduce((acc, config) => {
      const filter = filters.find((filterItem) => filterItem.key === config.key)

      if (!filter) {
        return acc
      }

      const filterString = config.format(filter)

      return !filterString ? acc : [...acc, filterString]
    }, [])
    .join('_')
})

export const getAllCollections = (state) =>
  getCollectionKeys('appointment', '')(state)

export const getAppointmentCollectionName = createSelector(
  [getCollectionName, getAllCollections],
  (name, allCollections) => {
    if (!allCollections) {
      return `${name}_appointment`
    }

    const existingCollection = allCollections.find((collection) =>
      collection.includes(name)
    )

    if (existingCollection) {
      return existingCollection
    }

    return `${name}_appointment`
  }
)

export const getPlaceByType = (type) =>
  createSelector(getAllPlaces, (places) => {
    if (!places) {
      return []
    }

    return values(places).map((place) => ({
      value: place.id,
      label: place[type]
    }))
  })

export const getCities = createSelector(getAllPlaces, (places) => {
  if (!places) {
    return []
  }

  const cities = values(places).reduce((cities, place) => {
    if (!cities[place.city]) {
      return {
        ...cities,
        [place.city]: [place.id]
      }
    }

    return {
      ...cities,
      [place.city]: [...cities[place.city], place.id]
    }
  }, {})

  return keys(cities).reduce(
    (cityLocations, name) => [
      ...cityLocations,
      {
        value: cities[name],
        label: name
      }
    ],
    []
  )
})

export const getAllowedLocations = getMetaField('places')
export const getLocations = getPlaceByType('location')
export const getLocationValues = createSelector(getLocations, (locations) =>
  locations.map(({value}) => value)
)
export const getPermittedLocations = createSelector(
  [getLocations, getAllowedLocations],
  (locations, allowedLocations) => {
    if (!allowedLocations || !allowedLocations.length) {
      return locations
    }

    return locations.filter(({value}) => allowedLocations.includes(value))
  }
)

export const getSelectedLocations = createSelector(
  [getPermittedLocations, getFilters],
  (places, filters) => {
    let filteredPlaceId = []

    if (Array.isArray(filters) && filters.length) {
      filters.forEach((filter) => {
        if (filter.key === 'placeId') {
          filteredPlaceId = flatten(filter.value)
        }
      })
    } else if (places) {
      return places
    }

    if (places) {
      return values(places).reduce((acc, place) => {
        if (!filteredPlaceId.length) {
          return [...acc, {...place}]
        } else if (filteredPlaceId.includes(place.value)) {
          return [...acc, {...place}]
        }

        return acc
      }, [])
    }

    return []
  }
)

const appointmentFields = [
  'id',
  'bookingId',
  'date',
  'time',
  'status',
  'placeId',
  'inspectionPointCity',
  'inspectionPointName',
  'address',
  'appointmentAddress'
]
const leadFields = ['country', 'id', 'make', 'model', 'year']
const transformAppointment = (app, lead) => ({
  ...pick(app, appointmentFields),
  lead: pick(lead, leadFields)
})
const getAppointments = createSelector(
  [getAllAppointments, getAppointmentsCollection, getAllLeads],
  (appointments, collection, leads) => {
    if (collection) {
      return collection.reduce(
        (acc, id) =>
          appointments[id]
            ? [
                ...acc,
                transformAppointment(
                  appointments[id],
                  leads[appointments[id].lead]
                )
              ]
            : [acc],
        []
      )
    }

    return []
  }
)

const getFormattedIndex = (index) => {
  if (index < 10) {
    return `0${index}:00:00`
  }

  return `${index}:00:00`
}

export const getAppointmentSlots = createSelector(
  [getAppointments],
  (appointments) => {
    let slots = timeSlotArray.reduce(
      (acc, time, index) => ({
        ...acc,
        [getFormattedIndex(index)]: []
      }),
      {}
    )

    if (Array.isArray(appointments)) {
      for (let i = 0; i < 24; i++) {
        const timeSlot = moment(`${i}:00:00`, timeFormat)
        const timeSlotExtended = moment(`${i + 1}:00:00`, timeFormat)

        slots = appointments.reduce((acc, appointment) => {
          const time = moment(appointment.time, timeFormat)

          if (
            (time.isBefore(timeSlotExtended) && time.isAfter(timeSlot)) ||
            time.isSame(timeSlot)
          ) {
            return {
              ...acc,
              [getFormattedIndex(i)]: [
                ...acc[getFormattedIndex(i)],
                appointment
              ]
            }
          } else {
            return {
              ...acc,
              [getFormattedIndex(i)]: acc[getFormattedIndex(i)] || null
            }
          }
        }, slots)
      }
    }

    return slots
  }
)

const getCells = (appointments, location) => {
  let cell = {
    header: location.value,
    data: {
      appointments: []
    }
  }

  if (Array.isArray(appointments)) {
    appointments.forEach((appointment) => {
      cell =
        appointment.placeId === location.value
          ? {
              ...cell,
              data: {
                appointments: [...cell.data.appointments, appointment]
              }
            }
          : {
              ...cell,
              data: {
                appointments: [...cell.data.appointments]
              }
            }
    })
  }

  return cell
}

export const getRowData = createSelector(
  [getAppointmentSlots, getSelectedLocations],
  (appointmentSlots, locations) =>
    keys(appointmentSlots).map((slotTime) => ({
      id: slotTime,
      cells: locations.map((location) =>
        getCells(appointmentSlots[slotTime], location)
      )
    }))
)

export const getDateFilterValue = createSelector(
  getFilterByKey('date'),
  (dateFilter) => {
    if (!dateFilter) {
      return null
    }

    return moment(dateFilter.value).format(dateDisplayFormat)
  }
)

export const getFiltersWithoutDate = createSelector(
  getSelectedFilters,
  (filters) => filters.filter((filter) => filter.key !== 'date')
)

export const getSelectedFiltersWithDefaults = createSelector(
  getSelectedFilters,
  getLocations,
  (selectedFilters, locations) => {
    const hasPlaceFilter = selectedFilters.find(({key}) => key === 'placeId')

    if (hasPlaceFilter) {
      return selectedFilters
    }

    return [
      ...selectedFilters,
      {
        key: 'placeId',
        value: locations.map(({value}) => value)
      }
    ]
  }
)

export const getSelectedFiltersRequestObject = createSelector(
  getSelectedFiltersWithDefaults,
  (filters) =>
    filters.reduce(
      (acc, filterItem) =>
        merge(filterFormats[filterItem.key].request(filterItem), acc),
      {}
    )
)

export const getAppointmentStatuses = createSelector(
  getOptionsArrayByType('appointmentStatus'),
  (statuses) => statuses
)

export const getAppointmentSlotsRequestParams = createSelector(
  [getSelectedFiltersRequestObject, getCountryCode, getAppointmentStatuses],
  (filters, countryCode, statuses) => ({
    country: countryCode,
    status: statuses,
    ...filters
  })
)

export const getSelectedAppointment = createSelector(
  [
    getSelectedAppointmentPath,
    getAllAppointments,
    getAllLeads,
    getAllPlaces,
    getAllContacts,
    appointmentForm
  ],
  ({id}, appointments, leads, places, contacts, appointmentForm) => {
    if (!id || !appointments) {
      return null
    }

    const selectedAppointment = {
      ...appointments[id],
      appointmentAddress: appointments[id]?.address,
      purchaseCoordinator: appointmentForm.purchaseCoordinator || '',
      purchaseCoordinatorId: appointmentForm.purchaseCoordinatorId || '',
      purchaseCoordinatorPhone: appointmentForm.purchaseCoordinatorPhone || ''
    }

    if (!selectedAppointment) {
      return null
    }

    if (
      leads &&
      selectedAppointment.leadId &&
      leads[selectedAppointment.leadId]
    ) {
      selectedAppointment.lead = leads[selectedAppointment.leadId]
    }

    if (
      leads &&
      contacts &&
      selectedAppointment.lead &&
      typeof selectedAppointment.lead === 'object' &&
      selectedAppointment.lead.contact
    ) {
      selectedAppointment.contact = contacts[selectedAppointment.lead.contact]
    }

    if (
      places &&
      selectedAppointment.placeId &&
      places[selectedAppointment.placeId]
    ) {
      selectedAppointment.place = leads[selectedAppointment.placeId]
    }

    if (selectedAppointment.date && selectedAppointment.time) {
      selectedAppointment.slot = `${selectedAppointment.date} ${selectedAppointment.time}`
    }

    return selectedAppointment
  }
)

export const getAppointmentContactId = createSelector(
  getSelectedAppointment,
  (appointment) => {
    if (!appointment || !appointment.contact) {
      return null
    }

    return appointment.contact.id
  }
)

export const getCalendarId = (state) => getDayPicker(state).id
export const getRefreshHandler = (state) => selectorPath(state).refreshHandler
