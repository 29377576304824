import gql from '@fcg/lib-gql/gql'
import omit from 'lodash/omit'
import * as signalTypes from '../signals/types'

export const createDocRequestParams = (documents) =>
  documents.reduce((acc, docObj) => {
    const doc = omit(docObj, ['entity', 'reference'])

    return [{...doc, visibility: new gql.EnumType(doc.visibility)}, ...acc]
  }, [])

export const showNotification = (variant) => (message) => () => ({
  type: signalTypes.showSnackbarNotification,
  payload: {
    variant,
    message,
    open: true
  }
})
export const showSuccessNotification = showNotification(
  signalTypes.variantTypes.success
)
export const showDocumentSuccessNotification = showSuccessNotification(
  'snackbar.notification.document.success'
)
export const showErrorNotification = showNotification(
  signalTypes.variantTypes.error
)
export const showDocumentErrorNotification = showErrorNotification(
  'snackbar.notification.document.error'
)
