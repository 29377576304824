import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core'
import {hideNonceDialog} from '../../store/webhook/actions'
import styled from 'styled-components'
import {default as selectors} from '../../store/webhook/selectors'
import ContentCopy from '@material-ui/icons/ContentCopy'
import copyToClipboard from '../../utils/copyToClipboard'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}

const SubTitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  margin: 0px 0px 20px 0px;
`

const TextFieldWrapper = styled.div`
  float: left;
`

const CopyIcon = styled(ContentCopy)`
  padding: 10px;
  margin: 15px 20px 0px;
  font-size: 35px !important;
  color: #fff;
  background-color: #3f51b5;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  float: left;
`

class NonceDialog extends Component {
  handleCancel = () => {
    this.props.hideNonceDialog()
  }

  copy = () => {
    const {webhookNonce} = this.props

    copyToClipboard(webhookNonce, 'webhookNonceWrapper')
  }

  render() {
    const {t, webhookNonce} = this.props

    return (
      <Dialog
        open={true}
        maxWidth={'md'}
        fullWidth={true}
        onClose={this.handleCancel}
      >
        <DialogTitle id='form-dialog-title'>
          {t('webhook.nonce.title')}!
        </DialogTitle>
        <DialogContent id='webhookNonceWrapper'>
          <SubTitle>{t('webhook.nonce.subtitle')}</SubTitle>
          <TextFieldWrapper>
            <TextField
              label={t('webhook.nonce.key')}
              defaultValue={webhookNonce}
              disabled={true}
              variant='outlined'
            />
          </TextFieldWrapper>
          <CopyIcon onClick={this.copy} />
        </DialogContent>
        <DialogActions style={dialogActionsStyles}>
          <Button onClick={this.handleCancel} variant='raised' color='primary'>
            {t('webhook.button.finish')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  webhookNonce: selectors.getWebhookNonce(state)
})

const mapDispatchToProps = (dispatch) => ({
  hideNonceDialog: () => dispatch(hideNonceDialog())
})

const WebhookNonceDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(NonceDialog))

export default WebhookNonceDialog
