import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import {
  fetchWebhookList,
  updateFilters,
  toggleSort,
  toggleField,
  deleteWebhook,
  openCreateDialog
} from '../../store/webhook/actions'
import {DeleteDialog} from '../CRM/common'
import {Button} from '@material-ui/core'
import {Add, Delete} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import selectors from '../../store/webhook/selectors'
import CustomSnackbar from '../../components/Snackbar'
import styled from 'styled-components'
import {PermissionWrapper} from '../../components/Permissions'
import {checkUserPermissions} from '../../store/auth/selectors'
import {getVisibleActions, isDeleteButtonVisible} from '../CRM/common/helpers'
import Editor from './Editor'
import NonceDialog from './NonceDialog'

const WebhookWrapper = styled.div`
  tr {
    td:first-child,
    th:first-child {
      padding-left: 24px;
    }
  }
`

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

class WebhookList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delete: {...deleteInitialState},
      isUploadDialogVisible: false
    }

    this.openDeleteDialog = this.openDeleteDialog.bind(this)
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this)
  }

  openCreateDialog = (props) => {
    props.openCreateDialog()
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({item, action}) => {
    const {t} = this.props

    this.setState({
      delete: {
        open: true,
        item,
        heading: t('webhook.delete.title', {name: item.name}),
        content: t('webhook.delete.body', {name: item.name}),
        handleSubmit: () => {
          action(item.id)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  openUploadDialog = () => {
    this.setState({isUploadDialogVisible: true})
  }

  closeUploadDialog = () => {
    this.setState({isUploadDialogVisible: false})
  }

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  singleSelectActions = [
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: 'web.admin.ui.car.webhooks',
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            item: item,
            action: this.props.deleteWebhook
          })
        },
        icon: <Delete />,
        title: 'global.action.delete'
      }
    }
  ]

  render() {
    return (
      <Fragment>
        {this.props.showCreateDialog ? <Editor {...this.props} /> : null}
        {this.props.showNonceDialog ? <NonceDialog /> : null}
        <WebhookWrapper className='page'>
          <PageTableBase
            {...this.props}
            multisort={false}
            title='entity.webhook'
            rowsPerPageOptions={[25, 50, 100]}
            filterable={false}
            singleSelectActions={this.singleSelectTableActions()}
          />
          <ActionButton>
            <PermissionWrapper
              entity='web.admin.ui.car.webhooks'
              types={['CREATE']}
            >
              <Button
                variant='fab'
                color='primary'
                onClick={this.openCreateDialog.bind({}, this.props)}
              >
                <Add />
              </Button>
            </PermissionWrapper>
          </ActionButton>
          <DeleteDialog {...this.state.delete} />
          <CustomSnackbar />
        </WebhookWrapper>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getWebhookList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  showNonceDialog: selectors.getShowNonceDialog(state),
  showCreateDialog: selectors.getCreateDialogState(state),
  hasPermissions: (permissions) => checkUserPermissions(state, permissions)
})

const mapDispatchToProps = (dispatch) => ({
  openCreateDialog: () => dispatch(openCreateDialog()),
  fetchData: (options) => dispatch(fetchWebhookList(options)),
  deleteWebhook: (webhookId) => dispatch(deleteWebhook(webhookId)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  onFilterChange: ({filters}) => dispatch(updateFilters(filters))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(WebhookList))
