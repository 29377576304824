export const BULK_UPLOAD_NOT_VALID = 'bulk-upload-notvalid'
export const BULK_UPLOAD_VALID = 'bulk-upload-valid'
export const BULK_UPLOAD_DUPLICATE = 'bulk-upload-duplicate'
export const BULK_UPLOAD_ALL = 'all'
export const BULK_UPLOAD = 'bulk-upload'
export const NOT_VALID = 'not-valid'
export const VALID = 'valid'
export const DUPLICATE = 'duplicate'
export const FIRST_STEP = 'first-step'
export const SECOND_STEP = 'second-step'
export const THIRD_STEP = 'third-step'
export const CREATE_CAR_PRIMARY_FIELD = 'carDuplicatedCheckField'
