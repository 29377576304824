module.exports = {
  required: ['firstname', 'lastname', 'email', 'status'],
  hidden: [
    'taxOffice',
    'dealerTaxOfficeCommune',
    'dealerTaxOfficeCity',
    'legalRepresentativeNationality',
    'legalRepresentativeProfesion',
    'legalRepresentativeId',
    'dealersPublicDeedIncorporationDate',
    'dealersPublicDeedNotary',
    'competitors',
    'guarantees',
    'keyContacts',
    'idealStock',
    'legallyRegistered',
    'onboardingAgent',
    'dealersPublicDeedNotaryCity',
    'personType',
    'legalRepresentativeLastName',
    'district',
    'nationality',
    'taxIdExpiryDate',
    'companyTradeLicense',
    'companyTaxId',
    'companyTransportAuthorityNo',
    'systemSetCreditLimit',
    'systemSetDepositAmount',
    'deposit',
    'invoicingType',
    'storageTime',
    'expectedDelivery',
    'expectedCarCare',
    'expectedFinancing',
    'depositBalance',
    'numberPurchasableCar',
    'businessTypeSelect',
    'cityId',
    'stateId',
    'isNewsLetterAccepted',
    'inactiveReason',
    'onboardingStatus',
    'rejectedReason',
    'onboardingDate',
    'offboardingDate',
    'companyTypeId',
    'regon',
    'postCode'
  ],
  hiddenValues: {
    status: ['rejected']
  },
  disabledPrefixFields: {
    dealerDetails: ['phone', 'phone2', 'legalRepresentativePhone']
  }
}
