export const carDataFilesFields = {
  fileName: {
    key: 'fileName',
    label: 'carDataFile.column.fileName',
    type: 'string',
    isFilterable: true,
    isSortable: true
  },
  createdAt: {
    key: 'createdAt',
    label: 'carDataFile.column.createdAt',
    type: 'date',
    isFilterable: true,
    isSortable: true
  },
  createdByName: {
    key: 'createdByName',
    label: 'carDataFile.column.createdByName',
    type: 'string',
    isFilterable: true,
    isSortable: true
  }
}
