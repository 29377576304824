const mergeWith = require('lodash/mergeWith')
const isNil = require('lodash/isNil')
const cloneDeep = require('lodash/cloneDeep')
const commonConfig = require('./common')

let countries = {}
if (process.env.WEBPACK_BUILD) {
  const countriesConfig = require.context('./', false, /.*\.js$/)

  countries = countriesConfig
    .keys()
    .reduce((acc, key) => ({...acc, [key]: countriesConfig(key)}), {})
} else {
  countries = require('require-all')({
    dirname: __dirname + '/',
    recursive: false,
    filter  : function (fileName) {
      if (fileName !== 'index.js'){
        return fileName.split('.js')[0];
      }
    }
  })
}

const urlMap = Object.values(countries).reduce(
  (acc, country) => ({
    ...acc,
    [country.url]: country
  }),
  {}
)

const hosts = Object.values(countries).reduce((acc, {url}) => {
  const host = url && url.split('.')[0]
  if (host) {
    acc.push(host)
  }

  return acc
}, [])

const getDomainName = () => {
  const host = window.location.hostname
  const parts = host.split('.').reverse()
  const output = []

  for (let i = 0; i < parts.length; i++) {
    output.push(parts[i])

    if (hosts.indexOf(parts[i]) > -1) {
      break
    }
  }

  return output.reverse().join('.')
}

const codeMap = Object.values(urlMap).reduce(
  (acc, config) => ({...acc, [config.countryCode]: config}),
  {}
)

const mergeConfig = (countryConfig, commonConfig) =>
  mergeWith(cloneDeep(countryConfig), commonConfig, (obj, source) => {
    if (obj && Array.isArray(obj)) {
      return obj
    }

    if (isNil(obj) && isNil(source) && Array.isArray(source)) {
      return source
    }
  })

const getCountryConfig = () => {
  const domain = getDomainName()

  if (typeof urlMap[domain] !== 'undefined') {
    return mergeConfig(urlMap[domain], commonConfig)
  }
}

const getCountryConfigByCode = (code) => codeMap[code]

module.exports = {
  getCountryConfig,
  getCountryConfigByCode,
  countries
}
