import React from 'react';
import '../../Notifications.scss';

const notificationsTableHeaders = ["message", "time", "actions"]

const NotificationsListingHeaders = (props: { translate: any }) => {
    const { translate } = props;
    return <div data-testid="NotificationsListingHeaders" className='paddingLeftRight24 notificationsTableHeaderRow backgroundTint75'>
        {
            notificationsTableHeaders.map((item) => {
                return <span key={item} className={`notificationsTableHeaderItem_${item}`}>
                    {translate("ui.notifications.headers." + item)}
                </span>
            })
        }
    </div>
}

export default NotificationsListingHeaders;