import get from 'lodash/get'
import {filterByMetaInfo} from './helpers'
import {getCountryConfig} from '../../../config/country'

const {
  appointmentStatusRejectionReasonMapping,
  leadStatusDiscardedReasonMapping
} = getCountryConfig()

export const Handlers = (props) => {
  const handleToggleChange = (e, name) => handleChange(e.target.checked, name)

  const handleSelectChange = (e, name) => {
    if (leadStatusDiscardedReasonMapping) {
      if (name === 'leadStatus') {
        if (e.target.value !== null) {
          props.setDiscardReasonOptions({
            data: filterByMetaInfo(
              props.options.allDiscardedReasons,
              e.target.value
            )
          })
          props.update({name: 'discardedReason', value: null})
        } else {
          props.setDiscardReasonOptions({data: []})
          props.update({name: 'discardedReason', value: null})
        }
      }
    }
    handleChange(e, name)
  }

  const handleTextInput = (e) => handleChange(e, e.target.name)

  const handleChange = (e, name) => {
    const value = e && typeof e.target !== 'undefined' ? e.target.value : e

    props.update({name, value})
  }

  const handleNumberInput = (e) => {
    handleChange(parseInt(e.target.value, 10), e.target.name)
  }

  const handleResponsible = (e) => {
    const value = e === null ? e : e.label
    props.update({name: 'responsibleName', value})
  }

  const handleAssignedTo = (e) => {
    const value = e === null ? e : e.label
    props.update({name: 'assignedToName', value})
  }

  const handleSlotChange = (e) => {
    const data =
      e !== null && typeof e.target !== 'undefined' ? e.target.value : e

    if (typeof data === 'undefined' || data === null) {
      props.update({name: 'date', value: ''})
      props.update({name: 'time', value: ''})
      props.update({name: 'slot', value: ''})
    } else {
      const [date, time] = data.split(' ')
      props.update({name: 'date', value: date})
      props.update({name: 'time', value: time})
      props.update({name: 'slot', value: data})
    }
  }

  const getType = (type) =>
    props.options.appointmentType.find(({value}) => value === type)

  const handleLocationChange = (e, name) => {
    const placeId =
      e !== null && typeof e.target !== 'undefined' ? e.target.value : e

    if (placeId !== null) {
      const appointmentType = getType(props.data.type)

      props.locationChange(
        {placeId},
        'appointment',
        appointmentType && appointmentType.metaInfo.locationType
      )
    }
    handleSlotChange(null)
    handleChange(placeId, name)
  }

  const handleAppointmentStatusChange = (e, name) => {
    if (appointmentStatusRejectionReasonMapping) {
      if (e.target.value !== null) {
        props.setRejectReasonOptions({
          data: filterByMetaInfo(
            props.options.allRejectionReasons,
            e.target.value
          )
        })
      } else {
        props.setRejectReasonOptions({data: []})
      }
    }
    props.update({name: 'rejectionReasons', value: null})
    handleSelectChange(e, name)
  }

  const handleAppointmentTypeChange = (e) => {
    handleLocationChange(null, 'placeId')
    const type =
      e !== null && typeof e.target !== 'undefined' ? e.target.value : e

    const selectedType = props.options.appointmentType.find(
      ({value}) => value === type
    )

    if (
      selectedType &&
      selectedType.metaInfo &&
      typeof selectedType.metaInfo !== 'undefined'
    ) {
      const {locationType} = selectedType.metaInfo

      props.update({name: 'type', value: type})
      props.appointmentTypeChange(locationType)
      // handleLocationChange({target: {value: props.data.placeId}}, 'placeId')
      // handleSlotChange({target: {value: props.data.slot}})
    }
    handleLocationChange(null, 'placeId')
  }

  const handlePurchaseCoordinatorChange = (event) => {
    const purchaseCoordinatorId = get(event, 'target.value.id')
    const purchaseCoordinator = get(event, 'target.value.purchaseCoordinator')
    const purchaseCoordinatorPhone = get(
      event,
      'target.value.purchaseCoordinatorPhone'
    )

    props.update({
      name: 'purchaseCoordinatorId',
      value: purchaseCoordinatorId || null
    })
    props.update({
      name: 'purchaseCoordinator',
      value: purchaseCoordinator || null
    })
    props.update({
      name: 'purchaseCoordinatorPhone',
      value: purchaseCoordinatorPhone || null
    })
  }

  return {
    handleToggleChange,
    handleSelectChange,
    handleTextInput,
    handleChange,
    handleAppointmentStatusChange,
    handleNumberInput,
    handleResponsible,
    handleAssignedTo,
    handleSlotChange,
    handleLocationChange,
    handleAppointmentTypeChange,
    handlePurchaseCoordinatorChange
  }
}

export default Handlers
