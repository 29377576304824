import React from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import StatusLabel from '../../../components/StatusLabel'
import {returnHolidayDateString} from '../../../config/utils'
import {useCommonStyles} from '../../../hooks/useStyles/ThemeConfig'
import {serviceTypes} from '../../../config/constants'
import OpeningTime from '../../../types/OpeningTime'

const isAllServicesOpen = (openingTimes: OpeningTime[]) => {
  let open = true
  Object.keys(serviceTypes).forEach((type) => {
    if (!open) return
    const service = openingTimes.find((openingTime) => {
      return openingTime.bookingType[0] === type && !openingTime.closed
    })
    if (!service) open = false
  })

  return open
}

interface HolidayRowProps {
  global?: boolean
  openingTimes?: OpeningTime[]
  id?: string
  from: Date
  to: Date
  applyToAllCities?: boolean
  cities?: string[] | null
  title: string
  isMaster: boolean
  onDelete: (id?: string) => void
  onSelect: (id?: string) => void
}

const HolidayRow = ({
  global,
  openingTimes = [],
  id,
  from,
  to,
  applyToAllCities,
  cities = [],
  title,
  onDelete = () => {},
  onSelect = () => {},
  isMaster
}: HolidayRowProps) => {
  const classes = useCommonStyles()
  const rowInactive = new Date(Date.now()) > from
  const holidayDateString = returnHolidayDateString(from, to)
  let status: {label: string; theme: string} = {
    label: 'Custom',
    theme: 'custom'
  }

  if (openingTimes.length === 0) {
    status = {label: 'Closed', theme: 'inactive'}
  } else if (isAllServicesOpen(openingTimes)) {
    status = {label: 'Opened', theme: 'active'}
  }

  return (
    <Grid
      container
      className={cx(
        {
          [classes.colorInactive]:
            rowInactive || (!isMaster && status.theme === 'inactive')
        },
        classes.borderBottom,
        classes.paddingY1,
        classes.hoverBackground,
        classes.itemsCenter,
        classes.paddingX2,
        classes.pointer
      )}
      onClick={() => {
        if (rowInactive) return false
        onSelect(id)
      }}
      dataTest='holiday-row'
    >
      <Grid item xs={4}>
        {isMaster ? (
          holidayDateString
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                disabled={rowInactive}
                checked={status.theme !== 'inactive'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault()
                }}
                value={holidayDateString}
              />
            }
            label={holidayDateString}
            dataTest='holiday-date-field'
          />
        )}
      </Grid>
      <Grid item xs={isMaster ? 4 : 5}>
        {title}
      </Grid>
      <Grid item xs={isMaster ? 3 : 2}>
        {isMaster ? (
          applyToAllCities ? (
            'All cities'
          ) : (
            cities!.join(', ')
          )
        ) : (
          <StatusLabel label={status.label} theme={status.theme} />
        )}
      </Grid>
      <Grid item xs={1} className={classes.textRight}>
        {!global && (
          <IconButton
            aria-label='delete'
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault()
              e.stopPropagation()
              onDelete(id)
            }}
            data-test='delete-holiday-btn'
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default HolidayRow
