import { Box, Grid, Paper } from "@material-ui/core";
import React from "react";

import FileInput from "../../components/Inputs/FileInput";
import Table from "../../components/Table";
import Download from '../../components/Download'
import CustomResponseSnackbar from '../../components/SnackBar/CustomResponseSnackbar'
import Country from "../../types/Country";
const ReasourceManager = ({country}: {country: Country}): JSX.Element => {

    return (
        <Box sx={{ padding: "24px" }}>
            <Grid container spacing={4}>
                <Grid container item sm={8} spacing={3}>
                    <Grid item sm={11} >
                        <FileInput />
                    </Grid>
                    <Grid item sm={11} >
                        <Table country={country}/>
                    </Grid>
                </Grid>
                <Download />
            </Grid >
            <CustomResponseSnackbar />
        </Box >
    )
}

export default ReasourceManager;
