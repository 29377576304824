import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import {RenderIf} from '../../components/RenderIf'

const COMMENT_MAX_LEN = 120
const RejectionComment = styled.div`
  margin-top: 10px;
`
const LoaderWrapper = styled.div`
  text-align: center;
`
const Loader = () => (
  <LoaderWrapper>
    <CircularProgress />
  </LoaderWrapper>
)

export const ApproveTicketDialog = ({
  isOpen = false,
  onDialogClose,
  onApproveTicket,
  isLoading,
  t
}) => (
  <Dialog open={isOpen} maxWidth='md' fullWidth>
    <DialogTitle id='form-dialog-title'>
      {t('carOwnershipTransfer.approveTicket.dialogTitle')}
    </DialogTitle>
    <DialogContent>
      {isLoading ? (
        <Loader />
      ) : (
        t('carOwnershipTransfer.approveTicket.dialogMessage')
      )}
    </DialogContent>
    <RenderIf if={!isLoading}>
      <DialogActions>
        <Button onClick={onDialogClose} color='primary' data-test='button-no'>
          {t('global.no')}
        </Button>
        <Button
          variant='raised'
          color='primary'
          type='submit'
          data-test='button-yes'
          onClick={onApproveTicket}
        >
          {t('global.yes')}
        </Button>
      </DialogActions>
    </RenderIf>
  </Dialog>
)
