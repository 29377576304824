import React, {useContext } from "react";
import { Button } from "@material-ui/core";
import { MainContext } from "../../context/MainContext";
import { downloadCSV } from '../../api/download'
import { downloadReservationCSV } from "../../api/downloadReservations";
import { exportCSVFile } from "../../helpers/jsonTocsv";
import {DownloadType} from '../../types'

function getFormatedDate(str: Date): string {
    return new Date(str).toISOString().split('T')[0]
}

type Props = {
    downloadType: DownloadType,
    selectedStoreIds?:string
}

const DownloadButton = ({downloadType, selectedStoreIds=''}:Props) => {
    const {setReservations, setDownloadedSlots, selectedDateRange, countryCode, isLoading, setIsLoading, setResponseBar } = useContext(MainContext)

    const startDate = getFormatedDate(selectedDateRange.from)
    const endDate = getFormatedDate(selectedDateRange.to)
    const handleDownload = () => {
        setIsLoading(true)
        if (downloadType ===DownloadType.availability) {
            downloadReservationCSV(countryCode, startDate, endDate, selectedStoreIds, downloadCallBack)  
        } else {
            downloadCSV(countryCode, startDate, endDate, '', downloadCallBack);
        }
    }

    const downloadCallBack = (response: {csv:string, statusCode?: Number, message?: string}) => {
        setIsLoading(false)
        if (response?.statusCode) {
            setResponseBar({
                type: 'error',
                message: response?.message
            })
        } else {
            if (downloadType === DownloadType.capacity) {
                setDownloadedSlots(response)
            } else {
                setReservations(response)
            }
            exportCSVFile(response?.csv, `${downloadType}-${startDate}-to-${endDate}-`)
            setResponseBar({
                type: 'success',
                message: 'File downloaded successfully'
            })
        }
    }

    return (
        <Button
            className="btn-upload"
            color="primary"
            variant="contained"
            component="span"
            disabled={isLoading}
            onClick={handleDownload}
            style={{
                height: '47.9px',
                padding: '4px 114px 3.9px 113.5px',
                borderRadius: '4px',
                backgroundColor: '#3f51b5',
                color:'white'
            }}
        >
            Download
        </Button >
    )
}

export default DownloadButton
