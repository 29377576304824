import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import gql from '@fcg/lib-gql/gql'

import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import ActionButton from '../../../components/layout/ActionButton'
import PageTableBase from '../../../components/PageTableBase'
import CustomSnackbar from '../../../components/Snackbar'
import selectors from '../../../store/crm/participant/selectors'
import Select from '../../../components/Select/Select'
import {
  fetchParticipants,
  toggleField,
  addParticipant,
  removeParticipant,
  updateParticipant
} from '../../../store/crm/participant/actions'
import {showSnackbarNotification} from '../../../store/signals/actions'
import * as signalTypes from '../../../store/signals/types'

import Contact from './Contact'
import {DeleteDialog} from '../common'

const deleteInitialState = {
  open: false,
  entity: null
}

const getEditableCellOptions = () => ({
  editableCellTypes: {
    select: Select
  },
  canEditCell: true,
  selectOptions: [
    {value: 'BUYER', label: 'participant.type.BUYER'},
    {value: 'SELLER', label: 'participant.type.SELLER'}
  ]
})

class ParticipantsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUpdateDialogVisible: false,
      delete: {...deleteInitialState}
    }
    this.deleteAction = null
  }

  toggleDialog = () => {
    this.setState((state) => ({
      isUpdateDialogVisible: !state.isUpdateDialogVisible
    }))
  }

  addParticipants = (e, participant) => {
    this.props.addParticipants({
      appointmentId: this.props.match.params.id,
      participant: [{id: participant.id, role: new gql.EnumType('BUYER')}]
    })

    this.toggleDialog()
  }

  onDelete = () => {
    if (this.state.delete.id) {
      this.deleteAction([...this.state.delete.id])
      this.closeDeleteDialog()
    }
  }

  openDeleteDialog = ({entity, id, action}) => {
    this.setState({
      delete: {
        open: true,
        entity,
        id
      }
    })

    this.deleteAction = action
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
    this.deleteAction = null
  }

  singleSelectTableActions = [
    {
      event: (item) => {
        this.openDeleteDialog({
          entity: 'deleteParticipant',
          id: [item.id],
          action: (ids) =>
            this.props.removeParticipant({
              participant: ids || [],
              appointmentId: this.props.match.params.id
            })
        })
      },
      icon: <Delete />,
      title: 'global.action.delete'
    }
  ]

  onCellChange = ({value, row}) => {
    const isCellEditable =
      !this.props.data.find((participant) => participant.role === 'SELLER') &&
      value === 'BUYER'

    if (row.role !== value && isCellEditable) {
      this.props.updateParticipant({
        appointmentId: this.props.match.params.id,
        participantId: row.id,
        role: new gql.EnumType(value)
      })
    } else {
      this.props.showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.appointment.participant.notAllowed',
        open: true,
        extras: {
          size: this.props.maxFileSize
        }
      })
    }
  }

  render() {
    return (
      <Fragment>
        <div className='page'>
          <PageTableBase
            {...this.props}
            args={{id: this.props.match.params.id}}
            multisort={false}
            title='entity.participants'
            hasFooter={false}
            filterable={false}
            withVisibility={false}
            singleSelectActions={this.singleSelectTableActions}
            editableCellOptions={getEditableCellOptions()}
            onCellChange={this.onCellChange}
          />
          <ActionButton>
            <Button variant='fab' color='primary' onClick={this.toggleDialog}>
              <Add />
            </Button>
          </ActionButton>
          <Contact
            open={this.state.isUpdateDialogVisible}
            handleClose={this.toggleDialog}
            addParticipants={this.addParticipants}
          />
          <CustomSnackbar />
          <DeleteDialog
            handleSubmit={this.onDelete}
            handleCancel={this.closeDeleteDialog}
            {...this.state.delete}
          />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getListData(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (options) => dispatch(fetchParticipants(options)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  addParticipants: (options) => dispatch(addParticipant(options)),
  removeParticipant: (options) => dispatch(removeParticipant(options)),
  updateParticipant: (options) => dispatch(updateParticipant(options)),
  showSnackbarNotification: (payload) =>
    dispatch(showSnackbarNotification(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(ParticipantsList))
