import {Component} from 'react'
import {Notification, ServerErrorDialog} from './Info'

export default class Content extends Component {
  render() {
    return (
      <main className='app-content'>
        {this.props.children}
        <ServerErrorDialog />
        <Notification />
      </main>
    )
  }
}
