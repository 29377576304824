import PropTypes from 'prop-types'
import moment from 'moment'
import withConfig from './../providers/withConfig'
import {getCountryConfig} from '../../../config/country'

const {countryCode} = getCountryConfig()

const timestampRegex = /^[0-9]+$/

export const DateFormat = (props) => {
  const {data, time, dateFormat, format, empty} = props

  if (typeof data === 'undefined' || data === null) {
    return empty
  }

  const fullFormat =
    time === true ? `${format || dateFormat} HH:mm` : format || dateFormat

  const parsedData = timestampRegex.test(data) ? Number(data) : data

  const realTime =
    time === true
      ? moment.utc(parsedData).format(fullFormat)
      : moment(parsedData).format(fullFormat)

  if (countryCode === 'TR') {
    return moment.isMoment(parsedData)
      ? parsedData.format(fullFormat)
      : realTime
  } else {
    return moment.isMoment(parsedData)
      ? parsedData.format(fullFormat)
      : moment(parsedData).format(fullFormat)
  }
}

DateFormat.defaultProps = {
  time: false,
  empty: '/'
}

DateFormat.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dateFormat: PropTypes.string.isRequired
}

export default withConfig(['dateFormat'], DateFormat)
