import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import {disabledCallback} from './utils'

export default {
  stepNum: 2,
  stepTitle: 'dealer.mainContact',
  description: 'dealer.panel.description.mainContact',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'firstname',
            label: 'dealer.page.field.firstName',
            margin: 'normal',
            onChangeHandler: 'updateField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'lastname',
            label: 'dealer.page.field.lastName',
            margin: 'normal',
            validator: {required: true, type: 'string'},
            onChangeHandler: 'updateField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 7,
        children: [
          {
            component: ValidatedTextField,
            id: 'metaInfoEmail',
            autoComplete: 'new-password',
            fullWidth: true,
            label: 'dealer.page.field.email',
            margin: 'normal',
            type: 'email',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            id: 'phone',
            label: 'dealer.page.field.phone',
            margin: 'normal',
            isNumber: true,
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            id: 'phone2',
            label: 'dealer.page.field.phone2',
            margin: 'normal',
            isNumber: true,
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'personType',
            label: 'dealer.page.field.personType',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'nationality',
            label: 'dealer.page.field.nationality',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'keyContacts',
            multiline: true,
            label: 'dealer.page.field.keyContacts',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            persistChoice: false,
            fullWidth: true,
            id: 'isNewsLetterAccepted',
            multiline: true,
            label: 'dealer.page.field.isNewsLetterAccepted',
            margin: 'normal',
            onChangeHandler: 'updateMetaField',
            disabled: disabledCallback
          }
        ]
      }
    ]
  }
}
