import React from 'react'
import cx from 'classnames'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import {convertToLowercaseWithInitialCapital} from '../../config/utils'

interface MSProps {
  title: string
  required?: boolean
  options: string[]
  value: string | string[]
  error?: string
  optionsLowerCase?: boolean
  onChange: (value: string[]) => void
}

const getValue = (values: string[], optionsLowerCase: boolean) => {
  return values.reduce((valString, val) => {
    const valueShown = optionsLowerCase
      ? convertToLowercaseWithInitialCapital(val)
      : val
    valString = valString === '' ? valueShown : valString + ', ' + valueShown

return valString
  }, '')
}

export default function SelectField({
  title,
  options = [],
  value = [],
  error,
  onChange,
  required = false,
  optionsLowerCase = false
}: MSProps) {
  const classes = useCommonStyles()
  const labelId = `${title}-select-label`

return (
    <FormControl
      className={cx(classes.fullWidth)}
      required={required}
      error={!!error}
    >
      <InputLabel id={labelId}>{title}</InputLabel>
      <Select
        fullWidth
        disabled={options.length === 0}
        labelId={labelId}
        multiple
        value={value}
        onChange={(event: React.ChangeEvent<{ value: string[] }>) => {
          onChange(event.target.value)
        }}
        renderValue={(selected: string[]) =>
          getValue(selected, optionsLowerCase)
        }
        input={<Input />}
        dataTest={`${title}-field`}
      >
        {options.map((option) => {
          const val = optionsLowerCase
            ? convertToLowercaseWithInitialCapital(option)
            : option

          return (
            <MenuItem key={option} value={option}>
              <Checkbox checked={value.indexOf(option) > -1} color='primary' />
              <ListItemText primary={val} />
            </MenuItem>
          )
        })}
      </Select>
      <Typography variant='caption' display='block' gutterBottom color='error'>
        {error}
      </Typography>
    </FormControl>
  )
}
