import {ApiClient} from '@fcg/admin-api-client'
import fields from './fields'

const client = new ApiClient('auction')

export const upsertDealer = (userId, maxBidAmount) => {
  return client.query('upsertDealer', {userId, maxBidAmount}, [
    'userId',
    'maxBidAmount'
  ])
}

export const getAuctionSchedules = (params = {}) =>
  client.query('auctionFlowConfigDB', params)

export async function createAuctionFlow(options) {
  return client.mutation(
    'createAuctionScheduleFlow',
    {
      ...options
    },
    ['id', 'name', 'configId', 'options', ['schedule', ['kind', 'duration']]]
  )
}

export const deleteAuctionFlow = async (options) => {
  return client.mutation('deleteAuctionScheduleFlow', {
    ...options
  })
}
