import React from 'react';
import {FormLabel, FormControlLabel, Radio, RadioGroup} from '@material-ui/core'

interface RadioProps {
    value: string,
    label: string,
    options: Array<{ value: string, label: string }>,
    onChange: (value: string)=>void
}

const RadioInputBase = (props: RadioProps) => {
  const handleChange = (e) => {
    const value = e?.target?.value

    if (props.onChange) {
      props.onChange(value)
    }
  }

  const renderOptions = () =>
    props.options.map((option, index) => (
      <FormControlLabel
        id={option.label}
        key={`formcontrol-label_${option.value}`}
        control={
          <Radio
            value={option.value}
            checked={props.value === option.value}
            color='primary'
          />
        }
        label={option.label}
      />
    ))

  return (
    <FormLabel>
      {props.label}
      <RadioGroup
        style={{flexDirection: 'row'}}
        aria-label={props.label}
        value={props.value}
        onChange={handleChange}
      >
        {renderOptions()}
      </RadioGroup>
    </FormLabel>
  )
}

export default RadioInputBase
