export const webhookSchema = {
  name: null,
  description: null
}

const state = {
  isFetchingList: false,
  isLoading: false,
  data: null,
  error: null,
  pageSetup: {},
  orderBy: null,
  selectedWebhookId: null,
  initialData: {...webhookSchema},
  webhookList: [],
  events: [],
  count: null,
  list: {
    page: 0,
    limit: 25,
    fetching: false,
    sort: [],
    data: [],
    fields: [],
    filters: []
  },
  isUploadingCarData: false,
  uploadError: null
}

export const getInitialState = (config) => ({
  options: {},
  form: {...webhookSchema},
  ...state,
  list: {
    ...state.list,
    ...config,
    fields: Object.values(config.fields)
  }
})
