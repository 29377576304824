import {getListSelectors} from '../common/listSelectors'
import {createSelector} from 'reselect'
import {values, map} from 'ramda'
import gql from '@fcg/lib-gql/gql'

import {getCountryCode} from '../config/selectors'
import {STATUS_TYPE, CAR, TRUCK} from '../../pages/TransitJobs/constants'
import {default as driverSelectors} from '../driver/selectors'

const carDetailFields = ['make', 'model', 'year']
const selectorPath = (state) => state.transitJob
const selectors = getListSelectors(selectorPath)
const listSelectorPath = (state) => selectorPath(state).list

const getExpandedRows = (state) => selectorPath(state).list.expandedRows
const isRowExpanded = createSelector(
  getExpandedRows,
  (state, props) => props,
  (rows, rowId) => rows.includes(rowId)
)

export const getTransitList = (state) => selectorPath(state).transitList

const getLocationOptions = (state) => {
  const locations =
    (state.config.options.locationOptions &&
      state.config.options.locationOptions['CURRENT']) ||
    []

  if (locations && locations.length) {
    return locations.reduce((acc, location) => {
      return {
        ...acc,
        [location.value]: location.label
      }
    }, {})
  }

  return {}
}

const countryCode = (state) => getCountryCode(state)

const getTransitListWithPlaces = createSelector(
  [getTransitList, getLocationOptions, countryCode],
  (transitList, locations, country) => {
    if (Object.values(locations).length) {
      return transitList.map((transit) => ({
        ...transit,
        numberOfCars: Array.isArray(transit.transits)
          ? transit.transits.length
          : 0,
        startPlace: locations[transit.startPlace],
        endPlace: locations[transit.endPlace],
        transits: getTransit(transit.transits, country)
      }))
    }

    return []
  }
)

const getTransit = (transits, country) => {
  return transits.map((transit) => {
    if (transit.carDetails) {
      return transit
    }

    if (transit && transit.car) {
      const carDetails = carDetailFields.reduce((acc, field) => {
        if (transit.car && transit.car[field]) {
          return [...acc, transit.car[field]]
        }

        return acc
      }, [])

      return {
        status: transit.status,
        internalId: `${country}-${transit.car.internalId}`,
        carDetails: carDetails.join(', '),
        licensePlate: transit.car.licensePlate
      }
    } else {
      return {
        status: transit.status,
        internalId: '',
        carDetails: '',
        licensePlate: ''
      }
    }
  })
}

const getTransitJobByIdWithPlaces = (state, props) => {
  const transitJobListWithPlaces = getTransitListWithPlaces(state)
  const transitJob = Object.values(transitJobListWithPlaces).find(
    ({id}) => id === props.id
  )

  return transitJob || null
}

const getTransitJobById = createSelector(
  getTransitList,
  (state, props) => props,
  (list, props) => {
    const transitJob = Object.values(list).find(({id}) => id === props.id)

    return transitJob || null
  }
)

const getCurrentLocation = (locations) => {
  const currentLocation = locations.find(
    ({locationType}) => locationType === 'CURRENT'
  )

  if (currentLocation) {
    return currentLocation.location
  }

  return ''
}

const getCarIdOptions = (state) => {
  const {carIdOptions} = selectorPath(state)

  return map(
    (car) => ({
      value: car.id,
      label: `${car.licensePlate} - ${car.internalId} - ${getCurrentLocation(
        car.location
      )}`
    }),
    carIdOptions && carIdOptions.options ? values(carIdOptions.options) : []
  )
}

const getCarIdOptionsCount = (state) => {
  const {carIdOptions} = selectorPath(state)

  return carIdOptions && carIdOptions.count ? carIdOptions.count : 0
}

const getTransitJob = (state) => {
  const {transitReview} = selectorPath(state)

  if (transitReview?.transitJob?.transits) {
    return {
      ...transitReview.transitJob,
      transits: transitReview.transitJob.transits.filter(
        (transit) => transit.status !== STATUS_TYPE.ABORTED
      )
    }
  }

  return transitReview.transitJob
}

const getDriverImageUrl = (state) => {
  const {transitReview} = selectorPath(state)

  return transitReview.driverImageUrl
}

const isFetchingTransitJob = (state) => {
  const {transitReview} = selectorPath(state)

  return transitReview.fetching
}

const isSubmittingTransitJob = (state) => {
  const {transitReview} = selectorPath(state)

  return transitReview.submiting
}

const isFetchingDriverImage = (state) => {
  const {transitReview} = selectorPath(state)

  return transitReview.isFetchingImage
}

const getDriverDetails = (state) => {
  const {transitReview} = selectorPath(state)

  return transitReview.driverDetails
}
const getGqlSort = createSelector(selectors.getSort, (sort) => {
  return sort && Array.isArray(sort) && sort.length
    ? {...sort[0], order: new gql.EnumType(sort[0].order)}
    : null
})

const getFormData = (state) => selectorPath(state).form

const isFetchingFormData = (state) => selectorPath(state).isFetchingFormData

const getDriverOptionsWithType = createSelector(
  driverSelectors.getDriverOptions,
  (_, type) => type,
  (driverOptions, type) => {
    let options = []

    if (type === CAR && driverOptions && driverOptions.length) {
      options = driverOptions.reduce((acc, option) => {
        if (option.driver.isCarDriver) {
          return [
            ...acc,
            {
              label: `${option.firstname} ${option.lastname}`,
              value: option.id
            }
          ]
        }

        return acc
      }, [])
    } else if (type === TRUCK && driverOptions && driverOptions.length) {
      options = driverOptions.reduce((acc, option) => {
        if (option.driver.isTruckDriver) {
          return [
            ...acc,
            {
              label: `${option.firstname} ${option.lastname}`,
              value: option.id
            }
          ]
        }

        return acc
      }, [])
    } else {
      options = driverOptions.map((driver) => ({
        label: `${driver.firstname} ${driver.lastname}`,
        value: driver.id
      }))
    }

    return options
  }
)

export default {
  ...selectors,
  getExpandedRows,
  isRowExpanded,
  getTransitList,
  getTransitListWithPlaces,
  getCarIdOptions,
  getCarIdOptionsCount,
  getTransitJob,
  getDriverImageUrl,
  isFetchingTransitJob,
  isSubmittingTransitJob,
  isFetchingDriverImage,
  getDriverDetails,
  getGqlSort,
  isFetchingFormData,
  getFormData,
  getTransitJobById,
  getTransitJobByIdWithPlaces,
  getDriverOptionsWithType,
  getFields: (state) => Object.values(selectorPath(state).list.fields),
  getTransitSubtableFields: (state) =>
    Object.values(listSelectorPath(state).carSubtableFields),
  getEditState: (state) => selectorPath(state).editState
}
