import {parseQueryParams} from './urlParsing'
import {devInstancePattern} from './regex'

export const isPathEnabled = ({
  countryConfig,
  path = '',
  cookies = '',
  env = 'production'
}) => {
  const devInstance = devInstancePattern.test(window.location.href)
  const queryParams = parseQueryParams([path])
  const queryParamOverride = queryParams && queryParams[path] === '1'

  const cookieOverride = cookies
    .split(';')
    .some((item) => item.includes(`${path}=1`))

  const pathReadOnlyPerConfig =
    countryConfig &&
    countryConfig.readOnlyPaths &&
    Array.isArray(countryConfig.readOnlyPaths) &&
    countryConfig.readOnlyPaths.includes(path)

  const hasLocalOverride = queryParamOverride || cookieOverride

  return (
    !pathReadOnlyPerConfig ||
    ((env === 'development' ||
      env === 'test' ||
      (env === 'production' && devInstance)) &&
      hasLocalOverride)
  )
}

export const getReturnUrl = () => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search)
    const returnURL = searchParams.get('returnUrl')

    const redirectUrl = returnURL && returnURL !== '/' ? returnURL : `/crm/home`
    return redirectUrl
  }
  return '/'
}
