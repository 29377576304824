export const defaultLimit = 25

export const state = {
  selectedAuctionSchedule: null,
  fetching: false,
  isFetchingList: false,
  isLoading: false,
  error: null,
  fetchedData: false,
  list: {
    page: 1,
    count: 0,
    sort: [],
    limit: defaultLimit,
    data: {
      flow: null,
      autoConfirmEnabled: [],
      autoReserveEnabled: [],
      expandedRows: [],
      fields: [],
      subfields: []
    }
  },
  create: {
    created: false,
    isSubmitting: false,
    error: null
  }
}

export const getInitialState = (config) => ({
  options: {},
  ...state,
  list: {
    ...state.list,
    page: config.page,
    sort: config.sort,
    limit: config.limit ? config.limit : defaultLimit,
    fields: Object.values(config.fields),
    subfields: Object.values(config.subtableFields)
  }
})
