import {API_STATUS} from '../../appConstants'
import {checkAuth} from '../../utils/authToken'

const state = {
  redirect: false,
  isAuthenticated: checkAuth(),
  googleLoginProgress: API_STATUS.STALE,
  loginForm: {
    google: {
      errors: null
    },
    okta: {
      errors: null
    },
    internal: {
      errors: null
    }
  },
  googleRefreshingTimeout: null,
  userInfo: {},
  isCheckedIn: true,
  isSubmitting: false,
  error: null,
  oauthParams: {
    redirectURI: '',
    state: '',
    clientId: '',
    clientSecret: ''
  }
}

export const getInitialState = (preferences = {}) => ({
  ...state,
  ...preferences
})
