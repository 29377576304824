import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import Delete from '@material-ui/icons/Delete'
import Add from '@material-ui/icons/Add'
import styled from 'styled-components'
import {
  fetchBusinessPartnerList,
  updateFilters,
  toggleField,
  deleteBusinessPartner
} from '../../store/businessPartners/actions'
import {DeleteDialog} from '../CRM/common'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import selectors from '../../store/businessPartners/selectors'
import CustomSnackbar from '../../components/Snackbar'
import {PermissionWrapper} from '../../components/Permissions'
import {checkUserPermissions} from '../../store/auth/selectors'
import {getVisibleActions, isDeleteButtonVisible} from '../CRM/common/helpers'
import BPDetailsModal from './BPDetailsModal/BPDetailsModal'
import SearchBPModal from './BPSearchModal/SearchBPModal'
import {PERMISSION_ENTITY} from './constants/businessPartners'
import {loadOptions} from './../../store/crm/actions'
import {getCountryCode} from '../../store/config/selectors'

const BP_CountryOptions = [
  'BP_Address_State_Type',
  'purchaseChannel',
  'BP_Bank_Account_Type',
  'BP_Tax_Type',
  'BP_Tax_Sub_Type',
  'BP_Tax_Distribution_Type',
  'BP_Status_Type',
  'BP_Tag_Type',
  'BP_Contact_Type',
  'BP_Gender_Type',
  'BP_Address_Type',
  'BP_Payment_Method',
  'BP_Payment_Terms',
  'businessPartnerType'
]

const BusinessPartnersWrapper = styled.div`
  tr {
    td:first-child,
    th:first-child {
      padding-left: 24px;
    }
  }
`

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  location: null
}

class BusinessPartnersList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delete: {...deleteInitialState},
      showDetailsModal: false,
      selectedBP: null,
      showSearchDialog: false
    }
  }

  componentDidMount() {
    this.props.loadCountryOptions()
  }

  toggleDetailsModal = (selectedBPRow) => {
    this.setState({
      showDetailsModal: !this.state.showDetailsModal,
      selectedBP: selectedBPRow
    })
  }

  openBPDetail = (e, selectedBPRow) => {
    this.toggleDetailsModal(selectedBPRow)
  }

  openSearchDialog = () => {
    this.setState({
      showSearchDialog: true
    })
  }

  closeSearchDialog = () => {
    this.setState({
      showSearchDialog: false
    })
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  openDeleteDialog = ({item, action}) => {
    const {t} = this.props

    this.setState({
      delete: {
        open: true,
        item,
        heading: t('businessPartners.delete.title', {name: item.name}),
        content: t('businessPartners.delete.confirm', {name: item.name}),
        handleSubmit: () => {
          action(item.id)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  singleSelectTableActions = () =>
    getVisibleActions(this.singleSelectActions, this.props)

  singleSelectActions = [
    {
      test: (props) =>
        isDeleteButtonVisible(props) &&
        this.props.hasPermissions({
          entity: PERMISSION_ENTITY,
          types: ['DELETE']
        }),
      action: {
        event: (item) => {
          this.openDeleteDialog({
            item: item,
            action: () =>
              this.props.deleteBusinessPartner(
                item.businessPartnerId,
                this.props.countryCode,
                this.props.t
              )
          })
        },
        icon: <Delete />,
        title: this.props.t('global.action.delete')
      }
    }
  ]

  fetchData = (options) => {
    if (!this.props.fetching) {
      this.props._fetchData(options)
    }
  }

  render() {
    let selectedBpInfo = {}
    let id = null

    //Id is added as it is used to create data-test attribute for each row in PageTable component
    const dataWithId =
      this.props.data.length > 0
        ? this.props.data.map((item) => {
            return {
              ...item,
              id: item.businessPartnerId
            }
          })
        : []

    //omit id from children props
    if (this.state.selectedBP?.id) {
      ;({id, ...selectedBpInfo} = this.state.selectedBP)
    } else {
      selectedBpInfo = {...this.state.selectedBP}
    }

    return (
      <Fragment>
        <BusinessPartnersWrapper className='page bpDealList'>
          <PageTableBase
            {...this.props}
            fetchData={this.fetchData}
            data={dataWithId}
            sort={false}
            multisort={false}
            title='entity.b2bBP'
            filterable={false}
            page={this.props.page}
            count={this.props.count}
            rowsPerPageOptions={[25, 50, 100]}
            singleSelectActions={this.singleSelectTableActions()}
            onRowClick={this.openBPDetail}
          />
          <ActionButton>
            <PermissionWrapper entity={PERMISSION_ENTITY} types={['CREATE']}>
              <Button
                variant='fab'
                color='primary'
                onClick={this.openSearchDialog}
              >
                <Add />
              </Button>
            </PermissionWrapper>
          </ActionButton>
          <DeleteDialog {...this.state.delete} />
          {this.state.showDetailsModal && (
            <BPDetailsModal
              onClose={this.toggleDetailsModal}
              businessPartnerInfo={selectedBpInfo}
            />
          )}
          {this.state.showSearchDialog && (
            <SearchBPModal onClose={this.closeSearchDialog} />
          )}
          <CustomSnackbar />
        </BusinessPartnersWrapper>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getBusinessPartnerList(state),
  countryCode: getCountryCode(state),
  isCountryOptionsFetching: selectors.isCountryOptionsFetching(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  totalPages: selectors.getTotalPages(state),
  currentPage: selectors.getCurrentPage(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state),
  hasPermissions: (permissions) => checkUserPermissions(state, permissions)
})

const mapDispatchToProps = (dispatch) => ({
  _fetchData: (options) => dispatch(fetchBusinessPartnerList(options)),
  loadCountryOptions: () => dispatch(loadOptions(BP_CountryOptions)),
  deleteBusinessPartner: (businessPartnerId, countryCode, t) =>
    dispatch(
      deleteBusinessPartner({businessPartnerId, country: countryCode, t})
    ),
  toggleField: ({key}) => dispatch(toggleField(key)),
  onFilterChange: ({filters}) => dispatch(updateFilters(filters))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(BusinessPartnersList))
