import {createSelector} from 'reselect'
import groupBy from 'lodash/groupBy'
import {mainPermissions, subPermisssions} from './permissionsConfig'

const groupSelector = (state) => state.crmUserGroups.groups
const locationSelector = (state) => {
  let result = state.locationManagement.list.data
  result = (result && Object.values(result)) || []
  return result
}
const permissionSelector = (state) => state.crmUserGroups.permissions
const rolePermissionSelector = (state) => state.crmUserGroups.rolePermissions

const getUiPermissions = (permissions) => {
  const uiPermissions = permissions.filter(
    (item) => item.entity.indexOf('web.admin') === 0
  )
  const permissionGroups = groupBy(uiPermissions, 'entity')

  return permissionGroups
}

const permissionConfigSelector = createSelector(
  permissionSelector,
  getUiPermissions
)

const rolePermissionConfigSelector = createSelector(
  rolePermissionSelector,
  getUiPermissions
)

export const getPermissionsConfig = createSelector(
  permissionConfigSelector,
  (permissions) => ({permissions, mainPermissions, subPermisssions})
)

export const getValues = createSelector(
  rolePermissionConfigSelector,
  (permissions) =>
    Object.entries(permissions).reduce(
      (acc, [key, props]) => ({
        ...acc,
        [key]: props.map((prop) => prop.type)
      }),
      {}
    )
)

export const getRoles = createSelector(groupSelector, (groups) =>
  groups.map(({name, id}) => ({
    label: name,
    value: id
  }))
)

export const getLocations = createSelector(locationSelector, (params) =>
  params.map(({location, id}) => ({
    label: location,
    value: id
  }))
)
