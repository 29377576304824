import Calendar from '../../../pages/Calendar'

export default {
  path: 'calendar',
  key: 'appointmentCalendar',
  component: Calendar,
  label: 'appointmentCalendar',
  permissions: {
    entity: 'web.admin.ui.crm.appointments2',
    types: ['READ']
  }
}
