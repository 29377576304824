import {
  ValidatedTextField,
  ValidatedSelectField
} from '../../../../components/FormValidation'
import ImageUpload from '../../../../components/ProfileImage'

export default {
  stepNum: 1,
  stepTitle: 'driver.main',
  description: 'driver.panel.description.main',
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: ImageUpload,
            fullWidth: true,
            id: 'imageUpload',
            label: '',
            margin: 'normal',
            onChangeHandler: 'updateProfileImage'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'firstname',
            label: 'driver.page.field.firstName',
            margin: 'normal',
            type: 'text',
            onChangeHandler: 'updateField',
            required: true
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'lastname',
            label: 'driver.page.field.lastName',
            margin: 'normal',
            required: true,
            onChangeHandler: 'updateField'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'companyName',
            label: 'driver.page.field.company',
            margin: 'normal',
            validator: {required: true, type: 'string'},
            onChangeHandler: 'updateMetaField'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'status',
            label: 'driver.page.field.isActive',
            margin: 'normal',
            onChangeHandler: 'updateMetaField'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'documentId',
            label: 'driver.page.field.documentId',
            margin: 'normal',
            onChangeHandler: 'updateDriverDocumentId'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'SwitchField',
            fullWidth: true,
            id: 'isAvailable',
            label: 'driver.page.field.isAvailable',
            margin: 'normal',
            value: 'isAvailable',
            checked: 'isAvailable',
            name: 'isAvailable',
            onChangeHandler: 'updateDriverInfo'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'SwitchField',
            fullWidth: true,
            id: 'isTruckDriver',
            label: 'driver.page.field.isTruckDriver',
            margin: 'normal',
            value: 'isTruckDriver',
            checked: 'isTruckDriver',
            name: 'isTruckDriver',
            onChangeHandler: 'updateDriverInfo'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        children: [
          {
            component: 'SwitchField',
            fullWidth: true,
            defaultChecked: true,
            id: 'isCarDriver',
            label: 'driver.page.field.isCarDriver',
            margin: 'normal',
            value: 'isCarDriver',
            checked: 'isCarDriver',
            name: 'isCarDriver',
            onChangeHandler: 'updateDriverInfo'
          }
        ]
      }
    ]
  }
}
