import pickBy from 'lodash/pickBy'
import gql from '@fcg/lib-gql/gql'
import {call, put, select, takeLatest, cancel, delay} from 'redux-saga/effects'

import * as DocumentApi from '../../api/document/requests'
import * as CarApi from '../../api/car/requests'
import * as types from './types'
import * as TicketApi from '../../api/ticket/requests'
import {showErrorMessage, showSuccessMessage} from '../signals/sagas.js'
import {
  fetchRowDataSuccess,
  fetchRowDataError,
  toggleRowSelection,
  fetchRowData as fetchRowDataAction,
  fetchDataStart,
  updateTicketEntityError,
  updateTicketEntitySuccess,
  updateTicketError,
  updateTicketStart,
  updateTicketSuccess,
  fetchCurrentPage as fetchCurrentPageAction,
  fetchData as fetchDataAction,
  createTicketEntitiesError,
  createTicketEntitiesStart,
  createTicketEntitiesSuccess,
  clearDocumentDialog as clearDocumentDialogAction,
  setPageOptions
} from './actions'
import * as selectors from './selectors'
import {
  TICKET_STATUS_ENUM,
  TICKET_STATUS,
  TICKET_ENTITY_TYPES_ENUM,
  TICKET_ENTITY_STATUS_ENUM
} from '../../config/entities/ticket'
import {documentList} from '../documentConfig/selectors'
import {deleteDocument, cleanUp} from '../documentConfig/actions'
import {getCountryCode} from '../config/selectors'
import {getCountryOptions} from '../utils/helpers'

function* fetchData(action) {
  try {
    const canFetch = yield select(selectors.canAccessPage)
    const filtersObj = yield select(selectors.getParsedFilterForQuery)
    const filters = yield select(selectors.getFilters)

    if (!canFetch) {
      return
    }

    yield put(fetchDataStart())

    const {limit, page, sort, countryCode, type} = yield select(
      selectors.getFetchDataParams,
      action
    )
    const options = pickBy({
      limit,
      page,
      sort,
      type,
      country: countryCode,
      ...filtersObj
    })
    const ticketList = yield call(TicketApi.getOwnershipTicketList, options)

    yield put({
      type: types.dataLoadingTypes.dataLoaded,
      payload: {
        data: ticketList.ownershipTicket,
        limit,
        filters,
        sort,
        page,
        flatten: 1
      }
    })
  } catch (e) {
    yield showErrorMessage(e)
    yield put({
      type: types.fetchDataError,
      error: e
    })
  }
}

export function* updateTicket({payload}) {
  return yield call(TicketApi.updateOwnershipTicket, payload)
}

export function* onApproveTicket() {
  let openedTickets

  try {
    const canUpdateTicket = select(selectors.hasFullAccess)

    if (!canUpdateTicket) {
      return
    }

    openedTickets = yield select(selectors.getExpandedRows)

    if (!openedTickets || !openedTickets[0]) {
      return
    }

    const ticket = select(selectors.getTicketById, {id: openedTickets[0]})

    if (ticket.status === TICKET_STATUS.APPROVED) {
      return yield showSuccessMessage('carOwnershipTransfer.alreadyApproved')
    }

    yield put(updateTicketStart(openedTickets[0]))

    const payload = {
      id: openedTickets[0],
      status: TICKET_STATUS_ENUM.APPROVED
    }

    yield call(updateTicket, {payload})
    yield showSuccessMessage('carOwnershipTransfer.approve.success')
    yield put(updateTicketSuccess(payload.id))
    yield put(fetchCurrentPageAction())
  } catch (error) {
    yield put(updateTicketError({id: openedTickets[0], error}))
    yield showErrorMessage(error)
  }
}

export function* fetchCurrentPage() {
  try {
    const canFetch = yield select(selectors.canAccessPage)

    if (!canFetch) {
      return
    }

    yield put(toggleRowSelection())

    const currentPage = yield select(selectors.getPage)
    const currentLimit = yield select(selectors.getLimit)

    yield put(
      fetchDataAction({
        page: currentPage,
        limit: currentLimit
      })
    )
  } catch (e) {
    yield showErrorMessage(e.message)
  }
}

function* onRowToggle({payload}) {
  const isRowClosing = yield select(selectors.isRowExpanded, payload.id)

  if (isRowClosing) {
    yield put(toggleRowSelection(payload))

    return
  }

  let fetchHandler

  try {
    const isRowDataFetched = yield select(selectors.rowHasData, payload)

    if (!isRowDataFetched) {
      fetchHandler = yield put(fetchRowDataAction(payload))
    }

    yield put(toggleRowSelection(payload))
  } catch (error) {
    yield cancel(fetchHandler)
  }
}

const unique = (array) => Array.from(new Set(array))

function* fetchRowData({payload}) {
  try {
    const ticketDocumentIds = yield select(selectors.getTicketDocumentIds, {
      id: payload.id
    })
    const uniqueIds = unique(ticketDocumentIds)

    const {documentData} = yield call(
      TicketApi.fetchCarOwnershipTicketDetails,
      {documentIds: uniqueIds}
    )

    yield put(fetchRowDataSuccess({...payload, documentData}))
  } catch (error) {
    yield put(fetchRowDataError({id: payload.id, error}))
  }
}

const getParams = (payload) => {
  const field = payload.field.key

  if (field === 'status') {
    return {
      id: payload.row.ticketEntityId,
      [field]: new gql.EnumType(payload.value)
    }
  }

  return null
}

function* onUpdateTicketEntity({payload}) {
  try {
    const params = getParams(payload)
    const canUpdateTicketEntity = select(selectors.hasCreateAccess)

    if (!params || !canUpdateTicketEntity) {
      return
    }

    const response = yield call(TicketApi.updateOwnershipTicketEntity, params)

    if (!response.updateOwnershipTicketEntity) {
      throw new Error('Error while updating ticket entity!')
    }

    yield put(updateTicketEntitySuccess(response.updateOwnershipTicketEntity))
    yield showSuccessMessage('carOwnershipTransfer.ticketEntity.updatedField', {
      field: payload.field.key
    })
  } catch (error) {
    yield put(updateTicketEntityError(error))
  }
}

function* refreshRow({payload}) {
  try {
    const ticketId = payload.ticketId

    if (!ticketId) {
      return
    }

    const params = {
      id: ticketId
    }

    const ticket = yield call(TicketApi.getOwnershipTicketList, params)

    if (!ticket.ownershipTicket || !ticket.ownershipTicket.list) {
      throw new Error()
    }

    yield put({
      type: types.dataLoadingTypes.updateOne,
      payload: {
        data: ticket.ownershipTicket.list[0],
        flatten: 1
      }
    })

    yield call(fetchRowData, {
      payload: {id: ticket.ownershipTicket.list[0].id}
    })
  } catch (error) {
    yield showErrorMessage(error.message)
  }
}

function* onAttachDocuments({payload}) {
  try {
    const canUpdateTicket = yield select(selectors.hasFullAccess)

    if (!canUpdateTicket) {
      yield showErrorMessage('carOwnershipTransfer.attach.noAccess')
    }

    yield put(createTicketEntitiesStart())

    const {ticketId, documentComments} = payload
    const documents = yield select(documentList)

    const params = documents.reduce(
      (acc, document, index) => ({
        ...acc,
        [`document${index}`]: {
          ticketId,
          entityId: document.id,
          entityType: TICKET_ENTITY_TYPES_ENUM.DOCUMENT,
          status: TICKET_ENTITY_STATUS_ENUM.OPEN,
          metaInfo: {comment: documentComments[document.id] || ''}
        }
      }),
      {}
    )

    yield call(TicketApi.createOwnershipTicketEntity, params)
    yield delay(200)
    yield call(refreshRow, {payload: {ticketId}})
    yield put(cleanUp())
    yield put(createTicketEntitiesSuccess())
  } catch (error) {
    yield showErrorMessage(error.message)
    yield put(createTicketEntitiesError(error))
  }
}

function* cleanUpDocuments() {
  try {
    const documetnList = yield select(documentList)

    if (documetnList && !documetnList.length) {
      return
    }

    const documentIds = documetnList.map(({id}) => id)

    yield put(
      deleteDocument({
        documentIds,
        doNotFetch: true
      })
    )
    yield put(clearDocumentDialogAction())
  } catch (error) {
    yield showErrorMessage(error.message)
  }
}

function* clearDocumentDialog() {
  yield put(cleanUp())
}

const setCategoryLabelPrefix = (name) => `car.page.saleDocumentCategory.${name}`

export function* fetchCountryOptions() {
  try {
    const country = yield select(getCountryCode)

    const data = yield call(
      CarApi.getCountryOptionsForType,
      {saleDocumentCategory: {type: 'saleDocumentCategory'}},
      {country}
    )

    if (
      !data ||
      !data.saleDocumentCategory ||
      !data.saleDocumentCategory.list
    ) {
      throw new Error('No document categories available.')
    }

    yield put(
      setPageOptions(
        getCountryOptions(data, setCategoryLabelPrefix).saleDocumentCategory
      )
    )
  } catch (e) {
    yield showErrorMessage(e)
  }
}

export function* updateDocumentCategory(action) {
  try {
    const document = {
      id: action.payload.id,
      category: action.payload.category
    }

    const response = yield call(DocumentApi.updateDocument, {
      documents: [document]
    })

    yield put({
      type: types.updateDocumentCategorySuccess,
      payload: response.updateDocument[0]
    })
    yield showSuccessMessage(
      'carOwnershipTransfer.ticketEntity.updateCategory',
      {
        category: document.category
      }
    )
  } catch (error) {
    yield showErrorMessage(error)
  }
}

export const sagas = [
  takeLatest(types.fetchData, fetchData),
  takeLatest(types.updateFilters, fetchData),
  takeLatest(types.approveTicket, onApproveTicket),
  takeLatest(types.fetchCurrentPage, fetchCurrentPage),
  takeLatest(types.fetchRowData, fetchRowData),
  takeLatest(types.toggleRow, onRowToggle),
  takeLatest(types.updateTicketEntity, onUpdateTicketEntity),
  takeLatest(types.attachDocumentsToTicket, onAttachDocuments),
  takeLatest(types.cleanUpDocuments, cleanUpDocuments),
  takeLatest(types.clearDocumentDialog, clearDocumentDialog),
  takeLatest(types.fetchPageOptions, fetchCountryOptions),
  takeLatest(types.updateDocumentCategory, updateDocumentCategory)
]
