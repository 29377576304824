/* global NODE_ENV */
import {WebStorageStateStore, localStorage} from 'oidc-client-ts'

export function getJumpCloudConfig(config, jumpCloudConfig) {
  const {url} = config
  const {authority, clientId} = jumpCloudConfig
  const urlDomain = `admin2.${url}`

  // Use the one of the following for local development
  // redirect_uri: `http://localhost:11000/login/callback`,
  // redirect_uri: `https://local.${urlDomain}/login/callback`,

  return {
    authority: authority,
    client_id: clientId,
    redirect_uri: `https://${urlDomain}/login/callback`,
    scope: 'openid email profile offline_access',
    refreshTokenAllowedScope: 'openid email profile offline_access',
    automaticSilentRenew: false,
    checkSessionIntervalInSeconds: 60,
    userStore: new WebStorageStateStore({
      store: localStorage,
      prefix: 'adminapp2.'
    })
  }
}
