const contactFields = ['firstname', 'lastname', 'phone']

const appointmentDependencies = [
  'type',
  'placeId',
  'slot',
  'bookingSource',
  'rejectionReasons',
  'callStatus',
  'status',
  'cancellationReason',
  'comments'
]

const carDataDependencies = [
  'make',
  'model',
  'year',
  'trim',
  'mileage',
  'transmission',
  'bodytype'
]

const leadDependencies = [
  ...contactFields,
  ...carDataDependencies,
  'plate',
  'leadSource',
  'referralCode',
  'price',
  'minPrice',
  'maxPrice',
  'serviceType',
  'discardedReason',
  'status',
  'externalLeadId',
  'responsible'
]
const leadRequiredFields = [
  {
    field: 'leadStatus',
    dependencies: leadDependencies
  },
  {
    field: 'leadSource',
    dependencies: leadDependencies
  },
  {
    field: 'inspectionType',
    dependencies: []
  },
  {
    field: 'VINnumber',
    dependencies: []
  }
]
const autoAllocation = [
  'tierName',
  'tierWeight',
  'tierMaxLeads',
  'tierUserGroup'
]

const requiredFields = ['make', 'model', 'year']

const purchaseCoordinatorInput = {
  key: 'group',
  queryValue: {include: ['CMS', 'INSPECTOR']}
}

module.exports = {
  required: [
    {
      field: 'type',
      dependencies: appointmentDependencies
    },
    {
      field: 'placeId',
      dependencies: appointmentDependencies
    },
    {
      field: 'slot',
      dependencies: appointmentDependencies
    },
    {
      field: 'bookingSource',
      dependencies: appointmentDependencies
    },
    {
      field: 'status',
      dependencies: appointmentDependencies
    },
    ...leadRequiredFields,
    ...contactFields,
    ...autoAllocation,
    ...requiredFields
  ],
  hideBookingSourceForUpdate: true,
  hideLeadSourceForUpdate: true,
  hidden: [
    'lead.bodyType',
    'lead.faceLift',
    'lead.seat',
    'lead.engine',
    'lead.wheelDrive',
    'tierAppointmentStatus',
    'tierLocation',
    'minPrice',
    'maxPrice',
    'externalLeadId',
    'city',
    'postcode',
    'VINnumber',
    'ownerName',
    'ownerPhone',
    'selfInspectionPrice',
    'purchaseCoordinator',
    'purchaseCoordinatorPhone'
  ],
  dependencies: {
    assignedTo: ['placeId']
  },
  landingPage: ['tasks', 'leads'],
  hiddenFilters: [
    'tierAppointmentStatus',
    'tierLocation',
    'tierMake',
    'postcode',
    'city',
    'taxId',
    'tierLeadSource',
    'tierLeadStatus',
    'appointmentAddress',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'leadSource',
    'bookingSource'
  ],
  hiddenColumns: [
    'tierAppointmentStatus',
    'tierLocation',
    'plate',
    'postcode',
    'city',
    'lead.status',
    'leadResponsibleName',
    'updated',
    'taxId',
    'VINnumber',
    'selfInspectionExists',
    'selfInspectionPrice',
    'selfInspectionPriceExists',
    'phone',
    'leadSource',
    'bookingSource',
    'leadCity',
    'sourcePathVariant',
    'carInfoExtraFields',
    'carInfoExtraFieldsAppointment'
  ],
  hiddenFeatures: [
    {
      name: 'deleteButton',
      conditions: {
        hiddenExceptFor: ['usermanager.in', 'superadmin']
      }
    },
    {
      name: 'phone',
      conditions: {}
    },
    {
      name: 'phone2',
      conditions: {}
    }
  ],
  appointmentTypesForWhichAddressIsRequired: [
    'c2cMeetingHome',
    'dealerInspectionHome',
    'purchaseInspectionHome'
  ],
  disabledFields: {walkin: {leadForm: ['price']}},
  purchaseCoordinatorInput
}
