const resourceName = 'webhook'

export const fetchWebhookList = `${resourceName}/FETCH_WEBHOOK_LIST`
export const fetchWebhookListSuccess = `${resourceName}/FETCH_WEBHOOK_LIST_SUCCESS`
export const fetchWebhookListError = `${resourceName}/FETCH_WEBHOOK_LIST_ERROR`
export const updateWebhookField = `${resourceName}/UPDATE_WEBHOOK_FIELD`

export const toggleSort = `${resourceName}/TOGGLE_SORT`
export const updateFilters = `${resourceName}/UPDATE_FILTERS`
export const setPageOptions = `${resourceName}/SET_PAGE_OPTIONS`
export const toggleField = `${resourceName}/TOGGLE_FIELD`

export const deleteWebhook = `${resourceName}/DELETE_WEBHOOK`
export const deleteWebhookSuccess = `${resourceName}/DELETE_WEBHOOK_SUCCESS`
export const deleteWebhookError = `${resourceName}/DELETE_WEBHOOK_ERROR`
export const createWebhook = `${resourceName}/CREATE_WEBHOOK`
export const createWebhookSuccess = `${resourceName}/CREATE_WEBHOOK_SUCCESS`
export const createWebhookError = `${resourceName}/CREATE_WEBHOOK_ERROR`

export const fetchWebhookEventsList = `${resourceName}/FETCH_WEBHOOK_EVENTS_LIST`
export const fetchWebhookEventsListSuccess = `${resourceName}/FETCH_WEBHOOK_EVENTS_LIST_SUCCESS`
export const fetchWebhookEventsListError = `${resourceName}/FETCH_WEBHOOK_EVENTS_LIST_ERROR`

export const openCreateDialog = `${resourceName}/OPEN_CREATE_DIALOG`
export const hideCreateDialog = `${resourceName}/HIDE_CREATE_DIALOG`

export const openNonceDialog = `${resourceName}/OPEN_NONCE_DIALOG`
export const hideNonceDialog = `${resourceName}/HIDE_NONCE_DIALOG`
