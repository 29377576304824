import {Fragment, useState, useCallback} from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import selectors from '../../store/countryOption/selectors'
import {openCreateDialog} from '../../store/countryOption/actions'
import {selectBusinessPartnerForBulkUpload} from './../../store/bulkUpload/actions'
import {connect} from 'react-redux'
import {countryOptionFormConfig} from '../../config/pages/countryOption/countryOptionFormConfig'
import SearchBPModal from './../BusinessPartners/BPSearchModal/SearchBPModal'

const B2BAddInlineCountryOptionWrapper = styled.div`
  margin-top: 40px;
  color: #3f51b5;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
`

export function B2BAddInlineCountryOption({
  t,
  _selectBusinessPartnerForBulkUpload
}) {
  const [showSearchDialog, toggleSearchDialogState] = useState(false)
  const toggleSearchDialog = useCallback(() => {
    toggleSearchDialogState((flag) => !flag)
  }, [])

  const onChoosingBP = useCallback(
    (businessPartner) => {
      _selectBusinessPartnerForBulkUpload({businessPartner})
    },
    [_selectBusinessPartnerForBulkUpload]
  )

  return (
    <Fragment>
      <B2BAddInlineCountryOptionWrapper onClick={toggleSearchDialog}>
        {t('countryOption.add.new.b2b')}
      </B2BAddInlineCountryOptionWrapper>
      {showSearchDialog && (
        <SearchBPModal
          onClose={toggleSearchDialog}
          onBPCreate={onChoosingBP}
          onBPSelect={onChoosingBP}
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  showCreateDialog: selectors.getCreateDialogState(state),
  pageConfig: countryOptionFormConfig
})

const mapDispatchToProps = {
  _openCreateDialog: openCreateDialog,
  _selectBusinessPartnerForBulkUpload: selectBusinessPartnerForBulkUpload
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(B2BAddInlineCountryOption))
