import {useRef, useCallback, useContext, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import {translate} from 'react-i18next'
import {ValidatedForm} from '@fcg/formvalidation'
import {BPDetailsContext} from '../context/BPDetailsContext'
import DetailTabs from './DetailTabs'
import {BPDetailsDispatchContext} from '../context/BPDetailsDispatchContext'
import {UPDATE_FIELD} from './../actions/bpDetailsActions'
import {updateBusinessPartner} from '../../../store/businessPartners/actions'
import * as BusinessPartnerApi from './../../../api/businessPartners/requests'
import {
  showErrorMessage,
  showSuccessMessage
} from '../../../store/signals/actions'
import {
  PERMISSION_ENTITY,
  PRIMARY_CONTACT_TYPE,
  DEALER_BP_TYPE
} from './../constants/businessPartners'

import {checkUserPermissions} from '../../../store/auth/selectors'
import TagModal from './TagModal'
import {getCountryCode} from '../../../store/config/selectors'
import CountryOptionsSelect from '../../../components/CountryOptionsSelect/CountryOptionsSelect'
import {ValidatedTextField} from '../../../components/FormValidation'
import {ConfigContext} from '../../../components/providers/withConfig'

const dialogActionsStyles = {
  position: 'sticky',
  bottom: '0',
  right: '0',
  background: 'white',
  border: '1px solid rgba(183, 174, 174, 0.54)',
  padding: '8px 4px',
  margin: '0'
}

export function BPDetailsModalUI({
  t,
  countryCode,
  onClose,
  _updateBusinessPartner,
  _showErrorMessage,
  hasPermissions,
  _showSuccessMessage
}) {
  const bpInfo = useContext(BPDetailsContext)
  const dispatch = useContext(BPDetailsDispatchContext)
  const {businessPartnerId, type, contacts, tagInfo} = bpInfo
  const {bpAddFormConfig} = useContext(ConfigContext)

  const formRef = useRef()

  const [showTagModal, setTagModalFlag] = useState(false)

  const [tagString, setTagString] = useState(() => {
    let tagStr = ''
    if (tagInfo && tagInfo.length > 0) {
      tagStr = tagInfo.map((ele) => ele.tagName).join(',')
    }

    return tagStr
  })

  const hasAddEditPermission = hasPermissions({
    entity: PERMISSION_ENTITY,
    types: ['UPDATE']
  })

  const closeTagModal = () => {
    setTagModalFlag(false)
  }

  const _disableEditOrAdd =
    !hasAddEditPermission ||
    (type === DEALER_BP_TYPE && !bpAddFormConfig?.includeDealerType)

  const handleTagClick = () => {
    if (!_disableEditOrAdd) {
      setTagModalFlag(true)
    }
  }

  const saveTagInfo = (tagData) => {
    if (tagData.length > 0) {
      const tagStr = tagData
        .filter((tag) => !tag.markDeleted)
        .map((ele) => ele.tagName)
        .join(',')
      setTagString(tagStr)

      dispatch({
        type: UPDATE_FIELD,
        payload: {
          fieldName: 'tagInfo',
          value: tagData
        }
      })
    }
  }

  const handleSubmit = useCallback(async () => {
    try {
      const success = await formRef.current.validate()
      if (success) {
        const finalBPInfo = await BusinessPartnerApi.updateBusinessPartner({
          ...bpInfo,
          country: countryCode
        })
        _updateBusinessPartner(finalBPInfo?.updateBBDealsBusinessPartner)
        onClose()
        _showSuccessMessage(t('businessPartners.b2bBP.update.success'))
      }
    } catch (err) {
      _showErrorMessage(err.message)
    }
  }, [
    bpInfo,
    countryCode,
    _updateBusinessPartner,
    onClose,
    _showErrorMessage,
    _showSuccessMessage,
    t
  ])

  const handleTextInput = (evt, key) => {
    dispatch({
      type: UPDATE_FIELD,
      payload: {
        fieldName: key,
        value: evt.target.value
      }
    })
  }

  const handleCancel = () => {
    onClose()
  }

  const {userId, legacyId} =
    Array.isArray(contacts) &&
    contacts.length > 0 &&
    contacts.find(({type}) => type === PRIMARY_CONTACT_TYPE)
      ? contacts.find(({type}) => type === PRIMARY_CONTACT_TYPE)
      : {}

  const bpInfoClone = {
    ...bpInfo,
    legacyId: legacyId,
    userId: userId,
    tag: tagString
  }

  return (
    <Fragment>
      <Dialog
        onClose={handleCancel}
        maxWidth='lg'
        open
        fullWidth
        data-test='bp-detail-modal'
      >
        <ValidatedForm ref={formRef} t={t}>
          <DialogTitle id='form-dialog-title'>
            Business Partners #{businessPartnerId}
          </DialogTitle>
          <DialogContent>
            <div>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={4}>
                  <CountryOptionsSelect
                    label={t('businessPartners.type.label')}
                    selected={type}
                    name='type'
                    countryOptionType='businessPartnerType'
                    value={type}
                    optionLabelPrefix='businessPartners'
                    margin='normal'
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ValidatedTextField
                    label={t('businessPartners.legalName.label')}
                    value={bpInfoClone.legalName || ''}
                    id='legalName'
                    margin='normal'
                    required
                    fullWidth
                    maxLength={80}
                    disabled={_disableEditOrAdd}
                    onChange={(evt) => handleTextInput(evt, 'legalName')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ValidatedTextField
                    label={t('businessPartners.dealerName.label')}
                    value={bpInfoClone.dealerName || ''}
                    id='dealerName'
                    margin='normal'
                    fullWidth
                    maxLength={80}
                    disabled={_disableEditOrAdd}
                    onChange={(evt) => handleTextInput(evt, 'dealerName')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('businessPartners.userId.label')}
                    value={bpInfoClone.userId || ''}
                    id='userId'
                    margin='normal'
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('businessPartners.legacyId.label')}
                    value={bpInfoClone.legacyId || ''}
                    id='userId'
                    margin='normal'
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('businessPartners.salesforceId.label')}
                    value={bpInfoClone.salesforceId || ''}
                    id='userId'
                    margin='normal'
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('businessPartners.tag.label')}
                    value={tagString}
                    id='tag'
                    margin='normal'
                    readOnly
                    disabled={_disableEditOrAdd}
                    onClick={handleTagClick}
                    fullWidth
                  />
                </Grid>
                {type === DEALER_BP_TYPE && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t('businessPartners.subType.label')}
                      value={bpInfoClone.subType || ''}
                      id='tag'
                      margin='normal'
                      disabled
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <CountryOptionsSelect
                    label={t('businessPartners.status.label')}
                    selected={bpInfoClone.status || ''}
                    name='status'
                    id='status'
                    countryOptionType='BP_Status_Type'
                    value={bpInfoClone.status || ''}
                    optionLabelPrefix='businessPartners'
                    margin='normal'
                    required
                    disabled={_disableEditOrAdd}
                    onChange={(evt) => handleTextInput(evt, 'status')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div>
                <DetailTabs disableEditOrAdd={_disableEditOrAdd} />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={dialogActionsStyles}>
            <Button
              onClick={handleCancel}
              color='primary'
              data-test='bp-detail-modal-cancel'
            >
              {t('button.cancel')}
            </Button>

            {!_disableEditOrAdd && (
              <Button
                variant='raised'
                type='submit'
                onClick={handleSubmit}
                color='primary'
                data-test='bp-detail-modal-submit'
              >
                {t('global.action.saved')}
              </Button>
            )}
          </DialogActions>
        </ValidatedForm>
      </Dialog>
      {showTagModal && (
        <TagModal
          onClose={closeTagModal}
          saveTags={saveTagInfo}
          tagInfo={tagInfo}
          countryOptionType='BP_Tag_Type'
        />
      )}
    </Fragment>
  )
}

BPDetailsModalUI.propTypes = {
  onClose: PropTypes.func.isRequired,
  _updateBusinessPartner: PropTypes.func.isRequired,
  _showErrorMessage: PropTypes.func.isRequired,
  hasPermissions: PropTypes.func,
  _showSuccessMessage: PropTypes.func
}
const mstp = (state) => ({
  hasPermissions: (permissions) => checkUserPermissions(state, permissions),
  countryCode: getCountryCode(state)
})

const mdtp = {
  _updateBusinessPartner: updateBusinessPartner,
  _showErrorMessage: showErrorMessage,
  _showSuccessMessage: showSuccessMessage
}

export default compose(translate(), connect(mstp, mdtp))(BPDetailsModalUI)
