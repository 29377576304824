export const globalPlaceFields = ['id', 'email', 'image']

export const openingTimeFields = [
  'id',
  'bookingType',
  'slots',
  'openingTime',
  'closingTime',
  'weekday',
  'closed'
]

export const openingTimesFields = [['openingTime', [...openingTimeFields]]]

export const servicesConfigurationFields = [
  'id',
  'type',
  'duration',
  'interval',
  'minAllowedTime',
  'minAllowedTimeUnit',
  'maxAllowedTime',
  'maxAllowedTimeUnit'
]
export const globalSpecialOpeningFields = [
  'id',
  'title',
  'cities',
  'applyToAllCities',
  'from',
  'to'
]

export const locationSpecialOpeningFields = [
  ...globalSpecialOpeningFields,
  'closed'
]

export const globalTemplateFields = [
  ['place', [...globalPlaceFields]],
  ['openingTimes', [...openingTimesFields]],
  ['servicesConfiguration', [...servicesConfigurationFields]],
  ['specialOpening', [...globalSpecialOpeningFields]]
]

const specialOpeningTimeFields = [
  'bookingType',
  'slots',
  'openingTime',
  'closingTime',
  'closed'
]
export const specialOpeningFields = [
  'id',
  'title',
  'globalSpecialOpeningId',
  'from',
  'to',
  'closed',
  ['openingTimes', [...specialOpeningTimeFields]]
]

export const specialOpeningListFields = [['list', [...specialOpeningFields]]]
