import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

export default (theme) => ({
  success: {
    color: green[500]
  },
  error: {
    color: red[500]
  },
  regular: {
    color: theme.palette.primary.contrastText
  }
})
