import { AttendanceManager } from '../../pages/ModulePages/AttendanceManager'

export default {
    path: 'attendance-manager/',
    exact: false,
    label: 'Attendance Manager',
    key: 'attendanceManager',
    component: AttendanceManager,
    fields: {}
}
