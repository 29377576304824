export const CAR_TYPE = [
  {
    value: 'SELLABLE',
    label: 'carData.field.isSellable.SELLABLE'
  },
  {
    value: 'NOT_SELLABLE',
    label: 'carData.field.isSellable.NOT_SELLABLE'
  }
]
