
import { createContext, ReactNode, useReducer } from "react";
import { StoreObject } from "../types";
import Country from "../types/Country";
import DateRange from "../types/Date";

type UploadResponse = {
    slots: string,
    status: string,
    message: string
}

type Downloadedslots = {
    csv: string | null,
    message: string,

}

type UploadHistory = {
    history: { data: Array<Object> },

}
type ReservationsData = {
    csv: string | null
}
type Stores = {
    status: Number,
    data: Array<StoreObject>
}
type ResponseBar = {
    type?: 'error' | 'info' | 'success' | 'warning'
    message?: string
}
interface State {
    uploadResponse: UploadResponse,
    downloadedSlots: Downloadedslots,
    uploadHistoryData: UploadHistory,
    stores: Stores,
    reservations: ReservationsData,
    selectedDateRange: DateRange,
    countryCode: Country,
    isLoading: boolean,
    responseBar: ResponseBar
}

const initialState: State = {
    uploadResponse: {
        slots: "",
        status: "",
        message: ""
    },
    uploadHistoryData: {
        history: { data: [] }
    },
    downloadedSlots: {
        csv: null,
        message: ""
    },
    reservations: {
        csv: '[]'
    },
    stores: {
        status: 1,
        data: []
    },
    selectedDateRange: {
        from: new Date(),
        to: new Date()
    },
    isLoading: false,
    countryCode: 'IN',
    responseBar: {}
}

type Action = { type: '', value: '' }
    | { type: 'INSERT_SLOTS', value: UploadResponse }
    | { type: 'UPDATE_SLOTS', value: UploadResponse }
    | { type: 'GET_SLOTS', value: Downloadedslots }
    | { type: 'GET_UPLOAD_HISTORY', value: UploadHistory }
    | { type: 'SET_SELECTED_DATERANGE', value: DateRange }
    | { type: 'SET_COUNTRY_CODE', value: Country }
    | { type: 'SET_IS_LOADING', value: boolean }
    | { type: 'SET_RESPONSE_BAR', value: ResponseBar }
    | { type: 'SET_RESERVATIONS', value: ReservationsData }
    | { type: 'SET_STORES', value: Stores }

type ContextValue = State & {
    setInsertSlots(value: UploadResponse): void,
    setUpdateSlots(value: UploadResponse): void,
    setDownloadedSlots(value: Downloadedslots): void,
    setUploadHistoryData(value: UploadHistory): void,
    setSelectedDateRange(value: DateRange): void,
    setCountryCode(value: Country): void,
    setIsLoading(value: boolean): void,
    setResponseBar(value: ResponseBar): void,
    setReservations(value: ReservationsData): void,
    setStores(value: Stores): void
}

export const MainContext = createContext<ContextValue>(null!)
MainContext.displayName = 'MainContext'

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'INSERT_SLOTS':
            return { ...state, uploadResponse: action.value }
        case 'UPDATE_SLOTS':
            return { ...state, uploadResponse: action.value }
        case 'GET_SLOTS':
            return { ...state, downloadedSlots: action.value }
        case 'GET_UPLOAD_HISTORY':
            return { ...state, uploadHistoryData: action.value }
        case 'SET_SELECTED_DATERANGE':
            return { ...state, selectedDateRange: action.value }
        case 'SET_COUNTRY_CODE':
            return { ...state, countryCode: action.value }
        case 'SET_IS_LOADING':
            return { ...state, isLoading: action.value }
        case "SET_RESPONSE_BAR":
            return { ...state, responseBar: action.value }
        case 'SET_STORES':
            return { ...state, stores: action.value }
        case "SET_RESERVATIONS":
            return { ...state, reservations: action.value }
        default:
            return { ...state }
    }
}

export default ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const value: ContextValue = {
        uploadResponse: state.uploadResponse,
        uploadHistoryData: state.uploadHistoryData,
        downloadedSlots: state.downloadedSlots,
        selectedDateRange: state.selectedDateRange,
        countryCode: state.countryCode,
        isLoading: state.isLoading,
        responseBar: state.responseBar,
        reservations: state.reservations,
        stores: state.stores,
        setDownloadedSlots: (value: Downloadedslots) => {
            dispatch({
                type: 'GET_SLOTS',
                value
            })
        },
        setInsertSlots: (value: UploadResponse) => {
            dispatch({
                type: 'INSERT_SLOTS',
                value
            })
        },
        setUpdateSlots: (value: UploadResponse) => {
            dispatch({
                type: 'UPDATE_SLOTS',
                value
            })
        },
        setUploadHistoryData: (value: UploadHistory) => {
            dispatch({
                type: 'GET_UPLOAD_HISTORY',
                value
            })
        },
        setReservations: (value: ReservationsData) => {
            dispatch({
                type: 'SET_RESERVATIONS',
                value
            })
        },
        setStores: (value: Stores) => {
            dispatch({
                type: 'SET_STORES',
                value
            })
        },
        setSelectedDateRange: (value: DateRange) => {
            dispatch({
                type: 'SET_SELECTED_DATERANGE',
                value
            })
        },
        setCountryCode: (value: Country) => {
            dispatch({
                type: 'SET_COUNTRY_CODE',
                value
            })
        },
        setIsLoading: (value: boolean) => {
            dispatch({
                type: 'SET_IS_LOADING',
                value
            })
        },
        setResponseBar: (value) =>
            dispatch({
                type: "SET_RESPONSE_BAR",
                value: value
            }),
    }

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}
