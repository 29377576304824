import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../../config/country'

const selectorPath = (state) => state.task

export const taskFields = (state) => {
  const {list} = selectorPath(state)

  if (list.fields) {
    return Object.values(list.fields)
  }
}

const childrenReducer = (field, hiddenColumns) => {
  if (field && hiddenColumns.includes(field.columnName)) {
    return -1
  }

  if (field.type === 'composite' && field.children && field.children.length) {
    field = {
      ...field,
      children: field.children.map((child) => {
        return childrenReducer(child, hiddenColumns)
      })
    }
  }

  return field
}

export const getTaskFields = createSelector(taskFields, (fields) => {
  const {crmDetails: config} = getCountryConfig()

  if (!config.hiddenColumns && fields) {
    return fields
  }

  if (fields && fields.length) {
    return fields.map((field) => childrenReducer(field, config.hiddenColumns))
  }

  return []
})
