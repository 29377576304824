import {useState, useCallback, useContext, useRef, useMemo} from 'react'
import PropTypes from 'prop-types'
import {translate} from 'react-i18next'
import {Edit, Delete} from '@material-ui/icons'
import {RichTable} from '@fcg/tars'
import moment from 'moment'

import {getVisibleActions} from '../../CRM/common/helpers'
import {DeleteDialog} from '../../CRM/common'
import OutlinedButton from '../../../components/Buttons/OutlinedButton'
import {BPDetailsContext} from '../context/BPDetailsContext'
import {ConfigContext} from '../../../components/providers/withConfig'
import EditRecordModal from '../EditRecordModal/EditRecordModal'
import {BPDetailsDispatchContext} from '../context/BPDetailsDispatchContext'
import {DELETE_RECORD} from '../actions/bpDetailsActions'

const DEFAULT_ARRAY = []

export function TabContent({t, hidden, tabInfo, disableEditOrAdd, bpType}) {
  const bpInfo = useContext(BPDetailsContext)
  const dispatch = useContext(BPDetailsDispatchContext)
  const {bpBasedTaxInfoConditions} = useContext(ConfigContext)

  const records =
    bpInfo[tabInfo.id] && bpInfo[tabInfo.id].length > 0
      ? bpInfo[tabInfo.id].filter((record) => !record.markDeleted)
      : DEFAULT_ARRAY

  let formatedRecords = records.map((item) => {
    return {...item}
  })

  if (records.length > 0) {
    if (tabInfo.id === 'contacts') {
      formatedRecords = records.map((contact) => {
        return {
          ...contact,
          dateOfBirth: contact.dateOfBirth
            ? moment(new Date(contact.dateOfBirth)).format('YYYY-MM-DD')
            : null
        }
      })
    }

    if (tabInfo.id === 'address') {
      formatedRecords = records.map((address) => {
        return {
          ...address,
          state: address.state
            ? t(`businessPartners.address.state.${address.state}`)
            : null
        }
      })
    }
  }

  const [showEditModal, setEditModalFlag] = useState(false)
  const [showDeleteDialog, setDeleteDialogFlag] = useState(null)
  const [userAction, setUserAction] = useState(null)
  const recordIdx = useRef()

  const toggleEditModal = useCallback((idx) => {
    setEditModalFlag((flag) => !flag)
    recordIdx.current = idx
  }, [])

  const toggleDeleteDialog = useCallback((idx) => {
    setDeleteDialogFlag((flag) => !flag)
    recordIdx.current = idx
  }, [])

  const handleDeleteRecord = useCallback(() => {
    dispatch({
      type: DELETE_RECORD,
      payload: {
        section: tabInfo.id,
        recordIdx: recordIdx.current
      }
    })
    toggleDeleteDialog()
  }, [dispatch, toggleDeleteDialog, tabInfo])

  const handleAddNewRecord = useCallback(() => {
    setUserAction('add')
    toggleEditModal()
  }, [toggleEditModal])

  const singleSelectActions = [
    {
      test: () => !disableEditOrAdd, //check permissions of edit button visibility
      action: {
        event: ({idx}) => {
          setUserAction('edit')
          toggleEditModal(idx)
        },
        icon: <Edit />,
        title: 'global.action.edit'
      }
    },
    {
      test: () =>
        // if current tab is taxInfo and there is a restriction bsed on bp type
        //    > dont show delete
        // else
        //    > show or no show based on disableEditOrAdd
        (tabInfo.id !== 'taxInfo' || !bpBasedTaxInfoConditions) &&
        !disableEditOrAdd, //check permissions for delete button visibility
      action: {
        event: ({idx}) => {
          toggleDeleteDialog(idx)
        },
        icon: <Delete />,
        title: 'global.action.delete'
      }
    }
  ]

  const singleSelectTableActions = () => getVisibleActions(singleSelectActions)

  const data = useMemo(
    () =>
      formatedRecords.map((recordVal, idx) => {
        return Object.keys(recordVal).reduce(
          (acc, recordKey) => {
            if (typeof recordVal[recordKey] === 'boolean') {
              acc[recordKey] = recordVal[recordKey] ? 'Yes' : 'No'
            } else {
              acc[recordKey] = recordVal[recordKey]
            }

            return acc
          },
          {idx}
        )
      }),
    [formatedRecords]
  )

  const fields = tabInfo.fields.map((field) => {
    return {
      key: field.id,
      label: t(`businessPartners.${tabInfo.id}.${field.id}.text`),
      type: 'string',
      isFilterable: false
    }
  })

  return (
    <div hidden={hidden}>
      <RichTable
        data={data}
        fields={fields}
        noFooter
        singleSelectActions={singleSelectTableActions()}
      />
      {disableEditOrAdd ||
      (tabInfo.id === 'taxInfo' &&
        bpBasedTaxInfoConditions &&
        Object.keys(bpBasedTaxInfoConditions).length &&
        data.length > 0) ? null : (
        <OutlinedButton
          onClick={handleAddNewRecord}
          className='tabContent__addNewBtn'
          data-test={`bp-${tabInfo.id}-create-new`}
        >
          {t('businessPartners.addNew.text')}
        </OutlinedButton>
      )}
      <DeleteDialog
        open={showDeleteDialog}
        handleCancel={toggleDeleteDialog}
        handleSubmit={handleDeleteRecord}
        content={t('global.action.delete.dialog.messageConfirmDelete')}
      />
      {showEditModal ? (
        <EditRecordModal
          onClose={toggleEditModal}
          action={userAction}
          record={records[recordIdx.current]}
          recordIdx={recordIdx.current}
          tabInfoId={tabInfo.id}
          bpType={bpType}
        />
      ) : null}
    </div>
  )
}

TabContent.propTypes = {
  t: PropTypes.func.isRequired,
  disableEditOrAdd: PropTypes.bool,
  businessPartnerInfo: PropTypes.object,
  hidden: PropTypes.bool,
  tabInfo: PropTypes.object
}

export default translate()(TabContent)
