import merge from 'lodash/merge'

export const leadSchema = {
  id: '',
  make: '',
  model: '',
  trim: '',
  faceLift: '',
  seat: '',
  engine: '',
  wheelDrive: '',
  transmission: '',
  year: '',
  mileage: '',
  price: '',
  minPrice: '',
  maxPrice: '',
  plate: '',
  quote: [],
  leadStatus: '',
  leadSource: '',
  responsible: [],
  responsibleName: [],
  referralCode: '',
  serviceType: '',
  discardedReason: null,
  externalLeadId: '',
  VINnumber: '',
  inspectionType: '',
  adId: '',
  ownerName: '',
  ownerPhone: '',
  selfInspectionPrice: '',
  bodyType: ''
}

export const state = {
  form: {...leadSchema},
  bulkForms: [],
  editState: {error: false, success: false, pending: false},
  inspections: [],
  list: {
    count: 0,
    page: 1,
    limit: 25,
    fetching: false,
    sort: [{field: 'createdAt', order: 'DESC'}],
    data: {},
    fields: []
  },
  carId: ''
}

export const getInitialState = (preferences = {}) => {
  const setup = merge(state, preferences)

  return {
    ...setup,
    list: {
      ...setup.list,
      fields: Object.values(setup.list.fields)
    }
  }
}
