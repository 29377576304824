import React, { ReactChildren } from 'react'
import ListItem from '@material-ui/core/ListItem'
import {withStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

const StyledListItem = withStyles({
  root: {
    minHeight: 74,
    cursor: 'pointer',
    borderBottom: '1px solid transparent',
    borderTop: '1px solid transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&$selected': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundColor: 'white'
    }
  },
  selected: {}
})(ListItem)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: 8,
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main
  }
}))

interface SBProps {
  selected: boolean
  children: React.ReactNode
  id:string
  onClick: (event: React.ChangeEvent<HTMLInputElement>) =>void
}

const SidebarListItem = ({selected, onClick, children, id}:SBProps) => {
  const classes = useStyles()

  return (
    <StyledListItem selected={selected} onClick={onClick} id={id}>
      {children}
      {selected && <Divider orientation='vertical' className={classes.root} />}
    </StyledListItem>
  )
}

export default SidebarListItem
