import React, { useContext, useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { MainContext } from '../../context/MainContext'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} {...props} />
}

const CustomResponseSnackbar = () => {
  const { responseBar } = useContext(MainContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!responseBar.type)
  }, [responseBar])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpen(false)
    if (reason === 'clickaway') {
      return
    }
  }

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open} autoHideDuration={3000} onClose={handleClose} data-test='snackbar-alert'>
      <Alert onClose={handleClose} severity={responseBar.type}>
        {responseBar.message}
      </Alert>
    </Snackbar>
  )
}

export default CustomResponseSnackbar
