// This file returns selectors
import {
  getCollectionInfo,
  isCacheValid,
  getObjects,
  getObject,
  findObject,
  findObjectKeys
} from './utils'

export const selectorPath = (state) => state.resources

export const getResourcesById = (type, id) => (state) => {
  const resources = getObjects(selectorPath(state), type, 'all')

  if (!resources || !resources[id]) {
    return {}
  }

  return resources[id]
}

export const getResourcesOfType = (type) => (state) =>
  getObjects(selectorPath(selectorPath(state)), type, 'all')

// TODO: Rethink this for future changes
export const getCollection = (
  type,
  name,
  cacheable = false,
  validate = false
) => (state) => {
  if (!cacheable) {
    return getObject(selectorPath(state), type, 'collections', name)
  }

  if (validate) {
    const {time} = getCollectionInfo(name)
    const cacheValid = isCacheValid(time)

    if (cacheValid) {
      return findObject(
        selectorPath(selectorPath(state)),
        type,
        'collections',
        name
      )
    }

    return null
  }

  return findObject(
    selectorPath(selectorPath(state)),
    type,
    'collections',
    name
  )
}

export const getCollectionKeys = (type, name) => (state) =>
  findObjectKeys(selectorPath(selectorPath(state)), type, 'collections', name)
