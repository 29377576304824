import {Component, createRef} from 'react'
import {renderResourceManagerToDom, unmount} from 'admin-resource-manager'

const ID_NAME = 'resource-manager'

export class ResourceManager extends Component {
  componentDidMount() {
    renderResourceManagerToDom(ID_NAME, this.props.countryCode)
  }

  container = createRef()

  componentWillUnmount() {
    unmount(this.container.current)
  }

  render() {
    return <div id={ID_NAME} ref={this.container} />
  }
}
