import {getCountryConfig} from '../../../config/country'
let countryConfig = getCountryConfig()

export const countryOptionFields = [
  'id',
  'name',
  'createdByName',
  'createdAt',
  ...(countryConfig.countryCode === 'AR' ? ['type'] : '')
]

export const countryoptionList = ['count', ['list', countryOptionFields]]
