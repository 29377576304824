import merge from 'lodash/merge'

export const state = {
  error: false,
  editState: {error: false, success: false, pending: false},
  list: {
    fetching: false,
    count: 0,
    page: 1,
    limit: 25,
    sort: [{field: 'dueDate', order: 'ASC'}],
    filters: [],
    fields: [],
    data: []
  }
}

export const getInitialState = (preferences = {}) => {
  const setup = merge(state, preferences)

  return {
    ...setup,
    list: {
      ...setup.list,
      fields: Object.values(setup.list.fields)
    }
  }
}
