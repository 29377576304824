import {Component} from 'react'
import {connect} from 'react-redux'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import {withStyles} from '@material-ui/core/styles'
import {SlideUp} from '../../../components/Animations/Slides'
import Form from './Edit'
import dialogStyles from '../common/dialogStyles'
import {loadOptions} from '../../../store/crm/actions'
import {getList} from '../../../store/locationManagement/actions'
import {ValidatedForm} from '@fcg/formvalidation'
import formWithCountryOptions from '../../../components/formWithCountryOptions'
import {tierFormConfig} from '../../../config/pages/crm/forms'
import {
  resetLeadAllocationForm,
  createTier,
  updateTier
} from '../../../store/crm/leadAllocation/actions'
import {getCarDataOptions} from '../../../store/config/actions'
import {getRoles, getLocations} from '../../../store/crmUserGroups/selectors'
import {getSelectedTier} from '../../../store/crm/leadAllocation/selectors'

export class TierDialog extends Component {
  state = {users: {options: [], count: 0}, data: {}}

  componentDidMount() {
    const {data} = this.props

    this.props.loadOptions()
    this.props.getMake()
    this.props.loadLocations({
      limit: 'NO_LIMIT',
      filters: [{key: 'active', value: true}]
    })
    this.setState({data})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.id) {
      this.setState({data: nextProps.data})
    }
  }

  update = ({name, value}) => {
    this.setState((state) => ({
      data: {
        ...state.data,
        [name]: value
      }
    }))
  }

  handleCancel = () => {
    this.props.reset()
    this.props.handleClose()
  }

  handleSubmit = async () => {
    const {data} = this.state

    if (data && data.id) {
      await this.props.save(data)
    } else {
      await this.props.create(data)
    }

    this.handleCancel()
  }

  setUserOptions = ({options, count}) => {
    this.setState({users: {options, count}})
  }

  render() {
    const {t, editState, data} = this.props
    const creation = !(data && data.id)
    const dialogTitle = creation
      ? `${t('global.action.create')} ${t('entity.tier')}`
      : `${t('global.action.update')} ${t('entity.tier')}`
    const buttonText = creation
      ? t('global.action.create')
      : t('global.action.update')

    if (editState.success) {
      this.props.handleSubmit()
    }

    return (
      <div>
        <Dialog
          open={this.props.isOpen}
          onClose={this.handleCancel}
          TransitionComponent={SlideUp}
          maxWidth='sm'
        >
          <ValidatedForm onSubmit={this.handleSubmit} t={t}>
            <DialogTitle id='form-dialog-title'>
              {`${dialogTitle} ${data ? data.tierName : ''}`}
            </DialogTitle>
            <DialogContent>
              {editState.error ? (
                <Typography variant='body2' color='error'>
                  {t('form.message.error.occurred')}
                </Typography>
              ) : null}
              <Form
                {...this.props}
                data={this.state.data}
                update={this.update}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} color='primary'>
                {t('button.cancel')}
              </Button>
              <Button
                variant='raised'
                disabled={editState.pending === true}
                type='submit'
                color='primary'
              >
                {buttonText}
              </Button>
            </DialogActions>
          </ValidatedForm>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const {leadAllocation, crm, config} = state
  const editState = leadAllocation.editState
  const {options} = crm

  return {
    data: getSelectedTier(props.match)(state),
    editState,
    options: {
      ...options,
      tierMake: config.options.makes,
      userGroup: getRoles(state),
      locations: getLocations(state)
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadOptions: () => dispatch(loadOptions()),
  loadLocations: (params) => dispatch(getList(params)),
  create: (params) => dispatch(createTier(params)),
  getMake: () => dispatch(getCarDataOptions({key: 'make', selection: []})),
  reset: () => dispatch(resetLeadAllocationForm()),
  save: (params) => dispatch(updateTier(params))
})

export default withMobileDialog()(
  withStyles(dialogStyles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      formWithCountryOptions(
        tierFormConfig,
        'crmDetails'
      )(translate()(TierDialog))
    )
  )
)
