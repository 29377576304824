export default (theme) => ({
  input: {
    underline: {
      '&:after': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  label: {
    '&:focused': {
      color: theme.palette.secondary.main
    }
  }
})
