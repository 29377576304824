const VALIDATION_ERROR_CODES = [400, 404, 422, 500]

const getErrorMessage = (errorResponse) => {
  try {
    if (
      Array.isArray(errorResponse?.properValue?.error?.fieldViolations) &&
      errorResponse.properValue.error.fieldViolations.length > 0
    ) {
      return errorResponse.properValue.error.fieldViolations[0]?.message
    }

    if (errorResponse?.properValue?.error?.detail) {
      return errorResponse.properValue.error.detail
    }

    return errorResponse?.message
  } catch (e) {
    console.error(e)

    return errorResponse
  }
}

const isErrorIn = (error, array) =>
  error?.status && array.includes(error.status)

export const bpResponseInceptor = {
  response: (response) => {
    let data = response.data.data

    try {
      const {extractPureData, extractList, gqlMethod} = response.config

      if (data && extractPureData && gqlMethod) {
        data = data[gqlMethod]
      }

      if (extractList && data) {
        data = data.list
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data
    }
  },
  error: (error) => {
    let customError =
      typeof error.response !== 'undefined'
        ? error.response.data
        : new Error('global.errors.noResponse')

    if (
      typeof error.response !== 'undefined' &&
      isErrorIn(error.response, VALIDATION_ERROR_CODES)
    ) {
      const errorMsg = getErrorMessage(error?.response?.data?.errors[0])

      customError = new Error(errorMsg)
    } else {
      console.error(error)
    }

    return Promise.reject(customError)
  }
}
