import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import {I18nextProvider} from 'react-i18next'
import {AuthProvider as JumpCloudProvider} from 'react-oidc-context'

import rawConfig from './config/appConfig'
import {getCountryConfig} from '../config/country'
import appStore from './store'
import ThemeProvider from './style/theme'
import {createTranslation} from './config/translation'
import Layout from './layout'
import {trackPage} from './utils/segment'
import {history} from '../../history.js'
import {getJumpCloudConfig} from './utils/jumpCloud.js'

const config = rawConfig(getCountryConfig())
const store = appStore(config)
const i18n = createTranslation(config)
const jumpCloudAuth = getJumpCloudConfig(config, window.jumpCloudConfig)

trackPage()

history.listen(() => {
  trackPage()
})

const App = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
          <ThemeProvider>
            <JumpCloudProvider {...jumpCloudAuth}>
              <Layout />
            </JumpCloudProvider>
          </ThemeProvider>
        </Router>
      </I18nextProvider>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

console.log('app loaded', new Date())
