import moment from 'moment-timezone'

import ResponseType from '../../types/ResponseType'
import Country from '../../types/Country'
import Location from '../../types/Location'
import Service from '../../types/Service'
import GlobalPlaceConfiguration from '../../types/GlobalPlaceConfiguration'
import Weekday from '../../types/Weekday'
import OpeningTime from '../../types/OpeningTime'
import ServicesObject from '../../types/ServicesObject'

interface ERROR_MESSAGES {
  required: string
  invalidEmail: string
}
export const ERROR_MESSAGES = {
  required: 'This field is required.',
  invalidEmail: 'Please enter a valid email.'
}

export let validationSchema = {}

export const setValidationSchema = (countryCode: Country) => {
  validationSchema = {
    name: {
      required: true
    },
    email: {
      required: false,
      validator: {
        regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        error: 'invalid email address.'
      }
    },
    bIMapping: {
      required: false,
      validator: {
        regEx: `[W|H|L]${countryCode}[0-9][0-9][0-9][X|Y|Z]?`,
        error: 'BI Mapping format is not correct'
      }
    },
    city: {
      required: true
    },
    sapPlantId: {
      required: true
    },
    sapStorageLocationId: {
      required: true
    },
    timezone: {
      required: true
    }
  }
}

export const RESPONSE_MESSAGES: {[type in ResponseType]: string} = {
  // TODO
  success: '',
  error: 'Request did not succeed. Please try again.',
  warning: '',
  info: ''
}

export const convertToRequestData = (
  selectedLocation: Location,
  place: any,
  setAddress2 = false
) => {
  const data =  {
    ...selectedLocation,
    address1: place.address.value,
    location: place.name.value,
    city: place.city.value,
    region: place.region.value,
    bIMapping: place.bIMapping.value,
    sapPlantId: place.sapPlantId.value,
    sapStorageLocationId: place.sapStorageLocationId.value,
    lat: place.lat.value.toString(),
    lng: place.lng.value.toString(),
    type: place.type.value,
    areaManager: place.areaManager.value,
    areaManagerName: place.areaManagerName.value,
    timezone: place.timezone.value,
    timezoneOffset: getTimeZoneOffset(place.timezone.value),
    metaInfo: {
      ...selectedLocation.metaInfo,
      locationEmail: place.email.value,
      locationPhone: place.phone.value,
      metaTitle: place.metaTitle.value,
      metaDescription: place.metaDescription.value,
      imageUrl: place.image.value,
      optionalText: place.optionalText.value,
      mapUrl: place.mapUrl.value
    }
  }

  if(setAddress2){
    data.address2= place.optionalText.value
  }

  return data
}

let timezoneOptions: string[] = []

export const setOrGetTimezoneOptions = (countryCode: Country) => {
  if (timezoneOptions.length === 0) {
    timezoneOptions = moment.tz.zonesForCountry(countryCode)
  }
  return timezoneOptions
}

export const formLocationState = (
  location: Location,
  global: GlobalPlaceConfiguration,
  countryCode: Country
) => {
  const countryTimezoneOptions = setOrGetTimezoneOptions(countryCode)
  let timezoneValue =
    location.timezone !== '' &&
    countryTimezoneOptions.includes(location.timezone)
      ? location.timezone
      : ''
  if (countryTimezoneOptions.length === 1)
    timezoneValue = countryTimezoneOptions[0]

  return {
    id: location.id,
    name: {value: location.location || ''},
    city: {value: location.city || ''},
    region: {value: location.region || ''},
    address: {value: location.address1 || ''},
    address2: {value: location.address2 || ''},
    bIMapping: {value: location.bIMapping || ''},
    sapPlantId: {value: location.sapPlantId || ''},
    sapStorageLocationId: {value: location.sapStorageLocationId || ''},
    email: {
      value: location.metaInfo?.locationEmail || global.email,
      error: ''
    },
    phone: {value: location.metaInfo?.locationPhone || ''},
    image: {
      value: location.metaInfo?.imageUrl || global.image,
      error: ''
    },
    metaTitle: {value: location.metaInfo?.metaTitle || ''},
    metaDescription: {
      value: location.metaInfo?.metaDescription || '',
      error: ''
    },
    active: location.active || true,
    lat: {value: location.lat || '', error: ''},
    lng: {value: location.lng || '', error: ''},
    type: {value: location.type || [], error: ''},
    timezone: {value: timezoneValue, error: ''},
    optionalText: {value: location.metaInfo?.optionalText, error: ''},
    mapUrl: {value: location.metaInfo?.mapUrl, error: ''},
    areaManager: {value: location.areaManager || '', error: ''},
    areaManagerName: {value: location.areaManagerName || '', error: ''}
  }
}

export const formGlobalState = (global: GlobalPlaceConfiguration) => {
  return {
    email: {
      value: global.email
    },
    image: {
      value: global.image
    }
  }
}

export const initialServiceDataWithGlobal = (services: ServicesObject) => {
  return Object.values(services)
    .reduce((totalOpeningHours, service) => {
      totalOpeningHours = Object.keys(service.openingHours).reduce(
        (totalOpeningHours, day: Weekday) => {
          totalOpeningHours = service.openingHours[day].reduce(
            (totalOpeningHours, range) => {
              totalOpeningHours = [...totalOpeningHours, {...range}]

              return totalOpeningHours
            },
            totalOpeningHours
          )

          return totalOpeningHours
        },
        totalOpeningHours
      )

      return totalOpeningHours
    }, [] as OpeningTime[])
}

export const getTimeZoneOffset = (timezone: string): Number => {
  const offset = - moment.tz.zone(timezone).utcOffset(moment.utc())
  return offset
}
