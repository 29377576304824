import styled from 'styled-components'
import PropTypes from 'prop-types'
import {RenderIf} from '../../../../components/RenderIf'
import CardTooltip from './Tooltip'

const CardTile = styled.div`
  width: 12px;
  height: 18px;
  background-color: ${({backgroundType}) => backgroundType};
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
`

const Card = (props) => {
  const {
    showTooltip,
    appointment,
    cardKey,
    showAllAppointments,
    cardIndex,
    cardsCount,
    legend,
    onClick,
    ...rest
  } = props
  const _onClick = (e) => {
    e.stopPropagation()

    if (typeof onClick === 'function') {
      onClick(appointment.id)
    }
  }

  if (cardsCount > 12 && !showAllAppointments && cardIndex >= 11) {
    return null
  }

  return (
    <div>
      <CardTile
        {...rest}
        key={`${cardKey}-card`}
        data-test={`${cardKey}-card`}
        backgroundType={legend}
        onClick={_onClick}
      />
      <RenderIf if={showTooltip}>
        <CardTooltip key={`${cardKey}-tooltip`} appointment={appointment} />
      </RenderIf>
    </div>
  )
}

Card.propTypes = {
  tooltipConstructor: PropTypes.func,
  onCardExtendClick: PropTypes.func,
  legend: PropTypes.string,
  appointment: PropTypes.object,
  onClick: PropTypes.func
}

Card.defaultProps = {
  onCardExtendClick: () => {}
}

export default Card
