import {Component} from 'react'

import WalkinForm from './Editor'
import {navigateToAA1} from '../../../utils/navigation'
import {connect} from 'react-redux'
import {PermissionWrapper} from '../../../components/Permissions'
import {ConfigContext} from '../../../components/providers/withConfig'
import {isOldEntity} from '../helpers'
import Empty from '../../../components/Empty'
import {translate} from 'react-i18next'

class Standalone extends Component {
  redirectToAA1 = (legacyHost = null) => {
    const {locale} = this.props

    navigateToAA1(
      `/${locale}/inspectionappointments`,
      true,
      undefined,
      legacyHost
    )
  }

  render() {
    const {t, history} = this.props
    return (
      <PermissionWrapper entity='web.admin.ui.crm.walkin1' withDescription>
        {isOldEntity({createdAt: new Date()}) ? (
          <ConfigContext.Consumer>
            {(config) => (
              <WalkinForm
                isOpen
                withTransition={false}
                handleClose={() => this.redirectToAA1(config.legacyHost)}
                handleSubmit={() => this.redirectToAA1(config.legacyHost)}
              />
            )}
          </ConfigContext.Consumer>
        ) : (
          <Empty
            title={t('appointment.salesForceGoLive')}
            buttonText={t('button.go.back')}
            onClick={history.goBack}
          />
        )}
      </PermissionWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: state.config.language.selected
})

export default connect(mapStateToProps)(translate()(Standalone))
