import {
  ValidatedSelectField,
  ValidatedTextField
} from '../../../../components/FormValidation'

export default {
  form: {
    children: [
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 2,
        id: 'salutationBlock',
        children: [
          {
            component: ValidatedSelectField,
            fullWidth: true,
            id: 'salutation',
            label: 'contact.salutation.label',
            margin: 'normal',
            name: 'salutation',
            onChangeHandler: 'handleSelectChange',
            options: [
              {value: 'Mr.', label: 'Mr.'},
              {value: 'Ms.', label: 'Ms.'}
            ]
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 5,
        id: 'firstnameBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'firstname',
            label: 'contact.firstname.label',
            margin: 'normal',
            name: 'firstname',
            onChangeHandler: 'handleTextInput',
            validator: {required: true, type: 'string'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 5,
        id: 'lastnameBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'lastname',
            label: 'contact.lastname.label',
            margin: 'normal',
            name: 'lastname',
            onChangeHandler: 'handleTextInput',
            validator: {required: true, type: 'string'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'emailBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'email',
            label: 'contact.email.label',
            margin: 'normal',
            name: 'email',
            onChangeHandler: 'handleTextInput',
            validator: {required: true, type: 'email'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'phoneBlock',
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            isNumber: true,
            id: 'phone',
            label: 'contact.phone.label',
            margin: 'normal',
            name: 'phone',
            onChangeHandler: 'handleSelectChange',
            disabled: ({options} = {}) =>
              !options?.creation && options?.isDialerAgent,
            validator: {required: true, type: 'phone'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'phone2Block',
        children: [
          {
            component: 'ValidatedPhoneInput',
            fullWidth: true,
            isNumber: true,
            id: 'phone2',
            label: 'contact.phone2.label',
            margin: 'normal',
            name: 'phone2',
            onChangeHandler: 'handleSelectChange',
            disabled: ({options} = {}) =>
              !options?.creation && options?.isDialerAgent,
            validator: {required: true, type: 'phone'}
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'addressBlock',
        children: [
          {
            component: ValidatedTextField,
            fullWidth: true,
            id: 'address',
            label: 'contact.address.label',
            margin: 'normal',
            name: 'address',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'cityBlock',
        children: [
          {
            component: ValidatedTextField,
            id: 'city',
            fullWidth: true,
            label: 'contact.address.city',
            margin: 'normal',
            name: 'city',
            onChangeHandler: 'handleTextInput'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 6,
        id: 'postcodeBlock',
        children: [
          {
            component: ValidatedTextField,
            id: 'postcode',
            fullWidth: true,
            isNumber: true,
            label: 'contact.address.postcode',
            margin: 'normal',
            name: 'postcode',
            onChangeHandler: 'handleTextInput',
            maxLength: 5
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'smsMarketingBlock',
        children: [
          {
            component: 'SwitchField',
            id: 'smsMarketing',
            label: 'contact.smsUserAgreement.label',
            margin: 'normal',
            value: 'smsMarketing',
            checked: 'smsMarketing',
            name: 'smsMarketing',
            onChangeHandler: 'handleToggleChange'
          }
        ]
      },
      {
        component: 'Grid',
        item: true,
        xs: 12,
        sm: 12,
        id: 'emailMarketingBlock',
        children: [
          {
            component: 'SwitchField',
            id: 'emailMarketing',
            label: 'contact.emailUserAgreement.label',
            margin: 'normal',
            name: 'emailMarketing',
            value: 'emailMarketing',
            checked: 'emailMarketing',
            onChangeHandler: 'handleToggleChange'
          }
        ]
      }
    ]
  }
}
