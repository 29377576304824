import React from 'react';
import { Button } from 'motofin-ui';
import '../../Notifications.scss';

const NotificationsModalFooter = (props: { translate: any, handleMarkAllAsReadBtnClick: () => void }) => {
    const { translate, handleMarkAllAsReadBtnClick } = props;
    return <div className='notificationsModalFooter'>
        <Button
            size="large"
            type="primary"
            test-id={`notificationsActionBtnFooter`}
            onClick={handleMarkAllAsReadBtnClick}
        >
            {translate('ui.notifications.actions.markAllAsRead')}
        </Button>
    </div>
}

export default NotificationsModalFooter;