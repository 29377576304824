import * as types from './types'
import {call, put, select, takeLatest} from 'redux-saga/effects'
import * as ContactApi from '../../../api/contact/requests'
import {getFilters, getSort} from './selectors'
import {showSnackbarNotification} from '../../signals/actions'
import * as signalTypes from '../../signals/types'
import {getCountryCode} from '../../config/selectors'
import gql from '@fcg/lib-gql/gql'
import {tierActionsReqConst} from '../../../config/pages/crm/leadAllocation'
import {fetchTiers as fetchTiersAction} from './actions'
import pickBy from 'lodash/pickBy'

function* fetchTiers(action) {
  try {
    const countryCode = yield select(getCountryCode)
    const limit =
      action.payload && action.payload.limit ? action.payload.limit : 25
    const page = action.payload && action.payload.page ? action.payload.page : 1
    const filters = yield select(getFilters)
    const sort = yield select(getSort)
    const filterObj = filters.reduce((acc, item) => ({...acc, ...item}), {})
    const {tier} = yield call(ContactApi.getTiers, {
      ...filterObj,
      country: countryCode
    })

    yield put({
      type: types.dataLoadingTypes.dataLoaded,
      payload: {
        data: tier,
        limit,
        sort,
        page,
        filters,
        flatten: false
      }
    })
  } catch (e) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: e.message,
        open: true
      })
    )
  }
}

function* createTier({payload}) {
  try {
    const countryCode = yield select(getCountryCode)

    const createTierParams = {
      name: payload.tierName,
      action: tierActionsReqConst.ROUND_ROBIN,
      weight: Number(payload.tierWeight),
      userRoleId: payload.tierUserGroup,
      isFinite: true,
      maxAssignments: Number(payload.tierMaxLeads),
      isEnabled: true,
      country: new gql.EnumType(countryCode)
    }

    const {createTier} = yield call(ContactApi.createTier, createTierParams)

    const criteriaValue = {
      make: payload.tierMake,
      leadSource: payload.tierLeadSource,
      status: payload.tierLeadStatus,
      email: payload.tierEmail,
      phone: payload.tierPhone
    }

    if (payload.tierAppointmentStatus) {
      criteriaValue['appointmentStatus'] = payload.tierAppointmentStatus
    }
    if (payload.tierLocation) {
      criteriaValue['placeId'] = payload.tierLocation
    }

    const tierCriteriaParams = {
      name: payload.tierName,
      tierId: createTier.id,
      isEnabled: true,
      value: pickBy(criteriaValue, (value) => !!value)
    }

    yield call(ContactApi.createTierCriteria, tierCriteriaParams)

    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.tiers.create.success',
        open: true
      }
    })
    yield put(fetchTiersAction())
  } catch (error) {
    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.tiers.create.error',
        open: true
      }
    })
  }
}

function* updateTier({payload}) {
  try {
    const createTierParams = {
      id: payload.id,
      name: payload.tierName,
      action: tierActionsReqConst.ROUND_ROBIN,
      weight: Number(payload.tierWeight),
      userRoleId: payload.tierUserGroup,
      isFinite: true,
      maxAssignments: Number(payload.tierMaxLeads),
      isEnabled: true
    }

    yield call(ContactApi.updateTier, createTierParams)

    const criteriaValue = {
      make: payload.tierMake,
      leadSource: payload.tierLeadSource,
      status: payload.tierLeadStatus,
      email: payload.tierEmail,
      phone: payload.tierPhone
    }
    if (payload.tierAppointmentStatus) {
      criteriaValue['appointmentStatus'] = payload.tierAppointmentStatus
    }
    if (payload.tierLocation) {
      criteriaValue['placeId'] = payload.tierLocation
    }

    const tierCriteriaParams = {
      id: payload.tierCriteriaId,
      name: payload.tierName,
      isEnabled: true,
      value: pickBy(criteriaValue, (value) => !!value)
    }

    yield call(ContactApi.updateTierCriteria, tierCriteriaParams)

    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.tiers.update.success',
        open: true
      }
    })
    yield put(fetchTiersAction())
  } catch (error) {
    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.tiers.update.error',
        open: true
      }
    })
  }
}

function* deleteTier({payload}) {
  try {
    yield call(ContactApi.deleteTier, {id: payload.id})

    yield call(ContactApi.updateTierCriteria, {id: payload.tierCriteriaId})

    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.success,
        message: 'snackbar.notification.tiers.delete.success',
        open: true
      }
    })
    yield put(fetchTiersAction())
  } catch (error) {
    yield put({
      type: signalTypes.showSnackbarNotification,
      payload: {
        variant: signalTypes.variantTypes.error,
        message: 'snackbar.notification.tiers.delete.error',
        open: true
      }
    })
  }
}

function* updateFiltersSaga() {
  try {
    yield put({type: types.fetchTiers})
  } catch (error) {
    yield put(
      showSnackbarNotification({
        variant: signalTypes.variantTypes.error,
        message: error.message,
        open: true
      })
    )
  }
}

export const sagas = [
  takeLatest(types.fetchTiers, fetchTiers),
  takeLatest(types.createTier, createTier),
  takeLatest(types.updateTier, updateTier),
  takeLatest(types.deleteTier, deleteTier),
  takeLatest(types.updateFilters, updateFiltersSaga)
]
