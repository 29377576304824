import React, {useState} from 'react'
import cx from 'classnames'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'

import List from './List'
import ActionButtons from './ActionButtons'
import FilterBar from './FilterBar'

import useFilters from '../../hooks/useFilters/useFilters'
import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import SearchField from '../../components/Search/SearchField'

const LocationList = () => {
  const classes = useCommonStyles()
  const [searchValue, setSearchValue] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const {
    filters,
    updateFilters,
    clearFilters,
    filteredLocationList = []
  } = useFilters(searchValue)
  const toggleShowFilters = () => {
    setShowFilters(!showFilters)
    setSearchValue('')
    clearFilters()
  }

  return (
    <div className={classes.fullWidth}>
      <Paper className={cx(classes.fullWidth, classes.marginBottom2)}>
        <Grid
          container
          className={cx(classes.paddingX2, classes.fullWidth, classes.flexGrow)}
        >
          <Typography
            className={cx(
              classes.selfCenter,
              classes.colorTitle,
              classes.flexGrow
            )}
            variant='h6'
          >
            Locations
          </Typography>
          {!showFilters && (
            <div className={cx(classes.marginRight2, classes.marginY1)}>
              <SearchField
                searchValue={searchValue}
                onChange={setSearchValue}
              />
            </div>
          )}
          <Tooltip
            title='Filter list'
            className={cx(classes.textRight, classes.marginTop1)}
          >
            <IconButton aria-label='filter list' onClick={toggleShowFilters}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Collapse in={showFilters}>
          <FilterBar
            filters={filters}
            onFilterChange={updateFilters}
            clearFilters={clearFilters}
            filteredLocationList={filteredLocationList}
          />
        </Collapse>

        <List
          locationList={filteredLocationList[filteredLocationList.length - 1]}
        />
        <ActionButtons />
      </Paper>
    </div>
  )
}

export default LocationList
