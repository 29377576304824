import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import {carDataInterceptor} from './helpers'
import * as grootQuery from './grootRequests'
import {getCountryConfig} from '../../../../app/config/country'
import {makeRestApiCall} from '../common/helpers'

const CARDATA_MULTIPART_API = '/restApi/carData/upload'

const grootFields = getCountryConfig().useGrootApi
const grootFieldSet = new Set(grootFields)
const client = new ApiClient('carData', [carDataInterceptor])

export function getCarData(params = {}) {
  return client.query('getCarData', params, fields.carDataList)
}

export function getCarDataMake(params = {}) {
  if (grootFieldSet.has('make')) {
    return grootQuery.getCarDataMake(params)
  }
  return client.query('make', params, fields.list)
}

export function getCarDataYear(params = {}) {
  if (grootFieldSet.has('year')) {
    return grootQuery.getCarDataYear(params)
  }
  return client.query('year', params, fields.list)
}

export function getCarDataModel(params = {}) {
  if (grootFieldSet.has('model')) {
    return grootQuery.getCarDataModel(params)
  }
  return client.query('model', params, fields.list)
}

export function getCarDataTrim(params = {}) {
  if (grootFieldSet.has('variant')) {
    return grootQuery.getCarDataTrim(params)
  }
  return client.query('trim', params, fields.list)
}

export function getCarDataTransmission(params = {}) {
  if (grootFieldSet.has('transmission')) {
    return grootQuery.getCarDataTransmission(params)
  }
  return client.query('transmission', params, fields.list)
}

export function getCarDataLocation(params = {}) {
  return client.query('location', params, fields.list)
}

export function getCarDataBodyType(params = {}) {
  if (grootFieldSet.has('bodyType')) {
    return grootQuery.getCarDataBodyType(params)
  }
  return client.query('bodyType', params, fields.list)
}

export const getCarDataFaceLift = grootQuery.getCarDataFaceLift

export const getCarDataSeat = grootQuery.getCarDataSeat

export const getCarDataEngine = grootQuery.getCarDataEngine

export const getCarDataWheelDrive = grootQuery.getCarDataWheelDrive

export function getCarDataQuote(params = {}) {
  return client.query('quote', params, fields.quote)
}

export function deleteCarData(params = {}) {
  return client.query('deleteCarData', params, ['deleted'])
}

export function uploadCarData(params = {}) {
  return client.withIncreasedTimeout().query('uploadCarData', params, ['count'])
}

export function createCarData(params = {}) {
  return client.query('createCarData', params, fields.carDataFields)
}

export function updateCarData(params = {}) {
  return client.query('updateCarData', params, fields.carDataFields)
}

export function uploadCarDataMultipart({carData, country}) {
  const params = {
    country
  }
  const formData = new FormData()
  formData.append('file', carData)

  return makeRestApiCall(CARDATA_MULTIPART_API, {
    method: 'post',
    formData,
    params,
    isMultipart: true,
    hasIncreaseTimeout: true,
    isTokenRequired: false
  })
}
