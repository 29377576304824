import {Fragment} from 'react'
import PropTypes from 'prop-types'

import NumberFormat from './NumberFormat'
import {ConfigConsumer} from '../providers/withConfig'
import isNil from 'lodash/isNil'

export const Currency = (props) => {
  const symbol =
    isNil(props.amount) || props.amount === ''
      ? ''
      : `${props.config.currency.symbol}`

  if (props.amount === null) {
    return null
  }

  return (
    <Fragment>
      {symbol}
      <NumberFormat {...props} asCurrency />
    </Fragment>
  )
}

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDecimals: PropTypes.bool
}
Currency.defaultProps = {withDecimals: false}

export default (props) => ConfigConsumer(Currency, props)
