import {LocationFilter, CityFilter, StatusFilter} from './Filters'

export const dateDisplayFormat = 'DD.MM.YYYY'
export const dateDisplayFormatAlternative = 'dddd Mo YYYY'
export const dateCollectionNameFormat = 'YYYY-MM-DD'
export const dateRequestFormat = 'YYYY-MM-DD'

// TODO: Refactor so filters are defined in one place only
export const getFilterConfig = () => {
  return [
    {
      key: 'location',
      label: 'appointmentCalendar.placeholder.location',
      filter: LocationFilter,
      separator: ' '
    },
    {
      key: 'city',
      label: 'appointmentCalendar.placeholder.city',
      filter: CityFilter,
      separator: ' '
    },
    {
      key: 'email',
      label: 'appointmentCalendar.placeholder.email',
      type: 'string',
      separator: ' '
    },
    {
      key: 'firstname',
      label: 'appointmentCalendar.placeholder.firstName',
      type: 'string',
      separator: ' '
    },
    {
      key: 'lastname',
      label: 'appointmentCalendar.placeholder.lastName',
      type: 'string',
      separator: ' '
    },
    {
      key: 'bookingId',
      label: 'appointmentCalendar.placeholder.bookingId',
      type: 'string',
      separator: ' '
    },
    {
      key: 'status',
      label: 'appointmentCalendar.placeholder.status',
      filter: StatusFilter,
      separator: ' '
    }
  ]
}

// Used to determine the priority in collection name building
export const filterPriority = [
  'date',
  ...getFilterConfig().map((conf) => conf.key)
]
