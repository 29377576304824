import {Fragment} from 'react'
import {translate} from 'react-i18next'

import {Chip, withStyles} from '@material-ui/core'
import Format from '../../Format/Format'

import isNil from 'lodash/isNil'

const styles = (theme) => ({
  list: {
    padding: theme.spacing.unit,
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    backgroundColor: theme.palette.grey[300],
    fontSize: theme.typography.fontSize,
    '& + &': {
      marginLeft: theme.spacing.unit / 2
    },
    '& + .FilterCreator': {
      marginLeft: theme.spacing.unit
    }
  }
})

const buildRangeLabel = ({value, ...rest}) =>
  value !== null ? (
    !isNil(value.from) && !isNil(value.to) ? (
      <Fragment>
        <Format {...rest} key={`format-1-${rest.key}`} data={value.from} />
        {' - '}
        <Format {...rest} key={`format-2-${rest.key}`} data={value.to} />
      </Fragment>
    ) : (
      <Format {...rest} data={value.from || value.to} />
    )
  ) : null

const translateValue = ({t, prefix, mask, value}) =>
  typeof prefix !== 'undefined'
    ? t(`${prefix}.${value || mask}`)
    : t(mask || value.toString())

const buildLabelFromFilterValue = ({t, prefix, mask, value}) => {
  if (value === null) {
    return '/'
  }

  if (value.from || value.to) {
    if (value.from && value.to) {
      return `${value.from} - ${value.to}`
    }

    return `${value.from || value.to}`
  }

  if (Array.isArray(value)) {
    return value
      .map((val, i) => {
        const translateMask = mask === null ? null : mask[i] || mask

        return translateValue({
          t,
          prefix,
          mask: translateMask,
          value: val
        })
      })
      .join(', ')
  }

  return `${translateValue({t, prefix, mask, value})}`
}

const FilterLabel = translate()(({t, filter}) => {
  if (
    isNil(filter.name) &&
    (filter.type === 'date' || filter.currency === true)
  ) {
    return (
      <span>
        {t(filter.label)}: {buildRangeLabel(filter)}
      </span>
    )
  }

  return (
    <span>
      {typeof filter.name === 'undefined'
        ? filter instanceof Object
          ? `${t(filter.label)}: ${buildLabelFromFilterValue({t, ...filter})}`
          : `${t(filter.label)}: ${filter.mask || filter.value}`
        : `${filter.name}`}
    </span>
  )
})

export const FilterList = (props) => {
  const {filters} = props

  return (
    <div className={props.classes.list}>
      {filters.reduce((filters, filter, index) => {
        if (
          Object.prototype.hasOwnProperty.call(filter, 'showChip') &&
          !filter.showChip
        ) {
          return filters
        }

        return [
          ...filters,
          <Chip
            className={props.classes.chip}
            label={<FilterLabel filter={filter} />}
            key={`${filter.id}_${index}`}
            onDelete={() => props.onRemove(filter)}
            onClick={() => props.onSelect(filter)}
            data-test={`filter-list-${filter.key}`}
          />
        ]
      }, [])}
      {props.children}
    </div>
  )
}

export default withStyles(styles)(FilterList)
