import {withValidation, validatorFields} from '@fcg/formvalidation'
import {TextField} from '@material-ui/core'
import errorMessageAdapter from './errorMessageAdapter'

const ValidatedTextField = withValidation(validatorFields.input)(
  errorMessageAdapter(TextField)
)

ValidatedTextField.type = 'ValidatedTextField'

export default ValidatedTextField
