import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../../config/country'

const selectorPath = (state) => state.lead

export const leadFields = (state) => {
  const {list} = selectorPath(state)

  if (list.fields) {
    return Object.values(list.fields)
  }
}

export function removeHiddenColumnsFromTableField(
  obj,
  hiddenColumns,
  hiddenFieldPropName
) {
  const res = {}

  Object.entries(obj).forEach(([key, value]) => {
    res[key] = {
      ...value,
      ...(value.children
        ? {
            children: childrenReducer(
              value.children,
              hiddenColumns,
              hiddenFieldPropName
            )
          }
        : {})
    }
  })

  return res
}

/** Function is used to remove country specific hidden fields from list of given fields
 *  @param fields {Array.<Object>} - List of all the fields
 *  @param hiddenFields {[string]} - List of field names which can be hidden
 *  @param {string} hiddenFieldPropName (filterName|columnName) which propertyName to use for check
 * */
export const childrenReducer = (fields, hiddenFields, hiddenFieldPropName) => {
  return fields.reduce((acc, field) => {
    if (!(field && hiddenFields.includes(field[hiddenFieldPropName]))) {
      if (
        field.type === 'composite' &&
        field.children &&
        field.children.length
      ) {
        acc.push({
          ...field,
          children: childrenReducer(
            field.children,
            hiddenFields,
            hiddenFieldPropName
          )
        })
      } else if (field.type === 'table') {
        acc.push({
          ...field,
          fields: removeHiddenColumnsFromTableField(
            field.fields || {},
            hiddenFields,
            hiddenFieldPropName
          )
        })
      } else {
        acc.push(field)
      }
    }

    return acc
  }, [])
}

export const getLeadFilters = createSelector(leadFields, (fields) => {
  const {crmDetails: config} = getCountryConfig()

  if (!config.hiddenFilters && fields) {
    return fields
  }

  if (fields && fields.length) {
    return childrenReducer(fields, config.hiddenFilters, 'filterName')
  }

  return []
})

export const getLeadFields = createSelector(leadFields, (fields) => {
  const {crmDetails: config} = getCountryConfig()

  if (!config.hiddenColumns && fields) {
    return fields
  }

  if (fields && fields.length) {
    return childrenReducer(fields, config.hiddenColumns, 'columnName')
  }

  return []
})

export const getCarId = (state) => selectorPath(state).carId

export const getListOfInspections = (state) => selectorPath(state).inspections
