import React from 'react'
import cx from 'classnames'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import isValid from 'date-fns/isValid'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import {makeStyles} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {useCommonStyles} from '../../../../hooks/useStyles/ThemeConfig'

export const useStyles = makeStyles(() => ({
  position: {
    left: '105%'
  },
  separator: {
    right: '49.5%'
  }
}))

const defaultTimeRange = [
  new Date('2014-08-18T10:00:00'),
  new Date('2014-08-18T18:00:00')
]
const convertValueToTimeString = (date:Date) => {
  return `${format(date,'HH',{})}:${format(date,'mm',{})}:00`
}
const formatTimeRange = (timeRange:string[]) => {
  return timeRange[0] === '00:00:00'
    ? defaultTimeRange
    : [
        new Date(`2014-08-18T${timeRange[0]}`),
        new Date(`2014-08-18T${timeRange[1]}`)
      ]
}

interface TRPPRops {
  timeRange: string[]
  timeRangeIndex:number
  onChange:(timeRangeIndex:number, field: 'openingTime'|'closingTime', value: string )=>void
  onDelete:(timeRangeIndex:number )=>void
}

const TimeRangePicker = ({
  timeRange=['10:00:00','18:00:00'],
  timeRangeIndex,
  onChange = () => {},
  onDelete = () => {}
}: TRPPRops) => {
  const classes = useCommonStyles()
  const customStyles = useStyles()
  const formattedTimeRange = formatTimeRange(timeRange)

  const handleChange = (date: Date, field: 'openingTime'|'closingTime') => {
    console.log(date)
    console.log(isValid(date))
    if (isValid(date)) {
      onChange(timeRangeIndex, field, convertValueToTimeString(date))
    }
  }

  return (
    <Grid container className={cx(classes.marginBottom2, classes.relative)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item xs={6} className={classes.paddingRight2}>
          <KeyboardTimePicker
            margin='normal'
            label='Start time'
            value={formattedTimeRange[0]}
            ampm={false}
            views={['hours', 'minutes']}
            fullWidth
            name='openingTime'
            onChange={(date:Date) => {
              handleChange(date, 'openingTime')
            }}
            KeyboardButtonProps={{
              'aria-label': 'change start time'
            }}
            keyboardIcon={<AccessTimeIcon/>}
            dataTest='start-time'
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingLeft2}>
          <KeyboardTimePicker
            margin='normal'
            label='End time'
            fullWidth
            ampm={false}
            value={formattedTimeRange[1]}
            views={['hours', 'minutes']}
            onChange={(date:Date) => {
              handleChange(date, 'closingTime')
            }}
            KeyboardButtonProps={{
              'aria-label': 'change end time'
            }}
            keyboardIcon={<AccessTimeIcon/>}
            dataTest='end-time'
          />
        </Grid>
      </MuiPickersUtilsProvider>
      {timeRangeIndex > 0 && (
        <IconButton
          aria-label='delete'
          className={cx(classes.absolute, customStyles.position)}
          onClick={() => {
            onDelete(timeRangeIndex)
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <Typography
        variant='body1'
        gutterBottom
        className={cx(
          classes.absolute,
          classes.marginTop1,
          customStyles.separator
        )}
      >
        _
      </Typography>
    </Grid>
  )
}

export default TimeRangePicker
