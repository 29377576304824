import {Component} from 'react'
import {connect} from 'react-redux'
import {
  fetchDriversList,
  toggleField,
  toggleSort,
  updateFilters
} from '../../store/driver/actions'
import {Button} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import PageTableBase from '../../components/PageTableBase'
import ActionButton from '../../components/layout/ActionButton'
import {Link} from 'react-router-dom'
import selectors from '../../store/driver/selectors'
import {PermissionWrapper} from '../../components/Permissions'

export class Drivers extends Component {
  static defaultProps = {selectable: false}

  routeToDriverProfile = (e, dealer) => {
    this.props.history.push(`drivers/${dealer.id}`)
  }

  render() {
    return (
      <div className='page'>
        <PageTableBase
          filterable
          {...this.props}
          multisort={false}
          title='Drivers'
          onRowClick={this.routeToDriverProfile}
          rowsPerPageOptions={[25, 50, 100]}
        />
        <PermissionWrapper entity='web.admin.ui.car.drivers' types={['CREATE']}>
          <ActionButton>
            <Button
              variant='fab'
              color='primary'
              component={Link}
              to='/drivers/create'
              data-test='button-create'
            >
              <Add />
            </Button>
          </ActionButton>
        </PermissionWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: selectors.getDriverList(state),
  fetching: selectors.isFetchingList(state),
  error: selectors.getError(state),
  sort: selectors.getSort(state),
  count: selectors.getCount(state),
  filters: selectors.getFilters(state),
  page: selectors.getPage(state),
  limit: selectors.getLimit(state),
  fields: selectors.getFields(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(fetchDriversList(args)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  toggleSort: (sort) => dispatch(toggleSort(sort)),
  onFilterChange: (filter) => dispatch(updateFilters(filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(Drivers)
