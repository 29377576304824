import {Component} from 'react'
import styled from 'styled-components'
import {DocumentSelector} from './DocumentSelector'
import {DocumentSlideshow} from './DocumentSlideshow'
import {NoDocumentMessage} from '../Subtable/components'
import {Content} from '../Subtable/styles'
import {OwnershipComment, SectionTitle} from './utils'
import {RenderIf} from '../RenderIf'

const DocumentContent = styled(Content)`
  height: 100%;
`
export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  background: #d8d8d8;
`

export class DocumentPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false
    }
  }
  openGallery = () => {
    this.setState({
      modalOpen: true
    })
  }
  closeGallery = () => {
    this.setState({
      modalOpen: false
    })
  }
  selectImage = (i) => {
    this.props.onSelectImage(i)
  }

  render() {
    if (!this.props.data || !this.props.data.length) {
      return <NoDocumentMessage t={this.props.t} fullWidth />
    }

    if (this.props.data.length && this.state.modalOpen) {
      const slideshowStyles =
        this.props.customStyles && this.props.customStyles.slideshowStyles
          ? this.props.customStyles.slideshowStyles
          : {}

      return (
        <DocumentSlideshow
          selected={this.props.selectedImageIndex}
          images={this.props.data}
          onClose={this.closeGallery}
          onSelect={this.selectImage}
          styles={slideshowStyles}
          HeaderChild={this.props.HeaderChild}
          Sidebar={this.props.Sidebar}
        />
      )
    }

    return (
      <DocumentContainer>
        <RenderIf
          if={this.props.title}
          then={() => (
            <SectionTitle>{this.props.t(this.props.title)}</SectionTitle>
          )}
        />
        <DocumentContent>
          <DocumentSelector
            useAdornement={this.props.useAdornement}
            availableAdornements={this.props.availableAdornements}
            selectImage={this.selectImage}
            openGallery={this.openGallery}
            images={this.props.data}
            selected={this.props.selectedImageIndex}
            showSelectedImageInList={this.props.showSelectedImageInList}
            customStyles={this.props.customStyles}
            showMetaInfo
            metaInfoInputs={
              <OwnershipComment>
                {this.props.selectedImage?.comment}
              </OwnershipComment>
            }
          />
        </DocumentContent>
      </DocumentContainer>
    )
  }
}
