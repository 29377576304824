import {Component} from 'react'
import {connect} from 'react-redux'
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  withStyles
} from '@material-ui/core'
import {translate} from 'react-i18next'
import noop from 'lodash/noop'
import {manualLogin} from '../../store/auth/actions'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit
  },
  centerText: {
    textAlign: 'center'
  }
})

class InternalLogin extends Component {
  state = {data: {email: '', password: ''}, pristine: true}

  static defaultProps = {onSubmit: noop}

  handleChange = (name) => (e) => {
    const value = e.target.value
    this.setState({data: {...this.state.data, [name]: value}, pristine: false})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({pristine: true})
    this.props.onSubmit({...this.state.data})

    if (this.props.standalone === true) {
      this.props.manualLogin({...this.state.data})
    }
  }

  render() {
    const {t, classes, errors, standalone} = this.props

    return (
      <div className={standalone === true ? `Login Login--center` : ''}>
        <Paper elevation={2} className={classes.paper}>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.centerText}>
                <Typography variant='title'>
                  {t('login.heading.main')}
                </Typography>
                <Typography variant='subheading'>
                  {t('login.heading.sub')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('userProfile.field.email')}
                  value={this.state.data.email}
                  onChange={this.handleChange('email')}
                  error={errors !== null}
                  className='internalLoginEmail'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  label={t('userProfile.field.password')}
                  value={this.state.data.password}
                  onChange={this.handleChange('password')}
                  error={errors !== null}
                  className='internalLoginPassword'
                />
              </Grid>
              <Grid item xs={12}>
                {errors !== null && (
                  <Typography color='error' gutterBottom align='left'>
                    {errors}
                  </Typography>
                )}
                <Button
                  className='fullwidth internalLoginSubmitButton'
                  variant='raised'
                  color='secondary'
                  type='submit'
                  onClick={this.handleSubmit}
                  disabled={this.state.pristine}
                >
                  {t('login.button.login')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = ({authReducer}) => {
  const {isAuthenticated, loginForm} = authReducer

  return {
    isAuthenticated,
    loginForm: loginForm.internal
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    manualLogin: (data) => dispatch(manualLogin(data))
  }
}

export default withStyles(styles, {name: 'InternalLogin'})(
  connect(mapStateToProps, mapDispatchToProps)(translate()(InternalLogin))
)
