export const listComments = 'comments/LIST'
export const setFetching = 'comments/SET_FETCHING'

// form types
export const userListLoaded = 'commentForm/USER_LIST_LOADED'
export const hydrateForm = 'commentForm/HYDRATE'
export const updateForm = 'commentForm/UPDATE'
export const resetForm = 'commentForm/RESET'
export const formError = 'commentForm/ERROR'
export const formSuccess = 'commentForm/SUCCESS'
export const formPending = 'commentForm/PENDING'
