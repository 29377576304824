// location list types
export const fetching = 'location/list/LOADING'
export const dataLoaded = 'location/list/LOADED'
export const fetchError = 'location/list/ERROR'
export const changePage = 'location/list/CHANGE_PAGE'
export const updateFilters = 'location/list/UPDATE_FILTER'
export const toggleField = 'location/list/TOGGLE_FIELD'
export const updateListItem = 'location/list/UPDATE_LIST_ITEM'
export const insertListItem = 'location/list/INSERT_LIST_ITEM'
export const deleteListItem = 'location/list/DELETE_LIST_ITEM'
export const toggleSort = 'location/list/TOGGLE_SORT'
export const setSort = 'location/list/SET_SORT'

// form types
export const updateForm = 'location/form/UPDATE'
export const setOriginalForm = 'location/form/SET_DEFAULT'
export const resetOriginalForm = 'location/form/RESET_DEFAULT'
export const hydrateForm = 'location/form/HYDRATE'
export const resetForm = 'location/form/RESET'
export const formError = 'location/form/ERROR'
export const formSuccess = 'location/form/SUCCESS'
export const formPending = 'location/form/PENDING'

// delete types
export const deleteSuccess = 'location/delete/SUCCESS'
export const deletePending = 'location/delete/PENDING'

// settings
export const setOptions = 'location/options/LOADED'
