export const NECCESSARY_PERMISSIONS = {
  all: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
  CREATE: ['CREATE'],
  READ: ['READ'],
  UPDATE: ['UPDATE'],
  DELETE: ['DELETE']
}

export const mainPermissions = [
  'web.admin.ui.crm.appointments2',
  'web.admin.ui.crm.appointments1',
  'web.admin.ui.crm.leads',
  'web.admin.ui.crm.contacts',
  'web.admin.ui.crm.tasks',
  'web.admin.ui.crm.myCrm',
  'web.admin.ui.crm.walkin2',
  'web.admin.ui.crm.walkin1',
  'web.admin.ui.crm.dbsearch',
  'web.admin.ui.crm.dialer'
]

export const subPermisssions = {
  'web.admin.ui.crm.appointments2': {
    type: ['UPDATE', 'DELETE'],
    extraPermissions: [
      {permission: 'web.admin.ui.crm.cancelAppointment', type: 'READ'}
    ]
  },
  'web.admin.ui.crm.appointments1': {
    type: ['UPDATE']
  },
  'web.admin.ui.crm.leads': {
    type: ['UPDATE', 'DELETE'],
    extraPermissions: [
      {permission: 'web.admin.ui.crm.appointments2', type: 'CREATE'}
    ]
  },
  'web.admin.ui.crm.contacts': {
    type: ['CREATE', 'UPDATE', 'DELETE'],
    extraPermissions: [{permission: 'web.admin.ui.crm.leads', type: 'CREATE'}]
  }
}

export const PAYSLIP_PERMISSION = 'web.admin.ui.car.payslips'
export const OWNERSHIP_PERMISSION = 'web.admin.ui.car.ownership'
export const SCHEDULES_PERMISSION = 'web.admin.ui.auctions.schedules'
