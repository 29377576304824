export const getCredentials = 'auth/CREDENTIALS_TYPE_GET'
export const getUserRoles = 'auth/ROLES_TYPE_GET'
export const displayAuthError = 'auth/ERROR_DISPLAY'
export const googleLoginStatus = 'auth/GOOGLE_LOGIN_STATUS'
export const connectOktaApi = 'auth/OKTA_API_CONNECT'
export const initializeOktaApi = 'auth/OKTA_API_INITIALIZE'
export const isAuthenticated = 'auth/LOGIN'
export const logout = 'auth/LOGOUT'
export const redirect = 'auth/REDIRECT'
export const redirectReset = 'auth/REDIRECT_RESET'
export const checkUserStatus = 'auth/CHECK_USER_STATUS'
export const setUserInfo = 'auth/SET_USER_INFO'
export const setCrmUserStatus = 'auth/SET_CRM_USER_STATUS'
export const oauth2Request = 'oauth/GET_OAUTH_TOKEN'
export const oauth2LoginRequest = 'oauth/LOGIN_REQUEST'
export const oauth2LoginSuccess = 'oauth/LOGIN_SUCCESS'
export const oauth2LoginError = 'oauth/LOGIN_ERROR'
export const callOktak2Login = 'oauth/CALL_OKTA_K2_LOGIN'
export const callGoogleK2Login = 'oauth/CALL_GOOGLE_K2_LOGIN'
export const setOAuthParams = 'oauth/SET_OAUTH_PARAMS'

export const oauthLoginTypes = {
  GOOGLE: 'google',
  MANUAL: 'manual',
  OKTA: 'okta'
}
