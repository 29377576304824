import {
  auctionTypeConfig,
  formFields,
  optionStatus,
  ON_AUCTION_END
} from '../../../config/pages/auctionsManagement/schedules/config/createSchedule'

export const setOnAuctionEndDisabledOptions = (type) => {
  return Object.entries(auctionTypeConfig[type].onAuctionEnd).reduce(
    (acc, [key, value]) => {
      if (value === optionStatus.DISABLED) {
        acc.push(key)
      }

      return acc
    },
    []
  )
}

export const setOnAuctionEndVisible = (type) =>
  auctionTypeConfig[type].onAuctionEnd[ON_AUCTION_END.AUTORESERVE.value] !==
    optionStatus.HIDDEN ||
  auctionTypeConfig[type].onAuctionEnd[ON_AUCTION_END.AUTOCONFIRM.value] !==
    optionStatus.HIDDEN

export const getAllHelperTexts = () => {
  return Object.values(formFields).reduce((acc, fieldConfig) => {
    return {
      ...acc,
      [fieldConfig.name]: fieldConfig.errorMessage
    }
  }, {})
}

export const getDefaultValue = ({fieldKey, config, countryCode}) => {
  let defaultValue = null

  if (formFields[fieldKey] && formFields[fieldKey].selected) {
    defaultValue =
      typeof formFields[fieldKey].selected === 'function'
        ? formFields[fieldKey].selected(countryCode)
        : formFields[fieldKey].selected
  } else {
    defaultValue = Object.values(config[fieldKey])[0].value
  }

  return defaultValue
}
