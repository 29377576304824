import {createSelector} from 'reselect'
import {getCountryConfig} from '../../../config/country'
import {getResourcesOfType, getCollection} from '../resources/selectors'
import {
  BULK_UPLOAD_NOT_VALID,
  BULK_UPLOAD_VALID,
  BULK_UPLOAD,
  BULK_UPLOAD_ALL,
  VALID,
  BULK_UPLOAD_DUPLICATE,
  DUPLICATE,
  FIRST_STEP,
  SECOND_STEP
} from '../../pages/BulkUpload/constants'

const selectorPath = (state) => state.bulkUpload

export const getSelectedB2BBusinessPartner = (state) =>
  selectorPath(state).selectedBusinessPartner

export const isUploading = (state) => selectorPath(state).isUploading

export const hasUploadError = (state) =>
  Boolean(selectorPath(state).uploadError)

export const getFormData = (state) => selectorPath(state).form

export const getFormDataForApi = (state) => {
  const formData = selectorPath(state).form
  const currentLocationLabel = state.config.options.locationOptions[
    'CURRENT'
  ].filter((location) => location.value === formData['currentLocation'])
  const purchaseLocationLabel = state.config.options.locationOptions[
    'PURCHASE'
  ].filter((location) => location.value === formData['purchaseLocation'])

  return {
    ...formData,
    purchaseLocation: {
      id: formData['purchaseLocation'],
      label:
        purchaseLocationLabel.length > 0 ? purchaseLocationLabel[0].label : '',
      type: 'PURCHASE'
    },
    currentLocation: {
      id: formData['currentLocation'],
      label:
        currentLocationLabel.length > 0 ? currentLocationLabel[0].label : '',
      type: 'CURRENT'
    }
  }
}

export const getFilters = (state) => selectorPath(state).list.filters

export const getError = (state) => selectorPath(state).error

export const getHiddenFields = (state) => selectorPath(state).hidden

export const getAllBulkUploadDataList = (state) =>
  getResourcesOfType(BULK_UPLOAD)(state) || []

export const getNotValidBulkUploadDataListIds = (state) => {
  const notBulkUploadList =
    getCollection(BULK_UPLOAD, BULK_UPLOAD_NOT_VALID)(state) || []

  return notBulkUploadList.sort((car1, car2) => car1.localeCompare(car2))
}

export const getDuplicateBulkUploadDataListIds = (state) => {
  const duplicateBulkUploadList =
    getCollection(BULK_UPLOAD, BULK_UPLOAD_DUPLICATE)(state) || []

  return duplicateBulkUploadList.sort((car1, car2) => car1.localeCompare(car2))
}

export const getValidBulkUploadDataListIds = (state) => {
  const bulkUploadList =
    getCollection(BULK_UPLOAD, BULK_UPLOAD_VALID)(state) || []

  return bulkUploadList.sort((car1, car2) => car1.localeCompare(car2))
}

export const getBulkUploadValidDataList = createSelector(
  [getAllBulkUploadDataList, getValidBulkUploadDataListIds],
  (list, validIds) => validIds.map((id) => list[id])
)

export const getBulkUploadNotValidDataList = createSelector(
  [getAllBulkUploadDataList, getNotValidBulkUploadDataListIds],
  (list, notValidIds) => notValidIds.map((id) => list[id])
)

export const getActiveListType = (state) => selectorPath(state).activeListType

export const getBulkUploadDataList = createSelector(
  [
    getActiveListType,
    getAllBulkUploadDataList,
    getNotValidBulkUploadDataListIds,
    getValidBulkUploadDataListIds,
    getDuplicateBulkUploadDataListIds
  ],
  (listType, list, notValidIds, validIds, duplicateIds) => {
    if (listType === BULK_UPLOAD_ALL) {
      return Object.values(list)
    }

    if (listType === BULK_UPLOAD_VALID || listType === VALID) {
      return validIds.map((id) => list[id])
    }

    if (listType === DUPLICATE) {
      return duplicateIds.map((id) => list[id])
    }

    return notValidIds.map((id) => list[id])
  }
)

export const getNotValidBulkUploadDataListCount = createSelector(
  getNotValidBulkUploadDataListIds,
  (list) => (list ? list.length : 0)
)

export const getBulkUploadDataListWithValidation = createSelector(
  getBulkUploadDataList,
  (list) => {
    return list
  }
)

export const getBulkUploadDataListCount = createSelector(
  [getAllBulkUploadDataList],
  (list) => Object.keys(list).length
)

export const isBulkUploadDialogVisible = (state) =>
  selectorPath(state).dialogVisibility

export const isBulkUploadNotififcationDialogVisible = (state) =>
  selectorPath(state).dialogNotificationVisibility

export const getConfig = (state) => {
  const config = state.config.pages['bulkUploadConfig']

  return config
}

export const getPageConfig = createSelector(
  [getHiddenFields, getConfig],
  (hidden, config) => {
    return {
      ...config,
      hidden
    }
  }
)

export const getCreatedCount = (state) =>
  selectorPath(state).upload.uploadSuccess.length

export const getDuplicateCount = (state) =>
  selectorPath(state).upload.duplicateCar.length

export const getNotCreatedCount = (state) =>
  selectorPath(state).upload.uploadFail.length

export const getSavingState = (state) => selectorPath(state).upload.loading

export const getIsCheckingDuplicateCars = (state) =>
  selectorPath(state).isCheckingDuplicateCars

export const getStep = (state) => selectorPath(state).step

export const getLocale = (state) => {
  return state.config.country.selected
}
export const getBulkUploadFields = createSelector(
  [getStep, getActiveListType],
  (step, visiblityType) => {
    const countryConfig = getCountryConfig()
    const {bulkuploadDetails = {}} = countryConfig
    let fields = getCountryConfig().bulkUpload.bulkUploadFields
    const {hiddenColumns = []} = bulkuploadDetails

    if (step === SECOND_STEP) {
      fields = {
        ...fields,
        ...getCountryConfig().bulkUpload.bulkUploadExtraFields
      }
    }

    hiddenColumns.forEach((field) => {
      delete fields[field]
    })

    if (
      (visiblityType === VALID || visiblityType === DUPLICATE) &&
      step !== FIRST_STEP
    ) {
      Object.keys(fields).map((field) => {
        fields[field].isEditable = false
      })
    }

    return Object.values(fields)
  }
)
