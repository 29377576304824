import {Link} from 'react-router-dom'
import {Button} from '@material-ui/core'
import Add from '@material-ui/icons/Add'

const RouteButton = (props) => {
  return (
    <Button variant='raised' size='small' color='primary'>
      <Add />
      <Link to={`${props.path}/${props.entity}`} style={{color: 'inherit'}}>
        Add new
      </Link>
    </Button>
  )
}

export default RouteButton
