import {
  getFormTypes,
  getSortTypes,
  getColumnTypes,
  getDataLoadingTypes
} from '../utils/typesUtil'

/**
 * Car Ownership Transfer
 * Shortened for easier reading in Redux dev tools
 */
export const name = 'OWNERSHIP'

export const fetchData = `${name}/FETCH_DATA`
export const fetchDataStart = `${name}/FETCH_DATA_START`
export const fetchDataSuccess = `${name}/FETCH_DATA_SUCCESS`
export const fetchDataError = `${name}/FETCH_DATA_ERROR`
export const fetchCurrentPage = `${name}/FETCH_CURRENT_PAGE`

export const toggleSort = `${name}/TOGGLE_SORT`
export const toggleField = `${name}/TOGGLE_FIELD`
export const updateFilters = `${name}/UPDATE_FILTERS`
export const setPageOptions = `${name}/SET_PAGE_OPTIONS`
export const fetchPageOptions = `${name}/FETCH_PAGE_OPTIONS`

export const updateTicketStart = `${name}/UPDATE_TICKET_START`
export const updateTicket = `${name}/UPDATE_TICKET`
export const updateTicketSuccess = `${name}/UPDATE_TICKET_SUCCESS`
export const updateTicketError = `${name}/UPDATE_TICKET_ERROR`

export const toggleRow = `${name}/TOGGLE_ROW`
export const toggleRowSelection = `${name}/TOGGLE_ROW_SELECTION`
export const fetchRowData = `${name}/ROW_DATA_FETCH`
export const fetchRowDataSuccess = `${name}/ROW_DATA_FETCH_SUCCESS`
export const fetchRowDataError = `${name}/ROW_DATA_FETCH_ERROR`

export const formTypes = getFormTypes(name)
export const sortTypes = getSortTypes(name)
export const columnTypes = getColumnTypes(name)
export const dataLoadingTypes = getDataLoadingTypes(name)

export const updateTicketEntity = `${name}/UPDATE_TICKET_ENTITY`
export const updateTicketEntitySuccess = `${name}/UPDATE_TICKET_ENTITY_SUCCESS`
export const updateTicketEntityError = `${name}/UPDATE_TICKET_ENTITY_ERROR`

export const approveTicket = `${name}/APPROVE_TICKET`

export const attachDocumentsToTicket = `${name}/ATTACH_DOCUMENTS_TO_TICKET`
export const createTicketEntityStart = `${name}/CREATE_TICKET_ENTITY_START`
export const createTicketEntitySuccess = `${name}/CREATE_TICKET_ENTITY_SUCCESS`
export const createTicketEntityError = `${name}/CREATE_TICKET_ENTITY_ERROR`

export const cleanUpDocuments = `${name}/CLEAN_UP_DOCUMENTS`
export const clearDocumentDialog = `${name}/CLEAR_DOC_DIALOG`
export const updateDocumentCategory = `${name}/UPDATE_DOC_CATEGORY`
export const updateDocumentCategorySuccess = `${name}/UPDATE_DOC_CATEGORY_SUCCESS`
