import * as types from './types'
import {getInitialState} from './initialState'
import {setAuth, removeAuth, removeGoogleAuth} from '../../utils/authToken'

export default {
  [types.isAuthenticated]: (state, action) => {
    setAuth(action.payload)

    return {
      ...state,
      isAuthenticated: action.payload,
      loginForm: getInitialState().loginForm
    }
  },
  [types.setCrmUserStatus]: (state, action) => {
    return {
      ...state,
      isCheckedIn: action.payload
    }
  },
  [types.connectOktaApi]: (state, action) => {
    return {
      ...state,
      oktaAuth: action.payload
    }
  },
  [types.logout]: (state) => {
    removeAuth()
    removeGoogleAuth()
    window.clearTimeout(state.googleRefreshingTimeout)

    return {
      ...state,
      isAuthenticated: false,
      redirect: '/login',
      googleRefreshingTimeout: null,
      userInfo: {}
    }
  },
  [types.redirect]: (state, action) => {
    return {
      ...state,
      redirect: action.payload
    }
  },
  [types.redirectReset]: (state, action) => {
    return {
      ...state,
      redirect: action.payload
    }
  },
  [types.displayAuthError]: (state, action) => {
    const {source, error} = action.payload
    const errors = Array.isArray(error.response.data.errors)
      ? error.response.data.errors[0].message
      : error.response.data.errors.message

    return {
      ...state,
      loginForm: {
        ...state.loginForm,
        [source]: {errors}
      }
    }
  },
  [types.setUserInfo]: (state, action) => ({
    ...state,
    userInfo: action.payload
  }),
  [types.oauth2LoginRequest]: (state) => ({
    ...state,
    isSubmitting: true,
    error: null
  }),
  [types.oauth2LoginSuccess]: (state) => ({
    ...state,
    isSubmitting: false,
    error: null
  }),
  [types.oauth2LoginError]: (state, action) => ({
    ...state,
    isSubmitting: false,
    error: action.payload
  }),
  [types.setOAuthParams]: (state, action) => ({
    ...state,
    oauthParams: action.payload
  }),
  [types.googleLoginStatus]: (state, action) => ({
    ...state,
    googleLoginProgress: action.payload.status
  })
}
