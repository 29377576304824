import * as types from './types'

export const fetchCarDataList = (payload) => ({
  type: types.fetchCarDataList,
  payload
})

export const fetchCarDataListSuccess = (payload) => ({
  type: types.fetchCarDataListSuccess,
  payload
})

export const fetchCarDataListError = (error) => ({
  type: types.fetchCarDataListError,
  payload: error
})

export const updateCarDataField = (payload) => ({
  type: types.updateCardDataField,
  payload
})

export const createCarData = (payload) => ({
  type: types.createCarData,
  payload
})

export const createCarDataSuccess = () => ({
  type: types.createCarDataSuccess
})

export const createCarDataError = () => ({
  type: types.createCarDataError
})

export const updateCarData = (payload) => ({
  type: types.updateCarData,
  payload
})

export const updateCarDataSuccess = () => ({
  type: types.updateCarDataSuccess
})

export const updateCarDataError = () => ({
  type: types.updateCarDataError
})

export const deleteCarData = (payload) => ({
  type: types.deleteCarData,
  payload
})

export const deleteCarDataSuccess = () => ({
  type: types.deleteCarDataSuccess
})

export const deleteCarDataError = (error) => ({
  type: types.deleteCarDataError,
  payload: error
})

export const resetCarData = () => ({
  type: types.resetCarData
})

export const resetError = () => ({
  type: types.resetError
})

export const toggleSort = (sort) => ({
  type: types.toggleSort,
  payload: sort
})

export const toggleField = (field) => ({
  type: types.toggleField,
  payload: field
})

export const setPageOptions = (payload) => ({
  type: types.setPageOptions,
  payload
})

export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})

export const uploadCarData = (payload) => ({
  type: types.uploadCarData,
  payload
})

export const uploadCarDataSuccess = (payload) => ({
  type: types.uploadCarDataSuccess,
  payload
})

export const uploadCarDataError = (error) => ({
  type: types.uploadCarDataError,
  payload: error
})

export const setUploading = (payload) => ({
  type: types.setUploading,
  payload
})

export const openCreateDialog = () => ({
  type: types.openCreateDialog
})

export const openEditDialog = (payload) => ({
  type: types.openEditDialog,
  payload
})

export const hideCreateEditDialog = () => ({
  type: types.hideCreateEditDialog
})
