import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import {translate} from 'react-i18next'

export const SuccessDialog = (props) => {
  const {t, heading, content, open, submitActionName} = props

  return (
    <Dialog open={open} onClose={props.handleClose}>
      <DialogTitle>
        {heading || t('global.action.success.dialog.title')}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color='primary'>
          {t('button.cancel')}
        </Button>
        {props.url !== null && typeof props.url !== 'undefined' ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={props.url} target='_blank'>
            <Button variant='raised' color='primary'>
              {t(submitActionName)}
            </Button>
          </a>
        ) : (
          <Button onClick={props.handleSubmit} variant='raised' color='primary'>
            {t(submitActionName)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default translate()(SuccessDialog)
