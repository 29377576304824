import {Component} from 'react'
import {Grid} from '@material-ui/core'
import UserPicker from '../../../components/Pickers/UserPicker'

export class Assign extends Component {
  handleAssignment = (type) => (value) => {
    const leads = this.props.leads.slice(0)

    leads.forEach((lead) => {
      lead[type] = value
    })

    this.props.onUpdateLeads(leads)
  }

  render() {
    const {leads} = this.props
    const handleResponsible = (e) =>
      this.handleAssignment('responsibleName')(e === null ? e : e.label)

    if (typeof leads === 'undefined' || leads === null) {
      return null
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <UserPicker
            id='leadResponsiblePicker'
            filterable
            fullWidth
            label='lead.responsible.label'
            userClass='INTERNAL'
            count={this.props.users.count}
            limit={25}
            options={this.props.users.options}
            onLoad={this.props.setUserOptions}
            onChange={this.handleAssignment('responsible')}
            onSelect={handleResponsible}
          />
        </Grid>
      </Grid>
    )
  }
}

export default Assign
