import gql from '@fcg/lib-gql/gql'
import omit from 'lodash/omit'
const numberInputs = ['idealStock', 'averageStock']

// stand-in until the original AuthApi's prepareDealerOptions can be simplified
export const formatDealerOptions = (options) => {
  const numFields = ['maxAmountBids', 'zipCode']
  const enumFields = []
  let preparedOptions = {}
  const restrictedOptionNames = [
    'dealerManagerName',
    'onboardingAgentName',
    'dealerFarmerName',
    'dealerHunterName',
    'dealerBidManagerName'
  ]
  const selectFields = [
    'dealerManager',
    'dealerFarmer',
    'dealerHunter',
    'dealerBidManager',
    'onboardingAgent',
    'dealerSize',
    'businessCategory',
    'personType',
    'status',
    'onboardingRejectionReason',
    'registrationType',
    'invoicingType',
    'storageTime',
    'expectedDelivery',
    'expectedCarCare',
    'expectedFinancing',
    'dealerAppsChannels'
  ]

  Object.keys(options).forEach((optionName) => {
    if (options[optionName] !== null) {
      if (optionName !== 'metaInfo') {
        preparedOptions = {
          ...preparedOptions,
          [optionName]: options[optionName]
        }
      } else {
        Object.keys(options.metaInfo).forEach((metaInfoOptionName) => {
          let metaInfoOptionValue = options.metaInfo[metaInfoOptionName]

          if (
            metaInfoOptionValue !== null ||
            numberInputs.indexOf(metaInfoOptionName) !== -1 ||
            (metaInfoOptionValue === null &&
              selectFields.includes(metaInfoOptionName))
          ) {
            if (numFields.indexOf(metaInfoOptionName) !== -1) {
              metaInfoOptionValue = parseFloat(metaInfoOptionValue)
            }

            if (enumFields.indexOf(metaInfoOptionName) !== -1) {
              metaInfoOptionValue = new gql.EnumType(metaInfoOptionValue)
            }

            if (restrictedOptionNames.includes(metaInfoOptionName)) {
              return
            }

            preparedOptions = {
              ...preparedOptions,
              metaInfo: {
                ...preparedOptions.metaInfo,
                [metaInfoOptionName]: metaInfoOptionValue
              }
            }
          }
        })
      }
    }
  })

  return omit(preparedOptions, 'internalId')
}

export const formatList = async (list, getDealerManager) => {
  for (const dealer of list.user.list) {
    const {dealerManager} = dealer.metaInfo

    if (dealer.dealerManager === null && dealerManager !== null) {
      const foundManager = await getDealerManager(dealerManager)

      dealer.dealerManager = foundManager.user.list[0]
    }
  }

  return list
}

export const formatResponse = (response) => {
  return response.data.data
}
