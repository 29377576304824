import {Select} from '../../components/Select'
import wrapFilter from '../../components/RichTable/FilterBar/Filter/wrapFilter'
import FilterString from '../../components/RichTable/FilterBar/Filter/FilterString'

const SELLING_STATUSES = {
  ONCONSIGNMENT: 'ONCONSIGNMENT',
  RESERVED: 'RESERVED',
  CONFIRMED: 'CONFIRMED'
}

const SELLING_STATUS_OPTIONS = [
  SELLING_STATUSES.ONCONSIGNMENT,
  SELLING_STATUSES.RESERVED,
  SELLING_STATUSES.CONFIRMED
].map((option) => ({
  label: `car.page.statusValue.${option}`,
  value: option
}))

const base = (props) => (
  <Select
    {...props}
    id={`LocationFilter_${props.fieldName}`}
    label={props.fieldName}
    options={SELLING_STATUS_OPTIONS}
    selected={props.value}
    onChange={props.handleChange(props.fieldName)}
  />
)

export const SellingStatusFilter = wrapFilter(base)

export const CarIDSearchFilter = (props) => <FilterString {...props} />
