import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {DialogBody} from './DialogBody'

export const SelfInspectionDialog = (props) => (
  <Dialog
    open={props.isOpen}
    onClose={props.onClose}
    zIndex={10}
    fullWidth
    maxWidth='lg'
  >
    <DialogContent>
      <DialogBody {...props} />
    </DialogContent>
  </Dialog>
)
