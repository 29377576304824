import {countryOptionConfigFields} from './countryOptionFields'
import CountryOptionList from '../../../pages/CountryOption/List'
import {countryOptionFormConfig} from './countryOptionFormConfig'

export const countryOptionConfig = {
  type: 'B2BDeal',
  path: 'countryoption',
  label: 'menu.countryOption.b2b',
  key: 'countryOption',
  sort: [],
  limit: 25,
  filters: [],
  page: 1,
  fields: {...countryOptionConfigFields},
  hiddenFields: [],
  component: CountryOptionList,
  ...countryOptionFormConfig,
  hidden: {},
  permissions: {
    entity: 'web.admin.ui.car.bulkUpload',
    types: ['READ']
  }
}
