export async function convertAndDownload(res, fileName) {
  let blob = new Blob([res], {type: 'application/octetstream'})

  //Check the Browser type and download the File.
  const isIE = false || !!document.documentMode
  if (isIE) {
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    let url = window.URL || window.webkitURL
    let link = url.createObjectURL(blob)
    let a = document.createElement('a')
    a.setAttribute('download', fileName)
    a.setAttribute('href', link)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
