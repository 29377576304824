import {all, call, put, select, takeLatest, takeEvery} from 'redux-saga/effects'

import * as globalTypes from '../global/types'
import * as types from './types'
import * as AuthApi from '../../api/auth/requests'
import {getDealerProfileData} from './selectors'

function* fetchDealerProfileSaga(action) {
  try {
    const id = action.payload
    const user = yield call(AuthApi.getDealerProfileDetails, id)
    let dealer = null

    if (user && user.user && user.user.list) {
      dealer = user.user.list[0]
    } else {
      dealer = yield select(getDealerProfileData)
    }

    yield put({
      type: types.fetchDealerProfileSuccess,
      payload: dealer
    })
  } catch (err) {
    yield put({
      type: types.fetchDealerProfileError,
      payload: err
    })
    yield put({
      type: globalTypes.apiError,
      payload: err
    })
  }
}

const metaInfoMapper = {
  dealerManager: types.dealerManagerNameSet,
  onboardingAgent: types.onboardingAgentNameSet,
  dealerFarmer: types.dealerFarmerNameSet,
  dealerHunter: types.dealerHunterNameSet,
  dealerBidManager: types.dealerBidManagerNameSet
}

function* loadDealerSaga(action) {
  try {
    const data = yield call(AuthApi.getDealer, action.payload)

    yield put({
      type: types.dealerSet,
      payload: data
    })
    yield put({
      type: types.fetchDealerMetaData,
      payload: data
    })
  } catch (e) {
    yield put({
      type: types.setDealerLoading,
      payload: true
    })
    yield put({
      type: globalTypes.apiError,
      payload: e
    })
  }
}

function* fetchDealerMetaSaga(action) {
  try {
    const user = action.payload.user

    if (user.list && user.list.length) {
      const {metaInfo} = user.list[0]
      const actionArray = Object.keys(metaInfoMapper).map((key) => {
        if (metaInfo[key]) {
          return call(findUserSaga, {
            id: metaInfo[key],
            actionName: metaInfoMapper[key]
          })
        }

        return
      })

      yield all(actionArray)
      yield put({
        type: types.setDealerLoading,
        payload: true
      })
    }
  } catch (e) {
    yield put({
      type: globalTypes.apiError,
      payload: e
    })
  }
}

function* findUserSaga(action) {
  try {
    const data = yield call(AuthApi.getDealerManager, action.id)

    yield put({
      type: action.actionName,
      payload: data
    })
  } catch (e) {
    yield put({
      type: globalTypes.apiError,
      payload: e
    })
  }
}

export const sagas = [
  takeLatest(types.fetchDealerProfile, fetchDealerProfileSaga),
  takeLatest(types.loadDealer, loadDealerSaga),
  takeLatest(types.fetchDealerMetaData, fetchDealerMetaSaga),
  takeEvery(types.findUserFromService, findUserSaga)
]
