import Filter from '../Filter/Filter'
import DropdownContent, {
  DropdownBody,
  DropdownHeading,
  DropdownAction
} from '../../common/Dropdown/Dropdown'
import {Button, withStyles} from '@material-ui/core'
import {translate} from 'react-i18next'

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
  button: {
    width: '100%'
  }
})

const ActionBase = translate()(({t, classes, ...props}) => {
  return (
    props.active.type !== 'date' && (
      <DropdownAction {...props}>
        <Button
          variant='raised'
          color='secondary'
          onClick={props.handleSave}
          className={classes.button}
          data-test='filter-apply'
        >
          {t('button.apply')}
        </Button>
      </DropdownAction>
    )
  )
})
const Action = withStyles(styles)(ActionBase)

const Body = (props) => (
  <DropdownBody {...props}>
    <Filter
      open={props.show}
      {...props.active}
      fieldName={props.active.key}
      handleChange={props.updateValue}
      handleSave={props.handleSave}
    />
  </DropdownBody>
)

const Heading = translate()(({t, ...props}) => (
  <DropdownHeading
    heading={t(props.active.label)}
    direction='back'
    onNavigate={props.reset}
    {...props}
  />
))

export const FilterSpecification = (props) => {
  return props.show === true ? (
    <DropdownContent
      Heading={<Heading {...props} />}
      Body={<Body {...props} />}
      Action={<Action {...props} />}
    />
  ) : null
}

export default FilterSpecification
