import {find, compose, path, map, pathEq} from 'ramda'
import {parseMetaInfoChange} from '../../../utils/dataTransforms'

export const findByEntityId = (entity) => find(pathEq(['id'], entity.entityId))

/**
 * Updates a document ticket entity with document data
 *
 * @param Object {documentData: documentData[]}; documentData[] = [{id: documentId, ...data}]
 *
 * @returns TicketEntity[]
 */
export const updatedTicketWithData = ({documentData}) =>
  compose(
    map((entity) => {
      const foundDocData = findByEntityId(entity)(documentData)

      if (foundDocData) {
        entity.data = foundDocData
      }

      return entity
    }),
    path(['ticketEntity'])
  )

/**
 * Updates a ticket entity with data from changes param
 *
 * @param Object {change, ticketEntityId}
 *
 * @returns TicketEntity[]
 */
export const updateDocumentWithMetaData = ({change, ticketEntityId}) =>
  compose(
    map((entity) => {
      const isThisEntityChanged = entity.id === ticketEntityId

      if (isThisEntityChanged) {
        const changeObject = {[change.field]: parseMetaInfoChange(change)}

        return {
          ...entity,
          metaInfo: {...entity.metaInfo, ...changeObject}
        }
      }

      return entity
    }),
    path(['ticketEntity'])
  )
