import * as types from './types'

export const fetchTiers = () => ({
  type: types.fetchTiers
})
export const resetLeadAllocationForm = () => ({
  type: types.formTypes.resetForm
})
export const createTier = (data) => ({
  type: types.createTier,
  payload: data
})
export const updateTier = (data) => ({
  type: types.updateTier,
  payload: data
})
export const deleteTier = (id, tierCriteriaId) => ({
  type: types.deleteTier,
  payload: {id, tierCriteriaId}
})
export const updateFilters = (payload) => ({
  type: types.updateFilters,
  payload
})
