/* eslint-disable no-console */
import {Component} from 'react'
import {connect} from 'react-redux'
import {
  fetchData,
  toggleField,
  toggleSort,
  updateFilters
} from '../../store/inspectionReports/actions'
import PageTableBase from '../../components/PageTableBase'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

export class InspectionReports extends Component {
  static defaultProps = {
    selectable: true
  }

  render() {
    return (
      <PageTableBase
        {...this.props}
        title='InspectionReports'
        noSubheader
        noSubfooter
        filterable
        multiSelectActions={[
          {
            event: () => console.log('DELETE'),
            icon: <DeleteIcon />,
            title: 'Delete'
          },
          {
            event: () => console.log('EDIT'),
            icon: <EditIcon />,
            title: 'Editor'
          }
        ]}
        singleSelectActions={[
          {
            event: () => console.log('DELETE'),
            icon: <DeleteIcon />,
            title: 'Delete'
          },
          {
            event: () => console.log('EDIT'),
            icon: <EditIcon />,
            title: 'Editor'
          }
        ]}
      />
    )
  }
}

const mapStateToProps = ({inspectionReports}) => {
  const {
    count,
    filters,
    data,
    error,
    fetching,
    fields,
    limit,
    sort,
    page
  } = inspectionReports

  return {
    count,
    filters,
    fetching,
    sort,
    error,
    data: data !== null ? Object.values(data) : [],
    page: page !== null ? page : 1,
    limit: limit !== null ? limit : 25,
    fields: fields !== null ? fields : []
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: (args = {}) => dispatch(fetchData(args)),
  toggleField: ({key}) => dispatch(toggleField(key)),
  handleSortChange: (sort) => dispatch(toggleSort(sort)),
  onFilterChange: ({filters}) => dispatch(updateFilters({filters}))
})

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReports)
