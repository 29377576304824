import React, {useEffect, useContext} from 'react'
import cx from 'classnames'
import {useParams} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Fade from '@material-ui/core/Fade'

import {history} from '../../../../history'

import Location from '../../types/Location'

import {MainContext} from '../../context/MainContext'
import {GlobalContext} from '../../context/GlobalContext'

import Sidebar from '../../components/Sidebar/Sidebar'
import CustomResponseSnackbar from '../../components/SnackBar/CustomResponseSnackbar'
import PageHeader from './PageHeader'
import GlobalConfiguration from './PlaceConfiguration/GlobalConfiguration'
import LocationConfiguration from './PlaceConfiguration/LocationConfiguration'
import ResetLocation from './PlaceConfiguration/components/ResetLocation'
import Services from './Services/Services'
import Holidays from './Holidays/Holidays'
import LotAreas from './LotAreas/LotAreas'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import {DEFAULT_LOCATIONS} from '../../config/constants'
import {getTitle} from '../../config/utils'
import GlobalPlaceConfiguration from '../../types/GlobalPlaceConfiguration'

const LocationPage = () => {
  const {id} = useParams<{id: string}>()
  const classes = useCommonStyles()
  const {selectedLocation, locationList, setSelectedLocation} = useContext(
    MainContext
  )
  const {globalPlaceConfiguration} = useContext(GlobalContext)

  useEffect(() => {
    if (selectedLocation === null) {
      setSelectedLocationWithUrlParam()
    }
  }, [])

  const setSelectedLocationWithUrlParam = () => {
    let location: Location | GlobalPlaceConfiguration
    switch (id) {
      case DEFAULT_LOCATIONS.master.id:
        location = DEFAULT_LOCATIONS.master
        break
      case DEFAULT_LOCATIONS.newLocation.id:
        location = DEFAULT_LOCATIONS.newLocation
        break
      default:
        location = locationList.find(
          (location: Location) => location.id === id
        )!
    }
    setSelectedLocation(location)
  }

  const handleBackToList = () => {
    history.push('/location-manager/')
    setSelectedLocation(null)
  }

  if (!selectedLocation || !globalPlaceConfiguration) return null

  const title: string = getTitle(selectedLocation)
  const isNewLocation = selectedLocation.id === DEFAULT_LOCATIONS.newLocation.id
  const isMaster = selectedLocation.id === DEFAULT_LOCATIONS.master.id

  return (
    <Fade in={!!selectedLocation}>
      <Grid
        container
        className={cx(classes.backgroundLightGray, classes.justifyCenter)}
      >
        {!isNewLocation && (
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
        )}
        <Grid item xs={isNewLocation ? 10 : 9}>
          <div
            id='scrollable-page-content'
            className={cx(
              classes.relative,
              classes.padding4,
              classes.paddingBottom8,
              classes.smoothScroll,
              classes.block,
              classes.fullWidth,
              {
                [classes.overFlowYAuto]: !isNewLocation,
                [classes.contentHeight]: !isNewLocation
              }
            )}
          >
            <PageHeader title={title} onBackClick={handleBackToList} />
            {isMaster ? (
              <GlobalConfiguration />
            ) : (
              <LocationConfiguration
                isNewLocation={isNewLocation}
                key={selectedLocation.id}
              />
            )}
            {!isNewLocation && <Services isMaster={isMaster} />}
            {!isNewLocation && <Holidays isMaster={isMaster} />}
            {!isNewLocation && <LotAreas isMaster={isMaster} />}
            {!isMaster && !isNewLocation && (
              <Grid item xs={12} className={classes.textRight}>
                <ResetLocation />
              </Grid>
            )}
          </div>
        </Grid>
        {/* Status bar for requests, or actions */}
        <CustomResponseSnackbar />
      </Grid>
    </Fade>
  )
}

export default LocationPage
