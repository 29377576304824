import wrapFilter from '../../../components/RichTable/FilterBar/Filter/wrapFilter'
import DealerBase from './DealerBase'
import StatusBase from './StatusBase'
import BusinessCategoryBase from './BusinessCategoryBase'

const StatusFilter = wrapFilter(StatusBase)
const DealerFilter = wrapFilter(DealerBase)
const BusinessCategoryFilter = wrapFilter(BusinessCategoryBase)

export {StatusFilter, DealerFilter, BusinessCategoryFilter}
