import {Component} from 'react'
import {translate} from 'react-i18next'

const POP = 'POP'

const BlockNavigation = (WrappedComponent) => {
  class Internal extends Component {
    constructor(props) {
      super(props)

      // Blocks the back/forward action of browser
      this.history = this.props.history.block((location, action) => {
        if (action === POP) {
          return this.props.t('calendar.navigation.leave')
        }
      })
    }

    componentWillUnmount() {
      // Unblocks the back/forward action of browser
      this.history()
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return translate()(Internal)
}

export default BlockNavigation
