import {useContext, useCallback, useState, Fragment} from 'react'
import PropTypes from 'prop-types'

import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import {BPDetailsContext} from '../context/BPDetailsContext'
import {BPDetailsDispatchContext} from '../context/BPDetailsDispatchContext'
import CountryOptionsSelect from '../../../components/CountryOptionsSelect/CountryOptionsSelect'
import {translate} from 'react-i18next'

import './TabContentForm.styl'
export function TabContentForm({
  t,
  tabInfo,
  tabInfoId,
  hidden = false,
  disableEditOrAdd
}) {
  const bpInfo = useContext(BPDetailsContext)
  const dispatch = useContext(BPDetailsDispatchContext)
  const fieldConfig = tabInfo.fields
  const bpSectionId = tabInfo.inMetaInfo ? 'metaInfo' : tabInfo.id

  const [formData, setFromData] = useState({...bpInfo[bpSectionId]})

  const onChangeHandler = useCallback(
    (e, {type, id}) => {
      setFromData((currentFormData) => {
        const {checked, value} = e.target || {}

        const newFormData = {
          ...currentFormData,
          [id]: type === 'checkbox' ? !!checked : value
        }

        dispatch({
          type: 'UPDATE_PAYMENT_INFO',
          payload: {
            fieldName: id,
            value: newFormData[id]
          }
        })

        return newFormData
      })
    },
    [dispatch]
  )

  return (
    <div hidden={hidden} className='tabContentForm'>
      {fieldConfig.map((fieldInfo) => (
        <Fragment key={fieldInfo.id}>
          {fieldInfo.type === 'select' && fieldInfo.optionsSourceType && (
            <CountryOptionsSelect
              label={t(`businessPartners.${tabInfo.id}.${fieldInfo.id}.label`)}
              name={fieldInfo.id}
              id={fieldInfo.id}
              countryOptionType={fieldInfo.optionsSourceType}
              value={
                Array.isArray(formData[fieldInfo.id])
                  ? formData[fieldInfo.id][0]
                  : formData[fieldInfo.id]
              }
              optionsLabelPrefix='businessPartners'
              margin='normal'
              disabled={disableEditOrAdd}
              required={fieldInfo.required}
              onChange={(e) => onChangeHandler(e, fieldInfo)}
              fullWidth
            />
          )}
          {fieldInfo.type === 'select' && !fieldInfo.optionsSourceType && (
            <Select
              id={fieldInfo.id}
              value={
                Array.isArray(formData[fieldInfo.id])
                  ? formData[fieldInfo.id][0]
                  : formData[fieldInfo.id]
              }
              onChange={(e) => onChangeHandler(e, fieldInfo)}
              required={fieldInfo.required}
            >
              {fieldInfo.options.map((fieldOpt) => (
                <MenuItem key={`${fieldInfo.id}-${fieldOpt}`} value={fieldOpt}>
                  {fieldOpt}
                </MenuItem>
              ))}
            </Select>
          )}
          {['text', 'number', 'email'].includes(fieldInfo.type) && (
            <TextField
              id={fieldInfo.id}
              value={formData[fieldInfo.id]}
              label={t(`businessPartners.${tabInfoId}.${fieldInfo.id}.label`)}
              placeholder={t(
                `businessPartners.${tabInfoId}.${fieldInfo.id}.placeholder`
              )}
              onChange={(e) => onChangeHandler(e, fieldInfo)}
              required={fieldInfo.required}
            />
          )}

          {fieldInfo.type === 'checkbox' && (
            <Switch
              name={fieldInfo.id}
              checked={formData[fieldInfo.id]}
              onChange={(e) => onChangeHandler(e, fieldInfo)}
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}

TabContentForm.propTypes = {
  t: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  tabInfo: PropTypes.object.isRequired
}

export default translate()(TabContentForm)
