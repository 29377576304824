import React from 'react'
import cx from 'classnames'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

import {useCommonStyles} from '../../hooks/useStyles/ThemeConfig'
import {convertToLowercaseWithInitialCapital} from '../../config/utils'

interface SelectProps {
  title: string
  required?: boolean
  options: string[]
  value: string
  error?: string
  dataTest?: string
  optionsLowerCase?: boolean
  onChange: (value: string) => void
}

export default function SelectField({
  title,
  options = [],
  value = '',
  error,
  dataTest,
  onChange,
  required = false,
  optionsLowerCase = false
}: SelectProps) {
  const classes = useCommonStyles()
  const labelId = `${title}-select-label`

  return (
    <FormControl
      className={cx(classes.fullWidth)}
      required={required}
      error={!!error}
    >
      <InputLabel id={labelId}>{title}</InputLabel>
      <Select
        fullWidth
        disabled={options.length === 0}
        labelId={labelId}
        value={value}
        onChange={(event: React.ChangeEvent<{value: string}>) => {
          onChange(event.target.value)
        }}
        input={<Input />}
        data-test={dataTest}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {optionsLowerCase
              ? convertToLowercaseWithInitialCapital(option)
              : option}
          </MenuItem>
        ))}
      </Select>
      <Typography variant='caption' display='block' gutterBottom color='error'>
        {error}
      </Typography>
    </FormControl>
  )
}
