import {createSelector} from 'reselect'
import {getListSelectors} from '../common/listSelectors'

const selectorPath = (state) => state.webhook
const selectors = getListSelectors(selectorPath)

export default {
  ...selectors,
  getWebhookList: (state) => selectorPath(state).webhookList,
  getEvents: (state) => selectorPath(state).events,
  getShowNonceDialog: (state) => selectorPath(state).showNonceDialog,
  getWebhookNonce: (state) => selectorPath(state).webhookNonce,
  getFormData: (state) => selectorPath(state).form,
  getSelectedWebhook: (state) => selectorPath(state).data,
  getSelectedWebhookId: (state) => selectorPath(state).selectedWebhookId,
  getCreateDialogState: (state) => selectorPath(state).showCreateDialog,
  getParsedFilterForQuery: createSelector(selectors.getFilters, (filters) => {
    if (filters && Array.isArray(filters)) {
      return filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]: filter.value
        }),
        {}
      )
    }

    return {}
  })
}
