import {PureComponent} from 'react'
import {ValidatedForm} from '@fcg/formvalidation'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import Upload from '../../components/Upload'
import Delete from '@material-ui/icons/Delete'
import {
  loadPdfPreference,
  updatePdfPreference,
  updatePdfPreferenceField,
  updatePdfPreferenceMetaField,
  resetPreferenceData,
  savePdfPreference,
  uploadAsset,
  setUploading
} from '../../store/documentConfig/actions'
import {Button, Grid} from '@material-ui/core'
import * as selectors from '../../store/documentConfig/selectors'
import withConfig from '../../components/providers/withConfig'
import Edit from './Edit'
import './documentConfig.styl'

import styled from 'styled-components'
import Slide from '../../components/Slide'
import {DeleteDialog} from '../CRM/common'

const deleteInitialState = {
  open: false,
  handleSubmit: () => {},
  handleCancel: () => {}
}

const DocumentConfigLogo = styled.img`
  width 466px;
  margin 20px;
`

const DocumentConfigLogoWrapper = styled.div`
  position relative;
`

const DocumentConfigLogoRemoveIcon = styled.a`
  position absolute;
  bottom 35px;
  cursor pointer;
`

const DocumentConfig = styled.div`
  width 100%;

  >hr{
    border 1px solid #BBBBBB;
  }
`

const ActionsRight = styled.div`
margin-left auto;
margin-top 8px;
`

const DocumentUploadWrapper = styled.div`
  display flex;
  flex-wrap wrap;
`

const DocumentConfigItem = styled.div`
  button.sc-bxivhb{
    float left;
    overflow hidden;
    width 466px;
    height 252px;
    margin 20px;
  }

  &:nth-child(even){
    padding-left 8px;
  }

  &:nth-child(odd){
    padding-right 8px
  }

  .dropzone{
    margin:20px 0
  }

  >h4{
    color #242424;
    font-family Roboto;
    font-size 17px;
    font-weight 300;
    margin-top: 40px;
  }
`

class DocumentConfigEdit extends PureComponent {
  state = {
    isModalOpen: false,
    currentImageInModal: null
  }

  constructor(props) {
    super(props)
  }
  openGallery(currentImageInModal) {
    this.setState({
      isModalOpen: true,
      currentImageInModal
    })
  }
  closeGallery() {
    this.setState({
      isModalOpen: false,
      currentImageInModal: null
    })
  }
  componentDidMount() {
    if (this.props.pageConfig.key !== 'documentConfigEdit') {
      this.props.resetPreferenceData()
      this.props.updatePdfPreferenceField(
        'country',
        this.props.country.countryCode
      )
    } else {
      this.props.loadPdfPreference(this.props.match.params.id)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.id !== nextProps.match.params.id &&
      this.props.pageConfig.key !== 'documentConfigEdit'
    ) {
      this.props.loadPdfPreference(nextProps.match.params.id)
    }
  }

  routeBack = () => this.props.history.goBack()

  updateField = (e, fieldName) => {
    this.props.updatePdfPreferenceField(fieldName, e.target.value)
  }

  updateMetaField = (e, fieldName) => {
    const value = e && e.target ? e.target.value : e

    this.props.updatePdfPreferenceMetaField(fieldName, value)
  }

  handleToggleChange = (event, name) => {
    this.props.updatePdfPreferenceMetaField(name, event.target.checked)
  }

  handleSelectFieldChange = (event, name) => {
    this.props.updatePdfPreferenceMetaField(name, event.target.value)
  }

  savePdfPreference = () => {
    if (this.props.pageConfig.key === 'documentConfigEdit') {
      this.props.updatePdfPreference()
    } else {
      this.props.savePdfPreference()
    }

    this.routeBack()
  }

  closeDeleteDialog = () => {
    this.setState({delete: {...deleteInitialState}})
  }

  onDeleteClick = (logoLabel, logoName) => {
    this.setState({
      delete: {
        open: true,
        entity: logoLabel,
        handleSubmit: () => {
          this.updateMetaField(null, logoName)
          this.closeDeleteDialog()
        },
        handleCancel: this.closeDeleteDialog
      }
    })
  }

  renderDocumentConfigImages = (data, logo, logoLabel) =>
    data.metaInfo[logo.name] ? (
      this.state.isModalOpen ? (
        <Slide
          image={this.state.currentImageInModal}
          onClose={() => this.closeGallery()}
        />
      ) : (
        <DocumentConfigLogoWrapper>
          <DocumentConfigLogo
            src={data.metaInfo[logo.name]}
            onClick={() => this.openGallery(data.metaInfo[logo.name])}
          />
          <DocumentConfigLogoRemoveIcon
            onClick={() => this.onDeleteClick(logoLabel, logo.name)}
          >
            <Delete className='icon' />
          </DocumentConfigLogoRemoveIcon>
        </DocumentConfigLogoWrapper>
      )
    ) : null

  renderDocumentConfigLogos = (data) => {
    const {t, setUploading, uploadAsset} = this.props
    const logos = [
      {
        label: 'documentConfigFormConfig.page.headerLogo',
        name: 'headerLogo'
      },
      {
        label: 'documentConfigFormConfig.page.firstPageLogo',
        name: 'firstPageLogo'
      },
      {
        label: 'documentConfigFormConfig.page.lastPageLogo',
        name: 'lastPageLogo'
      },
      {
        label: 'documentConfigFormConfig.page.footerLogo',
        name: 'footerLogo'
      }
    ]

    return (
      <DocumentConfig>
        {logos.map((logo) => {
          return (
            <DocumentConfigItem key={`document-config-${logo.name}`}>
              <h4>{t(logo.label)}</h4>
              <hr />
              <DocumentUploadWrapper>
                <Upload
                  entity='dealer'
                  visibility='internal'
                  status='reviewPending'
                  accept='image/jpeg, image/png, image/jpg'
                  uploadDocument={(doc) => {
                    uploadAsset(doc, logo.name)
                  }}
                  setUploading={setUploading}
                />
                {this.renderDocumentConfigImages(data, logo, t(logo.label))}
              </DocumentUploadWrapper>
            </DocumentConfigItem>
          )
        })}
      </DocumentConfig>
    )
  }

  render() {
    const {t} = this.props
    const data = {
      ...this.props.pdfPreferenceData,
      ...this.props.pdfPreferenceData.metaInfo
    }

    return (
      <section className='page'>
        <ValidatedForm onSubmit={this.savePdfPreference} t={t}>
          <Grid
            container
            className='DocumentConfig__form'
            spacing={16}
            style={{width: '80%', margin: '0 auto 40px'}}
          >
            <Edit
              {...this.props}
              data={data}
              updateMetaField={this.updateMetaField}
              updateField={this.updateField}
              handleToggleChange={this.handleToggleChange}
              handleSelectFieldChange={this.handleSelectFieldChange}
              t={t}
            />
            {this.renderDocumentConfigLogos(data)}
            <ActionsRight>
              <Button variant='raised' color='primary' type='submit'>
                {t('button.save')}
              </Button>
            </ActionsRight>
          </Grid>
        </ValidatedForm>
        <DeleteDialog {...this.state.delete} />
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  pdfPreferenceData: selectors.getPdfPreferenceData(state)
})

const mapDispatchToProps = (dispatch) => ({
  resetPreferenceData: () => dispatch(resetPreferenceData()),
  loadPdfPreference: (preferenceId) =>
    dispatch(loadPdfPreference(preferenceId)),
  updatePdfPreferenceField: (name, value) =>
    dispatch(updatePdfPreferenceField(name, value)),
  updatePdfPreferenceMetaField: (name, value) =>
    dispatch(updatePdfPreferenceMetaField(name, value)),
  savePdfPreference: () => dispatch(savePdfPreference()),
  updatePdfPreference: () => dispatch(updatePdfPreference()),
  uploadAsset: (payload, name) => dispatch(uploadAsset(payload, name)),
  setUploading: (payload) => dispatch(setUploading(payload))
})

export default withConfig(
  ['country'],
  connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentConfigEdit))
)
